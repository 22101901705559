import { getLayoverLogger } from '@/utils/layover/LayoverLogger';
import { getString } from '@/content/i18n';
import { hideModal } from '@/actions/ModalActionCreators';
import { isValidEmail } from '@/utils/form-validation';
import { ModalId } from '@/constants/Modal';
import { setState } from '@/utils/promise-utils';
import Api from '@/api/Api';
import AuthStore from '@/stores/AuthStore';
import CLCheckboxInput from '@/components/library/form/input/CLCheckboxInput';
import EnvInfo from '@/env/EnvInfo';
import EventTarget from '@/analytics/constants/EventTarget';
import FieldOfStudy from '@/constants/FieldOfStudy';
import Icon from '@/components/shared/icon/Icon';
import Input from '@/components/shared/common/form/Input';
import IsDesktop from '@/components/util/env/IsDesktop';
import IsMobile from '@/components/util/env/IsMobile';
import logger from '@/logger';
import MultiselectDropdown from '@/components/shared/common/form/MultiselectDropdown';
import S2Dispatcher from '@/utils/S2Dispatcher';
import ShowEvent from '@/analytics/models/ShowEvent';
import SubmitEvent from '@/analytics/models/SubmitEvent';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';
import UserOccupationInput from '@/components/shared/account/UserOccupationInput';
import WeblabStore from '@/weblab/WeblabStore';

import classnames from 'classnames';
import idx from 'idx';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

export default class DemographicModal extends React.PureComponent {
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    authStore: PropTypes.instanceOf(AuthStore).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    weblabStore: PropTypes.instanceOf(WeblabStore).isRequired,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      subscriptionOptIn: true,
      ...this.getStateFromAuthStore(),
    };

    this.context.authStore.registerComponent(this, () => {
      this.setState(this.getStateFromAuthStore);
    });

    this.onChangefirstName = this.onChangeInput.bind(this, 'firstName');
    this.onChangelastName = this.onChangeInput.bind(this, 'lastName');
    this.onChangeEmail = this.onChangeInput.bind(this, 's2AccountEmail');
    this.onChangeCurrentRole = this.onChangeInput.bind(this, 'currentOccupation');
    this.onChangeWebsiteUrl = this.onChangeInput.bind(this, 'websiteUrl');
  }

  componentDidMount() {
    const { userId } = this.state;

    // disable scrolling when modal opens
    const body = document.body;
    if (body) {
      body.style.overflow = 'hidden';
    }

    trackAnalyticsEvent(
      ShowEvent.create(EventTarget.DemographicModal.DEMOGRAPHIC_MODAL_IMPRESSION, userId)
    );
  }

  componentWillUnmount() {
    // reenable scrolling when modal closes
    const body = document.body;
    if (body) {
      body.style.removeProperty('overflow');
    }
  }

  onClickCloseModal = () => {
    this.context.dispatcher.dispatch(hideModal());
  };

  getStateFromAuthStore() {
    const { authStore } = this.context;
    const user = authStore.getUser();

    return {
      // $FlowFixMe: Next line causes error when flow typing was added to context (See: #22776)
      userId: user.id,
      // $FlowFixMe: Next line causes error when flow typing was added to context (See: #22776)
      firstName: user.firstName,
      // $FlowFixMe: Next line causes error when flow typing was added to context (See: #22776)
      lastName: user.lastName,
      // $FlowFixMe: Next line causes error when flow typing was added to context (See: #22776)
      s2AccountEmail: user.email,
      // $FlowFixMe: Next line causes error when flow typing was added to context (See: #22776)
      currentOccupation: user.currentOccupationLabel,
      // $FlowFixMe: Next line causes error when flow typing was added to context (See: #22776)
      fieldsOfStudy: user.fieldsOfStudy.map(_ => _.id),
    };
  }

  onChangeInput(field, event) {
    const fieldVal = event.currentTarget ? event.currentTarget.value : event;
    this.setState({
      [`${field}Error`]: null,
      [`${field}`]: fieldVal,
    });
  }

  onChangeOptInMarketing = () => {
    const { subscriptionOptIn } = this.state;
    this.setState({
      subscriptionOptIn: subscriptionOptIn ? false : true,
    });
  };

  onChangeFieldsOfStudy = studyFields => {
    this.setState({
      fieldsOfStudy: studyFields,
    });
  };

  generateErrorList = errors => {
    return Immutable.List(errors.filter(msg => !!msg));
  };

  validateFormState = () => {
    const { s2AccountEmail } = this.state;
    let emailError = null;
    let emailInvalidError = null;

    if (!s2AccountEmail) {
      emailError = getString(_ => _.demographicForm.error.missingEmail);
    }

    if (s2AccountEmail) {
      if (!isValidEmail(s2AccountEmail) || s2AccountEmail.trim() === '') {
        emailInvalidError = getString(_ => _.demographicForm.error.emailInvalid);
      }
    }

    return {
      emailError,
      emailInvalidError,
    };
  };

  onSubmitForm = async () => {
    if (this._onSubmitFormPromise) {
      throw new Error('Already sending form');
    }
    const promise = this.onSubmitFormImpl();
    this._onSubmitFormPromise = promise;
    try {
      await promise;
    } catch (e) {
      logger.error(e);
    } finally {
      this._onSubmitFormPromise = null;
    }
  };

  async onSubmitFormImpl() {
    const {
      userId,
      firstName,
      lastName,
      s2AccountEmail,
      websiteUrl,
      currentOccupation,
      fieldsOfStudy,
      subscriptionOptIn,
    } = this.state;

    const errorState = this.validateFormState();
    await setState(this, errorState);

    const hasErrors = !!Object.values(errorState).find(_ => _ !== null);
    // don't submit form if errors
    if (hasErrors) {
      logger.warn('Could not save due to errors', errorState);
      return;
    }

    let errorSubmitting = false;
    try {
      const marketingInformationSubscription = subscriptionOptIn;
      const productFeaturesSpotlightSubscription = subscriptionOptIn;
      const semanticScholarNewsletterSubscription = subscriptionOptIn;
      const allenInstituteNewsletterSubscription = subscriptionOptIn;

      const data = {
        firstName,
        lastName,
        s2AccountEmail,
        websiteUrl,
        currentOccupation,
        fieldsOfStudy,
        marketingInformationSubscription,
        productFeaturesSpotlightSubscription,
        semanticScholarNewsletterSubscription,
        allenInstituteNewsletterSubscription,
        hasCompletedDemographics: true,
      };

      getLayoverLogger().log('submit.demoModal.fieldsOfStudy', {
        fos: fieldsOfStudy,
      });
      await this.context.api.submitUserContactInfo(data);

      trackAnalyticsEvent(
        SubmitEvent.create(EventTarget.DemographicModal.DEMOGRAPHIC_SUBMIT, userId)
      );
    } catch (error) {
      if (idx(error, _ => _.errorCode) === 'ContactEmailTaken') {
        trackAnalyticsEvent(
          ShowEvent.create(EventTarget.DemographicModal.EMAIL_CONTACT_CONFLICT, userId)
        );
        await setState(this, {
          emailInvalidError: getString(_ => _.demographicForm.error.emailTaken),
        });
        errorSubmitting = true;
      } else if (idx(error, _ => _.errorCode) === 'ContactInvalidEmail') {
        await setState(this, {
          emailInvalidError: getString(_ => _.demographicForm.error.emailInvalid),
        });
        errorSubmitting = true;
      } else {
        // silent failure for other kinds of issues
        logger.error(error);
      }
    }
    if (!errorSubmitting) {
      this.context.dispatcher.dispatch(hideModal());
    }
  }

  renderInput = ({ label, isRequired, value, onChange, errors, placeholder }) => {
    return (
      <Input
        label={label}
        className="standard-form"
        isRequired={isRequired}
        type={'text'}
        value={value || ''}
        onChange={onChange}
        errors={this.generateErrorList([...errors])}
        placeholder={placeholder}
      />
    );
  };

  renderMarketingCheckbox = () => {
    const { subscriptionOptIn } = this.state;
    const checkboxText = getString(_ => _.demographicForm.optInMarketingText);
    const hoverArea = classnames({
      checkbox: true,
      'is-selected': subscriptionOptIn,
      'flex-row': true,
    });

    return (
      <div className="s2-form-input subscription-checkbox">
        <CLCheckboxInput
          name="marketing-subscription"
          value={subscriptionOptIn ? subscriptionOptIn.toString() : ''}
          isChecked={subscriptionOptIn}
          className={hoverArea}
          onChange={this.onChangeOptInMarketing}
        />
        <span className="checkbox-text">{checkboxText}</span>
      </div>
    );
  };

  renderDesktopNameInputs() {
    const { firstName, lastName } = this.state;
    const firstNameLabel = getString(_ => _.demographicForm.firstNameLabel);
    const lastNameLabel = getString(_ => _.demographicForm.lastNameLabel);

    return (
      <div className="flex-row">
        <div className="demographic-modal__name-container">
          {this.renderInput({
            label: firstNameLabel,
            isRequired: false,
            value: firstName,
            onChange: this.onChangefirstName,
            errors: [],
            placeholder: 'First Name',
          })}
        </div>
        <div className="demographic-modal__name-container demographic-modal__family-name">
          {this.renderInput({
            label: lastNameLabel,
            isRequired: false,
            value: lastName,
            onChange: this.onChangelastName,
            errors: [],
            placeholder: 'Last Name',
          })}
        </div>
      </div>
    );
  }

  renderMobileNameInputs() {
    const { firstName, lastName } = this.state;
    const firstNameLabel = getString(_ => _.demographicForm.firstNameLabel);
    const lastNameLabel = getString(_ => _.demographicForm.lastNameLabel);

    return (
      <React.Fragment>
        {this.renderInput({
          label: firstNameLabel,
          isRequired: true,
          value: firstName,
          onChange: this.onChangefirstName,
          errors: [],
          placeholder: 'First Name',
        })}
        {this.renderInput({
          label: lastNameLabel,
          isRequired: true,
          value: lastName,
          onChange: this.onChangelastName,
          errors: [],
          placeholder: 'Last Name',
        })}
      </React.Fragment>
    );
  }

  render() {
    const {
      s2AccountEmail,
      emailError,
      emailInvalidError,
      websiteUrl,
      fieldsOfStudy,
      currentOccupation,
    } = this.state;

    const isMobile = this.context.envInfo.isMobile;
    const emailLabel = getString(_ => _.demographicForm.emailLabel);
    const websiteLabel = getString(_ => _.demographicForm.websiteUrlLabel);
    const fieldsOfStudyLabel = getString(_ => _.demographicForm.fieldsOfStudyLabel);

    return (
      <div
        role="dialog"
        className="modal-container dark-modal demographic-modal"
        data-modal-id={ModalId.DEMOGRAPHIC}>
        <div
          className={classnames({
            'flex-row': true,
            'demographic-modal__desktop': !isMobile,
            'demographic-modal__mobile': isMobile,
          })}>
          <div className="demographic-modal__content">
            <form onSubmit={this.onSubmitForm}>
              <div className="demographic-modal__form">
                <div className="demographic-modal__header">
                  <IsMobile>
                    <button
                      className="sign-in-modal__mobile-close-btn"
                      onClick={this.onClickCloseModal}
                      aria-label={getString(_ => _.login.closeModalAriaLabel)}>
                      <Icon icon="x" width="15" height="15" />
                    </button>
                  </IsMobile>
                  <h1 className="demographic-modal__header-text">
                    {getString(_ => _.demographicForm.createAnAccountText)}
                  </h1>
                  <p className="demographic-modal__sub-header-text">
                    {getString(_ => _.demographicForm.stayConnectedText)}
                  </p>
                </div>
                <IsDesktop>{this.renderDesktopNameInputs()}</IsDesktop>
                <IsMobile>{this.renderMobileNameInputs()}</IsMobile>
                {this.renderInput({
                  label: emailLabel,
                  isRequired: true,
                  value: s2AccountEmail,
                  onChange: this.onChangeEmail,
                  errors: [emailError, emailInvalidError],
                })}
              </div>
              <div className="demographic-modal__footer">
                {this.renderInput({
                  label: websiteLabel,
                  value: websiteUrl,
                  onChange: this.onChangeWebsiteUrl,
                  errors: [],
                })}
                <UserOccupationInput
                  currentOccupation={currentOccupation}
                  onChangeCurrentRole={this.onChangeCurrentRole}
                />
                <MultiselectDropdown
                  isRequired={false}
                  options={FieldOfStudy.s2Selectors().map(field => field.name)}
                  selected={fieldsOfStudy}
                  label={fieldsOfStudyLabel}
                  onChange={this.onChangeFieldsOfStudy}
                />
                {this.renderMarketingCheckbox()}
                {emailInvalidError && (
                  <p className="demographic-modal__error-message">
                    {getString(_ => _.demographicForm.error.submitErrorMessage)}
                  </p>
                )}
                <button
                  className="button--primary demographic-modal__create-acct-btn"
                  aria-label={getString(_ => _.demographicForm.createAccountBtnLabel)}
                  onClick={this.onSubmitForm}
                  disabled={this._onSubmitFormPromise}>
                  {getString(_ => _.demographicForm.createAccountBtnLabel)}
                </button>
              </div>
            </form>
          </div>
          <IsDesktop>
            <button
              className="demographic-modal__close-btn"
              onClick={this.onClickCloseModal}
              aria-label={getString(_ => _.demographicForm.closeModalAriaLabel)}>
              <Icon icon="x" width="15" height="15" />
            </button>
          </IsDesktop>
        </div>
      </div>
    );
  }
}
