import React from 'react';

export default function IconTermUnderstanding(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 22.92 18.64">
      <path d="M10.27,11.26h-2.45l-.47,1.4h-1.48l2.52-6.77h1.29l2.53,6.77h-1.48l-.47-1.4Zm-2.07-1.13h1.69l-.85-2.53-.84,2.53Z" />
      <path d="M15.69,12.66c-.06-.12-.11-.27-.13-.45-.33,.36-.75,.54-1.27,.54-.49,0-.9-.14-1.23-.43-.32-.29-.49-.64-.49-1.08,0-.53,.2-.94,.59-1.23,.4-.29,.97-.43,1.71-.43h.62v-.29c0-.23-.06-.42-.18-.56-.12-.14-.31-.21-.57-.21-.23,0-.4,.05-.53,.16-.13,.11-.19,.26-.19,.45h-1.34c0-.29,.09-.56,.27-.81,.18-.25,.43-.44,.76-.58,.33-.14,.7-.21,1.11-.21,.62,0,1.11,.16,1.48,.47,.36,.31,.55,.75,.55,1.31v2.18c0,.48,.07,.84,.2,1.08v.08h-1.36Zm-1.11-.94c.2,0,.38-.04,.55-.13,.17-.09,.29-.21,.37-.36v-.87h-.5c-.67,0-1.03,.23-1.07,.7v.08c0,.17,.05,.31,.17,.41,.12,.11,.28,.16,.48,.16h0Z" />
      <path d="M1.18,1.25L11.28,3.44c.12,.03,.25,.03,.37,0h0S21.75,1.25,21.75,1.25V15.23l-10.29,2.23L1.18,15.23V1.25Zm-.24,13.93s0,0,0,0H.93ZM1.05,.02C.51-.1,0,.32,0,.87V15.48c0,.41,.29,.76,.69,.85,0,0,0,0,0,0l10.59,2.29h0c.12,.03,.25,.03,.37,0l10.59-2.29c.4-.09,.69-.44,.69-.85V.87c0-.55-.51-.97-1.05-.85,0,0,0,0,0,0L11.46,2.27,1.05,.02s0,0,0,0Z" />
    </symbol>
  );
}
