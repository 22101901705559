import {
  CUE_PAPERS,
  LIBRARY_FOLDER_SETTINGS,
  ORGANIZE_PAPERS,
  RECOMMENDATIONS_SETTINGS,
  SAVE_TO_LIBRARY,
  ShelfId,
} from '@/constants/ShelfId';
import { hideShelf } from '@/actions/ShelfActionCreators';
import { mapAppContextToProps, useStateFromStore } from '@/AppContext';
import { Nullable, ReactNodeish } from '@/utils/types';
import { ShelfContext } from '@/models/shelf/ShelfContexts';
import CLOverlay, { POSITION } from '@/components/library/layout/CLOverlay';
import CuePapersShelf from '@/components/shared/paper/CuePapersShelf';
import EnvInfo from '@/env/EnvInfo';
import LibraryFolderSettingsShelf from '@/components/shared/library/LibraryFolderSettingsShelf';
import ModalStore from '@/stores/ModalStore';
import OrganizePapersShelf from '@/components/shared/library/OrganizePapersShelf';
import RecommendationsSettingsShelf from '@/components/shared/recommendations/RecommendationsSettingsShelf';
import S2Dispatcher from '@/utils/S2Dispatcher';
import SaveToLibraryShelf from '@/components/shared/library/SaveToLibraryShelf';
import ShelfStore from '@/stores/ShelfStore';

import { CSSTransitionGroup } from 'react-transition-group';
import classnames from 'classnames';
import invariant from 'invariant';
import PropTypes from 'prop-types';
import React from 'react';

type PropsFromAppContext = {
  visibleShelfId: Nullable<ShelfId>;
  shelfContext: Nullable<ShelfContext>;
};

type Props = {
  isModalOpen: boolean;
} & PropsFromAppContext;

const SHELF_ANIMATION_MS = 400; // Keep in sync with _shelf.less

export class ShelfManager extends React.PureComponent<Props> {
  static contextTypes = {
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    shelfStore: PropTypes.instanceOf(ShelfStore).isRequired,
    modalStore: PropTypes.instanceOf(ModalStore).isRequired,
  };

  declare context: {
    dispatcher: S2Dispatcher;
    envInfo: EnvInfo;
    shelfStore: ShelfStore;
    modalStore: ModalStore;
  };

  onClickOverlay = event => {
    const { dispatcher } = this.context;
    event.stopPropagation();
    dispatcher.dispatch(hideShelf());
  };

  renderShelf(): ReactNodeish {
    const { shelfContext, visibleShelfId } = this.props;
    switch (visibleShelfId) {
      case SAVE_TO_LIBRARY:
        invariant(
          shelfContext && shelfContext.saveToLibrary,
          'shelf context was missing "saveToLibrary"'
        );
        return <SaveToLibraryShelf context={shelfContext.saveToLibrary} />;
      case LIBRARY_FOLDER_SETTINGS:
        invariant(
          shelfContext && shelfContext.libraryFolderSettings,
          'shelf context was missing "libraryFolderSettings"'
        );
        return <LibraryFolderSettingsShelf context={shelfContext.libraryFolderSettings} />;
      case RECOMMENDATIONS_SETTINGS:
        return <RecommendationsSettingsShelf />;
      case ORGANIZE_PAPERS:
        invariant(
          shelfContext && shelfContext.organizePapers,
          'shelf context was missing "organizePapers"'
        );
        return <OrganizePapersShelf context={shelfContext.organizePapers} />;

      case CUE_PAPERS:
        invariant(shelfContext && shelfContext.cuePapers, 'shelf context was missing "cuePapers"');
        return <CuePapersShelf context={shelfContext.cuePapers} />;
    }

    return null;
  }

  render(): ReactNodeish {
    const { isMobile } = this.context.envInfo;
    const { visibleShelfId, isModalOpen } = this.props;
    const shelf = this.renderShelf();
    const overlay = visibleShelfId ? (
      <div className="shelf-manager__overlay">
        <CLOverlay
          position={POSITION.SIDE_RIGHT_POS_MIDDLE}
          onClick={this.onClickOverlay}
          shouldFocusOnContent={true}>
          <CSSTransitionGroup
            transitionName="shelf-manager__trans-shelf"
            transitionEnterTimeout={SHELF_ANIMATION_MS}
            transitionLeaveTimeout={SHELF_ANIMATION_MS}>
            <div
              key={visibleShelfId}
              className={classnames('shelf-manager__shelf', {
                'shelf-manager__shelf--mobile': isMobile,
              })}>
              {shelf}
            </div>
          </CSSTransitionGroup>
        </CLOverlay>
      </div>
    ) : null;

    // Hides the Shelf manager from screen readers users when a modal is open
    // to trap focus within the modal
    const ariaHiddenProp = isModalOpen ? true : undefined;

    return (
      <div className="shelf-manager" aria-hidden={ariaHiddenProp}>
        <CSSTransitionGroup
          transitionName={
            isMobile ? 'shelf-manager__trans-overlay--mobile' : 'shelf-manager__trans-overlay'
          }
          transitionEnterTimeout={SHELF_ANIMATION_MS}
          transitionLeaveTimeout={SHELF_ANIMATION_MS}>
          {overlay}
        </CSSTransitionGroup>
      </div>
    );
  }
}

export default mapAppContextToProps<Props, PropsFromAppContext>(ShelfManager, appContext => {
  const shelfStoreProps = useStateFromStore(appContext.shelfStore, _ => ({
    visibleShelfId: _.getVisibleShelfId(),
    shelfContext: _.getShelfContext(),
  }));

  const modalStoreProps = useStateFromStore(appContext.modalStore, _ => ({
    isModalOpen: !!_.getVisibleModalId(),
  }));

  return {
    ...shelfStoreProps,
    ...modalStoreProps,
  };
});
