import {
  AuthorAliasFromJS,
  AuthorAliasRecord,
  AuthorAliasRecordFactory,
  getAuthorAliasFromJS,
} from './author/AuthorAlias';
import {
  getHighlightedFieldFromJS,
  HighlightedFieldFromJS,
  HighlightedFieldRecord,
  HighlightedFieldRecordFactory,
} from './HighlightedField';

import Immutable from 'immutable';

export type HighlightedAuthorFromJS = [AuthorAliasFromJS, HighlightedFieldFromJS];

type Props = {
  alias: AuthorAliasRecord;
  highlightedField: HighlightedFieldRecord;
};

const defaultProps: Props = {
  alias: AuthorAliasRecordFactory(),
  highlightedField: HighlightedFieldRecordFactory(),
};

export const HighlightedAuthorRecordFactory = Immutable.Record<Props>(defaultProps);
export type HighlightedAuthorRecord = Immutable.RecordOf<Props>;

export function parseHighlightedAuthors(
  arr: HighlightedAuthorFromJS[]
): Immutable.List<HighlightedAuthorRecord> {
  return Immutable.List<HighlightedAuthorRecord>(
    arr.map(authorTuple => {
      const [alias, highlightedField] = authorTuple;
      return HighlightedAuthorRecordFactory({
        alias: getAuthorAliasFromJS(alias),
        highlightedField: getHighlightedFieldFromJS(highlightedField),
      });
    })
  );
}
