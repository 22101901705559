import { CommonQueryProps } from './QueryUtils';
import { parseYears } from './Query';
import { YearRangeRecord, YearRangeRecordFactory } from './YearRange';

import { Nullable } from '@/utils/types';
import Citation from '@/constants/Citation';
import SortType from '@/constants/sort-type';

import Immutable from 'immutable';
import merge from 'merge';

export type CitationQueryFromQueryParams = {
  author?: string[];
  coAuthor?: string[];
  venue?: string[];
  fos?: string[];
  year: [string, string];
  page: number | string;
  sort?: string;
  pdf?: string;
  meq?: string;
  paperId?: string;
  templateId?: string;
  queryString?: string;
  citationIntent?: Nullable<string>;
};

export type CitationQueryFromJS = {
  authors: string[];
  coAuthors: string[];
  venues: string[];
  fieldsOfStudy: string[];
  yearFilter: YearRangeRecord;
  requireViewablePdf?: Nullable<boolean>;
  matchingEntityQuery?: Nullable<string>;
  templateId?: Nullable<string>;
  paperId?: Nullable<string>;
  citationIntent?: Nullable<string>;
  queryString?: Nullable<string>;
  citationRankingModelVersion?: Nullable<string>;
  includePdfVisibility?: Nullable<boolean>;
};

/**
 * This model is specifically about a _Search Within Citations_ Query
 */
type Props = CommonQueryProps & {
  requireViewablePdf: boolean;
  citationIntent?: Nullable<string>;
  queryString?: Nullable<string>;
  citationRankingModelVersion?: Nullable<string>;
};

const defaultProperties: Props = {
  page: 1,
  pageSize: 10,
  sort: SortType.RELEVANCE.id,
  authors: Immutable.Set(),
  coAuthors: Immutable.Set(),
  venues: Immutable.Set(),
  yearFilter: YearRangeRecordFactory(),
  requireViewablePdf: false,
  fieldsOfStudy: Immutable.OrderedSet(),
  citationIntent: undefined,
  queryString: undefined,
  citationRankingModelVersion: undefined,
};

export const RECORD_NAME = 'CitationQuery';
export const CitationQueryRecordFactory = Immutable.Record<Props>(defaultProperties, RECORD_NAME);
export type CitationQueryRecord = Immutable.RecordOf<Props>;

export function isCitationQueryRecord(r: unknown): r is CitationQueryRecord {
  return Immutable.isRecord(r) && Immutable.Record.getDescriptiveName(r) === RECORD_NAME;
}

export function resetCitationQueryRecord(query: CitationQueryRecord): CitationQueryRecord {
  return CitationQueryRecordFactory({
    sort: query.sort,
  });
}

/**
 * Prepares the query to be sent to the service layer by ensuring the sort parameter is set, and
 * that the year filter isn't set if either the min or the max is invalid.
 *
 * @param {string} sort=SortType.RELEVANCE.id
 *
 * @returns {object}
 */
export function prepareCitationQueryRecord(
  query: CitationQueryRecord,
  sort: Nullable<string> = SortType.RELEVANCE.id
): CitationQueryRecord {
  return query.withMutations(query => {
    if (!query.yearFilter?.min || !query.yearFilter?.max) {
      query.set('yearFilter', null);
    }
    if (!query.sort && sort) {
      query.set('sort', sort);
    }
    return query;
  });
}

export function citationQueryRecordToQueryString(
  query: CitationQueryRecord
): CitationQueryFromQueryParams {
  const params: any = {};

  const startYear = query.getIn(['yearFilter', 'min']);
  const endYear = query.getIn(['yearFilter', 'max']);
  if (startYear && endYear) {
    params.year = [startYear, endYear];
  }

  params.author = query.authors.toArray();
  params.coAuthor = query.coAuthors.toArray();
  params.fos = query.fieldsOfStudy.toArray();
  params.venue = query.venues.toArray();
  params.coAuthor = query.coAuthors.toArray();

  const sort = query.sort;
  if (sort) {
    params.sort = sort;
  }
  const page = query.page;
  if (page > 1) {
    params.page = page;
  }

  // Only append the `pdf` param if it's set to true, as `&pdf=false` in the url might mislead
  // a user (or us!) and cause them (or us!) to think that their search indludes results without
  // a PDF
  if (query.requireViewablePdf === true) {
    params.pdf = true;
  }

  if (query.citationIntent && query.citationIntent !== Citation.INTENTS.ALL_INTENTS.id) {
    params.citationIntent = query.citationIntent;
  }

  if (query.queryString) {
    params.queryString = query.queryString;
  }

  if (query.citationRankingModelVersion) {
    params.citationRankingModelVersion = query.citationRankingModelVersion;
  }

  return params;
}

export function getCitationQueryRecordFromQueryStringParams(
  query: CitationQueryFromQueryParams
): CitationQueryRecord {
  const years = parseYears(query.year);

  return CitationQueryRecordFactory({
    authors: Immutable.Set(query.author),
    coAuthors: Immutable.Set(query.coAuthor),
    venues: Immutable.Set(query.venue),
    yearFilter: YearRangeRecordFactory({
      min: years.startYear,
      max: years.endYear,
    }),
    sort: query.sort || SortType.RELEVANCE.id,
    page: query.page ? (typeof query.page === 'string' ? parseInt(query.page, 10) : query.page) : 1,
    requireViewablePdf: query.pdf === 'true',
    fieldsOfStudy: Immutable.OrderedSet(query.fos),
    citationIntent: query.citationIntent,
    queryString: query.queryString,
  });
}

export function getIndependentCitationQueryStringParams(
  // Takes a Partial type because `delete` can only be called on optional fields.
  query: Partial<CitationQueryFromQueryParams>
): Omit<
  CitationQueryFromQueryParams,
  | 'year'
  | 'author'
  | 'coAuthor'
  | 'venue'
  | 'sort'
  | 'page'
  | 'pdf'
  | 'fos'
  | 'citationIntent'
  | 'queryString'
> {
  const result = { ...query };
  delete result.year;
  delete result.author;
  delete result.coAuthor;
  delete result.venue;
  delete result.sort;
  delete result.page;
  delete result.pdf;
  delete result.fos;
  delete result.citationIntent;
  delete result.queryString;
  return result;
}

export function getCitationQueryRecordFromResultCitationQueryRecord(
  query: CitationQueryRecord
): CitationQueryRecord {
  return CitationQueryRecordFactory({
    authors: Immutable.Set(query.authors),
    coAuthors: Immutable.Set(query.coAuthors),
    venues: Immutable.Set(query.venues),
    yearFilter: YearRangeRecordFactory({
      min: (query.yearFilter && query.yearFilter.min) || null,
      max: (query.yearFilter && query.yearFilter.max) || null,
    }),
    sort: query.sort || '',
    page: query.page ? (typeof query.page === 'string' ? parseInt(query.page, 10) : query.page) : 1,
    requireViewablePdf: query.requireViewablePdf,
    fieldsOfStudy: Immutable.OrderedSet(query.fieldsOfStudy),
    citationIntent: query.citationIntent,
    queryString: query.queryString,
  });
}

export function getCitationQueryRecordFromJS(rawQuery: CitationQueryFromJS): CitationQueryRecord {
  return CitationQueryRecordFactory(
    merge.recursive(true, rawQuery, {
      authors: Immutable.Set(rawQuery.authors),
      coAuthors: Immutable.Set(rawQuery.coAuthors),
      venues: Immutable.Set(rawQuery.venues),
      yearFilter: YearRangeRecordFactory(rawQuery.yearFilter),
      requireViewablePdf: rawQuery.requireViewablePdf,
      fieldsOfStudy: Immutable.OrderedSet(rawQuery.fieldsOfStudy),
      citationIntent: rawQuery.citationIntent,
      queryString: rawQuery.queryString,
    })
  );
}
