import React from 'react';

export default function IconFaCheck(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 8.09 6.22">
      <path
        d="M2.88,6.22c-0.26,0-0.51-0.1-0.71-0.29L0.29,4.05c-0.39-0.39-0.39-1.02,0-1.41s1.02-0.39,1.41,0L2.88,3.8
		l3.51-3.51c0.39-0.39,1.02-0.39,1.41,0s0.39,1.02,0,1.41L3.58,5.93C3.39,6.12,3.13,6.22,2.88,6.22z"
      />
    </symbol>
  );
}
