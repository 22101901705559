import { Nullable } from '@/utils/types';

import Immutable from 'immutable';
import invariant from 'invariant';

export type ViewportRectFromJS = {
  height: number;
  width: number;
  bottom?: Nullable<number>;
  left?: Nullable<number>;
  right?: Nullable<number>;
  top?: Nullable<number>;
  x?: Nullable<number>;
  y?: Nullable<number>;
};

export type Props = {
  height: number;
  width: number;
  bottom: number;
  left: number;
  right: number;
  top: number;
  x: number;
  y: number;
};

const defaultProps: Props = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
};

export const ViewportRectRecordFactory = Immutable.Record<Props>(defaultProps);
export type ViewportRectRecord = Immutable.RecordOf<Props>;

export function getViewportRectFromJS(args: ViewportRectFromJS): ViewportRectRecord {
  let { bottom, height, left, right, top, width, x, y } = args;
  if (typeof left === 'number' && typeof x !== 'number') {
    x = left;
  }
  if (typeof top === 'number' && typeof y !== 'number') {
    y = top;
  }
  if (typeof x === 'number' && typeof left !== 'number') {
    left = x;
  }
  if (typeof y === 'number' && typeof top !== 'number') {
    top = y;
  }
  if (typeof bottom !== 'number') {
    bottom = 0;
  }
  if (typeof height !== 'number') {
    height = 0;
  }
  if (typeof left !== 'number') {
    left = 0;
  }
  if (typeof right !== 'number') {
    right = 0;
  }
  if (typeof top !== 'number') {
    top = 0;
  }
  if (typeof width !== 'number') {
    width = 0;
  }
  if (typeof x !== 'number') {
    x = 0;
  }
  if (typeof y !== 'number') {
    y = 0;
  }
  return ViewportRectRecordFactory({ bottom, height, left, right, top, width, x, y });
}

// WARNING: Calling this function will cause a reflow
export function getViewportRectFromDOM(document: Document): ViewportRectRecord {
  const docEl = document.documentElement;
  const body = document.body;
  invariant(docEl, 'cannot build a ViewportRect without a documentElement');
  invariant(body, 'cannot build a ViewportRect without a body');
  const top = docEl.scrollTop || body.scrollTop || 0;
  const left = docEl.scrollLeft || body.scrollLeft || 0;
  const height = docEl.clientHeight || 0;
  const width = docEl.clientWidth || 0;
  const bottom = (docEl.scrollHeight || 0) - top - height;
  const right = (docEl.scrollWidth || 0) - left - width;
  return getViewportRectFromJS({ bottom, height, left, right, top, width });
}
