import { ReactNodeish } from '@/utils/types';

import classnames from 'classnames';
import React, { ComponentProps } from 'react';

type CLPageAlertBaseProps = ComponentProps<'div'>;

export default function CLPageAlertBase({
  children,
  className,
  ...otherProps
}: CLPageAlertBaseProps): ReactNodeish {
  return (
    <div
      {...otherProps}
      className={classnames(
        {
          'cl-page-alert': true,
        },
        className
      )}>
      {children}
    </div>
  );
}
