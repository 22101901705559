import Immutable from 'immutable';

export type FacetValueFromJS = {
  unfilteredDocumentCount: number;
  documentCount: number;
  value: string;
  displayValue: string;
};

type Props = {
  unfilteredDocumentCount: number;
  documentCount: number;
  value: string;
  displayValue: string;
};

const defaultProps: Props = {
  unfilteredDocumentCount: 0,
  documentCount: 0,
  value: '',
  displayValue: '',
};

export const FacetValueRecordFactory = Immutable.Record<Props>(defaultProps);

export type FacetValueRecord = Immutable.RecordOf<Props>;

export function getFacetValueFromJS(args: FacetValueFromJS): FacetValueRecord {
  return FacetValueRecordFactory({
    ...args,
  });
}

/**
 * Returns the total document count for a collection of FacetValues.
 * If we ever deprecate the old facet structure, this won't be necessary.
 * @param {Immutable.List<import('./FacetValue').FacetValueRecord>} facetValues
 * @returns {number}
 */
export function getTotalDocumentCountFromFacetValues(
  facetValues: Immutable.OrderedSet<FacetValueRecord>
): number {
  return facetValues.reduce((sum, facetValue) => sum + facetValue.documentCount, 0);
}
