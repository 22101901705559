import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

type Props = {
  min: Nullable<number>;
  max: Nullable<number>;
};

const defaultProperties: Props = {
  min: null,
  max: null,
};

export const YearRangeRecordFactory = Immutable.Record<Props>(defaultProperties);
export type YearRangeRecord = Immutable.RecordOf<Props>;

export function getYearRangeFromJS({ min, max }: { min: number; max: number }): YearRangeRecord {
  return YearRangeRecordFactory({
    min: min,
    max: max,
  });
}
