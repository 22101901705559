import type { Nullable } from '@/utils/types';

export type UserOccupationKey = keyof typeof byKey;
export type UserOccupationValue = (typeof byKey)[UserOccupationKey];

const byKey = {
  UndergraduateStudent: 'Undergraduate Student',
  GraduateStudent: 'Graduate Student',
  PostDoctoral: 'Post-Doctoral',
  ResearchScientist: 'Research Scientist',
  Professor: 'Professor',
  Retired: 'Retired',
  Other: 'Other',
} as const;

// TODO: TS isn't quite sure what to make of this type
const byValue = Object.keys(byKey).reduce((memo, key) => {
  const value = byKey[key];
  memo[value] = key;
  return memo;
}, {});

export default Object.freeze(byKey);

// TODO: TS isn't quite sure what to make of this type
export const UserOccupationByValue = Object.freeze(byValue);

export function getUserOccupationValue(value: string): UserOccupationValue {
  if (value in byValue) {
    return value as UserOccupationValue;
  }
  return byKey.Other;
}

export function optUserOccupationValue(value?: string): Nullable<UserOccupationValue> {
  if (!value) {
    return null;
  }
  return getUserOccupationValue(value);
}
