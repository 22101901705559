import BaseStore from './BaseStore';

import {
  ClearUserSettingsAction,
  CreateUserSettingAction,
  RemoveUserSettingAction,
} from '@/actions/UserSettingActionCreators';
import {
  getUserSettingFromJS,
  PersistentUserSettingKey,
  UserSettingFromJS,
  UserSettingKey,
  UserSettingRecord,
} from '@/models/user/UserSetting';
import Constants from '@/constants';
import softError from '@/utils/softError';

import Immutable from 'immutable';
import store from 'store2';

/**
 * A store responsible for user settings. Currently supports storage of user settings
 * which persist via the localStorage API and the UserSettings table
 * Only logged in users will have settings persisted in the db, these values also get stored in localStorage to enable syncing settings across tabs
 *
 * There are 2 categories of UserSettings:
 * 1. Settings that apply to all users regardless of logged in status (ex. readerZoom, serpDensity)
 * 2. Settings that apply to logged in users only (ex. TBD)
 *
 * Usage:
 * - Use the action creators to trigger CRUD operations in localStorage only
 *    ex. When creating a setting that is for all users regardless of logged in status, dispatch a SET_USER_SETTING action to save it in localStorage only.
 * - Use the user setting api endpoints to trigger CRUD operations that sync across the DB and localStorage. Models for the setting will need to be built scala side.
 *    ex. When creating a setting for logged in users only, use api.createUserSetting() to save it to the UserSettings table in the db, it will also sync the setting to localStorage.
 *
 */

export default class UserSettingStore extends BaseStore {
  userSettings: Immutable.Map<UserSettingKey, UserSettingRecord>; // only holds user settings from the DB

  constructor(dispatcher) {
    super();
    this.userSettings = Immutable.Map();

    dispatcher.register(payload => {
      switch (payload.actionType) {
        // for user settings only stored in localStorage & not synced to DB
        case Constants.actions.SET_USER_SETTING: {
          const typedPayload = payload as CreateUserSettingAction;
          try {
            this.#setLocalUserSetting(typedPayload.key, typedPayload.value);
          } catch (e) {
            softError(
              'create-user-setting',
              `Error saving user setting: ${typedPayload.key} -> ${typedPayload.value}`
            );
          }
          break;
        }
        case Constants.actions.REMOVE_USER_SETTING: {
          const typedPayload = payload as RemoveUserSettingAction;
          try {
            this.#removeLocalUserSetting(typedPayload.key);
          } catch (e) {
            softError('remove-user-setting', `Error removing user setting: ${typedPayload.key}`);
          }
          break;
        }
        case Constants.actions.CLEAR_ALL_USER_SETTINGS: {
          const typedPayload = payload as ClearUserSettingsAction;
          try {
            this.#clearLocalStorage();
          } catch (e) {
            softError('clear-user-settings', `Error clearing localStorage: ${typedPayload.key}`);
          }
          break;
        }
        // for when user settings in DB & localStorage
        case Constants.actions.API_REQUEST_COMPLETE: {
          switch (payload.requestType) {
            case Constants.requestTypes.USER_INFO:
            case Constants.requestTypes.GET_ALL_USER_SETTINGS: {
              const rawSettings = payload.resultData.settings;
              this.userSettings = rawSettings.reduce(
                this.#addSetting.bind(this),
                this.userSettings
              );
              this.emitChange();
              break;
            }
            case Constants.requestTypes.GET_USER_SETTING_BY_KEY:
            case Constants.requestTypes.CREATE_USER_SETTING:
            case Constants.requestTypes.UPDATE_USER_SETTING: {
              const rawSetting = payload.resultData.setting;
              if (!rawSetting) {
                return;
              }
              this.userSettings = this.#addSetting(this.userSettings, rawSetting);
              this.emitChange();
              break;
            }
            case Constants.requestTypes.DELETE_ALL_USER_SETTINGS: {
              this.#clearLocalStorage();
              this.userSettings = Immutable.Map();
              this.emitChange();
              break;
            }
            case Constants.requestTypes.DELETE_USER_SETTING_BY_KEY: {
              const settingsKey = payload.resultData.settingKey;
              this.userSettings = this.userSettings.delete(settingsKey);
              // remove from localStorage
              this.#removeLocalUserSetting(settingsKey);
              this.emitChange();
              break;
            }
          }
        }
      }
    });
  }

  /**
   * Returns the value of the associated setting. It checks if the setting exists in localStorage and in the db and returns the most recent setting
   * Case 1: user is logged out, the setting will only exist in localStorage
   * Case 2: user is logged in, the setting may exist in both localStorage and db. Return whichever is more recent
   * Case 3: setting exists in db but not localStorage. This scenario can happen if a user clears their local storage, uses a new browser, or
   *    a setting is created in the db by something non-client side (ex. script, worker). As a result, the setting would only exist in the db.
   *    The setting would only be synced to localStorage on fresh pull of settings or if the setting is updated client side via api.updateUserSetting()
   *
   * @param {string} key the setting key
   *
   * @return {mixed} the value associated with the given setting.
   *
   */
  getUserSetting(key: UserSettingKey) {
    // check to see if the setting is stored in DB
    const maybePersistentSetting = this.userSettings.get(key);
    // check if setting is in localStorage
    const raw = typeof localStorage !== undefined ? store.get(key) : null;
    let localStorageSettingJson: UserSettingFromJS;
    try {
      localStorageSettingJson = raw ? JSON.parse(raw) : raw;
    } catch (e) {
      softError('parse-user-setting', `Error parsing user setting ${key}: ${e}`);
      return raw;
    }

    // if the setting from local storage has a valid settings key, create a typed record of the setting
    const parsedLocalStorageSetting =
      PersistentUserSettingKey[key] && !!localStorageSettingJson
        ? getUserSettingFromJS(localStorageSettingJson)
        : localStorageSettingJson;

    // if setting is only in localStorage
    if (parsedLocalStorageSetting && !maybePersistentSetting) {
      return parsedLocalStorageSetting;
    }
    // if setting is only in DB
    if (!parsedLocalStorageSetting && maybePersistentSetting) {
      return maybePersistentSetting;
    }

    // return whichever one has a more recent timestamp if both exist or
    // if localSetting shares same key as DB setting but the value structure is invalid, return the persistent setting version
    // since its structure will always be typed and valid
    const isKeyMatch = parsedLocalStorageSetting?.settingsKey === UserSettingKey.unsupportedKey;
    const isValueMoreRecent =
      maybePersistentSetting &&
      maybePersistentSetting?.updatedAtUtc > parsedLocalStorageSetting?.updatedAtUtc;
    return isKeyMatch || isValueMoreRecent ? maybePersistentSetting : parsedLocalStorageSetting;
  }

  /**
   * Set the specified setting in localStorage only
   *
   * @param {string} key
   * @param {mixed} value
   *
   * @returns {UserSettingStore}
   */
  #setLocalUserSetting(key: string, value: any): UserSettingStore {
    if (typeof localStorage !== undefined) {
      // no-op if setting the same value
      const maybeExistingValue = store.get(key);

      if (
        (typeof value === 'string' && value === maybeExistingValue) ||
        maybeExistingValue?.toString() === JSON.stringify(value)
      ) {
        return this;
      }

      store.set(key, value);
      this.emitChange();
    }
    return this;
  }

  /**
   * Removes the specified setting from localStorage only
   *
   * @param {string} key
   *
   * @returns {UserSettingStore}
   */
  #removeLocalUserSetting(key: string): UserSettingStore {
    if (typeof localStorage !== undefined && !!store.get(key)) {
      store.remove(key);
      this.emitChange();
    }
    return this;
  }

  // helper functions
  #addSetting(
    userSettings: Immutable.Map<UserSettingKey, UserSettingRecord>,
    settingJson: UserSettingFromJS
  ): Immutable.Map<UserSettingKey, UserSettingRecord> {
    const setting = getUserSettingFromJS(settingJson);
    this.#setLocalUserSetting(setting.settingsKey, JSON.stringify(settingJson));
    return userSettings.set(setting.settingsKey, setting);
  }

  #clearLocalStorage(): void {
    if (localStorage !== undefined) {
      store.clear();
      this.emitChange();
    }
  }
}
