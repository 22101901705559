/**
 * Base representation of a redirect.
 */
export default class Redirect {
  url?: string;
  status: number;

  constructor({ url, status }: { url?: string; status?: number }) {
    this.url = url;
    this.status = status || 301;
  }
}
