import CLAlertBase, { LEVEL as _LEVEL, SEVERITY as _SEVERITY } from './CLAlertBase';

import Icon from '@/components/shared/icon/Icon';

import React from 'react';

export const LEVEL = _LEVEL;
export const SEVERITY = _SEVERITY;

/**
 *
 * @param {{ icon?: React.ReactNode, children?: React.ReactNode, level?: string | null, severity?: string | null, className?: string }} props
 * @returns
 */
export default function CLAlert({ icon, ...otherProps }) {
  return <CLAlertBase icon={pickIcon(icon)} {...otherProps} />;
}

CLAlert.defaultProps = {
  icon: true,
};

export function pickIcon(icon) {
  if (typeof icon !== 'boolean') {
    return icon || null;
  }
  if (icon === true) {
    return <Icon icon="status-warning" />;
  }
  return null;
}

export const exampleConfig = {
  getComponent: async () => CLAlert,
  fields: [
    {
      name: 'children',
      desc: 'Content to be displayed within the component',
      value: {
        type: 'jsx',
        default: '',
        // eslint-disable-next-line react/no-danger
        transform: html => <div dangerouslySetInnerHTML={{ __html: html }} />,
      },
    },
    {
      name: 'className',
      desc: 'HTML classes to be added to the component',
      value: {
        type: 'text',
        default: '',
      },
    },
    {
      name: 'icon',
      desc: 'Key to an icon in the icon sprite',
      value: {
        type: 'icon',
        default: null,
        transform: iconKey => (iconKey ? <Icon icon={iconKey} /> : null),
      },
    },
    {
      name: 'level',
      desc: 'Where on the page is the alert found',
      value: {
        type: 'select',
        default: LEVEL.PAGE,
        options: [LEVEL.PAGE, LEVEL.FORM],
      },
    },
    {
      name: 'severity',
      desc: 'How important the alert is',
      value: {
        type: 'select',
        default: SEVERITY.ERROR,
        options: [SEVERITY.SUCCESS, SEVERITY.INFO, SEVERITY.WARNING, SEVERITY.ERROR],
      },
    },
  ],

  examples: [
    {
      title: 'Page level error',
      desc: 'Same as the legacy <CLPageAlertDanger/>',
      props: {
        children: `Suspendisse lacinia erat vitae tempor vehicula.`,
        icon: 'status-warning',
        level: LEVEL.PAGE,
        severity: SEVERITY.ERROR,
      },
      render: comp => <div style={{ width: '300px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'Page level success',
      desc: 'Success message',
      props: {
        children: `Suspendisse lacinia erat vitae tempor vehicula.`,
        icon: 'checkmark',
        level: LEVEL.PAGE,
        severity: SEVERITY.SUCCESS,
      },
      render: comp => <div style={{ width: '300px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'Form level error',
      desc: 'Error when submitting a form fails',
      props: {
        children: `Suspendisse lacinia erat vitae tempor vehicula.`,
        icon: 'status-warning',
        level: LEVEL.FORM,
        severity: SEVERITY.ERROR,
      },
      render: comp => <div style={{ width: '300px', padding: '16px' }}>{comp}</div>,
    },
  ],

  events: {},
};
