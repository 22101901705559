import classnames from 'classnames';
import React from 'react';

import type { Nullable, ReactNodeish } from '@/utils/types';

type Props = React.PropsWithChildren<{
  className?: string;
  title?: Nullable<string | ReactNodeish>;
}>;

const PageSection = ({ children, className, title }: Props): ReactNodeish => (
  <section className={classnames('page-section', className)}>
    {title ? <h4 className="page-section__title text--lg">{title}</h4> : null}
    {children}
  </section>
);

export default PageSection;
