import React from 'react';

export default function IconVenue(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 32 32">
      <path d="M16,21.2c-0.1,0-0.2,0-0.3-0.1L0.9,13.5c-0.2-0.1-0.4-0.4-0.4-0.6c0-0.3,0.1-0.5,0.4-0.6l14.8-8.1c0.2-0.1,0.5-0.1,0.7,0 l14.8,8.1c0.2,0.1,0.4,0.4,0.4,0.6c0,0.3-0.2,0.5-0.4,0.6L16.4,21C16.2,21.1,16.1,21.2,16,21.2z M2.8,12.9L16,19.6l13.2-6.7L16,5.6 L2.8,12.9z" />
      <path d="M16,27.9c-0.1,0-0.2,0-0.3-0.1L0.9,20.3c-0.4-0.2-0.5-0.6-0.3-1s0.6-0.5,1-0.3L16,26.4L30.5,19c0.4-0.2,0.8,0,1,0.3 c0.2,0.4,0,0.8-0.3,1l-14.8,7.5C16.2,27.9,16.1,27.9,16,27.9z" />
    </symbol>
  );
}
