import { mkHeapAttributes } from '@/analytics/heapUtils';

export const heapScorecard = (props: { key: string }) =>
  mkHeapAttributes({
    id: 'pdp_citing_scorecard_item',
    ...props,
  });

export const heapAbstractToggle = () =>
  mkHeapAttributes({
    id: 'text_truncator_toggle',
  });

export const heapFigurePaperTitle = () =>
  mkHeapAttributes({
    id: 'figure_paper_title',
  });

export const heapShareLink = () =>
  mkHeapAttributes({
    id: 'share_link',
  });
