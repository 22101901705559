import langEnUs from './i18n/en-us/index';

import { hasValue, Nullable } from '@/utils/types';
import { isUnitTest } from '@/utils/env';
import { pluralize } from '@/util';
import logger from '@/logger';

import React from 'react';

import type { LanguageMap } from './i18n/LanguageMap';

export type Getter<T> = (langMap: LanguageMap) => T;

interface ToStringable {
  toString(): string;
}

type Replacement = string | ToStringable;

function execStringGetter<T>(stringGetter: Getter<T>): T {
  const result = stringGetter(langEnUs);
  if (!hasValue(result) && !isUnitTest()) {
    logStringGetterError(stringGetter);
  }
  return result;
}

async function logStringGetterError<T>(
  stringGetter: Getter<T>,
  error?: Nullable<Error>
): Promise<void> {
  try {
    const src = stringGetter.toString();
    const softErrorArgs = {
      message: error?.message,
      stack: error?.stack,
    };
    const softErrorModule = await import('../utils/softError'); // Load async to prevent compiler errors
    softErrorModule.softErrorOnceInBrowser(
      'getString',
      `failed to find string for "${src}"`,
      softErrorArgs
    );
  } catch (error) {
    logger.error('failed to log error', error);
  }
}

export function getString(stringGetter: Getter<string>, ...replacements: Replacement[]): string {
  try {
    return replaceTemplateVariables(execStringGetter(stringGetter), replacements);
  } catch (error) {
    logStringGetterError(stringGetter, error);
    return '';
  }
}

export function getStringArray(
  stringGetter: Getter<string[]>,
  ...replacements: Replacement[]
): string[] {
  try {
    return execStringGetter(stringGetter).map(template =>
      replaceTemplateVariables(template, replacements)
    );
  } catch (error) {
    logStringGetterError(stringGetter, error);
    return [];
  }
}

export function getPluralizedString(
  stringGetter: Getter<{ singular: string; plural: string }>,
  number: number,
  ...replacements: Replacement[]
): string {
  try {
    const { singular, plural } = execStringGetter(stringGetter);
    return replaceTemplateVariables(pluralize(number, singular, plural, true /* wordOnly */), [
      number.toLocaleString(),
      ...replacements,
    ]);
  } catch (error) {
    logStringGetterError(stringGetter, error);
    return '';
  }
}

export function getReactNode(
  stringGetter: Getter<string>,
  ...replacements: Replacement[]
): React.ReactNode {
  try {
    const template = execStringGetter(stringGetter);
    const children = template.split(/(\$\d+)\b/).map(part => {
      if (/^\$\d+$/.test(part)) {
        const replacementIndex = parseInt(part.substr(1), 10) - 1; // $1 = index 0
        return replacements[replacementIndex];
      }
      return part;
    });
    return <React.Fragment>{children}</React.Fragment>;
  } catch (error) {
    logStringGetterError(stringGetter, error);
    return <React.Fragment />;
  }
}

export function replaceTemplateVariables(template: string, replacements: Replacement[]): string {
  return replacements.reduce<string>(
    (str, replacement, index) => str.split(`$${index + 1}`).join(replacement?.toString()),
    template
  );
}
