import {
  CitationContextFromJS,
  CitationContextRecord,
  getCitationContextFromJS,
} from './CitationContext';
import {
  CueDataWrapperFromJS,
  CueDataWrapperRecord,
  getCueDataWrapperMapFromJS,
} from './CueDataWrapper';
import {
  getHighlightedFieldFromJS,
  HighlightedFieldFromJS,
  HighlightedFieldRecord,
  HighlightedFieldRecordFactory,
} from './HighlightedField';
import { getTldrFromJS, TldrFromJS, TldrRecord } from './Tldr';
import {
  HighlightedAuthorFromJS,
  HighlightedAuthorRecord,
  parseHighlightedAuthors,
} from './HighlightedAuthor';
import { PaperBadgeProps, PaperBadgeRecord, PaperBadgeRecordFactory } from './PaperBadge';

import { Nullable } from '@/utils/types';

import Immutable from 'immutable';
import merge from 'merge';

import type { CueType } from '@/constants/CueType';

// NOTE: This should match service/model/Citation.scala

export type CitationFromJS = {
  id?: Nullable<string>; // can be null in the case where the cited paper isn't in the corpus (has no PDP)
  title: HighlightedFieldFromJS;
  slug: string;
  authors: HighlightedAuthorFromJS[];
  venue: HighlightedFieldFromJS;
  venueId: Nullable<string>;
  year?: Nullable<number>;
  citationContexts: CitationContextFromJS[];
  fieldsOfStudy: string[];
  // Citation counts (inbound and outbound) for the paper this citation references.
  numCitedBy: number;
  numCiting: number;
  isKey: boolean;
  badges: PaperBadgeProps[];
  debugInfo?: Nullable<any>;
  tldr: Nullable<TldrFromJS>;
  isPdfVisible: boolean;
  cues: Record<string, CueDataWrapperFromJS[]>;
};

type Props = {
  id: Nullable<string>;
  title: HighlightedFieldRecord;
  slug: string;
  authors: Immutable.List<HighlightedAuthorRecord>;
  venue: HighlightedFieldRecord;
  venueId: Nullable<string>;
  year: Nullable<number>;
  citationContexts: Immutable.List<CitationContextRecord>;
  fieldsOfStudy: Immutable.List<string>;
  numCitedBy: number;
  numCiting: number;
  isKey: boolean;
  badges: Immutable.List<PaperBadgeRecord>;
  debugInfo: Nullable<any>;
  paperAbstract: HighlightedFieldRecord;
  tldr: Nullable<TldrRecord>;
  isReaderAvailable: boolean;
  cues: Immutable.Map<CueType, Immutable.List<CueDataWrapperRecord>>;
};

const defaultProperties: Props = {
  id: null,
  title: HighlightedFieldRecordFactory(),
  slug: '',
  authors: Immutable.List(),
  venue: HighlightedFieldRecordFactory(),
  venueId: null,
  year: null,
  citationContexts: Immutable.List(),
  fieldsOfStudy: Immutable.List(),
  numCitedBy: 0,
  numCiting: 0,
  isKey: false,
  badges: Immutable.List(),
  debugInfo: null,
  paperAbstract: HighlightedFieldRecordFactory(),
  tldr: null,
  isReaderAvailable: false,
  cues: Immutable.Map(),
};

export const CitationRecordFactory = Immutable.Record<Props>(defaultProperties);
export type CitationRecord = Immutable.RecordOf<Props>;

export function getCitationFromJS(args: CitationFromJS): CitationRecord {
  return citationFromJs(args);
}
export function citationFromJs(args: CitationFromJS): CitationRecord {
  // Make a copy of the object since we're mutating it
  const citation = merge.recursive(true, args);
  citation.title = getHighlightedFieldFromJS(citation.title);
  citation.authors = parseHighlightedAuthors(citation.authors);
  citation.venue = getHighlightedFieldFromJS(citation.venue);
  citation.citationContexts = Immutable.List(args.citationContexts.map(getCitationContextFromJS));
  citation.fieldsOfStudy = Immutable.List(args.fieldsOfStudy);
  citation.badges = Immutable.List(args.badges ? args.badges.map(PaperBadgeRecordFactory) : []);
  citation.tldr = args.tldr ? getTldrFromJS(args.tldr) : null;
  citation.isReaderAvailable = args.isPdfVisible;
  citation.cues = args.cues ? getCueDataWrapperMapFromJS(args.cues) : Immutable.Map();
  return CitationRecordFactory(citation);
}
