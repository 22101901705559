import AnalyticsEvent from './AnalyticsEvent';

import EventType from '@/analytics/constants/EventType';

import merge from 'merge';

/** Event tracking when a piece of data or form is submitted. */
export default class SubmitEvent extends AnalyticsEvent {
  /**
   * @param {string} target - an identifier for what is being submitted
   * @param {object} [data] - optional key value pairs describing the event.
   */
  constructor(target: string, data?: any) {
    super(EventType.SUBMIT, merge.recursive({ target }, data));
  }

  static create(target: string, data?: any): SubmitEvent {
    return new SubmitEvent(target, data);
  }
}
