import Immutable from 'immutable';

export type NoteTakingStateFromJS = {
  isEnabled: boolean;
  showNoteTakingHighlights: boolean;
};

export type NoteTakingState = {
  isEnabled: boolean;
  showNoteTakingHighlights: boolean;
};

export const NoteTakingStateDefaultProps: NoteTakingState = {
  isEnabled: true,
  showNoteTakingHighlights: true,
};

export const NOTE_TAKING_RECORD_NAME = 'NoteTakingState';
export const NoteTakingStateRecordFactory = Immutable.Record<NoteTakingState>(
  NoteTakingStateDefaultProps,
  NOTE_TAKING_RECORD_NAME
);
export type NoteTakingStateRecord = Immutable.RecordOf<NoteTakingState>;

export function getNoteTakingStateFromJS(args: NoteTakingStateFromJS): NoteTakingStateRecord {
  return NoteTakingStateRecordFactory({
    ...args,
  });
}
