import { ReactNodeish } from '@/utils/types';
import CLAlert, { LEVEL, SEVERITY } from '@/components/library/alert/CLAlert';
import Icon from '@/components/shared/icon/Icon';

import React from 'react';

type Props = {
  message: string;
};

export default class FormLevelMessage extends React.Component<Props> {
  render(): ReactNodeish {
    const { message } = this.props;
    if (!message) {
      return null;
    }

    return (
      <div className="form-level-message" data-test-id="form-level-message">
        <CLAlert
          icon={<Icon icon="status-warning" width="15" height="15" />}
          level={LEVEL.FORM}
          severity={SEVERITY.ERROR}>
          {message}
        </CLAlert>
      </div>
    );
  }
}
