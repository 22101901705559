import { getFacetValueFromJS } from './FacetValue';
import { getMatchedAuthorFromJS, MatchedAuthorFromJS, MatchedAuthorRecord } from './MatchedAuthor';
import {
  getMatchedEntityFromJS,
  MatchedEntityFromJS,
  MatchedEntityRecord,
} from './entity/MatchedEntity';
import { getPaperFromJS, PaperFromJS, PaperRecord } from './Paper';
import { getQueryRecordFromJS, QueryFromJS, QueryRecord, QueryRecordFactory } from './Query';
import { getYearBucketFromFacet } from './YearBucket';
import { PaperSearchAggregationsFromJS } from './QueryUtils';

import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

type QueryResponseFromJS = {
  matchedAuthors: MatchedAuthorFromJS[];
  matchedEntity?: Nullable<MatchedEntityFromJS>;
  modifiedQueryString: string;
  query: QueryFromJS;
  querySuggestions: QuerySuggestion[];
  results: PaperFromJS[];
  stats: PaperSearchAggregationsFromJS;
  totalPages: number;
  totalResults: number;
};

export type QuerySuggestion = {
  text: string;
  score: number;
};

type Props = {
  query: QueryRecord;
  stats: Immutable.Map<string, any>;
  results: Immutable.List<PaperRecord>;
  querySuggestions: Immutable.List<QuerySuggestion>;
  totalPages: number;
  totalResults: number;
  matchedAuthors: Immutable.List<MatchedAuthorRecord>;
  matchedEntity: Nullable<MatchedEntityRecord>;
  modifiedQueryString: string;
};

const defaultProperties: Props = {
  query: QueryRecordFactory(),
  stats: Immutable.Map({
    authors: Immutable.List(),
    coAuthors: Immutable.List(),
    filteredYears: Immutable.List(),
    venues: Immutable.List(),
    years: Immutable.List(),
    totalCountAfterFilters: 0,
  }),
  results: Immutable.List(),
  querySuggestions: Immutable.List(),
  totalPages: 0,
  totalResults: 0,
  matchedAuthors: Immutable.List(),
  matchedEntity: null,
  modifiedQueryString: '',
};

export const QueryResponseRecordFactory = Immutable.Record<Props>(defaultProperties);
export type QueryResponseRecord = Immutable.RecordOf<Props>;

/**
 * Determines whether facets exist in case of no results.
 *
 * @param {QueryResponseRecord} qr Takes QueryResponseRecord
 *
 * @returns {boolean} Returns true if any facet has a document count greater than 0.
 */
export function queryResponseHasFacets(qr: QueryResponseRecord): boolean {
  // The facets collection is searched seperately, since it's structurally different
  const filteredStats = Immutable.List(['authors', 'coAuthors', 'venues']).map(
    key => qr.stats.get(key) || Immutable.List()
  );
  const hasStats = filteredStats.find(facet => facet.find(v => v.unfilteredDocumentCount > 0))
    ? true
    : false;

  return hasStats || qr.query.requireViewablePdf === true;
}

export function getQueryResponseFromJS(args: QueryResponseFromJS): QueryResponseRecord {
  const response = { ...args };
  const results = Immutable.List(response.results.map(paper => getPaperFromJS(paper)));
  const query = getQueryRecordFromJS(response.query);

  // Model the "stats" member. The scala equilvalent is `PaperSearchAggregations`.
  const stats = Object.keys(response.stats).reduce((map, facetKey) => {
    switch (facetKey) {
      case 'years':
      case 'filteredYears':
        return map.set(
          facetKey,
          Immutable.List(response.stats[facetKey]?.map(year => getYearBucketFromFacet(year)))
        );
      // This really weird, but required to stick with our existing paradigm
      case 'countOfPapersWithViewablePdf':
        return map.set('countOfPapersWithViewablePdf', response.stats.countOfPapersWithViewablePdf);
      case 'totalNumCitedBy':
        return map.set('totalNumCitedBy', response.stats.totalNumCitedBy);
      case 'totalNumKeyCitations':
        return map.set('totalNumKeyCitations', response.stats.totalNumKeyCitations);
      case 'totalCountAfterFilters':
        return map.set('totalCountAfterFilters', response.stats.totalCountAfterFilters);
      default:
        return map.set(
          facetKey,
          Immutable.OrderedSet(response.stats[facetKey].map(val => getFacetValueFromJS(val)))
        );
    }
  }, Immutable.Map<string, any>());

  // TODO (codeviking): "stats" is a terrible name, and should be renamed, it's also odd that
  // we parse this into a structure which doesn't mirror that into the server, but makes sense
  // to maintain for now as we integrate this more completely
  return QueryResponseRecordFactory({
    query,
    results,
    stats: stats,
    querySuggestions: Immutable.List(response.querySuggestions),
    totalPages: response.totalPages,
    totalResults: response.totalResults,
    matchedAuthors: Immutable.List(response.matchedAuthors.map(a => getMatchedAuthorFromJS(a))),
    matchedEntity: response.matchedEntity ? getMatchedEntityFromJS(response.matchedEntity) : null,
    modifiedQueryString: response.modifiedQueryString,
  });
}
