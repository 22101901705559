import { addHeapProps } from '@/components/util/HeapTracking';

import classnames from 'classnames';
import React, { ComponentProps, ReactNode } from 'react';

import type { HeapProps } from '@/analytics/heapUtils';
import type { Nullable, ReactNodeish, ValueOf } from '@/utils/types';

const CIRCLE = 'circle';
const DEFAULT = 'default';
const LARGE = 'large';
const LEFT = 'left';
const UP = 'up';
const MEDIUM = 'medium';
const PRIMARY = 'primary';
const RECTANGLE = 'rectangle';
const RIGHT = 'right';
const SECONDARY = 'secondary';
const SMALL = 'small';
const TERTIARY = 'tertiary';
const COMPACT = 'compact';
const COMFORTABLE = 'comfortable';
const SUBMIT = 'submit';
const BUTTON = 'button';
const RESET = 'reset';

export const DENSITY = Object.freeze({
  DEFAULT,
  COMPACT,
  COMFORTABLE,
});

export const FONT_SIZE = Object.freeze({
  DEFAULT,
  MEDIUM,
});

export const SHAPE = Object.freeze({
  RECTANGLE,
  CIRCLE,
});

export const SIZE = Object.freeze({
  DEFAULT,
  LARGE,
  SMALL,
});

export const TYPE = Object.freeze({
  DEFAULT,
  PRIMARY,
  SECONDARY,
  TERTIARY,
});

export const ICON_POSITION = Object.freeze({
  LEFT,
  RIGHT,
  UP,
});

export const BEHAVIOR = Object.freeze({
  SUBMIT,
  BUTTON,
  RESET,
});

export type ButtonDensity = ValueOf<typeof DENSITY>;
export type ButtonFontSize = ValueOf<typeof FONT_SIZE>;
export type ButtonShape = ValueOf<typeof SHAPE>;
export type ButtonSize = ValueOf<typeof SIZE>;
export type ButtonType = ValueOf<typeof TYPE>;
export type IconPosition = ValueOf<typeof ICON_POSITION>;

// HACK: This allows us to override the attributes that are normally on a element
type NonOverriddenButtonAttributeTypes = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'className' | 'type' | 'onClick'
>;

export type CLButtonBaseProps = React.PropsWithChildren<{
  ariaProps?: Nullable<object>;
  arrow?: Nullable<ReactNodeish>;
  behavior?: Nullable<ComponentProps<'button'>['type']>;
  className?: Nullable<string>;
  density?: Nullable<ButtonDensity>;
  fontSize?: Nullable<ButtonFontSize>;
  hasArrowDivider?: Nullable<boolean>;
  heapProps?: Nullable<HeapProps>;
  icon?: Nullable<ReactNodeish>;
  iconPosition?: Nullable<IconPosition>;
  label?: Nullable<ReactNode>;
  onClick?: Nullable<React.MouseEventHandler<HTMLButtonElement>>;
  shape?: Nullable<ButtonShape>;
  size?: Nullable<ButtonSize>;
  testId?: Nullable<string>;
  type?: Nullable<ButtonType>;
}> &
  NonOverriddenButtonAttributeTypes;

export default function CLButtonBase({
  ariaProps,
  arrow,
  behavior,
  className,
  density,
  hasArrowDivider,
  heapProps,
  fontSize,
  icon,
  iconPosition,
  label,
  testId,
  shape,
  size,
  type,
  onClick,
  ...extraProps
}: CLButtonBaseProps): ReactNodeish {
  const isIconOnly = !!icon && !label && !arrow; // Helpful for describing state with only one class

  return (
    <button
      {...extraProps}
      {...(heapProps ? addHeapProps(heapProps) : {})}
      {...(testId ? { 'data-test-id': testId } : {})}
      {...(ariaProps ? ariaProps : {})}
      onClick={onClick ?? undefined}
      type={behavior ?? undefined}
      className={classnames(
        {
          'cl-button': true,
          'cl-button--has-arrow-divider': hasArrowDivider,
          'cl-button--no-arrow-divider': !hasArrowDivider,
          'cl-button--is-icon-only': isIconOnly,
          'cl-button--not-icon-only': !isIconOnly,
          'cl-button--has-icon': icon,
          'cl-button--no-icon': !icon,
          'cl-button--has-label': label,
          'cl-button--no-label': !label,
          [`cl-button--font-size-${fontSize || ''}`]: true,
          [`cl-button--icon-pos-${iconPosition || ''}`]: true,
          [`cl-button--shape-${shape || ''}`]: true,
          [`cl-button--size-${size || ''}`]: true,
          [`cl-button--type-${type || ''}`]: true,
          [`cl-button--density-${density || ''}`]: true,
        },
        className
      )}>
      {icon && iconPosition === UP && <span className="cl-button__icon">{icon}</span>}
      {icon && iconPosition === LEFT && <span className="cl-button__icon">{icon}</span>}
      {label && <span className="cl-button__label">{label}</span>}
      {icon && iconPosition === RIGHT && <span className="cl-button__icon">{icon}</span>}
      {arrow && <span className="cl-button__arrow">{arrow}</span>}
    </button>
  );
}

CLButtonBase.defaultProps = {
  iconPosition: LEFT,
  behavior: SUBMIT, // Browser default is submit, see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-type
  density: DEFAULT,
  shape: RECTANGLE,
  size: DEFAULT,
  type: DEFAULT,
};
