import ApiRequest from './ApiRequest';
import BaseApi from './BaseApi';

import { COGNITO, GOOGLE_ONE_TAP, LoginMethodId } from '@/constants/LoginMethods';
import { getLayoverLogger } from '@/utils/layover/LayoverLogger';
import AccountCreatedEvent from '@/analytics/models/AccountCreatedEvent';
import Constants, { RequestTypeValue } from '@/constants';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

const COGNITO_SIGNOUT_FRAME = 'cognito-signout-iframe';
const COGNITO_SIGNOUT_SRC = '/api/1/auth/cognito/oauth/signout';

export default class AuthApi extends BaseApi {
  async loginViaPassword(method: LoginMethodId, email: string, password: string): Promise<void> {
    const loginPromise = (() => {
      switch (method) {
        case COGNITO:
          return this.loginViaCognitoAPI(email, password, method);
        default:
          throw new LoginFailedError();
      }
    })();

    try {
      await loginPromise;
    } catch (error) {
      this.logError(error, method);
      throw error;
    }
    getLayoverLogger().log('login.succeeded', { method });
  }

  async loginViaCognitoAPI(email: string, password: string, method: LoginMethodId): Promise<void> {
    await this.loginViaAPIImpl(
      Constants.requestTypes.AUTH_API__COGNITO_LOGIN,
      '/auth/cognito/login',
      method,
      {
        email,
        password,
      }
    );
  }

  async loginViaAPIImpl(
    requestType: RequestTypeValue,
    path: string,
    method: LoginMethodId,
    requestData: any
  ): Promise<void> {
    const request = this.apiRequest({
      requestType,
      method: 'POST',
      path,
      data: requestData,
    });
    await request.promise
      .then(response => {
        if (response.responseStatus === 201) {
          trackAnalyticsEvent(AccountCreatedEvent.create({ method: method }));
        }
      })
      .catch(error => {
        throw new LoginFailedError(error.message);
      });
  }

  async loginViaForgotPassword(method: LoginMethodId): Promise<void> {
    getLayoverLogger().log('login.started', { method });
    const loginPromise = this.loginViaOAuthImpl(`/auth/${method.toLowerCase()}/oauth/pwd`, method);
    try {
      await loginPromise;
    } catch (error) {
      this.logError(error, method);
      throw error;
    }
    getLayoverLogger().log('login.succeeded', { method });
  }

  async loginViaOAuth(method: LoginMethodId): Promise<void> {
    getLayoverLogger().log('login.started', { method });
    const loginPromise = this.loginViaOAuthImpl(`/auth/${method.toLowerCase()}/oauth`, method);
    try {
      await loginPromise;
    } catch (error) {
      this.logError(error, method);
      throw error;
    }
    getLayoverLogger().log('login.succeeded', { method });
  }

  async loginViaOAuthImpl(path: string, method: LoginMethodId): Promise<void> {
    if (typeof window === 'undefined') {
      return Promise.reject(new LoginFailedError());
    }
    return new Promise((resolve, reject) => {
      const cleanup = () => {
        delete window.authSuccessful;
        delete window.authSignUpSuccessful;
        delete window.authFailed;
        delete window.authCancelled;
        delete window.authForbidden;
      };

      window.authSuccessful = () => {
        const cognitoFrame = window.document.getElementById(COGNITO_SIGNOUT_FRAME);
        if (cognitoFrame) {
          // @ts-expect-error -- the cognito frame HTMLElement has a 'src'
          cognitoFrame.src = COGNITO_SIGNOUT_SRC;
        }
        cleanup();
        resolve();
      };

      window.authSignUpSuccessful = () => {
        trackAnalyticsEvent(AccountCreatedEvent.create({ method: method }));

        const cognitoFrame = window.document.getElementById(COGNITO_SIGNOUT_FRAME);
        if (cognitoFrame) {
          // @ts-expect-error -- the cognito frame HTMLElement has a 'src'
          cognitoFrame.src = COGNITO_SIGNOUT_SRC;
        }
        cleanup();
        resolve();
      };

      window.authFailed = () => {
        cleanup();
        reject(new LoginFailedError());
      };

      window.authCancelled = () => {
        cleanup();
        reject(new LoginCancelledError());
      };

      window.authForbidden = () => {
        cleanup();
        reject(new LoginForbiddenError());
      };

      window.open(
        ApiRequest.apiUrl(path),
        'oauthWindow',
        'width=600,height=580,resizable,scrollbars'
      );
    });
  }

  /**
   *
   * @param {string} credential base64 encoded JWT string returned to the One-Tap callback handler
   */
  async loginViaOneTap(credential: string): Promise<void> {
    await this.loginViaAPIImpl(
      Constants.requestTypes.AUTH_API__GOOGLE_ONE_TAP_LOGIN,
      '/auth/googleonetap/login',
      GOOGLE_ONE_TAP,
      credential
    );
  }

  logError(error: any, method: LoginMethodId): void {
    if (error instanceof LoginFailedError) {
      getLayoverLogger().log('login.failed', { method });
    } else if (error instanceof LoginCancelledError) {
      getLayoverLogger().log('login.cancelled', { method });
    } else if (error instanceof LoginForbiddenError) {
      getLayoverLogger().log('login.forbidden', { method });
    }
  }
}

export class LoginFailedError extends Error {}

export class LoginCancelledError extends Error {}

export class LoginForbiddenError extends Error {}
