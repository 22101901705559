import { fetchClaimedAuthorStats } from './AuthorActionCreators';

import Api from '@/api/Api';
import AuthStore from '@/stores/AuthStore';
import GraphQLApi from '@/api/GraphQLApi';

/**
 * Action creator for apis to call when a user logs in.
 */
export async function fetchUserData({
  api,
  authStore,
  graphQLApi,
}: {
  api: Api;
  authStore: AuthStore;
  graphQLApi: GraphQLApi;
}) {
  const authorStatsPromise = fetchClaimedAuthorStats({ graphQLApi });

  // Add new promises here whenever there is data that you need upfront about a user to determine
  // display
  // Wait for this response because it contains the claimed author ID, if the user has one.
  const userDispatch = await api.fetchUserInfo();

  const authorId = authStore.getClaimedAuthorId();
  const authorProfilePromise = authorId
    ? api.fetchAuthorProfile({ authorId })
    : Promise.resolve(null);

  return await Promise.all([
    authorStatsPromise,
    userDispatch,
    api.getLibraryFolders(),
    authorProfilePromise,
  ]);
}
