import { getString } from '@/content/i18n';
import { Nullable } from '@/utils/types';
import CLButton, { TYPE } from '@/components/library/button/CLButton';

import classnames from 'classnames';
import React from 'react';

import type { HighlightedFieldRecord } from '@/models/HighlightedField';
import type { HighlightedFragmentRecord } from '@/models/HighlightedFragment';

export const LESS = 'LESS';
export const MORE = 'MORE';

export type MoreOrLess = typeof MORE | typeof LESS;

type Props = {
  limit: number;
  expandable: boolean;
  className?: Nullable<string>;
  fullTextClassName?: Nullable<string>;
  heapId?: Nullable<string>;
  testId?: Nullable<string>;
  field: HighlightedFieldRecord;
  trackToggle?: Nullable<(moreOrLess: MoreOrLess) => any>;
};

type State = {
  showingFullText: boolean;
};

// TODO(codeviking): Ideally at some point this should use the TextTruncator component to handle
// the more / less logic
export default class HighlightedField extends React.PureComponent<Props, State> {
  static defaultProps = {
    limit: Number.MAX_VALUE,
    expandable: true,
  };

  constructor(...args: [any]) {
    super(...args);

    this.state = {
      showingFullText: false,
    };
  }

  componentWillReceiveProps() {
    this.setState({
      showingFullText: false,
    });
  }

  toggleFullText = () => {
    this.setState(
      prevState => {
        return {
          showingFullText: !prevState.showingFullText,
        };
      },
      () => {
        const event = this.state.showingFullText ? MORE : LESS;
        if (this.props.trackToggle && typeof this.props.trackToggle === 'function') {
          this.props.trackToggle(event);
        }
      }
    );
  };

  render() {
    const { field } = this.props;

    const { text } = field;
    let limit: number;
    if (this.state.showingFullText) {
      limit = text.length;
    } else {
      limit = Math.min(this.props.limit, text.length);

      if (this.props.limit < text.length) {
        // break on word boundaries
        const limitChar = text[limit - 1];
        const boundaryTester = /\s/.test(limitChar) ? /\s/ : /\S/;
        while (limit > 1 && boundaryTester.test(text[limit - 1])) {
          limit -= 1;
        }
        if (/\s/.test(text[limit - 1])) {
          limit -= 1;
        }
      }
    }

    // HACK: Because some code is lazy about passing in a REAL HighlightedFieldRecord, this code needs to be
    //       defensive about typing and converting to an array. Long term, we should track down all the places
    //       this component is called.
    const fragments: (HighlightedFragmentRecord | { start: number; end: number })[] = (
      Array.isArray(field.fragments) ? field.fragments : field.fragments.toArray()
    ).filter(f => f.start < limit);

    const hasMore = text.length > this.props.limit;

    let lastEnd = 0;
    const fragmentEls: React.ReactNode[] = fragments.map((fragment, i) => {
      const els = [
        fragment.start - lastEnd > 0 ? (
          <span key={i}>{text.slice(lastEnd, fragment.start)}</span>
        ) : null,
        <em key={i + 0.5}>{text.slice(fragment.start, Math.min(fragment.end, limit))}</em>,
      ];
      lastEnd = fragment.end;
      return els;
    });

    // This used to test for lastEnd > limit - 1, which had the effect of trimming punctuation and non-matched
    // characters off the end of the rendered title. Once we figure out how to test React components, we should
    // add a test to ensure that HighlightedField is rendering all the characters in a field.
    if (lastEnd < limit) {
      fragmentEls.push(<span key="l">{this.props.field.text.substring(lastEnd, limit)}</span>);
    }

    if (hasMore && this.props.expandable) {
      if (this.state.showingFullText) {
        fragmentEls.push(<span key="space"> </span>);
        fragmentEls.push(
          <CLButton
            aria-label={getString(_ => _.textTruncator.default.truncateAriaLabel)}
            type={TYPE.TERTIARY}
            tabIndex={0}
            key={text}
            className="more mod-clickable more-toggle"
            onClick={this.toggleFullText}
            label={getString(_ => _.textTruncator.default.truncateLabel)}
          />
        );
      } else {
        fragmentEls.push(<span key="ellipses">… </span>);
        fragmentEls.push(
          <CLButton
            aria-label={getString(_ => _.textTruncator.default.extendAriaLabel)}
            tabIndex={0}
            type={TYPE.TERTIARY}
            key={text}
            className="more mod-clickable more-toggle"
            onClick={this.toggleFullText}
            label={getString(_ => _.textTruncator.default.extendLabel)}
          />
        );
      }
    } else if (hasMore) {
      fragmentEls.push(<span key="ellipses">… </span>);
    }

    return (
      <span
        className={classnames(
          this.props.className,
          this.state.showingFullText && this.props.fullTextClassName
        )}
        data-heap-id={this.props.heapId}
        data-test-id={this.props.testId}>
        {fragmentEls}
      </span>
    );
  }
}
