import React from 'react';

export default function IconPublicFolderOpened(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 120.22 80">
      <path d="M21.78,36c2.67-1.78,6.22-2.67,9.78-2.67H100v-9.78c0-2.67-1.33-4.89-3.11-7.11-1.78-2.22-4.45-3.11-7.11-3.11H56.44L43.11,0H9.78C7.11,0,5.33,1.33,3.11,3.11S0,7.56,0,10.22v57.78l14.67-24.89c1.78-2.67,4.44-5.33,7.11-7.11Z" />
      <path d="M119.56,42.67c-.89-1.33-2.67-2.67-4.45-2.67H31.56c-2.22,0-4.45,.44-6.67,1.78-2.22,1.33-3.56,2.67-4.89,4.89l-15.11,25.78c-.89,1.78-.89,3.56,0,4.89,.89,1.33,2.22,2.67,4.44,2.67H92.89c2.22,0,4.44-.44,6.67-1.78,2.22-1.33,3.55-2.67,4.89-4.89l15.11-25.78c.89-1.78,.89-3.56,0-4.89Zm-30.55,29.34l-1.57,1.75h-29.25l1.57-1.75c2.14-2.39,5.18-4.29,9.13-5.7s8.11-2.11,12.49-2.11,7.29,.7,8.71,2.11,1.06,3.31-1.08,5.7Zm8.26-17.38c-1.57,1.75-3.63,3.26-6.18,4.5-2.55,1.25-4.83,1.87-6.84,1.87s-3.17-.62-3.49-1.87c-.31-1.25,.32-2.75,1.89-4.5,1.57-1.75,3.63-3.26,6.18-4.5,2.55-1.25,4.83-1.87,6.84-1.87s3.17,.62,3.49,1.87c.31,1.25-.32,2.75-1.89,4.5Z" />
    </symbol>
  );
}
