import { getString } from '@/content/i18n';
import { PaperRecord } from '@/models/Paper';
import PaperLinkType from '@/constants/PaperLinkType';

import Immutable from 'immutable';
import url from 'url';

import type { DEPRECATED__FlowOptional } from './types';
import type { IconId } from '@/components/shared/icon/IconSprite';
import type { PaperLinkRecord } from '@/models/PaperLink';

export type AlternateLinkGroups = {
  pdf: Immutable.List<PaperLinkRecord>;
  nonPdf: Immutable.List<PaperLinkRecord>;
  openAccess: Immutable.List<PaperLinkRecord>;
  institutional: DEPRECATED__FlowOptional<PaperLinkRecord>;
};

export function isPdfUrl(paperUrl: string): boolean {
  return paperUrl.endsWith('.pdf');
}

export function buildS2ReferralLink(baseUrl: string): string {
  const urlObj = new URL(baseUrl);
  urlObj.searchParams.append('sid', 'semanticscholar');

  return urlObj.toString();
}

export function isEduUrl(paperUrl: string): boolean {
  const host = url.parse(paperUrl).host;

  if (host) {
    return host.endsWith('.edu');
  }

  return false;
}

export function getDisplayableHostname(paperLink: PaperLinkRecord): string {
  const parsedUrl = url.parse(paperLink.url);
  const host = parsedUrl.host;

  return host ? host.replace(/^www\./, '') : '';
}

// Allow "View on Taylor & Francis" to fit. That's the longest allowed.
const MAX_LINK_TEXT_LENGTH = 24;

// This supports the text in the PDF button above the fold on a PDP
export function getLinkText(paperLink: PaperLinkRecord): string {
  if (paperLink.linkType === PaperLinkType.PUBLISHER) {
    const linkText = getString(
      _ => _.paper.link[paperLink.linkType],
      paperLink.publisherName || getString(_ => _.paper.link.defaultPublisher)
    );
    if (linkText.length <= MAX_LINK_TEXT_LENGTH) {
      return linkText;
    }
    return getString(
      _ => _.paper.link[paperLink.linkType],
      getString(_ => _.paper.link.defaultPublisher)
    );
  }
  return getString(_ => _.paper.link[paperLink.linkType]);
}

// This supports the text in the CLPaperRow actions
export function getLinkTextForPaperRowActions(paperLink: PaperLinkRecord): string {
  if (paperLink.linkType === PaperLinkType.PUBLISHER) {
    const linkText = getString(
      _ => _.paper.linkForPaperRow[paperLink.linkType],
      paperLink.publisherName || getString(_ => _.paper.linkForPaperRow.defaultPublisher)
    );
    if (linkText.length <= MAX_LINK_TEXT_LENGTH) {
      return linkText;
    }
    return getString(
      _ => _.paper.linkForPaperRow[paperLink.linkType],
      getString(_ => _.paper.linkForPaperRow.defaultPublisher)
    );
  }
  return getString(_ => _.paper.linkForPaperRow[paperLink.linkType]);
}

export function getPathname(paperLink: PaperLinkRecord): string {
  const parsedUrl = url.parse(paperLink.url);
  const pathname = parsedUrl.pathname;

  return pathname == '/' || !pathname ? '' : pathname;
}

export function getSortableUrl(paperLink: PaperLinkRecord): string {
  return getDisplayableHostname(paperLink) + getPathname(paperLink);
}

export function chooseAlternateLinkIcon(alternatePaperLinks: AlternateLinkGroups): IconId {
  return !alternatePaperLinks.openAccess.isEmpty()
    ? 'open-access'
    : alternatePaperLinks.pdf.isEmpty()
      ? 'fa-globe'
      : 'fa-unlock';
}

export function chooseAlternateLinkString(alternatePaperLinks) {
  return alternatePaperLinks.openAccess.isEmpty()
    ? getString(_ => _.paper.alternate)
    : getString(_ => _.paper.openAccess);
}

export function sortForDisplay(
  alternatePaperLinks: Immutable.List<PaperLinkRecord>
): Immutable.List<PaperLinkRecord> {
  /* Performs a 4-level sort to determine displayed order:
   *
   * 1) Always put open access first if present
   * 2) Puts pdfs ahead of non-pdfs
   * 3) Puts .edu urls ahead of non-edu urls
   * 4) Orders alphabetically by the rendered hostname substring
   */
  return alternatePaperLinks.sort((a, b) => {
    const aIsPdf = isPdfUrl(a.url);
    const bIsPdf = isPdfUrl(b.url);

    const aIsEdu = isEduUrl(a.url);
    const bIsEdu = isEduUrl(b.url);

    const bothOrNeitherAreEdus = (aIsEdu && bIsEdu) || !(aIsEdu || bIsEdu);

    const aIsOpenAccess = a.linkType === PaperLinkType.OPEN_ACCESS;
    const bIsOpenAccess = b.linkType === PaperLinkType.OPEN_ACCESS;

    if (aIsOpenAccess && !bIsOpenAccess) {
      return -1;
    } else if (bIsOpenAccess && !aIsOpenAccess) {
      return 1;
    } else if (aIsPdf && !bIsPdf) {
      return -1;
    } else if (bIsPdf && !aIsPdf) {
      return 1;
    } else if (bothOrNeitherAreEdus) {
      return getSortableUrl(a) >= getSortableUrl(b) ? 1 : -1;
    }

    return aIsEdu ? -1 : 1;
  });
}

export function groupForDisplay(
  alternatePaperLinks: Immutable.List<PaperLinkRecord>,
  maxAllowed: number
): AlternateLinkGroups {
  // First pull out open access link(s)
  const groupedByOpenAccess = alternatePaperLinks
    .take(maxAllowed)
    .filter(paperLink => paperLink.linkType !== PaperLinkType.INSTITUTIONAL_ACCESS)
    .groupBy(paperLink => paperLink.linkType === PaperLinkType.OPEN_ACCESS)
    .toMap();

  // Pull out institutional login link
  const institutionalLogin = alternatePaperLinks.find(
    paperLink => paperLink.linkType === PaperLinkType.INSTITUTIONAL_ACCESS
  );

  // Then group the non-open-access links into PDF and Non-PDF
  const groupedByPDF = groupedByOpenAccess
    .get<Immutable.List<PaperLinkRecord>>(false, Immutable.List())
    .groupBy(paperLink => isPdfUrl(paperLink.url))
    .toMap();

  return {
    openAccess: groupedByOpenAccess
      .get<Immutable.List<PaperLinkRecord>>(true, Immutable.List())
      .toList(),
    institutional: institutionalLogin,
    pdf: groupedByPDF.get<Immutable.List<PaperLinkRecord>>(true, Immutable.List()).toList(),
    nonPdf: groupedByPDF.get<Immutable.List<PaperLinkRecord>>(false, Immutable.List()).toList(),
  };
}

const MAX_ALLOWED_ALTERNATE_LINKS = 5;

export function prepareAlternateLinkGroups(
  alternatePaperLinks: Immutable.List<PaperLinkRecord>
): AlternateLinkGroups {
  const sorted = sortForDisplay(alternatePaperLinks);
  return groupForDisplay(sorted, MAX_ALLOWED_ALTERNATE_LINKS);
}

export function hasOpenAccessLinks(alternatePaperLinks: Immutable.List<PaperLinkRecord>): boolean {
  return alternatePaperLinks.some(paperLink => paperLink.linkType === PaperLinkType.OPEN_ACCESS);
}

// This is experiment hack that we use to check if
// the publisher is the one we have agreement on (below list)
// if the experiment ends up successful we may need to switch to
// get our back end to verify this instead of UI.
// https://github.com/allenai/scholar/issues/31858
const BRANDED_LINK_TYPES: string[] = [PaperLinkType.ACM, PaperLinkType.WILEY];
const BRANDED_PUBLISHER_NAMES: string[] = [
  'acm',
  'asmusa',
  'bioone',
  'biorxiv',
  'bmj',
  'cambridge press',
  'elsevier',
  'iop publishing',
  'medrxiv',
  'mit press',
  'nature',
  'royal society',
  'sage',
  'science',
  'scientific.net',
  'spie',
  'springer',
  'springer',
  'taylor & francis',
  'u chicago press',
  'wiley',
  'wolters kluwer',
].map(_ => _.toLowerCase());

export function isPaperLinkBranded(link: DEPRECATED__FlowOptional<PaperLinkRecord>): boolean {
  if (!link) {
    return false;
  }
  const { linkType, publisherName } = link;
  if (BRANDED_LINK_TYPES.includes(linkType.toString())) {
    return true;
  }
  if (linkType === PaperLinkType.PUBLISHER) {
    const lowerCasePubName = publisherName.toLowerCase();
    if (BRANDED_PUBLISHER_NAMES.includes(lowerCasePubName)) {
      return true;
    }
  }
  return false;
}

export function prepareAlternatePaperLinks(
  paper: PaperRecord,
  readerVisible: boolean
): Immutable.List<PaperLinkRecord> {
  const sortedAlternateLinkButton = sortForDisplay(paper.alternatePaperLinks);
  // remove extracted external link above from alternate link groups
  const otherAlternateLinks = sortedAlternateLinkButton.shift();

  const alternatePaperLinks = readerVisible ? sortedAlternateLinkButton : otherAlternateLinks;
  return alternatePaperLinks;
}

export function isDisplayingDropDown(alternateLinksGrouped: AlternateLinkGroups): boolean {
  const isAlternateOpenAccessPaperLinksExist = alternateLinksGrouped.openAccess.isEmpty();
  const isAlternatePdfPaperLinksExist = alternateLinksGrouped.pdf.isEmpty();
  const isAlternateExternalPaperLinksExist = alternateLinksGrouped.nonPdf.isEmpty();
  const isAlternateInstitutionalPaperLinkExist = !alternateLinksGrouped.institutional;
  const result = !(
    isAlternateOpenAccessPaperLinksExist &&
    isAlternatePdfPaperLinksExist &&
    isAlternateExternalPaperLinksExist &&
    isAlternateInstitutionalPaperLinkExist
  );
  return result;
}
