import { hideMessage } from '@/actions/MessageActionCreators';
import { MessageRecord } from '@/models/Message';
import { Nullable, ReactNodeish } from '@/utils/types';
import { softInvariant } from '@/utils/softError';
import CLAlert, { LEVEL, SEVERITY } from '@/components/library/alert/CLAlert';
import CLIconButton, {
  SIZE as BUTTON_SIZE,
  TYPE as BUTTON_TYPE,
} from '@/components/library/button/CLIconButton';
import Icon from '@/components/shared/icon/Icon';
import MessageStore from '@/stores/MessageStore';
import S2Dispatcher from '@/utils/S2Dispatcher';

import PropTypes from 'prop-types';
import React from 'react';

type Props = {
  message: Nullable<MessageRecord>;
};

export default class PageLevelMessage extends React.Component<Props> {
  static contextTypes = {
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    messageStore: PropTypes.instanceOf(MessageStore).isRequired,
  };

  declare context: {
    dispatcher: S2Dispatcher;
    messageStore: MessageStore;
  };

  onClickHideMessage = () => {
    const { dispatcher } = this.context;
    const { message } = this.props;
    softInvariant(
      !!message,
      'page-level-message',
      'Attempt to hide a page level message without a "message" state'
    );

    if (!message) {
      return;
    }

    const messagePayload = hideMessage(message);
    dispatcher.dispatch(messagePayload);
  };

  render(): ReactNodeish {
    const { message } = this.props;
    if (!message) {
      return null;
    }

    return (
      <div className="page-level-message" data-test-id="page-level-message">
        <div className="page-level-message__message">
          <CLAlert
            icon={<Icon icon="status-warning" width="15" height="15" />}
            level={LEVEL.FORM}
            severity={SEVERITY.ERROR}>
            <div className="page-level-message__message__body">
              <div className="page-level-message__message__text">{message.text}</div>
              <div className="page-level-message__message__close">
                <CLIconButton
                  className="page-level-message__message__close-btn"
                  icon={<Icon icon="remove-x" width="10" height="10" />}
                  onClick={this.onClickHideMessage}
                  size={BUTTON_SIZE.SMALL}
                  type={BUTTON_TYPE.TERTIARY}
                  testId="page-level-message-close"
                />
              </div>
            </div>
          </CLAlert>
        </div>
      </div>
    );
  }
}
