import {
  getLibraryEntriesQueryFromPath,
  getLibraryEntriesQueryFromQueryStringParams,
} from '@/models/library/LibraryEntriesQuery';
import { getLibraryFolderIdFromMatch } from '@/utils/library-utils';
import { getString } from '@/content/i18n';
import { LIBRARY_DEFAULT_SORT } from '@/models/library/LibrarySort';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import Routes from '@/router/Routes';
import S2History from '@/utils/S2History';
import S2Redirect from '@/models/redirects/S2Redirect';

import PropTypes from 'prop-types';
import React from 'react';

export const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE = 1;

type TODO__MATCH = any;

type Props = {
  match: TODO__MATCH;
};

export default class SharedLibraryFolderRoute extends React.PureComponent<Props> {
  static getPageTitle({ sharedLibraryFolderStore }) {
    const folder = sharedLibraryFolderStore.getFolder();
    const pageTitle = folder
      ? getString(_ => _.library.pageTitleWithFolder, folder.name)
      : getString(_ => _.library.pageTitle);
    return pageTitle;
  }

  static requiresAuthentication = () => false;

  static async willRouteTo({ api, sharedLibraryFolderStore }, routerState) {
    const { libraryFolderId } = routerState.params;
    const query = routerState?.query?.q || '';
    const sort = routerState?.query?.sort || LIBRARY_DEFAULT_SORT;
    const page = routerState?.query?.page || 1;
    const isNewFolderId = libraryFolderId !== sharedLibraryFolderStore.getFolder()?.id;

    const currentQuery = sharedLibraryFolderStore.getCurrentQuery();
    const incomingQuery = getLibraryEntriesQueryFromQueryStringParams({
      pageSize: DEFAULT_PAGE_SIZE,
      page,
      q: query,
      sort,
    });

    let folderPayload = null;
    if (libraryFolderId && (isNewFolderId || !incomingQuery.equals(currentQuery))) {
      try {
        folderPayload = await api.getFolderById(parseInt(libraryFolderId, 10), incomingQuery);
      } catch (e) {
        if (e.status === 403 || e.status === 401) {
          return new S2Redirect({
            path: Routes.UNAUTHORIZED_FOLDER,
            replace: true,
          });
        }
        throw e;
      }
    }

    return [folderPayload];
  }

  static contextTypes = {
    history: PropTypes.instanceOf(S2History).isRequired,
  };

  render() {
    const { history } = this.context;

    const queryParams = getLibraryEntriesQueryFromPath(history.location.search);
    const libraryFolderId = getLibraryFolderIdFromMatch(this.props.match);

    return (
      <AsyncLoadedPage
        key={libraryFolderId /* each page should render new components */}
        compProps={{ libraryFolderId, currentPageNumber: queryParams.page || DEFAULT_PAGE }}
        load={{
          importer: () =>
            import(/* webpackChunkName: "shared-LibraryFolderPage" */ './SharedLibraryFolderPage'),
          chunkName: 'shared-SharedLibraryFolderPage',
          moduleId: require.resolveWeak('./SharedLibraryFolderPage'),
        }}
      />
    );
  }
}
