import React from 'react';

export default function IconOpaqueEmptyLibrary(props: { id?: string }) {
  return (
    <symbol id={props.id} fill="#FFFFFF" viewBox="0 0 244.5 222">
      <style type="text/css">
        {`
          .empty{height:220px; display:block; margin:24px auto;}
          .empty__items{stroke:#D9DADB;stroke-width:2;stroke-dasharray:6,4;}
          .empty__backdrop{opacity: 0.6}
        `}
      </style>
      <circle className="empty__backdrop" cx="122" cy="111" r="110" />
      <path
        className="empty__items"
        d="M78.8,33.8c-1.2-1.2-2.9-1.9-4.7-1.9h-1.7v35.3h35.2v-1.7c0-1.7-0.6-3.4-1.9-4.7L78.8,33.8z M63.5,69.4V31.9 h-55c-3.6,0-6.5,2.9-6.5,6.6v128c0,3.6,2.9,6.7,6.5,6.6H101c3.6,0,6.5-2.9,6.5-6.6V76.1H70.2C66.6,76.1,63.6,73,63.5,69.4z M134,103 h59.4c2.3,0,4.2-1.9,4.2-4.2V41.2h-24c-2.3,0-4.2-2-4.3-4.3V13H134c-2.3,0-4.2,1.9-4.2,4.2v81.6C129.8,101.1,131.6,103.1,134,103z M197.6,34.4c0-1.1-0.4-2.1-1.2-3l-17.3-17.2c-0.7-0.7-1.9-1.2-3-1.2H175v22.5h22.6V34.4z M239.2,142.6c-1.9-2.3-4.7-3.3-7.5-3.3 h-42.6l-14-14.1h-35.1c-2.8,0-4.7,0.9-7,2.8c-2.3,1.9-3.3,4.7-3.3,7.5v63.8c0,2.8,0.9,5.2,2.8,7.5c1.9,2.3,4.7,3.3,7.5,3.3h91.6 c2.8,0,5.1-1.4,7.5-3.3c2.3-1.9,3.3-4.7,3.3-7.5v-49.3C242.5,147.3,241.1,144.9,239.2,142.6z"
      />
    </symbol>
  );
}
