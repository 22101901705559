import React from 'react';

export default function CoVizLogo(props) {
  return (
    <symbol id={props.id} viewBox="0 0 132 48">
      <path
        d="M22.6369 10.1926C26.311 10.1926 29.7481 11.6148 32.3555 14.2222C34.9629 16.8296 36.3851 20.2666 36.3851 23.9407C36.3851 27.6148 34.9629 31.0518 32.3555 33.6592C29.7481 36.2666 26.311 37.6889 22.6369 37.6889C18.9629 37.6889 15.5258 36.2666 12.9184 33.6592C10.311 31.0518 8.88879 27.6148 8.88879 23.9407C8.88879 20.2666 10.311 16.8296 12.9184 14.2222C15.4073 11.6148 18.9629 10.1926 22.6369 10.1926ZM22.6369 7.8222C13.6295 7.8222 6.3999 15.0518 6.3999 24.0592C6.3999 33.0666 13.6295 40.2963 22.6369 40.2963C31.6443 40.2963 38.874 33.0666 38.874 24.0592C38.874 15.0518 31.6443 7.8222 22.6369 7.8222Z"
        fill="#0F3875"
      />
      <path
        d="M41.837 33.6593L36.6222 30.6963L35.2 29.8667C35.2 29.8667 35.0815 29.8667 35.0815 29.7481C34.963 29.7481 34.963 29.6296 34.8444 29.6296C32 27.6148 31.0519 23.7037 32.8296 20.6222C33.4222 19.5556 34.2519 18.7259 35.2 18.1333H35.3185L37.3333 16.9481L41.9556 14.3407L43.1407 16.4741L45.2741 15.2889L44.0889 13.1556L42.9037 11.0222L41.7185 8.88889L39.5852 10.0741L40.7704 12.2074L34.1333 16.1185C32.8296 16.9481 31.6444 18.0148 30.8148 19.437C29.7481 21.2148 29.3926 23.2296 29.6296 25.2444C29.2741 24.7704 28.9185 24.2963 28.4444 23.8222C26.6667 21.4519 25.4815 18.8444 24.6519 16C24.1778 14.1037 23.9407 12.2074 23.9407 10.1926V7.82222V2.48889H26.4296V0H23.9407H21.4519H18.963V2.48889H21.4519V10.1926C21.4519 15.2889 22.9926 20.1481 25.837 24.4148L4.62222 12.2074L5.80741 10.0741L3.67407 8.88889L2.48889 11.0222L1.3037 13.1556L0.118519 15.2889L2.25185 16.4741L3.43704 14.3407L24.5333 26.5481C23.8222 26.5481 23.2296 26.4296 22.5185 26.4296C18.3704 26.4296 14.1037 27.4963 10.1926 29.7481L10.0741 29.8667L3.31852 33.6593L2.13333 31.5259L0 32.7111L1.18519 34.8444L2.37037 36.9778L3.55556 39.1111L5.68889 37.9259L4.5037 35.7926L9.12593 33.1852L10.9037 32.1185C11.0222 32.1185 11.1407 32 11.2593 32C11.2593 32 11.3778 32 11.3778 31.8815C14.8148 29.9852 18.6074 28.9185 22.5185 28.9185C24.0593 28.9185 25.4815 29.037 27.0222 29.3926C23.7037 30.8148 21.4519 34.0148 21.3333 37.8074V45.5111H18.8444V48H21.3333H23.8222H26.3111V45.5111H23.8222V40.1778V37.8074C23.8222 37.8074 23.8222 37.8074 23.8222 37.6889C23.8222 34.0148 26.9037 31.0519 30.5778 31.0519C31.763 31.0519 32.9482 31.4074 33.8963 31.8815L40.5333 35.7926L39.3481 37.9259L41.4815 39.1111L42.6667 36.9778L43.8519 34.8444L45.037 32.7111L42.9037 31.5259L41.837 33.6593Z"
        fill="#F1615F"
      />
    </symbol>
  );
}
