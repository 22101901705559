import { CitationPageRecord, CitationPageRecordFactory } from './CitationPage';
import { FigureRecord, getFigureLinkedData } from './Figure';
import { getPaperDescription, getPaperLinkedData, PaperRecord, PaperRecordFactory } from './Paper';
import { PaperEntitlementRecord } from './paper/PaperEntitlement';

import { CitationMeta } from '@/router/Route';
import { CitationType } from '@/constants/Citation';
import { Nullable, TODO } from '@/utils/types';
import { RouteName } from '@/router/Routes';
import SkipperExperiments from '@/skipper/models/SkipperExperiments';

import Immutable from 'immutable';
import merge from 'merge';

type TODO__LinkedData = TODO<'LinkedData'>;

type CitationSortAvailability = {
  citingPapers?: string[];
  citedPapers?: string[];
};

type Props = {
  paper: PaperRecord;
  citedPapers: CitationPageRecord;
  figures: Immutable.List<FigureRecord>;
  relatedPapers: Immutable.List<PaperRecord>;
  isLoadingRelatedPapers: boolean;
  skipperExperiments: SkipperExperiments;
  citationSortAvailability: CitationSortAvailability;
  entitlement?: PaperEntitlementRecord;
};

const defaultProperties: Props = {
  paper: PaperRecordFactory(),
  citedPapers: CitationPageRecordFactory({ citationType: CitationType.CITED_PAPERS }),
  figures: Immutable.List(),
  relatedPapers: Immutable.List(),
  isLoadingRelatedPapers: true,
  skipperExperiments: SkipperExperiments.fromJS([]),
  citationSortAvailability: {},
  entitlement: undefined,
};

export const PaperDetailRecordFactory = Immutable.Record<Props>(defaultProperties);
export type PaperDetailRecord = Immutable.RecordOf<Props>;

/**
 * Returns whether the paper detail has a valid title.
 *
 * @returns {boolean}
 */
export function paperDetailHasTitle(paperDetail: PaperDetailRecord): boolean {
  return !!(paperDetail.paper && paperDetail.paper.title?.text);
}

/**
 * Returns whether the paper detail has any extracted figures
 *
 * @returns {boolean}
 */
export function paperDetailHasFigures(paperDetail: PaperDetailRecord): boolean {
  return !!(paperDetail.figures && !paperDetail.figures.isEmpty());
}

/**
 * Returns whether the paper detail has a figure at the provided index.
 *
 * @param {number} index
 *
 * @returns {boolean}
 */
export function paperDetailHasFigure(paperDetail: PaperDetailRecord, index: number) {
  return !!(paperDetail.figures && paperDetail.figures.has(index));
}

/**
 * Returns the JSON-LD (JSON Linked Data) representation of the PaperDetail, which includes
 * all of the Paper#linkedData plus figures.
 */
export function getPaperDetailLinkedData(
  paperDetail: PaperDetailRecord,
  routeName: RouteName
): TODO__LinkedData {
  // TODO(markschaake): We are not including references (ScholarlyArticle#citation) because
  // we do not have all references available at page load time. Currently, we only have up
  // to the first 10 references. We don't want Google to use these as the basis for a count
  // or for putting together an incomplete list of referencses.
  //const referencesLinkedData = {
  //  'citation': this.citedPapers.linkedData()
  //};
  const figuresLinkedData = {
    image: paperDetail.figures.map(figure => getFigureLinkedData(figure)).toJS(),
  };

  const structuredDataBlocks = getPaperLinkedData(paperDetail.paper, routeName)['@graph'];
  for (const block in structuredDataBlocks) {
    merge(structuredDataBlocks[block], figuresLinkedData);
  }

  return structuredDataBlocks;
}

/**
 * The description of the paper, which is by default the abstract.
 */
export function getPaperDetailDescription(paperDetail: PaperDetailRecord) {
  return getPaperDescription(paperDetail.paper);
}

/**
 * The DOI tag of the paper.
 */
export function getPaperDetailDoi(paperDetail: PaperDetailRecord): Nullable<string> {
  return paperDetail.paper?.doiInfo?.doi || null;
}

/**
 * The metadata needed for citation meta tags.
 */
export function getPaperDetailCitationMeta(
  paperDetail: PaperDetailRecord
): Immutable.List<CitationMeta> {
  const paperData = paperDetail.paper;

  const paperCitationMeta = paperData.authors
    .map(author => ({ name: 'citation_author', content: author.alias.name }))
    .toArray();

  paperCitationMeta.push({
    name: 'citation_title',
    content: paperData.title.text,
  });

  if (paperData.year.text) {
    paperCitationMeta.push({
      name: 'citation_publication_date',
      content: paperData.year.text,
    });
  }

  if (paperData.journal || paperData.venue) {
    // fall back to venue if there is no journal nor no venue id
    const maybeJournal = paperData.journal?.name || paperData.venue.text;
    // if there is a venueId, show the venue text, else
    // show journal text. If all else failed, fall back to paperData.venue.text
    // like the old logic.
    const content =
      paperData.venueId && paperData.venueId !== '' ? paperData.venue.text : maybeJournal;

    paperCitationMeta.push({
      name: 'citation_journal_title',
      content: content,
    });
  }

  if (!paperData.links.isEmpty()) {
    paperCitationMeta.push({
      name: 'citation_pdf_url',
      content: paperData.links.first()?.url || '',
    });
  }

  return Immutable.List(paperCitationMeta);
}
