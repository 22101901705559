import { getString } from '@/content/i18n';
import Api from '@/api/Api';
import CLButton from '@/components/library/button/CLButton';
import LibraryRecommendationsStore from '@/stores/LibraryRecommendationsStore';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default class RecommendationsFooter extends React.PureComponent {
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    libraryRecommendationsStore: PropTypes.instanceOf(LibraryRecommendationsStore).isRequired,
  };

  static defaultProps = {
    hasOlderEnabled: true,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      ...this.getStateFromLibraryRecommendationsStore(),
    };

    this.context.libraryRecommendationsStore.registerComponent(this, () => {
      this.setState(this.getStateFromLibraryRecommendationsStore());
    });
  }

  getStateFromLibraryRecommendationsStore() {
    const store = this.context.libraryRecommendationsStore;
    return {
      visibleFolderIds: store.getVisibleFolderIds(),
      nextWindowUTC: store.getNextWindowUTC(),
    };
  }

  onShowMoreRecommendations = () => {
    const { visibleFolderIds, nextWindowUTC } = this.state;
    const { api } = this.context;

    if (nextWindowUTC) {
      api.getMoreLibraryFoldersRecommendations(visibleFolderIds, nextWindowUTC);
    }
  };

  render() {
    const { className, hasOlderEnabled } = this.props;
    const { nextWindowUTC } = this.state;

    if (!hasOlderEnabled || !nextWindowUTC) {
      return null;
    }

    return (
      <footer
        className={classnames('recommendations-footer', className)}
        data-test-id="recommendations-footer">
        <div className="recommendations-footer__pagination">
          <CLButton
            onClick={this.onShowMoreRecommendations}
            label={getString(_ => _.library.recommendationsFooter.showMoreButtonLabel)}
            testId="recommendations-footer__show-more-button"
          />
        </div>
      </footer>
    );
  }
}
