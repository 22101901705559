import EnvInfo from '@/env/EnvInfo';

import PropTypes from 'prop-types';
import React from 'react';

export default class IsMobile extends React.PureComponent {
  static contextTypes = {
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
  };

  render() {
    const { children } = this.props;

    if (!this.context.envInfo.isMobile) {
      return null;
    }

    if (typeof children === 'function') {
      return children();
    }
    return children;
  }
}
