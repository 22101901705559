import * as util from '@/util';
import { ADMIN, AUTHOR_CLAIM_MODERATOR, S2_TEAM } from '@/constants/Role';
import { AuthorProfileRecord } from '@/models/author/AuthorProfile';
import { AuthorStatisticsRecord } from '@/models/author/AuthorStatistics';
import { capitalizeFirst } from '@/format';
import { getString } from '@/content/i18n';
import {
  heapLibraryLink,
  heapResearchHomepageLink,
  heapSignOutButton,
} from '@/analytics/attributes/signin';
import { mapAppContextToProps, useStateFromStore } from '@/AppContext';
import { Nullable, ReactNodeish } from '@/utils/types';
import Api from '@/api/Api';
import AuthEvent from '@/analytics/models/AuthEvent';
import AuthorProfilePicture from '@/components/shared/author/AuthorProfilePicture';
import AuthStore from '@/stores/AuthStore';
import CLIconButton from '@/components/library/button/CLIconButton';
import CLTextButton from '@/components/library/button/CLTextButton';
import Constants from '@/constants';
import FeatureDisabled from '@/components/util/features/FeatureDisabled';
import FeatureEnabled from '@/components/util/features/FeatureEnabled';
import FeatureGate from '@/components/util/features/FeatureGate';
import Icon from '@/components/shared/icon/Icon';
import IsDesktop from '@/components/util/env/IsDesktop';
import ModerationStatus, { ModerationStatusValue } from '@/constants/ModerationStatus';
import NavLink from '@/router/NavLink';
import S2Dispatcher from '@/utils/S2Dispatcher';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';
import VerifiedCheckmark from '@/components/shared/author/VerifiedCheckmark';

import idx from 'idx';
import PropTypes from 'prop-types';
import React from 'react';

type Props = {} & PropsFromAppContext;

type PropsFromAppContext = {
  isAdmin: boolean;
  isModerator: boolean;
  isS2Team: boolean;
  claimedAuthorId: Nullable<number>;
  authorClaimStatus: Nullable<ModerationStatusValue>;
  authorProfile: Nullable<AuthorProfileRecord>;
  authorStatistics: Nullable<AuthorStatisticsRecord>;
};

class AccountMenuDropdown extends React.PureComponent<Props, {}> {
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    authStore: PropTypes.instanceOf(AuthStore).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
  };

  formatPublicationCount = (count: Nullable<number>): string => {
    return util.pluralize(
      count || 0,
      capitalizeFirst(
        getString(_ => _.general.publicationSingle),
        ''
      ),
      capitalizeFirst(
        getString(_ => _.general.publicationPlural),
        ''
      )
    );
  };

  formatCitationCount = (count: Nullable<number>): string => {
    return util.pluralize(
      count || 0,
      capitalizeFirst(
        getString(_ => _.general.citationSingle),
        ''
      ),
      capitalizeFirst(
        getString(_ => _.general.citationPlural),
        ''
      )
    );
  };

  onClickSignOut = (): void => {
    const { authStore } = this.context;
    const authSource = idx(authStore.getUser(), _ => _.authSource) || 'None';
    const method = capitalizeFirst(authSource.toLowerCase(), ''); // Match the capitalization used in LoginMethods
    trackAnalyticsEvent(
      AuthEvent.create({
        method: method,
        state: AuthEvent.State.LOGGED_OUT,
        action: undefined,
        location: '',
        subLocation: '',
      })
    );
    this.context.api.logout();
  };

  showOverridePane = (): void => {
    const { dispatcher } = this.context;
    dispatcher.dispatchEventually({
      actionType: Constants.actions.SHOW_OVERRIDE_PANE,
    });
  };

  renderAuthorSection = (): ReactNodeish => {
    const { claimedAuthorId, authorClaimStatus, authorProfile, authorStatistics } = this.props;
    if (!!claimedAuthorId && !!authorProfile && !!authorStatistics) {
      const isVerified = !!claimedAuthorId && authorClaimStatus === ModerationStatus.Approved;
      return (
        <React.Fragment>
          <section className="account-menu__section account-menu__section--author">
            <NavLink
              className="account-menu__dropdown-link"
              activeClassName="account-menu__dropdown-link--active"
              to="AUTHOR_PROFILE_BY_ID"
              shouldUnderline={false}
              params={{ authorId: claimedAuthorId }}>
              <div className="account-menu__dropdown-link__icon">
                <FeatureGate feature={_ => _.AuthorProfilePicture}>
                  <FeatureEnabled>
                    <AuthorProfilePicture
                      authorProfile={authorProfile}
                      sizePx={22}
                      fallback={<Icon icon="user" />}
                    />
                  </FeatureEnabled>
                  <FeatureDisabled>
                    <Icon icon="user" />
                  </FeatureDisabled>
                </FeatureGate>
              </div>
              <span data-test-id="account-menu-name">
                {authorProfile.fullName}
                {isVerified && <VerifiedCheckmark sizePx={12} />}
              </span>
            </NavLink>
            <ul className="account-menu__dropdown-author__stats" data-test-id="account-menu-stats">
              <li>{this.formatPublicationCount(authorStatistics.totalPaperCount)}</li>
              <li>{this.formatCitationCount(authorStatistics.totalCitationCount)}</li>
            </ul>
          </section>
          <hr className="account-menu__hr" />
        </React.Fragment>
      );
    }
    return null;
  };

  renderResearchOptions = (): ReactNodeish => {
    return (
      <section className="account-menu__section">
        <NavLink
          data-test-id="account-menu-homepage-link"
          className="account-menu__dropdown-link"
          activeClassName="account-menu__dropdown-link--active"
          {...heapResearchHomepageLink()}
          shouldUnderline={false}
          to="RESEARCH_HOMEPAGE">
          <div className="account-menu__dropdown-link__icon">
            <Icon icon="research-dashboard" />
          </div>
          {getString(_ => _.account.menu.researchDashboardLabel)}
        </NavLink>
        <NavLink
          data-test-id="account-menu-recommendations-link"
          className="account-menu__dropdown-link"
          activeClassName="account-menu__dropdown-link--active"
          shouldUnderline={false}
          to="RECOMMENDATIONS">
          <div className="account-menu__dropdown-link__icon">
            <Icon icon="lightning-bolt" />
          </div>
          <React.Fragment>{getString(_ => _.account.menu.researchFeedsLabel)}</React.Fragment>
        </NavLink>
        <NavLink
          data-test-id="account-menu-library-link"
          className="account-menu__dropdown-link"
          activeClassName="account-menu__dropdown-link--active"
          {...heapLibraryLink()}
          shouldUnderline={false}
          to="LIBRARY_ALL_ENTRIES">
          <div className="account-menu__dropdown-link__icon">
            <Icon icon="fa-bookmark" />
          </div>
          {getString(_ => _.account.menu.libraryLabel)}
        </NavLink>
      </section>
    );
  };

  renderAccountOptions = (): ReactNodeish => {
    return (
      <section className="account-menu__section">
        <IsDesktop>
          <NavLink
            className="account-menu__dropdown-link"
            activeClassName="account-menu__dropdown-link--active"
            shouldUnderline={false}
            to="ACCOUNT">
            <div className="account-menu__dropdown-link__icon">
              <Icon icon="gear" width="12" height="12" />
            </div>
            <React.Fragment>{getString(_ => _.account.menu.settingsLabel)}</React.Fragment>
          </NavLink>
        </IsDesktop>
        <CLIconButton
          className="account-menu__dropdown-link account-menu__dropdown-button"
          type="tertiary"
          icon={<Icon icon="sign-out" width="12" height="12" />}
          label={getString(_ => _.account.menu.signOutLabel)}
          onClick={this.onClickSignOut}
          {...heapSignOutButton()}
          data-test-id="sign-out"
        />
      </section>
    );
  };

  renderAdminOptions = (): ReactNodeish => {
    const { isS2Team, isAdmin, isModerator } = this.props;
    return (
      <section className="account-menu__section">
        {(isS2Team || isAdmin) && (
          <IsDesktop>
            <NavLink
              className="account-menu__dropdown-link"
              activeClassName="account-menu__dropdown-link--active"
              shouldUnderline={false}
              to="ADMIN">
              <React.Fragment>{getString(_ => _.account.menu.adminLabel)}</React.Fragment>
            </NavLink>
            <NavLink
              className="account-menu__dropdown-link"
              activeClassName="account-menu__dropdown-link--active"
              shouldUnderline={false}
              to="DEBUG_COMPONENT_LIBRARY">
              <React.Fragment>
                {getString(_ => _.account.menu.componentLibraryLabel)}
              </React.Fragment>
            </NavLink>
            <NavLink
              className="account-menu__dropdown-link"
              activeClassName="account-menu__dropdown-link--active"
              shouldUnderline={false}
              to="DEBUG_SPRITES">
              <React.Fragment>{getString(_ => _.account.menu.spritesLabel)}</React.Fragment>
            </NavLink>
            <div className="account-menu__dropdown-link">
              <CLTextButton label="Experiment Overrides" onClick={this.showOverridePane} />
            </div>
          </IsDesktop>
        )}
        {(isModerator || isAdmin) && (
          <IsDesktop>
            <NavLink
              className="account-menu__dropdown-link"
              activeClassName="account-menu__dropdown-link--active"
              shouldUnderline={false}
              to="MODERATION">
              <React.Fragment>{getString(_ => _.account.menu.moderationLabel)}</React.Fragment>
            </NavLink>
          </IsDesktop>
        )}
      </section>
    );
  };

  render(): ReactNodeish {
    const { isS2Team, isAdmin, isModerator } = this.props;
    return (
      <div data-test-id="account-menu-dropdown" className="account-menu__dropdown">
        {this.renderAuthorSection()}
        {this.renderResearchOptions()}
        <hr className="account-menu__hr" />
        {this.renderAccountOptions()}
        {(isAdmin || isModerator || isS2Team) && (
          <React.Fragment>
            <hr className="account-menu__hr" />
            {this.renderAdminOptions()}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default mapAppContextToProps<Props, PropsFromAppContext>(AccountMenuDropdown, appContext => {
  const authStoreState = useStateFromStore(appContext.authStore, _ => ({
    isAdmin: _.hasUserRole(ADMIN),
    isModerator: _.hasUserRole(AUTHOR_CLAIM_MODERATOR),
    isS2Team: _.hasUserRole(S2_TEAM),
    claimedAuthorId: _.getClaimedAuthorId(),
    authorClaimStatus: _.getAuthorClaimModerationStatus(),
  }));
  const authorProfileStoreState = useStateFromStore(appContext.authorProfileStore, _ => ({
    authorProfile: _.getAuthorProfile(),
  }));
  const authorStatsStoreState = useStateFromStore(appContext.authorStatsStore, _ => ({
    authorStatistics: _.getViewerAuthorStatistics(),
  }));

  return {
    ...authStoreState,
    ...authorProfileStoreState,
    ...authorStatsStoreState,
  };
});
