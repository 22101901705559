import { AuthorDetailsRecord, getAuthorDetailsFromJS } from '@/models/author/AuthorDetails';
import {
  AuthorRecommendationRecord,
  AuthorRecommendationRecordFactory,
} from '@/models/author/AuthorRecommendation';
import { getAuthorFromGraphQL } from '@/models/author/Author';
import { hasValue, Nullable } from '@/utils/types';
import BaseStore from '@/stores/BaseStore';
import Constants from '@/constants';
import softError from '@/utils/softError';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import Immutable from 'immutable';

import type { ApiRequestStartingPayload } from '@/api/BaseApi';
import type { ApiResponse, AuthorDetailResponseBody } from '@/api/ApiResponse';
import type { FetchAuthorRecommendationsResponse } from '@/actions/AuthorActionCreators';
import type { GraphQLResponse } from '@/api/GraphQLApi';
import type { RouteName } from '@/router/Routes';
import type { RoutingPayload } from '@/actions/RouterActionCreators';
import type S2Dispatcher from '@/utils/S2Dispatcher';

const AHP_ROUTE_NAMES: Readonly<RouteName[]> = [
  'AUTHOR_PROFILE',
  'AUTHOR_PROFILE_CITING_AUTHORS',
  'AUTHOR_PROFILE_REFERENCED_AUTHORS',
  'AUTHOR_PROFILE_CO_AUTHORS',
] as const;

export default class AuthorStore extends BaseStore {
  #state: StoreStateValue;
  #authorDetail: Nullable<AuthorDetailsRecord>;
  #recommendationsState: StoreStateValue;
  #recommendations: {
    coauthor: Nullable<Immutable.List<AuthorRecommendationRecord>>;
  };
  #loadingAuthorId: Nullable<string>;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#reset();

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.ROUTING: {
          return this.#handleRouting(payload as RoutingPayload);
        }
        case Constants.actions.API_REQUEST_STARTING: {
          const startingPayload = payload as ApiRequestStartingPayload;
          switch (startingPayload.requestType) {
            case Constants.requestTypes.AUTHOR_DETAIL:
            case Constants.requestTypes.AUTHOR_DETAIL_WITH_PAPERS:
              return this.#handleAuthorDetailStart(startingPayload);
            case Constants.requestTypes.GQL__AUTHOR_RECOMMENDATIONS:
              return this.#handleAuthorRecsStart();
          }
          break;
        }
        case Constants.actions.API_REQUEST_COMPLETE: {
          const competePayload = payload as ApiResponse;
          switch (competePayload.requestType) {
            case Constants.requestTypes.AUTHOR_DETAIL:
            case Constants.requestTypes.AUTHOR_DETAIL_WITH_PAPERS:
              return this.#handleAuthorDetailComplete(competePayload);
            case Constants.requestTypes.GQL__AUTHOR_RECOMMENDATIONS:
              return this.#handleAuthorRecsComplete(competePayload);
          }
          break;
        }
      }
    });
  }

  #handleRouting(payload: RoutingPayload): void {
    if (payload.routeName && AHP_ROUTE_NAMES.includes(payload.routeName)) {
      const [, routingAuthorIdStr] = payload.pathParams || [];
      const loadedAuthorIdStr = this.#authorDetail?.author?.id;
      if (loadedAuthorIdStr && loadedAuthorIdStr !== routingAuthorIdStr) {
        // Author is changing
        this.#reset();
        this.emitChange();
      }
    } else if (this.#authorDetail) {
      this.#reset();
      this.emitChange();
    }
  }

  #handleAuthorDetailStart(payload: ApiRequestStartingPayload<any>): void {
    const authorIdStr = payload.pathParams.authorId?.toString();
    if (!authorIdStr) {
      softError(
        'authorStore',
        `authorDetails request started without an authorId [authorId=${JSON.stringify(
          authorIdStr
        )}]`
      );
      return;
    }

    this.#state = StoreState.LOADING;
    this.#loadingAuthorId = authorIdStr;
    this.emitChange();
  }

  #handleAuthorRecsStart(): void {
    this.#recommendationsState = StoreState.LOADING;
    this.#recommendations = {
      coauthor: null,
    };
    this.emitChange();
  }

  #handleAuthorDetailComplete(payload: ApiResponse<AuthorDetailResponseBody>): void {
    this.#state = StoreState.LOADED;
    this.#loadingAuthorId = null;
    this.#authorDetail = getAuthorDetailsFromJS(payload.resultData);
    this.emitChange();
  }

  #handleAuthorRecsComplete(
    payload: ApiResponse<GraphQLResponse<FetchAuthorRecommendationsResponse>>
  ): void {
    const payloadAuthorId = payload.resultData.data?.author?.id?.toString();
    if (hasValue(this.#loadingAuthorId) && this.#loadingAuthorId != payloadAuthorId) {
      return;
    }
    this.#recommendationsState = StoreState.LOADED;
    const coauthor = Immutable.List(payload.resultData.data?.author?.coAuthors.edges || [])
      .map(edge => getAuthorFromGraphQL(edge.node.coAuthor))
      .map(author => AuthorRecommendationRecordFactory({ author, isCoauthor: false }));
    this.#recommendations = { coauthor };
    this.emitChange();
  }

  #reset(): void {
    this.#state = StoreState.UNINITIALIZED;
    this.#authorDetail = null;
    this.#loadingAuthorId = null;
    this.#recommendationsState = StoreState.UNINITIALIZED;
    this.#recommendations = {
      coauthor: null,
    };
  }

  isNewOrCanonicalAuthor(id: string | number, slug?: Nullable<string>): boolean {
    if (!this.#authorDetail) {
      return false;
    }
    const authorId = this.#authorDetail?.author?.id;
    const authorSlug = this.#authorDetail?.author?.slug || '';
    // We need to encode this so the comparison below works for authors with
    // special characters in their names.
    const encodedSlug = slug ? encodeURIComponent(slug) : null;
    const strId = id.toString();
    return !!(
      strId !== authorId ||
      (encodedSlug && encodedSlug !== encodeURIComponent(authorSlug))
    );
  }

  isUninitialized(): boolean {
    return this.#state === StoreState.UNINITIALIZED;
  }

  isLoading(): boolean {
    return this.isUninitialized() || this.#state === StoreState.LOADING;
  }

  isUninitializedRecommendations(): boolean {
    return this.#state === StoreState.UNINITIALIZED;
  }

  isLoadingRecommendations(): boolean {
    return (
      this.isUninitializedRecommendations() || this.#recommendationsState === StoreState.LOADING
    );
  }

  getAuthorDetails(): Nullable<AuthorDetailsRecord> {
    return this.#authorDetail;
  }

  getRecommendations(
    type: 'coauthor' = 'coauthor'
  ): Nullable<Immutable.List<AuthorRecommendationRecord>> {
    return this.#recommendations[type] || null;
  }
}
