import MultiselectChip from './MultiselectChip';

import { mkOnClickKeyDown } from '@/utils/a11y-utils';
import { setState } from '@/utils/promise-utils';
import Icon from '@/components/shared/icon/Icon';

import React from 'react';

export default class MultiselectDropdown extends React.PureComponent {
  static defaultProps = {
    isRequired: false,
  };

  constructor(props, ...args) {
    super(props, ...args);

    this.state = {
      selectedOptions: [...this.props.selected],
    };
  }

  selectOption = async selected => {
    const selectedOptions = [...this.state.selectedOptions, selected];
    const onChange = this.props.onChange;

    if (this.state.selectedOptions.includes(selected)) {
      return;
    }

    await setState(this, {
      selectedOptions: selectedOptions,
    });

    onChange(selectedOptions);
  };

  onClickRemove = async removedItem => {
    const selected = this.state.selectedOptions.filter(item => {
      return item !== removedItem;
    });

    await setState(this, {
      selectedOptions: selected,
    });

    this.props.onChange(this.state.selectedOptions);
  };

  // TODO: Rewrite this following aria practices: https://www.w3.org/TR/wai-aria-practices-1.1/examples/listbox/listbox-collapsible.html
  // If you remove the tabIndex=0 then you can't open the dropdown anymore, need to relook at this
  render() {
    const { label, isRequired, options } = this.props;

    const { selectedOptions } = this.state;

    return (
      <div className="standard-form">
        {label && (
          <label className="label">
            {label}
            {!isRequired && <span className="optional-label">Optional</span>}
          </label>
        )}
        <div
          className="multiselect__input select-dropdown-arrow"
          tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
        >
          {selectedOptions.map(option => (
            <MultiselectChip
              name={option}
              key={option}
              onClick={() => this.onClickRemove(option)}
            />
          ))}
          <Icon icon="disclosure" height="10" width="10" className="dropdown-arrow-icon" />
        </div>
        <div className="multiselect__dropdown" role="listbox">
          {options.map(option => (
            <div
              className="multiselect__option"
              /* Bad pattern as we lose out on performance but we do this inline in order to get
               access to the options parameter */
              {...mkOnClickKeyDown({
                /* $FlowFixMe: selectOption is an aynschronous function returning Promise<void> and
                the functions expects it to just be void */
                onClick: () => this.selectOption(option),
              })}
              key={option}
              role="option"
              aria-selected={selectedOptions.includes(option)}>
              {option}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
