import { AuthorAliasFromJS, getAuthorAliasFromJS } from './author/AuthorAlias';
import { CitationRecord } from './Citation';
import {
  getHighlightedFieldFromJS,
  HighlightedFieldFromJS,
  HighlightedFieldRecord,
  HighlightedFieldRecordFactory,
} from './HighlightedField';
import { HighlightedAuthorRecord, HighlightedAuthorRecordFactory } from './HighlightedAuthor';
import { PaperBadgeProps, PaperBadgeRecord, PaperBadgeRecordFactory } from './PaperBadge';
import { PaperRecord } from './Paper';
import { TldrRecord } from './Tldr';

import { DEPRECATED__FlowOptional, Nullable } from '@/utils/types';
import { isBrowser } from '@/utils/env';
import logger from '@/logger';

import Immutable from 'immutable';
import moment from 'moment';

/** This model is a trimmed down version of the Paper model. This is used for features that require
 * a limited amount of information compared to the full SERP, e.g., Author Suggestions.
 */
export type PaperLiteFromJS = {
  id: string;
  title: string;
  slug: string;
  authors: AuthorAliasFromJS[];
  venue: string;
  venueId: Nullable<string>;
  year: string;
  paperAbstract: HighlightedFieldFromJS;
  fieldsOfStudy: string[];
  pubDate: DEPRECATED__FlowOptional<string>;
  pubUpdateDate: DEPRECATED__FlowOptional<string>;
  badges: PaperBadgeProps[];
};

export type Props = {
  id: string;
  title: string;
  slug: string;
  authors: Immutable.List<HighlightedAuthorRecord>;
  venue: string;
  venueId: Nullable<string>;
  year: string;
  paperAbstract: HighlightedFieldRecord;
  fieldsOfStudy: Immutable.List<string>;
  pubDate: Nullable<string>;
  pubUpdateDate: Nullable<string>;
  badges: Immutable.List<PaperBadgeRecord>;
  tldr: Nullable<TldrRecord>;
};

const defaultProperties: Props = {
  id: '',
  title: '',
  slug: '',
  authors: Immutable.List(),
  venue: '',
  venueId: null,
  year: '',
  paperAbstract: HighlightedFieldRecordFactory(),
  fieldsOfStudy: Immutable.List(),
  pubDate: null,
  pubUpdateDate: null,
  badges: Immutable.List(),
  tldr: null,
};

export const PAPER_LITE_RECORD_NAME = 'PaperLite';

export const PaperLiteRecordFactory = Immutable.Record(defaultProperties, PAPER_LITE_RECORD_NAME);
export type PaperLiteRecord = Immutable.RecordOf<Props>;

export function getPaperLiteFromJS(args: PaperLiteFromJS): PaperLiteRecord {
  const { authors, badges, paperAbstract, fieldsOfStudy, pubDate, pubUpdateDate, venueId } = args;

  return PaperLiteRecordFactory({
    ...args,
    authors: Immutable.List(authors.map(toCanonicalAuthor)),
    fieldsOfStudy: Immutable.List(fieldsOfStudy),
    paperAbstract: paperAbstract
      ? getHighlightedFieldFromJS(paperAbstract)
      : HighlightedFieldRecordFactory(),
    pubDate: pubDate ? moment(pubDate).format('D MMMM YYYY') : null,
    pubUpdateDate: pubUpdateDate ? moment(pubUpdateDate).format('D MMMM YYYY') : null,
    badges: Immutable.List(badges ? badges.map(PaperBadgeRecordFactory) : []),
    venueId: venueId,
  });
}

export function getPaperLiteFromPaper(args: PaperRecord): PaperLiteRecord {
  const {
    id,
    title,
    slug,
    authors,
    venue,
    venueId,
    year,
    paperAbstract,
    fieldsOfStudy,
    pubDate,
    badges,
  } = args;
  // These are (or should be) HighlightedFields in the PaperRecord, so extract the text for the PaperLite.
  const yearText = year ? year.text : '';
  const pubDateText = pubDate ? pubDate.text : null;
  return PaperLiteRecordFactory({
    title: title.text,
    id,
    slug,
    authors,
    venue: venue.text,
    venueId: venueId,
    year: yearText,
    paperAbstract,
    fieldsOfStudy,
    pubDate: pubDateText,
    badges,
  });
}

export function getPaperLiteFromCitation(args: CitationRecord): PaperLiteRecord {
  const {
    id,
    title,
    slug,
    authors,
    venue,
    venueId,
    year,
    fieldsOfStudy,
    badges,
    paperAbstract,
    tldr,
  } = args;
  if (isBrowser()) {
    // Only check for these issues in the browser, so the DD logs don't fill up
    if (!id) {
      logger.error('Citation without an id cannot be converted into a PaperLite');
    }
    if (!year) {
      logger.error('Citation without a year cannot be converted into a PaperLite');
    }
  }
  const props = {
    id: id || '',
    title: title.text,
    slug,
    authors,
    venue: venue.text,
    venueId: venueId,
    year: year ? year.toString() : '',
    fieldsOfStudy,
    badges,
    paperAbstract: paperAbstract,
    pubDate: null,
    pubUpdateDate: null,
    tldr: tldr,
  };
  return PaperLiteRecordFactory(props);
}

const toCanonicalAuthor = (author: AuthorAliasFromJS): HighlightedAuthorRecord =>
  HighlightedAuthorRecordFactory({
    alias: getAuthorAliasFromJS(author),
    highlightedField: getHighlightedFieldFromJS({
      text: author.name || '',
      fragments: [],
    }),
  });
