import { SHARE_DIRECT_LINK_UTM_SOURCE } from '@/components/shared/share/util';

const LIBRARY_FOLDER = 'LIBRARY_FOLDER_ID_';

export function makeFolderListId(libraryFolderId: number): string {
  return LIBRARY_FOLDER + libraryFolderId;
}

export function buildShareFolderLink(
  folderId: number,
  utmSource: string = SHARE_DIRECT_LINK_UTM_SOURCE
): string {
  const origin =
    typeof window !== 'undefined' ? window.location.origin : 'https://www.semanticscholar.org';
  return `${origin}/shared/library/folder/${folderId}?utm_source=${utmSource}`;
}
