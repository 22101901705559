import MockPaper, { MockPaperLiteFromJs } from '../../../../../test/utils/MockPaper';

import * as util from '@/util';
import { getHighlightedFieldFromJS, HighlightedFieldRecord } from '@/models/HighlightedField';
import { PaperishRecord } from '@/models/Paper';
import { ReactNodeish } from '@/utils/types';
import HighlightedField from '@/components/shared/common/HighlightedField';

import classnames from 'classnames';
import moment from 'moment';
import React from 'react';

export default function CLPaperPubDates({
  paper,
  className,
  heapId,
}: {
  paper: PaperishRecord;
  className?: string;
  heapId?: string;
}): ReactNodeish {
  const { pubDate, year } = paper;

  const isValidYear =
    !!year &&
    ((typeof year !== 'string' && year.text.length > 0) || typeof year === 'string') &&
    util.isValidYear(year);
  const canRenderPubDate =
    !!pubDate &&
    ((typeof pubDate !== 'string' && pubDate.text.length > 0) ||
      (typeof pubDate === 'string' && moment(pubDate).isValid()));
  // Year-only is the fallback if the full date cannot be rendered
  const canRenderYear = !canRenderPubDate && isValidYear;

  if (!canRenderPubDate && !canRenderYear) {
    return null;
  }

  let renderedValue;
  if (canRenderPubDate && typeof pubDate === 'string') {
    renderedValue = <span data-heap-id={heapId}>{pubDate}</span>;
  } else if (typeof year === 'string') {
    renderedValue = <span data-heap-id={heapId}>{year}</span>;
  } else {
    const highlightedPubDate = pubDate as HighlightedFieldRecord;
    renderedValue = (
      <HighlightedField field={canRenderPubDate ? highlightedPubDate : year} heapId={heapId} />
    );
  }

  return <span className={classnames('cl-paper-pubdates', className)}>{renderedValue}</span>;
}

// TODO(#21359): Split this into a separate file
const PAPER_YEAR = MockPaper({
  title: getHighlightedFieldFromJS({ text: 'A Paper with Only a Year' }),
  year: getHighlightedFieldFromJS({ text: '2001' }),
  pubDate: getHighlightedFieldFromJS(),
});
const PAPER_PUBDATE = MockPaper({
  title: getHighlightedFieldFromJS({ text: 'A Paper with a Year and PubDate' }),
  year: getHighlightedFieldFromJS({ text: '2001' }),
  pubDate: getHighlightedFieldFromJS({ text: '5 May 2001' }),
});
const PAPER_YEAR_HIGHLIGHT = MockPaper({
  title: getHighlightedFieldFromJS({ text: 'A Paper with a Year and Highlighting' }),
  year: getHighlightedFieldFromJS({ text: '2001', fragments: [{ start: 0, end: 4 }] }),
  pubDate: getHighlightedFieldFromJS(),
});
const PAPER_PUBDATE_HIGHLIGHT = MockPaper({
  title: getHighlightedFieldFromJS({ text: 'A Paper with a Year, PubDate, and Highlighting' }),
  year: getHighlightedFieldFromJS({ text: '2001', fragments: [{ start: 0, end: 4 }] }),
  pubDate: getHighlightedFieldFromJS({ text: '5 May 2001', fragments: [{ start: 6, end: 10 }] }),
});
const PAPERLITE_YEAR = MockPaperLiteFromJs({
  title: 'A PaperLite with Only a Year',
  year: '2001',
});
const PAPERLITE_PUBDATE = MockPaperLiteFromJs({
  title: 'A PaperLite with a Year and PubDate',
  year: '2001',
  pubDate: '2001-05-05',
});

export const exampleConfig = {
  getComponent: () => CLPaperPubDates,
  fields: [
    {
      name: 'className',
      desc: 'HTML classes to be added to the component',
      value: {
        type: 'text',
        default: '',
      },
    },
  ],

  examples: [
    {
      title: 'Paper with Year',
      desc: 'Data provided by a PaperRecord with just a year defined',
      props: {
        paper: PAPER_YEAR,
      },
      render: comp => <div style={{ width: '300px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'Paper with Pub Date',
      desc: 'Data provided by a PaperRecord with year and publication date defined',
      props: {
        paper: PAPER_PUBDATE,
      },
      render: comp => <div style={{ width: '300px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'Paper with Year and Highlights',
      desc: 'Data provided by a PaperRecord with just a year defined, with text fragment for highlighting',
      props: {
        paper: PAPER_YEAR_HIGHLIGHT,
      },
      render: comp => <div style={{ width: '300px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'Paper with Pub Date and Highlights',
      desc: 'Data provided by a PaperRecord with year and publication date defined, with text fragment for highlighting',
      props: {
        paper: PAPER_PUBDATE_HIGHLIGHT,
      },
      render: comp => <div style={{ width: '300px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'PaperLite with Year',
      desc: 'Data provided by a PaperLiteRecord with just a year defined',
      props: {
        paper: PAPERLITE_YEAR,
      },
      render: comp => <div style={{ width: '300px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'PaperLite with Pub Date',
      desc: 'Data provided by a PaperLiteRecord with year and publication date defined',
      props: {
        paper: PAPERLITE_PUBDATE,
      },
      render: comp => <div style={{ width: '300px', padding: '16px' }}>{comp}</div>,
    },
  ],

  events: {},
};
