import { YearRangeBucketRecord, YearRangeBucketRecordFactory } from './YearRangeBucket';

import Immutable from 'immutable';

type Props = {
  count: number;
  year: number;
};

export type YearBucketFromFacet = {
  count: number;
  key: number;
};

const defaultProps: Props = {
  count: 0,
  year: 0,
};

export const YearBucketRecordFactory = Immutable.Record<Props>(defaultProps);
export type YearBucketRecord = Immutable.RecordOf<Props>;

export function getYearBucketFromFacet({ count, key }: YearBucketFromFacet): YearBucketRecord {
  return new YearBucketRecordFactory({ count, year: key });
}

export function flattenChunkToRange(
  chunk: Immutable.List<YearBucketRecord>
): YearRangeBucketRecord {
  return new YearRangeBucketRecordFactory({
    startKey: chunk.first()?.year,
    endKey: chunk.last()?.year,
    count: chunk.reduce((sum, year) => sum + year.count, 0),
  });
}

/**
 * Returns the provided list of YearBuckets into YearRangeBuckets of the provided stepSize.
 * If absoluteStartYear is specified, the returned collection will be padded such that the end
 * and start years difference from that value are evenly divisible by the provided step.
 *
 * @param {Immutable.List[YearBucketRecordFactory]} years
 * @param {number} [stepSize=1]
 * @param {number} [absoluteStartYear=undefined]
 *
 * @returns {Immutable.List[YearRangeBucket]}
 */
export function yearsToYearRanges(
  years: Immutable.List<YearBucketRecord>,
  stepSize: number = 1,
  absoluteStartYear?: number
): Immutable.List<YearRangeBucketRecord> {
  if (years.size > 0) {
    let beginningSlop = 0;
    let endSlop = 0;

    // If execution gets here, years is not empty, so first() and last() will return something.
    const firstYear = years.first()?.year as number;
    const lastYear = years.last()?.year as number;

    if (absoluteStartYear !== undefined) {
      beginningSlop = (firstYear - absoluteStartYear) % stepSize;

      const adjustedStartYear = firstYear - beginningSlop;
      endSlop =
        adjustedStartYear +
        stepSize * Math.ceil((lastYear - adjustedStartYear) / stepSize) -
        lastYear;
    } else {
      beginningSlop = getSlop(years, stepSize);
    }

    const toPrepend = Immutable.Range(firstYear - beginningSlop, firstYear).map(i => {
      return new YearBucketRecordFactory({
        count: 0,
        year: i,
      });
    });

    const toAppend =
      endSlop > 0
        ? Immutable.Range(lastYear + 1, lastYear + 1 + endSlop)
            .map(i => {
              return new YearBucketRecordFactory({
                count: 0,
                year: i,
              });
            })
            .toList()
        : Immutable.List<YearBucketRecord>();

    const chunked = toPrepend
      .concat(years)
      .concat(toAppend)
      .reduce<Immutable.List<Immutable.List<YearBucketRecord>>>((chunked, year) => {
        if (chunked.size > 0 && chunked.last<Immutable.List<YearBucketRecord>>().size < stepSize) {
          return chunked.pop().push(chunked.last<Immutable.List<YearBucketRecord>>().push(year));
        } else {
          return chunked.push(Immutable.List([year]));
        }
      }, Immutable.List());

    return chunked.map(flattenChunkToRange);
  } else {
    return Immutable.List();
  }
}

function getSlop(years: Immutable.List<YearBucketRecord>, stepSize: number) {
  const yearRange =
    years.last(YearBucketRecordFactory()).year - years.first(YearBucketRecordFactory()).year;
  return (yearRange + 1) % stepSize;
}
