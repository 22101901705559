import CLPaperLibraryButton from './buttons/CLPaperLibraryButton';

import { AnalyticsContextHelper } from '@/components/library/paper/CLPaperAnalytics';
import { getAddLibraryEntryBulkRequestFromJS } from '@/models/library/AddLibraryEntryBulkRequest';
import { getPaperTitleStr } from '@/utils/paper-util';
import { getString } from '@/content/i18n';
import { heapSaveToLibrary } from '@/analytics/attributes/paperObject';
import { Library } from '@/models/library/LibraryEntrySourceType';
import { LOGIN_LOCATION } from '@/constants/LoginMethods';
import { showOrganizePapersShelf, showSaveToLibraryShelf } from '@/actions/ShelfActionCreators';
import Api from '@/api/Api';
import AuthStore from '@/stores/AuthStore';
import LibraryFolderStore from '@/stores/LibraryFolderStore';
import logger from '@/logger';
import MessageStore from '@/stores/MessageStore';
import S2Dispatcher from '@/utils/S2Dispatcher';
import softError from '@/utils/softError';

import classnames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

export default class CLPaperActionLibraryFolder extends React.PureComponent {
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    authStore: PropTypes.instanceOf(AuthStore).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    libraryFolderStore: PropTypes.instanceOf(LibraryFolderStore).isRequired,
    messageStore: PropTypes.instanceOf(MessageStore).isRequired,
  };

  _onClickLibraryAnalytics;

  constructor(...args) {
    super(...args);

    const { libraryFolderStore } = this.context;

    this.state = {
      ...this.getStateFromLibraryFolderStore(),
    };

    libraryFolderStore.registerComponent(this, () => {
      this.setState(this.getStateFromLibraryFolderStore());
    });
  }

  // TODO #24698: Update this to use LibraryFolderStore
  getStateFromLibraryFolderStore = () => {
    const { libraryFolderStore } = this.context;
    const { paper } = this.props;

    return {
      isInLibrary: libraryFolderStore.isPaperInLibrary(paper.id),
    };
  };

  setAnalyticsCallbacks = ({ onClickLibrary }) => {
    this._onClickLibraryAnalytics = onClickLibrary;
  };

  openSaveToLibraryShelf = () => {
    const { paper, libraryProps } = this.props;
    const { api, messageStore, dispatcher } = this.context;

    // Save to library in the background
    const addEntryReq = getAddLibraryEntryBulkRequestFromJS({
      paperId: paper.id,
      paperTitle: getPaperTitleStr(paper),
      sourceType: Library,
    });

    api.createLibraryEntryBulk(addEntryReq).catch(error => {
      messageStore.addError(getString(_ => _.library.saveToLibraryShelf.errorMessage));
      logger.error(error);
    });

    const selectedFolders =
      libraryProps && libraryProps.selectedFolders ? libraryProps.selectedFolders : Immutable.Set();

    dispatcher.dispatch(
      showSaveToLibraryShelf({
        paper,
        selectedFolders,
      })
    );
  };

  openOrganizePapersShelf = () => {
    const { paper } = this.props;

    this.context.dispatcher.dispatch(
      showOrganizePapersShelf({
        paperId: paper.id,
        paperTitle: getPaperTitleStr(paper),
      })
    );
  };

  onClickSaveToLibrary = () => {
    const { api, authStore, dispatcher, libraryFolderStore, messageStore } = this.context;
    const { onSaveClick, paper } = this.props;

    if (this._onClickLibraryAnalytics) {
      this._onClickLibraryAnalytics({
        action: 'save',
      });
    }

    if (typeof onSaveClick === 'function') {
      onSaveClick();
    }

    authStore
      .ensureLogin({ dispatcher: dispatcher, location: LOGIN_LOCATION.library })
      .then(async () => {
        if (libraryFolderStore.isUninitialized()) {
          await api.getLibraryFolders();
        }

        // We grab the isInLibrary state from the store itself as timing issues from login may
        // not update value in time when shelf opens
        if (this.getStateFromLibraryFolderStore().isInLibrary) {
          this.openOrganizePapersShelf();
        } else {
          this.openSaveToLibraryShelf();
        }
      })
      .catch(error => {
        softError('library', `failed to open Library shelf for paperId="${paper.id}"]`, error);

        const header = getString(_ => _.library.message.error.header);
        const body = getString(_ => _.library.message.error.body);
        messageStore.addError(body, header);
      });
  };

  render() {
    const { isInLibrary } = this.state;
    const { className, heapProps, testId, icon, label } = this.props;
    const labelForRendering = isInLibrary ? getString(_ => _.paper.action.goToLibrary) : label;

    return (
      <AnalyticsContextHelper onUpdate={this.setAnalyticsCallbacks}>
        <CLPaperLibraryButton
          onClick={this.onClickSaveToLibrary}
          isInLibrary={isInLibrary}
          shouldLinkToLibrary={false}
          heapProps={heapProps || heapSaveToLibrary()}
          testId={testId}
          className={classnames(
            'cl-paper-action__button__library-button',
            {
              'cl-paper-action__button--blue-outline': !isInLibrary,
            },
            className
          )}
          icon={icon}
          label={labelForRendering}
        />
      </AnalyticsContextHelper>
    );
  }
}
