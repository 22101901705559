/* Constants for Alerts Page */
import { ValueOf } from '@/utils/types';

import Immutable from 'immutable';

export type AlertAction = ValueOf<typeof ALERT_ACTION>;
export type AlertType = keyof typeof alertTypes;
export type AlertQueryType = keyof typeof QUERY_TYPE;
export type AlertStatus = keyof typeof STATUS;

const alertTypes = {
  SEARCH: 'SEARCH',
  AUTHOR: 'AUTHOR',
  PAPER: 'PAPER',
  ENTITY: 'ENTITY',
  FEED: 'FEED',
  FOLDER: 'FOLDER',
} as const;
export const ALERT_TYPE = Immutable.OrderedMap(alertTypes);

// Matches QueryType class found in UserAlert.scala
export const QUERY_TYPE = {
  SEARCH_RESULTS: 'SEARCH_RESULTS',
  AUTHOR_PAPER: 'AUTHOR_PAPER',
  AUTHOR_CITATION: 'AUTHOR_CITATION',
  PAPER_CITATION: 'PAPER_CITATION',
  ENTITY_PAPER: 'ENTITY_PAPER',
  LIBRARY_FOLDER: 'LIBRARY_FOLDER',
} as const;

export const ALERT_ACTION = {
  ACTIVATE: 'activate',
  CREATE: 'create',
  DISABLED: 'disable',
} as const;

export const QUERY_TYPES = Immutable.OrderedMap(QUERY_TYPE);

// Matches Status class found in UserAlert.scala
export const STATUS = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
  DELETED: 'DELETED',
} as const;

export const QUERY_TYPE_TO_ALERT_TYPE = {
  SEARCH_RESULTS: 'SEARCH',
  AUTHOR_PAPER: 'AUTHOR',
  AUTHOR_CITATION: 'AUTHOR',
  PAPER_CITATION: 'PAPER',
  ENTITY_PAPER: 'ENTITY',
  LIBRARY_FOLDER: 'FOLDER',
} as const;

export const ApiErrors = {
  InvalidUserAlertEmailVerificationCode: {
    CODE: 'invalidUserAlertEmailVerificationCode',
    SubCodes: {
      ALREADY_VERIFIED: 'ALREADY_VERIFIED',
      TOKEN_EXPIRED: 'TOKEN_EXPIRED',
    },
  },
  VerifyUserEmailCode: {
    CODE: 'verifyUserEmailFailed',
    SubCodes: {
      ALREADY_VERIFIED: 'ALREADY_VERIFIED',
      TOKEN_EXPIRED: 'TOKEN_EXPIRED',
    },
  },
} as const;
