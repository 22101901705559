import {
  getProfilePictureFromJS,
  ProfilePictureFromJS,
  ProfilePictureRecord,
} from './ProfilePicture';

import { getRoleValue, RoleValue, UNSUPPORTED } from '@/constants/Role';
import { ModerationStatusValue, optModerationStatusValue } from '@/constants/ModerationStatus';

import Immutable from 'immutable';

import type { DEPRECATED__FlowOptional, Nullable } from '@/utils/types';

export type UserFromJS = {
  id: number;
  alertEmail: DEPRECATED__FlowOptional<string>;
  alertEmailIsVerified: DEPRECATED__FlowOptional<boolean>;
  claimedAuthorId: DEPRECATED__FlowOptional<number>;
  claimedAuthorModerationStatus: DEPRECATED__FlowOptional<{
    value?: DEPRECATED__FlowOptional<string>;
  }>;
  email: DEPRECATED__FlowOptional<string>;
  currentOccupation: DEPRECATED__FlowOptional<{ value: string }>;
  fieldsOfStudy: DEPRECATED__FlowOptional<{ id: string }[]>;
  hasCompletedDemographics: boolean;
  hash: number;
  hubSpotContactId: DEPRECATED__FlowOptional<number>;
  alertFrequency: DEPRECATED__FlowOptional<{ id?: DEPRECATED__FlowOptional<string> }>;
  authSource: DEPRECATED__FlowOptional<{ value?: DEPRECATED__FlowOptional<string> }>;
  fullName: DEPRECATED__FlowOptional<string>;
  firstName: DEPRECATED__FlowOptional<string>;
  lastName: DEPRECATED__FlowOptional<string>;
  roles: { roleName?: DEPRECATED__FlowOptional<{ value?: DEPRECATED__FlowOptional<string> }> }[];
  profilePicture: Nullable<ProfilePictureFromJS>;
};

type Props = {
  id: number;
  alertEmail: Nullable<string>;
  alertEmailIsVerified: Nullable<boolean>;
  claimedAuthorId: Nullable<number>;
  claimedAuthorModerationStatus: Nullable<ModerationStatusValue>;
  email: Nullable<string>;
  currentRole: Nullable<string>;
  fieldsOfStudy: Immutable.List<string>;
  hasCompletedDemographics: boolean;
  hash: number;
  hubSpotContactId: Nullable<number>;
  alertFrequency: Nullable<string>;
  authSource: Nullable<string>;
  fullName: Nullable<string>;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  roles: Immutable.List<RoleValue>;
  profilePicture: Nullable<ProfilePictureRecord>;
};

const defaultProps: Props = {
  id: -1,
  alertEmail: '',
  alertEmailIsVerified: null,
  claimedAuthorId: null,
  claimedAuthorModerationStatus: null,
  email: null,
  currentRole: null,
  fieldsOfStudy: Immutable.List(),
  hasCompletedDemographics: false,
  hash: -1,
  hubSpotContactId: null,
  alertFrequency: null,
  authSource: null,
  fullName: null,
  firstName: null,
  lastName: null,
  roles: Immutable.List(),
  profilePicture: null,
};

export const UserRecordFactory = Immutable.Record<Props>(defaultProps);
export type UserRecord = Immutable.RecordOf<Props>;

export function getUserRecordFromJS(args?: UserFromJS): UserRecord {
  return UserRecordFactory({
    ...args,
    alertFrequency: args?.alertFrequency?.id || defaultProps.alertFrequency,
    authSource: args?.authSource?.value || defaultProps.authSource,
    claimedAuthorModerationStatus: optModerationStatusValue(
      args?.claimedAuthorModerationStatus?.value
    ),
    currentRole: args?.currentOccupation?.value || defaultProps.currentRole,
    fieldsOfStudy: Immutable.List(args?.fieldsOfStudy || [])
      .map(_ => _.id)
      .filter(fos => !!fos),
    roles: Immutable.List(
      (args?.roles || [])
        .map(_ => _.roleName?.value)
        .filter(roleName => !!roleName && roleName !== UNSUPPORTED)
        .map(getRoleValue)
    ),
    profilePicture: args?.profilePicture ? getProfilePictureFromJS(args.profilePicture) : null,
  });
}
