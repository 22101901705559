import { AlertAction } from '@/constants/Alert';
import { BadgeId } from '@/models/PaperBadge';
import { mkHeapAttributes } from '@/analytics/heapUtils';

export const heapAuthorListItem = (props: { 'author-id': string }) =>
  mkHeapAttributes({
    id: 'heap_author_list_item',
    ...props,
  });

export const heapAnnotateRecommendationNotRelevant = () =>
  mkHeapAttributes({
    id: 'annotate_recommendation_not_relevant',
  });

export const heapAnnotateRecommendationRelevant = () =>
  mkHeapAttributes({
    id: 'annotate_recommendation_relevant',
  });

export const heapCitationTabStyleLink = (props: { 'citation-style': string }) =>
  mkHeapAttributes({
    id: 'citation_text_link',
    ...props,
  });

export const heapCitationDownloadLink = (props: { 'citation-style': string }) =>
  mkHeapAttributes({
    id: 'citation_download_link',
    ...props,
  });

export const heapCitationCopy = (props: { 'citation-style': string }) =>
  mkHeapAttributes({
    id: 'citation_copy',
    ...props,
  });

export const heapNormalView = () =>
  mkHeapAttributes({
    id: 'normal_view',
  });

export const heapDenseView = () =>
  mkHeapAttributes({
    id: 'dense_view',
  });

export const heapPaperTitle = () =>
  mkHeapAttributes({
    id: 'title_link',
  });

export const heapAlertAction = (action: AlertAction) =>
  mkHeapAttributes({
    id: `${action}_alert_button`,
  });

export const heapPaperBadge = (badge: BadgeId) =>
  mkHeapAttributes({
    id: `paper_badge_${badge.toLocaleLowerCase()}`,
  });

export const heapSaveToLibrary = () =>
  mkHeapAttributes({
    id: 'save_to_library',
  });

export const heapVenueLink = () =>
  mkHeapAttributes({
    id: 'venue_link',
  });

export const heapVenueSerpLinkClick = () =>
  mkHeapAttributes({
    id: 'venue_serp_link_click',
  });
