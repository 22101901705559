import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import S2Redirect from '@/models/redirects/S2Redirect';

import idx from 'idx';
import React from 'react';

export default class AuthorClaimRoute extends React.Component {
  static getPageTitle(context) {
    const authorDetail = context.authorStore.getAuthorDetails();
    return authorDetail
      ? getString(_ => _.authorClaim.pageTitle.withName, authorDetail.author.name)
      : getString(_ => _.authorClaim.pageTitle.withoutName);
  }

  static requiresAuthentication = () => true;

  static getRobotDirectives(robots) {
    robots.shouldFollow = false;
    robots.shouldIndex = false;
  }

  static async willRouteTo({ authStore, api }, routerState) {
    const authorIdParam = idx(routerState, _ => _.params.authorId);
    const slug = idx(routerState, _ => _.params.slug);
    const authorId = parseFloat(authorIdParam);
    const payload = await api.fetchAuthorDetail(authorId, slug, true);
    // $FlowFixMe: idx will handle the fact that resultData might not have author
    const isClaimedByAuthor = idx(payload, _ => _.resultData.author.isClaimedByAuthor) || false;
    if (isClaimedByAuthor || authStore.getClaimedAuthorId()) {
      // Don't allow if author is claimed, or if user already has a claim
      return new S2Redirect({
        routeName: 'AUTHOR_PROFILE',
        params: { authorId, slug },
      });
    }
    switch (idx(payload, _ => _.resultData.resultType)) {
      case 'AUTHOR_RESULT': {
        return payload;
      }
      case 'AUTHOR_RESULT_REDIRECT': {
        const params = idx(routerState, _ => _.params);
        const query = idx(routerState, _ => _.query);
        // $FlowFixMe: idx will handle the fact that resultData might not have canonical* fields
        const canonicalId = idx(payload, _ => _.resultData.canonicalId);
        // $FlowFixMe: idx will handle the fact that resultData might not have canonical* fields
        const canonicalSlug = idx(payload, _ => _.resultData.canonicalSlug);
        return new S2Redirect({
          query,
          params: {
            params,
            authorId: canonicalId,
            slug: canonicalSlug,
          },
          routeName: 'AUTHOR_CLAIM',
          replace: true,
          status: 301,
        });
      }
    }
  }

  render() {
    return (
      <AsyncLoadedPage
        footer={false}
        load={{
          importer: () =>
            import(/* webpackChunkName: "desktop-AuthorClaimPage" */ './AuthorClaimPage'),
          chunkName: 'desktop-AuthorClaimPage',
          moduleId: require.resolveWeak('./AuthorClaimPage'),
        }}
      />
    );
  }
}
