import Immutable from 'immutable';
import invariant from 'invariant';

export type NotRelevantTriggerContextProps = {
  paperId: string;
  folderId: number;
};

const defaultProps: NotRelevantTriggerContextProps = {
  paperId: '',
  folderId: 0,
};

export const NotRelevantTriggerContextFactory =
  Immutable.Record<NotRelevantTriggerContextProps>(defaultProps);
export type NotRelevantTriggerContext = Immutable.RecordOf<NotRelevantTriggerContextProps>;

export function getNotRelevantTriggerContextFromJS(
  rawContext?: NotRelevantTriggerContextProps
): NotRelevantTriggerContext {
  if (!rawContext || typeof rawContext !== 'object') {
    throw new Error('cannot validate a NotRelevantTriggerContext without a context');
  }

  const { paperId, folderId } = rawContext;
  invariant(
    typeof paperId === 'string',
    `context 'paperId' must be a string [paperId=%s]`,
    paperId
  );
  invariant(
    typeof folderId === 'number',
    `context 'folderId' must be a number [folderId=%s]`,
    folderId
  );

  return NotRelevantTriggerContextFactory({
    paperId,
    folderId,
  });
}

export function encodeNotRelevantTriggerContext(context: NotRelevantTriggerContext): string {
  return JSON.stringify(context);
}
