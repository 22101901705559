import { ApiResponse, AuthorDetailOrRedirectResponseBody } from '@/api/ApiResponse';
import { ChildRoutesContext } from '@/router/ChildRoutes';
import {
  fetchAuthorInfluenceStats,
  fetchAuthorRecommendations,
  fetchAuthorStats,
} from '@/actions/AuthorActionCreators';
import { getAuthorPageMetaDescription } from '@/models/author/AuthorDetails';
import { getString } from '@/content/i18n';
import { makePath } from '@/router/Routes';
import { Nullable } from '@/utils/types';
import { QUERY_TYPE } from '@/constants/Alert';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import AuthorQueryStore from '@/stores/AuthorQueryStore';
import Constants from '@/constants';
import S2Redirect from '@/models/redirects/S2Redirect';

import React from 'react';

import type { RouteConfig } from 'react-router-config';
import type { WillRouteToResult } from '@/router/Route';
import type AppContext from '@/AppContext';

type Props = {
  route: RouteConfig;
};

export const MAX_NUM_AUTHOR_RECS = 4;

export default class AuthorRoute extends React.Component<Props> {
  static getPageTitle({ authorStore }) {
    const authorDetail = authorStore.getAuthorDetails();
    const name: Nullable<string> = authorDetail?.author?.name;
    if (name) {
      return getString(_ => _.author.pageTitleWithAuthor, name);
    }
    return getString(_ => _.author.pageTitleWithoutAuthor);
  }

  static getPageMetaDescription({ authorStore }) {
    const authorDetail = authorStore.getAuthorDetails();
    if (authorDetail) {
      return getAuthorPageMetaDescription(authorDetail);
    }
  }

  static getCanonicalUrl(routerState) {
    if (routerState.params.slug) {
      const { authorId, slug } = routerState.params;
      return makePath({ routeName: 'AUTHOR_PROFILE', params: { authorId, slug } });
    }
  }

  static getRobotDirectives(robots, { history }) {
    // Crawlers should only be indexing the default render state.
    if (Object.keys(history.locationQueryParams).length > 0) {
      robots.shouldIndex = false;
    }
  }

  static async willRouteTo(appContext: AppContext, routerState): Promise<WillRouteToResult> {
    const { authStore, authorStore, authorInfluenceStatsStore, authorStatsStore, api, graphQLApi } =
      appContext;
    const { authorId, slug } = routerState.params;

    const promises: Promise<WillRouteToResult>[] = [];

    if (authorStore.isUninitialized() || authorStore.isNewOrCanonicalAuthor(authorId, slug)) {
      promises.push(
        api.fetchAuthorDetail(authorId, slug).then(response => checkResponseForRedirects(response))
      );
      promises.push(AuthorQueryStore.setQueryAndLoadingState(appContext, routerState));
      promises.push(
        fetchAuthorRecommendations({ authorId, limit: MAX_NUM_AUTHOR_RECS }, { graphQLApi })
      );
    }

    if (!authorInfluenceStatsStore.isAuthorLoaded(authorId)) {
      promises.push(fetchAuthorInfluenceStats({ authorId }, { graphQLApi }));
    }

    if (authorStatsStore.isUninitialized(authorId)) {
      promises.push(fetchAuthorStats(authorId, { graphQLApi }));
    }

    if (authStore.hasAuthenticatedUser()) {
      if (authorId) {
        promises.push(
          api.findAlertByQueryTypeAndValue(QUERY_TYPE.AUTHOR_PAPER, authorId),
          api.findAlertByQueryTypeAndValue(QUERY_TYPE.AUTHOR_CITATION, authorId)
        );
      }
    }

    return Promise.all(promises);
  }

  render(): React.ReactNode {
    const { route } = this.props;
    return (
      <ChildRoutesContext.Provider value={{ route }}>
        <AsyncLoadedPage
          footer={false}
          load={{
            importer: () => import(/* webpackChunkName: "shared-AuthorPage" */ './AuthorPage'),
            chunkName: 'shared-AuthorPage',
            moduleId: require.resolveWeak('./AuthorPage'),
          }}
        />
      </ChildRoutesContext.Provider>
    );
  }
}
function checkResponseForRedirects(response: ApiResponse<AuthorDetailOrRedirectResponseBody>) {
  if (response.resultData.resultType == Constants.requestTypes.AUTHOR_RESULT_REDIRECT) {
    // this redirect is usually requested when the url has an incorrect author slug
    return new S2Redirect({
      routeName: 'AUTHOR_PROFILE',
      params: {
        authorId: response.resultData.canonicalId,
        slug: response.resultData.canonicalSlug,
      },
      query: { sort: undefined },
      replace: true,
    });
  }
  return response;
}
