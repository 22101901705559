import { ApiRequestStartingPayload } from '@/api/BaseApi';
import { ApiResponse, PaperPdfDataResponseBody } from '@/api/ApiResponse';
import {
  FlattenedTerms,
  TermOnLoadMetrics,
} from '@/components/shared/paper-detail/reader/term-utils';
import {
  getPdfTermFromJS,
  PdfTermAndMention,
  PdfTermMentionFactory,
  PdfTermRecord,
} from '@/models/paper-pdf/PaperPdfTerms';
import { Nullable } from '@/utils/types';
import { PaperPdfOutputDescriptionRecord } from '@/models/paper-pdf/PaperPdfOutputDescription';
import { S2airsFeatureVersion } from '@/models/paper-pdf/S2airsFeatureVersion';
import BaseStore from '@/stores/BaseStore';
import Constants from '@/constants';
import S2Dispatcher from '@/utils/S2Dispatcher';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import Immutable from 'immutable';

type PageIndexToTerm = Immutable.Map<number, Immutable.List<PdfTermAndMention>>;

export default class ReaderTermsStore extends BaseStore {
  #storeState: StoreStateValue;
  #featureVersion: Nullable<S2airsFeatureVersion>;
  #outputDescription: Nullable<PaperPdfOutputDescriptionRecord>;
  #encodedOutputDescription: Nullable<string>;
  #paperSha: Nullable<string>;
  #terms: Immutable.List<PdfTermRecord>;
  #pageIndexToTermMapping: PageIndexToTerm;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#storeState = StoreState.UNINITIALIZED;
    this.#terms = Immutable.List();
    this.#pageIndexToTermMapping = Immutable.Map();

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const apiStartingPayload = payload as ApiRequestStartingPayload;
          switch (apiStartingPayload.requestType) {
            case Constants.requestTypes.PDF_DATA: {
              this.#storeState = StoreState.LOADING;
              this.emitChange();
              break;
            }
          }
          break;
        }

        case Constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.PDF_DATA: {
              const pdfDataApiResponse = apiResponse as ApiResponse<PaperPdfDataResponseBody>;
              const rawTerms = pdfDataApiResponse?.resultData?.terms?.feature || [];

              if (rawTerms) {
                this.#terms = Immutable.List(rawTerms.map(term => getPdfTermFromJS(term)));
                this.#pageIndexToTermMapping = this.#mapPageIndexToTerm(this.#terms);
                this.#featureVersion = pdfDataApiResponse.resultData.terms?.featureVersion || null;
                this.#outputDescription = pdfDataApiResponse.resultData?.outputDescription || null;
                this.#encodedOutputDescription =
                  pdfDataApiResponse.resultData?.encodedOutputDescription || null;
                this.#paperSha = pdfDataApiResponse.context?.paperId || null;
              }

              this.#storeState = StoreState.LOADED;
              this.emitChange();
              break;
            }
          }
          break;
        }

        case Constants.actions.API_REQUEST_FAILED: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.PDF_DATA: {
              this.#storeState = StoreState.ERROR;
              this.emitChange();
              return;
            }
          }
          return;
        }
      }
    });
  }

  getTerms(): Immutable.List<PdfTermRecord> {
    return this.#terms;
  }

  hasTerms(): boolean {
    return !this.#terms.isEmpty();
  }

  getOutputDescription(): Nullable<PaperPdfOutputDescriptionRecord> {
    return this.#outputDescription;
  }

  getEncodedOutputDescription(): Nullable<string> {
    return this.#encodedOutputDescription;
  }

  getPageIndexToTermMapping(): PageIndexToTerm {
    return this.#pageIndexToTermMapping;
  }

  getUniqueTermCountOnPage(pageIndex: number): number {
    return (
      this.#pageIndexToTermMapping
        .get(pageIndex)
        ?.map(m => m.term.s2airsId)
        .toSet().size || 0
    );
  }

  getMentionIdsInPage(pageIndex: number): string[] {
    return [
      ...(this.#pageIndexToTermMapping.get(pageIndex)?.map(m => m.termMention.s2airsId || '') ||
        []),
    ];
  }

  getOnLoadMetrics(): TermOnLoadMetrics {
    const flattenedTerms: FlattenedTerms[] = [];
    const termsMentionsPerPage = this.#pageIndexToTermMapping.mapEntries(([pageNum, mentions]) => {
      mentions.map(termAndMention => {
        flattenedTerms.push({
          termS2airsId: termAndMention.term.s2airsId,
          mentionS2airsId: termAndMention.termMention.s2airsId,
          displayText: termAndMention.term.displayText,
          mmdaId: termAndMention.termMention.mmdaId,
          pageNumber: pageNum,
        });
      });
      return [pageNum, mentions.size];
    });

    return {
      featureVersion: this.#featureVersion,
      outputDescription: this.#encodedOutputDescription,
      paperSha: this.#paperSha,
      totalUniqueTerms: this.#terms.size,
      termsMentionsPerPage,
      terms: flattenedTerms,
    };
  }

  isUninitialized(): boolean {
    return this.#storeState === StoreState.UNINITIALIZED;
  }

  isLoading(): boolean {
    return this.isUninitialized() || this.#storeState === StoreState.LOADING;
  }

  isFailed(): boolean {
    return this.#storeState === StoreState.ERROR;
  }

  // function takes in a terms and builds a map of terms per page
  #mapPageIndexToTerm(terms: Immutable.List<PdfTermRecord>): PageIndexToTerm {
    return terms.reduce((pageIndexToTermMapping, term) => {
      term.termMentions.map(mention => {
        mention.boundingBoxes.map(boundingBox => {
          const page = boundingBox.page;
          if (!pageIndexToTermMapping.has(page)) {
            pageIndexToTermMapping = pageIndexToTermMapping.set(
              page,
              Immutable.List<PdfTermAndMention>()
            );
          }

          // create a term mention instance for each bounding box otherwise
          // we will end up double rendering bounding boxes if there are multiple on a mention
          const mentionWithCurrentBoundingBox = PdfTermMentionFactory({
            s2airsId: mention.s2airsId,
            mmdaId: mention.mmdaId,
            context: mention.context,
            boundingBoxes: [boundingBox],
          });
          const termsAndMentionsForPage = pageIndexToTermMapping.get(page);

          if (termsAndMentionsForPage) {
            pageIndexToTermMapping = pageIndexToTermMapping.set(
              page,
              termsAndMentionsForPage.push({ term, termMention: mentionWithCurrentBoundingBox })
            );
          }
        });
      });

      return pageIndexToTermMapping;
    }, Immutable.Map());
  }
}
