import { getPaperFigureFromJS, PaperFigureFromJS, PaperFigureRecord } from '@/models/PaperFigure';
import { getPaperFromJS, PaperFromJS, PaperRecord, PaperRecordFactory } from '@/models/Paper';

import Immutable from 'immutable';

export type TimelinePaperFromJS = {
  label: string;
  paper: PaperFromJS;
  figures: PaperFigureFromJS[];
};

type Props = {
  label: string;
  paper: PaperRecord;
  figures: Immutable.List<PaperFigureRecord>;
};

const defaultProps: Props = {
  label: '',
  paper: PaperRecordFactory(),
  figures: Immutable.List(),
};

export const TimelinePaperRecordFactory = Immutable.Record<Props>(defaultProps);
export type TimelinePaperRecord = Immutable.RecordOf<Props>;

export function getTimelinePaperFromJS({
  label,
  paper,
  figures,
}: TimelinePaperFromJS): TimelinePaperRecord {
  return TimelinePaperRecordFactory({
    label,
    paper: getPaperFromJS(paper),
    figures: Immutable.List(figures.map(f => getPaperFigureFromJS(f))),
  });
}
