import CLPopoverBase, {
  ARROW_POSITION as _ARROW_POSITION,
  ArrowPosition as _ArrowPosition,
  PopoverTheme as _PopoverTheme,
  THEME as _THEME,
  Props as CLPopoverBaseProps,
} from './CLPopoverBase';

import { ReactNodeish } from '@/utils/types';

import React from 'react';

export const ARROW_POSITION = _ARROW_POSITION;
export const THEME = _THEME;

export type ArrowPosition = _ArrowPosition;
export type PopoverTheme = _PopoverTheme;

type Props = CLPopoverBaseProps;

export default function CLPopover({ arrow, ...props }: Props): ReactNodeish {
  return <CLPopoverBase arrow={arrow} {...props} />;
}

export const exampleConfig = {
  getComponent: () => CLPopover,
  fields: [
    {
      name: 'theme',
      desc: 'Color scheme to use for the popover',
      value: {
        type: 'select',
        default: THEME.DEFAULT,
        options: [THEME.DEFAULT, THEME.BORDERLESS, THEME.RESEARCH],
      },
    },
    {
      name: 'isInline',
      desc: 'Position the component based on the box, not the arrow',
      value: {
        type: 'boolean',
        default: false,
      },
    },
    {
      name: 'arrow',
      desc: 'Position of the arrow around the box (side first)',
      value: {
        type: 'select',
        default: ARROW_POSITION.SIDE_TOP_POS_LEFT,
        options: [
          ARROW_POSITION.SIDE_TOP_POS_LEFT,
          ARROW_POSITION.SIDE_TOP_POS_MIDDLE,
          ARROW_POSITION.SIDE_TOP_POS_RIGHT,
          ARROW_POSITION.SIDE_BOTTOM_POS_LEFT,
          ARROW_POSITION.SIDE_BOTTOM_POS_MIDDLE,
          ARROW_POSITION.SIDE_BOTTOM_POS_RIGHT,
          ARROW_POSITION.SIDE_LEFT_POS_TOP,
          ARROW_POSITION.SIDE_LEFT_POS_MIDDLE,
          ARROW_POSITION.SIDE_LEFT_POS_BOTTOM,
          ARROW_POSITION.SIDE_RIGHT_POS_TOP,
          ARROW_POSITION.SIDE_RIGHT_POS_MIDDLE,
          ARROW_POSITION.SIDE_RIGHT_POS_BOTTOM,
        ],
      },
    },
    {
      name: 'widthPx',
      desc: 'Width (in px) of the popover',
      value: {
        type: 'number',
        default: null,
      },
    },
    {
      name: 'children',
      desc: 'Content to be displayed within the component',
      value: {
        type: 'jsx',
        default: '',
        // eslint-disable-next-line react/no-danger
        transform: html => <div dangerouslySetInnerHTML={{ __html: html }} />,
      },
    },
  ],

  examples: [
    {
      title: 'Text Only',
      desc: 'Popover with some text',
      props: {
        theme: THEME.DEFAULT,
        isInline: false,
        arrow: ARROW_POSITION.SIDE_TOP_POS_LEFT,
        widthPx: 400,
        children:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      },
      render: comp => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}>
          <div style={{ position: 'relative' }}>{comp}</div>
        </div>
      ),
    },
    {
      title: 'Child elements',
      desc: 'Popover with a bunch of components',
      props: {
        theme: THEME.DEFAULT,
        isInline: false,
        arrow: ARROW_POSITION.SIDE_TOP_POS_LEFT,
        widthPx: 250,
        children: `
<div>
  <i>This</i> is some <b>test</b> content
</div>
<div>
  <img style="width: 100%" src="https://d3i71xaburhd42.cloudfront.net/1fc6a09dc106d5e5bbbb07da1d3bfaee425e872b/6-Figure1-1.png"/>
</div>
        `,
      },
      render: comp => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}>
          <div style={{ position: 'relative' }}>{comp}</div>
        </div>
      ),
    },
    {
      title: 'Borderless',
      desc: 'Popover without a border',
      props: {
        theme: THEME.BORDERLESS,
        isInline: false,
        arrow: ARROW_POSITION.SIDE_TOP_POS_LEFT,
        widthPx: null,
        children:
          '<div style="line-height: 0"><img width="400" src="https://d3i71xaburhd42.cloudfront.net/1fc6a09dc106d5e5bbbb07da1d3bfaee425e872b/6-Figure1-1.png"/></div>',
      },
      render: comp => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}>
          <div style={{ position: 'relative' }}>{comp}</div>
        </div>
      ),
    },
    {
      title: 'Inline',
      desc: 'Popover inline with the content around it',
      props: {
        theme: THEME.DEFAULT,
        isInline: true,
        arrow: ARROW_POSITION.SIDE_LEFT_POS_TOP,
        widthPx: null,
        children:
          '<div style="line-height: 0"><img width="400" src="https://d3i71xaburhd42.cloudfront.net/1fc6a09dc106d5e5bbbb07da1d3bfaee425e872b/6-Figure1-1.png"/></div>',
      },
      render: comp => (
        <div>
          <span style={{ float: 'right' }}>{comp}</span>
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum.
          </span>
        </div>
      ),
    },
    {
      title: 'Flexbox',
      desc: 'Popover with content around it',
      props: {
        theme: THEME.DEFAULT,
        isInline: true,
        arrow: ARROW_POSITION.SIDE_LEFT_POS_MIDDLE,
        widthPx: null,
        children:
          '<div style="line-height: 0"><img width="400" src="https://d3i71xaburhd42.cloudfront.net/1fc6a09dc106d5e5bbbb07da1d3bfaee425e872b/6-Figure1-1.png"/></div>',
      },
      render: comp => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <div style={{ flex: '1 1 auto' }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum.
          </div>
          {comp}
        </div>
      ),
    },
    {
      title: 'Research',
      desc: 'Popover with theme for research',
      props: {
        theme: THEME.RESEARCH,
        isInline: false,
        arrow: ARROW_POSITION.SIDE_LEFT_POS_MIDDLE,
        widthPx: 400,
        children:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      },
      render: comp => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}>
          <div style={{ position: 'relative', color: 'white' }}>{comp}</div>
        </div>
      ),
    },
  ],

  events: {
    onMouseEnter: () => ({}),
    onMouseLeave: () => ({}),
  },
};
