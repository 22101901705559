import type { Nullable } from '@/utils/types';

export type CorrectionTypeKey = keyof typeof byKey;
export type CorrectionTypeValue = (typeof byKey)[CorrectionTypeKey];

const byKey = {
  AddAuthorship: 'add-authorship',
  RemoveAuthorship: 'remove-authorship',
  SwapAuthorship: 'swap-authorship',
  Unsupported: 'Unsupported',
} as const;

const byValue = Object.keys(byKey).reduce((memo, key) => {
  const value = byKey[key];
  memo[value] = key;
  return memo;
}, {});

export default Object.freeze(byKey);

export const CorrectionTypeByValue = Object.freeze(byValue);

export function getCorrectionTypeValue(value: string): CorrectionTypeValue {
  if (value in byValue) {
    return value as CorrectionTypeValue;
  }
  return byKey.Unsupported;
}

export function optCorrectionTypeValue(value?: Nullable<string>): Nullable<CorrectionTypeValue> {
  if (!value) {
    return null;
  }
  return getCorrectionTypeValue(value);
}
