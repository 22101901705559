import {
  getPaperPdfCitationsFromJS,
  PaperPdfCitationsFromJS,
  PaperPdfCitationsRecord,
} from './PaperPdfCitations';
import {
  getPaperPdfOutputDescriptionFromJS,
  PaperPdfOutputDescriptionFromJS,
  PaperPdfOutputDescriptionRecord,
} from './PaperPdfOutputDescription';

import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

export type PaperPdfDataFromJS = {
  paperId: string;
  pdfSha: string;
  pdfUrl: string;
  pdfUrlSelfHosted: Nullable<string>;
  sourceType: string;
  citations: Nullable<PaperPdfCitationsFromJS>;
  outputDescription: Nullable<PaperPdfOutputDescriptionFromJS>;
  encodedOutputDescription: Nullable<string>;
};

type Props = {
  paperId: string;
  pdfSha: string;
  pdfUrl: string;
  pdfUrlSelfHosted: Nullable<string>;
  sourceType: string;
  citations: Nullable<PaperPdfCitationsRecord>;
  outputDescription: Nullable<PaperPdfOutputDescriptionRecord>;
  encodedOutputDescription: Nullable<string>;
};

const defaultProps: Props = {
  paperId: '',
  pdfSha: '',
  pdfUrl: '',
  pdfUrlSelfHosted: null,
  sourceType: '',
  citations: null,
  outputDescription: null,
  encodedOutputDescription: null,
};

export const PaperPdfFactory = Immutable.Record<Props>(defaultProps);
export type PaperPdfRecord = Immutable.RecordOf<Props>;

export function getPaperPdfDataFromJS(args: PaperPdfDataFromJS): PaperPdfRecord {
  const { citations, outputDescription } = args;

  const newCitations = citations ? getPaperPdfCitationsFromJS(citations) : null;

  const newOutputDescription = outputDescription
    ? getPaperPdfOutputDescriptionFromJS(outputDescription)
    : null;

  return PaperPdfFactory({
    ...args,
    citations: newCitations,
    outputDescription: newOutputDescription,
  });
}
