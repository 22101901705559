import { Nullable } from '@/utils/types';

import Immutable from 'immutable';
import invariant from 'invariant';

export const OPEN_ACCESS = 'OPEN_ACCESS';
export const PRE_PRINT = 'PRE_PRINT';
export const CORD_19 = 'CORD_19';
export const CLINICAL_TRIAL = 'CLINICAL_TRIAL';
export const UNPAYWALL = 'UNPAYWALL';
export const UNSUPPORTED = 'UNSUPPORTED';

export const BadgeType = {
  OPEN_ACCESS,
  PRE_PRINT,
  CORD_19,
  CLINICAL_TRIAL,
  UNPAYWALL,
  UNSUPPORTED,
} as const;

export type BadgeId = keyof typeof BadgeType;

export type PaperBadgeFromJS = {
  id: BadgeId;
};

export type PaperBadgeProps = {
  id: BadgeId;
};

const defaultProps: PaperBadgeProps = {
  id: UNSUPPORTED,
};

export function optPaperBadgeId(optBadge: string): Nullable<BadgeId> {
  if (optBadge && optBadge in BadgeType) {
    return BadgeType[optBadge];
  }
  return null;
}

export function getPaperBadgeId(optBadge: string): BadgeId {
  const paperBadge = optPaperBadgeId(optBadge);
  invariant(paperBadge, `"${optBadge || ''}" is not a valid PaperBadge`);
  return paperBadge;
}

export const PaperBadgeRecordFactory = Immutable.Record(defaultProps);
export type PaperBadgeRecord = Immutable.RecordOf<PaperBadgeProps>;

export function getPaperBadgeFromJS(args: PaperBadgeFromJS): PaperBadgeRecord {
  return PaperBadgeRecordFactory({
    ...args,
  });
}
