import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import idx from 'idx';
import invariant from 'invariant';
import React from 'react';

export default class AccountAuthorClaimPendingRoute extends React.Component {
  static getPageTitle = () => getString(_ => _.account.page.authorUnclaimSuccess.pageTitle);

  render() {
    const { match } = this.props;
    const authorId = getAuthorIdFromParams(match);
    return (
      <AsyncLoadedPage
        showLoadingPage={false}
        compProps={{ authorId }}
        load={{
          importer: () =>
            import(
              /* webpackChunkName: "shared-AccountAuthorUnclaimSuccessPage" */ './AccountAuthorUnclaimSuccessPage'
            ),
          chunkName: 'shared-AccountAuthorUnclaimSuccessPage',
          moduleId: require.resolveWeak('./AccountAuthorUnclaimSuccessPage'),
        }}
      />
    );
  }
}

export function optAuthorIdFromParams(match) {
  const strAuthorId = idx(match, _ => _.params.authorId) || '';
  return /^\d+$/.test(strAuthorId) ? parseInt(strAuthorId, 10) : null;
}

export function getAuthorIdFromParams(match) {
  const optAuthorId = optAuthorIdFromParams(match);
  invariant(optAuthorId, `param 'authorId' must be a number`);
  return optAuthorId;
}
