import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import qs from 'qs';
import React from 'react';

import type { Location } from 'history';

type Props = {
  location: Location;
};

export default class DebugAuthorListRoute extends React.Component<Props> {
  static pageTitle = () => getString(_ => _.general.appName);

  render() {
    const queryParams = qs.parse(this.props.location.search.replace(/^\?/, ''));
    return (
      <AsyncLoadedPage
        footer={false}
        compProps={{ initialAuthorIds: queryParams.ids }}
        load={{
          importer: () =>
            import(/* webpackChunkName: "shared-DebugAuthorListPage" */ './DebugAuthorListPage'),
          chunkName: 'shared-DebugAuthorListPage',
          moduleId: require.resolveWeak('./DebugAuthorListPage'),
        }}
      />
    );
  }
}
