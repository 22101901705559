import { MAX_SELECTED_PAPERS } from './CreateLibraryFTUEModal';

import { getString } from '@/content/i18n';
import { heapCreateLibraryFTUEAddPaperButton } from '@/analytics/attributes/researchFTUEPage';
import { PaperRecord } from '@/models/Paper';
import { ReactNodeish } from '@/utils/types';
import { SHAPE, SIZE } from '@/components/library/button/CLButtonBase';
import CLCardContainer from '@/components/library/container/CLCardContainer';
import CLIconButton from '@/components/library/button/CLIconButton';
import CLPaperRow from '@/components/library/paper/CLPaperRow';
import Icon from '@/components/shared/icon/Icon';

import React from 'react';

type Props = {
  searchPapers: Immutable.List<PaperRecord>;
  addedPapers: Immutable.OrderedMap<string, PaperRecord>;
  onAddPaper: (paper: PaperRecord) => void;
  onRemovePaper: (paper: PaperRecord) => void;
};

export default function CreateLibrarySearchResults({
  searchPapers,
  addedPapers,
  onAddPaper,
  onRemovePaper,
}: Props): ReactNodeish {
  const removePaperButtonAriaProps = {
    'aria-label': getString(
      _ => _.research.onboardingHomepage.createLibraryModal.removePaperAriaLabel
    ),
  };

  const addPaperButtonAriaProps = {
    'aria-label': getString(
      _ => _.research.onboardingHomepage.createLibraryModal.addPaperAriaLabel
    ),
  };

  return (
    <React.Fragment>
      {searchPapers.map(paper => (
        <div key={paper.id} className="create-library-modal__content">
          <div className="create-library-modal__content__icon-container">
            {addedPapers.has(paper.id) ? (
              <CLIconButton
                icon={<Icon icon="remove-x" height="8" width="8" />}
                className="create-library-modal__content__icon-x"
                size={SIZE.SMALL}
                shape={SHAPE.CIRCLE}
                onClick={() => onRemovePaper(paper)}
                ariaProps={removePaperButtonAriaProps}
                data-test-id="create-library-remove-paper-button"
              />
            ) : (
              addedPapers.size !== MAX_SELECTED_PAPERS && (
                <CLIconButton
                  icon={<Icon icon="fa-bookmark" height="8" width="6" />}
                  className="create-library-modal__content__icon-bookmark"
                  size={SIZE.SMALL}
                  shape={SHAPE.CIRCLE}
                  onClick={() => onAddPaper(paper)}
                  ariaProps={addPaperButtonAriaProps}
                  heapProps={heapCreateLibraryFTUEAddPaperButton()}
                  data-test-id="create-library-add-paper-button"
                />
              )
            )}
          </div>
          <CLCardContainer className="create-library-modal__results" hasDogEar>
            <CLPaperRow
              paper={paper}
              controls={false}
              className="flex-column paper-row-list"
              abstract={
                paper.paperAbstractTruncated ? (
                  <React.Fragment>{paper.paperAbstractTruncated}</React.Fragment>
                ) : (
                  false
                )
              }
              isInteractive={false}
            />
          </CLCardContainer>
        </div>
      ))}
    </React.Fragment>
  );
}
