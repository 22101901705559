/**
 * This file is for type utilities that make using Typescript easier
 */

import type { AppcuesInstance } from '@/analytics/Appcues';
import type { HeapInstance } from '@/analytics/Heap';
import type { Request } from 'express';
import type { S2WeblabConfigObj } from '@/weblab/WeblabConfig';
import type ServerCookieJar from './cookies/ServerCookieJar';
import type ServerWeblabStore from '@/weblab/ServerWeblabStore';

// A value that can be null
export type Nullable<T> = T | null;

// Returns a type of the values within an object (frequently used with "as const")
export type ValueOf<T> = T[keyof T];

// A value that can be null or undefined
// This is a hold over from Flow, which we should not repeat in new TS code
export type DEPRECATED__FlowOptional<T> = Nullable<T> | undefined;

// Something that needs to by typed later
export type TODO<TNote> = any | TNote;

// Placeholder type for an enum missing its type definition
export type TODO__ENUM<TNote> = string | TNote;

// Our version of React has a bug where there are multiple kinds of "nodes".
// Class-based returns React.ReactNode
// Function-based returns React.ReactElement
export type ReactNodeish = JSX.Element | null;

export type ObjectProperties<T extends Record<string, unknown>> = T[keyof T];
export type PickNestedProperty<
  T extends Record<string, unknown>,
  PropertyName extends keyof ObjectProperties<T>,
> = ObjectProperties<T>[PropertyName];

export type HypothesisConfig = {
  openSidebar: boolean;
  externalContainerSelector: string;
  showHighlights: boolean;
  enableExperimentalNewNoteButton: boolean;
};

export type RequestWithMiddleware = Request & {
  cookieJar: ServerCookieJar;
  trackingId: string;
  weblabStore: ServerWeblabStore;
};

/**
 * Window extensions
 * This interface describes non-standard `window` members added by our code
 */
declare global {
  interface Window {
    // Analytics members
    Appcues?: Nullable<AppcuesInstance>;
    heap?: Nullable<HeapInstance>;
    s2isMobile?: boolean;
    s2RouteName?: Nullable<string>;
    googleAnalyticsId?: string;

    // Sign-in members
    authSuccessful?: () => void;
    authSignUpSuccessful?: () => void;
    authFailed?: () => void;
    authCancelled?: () => void;
    authForbidden?: () => void;
    hypothesisConfig?: () => HypothesisConfig;

    // Vars syncing server-side state with browser
    S2_WEBLAB_CONFIG?: S2WeblabConfigObj;

    // Used by clientUtils
    DATA?: any;
  }
}

export function isNumber(any: any): any is number {
  return typeof any === 'number';
}

export function isString(any: any): any is string {
  return typeof any === 'string';
}

export function isBoolean(any: any): any is boolean {
  return typeof any === 'boolean';
}

export function isFunction<T = (...args: any[]) => any>(any: any): any is T {
  return typeof any === 'function';
}

export function isObject<T = object>(any: any): any is T {
  return any !== null && typeof any === 'object';
}

// Determines if value is a plain object (vs a class or other primitive)
export function isPojo<T = Record<string, any>>(any: any): any is T {
  return isObject(any) && Object.getPrototypeOf(any) === Object.prototype;
}

export function isBigInt(any: any): any is bigint {
  return typeof any === 'bigint';
}

export function isSymbol(any: any): any is symbol {
  return typeof any === 'symbol';
}

export function isRegExp(any: any): any is RegExp {
  return any instanceof RegExp;
}

export function isUndefined(any: any): any is undefined {
  return typeof any === 'undefined';
}

export function isNull(any: any): any is null {
  return null === any;
}

export function hasValue<T>(any: T | undefined | null): any is Exclude<T, null | undefined> {
  const isNonValue = isNull(any) || isUndefined(any);
  return !isNonValue;
}

/*
 * Type guards for various HTML Element classes are below
 */
export function isLinkElement(e: Element): e is HTMLLinkElement {
  return e.tagName.toUpperCase() === 'LINK';
}

export function isMetaElement(e: Element): e is HTMLMetaElement {
  return e.tagName.toUpperCase() === 'META';
}
