import { DEPRECATED__FlowOptional, isNull, isUndefined } from '@/utils/types';

import Immutable from 'immutable';

export type YearStatBucketFromJS = {
  startKey: DEPRECATED__FlowOptional<number>;
  endKey: DEPRECATED__FlowOptional<number>;
  count: DEPRECATED__FlowOptional<number>;
};

type Props = {
  startKey: number;
  endKey: number;
  count: number;
};

const defaultProps: Props = {
  startKey: -1,
  endKey: -1,
  count: 0,
};

export const YearStatBucketRecordFactory = Immutable.Record<Props>(defaultProps);
export type YearStatBucketRecord = Immutable.RecordOf<Props>;

export function getYearStatBucketFromJS(args: YearStatBucketFromJS): YearStatBucketRecord {
  return YearStatBucketRecordFactory({
    startKey: !(isNull(args.startKey) || isUndefined(args.startKey)) ? args.startKey : 0,
    endKey: !(isNull(args.endKey) || isUndefined(args.endKey)) ? args.endKey : 0,
    count: !(isNull(args.count) || isUndefined(args.count)) ? args.count : 0,
  });
}
