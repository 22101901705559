import BaseStore from './BaseStore';

import { ApiRequestStartingPayload } from '@/api/BaseApi';
import { ApiResponse } from '@/api/ApiResponse';
import { AuthorCorrectionRecord, getAuthorCorrectionRecordFromJS } from '@/models/AuthorCorrection';
import { CorrectionTypeValue } from '@/constants/CorrectionType';
import { DEPRECATED__FlowOptional, Nullable } from '@/utils/types';
import { FieldCorrectionRecord } from '@/models/FieldCorrection';
import {
  getPaperAuthorCorrectionFromJS,
  PaperAuthorCorrectionRecord,
} from '@/models/PaperAuthorCorrection';
import { getPaperLiteFromJS, PaperLiteFromJS, PaperLiteRecord } from '@/models/PaperLite';
import CompletionStatus, { CompletionStatusValue } from '@/constants/CompletionStatus';
import Constants from '@/constants';
import S2Dispatcher from '@/utils/S2Dispatcher';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import Immutable from 'immutable';

const LOADING_STATES: StoreStateValue[] = [StoreState.UNINITIALIZED, StoreState.LOADING];

const COMPLETION_PRIORITY_ORDER = [
  CompletionStatus.Submitted,
  CompletionStatus.Processing,
  CompletionStatus.Succeeded,
  CompletionStatus.Failed,
  CompletionStatus.Cancelled,
];

export default class CorrectionsStore extends BaseStore {
  #stateAuthorCorrections: StoreStateValue;
  #statePaperAuthorCorrections: StoreStateValue;
  #authorCorrections: Immutable.List<AuthorCorrectionRecord>;
  #paperAuthorCorrections: Immutable.List<PaperAuthorCorrectionRecord>;
  #correctedPapers: Immutable.Map<string, PaperLiteRecord>;
  dispatchToken: string;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#stateAuthorCorrections = StoreState.UNINITIALIZED;
    this.#statePaperAuthorCorrections = StoreState.UNINITIALIZED;
    this.#authorCorrections = Immutable.List();
    this.#paperAuthorCorrections = Immutable.List();
    this.#correctedPapers = Immutable.Map();

    this.dispatchToken = dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const apiStartingPayload = payload as ApiRequestStartingPayload;
          switch (apiStartingPayload.requestType) {
            case Constants.requestTypes.FETCH_AUTHOR_CORRECTIONS: {
              this.#stateAuthorCorrections = StoreState.LOADING;
              this.emitChange();
              break;
            }
            case Constants.requestTypes.FETCH_PAPER_AUTHOR_CORRECTIONS: {
              this.#statePaperAuthorCorrections = StoreState.LOADING;
              this.emitChange();
              break;
            }
          }
          break;
        }

        case Constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.FETCH_AUTHOR_CORRECTIONS: {
              this.#stateAuthorCorrections = StoreState.LOADED;
              const authorCorrectionsForAuthorResponse = apiResponse;
              const corrections = authorCorrectionsForAuthorResponse.resultData?.authorCorrections;
              const oldList = this.#authorCorrections;
              const newList = Immutable.List(corrections)
                .map(getAuthorCorrectionRecordFromJS)
                .sort((a, b) => (a.id || 0) - (b.id || 0)); // Make sure order by submission time
              if (!oldList.equals(newList)) {
                this.#authorCorrections = newList;
                this.emitChange();
              }
              break;
            }
            case Constants.requestTypes.FETCH_PAPER_AUTHOR_CORRECTIONS: {
              this.#statePaperAuthorCorrections = StoreState.LOADED;
              const paperAuthorCorrectionsForAuthorResponse = apiResponse;
              const corrections =
                paperAuthorCorrectionsForAuthorResponse.resultData?.paperAuthorCorrections;
              const paperIdToPaperMap: Map<string, PaperLiteFromJS> =
                paperAuthorCorrectionsForAuthorResponse.resultData?.paperLites;
              const oldList = this.#paperAuthorCorrections;
              const newList = Immutable.List(corrections)
                .map(getPaperAuthorCorrectionFromJS)
                .sort((a, b) => (a.id || 0) - (b.id || 0)); // Make sure order by submission time
              if (!oldList.equals(newList)) {
                this.#paperAuthorCorrections = newList;
                this.#correctedPapers = Immutable.Map(paperIdToPaperMap).map(rawPaper =>
                  getPaperLiteFromJS(rawPaper)
                );
                this.emitChange();
              }
              break;
            }
          }
          break;
        }
      }
    });
  }

  isLoading(): boolean {
    return (
      LOADING_STATES.includes(this.#stateAuthorCorrections) ||
      LOADING_STATES.includes(this.#statePaperAuthorCorrections)
    );
  }

  getAuthorCorrections(): Immutable.List<AuthorCorrectionRecord> {
    return this.#authorCorrections;
  }

  hasCorrections(): boolean {
    return !this.#authorCorrections.isEmpty() || !this.#paperAuthorCorrections.isEmpty();
  }

  getFieldCorrectionsByName(fieldName: string): Immutable.List<FieldCorrectionRecord> {
    return this.getAuthorCorrections()
      .filter(_ => _.fieldNames && _.fieldNames.includes(fieldName))
      .map(_ => _.fieldCorrections && _.fieldCorrections.find(_ => _.field === fieldName))
      .filter((correction): correction is FieldCorrectionRecord => !!correction);
  }

  getAuthorCorrectionsStatus(): Nullable<CompletionStatusValue> {
    const corrections = this.getAuthorCorrections();
    const completionStatusList = corrections
      .filter(_ => !!_.completionStatus)
      .map(_ => _.completionStatus);
    return pickCompletionStatusFromList(completionStatusList);
  }

  getPaperAuthorCorrections(): Immutable.List<PaperAuthorCorrectionRecord> {
    return this.#paperAuthorCorrections;
  }

  getPaperAuthorCorrectionsByTypes(
    types: CorrectionTypeValue[]
  ): Immutable.List<PaperAuthorCorrectionRecord> {
    const corrections = this.getPaperAuthorCorrections();
    return corrections.filter(_ => _.correctionType && types.includes(_.correctionType));
  }

  getPaperAuthorCorrectionsStatus(): Nullable<CompletionStatusValue> {
    const corrections = this.getPaperAuthorCorrections();
    const completionStatusList = corrections
      .filter(_ => !!_.completionStatus)
      .map(_ => _.completionStatus);
    return pickCompletionStatusFromList(completionStatusList);
  }

  getPaperAuthorCorrectionsStatusByTypes(
    types: CorrectionTypeValue[]
  ): Nullable<CompletionStatusValue> {
    const corrections = this.getPaperAuthorCorrectionsByTypes(types);
    const completionStatusList = corrections
      .filter(_ => !!_.completionStatus)
      .map(_ => _.completionStatus);
    return pickCompletionStatusFromList(completionStatusList);
  }

  getCorrectedPaperById(paperId): DEPRECATED__FlowOptional<PaperLiteRecord> {
    if (!this.#correctedPapers) {
      return null;
    }

    return this.#correctedPapers.get(paperId);
  }
}

// Pick which completion status best represents the status of changes requested
export function pickCompletionStatusFromList(statusList) {
  if (statusList.isEmpty()) {
    return null;
  }
  if (statusList.last() === CompletionStatus.Failed) {
    // If the last correction was failed, show as failed
    return CompletionStatus.Failed;
  }
  for (const status of COMPLETION_PRIORITY_ORDER) {
    // Check whether there are any corrections with the given status
    if (statusList.includes(status)) {
      return status;
    }
  }
  return null;
}
