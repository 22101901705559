import LayoverClient from './LayoverClient';
import LayoverDefaultClient from './LayoverDefaultClient';
import LayoverNoopClient from './LayoverNoopClient';

import { isBrowser, isTrackingAllowedOnBrowser, isUnitTest } from '@/utils/env';

import invariant from 'invariant';

import type { Nullable } from '@/utils/types';
import type AppContext from '@/AppContext';

type RicoEntry = {
  id: string;
  name: string;
  [key: string]: unknown;
};

export default class LayoverRico {
  _client: LayoverClient;
  _appContext: Nullable<AppContext>;

  constructor({ client }: { client: LayoverClient }) {
    this._client = client;
    this._appContext = null;
  }

  associateWithAppContext(appContext: AppContext): void {
    this._client.associateWithAppContext(appContext);
    this._appContext = appContext;
  }

  emit(entry: RicoEntry): boolean {
    const { id, name } = entry;
    // validate entries to only contain alphanumeric, period, or dashes to prevent sql injections
    const regex = new RegExp(/^[a-zA-Z0-9._-]*$/);
    // rico data expects at the very least an id and name, data can be arbitrary shape so hard to sanitize
    invariant(id && name && regex.test(id) && regex.test(name), 'entry data is invalid');
    return this._client.send(entry);
  }

  getUserId(): Nullable<number> {
    return this._client.getUserId();
  }

  getBrowserId(): Nullable<string> {
    return this._client.getBrowserId();
  }
}

let inst: Nullable<LayoverRico> = null;
export function getLayoverRico(): LayoverRico {
  if (!inst) {
    const args = {
      destinationUrl: '/beacon/rico',
    };
    const shouldSendLogs = isBrowser() && !isUnitTest() && isTrackingAllowedOnBrowser();
    const client = shouldSendLogs ? new LayoverDefaultClient(args) : new LayoverNoopClient();
    inst = new LayoverRico({ client });
  }
  return inst;
}
