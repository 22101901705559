import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import logger from '@/logger';

import idx from 'idx';
import React from 'react';

export default class AccountManageRoute extends React.Component {
  static getPageTitle = () => getString(_ => _.account.page.manageAccount.pageTitle);

  static async willRouteTo({ userContactStore, api }) {
    const userContactPromise = (() => {
      if (userContactStore.isUninitialized()) {
        return api.getUserContact().catch(ex => {
          if (idx(ex, _ => _.errorCode) === 'contactMissing') {
            logger.info('No contact associated yet');
          } else {
            throw ex;
          }
        });
      } else {
        return Promise.resolve();
      }
    })();

    return userContactPromise.then(payload => {
      return payload;
    });
  }

  render() {
    return (
      <AsyncLoadedPage
        showLoadingPage={false}
        load={{
          importer: () =>
            import(/* webpackChunkName: "shared-AccountManagePage" */ './AccountManagePage'),
          chunkName: 'shared-AccountManagePage',
          moduleId: require.resolveWeak('./AccountManagePage'),
        }}
      />
    );
  }
}
