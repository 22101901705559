import PageLevelMessage from './PageLevelMessage';

import { PAGE_ERROR } from '@/actions/MessageActionCreators';
import { ReactNodeish } from '@/utils/types';
import { useAppContext, useStateFromStore } from '@/AppContext';
import DesktopMessages from '@/components/desktop/common/message/Messages';
import MobileMessages from '@/components/mobile/common/message/Messages';

import React from 'react';

export default function MessageManager(): ReactNodeish {
  const { envInfo, messageStore } = useAppContext();

  const { pageMessages } = useStateFromStore(messageStore, () => ({
    pageMessages: messageStore.getMessageOfType(PAGE_ERROR),
  }));

  if (pageMessages.size > 0) {
    return <PageLevelMessage message={pageMessages.first()} />;
  }

  if (envInfo.isMobile) {
    return <MobileMessages max={4} />;
  }

  return <DesktopMessages max={4} />;
}
