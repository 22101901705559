import CLPaperCiteButton from './buttons/CLPaperCiteButton';

import { AnalyticsContextHelper } from '@/components/library/paper/CLPaperAnalytics';
import { checkForPaperRecord } from '@/utils/paper-util';
import { ModalId } from '@/constants/Modal';
import { showModal } from '@/actions/ModalActionCreators';
import ModalStore from '@/stores/ModalStore';
import S2Dispatcher from '@/utils/S2Dispatcher';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default class CLPaperActionCite extends React.PureComponent {
  static contextTypes = {
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    modalStore: PropTypes.instanceOf(ModalStore).isRequired,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      ...this.getStateFromModalStore(),
    };

    this.context.modalStore.registerComponent(this, () => {
      this.setState(this.getStateFromModalStore());
    });
  }

  getStateFromModalStore() {
    const { modalStore } = this.context;
    return {
      isModalOpen: modalStore.getVisibleModalId() === ModalId.CITE,
    };
  }

  _onClickCiteAnalytics;

  setAnalyticsCallbacks = ({ onClickCite }) => {
    this._onClickCiteAnalytics = onClickCite;
  };

  openCiteModal = () => {
    const { onClick, paper } = this.props;

    if (this._onClickCiteAnalytics) {
      this._onClickCiteAnalytics();
    }

    if (typeof onClick === 'function') {
      onClick();
    }

    this.context.dispatcher.dispatch(showModal({ id: ModalId.CITE, data: paper }));
  };

  render() {
    const { className, heapProps, testId, paper, icon } = this.props;
    const { isModalOpen } = this.state;

    // Only able to cite Paper objs so return early if a PaperLite was passed in
    const isPaperRecord = !!checkForPaperRecord(paper);
    if (!isPaperRecord) {
      return null;
    }

    return (
      <AnalyticsContextHelper onUpdate={this.setAnalyticsCallbacks}>
        <CLPaperCiteButton
          className={classnames(
            {
              'cl-paper-action__button--blue-outline': !isModalOpen,
            },
            className
          )}
          heapProps={heapProps}
          testId={testId}
          onClick={this.openCiteModal}
          icon={icon}
        />
      </AnalyticsContextHelper>
    );
  }
}
