export default {
  AUTH: 'auth',
  ABANDON: 'abandon',
  ACCOUNT_CREATED: 'accountcreated',
  APPLOAD: 'appload',
  APPLOADTIMING: 'apploadtiming',
  CLICK: 'click',
  CONTEXTMENUOPEN: 'contextmenuopen',
  VARIATION_MISMATCH: 'variationmismatch',
  HOVER: 'hover',
  OPTOUT: 'optout',
  PAGEVIEW: 'pageview',
  RICO: 'rico',
  ROUTELOADTIMING: 'routeloadtiming',
  SCROLL: 'scroll',
  SELECT: 'select',
  SHOW: 'show',
  SORT: 'sort',
  SUBMIT: 'submit',
  UNLOAD: 'unload',
  USERSETTINGS: 'usersettings',
};
