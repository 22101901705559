import {
  getYearStatBucketFromJS,
  YearStatBucketFromJS,
  YearStatBucketRecord,
} from './citation-stats/YearStatBucket';

import Immutable from 'immutable';
import merge from 'merge';

export type CitationStatsFromJS = {
  citedByBuckets: YearStatBucketFromJS[];
  keyCitedByBuckets: YearStatBucketFromJS[];
  numCitations: number;
  estNumCitations: number;
  numReferences: number;
  numKeyCitations: number;
  numKeyReferences: number;
  numViewableReferences: number;
  keyCitationRate: number;
};

type Props = {
  citedByBuckets: Immutable.List<YearStatBucketRecord>;
  numCitations: number;
  numReferences: number;
  numViewableReferences: number;
  numKeyCitations: number;
  numKeyReferences: number;
  keyCitationRate: number;
  estNumCitations: number;
};

const defaultProps: Props = {
  citedByBuckets: Immutable.List(),
  numCitations: 0,
  numReferences: 0,
  numViewableReferences: 0,
  numKeyCitations: 0,
  numKeyReferences: 0,
  keyCitationRate: 0,
  estNumCitations: 0,
};

export const CitationStatsRecordFactory = Immutable.Record<Props>(defaultProps);
export type CitationStatsRecord = Immutable.RecordOf<Props>;

export function citationStatsHasKeyCitations(stats: CitationStatsRecord): boolean {
  return stats.numKeyCitations > 0;
}

export function getCitationStatsFromJS(args: CitationStatsFromJS): CitationStatsRecord {
  const stats = merge.recursive(true, args);
  return CitationStatsRecordFactory(
    merge(stats, {
      numCitations: stats.numCitations,
      estNumCitations: stats.estNumCitations,
      citedByBuckets: Immutable.List(
        stats.citedByBuckets.map((bucket: YearStatBucketFromJS) => {
          return getYearStatBucketFromJS(bucket);
        })
      ),
    })
  );
}
