import { AlertQueryType, QUERY_TYPE } from '@/constants/Alert';
import { ReactNodeish } from '@/utils/types';
import { truncateText } from '@/util';
import Link, { S2LinkProps } from '@/router/Link';

import React from 'react';

type Props = {
  queryType: AlertQueryType;
  queryValue: string;
  displayValue: string;
};

const DISPLAY_VALUE_LIMIT = 100;

function mkAlertLinkProps(queryType: AlertQueryType, queryValue: string): S2LinkProps {
  switch (queryType) {
    case QUERY_TYPE.SEARCH_RESULTS: {
      return {
        to: 'SEARCH',
        query: {
          q: queryValue,
          sort: 'relevance',
        },
      };
    }

    case QUERY_TYPE.AUTHOR_PAPER: {
      return {
        to: 'AUTHOR_PROFILE_BY_ID',
        params: { authorId: queryValue },
      };
    }

    case QUERY_TYPE.AUTHOR_CITATION: {
      return {
        to: 'AUTHOR_PROFILE_BY_ID',
        params: { authorId: queryValue },
      };
    }

    case QUERY_TYPE.PAPER_CITATION: {
      return {
        to: 'PAPER_DETAIL_BY_ID',
        params: { paperId: queryValue },
      };
    }

    case QUERY_TYPE.ENTITY_PAPER: {
      return {
        to: 'ENTITY_BY_ID',
        params: { entityId: queryValue },
      };
    }

    case QUERY_TYPE.LIBRARY_FOLDER: {
      return {
        to: 'LIBRARY_FOLDER',
        params: { libraryFolderId: queryValue },
      };
    }

    default: {
      throw new Error(`Unknown Alert link type: ${queryType}`);
    }
  }
}

const AlertLink = ({ queryType, queryValue, displayValue }: Props): ReactNodeish => {
  const props = (() => {
    return mkAlertLinkProps(queryType, queryValue);
  })();
  const alertDisplay = truncateText(displayValue, DISPLAY_VALUE_LIMIT);
  return (
    <Link className="research__truncate-link" {...props}>
      <>{alertDisplay}</>
    </Link>
  );
};

export default AlertLink;
