import {
  getHighlightedFieldFromJS,
  HighlightedFieldFromJS,
  HighlightedFieldRecord,
  HighlightedFieldRecordFactory,
} from './HighlightedField';

import Immutable from 'immutable';

export type CitationIntent = 'methodology' | 'result' | 'background';
type TOrEnum<T> = T | { id: T };

export type CitationContextFromJS = {
  context: HighlightedFieldFromJS;
  intents: TOrEnum<CitationIntent>[];
};

type Props = {
  context: HighlightedFieldRecord;
  intents: CitationIntent[];
};

const defaultProps: Props = {
  context: HighlightedFieldRecordFactory(),
  intents: [],
};

export const CitationContextRecordFactory = Immutable.Record<Props>(defaultProps);
export type CitationContextRecord = Immutable.RecordOf<Props>;

export function getCitationContextFromJS(args: CitationContextFromJS): CitationContextRecord {
  return CitationContextRecordFactory({
    context: getHighlightedFieldFromJS(args.context),
    intents: (args.intents || []).map(intent => (typeof intent === 'string' ? intent : intent.id)),
  });
}
