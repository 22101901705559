import Page from '@/components/shared/layout/Page';

import classnames from 'classnames';
import React from 'react';

import type { ReactNodeish } from '@/utils/types';

type Props = React.PropsWithChildren<{
  altText: string;
  image: string;
  imageClass?: string;
  status: string;
}>;

function ErrorMessageBlock({ children, image, altText, status, imageClass }: Props): ReactNodeish {
  return (
    <Page data-test-id="error-message-block">
      <input type="hidden" id="error-status" value={status} />
      <section
        className="content error-message flex-column flex-row-centered"
        role="main"
        id="main-content">
        <img
          className={classnames('error-message__image', imageClass)}
          src={`/img/${image}.png`}
          alt={altText}
        />
        {children}
      </section>
    </Page>
  );
}

export default ErrorMessageBlock;
