import React from 'react';

export default function IconFaGlobe(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 36 36">
      <path
        d="M12,18c0,0.94,0.05,1.94,0.14,3H6.8c-0.28-1.03-0.42-2.03-0.42-3s0.14-1.97,0.42-3h5.34C12.05,16.03,12,17.03,12,18z
        M14.67,6.84c-1.12,1.56-1.91,3.78-2.34,6.66H7.27c0.69-1.59,1.68-2.98,2.98-4.15S13.02,7.34,14.67,6.84z M7.27,22.5h5.06
        c0.47,2.88,1.25,5.09,2.34,6.66c-1.66-0.5-3.13-1.34-4.43-2.51S7.95,24.09,7.27,22.5z M13.5,18c0-1,0.05-2,0.14-3h8.72
        c0.09,1,0.14,2,0.14,3s-0.05,2-0.14,3h-8.72C13.55,20,13.5,19,13.5,18z M22.17,13.5h-8.3c0.34-2.12,0.89-3.84,1.64-5.16
        S17.09,6.38,18,6.38s1.73,0.66,2.48,1.97S21.8,11.38,22.17,13.5z M13.88,22.5h8.25c-0.34,2.12-0.89,3.84-1.64,5.16
        s-1.58,1.97-2.48,1.97s-1.73-0.66-2.48-1.97S14.22,24.62,13.88,22.5z M28.73,13.5h-5.06c-0.47-2.88-1.25-5.09-2.34-6.66
        c1.66,0.5,3.13,1.34,4.43,2.51S28.05,11.91,28.73,13.5z M21.33,29.16c1.12-1.56,1.91-3.78,2.34-6.66h5.06
        c-0.69,1.59-1.68,2.98-2.98,4.15S22.98,28.66,21.33,29.16z M29.2,15c0.28,1,0.42,2,0.42,3s-0.14,2-0.42,3h-5.34
        c0.09-1.03,0.14-2.03,0.14-3s-0.05-1.97-0.14-3H29.2z"
      />
    </symbol>
  );
}
