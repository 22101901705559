import * as util from '@/util';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class HistogramYearPopover extends PureComponent {
  static propTypes = {
    bucket: PropTypes.object.isRequired,
    count: PropTypes.number,
    label: PropTypes.string,
  };

  render() {
    const years =
      this.props.bucket.startKey === this.props.bucket.endKey
        ? this.props.bucket.startKey
        : this.props.bucket.startKey + ' to ' + this.props.bucket.endKey;

    // TODO (erikad) clean this up once the data structure of the histogram buckets
    // stabilizes.
    let count;
    if (this.props.count) {
      count = this.props.count;
    } else {
      count = this.props.bucket.count;
    }

    return (
      <span>
        <strong>{util.pluralize(count, this.props.label || 'Publication')}</strong>
        {' – ' + years}
      </span>
    );
  }
}
