import { AlertRecord } from '@/models/Alert';
import { getString } from '@/content/i18n';
import { hideModal } from '@/actions/ModalActionCreators';
import { LOGIN_LOCATION } from '@/constants/LoginMethods';
import { ModalId } from '@/constants/Modal';
import Api from '@/api/Api';
import AuthStore from '@/stores/AuthStore';
import CLButton, { TYPE } from '@/components/library/button/CLButton';
import CLModal from '@/components/library/modal/CLModal';
import EnvInfo from '@/env/EnvInfo';
import EventTarget from '@/analytics/constants/EventTarget';
import MessageStore from '@/stores/MessageStore';
import S2Dispatcher from '@/utils/S2Dispatcher';
import SubmitEvent from '@/analytics/models/SubmitEvent';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import PropTypes from 'prop-types';
import React from 'react';

type Props = {
  alert: AlertRecord;
};

export default class DeleteAlertModal extends React.PureComponent<Props> {
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    authStore: PropTypes.instanceOf(AuthStore).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    messageStore: PropTypes.instanceOf(MessageStore).isRequired,
  };

  declare context: {
    api: Api;
    authStore: AuthStore;
    dispatcher: S2Dispatcher;
    envInfo: EnvInfo;
    messageStore: MessageStore;
  };

  handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    const { authStore, dispatcher, messageStore } = this.context;
    dispatcher.dispatch(hideModal());
    authStore
      .ensureLogin({ dispatcher: dispatcher, location: LOGIN_LOCATION.deleteAlertModal })
      .then(this.deleteAlert)
      .catch(() => messageStore.addError(getString(_ => _.alerts.delete.noAuth)));
  };

  deleteAlert = (): void => {
    const { messageStore, api, envInfo } = this.context;
    const alertId = this.props.alert.id;
    const { DELETE_SUCCESS, DELETE_FAILURE } = EventTarget.UserAlerts;
    const isMobile = envInfo.isMobile;

    api
      .deleteAlert(alertId)
      .then(() => {
        if (isMobile) {
          messageStore.addSuccess(
            null,
            getString(_ => _.alerts.delete.success)
          );
        } else {
          messageStore.addSuccess(getString(_ => _.alerts.delete.success));
        }
        trackAnalyticsEvent(SubmitEvent.create(DELETE_SUCCESS, { alertId }));
      })
      .catch(({ status, error }) => {
        if (isMobile) {
          messageStore.addError(
            null,
            getString(_ => _.alerts.delete.error)
          );
        } else {
          messageStore.addError(getString(_ => _.alerts.delete.error));
        }
        trackAnalyticsEvent(SubmitEvent.create(DELETE_FAILURE, { alertId, error, status }));
      });
  };

  render(): React.ReactNode {
    const { displayValue, queryType } = this.props.alert;
    const deleteMessage = getString(_ => _.alerts.delete[queryType]);

    return (
      <CLModal modalId={ModalId.DELETE_ALERT} className="alert-modal">
        <div className="alert-modal__content">
          <div className="alert-modal__header">
            <h1 className="alert-modal__header-text">
              {getString(_ => _.alerts.create.form.deleteAlertHeader)}
            </h1>
          </div>
          <div className="alert-modal__alert-information">
            <p>{deleteMessage}</p>
            <p>
              <strong>{displayValue}</strong>
            </p>
            <CLButton
              type={TYPE.PRIMARY}
              onClick={this.handleSubmit}
              label={getString(_ => _.alerts.create.form.confirmDeleteText)}
            />
          </div>
        </div>
      </CLModal>
    );
  }
}
