import { hasValue, Nullable, ReactNodeish } from '@/utils/types';
import Icon from '@/components/shared/icon/Icon';

import classnames from 'classnames';
import React from 'react';

import type { ProfilePictureRecord } from '@/models/user/ProfilePicture';

type Props = {
  altText: string;
  className?: Nullable<string>;
  profilePicture?: Nullable<ProfilePictureRecord>;
  heightPx?: Nullable<number>;
  widthPx?: Nullable<number>;
  sizePx?: Nullable<number>;
  shape: Shape;
  fallback?: Nullable<ReactNodeish | boolean>;
};

export enum Shape {
  Circle = 'circle',
  Square = 'square',
}

export default function ProfilePicture({
  altText,
  className,
  profilePicture,
  heightPx,
  widthPx,
  sizePx,
  shape,
  fallback,
}: Props): ReactNodeish {
  // Pick the size of the picture
  let iconHeight: string;
  let iconWidth: string;
  let pictureHeight: number;
  let pictureWidth: number;
  if (hasValue(heightPx) && hasValue(widthPx)) {
    pictureHeight = heightPx;
    pictureWidth = widthPx;
    iconHeight = `${heightPx / 2}px`;
    iconWidth = `${widthPx / 2}px`;
  } else {
    sizePx = hasValue(sizePx) ? sizePx : ProfilePicture.defaultProps.sizePx;
    pictureHeight = sizePx;
    pictureWidth = sizePx;
    iconHeight = `${sizePx / 2}px`;
    iconWidth = `${sizePx / 2}px`;
  }

  // Build the style props for the wrapper
  const styleProps = React.useMemo(() => {
    return {
      height: `${pictureHeight}px`,
      width: `${pictureWidth}px`,
    };
  }, [pictureHeight, pictureWidth]);

  // Pick the image that is rendered
  const imgElem = (() => {
    if (profilePicture) {
      return (
        <img className="profile-picture__custom-image" src={profilePicture.url} alt={altText} />
      );
    }
    if (!fallback) {
      return null;
    }
    if (fallback === true) {
      return (
        <div className="profile-picture__fallback-image">
          <Icon icon="user" width={iconWidth} height={iconHeight} />
        </div>
      );
    }
    return <div className="profile-picture__fallback-image">{fallback}</div>;
  })();

  // Don't render anything if there is no image
  if (!imgElem) {
    return null;
  }

  return (
    <div
      className={classnames('profile-picture', className, {
        'profile-picture--circle': shape === Shape.Circle,
        'profile-picture--square': shape === Shape.Square,
      })}
      style={styleProps}>
      {imgElem}
    </div>
  );
}

ProfilePicture.defaultProps = {
  sizePx: 38,
  shape: Shape.Circle,
  fallback: true,
};
