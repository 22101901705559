import Immutable from 'immutable';

export type TermDefinitionFromJS = {
  definitionId: string;
  definition: string;
  pdfSha: string;
  term: string;
  termContext: string;
  termId: string;
  termMentionId: string;
  s2airsOutputDescription: string;
};

export type TermDefinitionProps = {
  definitionId: string;
  definition: string;
  pdfSha: string;
  term: string;
  termContext: string;
  termId: string;
  termMentionId: string;
  s2airsOutputDescription: string;
};

const defaultProps: TermDefinitionProps = {
  definitionId: '',
  definition: '',
  pdfSha: '',
  term: '',
  termContext: '',
  termId: '',
  termMentionId: '',
  s2airsOutputDescription: '',
};

export const PdfTermDefinitionFactory = Immutable.Record<TermDefinitionProps>(defaultProps);
export type PdfTermDefinitionRecord = Immutable.RecordOf<TermDefinitionProps>;
