import {
  CitationQueryFromQueryParams,
  CitationQueryRecord,
  citationQueryRecordToQueryString,
  isCitationQueryRecord,
} from './CitationQuery';
import { FacetValueFromJS } from './FacetValue';
import {
  isReferenceQueryRecord,
  ReferenceQueryFromQueryParams,
  ReferenceQueryRecord,
  referenceQueryRecordToQueryString,
} from './ReferenceQuery';
import {
  isVenueQueryRecord,
  VenueQueryFromQueryParams,
  VenueQueryRecord,
  venueQueryRecordToQueryString,
} from './VenueQuery';
import { QueryFromQueryParams, QueryRecord, queryRecordToQueryString } from './Query';
import { YearBucketFromFacet } from './YearBucket';
import { YearRangeRecord } from './YearRange';

import { Nullable } from '@/utils/types';

/**
 * Utilities and common types for the Query pattern.
 */

/**
 * This model represents the common fields of ALL weird and wonderful Query models
 */
export type CommonQueryProps = {
  page: number;
  pageSize: number;
  sort: string;
  authors: Immutable.Set<string>;
  coAuthors: Immutable.Set<string>;
  venues: Immutable.Set<string>;
  yearFilter: Nullable<YearRangeRecord>;
  fieldsOfStudy: Immutable.OrderedSet<string>;
};

/**
 * The 'stats' field in the QueryResponse records comes from this.
 * This is PaperSearchAggregations on the Scala side.
 */
export type PaperSearchAggregationsFromJS = {
  authors: FacetValueFromJS[];
  coAuthors: FacetValueFromJS[];
  fieldsOfStudy: FacetValueFromJS[];
  publicationTypes: FacetValueFromJS[];
  venues: FacetValueFromJS[];
  years: YearBucketFromFacet[];
  filteredYears?: Nullable<YearBucketFromFacet[]>;
  countOfPapersWithViewablePdf: number;
  totalNumCitedBy?: Nullable<number>;
  totalNumKeyCitations: Nullable<number>;
  totalCountAfterFilters: number;
};

// Union type of all Query records
export type AnyQuery = QueryRecord | ReferenceQueryRecord | CitationQueryRecord | VenueQueryRecord;
// Union type of all Query records' query string params objects
export type AnyQueryParams =
  | VenueQueryFromQueryParams
  | CitationQueryFromQueryParams
  | ReferenceQueryFromQueryParams
  | QueryFromQueryParams
  | VenueQueryFromQueryParams;

export function anyQueryToQueryParams(q: AnyQuery): AnyQueryParams {
  if (isVenueQueryRecord(q)) {
    return venueQueryRecordToQueryString(q);
  } else if (isCitationQueryRecord(q)) {
    return citationQueryRecordToQueryString(q);
  } else if (isReferenceQueryRecord(q)) {
    return referenceQueryRecordToQueryString(q);
  } else if (queryRecordToQueryString(q)) {
    return queryRecordToQueryString(q);
  }
  throw new Error('Missing query type case in anyQueryToQueryParams()');
}

/**
 * Converts a singular filter type name to the plural form (if appropriate).
 *
 * This is a workaround for the fact that the model tense doesn't match the tense used in query
 * string parameters and front-end naming conventions.
 *
 * @param {string} filterType
 *
 * @returns {string}
 */
export function filterTypeToProp(filterType: string): string {
  if (filterType === 'fieldsOfStudy') {
    return filterType;
  } else {
    return `${filterType}s`;
  }
}
