import ManageAlertsLink from './ManageAlertsLink';

import { getAlertSuccessMessage } from '@/utils/alert-util';
import { getString } from '@/content/i18n';
import { LOGIN_LOCATION } from '@/constants/LoginMethods';
import { ModalId } from '@/constants/Modal';
import { showModal } from '@/actions/ModalActionCreators';
import { STATUS } from '@/constants/Alert';
import AlertsStore from '@/stores/AlertsStore';
import Api from '@/api/Api';
import AuthStore from '@/stores/AuthStore';
import EnvInfo from '@/env/EnvInfo';
import EventTarget from '@/analytics/constants/EventTarget';
import IconButton from '@/components/shared/common/button/IconButton';
import logger from '@/logger';
import MessageStore from '@/stores/MessageStore';
import S2Dispatcher from '@/utils/S2Dispatcher';
import softError from '@/utils/softError';
import SubmitEvent from '@/analytics/models/SubmitEvent';
import Toggle from '@/components/shared/common/form/Toggle';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import idx from 'idx';
import PropTypes from 'prop-types';
import React from 'react';

export default class AlertButton extends React.PureComponent {
  static contextTypes = {
    alertsStore: PropTypes.instanceOf(AlertsStore).isRequired,
    api: PropTypes.instanceOf(Api).isRequired,
    authStore: PropTypes.instanceOf(AuthStore).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    messageStore: PropTypes.instanceOf(MessageStore).isRequired,
  };

  static defaultProps = {
    isToggleButton: false,
  };

  constructor(...args) {
    super(...args);

    const { alertsStore, authStore } = this.context;

    this.state = {
      ...this.getStateFromAlertsStore(),
    };

    alertsStore.registerComponent(this, () => {
      this.setState(this.getStateFromAlertsStore());
    });

    authStore.registerComponent(this, () => {
      this.setState(this.fetchAlertStatus());
    });
  }

  fetchAlertStatus() {
    const { api, authStore } = this.context;
    if (authStore.hasAuthenticatedUser()) {
      const { queryType, queryValue } = this.props;

      if (!queryValue) {
        return;
      }

      // Set timeout to avoid dispatcher conflicts
      setTimeout(() => api.findAlertByQueryTypeAndValue(queryType, queryValue), 0);
    }
  }

  getStateFromAlertsStore() {
    const { alertsStore } = this.context;
    const { queryType, queryValue } = this.props;

    return {
      // $FlowFixMe: Next line causes error when flow typing was added to context (See: #22776)
      alert: alertsStore.getAlert(queryType, queryValue),
    };
  }

  handleCreate = () => {
    const { alertsStore, authStore, dispatcher, api, messageStore } = this.context;
    const { queryType, queryValue, displayValue } = this.props;

    // this block should technically not ever be encountered so there is an issue that should be looked into if it is
    // queryValue and display value are ?strings for linting purposes (ex. for AuthorDetailRecords whose ids may be null)
    if (!queryValue || !displayValue) {
      // Show error/warning message
      softError(
        'alertButton.missingValues',
        'Cannot create alert without a queryValue and displayValue'
      );
      const header = getString(_ => _.alerts.create.message.error.header);
      const body = getString(_ => _.alerts.create.message.error.body);
      messageStore.addError(body, header, <ManageAlertsLink />);

      return;
    }

    authStore
      .ensureLogin({
        dispatcher: dispatcher,
        analyticData: { title: getString(_ => _.alerts.login) },
        location: LOGIN_LOCATION.alertButton,
        subLocation: this.props.subLocation,
        modalData: {
          adHeaderText: getString(_ => _.login.alertDescriptionHeader),
          adDescriptionText: getString(_ => _.login.alertDescriptionText),
        },
      })
      .then(() => {
        const maybeGetExistingAlert = alertsStore.isUninitialized()
          ? api.findAlertByQueryTypeAndValue(queryType, queryValue)
          : Promise.resolve();
        maybeGetExistingAlert.then(() => {
          const existingAlert = alertsStore.getAlert(queryType, queryValue);
          alertsStore.createAlert({ queryType, queryValue, displayValue });
          const { alertEmail, alertEmailIsVerified } = authStore.getUser() || {};

          if (!alertEmail || (!alertEmailIsVerified && !existingAlert)) {
            dispatcher.dispatch(
              showModal({
                id: ModalId.CREATE_ALERT,
              })
            );
          } else {
            // $FlowFixMe: Next line causes error when flow typing was added to context (See: #22776)
            this.handleAlertSave(existingAlert);
          }
        });
      })
      .catch(err => logger.warn(err));
  };

  handleAlertSave(existingAlert) {
    const { messageStore, alertsStore, api } = this.context;

    const { newAlert } = alertsStore;
    // $FlowFixMe: Next line causes error when flow typing was added to context (See: #22776)
    const { queryType, displayValue } = newAlert;

    if (existingAlert) {
      const header = getString(_ => _.alerts.create.message.duplicate.header);
      const body = getString(_ => _.alerts.create[queryType].exists, displayValue);
      messageStore.addWarning(body, header, <ManageAlertsLink />);
    } else {
      api
        // $FlowFixMe: Next line causes error when flow typing was added to context (See: #22776)
        .createAlert(newAlert)
        .then(() => {
          // Show success message
          // $FlowFixMe: Next line causes error when flow typing was added to context (See: #22776)
          this.showSuccessMessage(queryType, displayValue);
        })
        .catch(error => {
          // Show error/warning message
          softError('alertButton.apiError', 'Failed to create alert due to API issue', error);
          logger.error(error);
          if (error.errorCode === 'UserAlertLimitExceeded') {
            const header = getString(_ => _.alerts.create.message.limitError.header);
            const body = getString(_ => _.alerts.create.message.limitError.body);
            messageStore.addError(body, header, <ManageAlertsLink />);
          } else {
            const header = getString(_ => _.alerts.create.message.error.header);
            const body = getString(_ => _.alerts.create.message.error.body);
            messageStore.addError(body, header, <ManageAlertsLink />);
          }
        });
    }
  }

  handleToggle = () => {
    // $FlowFixMe: Next line causes error when flow typing was added to context (See: #22776)
    const { id, isActive, canonicalQueryValue } = this.state.alert;
    const alertStatus = isActive ? STATUS.DISABLED : STATUS.ACTIVE;
    const { TOGGLE_SUCCESS, TOGGLE_FAILURE } = EventTarget.UserAlerts;

    this.context.api
      // $FlowFixMe: Next line causes error when flow typing was added to context (See: #22776)
      .updateAlert({ alertId: id, status: alertStatus, canonicalQueryValue: canonicalQueryValue })
      .then(() => {
        trackAnalyticsEvent(SubmitEvent.create(TOGGLE_SUCCESS, { id, alertStatus }));
      })
      .catch(({ status, error }) => {
        trackAnalyticsEvent(SubmitEvent.create(TOGGLE_FAILURE, { id, alertStatus, error, status }));
      });
  };

  showSuccessMessage = (queryType, displayValue) => {
    const { authStore, envInfo, messageStore } = this.context;
    const isMobile = envInfo.isMobile;
    const alertEmail = authStore.getAlertEmail();

    if (isMobile) {
      const header = (
        <div className="flex-row-vcenter flex-space-between">
          {getString(_ => _.alerts.create.message.success.header)}
          <ManageAlertsLink />
        </div>
      );
      messageStore.addSuccess(null, header, null);
      return;
    }

    // $FlowFixMe: Next line causes error when flow typing was added to context (See: #22776)
    const successMessage = getAlertSuccessMessage(queryType, displayValue, alertEmail);
    const header = getString(_ => _.alerts.create[queryType].createdTitle);
    messageStore.addSuccess(successMessage, header, <ManageAlertsLink />);
  };

  render() {
    const { className, queryType, displayValue, isToggleButton, toggleLabel } = this.props;
    const { alert } = this.state;

    const action = alert ? (alert.isActive ? 'disable' : 'activate') : 'create';
    const onClick = alert ? this.handleToggle : this.handleCreate;

    if (isToggleButton) {
      return (
        <Toggle
          onClick={onClick}
          testId={`${action}-alert-button`}
          selected={idx(alert, _ => _.isActive) || false}
          className={className}
          id={`${displayValue}-${queryType}-alert-button`}>
          {toggleLabel}
        </Toggle>
      );
    }

    return (
      <IconButton
        className={className}
        onClick={onClick}
        responsiveText={getString(_ => _.paper.action.alert)}
        testId={`${action}-alert-button`}
        text={getString(_ => _.alerts[`${action}Alerts`][queryType])}
        iconProps={{ icon: 'fa-bell', width: '12', height: '12' }}
      />
    );
  }
}
