import {
  AuthoredLibraryPaperCue,
  CitedByLibraryPaperCue,
  CitesLibraryPaperCue,
  CitesYourPaperCue,
  CoauthorCue,
  CueType,
  YouCitedAuthorCue,
} from '@/constants/CueType';
import { heapCuePaperTitle } from '@/analytics/attributes/personalizedCues';
import { isUndefined, Nullable, ReactNodeish } from '@/utils/types';
import { mapHooksToProps } from '@/utils/react-utils';
import { useAppContext, useStateFromStore } from '@/AppContext';
import CueLibraryFolderNames from '@/components/shared/library/CueLibraryFolderNames';
import Link from '@/router/Link';
import Shimmer from '@/components/shared/loading/Shimmer';

import React from 'react';

import type { AuthorId, PaperId } from '@/stores/CueStore';

type PropsFromParent = {
  authorId?: Nullable<AuthorId>;
  // This can be nullable because sometimes we use this component to create a loading state
  // and there is no paperId in that case
  paperId?: Nullable<PaperId>;
  prioritizedCue: CueType;
  isLoading?: boolean;
};

type Props = {
  paperTitle?: Nullable<string>;
  folderId?: Nullable<number>;
} & PropsFromParent;

export function CuePaperRow({
  paperId,
  paperTitle,
  folderId,
  isLoading,
  prioritizedCue,
}: Props): ReactNodeish {
  // when the folder id is undefined it means the cue is not associated with a folder
  // when a folder id is null it means that it is in the unsorted papers folder
  const isFolderIdUndefined = isUndefined(folderId);

  return isLoading ? (
    <div className="cue-paper-row-loading">
      <Shimmer className="cue-paper-row-loading__title-one" />
      <Shimmer className="cue-paper-row-loading__title-two" />
      <Shimmer cssWidth="209px" />
      {!isFolderIdUndefined && (
        <Shimmer
          className="cue-paper-row-loading__folder-names"
          isInline={false}
          cssWidth="100px"
        />
      )}
    </div>
  ) : (
    <div className="cue-paper-row">
      <Link
        to="PAPER_DETAIL_BY_ID"
        params={{ paperId: paperId }}
        shouldUnderline={false}
        enableVisitedStyle={false}
        {...heapCuePaperTitle({ 'cue-type': prioritizedCue })}>
        <>
          <h3 className="cue-paper-row__paper-title"> {paperTitle}</h3>
        </>
      </Link>
      {!isFolderIdUndefined && (
        <CueLibraryFolderNames
          folderId={folderId}
          paperId={paperId}
          prioritizedCue={prioritizedCue}
        />
      )}
    </div>
  );
}

export default mapHooksToProps<Props, PropsFromParent>(CuePaperRow, props => {
  const { paperId, prioritizedCue } = props;
  const { cueStore } = useAppContext();

  const cueStoreState = useStateFromStore(cueStore, _ => {
    switch (prioritizedCue) {
      case AuthoredLibraryPaperCue:
      case CitedByLibraryPaperCue:
      case CitesLibraryPaperCue: {
        const maybeLibraryEntry = paperId ? _.getEntryByPaperId(paperId) : null;
        return {
          paperTitle: maybeLibraryEntry?.paperTitle || null,
          folderId: maybeLibraryEntry?.folderId || null,
        };
      }
      case CitesYourPaperCue:
      case CoauthorCue:
      case YouCitedAuthorCue: {
        return {
          paperTitle: paperId ? _.getCuePaperByPaperId(paperId)?.title || null : null,
        };
      }
      default: {
        return {
          paperTitle: null,
          folderId: null,
        };
      }
    }
  });

  return {
    ...props,
    ...cueStoreState,
  };
});
