import { PaperFromJS } from './Paper';

import { gql } from 'graphql-tag';
import Immutable from 'immutable';

export type CuePaperFromGraphQL = {
  id: string;
  title: string;
};

type Props = {
  id: string;
  title: string;
};

const defaultProps: Props = {
  id: '',
  title: '',
};

export const CuePaperRecordFactory = Immutable.Record<Props>(defaultProps);

export type CuePaperRecord = Immutable.RecordOf<Props>;

export function getCuePaperFromGraphQL(args: CuePaperFromGraphQL): CuePaperRecord {
  return CuePaperRecordFactory({
    id: args.id,
    title: args.title,
  });
}

export function getCuePaperFromPaperRecord(args: PaperFromJS): CuePaperRecord {
  return CuePaperRecordFactory({
    id: args.id || defaultProps.id,
    title: args.title.text,
  });
}

export const CUE_PAPER_FRAGMENT_NAME = 'CuePaperRecordFragment';

export const CUE_PAPER_FRAGMENT = gql`
    fragment ${CUE_PAPER_FRAGMENT_NAME} on Paper {
      title
    }
`;
