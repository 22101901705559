import { getString } from '@/content/i18n';
import { ModalId } from '@/constants/Modal';
import { showModal } from '@/actions/ModalActionCreators';
import CLButton from '@/components/library/button/CLButton';
import Icon from '@/components/shared/icon/Icon';
import S2Dispatcher from '@/utils/S2Dispatcher';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const LEFT = 'left';
const RIGHT = 'right';

export const CIRCLE_POSITION = Object.freeze({
  RIGHT,
  LEFT,
});

export default class FTUECard extends React.PureComponent {
  static contextTypes = {
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
  };

  dispatchFTUEModal = () => {
    const { dispatcher } = this.context;
    dispatcher.dispatch(showModal({ id: ModalId.CREATE_LIBRARY_FTUE }));
  };

  render() {
    const {
      borderIconId,
      cardName,
      circlePosition,
      className,
      contentIconId,
      explanationIconId,
      showOverlay,
    } = this.props;

    const cssClasses = classnames('onboarding-pages__content__card', className);

    return (
      <div className={`onboarding-pages__content__${cardName}-card`}>
        <div className={cssClasses}>
          <div className="flex-row">
            <div className="onboarding-pages__content__card__text">
              <div className="flex-column">
                {contentIconId && (
                  <div className="onboarding-pages__content__icon-container">
                    <Icon
                      className="onboarding-pages__content__icon"
                      icon={contentIconId}
                      height="15"
                      width="11"
                    />
                  </div>
                )}
                {getString(_ => _.research.onboardingHomepage[`${cardName}Description`])}
                {!borderIconId && (
                  <CLButton
                    onClick={this.dispatchFTUEModal}
                    label={getString(_ => _.research.onboardingHomepage.letsGetStartedButton)}
                    className="onboarding-pages__get-started-button onboarding-pages__get-started-button--large-card"
                  />
                )}
              </div>
            </div>
            <div className="onboarding-pages__content__card__icon onboarding-pages__content__card__icon--large-card">
              <Icon
                className={`onboarding-pages__content__card__icon__${cardName}-card`}
                icon={explanationIconId}
                height="168"
                width="168"
              />
            </div>
          </div>
          <div
            className={`onboarding-pages__content__card__background-circle--${circlePosition} onboarding-pages__content__card__background-circle--large-card-${circlePosition}`}
          />
        </div>
        {!!borderIconId && (
          <div className="onboarding-pages__content__icon-container onboarding-pages__content__icon-container-position">
            <Icon
              className="onboarding-pages__content__icon"
              icon={borderIconId}
              height="15"
              width="11"
            />
            {showOverlay && (
              <div className="onboarding-pages__content__overlay onboarding-pages__content__icon-overlay" />
            )}
          </div>
        )}

        {showOverlay && (
          <div className="onboarding-pages__content__overlay onboarding-pages__content__card-overlay" />
        )}
      </div>
    );
  }
}
