import { getString } from '@/content/i18n';
import { useAppContext } from '@/AppContext';
import CLIconButton, { TYPE } from '@/components/library/button/CLIconButton';
import Icon from '@/components/shared/icon/Icon';
import Link from '@/router/Link';

import classnames from 'classnames';
import React from 'react';

/* Use CLPaperActionLibrary for the business logic and display. This component is just the display */
export default function CLPaperLibraryButton({
  isInLibrary,
  className,
  shouldLinkToLibrary,
  icon,
  label,
  ...props
}) {
  const { envInfo } = useAppContext();
  const isMobile = envInfo.isMobile;
  const saveToLibrary = isMobile
    ? getString(_ => _.paper.action.addToLibrary)
    : getString(_ => _.paper.action.saveShort);

  const buttonText = isInLibrary ? getString(_ => _.paper.action.goToLibrary) : saveToLibrary;
  const buttonLabel = label ? label : buttonText;
  const ariaLabel = isInLibrary
    ? getString(_ => _.paper.action.inLibraryAriaLabel)
    : getString(_ => _.paper.action.saveToLibraryAriaLabel);

  const button = (
    <CLIconButton
      {...props}
      aria-label={ariaLabel}
      type={TYPE.TERTIARY}
      icon={icon}
      label={buttonLabel}
      className={classnames('cl-paper-action__button', className)}
    />
  );

  if (!isInLibrary || !shouldLinkToLibrary) {
    return button;
  }

  return (
    <Link to="LIBRARY_WITH_FOLDERS" className="flex-row cl-paper-library">
      {button}
    </Link>
  );
}

CLPaperLibraryButton.defaultProps = {
  isInLibrary: false,
  shouldLinkToLibrary: true,
  testId: 'cl-paper-library',
  icon: <Icon icon={'fa-bookmark'} height="11" width="11" />,
};
