import Immutable from 'immutable';

import type { FeatureKey } from './Feature';
import type { Nullable } from '@/utils/types';

export type FeatureFromJS = {
  key: FeatureKey;
  description: string;
  fallbackState: boolean;
  includeInPerfMetrics?: Nullable<boolean>;
  includeAsEventProperty?: Nullable<boolean>;
};

type Props = {
  KEY: FeatureKey;
  description: string;
  fallbackState: boolean;
  includeInPerfMetrics: boolean;
  includeAsEventProperty: boolean;
};

const defaultProps: Props = {
  // @ts-expect-error -- There is no good fallback for KEY
  KEY: '',
  description: '',
  fallbackState: false,
  includeInPerfMetrics: false,
  includeAsEventProperty: true,
};

export const FeatureRecordFactory = Immutable.Record(defaultProps);

export type FeatureRecord = Immutable.RecordOf<Props>;

export function getFeatureRecordFromJS(args: FeatureFromJS): FeatureRecord {
  const {
    key: KEY,
    description,
    fallbackState,
    includeInPerfMetrics,
    includeAsEventProperty = true,
  } = args;
  return FeatureRecordFactory({
    KEY,
    description,
    fallbackState: !!fallbackState,
    includeInPerfMetrics: !!includeInPerfMetrics,
    includeAsEventProperty: !!includeAsEventProperty,
  });
}
