import { CiteSeeState, CiteSeeStateRecordFactory } from '@/models/reader-widgets/ReaderCiteSee';
import {
  NoteTakingState,
  NoteTakingStateRecordFactory,
} from '@/models/reader-widgets/ReaderNoteTaking';
import { Nullable } from '@/utils/types';
import { ReaderWidget, ReaderWidgetState } from '@/models/reader-widgets/ReaderWidgets';
import {
  SkimmingInteractionState,
  SkimmingInteractionStateRecordFactory,
  SkimmingState,
  SkimmingStateRecordFactory,
} from '@/models/reader-widgets/ReaderSkimming';
import { TermsState, TermsStateRecordFactory } from '@/models/reader-widgets/ReaderTerms';

import type { DispatchPayload } from '@/utils/S2Dispatcher';

export const ReaderWidgetAction = {
  UPDATE_STATE: 'UPDATE_READER_WIDGET_STATE',
  SET_ACTIVE_WIDGET: 'SET_ACTIVE_WIDGET',
} as const;

export type UpdateReaderWidgetStateAction = DispatchPayload<{
  actionType: typeof ReaderWidgetAction.UPDATE_STATE;
  widgetType: ReaderWidget;
  widgetState: ReaderWidgetState;
}>;

export type SetActiveReaderWidgetAction = DispatchPayload<{
  actionType: typeof ReaderWidgetAction.SET_ACTIVE_WIDGET;
  widgetType: Nullable<ReaderWidget>;
}>;

export function updateCiteSeeState(citeSeeState: CiteSeeState): UpdateReaderWidgetStateAction {
  return {
    actionType: ReaderWidgetAction.UPDATE_STATE,
    widgetType: ReaderWidget.CITE_SEE,
    widgetState: CiteSeeStateRecordFactory(citeSeeState),
  };
}

export function updateReaderSkimmingState(
  skimmingState: SkimmingState
): UpdateReaderWidgetStateAction {
  return {
    actionType: ReaderWidgetAction.UPDATE_STATE,
    widgetType: ReaderWidget.SKIMMING,
    widgetState: SkimmingStateRecordFactory(skimmingState),
  };
}

export function updateReaderSkimmingInteractionState(
  skimmingInteractionState: SkimmingInteractionState
): UpdateReaderWidgetStateAction {
  return {
    actionType: ReaderWidgetAction.UPDATE_STATE,
    widgetType: ReaderWidget.SKIMMING_INTERACTION,
    widgetState: SkimmingInteractionStateRecordFactory(skimmingInteractionState),
  };
}

export function setActiveReaderWidget(widget: Nullable<ReaderWidget>): SetActiveReaderWidgetAction {
  return {
    actionType: ReaderWidgetAction.SET_ACTIVE_WIDGET,
    widgetType: widget,
  };
}

export function updateReaderNotetakingState(
  noteTakingState: NoteTakingState
): UpdateReaderWidgetStateAction {
  return {
    actionType: ReaderWidgetAction.UPDATE_STATE,
    widgetType: ReaderWidget.NOTE_TAKING,
    widgetState: NoteTakingStateRecordFactory(noteTakingState),
  };
}

export function updateTermsState(termsState: TermsState): UpdateReaderWidgetStateAction {
  return {
    actionType: ReaderWidgetAction.UPDATE_STATE,
    widgetType: ReaderWidget.TERM_UNDERSTANDING,
    widgetState: TermsStateRecordFactory(termsState),
  };
}
