import React from 'react';

export default function IconCheckInGreenCircle(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 100 100">
      <circle cx="50" cy="50" r="50" fill="#ACE5AC" />
      <svg viewBox="0 0 45 45" x="17" y="12">
        <path
          fill="#2EB13A"
          d="M28.294,3.968c-0.918-0.615-2.16-0.373-2.775,0.543L12.173,24.2l-7.375-8.102c-0.743-0.816-2.007-0.875-2.826-0.132 c-0.816,0.744-0.876,2.008-0.132,2.826l9.124,10.021c0.395,0.434,0.936,0.653,1.479,0.653c0.48,0,0.962-0.172,1.346-0.521 c0.227-0.207,0.389-0.455,0.498-0.722l14.45-21.48C29.354,5.827,29.111,4.584,28.194,3.968z"
        />
      </svg>
    </symbol>
  );
}
