import AnalyticsEvent from './AnalyticsEvent';

import EventType from '@/analytics/constants/EventType';

export type EventData = {
  method: string;
  action: any; // I don't believe we ever use this field
  state: (typeof AuthEvent.State)[keyof typeof AuthEvent.State];
  location: string;
  subLocation: string;
};

/** An event associated with the user authentication process. */
export default class AuthEvent extends AnalyticsEvent {
  /**
   * @param {string} method - a string identifying the authentication method / provider, i.e.
   * "google", "facebook" or "twitter".
   * @param {AuthEvent.State} state - the authentication state expressed by the event.
   * @source {string} location - location where login was initialized
   * @source {string} subLocation - sub location where login was initialized
   * (Ex. location = alert-button, subLocation = watch-topic, the alert button prompted the modal but it was the watch-topic alert button)
   */
  constructor({ method, action, state, location, subLocation }: EventData) {
    super(EventType.AUTH, { method, action, state, location, subLocation });
  }

  static create(data: EventData): AuthEvent {
    return new AuthEvent(data);
  }

  static State = {
    RENDERED: 'rendered',
    STARTED: 'started',
    FAILED: 'failed',
    SUCCEEDED: 'succeeded',
    CANCELLED: 'cancelled',
    LOGGED_OUT: 'logged_out',
    FORBIDDEN: 'forbidden',
  } as const;
}
