import { CITESEE_RECORD_NAME, CiteSeeStateRecord } from './ReaderCiteSee';
import { NOTE_TAKING_RECORD_NAME, NoteTakingStateRecord } from './ReaderNoteTaking';
import {
  SKIMMING_INTERACTION_RECORD_NAME,
  SKIMMING_RECORD_NAME,
  SkimmingInteractionStateRecord,
  SkimmingStateRecord,
} from './ReaderSkimming';
import { TERMS_RECORD_NAME, TermsStateRecord } from './ReaderTerms';

import Immutable from 'immutable';

export type ReaderWidgetState =
  | CiteSeeStateRecord
  | NoteTakingStateRecord
  | SkimmingStateRecord
  | SkimmingInteractionStateRecord
  | TermsStateRecord;

export enum ReaderWidget {
  CITE_SEE = 'citesee',
  NOTE_TAKING = 'notetaking',
  SKIMMING = 'skimming',
  SKIMMING_INTERACTION = 'skimming_interaction',
  TERM_UNDERSTANDING = 'term_understanding',
}

export function isCiteSeeStateRecord(state: ReaderWidgetState): boolean {
  return Immutable.Record.getDescriptiveName(state) === CITESEE_RECORD_NAME;
}

export function isNoteTakingStateRecord(state: ReaderWidgetState): boolean {
  return Immutable.Record.getDescriptiveName(state) === NOTE_TAKING_RECORD_NAME;
}

export function isSkimmingStateRecord(state: ReaderWidgetState): boolean {
  return Immutable.Record.getDescriptiveName(state) === SKIMMING_RECORD_NAME;
}

export function isSkimmingInteractionStateRecord(state: ReaderWidgetState): boolean {
  return Immutable.Record.getDescriptiveName(state) === SKIMMING_INTERACTION_RECORD_NAME;
}

export function isTermsStateRecord(state: ReaderWidgetState): boolean {
  return Immutable.Record.getDescriptiveName(state) === TERMS_RECORD_NAME;
}

export const TOGGLE_ON = 'on';
export const TOGGLE_OFF = 'off';
