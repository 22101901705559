import ScreenReaderAnnouncement, {
  Politeness,
} from '@/components/util/a11y/ScreenReaderAnnouncement';

import classnames from 'classnames';
import React from 'react';

const ERROR = 'error';
const FORM = 'form';
const INFO = 'info';
const PAGE = 'page';
const SUCCESS = 'success';
const WARNING = 'warning';

export const LEVEL = Object.freeze({
  FORM,
  PAGE,
});

export const SEVERITY = Object.freeze({
  SUCCESS,
  INFO,
  WARNING,
  ERROR,
});

export default function CLAlertBase({ children, className, icon, level, severity, ...otherProps }) {
  return (
    <div
      {...otherProps}
      className={classnames(
        {
          'cl-alert': true,
          [`cl-alert--level-${level || ''}`]: !!level,
          [`cl-alert--severity-${severity || ''}`]: !!severity,
        },
        className
      )}>
      <div className="cl-alert__body">
        {icon && <div className="cl-alert__icon">{icon}</div>}
        <div className="cl-alert__message">{children}</div>
        <ScreenReaderAnnouncement politeness={Politeness.ASSERTIVE} message={children} />
      </div>
    </div>
  );
}
