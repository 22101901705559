import CLPaperAlertButton, { ALERT_BUTTON_TYPE } from './buttons/CLPaperAlertButton';
import MockPaper from '../../../../../../test/utils/MockPaper';

import { addHeapProps } from '@/components/util/HeapTracking';
import { ALERT_ACTION, AlertQueryType, QUERY_TYPE, STATUS } from '@/constants/Alert';
import { AlertRecord } from '@/models/Alert';
import { AnalyticsContextHelper } from '@/components/library/paper/CLPaperAnalytics';
import { ExampleConfig } from '@/components/library/ExampleUtils';
import { getAlertSuccessMessage } from '@/utils/alert-util';
import { getString } from '@/content/i18n';
import { heapAlertAction } from '@/analytics/attributes/paperObject';
import { HeapProps } from '@/analytics/heapUtils';
import { LOGIN_LOCATION, LoginLocationValue } from '@/constants/LoginMethods';
import { ModalId } from '@/constants/Modal';
import { Nullable, ReactNodeish, ValueOf } from '@/utils/types';
import { showModal } from '@/actions/ModalActionCreators';
import AlertsStore from '@/stores/AlertsStore';
import Api from '@/api/Api';
import AuthStore from '@/stores/AuthStore';
import EnvInfo from '@/env/EnvInfo';
import EventTarget from '@/analytics/constants/EventTarget';
import logger from '@/logger';
import ManageAlertsLink from '@/components/shared/alerts/ManageAlertsLink';
import MessageStore from '@/stores/MessageStore';
import S2Dispatcher from '@/utils/S2Dispatcher';
import softError from '@/utils/softError';
import SubmitEvent from '@/analytics/models/SubmitEvent';
import Toggle from '@/components/shared/common/form/Toggle';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import classnames from 'classnames';
import invariant from 'invariant';
import PropTypes from 'prop-types';
import React from 'react';

const TOGGLE = 'toggle';

export const TYPE = Object.freeze({
  ...ALERT_BUTTON_TYPE,
  TOGGLE,
});

export type OnClickAlertData = {
  action: string;
  alertId: Nullable<string>;
  queryType: string;
  queryValue: string;
};

type Props = {
  className?: Nullable<string>;
  displayValue: string;
  heapProps?: Nullable<HeapProps>;
  onClick?: Nullable<() => void>;
  queryType: string;
  queryValue: string;
  subLocation?: Nullable<LoginLocationValue>;
  type?: Nullable<AlertActionButtonType>;
  createAlertLabel?: string;
};

type State = {
  alert: Nullable<AlertRecord>;
};

export type AlertActionButtonType = ValueOf<typeof TYPE>;

export default class CLPaperActionAlert extends React.PureComponent<Props, State> {
  declare context: {
    alertsStore: AlertsStore;
    api: Api;
    authStore: AuthStore;
    dispatcher: S2Dispatcher;
    envInfo: EnvInfo;
    messageStore: MessageStore;
  };

  static contextTypes = {
    alertsStore: PropTypes.instanceOf(AlertsStore).isRequired,
    api: PropTypes.instanceOf(Api).isRequired,
    authStore: PropTypes.instanceOf(AuthStore).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    messageStore: PropTypes.instanceOf(MessageStore).isRequired,
  };

  static defaultProps = {
    type: ALERT_BUTTON_TYPE.TERTIARY,
    createAlertLabel: getString(_ => _.paper.action.alert),
  };

  _onClickAlertAnalytics;

  constructor(...args: [any]) {
    super(...args);
    const { alertsStore, authStore } = this.context;

    this.state = {
      ...this.getStateFromAlertsStore(),
    };

    alertsStore.registerComponent(this, () => {
      this.setState(this.getStateFromAlertsStore());
    });

    authStore.registerComponent(this, () => {
      this.fetchAlertStatus();
    });
  }

  fetchAlertStatus(): void {
    const { api, authStore } = this.context;
    if (authStore.hasAuthenticatedUser()) {
      const { queryType, queryValue } = this.props;

      if (!queryValue) {
        return;
      }

      // Set timeout to avoid dispatcher conflicts
      // TODO: ESLint doesn't like this line, but it's how we do things
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      setTimeout(() => api.findAlertByQueryTypeAndValue(queryType, queryValue), 0);
    }
  }

  getStateFromAlertsStore(): State {
    const { alertsStore } = this.context;
    const { queryType, queryValue } = this.props;

    return {
      alert: alertsStore.getAlert(queryType, queryValue),
    };
  }

  setAnalyticsCallbacks = ({ onClickAlert }): void => {
    this._onClickAlertAnalytics = onClickAlert;
  };

  handleCreate = async (): Promise<void> => {
    const { alertsStore, authStore, dispatcher, api, messageStore } = this.context;
    const { queryType, queryValue, displayValue, onClick } = this.props;

    if (this._onClickAlertAnalytics) {
      this._onClickAlertAnalytics({
        action: ALERT_ACTION.CREATE,
        alertId: null,
        queryType,
        queryValue,
      });
    }

    if (typeof onClick === 'function') {
      onClick();
    }

    // this block should technically not ever be encountered so there is an issue that should be looked into if it is
    // queryValue and display value are ?strings for linting purposes (ex. for AuthorDetailRecords whose ids may be null)
    if (!queryValue || !displayValue) {
      // Show error/warning message
      softError(
        'clAlertButton.missingValues',
        'Cannot create alert without a queryValue and displayValue'
      );
      const header = getString(_ => _.alerts.create.message.error.header);
      const body = getString(_ => _.alerts.create.message.error.body);
      messageStore.addError(body, header, <ManageAlertsLink />);

      return;
    }

    try {
      await authStore.ensureLogin({
        dispatcher: dispatcher,
        analyticData: { title: getString(_ => _.alerts.login) },
        location: LOGIN_LOCATION.alertButton,
        subLocation: this.props.subLocation,
        modalData: {
          adHeaderText: getString(_ => _.login.alertDescriptionHeader),
          adDescriptionText: getString(_ => _.login.alertDescriptionText),
        },
      });

      if (alertsStore.isUninitialized()) {
        await api.findAlertByQueryTypeAndValue(queryType, queryValue);
      }

      const existingAlert = alertsStore.getAlert(queryType, queryValue);
      alertsStore.createAlert({ queryType, queryValue, displayValue });

      const { alertEmail, alertEmailIsVerified } = authStore.getUser() || {};

      if (!alertEmail || (!alertEmailIsVerified && !existingAlert)) {
        dispatcher.dispatch(
          showModal({
            id: ModalId.CREATE_ALERT,
          })
        );
      } else {
        this.handleAlertSave(existingAlert);
      }
    } catch (err) {
      softError('clAlertButton.createError', 'Error creating alert', err);
    }
  };

  async handleAlertSave(existingAlert: Nullable<AlertRecord>): Promise<void> {
    const { messageStore, alertsStore, api } = this.context;
    const { newAlert } = alertsStore;

    if (existingAlert) {
      const header = getString(_ => _.alerts.create.message.duplicate.header);
      const body = getString(
        _ => _.alerts.create[existingAlert.queryType].exists,
        existingAlert.displayValue
      );
      messageStore.addWarning(body, header, <ManageAlertsLink />);
      return;
    }

    if (newAlert) {
      const { queryType, displayValue } = newAlert;

      try {
        await api.createAlert(newAlert);
        // Show success message
        this.showSuccessMessage(queryType, displayValue);
      } catch (error) {
        // Show error/warning message
        softError('clAlertButton.apiError', 'Failed to create alert due to API issue', error);
        logger.error(error);
        if (error.errorCode === 'UserAlertLimitExceeded') {
          const header = getString(_ => _.alerts.create.message.limitError.header);
          const body = getString(_ => _.alerts.create.message.limitError.body);
          messageStore.addError(body, header, <ManageAlertsLink />);
        } else {
          const header = getString(_ => _.alerts.create.message.error.header);
          const body = getString(_ => _.alerts.create.message.error.body);
          messageStore.addError(body, header, <ManageAlertsLink />);
        }
      }
    } else {
      softError('clAlertButton.newAlertError', 'Could not find new alert to save');
      const header = getString(_ => _.alerts.create.message.error.header);
      const body = getString(_ => _.alerts.create.message.error.body);
      messageStore.addError(body, header, <ManageAlertsLink />);
    }
  }

  handleToggle = (): void => {
    const { alert } = this.state;

    if (!alert) {
      softError('clAlertButton.updateFailed', 'Could not find alert to update');
      return;
    }

    const { id, isActive, queryType, queryValue, canonicalQueryValue } = alert;
    const alertStatus = isActive ? STATUS.DISABLED : STATUS.ACTIVE;
    const { TOGGLE_SUCCESS, TOGGLE_FAILURE } = EventTarget.UserAlerts;

    if (this._onClickAlertAnalytics) {
      this._onClickAlertAnalytics({
        action: isActive ? ALERT_ACTION.DISABLED : ALERT_ACTION.ACTIVATE,
        alertId: id,
        queryType,
        queryValue,
      });
    }

    this.context.api
      .updateAlert({
        // @ts-expect-error -- the types say this should already be number, but at runtime it can be string
        alertId: parseInt(id),
        status: alertStatus,
        queryValue,
        canonicalQueryValue: canonicalQueryValue,
      })
      .then(() => {
        trackAnalyticsEvent(SubmitEvent.create(TOGGLE_SUCCESS, { id, alertStatus }));
      })
      .catch(({ status, error }) => {
        trackAnalyticsEvent(SubmitEvent.create(TOGGLE_FAILURE, { id, alertStatus, error, status }));
      });
  };

  showSuccessMessage = (queryType: AlertQueryType, displayValue: string): void => {
    const { authStore, envInfo, messageStore } = this.context;
    const isMobile = envInfo.isMobile;
    const alertEmail = authStore.getAlertEmail();
    invariant(alertEmail, 'authStore lost track of alert email after being saved');

    if (isMobile) {
      const header = (
        <div className="flex-row-vcenter flex-space-between">
          {getString(_ => _.alerts.create.message.success.header)}
          <ManageAlertsLink />
        </div>
      );
      messageStore.addSuccess(null, header, null);
      return;
    }

    const successMessage = getAlertSuccessMessage(queryType, displayValue, alertEmail);
    const header = getString(_ => _.alerts.create[queryType].createdTitle);
    messageStore.addSuccess(successMessage, header, <ManageAlertsLink />);
  };

  renderButton(): ReactNodeish {
    const { className, heapProps, type, queryType, createAlertLabel } = this.props;
    const { alert } = this.state;
    const action = alert
      ? alert.isActive
        ? ALERT_ACTION.DISABLED
        : ALERT_ACTION.ACTIVATE
      : ALERT_ACTION.CREATE;
    const onClick = alert ? this.handleToggle : this.handleCreate;

    switch (type) {
      case TYPE.TOGGLE: {
        return (
          <span {...(heapProps ? addHeapProps(heapProps) : heapAlertAction(action))}>
            <Toggle
              onClick={onClick}
              testId={`${action}-alert-button`}
              selected={alert?.isActive || false}
              className={className}
            />
          </span>
        );
      }
      case TYPE.PRIMARY: {
        return (
          <CLPaperAlertButton
            action={action}
            queryType={queryType}
            className={className}
            heapProps={heapProps || heapAlertAction(action)}
            onClick={onClick}
            type={TYPE.PRIMARY}
          />
        );
      }
      case TYPE.TERTIARY: {
        return (
          <CLPaperAlertButton
            action={action}
            queryType={queryType}
            className={classnames(
              {
                'cl-paper-action__button--blue-outline': !alert?.isActive,
              },
              className
            )}
            heapProps={heapProps || heapAlertAction(action)}
            onClick={onClick}
            type={TYPE.TERTIARY}
            label={
              action === ALERT_ACTION.DISABLED
                ? getString(_ => _.paper.action.disableAlert)
                : createAlertLabel
            }
          />
        );
      }
      default: {
        softError('alert-button', 'Alert button type not supported');
        return null;
      }
    }
  }

  render(): ReactNodeish {
    return (
      <AnalyticsContextHelper onUpdate={this.setAnalyticsCallbacks}>
        {this.renderButton()}
      </AnalyticsContextHelper>
    );
  }
}

// TODO(#21359): Split this into a separate file
const PAPER = MockPaper({
  id: '94e2260617274d17ef9ebf84b5081d5fc919cbab',
});

export const exampleConfig: ExampleConfig = {
  getComponent: () => CLPaperActionAlert,
  fields: [
    {
      name: 'className',
      desc: 'HTML classes to be added to the component',
      value: {
        type: 'text',
        default: '',
      },
    },
    {
      name: 'type',
      desc: 'Button style type',
      value: {
        type: 'select',
        default: TYPE.TERTIARY,
        options: [TYPE.TERTIARY, TYPE.PRIMARY, TYPE.TOGGLE],
      },
    },
  ],

  examples: [
    {
      title: 'Tertiary Alert Button',
      desc: 'Primarily used on the Paper tile',
      props: {
        displayValue: PAPER.title.text,
        queryType: QUERY_TYPE.PAPER_CITATION,
        queryValue: PAPER.id,
      },
      render: comp => <div style={{ width: '300px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'Primary Alert Button',
      desc: 'Primarily used on the PDP, THP, and Feeds page',
      props: {
        displayValue: PAPER.title.text,
        queryType: QUERY_TYPE.PAPER_CITATION,
        queryValue: PAPER.id,
        type: TYPE.PRIMARY,
      },
      render: comp => <div style={{ width: '300px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'Toggle Alert Button',
      desc: 'Toggle used AHP',
      props: {
        displayValue: PAPER.title.text,
        queryType: QUERY_TYPE.PAPER_CITATION,
        queryValue: PAPER.id,
        type: TYPE.TOGGLE,
      },
      render: comp => (
        <div style={{ width: '300px', padding: '16px', display: 'flex' }}>
          {comp}
          <div style={{ marginLeft: '10px' }}>{getString(_ => _.author.alerts.citations)}</div>
        </div>
      ),
    },
  ],

  events: {},
};
