import React from 'react';

export default function IconCognitoLogin(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 36 36">
      <path
        d="M18,0C8.06,0,0,8.06,0,18s8.06,18,18,18s18-8.06,18-18S27.94,0,18,0z
        M28.27,23.77c0,1.06-0.86,1.93-1.93,1.93H9.66
        c-1.06,0-1.93-0.86-1.93-1.93v-8.2c0-0.2,0.23-0.31,0.39-0.19c0.9,0.7,2.09,1.58,6.18,4.56c0.85,0.62,2.28,1.92,3.7,1.91
        c1.43,0.01,2.89-1.31,3.7-1.91c4.09-2.97,5.28-3.86,6.18-4.56c0.15-0.13,0.39-0.01,0.39,0.19L28.27,23.77L28.27,23.77z
        M28.27,12.99
        c0,0.3-0.14,0.58-0.37,0.76c-1.23,0.96-1.63,1.3-6.96,5.16c-0.67,0.49-2.01,1.68-2.94,1.66c-0.93,0.01-2.27-1.17-2.94-1.66
        c-5.32-3.86-5.72-4.2-6.95-5.16c-0.23-0.18-0.37-0.46-0.37-0.76v-0.76c0-1.06,0.86-1.93,1.93-1.93h16.69c1.06,0,1.93,0.86,1.93,1.93
        V12.99z"
      />
    </symbol>
  );
}
