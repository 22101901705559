import React from 'react';

export default function IconBrokenLink(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 200 200">
      <path
        className="st0"
        d="M85.2,91.2C85.1,91.2,85.1,91.3,85.2,91.2c-10.4,10.5-10.5,27.4,0,37.8c0.9,0.9,2.6,2.2,3.6,3
        c1.1,0.8,2.6,0.7,3.6-0.3l3.7-3.8c1.5-1.4,2.2-3.4,2.1-5.2c-0.1-0.8-0.6-1.8-1.3-2.2c-0.5-0.4-1.1-0.8-1.8-1.5
        c-4.9-4.9-4.9-13,0-17.9l6.9-6.9c1.1-1.1,1.1-2.9,0-4l-5.8-5.9c-1.1-1.1-2.9-1.1-4,0L85.2,91.2z"
      />
      <path
        className="st0"
        d="M106.3,101.2c-1.1-0.8-2.6-0.7-3.6,0.3l-3.7,3.7c-1.5,1.4-2.1,3.4-2.1,5.2c0.1,0.8,0.6,1.8,1.3,2.2
        c0.5,0.4,1.1,0.8,1.8,1.5c4.9,4.9,4.9,13,0,17.9l-11.8,11.8c-4.9,4.9-13,4.9-18-0.1c-4.9-4.9-4.7-13,0.1-17.9l2.3-2.2
        c0.8-0.8,1-1.9,0.7-2.9c-1-3.1-1.6-6.1-1.7-9.3c-0.1-2.5-3.1-3.6-4.8-2l-6.5,6.5c-10.4,10.4-10.4,27.4,0,37.8
        c10.4,10.4,27.4,10.4,37.8,0l11.8-11.8c10.4-10.4,10.5-27.2-0.1-37.7C109,103.3,107.4,102,106.3,101.2z"
      />
      <path
        className="st0"
        d="M139.7,74.6c-10.4-10.4-27.4-10.4-37.8,0l-0.9,0.9c-1.1,1.1-1.1,2.9,0,4l5.9,5.9c1.1,1.1,2.9,1.1,4,0l0.9-0.9
        c4.9-4.9,13-4.9,18,0.1c4.9,4.9,4.7,13-0.2,17.9l-2.3,2.3c-0.8,0.8-1,1.9-0.7,2.9c1,3,1.6,6.1,1.7,9.2c0.1,2.5,3.1,3.6,4.8,1.9
        l6.5-6.5C150.1,101.9,150.1,84.9,139.7,74.6z"
      />
      <path
        className="st1"
        d="M56.4,40c1.9-1.9,5.1-1.9,7,0l17.9,17.9c1.9,1.9,1.9,5.1,0,7c-1.9,1.9-5.1,1.9-7,0L56.4,47
        C54.5,45,54.5,41.9,56.4,40z"
      />
      <path
        className="st1"
        d="M97.8,41.5c2.7,0.6,4.3,3.3,3.7,6l-3.6,15.1c-0.6,2.7-3.3,4.3-6,3.7c-2.7-0.6-4.3-3.3-3.7-6l3.6-15.1
        C92.4,42.5,95.1,40.9,97.8,41.5z"
      />
      <path
        className="st1"
        d="M82.5,74.2c0.7,2.7-1,5.3-3.6,6L63.8,84c-2.7,0.7-5.3-1-6-3.6c-0.7-2.7,1-5.3,3.6-6l15.1-3.8
        C79.1,69.9,81.8,71.5,82.5,74.2z"
      />
    </symbol>
  );
}
