/**
 * This file contains utilities for delaying promise chains based on specific timing needs.
 */

export type Resolver<T> = (result: T | PromiseLike<T>) => void;
export type Rejector = (result: any) => void;

// Resolves at the time of a date object
//   Examples:
//      await delayUntil(new Date('2019-07-17'))
//      .then(delayUntil(moment().add(10, 'seconds').toDate()))
export async function delayUntil<T>(date: Date, optArg?: T): Promise<T> {
  const timeoutMs = date.valueOf() - Date.now();
  return await delayFor(timeoutMs, optArg);
}

// Resolves after a timeout
//   Examples:
//      await delayFor(100)
//      .then(() => delayFor(100))      // the arrow function is needed because the timer starts when the function is called
//      .then(delayFor.bind(null, 100)) // Optionally, you can bind the time onto the function
export async function delayFor<T>(timeoutMs: number, optArg?: T): Promise<T> {
  await new Promise(resolve => setTimeout(resolve, timeoutMs));
  // @ts-expect-error -- TS doesn't know T can be void
  return optArg;
}

// Resolves after a timeout randomized within a range
//   Examples:
//     await delayBetween(3000, 6000)
export async function delayBetween<T>(minMs: number, maxMs: number, optArg?: T): Promise<T> {
  return await delayFor(minMs + (maxMs - minMs) * Math.random(), optArg);
}

// Resolves at the end of the current microtask queue
//   Examples:
//      await nextMicroTask()
//      .then(nextMicroTask)
export async function nextMicroTask<T>(optArg?: T): Promise<T> {
  await Promise.resolve();
  // @ts-expect-error -- TS doesn't know T can be void
  return optArg;
}

// Resolves at the end of the current task queue
//   Examples:
//      await nextTask()
//      .then(nextTask)
export async function nextTask<T>(optArg?: T): Promise<T> {
  return await delayFor(0, optArg);
}

// Resolves once the state have been applied
//   Examples:
//      await setState(this, { isLoading: true })
//      .then(setState(this, { isLoading: true }))
export async function setState<TPartialState>(
  comp: React.Component<any, any>,
  partialState: TPartialState
): Promise<TPartialState> {
  return await new Promise(resolve => {
    comp.setState(partialState, () => resolve(partialState));
  });
}

export async function asyncJsonParse<T>(json: string): Promise<T> {
  if (typeof Response === 'function') {
    return new Response(json).json(); // Hack using fetch API, faster than task based
  }
  await nextMicroTask();
  const obj = JSON.parse(json);
  await nextMicroTask();
  return obj;
}

export async function asyncJsonStringify(value: any, replacer?: any, space?: any): Promise<string> {
  await nextMicroTask();
  const json = JSON.stringify(value, replacer, space);
  await nextMicroTask();
  return json;
}

/**
 * Promise which has resolve/reject callbacks publicly available. This allows
 * code to keep reference to a single object, while changing it's settled state.
 *
 * Example: Reject if API doesn't return in 5 seconds
 *
 * const prom = new PublicPromise();
 * setTimeout(() => prom.reject(new Error('timeout')), 5000);
 * api.getPaperCount().then(v => prom.resolve(v));
 * const result = await prom;
 *
 * NOTE: Any chained function will return a regular, private promise. Example:
 *       (new PublicPromise()).then().resolve === undefined
 */

let nextId = 1;
export class PublicPromise<T> extends Promise<T> {
  _resolve: Resolver<T>;
  _reject: Rejector;
  _id = nextId++;

  constructor() {
    let _resolve: Resolver<T>, _reject: Rejector;
    super((resolve, reject) => {
      _resolve = resolve;
      _reject = reject;
    });
    // @ts-expect-error -- Callback is run immediately, to _resolve will contain a value
    this._resolve = _resolve;
    // @ts-expect-error -- Callback is run immediately, to _reject will contain a value
    this._reject = _reject;
  }

  get resolve(): Resolver<T> {
    return this._resolve;
  }

  get reject(): Rejector {
    return this._reject;
  }
}

// HACK: PublicPromise.then() throws an error without changing the constructor
PublicPromise.prototype.constructor = Promise;
