import AnalyticsEvent from '@/analytics/models/AnalyticsEvent';
import BrowserUtil from '@/browser';
import EventType from '@/analytics/constants/EventType';

import type { DEPRECATED__FlowOptional } from '@/utils/types';

export default class Pageview extends AnalyticsEvent {
  referrer: DEPRECATED__FlowOptional<string>;

  static create(): Pageview {
    return new Pageview();
  }

  constructor() {
    super(EventType.PAGEVIEW);

    // We only want to send the referrer on a pageview.
    this.referrer = document.referrer === '' ? null : document.referrer;
  }

  toGoogleAnalyticsData(): object {
    return {
      page_location: this.url,
      page_path: BrowserUtil.getPath(),
      page_title: BrowserUtil.getTitle(),
    };
  }
}
