import AnalyticsEvent from './AnalyticsEvent';

import EventType from '@/analytics/constants/EventType';

export type EventData = {
  method: string;
};

/** An event associated with new user signup success. */
export default class AccountCreatedEvent extends AnalyticsEvent {
  static create(eventData: EventData): AnalyticsEvent {
    return new AccountCreatedEvent(eventData);
  }

  /**
   *
   * @param {string} method - a string identifying the authentication method / provider, i.e.
   * "google", "googleOneTap", "facebook" or "twitter".
   */
  constructor(eventData: EventData) {
    super(EventType.ACCOUNT_CREATED, eventData);
  }
}
