import { mkHeapAttributes } from '@/analytics/heapUtils';

// AuthorCardPopover Elements
export const heapAuthorNameLinkInPopoverClick = (props: { 'author-id': number | string }) =>
  mkHeapAttributes({
    id: 'author_card_popover_author_name_link',
    ...props,
  });

export const heapViewAuthorsPublicationsButton = (props: { 'author-id': number | string }) =>
  mkHeapAttributes({
    id: 'author_card_popover_view_authors_publications_button',
    ...props,
  });

export const heapShowAuthorCardButton = (props: { 'author-id': number | string }) =>
  mkHeapAttributes({
    id: 'author_card_popover_button',
    ...props,
  });
