import {
  ExperimentStatus,
  FINISHED,
  optExperimentStatus,
  PENDING,
  RUNNING,
} from '@/constants/ExperimentStatus';
import { isNumber, isObject, isString, Nullable } from '@/utils/types';
import { taggedSoftError } from '@/utils/softError';

import Immutable from 'immutable';
import invariant from 'invariant';

import type { ExperimentKey, VariationKey } from './Experiment';
import type { ExperimentRecord } from './ExperimentRecord';

export type DynamicVariationConfig = {
  key: VariationKey;
  ratio: number;
};

export type DynamicExperimentConfig = {
  key: ExperimentKey;
  salt: string;
  trafficRatio: number;
  status: ExperimentStatus;
  variations: DynamicVariationConfig[];
  allocationSalt: Nullable<string>;
  exposureSalt: Nullable<string>;
};
export type DynamicExperimentConfigMap = Immutable.Map<ExperimentKey, DynamicExperimentConfig>;

const softError = taggedSoftError('experimentConfigUtils');

export function parseDynamicConfig(configStr: string): DynamicExperimentConfigMap {
  try {
    const configObj = JSON.parse(configStr);
    const config: DynamicExperimentConfigMap = Immutable.Map(configObj);
    // TODO: Validate config object
    return config;
  } catch (error) {
    softError('Failed to parse experiment config', error);
    return Immutable.Map();
  }
}

export function buildExperimentConfig(
  configStr: string,
  experiments: Immutable.List<ExperimentRecord>
): DynamicExperimentConfigMap {
  const dynamicConfig = parseDynamicConfig(configStr);
  return experiments.reduce((map, exp) => {
    const expKey = exp.KEY;
    const dynExpConfig = dynamicConfig.get(expKey);

    if (dynExpConfig) {
      try {
        const expConfig = validateDynamicExperimentConfig(exp, dynExpConfig);
        return map.set(expKey, expConfig);
      } catch (error) {
        softError(`config for experiment "${expKey}" is invalid, using fallback`, error.message);
      }
    }

    // Use static config defaults for experiment (if dynamic config is missing or invalid)
    const variationKeys = getVariationKeys(exp);
    const variations = variationKeys.map(key => ({
      key,
      ratio: exp.defaultVariation === key ? 1 : 0,
    }));
    return map.set(expKey, {
      key: expKey,
      salt: expKey,
      allocationSalt: null,
      exposureSalt: null,
      trafficRatio: 0.0,
      status: PENDING,
      variations,
    });
  }, Immutable.Map());
}

export function validateDynamicExperimentConfig(
  exp: ExperimentRecord,
  dynExpConfig: Record<string, unknown>
): DynamicExperimentConfig {
  const expKey = exp.KEY;
  const { key, salt, allocationSalt, exposureSalt, trafficRatio, status, variations } =
    dynExpConfig;

  // Validate "key"
  invariant(isString(key), `config key is invalid for experiment [experimentKey="${expKey}"]`);
  invariant(key === expKey, `config key mismatch [experimentKey="${expKey}", configKey="${key}"]`);

  // Validate "salt"
  invariant(
    isString(salt),
    `salt for experiment is invalid [experimentKey="${expKey}", salt=${JSON.stringify(salt)}]`
  );
  invariant(salt.length > 0, `salt for experiment is blank [experimentKey="${expKey}"]`);

  // Validate "allocationSalt"
  const optAllocationSalt = validateOptionalSalt(allocationSalt, expKey);

  // Validate "exposureSalt"
  const optExposureSalt = validateOptionalSalt(exposureSalt, expKey);

  // Validate "trafficRatio"
  invariant(
    isNumber(trafficRatio),
    `traffic ratio for experiment is invalid [experimentKey="${expKey}", trafficRatio=${JSON.stringify(
      trafficRatio
    )}]`
  );
  invariant(
    0 <= trafficRatio && trafficRatio <= 1,
    `traffic ratio is outside 0 and 1 (inclusive) [experimentKey=${expKey}, trafficRatio=${trafficRatio}]`
  );

  // Validate "status"
  invariant(
    isObject<{ value: string }>(status),
    `status for experiment is null or undefined [experimentKey="${expKey}"]`
  );
  const optStatus = optExperimentStatus(status.value);
  invariant(
    isString(optStatus),
    `status for experiment is invalid [experimentKey="${expKey}", status=${JSON.stringify(status)}]`
  );

  // Validate "variations"
  invariant(
    Array.isArray(variations) && variations.length > 0,
    `variations are missing for experiment [experimentKey="${expKey}"]`
  );
  switch (optStatus) {
    case PENDING: {
      invariant(
        trafficRatio === 0,
        `experiments with PENDING status must have traffic ratio of 0 [experimentKey=${expKey}, trafficRatio=${trafficRatio}]`
      );
      break;
    }
    case RUNNING: {
      // Check that running variations matches variations in code, and ratios sum to 1
      let remainingKeys = getVariationKeys(exp);
      let sumRatio = 0;
      for (const variation of variations) {
        invariant(
          isObject<Record<string, unknown>>(variation),
          `a variation is invalid [experimentKey="${expKey}"]`
        );
        const { key: variationKey, ratio } = variation;
        invariant(
          isString(variationKey),
          `variation's key is invalid [experimentKey="${expKey}", variationKey=${JSON.stringify(
            variationKey
          )}]`
        );
        invariant(
          isNumber(ratio),
          `variation's ratio is invalid [experimentKey="${expKey}", variationKey="${variationKey}", ratio=${JSON.stringify(
            ratio
          )}]`
        );
        invariant(
          0 <= ratio && ratio <= 1,
          `variation's ratio is not between 0 and 1 (inclusive) [experimentKey="${expKey}", variationKey="${variationKey}", ratio=${ratio}]`
        );
        if (ratio !== 0) {
          // Allow 0 ratio keys to be missing from code
          invariant(
            remainingKeys.includes(variationKey),
            `variation's key is not in static config [experimentKey="${expKey}", variationKey="${variationKey}"]`
          );
        }
        sumRatio += ratio;
        remainingKeys = remainingKeys.filter(_ => _ !== variationKey);
      }
      invariant(
        remainingKeys.length === 0,
        `experiment did not include all variations [experimentKey="${expKey}", missingVariations=${JSON.stringify(
          remainingKeys
        )}]`
      );
      invariant(
        sumRatio === 1,
        `variation ratios do not add up to 1 [experimentKey="${expKey}", sum=${sumRatio}]`
      );
      break;
    }
    case FINISHED: {
      // Check that a final variation was chosen, and that it is in the code
      invariant(
        trafficRatio === 1,
        `experiments with FINISHED status must have traffic ratio of 1 [experimentKey=${expKey}, trafficRatio=${trafficRatio}]`
      );
      const finalVariations = variations.filter(_ => _.ratio === 1);
      invariant(
        finalVariations.length === 1,
        `experiment was finished, but did not have a final variation selected [experimentKey="${expKey}"]`
      );
      const finalVariationKey = finalVariations[0].key;
      const sumRatio = variations.map(_ => _.ratio).reduce((sum, ratio) => sum + ratio, 0);
      invariant(
        sumRatio === 1,
        `experiment was finished, but ratios for variations was not 1 [experimentKey="${expKey}", ratio=${JSON.stringify(
          sumRatio
        )}]`
      );
      invariant(
        getVariationKeys(exp).includes(finalVariationKey),
        `experiment was finished, but final variation was missing from static config [experimentKey="${expKey}", finalVariationKey="${finalVariationKey}"]`
      );
      break;
    }
  }

  const expConfig: DynamicExperimentConfig = {
    key,
    salt,
    allocationSalt: optAllocationSalt,
    exposureSalt: optExposureSalt,
    trafficRatio,
    status: optStatus,
    variations,
  };
  return expConfig;
}

// Convert unknown salt field type to its original typing of a nullable string while validating
function validateOptionalSalt(potentialSalt: unknown, expKey: string): Nullable<string> {
  if (potentialSalt) {
    invariant(
      isString(potentialSalt),
      `optional salt for experiment is invalid [experimentKey="${expKey}", exposureSalt=${JSON.stringify(
        potentialSalt
      )}]`
    );
    return potentialSalt;
  }
  return null;
}

export function getVariationKeys(exp: ExperimentRecord): string[] {
  if (!exp || !exp.Variation) {
    return [];
  }
  const variationKeys = Object.values(exp.Variation);
  return variationKeys;
}
