import { mkOnClickKeyDown } from '@/utils/a11y-utils';
import { Nullable, ReactNodeish } from '@/utils/types';
import { useAppContext } from '@/AppContext';
import MobileLibraryNavMenu from '@/components/mobile/account/MobileLibraryNavMenu';

import React, { ReactNode } from 'react';

type ResearchPageHeaderProps = {
  headline?: Nullable<ReactNode>;
  subhead?: Nullable<ReactNode>;
  tagLine?: Nullable<ReactNode>;
  pageNav?: Nullable<ReactNode>;
  icon?: Nullable<ReactNode>;
  aside?: Nullable<ReactNode>;
};

export default function ResearchPageHeader({
  headline,
  subhead,
  tagLine,
  pageNav,
  icon,
  aside,
}: ResearchPageHeaderProps): ReactNodeish {
  const [isLibraryMenuVisible, setIsLibraryMenuVisible] = React.useState(false);

  const { envInfo } = useAppContext();

  const handleHeadLineClick = () => {
    setIsLibraryMenuVisible(true);
  };

  const hideLibraryModal = () => {
    setIsLibraryMenuVisible(false);
  };

  const _onClickKeyDownHeadlineProps = React.useMemo(
    () =>
      mkOnClickKeyDown({
        onClick: handleHeadLineClick,
      }),
    []
  );

  return (
    <React.Fragment>
      <header className="research__page-header">
        <hgroup className="research__page-header__content">
          {!!subhead && <h2 className="research__page-header__subhead">{subhead}</h2>}
          <main className="research__page-header__main">
            {!!icon && <div className="research__page-header__icon">{icon}</div>}
            <h1 className="research__page-header__headline" {..._onClickKeyDownHeadlineProps}>
              {headline}
            </h1>
          </main>
          {!!aside && <aside className="research__page-header__aside">{aside}</aside>}
          {!!tagLine && <div className="research__page-header__tag-line">{tagLine}</div>}
          {!!pageNav && <div className="research__page-header__page-nav">{pageNav}</div>}
        </hgroup>
      </header>
      {isLibraryMenuVisible && envInfo.isMobile && (
        <MobileLibraryNavMenu onClickClose={hideLibraryModal} />
      )}
    </React.Fragment>
  );
}
