import { getString } from '@/content/i18n';
import { isAllPapersFolder } from '@/models/library/LibraryFolderSourceType';
import CLButton from '@/components/library/button/CLButton';
import CLPaperActionAnnotate from '@/components/library/paper/paper-actions/CLPaperActionAnnotate';
import CLPaperActions from '@/components/library/paper/CLPaperActions';
import CLPaperAnalytics from '@/components/library/paper/CLPaperAnalytics';
import CLPaperCardActions from '@/components/library/paper/CLPaperCardActions';
import CLPaperControls from '@/components/library/paper/CLPaperControls';
import CLPaperControlsExpansionManager from '@/components/library/paper/CLPaperControlsExpansionManager';
import CLPaperObject, { ROW } from '@/components/library/paper/CLPaperObject';
import CLPaperTitle from '@/components/library/paper/CLPaperTitle';
import EnvInfo from '@/env/EnvInfo';
import Link from '@/router/Link';
import WeblabStore from '@/weblab/WeblabStore';

import classNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

export const MAX_PAPERS_COLLAPSED = 5;
export const MAX_PAPERS_EXPANDED = 20;

export default class RecommendationsListFolder extends React.PureComponent {
  static contextTypes = {
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    weblabStore: PropTypes.instanceOf(WeblabStore).isRequired,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      shouldShowMore: false,
    };
  }

  onShowMoreRecommendations = event => {
    event.preventDefault();
    const { shouldShowMore } = this.state;
    this.setState({ shouldShowMore: !shouldShowMore });
  };

  renderPapersWithNotRelevantButtons(shownFolderPapers, folderName) {
    const { folder, day } = this.props;
    const libraryProps = {
      selectedFolders: isAllPapersFolder(folder) ? Immutable.Set() : Immutable.Set.of(folder),
    };

    return shownFolderPapers.map((record, index) => (
      <CLPaperAnalytics
        key={record.paper.id}
        paper={record.paper}
        eventData={{ day: day, folderId: folder.id, index }}>
        <CLPaperControlsExpansionManager>
          <CLPaperObject
            paper={record.paper}
            className="recommendations-list-folder__row"
            controls={meta => {
              switch (meta) {
                case ROW:
                  return (
                    <CLPaperControls
                      paper={record.paper}
                      actions={
                        <div className="flex-row cl-paper-actions">
                          <CLPaperActions paper={record.paper} libraryProps={libraryProps} />
                          <div className="cl-paper-action__button-container">
                            <CLPaperActionAnnotate
                              paper={record.paper}
                              folderId={folder.id}
                              folderName={folderName}
                              buttonType="NotRelevant"
                            />
                          </div>
                        </div>
                      }
                    />
                  );
                default:
                  return null;
              }
            }}
            footer={
              <CLPaperControls
                className="flex-row cl-paper-actions"
                paper={record.paper}
                managed={false}
                stats={false}
                actions={
                  <div className="cl-paper-action__button-container">
                    <CLPaperActions
                      paper={record.paper}
                      alert={false}
                      cite={false}
                      viewPaper={false}
                    />
                  </div>
                }
                annotation={
                  <div className="cl-paper-action__button-container">
                    <CLPaperActionAnnotate
                      paper={record.paper}
                      folderId={folder.id}
                      folderName={folderName}
                      buttonType="NotRelevant"
                    />
                  </div>
                }
              />
            }
            title={<CLPaperTitle paper={record.paper} titleTagName="h4" />}
          />
        </CLPaperControlsExpansionManager>
      </CLPaperAnalytics>
    ));
  }

  renderPapers(shownFolderPapers) {
    const { folder, day } = this.props;

    return shownFolderPapers.map((record, index) => (
      <CLPaperAnalytics
        key={record.paper.id}
        paper={record.paper}
        eventData={{ day: day, folderId: folder.id, index }}>
        <CLPaperObject
          className={classNames({
            'recommendations-list-folder__row': true,
          })}
          paper={record.paper}
          footer={<CLPaperCardActions paper={record.paper} />}
          title={<CLPaperTitle paper={record.paper} titleTagName="h4" />}
        />
      </CLPaperAnalytics>
    ));
  }

  render() {
    const { folder, papers } = this.props;
    const { shouldShowMore } = this.state;

    if (!folder) {
      return null;
    }

    const limit = shouldShowMore ? MAX_PAPERS_EXPANDED : MAX_PAPERS_COLLAPSED;
    const allFolderPapers = papers.filter(paper => paper.libraryFolderId === folder.id);
    const shownFolderPapers = allFolderPapers.slice(0, limit);
    const hasMore = allFolderPapers.size > shownFolderPapers.size;

    if (shownFolderPapers.isEmpty()) {
      return null;
    }

    return (
      <div className="recommendations-list-folder">
        <h3 className="recommendations-list-folder__heading">
          {getString(_ => _.library.recommendationsList.folderHeading)}
          <Link
            to="LIBRARY_FOLDER"
            params={{ libraryFolderId: folder.id }}
            className="recommendations-list-folder__link"
            shouldUnderline={false}>
            {folder.name}
          </Link>
        </h3>
        {this.renderPapersWithNotRelevantButtons(shownFolderPapers, folder.name)}
        {!shouldShowMore && hasMore && (
          <div className="recommendations-list-folder__show-more">
            <CLButton
              onClick={this.onShowMoreRecommendations}
              label={getString(_ => _.library.recommendationsList.folderShowMore, folder.name)}
              testId="recommendations-list-folder__show-more-button"
            />
          </div>
        )}
      </div>
    );
  }
}
