import React from 'react';

export default function IconTrophy(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 24 21.3">
      <path
        d="M23,2.7c0.3,0,0.5,0.1,0.7,0.3S24,3.4,24,3.7V6c0,0.8-0.2,1.5-0.7,2.2s-1.1,1.4-1.9,2c-1.3,0.9-2.9,1.5-4.6,1.7
		c-0.4,0.7-0.8,1.3-1.3,1.8c-0.3,0.4-0.7,0.7-1,1L14,15v3h2c0.8,0,1.4,0.2,1.9,0.6c0.5,0.4,0.8,1,0.8,1.7v0.5c0,0.1,0,0.3-0.1,0.4
		c-0.1,0.1-0.2,0.1-0.4,0.1H5.8c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.4v-0.5c0-0.7,0.3-1.3,0.8-1.7S7.2,18,8,18h2v-3
		l-0.5-0.3c-0.4-0.3-0.7-0.6-1-1c-0.5-0.5-0.9-1.1-1.3-1.8c-1.7-0.2-3.2-0.8-4.6-1.7c-0.8-0.6-1.5-1.3-1.9-2S0,6.8,0,6V3.7
		C0,3.4,0.1,3.2,0.3,3S0.7,2.7,1,2.7h4.3V1c0-0.3,0.1-0.5,0.3-0.7S6.1,0,6.3,0h11.3c0.3,0,0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7v1.7H23z
		 M4.1,8c0.5,0.4,1.1,0.7,1.8,0.9C5.6,7.8,5.4,6.6,5.3,5.3H2.7V6c0,0.3,0.1,0.6,0.4,1S3.7,7.7,4.1,8z M21.3,6V5.3h-2.7
		c-0.1,1.2-0.2,2.4-0.5,3.6c0.6-0.2,1.2-0.5,1.8-0.9c0.4-0.3,0.7-0.6,1-1C21.2,6.6,21.3,6.3,21.3,6z"
      />
    </symbol>
  );
}
