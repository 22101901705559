import AnalyticsEvent from './AnalyticsEvent';

import EventType from '@/analytics/constants/EventType';

import merge from 'merge';

/** Event tracking when something is selected. */
export default class SelectEvent extends AnalyticsEvent {
  /**
   * @param {string} target - an identifier for what was selected
   * @param {object} [data] - optional key value pairs describing the event.
   */
  constructor(target: string, data?: any) {
    super(EventType.SELECT, merge.recursive({ target }, data));
  }

  static create(target: string, data?: any): SelectEvent {
    return new SelectEvent(target, data);
  }
}
