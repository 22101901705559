import { getEmptyPaperPdfBoundingRecord, PaperPdfBoundingBoxRecord } from './PaperPdfBoundingBox';

import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

export type PaperPdfCitationMentionFromJS = {
  id: Nullable<string>;
  referenceText: Nullable<string>;
  boundingBox: PaperPdfBoundingBoxRecord;
  s2airsId: Nullable<string>;
  corpusId: Nullable<number>;
  text: Nullable<string>;
};

type Props = {
  id: Nullable<string>;
  referenceText: Nullable<string>;
  boundingBox: PaperPdfBoundingBoxRecord;
  s2airsId: Nullable<string>;
  corpusId: Nullable<number>;
  text: Nullable<string>;
};

const defaultProps: Props = {
  id: null,
  referenceText: null,
  boundingBox: getEmptyPaperPdfBoundingRecord(),
  s2airsId: null,
  corpusId: null,
  text: null,
};

export const PaperPdfCitationMentionFactory = Immutable.Record<Props>(defaultProps);

export type PaperPdfCitationMentionRecord = Immutable.RecordOf<Props>;

export function getPaperPdfCitationMentionFromJS(
  args: PaperPdfCitationMentionFromJS
): PaperPdfCitationMentionRecord {
  return PaperPdfCitationMentionFactory({
    ...args,
  });
}
