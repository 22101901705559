import CLPaperActionAlert from './paper-actions/CLPaperActionAlert';
import CLPaperActionCite from './paper-actions/CLPaperActionCite';
import CLPaperActionLibraryFolder from './paper-actions/CLPaperActionLibraryFolder';
import CLPaperActionViewPaper from './paper-actions/CLPaperActionViewPaper';

import { addHeapProps } from '@/components/util/HeapTracking';
import { checkForPaperRecord } from '@/utils/paper-util';
import { HeapProps } from '@/analytics/heapUtils';
import { Nullable, ReactNodeish } from '@/utils/types';
import { PaperishRecord } from '@/models/Paper';
import { PaperLinkRecordFactory } from '@/models/PaperLink';
import { QUERY_TYPE } from '@/constants/Alert';
import MockPaper, { MockPaperLiteFromJs } from 'test/utils/MockPaper';
import PaperLinkType from '@/constants/PaperLinkType';

import classnames from 'classnames';
import React, { ComponentProps } from 'react';

type CLPaperActionsProps = {
  paper: PaperishRecord;
  className?: Nullable<string>;
  alert?: boolean;
  cite?: boolean;
  library?: boolean;
  viewPaper?: boolean;
  heapProps?: Nullable<HeapProps>;
  libraryProps?: Nullable<ComponentProps<typeof CLPaperActionLibraryFolder>['libraryProps']>;
};

export default function CLPaperActions({
  paper,
  className,
  alert,
  cite,
  library,
  viewPaper,
  heapProps,
  libraryProps,
}: CLPaperActionsProps): ReactNodeish {
  // this will return a PaperRecord if the paper prop is a Paper and will return null if it is a PaperLite
  // We only want to render CLPaperActionViewPaper and CLPaperActionCite for Papers
  const maybePaperRecord = checkForPaperRecord(paper);
  const hasPaperLink = !!maybePaperRecord?.primaryPaperLink;

  // grab the paper title from a PaperLite vs Paper
  const alertDisplayValue = typeof paper.title === 'string' ? paper.title : paper.title.text;

  return (
    <div
      className={classnames('flex-row', 'cl-paper-actions', className)}
      {...(heapProps ? addHeapProps(heapProps) : {})}>
      {!!viewPaper &&
        (viewPaper === true ? (
          maybePaperRecord &&
          hasPaperLink && (
            <div className="cl-paper-action__button-container">
              <CLPaperActionViewPaper paper={maybePaperRecord} />
            </div>
          )
        ) : (
          <div className="cl-paper-action__button-container">{viewPaper}</div>
        ))}
      {!!library && (
        <div className="cl-paper-action__button-container">
          {library === true ? (
            <CLPaperActionLibraryFolder paper={paper} libraryProps={libraryProps} />
          ) : (
            library
          )}
        </div>
      )}
      {!!alert && (
        <div className="cl-paper-action__button-container">
          {alert === true ? (
            <CLPaperActionAlert
              displayValue={alertDisplayValue}
              queryType={QUERY_TYPE.PAPER_CITATION}
              queryValue={paper.id}
            />
          ) : (
            alert
          )}
        </div>
      )}
      {!!cite &&
        (cite === true ? (
          maybePaperRecord && (
            <div className="cl-paper-action__button-container">
              <CLPaperActionCite paper={paper} />
            </div>
          )
        ) : (
          <div className="cl-paper-action__button-container">{cite}</div>
        ))}
    </div>
  );
}

CLPaperActions.defaultProps = {
  alert: true,
  cite: true,
  library: true,
  viewPaper: true,
};

// TODO(#21359): Split this into a separate file
const PAPER = MockPaper({
  id: '94e2260617274d17ef9ebf84b5081d5fc919cbab',
  primaryPaperLink: PaperLinkRecordFactory({
    url: 'https://www.semanticscholar.org/paper/94e2260617274d17ef9ebf84b5081d5fc919cbab',
    linkType: PaperLinkType.S2,
    publisherName: 'Semantic Scholar',
  }),
});

const PAPER_LITE = MockPaperLiteFromJs({
  id: '94e2260617274d17ef9ebf84b5081d5fc919cbab',
});

export const exampleConfig = {
  getComponent: () => CLPaperActions,
  fields: [],

  examples: [
    {
      title: 'PaperActions with Paper',
      desc: '',
      props: {
        paper: PAPER,
      },
      render: comp => <div style={{ width: '1000px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'PaperActions with PaperLite',
      desc: '',
      props: {
        paper: PAPER_LITE,
      },
      render: comp => <div style={{ width: '1000px', padding: '16px' }}>{comp}</div>,
    },
  ],

  events: {},
};
