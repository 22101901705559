import invariant from 'invariant';

import type { DEPRECATED__FlowOptional, Nullable } from '@/utils/types';

export type ExperimentExposureState = (typeof byKey)[keyof typeof byKey];

export const UNEXPOSED = 'UNEXPOSED';
export const EXPOSED = 'EXPOSED';
export const TERMINATED = 'TERMINATED';
export const REMOVED = 'REMOVED';

const byKey = {
  UNEXPOSED,
  EXPOSED,
  TERMINATED,
  REMOVED,
} as const;
export const ALL_STATES = Object.freeze(byKey);

export function optExperimentExposureState(
  optState: DEPRECATED__FlowOptional<string>
): Nullable<ExperimentExposureState> {
  if (optState && optState in byKey) {
    return byKey[optState];
  }
  return null;
}

export function getExperimentExposureState(
  optState: DEPRECATED__FlowOptional<string>
): Nullable<ExperimentExposureState> {
  const expState = optExperimentExposureState(optState);
  invariant(expState, `"${optState || ''}" is not a valid ExperimentExposureState`);
  return expState;
}
