import { getString } from '@/content/i18n';
import { WillRouteToResult } from '@/router/Route';
import AppContext from '@/AppContext';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import S2Redirect from '@/models/redirects/S2Redirect';

import React from 'react';

import type { ReactNodeish } from '@/utils/types';

export default class HomeRoute extends React.Component {
  static getPageTitle = () => getString(_ => _.home.pageTitle);

  static getPageMetaDescription = () => getString(_ => _.home.metaDescription);

  static getCanonicalUrl = () => '/';

  static async willRouteTo(appContext: AppContext): Promise<WillRouteToResult> {
    const {
      accountNavStore,
      api,
      authStore,
      queryStore,
      sampleQueriesStore,
      researchHomepageStore,
    } = appContext;
    const results: WillRouteToResult[] = [];

    // redirect to research homepage if eligible
    if (authStore.hasAuthenticatedUser()) {
      if (researchHomepageStore.isUninitialized()) {
        const alertsHistory = await api.fetchAlertsHistory();
        results.push(alertsHistory);
      }

      if (accountNavStore.isUninitialized()) {
        await api.fetchUserInfo();
      }

      const isClaimedAuthor = !!authStore.getClaimedAuthorId();
      const hasReceivedAlertEmail = researchHomepageStore.hasAlertsHistory();
      const hasRecommendationsHistory = accountNavStore.hasRecommendationsHistory();

      if (isClaimedAuthor || hasReceivedAlertEmail || hasRecommendationsHistory) {
        return new S2Redirect({
          routeName: 'RESEARCH_HOMEPAGE',
        });
      }
    }

    queryStore.reset();
    if (sampleQueriesStore.getSampleQueries().isEmpty()) {
      const sampleQueries = await api.fetchSampleQueries();
      results.push(sampleQueries);
    }

    return results;
  }

  render(): ReactNodeish {
    return (
      <AsyncLoadedPage
        footer={false}
        load={{
          importer: () => import(/* webpackChunkName: "shared-HomePage" */ './HomePage'),
          chunkName: 'shared-HomePage',
          moduleId: require.resolveWeak('./HomePage'),
        }}
      />
    );
  }
}
