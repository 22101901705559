import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

export default class VerifyEmailRoute extends React.Component {
  static getPageTitle = () => getString(_ => _.alerts.verification.pageTitle);

  render() {
    return (
      <AsyncLoadedPage
        footer={false}
        load={{
          importer: () =>
            import(/* webpackChunkName: "shared-VerifyEmailPage" */ './VerifyEmailPage'),
          chunkName: 'shared-VerifyEmailPage',
          moduleId: require.resolveWeak('./VerifyEmailPage'),
        }}
      />
    );
  }
}
