import { getString } from '@/content/i18n';
import { ReactNodeish } from '@/utils/types';
import CLCardContainer from '@/components/library/container/CLCardContainer';
import CLTextButton from '@/components/library/button/CLTextButton';

import React from 'react';

type Props = {
  clearSearch: () => void;
  queryText: string;
};

export default function CreateLibraryBlankState({ clearSearch, queryText }: Props): ReactNodeish {
  return (
    <div className="create-library-modal__blank-state">
      <CLCardContainer
        className="create-library-modal__blank-state__container"
        data-test-id="create-library-blank-state">
        <div className="create-library-modal__blank-state__content">
          <div className="create-library-modal__blank-state__header">
            {getString(_ => _.research.onboardingHomepage.createLibraryModal.blankState.header)}
          </div>
          <div className="create-library-modal__blank-state__no-search-results-text">
            {getString(
              _ => _.research.onboardingHomepage.createLibraryModal.blankState.noResultsText
            )}
            <span className="create-library-modal__blank-state__query-text">{queryText}</span>
          </div>
          <div className="create-library-modal__blank-state__clear-search-button">
            <CLTextButton
              label={getString(
                _ =>
                  _.research.onboardingHomepage.createLibraryModal.blankState.clearSearchButtonText
              )}
              onClick={clearSearch}
            />
          </div>
        </div>
      </CLCardContainer>
    </div>
  );
}
