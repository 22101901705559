import RicoEvent from './models/RicoEvent';
import trackAnalyticsEvent from './trackAnalyticsEvent';

import { isTrackingAllowedOnBrowser } from '@/utils/env';
import { Nullable } from '@/utils/types';
import LayoverRico, { getLayoverRico } from '@/utils/layover/LayoverRico';

import { v4 as uuidV4 } from 'uuid';

type BaseEventData = {
  id: string;
  layoverRico: LayoverRico;
  browserId: Nullable<string>;
  userId: Nullable<number>;
};

function getBaseEventData(): BaseEventData {
  const layoverRico = getLayoverRico();
  return {
    id: uuidV4(),
    layoverRico,
    browserId: layoverRico.getBrowserId(),
    userId: layoverRico.getUserId(),
  };
}

export default function trackRicoEvent(name: string, data: object) {
  if (!isTrackingAllowedOnBrowser()) {
    return;
  }

  const { id, layoverRico, browserId, userId } = getBaseEventData();
  trackAnalyticsEvent(new RicoEvent(name, id));
  layoverRico.emit({ id, name, data, browser_id: browserId, user_id: userId?.toString() });
}
