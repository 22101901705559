import { mkHeapAttributes } from '@/analytics/heapUtils';

// Shared across SaveToLibraryShelf & OrganizePapersShelf

export const heapLibraryShelfAddFolderButton = () =>
  mkHeapAttributes({
    id: 'library_shelf_add_folder_button',
  });

export const heapLibraryShelfAddFolderSubmit = () =>
  mkHeapAttributes({
    id: 'library_shelf_add_folder_submit',
  });

export const heapLibraryShelfAddFolderCancel = () =>
  mkHeapAttributes({
    id: 'library_shelf_add_folder_cancel',
  });

export const heapLibraryShelfFolderViewLink = () =>
  mkHeapAttributes({
    id: 'library_shelf_folder_view_link',
  });

export const heapLibraryShelfFolderCheckboxToggle = () =>
  mkHeapAttributes({
    id: 'library_shelf_folder_checkbox_toggle',
  });

export const heapLibraryShelfFolderFilterInput = () =>
  mkHeapAttributes({
    id: 'library_shelf_folder_filter_input',
  });

export const heapLibraryShelfRemoveFromLibraryButton = () =>
  mkHeapAttributes({
    id: 'library_shelf_remove_from_library_button',
  });

export const heapLibraryShelfSaveAndCloseButton = () =>
  mkHeapAttributes({
    id: 'library_shelf_save_and_close_button',
  });

// LibraryFolderSettingsShelf

export const heapLibraryFolderSettingsShelfEditNameInput = () =>
  mkHeapAttributes({
    id: 'library_folder_settings_shelf_edit_name_input',
  });

export const heapLibraryFolderSettingsShelfEditDescriptionInput = () =>
  mkHeapAttributes({
    id: 'library_folder_settings_shelf_edit_description_input',
  });

export const heapLibraryFolderSettingsShelfEditPrivacyPublic = () =>
  mkHeapAttributes({
    id: 'library_folder_settings_shelf_edit_privacy_public',
  });

export const heapLibraryFolderSettingsShelfEditPrivacyPrivate = () =>
  mkHeapAttributes({
    id: 'library_folder_settings_shelf_edit_privacy_private',
  });

export const heapLibraryFolderSettingsShelfSaveButton = () =>
  mkHeapAttributes({
    id: 'library_folder_settings_shelf_save_button',
  });

export const heapLibraryFolderSettingsShelfDeleteButton = () =>
  mkHeapAttributes({
    id: 'library_folder_settings_shelf_delete_button',
  });

export const heapLibraryFolderSettingsShelfConfirmDeleteButton = () =>
  mkHeapAttributes({
    id: 'library_folder_settings_shelf_confirm_delete_button',
  });

export const heapLibraryFolderSettingsShelfCopyShareableLinkButton = () =>
  mkHeapAttributes({
    id: 'library_folder_settings_shelf_copy_shareable_link_button',
  });
