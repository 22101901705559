/* eslint-disable max-params */

import { fetchUserData } from '@/actions/UserActionCreators';
import { getLayoverLogger } from '@/utils/layover/LayoverLogger';
import { GOOGLE_ONE_TAP, SIGN_IN } from '@/constants/LoginMethods';
import { isBrowser, isTrackingAllowedOnBrowser } from '@/utils/env';
import { ModalId } from '@/constants/Modal';
import { showModal } from '@/actions/ModalActionCreators';
import { useAppContext, useStateFromStore } from '@/AppContext';
import AuthEvent from '@/analytics/models/AuthEvent';
import BrowserUtil from '@/browser';
import EventTarget from '@/analytics/constants/EventTarget';
import Feature from '@/weblab/Feature';
import ShowEvent from '@/analytics/models/ShowEvent';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import idx from 'idx';
import React from 'react';

const GOOGLE_CLIENT_SCRIPT_URL = 'https://accounts.google.com/gsi/client';

export default function GoogleOneTap() {
  const { api, authApi, authStore, dispatcher, graphQLApi, weblabStore } = useAppContext();
  const [loaded, setLoaded] = React.useState(false);
  const hasAuthenticatedUser = useStateFromStore(authStore, _ => ({
    hasAuthenticatedUser: _.hasAuthenticatedUser(),
  }));

  React.useEffect(() => {
    if (loaded && hasAuthenticatedUser) {
      const id = idx(window, _ => _.google.accounts.id);
      if (id) {
        id.cancel();
      }
    }
  }, [hasAuthenticatedUser, loaded]);

  React.useEffect(() => {
    if (!window.GOOGLE_LOGIN_CLIENT_ID) {
      getLayoverLogger().log('Tried to render Google One-Tap without client_id', {});
      return;
    }

    async function loadScript() {
      await BrowserUtil.loadScript(GOOGLE_CLIENT_SCRIPT_URL).then(() => {
        setLoaded(true);
      });
    }

    if (
      isBrowser() &&
      !authStore.hasAuthenticatedUser() &&
      !loaded &&
      isTrackingAllowedOnBrowser()
    ) {
      loadScript();
    }
  }, []);

  React.useEffect(() => {
    if (window && window.google && loaded) {
      // initialize config docs at https://developers.google.com/identity/gsi/web/reference/js-reference
      window.google.accounts.id.initialize({
        client_id: window.GOOGLE_LOGIN_CLIENT_ID,
        callback: data =>
          handleCredentialResponse(
            data,
            authApi,
            api,
            dispatcher,
            graphQLApi,
            weblabStore,
            authStore
          ),
        cancel_on_tap_outside: false,
      });

      // docs at https://developers.google.com/identity/gsi/web/reference/js-reference#PromptMomentNotification
      window.google.accounts.id.prompt(notification => {
        if (notification.isDisplayed()) {
          trackAnalyticsEvent(new ShowEvent(EventTarget.GOOGLE_ONE_TAP));
        }
      });
    }
  }, [loaded]);

  return null;
}

async function handleCredentialResponse(
  response,
  authApi,
  api,
  dispatcher,
  graphQLApi,
  weblabStore,
  authStore
) {
  getLayoverLogger().log('login.started', { method: GOOGLE_ONE_TAP });
  const eventData = {
    method: GOOGLE_ONE_TAP,
    action: SIGN_IN,
  };
  trackAnalyticsEvent(
    AuthEvent.create({
      ...eventData,
      state: AuthEvent.State.STARTED,
    })
  );

  try {
    if (response.credential) {
      await authApi.loginViaOneTap(response.credential);
      getLayoverLogger().log('login.succeeded', { method: GOOGLE_ONE_TAP });
      trackAnalyticsEvent(
        AuthEvent.create({
          ...eventData,
          state: AuthEvent.State.SUCCEEDED,
        })
      );

      const isDemographicsModalOn = weblabStore.isFeatureEnabled(Feature.LoginDemographicsModal);
      // Show demographics modal, if new users
      await fetchUserData({ api, authStore, graphQLApi, weblabStore });
      const user = authStore.getUser();
      if (user && !user.hasCompletedDemographics && isDemographicsModalOn) {
        dispatcher.dispatchEventually(showModal({ id: ModalId.DEMOGRAPHIC }));
      }
    }
  } catch (error) {
    getLayoverLogger().log('login.failed', { method: GOOGLE_ONE_TAP, error: error });
    trackAnalyticsEvent(
      AuthEvent.create({
        ...eventData,
        state: AuthEvent.State.FAILED,
      })
    );
  }
}
