import { isFunction, isNull, isNumber, isString, Nullable, ReactNodeish } from '@/utils/types';

import classNames from 'classnames';
import React from 'react';

export enum Politeness {
  /* Normally, only polite is used. Any region which receives updates that are
   * important for the user to receive, but not so rapid as to be annoying,
   * should receive this attribute. The screen reader will speak changes
   * whenever the user is idle.
   */
  POLITE = 'polite',

  /* Used for time-sensitive/critical notifications that absolutely require the
   * user's immediate attention. Generally, a change to an assertive live region
   * will interrupt any announcement a screen reader is currently making. As
   * such, it can be extremely annoying and disruptive and should only be
   * used sparingly.
   */
  ASSERTIVE = 'assertive',

  /* As off is the assumed default for elements, it should not be necessary to
   * set this explicitly, unless you're trying to suppress the announcement of
   * elements which have an implicit live region role
   */
  OFF = 'off',
}

type Props = {
  message?: Nullable<string>;
  politeness: Politeness;
  isScreenReaderOnly?: Nullable<boolean>;
  durationMs?: Nullable<number>;
  onAnnouncementCompletion?: Nullable<() => void>;
};

type State = {
  announcement: Nullable<string>;
  originalMessage: Nullable<string>;
};

/* This component is used to indicate aria live-regions for screen readers. Live
 * regions are used to announce a dynamic change within a page to screen readers
 * that are usually communicated through display changes.
 *
 * Some examples of when you would use this component:
 * 1. A page displays a loading indicator that content is loading, the live
 *    region is used to announce to the user that content is loading and then
 *    that has been fully loaded.
 * 2. A user is using filters to search for something, they click a button to
 *    clear the filters. The announcement is made to say that the filters have
 *    been cleared.
 * 3. A user copies a citation by clicking a Copy button, the live region is
 *    used to indicate that the content has been copied to their clipboard.
 */
export default class ScreenReaderAnnouncement extends React.PureComponent<Props, State> {
  static defaultProps = {
    politeness: Politeness.POLITE,
    isScreenReaderOnly: true,
  };

  static getDerivedStateFromProps(props: Props, state: State): Nullable<Partial<State>> {
    const newState: Partial<State> = {};
    const { message } = props;
    const { originalMessage } = state;

    // Announce message if message has changed and announcement was previously made
    if (message !== originalMessage) {
      newState.announcement = message;
      newState.originalMessage = message;
    }

    return newState;
  }

  _announcementTimerId: Nullable<NodeJS.Timer> = null;

  constructor(...args: [any]) {
    super(...args);

    const { message } = this.props;
    this.state = {
      announcement: isString(message) ? message : null,
      originalMessage: isString(message) ? message : null,
    };
  }

  componentDidMount(): void {
    const { durationMs } = this.props;
    if (isNumber(durationMs)) {
      this.startAnnouncementTimer(durationMs);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    const { durationMs } = this.props;

    // Start a timer if announcement is intended to be made for a limited duration
    if (isNumber(durationMs)) {
      if (!isNull(this.state.announcement) && this.state.announcement !== prevState.announcement) {
        this.startAnnouncementTimer(durationMs);
      }
    }
  }

  componentWillUnmount(): void {
    // Prevent timer from triggering a setState on unmounted component
    this.stopAnnouncementTimer();
  }

  // Start the timer, setting that an announcement has not been completed yet
  startAnnouncementTimer(durationMs: number): void {
    if (!isNull(this._announcementTimerId)) {
      this.stopAnnouncementTimer();
    }
    this._announcementTimerId = setTimeout(() => {
      this.completeAnnouncement();
    }, durationMs);
  }

  // Prevent the announcement from completing
  stopAnnouncementTimer(): void {
    if (!isNull(this._announcementTimerId)) {
      clearTimeout(this._announcementTimerId);
      this._announcementTimerId = null;
    }
  }

  // Mark the announcement as being completed
  completeAnnouncement(): void {
    const { onAnnouncementCompletion } = this.props;
    this.setState({ announcement: null });
    if (isFunction(onAnnouncementCompletion)) {
      onAnnouncementCompletion();
    }
  }

  render(): ReactNodeish {
    const { politeness, message, isScreenReaderOnly } = this.props;
    const { announcement, originalMessage } = this.state;
    const hasAnnounced = isNull(announcement) && !isNull(message) && message === originalMessage;
    return (
      <span
        className={classNames({ 'screen-reader-only': isScreenReaderOnly })}
        aria-atomic="true"
        aria-live={politeness}
        data-test-id="screen-reader-announcement"
        data-has-announced={hasAnnounced}>
        {announcement}
      </span>
    );
  }
}
