import React from 'react';

export default function IconCitation(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 18 19.87">
      <path d="M8.46,8.74v2.37c0,.49-.41,.89-.91,.89h-2.42c-.5,0-.91-.4-.91-.89V6.67c0-1.64,1.36-2.96,3.02-2.96h.15c.25,0,.46,.2,.46,.45v.89c0,.25-.2,.45-.46,.45h-.15c-.67,0-1.21,.53-1.21,1.19v1.18h1.51c.5,0,.91,.4,.91,.89Zm5.45,0v2.37c0,.49-.41,.89-.91,.89h-2.42c-.5,0-.91-.4-.91-.89V6.67c0-1.64,1.35-2.96,3.02-2.96h.15c.25,0,.46,.2,.46,.45v.89c0,.25-.2,.45-.46,.45h-.15c-.67,0-1.21,.53-1.21,1.19v1.18h1.51c.5,0,.91,.4,.91,.89Z" />
      <path d="M9,19.87s0,0-.01,0c-.4,0-.76-.22-.96-.57l-1.91-3.31H3c-1.65,0-3-1.35-3-3V3C0,1.35,1.35,0,3,0H15c1.65,0,3,1.35,3,3V13c0,1.65-1.35,3-3,3h-3.11l-1.91,3.31c-.21,.36-.58,.56-.98,.56ZM3,1C1.9,1,1,1.9,1,3V13c0,1.1,.9,2,2,2h3.69l2.21,3.81s.06,.07,.11,.07c0,.03,.08,0,.12-.07l2.19-3.8h3.69c1.1,0,2-.9,2-2V3c0-1.1-.9-2-2-2H3Z" />
    </symbol>
  );
}
