import { ApiResponse } from '@/api/ApiResponse';
import {
  getPaperAuthorCorrectionFromJS,
  PaperAuthorCorrectionRecord,
} from '@/models/PaperAuthorCorrection';
import BaseStore from '@/stores/BaseStore';
import Constants from '@/constants';
import S2Dispatcher from '@/utils/S2Dispatcher';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import Immutable from 'immutable';

const LOADING_STATES: StoreStateValue[] = [StoreState.UNINITIALIZED, StoreState.LOADING];

export default class AuthorshipModerationStore extends BaseStore {
  _state: StoreStateValue;
  _paperAuthorCorrections: Immutable.List<PaperAuthorCorrectionRecord>;
  _numPendingAuthorshipCorrections: number;
  dispatchToken: string;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this._state = StoreState.UNINITIALIZED;
    this._paperAuthorCorrections = Immutable.List();
    this._numPendingAuthorshipCorrections = 0;

    this.dispatchToken = dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.GET_PENDING_PAPER_AUTHOR_CORRECTIONS: {
              this._state = StoreState.LOADING;
              this.emitChange();
              break;
            }
          }
          break;
        }
        case Constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.MODERATION_STATS: {
              this._numPendingAuthorshipCorrections = apiResponse.resultData
                ? apiResponse.resultData.numPendingAuthorshipCorrections
                : 0;
              this.emitChange();
              break;
            }
            case Constants.requestTypes.GET_PENDING_PAPER_AUTHOR_CORRECTIONS: {
              if (apiResponse.resultData) {
                this._paperAuthorCorrections = Immutable.List(
                  apiResponse.resultData.results.map(raw => getPaperAuthorCorrectionFromJS(raw))
                );
              }
              this._state = StoreState.LOADED;
              this.emitChange();
              break;
            }
          }
          break;
        }
      }
    });
  }

  isLoading(): boolean {
    return LOADING_STATES.includes(this._state);
  }

  getPendingPaperAuthorCorrections(): Immutable.List<PaperAuthorCorrectionRecord> {
    return this._paperAuthorCorrections;
  }

  getPendingPaperAuthorCorrectionsCount(): number {
    return this._numPendingAuthorshipCorrections;
  }
}
