import classnames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class TextArea extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    rows: PropTypes.number,
    isRequired: PropTypes.bool,
    value: PropTypes.string,
    errors: PropTypes.instanceOf(Immutable.List),
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    errors: Immutable.List([]),
  };

  onChange = e => {
    this.props.onChange(e.target.value);
  };

  render() {
    const { className, label, placeholder, rows, isRequired, value, errors } = this.props;
    const labelText = isRequired ? label + ' *' : label;
    const optional = !isRequired ? <span className="optional text--gray">(optional)</span> : null;
    const classes = classnames(className, { 'has-errors': errors.size > 0 }, 's2-form-input');

    return (
      <div className={classes}>
        {label && (
          <label htmlFor={label} className="input-label">
            <span className="input-label-text">
              {labelText} {optional}
            </span>
            {errors && (
              <span className="input-error" data-test-id="input-error">
                {errors.first()}
              </span>
            )}
          </label>
        )}
        <textarea
          className="legacy__textarea textarea"
          placeholder={placeholder}
          rows={rows}
          required={isRequired}
          value={value}
          onChange={this.onChange}
        />
      </div>
    );
  }
}
