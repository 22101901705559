import {
  AuthorCardFromGraphQL,
  AuthorCardRecord,
  getAuthorCardFromGraphQL,
} from '@/models/author/AuthorCard';
import { getAuthorIdAsNumber } from '@/utils/author-utils';
import { hasValue, Nullable } from '@/utils/types';
import BaseStore from '@/stores/BaseStore';
import Constants from '@/constants';
import S2Dispatcher from '@/utils/S2Dispatcher';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import Immutable from 'immutable';

import type { ApiRequestFailurePayload, ApiRequestStartingPayload } from '@/api/BaseApi';
import type { ApiResponse } from '@/api/ApiResponse';
import type { FetchAuthorCardsResponse } from '@/actions/AuthorActionCreators';
import type { GraphQLResponse } from '@/api/GraphQLApi';

type AuthorId = number | string;

export default class AuthorCardStore extends BaseStore {
  #state: StoreStateValue;
  #authorStates: Immutable.Map<AuthorId, StoreStateValue>;
  #authorCardRecords: Immutable.Map<AuthorId, AuthorCardRecord>;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#authorStates = Immutable.Map();
    this.#authorCardRecords = Immutable.Map();
    this.#reset();

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.ROUTING: {
          this.#handleRouting();
          return;
        }
        case Constants.actions.API_REQUEST_STARTING: {
          const apiStartingPayload = payload as ApiRequestStartingPayload;
          switch (apiStartingPayload.requestType) {
            case Constants.requestTypes.GQL__AUTHOR_CARDS: {
              return this.#handleAuthorCardsStart(apiStartingPayload);
            }
          }
          return;
        }

        case Constants.actions.API_REQUEST_FAILED:
          {
            const apiFailedPayload = payload as ApiRequestFailurePayload;
            switch (apiFailedPayload.requestType) {
              case Constants.requestTypes.GQL__AUTHOR_CARDS: {
                return this.#handleAuthorCardsFailed(apiFailedPayload);
              }
            }
            return;
          }
          break;

        case Constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.GQL__AUTHOR_CARDS: {
              return this.#handleAuthorCardsResponse(apiResponse);
            }
          }
          return;
        }
      }
    });
  }

  #reset(): void {
    this.#authorStates = Immutable.Map();
    this.#authorCardRecords = Immutable.Map();
  }

  #handleRouting(): void {
    this.#reset();
  }

  #handleAuthorCardsStart(payload: ApiRequestStartingPayload): void {
    const authorIds = payload.context?.variables?.authorIds || [];
    const origAuthorStates = this.#authorStates;
    authorIds.map((authorId: number) => {
      this.#authorStates = this.#authorStates.set(authorId, StoreState.LOADING);
    });
    if (!origAuthorStates.equals(this.#authorStates)) {
      this.emitChange();
    }
  }

  #handleAuthorCardsResponse(
    payload: ApiResponse<GraphQLResponse<FetchAuthorCardsResponse>>
  ): void {
    const authorCardsFromGraphQL = (payload.resultData.data?.authors || []).filter(author =>
      hasValue(author)
    ) as AuthorCardFromGraphQL[];
    const authorCardRecords = authorCardsFromGraphQL.map(author =>
      getAuthorCardFromGraphQL(author)
    );
    for (const authorCard of authorCardRecords) {
      this.#authorStates = this.#authorStates.set(authorCard.id, StoreState.LOADED);
      this.#authorCardRecords = this.#authorCardRecords.set(authorCard.id, authorCard);
    }
    this.emitChange();
  }

  #handleAuthorCardsFailed(payload: ApiRequestFailurePayload): void {
    const authorIds = payload.context?.variables?.authorIds || [];
    const originalAuthorStates = this.#authorStates;
    authorIds.map((authorId: number) => {
      this.#authorStates = this.#authorStates.set(authorId, StoreState.ERROR);
    });
    if (!originalAuthorStates.equals(this.#authorStates)) {
      this.emitChange();
    }
  }

  isUninitialized(authorId: number | string): Nullable<boolean> {
    if (!authorId) {
      return null;
    }
    const authorIdNum = getAuthorIdAsNumber(authorId);
    return (
      !this.#authorStates.has(authorIdNum) ||
      this.#authorStates.get(authorIdNum) === StoreState.UNINITIALIZED
    );
  }

  isLoading(authorId: number | string): boolean {
    if (this.isUninitialized(authorId)) {
      return true;
    }
    const authorIdNum = getAuthorIdAsNumber(authorId);
    return this.#authorStates.get(authorIdNum) === StoreState.LOADING;
  }

  getAuthorCard(authorId: number | string): Nullable<AuthorCardRecord> {
    if (!authorId) {
      return null;
    }
    const authorIdNum = getAuthorIdAsNumber(authorId);
    return this.#authorCardRecords.get(authorIdNum) || null;
  }
}
