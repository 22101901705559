import { getString } from '@/content/i18n';
import { PublicPromise, setState } from '@/utils/promise-utils';
import BundlePreloader from '@/utils/BundlePreloader';
import LoadingPage from '@/components/shared/loading/LoadingPage';

import PropTypes from 'prop-types';
import React from 'react';

export default class AsyncLoadedPage extends React.Component {
  _didMountProm = new PublicPromise();
  _comp = null;

  static defaultProps = {
    loadingMessage: getString(_ => _.loadingPage.defaultLoadingMessage),
    showLoadingPage: true,
  };

  static contextTypes = {
    bundlePreloader: PropTypes.instanceOf(BundlePreloader).isRequired,
  };

  constructor(...args) {
    super(...args);

    const { bundlePreloader } = this.context;
    bundlePreloader.addChunkByName(this.props.load.chunkName);
    this._comp = bundlePreloader.optSyncImport(this.props.load);

    this.state = {
      isLoading: !this._comp,
      lastError: null,
    };

    if (!this._comp) {
      this.loadComponent(this.props.load);
    }
  }

  componentDidMount() {
    this._didMountProm.resolve();
  }

  async loadComponent(loadArgs) {
    const { bundlePreloader } = this.context;
    if (!this.state.isLoading) {
      await setState(this, { isLoading: true });
    }
    let lastError = null;
    try {
      this._comp = await bundlePreloader.asyncImport(loadArgs);
    } catch (error) {
      lastError = error;
    }
    await this._didMountProm;
    await setState(this, { isLoading: false, lastError });
  }

  renderLoadingPage() {
    const { children, className, footer, header, loadingMessage } = this.props;
    const { isLoading, lastError } = this.state;
    const errorMessage = lastError ? getString(_ => _.loadingPage.defaultErrorMessage) : null;
    const pageProps = {
      children,
      className,
      footer,
      header,
    };
    return (
      <LoadingPage
        {...{
          isLoading,
          loadingMessage,
          errorMessage,
        }}
        {...pageProps}
      />
    );
  }

  renderLoadingMessage() {
    const { loadingMessage } = this.props;
    const { lastError } = this.state;
    const errorMessage = lastError ? getString(_ => _.loadingPage.defaultErrorMessage) : null;
    if (errorMessage) {
      return <span data-test-id="async-loading-error">{errorMessage}</span>;
    }
    return <span data-test-id="async-loading-indicator">{loadingMessage}</span>;
  }

  renderComponent() {
    const Comp = this._comp;
    if (!Comp) {
      return null;
    }
    const { compProps } = this.props;
    return (
      // $FlowFixMe: ?TCompProps doesn't work with components...technically...
      <Comp {...(compProps || {})} />
    );
  }

  render() {
    if (this.state.isLoading) {
      return this.props.showLoadingPage ? this.renderLoadingPage() : this.renderLoadingMessage();
    }
    return this.renderComponent();
  }
}
