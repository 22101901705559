import RecommendationsFooter from './RecommendationsFooter';
import RecommendationsList from './RecommendationsList';
import RecommendationsViewingFilterNav from './RecommendationsViewingFilterNav';

import { getString } from '@/content/i18n';
import {
  heapLibraryRecommendationResearchHomePageLink,
  heapLibraryRecommendationViewLibraryLink,
  heapRecommendationPageSettingButton,
} from '@/analytics/attributes/recommendationPage';
import { showRecommendationsSettingsShelf } from '@/actions/ShelfActionCreators';
import CLIconButton, { TYPE } from '@/components/library/button/CLIconButton';
import CLTwoColumnLayout, {
  ASIDE_POSITION,
  CLMain,
  CLUpperAside,
} from '@/components/library/layout/CLTwoColumnLayout';
import EnvInfo from '@/env/EnvInfo';
import Icon from '@/components/shared/icon/Icon';
import Link from '@/router/Link';
import Page from '@/components/shared/layout/Page';
import ResearchHomepageStore from '@/stores/ResearchHomepageStore';
import ResearchPageHeader from '@/components/shared/research/ResearchPageHeader';
import S2Dispatcher from '@/utils/S2Dispatcher';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default class RecommendationsPage extends React.PureComponent {
  static contextTypes = {
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    researchHomepageStore: PropTypes.instanceOf(ResearchHomepageStore).isRequired,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      ...this.getStateFromResearchHomepageStore(),
    };

    this.context.researchHomepageStore.registerComponent(this, () => {
      this.setState(this.getStateFromResearchHomepageStore());
    });
  }

  getStateFromResearchHomepageStore = () => {
    const { researchHomepageStore } = this.context;
    return {
      isLoading: researchHomepageStore.isRecommendationsHistoryLoading(),
    };
  };

  onClickSettings = event => {
    event.preventDefault();
    const { dispatcher } = this.context;
    dispatcher.dispatch(showRecommendationsSettingsShelf());
  };

  renderTagLine() {
    return (
      <React.Fragment>
        {getString(_ => _.recommendations.tagLine)}
        <Link
          to="LIBRARY_WITH_FOLDERS"
          className="recommendations__library-link"
          shouldUnderline={false}
          {...heapLibraryRecommendationViewLibraryLink()}>
          {getString(_ => _.recommendations.tagLineLibraryLink)}
        </Link>
      </React.Fragment>
    );
  }

  renderSettingsButton() {
    return (
      <CLIconButton
        className="recommendations__settings-button"
        {...heapRecommendationPageSettingButton()}
        onClick={this.onClickSettings}
        type={TYPE.TERTIARY}
        icon={<Icon icon="settings" height="12" width="13" />}
        label={getString(_ => _.recommendations.settingsLabel)}
      />
    );
  }

  render() {
    const { isMobile } = this.context.envInfo;
    return (
      <Page className={classnames('research', `research--is-${isMobile ? 'mobile' : 'desktop'}`)}>
        <ResearchPageHeader
          headline={getString(_ => _.recommendations.headline)}
          subhead={
            <Link
              data-test-id="research-homepage-link"
              to="RESEARCH_HOMEPAGE"
              {...heapLibraryRecommendationResearchHomePageLink()}>
              {getString(_ => _.research.headline)}
            </Link>
          }
          tagLine={this.renderTagLine()}
          aside={this.renderSettingsButton()}
          icon={<Icon icon="lightning-bolt" height="23" width="18" />}
        />
        <CLTwoColumnLayout asidePosition={ASIDE_POSITION.RIGHT}>
          <CLUpperAside className="research__page-aside research__page-aside--right">
            <RecommendationsViewingFilterNav />
          </CLUpperAside>
          <CLMain className="research__page-main">
            <div className="research__main">
              <RecommendationsList />
              <RecommendationsFooter />
            </div>
          </CLMain>
        </CLTwoColumnLayout>
      </Page>
    );
  }
}
