export type ModalActionValue = (typeof ModalAction)[keyof typeof ModalAction];

export type ModalIdValue = (typeof ModalId)[keyof typeof ModalId];

export const ModalAction = {
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
} as const;

export const ModalId = {
  AUTHOR_ALERTS_MODAL: 'AUTHOR_ALERTS_MODAL',
  CITE: 'CITE',
  COLLABORATORS: 'COLLABORATORS',
  CONFIRM_PUBLIC_LIBRARY: 'CONFIRM_PUBLIC_LIBRARY',
  CREATE_ALERT: 'CREATE_ALERT',
  DELETE_ALERT: 'DELETE_ALERT',
  DEMOGRAPHIC: 'DEMOGRAPHIC',
  ENTITY_FIGURE: 'ENTITY_FIGURE',
  EXPORT: 'EXPORT',
  FEEDBACK: 'FEEDBACK',
  CREATE_LIBRARY_FTUE: 'CREATE_LIBRARY_FTUE',
  LOGIN: 'LOGIN',
  MOBILE_NAV: 'MOBILE_NAV',
  RECOMMENDATIONS_NOT_RELEVANT: 'RECOMMENDATIONS_NOT_RELEVANT',
  SIGNUP: 'SIGNUP',
  SOURCES: 'SOURCES',
  TERMINATE_EXPERIMENT: 'TERMINATE_EXPERIMENT',
} as const;
