import {
  buildExperimentConfig,
  DynamicExperimentConfig,
  DynamicExperimentConfigMap,
} from './experimentConfigUtils';
import { ExperimentAllocation, ExperimentRecord } from './ExperimentRecord';
import { getRandomVariation, shouldAllocate } from './decisions';
import ExperimentCookieManager from './ExperimentCookieManager';

import { ApiResponse, ExposeUserResponseBody } from '@/api/ApiResponse';
import { EXPOSED, TERMINATED, UNEXPOSED } from '@/constants/ExperimentExposureState';
import { FINISHED, RUNNING } from '@/constants/ExperimentStatus';
import { runWithHeap } from '@/analytics/Heap';
import { taggedSoftError } from '@/utils/softError';
import Api from '@/api/Api';
import AuthStore from '@/stores/AuthStore';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';
import VariationMismatchEvent from '@/analytics/models/VariationMismatchEvent';

import Immutable from 'immutable';
import invariant from 'invariant';

import type { ExperimentKey, VariationKey } from './Experiment';
import type { Nullable } from '@/utils/types';
import type CookieJar from '@/utils/cookies/CookieJar';

const softError = taggedSoftError('experimentManager');
export default class ExperimentManager {
  _expCookieManager: ExperimentCookieManager;
  _config: DynamicExperimentConfigMap;
  _expMap: Immutable.Map<ExperimentKey, ExperimentRecord>;
  _tid: string;
  _api: Api;
  _authStore: AuthStore;

  constructor({
    configStr,
    cookieJar,
    experimentList,
    tid,
  }: {
    configStr: string;
    cookieJar: CookieJar;
    experimentList: Immutable.List<ExperimentRecord>;
    tid: string;
  }) {
    this._expCookieManager = new ExperimentCookieManager({ cookieJar, experimentList });
    this._config = this._buildConfig(configStr, experimentList);
    this._expMap = experimentList.reduce((map, exp) => map.set(exp.KEY, exp), Immutable.Map());
    this._tid = tid;
  }

  activate(key: ExperimentKey): Nullable<VariationKey> {
    const experimentConfig = this._config.get(key);
    const experimentRecord = this._expMap.get(key);
    const allocationType = experimentRecord?.allocationType;
    invariant(experimentConfig, 'Cannot attempt to expose an experiment that is not in the config');
    invariant(
      allocationType === ExperimentAllocation.User ||
        allocationType === ExperimentAllocation.Session,
      `AllocationType "${allocationType}" for experiment "${key}" cannot be handled, participant will not be exposed to experiment.`
    );

    if (experimentConfig.status === FINISHED) {
      return this._terminateExperiment(experimentConfig);
    }

    const existingState = this._expCookieManager.getExperimentCookie(key);

    if (existingState && existingState.exposureState === EXPOSED) {
      return existingState.variationKey;
    } else {
      if (experimentConfig.status === RUNNING) {
        const { allocationSalt, exposureSalt, trafficRatio, variations } = experimentConfig;

        switch (allocationType) {
          case ExperimentAllocation.User: {
            return this._activateUserExperiment({
              allocationSalt,
              exposureSalt,
              key,
              trafficRatio,
              variations,
            });
          }
          case ExperimentAllocation.Session: {
            return this._activateSessionExperiment({
              allocationSalt,
              exposureSalt,
              key,
              trafficRatio,
              variations,
            });
          }
          default:
            // We don't understand the allocation type, so we return null
            return null;
        }
      }
    }
    return null;
  }

  _activateSessionExperiment({
    allocationSalt,
    exposureSalt,
    key,
    trafficRatio,
    variations,
  }): Nullable<VariationKey> {
    if (shouldAllocate({ tid: this._tid, allocationSalt, trafficRatio })) {
      const assignedVariation = getRandomVariation({
        variations,
        exposureSalt,
        identifier: this._tid,
      });
      this._setCookieAndHeapMetrics(key, assignedVariation);
      return assignedVariation;
    }
    return null;
  }

  _activateUserExperiment({
    allocationSalt,
    exposureSalt,
    key,
    trafficRatio,
    variations,
  }): Nullable<VariationKey> {
    const identifier = this._getIdentifierForHash(ExperimentAllocation.User);

    if (!identifier) {
      return null;
    }

    if (shouldAllocate({ userId: identifier, allocationSalt, trafficRatio })) {
      const clientVariation = getRandomVariation({
        variations,
        exposureSalt,
        identifier,
      });

      // We set the cookie with the client calculated variation so that we have a response until the server
      // variation is returned asynchronously
      this._setCookieAndHeapMetrics(key, clientVariation);

      this._fetchUserExposureVariation(key).then(response => {
        const serverVariation = response?.resultData.variation;

        if (serverVariation) {
          // Compare the client calculated variation to the server calculated, they should match
          // and if they don't we emit a metric to track mismatches
          if (clientVariation !== serverVariation) {
            trackAnalyticsEvent(
              VariationMismatchEvent.create({ experimentKey: key, userId: identifier })
            );

            // Then we override it to the server calculated variation
            this._setCookieAndHeapMetrics(key, serverVariation);
          }
        }
        return serverVariation;
      });
      return clientVariation;
    }
    return null;
  }

  /**
   * In order to choose the right identifier used in the hash for allocation/bucketing
   * we need to know whether the tid or userId should be used based on the experiment allocation type
   */
  _getIdentifierForHash(allocationType: ExperimentAllocation): Nullable<string> {
    switch (allocationType) {
      case ExperimentAllocation.User: {
        if (!this._authStore) {
          softError('AuthStore was not associated with the ExperimentManager before use');
          return null;
        }

        const userRecord = this._authStore.getUser();
        if (userRecord) {
          return userRecord.id.toString();
        } else {
          return null;
        }
      }
      case ExperimentAllocation.Session: {
        return this._tid;
      }
      default:
        softError(
          `Experiment allocation type ${allocationType} is not user or session, unable to determine identifier for activation.`
        );
        return null;
    }
  }

  async _fetchUserExposureVariation(
    experimentKey: ExperimentKey
  ): Promise<Nullable<ApiResponse<ExposeUserResponseBody>>> {
    invariant(!!this._api, 'api must be associated before experiment can be allocated');
    invariant(!!this._authStore, 'authStore must be associated before experiment can be allocated');
    invariant(
      this._authStore.hasAuthenticatedUser(),
      'user must be signed in to be exposed to experiment'
    );

    try {
      return await this._api.exposeUser({ experimentKey });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to expose user to experiment variation.', error.toString());
      return null;
    }
  }

  _setCookieAndHeapMetrics(key: ExperimentKey, assignedVariation: VariationKey): void {
    this._expCookieManager.markAsExposed(key, assignedVariation);
    runWithHeap(heap => {
      heap.addEventProperties({
        [mkHeapPropFromKey(key)]: assignedVariation,
      });
    });
  }

  associateWithAuthStore(authStore: AuthStore): void {
    invariant(!this._authStore, 'Cannot associate authStore again');
    this._authStore = authStore;
  }

  associateWithApi(api: Api): void {
    invariant(!this._api, 'Cannot associate api again');
    this._api = api;
  }

  // Returns the variation from the cookie (whether exposed or finished)
  getVariation(experimentKey: ExperimentKey): Nullable<VariationKey> {
    const existingState = this._expCookieManager.getExperimentCookie(experimentKey);

    if (!existingState) {
      return null;
    }
    switch (existingState.exposureState) {
      case EXPOSED:
      case TERMINATED:
        return existingState.variationKey;
      default:
        return null;
    }
  }

  // Returns the variation if the person is exposed to the experiment
  getExposedVariation(experimentKey: ExperimentKey): Nullable<VariationKey> {
    const existingState = this._expCookieManager.getExperimentCookie(experimentKey);
    if (existingState && existingState.exposureState === EXPOSED) {
      return existingState.variationKey;
    }
    return null;
  }

  // Returns the variation if the experiment was terminated
  getTerminatedVariation(experimentKey: ExperimentKey): Nullable<VariationKey> {
    const existingState = this._expCookieManager.getExperimentCookie(experimentKey);
    if (existingState && existingState.exposureState === TERMINATED) {
      return existingState.variationKey;
    }
    return null;
  }

  // Build heap properties to track what experiments a user sees (including unexposed experiments)
  buildTrackExperimentsObject(): Immutable.Map<string, VariationKey> {
    return this._expMap
      .valueSeq()
      .toList()
      .reduce((result, experiment) => {
        const variation = this.getExposedVariation(experiment.KEY);
        if (!variation) {
          return result;
        }
        return result.set(mkHeapPropFromModel(experiment), variation);
      }, Immutable.Map());
  }

  // Handle state change from last time the manager was created (whether as fast as an app load, or after several weeks of not visiting the site)
  cleanupExperiments() {
    this._terminateNewlyFinishedExperiments();
    this._expCookieManager.cleanupRemovedExperiments();
  }

  // Clean up experiments that are user based, used when someone logs out of a user account
  clearUserExperimentCookies() {
    const userExperimentRecords = this._expMap.filter(
      exp => exp.allocationType === ExperimentAllocation.User
    );
    userExperimentRecords.map(record => this._expCookieManager.clearExperimentCookie(record.KEY));
  }

  // Merge the dynamic config from the server and the static config in Experiment.js
  _buildConfig(configStr, experimentList) {
    const config = buildExperimentConfig(configStr, experimentList);
    return config;
  }

  // Experiments which are now in a finished state, but were not set in cookie
  _terminateNewlyFinishedExperiments() {
    const finishedConfigs = this._config
      .valueSeq()
      .toList()
      .filter(expConfig => expConfig.status === FINISHED);
    const finishedKeys = finishedConfigs.map(_ => _.key).toJS() as ExperimentKey[];
    const finishedCookies = this._expCookieManager.getExperimentCookiesMap(finishedKeys);
    for (const expConfig of finishedConfigs) {
      const cookie = finishedCookies[expConfig.key];
      switch (cookie && cookie.exposureState) {
        case UNEXPOSED:
        case EXPOSED: {
          this._terminateExperiment(expConfig);
          break;
        }
      }
    }
  }

  // Mark an experiment as terminated and pick the variation to show
  _terminateExperiment(expConfig) {
    // When an experiment is terminated, we need to pass in an identifier to use the same function to get a random variation, but the identifier
    // isn't really used because it is the ratio that controls which variation is actually chosen as the terminated default. We pass an empty string
    // to avoid passing extraneous information.
    const assignedVariation = getRandomVariation({
      variations: expConfig.variations,
      exposureSalt: expConfig.exposureSalt,
      identifier: '',
    });

    this._expCookieManager.markAsTerminated(expConfig.key, assignedVariation);
    runWithHeap(heap => {
      heap.removeEventProperty(mkHeapPropFromConfig(expConfig));
    });
    return assignedVariation;
  }
}

export function mkHeapPropFromKey(key: ExperimentKey): string {
  return `experiment:${key}`;
}

export function mkHeapPropFromModel(model: ExperimentRecord): string {
  return mkHeapPropFromKey(model.KEY);
}

export function mkHeapPropFromConfig(config: DynamicExperimentConfig): string {
  return mkHeapPropFromKey(config.key);
}
