// This should be kept in sync with the corresponding Scala-side FolderRecommendedPaper model

import { DEPRECATED__FlowOptional } from '@/utils/types';
import { getPaperFromJS, PaperFromJS, PaperRecord, PaperRecordFactory } from '@/models/Paper';

import Immutable from 'immutable';

export type FolderRecommendedPaperFromJS = {
  libraryFolderId: number;
  rankerScore: DEPRECATED__FlowOptional<number>;
  paper: DEPRECATED__FlowOptional<PaperFromJS>;
};

type Props = {
  libraryFolderId: number;
  rankerScore: number;
  paper: PaperRecord;
};

const defaultProps: Props = {
  libraryFolderId: -1,
  rankerScore: -1,
  paper: PaperRecordFactory(),
};

export const RECORD_NAME = 'FolderRecommendedPaper';

export const FolderRecommendedPaperRecordFactory = Immutable.Record(defaultProps, RECORD_NAME);
export type FolderRecommendedPaperRecord = Immutable.RecordOf<Props>;

export function getFolderRecommendedPaperFromJS(
  args: FolderRecommendedPaperFromJS
): FolderRecommendedPaperRecord {
  return FolderRecommendedPaperRecordFactory({
    ...args,
    rankerScore: args.rankerScore || defaultProps.rankerScore,
    paper: args.paper ? getPaperFromJS(args.paper) : PaperRecordFactory(),
  });
}
