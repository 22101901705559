import { Nullable } from './types';

import { useAppContext, useStateFromStore } from '@/AppContext';

import idx from 'idx';
import invariant from 'invariant';

export function useArePapersInLibrary(paperIds: Nullable<string>[]): Record<string, boolean> {
  const { libraryFolderStore } = useAppContext();

  const result = useStateFromStore(libraryFolderStore, _ =>
    paperIds.reduce((memo, paperId) => {
      if (!paperId) {
        return memo;
      }
      memo[paperId] = _.isPaperInLibrary(paperId);
      return memo;
    }, {})
  );

  return result;
}

export function getLibraryFolderIdFromMatch(match) {
  const id = idx(match, _ => _.params.libraryFolderId);
  invariant(id, '"libraryFolderId" param was missing from path');
  const libraryFolderId = parseInt(id, 10);
  invariant(!Number.isNaN(libraryFolderId), '"libraryFolderId" in path is not a number');
  return libraryFolderId;
}
