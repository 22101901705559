import Immutable from 'immutable';

import type { Nullable } from '@/utils/types';

export type PaperLabFromJS = {
  id: number;
  key: string;
  title: string;
  html: Nullable<string>;
  shadowRootId: Nullable<string>;
};

type Props = {
  id: number;
  key: string;
  title: string;
  html: Nullable<string>;
  shadowRootId: Nullable<string>;
};

const defaultProps: Props = {
  id: 0,
  key: '',
  title: '',
  html: null,
  shadowRootId: null,
};

export const PaperLabFactory = Immutable.Record<Props>(defaultProps);
export type PaperLabRecord = Immutable.RecordOf<Props>;

export function getPaperLabRecordFromJS(props: PaperLabFromJS): PaperLabRecord {
  return PaperLabFactory({
    ...props,
  });
}
