import { ADMIN, LOGIN_AS } from '@/constants/Role';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

export default class AdminLoginAsRoute extends React.Component {
  static requiresRoleFrom = () => [ADMIN, LOGIN_AS];

  render() {
    return (
      <AsyncLoadedPage
        showLoadingPage={false}
        load={{
          importer: () =>
            import(/* webpackChunkName: "desktop-AdminLoginAsPage" */ './AdminLoginAsPage'),
          chunkName: 'desktop-AdminLoginAsPage',
          moduleId: require.resolveWeak('./AdminLoginAsPage'),
        }}
      />
    );
  }
}
