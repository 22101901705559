import { getString } from '@/content/i18n';
import { Less, More, NotRelevant, NotRelevantRemoveX } from '@/models/library/AnnotationType';
import CLIconButton, { TYPE } from '@/components/library/button/CLIconButton';
import Icon from '@/components/shared/icon/Icon';

import classnames from 'classnames';
import React from 'react';

/* Use CLPaperFeedAnnotations for the business logic and display. This component is just the display */
export default function CLPaperAnnotationButton({
  buttonAnnotationType,
  isActive,
  className,
  onClick,
  heapProps,
  testId,
}) {
  let activeIconId;
  let inactiveIconId;
  let label;

  switch (buttonAnnotationType) {
    case More: {
      inactiveIconId = 'thumbs-up';
      activeIconId = 'thumbs-up-active';
      label = getString(_ => _.library.annotationButtonLabels.More);
      break;
    }
    case Less: {
      inactiveIconId = 'thumbs-down';
      activeIconId = 'thumbs-down-active';
      label = getString(_ => _.library.annotationButtonLabels.Less);
      break;
    }
    case NotRelevant: {
      inactiveIconId = 'thumbs-down';
      activeIconId = 'thumbs-down-active';
      label = getString(_ => _.paper.action.notRelevantAnnotation);
      break;
    }
    case NotRelevantRemoveX: {
      inactiveIconId = 'remove-x';
      activeIconId = 'remove-x';
      label = getString(_ => _.paper.action.notRelevantAnnotation);
      break;
    }
    default: {
      throw new Error(`Unknown annotationType: ${buttonAnnotationType}`);
    }
  }

  return (
    <CLIconButton
      onClick={onClick}
      type={TYPE.TERTIARY}
      label={label}
      icon={
        <Icon
          className={className}
          icon={isActive ? activeIconId : inactiveIconId}
          height="11"
          width="11"
        />
      }
      className={classnames(
        {
          'cl-paper-action__button': true,
        },
        className
      )}
      heapProps={heapProps}
      testId={testId}
    />
  );
}

CLPaperAnnotationButton.defaultProps = {
  isActive: false,
  testId: 'annotate-button',
};
