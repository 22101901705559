import { AuthorAliasRecordFactory, getAuthorAliasLinkedData } from './author/AuthorAlias';
import {
  BadgeId,
  optPaperBadgeId,
  PaperBadgeProps,
  PaperBadgeRecord,
  PaperBadgeRecordFactory,
} from './PaperBadge';
import {
  CitationStatsFromJS,
  CitationStatsRecord,
  CitationStatsRecordFactory,
  getCitationStatsFromJS,
} from './CitationStats';
import {
  CueDataWrapperFromJS,
  CueDataWrapperRecord,
  getCueDataWrapperMapFromJS,
} from './CueDataWrapper';
import { DebugInfoFromJS, debugInfoFromJs, DebugInfoRecord } from './DebugInfo';
import {
  EntityLiteProperties,
  EntityLiteRecord,
  EntityLiteRecordFactory,
} from './entity/EntityLite';
import {
  EntityRelationMentionFromJS,
  EntityRelationMentionRecord,
  getEntityRelationMentionFromJS,
} from './entity/EntityRelationMention';
import {
  getHighlightedFieldFromJS,
  HighlightedFieldFromJS,
  HighlightedFieldRecord,
  HighlightedFieldRecordFactory,
} from './HighlightedField';
import { getPaperFAQFromJS, PaperFAQFromJS, PaperFAQRecord } from './PaperFAQ';
import { getPaperLinkFromJS, PaperLinkFromJS, PaperLinkRecord } from './PaperLink';
import { getScorecardStatFromJS, ScorecardStatFromJS, ScorecardStatRecord } from './ScorecardStat';
import {
  getStructuredAuthorNameFromJS,
  StructuredAuthorNameFromJS,
  StructuredAuthorNameRecord,
} from './StructuredAuthorName';
import { getTldrFromJS, TldrFromJS, TldrRecord, TldrRecordFactory } from './Tldr';
import {
  HighlightedAuthorFromJS,
  HighlightedAuthorRecord,
  HighlightedAuthorRecordFactory,
  parseHighlightedAuthors,
} from './HighlightedAuthor';
import { JournalFromJS, JournalRecord, JournalRecordFactory } from './Journal';
import { PAPER_LITE_RECORD_NAME, PaperLiteRecord } from './PaperLite';
import { YearStatBucketRecordFactory } from './citation-stats/YearStatBucket';

import { createPubDateHighlightedField } from '@/utils/paper-util';
import { DEPRECATED__FlowOptional, Nullable, TODO, TODO__ENUM } from '@/utils/types';
import { getString } from '@/content/i18n';
import { makePath, RouteName } from '@/router/Routes';

import gql from 'graphql-tag';
import Immutable from 'immutable';

import type { CueType } from '@/constants/CueType';

export const PAPER_RECORD_NAME = 'Paper';

// We don't use this for anything in the webapp, but it's part of the response.
type TODO__PaperSocialLink = TODO<'PaperSocialLink'>;

type DoiInfo = {
  doi: string;
  doiUrl: string;
};

export type PaperRecordFromGraphQL = {
  abstract: string;
  authors: {
    edges: {
      node: HighlightedAuthorFromGraphQL;
    }[];
  };
  badges: string[];
  citationCount: number;
  fieldsOfStudy: string[];
  id: string;
  isPDFAvailable: boolean;
  keyCitationCount: number;
  keyReferenceCount: number;
  pubDate: string;
  referenceCount: number;
  slug: string;
  title: string;
  tldr: Nullable<string>;
  venueName: string;
  venue: Nullable<{
    id: string;
    name: string;
  }>;
  year: string;
  abstractSimilarityScore: Nullable<number>;
  citationsByYear: CitationsByYearFromGraphQL[];
};

type CitationsByYearFromGraphQL = {
  year: number;
  citationCount: number;
};

type HighlightedAuthorFromGraphQL = {
  fullName: string;
  id: number;
  isClaimed: boolean;
  slug: string;
};

export type PaperFromJS = {
  id: DEPRECATED__FlowOptional<string>;
  authors: HighlightedAuthorFromJS[];
  alternatePaperLinks: PaperLinkFromJS[];
  badges: PaperBadgeProps[];
  citationStats: CitationStatsFromJS;
  corpusId: DEPRECATED__FlowOptional<number>;
  cues: Record<string, CueDataWrapperFromJS[]>;
  debugInfo: DEPRECATED__FlowOptional<DebugInfoFromJS>;
  doiInfo: DEPRECATED__FlowOptional<DoiInfo>;
  entities: EntityLiteProperties[];
  entityRelations: EntityRelationMentionFromJS[];
  faqs: PaperFAQFromJS[];
  fieldsOfStudy: DEPRECATED__FlowOptional<TODO__ENUM<'FieldOfStudy'>[]>;
  hasPdf: boolean;
  isPdfVisible: boolean;
  indexInclusionReason: DEPRECATED__FlowOptional<string>;
  journal: DEPRECATED__FlowOptional<JournalFromJS>;
  links: PaperLinkFromJS[];
  paperAbstract: HighlightedFieldFromJS;
  paperAbstractTruncated: DEPRECATED__FlowOptional<string>;
  primaryPaperLink: DEPRECATED__FlowOptional<PaperLinkFromJS>;
  pubDate: DEPRECATED__FlowOptional<string>;
  readerId: DEPRECATED__FlowOptional<string>;
  scorecardStats: DEPRECATED__FlowOptional<ScorecardStatFromJS[]>;
  structuredAuthors: StructuredAuthorNameFromJS[];
  socialLinks: TODO__PaperSocialLink[];
  title: HighlightedFieldFromJS;
  tldr: DEPRECATED__FlowOptional<TldrFromJS>;
  venue: HighlightedFieldFromJS;
  venueId: string;
  year: HighlightedFieldFromJS;
};

export type Props = {
  id: string;
  authors: Immutable.List<HighlightedAuthorRecord>;
  alternatePaperLinks: Immutable.List<PaperLinkRecord>;
  badges: Immutable.List<PaperBadgeRecord>;
  citationStats: Nullable<CitationStatsRecord>;
  corpusId: DEPRECATED__FlowOptional<number>;
  cues: Immutable.Map<CueType, Immutable.List<CueDataWrapperRecord>>;
  doiInfo: Nullable<DoiInfo>;
  entities: Immutable.List<EntityLiteRecord>;
  entityRelations: Immutable.List<EntityRelationMentionRecord>;
  debugInfo: Nullable<DebugInfoRecord>;
  faqs: Immutable.List<PaperFAQRecord>;
  // This corresponds to the common-model enum, e.g. "Computer Science"
  fieldsOfStudy: Immutable.List<TODO__ENUM<'FieldOfStudy'>>;
  hasPdf: boolean;
  isReaderAvailable: boolean;
  indexInclusionReason: DEPRECATED__FlowOptional<string>;
  journal: Nullable<JournalRecord>;
  links: Immutable.List<PaperLinkRecord>;
  paperAbstract: HighlightedFieldRecord;
  paperAbstractTruncated: DEPRECATED__FlowOptional<string>;
  pubDate: Nullable<HighlightedFieldRecord>;
  primaryPaperLink: DEPRECATED__FlowOptional<PaperLinkRecord>;
  readerId: Nullable<string>;
  scorecardStats: Immutable.List<ScorecardStatRecord>;
  slug: string;
  structuredAuthors: Immutable.List<StructuredAuthorNameRecord>;
  socialLinks: Immutable.List<TODO__PaperSocialLink>;
  title: HighlightedFieldRecord;
  tldr: Nullable<TldrRecord>;
  venue: HighlightedFieldRecord;
  venueId: string;
  year: HighlightedFieldRecord;
};

const defaultProps: Props = {
  alternatePaperLinks: Immutable.List(),
  authors: Immutable.List(),
  badges: Immutable.List(),
  citationStats: CitationStatsRecordFactory(),
  corpusId: null,
  cues: Immutable.Map(),
  debugInfo: null,
  doiInfo: null,
  entities: Immutable.List(),
  entityRelations: Immutable.List(),
  faqs: Immutable.List(),
  // This corresponds to the common-model enum, e.g. "Computer Science"
  fieldsOfStudy: Immutable.List(),
  hasPdf: false,
  id: '',
  indexInclusionReason: null,
  isReaderAvailable: false,
  journal: null,
  links: Immutable.List(),
  paperAbstract: HighlightedFieldRecordFactory(),
  paperAbstractTruncated: null,
  primaryPaperLink: null,
  pubDate: HighlightedFieldRecordFactory(),
  readerId: null,
  scorecardStats: Immutable.List(),
  slug: '',
  socialLinks: Immutable.List(),
  structuredAuthors: Immutable.List(),
  title: HighlightedFieldRecordFactory(),
  tldr: null,
  venue: HighlightedFieldRecordFactory(),
  venueId: '',
  year: HighlightedFieldRecordFactory(),
};

export const PaperRecordFactory = Immutable.Record(defaultProps, PAPER_RECORD_NAME);
export type PaperRecord = Immutable.RecordOf<Props>;

export type PaperishRecord = PaperRecord | PaperLiteRecord;

export function isPaperRecord(paperish: PaperishRecord): paperish is PaperRecord {
  return Immutable.Record.getDescriptiveName(paperish) === PAPER_RECORD_NAME;
}

export function isPaperLiteRecord(paperish: PaperishRecord): paperish is PaperLiteRecord {
  return Immutable.Record.getDescriptiveName(paperish) === PAPER_LITE_RECORD_NAME;
}

export function getPaperLinkByType(
  paper: PaperRecord,
  linkType: TODO<'LinkType'>
): PaperLinkRecord | undefined {
  return paper.links.find(link => link.linkType === linkType);
}

/**
 * Returns the JSON-LD (JSON Linked Data) representation of the Paper.
 * Used for providing metadata to crawlers to support enhanced SERP.
 * See https://developers.google.com/structured-data/ for more information.
 */
export function getPaperLinkedData(paper: PaperRecord, routeName: RouteName): TODO<'LinkedData'> {
  const authors = paper.authors
    .map(highlightedAuthor => getAuthorAliasLinkedData(highlightedAuthor.alias))
    .toJS();

  const mainEntityOfPage = makePath({
    routeName,
    params: { paperId: paper.id, slug: paper.slug },
  });

  const url = paper.primaryPaperLink?.url || paper.alternatePaperLinks.first()?.url;

  return {
    '@graph': [
      {
        // https://schema.org/Article
        '@type': 'Article',
        about: paper.tldr?.text, // this field is used as TLDR
        abstract: getPaperDescription(paper),
        author: authors,
        copyrightYear: paper.year.text,
        datePublished: paper.pubDate?.text || paper.year.text,
        headline: paper.title.text, // Required for Google articles
        mainEntity: url, // the original publisher's link (not necessarily a pdf link)
        mainEntityOfPage,
        name: paper.title.text,
        publication: paper.journal?.name,
        publisher: { '@type': 'Organization', name: paper.venue.text },
        sameAs: paper.doiInfo?.doiUrl,
      },
      {
        // https://schema.org/ScholarlyArticle
        '@type': 'ScholarlyArticle',
        about: paper.tldr?.text, // this field is used as TLDR
        abstract: getPaperDescription(paper),
        author: authors,
        copyrightYear: paper.year.text,
        datePublished: paper.pubDate?.text || paper.year.text,
        headline: paper.title.text, // Required for Google articles
        mainEntity: url, // the original publisher's link (not necessarily a pdf link)
        mainEntityOfPage,
        name: paper.title.text,
        publication: paper.journal?.name,
        publisher: { '@type': 'Organization', name: paper.venue.text },
        sameAs: paper.doiInfo?.doiUrl,
      },
    ],
  };
}

/**
 * The description of the paper, which is by default the abstract.
 * description would also include the TLDR when available
 */
export function getPaperDescription(paper: PaperRecord): Nullable<string> {
  if (paper.paperAbstract && paper.paperAbstract.text) {
    if (paper.tldr) {
      return paper.tldr.text + ' ' + paper.paperAbstract.text;
    } else {
      return paper.paperAbstract.text;
    }
  } else if (paper.authors.size) {
    const authors =
      paper.authors.size === 1
        ? paper.authors.first()?.alias.name
        : `${paper.authors.first()?.alias.name} et al.`;
    return getString(_ => _.metaDescription.paperFallback.content, paper.title.text, authors || '');
  }
  return null;
}

export function getPaperFromJS(args: PaperFromJS): PaperRecord {
  const highlightedFieldYear = getHighlightedFieldFromJS(args.year);
  return PaperRecordFactory({
    ...args,
    id: args.id || defaultProps.id,
    title: getHighlightedFieldFromJS(args.title),
    authors: parseHighlightedAuthors(args.authors),
    socialLinks: Immutable.List(args.socialLinks),
    structuredAuthors: Immutable.List(
      args.structuredAuthors.map(author => getStructuredAuthorNameFromJS(author))
    ),
    paperAbstract: getHighlightedFieldFromJS(args.paperAbstract),
    venue: getHighlightedFieldFromJS(args.venue),
    year: highlightedFieldYear,
    pubDate: args.pubDate
      ? createPubDateHighlightedField(highlightedFieldYear, args.pubDate)
      : null,
    citationStats: getCitationStatsFromJS(args.citationStats),
    cues: args.cues ? getCueDataWrapperMapFromJS(args.cues) : Immutable.Map(),
    isReaderAvailable: args.isPdfVisible,
    journal: args.journal ? JournalRecordFactory(args.journal) : null,
    links: Immutable.List(args.links.map(data => getPaperLinkFromJS(data))),
    primaryPaperLink: args.primaryPaperLink ? getPaperLinkFromJS(args.primaryPaperLink) : null,
    alternatePaperLinks: Immutable.List(
      args.alternatePaperLinks.map(data => getPaperLinkFromJS(data))
    ),
    entities: Immutable.List(args.entities.map(EntityLiteRecordFactory)),
    entityRelations: Immutable.List(args.entityRelations.map(getEntityRelationMentionFromJS)),
    faqs: args.faqs ? Immutable.List(args.faqs.map(getPaperFAQFromJS)) : Immutable.List(),
    scorecardStats: args.scorecardStats
      ? Immutable.List(args.scorecardStats.map(getScorecardStatFromJS))
      : Immutable.List(),
    fieldsOfStudy: args.fieldsOfStudy ? Immutable.List(args.fieldsOfStudy) : Immutable.List(),
    badges: Immutable.List(args.badges ? args.badges.map(PaperBadgeRecordFactory) : []),
    debugInfo: args.debugInfo ? debugInfoFromJs(args.debugInfo) : null,
    tldr: args.tldr ? getTldrFromJS(args.tldr) : null,
  });
}

export function getPaperRecordFromGraphQL(args: PaperRecordFromGraphQL): PaperRecord {
  const {
    id,
    title,
    slug,
    pubDate,
    isPDFAvailable,
    abstract,
    year,
    venueName,
    venue,
    fieldsOfStudy,
    tldr,
    citationCount,
    keyCitationCount,
    referenceCount,
    keyReferenceCount,
    badges,
    authors,
    abstractSimilarityScore,
    citationsByYear,
  } = args;

  const highlightedAuthorRecords: HighlightedAuthorRecord[] = authors.edges.map(({ node }) => {
    return HighlightedAuthorRecordFactory({
      alias: AuthorAliasRecordFactory({
        name: node.fullName,
        slug: node.slug,
        ids: Immutable.List([node.id.toString()]),
        optIsClaimed: node.isClaimed,
      }),
      highlightedField: getHighlightedFieldFromJS({
        text: node.fullName,
        fragments: [],
      }),
    });
  });

  const badgeValues = badges
    .map(badge => optPaperBadgeId(badge))
    .filter((badgeValue): badgeValue is BadgeId => badgeValue !== null);

  const badgeRecords = badgeValues.map(badgeValue => {
    return PaperBadgeRecordFactory({
      id: badgeValue,
    });
  });

  const yearStatBucketRecord = citationsByYear.map(citationByYear => {
    return YearStatBucketRecordFactory({
      startKey: citationByYear.year,
      endKey: citationByYear.year,
      count: citationByYear.citationCount,
    });
  });

  const maybeNormalizedVenueName = getHighlightedFieldFromJS({
    text: venue ? venue.name : venueName,
  });

  return PaperRecordFactory({
    id: id,
    title: getHighlightedFieldFromJS({
      text: title,
    }),
    slug: slug,
    pubDate: getHighlightedFieldFromJS({
      text: pubDate,
    }),
    authors: Immutable.List(highlightedAuthorRecords),
    hasPdf: isPDFAvailable,
    paperAbstract: getHighlightedFieldFromJS({
      text: abstract,
    }),
    year: getHighlightedFieldFromJS({
      text: year,
    }),
    // use the normalized venue name if possible
    venue: maybeNormalizedVenueName,
    venueId: venue?.id,
    fieldsOfStudy: Immutable.List(fieldsOfStudy),
    tldr: TldrRecordFactory({
      text: tldr || '',
      abstractSimilarityScore: abstractSimilarityScore,
    }),
    citationStats: CitationStatsRecordFactory({
      citedByBuckets: Immutable.List(yearStatBucketRecord),
      numCitations: citationCount,
      numReferences: referenceCount,
      numViewableReferences: 0,
      numKeyCitations: keyCitationCount,
      numKeyReferences: keyReferenceCount,
      keyCitationRate: 0,
      estNumCitations: 0,
    }),
    badges: Immutable.List(badgeRecords),
  });
}

export const PAPER_FRAGMENT_NAME = 'PaperRecordFragment';

export const PAPER_FRAGMENT = gql`
    fragment ${PAPER_FRAGMENT_NAME} on Paper {
      id
      abstract
      year
      title
      pubDate
      slug
      authors {
        edges {
          node {
            id
            fullName
            isClaimed
            slug
          }
        }
      }
      isPDFAvailable
      venueName
      venue {
         id
         name
      }
      fieldsOfStudy
      tldr
      citationCount
      keyCitationCount
      referenceCount
      keyReferenceCount
      badges
      abstractSimilarityScore
      citationsByYear {
        year
        citationCount
      }
    }
`;
