import { ReactNodeish } from '@/utils/types';

import classnames from 'classnames';
import React from 'react';

export type CLTextInputBaseProps = {
  ariaLabel?: string;
  className?: string;
  name?: string;
  placeholder?: string;
  type?: string;
  value?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export default function CLTextInputBase({
  ariaLabel,
  className,
  name,
  placeholder,
  type = 'text',
  value,
  ...otherProps
}: CLTextInputBaseProps): ReactNodeish {
  return (
    <input
      {...otherProps}
      type={type}
      className={classnames('cl-text-input', className)}
      aria-label={ariaLabel}
      name={name}
      placeholder={placeholder}
      value={value}
    />
  );
}
