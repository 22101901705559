import { ValueOf } from '@/utils/types';

import Immutable from 'immutable';

export const ReaderFeatureName = {
  citations: 'citations',
  acronyms: 'acronyms',
  terms: 'terms',
  scims: 'scims',
} as const;

export type ReaderFeatureNameType = ValueOf<typeof ReaderFeatureName>;

export type PaperPdfOverlapsPropsFromJS = {
  boundingBoxOverlaps: {
    [N1 in ReaderFeatureNameType]?: {
      [N2 in ReaderFeatureNameType]?: string[][];
    };
  };
};

export type OverlapItemProps = {
  s2airsId: string;
  featureName: ReaderFeatureNameType;
};

export type PaperPdfOverlapsMap = Immutable.Map<OverlapItemProps, Immutable.List<OverlapItemProps>>;

export function getEmptyPaperPdfOverlapsMap(): PaperPdfOverlapsMap {
  return Immutable.Map();
}

export function getPaperPdfOverlapsFromJS({
  boundingBoxOverlaps,
}: PaperPdfOverlapsPropsFromJS): PaperPdfOverlapsMap {
  const visitedKeyMap = new Map<string, OverlapItemProps>();
  const overlapMap = Immutable.Map<
    OverlapItemProps,
    Immutable.List<OverlapItemProps>
  >().asMutable();

  for (const featureName of Object.keys(boundingBoxOverlaps)) {
    const overlappingFeatureMap = boundingBoxOverlaps[featureName];
    for (const overlappingFeatureName of Object.keys(overlappingFeatureMap)) {
      const s2airsIdPairs: string[][] = overlappingFeatureMap[overlappingFeatureName];
      s2airsIdPairs.forEach(([s2airsId, overlappingS2airsId]) => {
        let key = visitedKeyMap.get(s2airsId);
        if (!key) {
          const newKey = {
            s2airsId: s2airsId,
            featureName: featureName as ReaderFeatureNameType,
          };
          visitedKeyMap.set(s2airsId, newKey);
          key = newKey;
        }

        const listOfOverlapItems = overlapMap.get(key, Immutable.List<OverlapItemProps>());
        overlapMap.set(
          key,
          listOfOverlapItems.push({
            s2airsId: overlappingS2airsId,
            featureName: overlappingFeatureName as ReaderFeatureNameType,
          })
        );
      });
    }
  }

  return overlapMap.asImmutable();
}
