import { cleanAndStringifyQuery } from './routerUtils';
import { isRouteProxied, makePath, PathParams, RouteName } from './Routes';

import { Link as ReactRouterLink } from 'react-router-dom';
import classnames from 'classnames';
import React from 'react';

import type { Nullable, ReactNodeish } from '@/utils/types';

export type S2LinkProps = React.PropsWithChildren<{
  className?: Nullable<string>;
  to: RouteName;
  params?: PathParams;
  query?: object;
  hash?: string;
  shouldUnderline?: Nullable<boolean>;
  shouldOpenInNewTab?: Nullable<boolean>;
  enableVisitedStyle?: Nullable<boolean>;
  onClick?: (e: React.SyntheticEvent) => void;
  id?: string;
}>;

const Link = (props: S2LinkProps): ReactNodeish => {
  const {
    children,
    className,
    to,
    params,
    query,
    hash,
    shouldUnderline,
    shouldOpenInNewTab,
    enableVisitedStyle,
    ...rest
  } = props;
  const toObj = {
    pathname: makePath({ params, routeName: to }),
    search: query ? cleanAndStringifyQuery(query) : '',
    hash: hash || '',
  };

  // This logic was added for proxied routes so the browser back button works
  // related issue: #29512
  if (isRouteProxied(to)) {
    return (
      <a
        href={makePath({ params, routeName: to, query, hash })}
        {...(shouldOpenInNewTab ? { target: '_blank' } : {})}
        className={classnames(className, {
          'link-button--no-underline': !shouldUnderline,
          'link-button--in-new-tab': shouldOpenInNewTab,
          'link-button--show-visited': enableVisitedStyle,
        })}
        {...rest}>
        {children}
      </a>
    );
  }

  return (
    <ReactRouterLink
      to={toObj}
      {...(shouldOpenInNewTab ? { target: '_blank' } : {})}
      className={classnames(className, {
        'link-button--no-underline': !shouldUnderline,
        'link-button--in-new-tab': shouldOpenInNewTab,
        'link-button--show-visited': enableVisitedStyle,
      })}
      {...rest}>
      {children}
    </ReactRouterLink>
  );
};

Link.defaultProps = {
  shouldUnderline: true,
  shouldOpenInNewTab: false,
  enableVisitedStyle: false,
};

export default Link;
