// This should be kept in sync with a corresponding scala-side model LibraryFolder.SourceType
import { LibraryFolderRecord } from './LibraryFolder';

import { Nullable, ValueOf } from '@/utils/types';

import invariant from 'invariant';

export type LibraryFolderSourceType = ValueOf<typeof byKey>;

export const AllPapers = 'AllPapers';
export const AuthorLibraryFolder = 'AuthorLibraryFolder';
export const Feed = 'Feed';
export const Library = 'Library';
export const Unsupported = 'Unsupported';

const byKey = {
  AllPapers,
  AuthorLibraryFolder,
  Feed,
  Library,
  Unsupported,
} as const;

export const LibraryFolderSourceTypeMap = Object.freeze(byKey);

/*
  Folder SourceType Checks
  Keep these in sync with LibraryFolder.scala in the online-service
   */

export const SystemGeneratedFolderTypes: readonly LibraryFolderSourceType[] = [
  AllPapers,
  AuthorLibraryFolder,
] as const;

export const LibraryVisibleFolderTypes: readonly LibraryFolderSourceType[] = [
  AllPapers,
  AuthorLibraryFolder,
  Feed,
  Library,
];

export const EditableFolderTypes: readonly LibraryFolderSourceType[] = [
  AuthorLibraryFolder,
  Feed,
  Library,
];

export function isSystemGeneratedFolder(folder: LibraryFolderRecord): boolean {
  return SystemGeneratedFolderTypes.indexOf(folder.sourceType) >= 0;
}

export function isEditableFolder(folder: LibraryFolderRecord): boolean {
  return EditableFolderTypes.indexOf(folder.sourceType) >= 0;
}

export function isLibraryVisibleFolder(folder: LibraryFolderRecord): boolean {
  return LibraryVisibleFolderTypes.indexOf(folder.sourceType) >= 0;
}

export function isAllPapersFolder(folder: LibraryFolderRecord): boolean {
  return folder.sourceType === AllPapers;
}

export function isAuthorLibraryFolder(folder: LibraryFolderRecord): boolean {
  return folder.sourceType === AuthorLibraryFolder;
}

export function getOptLibraryFolderSourceType(
  optSource?: Nullable<string>
): Nullable<LibraryFolderSourceType> {
  if (optSource && optSource in byKey) {
    return byKey[optSource];
  }
  return null;
}

export function getLibraryFolderSourceType(optSource?: Nullable<string>): LibraryFolderSourceType {
  const expSource = getOptLibraryFolderSourceType(optSource);
  invariant(expSource, `"${optSource || ''}" is not a valid LibraryFolderSourceType`);
  return expSource;
}
