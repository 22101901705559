import React from 'react';

export default function IconShareEmail(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 32 32">
      <path
        d="M32,3.43v25.14c0,1.89-1.54,3.43-3.43,3.43H3.43C1.54,32,0,30.46,0,28.57V3.43C0,1.54,1.54,0,3.43,0h25.14
        C30.46,0,32,1.54,32,3.43z M16,18.29c1.04,0.02,2.53-1.3,3.28-1.85c6.48-4.7,6.41-4.71,8.15-6.07v-1.8c0-0.95-0.77-1.71-1.71-1.71
        H6.29c-0.95,0-1.71,0.77-1.71,1.71v1.8c1.74,1.35,1.67,1.37,8.15,6.07C13.47,16.98,14.96,18.3,16,18.29z M20.62,18.28
        c-0.98,0.71-2.7,2.3-4.62,2.29c-1.91,0.01-3.61-1.55-4.62-2.29c-4.43-3.21-5.81-4.24-6.81-5.01v10.16c0,0.95,0.77,1.71,1.71,1.71
        h19.43c0.95,0,1.71-0.77,1.71-1.71V13.27C26.43,14.04,25.05,15.07,20.62,18.28z"
      />
    </symbol>
  );
}
