import LayoverClient, { Entry, FlushResult } from './LayoverClient';

import { isBrowser } from '@/utils/env';
import { TID } from '@/constants/UserCookie';
import Feature from '@/weblab/Feature';
import logger from '@/logger';

import Immutable from 'immutable';
import moment from 'moment';
import request from 'superagent';

import type { Nullable } from '@/utils/types';
import type AppContext from '@/AppContext';

export const DEFAULT_MAX_ENTRY_AGE_MS = 5000; // 5 seconds
export const DEFAULT_MAX_ENTRIES_BEFORE_FLUSH = 20; // Number of entries to buffer before forcing a flush

export default class LayoverDefaultClient implements LayoverClient {
  _maxEntryAgeMs: number;
  _maxEntriesBeforeFlush: number;
  _queue: Immutable.List<Entry>;
  _flushTimer: Nullable<NodeJS.Timeout>;
  _destinationUrl: string;
  _appContext: Nullable<AppContext>;

  constructor({
    destinationUrl,
    maxEntryAgeMs,
    maxEntriesBeforeFlush,
  }: {
    destinationUrl: string;
    maxEntryAgeMs?: number;
    maxEntriesBeforeFlush?: number;
  }) {
    this._maxEntryAgeMs = maxEntryAgeMs || DEFAULT_MAX_ENTRY_AGE_MS;
    this._maxEntriesBeforeFlush = maxEntriesBeforeFlush || DEFAULT_MAX_ENTRIES_BEFORE_FLUSH;
    this._destinationUrl = destinationUrl;
    this._flushTimer = null;
    this._queue = Immutable.List();
    this._appContext = null;

    if (typeof window !== 'undefined') {
      window.addEventListener('popstate', () => this._onUrlChange());
      window.addEventListener('unload', () => this._onAppUnload());
    }
  }

  associateWithAppContext(appContext: AppContext): void {
    this._appContext = appContext;
  }

  getUserId(): Nullable<number> {
    return this._appContext?.authStore?.getUser()?.id || null;
  }

  getBrowserId(): Nullable<string> {
    return this._appContext?.cookieJar?.getCookie(TID) || null;
  }

  send(entry: Entry): boolean {
    const wasAdded = this._addToQueue(entry);
    if (wasAdded) {
      this._scheduleFlush();
    }
    return wasAdded;
  }

  sendNow(entry: Entry): boolean {
    const wasAdded = this._addToQueue(entry);
    if (wasAdded) {
      if (this._isTransportEnabled()) {
        this.flush();
      } else {
        this._scheduleFlush();
      }
    }
    return wasAdded;
  }

  // Add given entry to the queue
  _addToQueue(entry: Entry): boolean {
    if (!isBrowser() || !this._isCollectingEnabled()) {
      return false;
    }

    this._queue = this._queue.push(entry);
    if (this._queue.size > this._maxEntriesBeforeFlush) {
      this.flush();
    }
    return true;
  }

  // Determine whether entries should be sent to the server
  _isTransportEnabled(): boolean {
    if (!this._appContext) {
      return false;
    }

    const { weblabStore } = this._appContext;

    if (!weblabStore || !weblabStore.isInitialized()) {
      // Don't send entries to server until we know we are allowed to
      return false;
    }
    return !!weblabStore.isFeatureEnabled(Feature.LayoverClient);
  }

  // Determine whether to add entries to the queue (or not, to save memory)
  _isCollectingEnabled(): boolean {
    if (!this._appContext?.weblabStore.isInitialized()) {
      // Collect all entries until we know whether they can be sent to the server
      return true;
    }
    return this._isTransportEnabled();
  }

  // Stop the timer to send entries
  _cancelScheduledFlush(): void {
    if (this._flushTimer) {
      clearTimeout(this._flushTimer);
      this._flushTimer = null;
    }
  }

  // Start a timer to schedule sending entries to the server if not already set
  _scheduleFlush(): void {
    if (!this._flushTimer) {
      this._flushTimer = setTimeout(() => void this.flush(), this._maxEntryAgeMs);
    }
  }

  // Cause the queue of entries to send to the server
  flush(): Promise<FlushResult> {
    this._cancelScheduledFlush();
    if (this._queue.isEmpty() || !this._isTransportEnabled()) {
      return Promise.resolve({ sentToServer: false });
    }
    const entries = this._queue;
    this._queue = Immutable.List();

    return Promise.resolve()
      .then(() => this._toJSON(entries))
      .then(json => this._transportUsingAjax(json))
      .then(() => ({ sentToServer: true }))
      .catch(error => {
        // Add items back to the queue, so they will be sent in next request
        this._queue = entries.concat(this._queue).toList();
        this._scheduleFlush();
        logger.error(error);
        return { sentToServer: false, error };
      });
  }

  // Change to a new page, flush
  _onUrlChange(): void {
    this.flush();
  }

  // Final chance to send entries, flush them now!
  _onAppUnload(): void {
    if (this._queue.isEmpty() || !this._isTransportEnabled()) {
      return;
    }
    const json = this._toJSON(this._queue);
    if (this._transportUsingBeacon(json)) {
      return;
    }
    this._transportUsingAjax(json);
  }

  // Send the entries using a navigator.sendBeacon() as the transport
  _transportUsingBeacon(json: string): boolean {
    try {
      if (window.navigator.sendBeacon) {
        return window.navigator.sendBeacon(this._destinationUrl, json);
      }
    } catch (error) {
      logger.error(error);
    }
    return false;
  }

  // Send the entries using an ajax query as the transport
  _transportUsingAjax(json: string): void {
    request
      .post(this._destinationUrl)
      .set('Content-Type', 'text/plain;charset=UTF-8') // To match sendBeacon
      .send(json)
      .end();
  }

  // Build the json string for a list of entries
  _toJSON(entries: Immutable.List<Entry>): string {
    return JSON.stringify(
      {
        clientTimestamp: moment().toISOString(),
        entries: entries.toJS(),
      },
      replaceErrors
    );
  }
}

// By default Error objects are not json serializable and were being stripped out at serialization time.
// This snippet from https://stackoverflow.com/questions/18391212/is-it-not-possible-to-stringify-an-error-using-json-stringify
// will replace the Error object with a plain object with the Error's properties on it (basically 'message' and 'stacktrace' in most cases)
function replaceErrors(key: string, value: any) {
  if (value instanceof Error) {
    const errorFields = {};

    Object.getOwnPropertyNames(value).forEach(function (key) {
      errorFields[key] = value[key];
    });

    return errorFields;
  }

  return value;
}
