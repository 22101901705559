import { mkOnClickKeyDown } from '@/utils/a11y-utils';

import React, { PureComponent } from 'react';

import type { Nullable } from '@/utils/types';

type Props = {
  citation: string;
  citationStyle: string;
  tabIndex: number;
  onTextSelected?: Nullable<() => void>;
};

export default class FormattedCitation extends PureComponent<Props> {
  static defaultProps = {
    tabIndex: 0,
  };

  citeElement: Nullable<HTMLElement> = null;

  selectCitationText = (silent: boolean = false) => {
    const selection = typeof window.getSelection === 'function' ? window.getSelection() : false;
    if (selection && this.citeElement) {
      selection.selectAllChildren(this.citeElement);
      if (!silent && typeof this.props.onTextSelected === 'function') {
        this.props.onTextSelected();
      }
    }
  };

  _onClickKeyDownProps = mkOnClickKeyDown({
    onClick: () => this.selectCitationText(false),
  });

  setCiteElement = (element: Nullable<HTMLElement>) => {
    this.citeElement = element;
  };

  focus = () => {
    if (this.citeElement) {
      this.citeElement.focus();
    }
  };

  render() {
    return (
      <cite
        className={`formatted-citation formatted-citation--style-${this.props.citationStyle.toLowerCase()}`}
        ref={this.setCiteElement}
        tabIndex={this.props.tabIndex}
        {...this._onClickKeyDownProps}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: this.props.citation }}
      />
    );
  }
}
