import React from 'react';

export default function IconCirclePlus(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 20 20">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-100.000000, -20.000000)" fill="#237aaa">
          <path d="M108.812489,28.8333333 L107.6482,28.8333333 C106.997315,28.8333333 106.466793,29.3631913 106.466793,30.0168043 C106.466793,30.6749741 106.995727,31.2002752 107.6482,31.2002752 L108.812489,31.2002752 L108.812489,32.3645647 C108.812489,33.0154488 109.342347,33.545971 109.99596,33.545971 C110.65413,33.545971 111.179431,33.0170374 111.179431,32.3645647 L111.179431,31.2002752 L112.34372,31.2002752 C112.994605,31.2002752 113.525127,30.6704172 113.525127,30.0168043 C113.525127,29.3586344 112.996193,28.8333333 112.34372,28.8333333 L111.179431,28.8333333 L111.179431,27.6690438 C111.179431,27.0181597 110.649573,26.4876375 109.99596,26.4876375 C109.33779,26.4876375 108.812489,27.0165711 108.812489,27.6690438 L108.812489,28.8333333 Z M110,37 C106.134007,37 103,33.8659932 103,30 C103,26.1340068 106.134007,23 110,23 C113.865993,23 117,26.1340068 117,30 C117,33.8659932 113.865993,37 110,37 Z" />
        </g>
      </g>
    </symbol>
  );
}
