import * as log from '../../shared/log';
import LogLevel from '../../shared/LogLevel';

import qs from 'qs';

const logger = log.getLogger('s2-client');
const logLevel = (() => {
  if (typeof window !== 'undefined') {
    const query = qs.parse(window.location.search);
    if (query.debug == 'true') {
      return LogLevel.DEBUG;
    }
  }
  if (process.env.NODE_ENV !== 'production') {
    return LogLevel.DEBUG;
  }
  return LogLevel.ERROR;
})();
logger.setLevel(logLevel);

export default logger;
