import { useCiteSeeCounts } from './citesee-utils';

import { useAppContext, useStateFromStore } from '@/AppContext';
import Experiment from '@/weblab/Experiment';
import Feature from '@/weblab/Feature';

export type ReaderWidgetAvailability = {
  isAcronymsAvailable: boolean;
  isCiteSeeAvailable: boolean;
  isNoteTakingAvailable: boolean;
  isSkimmingV2Available: boolean;
  isTermUnderstandingAvailable: boolean;
};

export type ReaderWidgetMetrics = {
  isAvailable?: boolean;
  hasData?: boolean;
};

export type ReaderWidgetStateMetrics = {
  acronyms: ReaderWidgetMetrics;
  citeSee: ReaderWidgetMetrics;
  noteTaking: ReaderWidgetMetrics;
  skimmingV2: ReaderWidgetMetrics;
};

export type ReaderLinkStylesExperimentVariations = {
  isControlVariation: boolean;
  isNoUnderlineVariation: boolean;
  isSolidUnderlineVariation: boolean;
};

export function useReaderWidgetAvailability(): ReaderWidgetAvailability {
  const { weblabStore } = useAppContext();
  const {
    isAcronymsFlagEnabled,
    isNoteTakingFlagEnabled,
    isTermUnderstandingFlagEnabled,
    isTermUnderstandingExperimentEnabled,
  } = useStateFromStore(weblabStore, _ => {
    return {
      isAcronymsFlagEnabled: _.isFeatureEnabled(Feature.ReaderAcronyms),
      isNoteTakingFlagEnabled: _.isFeatureEnabled(Feature.PdfNoteTaking),
      isTermUnderstandingFlagEnabled: _.isFeatureEnabled(Feature.TermUnderstanding),
      isTermUnderstandingExperimentEnabled: _.isVariationEnabled(
        Experiment.TermUnderstanding.KEY,
        Experiment.TermUnderstanding.Variation.ENABLE_TERM_UNDERSTANDING
      ),
    };
  });

  return {
    isAcronymsAvailable: isAcronymsFlagEnabled && isTermUnderstandingExperimentEnabled,
    isCiteSeeAvailable: true,
    isNoteTakingAvailable: isNoteTakingFlagEnabled,
    isSkimmingV2Available: true,
    isTermUnderstandingAvailable:
      isTermUnderstandingFlagEnabled && isTermUnderstandingExperimentEnabled,
  };
}

export function useReaderWidgetStateMetrics(): ReaderWidgetStateMetrics {
  const { readerAcronymsStore, readerSkimmingStore } = useAppContext();
  const hasAcronymsData = readerAcronymsStore.hasAcronyms();
  const hasSkimmingSnippets = readerSkimmingStore.hasSkimmingSnippets();
  const { isAcronymsAvailable, isCiteSeeAvailable, isNoteTakingAvailable, isSkimmingV2Available } =
    useReaderWidgetAvailability();

  const { savedToLibraryCount, citedByLibraryCount } = useCiteSeeCounts();

  return {
    acronyms: {
      isAvailable: isAcronymsAvailable,
      hasData: hasAcronymsData,
    },
    citeSee: {
      isAvailable: isCiteSeeAvailable,
      hasData: savedToLibraryCount + citedByLibraryCount > 0,
    },
    noteTaking: {
      isAvailable: isNoteTakingAvailable,
    },
    skimmingV2: {
      isAvailable: isSkimmingV2Available,
      hasData: hasSkimmingSnippets,
    },
  };
}

export function useReaderLinkStylesExperimentVariations(): ReaderLinkStylesExperimentVariations {
  const { weblabStore } = useAppContext();
  const linkStyleVariation = useStateFromStore(weblabStore, _ =>
    _.getVariation(Experiment.ReaderLinkStylingKey.KEY)
  );

  const isControlVariation =
    linkStyleVariation === Experiment.ReaderLinkStylingKey.Variation.CONTROL;
  const isNoUnderlineVariation =
    linkStyleVariation === Experiment.ReaderLinkStylingKey.Variation.NO_UNDERLINE;
  const isSolidUnderlineVariation =
    linkStyleVariation === Experiment.ReaderLinkStylingKey.Variation.SOLID_UNDERLINE;

  return {
    isControlVariation,
    isNoUnderlineVariation,
    isSolidUnderlineVariation,
  };
}
