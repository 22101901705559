import LayoverClient, { Entry, FlushResult } from './LayoverClient';

import { Nullable } from '@/utils/types';

import type AppContext from '@/AppContext';

export const DEFAULT_MAX_ENTRY_AGE_MS = 5000; // 5 seconds
export const DEFAULT_MAX_ENTRIES_BEFORE_FLUSH = 20; // Number of entries to buffer before forcing a flush

// Turns client into no-ops, so no entries are collected or sent
export default class LayoverNoopClient implements LayoverClient {
  /* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
  associateWithAppContext(appContext: AppContext): void {}
  send(entry: Entry): boolean {
    return false;
  }
  sendNow(entry: Entry): boolean {
    return false;
  }
  getUserId(): Nullable<number> {
    return null;
  }
  getBrowserId(): Nullable<string> {
    return null;
  }
  flush(): Promise<FlushResult> {
    return Promise.resolve({ sentToServer: false });
  }
  /* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */
}
