export function getFacebookShareUrl(currentUrl: string): string {
  return `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`;
}

export function getTwitterShareUrl(currentUrl: string, paperTitle: string): string {
  return `https://twitter.com/intent/tweet?url=${currentUrl}&text=${paperTitle}`;
}

export function getDirectShareUrlWithCorpusId(corpusId: string | number): string {
  return `https://api.semanticscholar.org/CorpusID:${corpusId}`;
}
