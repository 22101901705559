import { KEY_CODE_ENTER, KEY_CODE_SPACE, KEY_CODE_TAB, KeyCodeValue } from '@/constants/KeyCode';

import type { KeyboardEvent, MouseEvent, RefObject, SyntheticEvent } from 'react';

export type OnClickKeyDownInputProps<TEventTarget> = {
  onClick: (event: SyntheticEvent<TEventTarget>) => void;
  extraKeys?: KeyCodeValue[];
  overrideKeys?: KeyCodeValue[];
  focusTrappedRef?: RefObject<Element>;
};

export type OnClickKeyDownOutput<TEventTarget> = {
  onClick: (event: MouseEvent<TEventTarget>) => void;
  onKeyDown: (event: KeyboardEvent<TEventTarget>) => void;
};

const DEFAULT_KEY_CODES = [KEY_CODE_ENTER, KEY_CODE_SPACE] as const;
Object.freeze(DEFAULT_KEY_CODES); // TODO: (#32585) Determine what to do with Object.freeze() on constants

export const FOCUSABLE_ELEMENTS =
  'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]';

/**
 * Adds `onClick` with an associated `onKeyPress` to an element to cover interactivity and keyboard
   accessibility. By default, the click handler is associated with the ENTER and SPACE keys.
   However, you can use the `extraKeys` parameter to specify key codes used in addition to the
   defaults that would fire the onClick when pressed OR the parameter `overrideKeys` which will only
   use the keys passed in.

   To use, cache the parameters into a class prop, for example:

   class MyComponent extends React.PureComponent {
     ...
     onCloseModal = (event) => {
       ...
     };

     _onClickProps = mkOnClickKeyDown<HTMLElement>({
       onClick: this.onCloseModal,
       overrideKeys: [KeyCode.ESC]
     });

     render() {
       return (
         <div {...this._onClickProps} />
       );
     }
   }

   Output: <div onClick={this.onCloseModal} onKeyDown={onKeyDownCloseModal} /> where
           onKeyDownCloseModal is a method that wraps this.onCloseModal with key commands
           for ESC

   Make sure that the onClick is autobound to the class it's declared in by using the autobinding
   syntax on the method [i.e. () => {}]
 *
 **/
export function mkOnClickKeyDown<TEventTarget extends EventTarget>({
  onClick,
  extraKeys,
  overrideKeys,
  focusTrappedRef,
}: OnClickKeyDownInputProps<TEventTarget>): OnClickKeyDownOutput<TEventTarget> {
  function onClickElement(event: SyntheticEvent<TEventTarget>): void {
    onClick.call(this, event);
  }

  // Need to convert to number in case event.keyCode isn't an included value
  const keyCodes: number[] = resolveKeyCodes({ extraKeys, overrideKeys });

  const onKeyDown = (event: KeyboardEvent<TEventTarget>): void => {
    if (keyCodes.includes(event.keyCode)) {
      onClickElement(event as SyntheticEvent<TEventTarget>);
    }

    if (focusTrappedRef) {
      trapFocusForKeyboardNavigation(event, focusTrappedRef);
    }
  };

  return {
    onClick: onClickElement,
    onKeyDown: onKeyDown,
  };
}

// Takes in the additional parameters to decide which key codes are included along with the
// defaults in the onKeyPress
function resolveKeyCodes({
  extraKeys,
  overrideKeys,
}: {
  extraKeys?: KeyCodeValue[];
  overrideKeys?: KeyCodeValue[];
}): KeyCodeValue[] {
  if (overrideKeys) {
    return overrideKeys;
  }

  // Need to make a copy of defaults before possibly adding extra keys
  const keyCodes: KeyCodeValue[] = DEFAULT_KEY_CODES.slice();
  if (extraKeys) {
    keyCodes.push(...extraKeys);
  }

  return keyCodes;
}

export function focusOnFocusableElement({
  focusTrappedRef,
  shouldFocusOnContent,
}: {
  focusTrappedRef: RefObject<Element> | Element;
  shouldFocusOnContent?: boolean;
}): void {
  if (!focusTrappedRef) {
    return;
  }

  const index: number = shouldFocusOnContent ? 1 : 0;
  const modalElm = 'current' in focusTrappedRef ? focusTrappedRef.current : focusTrappedRef;

  const firstFocusableElement = modalElm
    ?.querySelectorAll<HTMLElement>(FOCUSABLE_ELEMENTS)
    .item(index);
  if (firstFocusableElement) {
    firstFocusableElement.focus();
  }
}

export function trapFocusForKeyboardNavigation<TEventTarget extends EventTarget>(
  event: KeyboardEvent<TEventTarget>,
  focusTrappedRef: RefObject<Element>
): void {
  const isTabPressed = event.keyCode === KEY_CODE_TAB;

  if (!isTabPressed || !focusTrappedRef.current) {
    return;
  }

  const focusableContent: NodeListOf<HTMLElement> =
    focusTrappedRef.current.querySelectorAll<HTMLElement>(FOCUSABLE_ELEMENTS);
  const firstFocusableElement: HTMLElement = focusableContent.item(0);
  const lastFocusableElement: HTMLElement = focusableContent.item(focusableContent.length - 1);

  if (event.shiftKey) {
    if (document.activeElement === firstFocusableElement) {
      lastFocusableElement.focus();
      event.preventDefault();
    }
  } else {
    if (document.activeElement === lastFocusableElement) {
      firstFocusableElement.focus();
      event.preventDefault();
    }
  }
}

// Screen readers will skip reading out images that are only decorative if they
// have an empty alt tag. If an image is missing the alt attribute a screen
// reader would be read the filename, which is less than useful.
// More info here: https://www.w3.org/WAI/tutorials/images/decorative/
export const DECORATIVE_ALT_TEXT = '';

// Screenreader users expect new content to be after the expand button so to limit confusion
// we focus on the first new content and if it is unable to focus on the new content we default to focusing
// on the expand/collapse button

// listRef - list of elements that includes toggle button
// newContentRef - ref for new content that you want to focus on
// defaultButtonClass - class of the toggle button
//  expandedTargetClass - class of the new content you want to focus on

export function focusOnNewContent({
  collapsed,
  listRef,
  newContentRef,
  defaultButtonClass,
  expandedTargetClass,
}: {
  collapsed: boolean;
  listRef: RefObject<Element>;
  newContentRef: RefObject<Element>;
  defaultButtonClass: string;
  expandedTargetClass: string;
}): void {
  const toggleButton = listRef?.current?.querySelector<HTMLElement>('.' + defaultButtonClass);

  if (collapsed) {
    toggleButton?.focus();
  } else {
    const authorLink = newContentRef?.current?.querySelector<HTMLElement>(
      '.' + expandedTargetClass
    );
    authorLink ? authorLink.focus() : toggleButton?.focus();
  }
}
