import { getString } from '@/content/i18n';
import { heapAbstractToggle } from '@/analytics/attributes/paperDetailPage';
import { Nullable, ReactNodeish } from '@/utils/types';
import { truncateText } from '@/util';
import CLButton, { TYPE } from '@/components/library/button/CLButton';

import classnames from 'classnames';
import React from 'react';

export const DEFAULT_LIMIT = 360;

type Props = {
  className?: Nullable<string>;
  limit: number;
  text?: Nullable<string>;
  html?: Nullable<string>;
  truncateByDefault: boolean;
  onClick?: (isTruncated: boolean) => void;
  extendActionText: string;
  extendAriaLabel: string;
  truncateActionText: string;
  truncateAriaLabel: string;
};

type State = {
  isTruncated: boolean;
};

/**
 * Component responsible for rendering long blocks of text and truncating them to a given limit,
 * while including a control which allows the user to toggle between showing the full text and the
 * truncated version.
 */
export default class TextTruncator extends React.PureComponent<Props, State> {
  static defaultProps = {
    limit: DEFAULT_LIMIT,
    truncateByDefault: true,
    extendActionText: getString(_ => _.textTruncator.default.extendLabel),
    extendAriaLabel: getString(_ => _.textTruncator.default.extendAriaLabel),
    truncateActionText: getString(_ => _.textTruncator.default.truncateLabel),
    truncateAriaLabel: getString(_ => _.textTruncator.default.truncateAriaLabel),
  };

  constructor(...args: [any]) {
    super(...args);

    this.state = {
      isTruncated: !!this.props.truncateByDefault,
    };
  }

  toggleTextTruncation = () => {
    this.setState(
      prevState => {
        return {
          isTruncated: !prevState.isTruncated,
        };
      },
      () => {
        if (this.props.onClick) {
          this.props.onClick(this.state.isTruncated);
        }
      }
    );
  };

  renderHtml(): ReactNodeish {
    return (
      <span dangerouslySetInnerHTML={{ __html: this.props.html || '' }} /> // eslint-disable-line react/no-danger
    );
  }

  renderToggle(): ReactNodeish {
    const { isTruncated } = this.state;
    const toggleClasses = classnames(
      'text-truncator__toggle',
      'more-toggle',
      'mod-clickable',
      isTruncated ? 'more' : 'less'
    );
    const toggleText = isTruncated ? this.props.extendActionText : this.props.truncateActionText;
    const ariaLabel = isTruncated ? this.props.extendAriaLabel : this.props.truncateAriaLabel;

    return (
      <CLButton
        aria-label={ariaLabel}
        label={toggleText}
        className={toggleClasses}
        data-test-id="text-truncator-toggle"
        onClick={this.toggleTextTruncation}
        tabIndex={0}
        type={TYPE.TERTIARY}
        {...heapAbstractToggle()}
      />
    );
  }

  render(): ReactNodeish {
    const { className, limit, text, html } = this.props;
    if (!text) {
      return null;
    }

    const truncatedText = text ? truncateText(text, limit) : '';
    const showToggle = truncatedText !== text;
    const isTruncated = showToggle && this.state.isTruncated;

    return (
      <div className={classnames('text-truncator', className)}>
        <span data-test-id="text-truncator-text">
          {isTruncated ? `${truncatedText}\xa0` : html ? this.renderHtml() : `${text}\xa0`}
        </span>
        {showToggle ? this.renderToggle() : null}
      </div>
    );
  }
}
