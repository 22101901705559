import BaseStore from './BaseStore';

import { getVenueRecordFromJS, VenueRecord, VenueRecordFactory } from '@/models/venue/Venue';
import { Nullable } from '@/utils/types';
import Constants from '@/constants';
import S2Dispatcher from '@/utils/S2Dispatcher';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import type { ApiResponse, GetVenueResponseBody } from '@/api/ApiResponse';

export const parseVenueName = (venueName: string) => {
  return venueName.toLowerCase().replace(/\W+/g, '-');
};

export default class VenueStore extends BaseStore {
  #venueRecord: Nullable<VenueRecord>;
  #state: StoreStateValue;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#venueRecord = VenueRecordFactory();
    this.#state = StoreState.UNINITIALIZED;

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const startingPayload = payload as ApiResponse;
          const requestType = startingPayload.requestType;
          // we listen to SEARCH_PAPERS_BY_VENUE because we will
          // be receiving our normalized venue object along with our paper
          // results from the server side. Else,
          // we will have to issue two separate API requests from the
          // browser, which could negatively impact the venue page's performance.
          if (
            requestType === Constants.requestTypes.GET_VENUE_BY_NAME ||
            requestType === Constants.requestTypes.SEARCH_PAPERS_BY_VENUE
          ) {
            return this.#handleGetVenueStart();
          }
          break;
        }
        case Constants.actions.API_REQUEST_COMPLETE: {
          const completePayload = payload as ApiResponse;
          const requestType = completePayload.requestType;
          // same reason as above.
          if (
            requestType === Constants.requestTypes.GET_VENUE_BY_NAME ||
            requestType === Constants.requestTypes.SEARCH_PAPERS_BY_VENUE
          ) {
            return this.#handleVenuePayload(completePayload);
          }
          break;
        }
      }
    });
  }

  isUninitialized(): boolean {
    return this.#state === StoreState.UNINITIALIZED;
  }

  isLoading(): boolean {
    return this.isUninitialized() || this.#state === StoreState.LOADING;
  }

  #handleGetVenueStart(): void {
    this.#state = StoreState.LOADING;
    this.emitChange();
  }

  #handleVenuePayload(completePayload: ApiResponse<GetVenueResponseBody>): void {
    const venueResponse = completePayload.resultData.venue;
    this.#venueRecord = getVenueRecordFromJS(venueResponse);
    this.#state = StoreState.LOADED;
    this.emitChange();
  }

  getVenueRecord(): Nullable<VenueRecord> {
    return this.#venueRecord;
  }
}
