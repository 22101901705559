import { getCitation } from '@/utils/citation-util';
import { getString } from '@/content/i18n';
import { heapCitationCopy, heapCitationTabStyleLink } from '@/analytics/attributes/paperObject';
import { mapHooksToProps } from '@/utils/react-utils';
import { ModalId } from '@/constants/Modal';
import { PersistentUserSettingKey } from '@/models/user/UserSetting';
import { trackCitationCopyClick } from '@/components/shared/paper/PaperActionTracking';
import { useAppContext } from '@/AppContext';
import { useUserSettingUtils } from '@/utils/user-setting-utils';
import CitationStyle, { AllCitationStyles } from '@/constants/citation-style';
import CLIconButton, { TYPE } from '@/components/library/button/CLIconButton';
import CLModal from '@/components/library/modal/CLModal';
import DownloadCitationLink from '@/components/desktop/common/paper/DownloadCitationLink';
import EnvInfo from '@/env/EnvInfo';
import Feature from '@/weblab/Feature';
import FormattedCitation from '@/components/shared/paper/FormattedCitation';
import Icon from '@/components/shared/icon/Icon';
import Link from '@/router/Link';
import S2History from '@/utils/S2History';
import ScreenReaderAnnouncement, {
  Politeness,
} from '@/components/util/a11y/ScreenReaderAnnouncement';
import Tab from '@/components/desktop/common/layout/tabs/Tab';
import Tabs from '@/components/desktop/common/layout/tabs/Tabs';

import PropTypes from 'prop-types';
import React from 'react';

// defines which citation styles are available as file downloads vs being displayed
// in plain text for the user to copy.
const DOWNLOAD_ONLY_CITATION_STYLES = [CitationStyle.ENDNOTE];
const DOWNLOAD_CITATION_STYLES = [CitationStyle.BIBTEX, ...DOWNLOAD_ONLY_CITATION_STYLES];
const TEXT_CITATION_STYLES = AllCitationStyles.filterNot(style =>
  DOWNLOAD_ONLY_CITATION_STYLES.includes(style)
);

const ALL_LIBRARY_ROUTES = ['LIBRARY_ALL_ENTRIES', 'LIBRARY_FOLDER', 'LIBRARY_UNSORTED_ENTRIES'];

export class CiteModal extends React.PureComponent {
  static contextTypes = {
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    history: PropTypes.instanceOf(S2History).isRequired,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      citationStyle: this.props.defaultCitationStyle,
      isCopied: false,
    };
  }

  focusAndSelectCitationText() {
    const citationComponent = this.citationComponent;
    if (citationComponent) {
      citationComponent.focus();
      citationComponent.selectCitationText();
    }
  }

  onCopyClicked = () => {
    const { data: paper } = this.props;
    const { citationStyle } = this.state;
    const citationComponent = this.citationComponent;
    if (citationComponent) {
      citationComponent.focus();
      citationComponent.selectCitationText();
      if (document.execCommand('copy')) {
        this.setState({ isCopied: true });
      }
    }

    trackCitationCopyClick(paper.id, citationStyle);
  };

  setActiveTab(tab) {
    const { citationStyle } = this.state;
    const { isCitationStyleFeatureEnabled, setPersistentUserSetting, authStore } = this.props;

    if (citationStyle !== tab) {
      this.setState(
        {
          citationStyle: tab,
          isCopied: false,
        },
        this.focusAndSelectCitationText
      );
      if (isCitationStyleFeatureEnabled && authStore.hasAuthenticatedUser()) {
        const citationSetting = { citationStyle: tab };
        setPersistentUserSetting(PersistentUserSettingKey.citationStyle, citationSetting);
      }
    }
  }

  render() {
    const { data: paper } = this.props;
    const { citationStyle, isCopied } = this.state;
    const tabStyles = TEXT_CITATION_STYLES;
    const {
      history,
      envInfo: { isMobile },
    } = this.context;
    const currentRoute = history.getLocationRouteName();
    const tabs = tabStyles.map((style, i) => {
      return (
        <Tab key={i} isActive={citationStyle === style} className="cite-modal__tab">
          <button
            className="cite-modal__button"
            onClick={this.setActiveTab.bind(this, style)}
            {...heapCitationTabStyleLink({ 'citation-style': citationStyle })}>
            {getString(_ => _.modal.export.citationStyleLabel[style])}
          </button>
        </Tab>
      );
    });

    return (
      <CLModal className="cite-modal" modalId={ModalId.CITE}>
        <div className="cite-modal__container">
          <div className="cite-modal__header">
            <h2 className="cite-modal__header-text">{getString(_ => _.modal.cite.modalTitle)}</h2>
            <Tabs classes="cite-modal__tabs">{tabs}</Tabs>
          </div>
          <FormattedCitation
            ref={component => (this.citationComponent = component)}
            citation={getCitation(paper, citationStyle)}
            citationStyle={citationStyle}
          />
          <div className="cite-modal__toolbar">
            <CLIconButton
              type={TYPE.SECONDARY}
              icon={<Icon icon="copy" height="12" width="10" />}
              label={getString(_ =>
                isCopied ? _.modal.cite.copyButtonTextCopied : _.modal.cite.copyButtonText
              )}
              {...heapCitationCopy({ 'citation-style': citationStyle })}
              className="cite-modal__copy-button"
              onClick={this.onCopyClicked}
            />
            {isCopied && (
              <ScreenReaderAnnouncement
                politeness={Politeness.ASSERTIVE}
                message={getString(_ => _.modal.cite.copiedCitationAnnouncement)}
              />
            )}
          </div>
        </div>
        <div className="cite-modal__footer">
          <h4 className="cite-modal__footer-label">{getString(_ => _.modal.cite.export)}</h4>
          <div className="cite-modal__footer-links">
            {DOWNLOAD_CITATION_STYLES.map(citationStyle => (
              <DownloadCitationLink
                papers={[paper]}
                citationStyle={citationStyle}
                key={citationStyle}
              />
            ))}
          </div>
        </div>
        {!isMobile && !ALL_LIBRARY_ROUTES.includes(currentRoute) && (
          <React.Fragment>
            <div className="cite-modal__gap" />
            <div className="cite-modal__export-banner">
              <div className="cite-modal__export-banner__info">
                <div className="cite-modal__export-banner__header">
                  <Icon
                    icon="fa-bookmark"
                    width="15"
                    height="15"
                    className="cite-modal__export-banner__icon"
                  />
                  <h6 className="cite-modal__export-banner__text">
                    {getString(_ => _.export.bulkExportHeaderText)}
                  </h6>
                </div>
                <p className="cite-modal__export-banner__content">
                  {getString(_ => _.export.bulkExportDescriptionText)}
                  <Link to="LIBRARY_ALL_ENTRIES" className="cite-modal__export-banner__link-button">
                    {getString(_ => _.export.exportLinkLabel)}
                    <Icon
                      icon="arrow-lite"
                      width="15"
                      height="10"
                      className="cite-modal__export-banner__arrow"
                    />
                  </Link>
                </p>
              </div>

              <div className="cite-modal__export-banner__logo">
                <div className="cite-modal__export-banner__logo-container">
                  <div className="cite-modal__export-banner__background-circle" />
                </div>
                <div className="cite-modal-export-banner__wrapper">
                  <Icon icon="export-paper" width="200" height="70" />
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </CLModal>
    );
  }
}

export default mapHooksToProps(CiteModal, props => {
  const { setPersistentUserSetting } = useUserSettingUtils();
  const { userSettingStore, weblabStore, authStore } = useAppContext();
  const isCitationStyleFeatureEnabled = weblabStore.isFeatureEnabled(
    Feature.CitationStylePreference
  );
  let defaultCitationStyle = TEXT_CITATION_STYLES.first();
  if (isCitationStyleFeatureEnabled && authStore.hasAuthenticatedUser()) {
    const citationStyleSettingRecord = userSettingStore.getUserSetting(
      PersistentUserSettingKey.citationStyle
    );
    defaultCitationStyle =
      citationStyleSettingRecord?.settingsValue?.citationStyle || defaultCitationStyle;
  }
  return {
    ...props,
    setPersistentUserSetting,
    defaultCitationStyle,
    isCitationStyleFeatureEnabled,
    authStore,
  };
});
