import { getString } from '@/content/i18n';
import CLTextButton from '@/components/library/button/CLTextButton';
import Experiment from '@/weblab/Experiment';
import Feature from '@/weblab/Feature';
import S2History from '@/utils/S2History';
import WeblabStore, { OVERRIDE_RESET_VALUE } from '@/weblab/WeblabStore';

import classnames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';

/**
 * OverridePane, shows what experiments/features are set, creates links to override or reset
 */
export default class OverridePane extends React.PureComponent {
  static contextTypes = {
    history: PropTypes.instanceOf(S2History).isRequired,
    weblabStore: PropTypes.instanceOf(WeblabStore).isRequired,
  };

  constructor(...args) {
    super(...args);

    const weblabState = this.getStateFromWeblabStore();
    this.state = {
      overrideLink: getOverrideLink(
        this.context.history.location,
        weblabState.experimentStates,
        weblabState.featureStates
      ),
      ...weblabState,
    };

    this.context.weblabStore.registerComponent(this, () => {
      const newState = this.getStateFromWeblabStore();
      const overrideLink = getOverrideLink(
        this.context.history.location,
        newState.experimentStates,
        newState.featureStates
      );
      this.setState({
        ...newState,
        overrideLink,
      });
    });
  }

  getStateFromWeblabStore() {
    const { weblabStore } = this.context;
    const forcedVariations = weblabStore.getForcedVariations__UNSAFE();
    const experimentStates = Object.keys(Experiment).reduce(
      (map, key) =>
        map.set(Experiment[key].KEY, {
          isExposed: !!weblabStore.getExposedVariation__UNSAFE(Experiment[key].KEY),
          currentVariation: weblabStore.getVariation(Experiment[key].KEY),
        }),
      Immutable.Map()
    );
    const featureStates = Object.keys(Feature).reduce(
      (map, key) => map.set(Feature[key].KEY, weblabStore.isFeatureEnabled(Feature[key])),
      Immutable.Map()
    );
    return {
      experimentStates,
      featureStates,
      forcedVariations,
    };
  }

  onClickClose = () => {
    const { onClose } = this.props;
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  onClickExperiment = event => {
    const { name: experimentKey, value } = event.currentTarget;
    const { weblabStore, history } = this.context;
    const { experimentStates: experimentStatesOld, featureStates } = this.state;
    const experimentStates = experimentStatesOld.set(experimentKey, {
      ...experimentStatesOld.get(experimentKey),
      currentVariation: value,
    });

    // Update the forcedVariations in the weblab store so the changes can been seen immediately
    const updatedForcedVariations = experimentStates.map(
      experimentState => experimentState.currentVariation
    );
    weblabStore.setForcedVariations__UNSAFE(updatedForcedVariations);

    this.setState({
      experimentStates,
      overrideLink: getOverrideLink(history.location, experimentStates, featureStates),
    });
  };

  onClickFeature = event => {
    const { name } = event.currentTarget;
    const { weblabStore, history } = this.context;
    const { experimentStates, featureStates: featureStatesOld } = this.state;
    const featureStates = featureStatesOld.set(name, !featureStatesOld.get(name));

    // Update the forcedFeatures in the weblab store so the changes can been seen immediately
    weblabStore.setForcedFeatures__UNSAFE(featureStates);

    this.setState({
      featureStates,
      overrideLink: getOverrideLink(history.location, experimentStates, featureStates),
    });
  };

  render() {
    const { overrideLink, experimentStates, featureStates, forcedVariations } = this.state;
    const resetLink = getResetLink(this.context.history.location);

    const visibleExperiments = Object.keys(Experiment)
      .map(key => Experiment[key])
      .filter(experiment => !experiment.hiddenFromOverrides);

    const hasForcedVariations = forcedVariations && !forcedVariations.isEmpty();

    return (
      <div className="override-pane">
        <div className="override-pane__header">
          <div className="override-pane__header-cell">
            <a
              href={overrideLink}
              title="Saves these overrides in a cookie for 1 hour before resetting">
              Overrides Link
            </a>
          </div>
          <div className="override-pane__header-cell">
            <a
              href={resetLink}
              title="Clears the overrides cookie, putting back the state of experiments and feature flags before the overrides">
              Reset Overrides
            </a>
          </div>
          <div className="override-pane__header-cell">
            <CLTextButton
              className="override-pane__close-btn"
              label={getString(_ => _.overridePanel.closeButtonLabel)}
              onClick={this.onClickClose}
              title="Close this panel (changes apply immediately)"
            />
          </div>
        </div>

        <div className="override-pane__description">
          This panel shows all the Experiments and Feature Flags that are currently in the code.
          Changing them will override the state just for you while the page is loaded. To override
          the state for an hour, you can click "Overrides Link" above (or you can copy the link and
          send it to someone else). When you are done, the "Reset Overrides" link will clear all the
          overrides and put you back to where you were before anything was overridden.
        </div>

        <div className="override-pane__group">
          <h3 className="override-pane__group-header">
            Experiments (* default)
            {hasForcedVariations ? (
              <span className="override-pane-forced"> -- Overrides active</span>
            ) : null}
          </h3>
          <table className={classnames({ 'override-pane-forced': hasForcedVariations })}>
            <tbody>
              {visibleExperiments.map(experiment => (
                <tr key={experiment.KEY}>
                  <td>
                    <span
                      className={classnames('override-pane__label', {
                        'override-pane__experiment-is_exposed': experimentStates.get(experiment.KEY)
                          .isExposed,
                      })}
                      title={
                        experimentStates.get(experiment.KEY).isExposed
                          ? 'Experiment is currently exposed'
                          : undefined
                      }>
                      {experiment.KEY}
                    </span>
                  </td>
                  <td>
                    <select
                      className="legacy__select"
                      name={experiment.KEY}
                      value={experimentStates.get(experiment.KEY).currentVariation}
                      onChange={this.onClickExperiment}>
                      {getExperimentVariationValues(experiment).map(variation => (
                        <option key={`${experiment.KEY}__${variation}`} value={variation}>
                          {variation}
                          {variation === experiment.defaultVariation ? ' *' : null}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="override-pane__group">
          <h3 className="override-pane__group-header">Features</h3>
          <div className="override-pane__group-items">
            {Object.keys(Feature)
              .map(key => Feature[key])
              .map(feature => (
                <label key={feature.KEY} className="override-pane__group-item">
                  <input
                    className="legacy__input override-pane__checkbox"
                    type="checkbox"
                    name={feature.KEY}
                    checked={featureStates.get(feature.KEY)}
                    onChange={this.onClickFeature}
                  />
                  <span className="override-pane__label">{feature.KEY}</span>
                </label>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

function getExperimentByKey(key) {
  for (const name of Object.keys(Experiment)) {
    if (Experiment[name].KEY === key) {
      return Experiment[name];
    }
  }
  return null;
}

function getExperimentVariationValues(experiment) {
  return [...Object.keys(experiment.Variation)].map(key => experiment.Variation[key]);
}

function getFeatureByKey(key) {
  for (const name of Object.keys(Feature)) {
    if (Feature[name].KEY === key) {
      return Feature[name];
    }
  }
  return null;
}

export function getOverrideLink(location, experimentStates, featureStates) {
  const queryObj = qs.parse(location.search.substr(1));

  // Only experiments that are not defaults
  queryObj.experiments = experimentStates
    .keySeq()
    .filter(key => {
      const experiment = getExperimentByKey(key);
      return (
        !experiment || experiment.defaultVariation != experimentStates.get(key).currentVariation
      );
    })
    .map(key => `${key}:${experimentStates.get(key).currentVariation}`)
    .join(',');

  // Only features that are not defaults
  queryObj.features = featureStates
    .keySeq()
    .filter(key => {
      const feature = getFeatureByKey(key);
      return !feature || feature.fallbackState !== featureStates.get(key);
    })
    .map(key => `${key}:${JSON.stringify(featureStates.get(key))}`)
    .join(',');

  return location.pathname + '?' + qs.stringify(queryObj);
}

export function getResetLink(location) {
  const queryObj = qs.parse(location.search.substr(1));
  queryObj.experiments = OVERRIDE_RESET_VALUE;
  queryObj.features = OVERRIDE_RESET_VALUE;
  return location.pathname + '?' + qs.stringify(queryObj);
}
