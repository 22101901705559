import ScreenReaderAnnouncement, {
  Politeness,
} from '@/components/util/a11y/ScreenReaderAnnouncement';

import classnames from 'classnames';
import React from 'react';

const TOP = 'top';
const LEFT = 'left';
const RIGHT = 'right';
const BOTTOM = 'bottom';

export const ARROW_SIDE = {
  TOP,
  LEFT,
  RIGHT,
  BOTTOM,
};

export default function CLFieldErrorBase({
  arrow,
  children,
  className,
  isInline,
  widthPx,
  ...otherProps
}) {
  const style = {};
  if (typeof widthPx === 'number') {
    style.width = `${widthPx}px`;
  }

  return (
    <div
      {...otherProps}
      className={classnames(
        'cl-field-error',
        `cl-field-error--arrow-${arrow}`,
        isInline ? 'cl-field-error--is-inline' : 'cl-field-error--is-floating',
        className
      )}
      style={style}>
      <div className="cl-field-error__content">
        {children}
        <ScreenReaderAnnouncement politeness={Politeness.ASSERTIVE} message={children} />
      </div>
    </div>
  );
}

CLFieldErrorBase.defaultProps = {
  arrow: TOP,
};
