import { fetchPaperRedirectByCorpusId } from '@/actions/PaperDetailActionCreators';
import { getLayoverLogger } from '@/utils/layover/LayoverLogger';
import { Nullable } from '@/utils/types';
import AppContext from '@/AppContext';
import LoadingPage from '@/components/shared/loading/LoadingPage';
import S2Redirect from '@/models/redirects/S2Redirect';

import React, { PureComponent } from 'react';

export function redirectToPaperDetailAbstract({
  id,
  slug,
  query,
}: {
  id: string;
  slug: string;
  query: any;
}) {
  return new S2Redirect({
    query,
    routeName: 'PAPER_DETAIL',
    params: { slug, paperId: id },
    replace: true,
  });
}

/**
 * Component which redirects to the paper detail page, if a paper is found with the provided id.
 */
export default class PaperDetailByCorpusIdRedirect extends PureComponent {
  static async willRouteTo(appContext: AppContext, routerState: any): Promise<S2Redirect> {
    const { graphQLApi } = appContext;
    let paperId: Nullable<string> = null;
    let slug: Nullable<string> = null;
    try {
      const corpusId = Number.parseInt(routerState.params?.corpusId);
      const results = await fetchPaperRedirectByCorpusId({ corpusId }, { graphQLApi });
      const paper = results?.resultData.data?.paper;
      if (paper) {
        paperId = paper.id;
        slug = paper.slug;
      }
    } catch (error) {
      getLayoverLogger().logError('Error fetching paper redirect by corpus id', error);
    }

    if (paperId && slug) {
      return redirectToPaperDetailAbstract({
        id: paperId,
        slug,
        query: routerState.query,
      });
    }
    return new S2Redirect({ routeName: 'NOT_FOUND', status: 404, replace: true });
  }

  render() {
    return <LoadingPage isLoading />;
  }
}
