import { ApiRequestStartingPayload } from '@/api/BaseApi';
import { ApiResponse, AuthorProfileResponseBody } from '@/api/ApiResponse';
import { AuthorProfileRecord, getAuthorProfileFromJS } from '@/models/author/AuthorProfile';
import { Nullable } from '@/utils/types';
import BaseStore from '@/stores/BaseStore';
import Constants from '@/constants';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import type S2Dispatcher from '@/utils/S2Dispatcher';

export default class AuthorStore extends BaseStore {
  #state: StoreStateValue;
  #authorProfile: Nullable<AuthorProfileRecord>;
  #gravatarEmail: Nullable<string>;
  dispatchToken: string;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#state = StoreState.UNINITIALIZED;
    this.#authorProfile = null;
    this.#gravatarEmail = null;

    this.dispatchToken = dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const apiStartingPayload = payload as ApiRequestStartingPayload;
          switch (apiStartingPayload.requestType) {
            case Constants.requestTypes.AUTHOR_PROFILE_FETCH: {
              return this.handleAuthorProfileFetchStarting();
            }
          }
          break;
        }

        case Constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.AUTHOR_PROFILE_FETCH: {
              return this.handleAuthorProfileFetchComplete(apiResponse);
            }
            case Constants.requestTypes.AUTHOR_PROFILE_UPDATE: {
              return this.handleAuthorProfileUpdateComplete(apiResponse);
            }
            case Constants.requestTypes.LOGOUT: {
              return this.handleLogout();
            }
          }
          break;
        }
      }
    });
  }

  handleAuthorProfileFetchStarting(): void {
    this.#state = StoreState.LOADING;
    this.#authorProfile = null;
    this.#gravatarEmail = null;
    this.emitChange();
  }

  handleAuthorProfileFetchComplete(payload: ApiResponse<AuthorProfileResponseBody>): void {
    const rawAuthorProfile = payload.resultData?.authorProfile;
    if (rawAuthorProfile) {
      this.#authorProfile = getAuthorProfileFromJS(rawAuthorProfile);
    }
    this.#gravatarEmail = payload.resultData?.gravatarEmail || null;
    this.#state = StoreState.LOADED;
    this.emitChange();
  }

  handleAuthorProfileUpdateComplete(payload: ApiResponse<AuthorProfileResponseBody>): void {
    const rawAuthorProfile = payload.resultData?.authorProfile;
    if (rawAuthorProfile) {
      this.#authorProfile = getAuthorProfileFromJS(rawAuthorProfile);
    }
    this.emitChange();
  }

  handleLogout(): void {
    this.#state = StoreState.UNINITIALIZED;
    this.#authorProfile = null;
    this.#gravatarEmail = null;
    this.emitChange();
  }

  isUninitialized(): boolean {
    return this.#state === StoreState.UNINITIALIZED;
  }

  isLoading(): boolean {
    return this.isUninitialized() || this.#state === StoreState.LOADING;
  }

  getAuthorProfile(): Nullable<AuthorProfileRecord> {
    return this.#authorProfile || null;
  }

  getGravatarEmail(): Nullable<string> {
    return this.#gravatarEmail || null;
  }
}
