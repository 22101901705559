import { addHeapProps } from '@/components/util/HeapTracking';

import classnames from 'classnames';
import React from 'react';

import type { HeapPropObject } from '@/analytics/Heap';

type Props = React.PropsWithChildren<{
  selected: boolean;
  onClick: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  className?: string;
  testId?: string;
  ariaLabel?: string;
  ariaDescribedBy?: string;
  heapProps?: HeapPropObject;
  id?: string;
}>;

const Toggle = props => {
  const {
    selected,
    onClick,
    onKeyDown,
    disabled,
    testId,
    ariaLabel,
    ariaDescribedBy,
    className,
    heapProps,
    id,
    children,
  }: Props = props;
  const disabledVal = typeof disabled == 'boolean' && disabled;
  const labelId = children && id ? `${id}__label` : undefined;

  return (
    <label className={classnames('toggle-wrapper', className)}>
      <label
        className="toggle-slider flex-static"
        data-test-id={testId}
        {...(heapProps ? addHeapProps(heapProps) : { 'data-test': 'testing' })}>
        <input
          className="legacy__input"
          type="checkbox"
          checked={selected}
          onChange={onClick}
          onKeyDown={onKeyDown}
          disabled={disabledVal}
          id={id}
          aria-checked={selected}
          aria-label={ariaLabel}
          aria-labelledby={!ariaLabel ? labelId : ''}
          aria-describedby={ariaDescribedBy}
        />
        <span className="toggle-slider__bg" />
      </label>
      {children && (
        <div id={labelId} className="toggle-label">
          {children}
        </div>
      )}
    </label>
  );
};

export default Toggle;
