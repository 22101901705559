import CLModalContainer from './CLModalContainer';

import { hideModal } from '@/actions/ModalActionCreators';
import CLOverlay from '@/components/library/layout/CLOverlay';
import S2Dispatcher from '@/utils/S2Dispatcher';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default class CLModal extends React.Component {
  static contextTypes = {
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
  };

  // setting the onHideClick prop will prevent the modal dispatch close from triggering
  hideModal = () => {
    if (typeof this.props.onHideClick === 'function') {
      this.props.onHideClick();
    } else {
      this.context.dispatcher.dispatch(hideModal());
    }
  };

  componentWillUnmount() {
    if (typeof this.props.onHidden === 'function') {
      this.props.onHidden();
    }
  }

  render() {
    const { className, children, modalId, ariaProps } = this.props;
    return (
      <CLOverlay onClick={this.hideModal} data-modal-id={modalId}>
        <CLModalContainer
          ariaProps={ariaProps}
          onClickClose={this.hideModal}
          className={classnames('cl-modal__centered-offset', className)}>
          {children}
        </CLModalContainer>
      </CLOverlay>
    );
  }
}
