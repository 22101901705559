import { DECORATIVE_ALT_TEXT, mkOnClickKeyDown } from '@/utils/a11y-utils';
import { FigureRecord } from '@/models/Figure';
import { getString } from '@/content/i18n';
import { heapPaperTitle } from '@/analytics/attributes/paperObject';
import { Nullable, ReactNodeish } from '@/utils/types';
import { PaperRecord } from '@/models/Paper';
import { S2LogoMarkOnly } from '@/components/shared/s2Logos';
import Arrow from '@/components/shared/icon/Arrow';
import CLPaperAuthors from '@/components/library/paper/CLPaperAuthors';
import Link from '@/router/Link';
import TextTruncator from '@/components/shared/common/TextTruncator';

import classnames from 'classnames';
import React from 'react';

const stopPropagation = e => e.stopPropagation();
const FIGURE_CAPTION_CHAR_LIMIT = 220;

type Props = {
  figure: FigureRecord;
  paper: PaperRecord;
};

type State = {
  isFooterSticky: boolean;
  showExpandIcon: boolean;
};

export default class FigureWithStickyFooter extends React.PureComponent<Props, State> {
  figureModalRef;

  state = {
    isFooterSticky: true,
    showExpandIcon: true,
  };

  componentDidMount(): void {
    this.updateFooterExpand();
    window.addEventListener('resize', this.updateFooterExpand);
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    if (prevState.showExpandIcon === this.state.showExpandIcon) {
      // Update was not the footer expanding (prevents recursive loop, see #18575)
      this.updateFooterExpand();
    }
  }

  componentWillUnmount(): void {
    this.figureModalRef = null;
    window.removeEventListener('resize', this.updateFooterExpand);
  }

  updateFooterExpand = (): void => {
    if (!this.figureModalRef) {
      return;
    }
    const isOffScreen = this.figureModalRef.scrollHeight > this.figureModalRef.clientHeight;

    this.setState({ showExpandIcon: isOffScreen });
  };

  toggleFooterState = (): void => {
    this.setState(({ isFooterSticky }) => ({ isFooterSticky: !isFooterSticky }));
  };

  _onClickKeyDownStopProps = mkOnClickKeyDown<HTMLElement>({
    onClick: stopPropagation,
  });

  setFigureModalRef = (ref: Nullable<HTMLElement>): void => {
    this.figureModalRef = ref;
  };

  render(): ReactNodeish {
    const { figure, paper } = this.props;
    const { isFooterSticky } = this.state;
    const ariaProps = {
      'aria-hidden': 'true',
    };

    return (
      <div
        className="modal__with-footer"
        data-test-id="figure-modal"
        {...this._onClickKeyDownStopProps}
        ref={this.setFigureModalRef}>
        <div className="figure-content">
          <img
            src={figure.cdnUri}
            alt={DECORATIVE_ALT_TEXT}
            data-test-id="figure-image"
            onLoad={this.updateFooterExpand}
          />
        </div>
        <div
          className={classnames({
            'figure-footer': true,
            'figure-footer__sticky': this.state.isFooterSticky,
          })}>
          <div className="figure-caption">
            <TextTruncator
              text={figure.caption}
              limit={FIGURE_CAPTION_CHAR_LIMIT}
              truncateByDefault={true}
              truncateAriaLabel={getString(_ => _.figureDetail.truncateAriaLabel)}
              extendAriaLabel={getString(_ => _.figureDetail.expandAriaLabel)}
              className="figure-caption__text"
            />
            {this.state.showExpandIcon && (
              <Arrow
                ariaProps={ariaProps}
                onClick={this.toggleFooterState}
                direction={isFooterSticky ? Arrow.Direction.DOWN : Arrow.Direction.UP}
              />
            )}
          </div>
          <div className="figure-meta">
            <div className="figure-meta-left">
              <p className="figure-meta-text" data-test-id="figure-meta-text">
                {getString(
                  _ => _.figureDetail.published.bothYearAndVenue,
                  paper.venue.text,
                  paper.year.text
                )}
              </p>
              <Link
                to="PAPER_DETAIL"
                params={{ paperId: paper.id, slug: paper.slug }}
                className="figure_paper-title"
                data-test-id="figure_paper-title"
                {...heapPaperTitle()}>
                {paper.title.text}
              </Link>
              <p className="figure-authors">
                <CLPaperAuthors paper={paper} onWhiteBackground />
              </p>
            </div>
            <div className="s2-logo">
              <S2LogoMarkOnly />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
