import BaseStore from './BaseStore';

import { ApiResponse } from '@/api/ApiResponse';
import { ExperimentsStoreModelRecord } from '@/models/experiment/ExperimentsStoreModel';
import constants from '@/constants';
import S2Dispatcher from '@/utils/S2Dispatcher';
import WeblabConfig from '@/weblab/WeblabConfig';

export default class ExperimentsStore extends BaseStore {
  _experimentStates: Map<string, ExperimentsStoreModelRecord>;
  dispatchToken: string;
  weblabConfig: WeblabConfig;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this._experimentStates = new Map();
    this.weblabConfig = this.getWeblabConfig();

    this.dispatchToken = dispatcher.register(payload => {
      switch (payload.actionType) {
        // Parse them sample queries when the associated API request is completed
        case constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          if (apiResponse.requestType === constants.requestTypes.GET_EXPERIMENTS) {
            this._experimentStates = apiResponse.resultData.states;
            this.emitChange();
          }
          break;
        }
      }
    });
  }

  getWeblabConfig(): WeblabConfig {
    return new WeblabConfig();
  }

  getExperimentStates(): Map<string, ExperimentsStoreModelRecord> {
    return this._experimentStates;
  }
}
