import { getString } from '@/content/i18n';
import { isValidEmail } from '@/utils/form-validation';
import { ReactNodeish } from '@/utils/types';
import Api from '@/api/Api';
import CLButton, { TYPE } from '@/components/library/button/CLButton';
import CLTextInput from '@/components/library/form/input/CLTextInput';
import EventTarget from '@/analytics/constants/EventTarget';
import MessageStore from '@/stores/MessageStore';
import PageSection from '@/components/shared/layout/PageSection';
import SubmitEvent from '@/analytics/models/SubmitEvent';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';
import WeblabStore from '@/weblab/WeblabStore';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

type Props = {
  theme: string;
};

type State = {
  email: string;
  isSubscribing: boolean;
  isSubscribed: boolean;
};

export default class SubscribeForm extends React.PureComponent<Props, State> {
  static defaultProps = {
    theme: 'light',
  };

  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    messageStore: PropTypes.instanceOf(MessageStore).isRequired,
    weblabStore: PropTypes.instanceOf(WeblabStore).isRequired,
  };

  constructor(...args: [any]) {
    super(...args);

    this.state = {
      isSubscribing: false,
      isSubscribed: false,
      email: '',
    };
  }

  onClickSubscribe: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    this.subscribe();
  };

  async subscribe(): Promise<void> {
    const email = this.state.email.trim();
    if (isValidEmail(email)) {
      this.setState({ isSubscribing: true });
      trackAnalyticsEvent(SubmitEvent.create(EventTarget.SUBSCRIBE_SUBMIT, { email }));

      try {
        await this.context.api.subscribe(email);
        this.setState({
          isSubscribed: true,
          isSubscribing: false,
        });
      } catch {
        this.context.messageStore.addError(getString(_ => _.error['general-error']));
        this.setState({ isSubscribing: false });
      }
    } else {
      this.context.messageStore.addError(getString(_ => _.error['bad-email-address']));
    }
  }

  setEmailValue = (email: string): void => {
    this.setState({ email });
  };

  render(): ReactNodeish {
    const { theme } = this.props;
    const { email } = this.state;
    const cssClassName = classNames('subscribe-tile tile padded', {
      light: theme === 'light',
      'is-subscribing': this.state.isSubscribing,
    });

    if (this.state.isSubscribed) {
      return (
        <PageSection className={cssClassName}>
          <div className="container">
            <h2 className="subscribe-tile__success">{getString(_ => _.home.subscribe.thankYou)}</h2>
            <p>{getString(_ => _.home.subscribe.thankYouBlurb)}</p>
          </div>
        </PageSection>
      );
    }

    const subscribeForm: ReactNodeish = (
      <div className="subscribe-form">
        <CLTextInput
          placeholder={getString(_ => _.home.subscribe.placeholder)}
          value={email}
          className="subscribe-form__input"
          onChange={e => this.setEmailValue(e.target.value)}
        />
        <CLButton
          type={TYPE.PRIMARY}
          onClick={this.onClickSubscribe}
          className="subscribe-form__submit-button"
          label={
            this.state.isSubscribing
              ? getString(_ => _.home.subscribe.subscribing)
              : getString(_ => _.home.subscribe.signUp)
          }
        />
      </div>
    );

    return (
      <PageSection className={cssClassName}>
        <div
          className="container flex-row-vcenter subscribe-tile__container"
          aria-label={getString(_ => _.home.subscribe.ariaTitle)}>
          <div className="subscribe-tile__blurb">
            {getString(_ => _.home.subscribe.subscribeBlurb)}
          </div>
          {subscribeForm}
        </div>
      </PageSection>
    );
  }
}
