import React from 'react';

export default function IconEnvelope(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 204 62">
      <style type="text/css">
        {`
          .st0{fill:#F5F6F7;}
          .st1{fill:#E3CCE3;}
          .st2{fill:#4E276F;}
        `}
      </style>
      <path
        className="st0"
        d="M202,38c-5.5,0-8.3-3.6-10.7-6.8c-2.4-3.2-4.1-5.2-7.5-5.2c-3.4,0-5.1,2-7.5,5.2c-2.4,3.2-5.1,6.8-10.7,6.8
          c-5.5,0-8.3-3.6-10.7-6.8c-2.4-3.2-4.1-5.2-7.5-5.2s-5,2-7.5,5.2c-2.4,3.2-5.1,6.8-10.7,6.8c-5.5,0-8.3-3.6-10.7-6.8
          c-2.4-3.2-4.1-5.2-7.5-5.2c-3.4,0-5,2-7.5,5.2c-2.4,3.2-5.1,6.8-10.7,6.8s-8.3-3.6-10.7-6.8C79.8,28,78.1,26,74.7,26s-5,2-7.5,5.2
          c-2.4,3.2-5.1,6.8-10.7,6.8s-8.3-3.6-10.7-6.8C43.4,28,41.7,26,38.4,26s-5.1,2-7.5,5.2c-2.4,3.2-5.1,6.8-10.7,6.8s-8.3-3.6-10.7-6.8
          C7.1,28,5.4,26,2,26c-1.1,0-2-0.9-2-2s0.9-2,2-2c5.5,0,8.3,3.6,10.7,6.8c2.4,3.2,4.1,5.2,7.5,5.2s5-2,7.5-5.2
          c2.4-3.2,5.1-6.8,10.7-6.8s8.3,3.6,10.7,6.8c2.4,3.2,4.1,5.2,7.5,5.2s5.1-2,7.5-5.2c2.4-3.2,5.1-6.8,10.7-6.8s8.3,3.6,10.7,6.8
          c2.4,3.2,4.1,5.2,7.5,5.2s5-2,7.5-5.2c2.4-3.2,5.1-6.8,10.7-6.8s8.3,3.6,10.7,6.8c2.4,3.2,4.1,5.2,7.5,5.2c3.4,0,5-2,7.5-5.2
          c2.4-3.2,5.1-6.8,10.7-6.8s8.3,3.6,10.7,6.8c2.4,3.2,4.1,5.2,7.5,5.2c3.4,0,5-2,7.5-5.2c2.4-3.2,5.1-6.8,10.7-6.8
          c5.5,0,8.3,3.6,10.7,6.8c2.4,3.2,4.1,5.2,7.5,5.2c1.1,0,2,0.9,2,2S203.1,38,202,38z"
      />
      <path
        className="st1"
        d="M66,11h84c1.1,0,2,0.9,2,2v47c0,1.1-0.9,2-2,2H66c-1.1,0-2-0.9-2-2V13C64,11.9,64.9,11,66,11z"
      />
      <path
        className="st2"
        d="M143.5,5h-84c-1.1,0-2,0.9-2,2v47c0,1.1,0.9,2,2,2h84c1.1,0,2-0.9,2-2V7C145.5,5.9,144.6,5,143.5,5z M133.7,8.5
          L101,28.3l-32-19.8H133.7z M142,53H61V8.2L101,33l41-24.9V53z"
      />
    </symbol>
  );
}
