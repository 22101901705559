import { isFunction } from '@/utils/types';

import type { FunctionComponent } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

export default (function FeatureEnabled({ children }) {
  if (isFunction(children)) {
    return children();
  }
  return children;
} as FunctionComponent<Props>);
