import { MessageRecord } from '@/models/Message';
import { Nullable, ReactNodeish } from '@/utils/types';
import Constants from '@/constants';

export const ERROR = 'error';
export const PAGE_ERROR = 'page-error';
export const WARNING = 'warning';
export const SUCCESS = 'success';

type MessageActionCreatorProps = {
  text: Nullable<ReactNodeish> | string;
  title?: Nullable<ReactNodeish>;
  extraContent?: Nullable<ReactNodeish>;
};

type MessageType = typeof PAGE_ERROR | typeof ERROR | typeof WARNING | typeof SUCCESS;

export type ShowMessagePayload = {
  actionType: typeof Constants.actions.SHOW_MESSAGE;
  type: MessageType;
  text: Nullable<ReactNodeish> | string;
  title: Nullable<ReactNodeish>;
  extraContent?: Nullable<ReactNodeish>;
};

export type HideMessagePayload = {
  actionType: typeof Constants.actions.HIDE_MESSAGE;
  message: MessageRecord;
};

export function hideMessage(message: MessageRecord): HideMessagePayload {
  return {
    actionType: Constants.actions.HIDE_MESSAGE,
    message,
  };
}

export function showPageErrorMessage({
  text,
  title = null,
  extraContent,
}: MessageActionCreatorProps): ShowMessagePayload {
  return {
    actionType: Constants.actions.SHOW_MESSAGE,
    type: PAGE_ERROR,
    text,
    title,
    extraContent,
  };
}

export function showErrorMessage({
  text,
  title = null,
  extraContent,
}: MessageActionCreatorProps): ShowMessagePayload {
  return {
    actionType: Constants.actions.SHOW_MESSAGE,
    type: ERROR,
    text,
    title,
    extraContent,
  };
}

export function showWarningMessage({
  text,
  title = null,
  extraContent,
}: MessageActionCreatorProps): ShowMessagePayload {
  return {
    actionType: Constants.actions.SHOW_MESSAGE,
    type: WARNING,
    text,
    title,
    extraContent,
  };
}

export function showSuccessMessage({
  text,
  title = null,
  extraContent,
}: MessageActionCreatorProps): ShowMessagePayload {
  return {
    actionType: Constants.actions.SHOW_MESSAGE,
    type: SUCCESS,
    text,
    title,
    extraContent,
  };
}
