import { DEPRECATED__FlowOptional, Nullable } from '@/utils/types';

import Immutable from 'immutable';

// NOTE: This should be kept in sync with LibraryFolderOwner.scala
export type LibraryFolderOwnerFromJS = {
  claimedAuthorId?: DEPRECATED__FlowOptional<number>;
  fullName?: DEPRECATED__FlowOptional<string>;
};

type Props = {
  claimedAuthorId: Nullable<number>;
  fullName: Nullable<string>;
};

export const defaultProps: Props = {
  claimedAuthorId: null,
  fullName: null,
};

export const RECORD_NAME = 'LibraryFolderOwner';

export const LibraryFolderOwnerRecordFactory = Immutable.Record(defaultProps, RECORD_NAME);
export type LibraryFolderOwnerRecord = Immutable.RecordOf<Props>;

export function getLibraryFolderOwnerFromJS(
  args: LibraryFolderOwnerFromJS
): LibraryFolderOwnerRecord {
  return LibraryFolderOwnerRecordFactory({
    ...args,
  });
}
