import ApiRequest, { HttpMethod } from './ApiRequest';
import reloadIfUIOutdated from './reloadIfUIOutdated';

import * as util from '@/util';
import { ApiErrorRecord, getApiErrorFromJS } from '@/models/ApiError';
import { isBrowser } from '@/utils/env';
import Constants, { RequestTypeValue } from '@/constants';

import { generatePath } from 'react-router';
import idx from 'idx';

import type { ApiResponse } from './ApiResponse';
import type { DEPRECATED__FlowOptional, Nullable, TODO } from '@/utils/types';
import type S2Dispatcher from '@/utils/S2Dispatcher';
// eslint-disable-next-line no-duplicate-imports
import type { DispatchPayload } from '@/utils/S2Dispatcher';

type TODO__RequestId = TODO<'Bug: Current returns a function rather than a value'>;

export type ApiRequestStartingPayload<TRequestData = any> = DispatchPayload<{
  actionType: typeof Constants.actions.API_REQUEST_STARTING;
  requestType: RequestTypeValue;
  requestData: TRequestData;
  path: string;
  pathParams: PathParams;
  requestId: TODO__RequestId;
  context: any;
}>;

export type ApiRequestFailurePayload = DispatchPayload<{
  actionType: typeof Constants.actions.API_REQUEST_FAILED;
  requestType: RequestTypeValue;
  status: number;
  errorBody: string;
  errorCode: Nullable<string>;
  path: string;
  requestId: TODO__RequestId;
  pathParams: PathParams;
  context: any;
}>;

export type ApiPayload =
  | ApiRequestStartingPayload<any>
  | ApiRequestFailurePayload
  | ApiResponse<any>;

// copied in from @types/react-router, because it is not exported. Thanks <3
type PathParams = { [paramName: string]: string | number | boolean | undefined };

const API_REQUEST_ACTION_TYPES = [
  Constants.actions.API_REQUEST_STARTING,
  Constants.actions.API_REQUEST_FAILED,
  Constants.actions.API_REQUEST_COMPLETE,
] as const;

export default class BaseApi {
  static _clientRequest: DEPRECATED__FlowOptional<any> = null;
  static _isDebugEnabled: boolean = false;

  _isPushSafetyCheckEnabled: boolean = true;
  _dispatcher: S2Dispatcher;
  _isDebugEnabled: boolean;
  _extraHeaders: DEPRECATED__FlowOptional<{ [header: string]: any }>;

  getClientRequest(): DEPRECATED__FlowOptional<any> {
    // @ts-expect-error -- Weird prototypal stuff
    return this.constructor._clientRequest;
  }

  static setClientRequest(request: DEPRECATED__FlowOptional<any>): void {
    this._clientRequest = request;
  }

  static setDebugEnabled(isEnabled: boolean): void {
    this._isDebugEnabled = isEnabled;
  }

  isDebugEnabled(): boolean {
    // @ts-expect-error -- Weird prototypal stuff
    return this.constructor._isDebugEnabled;
  }

  constructor(dispatcher: S2Dispatcher) {
    // TODO(codeviking): The API really shouln't dispatch anything.  It should just be a pure mechanism
    // for sending and receiving requests.  The willRouteTo handlers should individually decide how to
    // handle success / failure (by, for instance, more explicitly populating a store of their choice)
    // This will however require some rework!  This might be of merit for tech-debt week...
    this._dispatcher = dispatcher;

    // If we're not in the browser, we're making a request on behalf of a client on the node
    // renderer. In this case we:
    //  * Set the `X-Is-Node-Renderer` flag so it's easy to identify this traffic in our logs.
    //  * Set the `User-Agent` to that of the client.
    //  * Set the `X-Forwarded-For` header to that of the actual client.
    //  * Set client request cookies
    const clientRequest = this.getClientRequest();
    if (!isBrowser() && clientRequest) {
      this._extraHeaders = {
        'X-Is-Node-Renderer': true,
        'User-Agent': clientRequest.headers['user-agent'],
        // nginx sets the X-Real-IP header to the value of X-Forwarded-For. Since requests
        // to the API get routed back through nginx, we need set this (instead of `X-Real-IP`) --
        // otherwise it'll get overwritten.
        'X-Forwarded-For': clientRequest.headers['x-real-ip'],
        'X-Amzn-Trace-Id': clientRequest.headers['x-amzn-trace-id'],
        cookie: clientRequest.headers.cookie,
      };
    }
  }

  /**
   * Wrap an API call to disable reloading of a page if the server is running a different
   * version of the code than the browser. Useful for forms and places where a reload would
   * cause a bad experience.
   *
   * Example:
   *
   * const response = await api.disablePushSafetyCheck(_ => _.submitForm(formValues));
   */
  disablePushSafetyCheck<T>(callback: (api: this) => T): T {
    this._isPushSafetyCheckEnabled = false;
    try {
      return callback(this);
    } finally {
      // Make sure to reset the flag, regardless of whether the callback throws and error
      this._isPushSafetyCheckEnabled = true;
    }
  }

  /**
   *
   * @param {*} requestType
   * @param {*} method GET, POST, etc..
   * @param {*} path request path, optionally templated with placeholders to fill fom `pathParams`
   * @param {*} data data for request (query string for GET, request body for POST/PUT etc..)
   * @param {*} pathParams optional object mapping keys to values in the path template
   * @param {*} context optional object included in dispatch payloads, but not sent to server
   */
  // eslint-disable-next-line max-params
  apiRequest<T>({
    requestType,
    method,
    path,
    pathParams,
    data,
    context = {},
  }: {
    requestType: RequestTypeValue;
    method: HttpMethod;
    path: string;
    data?: Nullable<any>;
    pathParams?: Nullable<PathParams>;
    context?: Nullable<any>;
  }): ApiRequest<T> {
    // This needs to be stored in a local variable because the flag is reset when
    // .disablePushSafetyCheck() returns (which is before the response comes back)
    const shouldReloadIfUIOutdatedForRequest = this._isPushSafetyCheckEnabled;

    const requestId = util.generateRequestId();
    const finalPath =
      pathParams && Object.keys(pathParams).length > 0 ? generatePath(path, pathParams) : path;
    const apiRequestStartingPayload: ApiRequestStartingPayload<any> = {
      actionType: Constants.actions.API_REQUEST_STARTING,
      requestType: requestType,
      requestData: data,
      path: finalPath,
      requestId,
      pathParams: pathParams || {},
      context,
    };
    this._dispatcher.dispatch(apiRequestStartingPayload);

    const apiRequest = this._buildApiRequest<T>({
      method,
      path: finalPath,
      data,
      debug: this.isDebugEnabled(),
      extraHeaders: this._extraHeaders,
    });

    // TODO(codeviking): The API request handling code here is quite complex. We should think
    // about cleaning this up in the long-run
    return apiRequest.send(
      res => {
        if (shouldReloadIfUIOutdatedForRequest) {
          reloadIfUIOutdated(res);
        }

        const resultPayload: ApiResponse<T> = {
          actionType: Constants.actions.API_REQUEST_COMPLETE,
          requestType: requestType,
          resultData: res.body,
          path: finalPath,
          requestId,
          pathParams: pathParams || {},
          responseStatus: idx(res, _ => _.status),
          context,
        };
        this._dispatcher.dispatch(resultPayload);
        return resultPayload;
      },
      (err: any, res: any) => {
        if (res) {
          // 500-level may be caused by different versions between server and browser
          const is500LevelError = /^5/.test(err.status);
          if (is500LevelError || shouldReloadIfUIOutdatedForRequest) {
            reloadIfUIOutdated(res);
          }
        }

        const error = this._buildApiError(err, res, requestType, path);
        const apiRequestFailurePayload: ApiRequestFailurePayload = {
          actionType: Constants.actions.API_REQUEST_FAILED,
          requestType: requestType,
          status: error.status,
          errorBody: error.error,
          errorCode: error.errorCode || null,
          path: finalPath,
          requestId,
          pathParams: pathParams || {},
          context,
        };
        this._dispatcher.dispatch(apiRequestFailurePayload);

        return error;
      }
    );
  }

  _buildApiRequest<T>(args: {
    method: HttpMethod;
    path: string;
    data?: DEPRECATED__FlowOptional<unknown>;
    debug?: DEPRECATED__FlowOptional<boolean>;
    extraHeaders?: DEPRECATED__FlowOptional<unknown>;
  }): ApiRequest<T> {
    return new ApiRequest({
      ...args,
    });
  }

  _buildApiError(err: any, res: any, requestType: RequestTypeValue, path: string): ApiErrorRecord {
    if (err.status) {
      return getApiErrorFromJS({
        path,
        requestType,
        errorType:
          err.status === 503
            ? Constants.errorTypes.SERVER_UNAVAILABLE
            : Constants.errorTypes.SERVER_ERROR,
        status: err.status,
        error: res.body ? res.body.error : res.error,
        errorCode: res.body ? res.body.code : null,
        errorSubCode: res.body ? res.body.subCode : null,
        extraInfo: res.body ? res.body.extraInfo : undefined,
      });
    } else {
      return getApiErrorFromJS({
        path,
        requestType,
        errorType:
          err && err.timeout !== undefined
            ? Constants.errorTypes.API_TIMEOUT
            : Constants.errorTypes.NETWORK_ERROR,
        error: err,
      });
    }
  }

  _cancelRequest(request: any) {
    if (request && typeof request.cancel === 'function') {
      request.cancel();
    }
  }
}

export function isApiPayload(payload: DispatchPayload<any>): payload is ApiPayload {
  return API_REQUEST_ACTION_TYPES.includes(payload.actionType);
}
