import AccountMenuDropdown from './AccountMenuDropdown';

import { AuthorProfileRecord } from '@/models/author/AuthorProfile';
import { getRouteTheme } from '@/utils/theme-utils';
import { getString } from '@/content/i18n';
import { heapSignInModalButton, heapSignUpModalButton } from '@/analytics/attributes/signin';
import { LOGIN_LOCATION } from '@/constants/LoginMethods';
import { mapAppContextToProps, useStateFromStore } from '@/AppContext';
import { ModalId } from '@/constants/Modal';
import { Nullable, ReactNodeish } from '@/utils/types';
import { showModal } from '@/actions/ModalActionCreators';
import { UserRecord } from '@/models/user/User';
import Api from '@/api/Api';
import AuthStore from '@/stores/AuthStore';
import CLButton, { TYPE } from '@/components/library/button/CLButton';
import CLDropdown from '@/components/library/dropdown/CLDropdown';
import CLDropdownButton from '@/components/library/button/CLDropdownButton';
import CLIconButton from '@/components/library/button/CLIconButton';
import FeatureDisabled from '@/components/util/features/FeatureDisabled';
import FeatureEnabled from '@/components/util/features/FeatureEnabled';
import FeatureGate from '@/components/util/features/FeatureGate';
import Icon from '@/components/shared/icon/Icon';
import IsDesktop from '@/components/util/env/IsDesktop';
import IsMobile from '@/components/util/env/IsMobile';
import ProfilePicture from '@/components/shared/user/ProfilePicture';
import S2Dispatcher from '@/utils/S2Dispatcher';
import S2History from '@/utils/S2History';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// TODO  #29139 - Refactor to use App Context instead of isResearchFTUE prop
type Props = {
  isResearchFTUE?: Nullable<boolean>;
  isReader?: Nullable<boolean>;
} & PropsFromAppContext;

type PropsFromAppContext = {
  user: Nullable<UserRecord>;
  authorProfile: Nullable<AuthorProfileRecord>;
};
class AccountMenu extends React.PureComponent<Props, {}> {
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    authStore: PropTypes.instanceOf(AuthStore).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    history: PropTypes.instanceOf(S2History).isRequired,
  };

  declare context: {
    api: Api;
    authStore: AuthStore;
    dispatcher: S2Dispatcher;
    history: S2History;
  };

  onClickSignIn = (): void => {
    this.showLoginModal();
  };

  onClickSignUp = (): void => {
    this.showSignUpModal();
  };

  showLoginModal = (): void => {
    this.context.dispatcher.dispatch(
      showModal({ id: ModalId.LOGIN, location: LOGIN_LOCATION.navBar })
    );
  };

  showSignUpModal = (): void => {
    this.context.dispatcher.dispatch(
      showModal({ id: ModalId.SIGNUP, location: LOGIN_LOCATION.navBar })
    );
  };

  openMobileMenu = (): void => {
    const { dispatcher } = this.context;
    dispatcher.dispatch(showModal({ id: ModalId.MOBILE_NAV }));
  };

  _mobileMenuButtonAriaProps = {
    'aria-label': getString(_ => _.account.menu.accountMenuAriaLabel),
  };

  renderMobileSignedIn = (): ReactNodeish => {
    const { isResearchFTUE } = this.props;
    return (
      <CLIconButton
        className={classnames('mobile-nav__menu-button', {
          'mobile-nav__menu-button--research-ftue': isResearchFTUE,
        })}
        testId="mobile-nav__menu-button"
        icon={<Icon icon="hamburger" height="16" width="16" />}
        ariaProps={this._mobileMenuButtonAriaProps}
        onClick={this.openMobileMenu}
      />
    );
  };

  renderDesktopSignedIn = (): ReactNodeish => {
    const { isResearchFTUE, authorProfile, user } = this.props;
    const { history } = this.context;
    // Order of precedence for the button text:
    // 1. Claimed author profile name
    // 2. Supplied user name
    // 3. Default ("Account")
    const hasUserName = authorProfile?.fullName || user?.fullName;
    const buttonText =
      authorProfile?.fullName || user?.fullName || getString(_ => _.account.menu.accountLabel);
    const profilePicture = authorProfile?.profilePicture || user?.profilePicture;

    const activeRoute = history.getLocationRouteName();

    const routeTheme = getRouteTheme(activeRoute);

    const className: string = `account-menu__button__icon--profile-picture account-menu__button__icon--profile-picture--theme-${routeTheme.toLowerCase()}`;

    return (
      <div className="account-menu" id="account-menu">
        <CLDropdown contents={() => <AccountMenuDropdown />}>
          <CLDropdownButton
            data-test-id="account-menu-button"
            className={classnames('account-menu__button', {
              'account-menu__button--research-ftue': isResearchFTUE,
              notranslate: hasUserName, // so google translate doesn't auto translate user's name
            })}
            icon={
              <FeatureGate feature={_ => _.AuthorProfilePicture}>
                <FeatureEnabled>
                  <ProfilePicture
                    altText={buttonText}
                    className={className}
                    profilePicture={profilePicture}
                    heightPx={18}
                    widthPx={18}
                    fallback={
                      <Icon
                        icon="user"
                        className="account-menu__button__icon"
                        height="12"
                        width="12"
                      />
                    }
                  />
                </FeatureEnabled>
                <FeatureDisabled>
                  <Icon icon="user" className="account-menu__button__icon" height="12" width="12" />
                </FeatureDisabled>
              </FeatureGate>
            }
            label={buttonText}
            ariaProps={{
              'aria-label': getString(_ => _.account.menu.accountMenuAriaLabel),
            }}
          />
        </CLDropdown>
      </div>
    );
  };

  renderPlatformSignedIn = (): ReactNodeish => {
    return (
      <React.Fragment>
        <IsMobile>{() => this.renderMobileSignedIn()}</IsMobile>
        <IsDesktop>{() => this.renderDesktopSignedIn()}</IsDesktop>
      </React.Fragment>
    );
  };

  renderSignedOut = (): ReactNodeish => {
    const { isReader } = this.props;
    return (
      <div className="account-menu__signed-out account-menu__show-sign-up">
        <CLButton
          className={`button ${
            isReader ? 'button--secondary' : 'button--tertiary'
          } account-menu__sign-in-button`}
          type={isReader ? TYPE.SECONDARY : TYPE.TERTIARY}
          aria-label={getString(_ => _.account.menu.signInLabel)}
          label={getString(_ => _.account.menu.signInLabel)}
          data-test-id="reading-list-sign-in"
          {...heapSignInModalButton()}
          onClick={this.onClickSignIn}
        />
        <CLButton
          className={`button ${
            isReader ? 'button--primary' : 'button--secondary'
          } account-menu__sign-up-button`}
          type={isReader ? TYPE.PRIMARY : TYPE.SECONDARY}
          aria-label={getString(_ => _.account.menu.signUpLabel)}
          label={getString(_ => _.account.menu.signUpLabel)}
          data-test-id="reading-list-sign-in"
          {...heapSignUpModalButton()}
          onClick={this.onClickSignUp}
        />
      </div>
    );
  };

  render(): ReactNodeish {
    const { user } = this.props;
    return (
      <React.Fragment>
        <nav className="account-menu" id="account-menu">
          {user ? this.renderPlatformSignedIn() : this.renderSignedOut()}
        </nav>
        <iframe
          id="cognito-signout-iframe"
          className="cognito-signout__iframe"
          title="Intentionally blank"
          aria-hidden="true"
          tabIndex={-1}
        />
      </React.Fragment>
    );
  }
}

export default mapAppContextToProps<Props, PropsFromAppContext>(AccountMenu, appContext => {
  const authStoreState = useStateFromStore(appContext.authStore, _ => ({
    user: _.getUser(),
  }));
  const authorProfileStoreState = useStateFromStore(appContext.authorProfileStore, _ => ({
    authorProfile: _.getAuthorProfile(),
  }));

  return {
    ...authStoreState,
    ...authorProfileStoreState,
  };
});
