import React from 'react';

export default function IconAuthor(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 21 24">
      <path
        d="M14.7,14.2c1.1,0,2.2,0.3,3.1,0.8s1.7,1.3,2.3,2.3s0.8,2,0.8,3.1v1.2c0,0.6-0.2,1.2-0.7,1.6s-1,0.7-1.6,0.7H2.2
		c-0.6,0-1.2-0.2-1.6-0.7S0,22.4,0,21.8v-1.2c0-1.1,0.3-2.2,0.8-3.1s1.3-1.7,2.3-2.3s2-0.8,3.1-0.8c0.4,0,0.9,0.1,1.6,0.3
		c0.4,0.1,0.8,0.2,1.1,0.3c0.5,0.1,1,0.1,1.5,0.1s1.1,0,1.5-0.1c0.3-0.1,0.6-0.2,1.1-0.3C13.8,14.4,14.3,14.2,14.7,14.2z M18.8,21.8
		v-1.2c0-1.1-0.4-2-1.2-2.8s-1.7-1.2-2.8-1.2c-0.2,0-0.5,0.1-0.9,0.2c-1.1,0.4-2.2,0.6-3.3,0.6s-2.1-0.2-3.3-0.6
		c-0.5-0.1-0.8-0.2-0.9-0.2c-1.1,0-2,0.4-2.8,1.2s-1.2,1.7-1.2,2.8v1.2H18.8z M10.5,13.5c-1.2,0-2.3-0.3-3.4-0.9s-1.9-1.4-2.5-2.5
		S3.8,8,3.8,6.8s0.3-2.3,0.9-3.4s1.4-1.9,2.5-2.5S9.3,0,10.5,0s2.3,0.3,3.4,0.9s1.9,1.4,2.5,2.5s0.9,2.2,0.9,3.4s-0.3,2.3-0.9,3.4
		s-1.4,1.9-2.5,2.5S11.7,13.5,10.5,13.5z M10.5,2.2c-1.2,0-2.3,0.4-3.2,1.3S6,5.5,6,6.8s0.4,2.3,1.3,3.2s1.9,1.3,3.2,1.3
		s2.3-0.4,3.2-1.3S15,8,15,6.8s-0.4-2.3-1.3-3.2S11.8,2.2,10.5,2.2z"
      />
    </symbol>
  );
}
