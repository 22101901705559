import { RouteType } from './Route';

import { isBrowser } from '@/utils/env';
import { ReactNodeish } from '@/utils/types';
import LoadingPage from '@/components/shared/loading/LoadingPage';
import Redirect from '@/models/redirects/Redirect';

import React from 'react';

type Props = {
  route: RouteType;
};

export default class RouteRedirect extends React.Component<Props> {
  static willRouteTo() {
    if (isBrowser()) {
      const url = document.location.href || '';
      if (url.slice(-1) == '/') {
        // some pages won't hit nginx if there is a trailing forward slash
        document.location.replace(url.substring(0, url.length - 1));
      } else {
        // NOTE: This will cause the browser to hit nginx for the proxy to Webflow
        document.location.reload();
      }
    }
  }

  render(): ReactNodeish {
    return <LoadingPage isLoading />;
  }
}

export function mkRedirectTo(url: string) {
  // eslint-disable-next-line react/no-multi-comp
  return class UrlRedirect extends React.Component<{}> {
    static willRouteTo() {
      return new Redirect({ url });
    }
    render(): ReactNodeish {
      return <LoadingPage isLoading />;
    }
  };
}
