import React from 'react';

export default function IconStatusWaiting(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 40 32">
      <path
        d="M21.58,6.42c-0.15-0.15-0.33-0.23-0.55-0.23h-2.06c-0.22,0-0.4,0.08-0.55,0.23c-0.15,0.15-0.23,0.33-0.23,0.55
        v10.58c0,0.26,0.11,0.47,0.32,0.65l5.48,4c0.17,0.13,0.37,0.17,0.58,0.13c0.22-0.04,0.39-0.15,0.52-0.32l1.16-1.68
        c0.13-0.17,0.18-0.37,0.16-0.58c-0.02-0.22-0.12-0.39-0.29-0.52l-4.32-3.1V6.97C21.8,6.75,21.73,6.57,21.58,6.42z
        M9.94,16H7.09 c0-1.07,0.14-2.11,0.39-3.1c0.29-1.16,0.73-2.28,1.36-3.35C10,7.57,11.57,6,13.54,4.84C15.52,3.68,17.67,3.1,20,3.1
        s4.47,0.58,6.45,1.74c1.53,0.9,2.81,2.06,3.84,3.45c0.27,0.36,0.78,0.41,1.1,0.1l1.15-1.15c0.26-0.26,0.29-0.68,0.07-0.97
        C31.35,4.62,29.83,3.24,28,2.16C25.54,0.72,22.88,0,20,0s-5.55,0.72-8,2.16C9.54,3.6,7.6,5.55,6.16,8c-0.91,1.54-1.49,3.18-1.83,4.9
        C4.13,13.9,4,14.93,4,16H1.16c-0.7,0-1.05,0.85-0.56,1.34l4.39,4.39c0.15,0.15,0.35,0.23,0.56,0.23c0.2,0,0.4-0.08,0.56-0.23
        l4.39-4.39C10.99,16.85,10.64,16,9.94,16z M39.4,14.66l-4.39-4.39c-0.15-0.15-0.35-0.23-0.56-0.23c-0.2,0-0.4,0.08-0.56,0.23
        l-4.39,4.39c-0.5,0.5-0.14,1.34,0.56,1.34h2.84c0,1.07-0.14,2.11-0.39,3.1c-0.29,1.16-0.73,2.28-1.36,3.35
        C30,24.43,28.43,26,26.45,27.16c-1.98,1.16-4.13,1.74-6.45,1.74s-4.47-0.58-6.45-1.74c-1.53-0.9-2.81-2.06-3.84-3.45
        c-0.27-0.36-0.77-0.41-1.09-0.1l-1.16,1.16c-0.26,0.26-0.29,0.67-0.07,0.96c1.26,1.65,2.78,3.03,4.61,4.11
        c2.45,1.44,5.12,2.16,8,2.16s5.55-0.72,8-2.16c2.45-1.44,4.4-3.39,5.84-5.84c0.91-1.54,1.49-3.18,1.83-4.9
        C35.86,18.1,36,17.07,36,16h2.85C39.54,16,39.89,15.15,39.4,14.66z"
      />
    </symbol>
  );
}
