import { ADMIN, AUTHOR_CLAIM_MODERATOR, LOGIN_AS, RoleValue } from '@/constants/Role';
import { ChildRoutesContext } from '@/router/ChildRoutes';
import { getString } from '@/content/i18n';
import { Nullable } from '@/utils/types';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

import type { RouteConfig } from 'react-router-config';

type AdminRouteProps = {
  route: Nullable<RouteConfig>;
};

export default class AdminRoute extends React.Component<AdminRouteProps> {
  static getPageTitle = () => getString(_ => _.moderation.pageTitle);

  static requiresRoles = () => [ADMIN, AUTHOR_CLAIM_MODERATOR, LOGIN_AS] as Array<RoleValue>;

  render() {
    const { route } = this.props;
    return (
      <ChildRoutesContext.Provider value={{ route }}>
        <AsyncLoadedPage
          footer={false}
          load={{
            importer: () => import(/* webpackChunkName: "desktop-AdminPage" */ './AdminPage'),
            chunkName: 'desktop-AdminPage',
            moduleId: require.resolveWeak('./AdminPage'),
          }}
        />
      </ChildRoutesContext.Provider>
    );
  }
}
