import { hasValue, Nullable, ReactNodeish, ValueOf } from '@/utils/types';
import Icon from '@/components/shared/icon/Icon';

import classnames from 'classnames';
import React from 'react';

const DEFAULT = 'default';
const LARGE = 'large';

const CHECKED = 'checked';
const UNCHECKED = 'unchecked';
const INDETERMINATE = 'indeterminate';

export const SIZE = {
  DEFAULT,
  LARGE,
} as const;

export const CHECKBOX_STATE = {
  CHECKED,
  UNCHECKED,
  INDETERMINATE,
} as const;

const ARIA_CHECKBOX_STATE = ['false', 'mixed', 'true'] as const;

export type CheckboxSize = ValueOf<typeof SIZE>;
export type CheckboxState = ValueOf<typeof CHECKBOX_STATE>;
type AriaCheckboxState = (typeof ARIA_CHECKBOX_STATE)[number];

export type CLCheckboxInputBaseProps = {
  ariaLabel?: string;
  ariaLabelledBy?: string;
  isChecked?: Nullable<boolean | CheckboxState>;
  isDisabled?: boolean;
  size?: CheckboxSize;
  onChange?: (isChecked: boolean) => void;
} & Pick<React.InputHTMLAttributes<HTMLInputElement>, 'name' | 'value' | 'className'>;

type State = {
  isChecked: boolean;
};

export default class CLCheckboxInputBase extends React.PureComponent<
  CLCheckboxInputBaseProps,
  State
> {
  static defaultProps = {
    isChecked: null,
    isDisabled: false,
    size: DEFAULT,
  };

  constructor(...args: [any]) {
    super(...args);

    this.state = {
      isChecked: hasValue(this.props.isChecked) ? shouldBeChecked(this.props.isChecked) : false,
    };
  }

  onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { isChecked: isCheckedFromState } = this.state;
    const { isDisabled, onChange } = this.props;

    if (isDisabled) {
      return;
    }

    const isCurrentTargetChecked = event.currentTarget.checked;
    if (onChange) {
      onChange(isCurrentTargetChecked);
    }

    this.setState({ isChecked: !isCheckedFromState });
  };

  getIsCheckedFromPropsOrState(): boolean {
    const { isChecked: isCheckedFromState } = this.state;
    const { isChecked: isCheckedFromProps } = this.props;
    return hasValue(isCheckedFromProps) ? shouldBeChecked(isCheckedFromProps) : isCheckedFromState;
  }

  ariaCheckedLabel(isCheckedValue: boolean): AriaCheckboxState {
    const { isChecked: isCheckedFromProps } = this.props;
    return isCheckedFromProps === CHECKBOX_STATE.INDETERMINATE
      ? 'mixed'
      : (isCheckedValue.toString() as AriaCheckboxState);
  }
  render(): ReactNodeish {
    const {
      isDisabled,
      name,
      value,
      ariaLabel,
      className,
      ariaLabelledBy,
      size,
      isChecked: isCheckedFromProps,
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      onChange,
      // NOTE: Below are props we want to pass to the component, like heap or data selenium selectors.
      //       Everything we don't want passed through should be in the list above.
      ...rest
    } = this.props;
    const checkedState = this.getIsCheckedFromPropsOrState();

    return (
      <div className={classnames('cl-checkbox-input', className)} {...rest}>
        <input
          type="checkbox"
          aria-checked={this.ariaCheckedLabel(checkedState)}
          checked={checkedState}
          disabled={isDisabled}
          onChange={this.onChangeHandler}
          className="cl-checkbox-input__input"
          aria-labelledby={ariaLabelledBy}
          aria-label={ariaLabel}
          name={name}
          value={value}
        />
        <div
          className={classnames({
            'cl-checkbox-input__checkbox-icon': true,
            'cl-checkbox-input__checkbox-icon--is-checked': checkedState,
            'cl-checkbox-input__checkbox-icon--is-disabled': isDisabled,
            [`cl-checkbox-input--size-${size || ''}`]: true,
          })}>
          <Icon
            icon={isCheckedFromProps === INDETERMINATE ? 'fa-horizontal-rule' : 'fa-check'}
            height="6"
            width="6"
          />
        </div>
      </div>
    );
  }
}

export function shouldBeChecked(isCheckedValue: boolean | CheckboxState): boolean {
  if (typeof isCheckedValue === 'boolean') {
    return isCheckedValue;
  }

  // if isCheckedValue is an enum, resolve it as a boolean
  switch (isCheckedValue) {
    case CHECKED:
    case INDETERMINATE:
      return true;
    case UNCHECKED:
      return false;
    default:
      return false;
  }
}
