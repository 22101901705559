import {
  AuthorInfluenceStatisticsRecord,
  getAuthorInfluenceStatisticsFromGraphQL,
} from '@/models/author/AuthorInfluenceStatistics';
import { isNumber, Nullable } from '@/utils/types';
import BaseStore from '@/stores/BaseStore';
import Constants from '@/constants';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import type { ApiRequestStartingPayload } from '@/api/BaseApi';
import type { ApiResponse } from '@/api/ApiResponse';
import type { FetchAuthorInfluenceStatsResponse } from '@/actions/AuthorActionCreators';
import type { GraphQLResponse } from '@/api/GraphQLApi';
import type S2Dispatcher from '@/utils/S2Dispatcher';

export default class AuthorInfluenceStatsStore extends BaseStore {
  #state: StoreStateValue;
  #authorId: Nullable<number>;
  #stats: Nullable<AuthorInfluenceStatisticsRecord>;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#state = StoreState.UNINITIALIZED;
    this.#authorId = null;
    this.#stats = null;

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const apiStartingPayload = payload as ApiRequestStartingPayload;
          switch (apiStartingPayload.requestType) {
            case Constants.requestTypes.GQL__AUTHOR_INFLUENCE_STATS: {
              return this.#handleStatsStart(apiStartingPayload);
            }
          }
          return;
        }
        case Constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.GQL__AUTHOR_INFLUENCE_STATS: {
              return this.#handleStatsComplete(apiResponse);
            }
          }
          return;
        }
      }
    });
  }

  #handleStatsStart(payload: ApiRequestStartingPayload): void {
    let hasChanged = false;

    if (this.#state !== StoreState.LOADING) {
      this.#state = StoreState.LOADING;
      hasChanged = true;
    }

    const authorId = payload.context?.variables?.authorId || null;
    if (authorId && this.#authorId !== authorId) {
      this.#authorId = authorId;
    }

    if (hasChanged) {
      this.emitChange();
    }
  }

  #handleStatsComplete(
    payload: ApiResponse<GraphQLResponse<FetchAuthorInfluenceStatsResponse>>
  ): void {
    const authorId = payload.context?.variables?.authorId;
    if (this.#authorId !== null && this.#authorId !== authorId) {
      // A request has come in out of order
      return;
    }

    const author = payload.resultData.data?.author;
    if (!author) {
      return;
    }

    this.#state = StoreState.LOADED;
    this.#authorId = authorId;
    this.#stats = getAuthorInfluenceStatisticsFromGraphQL(author);

    this.emitChange();
  }

  isUninitialized(): boolean {
    return this.#state === StoreState.UNINITIALIZED;
  }

  isLoading(): boolean {
    return this.isUninitialized() || this.#state === StoreState.LOADING;
  }

  isAuthorLoaded(authorId: string | number): boolean {
    if (this.isLoading()) {
      return false;
    }
    const authorIdNum = isNumber(authorId) ? authorId : parseInt(authorId, 10);
    return this.#authorId === authorIdNum;
  }

  getStats(): Nullable<AuthorInfluenceStatisticsRecord> {
    return this.#stats;
  }

  getNumCitingAuthors(): Nullable<number> {
    if (!this.#stats) {
      return null;
    }
    return this.#stats.numCitingAuthors;
  }

  getNumCoAuthors(): Nullable<number> {
    if (!this.#stats) {
      return null;
    }
    return this.#stats.numCoAuthors;
  }

  getNumReferencedAuthors(): Nullable<number> {
    if (!this.#stats) {
      return null;
    }
    return this.#stats.numReferencedAuthors;
  }

  getNumPublications(): Nullable<number> {
    if (!this.#stats) {
      return null;
    }
    return this.#stats.numPublications;
  }
}
