import AnalyticsEvent from './AnalyticsEvent';

import EventType from '@/analytics/constants/EventType';

export type EventData = {
  experimentKey: string;
  userId: string;
};

/** Event for when server side and client side calculated variations do not match as they should */
export default class VariationMismatchEvent extends AnalyticsEvent {
  constructor(eventData: EventData) {
    super(EventType.VARIATION_MISMATCH, eventData);
  }

  static create(eventData: EventData): VariationMismatchEvent {
    return new VariationMismatchEvent(eventData);
  }
}
