import {
  AcronymDefinitionFromJS,
  AcronymMentionFromJS,
  getPaperPdfAcronymMentionFromJS,
  PaperPdfAcronymMentionRecord,
} from '@/models/paper-pdf/PaperPdfAcronyms';
import {
  AcronymLongformMetrics,
  AcronymMentionMetrics,
  AcronymOnClickMetrics,
  AcronymOnLoadMetrics,
} from '@/components/shared/paper-detail/reader/acronym-utils';
import { ApiRequestStartingPayload } from '@/api/BaseApi';
import { ApiResponse, PaperPdfDataResponseBody } from '@/api/ApiResponse';
import {
  getPaperPdfBoundingBoxFromJS,
  isBoundingBoxComplete,
  PaperPdfBoundingBoxPropsFromJS,
  PaperPdfBoundingBoxRecord,
} from '@/models/paper-pdf/PaperPdfBoundingBox';
import { Nullable } from '@/utils/types';
import { PaperPdfOutputDescriptionRecord } from '@/models/paper-pdf/PaperPdfOutputDescription';
import { S2airsFeatureVersion } from '@/models/paper-pdf/S2airsFeatureVersion';
import BaseStore from '@/stores/BaseStore';
import Constants from '@/constants';
import S2Dispatcher from '@/utils/S2Dispatcher';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import Immutable from 'immutable';

export type PageIndexToAcronym = Immutable.Map<
  number,
  Immutable.List<PaperPdfAcronymMentionRecord>
>;

type FlattenedAcronymMention = {
  pageIndex: number;
  s2airsId: Nullable<string>;
  mmdaId: Nullable<string>;
  text: Nullable<string>;
  definitionS2airsId: Nullable<string>;
  definitionMmdaId: Nullable<string>;
  definitionText: Nullable<string>;
  definitionBoundingBox: PaperPdfBoundingBoxRecord;
  boundingBox: PaperPdfBoundingBoxRecord;
};
export default class ReaderAcronymsStore extends BaseStore {
  #storeState: StoreStateValue;
  #pageIndexToAcronymMapping: PageIndexToAcronym;
  #featureVersion: Nullable<S2airsFeatureVersion>;
  #outputDescription: Nullable<PaperPdfOutputDescriptionRecord>;
  #encodedOutputDescription: Nullable<string>;
  #paperSha: Nullable<string>;
  #totalUniqueAcronyms: Nullable<number>;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#storeState = StoreState.UNINITIALIZED;
    this.#pageIndexToAcronymMapping = Immutable.Map();

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const apiStartingPayload = payload as ApiRequestStartingPayload;
          switch (apiStartingPayload.requestType) {
            case Constants.requestTypes.PDF_DATA: {
              this.#pageIndexToAcronymMapping = Immutable.Map();
              this.#storeState = StoreState.LOADING;
              this.emitChange();
              break;
            }
          }
          break;
        }

        case Constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.PDF_DATA: {
              const pdfDataApiResponse = apiResponse as ApiResponse<PaperPdfDataResponseBody>;
              const rawAcronyms = pdfDataApiResponse?.resultData?.acronyms?.feature || [];
              if (rawAcronyms) {
                this.#totalUniqueAcronyms = Immutable.List(
                  rawAcronyms.map(a => a.text)
                ).toSet().size;
                this.#pageIndexToAcronymMapping = mapPageIndexToAcronym(rawAcronyms);
                this.#featureVersion =
                  pdfDataApiResponse.resultData.acronyms?.featureVersion || null;
                this.#outputDescription = pdfDataApiResponse.resultData?.outputDescription || null;
                this.#encodedOutputDescription =
                  pdfDataApiResponse.resultData?.encodedOutputDescription || null;
                this.#paperSha = pdfDataApiResponse.context?.paperId || null;
              }
              this.#storeState = StoreState.LOADED;
              this.emitChange();
              break;
            }
          }
          break;
        }

        case Constants.actions.API_REQUEST_FAILED: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.PDF_DATA: {
              this.#storeState = StoreState.ERROR;
              this.emitChange();
              return;
            }
          }
          return;
        }
      }
    });
  }

  getOutputDescription(): Nullable<PaperPdfOutputDescriptionRecord> {
    return this.#outputDescription;
  }

  getEncodedOutputDescription(): Nullable<string> {
    return this.#encodedOutputDescription;
  }

  getPageIndexToAcronymMapping(): PageIndexToAcronym {
    return this.#pageIndexToAcronymMapping;
  }

  getOnClickMetrics(s2airsId: Nullable<string>): AcronymOnClickMetrics {
    return {
      s2airsId,
      outputDescription: this.#encodedOutputDescription,
    };
  }

  getOnLoadMetrics(): AcronymOnLoadMetrics {
    const longformKey: Set<string> = new Set(); // helps prevent duplicate longforms
    const longforms: AcronymLongformMetrics[] = [];
    const mentions: AcronymMentionMetrics[] = [];
    this.#pageIndexToAcronymMapping.forEach(pageOfMentions => {
      pageOfMentions.forEach(m => {
        const longform = {
          s2airsId: m.definitionS2airsId,
          mmdaId: m.definitionMmdaId,
          text: m.definitionText,
          pageNumber: m.definitionBoundingBox.page.toString() || '',
        };
        const mention = {
          s2airsId: m.s2airsId,
          mmdaId: m.mmdaId,
          text: m.text,
          definitionS2airsId: m.definitionS2airsId,
          pageNumber: m.boundingBox.page.toString() || '',
        };
        if (longform.s2airsId && !longformKey.has(longform.s2airsId)) {
          longformKey.add(longform.s2airsId);
          longforms.push(longform);
        }
        mentions.push(mention);
      });
    });

    return {
      featureVersion: this.#featureVersion,
      outputDescription: this.#encodedOutputDescription,
      paperSha: this.#paperSha,
      longforms: longforms,
      mentions: mentions,
    };
  }

  getMentionIdsInPage(pageIndex: number): string[] {
    return [...(this.#pageIndexToAcronymMapping.get(pageIndex)?.map(m => m.s2airsId || '') || [])];
  }

  getTotalUniqueAcronyms(): number {
    return this.#totalUniqueAcronyms || 0;
  }

  getUniqueAcronymCountOnPage(pageIndex: number): number {
    return new Set(this.#pageIndexToAcronymMapping.get(pageIndex)?.map(m => m.text)).size;
  }

  hasAcronyms(): boolean {
    return !this.#pageIndexToAcronymMapping.isEmpty();
  }

  isUninitialized(): boolean {
    return this.#storeState === StoreState.UNINITIALIZED;
  }

  isLoading(): boolean {
    return this.isUninitialized() || this.#storeState === StoreState.LOADING;
  }

  isFailed(): boolean {
    return this.#storeState === StoreState.ERROR;
  }
}

function getTopBoundingBox(boundingBoxes: PaperPdfBoundingBoxPropsFromJS[]) {
  boundingBoxes.sort((a, b) => {
    if (a.page == b.page) {
      return (b.top || 0) - (a.top || 0);
    }
    return (b.page || -1) - (a.page || -1);
  });
  return boundingBoxes[0];
}

// function takes in a acronyms data and map each acronym respectively based on the page index
function mapPageIndexToAcronym(rawAcronyms: AcronymDefinitionFromJS[]): PageIndexToAcronym {
  // Collect acronyms into a flattened record
  const flattenedAcronymMentionsArray: FlattenedAcronymMention[] = [];
  const acronyms = rawAcronyms.filter(a => !!a?.mentions.length);
  for (const acronym of acronyms) {
    const flatMentions = acronym.mentions.flat().flatMap(mention => {
      const boundingBoxes = mention.boundingBoxes.filter(b => !!b && isBoundingBoxComplete(b));
      return boundingBoxes.map(b => {
        const boxRecord = getPaperPdfBoundingBoxFromJS(b);
        const topDefinitionBox = getTopBoundingBox(acronym.boundingBoxes);
        const definitionBoxRecord = getPaperPdfBoundingBoxFromJS(topDefinitionBox);
        return {
          pageIndex: boxRecord.page,
          s2airsId: mention.s2airsId,
          mmdaId: mention.mmdaId,
          text: mention.text,
          definitionS2airsId: acronym.s2airsId,
          definitionMmdaId: acronym.mmdaId,
          definitionText: acronym.text,
          definitionBoundingBox: definitionBoxRecord,
          boundingBox: boxRecord,
        };
      });
    });
    flattenedAcronymMentionsArray.push(...flatMentions);
  }
  const flattenedAcronyms = Immutable.List(flattenedAcronymMentionsArray);

  // Break flattened acronyms in a map indexed by page index
  return flattenedAcronyms.reduce((pageIndexToAcronym, flattenedAcronyms) => {
    if (!pageIndexToAcronym.has(flattenedAcronyms.pageIndex)) {
      pageIndexToAcronym = pageIndexToAcronym.set(flattenedAcronyms.pageIndex, Immutable.List());
    }

    const acronym: AcronymMentionFromJS = {
      s2airsId: flattenedAcronyms.s2airsId,
      mmdaId: flattenedAcronyms.mmdaId,
      text: flattenedAcronyms.text,
      definitionS2airsId: flattenedAcronyms.definitionS2airsId,
      definitionMmdaId: flattenedAcronyms.definitionMmdaId,
      definitionText: flattenedAcronyms.definitionText,
      definitionBoundingBox: flattenedAcronyms.definitionBoundingBox,
      boundingBox: flattenedAcronyms.boundingBox,
    };

    const acronymRecord = getPaperPdfAcronymMentionFromJS(acronym);

    let acronymBasedOnPageIndex = pageIndexToAcronym.get(flattenedAcronyms.pageIndex);

    if (acronymBasedOnPageIndex) {
      acronymBasedOnPageIndex = acronymBasedOnPageIndex.push(acronymRecord);

      pageIndexToAcronym = pageIndexToAcronym.set(
        flattenedAcronyms.pageIndex,
        acronymBasedOnPageIndex
      );
    }

    return pageIndexToAcronym;
  }, Immutable.Map());
}
