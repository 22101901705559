import { getParamsForRoute, getRouteNameForPath, RouteName } from '@/router/Routes';
import { searchStringToObj } from '@/router/routerUtils';
import Constants from '@/constants';

import type { DispatchPayload } from '@/utils/S2Dispatcher';
import type { Nullable } from '@/utils/types';

export type RoutingPayload = DispatchPayload<{
  actionType: typeof Constants.actions.ROUTING;
  routeName: Nullable<RouteName>;
  pathParams: Nullable<string[]>;
  state: {
    query: Record<string, string>;
    path: string;
    action?: null; // HistoryStore needs this, but nothing ever adds it
  };
}>;

export function routingForPath({
  query,
  search,
  path,
}: {
  query?: Record<string, string>;
  search?: string;
  path: string;
}): RoutingPayload {
  const queryObj = query ? query : searchStringToObj(search);
  const routeName = getRouteNameForPath(path);
  const pathParams = routeName ? getParamsForRoute(routeName, path) : null;
  return {
    actionType: Constants.actions.ROUTING,
    routeName,
    pathParams,
    state: {
      query: queryObj,
      path,
    },
  };
}
