import LoginCredentialsForm from './LoginCredentialsForm';

import { COGNITO, FACEBOOK, GOOGLE, OPENATHENS, SIGN_IN, TWITTER } from '@/constants/LoginMethods';
import { getString } from '@/content/i18n';
import { heapSignInMethodButton } from '@/analytics/attributes/signin';
import CLLoginButton from '@/components/library/button/CLLoginButton';
import CLRule from '@/components/library/layout/CLRule';
import CLTextButton from '@/components/library/button/CLTextButton';
import CLTooltip, { PLACEMENT } from '@/components/library/tooltip/CLTooltip';
import Icon from '@/components/shared/icon/Icon';
import Link from '@/router/Link';

import classnames from 'classnames';
import React from 'react';

export default function LoginMethods({ onRequestLogin, action }) {
  const isFormVisible = action === SIGN_IN;
  const isTwitterVisible = action === SIGN_IN;

  // State for buttons
  const [isButtonListCompressed, setButtonListCompressed] = React.useState(true);

  return (
    <div className="login-methods">
      <div className="login-methods__scrollable">
        <ul
          className={classnames({
            'login-methods__button-list': true,
            'login-methods__button-list--is-compressed': isButtonListCompressed,
          })}>
          <li key={OPENATHENS} className="login-methods__button">
            <LoginMethodButton
              action={action}
              method={OPENATHENS}
              onRequestLogin={onRequestLogin}
            />
            <Link
              aria-label={getString(_ => _.login.signUp.institutionalLoginAriaLabel)}
              to="FAQ_ROOT"
              hash="institutional-login"
              className="login-methods__button__tooltip-anchor">
              <CLTooltip
                placement={PLACEMENT.RIGHT}
                tooltipContent={getString(_ => _.login.signUp.institutionalLoginTooltip)}>
                <Icon icon="information" />
              </CLTooltip>
            </Link>
          </li>

          <li key={GOOGLE} className="login-methods__button">
            <LoginMethodButton action={action} method={GOOGLE} onRequestLogin={onRequestLogin} />
          </li>

          <li key={FACEBOOK} className="login-methods__button">
            <LoginMethodButton action={action} method={FACEBOOK} onRequestLogin={onRequestLogin} />
          </li>

          {isTwitterVisible && (
            <li key={TWITTER} className="login-methods__button">
              <LoginMethodButton
                action={action}
                method={TWITTER}
                onRequestLogin={onRequestLogin}
                disabled
              />
              <Link
                aria-label={getString(_ => _.login.signUp.twitterLoginAriaLabel)}
                to="FAQ_ROOT"
                hash="sign-in"
                className="login-methods__button__tooltip-anchor">
                <CLTooltip
                  placement={PLACEMENT.RIGHT}
                  tooltipContent={getString(_ => _.login.signUp.twitterLoginTooltip)}>
                  <Icon icon="information" />
                </CLTooltip>
              </Link>
            </li>
          )}
        </ul>

        {isButtonListCompressed && (
          <div className="login-methods__view-more">
            <CLTextButton
              label={getString(_ => _.login.moreOptionsLabel)}
              onClick={() => setButtonListCompressed(false)}
            />
          </div>
        )}

        <CLRule
          className="login-methods__form-rule"
          label={getString(_ => _.login.loginForm.ruleLabel)}
        />
        {isFormVisible ? (
          <LoginCredentialsForm onRequestLogin={onRequestLogin} />
        ) : (
          <div className="login-methods__button">
            <LoginMethodButton action={action} method={COGNITO} onRequestLogin={onRequestLogin} />
          </div>
        )}
      </div>
    </div>
  );
}

export function LoginMethodButton({ action, method, onRequestLogin, disabled }) {
  const onClick = React.useCallback(
    event => {
      event.preventDefault();
      onRequestLogin({ action, method });
    },
    [action, method]
  );

  return (
    <CLLoginButton
      {...heapSignInMethodButton({ method, action })}
      data-test-id={`${method}-sign-in`}
      method={method}
      action={action}
      onClick={onClick}
      disabled={disabled}
    />
  );
}
