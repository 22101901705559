import React from 'react';

export default function IconTabPapers(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 27 36">
      <path
        d="M20.25,17.44v1.97c0,0.46-0.38,0.84-0.84,0.84H7.59c-0.46,0-0.84-0.38-0.84-0.84v-1.97c0-0.46,0.38-0.84,0.84-0.84h11.81
      C19.87,16.59,20.25,16.97,20.25,17.44z M19.41,22.5H7.59c-0.46,0-0.84,0.38-0.84,0.84v1.97c0,0.46,0.38,0.84,0.84,0.84h11.81
      c0.46,0,0.84-0.38,0.84-0.84v-1.97C20.25,22.88,19.87,22.5,19.41,22.5z M27,9.27v23.35c0,1.86-1.51,3.38-3.38,3.38H3.38
      C1.51,36,0,34.49,0,32.62V3.38C0,1.51,1.51,0,3.38,0h14.35c0.89,0,1.75,0.36,2.38,0.99l5.9,5.9C26.64,7.52,27,8.38,27,9.27z
      M18,3.65V9h5.35C23.35,9,18,3.65,18,3.65z M23.62,32.62V12.38h-7.31c-0.94,0-1.69-0.75-1.69-1.69V3.38H3.38v29.25H23.62z"
      />
    </symbol>
  );
}
