import { cleanAndStringifyQuery } from './routerUtils';
import { makePath, PathParams, RouteName } from './Routes';

import { NavLink as ReactRouterNavLink } from 'react-router-dom';
import classnames from 'classnames';
import React from 'react';

import type { Nullable, ReactNodeish } from '@/utils/types';

type Props = React.PropsWithChildren<{
  activeClassName?: Nullable<string>; // Class added when url matches route
  activeStyle?: any; // Style object added when url matches route
  className?: Nullable<string>;
  to: RouteName;
  exact?: Nullable<boolean>;
  strict?: Nullable<boolean>;
  params: PathParams;
  query?: Nullable<any>;
  hash?: Nullable<string>;
  shouldUnderline?: Nullable<boolean>;
}>;

const NavLink = (props: Props): ReactNodeish => {
  // eslint-disable-next-line react/prop-types
  const {
    activeClassName,
    activeStyle,
    className,
    children,
    to,
    params,
    query,
    hash,
    exact,
    strict,
    shouldUnderline,
    ...rest
  } = props;
  const toObj = {
    pathname: makePath({ params, routeName: to }),
    search: query ? cleanAndStringifyQuery(query) : '',
    hash: hash || '',
  };
  return (
    <ReactRouterNavLink
      activeClassName={activeClassName || ''}
      activeStyle={activeStyle || null}
      className={classnames(className, {
        'link-button--no-underline': !shouldUnderline,
      })}
      to={toObj}
      exact={exact || false}
      strict={strict || false}
      {...rest}>
      {children}
    </ReactRouterNavLink>
  );
};

NavLink.defaultProps = {
  params: {},
  shouldUnderline: true,
};

export default NavLink;
