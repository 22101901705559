import * as util from './util';
import { FigureRecord } from './models/Figure';
import { getString } from './content/i18n';

/**
 * Utilities related to string based formatting.
 */

export function citationsValue(citations: number): string {
  return util.format(Math.round(citations));
}

/**
 * Returns the appropriate page title for a figure detail page.
 *
 * @param {Figure} figure the figure
 * @param {string} paperTitle the associated figure's paper title
 *
 * @returns {string} the appropriate page title
 */
export function figureDetailPageTitle(paperTitle: string, figure: FigureRecord): string {
  return getString(
    _ => _.paperDetail.figure.pageTitle,
    capitalizeFirst(figure.displayName, 'Figure'),
    paperTitle
  );
}

/**
 * Returns str, with the first character capitalized.  If str isn't a string, returns defaultStr
 *
 * @param {string} str
 * @param {string} defaultStr
 *
 * @returns {string} str, with the first character capitalized or defaultStr if str isn't a string.
 */
export function capitalizeFirst(str: any, defaultStr: string): string {
  return typeof str === 'string' ? str.slice(0, 1).toUpperCase() + str.slice(1) : defaultStr;
}

/**
 * Returns str, with each word capitalized. Words are seperated by spaces or hyphens. Returns if str isnt a string, returns ''
 *
 * @param {string} str
 *
 * @returns {string} str, with all words capitalized. If str isnt a string, returns ''
 */
export function capitalizeAll(str: string): string {
  return str?.replace(/(^\w{1})|([\s-]+\w{1})/g, (char: string) => char.toUpperCase()) || '';
}

/**
 * Returns a string representing a list of values as a comma-separated list,
 * with "and" before the final element, but omitting a comma betweent the
 * last two elements. E.g. "apples, pears and oranges"
 *
 * @param {array} values
 * @param {object} options
 *
 * @returns {string} values as a comma-separated list with "and" before the last element
 */
export function commaList(values: string[], { useAmpersand = false } = {}): string {
  if (values.length > 1) {
    // Type assertions used because we know this array has at least 2 elements
    const lastOne = values.pop() as string;
    const nextToLast = values.pop() as string;
    if (useAmpersand) {
      values.push(getString(_ => _.general.XAmpY, nextToLast, lastOne));
    } else {
      values.push(getString(_ => _.general.XAndY, nextToLast, lastOne));
    }
  }
  return values.join(', ');
}

/**
 * Returns a string representing a list of values as a comma-separated list,
 * with "and" before the final element, and using a comma between the next-to-last
 * and final elements.  E.g. "apples, pears, and oranges"
 *
 * @param {array} values
 *
 * @returns {string} values as a comma-separated list with ", and" before the last element
 */
export function oxfordCommaList(values: string[]): string {
  if (values.length === 2) {
    return commaList(values); // no penultimate comma if there are only two to begin with
  }
  if (values.length > 1) {
    // Type assertion because we know we're pop()-ing from a non-empty array
    const lastOne = values.pop() as string;
    values.push(getString(_ => _.general.andX, lastOne));
  }
  return values.join(', ');
}
