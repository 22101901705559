import React from 'react';

export default function IconRemoveX(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 24 24">
      <path
        d="M19.49,0.77L12,8.26L4.51,0.77c-1.03-1.03-2.71-1.03-3.74,0s-1.03,2.71,0,3.74L8.26,12l-7.49,7.49
        c-1.03,1.03-1.03,2.71,0,3.74s2.71,1.03,3.74,0L12,15.74l7.49,7.49c1.03,1.03,2.71,1.03,3.74,0s1.03-2.71,0-3.74L15.74,12l7.49-7.49
        c1.03-1.03,1.03-2.71,0-3.74S20.51-0.26,19.49,0.77z"
      />
    </symbol>
  );
}
