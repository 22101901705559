import {
  getPaperPdfCitationMentionsFromJS,
  PaperPdfCitationMentionsFromJS,
  PaperPdfCitationMentionsRecord,
} from './PaperPdfCitationMentions';

import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

import type { S2airsFeatureVersion } from './S2airsFeatureVersion';

export type CitationFeatureFromJS = {
  citedPaperId: Nullable<string>;
  referenceText: Nullable<string>;
  mentions: Nullable<PaperPdfCitationMentionsFromJS[]>;
  s2airsId: Nullable<string>; // this s2airsId is corresponding to bibliography entry, not corresponding to the citation mention
  citedCorpusId: Nullable<number>;
};

export type CitationFeatureRecord = {
  citedPaperId: Nullable<string>;
  referenceText: Nullable<string>;
  mentions: Nullable<Immutable.List<PaperPdfCitationMentionsRecord>>;
  s2airsId: Nullable<string>; // this s2airsId is corresponding to bibliography entry, not corresponding to the citation mention
  citedCorpusId: Nullable<number>;
};

export type PaperPdfCitationsFromJS = {
  feature: Nullable<CitationFeatureFromJS[]>;
  featureVersion: Nullable<S2airsFeatureVersion>;
};

type Props = {
  feature: Nullable<Immutable.List<CitationFeatureRecord>>;
  featureVersion: Nullable<S2airsFeatureVersion>;
};

const defaultProps: Props = {
  feature: null,
  featureVersion: null,
};

export const PaperPdfCitationsFactory = Immutable.Record<Props>(defaultProps);
export type PaperPdfCitationsRecord = Immutable.RecordOf<Props>;

export function getPaperPdfCitationsFromJS(args: PaperPdfCitationsFromJS): PaperPdfCitationsRecord {
  const { feature, featureVersion } = args;
  const citationFeatures = Immutable.List(feature || []).map(f => {
    return { ...f, mentions: buildMentionsList(f.mentions) };
  });

  return PaperPdfCitationsFactory({
    feature: citationFeatures,
    featureVersion,
  });
}

const buildMentionsList = (
  rawMentions: Nullable<PaperPdfCitationMentionsFromJS[]>
): Immutable.List<PaperPdfCitationMentionsRecord> => {
  return Immutable.List(rawMentions || []).map(mention =>
    getPaperPdfCitationMentionsFromJS(mention)
  );
};
