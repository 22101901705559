import { hasValue, Nullable, ReactNodeish } from '@/utils/types';

import classnames from 'classnames';
import React from 'react';

type Props = {
  className?: Nullable<string>;
  cssHeight?: Nullable<string>;
  cssWidth?: Nullable<string>;
  isInline?: Nullable<boolean>;
};

export default function Shimmer({
  className,
  cssHeight,
  cssWidth,
  isInline = true,
}: Props): ReactNodeish {
  const cssProps = React.useMemo(() => {
    const props: React.CSSProperties = {};
    if (hasValue(cssHeight)) {
      props.height = cssHeight;
    }
    if (hasValue(cssWidth)) {
      props.width = cssWidth;
    }
    if (isInline) {
      props.display = 'inline-block';
    }
    return props;
  }, [cssHeight, cssWidth, isInline]);

  return <div className={classnames('shimmer', className)} style={cssProps} />;
}
