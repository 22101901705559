import Immutable from 'immutable';

export type LinkProps = {
  url: string;
  text: string;
};

const defaultProps: LinkProps = {
  url: '',
  text: '',
};

export const LinkRecordFactory = Immutable.Record<LinkProps>(defaultProps);
export type LinkRecord = Immutable.RecordOf<LinkProps>;
