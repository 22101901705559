import CLTextInputBase, { CLTextInputBaseProps } from './CLTextInputBase';

import { ReactNodeish } from '@/utils/types';

import React from 'react';

type CLEmailInputProps = Omit<CLTextInputBaseProps, 'type'>;

export default function CLEmailInput(props: CLEmailInputProps): ReactNodeish {
  return <CLTextInputBase type="email" {...props} />;
}
