// TODO (#31135): Strongly type QueryState
export type QueryStateInst = string;

export default {
  INITIALIZED: 'INITIALIZED',
  SORTING: 'SORTING',
  LOADING: 'LOADING',
  FILTERING: 'FILTERING',
  LOADED: 'LOADED',
};
