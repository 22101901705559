import { FeatureRecord, getFeatureRecordFromJS } from './FeatureRecord';

import Immutable from 'immutable';

import type { Nullable } from '@/utils/types';

// Update this map of Feature Flags when you add logic to control the visibility of a feature.
// For more details see https://github.com/allenai/scholar/blob/main/online/Experimenting.md

export const AHP_SORT_PREF = 'ahp_sort_pref';
export const AUTHOR_PROFILE_PICTURE = 'author_profile_picture';
export const APPCUES = 'appcues';
export const AUTHOR_CLAIM_ON_PDP = 'author_claim_on_pdp';
export const AUTH_GOOGLE_ONE_TAP = 'auth_google_one_tap';
export const AWS_WAF = 'aws_waf';
export const VENUES_PAGE = 'venues_page';
export const CITATION_STYLE_PREFERENCE = 'citation_style_preference';
export const FRESHDESK_DOWN = 'freshdesk_down';
export const GOOGLE_ANALYTICS = 'google_analytics';
export const GOOGLE_FONTS = 'google_fonts';
export const GOOGLE_TAG_MANAGER = 'google_tag_manager';
export const GRAPHQL_PROXY = 'graphql_proxy';
export const HEAP = 'heap';
export const HOMEPAGE_ADS = 'homepage_ads';
export const HOMEPAGE_AD_PUBLIC_API = 'homepage_ad_public_api';
export const HOMEPAGE_AD_SEMANTIC_READER = 'homepage_ad_semantic_reader';
export const HUBSPOT = 'hubspot';
export const HUBSPOT_NEWSLETTER_FORM = 'hubspot_newsletter_form';
export const LAYOVER_CLIENT = 'layover_client';
export const LOG_DISPATCHES = 'log_dispatches';
export const LOG_HEAP_LANDMARKS = 'log_heap_landmarks';
export const LOGIN_DEMOGRAPHICS_MODAL = 'login_demographics_modal';
export const MINIFY_JS = 'minify_js';
export const OPEN_ATHENS_REDIRECT = 'open_athens_redirect';
export const PAPER_LABS = 'paper_labs';
export const PAPER_LABS_CARD = 'paper_labs_card';
export const PAPER_QA_CARD = 'paper_qa_card';
export const PAPER_QA_PAIRWISE = 'paper_qa_pairwise';
export const PDF_NOTE_TAKING = 'pdf_note_taking';
export const READER_ACRONYMS = 'reader_acronyms';
export const SCROLL_TO_TOP = 'scroll_to_top';
export const SEARCH_CITATIONS_PERF = 'search_citations_perf';
export const SEARCH_SPELLCHECK_SUGGESTIONS = 'search_spellcheck_suggestions';
export const SIMILAR_PAPERS_PDP = 'similar_papers_pdp';
export const TERM_UNDERSTANDING = 'term_understanding';
export const WEB_APP_CAPABLE_META_TAG = 'web_app_capable_meta_tag';
export const WEBLAB_OPT_OUT = 'weblab_opt_out';
export const WITH_ENTITLEMENTS = 'with_entitlements';
export const VALIDATE_USER_IMAGES = 'validate_user_images';

export type FeatureKey =
  | typeof AHP_SORT_PREF
  | typeof AUTHOR_PROFILE_PICTURE
  | typeof APPCUES
  | typeof AUTHOR_CLAIM_ON_PDP
  | typeof AUTH_GOOGLE_ONE_TAP
  | typeof AWS_WAF
  | typeof CITATION_STYLE_PREFERENCE
  | typeof FRESHDESK_DOWN
  | typeof GOOGLE_ANALYTICS
  | typeof GOOGLE_FONTS
  | typeof GOOGLE_TAG_MANAGER
  | typeof GRAPHQL_PROXY
  | typeof HEAP
  | typeof HOMEPAGE_ADS
  | typeof HOMEPAGE_AD_PUBLIC_API
  | typeof HOMEPAGE_AD_SEMANTIC_READER
  | typeof HUBSPOT
  | typeof HUBSPOT_NEWSLETTER_FORM
  | typeof LAYOVER_CLIENT
  | typeof LOG_DISPATCHES
  | typeof LOG_HEAP_LANDMARKS
  | typeof LOGIN_DEMOGRAPHICS_MODAL
  | typeof MINIFY_JS
  | typeof OPEN_ATHENS_REDIRECT
  | typeof PAPER_LABS
  | typeof PAPER_LABS_CARD
  | typeof PAPER_QA_CARD
  | typeof PAPER_QA_PAIRWISE
  | typeof PDF_NOTE_TAKING
  | typeof READER_ACRONYMS
  | typeof SCROLL_TO_TOP
  | typeof SEARCH_CITATIONS_PERF
  | typeof SEARCH_SPELLCHECK_SUGGESTIONS
  | typeof SIMILAR_PAPERS_PDP
  | typeof TERM_UNDERSTANDING
  | typeof WEB_APP_CAPABLE_META_TAG
  | typeof WEBLAB_OPT_OUT
  | typeof WITH_ENTITLEMENTS
  | typeof VENUES_PAGE
  | typeof VALIDATE_USER_IMAGES;

const Feature = {
  AHPSortPref: getFeatureRecordFromJS({
    key: AHP_SORT_PREF,
    fallbackState: false,
    description: "Set and persist user's preferred sort option on AHP",
  }),

  AuthorProfilePicture: getFeatureRecordFromJS({
    key: AUTHOR_PROFILE_PICTURE,
    fallbackState: true,
    description: "Show the author's profile picture from gravatar",
  }),

  AppCues: getFeatureRecordFromJS({
    key: APPCUES,
    fallbackState: false,
    description: '[DEPRECATED] Load the AppCues script on the site',
  }),

  AuthorClaimOnPDP: getFeatureRecordFromJS({
    key: AUTHOR_CLAIM_ON_PDP,
    fallbackState: true,
    description: 'Enable the `?claim=1` flag on PDP for starting the author claim process',
  }),

  AuthGoogleOneTap: getFeatureRecordFromJS({
    key: AUTH_GOOGLE_ONE_TAP,
    fallbackState: true,
    description: 'Enable Google One Tap integration',
  }),

  AwsWaf: getFeatureRecordFromJS({
    key: AWS_WAF,
    fallbackState: true,
    description: 'Load the AWS WAF script on the site',
  }),

  CitationStylePreference: getFeatureRecordFromJS({
    key: CITATION_STYLE_PREFERENCE,
    fallbackState: false,
    description: 'Allows users to set and persist their preferred citation style',
  }),

  FreshdeskDown: getFeatureRecordFromJS({
    key: FRESHDESK_DOWN,
    fallbackState: false,
    description: 'Replaces the contact us form with a message and our email if Freshdesk is down',
  }),

  GoogleAnalytics: getFeatureRecordFromJS({
    key: GOOGLE_ANALYTICS,
    fallbackState: true,
    description: 'Load the Google Analytics script on the site',
  }),

  GoogleFonts: getFeatureRecordFromJS({
    key: GOOGLE_FONTS,
    fallbackState: true,
    description: 'Load Google Fonts CSS on the site',
  }),

  GoogleTagManager: getFeatureRecordFromJS({
    key: GOOGLE_TAG_MANAGER,
    fallbackState: true,
    description: 'Load the Google Tag Manager script on the site',
  }),

  GraphQLProxy: getFeatureRecordFromJS({
    key: GRAPHQL_PROXY,
    fallbackState: false,
    description: 'Redirect graphql api request through the online-service proxy',
  }),

  Heap: getFeatureRecordFromJS({
    key: HEAP,
    fallbackState: true,
    description: 'Load the Heap script on the site',
  }),

  HomepageAds: getFeatureRecordFromJS({
    key: HOMEPAGE_ADS,
    fallbackState: true,
    description: 'Show ads section on the home page',
  }),

  HomepageAdPublicApi: getFeatureRecordFromJS({
    key: HOMEPAGE_AD_PUBLIC_API,
    fallbackState: false,
    description: 'Show ad on homepage for the Public API',
  }),

  HomepageAdSemanticReader: getFeatureRecordFromJS({
    key: HOMEPAGE_AD_SEMANTIC_READER,
    fallbackState: false,
    description: 'Show ad on homepage for Semantic Reader',
  }),

  HubSpot: getFeatureRecordFromJS({
    key: HUBSPOT,
    fallbackState: true,
    description: 'Load the HubSpot script on the site',
  }),

  HubspotNewsletterForm: getFeatureRecordFromJS({
    key: HUBSPOT_NEWSLETTER_FORM,
    fallbackState: true,
    description: 'Use Hubspot Forms for the newsletter on the homepage',
  }),

  LayoverClient: getFeatureRecordFromJS({
    key: LAYOVER_CLIENT,
    fallbackState: true,
    description: 'Send browser metrics to the layover service',
    includeAsEventProperty: false,
  }),

  LogDispatches: getFeatureRecordFromJS({
    key: LOG_DISPATCHES,
    fallbackState: false,
    description: 'Whether to log dispatches to the console for debugging',
    includeAsEventProperty: false,
  }),

  LogHeapLandmarks: getFeatureRecordFromJS({
    key: LOG_HEAP_LANDMARKS,
    fallbackState: false,
    description: 'Whether or not to log scroll events to GA and Heap',
  }),

  LoginDemographicsModal: getFeatureRecordFromJS({
    key: LOGIN_DEMOGRAPHICS_MODAL,
    fallbackState: true,
    description: 'Show the demographics modal after a person who has not filled it out signs in',
  }),

  MinifyJs: getFeatureRecordFromJS({
    key: MINIFY_JS,
    fallbackState: true,
    description:
      'Whether to minify to use the minified bundles or not (should not be enabled for public in production)',
  }),

  OpenAthensRedirect: getFeatureRecordFromJS({
    key: OPEN_ATHENS_REDIRECT,
    fallbackState: true,
    description: 'Show links to Open Athens for papers when person is signed in with institution',
    includeAsEventProperty: false,
  }),

  PaperLabs: getFeatureRecordFromJS({
    key: PAPER_LABS,
    fallbackState: false,
    description: `Gates whether to fetch data for Labs on the PDP`,
  }),

  PaperLabsCard: getFeatureRecordFromJS({
    key: PAPER_LABS_CARD,
    fallbackState: false,
    description: `Display the <PaperLabs/> card on the PDP (requires ${PAPER_LABS} feature enabled, to fetch data)`,
  }),

  PaperQACard: getFeatureRecordFromJS({
    key: PAPER_QA_CARD,
    fallbackState: false,
    description: `Display the Paper Question Answering card on the PDP (requires ${PAPER_LABS} feature enabled, to fetch data)`,
  }),

  PaperQAPairwise: getFeatureRecordFromJS({
    key: PAPER_QA_PAIRWISE,
    fallbackState: false,
    description: `Display the Paper Question Answering card on the PDP in the scorecard (requires ${PAPER_LABS} feature enabled, to fetch data)`,
  }),

  PdfNoteTaking: getFeatureRecordFromJS({
    key: PDF_NOTE_TAKING,
    fallbackState: true,
    description: 'Enable the Note taking feature on PDF Reader pages',
  }),

  ReaderAcronyms: getFeatureRecordFromJS({
    key: READER_ACRONYMS,
    fallbackState: false,
    description: 'Enable Acronym definition links on the Reader',
  }),

  ScrollToTop: getFeatureRecordFromJS({
    key: SCROLL_TO_TOP,
    fallbackState: false,
    description: 'Have pages without a hash scroll to the top when navigated to',
  }),

  SearchCitationsPerf: getFeatureRecordFromJS({
    key: SEARCH_CITATIONS_PERF,
    fallbackState: false,
    description: 'Enable lazy loading for pdp search citations performance',
  }),

  SearchSpellCheckSuggestions: getFeatureRecordFromJS({
    key: SEARCH_SPELLCHECK_SUGGESTIONS,
    fallbackState: false,
    description: 'Enable suggesting alternate search queries when no matches found.',
  }),

  SimilarPapersPdp: getFeatureRecordFromJS({
    key: SIMILAR_PAPERS_PDP,
    fallbackState: true,
    description: 'Show similar papers widget on the PDP (disabling improves perf)',
  }),

  TermUnderstanding: getFeatureRecordFromJS({
    key: TERM_UNDERSTANDING,
    fallbackState: false,
    description: 'Show term definitions in reader',
  }),

  WebAppCapableMetaTag: getFeatureRecordFromJS({
    key: WEB_APP_CAPABLE_META_TAG,
    fallbackState: false,
    description: 'Add <meta name="mobile-web-app-capable" content="yes"> in HTML header',
  }),

  WeblabOptOut: getFeatureRecordFromJS({
    key: WEBLAB_OPT_OUT,
    fallbackState: false,
    description: 'Allow users to opt out of weblabs and analytics tracking via the cookie banner',
  }),

  WithPaperEntitlements: getFeatureRecordFromJS({
    key: WITH_ENTITLEMENTS,
    fallbackState: true,
    description: 'Provide paper entitlement data for the requested PDP',
  }),

  ValidateUserImages: getFeatureRecordFromJS({
    key: VALIDATE_USER_IMAGES,
    fallbackState: false,
    description:
      'Check user image exists before returning it (must be 100% enabled to have an effect)',
  }),

  VenuesPage: getFeatureRecordFromJS({
    key: VENUES_PAGE,
    fallbackState: false,
    description: 'Enables The Venues Page feature',
  }),

  // Please help keep these sorted by alpha
};

export function getFeatureList(featuresObj: typeof Feature): Immutable.List<FeatureRecord> {
  const features = Immutable.List(Object.values(featuresObj));
  return features;
}

export const FeatureKeys = Immutable.List(Object.values(Feature).map(_ => _.KEY));
export function asFeatureKey(featureKey: Nullable<string>): Nullable<FeatureKey> {
  return FeatureKeys.find(name => name === featureKey) || null;
}

export function getFeatureFlagByKey(key: Nullable<string>): Nullable<FeatureRecord> {
  if (!key) {
    return null;
  }
  const feature = getFeatureList(Feature).find(_ => _.KEY === key);
  return feature || null;
}

export type FeatureName = keyof typeof Feature;
export const FeatureNames = Immutable.List(Object.keys(Feature) as FeatureName[]);
export function asFeatureName(featureName: Nullable<string>): Nullable<FeatureName> {
  return FeatureNames.find(name => name === featureName) || null;
}

Object.freeze(Feature);
export default Feature;
