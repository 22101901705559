import { addHeapProps } from '@/components/util/HeapTracking';
import { AnalyticsContextHelper } from '@/components/library/paper/CLPaperAnalytics';
import { getAddLibraryEntryBulkRequestFromJS } from '@/models/library/AddLibraryEntryBulkRequest';
import {
  getLibraryEntriesQueryFromPath,
  LIBRARY_DEFAULT_PAGE_SIZE,
  LibraryEntriesQueryRecordFactory,
} from '@/models/library/LibraryEntriesQuery';
import { getParamsForRoute, getRouteNameForPath } from '@/router/Routes';
import { getString } from '@/content/i18n';
import { heapLibraryRemoveFromLibraryUndoButton } from '@/analytics/attributes/libraryPage';
import { Library } from '@/models/library/LibraryEntrySourceType';
import { LIBRARY_DEFAULT_SORT } from '@/models/library/LibrarySort';
import { showPageErrorMessage } from '@/actions/MessageActionCreators';
import Api from '@/api/Api';
import AuthStore from '@/stores/AuthStore';
import CLButton from '@/components/library/button/CLButton';
import CLIconButton, { TYPE } from '@/components/library/button/CLIconButton';
import Constants from '@/constants';
import Icon from '@/components/shared/icon/Icon';
import LibraryCitationIntersectionStore from '@/stores/LibraryCitationIntersectionStore';
import LibraryFolderStore from '@/stores/LibraryFolderStore';
import LibraryPageStore from '@/stores/LibraryPageStore';
import MessageStore from '@/stores/MessageStore';
import ResearchListSelectionStore from '@/stores/ResearchListSelectionStore';
import S2Dispatcher from '@/utils/S2Dispatcher';
import S2History from '@/utils/S2History';
import softError from '@/utils/softError';

import classnames from 'classnames';
import idx from 'idx';
import PropTypes from 'prop-types';
import React from 'react';

const LIBRARY_FOLDER_ROUTE = 'LIBRARY_FOLDER';
const LIBRARY_ALL_ENTRIES_ROUTE = 'LIBRARY_ALL_ENTRIES';
const LIBRARY_UNSORTED_ENTRIES_ROUTE = 'LIBRARY_UNSORTED_ENTRIES';
const READER_ROUTE = 'READER';

export default class RemoveFromLibraryButton extends React.PureComponent {
  static contextTypes = {
    authStore: PropTypes.instanceOf(AuthStore).isRequired,
    api: PropTypes.instanceOf(Api).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    history: PropTypes.instanceOf(S2History).isRequired,
    libraryCitationIntersectionStore: PropTypes.instanceOf(LibraryCitationIntersectionStore)
      .isRequired,
    libraryPageStore: PropTypes.instanceOf(LibraryPageStore).isRequired,
    libraryFolderStore: PropTypes.instanceOf(LibraryFolderStore).isRequired,
    messageStore: PropTypes.instanceOf(MessageStore).isRequired,
    researchListSelectionStore: PropTypes.instanceOf(ResearchListSelectionStore).isRequired,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      ...this.getStateFromLibraryFolderStore(),
      ...this.getStateFromLibraryPageStore(),
    };

    this.context.libraryFolderStore.registerComponent(this, () => {
      this.setState(this.getStateFromLibraryFolderStore());
    });

    this.context.libraryPageStore.registerComponent(this, () => {
      this.setState(this.getStateFromLibraryPageStore());
    });
  }

  _onClickLibraryAnalytics;

  setAnalyticsCallbacks = ({ onClickLibrary }) => {
    this._onClickLibraryAnalytics = onClickLibrary;
  };

  getStateFromLibraryFolderStore = () => {
    const { libraryFolderStore } = this.context;
    const { paperId } = this.props;

    return {
      foldersWithPaperId: libraryFolderStore.getFoldersWithPaperId(paperId),
    };
  };

  getStateFromLibraryPageStore() {
    const { libraryPageStore, history } = this.context;
    const currentQuery = libraryPageStore.getCurrentQuery()
      ? libraryPageStore.getCurrentQuery()
      : getLibraryEntriesQueryFromPath(history.location.search);

    return {
      currentQuery,
    };
  }

  async createLibraryEntry({ paperId, paperTitle, folderIds }) {
    const { api } = this.context;

    await api.createLibraryEntryBulk(
      getAddLibraryEntryBulkRequestFromJS({
        paperId: paperId,
        paperTitle: paperTitle,
        folderIds: Array.from(folderIds),
        sourceType: Library,
      })
    );
  }

  updateLibraryCitationIntersectionStore = () => {
    const { api, libraryCitationIntersectionStore } = this.context;
    const paperIds = libraryCitationIntersectionStore.getCitingPaperIdsOfSharedLibraryCitations();
    api.fetchSharedCitationsWithLibraryForPaperIds(paperIds);
  };

  deleteLibraryEntry = async () => {
    const { api } = this.context;
    const { paperId } = this.props;
    await api.deleteLibraryEntriesBulk(paperId);
  };

  // foldersWithPaperId is passed in so that when the store updates the folders after the
  // delete action, we still have a reference to the folders that the entry was in to use in the
  // undo action
  undoDelete = foldersWithPaperId => event => {
    event.preventDefault();
    const { paperId, paperTitle } = this.props;
    const { messageStore } = this.context;

    this.createLibraryEntry({
      paperId: paperId,
      paperTitle: paperTitle,
      folderIds: foldersWithPaperId.map(folder => folder.id),
    }).then(() => {
      messageStore.clearMessages();
      this.refreshEntries();
    });
  };

  // refresh list of papers so the new entry is sorted accordingly if on library page
  refreshEntries = () => {
    const { currentQuery } = this.state;
    const { api, history } = this.context;

    const activeRoute = getRouteNameForPath(history.location.pathname);
    const query = LibraryEntriesQueryRecordFactory({
      pageSize: LIBRARY_DEFAULT_PAGE_SIZE,
      page: idx(currentQuery, _ => _.page) || 1,
      q: idx(currentQuery, _ => _.q) || '',
      sort: idx(currentQuery, _ => _.sort) || LIBRARY_DEFAULT_SORT,
    });

    switch (activeRoute) {
      case LIBRARY_FOLDER_ROUTE: {
        const [libraryFolderIdStr] = getParamsForRoute(
          LIBRARY_FOLDER_ROUTE,
          history.location.pathname
        );
        api.getLibraryEntriesByFolderId(parseInt(libraryFolderIdStr, 10), query);
        break;
      }
      case LIBRARY_ALL_ENTRIES_ROUTE:
        api.getAllLibraryEntries(query);
        break;
      case LIBRARY_UNSORTED_ENTRIES_ROUTE:
        api.getUnsortedEntries(query);
        break;
      case READER_ROUTE:
        this.updateLibraryCitationIntersectionStore();
        break;
      default:
        break;
    }
  };

  onClickDelete = event => {
    event.preventDefault();
    const { listId, paperId, onSaveClick } = this.props;
    const { dispatcher, messageStore, researchListSelectionStore } = this.context;
    const { foldersWithPaperId } = this.state;
    if (
      listId &&
      researchListSelectionStore.isPaperSelected({
        listId: listId,
        paperId: paperId,
      })
    ) {
      dispatcher.dispatch({
        actionType: Constants.actions.RESEARCH_LIST_DELETE,
        listId: listId,
        paperId: paperId,
      });
    }
    if (typeof onSaveClick === 'function') {
      onSaveClick();
    }

    this.deleteLibraryEntry()
      .then(() => {
        const header = getString(_ => _.library.removeFromLibrary.successHeader);
        const undoButton = (
          <CLButton
            type={TYPE.TERTIARY}
            label={getString(_ => _.library.removeFromLibrary.undoLabel)}
            {...heapLibraryRemoveFromLibraryUndoButton()}
            onClick={event => this.undoDelete(foldersWithPaperId)(event)}
            className="remove-from-library__undo-button"
            testId="undo-remove-from-library"
          />
        );
        const successText = this.getPaperInFoldersSuccessText(foldersWithPaperId);
        messageStore.addSuccess(successText, header, undoButton);
      })
      .catch(error => {
        softError('library', `failed to remove paper from library paperId="${paperId}"]`, error);

        const header = getString(_ => _.library.message.deleteError.header);
        const body = getString(_ => _.library.message.deleteError.body);
        const messagePayload = showPageErrorMessage({
          extraContent: null,
          text: body,
          title: header,
        });
        dispatcher.dispatch(messagePayload);
      });
  };

  /*
 * Creates the text description in the toast when removing a paper form the library based on
 *  the number of folders the paper is in
  None = 0 folder
  This paper was in: 1 folder = 1 folder
  This paper was in: 1 folder, 2 folder = 2 folders
  This paper was in: 1 folder, 2 folder, +1 more folder. = 3 folders
  This paper was in: 1 folder, 2 folder, +x more folders. = > 3 folders
 */
  getPaperInFoldersSuccessText = foldersWithPaperId => {
    const numberOfFolders = foldersWithPaperId.size;

    switch (numberOfFolders) {
      case 0:
        return null;
      case 1:
        return getString(
          _ => _.library.removeFromLibrary.successSubHeaderOneFolder,
          foldersWithPaperId.get(0).name
        );
      case 2:
        return getString(
          _ => _.library.removeFromLibrary.successSubHeaderTwoFolders,
          foldersWithPaperId.get(0).name,
          foldersWithPaperId.get(1).name
        );
      case 3:
        return getString(
          _ => _.library.removeFromLibrary.successSubHeaderThreeFolders,
          foldersWithPaperId.get(0).name,
          foldersWithPaperId.get(1).name
        );
      default:
        return getString(
          _ => _.library.removeFromLibrary.successSubHeaderMoreFolders,
          foldersWithPaperId.get(0).name,
          foldersWithPaperId.get(1).name,
          numberOfFolders - 2
        );
    }
  };

  render() {
    const { className, heapProps, buttonLabel } = this.props;
    const label = buttonLabel
      ? buttonLabel
      : getString(_ => _.library.removeFromLibrary.buttonLabel);

    return (
      <AnalyticsContextHelper onUpdate={this.setAnalyticsCallbacks}>
        <CLIconButton
          type={TYPE.TERTIARY}
          label={label}
          icon={<Icon icon="remove-bookmark" height="11" width="11" />}
          onClick={this.onClickDelete}
          className={classnames('cl-paper-action__button', className)}
          {...(heapProps ? addHeapProps(heapProps) : {})}
          testId="remove-from-library-button"
        />
      </AnalyticsContextHelper>
    );
  }
}
