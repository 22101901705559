import BaseStore from './BaseStore';

import { ApiRequestStartingPayload } from '@/api/BaseApi';
import {
  ApiResponse,
  CitationIntersectionResponseBody,
  DeleteLibraryEntryBulkResponseBody,
} from '@/api/ApiResponse';
import { DEPRECATED__FlowOptional, hasValue } from '@/utils/types';
import { RemoveSharedCitationsForPaperPayload } from '@/actions/RemoveShareCitationsActionCreators';
import Constants from '@/constants'; // Reusing these constants for now
import S2Dispatcher from '@/utils/S2Dispatcher';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import Immutable from 'immutable';

export default class LibraryCitationIntersectionStore extends BaseStore {
  // stored for refetching sharedCitations
  #citingPaperIdsOfSharedLibraryCitations: Immutable.List<string>;
  #storeState: StoreStateValue;
  #sharedCitations: Immutable.Map<number, Immutable.List<number>>;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#storeState = StoreState.UNINITIALIZED;
    this.#sharedCitations = Immutable.Map();

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const apiStartingPayload = payload as ApiRequestStartingPayload;
          switch (apiStartingPayload.requestType) {
            case Constants.requestTypes.PDF_DATA: {
              this.#storeState = StoreState.LOADING;
              this.#sharedCitations = Immutable.Map();
              this.emitChange();
              break;
            }

            case Constants.requestTypes.GET_SHARED_LIBRARY_CITATIONS_FOR_PAPER_IDS: {
              this.#citingPaperIdsOfSharedLibraryCitations = Immutable.List(
                apiStartingPayload.requestData.paperIds
              );
              this.#storeState = StoreState.LOADING;
              this.#sharedCitations = Immutable.Map();
              this.emitChange();
              break;
            }

            case Constants.requestTypes.DELETE_LIBRARY_ENTRIES_BULK: {
              this.#storeState = StoreState.LOADING;
              this.emitChange();
              break;
            }
          }
          break;
        }

        case Constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.GET_SHARED_LIBRARY_CITATIONS_FOR_PAPER_IDS: {
              this.#storeState = StoreState.LOADED;
              const citedByLibraryResponse =
                apiResponse as ApiResponse<CitationIntersectionResponseBody>;
              this.#sharedCitations = Immutable.Map(
                citedByLibraryResponse.resultData.citedToCitingCorpusIds
              ).mapEntries(([k, v]) => [parseInt(k), Immutable.List(v)]);
              this.emitChange();
              break;
            }

            case Constants.requestTypes.DELETE_LIBRARY_ENTRIES_BULK: {
              this.#storeState = StoreState.LOADED;
              const deleteLibraryEntryBulkResponse =
                apiResponse as ApiResponse<DeleteLibraryEntryBulkResponseBody>;
              const deletedCorpusId = deleteLibraryEntryBulkResponse.resultData.corpusId;
              if (hasValue(deletedCorpusId)) {
                for (const [citedCorpusId, citingCorpusIds] of this.#sharedCitations) {
                  if (citingCorpusIds.includes(deletedCorpusId)) {
                    const newList = citingCorpusIds.filter(corpusId => corpusId != deletedCorpusId);
                    this.#sharedCitations = this.#sharedCitations.set(citedCorpusId, newList);
                  }
                }
              }
              this.emitChange();
              break;
            }

            case Constants.requestTypes.LOGOUT: {
              this.#storeState = StoreState.UNINITIALIZED;
              this.#sharedCitations = Immutable.Map();
              this.#citingPaperIdsOfSharedLibraryCitations = Immutable.List();
              this.emitChange();
              break;
            }
          }
          break;
        }

        case Constants.actions.REMOVE_SHARED_CITATIONS_FOR_PAPER: {
          const removeSharedCitationsForPaperPayload =
            payload as RemoveSharedCitationsForPaperPayload;
          const { corpusId } = removeSharedCitationsForPaperPayload;
          for (const [key, value] of this.#sharedCitations) {
            if (value.includes(corpusId)) {
              const newList = Immutable.List(value.toArray().filter(e => e !== corpusId));
              this.#sharedCitations = this.#sharedCitations.set(key, newList);
              break;
            }
          }
          this.emitChange();
          break;
        }

        case Constants.actions.API_REQUEST_FAILED: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.PDF_DATA:
            case Constants.requestTypes.GET_SHARED_LIBRARY_CITATIONS_FOR_PAPER_IDS: {
              this.#storeState = StoreState.ERROR;
              this.#sharedCitations = Immutable.Map();
              this.emitChange();
              return;
            }
          }
          return;
        }
      }
    });
  }

  isUninitialized(): boolean {
    return this.#storeState === StoreState.UNINITIALIZED;
  }

  isLoading(): boolean {
    return this.isUninitialized() || this.#storeState === StoreState.LOADING;
  }

  isFailed(): boolean {
    return this.#storeState === StoreState.ERROR;
  }

  getCitingCorpusIdsForCitedCorpusId(
    citedCorpusId: number
  ): DEPRECATED__FlowOptional<Immutable.List<number>> {
    if (!this.#sharedCitations) {
      return null;
    }
    return this.#sharedCitations.get(citedCorpusId);
  }

  getCitingPaperIdsOfSharedLibraryCitations(): Immutable.List<string> {
    return this.#citingPaperIdsOfSharedLibraryCitations;
  }

  isPaperCitedByLibrary(corpusId: number): boolean {
    if (!this.#sharedCitations) {
      return false;
    }
    return this.#sharedCitations.has(corpusId) && !!this.#sharedCitations.get(corpusId)?.size;
  }

  getSharedCitations(): Immutable.Map<number, Immutable.List<number>> {
    return this.#sharedCitations;
  }
}
