import React from 'react';

export default function IconPaperSearch(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 131 147">
      <path
        d="M102.96 22V2"
        stroke="#F5F6F7"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.32 26.99L77 16.99"
        stroke="#F5F6F7"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.32 36.97L77 46.97"
        stroke="#F5F6F7"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M102.96 41.96V61.96"
        stroke="#F5F6F7"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.61 36.97L128.93 46.97"
        stroke="#F5F6F7"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.61 26.99L128.93 16.99"
        stroke="#F5F6F7"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M91 137H6V27H60L91 58V137Z" fill="#5492EF" />
      <path
        d="M49.63 23H4C2.9 23 2 23.9 2 25V131C2 132.1 2.9 133 4 133H85C86.1 133 87 132.1 87 131V50.38L59.63 23V50.38C59.63 51.48 60.53 52.38 61.63 52.38H77"
        stroke="#0F3875"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.0978 101.533C85.2492 92.1742 85.8917 76.3581 76.5329 66.2067C67.1741 56.0554 51.358 55.4129 41.2067 64.7717C31.0553 74.1305 30.4128 89.9465 39.7716 100.098C49.1304 110.249 64.9465 110.892 75.0978 101.533Z"
        fill="#5492EF"
        stroke="#11223D"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.3196 94.1807C74.4104 88.5654 74.7959 79.0758 69.1806 72.9849C63.5654 66.8941 54.0757 66.5086 47.9849 72.1239C41.8941 77.7392 41.5086 87.2288 47.1239 93.3196C52.7392 99.4104 62.2288 99.7959 68.3196 94.1807Z"
        fill="white"
        stroke="#11223D"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.228 138.466L108.817 142.533C108.008 143.279 106.736 143.227 105.991 142.419L78.2 112.274C77.4544 111.466 77.5061 110.194 78.3148 109.448L82.7262 105.381C83.5349 104.636 84.8067 104.687 85.5523 105.496L113.343 135.64C114.089 136.449 114.037 137.721 113.228 138.466Z"
        fill="#5492EF"
        stroke="#11223D"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </symbol>
  );
}
