import RecommendationsListFolder from './RecommendationsListFolder';

import { getString } from '@/content/i18n';
import { On } from '@/models/library/RecommendationStatus';
import Arrow from '@/components/shared/icon/Arrow';
import CLCardContainer from '@/components/library/container/CLCardContainer';
import Icon from '@/components/shared/icon/Icon';
import LibraryFolderStore from '@/stores/LibraryFolderStore';
import LibraryRecommendationsStore from '@/stores/LibraryRecommendationsStore';

import Immutable from 'immutable';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

export default class RecommendationsList extends React.PureComponent {
  static contextTypes = {
    libraryFolderStore: PropTypes.instanceOf(LibraryFolderStore).isRequired,
    libraryRecommendationsStore: PropTypes.instanceOf(LibraryRecommendationsStore).isRequired,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      collapsedDayList: Immutable.List(),
      ...this.getStateFromLibraryFolderStore(),
      ...this.getStateFromLibraryRecommendationsStore(),
    };

    this.context.libraryFolderStore.registerComponent(this, () => {
      this.setState(this.getStateFromLibraryFolderStore());
    });

    this.context.libraryRecommendationsStore.registerComponent(this, () => {
      this.setState(this.getStateFromLibraryRecommendationsStore());
    });
  }

  getStateFromLibraryFolderStore() {
    const { libraryFolderStore } = this.context;
    const folders = libraryFolderStore.getFoldersWithRecommendationStatus(On);
    return {
      folders,
    };
  }

  getStateFromLibraryRecommendationsStore() {
    const { libraryRecommendationsStore } = this.context;

    return {
      recsDaysList: libraryRecommendationsStore.getRecommendationsByDay(),
      visibleFolderIds: libraryRecommendationsStore.getVisibleFolderIds(),
      nextWindowUTC: libraryRecommendationsStore.getNextWindowUTC(),
    };
  }

  toggleCollapsible = dayValue => {
    const { collapsedDayList } = this.state;
    if (collapsedDayList.includes(dayValue)) {
      this.setState({
        collapsedDayList: collapsedDayList.filter(e => e !== dayValue),
      });
    } else {
      this.setState({
        collapsedDayList: collapsedDayList.push(dayValue),
      });
    }
  };

  renderNone = () => {
    const { visibleFolderIds } = this.state;
    return (
      <CLCardContainer className="recommendations__list__empty">
        <Icon
          width="220"
          height="220"
          icon={'empty-folder'}
          data-test-id="recommendations__list__empty-icon"
        />
        {visibleFolderIds.isEmpty() ? (
          <h2
            className="recommendations__list-heading__no-folders"
            data-test-id="recommendations__list-heading__no-folders">
            {getString(_ => _.library.recommendationsList.noFoldersSelected)}
          </h2>
        ) : (
          <React.Fragment>
            <h2
              className="recommendations__list-heading__empty-response"
              data-test-id="recommendations__list-heading__empty-response">
              {getString(_ => _.library.recommendationsList.noPapersForFilter)}
            </h2>
            {getString(_ => _.library.recommendationsList.noPapersCallToAction)}
          </React.Fragment>
        )}
      </CLCardContainer>
    );
  };

  render() {
    const { recsDaysList, visibleFolderIds, collapsedDayList, folders } = this.state;

    if (recsDaysList.isEmpty()) {
      return this.renderNone();
    }

    return (
      <CLCardContainer className="recommendations__list">
        {recsDaysList
          .filter(day => !!day.papers && !day.papers.isEmpty())
          .map(day => (
            <div className="recommendations__list__day" key={day.date}>
              <h2 className="recommendations__list__day_heading">
                <Arrow
                  ariaProps={{
                    'aria-expanded': !collapsedDayList.includes(day.date),
                  }}
                  testId="recommendations__list__arrow"
                  className="recommendations__list__arrow"
                  onClick={() => this.toggleCollapsible(day.date)}
                  direction={
                    !collapsedDayList.includes(day.date)
                      ? Arrow.Direction.DOWN
                      : Arrow.Direction.RIGHT
                  }
                />
                <span className="screen-reader-only">
                  {getString(_ => _.library.recommendationsList.recommendationsFor)}
                </span>
                {iso8601ToRelativeDate(day.date)}
              </h2>
              {!collapsedDayList.includes(day.date) &&
                folders.map(
                  folder =>
                    visibleFolderIds.has(folder.id) && (
                      <RecommendationsListFolder
                        key={[day, folder.id].join('_')}
                        folder={folder}
                        papers={day.papers}
                        day={day.date}
                      />
                    )
                )}
            </div>
          ))}
      </CLCardContainer>
    );
  }
}

// Take the ISO 8601 date string that we get from the recommendations service for "days" and
// convert that to a string of appropriate form for display (e.g. "2021-09-08" is reformated
// "8 September 2021")
export function formatDay(dayValue) {
  return moment(dayValue, 'YYYY-MM-DD').format('DD MMMM YYYY');
}

// Take the string format of the format day and transform it for display
// if the dayValue is the same day then it will be display Today
// if its yesterday than the value would be display Yesterday
// any other value will be display just as the value of the formatDay function
export function iso8601ToRelativeDate(dayValue) {
  const day = moment(dayValue);
  if (day.isSame(moment(), 'day')) {
    return getString(_ => _.library.recommendationsList.today);
  }
  if (day.isSame(moment().subtract(1, 'day'), 'day')) {
    return getString(_ => _.library.recommendationsList.yesterday);
  }
  return formatDay(dayValue);
}
