import classnames from 'classnames';
import React from 'react';

const ResearchAdContainer = ({ headerText, subText, className, children, isResearchFTUE }) => {
  const classes = classnames('research-ad-container', className);
  return (
    <div>
      <div className={classes}>
        <div className="research-ad-container__content">
          <div className="research-ad-container__content__text">
            <p className="research-ad-container__content__header-text">{headerText}</p>
            <p className="research-ad-container__content__sub-text">{subText}</p>
          </div>
          <div className="research-ad-container__content__right-column">
            {/* content you want displayed on the far right of the container */}
            {children}
          </div>
        </div>

        {isResearchFTUE && <div className="research-ad-container__circle" />}
      </div>
    </div>
  );
};

export default ResearchAdContainer;
