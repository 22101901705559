import { useAppContext, useStateFromStore } from '@/AppContext';

import classnames from 'classnames';
import React from 'react';

export default function AppPage({ children, className, ...otherProps }) {
  const kids = React.Children.toArray(children);
  const header = kids.find(_ => _.type === AppPageHeader);
  const footer = kids.find(_ => _.type === AppPageFooter);
  const excludeComps = [header, footer];
  const content = kids.filter(comp => !excludeComps.includes(comp));

  const { envInfo, modalStore, shelfStore } = useAppContext();

  const isModalOpen = useStateFromStore(modalStore, store => {
    return !!store.getVisibleModalId();
  });

  const isShelfOpen = useStateFromStore(shelfStore, store => {
    return !!store.getVisibleShelfId();
  });

  // Hides the app page from screen readers users when a modal or shelf is open
  // to trap focus within the modal or shelf
  const ariaHiddenProp = isModalOpen || isShelfOpen ? true : undefined;

  return (
    <div
      {...otherProps}
      aria-hidden={ariaHiddenProp}
      className={classnames(
        {
          'app-page': true,
          // Global .is-desktop and .is-mobile, which should be called out as hacks
          'HACK__is-desktop': !envInfo.isMobile,
          'HACK__is-mobile': envInfo.isMobile,
        },
        className
      )}>
      {header && (
        <div key="header" className="app-page__header">
          {header}
        </div>
      )}

      <div key="content" className="app-page__content">
        {content}
      </div>

      {footer && (
        <div key="footer" className="app-page__footer">
          {footer}
        </div>
      )}
    </div>
  );
}

export const AppPageHeader = ({ children }) => {
  return children;
};

export const AppPageContent = ({ children }) => {
  return children;
};

export const AppPageFooter = ({ children }) => {
  return children;
};
