import BaseStore from './BaseStore';

import { ApiRequestStartingPayload } from '@/api/BaseApi';
import { ApiResponse, GetUserContactResponseBody } from '@/api/ApiResponse';
import { getUserContactFromJS, UserContactRecord } from '@/models/user/UserContact';
import { Nullable } from '@/utils/types';
import Constants from '@/constants';
import S2Dispatcher from '@/utils/S2Dispatcher';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

export default class UserContactStore extends BaseStore {
  #state: StoreStateValue;
  #userContact: Nullable<UserContactRecord>;
  dispatchToken: string;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#state = StoreState.UNINITIALIZED;

    this.dispatchToken = dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const apiStartingPayload = payload as ApiRequestStartingPayload;
          switch (apiStartingPayload.requestType) {
            case Constants.requestTypes.GET_USER_CONTACT: {
              this.#state = StoreState.LOADING;
              this.#userContact = null;
              this.emitChange();
              break;
            }
          }
          break;
        }

        case Constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.GET_USER_CONTACT:
            case Constants.requestTypes.USER_CONTACT: {
              const userContactInfoApiResponse = payload as ApiResponse<GetUserContactResponseBody>;
              const rawUserContact = userContactInfoApiResponse.resultData;
              if (rawUserContact) {
                this.#userContact = getUserContactFromJS(rawUserContact);
              }
              this.#state = StoreState.LOADED;
              this.emitChange();
              break;
            }
          }
          break;
        }

        case Constants.actions.API_REQUEST_FAILED: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.GET_USER_CONTACT: {
              // 404 indicates there's no contact associated with this user yet
              if (apiResponse.responseStatus === 404) {
                // mark store as loaded, we'll use the default values for everything
                this.#state = StoreState.LOADED;
                this.emitChange();
              }
              break;
            }
          }
        }
      }
    });
  }

  isUninitialized(): boolean {
    return this.#state === StoreState.UNINITIALIZED;
  }

  isLoading(): boolean {
    return this.#state === StoreState.UNINITIALIZED || this.#state === StoreState.LOADING;
  }

  getUserContact(): Nullable<UserContactRecord> {
    return this.#userContact || null;
  }
}
