import React from 'react';
import Tooltip from 'rc-tooltip';

import type { ExampleConfig } from '@/components/library/ExampleUtils';
import type { Nullable, ValueOf } from '@/utils/types';

export type Props = React.PropsWithChildren<{
  tooltipContent: TooltipReactContent;
  placement: Placement;
  overlayClassName?: Nullable<string>;
  overlayStyle?: Nullable<React.CSSProperties>;
  trigger?: Nullable<TooltipTrigger[]>;
  id?: Nullable<string>;
  mouseEnterDelay?: Nullable<number>;
  mouseLeaveDelay?: Nullable<number>;
  defaultVisible?: Nullable<boolean>;
  getTooltipContainer?: Nullable<() => Element>;
  align?: Nullable<AlignConfig>;
  visible?: boolean | undefined;
}>;

// AlignConfig allows the tooltip placement to be adjusted in case the default behavior is not placing it in the correct spot.
// See documentation for further reference: https://github.com/yiminghe/dom-align#alignconfig-object-details
export type AlignConfig = {
  points: [string, string];
  offset: [number, number];
  targetOffset: [number, number];
  overflow: { adjustX: boolean; adjustY: boolean };
  useCssRight: boolean;
  useCssBottom: boolean;
  useCssTransform: boolean;
};

// This type matches the prop type from rc-tooltip
// nulls and undefineds are not supported
export type TooltipReactContent =
  | (() => React.ReactChild)
  | React.ReactChild
  | React.ReactFragment
  | React.ReactPortal
  | string;

export type Placement = ValueOf<typeof PLACEMENT>;
export type TooltipTrigger = ValueOf<typeof TOOLTIP_TRIGGER>;

const TOP = 'top';
const TOP_LEFT = 'topLeft';
const TOP_RIGHT = 'topRight';
const LEFT = 'left';
const LEFT_TOP = 'leftTop';
const LEFT_BOTTOM = 'leftBottom';
const RIGHT = 'right';
const RIGHT_TOP = 'rightTop';
const RIGHT_BOTTOM = 'rightBottom';
const BOTTOM = 'bottom';
const BOTTOM_LEFT = 'bottomLeft';
const BOTTOM_RIGHT = 'bottomRight';

export const PLACEMENT = {
  TOP,
  TOP_LEFT,
  TOP_RIGHT,
  LEFT,
  LEFT_TOP,
  LEFT_BOTTOM,
  RIGHT,
  RIGHT_TOP,
  RIGHT_BOTTOM,
  BOTTOM,
  BOTTOM_LEFT,
  BOTTOM_RIGHT,
} as const;

const CLICK = 'click';
const HOVER = 'hover';
const FOCUS = 'focus';

export const TOOLTIP_TRIGGER = {
  CLICK,
  HOVER,
  FOCUS,
} as const;

export default class CLTooltip extends React.Component<Props> {
  static defaultProps = {
    trigger: [TOOLTIP_TRIGGER.HOVER, TOOLTIP_TRIGGER.FOCUS],
  };

  render() {
    const {
      children,
      tooltipContent,
      overlayClassName,
      overlayStyle,
      placement,
      trigger,
      id,
      mouseEnterDelay,
      mouseLeaveDelay,
      defaultVisible,
      getTooltipContainer,
      align,
      visible,
    } = this.props;
    return (
      <Tooltip
        id={id || undefined}
        overlay={tooltipContent}
        overlayClassName={overlayClassName || undefined}
        overlayStyle={overlayStyle || undefined}
        placement={placement}
        trigger={trigger || undefined}
        mouseEnterDelay={mouseEnterDelay || undefined}
        mouseLeaveDelay={mouseLeaveDelay || undefined}
        defaultVisible={defaultVisible || undefined}
        align={align || undefined}
        prefixCls="cl-tooltip"
        getTooltipContainer={getTooltipContainer || undefined}
        destroyTooltipOnHide
        // this is a hack to get around the fact that rc-tooltip will always display the tooltip if undefined
        // so if you want to explicitly control the tooltip's visbility from the parent, you must pass in a boolean
        {...(typeof visible === 'boolean' ? { visible } : {})}>
        {children}
      </Tooltip>
    );
  }
}

export const exampleConfig: ExampleConfig = {
  getComponent: () => CLTooltip,
  fields: [
    {
      name: 'placement',
      desc: 'Position of the arrow around the box',
      value: {
        type: 'select',
        default: PLACEMENT.TOP,
        options: [
          PLACEMENT.TOP,
          PLACEMENT.TOP_LEFT,
          PLACEMENT.TOP_RIGHT,
          PLACEMENT.LEFT,
          PLACEMENT.LEFT_BOTTOM,
          PLACEMENT.LEFT_TOP,
          PLACEMENT.RIGHT,
          PLACEMENT.RIGHT_BOTTOM,
          PLACEMENT.RIGHT_TOP,
          PLACEMENT.BOTTOM,
          PLACEMENT.BOTTOM_LEFT,
          PLACEMENT.BOTTOM_RIGHT,
        ],
      },
    },
    {
      name: 'trigger',
      desc: 'Action that opens/closes the tooltip',
      value: {
        type: 'select',
        default: [TOOLTIP_TRIGGER.HOVER],
        options: [
          [TOOLTIP_TRIGGER.HOVER],
          [TOOLTIP_TRIGGER.CLICK],
          [TOOLTIP_TRIGGER.FOCUS],
          [TOOLTIP_TRIGGER.HOVER, TOOLTIP_TRIGGER.CLICK],
        ],
      },
    },
    {
      name: 'children',
      desc: 'Content to be displayed within the component',
      value: {
        type: 'jsx',
        default: '',
        // eslint-disable-next-line react/no-danger
        transform: html => <div dangerouslySetInnerHTML={{ __html: html }} />,
      },
    },
  ],

  examples: [
    {
      title: 'Tooltip',
      desc: 'Tooltip with arrow pointing at a something with controllable trigger behavior',
      props: {
        placement: TOP,
        children: `Hover over me!`,
        tooltipContent: `I'm a tooltip!`,
      },
      render: comp => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}>
          {comp}
        </div>
      ),
    },
    {
      title: 'Default Open',
      desc: 'Tooltip with "defaultVisible=true" so it mounts in the open state',
      props: {
        placement: TOP,
        children: `Hover over me!`,
        tooltipContent: `I'm a tooltip!`,
        defaultVisible: true,
      },
      render: comp => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}>
          {comp}
        </div>
      ),
    },
    {
      title: 'Locked Open (Top)',
      desc: 'Tooltip with "visible=true" forcing visibility of the tooltip',
      props: {
        placement: TOP,
        children: `Hover over me!`,
        tooltipContent: `I'm a tooltip!`,
        visible: true,
      },
      render: comp => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}>
          {comp}
        </div>
      ),
    },
    {
      title: 'Locked Open (Right)',
      desc: 'Tooltip with "visible=true" forcing visibility of the tooltip',
      props: {
        placement: RIGHT,
        children: `Hover over me!`,
        tooltipContent: `I'm a tooltip!`,
        visible: true,
      },
      render: comp => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}>
          {comp}
        </div>
      ),
    },
    {
      title: 'Locked Open (Bottom)',
      desc: 'Tooltip with "visible=true" forcing visibility of the tooltip',
      props: {
        placement: BOTTOM,
        children: `Hover over me!`,
        tooltipContent: `I'm a tooltip!`,
        visible: true,
      },
      render: comp => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}>
          {comp}
        </div>
      ),
    },
    {
      title: 'Locked Open (Left)',
      desc: 'Tooltip with "visible=true" forcing visibility of the tooltip',
      props: {
        placement: LEFT,
        children: `Hover over me!`,
        tooltipContent: `I'm a tooltip!`,
        visible: true,
      },
      render: comp => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}>
          {comp}
        </div>
      ),
    },
  ],

  events: {
    onMouseEnter: () => ({}),
    onMouseLeave: () => ({}),
  },
};
