import {
  getHighlightedFragmentFromJS,
  HighlightedFragmentFromJS,
  HighlightedFragmentRecord,
} from './HighlightedFragment';

import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

export type HighlightedFieldFromJS = {
  text: string;
  fragments?: Nullable<HighlightedFragmentFromJS[]>;
};

type HighlightedFieldRecordProps = {
  text: string;
  fragments: Immutable.List<HighlightedFragmentRecord>;
};

const defaultProps: HighlightedFieldRecordProps = {
  text: '',
  fragments: Immutable.List(),
};

export const HighlightedFieldRecordFactory =
  Immutable.Record<HighlightedFieldRecordProps>(defaultProps);

export type HighlightedFieldRecord = Immutable.RecordOf<HighlightedFieldRecordProps>;

export function getHighlightedFieldFromJS(args?: HighlightedFieldFromJS): HighlightedFieldRecord {
  if (!args) {
    return HighlightedFieldRecordFactory();
  }
  const fragments = Array.isArray(args.fragments)
    ? args.fragments.map(f => getHighlightedFragmentFromJS(f))
    : [];
  return HighlightedFieldRecordFactory({
    ...args,
    fragments: Immutable.List(fragments),
  });
}

const MIN_HIGHLIGHT_WORD_LENGTH = 2;

/**
 * A very basic JS implementation of highlighting.
 * Does a relatively naive case-insensitive search for word terms in the query text and returns their positions.
 * Doesn't handle things like normalizing special characters or playing with stop words so this will not match
 * the results from ElasticSearch 1:1.
 * @param {string} query the query to search for in the target text
 * @param {string} text the target text to be searched over
 */
export function highlightText({
  query,
  text,
}: {
  query: string;
  text: string;
}): HighlightedFieldRecord {
  const words = Immutable.Set(
    query
      .toLowerCase()
      .split(/\s+/)
      // limit to alpha numeric characters to avoid regex issues
      .map(_ => _.replace(/[^a-zA-Z0-9]/g, ''))
      .filter(_ => _ !== '' && _.length >= MIN_HIGHLIGHT_WORD_LENGTH)
  );
  const targetText = text.toLowerCase();

  const fragments = words
    .reduce((fragmentList, currentWord) => {
      let match: Nullable<RegExpExecArray>;
      const regex = new RegExp(currentWord, 'g');
      const matches: RegExpExecArray[] = [];

      while ((match = regex.exec(targetText))) {
        matches.push(match);
      }
      return fragmentList.concat(
        matches.map(match => ({
          start: match.index,
          end: match.index + match[0].length,
        }))
      );
    }, Immutable.List<HighlightedFragmentFromJS>())
    .sortBy(_ => _.start)
    .reduce((fragmentList, currentFragment) => {
      // combine overlapping fragments
      const lastFragment = fragmentList.get(-1);
      if (!lastFragment || lastFragment.end < currentFragment.start) {
        // if the current fragment is the first or doesn't overlap, add it to the list
        return fragmentList.push(currentFragment);
      } else {
        // otherwise it overlaps, merge it with the previous entry
        return fragmentList.update(-1, () => ({
          start: lastFragment.start,
          end: Math.max(currentFragment.end, lastFragment.end),
        }));
      }
    }, Immutable.List<HighlightedFragmentFromJS>())
    .toArray();

  return getHighlightedFieldFromJS({ text, fragments });
}
