import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

/**
 * Model for a filter option.
 * @param {string} id The id of the filter
 * @param {string} icon The icon associated with the filter.
 */
type Props = {
  id: string;
  icon: Nullable<string>;
};

const defaultProps: Props = {
  id: '',
  icon: null,
};

export const FilterRecordFactory = Immutable.Record<Props>(defaultProps);
export type FilterRecord = Immutable.RecordOf<Props>;
