/* eslint-disable max-lines-per-function */
import AuthorProfilePicture from './AuthorProfilePicture';
import VerifiedCheckmark from './VerifiedCheckmark';

import { AuthoredLibraryPaperCue, CueType } from '@/constants/CueType';
import { format } from '@/util';
import { getCueText } from '@/utils/personalized-cues-util';
import { getString } from '@/content/i18n';
import {
  heapAuthorNameLinkInPopoverClick,
  heapViewAuthorsPublicationsButton,
} from '@/analytics/attributes/authorCardPopover';
import { Nullable, ReactNodeish } from '@/utils/types';
import CLPopover, { ARROW_POSITION } from '@/components/library/popover/CLPopover';
import CLRule from '@/components/library/layout/CLRule';
import CuePapersList from '@/components/shared/paper/CuePapersList';
import EventTarget from '@/analytics/constants/EventTarget';
import FeatureDisabled from '@/components/util/features/FeatureDisabled';
import FeatureEnabled from '@/components/util/features/FeatureEnabled';
import FeatureGate from '@/components/util/features/FeatureGate';
import Icon from '@/components/shared/icon/Icon';
import Link from '@/router/Link';
import Shimmer from '@/components/shared/loading/Shimmer';
import ShowEvent from '@/analytics/models/ShowEvent';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import React from 'react';

import type { AuthorCardRecord } from '@/models/author/AuthorCard';
import type { PaperId } from '@/stores/CueStore';
import type { PaperishRecord } from '@/models/Paper';

const POPOVER_WIDTH = 500;
const VERIFIED_CHECKMARK_WIDTH = 14;

type Props = {
  authorCard?: Nullable<AuthorCardRecord>;
  isAuthorCardLoading: boolean;
  isAuthorCueLoading: boolean;
  className?: Nullable<string>;
  paper?: Nullable<PaperishRecord>;
  prioritizedCue: CueType;
  paperIds: Immutable.List<PaperId>;
  isAuthorPersonalized: boolean;
  onClick: (id: string) => void;
};

export default function AuthorCardPopover({
  authorCard,
  isAuthorCardLoading,
  isAuthorCueLoading,
  paper,
  prioritizedCue,
  paperIds,
  isAuthorPersonalized,
  onClick,
}: Props): ReactNodeish {
  React.useEffect(() => {
    if (authorCard) {
      const startTime = Date.now();

      return () => {
        const endTime = Date.now();
        const viewDuration = endTime - startTime;
        trackAnalyticsEvent(
          ShowEvent.create(EventTarget.AuthorCard.AUTHOR_CARD_VIEW, {
            authorId: authorCard.id,
            paperId: paper?.id,
            viewDuration: viewDuration,
            cueType: prioritizedCue,
          })
        );
      };
    }
  }, [authorCard]);

  if (!authorCard) {
    return null;
  }

  const cueTextInfo = paperIds ? getCueText(prioritizedCue, paperIds.size) : null;

  const { name, id, slug, affiliationNames, pronouns, isClaimed, profilePicture } = authorCard;

  const renderAuthorDetailsLoading = () => {
    return (
      <>
        <div className="flex-row">
          <div className="author-card-popover__profile-picture">
            <AuthorProfilePicture profilePicture={null} heightPx={66} widthPx={66} />
          </div>
          <div className="author-card-popover-loading__author-info">
            <Shimmer className="author-card-popover-loading__author-name" cssWidth="200px" />
            <Shimmer cssWidth="210px" />
          </div>
        </div>
      </>
    );
  };

  const renderAuthorDetails = () => {
    const affiliationValue = affiliationNames ? affiliationNames.join(', ') : '';

    return (
      <>
        <div className="flex-row">
          <FeatureGate feature={_ => _.AuthorProfilePicture}>
            <FeatureEnabled>
              <div className="author-card-popover__profile-picture">
                <AuthorProfilePicture
                  authorId={id.toString()}
                  slug={slug}
                  profilePicture={profilePicture}
                  altText={name}
                  heightPx={66}
                  widthPx={66}
                />
              </div>
            </FeatureEnabled>
            <FeatureDisabled>
              <div className="author-card-popover__profile-picture">
                <AuthorProfilePicture profilePicture={null} heightPx={66} widthPx={66} />
              </div>
            </FeatureDisabled>
          </FeatureGate>

          <div className="author-card-popover__author-info">
            <div className="author-card-popover__author-info__name">
              <h3 className="author-card-popover__author-info__header">
                {!!id && !!slug ? (
                  <Link
                    to="AUTHOR_PROFILE"
                    params={{ authorId: id, slug: slug }}
                    {...heapAuthorNameLinkInPopoverClick({
                      'author-id': id,
                    })}>
                    <span className="flex-row">
                      {name}
                      {renderVerifiedCheckmark()}
                    </span>
                  </Link>
                ) : (
                  name
                )}
              </h3>
            </div>

            {!!pronouns && <div className="author-card-popover__author-pronouns">{pronouns}</div>}
            {isClaimed && affiliationValue && (
              <div className="author-card-popover__author-affilitation">{affiliationValue}</div>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderAuthorStatsLoading = () => {
    return (
      <>
        <div className="author-card-popover-loading__author-stats">
          <div className="flex-column">
            <Shimmer
              className="author-card-popover-loading__author-stats-left-column"
              cssWidth="170px"
            />
            <Shimmer
              className="author-card-popover-loading__author-stats-left-column"
              cssWidth="180px"
            />
            <Shimmer
              className="author-card-popover-loading__author-stats-left-column"
              cssWidth="180px"
            />
            <Shimmer
              className="author-card-popover-loading__author-stats-last-row"
              cssWidth="180px"
            />
          </div>
          <div className="flex-column">
            <Shimmer
              className="author-card-popover-loading__author-stats-right-column"
              cssWidth="40px"
            />
            <Shimmer
              className="author-card-popover-loading__author-stats-right-column"
              cssWidth="40px"
            />
            <Shimmer
              className="author-card-popover-loading__author-stats-right-column"
              cssWidth="40px"
            />
            <Shimmer
              className="author-card-popover-loading__author-stats-last-row"
              cssWidth="40px"
            />
          </div>
        </div>
      </>
    );
  };

  const renderAuthorStats = () => {
    const { paperCount, hIndex, citationCount, influentialPaperCount } = authorCard;
    return (
      <div className="author-card-popover__author-stats-content">
        {!!paperCount && (
          <div className="author-card-popover__stats-row">
            <span className="author-card-popover__stats-row__label">
              {getString(_ => _.author.card.publications)}
            </span>
            <span className="author-card-popover__stats-row__value">{format(paperCount)}</span>
          </div>
        )}
        <div className="author-card-popover__stats-row">
          <Link className="author-card-popover__stats-row__label" to="FAQ_ROOT" hash="h-index">
            <>{getString(_ => _.author.card.hIndex)} </>
          </Link>

          <span className="author-card-popover__stats-row__value">{format(hIndex || 0)}</span>
        </div>
        <div className="author-card-popover__stats-row">
          <span className="author-card-popover__stats-row__label">
            {getString(_ => _.author.card.citations)}
          </span>
          <span className="author-card-popover__stats-row__value">
            {format(citationCount || 0)}
          </span>
        </div>
        <div className="author-card-popover__stats-row">
          <span className="author-card-popover__stats-row__label">
            {getString(_ => _.author.card.influentialCitations)}
          </span>
          <span className="author-card-popover__stats-row__value">
            {format(influentialPaperCount || 0)}
          </span>
        </div>
      </div>
    );
  };

  const renderVerifiedCheckmark = () => {
    const showVerifiedCheckmark = isClaimed && !!authorCard;

    if (isClaimed && showVerifiedCheckmark) {
      return (
        <div className="author-card-popover__verified-author-checkmark">
          <VerifiedCheckmark sizePx={VERIFIED_CHECKMARK_WIDTH} />
        </div>
      );
    }
  };

  const renderPersonalizedCues = () => {
    return (
      <>
        <div
          className="author-card-popover__personalized-cues-container"
          data-test-id="author-card-popover__personalized-cues">
          {isAuthorCueLoading ? (
            <div className="author-card-popover-loading__personalized-cues-title">
              <Shimmer cssWidth="232px" />
              <Shimmer cssWidth="386px" />
            </div>
          ) : (
            <>
              <div className="author-card-popover__personalized-cues-header">
                <Icon
                  icon="personalization-small"
                  width="14"
                  height="14"
                  className="author-card-popover__personalized-cues-header__icon"
                />
                <div className="author-card-popover__personalized-cues-header__text">
                  {cueTextInfo?.cueLabel}
                </div>
              </div>
              <div className="author-card-popover__personalized-cues-subheader">
                <p className="author-card-popover__personalized-cues-subheader__cue-description">
                  {cueTextInfo?.cueDescription}
                </p>
                <span
                  className="author-card-popover__personalized-cues-subheader__bullet"
                  aria-hidden="true"
                />
                <Link
                  to="FAQ_ROOT"
                  hash="why-are-some-papers-annotated-with-a-thumbprint-icon-how-do-these-work">
                  <>
                    {getString(_ => _.personalizedCue.learnMore)}
                    <Icon
                      icon="arrow-lite"
                      width="12"
                      height="8"
                      className="author-card-popover__personalized-cues-subheader__arrow-icon"
                    />
                  </>
                </Link>
              </div>
            </>
          )}
          <CuePapersList
            prioritizedCue={prioritizedCue}
            paperIds={paperIds}
            authorId={id.toString()}
            className="author-card-popover__personalized-cues-content"
            showDefaultLoading={prioritizedCue !== AuthoredLibraryPaperCue}
          />
        </div>
        <CLRule className="author-card-popover__divider" />
      </>
    );
  };

  return (
    <CLPopover
      arrow={ARROW_POSITION.SIDE_TOP_POS_LEFT}
      className="author-card-popover__content-container"
      widthPx={POPOVER_WIDTH}>
      <div className="author-card-popover__author-content-container">
        {isAuthorCardLoading ? renderAuthorDetailsLoading() : renderAuthorDetails()}
      </div>
      <CLRule className="author-card-popover__divider" />
      <div className="author-card-popover__author-stats-container">
        {isAuthorCardLoading ? renderAuthorStatsLoading() : renderAuthorStats()}
      </div>
      <CLRule className="author-card-popover__divider" />

      {isAuthorPersonalized && !!cueTextInfo ? renderPersonalizedCues() : null}

      <Link
        to="AUTHOR_PROFILE"
        params={{ authorId: id, slug: slug }}
        className="author-card-popover__view-author-button"
        {...heapViewAuthorsPublicationsButton({
          'author-id': id,
        })}
        onClick={() => onClick(id.toString())}>
        <>
          {getString(_ => _.author.card.viewAuthorsPublicationsButton)}
          <Icon
            icon="arrow-lite"
            width="12"
            height="8"
            className="author-card-popover__view-author-button__arrow-icon"
          />
        </>
      </Link>
    </CLPopover>
  );
}
