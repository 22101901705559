import { isBrowser, isTrackingAllowedOnBrowser, isTrackingAllowedOnServer } from './utils/env';
import AccountNavStore from './stores/AccountNavStore';
import AlertsStore from './stores/AlertsStore';
import Api from './api/Api';
import ApiErrorStore from './stores/ApiErrorStore';
import AuthApi from './api/AuthApi';
import AuthorCardStore from './stores/author/AuthorCardStore';
import AuthorCitingAuthorsStore from './stores/author/AuthorCitingAuthorsStore';
import AuthorClaimModerationStore from './stores/AuthorClaimModerationStore';
import AuthorCoAuthorsStore from './stores/author/AuthorCoAuthorsStore';
import AuthorCorrectionsStore from './stores/author/AuthorCorrectionsStore';
import AuthorInfluenceStatsStore from './stores/author/AuthorInfluenceStatsStore';
import AuthorProfileStore from './stores/author/AuthorProfileStore';
import AuthorQueryStore from './stores/AuthorQueryStore';
import AuthorReferencedAuthorsStore from './stores/author/AuthorReferencedAuthorsStore';
import AuthorshipModerationStore from './stores/moderation/AuthorshipModerationStore';
import AuthorStatsStore from './stores/author/AuthorStatsStore';
import AuthorStore from './stores/author/AuthorStore';
import AuthStore from './stores/AuthStore';
import BundlePreloader from './utils/BundlePreloader';
import CitationQueryStore from './stores/CitationQueryStore';
import CookieJar from './utils/cookies/CookieJar';
import CorrectionsStore from './stores/CorrectionsStore';
import CueStore from './stores/CueStore';
import EnrollmentsStore from './stores/EnrollmentsStore';
import EntityStore from './stores/EntityStore';
import EnvInfo from './env/EnvInfo';
import ExperimentsStore from './stores/ExperimentsStore';
import FeatureFlagStore from './stores/FeatureFlagStore';
import GraphQLApi from './api/GraphQLApi';
import HistoryStore from './stores/HistoryStore';
import IconStore from './stores/IconStore';
import IndexMetadataStore from './stores/IndexMetadataStore';
import LibraryCitationIntersectionStore from './stores/LibraryCitationIntersectionStore';
import LibraryEntryStore from './stores/LibraryEntryStore';
import LibraryFolderStore from './stores/LibraryFolderStore';
import LibraryPageStore from './stores/LibraryPageStore';
import LibraryRecommendationsStore from './stores/LibraryRecommendationsStore';
import logger from './logger';
import MessageStore from './stores/MessageStore';
import ModalStore from './stores/ModalStore';
import PaperLabsStore from './stores/PaperLabsStore';
import PaperNavStore from './stores/PaperNavStore';
import PaperStore from './stores/PaperStore';
import QueryStore from './stores/QueryStore';
import ReaderAcronymsStore from './stores/reader/ReaderAcronymsStore';
import ReaderCitationStore from './stores/reader/ReaderCitationStore';
import ReaderInteractionsStore from './stores/reader/ReaderInteractionsStore';
import ReaderPdfStore from './stores/reader/ReaderPdfStore';
import ReaderSkimmingStore from './stores/reader/ReaderSkimmingStore';
import ReaderTermDefinitionsStore from './stores/reader/ReaderTermDefinitionsStore';
import ReaderTermsStore from './stores/reader/ReaderTermsStore';
import ReaderVisibilityStore from './stores/reader/ReaderVisibilityStore';
import ReaderWidgetStore from './stores/ReaderWidgetStore';
import ReferenceQueryStore from './stores/ReferenceQueryStore';
import ResearchHomepageStore from './stores/ResearchHomepageStore';
import ResearchListSelectionStore from './stores/ResearchListSelectionStore';
import S2Dispatcher from './utils/S2Dispatcher';
import S2History, { getBrowserS2History, getServerS2History } from './utils/S2History';
import SampleQueriesStore from './stores/SampleQueriesStore';
import SharedLibraryFolderStore from './stores/SharedLibraryFolderStore';
import ShelfStore from './stores/ShelfStore';
import UIOptionsStore from './stores/UIOptionsStore';
import UserContactStore from './stores/UserContactStore';
import UserPreferencesStore from './stores/UserPreferencesStore';
import UserSettingStore from './stores/UserSettingStore';
import VenueQueryStore from './stores/VenueQueryStore';
import VenueStore from './stores/VenueStore';
import WeblabStore from './weblab/WeblabStore';

import idx from 'idx';
import invariant from 'invariant';
import PropTypes from 'prop-types';
import React from 'react';

import type { Nullable, RequestWithMiddleware } from './utils/types';
import type BaseStore from './stores/BaseStore';

export const AppReactContext = React.createContext<Nullable<AppContext>>(null);

// wraps useContext and does runtime type checking
export function useAppContext(): AppContext {
  const appContext = React.useContext(AppReactContext);
  invariant(appContext, 'AppContext required');
  return appContext;
}

/**
 * Re-render a component when a store emits, yielding a new state
 *
 * Example: Render first name of user, updates when signing in/out
 *
 *   function FirstName(): React.Node {
 *     const { authStore } = useAppContext();
 *     const { user } = useStateFromStore(authStore, (store) => {
 *       return {
 *         user: store.getUser()
 *       };
 *     });
 *     return user ? (
 *       <div className="user-first-name">{user.firstName}</div>
 *     ) : null;
 *   }
 *
 * If you need to to use a value from one store (or props) in the onChange callback,
 * pass the value in as a dependency in the last argument, like this:
 *
 *   const { authorDetail } = useStateFromStore(authorStore, _ => _.getAuthorDetails());
 *   const authorStats = useStateFromStore(authorStatsStore, _ => (
 *     authorDetail ? _.getAuthorStats(authorDetail.author.id) : null
 *   ), [authorDetail]);
 */
export function useStateFromStore<TState, TStore extends BaseStore>(
  store: TStore,
  onChange: (store: TStore) => TState,
  deps: React.DependencyList = []
): TState {
  // The counter causes the state to be recomputed when the store emits a change.
  // We don't need the value, since it is in the mutable ref.
  const [, setStoreEmitCounter] = React.useState(0);

  // We use a ref so the count is mutable, which allows the callback to stay the
  // same, so we don't have to rely on useEffect() replacing it (see below)
  const emitCounterRef = React.useRef<number>(0);

  // Calculate the state only when the count changes, or when the dependencies change
  const state = React.useMemo<TState>(() => onChange(store), [...deps, emitCounterRef.current]);

  // React guarantees that the code inside useLayoutEffect and any state updates
  // scheduled inside it will be processed before the browser repaints the screen.
  // Using useLayoutEffect makes sure there will be a listener for the store to
  // emitChange() before components start rendering.
  React.useLayoutEffect(() => {
    const onStoreEmit = () => {
      emitCounterRef.current += 1;
      setStoreEmitCounter(emitCounterRef.current);
    };
    store.addChangeListener(onStoreEmit);
    return () => {
      store.removeChangeListener(onStoreEmit);
    };
  }, []);

  return state;
}

/**
 * Utility to map state from AppContext stores into component props in a generic way.
 * The goal is to make get rid of getStateFromStore() functions.
 *
 * Example: Author Page context props
 *
 * export default mapAppContextToProps<Props, TPropsFromAppContext>(AuthorPage, appContext => {
 *   const authorQueryStoreProps = useStateFromStore(appContext.authorQueryStore, _ => ({
 *     isLoading: _.isLoading(),
 *   }));
 *   const authorStoreProps = useStateFromStore(appContext.authorStore, _ => ({
 *     isLoading: _.isLoading(),
 *     author: _.getAuthorDetails(),
 *   }));
 *   return {
 *     ...authorQueryStoreProps,
 *     ...authorStoreProps,
 *     isLoading: authorQueryStoreProps.isLoading || authorStoreProps.isLoading,
 *   };
 * });
 */
export function mapAppContextToProps<TProps, TPropsFromAppContext>(
  Component: any,
  propMapper: (appContext: AppContext, passedProps: TProps) => TPropsFromAppContext
): React.FunctionComponent<Partial<TProps>> {
  return function AppContextMappedComponent(passedProps: TProps): React.ReactElement {
    const appContext = useAppContext();
    const mappedProps = propMapper(appContext, passedProps);
    const props = {
      ...mappedProps,
      ...passedProps,
    };
    return React.createElement(Component, props);
  };
}

class AppContext {
  accountNavStore: AccountNavStore;
  alertsStore: AlertsStore;
  api: Api;
  apiErrorStore: ApiErrorStore;
  authApi: AuthApi;
  authStore: AuthStore;
  authorCardStore: AuthorCardStore;
  authorCitingAuthorsStore: AuthorCitingAuthorsStore;
  authorCoAuthorsStore: AuthorCoAuthorsStore;
  authorCorrectionsStore: AuthorCorrectionsStore;
  authorInfluenceStatsStore: AuthorInfluenceStatsStore;
  authorStatsStore: AuthorStatsStore;
  authorStore: AuthorStore;
  authorProfileStore: AuthorProfileStore;
  authorQueryStore: AuthorQueryStore;
  authorReferencedAuthorsStore: AuthorReferencedAuthorsStore;
  authorshipModerationStore: AuthorshipModerationStore;
  bundlePreloader: BundlePreloader;
  citationQueryStore: CitationQueryStore;
  claimModerationStore: AuthorClaimModerationStore;
  cookieJar: CookieJar;
  correctionsStore: CorrectionsStore;
  cueStore: CueStore;
  dispatcher: S2Dispatcher;
  entityStore: EntityStore;
  envInfo: EnvInfo;
  enrollmentsStore: EnrollmentsStore;
  experimentsStore: ExperimentsStore;
  featureFlagStore: FeatureFlagStore;
  graphQLApi: GraphQLApi;
  history: S2History;
  historyStore: HistoryStore;
  iconStore: IconStore;
  indexMetadataStore: IndexMetadataStore;
  libraryEntryStore: LibraryEntryStore;
  libraryPageStore: LibraryPageStore;
  libraryFolderStore: LibraryFolderStore;
  libraryRecommendationsStore: LibraryRecommendationsStore;
  libraryCitationIntersectionStore: LibraryCitationIntersectionStore;
  messageStore: MessageStore;
  modalStore: ModalStore;
  paperLabsStore: PaperLabsStore;
  paperNavStore: PaperNavStore;
  paperStore: PaperStore;
  queryStore: QueryStore;
  readerPdfStore: ReaderPdfStore;
  readerAcronymsStore: ReaderAcronymsStore;
  readerCitationStore: ReaderCitationStore;
  readerInteractionsStore: ReaderInteractionsStore;
  readerSkimmingStore: ReaderSkimmingStore;
  readerTermsStore: ReaderTermsStore;
  readerTermDefinitionsStore: ReaderTermDefinitionsStore;
  readerVisibilityStore: ReaderVisibilityStore;
  readerWidgetStore: ReaderWidgetStore;
  referenceQueryStore: ReferenceQueryStore;
  researchHomepageStore: ResearchHomepageStore;
  researchListSelectionStore: ResearchListSelectionStore;
  sampleQueriesStore: SampleQueriesStore;
  sharedLibraryFolderStore: SharedLibraryFolderStore;
  shelfStore: ShelfStore;
  uiOptionsStore: UIOptionsStore;
  userContactStore: UserContactStore;
  userPrefsStore: UserPreferencesStore;
  userSettingStore: UserSettingStore;
  venueStore: VenueStore;
  venueQueryStore: VenueQueryStore;
  weblabStore: WeblabStore;

  static get ChildContextTypes() {
    return {
      accountNavStore: PropTypes.instanceOf(AccountNavStore).isRequired,
      alertsStore: PropTypes.instanceOf(AlertsStore).isRequired,
      api: PropTypes.instanceOf(Api).isRequired,
      apiErrorStore: PropTypes.instanceOf(ApiErrorStore).isRequired,
      authApi: PropTypes.instanceOf(AuthApi).isRequired,
      authStore: PropTypes.instanceOf(AuthStore).isRequired,
      authorCardStore: PropTypes.instanceOf(AuthorCardStore).isRequired,
      authorCitingAuthorsStore: PropTypes.instanceOf(AuthorCitingAuthorsStore).isRequired,
      authorCoAuthorsStore: PropTypes.instanceOf(AuthorCoAuthorsStore).isRequired,
      authorCorrectionsStore: PropTypes.instanceOf(AuthorCorrectionsStore).isRequired,
      authorInfluenceStatsStore: PropTypes.instanceOf(AuthorInfluenceStatsStore).isRequired,
      authorStatsStore: PropTypes.instanceOf(AuthorStatsStore).isRequired,
      authorStore: PropTypes.instanceOf(AuthorStore).isRequired,
      authorProfileStore: PropTypes.instanceOf(AuthorProfileStore).isRequired,
      authorQueryStore: PropTypes.instanceOf(AuthorQueryStore).isRequired,
      authorReferencedAuthorsStore: PropTypes.instanceOf(AuthorReferencedAuthorsStore).isRequired,
      authorshipModerationStore: PropTypes.instanceOf(AuthorshipModerationStore).isRequired,
      bundlePreloader: PropTypes.instanceOf(BundlePreloader).isRequired,
      citationQueryStore: PropTypes.instanceOf(CitationQueryStore).isRequired,
      claimModerationStore: PropTypes.instanceOf(AuthorClaimModerationStore).isRequired,
      cookieJar: PropTypes.instanceOf(CookieJar).isRequired,
      correctionsStore: PropTypes.instanceOf(CorrectionsStore).isRequired,
      cueStore: PropTypes.instanceOf(CueStore).isRequired,
      dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
      entityStore: PropTypes.instanceOf(EntityStore).isRequired,
      envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
      enrollmentsStore: PropTypes.instanceOf(EnrollmentsStore).isRequired,
      experimentsStore: PropTypes.instanceOf(ExperimentsStore).isRequired,
      featureFlagStore: PropTypes.instanceOf(FeatureFlagStore).isRequired,
      graphQLApi: PropTypes.instanceOf(GraphQLApi).isRequired,
      history: PropTypes.instanceOf(S2History).isRequired,
      historyStore: PropTypes.instanceOf(HistoryStore).isRequired,
      iconStore: PropTypes.instanceOf(IconStore).isRequired,
      indexMetadataStore: PropTypes.instanceOf(IndexMetadataStore).isRequired,
      libraryEntryStore: PropTypes.instanceOf(LibraryEntryStore).isRequired,
      libraryPageStore: PropTypes.instanceOf(LibraryPageStore).isRequired,
      libraryFolderStore: PropTypes.instanceOf(LibraryFolderStore).isRequired,
      libraryRecommendationsStore: PropTypes.instanceOf(LibraryRecommendationsStore).isRequired,
      libraryCitationIntersectionStore: PropTypes.instanceOf(LibraryCitationIntersectionStore)
        .isRequired,
      messageStore: PropTypes.instanceOf(MessageStore).isRequired,
      modalStore: PropTypes.instanceOf(ModalStore).isRequired,
      paperLabsStore: PropTypes.instanceOf(PaperLabsStore).isRequired,
      paperNavStore: PropTypes.instanceOf(PaperNavStore).isRequired,
      paperStore: PropTypes.instanceOf(PaperStore).isRequired,
      queryStore: PropTypes.instanceOf(QueryStore).isRequired,
      readerPdfStore: PropTypes.instanceOf(ReaderPdfStore).isRequired,
      readerAcronymsStore: PropTypes.instanceOf(ReaderAcronymsStore).isRequired,
      readerCitationStore: PropTypes.instanceOf(ReaderCitationStore).isRequired,
      readerInteractionsStore: PropTypes.instanceOf(ReaderInteractionsStore).isRequired,
      readerSkimmingStore: PropTypes.instanceOf(ReaderSkimmingStore).isRequired,
      readerTermsStore: PropTypes.instanceOf(ReaderTermsStore).isRequired,
      readerTermDefinitionsStore: PropTypes.instanceOf(ReaderTermDefinitionsStore).isRequired,
      readerVisibilityStore: PropTypes.instanceOf(ReaderVisibilityStore).isRequired,
      readerWidgetStore: PropTypes.instanceOf(ReaderWidgetStore).isRequired,
      referenceQueryStore: PropTypes.instanceOf(ReferenceQueryStore).isRequired,
      researchHomepageStore: PropTypes.instanceOf(ResearchHomepageStore).isRequired,
      researchListSelectionStore: PropTypes.instanceOf(ResearchListSelectionStore).isRequired,
      sampleQueriesStore: PropTypes.instanceOf(SampleQueriesStore).isRequired,
      sharedLibraryFolderStore: PropTypes.instanceOf(SharedLibraryFolderStore).isRequired,
      shelfStore: PropTypes.instanceOf(ShelfStore).isRequired,
      uiOptionsStore: PropTypes.instanceOf(UIOptionsStore).isRequired,
      userContactStore: PropTypes.instanceOf(UserContactStore).isRequired,
      userPrefsStore: PropTypes.instanceOf(UserPreferencesStore).isRequired,
      userSettingStore: PropTypes.instanceOf(UserSettingStore).isRequired,
      venueStore: PropTypes.instanceOf(VenueStore).isRequired,
      venueQueryStore: PropTypes.instanceOf(VenueQueryStore).isRequired,
      weblabStore: PropTypes.instanceOf(WeblabStore).isRequired,
    };
  }

  static set ChildContextTypes(types) {}

  /**
   * The application context.
   *
   * @param {object} props
   * @param {object} props.clientRequest the client request, in the event that we're handling a request
   * server side.
   * @param {boolean} props.isMobile indicates if the end client is mobile
   * @param {boolean} props.cookieJar the cookie jar used for getting and saving cookies
   */
  static create(props: {
    clientRequest?: RequestWithMiddleware;
    isMobile: boolean;
    cookieJar: CookieJar;
  }) {
    const { clientRequest, isMobile, cookieJar } = props;

    const bundlePreloader = new BundlePreloader();

    // Extract the request hostname, either from the client request or via document.location
    let hostname: string;
    let isTrackingAllowed: boolean;
    if (isBrowser()) {
      hostname = document.location.hostname;
      isTrackingAllowed = isTrackingAllowedOnBrowser();
      bundlePreloader.disableCapture();
    } else if (clientRequest) {
      // The hostname prop is derived from the "Host" header, or "X-Forwarded-Host" if "trust proxy" is enabled
      // https://expressjs.com/en/api.html#req.hostname
      hostname = clientRequest.hostname;
      isTrackingAllowed = isTrackingAllowedOnServer(clientRequest);
    } else {
      logger.warn('Unable to determine hostname, setting to empty string.');
      hostname = '';
      isTrackingAllowed = false;
    }

    const dispatcher = new S2Dispatcher();
    const history = clientRequest ? getServerS2History(clientRequest) : getBrowserS2History();
    invariant(history, `'history' is missing; cannot create AppContext`);

    dispatcher.register(payload => {
      if (payload.actionType === undefined) {
        logger.error(
          'An action was dispatched with an undefined actionType. This is likely a programmer error.',
          payload
        );
      }
    });

    const weblabStore = getWeblabStore(clientRequest, cookieJar);
    dispatcher.associateWithWeblabStore(weblabStore);

    const accountNavStore = new AccountNavStore(dispatcher);
    const alertsStore = new AlertsStore(dispatcher);
    const apiErrorStore = new ApiErrorStore(dispatcher);
    const authApi = new AuthApi(dispatcher);
    const authorCardStore = new AuthorCardStore(dispatcher);
    const authStore = new AuthStore(dispatcher, weblabStore);
    const authorStore = new AuthorStore(dispatcher);
    const authorCitingAuthorsStore = new AuthorCitingAuthorsStore(dispatcher);
    const authorCoAuthorsStore = new AuthorCoAuthorsStore(dispatcher);
    const authorInfluenceStatsStore = new AuthorInfluenceStatsStore(dispatcher);
    const authorStatsStore = new AuthorStatsStore(dispatcher);
    const authorProfileStore = new AuthorProfileStore(dispatcher);
    const authorQueryStore = new AuthorQueryStore(dispatcher, cookieJar, history, weblabStore);
    const authorReferencedAuthorsStore = new AuthorReferencedAuthorsStore(dispatcher);
    const authorshipModerationStore = new AuthorshipModerationStore(dispatcher);
    const citationQueryStore = new CitationQueryStore(dispatcher, history, weblabStore);
    const claimModerationStore = new AuthorClaimModerationStore(dispatcher);
    const correctionsStore = new CorrectionsStore(dispatcher);
    const cueStore = new CueStore(dispatcher);
    const entityStore = new EntityStore(dispatcher);
    const envInfo = new EnvInfo({
      isMobile,
      hostname,
      isTrackingAllowed,
    });
    const enrollmentsStore = new EnrollmentsStore(dispatcher);
    const experimentsStore = new ExperimentsStore(dispatcher);
    const featureFlagStore = new FeatureFlagStore(dispatcher);
    const graphQLApi = new GraphQLApi(dispatcher, weblabStore);
    const historyStore = new HistoryStore(dispatcher);
    const iconStore = new IconStore(dispatcher);
    const indexMetadataStore = new IndexMetadataStore(dispatcher);
    const libraryEntryStore = new LibraryEntryStore(dispatcher);
    const libraryPageStore = new LibraryPageStore(dispatcher);
    const libraryFolderStore = new LibraryFolderStore(dispatcher);
    const libraryRecommendationsStore = new LibraryRecommendationsStore(dispatcher);
    const libraryCitationIntersectionStore = new LibraryCitationIntersectionStore(dispatcher);
    const messageStore = new MessageStore(dispatcher);
    const modalStore = new ModalStore(dispatcher);
    const paperLabsStore = new PaperLabsStore(dispatcher);
    const paperNavStore = new PaperNavStore(dispatcher);
    const paperStore = new PaperStore(dispatcher);
    const queryStore = new QueryStore(dispatcher, cookieJar, history, weblabStore);
    const readerPdfStore = new ReaderPdfStore(dispatcher);
    const readerAcronymsStore = new ReaderAcronymsStore(dispatcher);
    const readerCitationStore = new ReaderCitationStore(dispatcher);
    const readerInteractionsStore = new ReaderInteractionsStore(dispatcher);
    const readerSkimmingStore = new ReaderSkimmingStore(dispatcher);
    const readerTermsStore = new ReaderTermsStore(dispatcher);
    const readerTermDefinitionsStore = new ReaderTermDefinitionsStore(dispatcher);
    const readerVisibilityStore = new ReaderVisibilityStore(dispatcher);
    const readerWidgetStore = new ReaderWidgetStore(dispatcher);
    const referenceQueryStore = new ReferenceQueryStore(dispatcher, history, weblabStore);
    const researchHomepageStore = new ResearchHomepageStore(dispatcher);
    const researchListSelectionStore = new ResearchListSelectionStore(dispatcher);
    const sampleQueriesStore = new SampleQueriesStore(dispatcher);
    const sharedLibraryFolderStore = new SharedLibraryFolderStore(dispatcher);
    const shelfStore = new ShelfStore(dispatcher);
    const userContactStore = new UserContactStore(dispatcher);
    const userPrefsStore = new UserPreferencesStore(dispatcher, cookieJar);
    const userSettingStore = new UserSettingStore(dispatcher);
    const venueStore = new VenueStore(dispatcher);
    const venueQueryStore = new VenueQueryStore(dispatcher, history);

    const authorCorrectionsStore = new AuthorCorrectionsStore(dispatcher, authorProfileStore);
    const uiOptionsStore = new UIOptionsStore(dispatcher, userPrefsStore);
    const api = new Api(
      dispatcher,
      queryStore,
      citationQueryStore,
      referenceQueryStore,
      paperStore,
      weblabStore,
      authorQueryStore
    );

    weblabStore.associateWithAuthStore(authStore);
    weblabStore.associateWithApi(api);

    return new AppContext({
      accountNavStore,
      alertsStore,
      api,
      apiErrorStore,
      authApi,
      authStore,
      authorCardStore,
      authorCitingAuthorsStore,
      authorCoAuthorsStore,
      authorCorrectionsStore,
      authorInfluenceStatsStore,
      authorStatsStore,
      authorStore,
      authorProfileStore,
      authorQueryStore,
      authorReferencedAuthorsStore,
      authorshipModerationStore,
      bundlePreloader,
      citationQueryStore,
      claimModerationStore,
      cookieJar,
      correctionsStore,
      cueStore,
      dispatcher,
      entityStore,
      envInfo,
      enrollmentsStore,
      experimentsStore,
      featureFlagStore,
      graphQLApi,
      history,
      historyStore,
      iconStore,
      indexMetadataStore,
      libraryEntryStore,
      libraryPageStore,
      libraryFolderStore,
      libraryRecommendationsStore,
      libraryCitationIntersectionStore,
      messageStore,
      modalStore,
      paperLabsStore,
      paperNavStore,
      paperStore,
      queryStore,
      readerPdfStore,
      readerAcronymsStore,
      readerCitationStore,
      readerInteractionsStore,
      readerSkimmingStore,
      readerTermsStore,
      readerTermDefinitionsStore,
      readerVisibilityStore,
      readerWidgetStore,
      referenceQueryStore,
      researchHomepageStore,
      researchListSelectionStore,
      sampleQueriesStore,
      sharedLibraryFolderStore,
      shelfStore,
      uiOptionsStore,
      userContactStore,
      userPrefsStore,
      userSettingStore,
      venueStore,
      venueQueryStore,
      weblabStore,
    });
  }

  constructor(props: {
    accountNavStore: AccountNavStore;
    alertsStore: AlertsStore;
    api: Api;
    apiErrorStore: ApiErrorStore;
    authApi: AuthApi;
    authStore: AuthStore;
    authorCardStore: AuthorCardStore;
    authorCitingAuthorsStore: AuthorCitingAuthorsStore;
    authorCoAuthorsStore: AuthorCoAuthorsStore;
    authorCorrectionsStore: AuthorCorrectionsStore;
    authorInfluenceStatsStore: AuthorInfluenceStatsStore;
    authorStatsStore: AuthorStatsStore;
    authorStore: AuthorStore;
    authorProfileStore: AuthorProfileStore;
    authorQueryStore: AuthorQueryStore;
    authorReferencedAuthorsStore: AuthorReferencedAuthorsStore;
    authorshipModerationStore: AuthorshipModerationStore;
    bundlePreloader: BundlePreloader;
    citationQueryStore: CitationQueryStore;
    claimModerationStore: AuthorClaimModerationStore;
    cookieJar: CookieJar;
    correctionsStore: CorrectionsStore;
    cueStore: CueStore;
    dispatcher: S2Dispatcher;
    entityStore: EntityStore;
    envInfo: EnvInfo;
    enrollmentsStore: EnrollmentsStore;
    experimentsStore: ExperimentsStore;
    featureFlagStore: FeatureFlagStore;
    graphQLApi: GraphQLApi;
    history: S2History;
    historyStore: HistoryStore;
    iconStore: IconStore;
    indexMetadataStore: IndexMetadataStore;
    libraryEntryStore: LibraryEntryStore;
    libraryPageStore: LibraryPageStore;
    libraryFolderStore: LibraryFolderStore;
    libraryRecommendationsStore: LibraryRecommendationsStore;
    libraryCitationIntersectionStore: LibraryCitationIntersectionStore;
    messageStore: MessageStore;
    modalStore: ModalStore;
    paperLabsStore: PaperLabsStore;
    paperNavStore: PaperNavStore;
    paperStore: PaperStore;
    queryStore: QueryStore;
    readerPdfStore: ReaderPdfStore;
    readerAcronymsStore: ReaderAcronymsStore;
    readerCitationStore: ReaderCitationStore;
    readerInteractionsStore: ReaderInteractionsStore;
    readerSkimmingStore: ReaderSkimmingStore;
    readerTermsStore: ReaderTermsStore;
    readerTermDefinitionsStore: ReaderTermDefinitionsStore;
    readerVisibilityStore: ReaderVisibilityStore;
    readerWidgetStore: ReaderWidgetStore;
    referenceQueryStore: ReferenceQueryStore;
    researchHomepageStore: ResearchHomepageStore;
    researchListSelectionStore: ResearchListSelectionStore;
    sampleQueriesStore: SampleQueriesStore;
    sharedLibraryFolderStore: SharedLibraryFolderStore;
    shelfStore: ShelfStore;
    uiOptionsStore: UIOptionsStore;
    userContactStore: UserContactStore;
    userPrefsStore: UserPreferencesStore;
    userSettingStore: UserSettingStore;
    venueStore: VenueStore;
    venueQueryStore: VenueQueryStore;
    weblabStore: WeblabStore;
  }) {
    this.accountNavStore = props.accountNavStore;
    this.alertsStore = props.alertsStore;
    this.api = props.api;
    this.apiErrorStore = props.apiErrorStore;
    this.authApi = props.authApi;
    this.authStore = props.authStore;
    this.authorCardStore = props.authorCardStore;
    this.authorCitingAuthorsStore = props.authorCitingAuthorsStore;
    this.authorCoAuthorsStore = props.authorCoAuthorsStore;
    this.authorCorrectionsStore = props.authorCorrectionsStore;
    this.authorInfluenceStatsStore = props.authorInfluenceStatsStore;
    this.authorStatsStore = props.authorStatsStore;
    this.authorStore = props.authorStore;
    this.authorProfileStore = props.authorProfileStore;
    this.authorQueryStore = props.authorQueryStore;
    this.authorReferencedAuthorsStore = props.authorReferencedAuthorsStore;
    this.authorshipModerationStore = props.authorshipModerationStore;
    this.bundlePreloader = props.bundlePreloader;
    this.citationQueryStore = props.citationQueryStore;
    this.claimModerationStore = props.claimModerationStore;
    this.cookieJar = props.cookieJar;
    this.correctionsStore = props.correctionsStore;
    this.cueStore = props.cueStore;
    this.dispatcher = props.dispatcher;
    this.entityStore = props.entityStore;
    this.envInfo = props.envInfo;
    this.enrollmentsStore = props.enrollmentsStore;
    this.experimentsStore = props.experimentsStore;
    this.featureFlagStore = props.featureFlagStore;
    this.graphQLApi = props.graphQLApi;
    this.history = props.history;
    this.historyStore = props.historyStore;
    this.iconStore = props.iconStore;
    this.indexMetadataStore = props.indexMetadataStore;
    this.libraryEntryStore = props.libraryEntryStore;
    this.libraryPageStore = props.libraryPageStore;
    this.libraryFolderStore = props.libraryFolderStore;
    this.libraryRecommendationsStore = props.libraryRecommendationsStore;
    this.libraryCitationIntersectionStore = props.libraryCitationIntersectionStore;
    this.messageStore = props.messageStore;
    this.modalStore = props.modalStore;
    this.paperLabsStore = props.paperLabsStore;
    this.paperNavStore = props.paperNavStore;
    this.paperStore = props.paperStore;
    this.queryStore = props.queryStore;
    this.readerPdfStore = props.readerPdfStore;
    this.readerAcronymsStore = props.readerAcronymsStore;
    this.readerCitationStore = props.readerCitationStore;
    this.readerInteractionsStore = props.readerInteractionsStore;
    this.readerSkimmingStore = props.readerSkimmingStore;
    this.readerTermsStore = props.readerTermsStore;
    this.readerTermDefinitionsStore = props.readerTermDefinitionsStore;
    this.readerVisibilityStore = props.readerVisibilityStore;
    this.readerWidgetStore = props.readerWidgetStore;
    this.referenceQueryStore = props.referenceQueryStore;
    this.researchHomepageStore = props.researchHomepageStore;
    this.researchListSelectionStore = props.researchListSelectionStore;
    this.sampleQueriesStore = props.sampleQueriesStore;
    this.sharedLibraryFolderStore = props.sharedLibraryFolderStore;
    this.shelfStore = props.shelfStore;
    this.uiOptionsStore = props.uiOptionsStore;
    this.userContactStore = props.userContactStore;
    this.userPrefsStore = props.userPrefsStore;
    this.userSettingStore = props.userSettingStore;
    this.venueStore = props.venueStore;
    this.venueQueryStore = props.venueQueryStore;
    this.weblabStore = props.weblabStore;
  }
}

function getWeblabStore(req, cookieJar) {
  return idx(req, _ => _.weblabStore) || new WeblabStore(req, cookieJar);
}

export default AppContext;
