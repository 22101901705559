import { MessageBase, MessagesBase } from '@/components/shared/message/MessagesBase';
import { ReactNodeish } from '@/utils/types';

import React from 'react';

class Message extends MessageBase {
  render(): ReactNodeish {
    const { title, text, extraContent } = this.props.message;
    return (
      <div className={this.getClassNames()} ref="message">
        <div className="msg-title-text">{title}</div>
        {text && <div className="msg-text">{text}</div>}
        {extraContent && extraContent}
      </div>
    );
  }
}

export default class Messages extends MessagesBase {
  renderImpl(): ReactNodeish {
    const { messages } = this.state;
    const msg = messages.last();
    if (!msg) {
      return null;
    }
    const message = <Message key={msg.id} message={msg} onClose={this.closeMessage} />;
    return <div className={this.getClassNames()}>{message}</div>;
  }
}
