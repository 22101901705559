import BaseStore from './BaseStore';

import { ApiRequestStartingPayload } from '@/api/BaseApi';
import { ApiResponse, CitationsResponseBody, PaperDetailResponseBody } from '@/api/ApiResponse';
import {
  CitationPageFromJS,
  CitationPageRecord,
  CitationPageRecordFactory,
} from '@/models/CitationPage';
import { DEPRECATED__FlowOptional } from '@/utils/types';
import { getCitationFromJS } from '@/models/Citation';
import { getFigureFromJS } from '@/models/Figure';
import { getPaperEntitlementRecordFromJs } from '@/models/paper/PaperEntitlement';
import { getPaperFromJS, getPaperRecordFromGraphQL, PaperRecord } from '@/models/Paper';
import { PaperDetailRecord, PaperDetailRecordFactory } from '@/models/PaperDetail';
import { RelatedPaperResponseFromGraphQL } from '@/actions/RelatedPapersActionCreators';
import CitationConstants, { CitationType } from '@/constants/Citation';
import Constants from '@/constants';
import logger from '@/logger';
import SkipperExperiments from '@/skipper/models/SkipperExperiments';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import Immutable from 'immutable';

export type QueryParams = Record<string, any>;

function parseCitationPage(
  currentPage: CitationPageRecord,
  nextPage: CitationPageFromJS
): CitationPageRecord {
  const yearFilter = Immutable.Map(nextPage.yearFilter || { min: null, max: null });
  const totalCitationsUnfiltered =
    nextPage.citationIntent === CitationConstants.INTENTS.ALL_INTENTS.id && !nextPage.yearFilter
      ? nextPage.totalCitations
      : currentPage.totalCitationsUnfiltered;

  return currentPage.merge({
    citations: Immutable.List(nextPage.citations).map(getCitationFromJS),
    citationType: CitationType[nextPage.citationType],
    requestedPageSize: nextPage.requestedPageSize,
    pageNumber: nextPage.pageNumber,
    totalCitations: nextPage.totalCitations,
    totalCitationsUnfiltered: totalCitationsUnfiltered,
    totalPages: nextPage.totalPages,
    sort: nextPage.sort,
    lastSort: nextPage.sort,
    citationIntent: nextPage.citationIntent,
    lastIntent: nextPage.citationIntent,
    loading: false,
    yearFilter: yearFilter,
  });
}

export default class PaperStore extends BaseStore {
  state: StoreStateValue;
  dispatchToken: string;
  #paperDetail: PaperDetailRecord;
  focusedFigureIndex: DEPRECATED__FlowOptional<number>;

  constructor(dispatcher) {
    super();

    this.state = StoreState.UNINITIALIZED;
    this.#paperDetail = PaperDetailRecordFactory();
    this.focusedFigureIndex = undefined;

    this.dispatchToken = dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const apiStartingPayload = payload as ApiRequestStartingPayload;
          switch (apiStartingPayload.requestType) {
            case Constants.requestTypes.PAPER_DETAIL: {
              this.state = StoreState.LOADING;
              this.#paperDetail = PaperDetailRecordFactory();
              this.focusedFigureIndex = undefined;
              this.emitChange();
              break;
            }
            case Constants.requestTypes.CITATIONS: {
              const { citationType, sort, citationIntent } = payload.requestData;
              this.setLoading(citationType, sort, citationIntent);
              break;
            }
            case Constants.requestTypes.GQL__RELATED_PAPERS: {
              this.#paperDetail = this.#paperDetail.withMutations(detail => {
                detail.set('isLoadingRelatedPapers', true);
                detail.set('relatedPapers', Immutable.List());
              });
              this.emitChange();
              break;
            }
          }
          break;
        }

        case Constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            // if u want information for paper details make sure to using createPaperDetailLoadedDispatch from PaperDetailActionCreators.js
            case Constants.requestTypes.CITATIONS: {
              const citationsApiResponse = apiResponse as ApiResponse<CitationsResponseBody>;
              this.updateFromCitationPage(citationsApiResponse.resultData);
              break;
            }
            case Constants.requestTypes.GQL__RELATED_PAPERS: {
              this.updateFromRelatedPapers(apiResponse.resultData.data.paper);
              this.emitChange();
              break;
            }
          }
          break;
        }

        case Constants.actions.PAPER_DETAIL_LOADED: {
          const { paperDetailResponse } = payload;
          if (paperDetailResponse.paper) {
            this.state = StoreState.LOADED;
            this.updateFromPaper(paperDetailResponse);
            this.emitChange();
          }
          break;
        }

        case Constants.actions.UPDATE_FOCUSED_FIGURE_INDEX: {
          const index = parseInt(payload.figureIndex, 10);
          this.focusedFigureIndex = isNaN(index) ? undefined : index;
          this.emitChange();
          break;
        }

        case Constants.actions.API_REQUEST_FAILED: {
          switch (payload.requestType) {
            case Constants.requestTypes.GQL__RELATED_PAPERS: {
              this.#paperDetail = this.#paperDetail.set('isLoadingRelatedPapers', false);
              this.emitChange();
              break;
            }
          }
        }
      }
    });
  }

  isLoading(): boolean {
    return this.state === StoreState.UNINITIALIZED || this.state === StoreState.LOADING;
  }

  setLoading(citationType: string, sort: string, intent: string): void {
    this.#paperDetail = this.#paperDetail.mergeIn([citationType], {
      loading: true,
      lastSort: sort,
      lastIntent: intent,
    });
    this.emitChange();
  }

  updateFromPaper({
    paper,
    citedPapers,
    citationSortAvailability,
    figureExtractions,
    skipperExperiments,
    entitlement,
  }: PaperDetailResponseBody): void {
    this.#paperDetail = this.#paperDetail.withMutations(map => {
      map.set('paper', getPaperFromJS(paper));
      map.set('citedPapers', parseCitationPage(CitationPageRecordFactory(), citedPapers));
      map.set('citationSortAvailability', citationSortAvailability);
      map.set('figures', Immutable.List(figureExtractions.figures.map(getFigureFromJS)));
      if (entitlement) {
        map.set('entitlement', getPaperEntitlementRecordFromJs(entitlement));
      }
      map.set('skipperExperiments', SkipperExperiments.fromJS(skipperExperiments));
    });
  }

  updateFromRelatedPapers(relatedPapersResponse: RelatedPaperResponseFromGraphQL): void {
    // Since related papers is loaded asynchronously, it's possible that the result could return
    // after changing pages to another paper detail page. To circumvent any resulting side-effect
    // (displaying the wrong related papers) the response includes the paper id to which the
    // papers are related.
    if (this.#paperDetail.paper && this.#paperDetail.paper.id === relatedPapersResponse?.id) {
      const paperRecords: Immutable.List<PaperRecord> = Immutable.List(
        relatedPapersResponse.relatedPapers.edges.map(({ node }) => getPaperRecordFromGraphQL(node))
      );
      this.#paperDetail = this.#paperDetail.withMutations(detail => {
        detail.set('relatedPapers', paperRecords);
        detail.set('isLoadingRelatedPapers', false);
      });
      this.emitChange();
    } else {
      logger.warn(
        `Related papers response for paper id ${relatedPapersResponse?.id} received while rendering paper ${this.#paperDetail.paper.id}`
      );
    }
  }

  updateFromCitationPage(citationPage: CitationPageFromJS): void {
    const citations = this.#paperDetail.get(CitationType[citationPage.citationType]);

    this.#paperDetail = this.#paperDetail.withMutations(map => {
      map.set(CitationType[citationPage.citationType], parseCitationPage(citations, citationPage));
    });

    this.emitChange();
  }

  getPaperDetail(): PaperDetailRecord {
    return this.#paperDetail;
  }
}
