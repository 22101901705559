import AuthStore from '@/stores/AuthStore';
import S2Redirect from '@/models/redirects/S2Redirect';

import PropTypes from 'prop-types';
import React from 'react';

export default class AuthorClaimedRedirect extends React.PureComponent {
  static contextTypes = {
    authStore: PropTypes.instanceOf(AuthStore).isRequired,
  };

  static willRouteTo(appContext) {
    const { authStore } = appContext;
    const authorId = authStore.getClaimedAuthorId();
    if (!authorId) {
      return new S2Redirect({
        routeName: 'HOME',
      });
    }
    return new S2Redirect({
      routeName: 'AUTHOR_PROFILE_BY_ID',
      params: { authorId },
    });
  }

  render() {
    return null;
  }
}
