const LABEL_TO_SEV = {
  ALL: 0,
  TRACE: 1,
  DEBUG: 2,
  INFO: 3,
  WARN: 4,
  ERROR: 5,
  FATAL: 6,
} as const;

type LogLevelLabel = keyof typeof LABEL_TO_SEV;
type LogSeverity = typeof LABEL_TO_SEV[LogLevelLabel];

export class LogLevelModel {
  label: LogLevelLabel;
  priority: LogSeverity;

  constructor(label: LogLevelLabel, priority: LogSeverity) {
    this.label = label;
    this.priority = priority;
    Object.freeze(this);
  }
}

/**
 * Enumeration of log levels.  Based off of the log4j log levels.
 * @see http://logging.apache.org/log4j/2.x/log4j-api/apidocs/index.html
 */

/** Level indicating that all events should be logged. */
export const ALL = new LogLevelModel('ALL', LABEL_TO_SEV.ALL);
/** A fine-grained debug message. */
export const TRACE = new LogLevelModel('TRACE', LABEL_TO_SEV.TRACE);
/** A general debugging event. */
export const DEBUG = new LogLevelModel('DEBUG', LABEL_TO_SEV.DEBUG);
/** An event for informational purposes. */
export const INFO = new LogLevelModel('INFO', LABEL_TO_SEV.INFO);
/** An event that might lead to an error. */
export const WARN = new LogLevelModel('WARN', LABEL_TO_SEV.WARN);
/** An error, possibly recoverable. */
export const ERROR = new LogLevelModel('ERROR', LABEL_TO_SEV.ERROR);
/** A severe error that will prevent the application from continuing. */
export const FATAL = new LogLevelModel('FATAL', LABEL_TO_SEV.FATAL);

export default {
  ALL,
  TRACE,
  DEBUG,
  INFO,
  WARN,
  ERROR,
  FATAL,
};
