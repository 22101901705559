import { getLayoverMetrics } from './layover/LayoverMetrics';

import invariant from 'invariant';

export type CounterIdKey = keyof typeof ALL_IDS;
export type CounterId = (typeof ALL_IDS)[CounterIdKey];

/**
 * NOTE: Each counter should be listed here. Each unique counter costs money in
 * Datadog, so we should be aware not to add counters with unique data in the
 * name (such as a paper ID, author ID, etc.). The typing and invariant are
 * meant to enforce that. If you need something short term that is unbounded,
 * use LayoverMetrics.sendIncrement() directly.
 */

export const PERF_TIMING_NO_SUPPORT = 'performanceTiming.noSupport';
export const REFRESH_UI_OUTDATED = 'refresh.outdatedUI';
export const READER_FILE_LOAD_ERROR = 'error.reader.fileload';

export const ALL_IDS = {
  PERF_TIMING_NO_SUPPORT,
  REFRESH_UI_OUTDATED,
  READER_FILE_LOAD_ERROR,
} as const;
Object.freeze(ALL_IDS);

const idSet = new Set(Object.values(ALL_IDS));

export function incrementCounter(counterId: CounterId): void {
  invariant(idSet.has(counterId), `counter "${counterId}" is missing from counters.js`);
  const layover = getLayoverMetrics();
  layover.sendIncrement(counterId, +1);
}
