import { getString } from '@/content/i18n';
import { makePath } from '@/router/Routes';
import { QUERY_TYPE } from '@/constants/Alert';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import S2Redirect from '@/models/redirects/S2Redirect';

import idx from 'idx';
import React from 'react';

export default class EntityRoute extends React.Component {
  static getCanonicalUrl = routerState => {
    if (routerState.params.slug) {
      const { entityId, slug } = routerState.params;
      return makePath({ routeName: 'ENTITY', params: { entityId, slug } });
    }
  };

  static async willRouteTo({ api, authStore, entityStore }, routerState) {
    const { entityId, slug: entitySlug } = routerState.params;

    // Validate the entity id
    if (!entityId) {
      return;
    }
    const entityIdNum = parseInt(entityId, 10);
    if (Number.isNaN(entityIdNum)) {
      return;
    }

    // Check if the entity is already loaded
    const loadedEntityId = idx(entityStore.getEntity(), _ => _.id) || null;
    if (loadedEntityId === entityId) {
      return;
    }

    // Load the entity
    const entityPromise = api.fetchEntityById(entityIdNum, routerState.query);
    const alertPromise = authStore.hasAuthenticatedUser()
      ? api.findAlertByQueryTypeAndValue(QUERY_TYPE.ENTITY_PAPER, entityId)
      : null;

    return Promise.all([entityPromise, alertPromise]).then(responses => {
      const entityResponse = responses[0];
      const { responseType, slug, id } = entityResponse.resultData;
      if (responseType === 'redirect' || slug !== entitySlug) {
        return new S2Redirect({
          routeName: 'ENTITY',
          params: { slug, entityId: id },
          replace: true,
        });
      } else {
        return responses;
      }
    });
  }

  static getPageTitle({ entityStore }) {
    return getString(
      _ => _.entity.pageTitle,
      idx(entityStore, _ => _.entity.name)
    );
  }

  static getPageMetaDescription({ entityStore }) {
    return idx(entityStore, _ => _.entity.definitionText);
  }

  render() {
    return (
      <AsyncLoadedPage
        footer={false}
        load={{
          importer: () => import(/* webpackChunkName: "shared-EntityPage" */ './EntityPage'),
          chunkName: 'shared-EntityPage',
          moduleId: require.resolveWeak('./EntityPage'),
        }}
      />
    );
  }
}
