import { CommonQueryProps, filterTypeToProp } from './QueryUtils';
import { YearRangeRecord, YearRangeRecordFactory } from './YearRange';

import { Nullable } from '@/utils/types';
import { removeFloatingPunctuation } from '@/util';
import SortType from '@/constants/sort-type';

import Immutable from 'immutable';

export type QueryFromQueryParams = {
  author: string[];
  coAuthor: string[];
  venue: string[];
  fos: string[];
  year: [string, string];
  page: number | string;
  sort?: string;
  pdf?: string;
  meq?: string;
  paperId?: string;
  templateId?: string;
  q?: string;
};

export type QueryFromJS = {
  authors: string[];
  coAuthors: string[];
  venues: string[];
  fieldsOfStudy: string[];
  yearRange: YearRangeRecord;
  requireViewablePdf?: Nullable<boolean>;
  matchingEntityQuery?: Nullable<string>;
  templateId?: Nullable<string>;
  paperId?: Nullable<string>;
  hydrateWithDdb: boolean;
};

/**
 * This model is specifically about a _Search_ Query
 */
type Props = CommonQueryProps & {
  queryString: string;
  requireViewablePdf?: Nullable<boolean>;
  matchingEntityQuery?: Nullable<string>;
  templateId?: Nullable<string>;
  paperId?: Nullable<string>;
  isCompact?: Nullable<boolean>;
  hydrateWithDdb: boolean;
};

export type QueryRouterParams = {
  q: string;
  page?: number | string;
};

const defaultProperties: Props = {
  queryString: '',
  page: 1,
  pageSize: 10,
  sort: SortType.RELEVANCE.id,
  authors: Immutable.Set(),
  coAuthors: Immutable.Set(),
  venues: Immutable.Set(),
  yearFilter: YearRangeRecordFactory(),
  requireViewablePdf: undefined,
  matchingEntityQuery: undefined,
  templateId: undefined,
  fieldsOfStudy: Immutable.OrderedSet(),
  paperId: undefined,
  isCompact: undefined,
  hydrateWithDdb: false,
};

export const RECORD_NAME = 'Query';
export const QueryRecordFactory = Immutable.Record<Props>(defaultProperties, RECORD_NAME);
export type QueryRecord = Immutable.RecordOf<Props>;

export function isQueryRecord(r: unknown): r is QueryRecord {
  return Immutable.isRecord(r) && Immutable.Record.getDescriptiveName(r) === RECORD_NAME;
}

/**
 * Reset the query to defaults while optionally retaining certain
 * fields when necessary.
 */
export function resetQueryRecord(
  query: QueryRecord,
  retainQueryString?: boolean,
  retainSortOrder?: boolean
): QueryRecord {
  let newQuery = QueryRecordFactory({
    queryString: query.queryString,
    sort: query.sort,
  });
  if (!retainQueryString) {
    newQuery = newQuery.remove('queryString');
  }
  if (!retainSortOrder) {
    newQuery = newQuery.remove('sort');
  }
  return newQuery;
}

/**
 * Prepares the query to be sent to the service layer by ensuring the sort parameter is set, and
 * that the year filter isn't set if either the min or the max is invalid.
 *
 * @param {string} sort=SortType.RELEVANCE.id
 *
 * @returns {object}
 */
export function prepareQueryRecord(
  query: QueryRecord,
  sort: Nullable<string> = SortType.RELEVANCE.id
): QueryRecord {
  return query.withMutations(query => {
    if (query.yearFilter?.min === null || query.yearFilter?.max === null) {
      query.set('yearFilter', null);
    }
    if (!query.sort && sort) {
      query.set('sort', sort);
    }
    return query;
  });
}

export function queryRecordToQueryString(query: QueryRecord): QueryFromQueryParams {
  const params: any = {};

  const startYear = query.getIn(['yearFilter', 'min']);
  const endYear = query.getIn(['yearFilter', 'max']);
  if (startYear && endYear) {
    params.year = [startYear, endYear];
  }

  params.author = query.authors.toArray();
  params.fos = query.fieldsOfStudy.toArray();
  params.coAuthor = query.coAuthors.toArray();
  params.venue = query.venues.toArray();
  params.coAuthor = query.coAuthors.toArray();
  params.templateId = query.templateId;
  params.isCompact = query.isCompact;

  params.q = removeFloatingPunctuation(query.queryString);
  const sort = query.sort;
  if (sort) {
    params.sort = sort;
  }
  const page = query.page;
  if (page > 1) {
    params.page = page;
  }

  // Only append the `pdf` param if it's set to true, as `&pdf=false` in the url might mislead
  // a user (or us!) and cause them (or us!) to think that their search indludes results without
  // a PDF
  if (query.requireViewablePdf === true) {
    params.pdf = true;
  }

  if (query.matchingEntityQuery) {
    params.meq = query.matchingEntityQuery;
  }

  if (query.templateId) {
    params.templateId = query.templateId;
  }

  if (query.paperId) {
    params.paperId = query.paperId;
  }

  return params;
}

// Upper bound of a Java Integer.  We use this to make sure we don't send a number > than this to
// the back-end which would trigger an error
const MAX_INTEGER = Math.pow(2, 31) - 1;

export function validateQuery(routerQuery: QueryRouterParams): {
  shouldRedirect: boolean;
  newQuery?: QueryRouterParams;
} {
  const requestedPage = routerQuery.page
    ? typeof routerQuery.page === 'number'
      ? routerQuery.page
      : parseInt(routerQuery.page, 10)
    : undefined;

  if (typeof requestedPage !== 'undefined') {
    if (requestedPage > MAX_INTEGER) {
      return {
        shouldRedirect: true,
        newQuery: {
          ...routerQuery,
          page: MAX_INTEGER,
        },
      };
    } else if (isNaN(requestedPage) || requestedPage <= 0) {
      return {
        shouldRedirect: true,
        newQuery: {
          ...routerQuery,
          page: 1,
        },
      };
    } else {
      return { shouldRedirect: false };
    }
  } else {
    return { shouldRedirect: false };
  }
}

export function parseYears(yearFilter: [string, string]): {
  startYear: Nullable<number>;
  endYear: Nullable<number>;
} {
  let startYear: Nullable<number> = null;
  let endYear: Nullable<number> = null;
  if (Array.isArray(yearFilter) && yearFilter.length >= 2) {
    const years = yearFilter.map(y => {
      return parseInt(y, 10);
    });
    years.sort();
    [startYear, endYear] = years;
  }
  return {
    startYear: startYear,
    endYear: endYear,
  };
}

export function getQueryRecordFromQueryStringString(
  queryString: string,
  filterType?: string,
  defaultSort?: string
): QueryRecord {
  const queryParams: Partial<Props> = {};
  queryParams.queryString = queryString;
  if (defaultSort) {
    queryParams.sort = defaultSort;
  }
  if (filterType) {
    queryParams[filterTypeToProp(filterType)] = Immutable.Set.of(queryString);
  }
  return QueryRecordFactory(queryParams);
}

export function getQueryRecordFromQueryStringParams(query: QueryFromQueryParams): QueryRecord {
  const years = parseYears(query.year);

  return QueryRecordFactory({
    authors: Immutable.Set(query.author),
    coAuthors: Immutable.Set(query.coAuthor),
    venues: Immutable.Set(query.venue),
    yearFilter: YearRangeRecordFactory({
      min: years.startYear,
      max: years.endYear,
    }),
    queryString: query.q || '',
    sort: query.sort,
    page: query.page ? (typeof query.page === 'string' ? parseInt(query.page, 10) : query.page) : 1,
    requireViewablePdf: query.pdf === 'true' ? true : false,
    matchingEntityQuery: query.meq,
    templateId: query.templateId,
    fieldsOfStudy: Immutable.OrderedSet(query.fos),
    paperId: query.paperId,
  });
}

export function getQueryRecordFromResultQueryRecord(query: QueryRecord): QueryRecord {
  return QueryRecordFactory({
    authors: Immutable.Set(query.authors),
    coAuthors: Immutable.Set(query.coAuthors),
    venues: Immutable.Set(query.venues),
    yearFilter: YearRangeRecordFactory({
      min: (query.yearFilter && query.yearFilter.min) || null,
      max: (query.yearFilter && query.yearFilter.max) || null,
    }),
    queryString: query.queryString || '',
    sort: query.sort || '',
    page: query.page ? (typeof query.page === 'string' ? parseInt(query.page, 10) : query.page) : 1,
    requireViewablePdf: query.requireViewablePdf,
    matchingEntityQuery: query.matchingEntityQuery,
    templateId: query.templateId,
    fieldsOfStudy: Immutable.OrderedSet(query.fieldsOfStudy),
    paperId: query.paperId,
  });
}

export function getQueryRecordFromJS(rawQuery: QueryFromJS): QueryRecord {
  return QueryRecordFactory({
    authors: Immutable.Set(rawQuery.authors),
    coAuthors: Immutable.Set(rawQuery.coAuthors),
    venues: Immutable.Set(rawQuery.venues),
    yearFilter: YearRangeRecordFactory(rawQuery.yearRange),
    requireViewablePdf: rawQuery.requireViewablePdf,
    templateId: rawQuery.templateId,
    fieldsOfStudy: Immutable.OrderedSet(rawQuery.fieldsOfStudy),
    paperId: rawQuery.paperId,
    hydrateWithDdb: rawQuery.hydrateWithDdb,
  });
}
