// This should be kept in sync with a corresponding scala-side LibraryFolder.PrivacySetting

import { isString, Nullable } from '@/utils/types';

import invariant from 'invariant';

export type PrivacySetting = keyof typeof byKey;

export const Private = 'Private';
export const Public = 'Public';
export const Unsupported = 'Unsupported';

const byKey = {
  Private,
  Public,
  Unsupported,
} as const;

export const PrivacySettingMap = Object.freeze(byKey);

export function getOptPrivacySetting(optPrivacy?: Nullable<string>): Nullable<PrivacySetting> {
  if (!isString(optPrivacy)) {
    return null;
  }
  if (optPrivacy in byKey) {
    return byKey[optPrivacy];
  }
  return null;
}

export function getPrivacySetting(optPrivacy?: Nullable<string>): PrivacySetting {
  const privacySetting = getOptPrivacySetting(optPrivacy);
  invariant(privacySetting, `"$optPrivacy || ''}" is not a valid PrivacySetting`);
  return privacySetting;
}
