import React from 'react';

export default function IconEmotion3(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 36 36">
      <path
        id="rate-4"
        d="M36,18c0,9.95-8.06,18-18,18C8.06,36,0,27.94,0,18C0,8.05,8.06,0,18,0C27.94,0,36,8.06,36,18z M24.55,23.06 C22.92,25,20.54,26.12,18,26.12s-4.92-1.11-6.55-3.06c-0.98-1.17-2.77,0.31-1.78,1.49c2.07,2.48,5.1,3.9,8.33,3.9 c3.23,0,6.27-1.42,8.33-3.9C27.31,23.36,25.52,21.88,24.55,23.06z M9.87,14.52c0,1.28,1.04,2.32,2.32,2.32s2.32-1.04,2.32-2.32 c0-1.28-1.04-2.32-2.32-2.32S9.87,13.23,9.87,14.52z M21.48,14.52c0,1.28,1.04,2.32,2.32,2.32c1.28,0,2.32-1.04,2.32-2.32 c0-1.28-1.04-2.32-2.32-2.32C22.52,12.19,21.48,13.23,21.48,14.52z"
      />
    </symbol>
  );
}
