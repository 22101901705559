import React from 'react';

export default function IconTag(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 24 24">
      <path
        d="M0,2.25c0-0.62,0.22-1.16,0.66-1.59S1.62,0,2.25,0h9.56c0.62,0,1.16,0.22,1.59,0.66l9.94,9.94
        c0.44,0.44,0.66,0.97,0.66,1.59s-0.22,1.16-0.66,1.59l-9.56,9.56C13.34,23.78,12.81,24,12.19,24s-1.16-0.22-1.59-0.66l-9.94-9.94
        C0.22,12.97,0,12.44,0,11.81V2.25z M3.66,3.66C3.22,4.09,3,4.62,3,5.25s0.22,1.16,0.66,1.59S4.62,7.5,5.25,7.5s1.16-0.22,1.59-0.66
        S7.5,5.87,7.5,5.25S7.28,4.09,6.84,3.66S5.87,3,5.25,3S4.09,3.22,3.66,3.66z"
      />
    </symbol>
  );
}
