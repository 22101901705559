import React from 'react';

export default function IconFolderNotFound(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 156 156" className="empty empty--folder">
      <style type="text/css">
        {`
            .empty{height:220px; display:block; margin:24px auto;}
            .empty__backdrop{fill:#F5F6F7;}
            .empty__items{fill:#FFFFFF;stroke:#D9DADB;stroke-width:2;stroke-dasharray:6,4;}
            .empty--folder{height:156px}
        `}
      </style>
      <circle className="empty__backdrop" cx="78" cy="78" r="78" />
      <path
        className="empty__items"
        d="M123.9,14.1c2.8,0,5.6,0.9,7.5,3.3c1.9,2.3,3.3,4.7,3.3,7.5v49.3c0,2.8-0.9,5.6-3.3,7.5 c-2.3,1.9-4.7,3.3-7.5,3.3H32.3c-2.8,0-5.6-0.9-7.5-3.3c-1.9-2.3-2.8-4.7-2.8-7.5V10.3c0-2.8,0.9-5.6,3.3-7.5c2.3-1.9,4.2-2.8,7-2.8 h35.1l14,14.1H123.9z"
      />
    </symbol>
  );
}
