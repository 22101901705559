import CLPaperBadge from './CLPaperBadge';

import * as linkUtils from '@/utils/paper-link-util';
import { BadgeType, PaperBadgeRecord, PaperBadgeRecordFactory } from '@/models/PaperBadge';
import { checkForPaperRecord } from '@/utils/paper-util';
import { heapPaperBadge } from '@/analytics/attributes/paperObject';
import { isPaperRecord, PaperishRecord } from '@/models/Paper';
import { Nullable, ReactNodeish } from '@/utils/types';
import { PLACEMENT } from '@/components/library/tooltip/CLTooltip';
import { useAppContext } from '@/AppContext';
import ExternalLink from '@/components/shared/ExternalLink';
import Link from '@/router/Link';
import PaperLinkType from '@/constants/PaperLinkType';

import classnames from 'classnames';
import Immutable from 'immutable';
import React from 'react';

type Props = {
  paper: PaperishRecord;
  isCompact?: Nullable<boolean>;
};

const LIBKEY_URL_BASE = 'https://libkey.io/';
const LIBKEY_UTM_SUFFIX = '?utm_source=Semantic_Scholar';
export const OPEN_ATHENS = 'OPENATHENS';

const defaultSortByType = Immutable.List.of(
  PaperBadgeRecordFactory({ id: BadgeType.OPEN_ACCESS }),
  PaperBadgeRecordFactory({ id: BadgeType.UNPAYWALL })
);

export function sortBadgesByType(
  badges: Immutable.List<PaperBadgeRecord>
): Immutable.List<PaperBadgeRecord> {
  const badgesList = combinePDFBadges(badges);
  // This is not an efficient sorting algo (O(n*m)) but the list will never be greater than 10
  // so readability wins here IMO (tylerm)
  return defaultSortByType.flatMap(sortBadge => badgesList.filter(b => b.id === sortBadge.id));
}

export function combinePDFBadges(
  badges: Immutable.List<PaperBadgeRecord>
): Immutable.List<PaperBadgeRecord> {
  if (badges.find(badge => badge.id === BadgeType.UNPAYWALL)) {
    return badges.filter(badge => badge.id !== BadgeType.OPEN_ACCESS);
  }
  return badges;
}

export function findPDFBadgeLink(paper: PaperishRecord, isOpenAthens: Nullable<boolean>) {
  let pdfLink: Nullable<string> = null;
  if (isPaperRecord(paper)) {
    const primaryPaperLinkType = paper?.primaryPaperLink?.linkType;
    const isLibKeyPossible =
      isOpenAthens &&
      primaryPaperLinkType != PaperLinkType.S2 &&
      primaryPaperLinkType != PaperLinkType.ARXIV &&
      paper.doiInfo;
    const linkGroups = linkUtils.prepareAlternateLinkGroups(paper.alternatePaperLinks);
    if (isLibKeyPossible) {
      pdfLink = createLibkeyPDFUrl(paper.doiInfo.doi);
    } else if (paper.primaryPaperLink && linkUtils.isPdfUrl(paper.primaryPaperLink.url)) {
      pdfLink = paper.primaryPaperLink.url;
    } else if (linkGroups.openAccess.first()) {
      pdfLink = linkGroups.openAccess.first()?.url || null;
    } else if (linkGroups.pdf.first()) {
      pdfLink = linkGroups.pdf.first()?.url || null;
    }
  }
  return pdfLink;
}

export function createLibkeyPDFUrl(doiId: string): string {
  return LIBKEY_URL_BASE + doiId + LIBKEY_UTM_SUFFIX;
}

// eslint-disable-next-line max-params
export function useCreateBadge(
  badge: PaperBadgeRecord,
  paper: PaperishRecord,
  isOpenAthens: Nullable<boolean>,
  isCompact?: Nullable<boolean>
) {
  const { envInfo } = useAppContext();
  const isMobile = envInfo.isMobile;
  const paperBadge = (
    <CLPaperBadge
      key={badge.id}
      type={badge.id}
      isCompact={isCompact}
      tooltipPlacement={PLACEMENT.BOTTOM_LEFT}
      className={classnames('cl-paper-stats__badge-hover', {
        'cl-paper-stats__mobile-badge': isMobile,
      })}
      testId={`paper-badge-${badge.id.toLowerCase()}`}
      heapProps={heapPaperBadge(badge.id)}
    />
  );

  // cast to PaperRecord for findPDFBadgeLink function below
  const maybePaperRecord = checkForPaperRecord(paper);
  const pdfLink = maybePaperRecord ? findPDFBadgeLink(maybePaperRecord, isOpenAthens) : null;

  switch (badge.id) {
    case BadgeType.UNPAYWALL:
    case BadgeType.OPEN_ACCESS: {
      return pdfLink ? (
        <ExternalLink href={pdfLink}>{paperBadge}</ExternalLink>
      ) : (
        <Link to="PAPER_DETAIL_BY_ID" params={{ paperId: paper.id }} shouldUnderline={false}>
          {paperBadge}
        </Link>
      );
    }

    default: {
      return paperBadge;
    }
  }
}

export default function CLPaperBadgeList({ paper, isCompact }: Props): ReactNodeish {
  const { authStore } = useAppContext();
  const authSource = authStore.getUser()?.authSource;
  const isOpenAthens = authSource === OPEN_ATHENS;

  return (
    <div className="flex-row paper-badge-list">
      {sortBadgesByType(paper.badges).map(badge =>
        // lint thinks that this component is not a functional component and is upset we're using a hook inside it
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useCreateBadge(badge, paper, isOpenAthens, isCompact)
      )}
    </div>
  );
}

export function canRender(paper: PaperishRecord): boolean {
  const { badges } = paper;

  return !!badges && badges.size > 0;
}
