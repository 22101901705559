import { extractVenueInformation } from './extractVenueInformation';

import { heapVenueSerpLinkClick } from '@/analytics/attributes/paperObject';
import { PaperLiteRecord } from '@/models/PaperLite';
import { ReactNodeish } from '@/utils/types';
import Link from '@/router/Link';

import classNames from 'classnames';
import React from 'react';

type PaperLiteVenueProps = {
  paper: PaperLiteRecord;
  stripYear?: boolean;
  maxVenueTextLength?: number;
  isVenueLinkEnabled?: boolean;
  className?: string;
};

export const PaperLiteVenue = ({
  paper,
  stripYear,
  maxVenueTextLength,
  isVenueLinkEnabled = false,
  className,
}: PaperLiteVenueProps): ReactNodeish => {
  const { shouldLinkToVenuePage, venueText, venueRouteObject } = extractVenueInformation(
    paper,
    stripYear,
    maxVenueTextLength
  );

  const shouldShowVenueLink = isVenueLinkEnabled && shouldLinkToVenuePage;

  const Container: React.ElementType = shouldShowVenueLink ? Link : 'span';

  const containerProps = {
    'data-heap-id': 'paper-meta-venue',
    className: classNames('cl-paper-venue', className),
    ...(shouldLinkToVenuePage ? { ...venueRouteObject, ...heapVenueSerpLinkClick } : {}),
  };

  return <Container {...containerProps}>{venueText}</Container>;
};
