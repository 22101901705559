function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { anyQueryToQueryParams } from "./../../../models/QueryUtils";
import CitationActionName from "./../../../analytics/constants/CitationActionName";
import SearchActionName from "./../../../analytics/constants/SearchActionName";
import trackRicoEvent from "./../../../analytics/trackRicoEvent";
export function trackSearchResultTitleClick(clickContext) {
  trackWithRico(SearchActionName.Result.Title.CLICK, _objectSpread({}, clickContext));
}
export function trackSearchResultAuthorClick(clickContext) {
  trackWithRico(SearchActionName.Result.Author.CLICK, _objectSpread({}, clickContext));
}
export function trackSearchResultCiteClick(clickContext) {
  trackWithRico(SearchActionName.Result.Cite.CLICK, _objectSpread({}, clickContext));
}
export function trackSearchResultPaperClick(clickContext) {
  trackWithRico(SearchActionName.Result.Paper.CLICK, _objectSpread({}, clickContext));
}
export function trackSearchResultLibraryAddClick(clickContext) {
  trackWithRico(SearchActionName.Result.Library.SAVE, _objectSpread({}, clickContext));
}
export function trackCitationResultsImpression(queryRecord, results) {
  var candidates = buildCandidates(queryRecord, results);
  trackWithRico(CitationActionName.ResultList.Impression, {
    candidates: candidates,
    query: anyQueryToQueryParams(queryRecord)
  });
}
export function trackCitationSearchResultsImpression(queryRecord, results) {
  var candidates = buildCitationSearchCandidates(queryRecord, results);
  trackWithRico(CitationActionName.ResultList.Impression, {
    candidates: candidates,
    query: anyQueryToQueryParams(queryRecord)
  });
}
export function trackSearchResultsImpression(queryRecord, results) {
  var candidates = buildCandidates(queryRecord, results);
  trackWithRico(SearchActionName.ResultList.Impression, {
    candidates: candidates,
    query: anyQueryToQueryParams(queryRecord)
  });
}
function buildCandidates(query, results) {
  // Note: We determine absolute position of the candidates not position on current page
  return results.map(function (result, i) {
    return {
      resultPage: query.page,
      paperSha: result.id,
      paperIndex: (query.page - 1) * query.pageSize + i
    };
  });
}
function buildCitationSearchCandidates(query, results) {
  // Note: We determine absolute position of the candidates not position on current page
  return results.map(function (result, i) {
    return {
      resultPage: query.page,
      paperSha: result.id,
      paperIndex: (query.page - 1) * query.pageSize + i
    };
  });
}
export function buildBaseClickContext(queryStore, result) {
  var candidates = buildCandidates(queryStore.getQuery(), queryStore.getQueryResponse().results);
  return {
    candidates: candidates,
    paperSha: result.paperSha,
    paperIndex: result.paperIndex,
    query: anyQueryToQueryParams(queryStore.getQuery()),
    ranker: 'default',
    // TODO(tylerm): Figure out how to get the ranker in here...
    resultPage: queryStore.getQuery().page
  };
}
function trackWithRico(action, data) {
  trackRicoEvent(action, data);
}