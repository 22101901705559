import { getString } from '@/content/i18n';
import { Nullable } from '@/utils/types';
import logger from '@/logger';

import Immutable from 'immutable';
import invariant from 'invariant';

export type LibrarySortType =
  | typeof RECENCY
  | typeof RELEVANCE
  | typeof ALPHABETICAL
  | typeof DATE_ADDED_TO_LIBRARY;

export const RECENCY = 'recency'; // aka pubDate
export const RELEVANCE = 'relevance';
export const ALPHABETICAL = 'alphabetical';
export const DATE_ADDED_TO_LIBRARY = 'date_added_to_library';

export const LIBRARY_DEFAULT_SORT = DATE_ADDED_TO_LIBRARY;

export const ALL = Immutable.List.of<LibrarySortType>(
  RECENCY,
  RELEVANCE,
  ALPHABETICAL,
  DATE_ADDED_TO_LIBRARY
);

export const LibrarySort = {
  RECENCY,
  RELEVANCE,
  ALPHABETICAL,
  DATE_ADDED_TO_LIBRARY,

  /**
   * Returns a list of the possible library sort values, sorted based on display preference.
   * @return {Immutable.List[LibrarySort]}
   */
  all(): Immutable.List<LibrarySortType> {
    return ALL;
  },

  /**
   * Converts a sort type to its display value.
   * @param {string} sort - Sort type.
   * @returns {string} - Display text.
   */
  toDisplayText(sort: Nullable<LibrarySortType>): string | undefined {
    switch (sort) {
      case RECENCY: {
        return getString(_ => _.library.sort.recency);
      }
      case RELEVANCE: {
        return getString(_ => _.library.sort.relevance);
      }
      case ALPHABETICAL: {
        return getString(_ => _.library.sort.alphabetical);
      }
      case DATE_ADDED_TO_LIBRARY: {
        return getString(_ => _.library.sort.dateAdded);
      }
      default: {
        logger.error('Attempted to get display text for a LibrarySort that does not exist.');
      }
    }
  },
} as const;

export function optLibrarySortType(str?: Nullable<string>): Nullable<LibrarySortType> {
  return LibrarySort.all().find(_ => _ === str) || null;
}

export function getLibrarySortType(str?: Nullable<string>): LibrarySortType {
  const sortType = optLibrarySortType(str);
  invariant(sortType, `String "${str}" does not map to a LibrarySortType`);
  return sortType;
}
