import { Nullable } from './types';

import { RouteName } from '@/router/Routes';

export enum THEME {
  Default = 'default',
  Research = 'research',
}
export function getRouteTheme(activeRoute: Nullable<RouteName>): THEME {
  switch (activeRoute) {
    case 'HOME':
      return THEME.Default;
    case 'RESEARCH_HOMEPAGE':
      return THEME.Research;
    default:
      return THEME.Research;
  }
}
