import { getAddLibraryEntryBulkRequestFromJS } from '@/models/library/AddLibraryEntryBulkRequest';
import { getString } from '@/content/i18n';
import { ModalId } from '@/constants/Modal';
import { NotRelevant } from '@/models/library/LibraryEntryAnnotationState';
import { Recommendation } from '@/models/library/LibraryEntrySourceType';
import { showPageErrorMessage } from '@/actions/MessageActionCreators';
import Api from '@/api/Api';
import CLButton, { TYPE } from '@/components/library/button/CLButton';
import CLModal from '@/components/library/modal/CLModal';
import CLPaperAuthors from '@/components/library/paper/CLPaperAuthors';
import CLPaperMeta from '@/components/library/paper/CLPaperMeta';
import CLPaperRow from '@/components/library/paper/CLPaperRow';
import Icon from '@/components/shared/icon/Icon';
import LibraryRecommendationsStore from '@/stores/LibraryRecommendationsStore';
import S2Dispatcher from '@/utils/S2Dispatcher';

import PropTypes from 'prop-types';
import React from 'react';

export default class RecommendationsNotRelevantModal extends React.PureComponent {
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    libraryRecommendationsStore: PropTypes.instanceOf(LibraryRecommendationsStore).isRequired,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      ...this.getStateFromLibraryRecommendationsStore(),
    };

    this.context.libraryRecommendationsStore.registerComponent(this, () => {
      this.setState(this.getStateFromLibraryRecommendationsStore);
    });
  }

  getStateFromLibraryRecommendationsStore = () => {
    const { libraryRecommendationsStore } = this.context;
    const { data } = this.props;
    const notRelevantEntries = libraryRecommendationsStore.getNotRelevantPapersInFolder(
      data.folderId
    );

    return {
      notRelevantEntries: notRelevantEntries,
    };
  };

  removeAnnotation = (paperId, paperTitle) => {
    const { api, dispatcher } = this.context;
    const { data } = this.props;

    const entryRequest = getAddLibraryEntryBulkRequestFromJS({
      paperId: paperId,
      paperTitle: paperTitle,
      sourceType: Recommendation,
      folderIds: [data.folderId],
      annotationState: null,
    });

    api
      .annotateLibraryEntry(entryRequest)
      .then(() => {
        api.getLibraryAnnotationEntries(NotRelevant);
      })
      .catch(() => {
        const messagePayload = showPageErrorMessage({
          text: getString(_ => _.research.recommendations.annotateErrorMessage),
        });
        dispatcher.dispatch(messagePayload);
      });
  };

  renderResults() {
    const { notRelevantEntries } = this.state;
    return notRelevantEntries.map(
      entry =>
        entry.paper && (
          <div className="not-relevant-modal__content" key={entry.id}>
            <CLPaperRow
              controls={false}
              paper={entry.paper}
              meta={
                <CLPaperMeta
                  paper={entry.paper}
                  authors={<CLPaperAuthors paper={entry.paper} onWhiteBackground={false} />}
                />
              }
            />
            <div className="not-relevant-modal__content__button">
              <CLButton
                className="not-relevant-modal__content__button__test"
                type={TYPE.SECONDARY}
                onClick={() => {
                  this.removeAnnotation(entry.paperId, entry.paperTitle);
                }}
                label={getString(_ => _.library.notRelevantModal.remove)}
              />
            </div>
          </div>
        )
    );
  }

  renderBlankState() {
    return (
      <div className="not-relevant-modal__blank-state">
        <Icon icon="not-relevant" width="233" height="200" />
        <p className="not-relevant-modal__blank-state__header">
          {getString(_ => _.library.notRelevantModal.blankStateHeader)}
        </p>
        <p className="not-relevant-modal__blank-state__description">
          {getString(_ => _.library.notRelevantModal.blankStateDescription)}
        </p>
      </div>
    );
  }

  render() {
    const { notRelevantEntries } = this.state;
    const { data } = this.props;
    return (
      <CLModal className="not-relevant-modal" modalId={ModalId.RECOMMENDATIONS_NOT_RELEVANT}>
        <div className="not-relevant-modal__header">
          <h3 className="not-relevant-modal__header__content">
            {getString(_ => _.library.notRelevantModal.header, data.folderName)}
          </h3>
        </div>

        {notRelevantEntries.isEmpty() ? this.renderBlankState() : this.renderResults()}
      </CLModal>
    );
  }
}
