import CLPaperFeedAnnotationButton from './buttons/CLPaperAnnotationButton';

import { AnalyticsContextHelper } from '@/components/library/paper/CLPaperAnalytics';
import { CLPaperControlsContext } from '@/components/library/paper/CLPaperControlsExpansionManager';
import { getAddLibraryEntryBulkRequestFromJS } from '@/models/library/AddLibraryEntryBulkRequest';
import { getPaperTitleStr } from '@/utils/paper-util';
import { getString } from '@/content/i18n';
import {
  heapAnnotateRecommendationNotRelevant,
  heapAnnotateRecommendationRelevant,
} from '@/analytics/attributes/paperObject';
import { NotRelevant, NotRelevantRemoveX } from '@/models/library/AnnotationType';
import { NotRelevant as NotRelevantAnnotationState } from '@/models/library/LibraryEntryAnnotationState';
import { Recommendation } from '@/models/library/LibraryEntrySourceType';
import { showPageErrorMessage } from '@/actions/MessageActionCreators';
import AnnotatePaperPopup from '@/components/shared/common/AnnotatePaperPopup';
import Api from '@/api/Api';
import LibraryRecommendationsStore from '@/stores/LibraryRecommendationsStore';
import S2Dispatcher from '@/utils/S2Dispatcher';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * This component is used to negatively annotate a recommendation sourced from a library folder
 **/

export default class CLPaperActionAnnotate extends React.PureComponent {
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    libraryRecommendationsStore: PropTypes.instanceOf(LibraryRecommendationsStore).isRequired,
  };

  static defaultProps = {
    testId: 'annotate-recommendation-paper-button',
    buttonType: 'NotRelevant',
  };

  _onClickAnnotationAnalytics = null;
  _setControlsExpandedContent = null;

  constructor(...args) {
    super(...args);

    this.state = {
      ...this.getStateFromLibraryRecommendationsStore(),
    };

    this.context.libraryRecommendationsStore.registerComponent(this, () => {
      this.setState(this.getStateFromLibraryRecommendationsStore());
    });
  }

  setAnalyticsCallbacks = ({ onClickAnnotation }) => {
    this._onClickAnnotationAnalytics = onClickAnnotation;
  };

  getStateFromLibraryRecommendationsStore() {
    const { libraryRecommendationsStore } = this.context;
    const { paper, folderId } = this.props;

    return {
      isPaperNotRelevant: libraryRecommendationsStore.isPaperNotRelevantInFolder(
        paper.id,
        folderId
      ),
    };
  }

  onClickAnnotation = () => {
    const { isPaperNotRelevant } = this.state;
    this.annotatePaper();

    if (this._onClickAnnotationAnalytics) {
      this._onClickAnnotationAnalytics();
    }

    if (this._setControlsExpandedContent) {
      const children = isPaperNotRelevant ? null : this.renderPopupContext();
      this._setControlsExpandedContent(children);
    }
  };

  annotatePaper = () => {
    const { api, dispatcher } = this.context;
    const { folderId, paper } = this.props;
    const { isPaperNotRelevant } = this.state;

    const newAnnotation = !isPaperNotRelevant;
    const newAnnotationState = newAnnotation ? NotRelevantAnnotationState : null;

    if (this._onClickAnnotationAnalytics) {
      this._onClickAnnotationAnalytics();
    }

    if (this._setControlsExpandedContent) {
      const children = isPaperNotRelevant ? null : this.renderPopupContext();
      this._setControlsExpandedContent(children);
    }

    const entryRequest = getAddLibraryEntryBulkRequestFromJS({
      paperId: paper.id,
      paperTitle: getPaperTitleStr(paper),
      sourceType: Recommendation,
      folderIds: [folderId],
      annotationState: newAnnotationState,
    });
    api
      .annotateLibraryEntry(entryRequest)
      .then(async () => {
        await api.getLibraryAnnotationEntries(NotRelevant);
        this.setState({ isPaperNotRelevant: newAnnotation });
      })
      .catch(() => {
        const messagePayload = showPageErrorMessage({
          text: getString(_ => _.research.recommendations.annotateErrorMessage),
        });
        dispatcher.dispatch(messagePayload);
      });
  };

  renderPopupContext() {
    const { paper, folderId, folderName } = this.props;

    return (
      <AnnotatePaperPopup
        paper={paper}
        folderId={folderId}
        folderName={folderName}
        onUndoClick={this.annotatePaper}
      />
    );
  }

  render() {
    const { isPaperNotRelevant } = this.state;
    const { className, testId, buttonType } = this.props;
    const buttonIcon = buttonType == 'NotRelevantRemoveX' ? NotRelevantRemoveX : NotRelevant;

    const annnotationContent = (
      <AnalyticsContextHelper onUpdate={this.setAnalyticsCallbacks}>
        <div className="cl-paper-action-annotate">
          <CLPaperFeedAnnotationButton
            isActive={isPaperNotRelevant}
            onClick={this.onClickAnnotation}
            heapProps={
              isPaperNotRelevant
                ? heapAnnotateRecommendationNotRelevant()
                : heapAnnotateRecommendationRelevant()
            }
            testId={testId ? `${testId}-less` : null}
            className={classnames(
              {
                'cl-paper-annotations__not-relevant-button': true,
              },
              className
            )}
            buttonAnnotationType={buttonIcon}
          />
        </div>
      </AnalyticsContextHelper>
    );

    return (
      <CLPaperControlsContext.Consumer>
        {({ setExpandedContent }) => {
          this._setControlsExpandedContent = setExpandedContent;
          return annnotationContent;
        }}
      </CLPaperControlsContext.Consumer>
    );
  }
}
