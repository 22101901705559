import CLButtonBase, {
  ButtonFontSize as _ButtonFontSize,
  ButtonShape as _ButtonShape,
  ButtonSize as _ButtonSize,
  ButtonType as _ButtonType,
  FONT_SIZE as _FONT_SIZE,
  ICON_POSITION as _ICON_POSITION,
  IconPosition as _IconPosition,
  SHAPE as _SHAPE,
  SIZE as _SIZE,
  TYPE as _TYPE,
  CLButtonBaseProps,
} from './CLButtonBase';

import Icon from '@/components/shared/icon/Icon';

import classnames from 'classnames';
import React from 'react';

import type { ExampleConfig } from '@/components/library/ExampleUtils';
import type { ReactNodeish } from '@/utils/types';

export const FONT_SIZE = _FONT_SIZE;
export const ICON_POSITION = _ICON_POSITION;
export const SHAPE = _SHAPE;
export const SIZE = _SIZE;
export const TYPE = _TYPE;

export type IconPosition = _IconPosition;
export type ButtonFontSize = _ButtonFontSize;
export type ButtonShape = _ButtonShape;
export type ButtonSize = _ButtonSize;
export type ButtonType = _ButtonType;

export type CLIconButtonProps = CLButtonBaseProps;

export default function CLIconButton({ className, ...props }: CLIconButtonProps): ReactNodeish {
  return <CLButtonBase {...props} className={classnames('cl-icon-button', className)} />;
}

export const exampleConfig: ExampleConfig = {
  getComponent: () => CLIconButton,
  fields: [
    {
      name: 'label',
      desc: 'Text displayed within the button',
      value: {
        type: 'text',
        default: null,
      },
    },
    {
      name: 'type',
      desc: 'Importance within the form',
      value: {
        type: 'select',
        default: TYPE.DEFAULT,
        options: [TYPE.DEFAULT, TYPE.PRIMARY, TYPE.SECONDARY, TYPE.TERTIARY],
      },
    },
    {
      name: 'size',
      desc: 'How large the button should be',
      value: {
        type: 'select',
        default: SIZE.DEFAULT,
        options: [SIZE.DEFAULT, SIZE.LARGE, SIZE.SMALL],
      },
    },
    {
      name: 'shape',
      desc: 'The form of the outer edge of the button',
      value: {
        type: 'select',
        default: SHAPE.RECTANGLE,
        options: [SHAPE.RECTANGLE, SHAPE.CIRCLE],
      },
    },
    {
      name: 'icon',
      desc: 'Key to an icon in the icon sprite',
      value: {
        type: 'icon',
        default: null,
        transform: iconKey => (iconKey ? <Icon icon={iconKey} width="12" height="12" /> : null),
      },
    },
    {
      name: 'iconPosition',
      desc: 'Position of icon relative to label',
      value: {
        type: 'select',
        default: ICON_POSITION.LEFT,
        options: [ICON_POSITION.LEFT, ICON_POSITION.RIGHT, ICON_POSITION.UP],
      },
    },
    {
      name: 'className',
      desc: 'HTML classes to be added to the button',
      value: {
        type: 'text',
        default: '',
      },
    },
  ],

  examples: [
    {
      title: 'General',
      desc: 'Button with icon and some simple text',
      props: {
        label: 'Menu',
        type: TYPE.DEFAULT,
        size: SIZE.DEFAULT,
        icon: 'hamburger',
        className: '',
      },
    },
    {
      title: 'Watch Topic',
      desc: 'Watch topic button on the Topic Page',
      props: {
        label: 'Watch Topic',
        type: TYPE.PRIMARY,
        size: SIZE.DEFAULT,
        icon: 'fa-bell',
        className: '',
      },
    },
    {
      title: 'Alerts',
      desc: 'Alerts button in the header nav',
      props: {
        label: 'Alerts',
        type: TYPE.TERTIARY,
        size: SIZE.DEFAULT,
        icon: 'fa-bell',
        className: '',
      },
    },
    {
      title: 'More Menu',
      desc: 'Menu button on Mobile',
      props: {
        type: TYPE.SECONDARY,
        icon: 'hamburger',
      },
    },
    {
      title: 'Carousel Chevron',
      desc: 'The arrow used to move the carousel forward',
      props: {
        type: TYPE.SECONDARY,
        shape: SHAPE.CIRCLE,
        size: SIZE.DEFAULT,
        icon: 'disclosure',
      },
    },
  ],

  events: {
    onClick: (event: React.SyntheticEvent<HTMLInputElement>) => ({
      label: event.currentTarget.innerText,
      class: event.currentTarget.className,
    }),
  },
};
