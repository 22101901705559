import { softErrorOnceInBrowser } from '@/utils/softError';
import EventType from '@/analytics/constants/EventType';

import Immutable from 'immutable';

import type { Nullable } from '@/utils/types';

type EventDataValue = string | number | boolean | undefined;
type EventData = { [key: string]: EventDataValue };

const ALLOWED_TYPES = Immutable.Set(['string', 'number', 'boolean', 'undefined']);

/**
 * Model for an event we'd like to track.  This class should never be tracked directly, the intent is
 * that you create a subclass modeling the specific event you'd like to track.
 * @param {string} eventType - a string uniquely identifying the event, i.e. "click" or "hover"
 * @param {object} [eventData] - data associated with the event.
 */
export default class AnalyticsEvent {
  url: string;
  eventData: Nullable<EventData>;
  eventType: string;
  timestamp: string;
  isMobile: boolean;
  screenSize: {
    height: number;
    width: number;
  };
  viewportSize: {
    height: number;
    width: number;
  };

  constructor(eventType: string, eventData?: Nullable<EventData>) {
    this.url = document.location.toString();
    this.eventData = eventData || null;
    this.timestamp = new Date().toISOString();
    this.isMobile = window.s2isMobile || false;

    if (typeof window !== 'undefined') {
      this.screenSize = {
        width: window.screen.width,
        height: window.screen.height,
      };
      this.viewportSize = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    } else {
      throw new Error('An AnalyticsEvent can only be constructed and recorded client side');
    }

    if (eventType) {
      this.eventType = eventType;
    } else {
      throw new Error('"eventType" was not defined on analytics event.');
    }

    // Make sure that event data is a map where each key and value are both strings,
    // otherwise the server will blow up.
    if (this.eventData) {
      for (const propName of Object.keys(this.eventData)) {
        const propType = typeof this.eventData[propName];
        if (!ALLOWED_TYPES.contains(propType)) {
          const typesList = ALLOWED_TYPES.join(', ');
          softErrorOnceInBrowser(
            'analytics.eventDataInvalid',
            `Invalid event data value, the value of ${propName} is a ${propType} and must be one of: ${typesList}`
          );
        }
      }
    }
  }
  /**
   * Returns the Google Analytics "hit" representing the event. If this isn't specified the event
   * won't be recorded in Google Analytics.
   *
   * @returns {object} the Google Analytics representation of the event.
   */
  toGoogleAnalyticsData(): object {
    const gaData: any = {
      event_action: this.eventType,
      // GA requires a category, so if for some reason it's not set put something there so
      // we don't lose the event.
      event_category: this.eventData && this.eventData.target ? this.eventData.target : 'NO TARGET',
    };

    // The `eventLabel` field is supposed to be a string. If we send a non-string value (even `null`)
    // GA fails to record the event as it's considered an invalid hit. Accordingly we only set the
    // value if we have something to serialize.
    if (this.eventData) {
      gaData.event_label = JSON.stringify(this.eventData);
    }

    // To avoid negatively affecting the bounce rate, we mark the `show` event as `nonInteractive`.
    if (this.eventType === EventType.SHOW) {
      gaData.non_interaction = true;
    }

    return gaData;
  }
}
