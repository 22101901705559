import { extractVenueInformation } from './extractVenueInformation';

import { heapVenueSerpLinkClick } from '@/analytics/attributes/paperObject';
import { HighlightedFieldRecord } from '@/models/HighlightedField';
import { PaperRecord } from '@/models/Paper';
import { RouteName } from '@/router/Routes';
import CLTooltip, { PLACEMENT } from '@/components/library/tooltip/CLTooltip';
import EnvInfo from '@/env/EnvInfo';
import HighlightedField from '@/components/shared/common/HighlightedField';
import Link from '@/router/Link';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import type { ReactNodeish } from '@/utils/types';

type TooltipWrapperProps = React.PropsWithChildren<{
  venue: HighlightedFieldRecord;
}>;

const TooltipWrapper = ({ children, venue }: TooltipWrapperProps): ReactNodeish => (
  <CLTooltip
    overlayClassName="truncated-venue-tooltip"
    placement={PLACEMENT.BOTTOM_LEFT}
    tooltipContent={<HighlightedField field={venue} testId="venue-metadata" />}>
    <span>{children}</span>
  </CLTooltip>
);

type HighlightedPaperVenueProps = {
  paper: PaperRecord;
  stripYear?: boolean;
  maxVenueTextLength?: number;
  isVenueLinkEnabled?: boolean;
  className?: string;
};

/* This is an inner component for PaperVenue. It shouldn't be used directly
 * Use PaperVenue to render the venue as it can handle both Paper and PaperLite models*/
export default class HighlightedPaperVenue extends React.PureComponent<HighlightedPaperVenueProps> {
  static contextTypes = {
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
  };

  declare context: {
    envInfo: EnvInfo;
  };

  venueMetaData(venueText: string) {
    const { paper } = this.props;
    const venueWithExtractedText = paper.venue.merge({ text: venueText });
    return ({ className }: { className?: string }) => (
      <HighlightedField
        field={venueWithExtractedText}
        testId="venue-metadata"
        className={className}
      />
    );
  }

  maybeShowVenueLink(
    shouldShowVenueLink: boolean,
    venueText: string,
    venueRouteObject: { to: RouteName; query: { name: string } }
  ) {
    const { className } = this.props;

    const containerClassName = classNames('cl-paper-venue', className);
    const Content = this.venueMetaData(venueText);

    return shouldShowVenueLink ? (
      <Link
        data-test-id="normalized-venue-link"
        className={containerClassName}
        {...heapVenueSerpLinkClick()}
        {...venueRouteObject}>
        <Content />
      </Link>
    ) : (
      <Content className={containerClassName} />
    );
  }

  render() {
    const { paper, stripYear, maxVenueTextLength, isVenueLinkEnabled } = this.props;

    if (!paper.venue?.text) {
      return null;
    }
    const { venueText, venueRouteObject, isVenueTextTruncated, shouldLinkToVenuePage } =
      extractVenueInformation(paper, stripYear, maxVenueTextLength);

    // Show the venue tooltip if we're not on mobile and the venue is truncated
    const showVenueTooltip = !this.context.envInfo.isMobile && isVenueTextTruncated;
    const shouldShowVenueLink = !!isVenueLinkEnabled && shouldLinkToVenuePage;

    return showVenueTooltip ? (
      <TooltipWrapper venue={paper.venue}>
        {this.maybeShowVenueLink(shouldShowVenueLink, venueText, venueRouteObject)}
      </TooltipWrapper>
    ) : (
      <React.Fragment>
        {this.maybeShowVenueLink(shouldShowVenueLink, venueText, venueRouteObject)}
      </React.Fragment>
    );
  }
}
