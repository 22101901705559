import React from 'react';

export default function IconViewDense(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 10 8">
      <rect width="10" height="2" rx="1" />
      <rect y="3" width="10" height="2" rx="1" />
      <rect y="6" width="10" height="2" rx="1" />
    </symbol>
  );
}
