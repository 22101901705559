import { loadSourcemappedStacktrace } from './layoverAsyncModules';

import { delayFor } from '@/utils/promise-utils';
import { isBrowser } from '@/utils/env';
import softError from '@/utils/softError';

// Duration of time we are willing to wait for parsing stack traces
const MAX_DELAY_UNTIL_FAIL_MS = 5000;

// Flag whether stack parsing failure has been logged
let hasLoggedError = false;

export default class LayoverStackTraceParser {
  async parse(error: Error): Promise<string> {
    const compiledStack: string = error.stack || '';
    if (!isBrowser()) {
      return compiledStack; // Server stack is good enough
    }
    const resultStack: string = await Promise.race([
      this._parseStack(compiledStack),
      (async () => {
        await delayFor(MAX_DELAY_UNTIL_FAIL_MS);
        throw new Error('failed to parse error stack before timeout expired');
      })(),
    ]).catch(error => {
      if (!hasLoggedError) {
        hasLoggedError = true;
        softError(
          'stackParseFailure',
          'failed to create a source stacktrace from compiled stack',
          error.message
        );
      }
      return compiledStack;
    });
    return resultStack;
  }

  async _parseStack(compiledStack: string): Promise<string> {
    const { mapStackTrace } = await loadSourcemappedStacktrace();
    return new Promise((resolve, reject) => {
      mapStackTrace(
        compiledStack,
        sourceStack => {
          if (sourceStack.length === 0) {
            reject(new Error('failed to parse error stack using sourcemaps'));
          } else {
            resolve(sourceStack.join('\n'));
          }
        },
        {
          cacheGlobally: true, // sourcemaps are cached across multiple mapStackTrace() calls
        }
      );
    });
  }
}
