import { EntitlementSourceValue, getEntitlementSourceValue } from './EntitlementSource';

import Immutable from 'immutable';

export type PaperEntitlementFromJS = {
  url: string;
  source: any;
};

type Props = {
  url: string;
  source: EntitlementSourceValue;
};

const defaultProps: Props = {
  url: '',
  source: '' as EntitlementSourceValue,
};

export const PaperEntitlementFactory = Immutable.Record<Props>(defaultProps);
export type PaperEntitlementRecord = Immutable.RecordOf<Props>;

export function getPaperEntitlementRecordFromJs({ url, source }: PaperEntitlementFromJS) {
  return PaperEntitlementFactory({
    url: url,
    source: getEntitlementSourceValue(source.value),
  });
}
