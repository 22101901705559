import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

export default class AdminEnrollmentsRoute extends React.Component {
  static getPageTitle = () => getString(_ => _.general.appName);

  static willRouteTo({ api }) {
    return api.getAllEnrollments();
  }

  render() {
    return (
      <AsyncLoadedPage
        showLoadingPage={false}
        load={{
          importer: () =>
            import(/* webpackChunkName: "desktop-AdminEnrollmentsPage" */ './AdminEnrollmentsPage'),
          chunkName: 'desktop-AdminEnrollmentsPage',
          moduleId: require.resolveWeak('./AdminEnrollmentsPage'),
        }}
      />
    );
  }
}
