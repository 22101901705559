// This should be kept in sync with the corresponding Scala-side LibraryEntry model

import {
  getLibraryEntrySourceType,
  LibraryEntrySourceType,
  Unsupported as UnsupportedSourceType,
} from './LibraryEntrySourceType';
import {
  getLibraryEntryStatus,
  LibraryEntryStatus,
  Unsupported as UnsupportedStatus,
} from './LibraryEntryStatus';
import {
  getOptLibraryEntryAnnotationState,
  LibraryEntryAnnotationState,
  Unsupported as UnsupportedAnnotationState,
} from './LibraryEntryAnnotationState';

import { getPaperFromJS, PaperFromJS, PaperRecord } from '@/models/Paper';
import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

export const RECORD_NAME = 'LibraryEntry';

export type LibraryEntryFromJS = {
  id: number;
  createdAtUtc: number;
  modifiedAtUtc: number;
  status: string;
  annotationState?: string;
  // TODO: In practice, this is never null. Correct the data model?
  sourceType?: string;
  folderId?: number;
  paper?: PaperFromJS;
  paperId: string;
  paperTitle: string;
  corpusId?: number;
};

export type Props = {
  id: number;
  createdAtUtc: number;
  modifiedAtUtc: number;
  status: LibraryEntryStatus;
  annotationState: Nullable<LibraryEntryAnnotationState>;
  sourceType: LibraryEntrySourceType;
  folderId: Nullable<number>;
  paper: Nullable<PaperRecord>;
  paperId: string;
  paperTitle: string;
  corpusId: Nullable<number>;
};

const defaultProps: Props = {
  id: -1,
  createdAtUtc: 0,
  modifiedAtUtc: 0,
  status: UnsupportedStatus,
  annotationState: UnsupportedAnnotationState,
  sourceType: UnsupportedSourceType,
  folderId: null,
  paper: null,
  paperId: '',
  paperTitle: '',
  corpusId: null,
};

export const LibraryEntryRecordFactory = Immutable.Record(defaultProps, RECORD_NAME);
export type LibraryEntryRecord = Immutable.RecordOf<Props>;

export function getLibraryEntryFromJS(args: LibraryEntryFromJS): LibraryEntryRecord {
  return LibraryEntryRecordFactory({
    id: args.id,
    createdAtUtc: args.createdAtUtc,
    modifiedAtUtc: args.modifiedAtUtc,
    status: getLibraryEntryStatus(args.status),
    annotationState: getOptLibraryEntryAnnotationState(args.annotationState),
    sourceType: getLibraryEntrySourceType(args.sourceType),
    folderId: args.folderId ? args.folderId : null,
    paper: args.paper ? getPaperFromJS(args.paper) : null,
    paperId: args.paperId,
    paperTitle: args.paperTitle,
    corpusId: args.corpusId,
  });
}
