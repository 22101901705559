import { debounce } from '@/debounce';
import { getString } from '@/content/i18n';
import {
  KEY_CODE_DOWN,
  KEY_CODE_ENTER,
  KEY_CODE_ESC,
  KEY_CODE_TAB,
  KEY_CODE_UP,
} from '@/constants/KeyCode';
import { mapAppContextToProps, useStateFromStore } from '@/AppContext';
import { mkOnClickKeyDown } from '@/utils/a11y-utils';
import { QueryRecordFactory } from '@/models/Query';
import Api from '@/api/Api';
import constants from '@/constants';
import EnvInfo from '@/env/EnvInfo';
import EventTarget from '@/analytics/constants/EventTarget';
import HighlightedField from '@/components/shared/common/HighlightedField';
import Icon from '@/components/shared/icon/Icon';
import QueryRoutes from '@/utils/routing/query-routes';
import QueryStore from '@/stores/QueryStore';
import Routes, { makePath } from '@/router/Routes';
import S2Dispatcher from '@/utils/S2Dispatcher';
import S2History from '@/utils/S2History';
import SearchBarRecommendationsMessage, {
  getRecommendationsMessage,
} from '@/components/shared/research/SearchBarRecommendationMessage';
import SelectEvent from '@/analytics/models/SelectEvent';
import ShowEvent from '@/analytics/models/ShowEvent';
import SubmitEvent from '@/analytics/models/SubmitEvent';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import classNames from 'classnames';
import Immutable from 'immutable';
import invariant from 'invariant';
import PropTypes from 'prop-types';
import React from 'react';

// TODO  #29139 - Refactor to use App Context instead of isResearchFTUE prop

const SUGGESTION_CACHE_LIMIT = 10000;
export const SUGGESTION_DEBOUNCE_DELAY_MS = 400;
// $FlowFixMe: Flow has trouble with ordered sets
// entity and venue were removed as options but are values provided by the backend
const SUGGESTION_TYPES = Immutable.OrderedSet(['author', 'paper']);
const MIN_CHARS_COMPLETIONS = 3;
const MIN_CHARS_QUERY = 2;

function getSelectedSuggestionText(suggestion, quoteTermWithSpaces = true) {
  if ((quoteTermWithSpaces && suggestion.type === 'paper') || suggestion.type === 'author') {
    return `"${suggestion.text.text.replace(/"/g, '')}"`;
  } else {
    return suggestion.text.text;
  }
}

// We disable the no-autofocus rule because we want to allow focus on the homepage searchbar but
// nowhere else
/* eslint-disable jsx-a11y/no-autofocus */
export class Searchbar extends React.PureComponent {
  static defaultProps = {
    submitOnFieldOfStudyChange: false,
  };

  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    history: PropTypes.instanceOf(S2History).isRequired,
    queryStore: PropTypes.instanceOf(QueryStore).isRequired,
    router: PropTypes.object.isRequired,
  };

  constructor(...args) {
    super(...args);

    // HACK: Don't set query text in the Searchbar on the AHP since it is used to filter the author's papers instead.
    const queryText =
      this.context.router.route.match.path === Routes.AUTHOR_PROFILE
        ? ''
        : this.context.queryStore.getQueryString();

    this.state = {
      // The current text value in the input bar
      queryText,

      // The current query for which we should display suggestions. This lags behind queryText, as
      // while we're fetching suggestions for the current, entered query we display the suggestions
      // we fetched previously
      suggestQueryText: queryText,

      // Whether to display the suggestions. Set to true when we
      // start typing, set to false by explicit user action.
      showSuggestions: false,

      // A cache of suggestions keyed by queryText. Reset when we navigate.
      suggestions: Immutable.Map(),

      // The index of the highlighted suggestion, if any.
      selectedSuggestionIndex: null,

      // Determines whether the "see all results for query" link is selected
      isSeeAllSelected: false,

      // Is the query string valid
      isQueryValid: false,

      inputFocused: !!this.props.autoFocus,

      showRecommendationsMessage: false,

      // indicates where focus on search bar is called from on the feeds page
      recommendationsLocation: null,
    };

    this.context.queryStore.registerComponent(this, () => {
      // HACK: Don't set query text in the Searchbar on the AHP since it is used to filter the author's papers instead.
      const queryText =
        this.context.router.route.match.path === Routes.AUTHOR_PROFILE
          ? ''
          : this.context.queryStore.getQueryString();

      this.setState({
        queryText,
        suggestQueryText: queryText,
      });
    });

    this.dispatchToken = this.context.dispatcher.register(event => {
      if (event.actionType === constants.actions.FOCUS_ON_SEARCH_BAR) {
        this.focusOnSearchBar({
          location: event.location,
          queryText: event.queryText,
        });
      }
    });
  }

  componentDidMount() {
    // Rate limit API requests until the user has stopped typing for a moment (though fire on the
    // leading edge to get quick results for typing one character). This only needs to happen on
    // the client, as we don't fetch suggestions on the server.
    // $FlowFixMe: debounce is not typed, which breaks the assignment here
    this.fetchSuggestions = debounce(this.fetchSuggestions, SUGGESTION_DEBOUNCE_DELAY_MS, {
      leading: true,
      trailing: true,
    });
  }

  componentWillUnmount() {
    if (this.fetchSuggestions) {
      this.fetchSuggestions.cancel();
    }

    this.context.dispatcher.unregister(this.dispatchToken);
  }

  componentDidUpdate(prevProps, prevState) {
    const newSuggestions = this.getSuggestionsFromState();
    const hasNewSuggestions =
      !newSuggestions.isEmpty() &&
      !Immutable.is(this.getSuggestionsFromState(prevState), newSuggestions);
    // If the suggestions are now visible and previously they weren't or we updated the
    // suggestions that are visible, track an event.
    if (
      this.shouldDisplaySuggestions(this.state) &&
      (!this.shouldDisplaySuggestions(prevState) || hasNewSuggestions)
    ) {
      trackAnalyticsEvent(
        ShowEvent.create(EventTarget.AUTOCOMPLETE_MENU, {
          suggestionCount: newSuggestions.size,
        })
      );
    }
  }

  buildCachedSuggestionsKey(queryText) {
    return queryText;
  }

  getSuggestionsFromState(state = this.state) {
    const cacheKey = this.buildCachedSuggestionsKey(state.suggestQueryText);
    return state.suggestions.get(cacheKey) || Immutable.List();
  }

  shouldDisplaySuggestions(state = this.state) {
    return state.showSuggestions && !this.getSuggestionsFromState(state).isEmpty();
  }

  fetchSuggestions() {
    const { queryText } = this.state;
    if (queryText.length < MIN_CHARS_COMPLETIONS) {
      return;
    }
    this.context.api.fetchSearchSuggestions(queryText).then(payload => {
      const suggestions = Immutable.List(payload.resultData.suggestions);
      this.setState(prevState => {
        const cachedSuggestions =
          prevState.suggestions.size < SUGGESTION_CACHE_LIMIT
            ? prevState.suggestions
            : Immutable.Map();
        const cacheKey = this.buildCachedSuggestionsKey(queryText);
        const updatedSuggestions = cachedSuggestions.set(cacheKey, suggestions);

        // Only update the value of suggestQueryText if the current value of the search input matches
        // the suggestions that were returned.  Otherwise we risk showing suggestions for a
        // prior value of the input because the API request took longer than a subsequent one.
        const suggestQueryText =
          prevState.queryText === queryText ? queryText : prevState.suggestQueryText;
        return {
          suggestions: updatedSuggestions,
          suggestQueryText,
        };
      });
    });
  }

  setQueryStringValid = queryString => {
    return this.setState({ isQueryValid: queryString && queryString.length >= MIN_CHARS_QUERY });
  };

  onChange = event => {
    const queryText = event.currentTarget.value;
    this.setQueryStringValid(queryText);

    // If the user has entered > 2 chars, display any suggestions we might already have, and/or
    // query for new suggestions
    if (queryText.length >= MIN_CHARS_COMPLETIONS) {
      const cacheKey = this.buildCachedSuggestionsKey(queryText);
      // $FlowFixMe: This looks to be an actual bug, but I can't figure out what it should be doing
      const hasCachedSuggestions = this.getSuggestionsFromState().has(cacheKey);
      const suggestQueryText = hasCachedSuggestions ? queryText : this.state.suggestQueryText;

      this.setState(
        {
          queryText,
          suggestQueryText,
          showSuggestions: true,
          selectedSuggestionIndex: null,
          showRecommendationsMessage: false,
        },
        () => {
          if (!hasCachedSuggestions) {
            this.fetchSuggestions();
          }
        }
      );
    } else {
      // If there's less than 2 chars, don't query anything (and hide any visible suggestions)
      this.setState({
        queryText,
        suggestQueryText: queryText,
        showSuggestions: false,
        selectedSuggestionIndex: null,
      });
    }
  };

  submitSearch = () => {
    const queryText = this.removeTrailingBackSlash(this.state.queryText);
    const { linkedId, type } = this.getSelectedSuggestion() || {};

    if (queryText.length > 0) {
      this.setState(
        {
          showSuggestions: false,
          selectedSuggestionIndex: null,
          isSeeAllSelected: false,
          inputFocused: false,
        },
        () => {
          if (this.queryInput) {
            this.queryInput.blur();
          }

          // We need to broadcast this *before* triggering the onChange, so that the url of the
          // associated event is correct (the page wherein they made the query)
          trackAnalyticsEvent(SubmitEvent.create(EventTarget.SEARCH_FORM, { query: queryText }));

          const { history, router } = this.context;

          if (type === 'paper') {
            history.push(
              makePath({
                routeName: 'PAPER_DETAIL_BY_ID',
                params: { paperId: linkedId },
              })
            );
          } else {
            const query = QueryRecordFactory({
              queryString: queryText,
            });

            QueryRoutes.changeRouteForQuery(query, history, router, 'SEARCH');
          }
        }
      );
    }
  };

  removeTrailingBackSlash = query => {
    // trailing backslashes causes query to fail
    return query.trim().replace(/\\+$/g, '');
  };

  onSubmit = event => {
    event.preventDefault();
    this.submitSearch();
  };

  _onClickKeyDownSubmitProps = mkOnClickKeyDown({
    onClick: this.onSubmit,
  });

  /**
   * Sets the input value to the text of the current selected autocomplete entry and tracks an
   * analytic indicating that the autocomplete entry was "selected".
   *
   * @param {string} selectionMethod the trigger via which the entry was selected, can be one
   * of 'click', 'tab' or 'enter'.
   * @returns {undefined}
   */
  setInputValueToSelectedAutocompleteEntry = (selectionMethod, callback = () => {}) => {
    const originalQueryText = this.state.queryText;
    const selectedIndex = this.state.selectedSuggestionIndex;
    const selectedSuggestion = this.getSelectedSuggestion();
    if (!selectedSuggestion) {
      return;
    }
    const queryText = getSelectedSuggestionText(selectedSuggestion, selectionMethod !== 'tab');

    this.setState({ queryText }, callback);

    trackAnalyticsEvent(
      SelectEvent.create(EventTarget.AUTOCOMPLETE_ENTRY, {
        selectedText: selectedSuggestion.text.text,
        selectedType: selectedSuggestion.type,
        selectedIndex,
        selectionMethod,
        entered: originalQueryText,
        ...this.getSuggestionCountByType().toJS(),
      })
    );
  };

  getSelectedSuggestion() {
    if (this.hasSelectedSuggestion()) {
      const { selectedSuggestionIndex } = this.state;
      invariant(
        typeof selectedSuggestionIndex === 'number',
        'hasSelectedSuggestion() failed to validate selectedSuggestionIndex'
      );
      const suggestions = this.getSuggestionsFromState();
      return suggestions.get(selectedSuggestionIndex);
    } else {
      return undefined;
    }
  }

  getSuggestionCountByType() {
    const suggestions = this.getSuggestionsFromState();
    return suggestions.reduce((accumulator, suggestion) => {
      const type = `${suggestion.type}SuggestionCount`;
      const typeCount = accumulator.has(type) ? accumulator.get(type) : 0;
      return accumulator.set(type, typeCount + 1);
    }, Immutable.Map());
  }

  hasSelectedSuggestion() {
    const i = this.state.selectedSuggestionIndex;
    return typeof i === 'number' && i >= 0 && this.getSuggestionsFromState().get(i) ? true : false;
  }

  clearSearch = () => {
    this.setState(
      {
        queryText: '',
        suggestQueryText: '',
        inputFocused: false,
        showSuggestions: false,
        selectedSuggestionIndex: null,
        isSeeAllSelected: false,
      },
      () => {
        if (this.queryInput) {
          this.queryInput.focus();
        }
      }
    );
  };

  onKeyDown = e => {
    switch (e.keyCode) {
      case KEY_CODE_ENTER: {
        if (this.hasSelectedSuggestion()) {
          e.preventDefault();
          this.setInputValueToSelectedAutocompleteEntry('enter', this.submitSearch);
        }
        break;
      }

      case KEY_CODE_TAB: {
        if (this.hasSelectedSuggestion()) {
          e.preventDefault();
          this.setInputValueToSelectedAutocompleteEntry('tab');
          this.setState({
            showSuggestions: false,
            inputFocused: false,
            selectedSuggestionIndex: null,
            isSeeAllSelected: false,
          });
        } else if (this.state.isSeeAllSelected) {
          e.preventDefault();
        }
        break;
      }

      case KEY_CODE_ESC: {
        this.clearSearch();
        break;
      }

      case KEY_CODE_UP: {
        const suggestions = this.getSuggestionsFromState();
        if (this.state.showSuggestions && !suggestions.isEmpty()) {
          e.preventDefault();
          if (this.state.isSeeAllSelected) {
            this.selectSuggestion(suggestions.size - 1, () => {
              this.setInputValueToSelectedAutocompleteEntry('arrow-up');
            });
          } else if (this.state.selectedSuggestionIndex === null) {
            this.selectSeeAll();
          } else if (this.state.selectedSuggestionIndex === 0) {
            this.selectNone();
          } else if (typeof this.state.selectedSuggestionIndex === 'number') {
            this.selectSuggestion(this.state.selectedSuggestionIndex - 1, () => {
              this.setInputValueToSelectedAutocompleteEntry('arrow-up');
            });
          }
        }
        break;
      }

      case KEY_CODE_DOWN: {
        const suggestions = this.getSuggestionsFromState();
        if (this.state.showSuggestions && !suggestions.isEmpty()) {
          e.preventDefault();
          if (this.state.isSeeAllSelected) {
            this.selectNone();
          } else if (this.state.selectedSuggestionIndex === null) {
            this.selectSuggestion(0, () => {
              this.setInputValueToSelectedAutocompleteEntry('arrow-down');
            });
          } else if (this.state.selectedSuggestionIndex === suggestions.size - 1) {
            this.selectSeeAll();
          } else if (typeof this.state.selectedSuggestionIndex === 'number') {
            this.selectSuggestion(this.state.selectedSuggestionIndex + 1, () => {
              this.setInputValueToSelectedAutocompleteEntry('arrow-down');
            });
          }
        }
        break;
      }
    }
  };

  hideOverlay(callback) {
    this.setState(
      {
        showSuggestions: false,
        selectedSuggestionIndex: null,
        isSeeAllSelected: false,
        inputFocused: false,
      },
      callback
    );
  }

  onOverlayClick = () => {
    // Blur the input, which in turn cause the overlay to be hidden
    if (this.queryInput) {
      this.queryInput.blur();
    }
  };

  _onClickKeyDownOverlayProps = mkOnClickKeyDown({
    onClick: this.onOverlayClick,
    overrideKeys: [KEY_CODE_ESC],
  });

  onFocus = () => {
    this.setState(
      {
        showSuggestions: true,
        inputFocused: true,
      },
      () => {
        if (this.props.onFocus) {
          this.props.onFocus();
        }
      }
    );
  };

  focusOnSearchBar({ location, queryText } = {}) {
    this.onFocus();
    const msg = getRecommendationsMessage(location);
    const newState = {
      showRecommendationsMessage: !!msg,
      recommendationsLocation: location,
    };
    if (typeof queryText === 'string') {
      newState.queryText = queryText;
    }
    this.setState(newState, () => {
      if (this.queryInput) {
        this.queryInput.focus();
      }
    });
  }

  onSuggestionMouseDown = e => {
    // prevent blur when clicking on suggestions
    e.preventDefault();
  };

  onBlur = () => {
    const { showRecommendationsMessage } = this.state;
    this.hideOverlay(() => {
      if (typeof this.props.onBlur === 'function') {
        this.props.onBlur();
      }
    });

    if (showRecommendationsMessage) {
      this.setState({
        showRecommendationsMessage: false,
      });
    }
  };

  selectSuggestion(index, afterSelected) {
    this.setState({ selectedSuggestionIndex: index, isSeeAllSelected: false }, afterSelected);
  }

  selectSeeAll = () => {
    this.setState({ selectedSuggestionIndex: null, isSeeAllSelected: true });
  };

  selectNone = () => {
    this.setState({ selectedSuggestionIndex: null, isSeeAllSelected: false });
  };

  renderSuggestions() {
    const { selectedSuggestionIndex, queryText, isSeeAllSelected } = this.state;
    const seeAllClassName = classNames('flex-row-vcenter see-all-link', {
      cursor: isSeeAllSelected,
    });

    const suggestions = this.getSuggestionsFromState();

    // groups the suggestions by type and adds an index
    // we use the index later on to know which suggestion was selected
    const groupedSuggestions = suggestions
      .map((suggestion, index) => {
        return {
          suggestion,
          index,
        };
      })
      .groupBy(({ suggestion }) => suggestion.type);

    return (
      <ul
        className="dropdown-menu"
        data-test-id="autocomplete-suggestions"
        onMouseLeave={this.selectNone}
        role="listbox">
        {SUGGESTION_TYPES.map(type => {
          if (groupedSuggestions.get(type)) {
            return (
              <React.Fragment key={`${type}-suggestions`}>
                <ul>
                  <li className="dropdown-menu__header">
                    {getString(_ => _.appHeader.searchHeaders[type])}
                  </li>
                  {groupedSuggestions.get(type).map(suggestionInfo => {
                    const { suggestion, index } = suggestionInfo;
                    const { type, text, disambiguation } = suggestion;
                    const suggestionClass = classNames('flex-row suggestion', {
                      cursor: index === selectedSuggestionIndex,
                    });

                    const onSuggestionClicked = () => {
                      this.selectSuggestion(index, () => {
                        this.setInputValueToSelectedAutocompleteEntry('click', this.submitSearch);
                      });
                    };

                    const _onClickKeyDownSuggestionProps = mkOnClickKeyDown({
                      onClick: onSuggestionClicked,
                    });

                    const iconClasses = classNames(
                      'type-icon flex-static',
                      SUGGESTION_TYPES.get(type, 'search')
                    );

                    const iconName =
                      type === 'paper' ? 'tab-papers' : SUGGESTION_TYPES.get(type, 'search');

                    return (
                      <li
                        key={`${text.text} ${index}`}
                        data-test-id={`${type}-suggestion`}
                        className={suggestionClass}
                        role="option"
                        aria-selected={selectedSuggestionIndex === index}
                        onMouseEnter={() => this.selectSuggestion(index)}
                        onMouseDown={this.onSuggestionMouseDown}
                        {..._onClickKeyDownSuggestionProps}>
                        <Icon className={iconClasses} width="15" height="15" icon={iconName} />
                        <div className="flex-column truncate-line">
                          <HighlightedField
                            className="suggestion__text truncate-line"
                            field={text}
                          />
                          {disambiguation && <span className="text--gray">{disambiguation}</span>}
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <hr className="dropdown-menu__seperator" />
              </React.Fragment>
            );
          }
        })}
        <li
          className={seeAllClassName}
          role="option"
          onMouseEnter={this.selectSeeAll}
          aria-selected={isSeeAllSelected}
          onMouseDown={this.onSuggestionMouseDown}
          {...this._onClickKeyDownSubmitProps}>
          <div>{getString(_ => _.appHeader.seeAllLabel, queryText)}</div>
        </li>
      </ul>
    );
  }

  renderOverlay() {
    const { showSuggestions, suggestQueryText } = this.state;
    if (showSuggestions && (!this.props.autoFocus || suggestQueryText)) {
      // In order to avoid distracting flashes,
      // use a click on the overlay to blur the search input.
      return (
        <div id="search-overlay" className="search-overlay" {...this._onClickKeyDownOverlayProps} />
      );
    } else {
      return null;
    }
  }

  render() {
    const { showRecommendationsMessage, recommendationsLocation } = this.state;
    const { envInfo } = this.context;
    const { isResearchFTUE } = this.props;
    const formClasses = classNames('search-bar v2-search-bar', {
      'is-focused': this.state.inputFocused,
      'showing-suggestions': this.state.showSuggestions && this.getSuggestionsFromState().size > 0,
    });

    const placeholderText = envInfo.isMobile
      ? getString(_ => _.searchBar.searchTextMobile, this.props.pageCount.toLocaleString())
      : getString(_ => _.searchBar.searchText, this.props.pageCount.toLocaleString());

    return (
      <form
        className={formClasses}
        id="search-form"
        role="search"
        autoComplete="off"
        action={Routes.SEARCH}
        onSubmit={this.onSubmit}>
        {this.renderOverlay()}
        <div
          className={classNames('flex-row-vcenter  input-container', {
            'input-container--research-ftue': isResearchFTUE,
          })}>
          <div
            className={classNames('flex-row-vcenter input-bg', {
              'input-bg--research-ftue': isResearchFTUE,
            })}>
            <label htmlFor="q" className="search-input__label">
              {!this.state.queryText && placeholderText}
            </label>
            <input
              type="search"
              name="q"
              aria-label={getString(_ => _.appHeader.searchInputAriaLabel)}
              className={classNames('legacy__input input form-input search-bar__input', {
                'form--research-ftue': isResearchFTUE,
                'has-text': !!this.state.queryText,
              })}
              ref={queryInput => (this.queryInput = queryInput)}
              onKeyDown={this.onKeyDown}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              autoFocus={this.props.autoFocus}
              onChange={this.onChange}
              value={this.state.queryText}
            />
            <button
              disabled={!this.state.isQueryValid}
              aria-label={getString(_ => _.appHeader.searchSubmitAriaLabel)}
              aria-disabled={!this.state.isQueryValid}
              data-test-id="search__form-submit"
              className={classNames('form-submit form-submit__icon-text', {
                'form-submit--research-ftue': isResearchFTUE,
              })}>
              <div className="flex-row-vcenter">
                {!isResearchFTUE && (
                  <span className="form-submit-label">
                    {getString(_ => _.appHeader.searchButtonLabel)}
                  </span>
                )}
                <Icon width="13" height="13" icon="search-small" />
              </div>
            </button>
          </div>
        </div>
        {showRecommendationsMessage && recommendationsLocation && (
          <SearchBarRecommendationsMessage location={recommendationsLocation} />
        )}
        {this.shouldDisplaySuggestions() ? this.renderSuggestions() : null}
      </form>
    );
  }
}

export default mapAppContextToProps(Searchbar, appContext => {
  const indexMetadataStoreProps = useStateFromStore(appContext.indexMetadataStore, _ => ({
    pageCount: _.getTotalPapersCount(),
  }));

  return {
    ...indexMetadataStoreProps,
  };
});
