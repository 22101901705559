import { ApiResponse, CreateLibraryFolderResponseBody } from '@/api/ApiResponse';
import {
  DENSITY as BUTTON_DENSITY,
  FONT_SIZE as BUTTON_FONT_SIZE,
} from '@/components/library/button/CLButtonBase';
import { getLibraryFolderFromJS, LibraryFolderRecord } from '@/models/library/LibraryFolder';
import { getString } from '@/content/i18n';
import {
  heapLibraryShelfAddFolderCancel,
  heapLibraryShelfAddFolderSubmit,
} from '@/analytics/attributes/libraryShelf';
import { maybeCreateAlert } from '@/utils/alert-util';
import { Nullable, ReactNodeish } from '@/utils/types';
import { Off, On } from '@/models/library/RecommendationStatus';
import { QUERY_TYPE } from '@/constants/Alert';
import Api from '@/api/Api';
import AuthStore from '@/stores/AuthStore';
import CLButton, { TYPE } from '@/components/library/button/CLButton';
import CLFieldError, { ARROW_SIDE } from '@/components/library/form/error/CLFieldError';
import CLTextInput from '@/components/library/form/input/CLTextInput';
import EnvInfo from '@/env/EnvInfo';
import FormLevelMessage from '@/components/shared/message/FormLevelMessage';
import Icon from '@/components/shared/icon/Icon';
import LoadingIndicator from '@/components/shared/loading/LoadingIndicator';
import softError from '@/utils/softError';
import Toggle from '@/components/shared/common/form/Toggle';
import WeblabStore from '@/weblab/WeblabStore';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

type Props = {
  onClickClose?: Nullable<(event: React.SyntheticEvent) => void>;
  onSuccessfulSave?: Nullable<(folder: LibraryFolderRecord) => void>;
};

type FormState = {
  folderName: string;
  hasFolderRecommendationsOn: boolean;
};
type State = {
  isSending: boolean;
  isFolderNameMissingError: boolean;
  errorMessage: Nullable<string>;
} & FormState;

export const DEFAULT_FORM_STATE = Object.freeze({
  folderName: '',
  hasFolderRecommendationsOn: true,
});

const preventDefault = event => {
  event.preventDefault();
};

export default class AddFolderForm extends React.PureComponent<Props, State> {
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    authStore: PropTypes.instanceOf(AuthStore).isRequired,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    weblabStore: PropTypes.instanceOf(WeblabStore).isRequired,
  };

  declare context: {
    api: Api;
    authStore: AuthStore;
    envInfo: EnvInfo;
    weblabStore: WeblabStore;
  };

  _formRef: Nullable<HTMLElement>;

  constructor(...args: [any]) {
    super(...args);

    this.state = {
      ...DEFAULT_FORM_STATE,
      isSending: false,
      isFolderNameMissingError: false,
      errorMessage: null,
    };
  }

  componentDidMount(): void {
    this.focusOnFirstInput();
  }

  setFormRef = (ref: Nullable<HTMLElement>): void => {
    this._formRef = ref;
  };

  onChangeFolderName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ folderName: event.target.value, isFolderNameMissingError: false });
  };

  onClickRecommendationsToggle = (): void => {
    this.setState({ hasFolderRecommendationsOn: !this.state.hasFolderRecommendationsOn });
  };

  onSubmitAddFolderForm = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    const { onSuccessfulSave } = this.props;
    const { folderName, hasFolderRecommendationsOn } = this.state;
    if (!folderName.trim()) {
      this.setState({ isFolderNameMissingError: true });
      return;
    }

    this.setState({
      isSending: true,
      isFolderNameMissingError: false,
      errorMessage: null,
    });
    this.submitCreateFolder({ folderName, hasFolderRecommendationsOn })
      .then(response => {
        if (onSuccessfulSave) {
          onSuccessfulSave(getLibraryFolderFromJS(response.resultData.folder));
        }
        this.setState({
          isSending: false,
          folderName: DEFAULT_FORM_STATE.folderName,
        });
      })
      .catch(error => {
        softError('addFolderForm', 'failed to create new library folder', error);

        if (error.errorCode === 'LibraryTagAlreadyExistsError') {
          this.setState({
            isSending: false,
            errorMessage: getString(_ => _.library.addFolderForm.folderExistsErrorMessage),
          });

          return;
        }

        this.setState({
          isSending: false,
          errorMessage: getString(_ => _.library.addFolderForm.errorMessage),
        });
      });
  };

  focusOnFirstInput(): void {
    if (!this._formRef) {
      return;
    }
    const inputEl = this._formRef.querySelector<HTMLInputElement>('input[type="text"], textarea');
    if (inputEl) {
      inputEl.focus();
    }
  }

  onNameInputFocus = (): void => {
    this.setState({
      isFolderNameMissingError: false,
      errorMessage: null,
    });
  };

  async submitCreateFolder({
    folderName,
    hasFolderRecommendationsOn,
  }: {
    folderName: string;
    hasFolderRecommendationsOn: boolean;
  }): Promise<ApiResponse<CreateLibraryFolderResponseBody>> {
    const { api, authStore } = this.context;
    const response = await api.createLibraryFolder({
      name: folderName,
      recommendationStatus: hasFolderRecommendationsOn ? On : Off,
    });

    maybeCreateAlert(api, authStore, QUERY_TYPE.LIBRARY_FOLDER, response.resultData.folder.id);

    await api.getLibraryFolders(); // Refresh folders in shelf

    return response;
  }

  render(): ReactNodeish {
    const {
      isSending,
      isFolderNameMissingError,
      folderName,
      hasFolderRecommendationsOn,
      errorMessage,
    } = this.state;
    const { onClickClose } = this.props;
    const { isMobile } = this.context.envInfo;

    return (
      <div className="add-folder__form__wrapper">
        <div className="add-folder__form__arrow" />
        <form
          className={classnames('add-folder__form', {
            'add-folder__form--mobile': isMobile,
          })}
          data-test-id="add-folder-form"
          onSubmit={preventDefault}
          ref={this.setFormRef}>
          {!!errorMessage && (
            <div className="add-folder__form__error-msg">
              <FormLevelMessage message={errorMessage} />
            </div>
          )}
          <div
            className={classnames('add-folder__form__row', 'add-folder__form__row--text-input', {
              'add-folder__form__row--text-input__has-name-error': isFolderNameMissingError,
              'add-folder__form__row--mobile': isMobile,
            })}>
            <CLTextInput
              data-test-id="add-folder-form-name"
              value={folderName}
              onFocus={this.onNameInputFocus}
              className="add-folder-form-name--text-input"
              onChange={this.onChangeFolderName}
              placeholder={getString(_ => _.library.addFolderForm.folderNameLabel)}
            />
            {isFolderNameMissingError && (
              <CLFieldError
                className="add-folder__row__error"
                data-test-id="add-folder-form-name-error"
                arrow={ARROW_SIDE.TOP}
                widthPx={200}>
                {getString(_ => _.library.addFolderForm.missingNameErrorMessage)}
              </CLFieldError>
            )}
          </div>
          <div
            className={classnames({
              'add-folder__form__row': true,
              'add-folder__form__row--recs-toggle': true,
              'add-folder__form__row--has-folder-recs-on': hasFolderRecommendationsOn,
            })}>
            <Toggle
              testId="add-form-recs-toggle"
              selected={hasFolderRecommendationsOn}
              onClick={this.onClickRecommendationsToggle}
              id="add-folder__form__recs__toggle">
              <Icon icon="lightning-bolt" height="16" width="8" />
              <span>{getString(_ => _.library.addFolderForm.feedToggleLabel)}</span>
            </Toggle>
          </div>
          <div
            className={classnames('add-folder__form__row', 'add-folder__form__row--actions', {
              'add-folder__form__row--actions--mobile': isMobile,
            })}>
            <div>
              <CLButton
                type={TYPE.PRIMARY}
                fontSize={isMobile ? BUTTON_FONT_SIZE.MEDIUM : BUTTON_FONT_SIZE.DEFAULT}
                density={isMobile ? BUTTON_DENSITY.COMFORTABLE : BUTTON_DENSITY.DEFAULT}
                testId="add-folder-form-submit"
                {...heapLibraryShelfAddFolderSubmit()}
                label={getString(_ => _.library.addFolderForm.submitButtonLabel)}
                onClick={this.onSubmitAddFolderForm}
              />
            </div>
            <div>
              <CLButton
                type={TYPE.SECONDARY}
                fontSize={isMobile ? BUTTON_FONT_SIZE.MEDIUM : BUTTON_FONT_SIZE.DEFAULT}
                density={isMobile ? BUTTON_DENSITY.COMFORTABLE : BUTTON_DENSITY.DEFAULT}
                testId="add-folder-form-cancel"
                {...heapLibraryShelfAddFolderCancel()}
                label={getString(_ => _.library.addFolderForm.closeButtonLabel)}
                onClick={onClickClose}
              />
            </div>
          </div>
          <div className="add-folder__form__row">{!!isSending && <LoadingIndicator />}</div>
        </form>
      </div>
    );
  }
}
