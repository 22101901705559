import {
  AUTHOR_CARD_FRAGMENT,
  AUTHOR_CARD_FRAGMENT_NAME,
  AuthorCardFromGraphQL,
} from '@/models/author/AuthorCard';
import { AUTHOR_FRAGMENT, AUTHOR_FRAGMENT_NAME, AuthorFromGraphQL } from '@/models/author/Author';
import {
  AUTHOR_INFLUENCE_STATISTICS_FRAGMENT,
  AUTHOR_INFLUENCE_STATISTICS_FRAGMENT_NAME,
  AuthorInfluenceStatisticsFromGraphQL,
} from '@/models/author/AuthorInfluenceStatistics';
import {
  AUTHOR_STATISTICS_FRAGMENT,
  AUTHOR_STATISTICS_FRAGMENT_NAME,
  AuthorStatisticsFromGraphQL,
} from '@/models/author/AuthorStatistics';
import {
  CITING_AUTHOR_FRAGMENT,
  CITING_AUTHOR_FRAGMENT_NAME,
  CitingAuthorFromGraphQL,
} from '@/models/author/CitingAuthor';
import {
  CO_AUTHORSHIP_FRAGMENT,
  CO_AUTHORSHIP_FRAGMENT_NAME,
  CoAuthorshipFromGraphQL,
} from '@/models/author/CoAuthorship';
import { DispatchPayload } from '@/utils/S2Dispatcher';
import { getAuthorIdAsNumber } from '@/utils/author-utils';
import { Nullable } from '@/utils/types';
import {
  REFERENCED_AUTHOR_FRAGMENT,
  REFERENCED_AUTHOR_FRAGMENT_NAME,
  ReferencedAuthorFromGraphQL,
} from '@/models/author/ReferencedAuthor';
import AuthorCitingAuthorsStore from '@/stores/author/AuthorCitingAuthorsStore';
import AuthorCoAuthorsStore from '@/stores/author/AuthorCoAuthorsStore';
import AuthorReferencedAuthorsStore from '@/stores/author/AuthorReferencedAuthorsStore';
import constants from '@/constants';
import GraphQLApi from '@/api/GraphQLApi';

import { gql } from 'graphql-tag';

export type FetchAuthorStatsResponse = {
  author: Nullable<
    {
      id: number;
    } & AuthorStatisticsFromGraphQL
  >;
};

export async function fetchAuthorStats(
  authorId: number | string,
  { graphQLApi }: { graphQLApi: GraphQLApi }
): Promise<DispatchPayload> {
  const query = gql`
    ${AUTHOR_STATISTICS_FRAGMENT}

    query GetAuthorStats($authorId: Long!) {
      author(authorId: $authorId) {
        id
        ...${AUTHOR_STATISTICS_FRAGMENT_NAME}
      }
    }
  `;
  const variables = {
    authorId: getAuthorIdAsNumber(authorId),
  };
  const result = await graphQLApi.fetchQuery<FetchAuthorStatsResponse>({
    requestType: constants.requestTypes.GQL__AUTHOR_STATS,
    query,
    variables,
    operationName: 'GetAuthorStats',
  });
  return result;
}

export type FetchClaimedAuthorStatsResponse = {
  me: {
    user: Nullable<{
      claimedAuthor: {
        id: number;
      } & AuthorStatisticsFromGraphQL;
    }>;
  };
};

export async function fetchClaimedAuthorStats({
  graphQLApi,
}: {
  graphQLApi: GraphQLApi;
}): Promise<DispatchPayload> {
  const query = gql`
    ${AUTHOR_STATISTICS_FRAGMENT}

    query GetClaimedAuthorStats {
      me {
        user {
          claimedAuthor {
            id
            ...${AUTHOR_STATISTICS_FRAGMENT_NAME}
          }
        }
      }
    }
  `;

  const result = await graphQLApi.fetchQuery<FetchClaimedAuthorStatsResponse>({
    requestType: constants.requestTypes.GQL__CLAIMED_AUTHOR_STATS,
    query,
    operationName: 'GetClaimedAuthorStats',
  });
  return result;
}

export type FetchAuthorInfluenceStatsResponse = {
  author: Nullable<
    {
      id: number;
    } & AuthorInfluenceStatisticsFromGraphQL
  >;
};

export async function fetchAuthorInfluenceStats(
  { authorId }: { authorId: number | string },
  { graphQLApi }: { graphQLApi: GraphQLApi }
): Promise<DispatchPayload> {
  const query = gql`
    ${AUTHOR_INFLUENCE_STATISTICS_FRAGMENT}
    query GetAuthorInfluenceStats($authorId: Long!) {
      author(authorId: $authorId) {
        id
        ...${AUTHOR_INFLUENCE_STATISTICS_FRAGMENT_NAME}
      }
    }
  `;
  const variables = {
    authorId: getAuthorIdAsNumber(authorId),
  };
  const result = await graphQLApi.fetchQuery<FetchAuthorStatsResponse>({
    requestType: constants.requestTypes.GQL__AUTHOR_INFLUENCE_STATS,
    query,
    variables,
    operationName: 'GetAuthorInfluenceStats',
  });
  return result;
}

export type FetchAuthorCitingAuthorsPageResponse = {
  author: Nullable<{
    id: number;
    citingAuthorCount: number;
    citingAuthors: {
      pageInfo: {
        endCursor: string;
      };
      edges: {
        node: CitingAuthorFromGraphQL;
      }[];
    };
  }>;
};

export async function fetchAuthorCitingAuthorsPage(
  {
    authorId,
    limit = 10,
    afterCursor,
    pageNumber,
  }: {
    authorId: number | string;
    limit?: number;
    afterCursor: Nullable<string>;
    pageNumber: number;
  },
  { graphQLApi }: { graphQLApi: GraphQLApi }
): Promise<DispatchPayload> {
  const query = gql`
    ${CITING_AUTHOR_FRAGMENT}

    query GetAuthorCitingAuthorsPage($authorId: Long!, $limit: Int!, $afterCursor: String) {
      author(authorId: $authorId) {
        id
        citingAuthorCount
        citingAuthors(first: $limit, after: $afterCursor) {
          pageInfo {
            endCursor
          }
          edges {
            node {
              ...${CITING_AUTHOR_FRAGMENT_NAME}
            }
          }
        }
      }
    }
  `;
  const variables = {
    authorId: getAuthorIdAsNumber(authorId),
    limit,
    afterCursor,
  };
  const result = await graphQLApi.fetchQuery<FetchAuthorStatsResponse>({
    requestType: constants.requestTypes.GQL__AUTHOR_CITING_AUTHORS,
    query,
    variables,
    operationName: 'GetAuthorCitingAuthorsPage',
    context: {
      pageNumber,
    },
  });
  return result;
}

export async function fetchAuthorCitingAuthorsUntilPageNumber(
  {
    authorId,
    limit = 10,
    pageNumber,
  }: {
    authorId: number | string;
    limit?: number;
    pageNumber: number;
  },
  {
    graphQLApi,
    authorCitingAuthorsStore,
  }: {
    graphQLApi: GraphQLApi;
    authorCitingAuthorsStore: AuthorCitingAuthorsStore;
  }
): Promise<DispatchPayload[]> {
  const results: DispatchPayload[] = [];
  for (let i = 1; i <= pageNumber; i++) {
    if (authorCitingAuthorsStore.hasAuthorsForPage(i)) {
      continue;
    }
    const afterCursor = authorCitingAuthorsStore.getEndCursorForPage(i - 1);
    const result = await fetchAuthorCitingAuthorsPage(
      {
        authorId,
        limit,
        pageNumber: i,
        afterCursor,
      },
      {
        graphQLApi,
      }
    );
    results.push(result);
  }
  return results;
}

export type FetchAuthorCoAuthorsPageResponse = {
  author: Nullable<{
    id: number;
    coAuthorCount: number;
    coAuthors: {
      pageInfo: {
        endCursor: string;
      };
      edges: {
        node: CoAuthorshipFromGraphQL;
      }[];
    };
  }>;
};

export async function fetchAuthorCoAuthorsPage(
  {
    authorId,
    limit = 10,
    afterCursor,
    pageNumber,
  }: {
    authorId: number | string;
    limit?: number;
    afterCursor: Nullable<string>;
    pageNumber: number;
  },
  { graphQLApi }: { graphQLApi: GraphQLApi }
): Promise<DispatchPayload> {
  const query = gql`
    ${CO_AUTHORSHIP_FRAGMENT}

    query GetAuthorCoAuthorsPage($authorId: Long!, $limit: Int!, $afterCursor: String) {
      author(authorId: $authorId) {
        id
        coAuthorCount
        coAuthors(first: $limit, after: $afterCursor) {
          pageInfo {
            endCursor
          }
          edges {
            node {
              ...${CO_AUTHORSHIP_FRAGMENT_NAME}
            }
          }
        }
      }
    }
  `;
  const variables = {
    authorId: getAuthorIdAsNumber(authorId),
    limit,
    afterCursor,
  };
  const result = await graphQLApi.fetchQuery<FetchAuthorStatsResponse>({
    requestType: constants.requestTypes.GQL__AUTHOR_CO_AUTHORS,
    query,
    variables,
    operationName: 'GetAuthorCoAuthorsPage',
    context: {
      pageNumber,
    },
  });
  return result;
}

export async function fetchAuthorCoAuthorsUntilPageNumber(
  {
    authorId,
    limit = 10,
    pageNumber,
  }: {
    authorId: number | string;
    limit?: number;
    pageNumber: number;
  },
  {
    graphQLApi,
    authorCoAuthorsStore,
  }: {
    graphQLApi: GraphQLApi;
    authorCoAuthorsStore: AuthorCoAuthorsStore;
  }
): Promise<DispatchPayload[]> {
  const results: DispatchPayload[] = [];
  for (let i = 1; i <= pageNumber; i++) {
    if (authorCoAuthorsStore.hasAuthorsForPage(i)) {
      continue;
    }
    const afterCursor = authorCoAuthorsStore.getEndCursorForPage(i - 1);
    const result = await fetchAuthorCoAuthorsPage(
      {
        authorId,
        limit,
        pageNumber: i,
        afterCursor,
      },
      {
        graphQLApi,
      }
    );
    results.push(result);
  }
  return results;
}

export type FetchAuthorReferencedAuthorsPageResponse = {
  author: Nullable<{
    id: number;
    referencedAuthorCount: number;
    referencedAuthors: {
      pageInfo: {
        endCursor: string;
      };
      edges: {
        node: ReferencedAuthorFromGraphQL;
      }[];
    };
  }>;
};

export async function fetchAuthorReferencedAuthorsPage(
  {
    authorId,
    limit = 10,
    afterCursor,
    pageNumber,
  }: {
    authorId: number | string;
    limit?: number;
    afterCursor: Nullable<string>;
    pageNumber: number;
  },
  { graphQLApi }: { graphQLApi: GraphQLApi }
): Promise<DispatchPayload> {
  const query = gql`
    ${REFERENCED_AUTHOR_FRAGMENT}

    query GetAuthorReferencedAuthorsPage($authorId: Long!, $limit: Int!, $afterCursor: String) {
      author(authorId: $authorId) {
        id
        referencedAuthorCount
        referencedAuthors(first: $limit, after: $afterCursor) {
          pageInfo {
            endCursor
          }
          edges {
            node {
              ...${REFERENCED_AUTHOR_FRAGMENT_NAME}
            }
          }
        }
      }
    }
  `;
  const variables = {
    authorId: getAuthorIdAsNumber(authorId),
    limit,
    afterCursor,
  };
  const result = await graphQLApi.fetchQuery<FetchAuthorStatsResponse>({
    requestType: constants.requestTypes.GQL__AUTHOR_REFERENCED_AUTHORS,
    query,
    variables,
    operationName: 'GetAuthorReferencedAuthorsPage',
    context: {
      pageNumber,
    },
  });
  return result;
}

export async function fetchAuthorReferencedAuthorsUntilPageNumber(
  {
    authorId,
    limit = 10,
    pageNumber,
  }: {
    authorId: number | string;
    limit?: number;
    pageNumber: number;
  },
  {
    graphQLApi,
    authorReferencedAuthorsStore,
  }: {
    graphQLApi: GraphQLApi;
    authorReferencedAuthorsStore: AuthorReferencedAuthorsStore;
  }
): Promise<DispatchPayload[]> {
  const results: DispatchPayload[] = [];
  for (let i = 1; i <= pageNumber; i++) {
    if (authorReferencedAuthorsStore.hasAuthorsForPage(i)) {
      continue;
    }
    const afterCursor = authorReferencedAuthorsStore.getEndCursorForPage(i - 1);
    const result = await fetchAuthorReferencedAuthorsPage(
      {
        authorId,
        limit,
        pageNumber: i,
        afterCursor,
      },
      {
        graphQLApi,
      }
    );
    results.push(result);
  }
  return results;
}

export type FetchAuthorRecommendationsResponse = {
  author: Nullable<{
    id: number;
    coAuthors: {
      edges: {
        node: {
          coAuthor: AuthorFromGraphQL;
        };
      }[];
    };
  }>;
};

export async function fetchAuthorRecommendations(
  {
    authorId,
    limit,
  }: {
    authorId: number | string;
    limit: number;
  },
  {
    graphQLApi,
  }: {
    graphQLApi: GraphQLApi;
  }
): Promise<DispatchPayload> {
  const query = gql`
    ${AUTHOR_FRAGMENT}

    query GetAuthorRecommendations($authorId: Long!, $limit: Int!) {
      author(authorId: $authorId) {
        id
        coAuthors(first: $limit) {
          edges {
            node {
              coAuthor {
                ...${AUTHOR_FRAGMENT_NAME}
              }
            }
          }
        }
      }
    }
  `;
  const variables = {
    authorId: getAuthorIdAsNumber(authorId),
    limit,
  };
  const result = await graphQLApi.fetchQuery<FetchAuthorStatsResponse>({
    requestType: constants.requestTypes.GQL__AUTHOR_RECOMMENDATIONS,
    query,
    variables,
    operationName: 'GetAuthorRecommendations',
  });
  return result;
}

export type FetchAuthorCardsResponse = {
  authors: Nullable<
    {
      id: number;
    } & AuthorCardFromGraphQL
  >[];
};

export async function fetchAuthorCards(
  { authorIds }: { authorIds: Immutable.List<number | string> },
  { graphQLApi }: { graphQLApi: GraphQLApi },
  { limit }: { limit: number }
): Promise<DispatchPayload> {
  const query = gql`
    ${AUTHOR_CARD_FRAGMENT}
    query GetAuthorCards($authorIds: [Long!]!) {
      authors(authorIds: $authorIds) {
        id
        ...${AUTHOR_CARD_FRAGMENT_NAME}
      }
    }
  `;
  const variables = {
    authorIds: authorIds.map(id => getAuthorIdAsNumber(id)),
    limit,
  };

  const result = await graphQLApi.fetchQuery<FetchAuthorCardsResponse>({
    requestType: constants.requestTypes.GQL__AUTHOR_CARDS,
    query,
    variables,
    operationName: 'GetAuthorCards',
  });
  return result;
}
