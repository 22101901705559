import BaseStore from './BaseStore';

import { getLibraryEntryFromJS, LibraryEntryRecord } from '@/models/library/LibraryEntry';
import { hasValue, Nullable } from '@/utils/types';
import Constants from '@/constants';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import Immutable from 'immutable';

import type { ApiRequestStartingPayload } from '@/api/BaseApi';
import type {
  ApiResponse,
  CreateLibraryEntryBulkResponseBody,
  DeleteLibraryEntryBulkResponseBody,
  GetLibraryEntriesResponseBody,
  LibraryEntriesBulkResponseBody,
} from '@/api/ApiResponse';
import type S2Dispatcher from '@/utils/S2Dispatcher';

export default class LibraryEntryStore extends BaseStore {
  #entriesByIdMap: Immutable.Map<number, LibraryEntryRecord>;
  #state: StoreStateValue;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#entriesByIdMap = Immutable.OrderedMap();
    this.#state = StoreState.UNINITIALIZED;

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const startingPayload = payload as ApiRequestStartingPayload;
          switch (startingPayload.requestType) {
            case Constants.requestTypes.GET_ALL_LIBRARY_ENTRIES:
              return this.#handleGetLibraryEntriesStart();
          }
          break;
        }

        case Constants.actions.API_REQUEST_COMPLETE: {
          const completePayload = payload as ApiResponse;
          switch (completePayload.requestType) {
            case Constants.requestTypes.LOGOUT:
              return this.#handleLogoutComplete();

            case Constants.requestTypes.GET_ALL_LIBRARY_ENTRIES:
              return this.#handleGetLibraryEntriesComplete(completePayload);

            case Constants.requestTypes.DELETE_LIBRARY_ENTRIES_BULK:
              return this.#handleDeleteLibraryEntriesBulkComplete(completePayload);

            case Constants.requestTypes.CREATE_LIBRARY_ENTRY_BULK:
            case Constants.requestTypes.GET_LIBRARY_ENTRIES_BULK:
              return this.#handleCreateOrGetLibraryEntriesBulkComplete(completePayload);
          }
        }
      }
    });
  }

  #handleGetLibraryEntriesStart(): void {
    this.#state = StoreState.LOADING;
    this.emitChange();
  }

  #handleLogoutComplete(): void {
    this.#entriesByIdMap = Immutable.OrderedMap();
    this.#state = StoreState.UNINITIALIZED;
    this.emitChange();
  }

  #handleGetLibraryEntriesComplete(payload: ApiResponse<GetLibraryEntriesResponseBody>): void {
    const { resultData } = payload;
    const entriesList: Immutable.List<[number, LibraryEntryRecord]> = Immutable.List(
      resultData.entries
    ).map(entry => [entry.id, getLibraryEntryFromJS(entry)]);

    const newEntriesByIdMap = Immutable.OrderedMap(entriesList);
    if (this.#state !== StoreState.LOADED || !this.#entriesByIdMap.equals(newEntriesByIdMap)) {
      this.#entriesByIdMap = newEntriesByIdMap;
      this.#state = StoreState.LOADED;
      this.emitChange();
    }
  }

  #handleCreateOrGetLibraryEntriesBulkComplete(
    payload: ApiResponse<CreateLibraryEntryBulkResponseBody | LibraryEntriesBulkResponseBody>
  ): void {
    // the bulk create endpoint will return a list of entries created/fetched
    // ex. adding a paper to 2 folders will create 2 separate entries with that paper id
    payload.resultData.entries.forEach(entry => {
      this.#entriesByIdMap = this.#entriesByIdMap.set(entry.id, getLibraryEntryFromJS(entry));
    });

    this.emitChange();
  }

  #handleDeleteLibraryEntriesBulkComplete(
    payload: ApiResponse<DeleteLibraryEntryBulkResponseBody>
  ): void {
    const { deletedEntryIds } = payload.resultData;
    const newEntriesByIdMap = this.#entriesByIdMap.deleteAll(deletedEntryIds);
    if (!this.#entriesByIdMap.equals(newEntriesByIdMap)) {
      this.#entriesByIdMap = newEntriesByIdMap;
      this.emitChange();
    }
  }

  getEntries(): Immutable.List<LibraryEntryRecord> {
    return this.#entriesByIdMap.valueSeq().toList();
  }

  getEntriesByPaperId(paperId?: Nullable<string>): Nullable<LibraryEntryRecord[]> {
    if (!hasValue(paperId)) {
      return null;
    }

    // find paper by paper.id and fallback to the paper id on the entry itself if paper doesn't exist
    return this.#entriesByIdMap
      .valueSeq()
      .filter(entry => (entry.paper?.id || entry.paperId) === paperId)
      .toArray();
  }

  getMostRecentEntryByPaperId(paperId?: Nullable<string>): Nullable<LibraryEntryRecord> {
    if (!hasValue(paperId)) {
      return null;
    }

    const entriesWithPaperId = this.getEntriesByPaperId(paperId) || [];

    return (
      entriesWithPaperId.sort((entry1, entry2) => {
        return entry2.modifiedAtUtc - entry1.modifiedAtUtc;
      })[0] || null
    );
  }

  getEntriesByCorpusId(corpusId?: Nullable<number>): LibraryEntryRecord[] {
    if (!hasValue(corpusId)) {
      return [];
    }

    // find paper by paper.corpusId and fallback to the corpus id on the entry itself if paper doesn't exist
    return this.#entriesByIdMap
      .valueSeq()
      .filter(entry => (entry.paper?.corpusId || entry.corpusId) === corpusId)
      .toArray();
  }

  getMostRecentEntryByCorpusId(corpusId?: Nullable<number>): Nullable<LibraryEntryRecord> {
    if (!hasValue(corpusId)) {
      return null;
    }

    const entriesWithCorpusId = this.getEntriesByCorpusId(corpusId) || [];

    return (
      entriesWithCorpusId.sort((entry1, entry2) => {
        return entry2.modifiedAtUtc - entry1.modifiedAtUtc;
      })[0] || null
    );
  }

  getMostRecentEntryByCorpusIdWithPaperIdFallback(
    corpusId?: Nullable<number>,
    paperId?: Nullable<string>
  ): Nullable<LibraryEntryRecord> {
    return this.getMostRecentEntryByCorpusId(corpusId) || this.getMostRecentEntryByPaperId(paperId);
  }

  isUninitialized(): boolean {
    return this.#state === StoreState.UNINITIALIZED;
  }
}
