import Immutable from 'immutable';

import type { IconId } from '@/components/shared/icon/IconSprite';

type Props = {
  id: string;
  icon: IconId | '';
};

const defaultProps: Props = {
  id: '',
  icon: '',
};

export const SortRecordFactory = Immutable.Record<Props>(defaultProps);

export type SortRecord = Immutable.RecordOf<Props>;
