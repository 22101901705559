import React from 'react';

export default function IconMic(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 32 32">
      <path
        d="M24.75,14.64V18c0,6.24-4.65,11.43-10.68,12.24v2.37H18c0.63,0,1.14,0.51,1.14,1.14v1.14c0,0.6-0.51,1.11-1.14,1.11H6.75
        c-0.63,0-1.14-0.51-1.14-1.14v-1.14c0-0.63,0.51-1.14,1.14-1.14h3.93v-2.4C4.5,29.37,0,23.76,0,17.46v-2.82
        c0-0.63,0.51-1.14,1.14-1.14h1.14c0.63,0,1.14,0.51,1.14,1.14v2.97c0,4.71,3.42,8.91,8.1,9.36c5.31,0.51,9.84-3.72,9.84-8.97v-3.36
        c0-0.63,0.51-1.14,1.14-1.14h1.14C24.24,13.5,24.75,14.01,24.75,14.64z M5.64,18V6.75c0-3.72,3-6.75,6.72-6.75s6.75,3.03,6.75,6.75
        V18c0,3.72-3.03,6.75-6.75,6.75S5.64,21.72,5.64,18z"
      />
    </symbol>
  );
}
