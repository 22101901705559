import {
  AuthorStatisticsRecord,
  getAuthorStatisticsFromGraphQL,
} from '@/models/author/AuthorStatistics';
import {
  FetchAuthorStatsResponse,
  FetchClaimedAuthorStatsResponse,
} from '@/actions/AuthorActionCreators';
import { getAuthorIdAsNumber } from '@/utils/author-utils';
import { GraphQLResponse } from '@/api/GraphQLApi';
import { Nullable } from '@/utils/types';
import BaseStore from '@/stores/BaseStore';
import Constants from '@/constants';
import S2Dispatcher from '@/utils/S2Dispatcher';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import Immutable from 'immutable';

import type { ApiRequestStartingPayload } from '@/api/BaseApi';
import type { ApiResponse, LogoutResponseBody } from '@/api/ApiResponse';

type AuthorId = number | string;

export default class AuthorStatsStore extends BaseStore {
  #loadingStates: Immutable.Map<number, StoreStateValue>;
  #authorStats: Immutable.Map<number, AuthorStatisticsRecord>;
  #viewerAuthorId: Nullable<number>;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#loadingStates = Immutable.Map();
    this.#authorStats = Immutable.Map();
    this.#viewerAuthorId = null;

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const apiStartingPayload = payload as ApiRequestStartingPayload;
          switch (apiStartingPayload.requestType) {
            case Constants.requestTypes.AUTHOR_STATS:
              return this.#handleAuthorStatsStart(apiStartingPayload);
            case Constants.requestTypes.MENU_AUTHOR_STATS:
              return this.#handleMenuAuthorStatsStart(apiStartingPayload);
          }
          return;
        }

        case Constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.GQL__CLAIMED_AUTHOR_STATS:
              return this.#handleClaimedAuthorStatsGQLResponse(apiResponse);
            case Constants.requestTypes.GQL__AUTHOR_STATS:
              return this.#handleAuthorStatsGQLResponse(apiResponse);
            case Constants.requestTypes.LOGOUT:
              return this.#handleLogoutResponse(apiResponse);
          }
          return;
        }
      }
    });
  }

  #handleAuthorStatsStart(payload: ApiRequestStartingPayload): void {
    const authorIdNum = getAuthorIdAsNumber(payload.context.authorId);
    const wasChanged = this.#setLoadingState(authorIdNum, StoreState.LOADING);
    if (wasChanged) {
      this.emitChange();
    }
  }

  #handleMenuAuthorStatsStart(payload: ApiRequestStartingPayload): void {
    this.#viewerAuthorId = getAuthorIdAsNumber(payload.context.authorId);
    this.#handleAuthorStatsStart(payload); // Currently, the payloads are the same
  }

  #handleClaimedAuthorStatsGQLResponse(
    payload: ApiResponse<GraphQLResponse<FetchClaimedAuthorStatsResponse>>
  ): void {
    const claimedAuthorData = payload.resultData.data?.me.user?.claimedAuthor;
    if (!claimedAuthorData) {
      return;
    }
    const authorId = claimedAuthorData.id;
    const authorStats = getAuthorStatisticsFromGraphQL(claimedAuthorData);
    let wasChanged = this.#viewerAuthorId !== authorId;
    this.#viewerAuthorId = authorId;
    wasChanged = this.#updateAuthorStats(authorId, authorStats) || wasChanged;
    if (wasChanged) {
      this.emitChange();
    }
  }

  #handleAuthorStatsGQLResponse(
    payload: ApiResponse<GraphQLResponse<FetchAuthorStatsResponse>>
  ): void {
    const authorData = payload.resultData.data?.author;
    if (!authorData) {
      return;
    }
    const authorId = authorData.id;
    const authorStats = getAuthorStatisticsFromGraphQL(authorData);
    const wasChanged = this.#updateAuthorStats(authorId, authorStats);
    if (wasChanged) {
      this.emitChange();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  #handleLogoutResponse(payload: ApiResponse<LogoutResponseBody>): void {
    this.#viewerAuthorId = null;
    this.emitChange();
  }

  #updateAuthorStats(authorId: AuthorId, authorStats: AuthorStatisticsRecord): boolean {
    let wasChanged = false;
    wasChanged = this.#setLoadingState(authorId, StoreState.LOADED) || wasChanged;
    wasChanged = this.#setAuthorStatsRecord(authorId, authorStats) || wasChanged;
    return wasChanged;
  }

  #setLoadingState(authorId: AuthorId, loadingState: StoreStateValue): boolean {
    const authorIdNum = getAuthorIdAsNumber(authorId);
    if (this.#loadingStates.get(authorIdNum) === loadingState) {
      // No need to update
      return false;
    }
    this.#loadingStates = this.#loadingStates.set(authorIdNum, loadingState);
    return true;
  }

  #setAuthorStatsRecord(authorId: AuthorId, newRecord: AuthorStatisticsRecord): boolean {
    const authorIdNum = getAuthorIdAsNumber(authorId);
    const oldRecord = this.#authorStats.get(authorIdNum);
    if (oldRecord && oldRecord.equals(newRecord)) {
      return false; // Records match, no need to update
    }
    this.#authorStats = this.#authorStats.set(authorIdNum, newRecord);
    return true;
  }

  isUninitialized(authorId: AuthorId): boolean {
    const authorIdNum = getAuthorIdAsNumber(authorId);
    const state = this.#loadingStates.get(authorIdNum);
    return !state || state === StoreState.UNINITIALIZED;
  }

  isLoading(authorId: AuthorId): boolean {
    if (this.isUninitialized(authorId)) {
      return true;
    }
    const authorIdNum = getAuthorIdAsNumber(authorId);
    const state = this.#loadingStates.get(authorIdNum);
    return state === StoreState.LOADING;
  }

  getAuthorStatistics(authorId: AuthorId): Nullable<AuthorStatisticsRecord> {
    const authorIdNum = getAuthorIdAsNumber(authorId);
    return this.#authorStats.get(authorIdNum) || null;
  }

  isViewerUninitialized(): boolean {
    return this.#viewerAuthorId ? this.isUninitialized(this.#viewerAuthorId) : true;
  }

  isViewerLoading(): boolean {
    return this.#viewerAuthorId ? this.isLoading(this.#viewerAuthorId) : true;
  }

  getViewerAuthorStatistics(): Nullable<AuthorStatisticsRecord> {
    return this.#viewerAuthorId ? this.getAuthorStatistics(this.#viewerAuthorId) : null;
  }
}
