/** Enumeration of metadata (page titles, descriptions) for specific HTTP status codes. */
const HttpStatusMeta = {
  NOT_FOUND: {
    TITLE: 'Page Not Found | Semantic Scholar',
    DESC: "Sorry, we couldn't find anything at that url",
  },
  SERVER_ERROR: {
    TITLE: 'Error | Semantic Scholar',
    DESC: "Sorry, an error occured and we weren't able to complete your request.",
  },
  UNAUTHORIZED: {
    TITLE: 'Sign in to continue | Semantic Scholar',
    DESC: 'Sorry, you are not authorized to view this page.',
  },
} as const;
Object.freeze(HttpStatusMeta);

export default HttpStatusMeta;
