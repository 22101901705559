import Modal from './Modal';

import { FINISHED } from '@/constants/ExperimentStatus';
import { getString } from '@/content/i18n';
import { hideModal } from '@/actions/ModalActionCreators';
import { ModalId } from '@/constants/Modal';
import Api from '@/api/Api';
import Checkbox from '@/components/shared/common/form/Checkbox';
import logger from '@/logger';
import S2Dispatcher from '@/utils/S2Dispatcher';

import PropTypes from 'prop-types';
import React from 'react';

export default class TerminateExperimentModal extends React.PureComponent {
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      defaultExperiment: null,
      terminateError: null,
    };
  }

  selectDefaultExperiment = variationName => {
    this.setState({
      defaultExperiment: variationName,
    });
  };

  terminateExperiment = async () => {
    const { experiment } = this.props;
    const { defaultExperiment } = this.state;
    const { api } = this.context;

    if (!defaultExperiment) {
      this.setState({ terminateError: getString(_ => _.admin.terminateModal.terminateError) });
      return;
    }

    try {
      const variations = experiment.Variation;
      const variationRatios = Object.keys(variations).map(key => {
        return {
          key: variations[key],
          ratio: variations[key] === defaultExperiment ? 1.0 : 0.0,
        };
      });

      const data = {
        key: experiment.KEY,
        variations: variationRatios,
        trafficRatio: 1.0,
        status: FINISHED,
        salt: experiment.KEY,
      };

      await api.setExperimentState(data);
      await api.getExperiments();
    } catch (err) {
      logger.error(err);
    }

    this.closeModal();
  };

  closeModal = () => {
    this.context.dispatcher.dispatch(hideModal());
  };

  render() {
    const { experiment } = this.props;
    const { defaultExperiment, terminateError } = this.state;
    const variations = experiment.Variation;

    return (
      <Modal
        className="terminate-experiment-modal"
        darkBackground={true}
        modalId={ModalId.TERMINATE_EXPERIMENT}>
        <h2>{getString(_ => _.admin.terminateModal.terminateExperimentHeader)}</h2>
        <p>{getString(_ => _.admin.terminateModal.fallbackVariationText)}</p>
        {Object.keys(variations).map((key, index) => {
          return (
            <Checkbox
              key={index}
              checkboxText={variations[key]}
              value={variations[key] === defaultExperiment}
              onChange={() => this.selectDefaultExperiment(variations[key])}
            />
          );
        })}
        <p className="terminate-experiment__error">{terminateError}</p>
        <button
          className="button button--primary terminate-experiment__button"
          onClick={this.terminateExperiment}>
          {getString(_ => _.admin.terminateModal.terminateButtonLabel)}
        </button>
        <button
          className="button button--primary terminate-experiment__cancel-button"
          onClick={this.closeModal}>
          {getString(_ => _.admin.terminateModal.cancelButtonLabel)}
        </button>
      </Modal>
    );
  }
}
