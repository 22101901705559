import { buildShareFolderLink } from '@/components/shared/library/util';
import { getString } from '@/content/i18n';
import {
  heapCancelPublicizeFolderButton,
  heapConfirmPublicizeFolderButton,
} from '@/analytics/attributes/libraryPage';
import { hideModal } from '@/actions/ModalActionCreators';
import { LibraryFolderRecord } from '@/models/library/LibraryFolder';
import { ModalId } from '@/constants/Modal';
import { Public } from '@/models/library/PrivacySetting';
import { useAppContext } from '@/AppContext';
import BrowserUtil from '@/browser';
import CLButton, { TYPE } from '@/components/library/button/CLButton';
import CLModal from '@/components/library/modal/CLModal';
import softError from '@/utils/softError';

import React from 'react';

type Props = {
  data: {
    folder: LibraryFolderRecord;
  };
};

export default function ConfirmPublicFolderModal({ data: { folder } }: Props): React.ReactNode {
  const { api, dispatcher, messageStore } = useAppContext();

  const onClickMakePublic: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(() => {
    makePublicAndCopyLink();
  }, []);

  const makePublicAndCopyLink = async (): Promise<void> => {
    try {
      await api.updateLibraryFolder({ id: folder.id, privacySetting: Public });
      const shareUrl = buildShareFolderLink(folder.id);
      BrowserUtil.copyToClipboard(shareUrl);
      messageStore.addSuccess(getString(_ => _.library.shared.changePrivacySuccess));
      closeModal();
    } catch (e) {
      softError('library', `failed to update folder[id="${folder.id}"]`, e);

      const header = getString(_ => _.library.shared.errorTitle);
      const body = getString(_ => _.library.shared.updatePrivacyMessage);
      messageStore.addError(body, header);
    }
  };

  const onClickClose: React.MouseEventHandler = React.useCallback((): void => {
    closeModal();
  }, []);

  const closeModal = (): void => {
    dispatcher.dispatch(hideModal());
  };

  return (
    <CLModal modalId={ModalId.CONFIRM_PUBLIC_LIBRARY} className="confirm-public-folder-modal">
      <h4 className="confirm-public-folder__header">
        {getString(_ => _.library.shared.makePublicHeader)}
      </h4>
      <p className="confirm-public-folder__description">
        {getString(_ => _.library.shared.makePublicDescription)}
      </p>
      <div className="confirm-public-folder__actions">
        <CLButton
          label={getString(_ => _.library.shared.makePublicButton)}
          type={TYPE.PRIMARY}
          onClick={onClickMakePublic}
          data-test-id="confirm-public-folder-button"
          {...heapConfirmPublicizeFolderButton()}
        />
        <CLButton
          label={getString(_ => _.library.shared.cancel)}
          onClick={onClickClose}
          data-test-id="cancel-public-folder-button"
          {...heapCancelPublicizeFolderButton()}
        />
      </div>
    </CLModal>
  );
}
