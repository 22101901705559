import React from 'react';

export default function IconGlobe(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 24 24">
      <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g
          id="SERP-with-Entity"
          transform="translate(-1151.000000, -591.000000)"
          fillRule="nonzero">
          <g id="Sidebar" transform="translate(930.000000, 242.000000)">
            <g id="Authors" transform="translate(0.000000, 179.000000)">
              <g id="Source" transform="translate(221.000000, 169.000000)">
                <g transform="translate(0.000000, 1.000000)">
                  <path
                    d="M23,11.9565 C23,5.86775805 18.1080186,1.0005 12.005,1.0005 C11.8329476,1.0005 11.6774772,1.00376815 11.5251141,1.01109526 C5.58721094,1.25015998 1,5.87984777 1,11.7495 C1,17.9049777 5.92920226,23.0005 11.968,23.0005 C18.0605205,23.0005 23,18.0556949 23,11.9565 Z M24,11.9565 C24,18.6077385 18.6130466,24.0005 11.968,24.0005 C5.36935543,24.0005 0,18.449973 0,11.7495 C0,5.33624766 5.01749953,0.272286882 11.4809315,0.0120796263 C11.6465082,0.00410002878 11.8177667,0.000499999999 12.005,0.000499999999 C18.6591723,0.000499999999 24,5.31433592 24,11.9565 Z"
                    id="Stroke-5698"
                  />
                  <path
                    d="M11.1374983,0.172059949 C4.96613362,6.85770499 4.96613362,15.6061782 11.1049,23.7912 L11.9049,23.1912 C6.04366638,15.3762218 6.04366638,7.16469501 11.8723017,0.850340051 L11.1374983,0.172059949 Z"
                    id="Stroke-5699"
                  />
                  <path
                    d="M12.1374983,0.850340051 C17.9661336,7.16469501 17.9661336,15.3732218 12.1049,23.1882 L12.9049,23.7882 C19.0436664,15.6031782 19.0436664,6.85770499 12.8723017,0.172059949 L12.1374983,0.850340051 Z"
                    id="Stroke-5700"
                  />
                  <polygon
                    id="Stroke-5701"
                    points="2 17.9785 22.062 17.9785 22.062 16.9785 2 16.9785"
                  />
                  <polygon
                    id="Stroke-5702"
                    points="2.4058 5.9785 21.4678 5.9785 21.4678 4.9785 2.4058 4.9785"
                  />
                  <polygon
                    id="Stroke-5703"
                    points="0.5 11.9785 23.437 11.9785 23.437 10.9785 0.5 10.9785"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </symbol>
  );
}
