import React from 'react';

export default function IconFaLink(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 36 36">
      <path
        d="M22.99,28.12c-0.01,0.01-0.02,0.02-0.02,0.03l-4.73,4.73c-4.17,4.17-10.95,4.17-15.11,0c-4.17-4.17-4.17-10.95,0-15.12
        l2.61-2.61c0.69-0.69,1.88-0.23,1.92,0.75c0.05,1.25,0.27,2.5,0.68,3.71c0.14,0.41,0.04,0.86-0.27,1.17l-0.92,0.92
        c-1.97,1.97-2.03,5.18-0.08,7.17c1.97,2.01,5.21,2.02,7.2,0.04l4.72-4.72c1.98-1.98,1.98-5.19,0-7.16
        c-0.26-0.26-0.52-0.46-0.73-0.6c-0.26-0.18-0.47-0.57-0.49-0.89c-0.03-0.74,0.24-1.51,0.82-2.1l1.48-1.48
        c0.39-0.39,1-0.44,1.45-0.12c0.43,0.3,1.07,0.84,1.44,1.21C27.17,17.24,27.11,23.98,22.99,28.12z M32.87,18.24l-2.61,2.61
        c-0.69,0.69-1.88,0.23-1.92-0.75c-0.05-1.25-0.27-2.5-0.68-3.71c-0.14-0.41-0.04-0.86,0.27-1.17l0.92-0.92
        c1.97-1.97,2.03-5.18,0.08-7.17c-1.97-2.01-5.21-2.02-7.19-0.04l-4.72,4.72c-1.98,1.98-1.98,5.19,0,7.16
        c0.26,0.26,0.52,0.46,0.73,0.6c0.26,0.18,0.47,0.57,0.49,0.89c0.03,0.74-0.24,1.51-0.82,2.1l-1.48,1.48
        c-0.39,0.39-1,0.44-1.45,0.12c-0.43-0.3-1.07-0.84-1.44-1.21c-4.2-4.21-4.14-10.95-0.02-15.09c0.01-0.01,0.02-0.02,0.02-0.03
        l4.73-4.73c4.17-4.17,10.95-4.17,15.12,0C37.04,7.29,37.04,14.07,32.87,18.24z"
      />
    </symbol>
  );
}
