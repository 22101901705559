import CLButtonBase, {
  BEHAVIOR as _BEHAVIOR,
  DENSITY as _DENSITY,
  FONT_SIZE as _FONT_SIZE,
  SIZE as _SIZE,
  TYPE as _TYPE,
  CLButtonBaseProps,
} from './CLButtonBase';

import React from 'react';

import type { ReactNodeish } from '@/utils/types';

export const DENSITY = _DENSITY;
export const FONT_SIZE = _FONT_SIZE;
export const SIZE = _SIZE;
export const TYPE = _TYPE;
export const BEHAVIOR = _BEHAVIOR;

export type CLButtonProps = CLButtonBaseProps;

export default function CLButton(props: CLButtonProps): ReactNodeish {
  return <CLButtonBase {...props} />;
}

export const exampleConfig = {
  getComponent: () => CLButton,
  fields: [
    {
      name: 'label',
      desc: 'Text displayed within the button',
      value: {
        type: 'text',
        default: 'Button Text',
      },
    },
    {
      name: 'type',
      desc: 'Importance within the form',
      value: {
        type: 'select',
        default: TYPE.DEFAULT,
        options: [TYPE.DEFAULT, TYPE.PRIMARY, TYPE.SECONDARY, TYPE.TERTIARY],
      },
    },
    {
      name: 'size',
      desc: 'How large the button should be',
      value: {
        type: 'select',
        default: SIZE.DEFAULT,
        options: [SIZE.DEFAULT, SIZE.LARGE, SIZE.SMALL],
      },
    },
    {
      name: 'density',
      desc: 'How much padding the button should have',
      value: {
        type: 'select',
        default: DENSITY.DEFAULT,
        options: [DENSITY.DEFAULT, DENSITY.COMPACT, DENSITY.COMFORTABLE],
      },
    },
    {
      name: 'className',
      desc: 'HTML classes to be added to the button',
      value: {
        type: 'text',
        default: '',
      },
    },
    {
      name: 'disabled',
      desc: 'Is button disabled',
      value: {
        type: 'boolean',
        default: false,
      },
    },
  ],

  examples: [
    {
      title: 'General',
      desc: 'Button with some simple text',
      props: {
        label: 'Button Text',
        type: TYPE.DEFAULT,
        size: SIZE.DEFAULT,
        className: '',
      },
    },
    {
      title: 'Primary',
      desc: 'Main action on a form',
      props: {
        label: 'Button Text',
        type: TYPE.PRIMARY,
        size: SIZE.DEFAULT,
        className: '',
      },
    },
    {
      title: 'Large',
      desc: 'Super sized button',
      props: {
        label: 'Button Text',
        type: TYPE.PRIMARY,
        size: SIZE.LARGE,
        className: '',
      },
    },
    {
      title: 'Contact Us',
      desc: 'Contact Us button in the header nav',
      props: {
        label: 'Contact Us',
        type: TYPE.TERTIARY,
        size: SIZE.DEFAULT,
        className: '',
      },
    },
  ],

  events: {
    onClick: event => ({
      label: event.currentTarget.innerText,
      class: event.currentTarget.className,
    }),
  },
};
