import FeatureDisabled from './FeatureDisabled';
import FeatureEnabled from './FeatureEnabled';

import { FeatureRecord } from '@/weblab/FeatureRecord';
import { isFunction, ReactNodeish } from '@/utils/types';
import { mapHooksToProps } from '@/utils/react-utils';
import { useAppContext, useStateFromStore } from '@/AppContext';
import Feature from '@/weblab/Feature';

import React from 'react';

export type PropsFromParent = React.PropsWithChildren<{
  feature: FeatureRecord | ((features: typeof Feature) => FeatureRecord);
}>;

export type Props = React.PropsWithChildren<{
  isEnabled: boolean;
}>;

export function FeatureGateComp({ children, isEnabled }: Props): ReactNodeish {
  if (!children) {
    return null;
  }
  const GateType = isEnabled ? FeatureEnabled : FeatureDisabled;
  const kids = React.Children.toArray(children) as ReactNodeish[];
  return kids.find(kid => kid?.type === GateType) || null;
}

export default mapHooksToProps<Props, PropsFromParent>(FeatureGateComp, props => {
  const { weblabStore } = useAppContext();
  const feature = isFunction(props.feature) ? props.feature(Feature) : props.feature;
  const isEnabled = useStateFromStore(weblabStore, _ => _.isFeatureEnabled(feature), [feature]);
  return {
    children: props.children,
    isEnabled,
  };
});
