import { mkHeapAttributes } from '@/analytics/heapUtils';

export const heapReaderButton = () =>
  mkHeapAttributes({
    id: 'reader_button',
  });

export const heapReaderAcronymCardTitle = () =>
  mkHeapAttributes({
    id: 'reader_acronym_card_title',
  });

export const heapReaderAcronymCardDescription = () =>
  mkHeapAttributes({
    id: 'reader_acronym_card_description',
  });

export const heapReaderAcronymCardPageNumber = () =>
  mkHeapAttributes({
    id: 'reader_acronym_card_pagenumber',
  });

export const heapReaderCitationAuthorLink = () => {
  // Don't include Author ID as an attribute- it's added in CLPaperAuthors.jsx
  return {
    id: 'reader_citation_author_link',
  };
};

export const heapReaderCitationBoundingBox = (props: {
  'citation-paper-id': string;
  'citesee-citation': boolean;
}) =>
  mkHeapAttributes({
    id: 'reader_citation_bounding_box',
    ...props,
  });

export const heapReaderCitationLibraryButton = (props: { 'paper-id': string }) => {
  return {
    id: 'reader_citation_library_button',
    ...props,
  };
};

export const heapReaderCitationPaperTitle = (props: { 'paper-id': string }) => {
  return {
    id: 'reader_citation_paper_title',
    ...props,
  };
};

export const heapReaderCiteButton = () =>
  mkHeapAttributes({
    id: 'reader_cite_button',
  });

export const heapReaderDownloadButton = () =>
  mkHeapAttributes({
    id: 'reader_download_button',
  });

export const heapReaderShareButton = () =>
  mkHeapAttributes({
    id: 'reader_share_button',
  });

export const heapReaderAboutButton = () =>
  mkHeapAttributes({
    id: 'reader_about_button',
  });

export const heapReaderFeedbackButton = () =>
  mkHeapAttributes({
    id: 'reader_feedback_button',
  });

export const heapReaderLibraryButton = () =>
  mkHeapAttributes({
    id: 'reader_library_button',
  });

export const heapReaderCreateAlertButton = () =>
  mkHeapAttributes({
    id: 'reader_create_alert_button',
  });

export const heapReaderToggleAlertButton = () =>
  mkHeapAttributes({
    id: 'reader_toggle_alert_button',
  });

export const heapReaderPageWrapper = props =>
  mkHeapAttributes({
    id: 'reader_page_wrapper',
    ...props,
  });

export const heapReaderNavigateButton = () =>
  mkHeapAttributes({
    id: 'reader_navigate_button',
  });

export const heapReaderToPdpLink = () =>
  mkHeapAttributes({
    id: 'reader_to_pdp_link',
  });

export const heapReaderZoomInButton = () =>
  mkHeapAttributes({
    id: 'reader_zoom_in_button',
  });

export const heapReaderZoomOutButton = () =>
  mkHeapAttributes({
    id: 'reader_zoom_out_button',
  });

export const heapReaderZoomDropdownButton = () =>
  mkHeapAttributes({
    id: 'reader_zoom_dropdown_button',
  });

export const heapReaderZoomDropdownOption = (props: { 'zoom-option': string }) =>
  mkHeapAttributes({
    id: 'reader_zoom_dropdown_option',
    ...props,
  });

export const heapReaderPrintButton = () =>
  mkHeapAttributes({
    id: 'reader_print_button',
  });

export const heapReaderThumbnailTab = () =>
  mkHeapAttributes({
    id: 'reader_thumbnail_tab',
  });

export const heapReaderTOCTab = () =>
  mkHeapAttributes({
    id: 'reader_toc_tab',
  });

export const heapReaderSkimmingButton = (props: { 'toggle-state': string }) =>
  mkHeapAttributes({
    id: 'reader_skimming_button',
    ...props,
  });

export const heapReaderSkimmingPageHighlight = () =>
  mkHeapAttributes({
    id: 'reader_skimming_page_highlight',
  });

export const heapReaderSkimmingPageFlag = () =>
  mkHeapAttributes({
    id: 'reader_skimming_page_flag',
  });

export const heapReaderSkimmingCloseButton = () =>
  mkHeapAttributes({
    id: 'reader_skimming_close_button',
  });

export const heapReaderSkimmingPanelSnippet = () =>
  mkHeapAttributes({
    id: 'reader_skimming_panel_snippet',
  });

export const heapReaderSkimmingSettingsButton = () =>
  mkHeapAttributes({
    id: 'reader_skimming_settings_button',
  });

export const heapReaderSkimmingSettingsLabelToggle = (props: {
  label: string;
  'toggle-state': string;
}) =>
  mkHeapAttributes({
    id: 'reader_skimming_settings_label_toggle',
    ...props,
  });

export const heapReaderSkimmingSettingsEnableSkimmingToggle = (props: { 'toggle-state': string }) =>
  mkHeapAttributes({
    id: 'reader_skimming_settings_enable_skimming_toggle',
    ...props,
  });

export const heapReaderSkimmingSettingsNumberOfHighlightsDropdown = () =>
  mkHeapAttributes({
    id: 'reader_skimming_settings_number_of_highlights_dropdown',
  });

export const heapReaderSkimmingSettingsOpacityRadio = (props: { opacity: string }) =>
  mkHeapAttributes({
    id: 'reader_skimming_settings_opacity_radio',
    ...props,
  });

export const heapReaderSkimmingSettingsMarginFlagToggle = (props: { 'toggle-state': string }) =>
  mkHeapAttributes({
    id: 'reader_skimming_settings_margin_flag_toggle',
    ...props,
  });

export const heapReaderNoteTakingButton = (props: { 'toggle-state': string }) =>
  mkHeapAttributes({
    id: 'reader_notetaking_button',
    ...props,
  });

export const heapReaderNoteTakingCloseButton = () =>
  mkHeapAttributes({
    id: 'reader_notetaking_close_button',
  });

export const heapReaderCiteSeeButton = (props: { 'toggle-state': string }) =>
  mkHeapAttributes({
    id: 'reader_citesee_button',
    ...props,
  });

export const heapReaderCiteSeeCloseButton = () =>
  mkHeapAttributes({
    id: 'reader_citesee_close_button',
  });

export const heapReaderCitationsToggle = (props: { 'toggle-state': string }) =>
  mkHeapAttributes({
    id: 'reader_citations_toggle',
    ...props,
  });

export const heapReaderCiteseeSavedToLibrary = (props: { 'toggle-state': string }) =>
  mkHeapAttributes({
    id: 'reader_citesee_saved_to_library',
    ...props,
  });

export const heapReaderCiteseeCitedByLibrary = (props: { 'toggle-state': string }) =>
  mkHeapAttributes({
    id: 'reader_citesee_cited_by_library',
    ...props,
  });

export const heapReaderTermUnderstandingButton = (props: { 'toggle-state': string }) =>
  mkHeapAttributes({
    id: 'reader_term_understanding_button',
    ...props,
  });

export const heapReaderTermCardTitle = () =>
  mkHeapAttributes({
    id: 'reader_term_card_title',
  });

export const heapReaderTermCardDescription = () =>
  mkHeapAttributes({
    id: 'reader_term_card_description',
  });

export const heapReaderTermCardFAQ = () =>
  mkHeapAttributes({
    id: 'reader_term_card_faq',
  });

export const heapReaderTermCardAIExplanation = () =>
  mkHeapAttributes({
    id: 'reader_term_card_AI_explanation',
  });

export const heapReaderAcronymsToggle = (props: { 'toggle-state': string }) =>
  mkHeapAttributes({
    id: 'reader_acronyms_toggle',
    ...props,
  });

export const heapReaderTermsToggle = (props: { 'toggle-state': string }) =>
  mkHeapAttributes({
    id: 'reader_terms_toggle',
    ...props,
  });

export const heapReaderButtonPaperRow = () =>
  mkHeapAttributes({
    id: 'reader_button_paper_row',
  });
