import { hasValue, Nullable } from '@/utils/types';

export const AHP_INFLUENCE_V2 = 'ahp_influence_v2';
export const FEED_LENS = 'feed_lens';

// When removing enrollments, make sure to archive the enrollment records in the db by writing
// a migration script to set the archived_at_utc timestamp.
const Enrollment = {
  AHP_INFLUENCE_V2,
  FEED_LENS,
} as const;

export type EnrollmentKey = keyof typeof Enrollment;
export type EnrollmentValue = (typeof Enrollment)[keyof typeof Enrollment];

Object.freeze(Enrollment);

export default Enrollment;

export function optEnrollmentValue(enrollmentValue?: Nullable<string>): Nullable<EnrollmentValue> {
  if (!hasValue(enrollmentValue)) {
    return null;
  }
  const value = Object.values(Enrollment).find(value => value === enrollmentValue);
  return value || null;
}

export function getEnrollmentValue(enrollmentValue?: Nullable<string>): EnrollmentValue {
  const optValue = optEnrollmentValue(enrollmentValue);
  if (!hasValue(optValue)) {
    throw new Error(`string "${enrollmentValue}" is not a known EnrollmentValue`);
  }
  return optValue;
}
