import Immutable from 'immutable';

export type PaperPdfOutputComponentIdFromJS = {
  pk: string;
  sk: string;
  nickname: string;
};

type Props = {
  pk: string;
  sk: string;
  nickname: string;
};

const defaultProps: Props = {
  pk: '',
  sk: '',
  nickname: '',
};

export const PaperPdfOutputComponentIdFactory = Immutable.Record<Props>(defaultProps);

export type PaperPdfOutputComponentIdRecord = Immutable.RecordOf<Props>;

export function getPaperPdfOutputComponentIdFromJS(
  args: PaperPdfOutputComponentIdFromJS
): PaperPdfOutputComponentIdRecord {
  return PaperPdfOutputComponentIdFactory({
    ...args,
  });
}
