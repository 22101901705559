import { HttpStatus } from '@/router/routerUtils';
import { ReactNodeish } from '@/utils/types';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import HttpStatusMeta from '@/constants/HttpStatusMeta';

import React from 'react';

export default class NotFoundRoute extends React.Component {
  static getPageTitle = (): string => HttpStatusMeta.NOT_FOUND.TITLE;

  static getPageMetaDescription = (): string => HttpStatusMeta.NOT_FOUND.DESC;

  static willRouteTo(): HttpStatus {
    return { status: 404 };
  }

  render(): ReactNodeish {
    return (
      <AsyncLoadedPage
        footer={false}
        load={{
          importer: () => import(/* webpackChunkName: "shared-NotFoundPage" */ './NotFoundPage'),
          chunkName: 'shared-NotFoundPage',
          moduleId: require.resolveWeak('./NotFoundPage'),
        }}
      />
    );
  }
}
