import { cleanAndStringifyQuery, searchStringToObj } from '@/router/routerUtils';
import { isBrowser } from '@/utils/env';

import type { Nullable } from '@/utils/types';

export const SHARE_DIRECT_LINK_UTM_SOURCE = 'direct_link';

export function buildShareLink(searchHistory: Nullable<string>): Nullable<string> {
  if (!isBrowser()) {
    return null;
  }
  const queryObj = searchStringToObj(searchHistory);

  // drop any existing utm_source so we can replace it with the current shared medium but keep all other queries
  delete queryObj.utm_source;
  const cleanedQuery = cleanAndStringifyQuery(queryObj);
  const newQs = `?utm_source=${SHARE_DIRECT_LINK_UTM_SOURCE}${
    cleanedQuery ? '&' + cleanedQuery : ''
  }`;
  const currentUrl = searchHistory
    ? window?.location.href.replace(searchHistory, newQs)
    : window?.location.href + newQs;

  return currentUrl;
}
