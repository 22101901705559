import ClickEvent from '@/analytics/models/ClickEvent';
import EventTarget from '@/analytics/constants/EventTarget';
import HoverEvent from '@/analytics/models/HoverEvent';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import PropTypes from 'prop-types';
import React from 'react';

const NOOP = () => {};

// We don't log anything for alerts other than through heap-props
export const defaultOnClickAlert = NOOP;

export const defaultOnClickAuthor = ({ paper, analyticsLocation, compData, eventData }) => {
  const eventTarget = EventTarget.getIn(analyticsLocation || EventTarget.PaperObject, 'AUTHOR');
  const { authorId } = compData;
  const index = eventData && typeof eventData.index === 'number' ? eventData.index : null;
  trackAnalyticsEvent(
    ClickEvent.create(eventTarget, {
      paperId: paper.id,
      authorId,
      index,
    })
  );
};

export const defaultOnClickCite = ({ paper, analyticsLocation, eventData }) => {
  const eventTarget = EventTarget.getIn(
    analyticsLocation || EventTarget.PaperObject,
    'Action',
    'CITE'
  );
  const index = eventData && typeof eventData.index === 'number' ? eventData.index : null;
  trackAnalyticsEvent(
    ClickEvent.create(eventTarget, {
      paperId: paper.id,
      index,
    })
  );
};

// We don't log anything for flags other than through heap-props
export const defaultOnClickFlag = NOOP;

export const defaultOnClickLibrary = ({ paper, analyticsLocation, compData, eventData }) => {
  const eventTarget = EventTarget.getIn(
    analyticsLocation || EventTarget.PaperObject,
    'Action',
    'READING_LIST'
  );
  const { action } = compData;
  const index = eventData && typeof eventData.index === 'number' ? eventData.index : null;
  trackAnalyticsEvent(
    ClickEvent.create(eventTarget, {
      paperId: paper.id,
      action,
      index,
    })
  );
};

export const defaultOnClickTitle = ({ paper, analyticsLocation, eventData }) => {
  const eventTarget = EventTarget.getIn(analyticsLocation || EventTarget.PaperObject, 'TITLE');
  const index = eventData && typeof eventData.index === 'number' ? eventData.index : null;

  trackAnalyticsEvent(
    ClickEvent.create(eventTarget, {
      paperId: paper.id,
      index,
      cueTypes: eventData.cueTypes || undefined,
    })
  );
};

export const defaultOnClickViewPaper = ({ paper, analyticsLocation, compData, eventData }) => {
  const eventTarget = EventTarget.getIn(
    analyticsLocation || EventTarget.PaperObject,
    'Action',
    'PAPER_LINK'
  );
  const index = eventData && typeof eventData.index === 'number' ? eventData.index : null;
  trackAnalyticsEvent(
    ClickEvent.create(eventTarget, {
      paperId: paper.id,
      index,
      linkType: compData.linkType,
      linkUrl: compData.linkUrl,
    })
  );
};

export const defaultOnHoverStats = ({ paper, analyticsLocation, compData, eventData }) => {
  const eventTarget = EventTarget.getIn(analyticsLocation || EventTarget.PaperObject, 'STAT');
  const index = eventData && typeof eventData.index === 'number' ? eventData.index : null;
  trackAnalyticsEvent(
    HoverEvent.create(eventTarget, {
      id: paper.id,
      index,
      statName: compData.citationStat,
    })
  );
};

export const defaultOnToggleAbstract = ({ paper, analyticsLocation, compData, eventData }) => {
  const eventTarget = EventTarget.getIn(
    analyticsLocation || EventTarget.PaperObject,
    'Abstract',
    compData.expandState
  );
  const index = eventData && typeof eventData.index === 'number' ? eventData.index : null;
  trackAnalyticsEvent(
    ClickEvent.create(eventTarget, {
      paperId: paper.id,
      index,
    })
  );
};

// We don't log anything for annotations other than through heap-props
export const defaultOnClickAnnotation = NOOP;

export const defaultOnClickAnnotationButton = NOOP;

const defaultContext = Object.freeze({
  // Cannot use defaults from above because paper will be undefined
  onClickAlert: NOOP,
  onClickAuthor: NOOP,
  onClickCite: NOOP,
  onClickFlag: NOOP,
  onClickLibrary: NOOP,
  onClickTitle: NOOP,
  onClickViewPaper: NOOP,
  onHoverStats: NOOP,
  onToggleAbstract: NOOP,
  onClickAnnotation: NOOP,
  onClickAnnotationButton: NOOP,
});

export const AnalyticsContext = React.createContext(defaultContext);
export const AnalyticsContextHelper = ({ children, onUpdate }) => (
  <AnalyticsContext.Consumer>
    {context => {
      onUpdate && onUpdate(context);
      return children;
    }}
  </AnalyticsContext.Consumer>
);

export default class CLPaperAnalytics extends React.PureComponent {
  static contextTypes = {
    analyticsLocation: PropTypes.object,
  };

  static defaultProps = {
    eventData: {},
    onClickAlert: false,
    onClickAuthor: true,
    onClickCite: true,
    onClickFlag: false,
    onClickLibrary: true,
    onClickTitle: true,
    onClickViewPaper: true,
    onHoverStats: false,
    onToggleAbstract: true,
    onClickAnnotation: false,
    onClickAnnotationButton: false,
  };

  render() {
    const { analyticsLocation } = this.context;
    const {
      paper,
      eventData,
      children,
      onClickAlert,
      onClickAuthor,
      onClickCite,
      onClickFlag,
      onClickLibrary,
      onClickTitle,
      onClickViewPaper,
      onHoverStats,
      onToggleAbstract,
      onClickAnnotation,
      onClickAnnotationButton,
    } = this.props;
    const commonData = {
      paper,
      eventData,
      analyticsLocation,
    };
    return (
      <AnalyticsContext.Provider
        value={{
          onClickAlert: mkComponentCallback({
            defaultCallback: defaultOnClickAlert,
            customCallback: onClickAlert,
            ...commonData,
          }),

          onClickAuthor: mkComponentCallback({
            defaultCallback: defaultOnClickAuthor,
            customCallback: onClickAuthor,
            ...commonData,
          }),

          onClickCite: mkComponentCallback({
            defaultCallback: defaultOnClickCite,
            customCallback: onClickCite,
            ...commonData,
          }),

          onClickFlag: mkComponentCallback({
            defaultCallback: defaultOnClickFlag,
            customCallback: onClickFlag,
            ...commonData,
          }),

          onClickLibrary: mkComponentCallback({
            defaultCallback: defaultOnClickLibrary,
            customCallback: onClickLibrary,
            ...commonData,
          }),

          onClickTitle: mkComponentCallback({
            defaultCallback: defaultOnClickTitle,
            customCallback: onClickTitle,
            ...commonData,
          }),

          onClickViewPaper: mkComponentCallback({
            defaultCallback: defaultOnClickViewPaper,
            customCallback: onClickViewPaper,
            ...commonData,
          }),

          onHoverStats: mkComponentCallback({
            defaultCallback: defaultOnHoverStats,
            customCallback: onHoverStats,
            ...commonData,
          }),

          onToggleAbstract: mkComponentCallback({
            defaultCallback: defaultOnToggleAbstract,
            customCallback: onToggleAbstract,
            ...commonData,
          }),

          onClickAnnotation: mkComponentCallback({
            defaultCallback: defaultOnClickAnnotation,
            customCallback: onClickAnnotation,
            ...commonData,
          }),

          onClickAnnotationButton: mkComponentCallback({
            defaultCallback: defaultOnClickAnnotationButton,
            customCallback: onClickAnnotationButton,
            ...commonData,
          }),
        }}>
        {children}
      </AnalyticsContext.Provider>
    );
  }
}

export function mkComponentCallback({
  defaultCallback,
  customCallback,
  analyticsLocation,
  paper,
  eventData,
}) {
  return compData => {
    if (customCallback === false) {
      // default analytics disabled, do nothing
      return;
    }
    const args = {
      paper,
      compData,
      eventData,
      analyticsLocation: analyticsLocation || null,
    };
    if (typeof customCallback === 'function') {
      customCallback(args);
    } else {
      defaultCallback(args);
    }
  };
}
