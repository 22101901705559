import CLDropdownBase, {
  SIZE as _SIZE,
  TYPE as _TYPE,
  CLDropdownBaseProps,
} from './CLDropdownBase';

import { ExampleConfig } from '@/components/library/ExampleUtils';
import { ReactNodeish } from '@/utils/types';
import Icon from '@/components/shared/icon/Icon';

import React from 'react';

export const SIZE = _SIZE;
export const TYPE = _TYPE;

type CLDropdownProps = CLDropdownBaseProps;

export default function CLDropdown(props: CLDropdownProps): ReactNodeish {
  return <CLDropdownBase {...props} />;
}

export const exampleConfig: ExampleConfig = {
  getComponent: () => CLDropdown,
  fields: [
    {
      name: 'label',
      desc: 'Text displayed within the button',
      value: {
        type: 'text',
        default: 'Button Text',
      },
    },
    {
      name: 'type',
      desc: 'Importance within the form',
      value: {
        type: 'select',
        default: TYPE.DEFAULT,
        options: [TYPE.DEFAULT, TYPE.PRIMARY, TYPE.SECONDARY],
      },
    },
    {
      name: 'size',
      desc: 'How large the button should be',
      value: {
        type: 'select',
        default: SIZE.DEFAULT,
        options: [SIZE.DEFAULT, SIZE.SMALL, SIZE.LARGE],
      },
    },
    {
      name: 'icon',
      desc: 'Key to an icon in the icon sprite',
      value: {
        type: 'icon',
        default: null,
        transform: iconKey => (iconKey ? <Icon icon={iconKey} width="12" height="12" /> : null),
      },
    },
    {
      name: 'hasArrowDivider',
      desc: 'Whether to show a line between the label and the dropdown arrow',
      value: {
        type: 'boolean',
        default: false,
      },
    },
    {
      name: 'isDropdownShown',
      desc: 'Whether the dropdown is visible or not, if not self-managed',
      value: {
        type: 'boolean',
        default: null,
      },
    },
    {
      name: 'className',
      desc: 'HTML classes to be added to the button',
      value: {
        type: 'text',
        default: '',
      },
    },
  ],

  examples: [
    {
      title: 'Account Dropdown',
      desc: 'Dropdown button used for the Account Menu',
      props: {
        label: 'Account',
        type: TYPE.DEFAULT,
        size: SIZE.DEFAULT,
        hasArrowDivider: true,
        icon: 'user',
        className: '',
        contents: () => (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `<div style="padding: 1rem; white-space: nowrap">
              <div><a>Account Settings</a></div>
              <div><a>Moderation Tools</a></div>
              <div><a>Admin Tools</a></div>
              <hr/>
              <div><a>Sign Out</a></div>
              </div>`,
            }}
          />
        ),
      },
      render: comp => (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div>{comp}</div>
        </div>
      ),
    },
    {
      title: 'Top Left',
      desc: 'Arrow is pointing up and in the left of center',
      props: {
        label: 'Top Left',
        isDropdownShown: true,
        contents: () => (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `<div style="padding: 1rem; white-space: nowrap;">Menu Content</div>`,
            }}
          />
        ),
      },
      render: comp => (
        <div
          style={{
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            display: 'flex',
            height: '100%',
          }}>
          <div>{comp}</div>
        </div>
      ),
    },
    {
      title: 'Top Middle',
      desc: 'Arrow is pointing up and in the center',
      props: {
        label: 'Top Middle',
        isDropdownShown: true,
        contents: () => (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `<div style="padding: 1rem; white-space: nowrap;">Menu Content</div>`,
            }}
          />
        ),
      },
      render: comp => (
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'flex-start',
            display: 'flex',
            height: '100%',
          }}>
          <div>{comp}</div>
        </div>
      ),
    },
    {
      title: 'Top Right',
      desc: 'Arrow is pointing up and in the right of center',
      props: {
        label: 'Top Right',
        isDropdownShown: true,
        contents: () => (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `<div style="padding: 1rem; white-space: nowrap;">Menu Content</div>`,
            }}
          />
        ),
      },
      render: comp => (
        <div
          style={{
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            display: 'flex',
            height: '100%',
          }}>
          <div>{comp}</div>
        </div>
      ),
    },
    {
      title: 'Bottom Left',
      desc: 'Arrow is pointing up and in the left of center',
      props: {
        label: 'Bottom Left',
        isDropdownShown: true,
        contents: () => (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `<div style="padding: 1rem; white-space: nowrap;">Menu Content</div>`,
            }}
          />
        ),
      },
      render: comp => (
        <div
          style={{
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            display: 'flex',
            height: '100%',
          }}>
          <div>{comp}</div>
        </div>
      ),
    },
    {
      title: 'Bottom Middle',
      desc: 'Arrow is pointing up and in the center',
      props: {
        label: 'Bottom Middle',
        isDropdownShown: true,
        contents: () => (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `<div style="padding: 1rem; white-space: nowrap;">Menu Content</div>`,
            }}
          />
        ),
      },
      render: comp => (
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'flex-end',
            display: 'flex',
            height: '100%',
          }}>
          <div>{comp}</div>
        </div>
      ),
    },
    {
      title: 'Bottom Right',
      desc: 'Arrow is pointing up and in the right of center',
      props: {
        label: 'Bottom Right',
        isDropdownShown: true,
        contents: () => (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `<div style="padding: 1rem; white-space: nowrap;">Menu Content</div>`,
            }}
          />
        ),
      },
      render: comp => (
        <div
          style={{
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            display: 'flex',
            height: '100%',
          }}>
          <div>{comp}</div>
        </div>
      ),
    },
  ],

  events: {
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => ({
      label: event.currentTarget.innerText,
      class: event.currentTarget.className,
    }),
  },
};
