import { ReaderCitationRelevanceCue } from './reader-constant';

import {
  CiteSeeStateRecord,
  CiteSeeStateRecordFactory,
} from '@/models/reader-widgets/ReaderCiteSee';
import { Nullable } from '@/utils/types';
import { PageIndexToCited } from '@/stores/reader/ReaderCitationStore';
import { ReaderWidget } from '@/models/reader-widgets/ReaderWidgets';
import { useAppContext, useStateFromStore } from '@/AppContext';

import React from 'react';

export type CiteSeeCounts = {
  savedToLibraryCount: number;
  citedByLibraryCount: number;
  pageIndexToCiteSeeCount: Map<number, number>;
};

export function useRelevanceCueForCitedPaper(
  citedCorpusId: Nullable<number>
): Nullable<ReaderCitationRelevanceCue> {
  const { libraryFolderStore, libraryCitationIntersectionStore, readerWidgetStore } =
    useAppContext();

  const isPaperInLibrary = useStateFromStore(
    libraryFolderStore,
    _ => citedCorpusId && _.isPaperWithCorpusIdInLibrary(citedCorpusId)
  );
  const isPaperCitedByLibrary = useStateFromStore(
    libraryCitationIntersectionStore,
    _ => citedCorpusId && _.isPaperCitedByLibrary(citedCorpusId)
  );

  const { isCitedByLibraryEnabled, isSavedToLibraryEnabled } = useStateFromStore(
    readerWidgetStore,
    _ => {
      const state = _.getStateForWidget(ReaderWidget.CITE_SEE) || CiteSeeStateRecordFactory();
      return state as CiteSeeStateRecord;
    }
  );

  const isSavedToLibraryCue = isPaperInLibrary && isSavedToLibraryEnabled;
  const isCitedByLibraryCue = isPaperCitedByLibrary && isCitedByLibraryEnabled;

  // determine which citesee cue to show, prioritizing saved to library
  // taking into account the state of the citesee toggles
  // if a citation matches multiple cues, it will fall back to the secondary cue
  // if the primary one is toggled off
  return isSavedToLibraryCue
    ? ReaderCitationRelevanceCue.SAVED_TO_LIBRARY
    : isCitedByLibraryCue
      ? ReaderCitationRelevanceCue.CITED_BY_LIBRARY
      : null;
}

export function useRelatedCorpusIdForCitedPaper(
  citedCorpusId: Nullable<number>,
  relevanceCue: Nullable<ReaderCitationRelevanceCue>
): Nullable<number> {
  const { libraryCitationIntersectionStore } = useAppContext();
  const citingLibraryCorpusId = useStateFromStore(libraryCitationIntersectionStore, _ =>
    citedCorpusId ? _.getCitingCorpusIdsForCitedCorpusId(citedCorpusId)?.get(0) || null : null
  );

  switch (relevanceCue) {
    case ReaderCitationRelevanceCue.CITED_BY_LIBRARY:
      return citingLibraryCorpusId;
    case ReaderCitationRelevanceCue.SAVED_TO_LIBRARY:
      return citedCorpusId;
    default:
      return null;
  }
}

export function useCiteSeeCounts(): CiteSeeCounts {
  const { libraryCitationIntersectionStore, libraryFolderStore, readerCitationStore } =
    useAppContext();
  const totalPapersInLibrary = useStateFromStore(libraryFolderStore, _ => _.getTotalPaperCount());
  const sharedCitations = useStateFromStore(libraryCitationIntersectionStore, _ =>
    _.getSharedCitations()
  );

  const pageIndexToCitationMap = readerCitationStore.getPageIndexToCitationMapping();
  const countUpCiteseeCitations = (
    pageIndexToCitationMap: PageIndexToCited
  ): {
    pageIndexToCiteSeeCount: Map<number, number>;
    savedToLibraryCorpusIds: Set<number>;
  } => {
    return pageIndexToCitationMap
      .entrySeq()
      .toList()
      .reduce(
        (memo, entry) => {
          const [pageIndex, citationMentions] = entry;
          let citeSeeCountOnPage = 0;
          citationMentions?.forEach(mention => {
            const corpusId = mention.corpusId;
            if (!corpusId) {
              return;
            }
            const isCitedByLibrary = sharedCitations.has(corpusId);
            const isSavedToLibrary = libraryFolderStore.isPaperWithCorpusIdInLibrary(corpusId);

            if (isCitedByLibrary || isSavedToLibrary) {
              citeSeeCountOnPage++;
            }

            if (isSavedToLibrary) {
              memo.savedToLibraryCorpusIds.add(corpusId);
            }
          });

          memo.pageIndexToCiteSeeCount.set(pageIndex, citeSeeCountOnPage);

          return memo;
        },
        {
          pageIndexToCiteSeeCount: new Map<number, number>(),
          savedToLibraryCorpusIds: new Set<number>(),
        }
      );
  };

  const { pageIndexToCiteSeeCount, savedToLibraryCorpusIds } = React.useMemo(() => {
    return countUpCiteseeCitations(pageIndexToCitationMap);
  }, [totalPapersInLibrary, sharedCitations]);

  return {
    pageIndexToCiteSeeCount,
    savedToLibraryCount: savedToLibraryCorpusIds.size,
    citedByLibraryCount: sharedCitations.size,
  };
}
