import {
  AllPapers,
  AuthorLibraryFolder,
  Feed,
  Library,
  LibraryFolderSourceType,
} from '@/models/library/LibraryFolderSourceType';
import { ReactNodeish } from '@/utils/types';
import Icon, { Props as IconProps } from '@/components/shared/icon/Icon';

import React from 'react';

const closedFolderIconProps: IconProps = {
  icon: 'folder-closed',
  height: '16',
  width: '16',
};

const openFolderIconProps: IconProps = {
  icon: 'folder-opened',
  height: '16',
  width: '18',
};

const closedPublicFolderIconProps: IconProps = {
  icon: 'public-folder-closed',
  height: '16',
  width: '16',
};

const openPublicFolderIconProps: IconProps = {
  icon: 'public-folder-opened',
  height: '16',
  width: '18',
};

const allPapersIconProps: IconProps = {
  icon: 'fa-page',
  height: '16',
  width: '16',
};

const unsupportedIconProps: IconProps = {
  icon: 'x',
  height: '16',
  width: '16',
};

function getIconProps(
  sourceType: LibraryFolderSourceType,
  isActive: boolean,
  isPublic: boolean
): IconProps {
  switch (sourceType) {
    case AllPapers:
      return allPapersIconProps;
    case AuthorLibraryFolder:
    case Feed:
    case Library:
      if (isActive) {
        return isPublic ? openPublicFolderIconProps : openFolderIconProps;
      }
      return isPublic ? closedPublicFolderIconProps : closedFolderIconProps;
    default:
      return unsupportedIconProps;
  }
}

export function LibraryFolderIcon({
  sourceType,
  isActive = false,
  isPublic = false,
  width,
  height,
}: {
  sourceType: LibraryFolderSourceType;
  isActive?: boolean;
  isPublic?: boolean;
  width?: string;
  height?: string;
}): ReactNodeish {
  const iconProps = getIconProps(sourceType, isActive, isPublic);
  const propsWithSizes = {
    ...iconProps,
    width: width || iconProps.width,
    height: height || iconProps.height,
  };

  return (
    <Icon
      className={`library-folder-name__folder-icon--${isActive ? 'active' : 'inactive'}`}
      {...propsWithSizes}
    />
  );
}
