export type ActionKey = keyof typeof ACTIONS;
export type ActionValue = (typeof ACTIONS)[ActionKey];
export type ErrorTypeKey = keyof typeof ERROR_TYPES;
export type ErrorTypeValue = (typeof ERROR_TYPES)[ErrorTypeKey];
export type RequestTypeKey = keyof typeof REQUEST_TYPES;
export type RequestTypeValue = (typeof REQUEST_TYPES)[RequestTypeKey];

export const ACTIONS = {
  ROUTING: 'ROUTING',
  ROUTING_ERROR: 'ROUTING_ERROR',
  SHOW_MESSAGE: 'SHOW_MESSAGE',
  CITATION_QUERY_UPDATED: 'CITATION_QUERY_UPDATED',
  REFERENCE_QUERY_UPDATED: 'REFERENCE_QUERY_UPDATED',
  QUERY_UPDATED: 'QUERY_UPDATED',
  AUTHOR_QUERY_UPDATED: 'AUTHOR_QUERY_UPDATED',
  ALERT_CREATION_ERROR: 'ALERT_CREATION_ERROR',
  API_REQUEST_STARTING: 'API_REQUEST_STARTING',
  API_REQUEST_COMPLETE: 'API_REQUEST_COMPLETE',
  API_REQUEST_FAILED: 'API_REQUEST_FAILED',
  API_ERROR: 'API_ERROR',
  CLEAR_ERROR: 'CLEAR_ERROR',
  CLEAR_ALERT_CREATION_ERROR: 'CLEAR_ALERT_CREATION_ERROR',
  FOCUS_ON_SEARCH_BAR: 'FOCUS_ON_SEARCH_BAR',
  ICON_SPRITE: 'ICON_SPRITE',
  HIDE_MESSAGE: 'HIDE_MESSAGE',
  SHOW_OVERRIDE_PANE: 'SHOW_OVERRIDE_PANE',
  READER_INITIALIZED: 'READER_INITIALIZED',
  READER_ERROR: 'READER_ERROR',
  READER_VIEW_PANEL: 'READER_VIEW_PANEL',
  READER_VIEW_SECTION: 'READER_VIEW_SECTION',
  READER_POSITION_UPDATED: 'READER_POSITION_UPDATED',
  READER_CLOSED: 'READER_CLOSED',
  REMOVE_SHARED_CITATIONS_FOR_PAPER: 'REMOVE_SHARED_CITATIONS_FOR_PAPER',
  RESEARCH_LIST_DELETE: 'RESEARCH_LIST_DELETE',
  RESEARCH_LIST_SELECT: 'RESEARCH_LIST_SELECT',
  RESEARCH_LIST_UNSELECT: 'RESEARCH_LIST_UNSELECT',
  RESEARCH_LIST_SELECT_ALL: 'RESEARCH_LIST_SELECT_ALL',
  RESEARCH_LIST_UNSELECT_ALL: 'RESEARCH_LIST_UNSELECT_ALL',
  UPDATE_FOCUSED_FIGURE_INDEX: 'UPDATE_FOCUSED_FIGURE_INDEX',
  SET_LOCAL_USER_PREFERENCE: 'SET_LOCAL_USER_PREFERENCE',
  REMOVE_LOCAL_USER_PREFERENCE: 'REMOVE_LOCAL_USER_PREFERENCE',
  GET_ALL_USER_SETTINGS: 'GET_ALL_USER_SETTINGS',
  GET_USER_SETTING: 'GET_USER_SETTING',
  SET_USER_SETTING: 'SET_USER_SETTING',
  REMOVE_USER_SETTING: 'REMOVE_USER_SETTING',
  CLEAR_ALL_USER_SETTINGS: 'CLEAR_ALL_USER_SETTINGS',
  SET_LIBRARY_STORE_QUERY: 'SET_LIBRARY_STORE_QUERY',
  PAPER_NAV_DOM_DIRTY: 'PAPER_NAV_DOM_DIRTY',
  PAPER_NAV_TARGET_PENDING: 'PAPER_NAV_TARGET_PENDING',
  PAPER_NAV_TARGET_COMPLETE: 'PAPER_NAV_TARGET_COMPLETE',
  PAPER_NAV_TARGET_ADDED: 'PAPER_NAV_TARGET_ADDED',
  PAPER_NAV_TARGET_REMOVED: 'PAPER_NAV_TARGET_REMOVED',
  PAPER_DETAIL_LOADED: 'PAPER_DETAIL_LOADED',
  TABBED_CARD_ADDED: 'TABBED_CARD_ADDED',
  TABBED_CARD_REMOVED: 'TABBED_CARD_REMOVED',
  SHOW_SHELF: 'SHOW_SHELF',
  HIDE_SHELF: 'HIDE_SHELF',
  VIEW_ONLY: 'VIEW_ONLY',
  VENUE_QUERY_UPDATED: 'VENUE_QUERY_UPDATED',
} as const;

export const REQUEST_TYPES = {
  SEARCH: 'SEARCH',
  SEARCH_PAPERS: 'SEARCH_PAPERS',
  PAPER_DETAIL: 'PAPER_DETAIL',
  PAPER_LABS: 'PAPER_LABS',
  PAPERS_BATCH: 'PAPERS_BATCH',
  CITATIONS: 'CITATIONS',
  CITATION_COMPLETIONS: 'CITATION_COMPLETIONS',
  SEARCH_CITATIONS: 'SEARCH_CITATIONS',
  SEARCH_CITATIONS_AGG: 'SEARCH_CITATIONS_AGG',
  SEARCH_REFERENCES: 'SEARCH_REFERENCES',
  SEARCH_REFERENCES_AGG: 'SEARCH_REFERENCES_AGG',
  REFERENCE_COMPLETIONS: 'REFERENCE_COMPLETIONS',
  ANNOTATE_LIBRARY_ENTRY: 'ANNOTATE_LIBRARY_ENTRY',
  AUTH_API__COGNITO_LOGIN: 'AUTH_API__COGNITO_LOGIN',
  AUTH_API__GOOGLE_ONE_TAP_LOGIN: 'AUTH_API__GOOGLE_ONE_TAP_LOGIN',
  AUTHOR_PAPER_COMPLETIONS: 'AUTHOR_PAPER_COMPLETIONS',
  AUTHOR_DETAIL: 'AUTHOR_DETAIL',
  AUTHOR_DETAIL_WITH_PAPERS: 'AUTHOR_DETAIL_WITH_PAPERS',
  AUTHOR_IMPACT: 'AUTHOR_IMPACT',
  AUTHOR_PAPERS: 'AUTHOR_PAPERS',
  AUTHOR_PROFILE_CLAIM: 'AUTHOR_PROFILE_CLAIM',
  AUTHOR_PROFILE_UNCLAIM: 'AUTHOR_PROFILE_UNCLAIM',
  AUTHOR_PROFILE_FETCH: 'AUTHOR_PROFILE_FETCH',
  AUTHOR_PROFILE_UPDATE: 'AUTHOR_PROFILE_UPDATE',
  AUTHOR_RESULT_REDIRECT: 'AUTHOR_RESULT_REDIRECT',
  MENU_AUTHOR_STATS: 'MENU_AUTHOR_STATS',
  AUTHOR_STATS: 'AUTHOR_STATS',
  AUTHOR_MANAGE_PAPERS: 'AUTHOR_MANAGE_PAPERS',
  AUTHOR_INDEX_REQUEST_FETCH: 'AUTHOR_INDEX_REQUEST_FETCH',
  AUTHOR_INDEX_REQUEST_UPDATE: 'AUTHOR_INDEX_REQUEST_UPDATE',
  AUTHOR_METADATA_CORRECTIONS: 'AUTHOR_METADATA_CORRECTIONS',
  USER_CONTACT: 'USER_CONTACT',
  GET_USER_CONTACT: 'GET_USER_CONTACT',
  MODERATION_STATS: 'MODERATION_STATS',
  GET_SHARED_LIBRARY_CITATIONS_FOR_PAPER_IDS: 'GET_SHARED_LIBRARY_CITATIONS_FOR_PAPER_IDS',
  SET_AUTHOR_PROFILE_MODERATION_TAG: 'SET_AUTHOR_PROFILE_MODERATION_TAG',
  GET_AUTHOR_PROFILE_LIST_FOR_MODERATION: 'GET_AUTHOR_PROFILE_LIST_FOR_MODERATION',
  GET_AUTHOR_PROFILE_FOR_MODERATION: 'GET_AUTHOR_PROFILE_FOR_MODERATION',
  GET_PENDING_PAPER_AUTHOR_CORRECTIONS: 'GET_PENDING_PAPER_AUTHOR_CORRECTIONS',
  GET_MODERATION_RECOMMENDATION: 'GET_MODERATION_RECOMMENDATION',
  UPDATE_INTERNAL_NOTES: 'UPDATE_INTERNAL_NOTES',
  UPDATE_AUTHOR_CLAIM_STATUS: 'UPDATE_AUTHOR_CLAIM_STATUS',
  UPDATE_PAPER_AUTHOR_CORRECTION_STATUS: 'UPDATE_PAPER_AUTHOR_CORRECTION_STATUS',
  SEARCH_SUGGEST: 'SEARCH_SUGGEST',
  USER_INFO: 'USER_INFO',
  GET_BASIC_USER: 'GET_BASIC_USER',
  LOGIN_AS: 'LOGIN_AS',
  DELETE_USER: 'DELETE_USER',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_ROLES: 'UPDATE_USER_ROLES',
  MERGE_USERS: 'MERGE_USERS',
  USER_ADD_ENROLLMENT: 'USER_ADD_ENROLLMENT',
  USER_REMOVE_ENROLLMENT: 'USER_REMOVE_ENROLLMENT',
  USER_EXPOSE_TO_EXPERIMENT: 'USER_EXPOSE_TO_EXPERIMENT',
  GET_USER_EXPOSURE: 'GET_USER_EXPOSURE',
  UPDATE_ALERT_FREQUENCY: 'UPDATE_ALERT_FREQUENCY',
  RESEND_VERIFICATION_EMAIL: 'RESEND_VERIFICATION_EMAIL',
  LOGOUT: 'LOGOUT',
  LOG_ERROR: 'LOG_ERROR',
  TOP_AUTHORS: 'INSIGHTS_AUTHORS',
  TOP_UNIVERSITIES: 'TOP_UNIVERSITIES',
  TOP_PAPERS: 'TOP_PAPERS',
  TRANSFER_AUTHOR_PROFILE: 'TRANSFER_AUTHOR_PROFILE',
  ENTITIES: 'ENTITIES',
  ENTITY_BY_ID: 'ENTITY_BY_ID',
  SAMPLE_QUERIES: 'SAMPLE_QUERIES',
  ALERTS_FOR_PAGE: 'ALERTS_FOR_PAGE',
  ALERTS: 'ALERTS',
  ALERTS_HISTORY: 'ALERTS_HISTORY',
  CREATE_ALERT: 'CREATE_ALERT',
  UPDATE_ALERT: 'UPDATE_ALERT',
  DELETE_ALERT: 'DELETE_ALERT',
  UNSUBSCRIBE_FROM_ALL_ALERTS: 'UNSUBSCRIBE_FROM_ALL_ALERTS',
  VERIFY_ALERT_EMAIL: 'VERIFY_ALERT_EMAIL',
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  GET_ALL_LIBRARY_ENTRIES: 'GET_ALL_LIBRARY_ENTRIES',
  GET_LIBRARY_ANNOTATIONS_BY_STATE: 'GET_LIBRARY_ANNOTATIONS_BY_STATE',
  GET_LIBRARY_ENTRIES_BY_FOLDER: 'GET_LIBRARY_ENTRIES_BY_FOLDER',
  GET_LIBRARY_ENTRIES: 'GET_LIBRARY_ENTRIES',
  GET_LIBRARY_FOLDERS_RECOMMENDATIONS: 'GET_LIBRARY_FOLDERS_RECOMMENDATIONS',
  GET_LIBRARY_FOLDERS_RECOMMENDATIONS_BY_ID: 'GET_LIBRARY_FOLDERS_RECOMMENDATIONS_BY_ID',
  GET_MORE_LIBRARY_FOLDERS_RECOMMENDATIONS: 'GET_MORE_LIBRARY_FOLDERS_RECOMMENDATIONS',
  GET_FOLDER_BY_ID: 'GET_FOLDER_BY_ID',
  GET_UNSORTED_LIBRARY_ENTRIES: 'GET_UNSORTED_LIBRARY_ENTRIES',
  GET_UNSORTED_LIBRARY_ENTRIES_RECOMMENDATIONS: 'GET_UNSORTED_LIBRARY_ENTRIES_RECOMMENDATIONS',
  DELETE_LIBRARY_ENTRIES: 'DELETE_LIBRARY_ENTRIES',
  DELETE_LIBRARY_ENTRIES_BULK: 'DELETE_LIBRARY_ENTRIES_BULK',
  CREATE_LIBRARY_ENTRY: 'CREATE_LIBRARY_ENTRY',
  CREATE_LIBRARY_ENTRY_BULK: 'CREATE_LIBRARY_ENTRY_BULK',
  MATCHED_PAPER_CONTENT: 'MATCHED_PAPER_CONTENT',
  GET_PAPER_COUNT: 'GET_TOTAL_PAPERS_COUNT',
  GET_LIBRARY_FOLDERS: 'GET_LIBRARY_FOLDERS',
  CREATE_LIBRARY_TAG: 'CREATE_LIBRARY_TAG',
  UPDATE_LIBRARY_TAG: 'UPDATE_LIBRARY_TAG',
  DELETE_LIBRARY_TAG: 'DELETE_LIBRARY_TAG',
  CREATE_LIBRARY_FOLDER: 'CREATE_LIBRARY_FOLDER',
  COPY_LIBRARY_FOLDER: 'COPY_LIBRARY_FOLDER',
  UPDATE_LIBRARY_FOLDER: 'UPDATE_LIBRARY_FOLDER',
  DELETE_LIBRARY_FOLDER: 'DELETE_LIBRARY_FOLDER',
  REMOVE_ENTRIES_FROM_FOLDERS: 'REMOVE_ENTRIES_FROM_FOLDERS',
  MANAGE_ENTRY_TAGS: 'MANAGE_ENTRY_TAGS',
  GET_RECOMMENDATIONS_FOR_RESEARCH_HOME: 'GET_RECOMMENDATIONS_FOR_RESEARCH_HOME',
  GET_LIBRARY_RECOMMENDATIONS: 'GET_LIBRARY_RECOMMENDATIONS',
  FETCH_AUTHOR_CORRECTIONS: 'FETCH_AUTHOR_CORRECTIONS',
  FETCH_PAPER_AUTHOR_CORRECTIONS: 'FETCH_PAPER_AUTHOR_CORRECTIONS',
  FETCH_PAPERS_WITH_CORRECTIONS_APPLIED: 'FETCH_PAPERS_WITH_CORRECTIONS_APPLIED',
  GET_EXPERIMENTS: 'GET_EXPERIMENT',
  SET_EXPERIMENT_STATE: 'SET_EXPERIMENT_STATE',
  GET_FEATURE_FLAGS: 'GET_FEATURE_FLAGS',
  SET_FEATURE_FLAG_STATE: 'SET_FEATURE_FLAG_STATE',
  GET_ALL_ENROLLMENTS: 'GET_ALL_ENROLLMENTS',
  ARCHIVE_ENROLLMENT: 'ARCHIVE_ENROLLMENT',
  RECENT_ARXIV_PUB_DATE: 'RECENT_ARXIV_PUB_DATE',
  GET_SERP_RERANKING: 'GET_SERP_RERANKING',
  GET_SKIPPER_EXPERIMENTS_DATA: 'GET_SKIPPER_EXPERIMENTS_DATA',
  ADMIN_WEBLAB__GET_EXPERIMENT_STATES: 'ADMIN_WEBLAB__GET_EXPERIMENT_STATES',
  ADMIN_WEBLAB__GET_EXPERIMENT_STATES_BY_STATUS: 'ADMIN_WEBLAB__GET_EXPERIMENT_STATES_BY_STATUS',
  ADMIN_WEBLAB__SET_EXPERIMENT_STATE: 'ADMIN_WEBLAB__SET_EXPERIMENT_STATE',
  ADMIN_WEBLAB__GET_FEATURE_FLAG_STATES: 'ADMIN_WEBLAB__GET_FEATURE_FLAG_STATES',
  ADMIN_WEBLAB__SET_FEATURE_FLAG_STATE: 'ADMIN_WEBLAB__SET_FEATURE_FLAG_STATE',
  PDF_DATA: 'PDF_DATA',
  PDF_VISIBILITY: 'PDF_VISIBILITY',
  READER_PAPER_DETAILS: 'READER_PAPER_DETAILS',
  GET_LIBRARY_ENTRIES_BULK: 'GET_LIBRARY_ENTRIES_BULK',
  FETCH_LIBRARY_CUE_DATA: 'FETCH_LIBRARY_CUE_DATA',
  FETCH_AUTHOR_CUE_DATA: 'FETCH_AUTHOR_CUE_DATA',
  GQL__AUTHOR_CARDS: 'GQL__AUTHOR_CARDS',
  GQL__AUTHOR_CITING_AUTHORS: 'GQL__AUTHOR_CITING_AUTHORS',
  GQL__AUTHOR_CO_AUTHORS: 'GQL__AUTHOR_CO_AUTHORS',
  GQL__AUTHOR_INFLUENCE_STATS: 'GQL__AUTHOR_INFLUENCE_STATS',
  GQL__AUTHOR_REFERENCED_AUTHORS: 'GQL__AUTHOR_REFERENCED_AUTHORS',
  GQL__AUTHOR_RECOMMENDATIONS: 'GQL__AUTHOR_RECOMMENDATIONS',
  GQL__AUTHOR_STATS: 'GQL__AUTHOR_STATS',
  GQL__CLAIMED_AUTHOR_STATS: 'GQL__CLAIMED_AUTHOR_STATS',
  GQL__CUE_PAPERS: 'GQL__CUE_PAPERS',
  GQL__MATCHED_AUTHOR_STAT: 'GQL__MATCHED_AUTHOR_STAT',
  GQL__NO_STORE: 'GQL__NO_STORE', // Use sparingly, mostly for Admin tools
  GQL__RELATED_PAPERS: 'GQL__RELATED_PAPERS',
  GQL__TOTAL_PAPER_COUNT: 'GQL__TOTAL_PAPER_COUNT',
  GET_ALL_USER_SETTINGS: 'GET_ALL_USER_SETTINGS',
  GET_USER_SETTING_BY_KEY: 'GET_USER_SETTING_BY_KEY',
  SEARCH_PAPERS_BY_VENUE: 'SEARCH_PAPERS_BY_VENUE',
  GET_VENUE_BY_NAME: 'GET_VENUE_BY_NAME',
  GET_VENUE_BY_ID: 'GET_VENUE_BY_ID',
  CREATE_USER_SETTING: 'CREATE_USER_SETTING',
  UPDATE_USER_SETTING: 'UPDATE_USER_SETTING',
  DELETE_ALL_USER_SETTINGS: 'DELETE_ALL_USER_SETTINGS',
  DELETE_USER_SETTING_BY_KEY: 'DELETE_USER_SETTING_BY_KEY',
  DEFINITION_FOR_PAPER_TERM: 'DEFINITION_FOR_PAPER_TERM',
} as const;

export const ERROR_TYPES = {
  NETWORK_ERROR: 'NETWORK_ERROR',
  SERVER_ERROR: 'SERVER_ERROR',
  SERVER_UNAVAILABLE: 'SERVER_UNAVAILABLE',
  API_TIMEOUT: 'API_TIMEOUT',
} as const;

export const DATA = {
  FILTER_DISPLAY_LIMIT: 3,
  MAX_VENUE_LENGTH: 50,
} as const;

export const TEXT = {
  MAX_PAPER_TITLE_CHARS: 200,
} as const;

export const PARAMS = {
  PAPER_META_MAX_AUTHORS: 6,
} as const;

export default {
  actions: ACTIONS,
  requestTypes: REQUEST_TYPES,
  errorTypes: ERROR_TYPES,
  data: DATA,
  text: TEXT,
  params: PARAMS,
};
