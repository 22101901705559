/* eslint-disable react/no-multi-comp */

import Modal from './Modal';
import ModalHeader from './ModalHeader';

import { getString } from '@/content/i18n';
import { ModalId } from '@/constants/Modal';
import { showModal } from '@/actions/ModalActionCreators';
import ExternalLink from '@/components/shared/ExternalLink';
import S2Dispatcher from '@/utils/S2Dispatcher';

import PropTypes from 'prop-types';
import React from 'react';

export default class SourcesModal extends React.PureComponent {
  static contextTypes = {
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
  };

  showFeedbackModal = () => {
    const { dispatcher } = this.context;
    dispatcher.dispatch(showModal({ id: ModalId.FEEDBACK }));
  };

  render() {
    return (
      <Modal
        className="sources-modal"
        onHideClick={this.props.onHideClick}
        modalId={ModalId.SOURCES}>
        <ModalHeader
          title={getString(_ => _.appFooter.sourcesModal.title)}
          onHideClick={this.props.onHideClick}
        />
        <div className="modal-content" data-test-id="modal-sources">
          <p>{getString(_ => _.appFooter.sourcesModal.desc)}</p>
          <ul className="sources-list">
            <Source
              href="https://www.aclweb.org/portal/"
              name="ACL"
              description="Publications and transactions from the Association for Computational Linguistics."
            />
            <Source
              href="http://dl.acm.org/"
              name="ACM"
              description="Publications and transactions from the Association for Computing Machinery."
            />
            <Source
              href="https://aminer.org/"
              name="AMiner"
              description="Mining deep knowledge from scientific sources."
            />
            <Source
              href="http://arxiv.org/"
              name="arXiv"
              description="Open access to e-prints in Physics, Mathematics, Computer Science, Quantitative Biology, Quantitative Finance and Statistics."
            />
            <Source
              href="https://bioone.org/"
              name="BioOne"
              description="Scientific publisher of more than 200 leading titles in the biological, ecological, and environmental sciences."
            />
            <Source
              href="http://citeseerx.ist.psu.edu/index"
              name="CiteSeer"
              description="Scientific literature digital library and search engine."
            />
            <Source
              href="https://www.ctti-clinicaltrials.org/"
              name="Clinical Trials Transformation Initiative"
              description="Provides public access to aggregate clinical trials content from clinicaltrials.gov via the AACT database."
            />
            <Source
              href="http://dblp.uni-trier.de/"
              name="DBLP"
              description="The dblp computer science bibliography is the online reference for open bibliographic information on computer science journals and proceedings."
            />
            <Source
              href="https://www.degruyter.com/"
              name="De Gruyter"
              description="De Gruyter group publishes over 1,300 new titles each year in the humanities, social sciences, STM and law, more than 700 subscription based or Open Access journals, and a variety of digital products."
            />
            <Source
              href="http://home.frontiersin.org/"
              name="Frontiers"
              description="Peer-reviewed open access journals in science and technology."
            />
            <Source
              href="https://www.highwirepress.com/"
              name="HighWire Press"
              description="Born out of Stanford University, their aim is to bring cutting-edge digital solutions to help publishers and societies improve their engagement, impact and revenue."
            />
            <Source
              href="https://hal.archives-ouvertes.fr/"
              name="Hyper Articles en Ligne (HAL)"
              description="Open archive run by Centre pour la communication scientifique directe, a French computing centre, which is part of the French National Centre for Scientific Research (CNRS) where authors can deposit scholarly documents from all academic fields."
            />
            <Source
              href="https://www.ieee.org/index.html"
              name="IEEE"
              description="Leading organization for technology publishing."
            />
            <Source
              href="https://www.karger.com/"
              name="Karger"
              description="An independent and family-run publishing house globally active in health sciences."
            />
            <Source
              href="https://www.microsoft.com/en-us/research/project/academic/"
              name="Microsoft Academic"
              description="Powered by the Microsoft Academic Knowledge API"
            />
            <Source
              href="https://mitpress.mit.edu/"
              name="MIT Press"
              description="The MIT Press is a university press affiliated with the Massachusetts Institute of Technology in Cambridge, Massachusetts."
            />
            <Source
              name="OdySci Academic"
              description="A website for searching and ranking technical publications."
            />
            <Source
              href="https://paperswithcode.com/"
              name="Papers with Code"
              description="Provides links to code repositories for papers sourced from arXiv."
            />
            <Source
              href="https://muse.jhu.edu/"
              name="Project MUSE"
              description="Project MUSE is your trusted source for the highest quality books and journals in the humanities and social sciences from over 200 of the world’s most distinguished university presses and scholarly societies."
            />
            <Source
              href="https://www.ncbi.nlm.nih.gov/pubmed"
              name="PubMed"
              description="More than 27 million citations for biomedical papers."
            />
            <Source
              href="https://royalsociety.org/"
              name="The Royal Society"
              description="Scientific publisher of the independent scientific academy of the UK and the Commonwealth, dedicated to promoting excellence in science."
            />
            <Source
              href="https://us.sagepub.com/en-us/nam/home"
              name="SAGE Publishers"
              description="SAGE is a leading independent, academic and professional publisher of innovative, high-quality content."
            />
            <Source
              href="http://science.sciencemag.org"
              name="Science"
              description="Peer-reviewed academic journal of the American Association for the Advancement of Science and one of the world's top academic journals."
            />
            <Source
              href="http://www.scitepress.org/HomePage.aspx"
              name="SciTePress"
              description="An open access repository that specializes in publishing conference proceedings."
            />
            <Source
              href="http://spie.org/"
              name="SPIE"
              description="Scientific publisher for the international society of optics and photonics."
            />
            <Source
              href="http://www.springernature.com/us/"
              name="Springer Nature"
              description="Publisher of some of the world's most influential science and technology journals."
            />
            <Source
              href="https://taylorandfrancis.com/"
              name="Taylor & Francis"
              description="A leading publisher of scholarly journals, books, eBooks, text books and reference works."
            />
            <Source
              href="https://wolterskluwer.com/products-services/our-portfolio/health.html"
              name="Wolters Kluwer"
              description="Leading scientific publisher with a focus on biomedical research."
            />
          </ul>
          <div className="flex-row-vcenter sources-footer">
            <span>{getString(_ => _.appFooter.sourcesModal.callToAction)}</span>
            <button onClick={this.showFeedbackModal} className="button flex-right">
              {getString(_ => _.appFooter.sourcesModal.feedbackButtonLabel)}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export function Source({ href, name, description }) {
  return (
    <li>
      <strong>{href ? <ExternalLink href={href}>{name}</ExternalLink> : name}</strong>
      <br />
      <div className="source-description">{description}</div>
    </li>
  );
}
