import CLPaperAuthors from './CLPaperAuthors';
import CLPaperFoS from './CLPaperFoS';
import CLPaperPubDates from './CLPaperPubDates';
import MockPaper, { MockPaperLiteFromJs } from '../../../../../test/utils/MockPaper';

import * as util from '@/util';
import { addHeapProps } from '@/components/util/HeapTracking';
import {
  AuthorAliasFromJS,
  AuthorAliasRecordFactory,
  getAuthorAliasFromJS,
} from '@/models/author/AuthorAlias';
import { ExampleConfig } from '@/components/library/ExampleUtils';
import { getHighlightedFieldFromJS } from '@/models/HighlightedField';
import { HeapProps } from '@/analytics/heapUtils';
import { HighlightedAuthorRecordFactory } from '@/models/HighlightedAuthor';
import { isBoolean, isString, Nullable, ReactNodeish } from '@/utils/types';
import { PaperishRecord } from '@/models/Paper';
import PaperVenue from '@/components/shared/paper-venue/PaperVenue';

import classnames from 'classnames';
import Immutable from 'immutable';
import moment from 'moment';
import React from 'react';

type Props = {
  authors?: Nullable<boolean> | ReactNodeish;
  className?: Nullable<string>;
  date?: Nullable<boolean> | ReactNodeish;
  fos?: Nullable<boolean> | ReactNodeish;
  venue?: Nullable<boolean> | ReactNodeish;
  paper: PaperishRecord;
  heapProps?: Nullable<HeapProps>;
  shouldStackMeta?: Nullable<boolean>;
  isInteractive?: Nullable<boolean>;
};

export default function CLPaperMeta({
  authors,
  className,
  date,
  fos,
  venue,
  paper,
  heapProps,
  shouldStackMeta,
  isInteractive,
}: Props): ReactNodeish {
  /* shouldStackMeta gives some meta items their own line
    ex. authors
        FoS, venue
        pubdate
  */
  const fosToRender = renderFos({ fos, paper });
  const venueToRender = renderVenue({ venue, paper });
  const hasFosOrVenue = fosToRender || venueToRender;
  const fosAndVenue = (
    <React.Fragment>
      {fosToRender}
      {venueToRender}
    </React.Fragment>
  );

  return (
    <ul
      className={classnames(
        {
          'cl-paper__bulleted-row': !shouldStackMeta,
        },
        className
      )}
      {...(heapProps ? addHeapProps(heapProps) : {})}>
      {renderAuthors({ authors, paper, isInteractive })}
      {hasFosOrVenue ? (
        shouldStackMeta ? (
          <li className="cl-paper__bulleted-row">{fosAndVenue}</li>
        ) : (
          fosAndVenue
        )
      ) : null}
      {renderDate({ date, paper })}
    </ul>
  );
}

CLPaperMeta.defaultProps = {
  authors: true,
  fos: true,
  date: true,
  venue: true,
  shouldStackMeta: false,
  isInteractive: true,
};

function renderAuthors({
  authors,
  paper,
  isInteractive,
}: {
  authors?: Nullable<boolean> | ReactNodeish;
  paper: PaperishRecord;
  isInteractive?: Nullable<boolean>;
}): ReactNodeish {
  const authorsExists = paper?.authors && !paper.authors.isEmpty();

  if (!authors || (typeof authors === 'boolean' && !authorsExists)) {
    return null;
  }

  return typeof authors === 'boolean' ? (
    <CLPaperAuthors paper={paper} shouldLinkToAHP={isInteractive} />
  ) : (
    authors
  );
}

function renderFos({
  fos,
  paper,
}: {
  fos?: Nullable<boolean> | ReactNodeish;
  paper: PaperishRecord;
}): ReactNodeish {
  const fosExists = !paper?.fieldsOfStudy.isEmpty();

  if (!fos || (typeof fos === 'boolean' && !fosExists)) {
    return null;
  }

  return (
    <div className={'cl-paper__bulleted-row__item'}>
      {typeof fos === 'boolean' ? <CLPaperFoS paper={paper} /> : fos}
    </div>
  );
}

function renderVenue({
  venue,
  paper,
}: {
  venue?: Nullable<boolean> | ReactNodeish;
  paper: PaperishRecord;
}): ReactNodeish {
  const paperVenue: string = isString(paper.venue) ? paper.venue : paper.venue.text;

  // if it's a paper and empty venue
  if (isBoolean(venue) && (!paperVenue || !venue)) {
    return null;
  }

  return (
    <div className={'cl-paper__bulleted-row__item'}>
      {venue === true ? (
        <PaperVenue paper={paper} className="cl-paper-venue--paper-metadata" />
      ) : (
        venue
      )}
    </div>
  );
}

function renderDate({
  date,
  paper,
}: {
  date?: Nullable<boolean> | ReactNodeish;
  paper: PaperishRecord;
}): ReactNodeish {
  if (!date) {
    return null;
  }

  const year = paper.year;
  const isValidYear =
    !!year &&
    ((typeof year !== 'string' && year.text.length > 0) || typeof year === 'string') &&
    util.isValidYear(year);

  const pubDate = paper.pubDate;
  const canRenderPubDate =
    !!pubDate &&
    ((typeof pubDate !== 'string' && pubDate?.text?.length > 0) ||
      (typeof pubDate === 'string' && moment(pubDate).isValid()));

  // Year-only is the fallback if the full date cannot be rendered
  const canRenderYear = !canRenderPubDate && isValidYear;

  if (typeof date === 'boolean' && !canRenderPubDate && !canRenderYear) {
    return null;
  }

  return (
    <li className={'cl-paper__bulleted-row__item'}>
      {typeof date === 'boolean' ? <CLPaperPubDates paper={paper} /> : date}
    </li>
  );
}

// TODO(#21359): Split this into a separate file
const rawAuthor: AuthorAliasFromJS = {
  name: 'John Doe',
  slug: 'John-Doe',
  ids: ['1337'],
  optIsClaimed: false,
};

const author1 = HighlightedAuthorRecordFactory({
  alias: getAuthorAliasFromJS(rawAuthor),
  highlightedField: getHighlightedFieldFromJS({
    text: 'John Doe',
    fragments: [],
  }),
});

const author2 = HighlightedAuthorRecordFactory({
  alias: AuthorAliasRecordFactory({
    name: 'Peter Parker',
    slug: 'Peter-Parker',
    ids: Immutable.List(['1234']),
    optIsClaimed: false,
  }),
  highlightedField: getHighlightedFieldFromJS({
    text: 'Peter Parker',
    fragments: [],
  }),
});

const PAPER = MockPaper({
  authors: Immutable.List([author1, author1, author1, author1]),
  fieldsOfStudy: Immutable.List(['Biology', 'Computer Science']),
  pubDate: getHighlightedFieldFromJS({ text: '5 May 2001' }),
  venue: getHighlightedFieldFromJS({ text: 'A venue for a paper' }),
});

const PAPER_LITE = MockPaperLiteFromJs({
  authors: [rawAuthor, rawAuthor, rawAuthor, rawAuthor],
  fieldsOfStudy: ['Biology', 'Computer Science'],
  pubDate: '2001-05-05',
  venue: 'A venue for a paper',
});

export const exampleConfig: ExampleConfig = {
  getComponent: () => CLPaperMeta,
  fields: [
    {
      name: 'authors',
      desc: 'Displayed default authors or not',
      value: {
        type: 'boolean',
        default: true,
      },
    },
    {
      name: 'fos',
      desc: 'Displayed default fos or not',
      value: {
        type: 'boolean',
        default: true,
      },
    },
    {
      name: 'date',
      desc: 'Displayed default date or not',
      value: {
        type: 'boolean',
        default: true,
      },
    },
    {
      name: 'venue',
      desc: 'Displayed default venue or not',
      value: {
        type: 'boolean',
        default: true,
      },
    },
    {
      name: 'shouldStackMeta',
      desc: 'Stack author, FoS/venue, and pubdate on their own lines',
      value: {
        type: 'boolean',
        default: false,
      },
    },
    {
      name: 'isInteractive',
      desc: 'Adds links and click handlers when enabled',
      value: {
        type: 'boolean',
        default: true,
      },
    },
  ],

  examples: [
    {
      title: 'Default PaperMeta with Paper',
      desc: 'Default data provided by Paper passed in',
      props: {
        paper: PAPER,
        isInteractive: true,
      },
      render: comp => <div style={{ width: '1000px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'Default PaperMeta with PaperLite',
      desc: 'Default data provided by PaperLite passed in',
      props: {
        paper: PAPER_LITE,
        isInteractive: true,
      },
      render: comp => <div style={{ width: '1000px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'Custom Author',
      desc: 'Pass a different set of authors to render instead of the default',
      props: {
        paper: PAPER,
        isInteractive: true,
        authors: <CLPaperAuthors authors={Immutable.List([author2])} />,
      },
      render: comp => <div style={{ width: '1000px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'Author No Links',
      desc: 'Pass a different set of authors to render but not linking them',
      props: {
        paper: PAPER,
        isInteractive: false,
      },
      render: comp => <div style={{ width: '1000px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'Custom FOS',
      desc: 'Pass a different set of fos to render instead of the default',
      props: {
        paper: PAPER,
        isInteractive: true,
        fos: (
          <CLPaperFoS
            paper={MockPaper({
              fieldsOfStudy: Immutable.List(['Art']),
            })}
          />
        ),
      },
      render: comp => <div style={{ width: '1000px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'Custom Date',
      desc: 'Pass a different date to render instead of the default',
      props: {
        paper: PAPER,
        isInteractive: true,
        date: (
          <CLPaperPubDates
            paper={MockPaper({
              year: getHighlightedFieldFromJS({ text: '2001' }),
            })}
          />
        ),
      },
      render: comp => <div style={{ width: '1000px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'Custom Venue',
      desc: 'Pass a different venue to render instead of the default',
      props: {
        paper: PAPER,
        isInteractive: true,
        venue: (
          <PaperVenue
            paper={MockPaper({
              venue: getHighlightedFieldFromJS({ text: 'A custom venue' }),
            })}
          />
        ),
      },
      render: comp => <div style={{ width: '1000px', padding: '16px' }}>{comp}</div>,
    },
  ],

  events: {},
};
