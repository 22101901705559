import { Nullable } from '@/utils/types';
import Constants from '@/constants';

import Immutable from 'immutable';

type ApiErrorFromJS = {
  actionType?: string;
  errorType: string;
  requestType: string;
  status?: number;
  error: string;
  errorCode?: string;
  errorSubCode?: string;
  extraInfo?: Nullable<object>;
  path: string;
};

type Props = {
  actionType: string;
  errorType: string;
  requestType: string;
  status: number;
  error: string;
  errorCode: string;
  errorSubCode: string;
  extraInfo: Nullable<object>;
  path: string;
};

const defaultProps: Props = {
  actionType: Constants.actions.API_ERROR,
  errorType: '',
  requestType: '',
  status: 0,
  error: '',
  errorCode: '',
  errorSubCode: '',
  extraInfo: null,
  path: '',
};

export const ApiErrorRecordFactory = Immutable.Record<Props>(defaultProps);

export type ApiErrorRecord = Immutable.RecordOf<Props>;

export function getApiErrorFromJS(args: ApiErrorFromJS): ApiErrorRecord {
  return ApiErrorRecordFactory({
    ...args,
  });
}
