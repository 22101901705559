import { hasValue, Nullable, ReactNodeish } from '@/utils/types';
import { hideShelf } from '@/actions/ShelfActionCreators';
import { ShelfContext } from '@/models/shelf/ShelfContexts';
import { ShelfId } from '@/constants/ShelfId';
import { SIZE } from '@/components/library/button/CLButtonBase';
import BrowserUtil from '@/browser';
import CLIconButton, { TYPE } from '@/components/library/button/CLIconButton';
import EnvInfo from '@/env/EnvInfo';
import Icon from '@/components/shared/icon/Icon';
import S2Dispatcher from '@/utils/S2Dispatcher';
import ShelfStore from '@/stores/ShelfStore';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

type Props = React.PropsWithChildren<{
  ariaLabel?: Nullable<string>;
  className?: Nullable<string>;
  header?: Nullable<string> | ReactNodeish;
  shelfId: ShelfId;

  onShow?: Nullable<() => any>;
  onHide?: Nullable<() => any>;
}>;

type StateFromShelfStore = {
  // NOTE: this component doesn't control it's own visibility, due to animations (see ShelfManager.jsx)
  isVisible: boolean;
  shelfContext: Nullable<ShelfContext>;
};

type State = {} & StateFromShelfStore;

export default class ShelfManager extends React.PureComponent<Props, State> {
  static contextTypes = {
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    shelfStore: PropTypes.instanceOf(ShelfStore).isRequired,
  };

  declare context: {
    dispatcher: S2Dispatcher;
    envInfo: EnvInfo;
    shelfStore: ShelfStore;
  };

  constructor(...args: [any]) {
    super(...args);

    this.state = {
      ...this.getStateFromShelfStore(),
    };

    this.context.shelfStore.registerComponent(this, () => {
      const { isVisible } = this.state;
      this.setState(this.getStateFromShelfStore(), () => {
        if (isVisible !== this.state.isVisible) {
          this.onVisibilityChanged();
        }
      });
    });
  }

  componentDidMount(): void {
    // disable scrolling when modal opens
    BrowserUtil.disableBodyScroll();
  }

  componentWillUnmount(): void {
    BrowserUtil.enableBodyScroll();
  }

  getStateFromShelfStore(): StateFromShelfStore {
    const { shelfId } = this.props;
    const { shelfStore } = this.context;
    const isVisible = shelfStore.getVisibleShelfId() === shelfId;
    const shelfContext = isVisible ? shelfStore.getShelfContext() : null;
    return {
      isVisible,
      shelfContext,
    };
  }

  onVisibilityChanged = (): void => {
    const { onShow, onHide } = this.props;
    const { isVisible } = this.state;
    if (isVisible) {
      onShow && onShow();
    } else {
      onHide && onHide();
    }
  };

  onClickClose = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    const { isVisible } = this.state;
    if (!isVisible) {
      return; // Cannot close twice
    }

    const { dispatcher } = this.context;
    dispatcher.dispatch(hideShelf());
  };

  renderDesktop(): ReactNodeish {
    const { ariaLabel, shelfId, children, className, header } = this.props;
    let headerComp = header;
    if (header) {
      if (typeof header == 'string') {
        headerComp = (
          <div key="header" className="shelf__header-container">
            <h3 className="shelf__primary-header">{header}</h3>
          </div>
        );
      } else {
        headerComp = header;
      }
    }

    return (
      <div role="dialog" className={classnames('shelf', className)} data-shelf-id={shelfId}>
        <div className="shelf__actions">
          <button
            aria-label={hasValue(ariaLabel) ? ariaLabel : undefined}
            data-test-id="shelf-close-button"
            className="shelf__close-button"
            onClick={this.onClickClose}
            tabIndex={0}>
            <Icon icon="remove-x" height="12" width="12" />
          </button>
        </div>
        <div className="shelf__content">
          {headerComp}
          {children}
        </div>
      </div>
    );
  }

  renderMobile(): ReactNodeish {
    const { ariaLabel, shelfId, children, className, header } = this.props;
    let headerComp = header;
    if (header) {
      if (typeof header == 'string') {
        headerComp = (
          <div key="header" className="shelf__header-container shelf__header-container--mobile">
            <h3 className="shelf__primary-header shelf__primary-header--mobile">{header}</h3>
          </div>
        );
      } else {
        headerComp = header;
      }
    }

    return (
      <div className={hasValue(className) ? className : undefined} data-shelf-id={shelfId}>
        <div role="dialog" className="shelf__content shelf__content--mobile">
          <CLIconButton
            type={TYPE.DEFAULT}
            size={SIZE.LARGE}
            className="shelf__close-button--mobile"
            aria-label={ariaLabel || undefined}
            data-test-id="shelf-close-button"
            icon={<Icon icon="x" />}
            onClick={this.onClickClose}
          />
          {headerComp}
          {children}
        </div>
      </div>
    );
  }

  render(): ReactNodeish {
    const { isMobile } = this.context.envInfo;

    return isMobile ? this.renderMobile() : this.renderDesktop();
  }
}
