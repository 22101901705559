import LogLevel, { LogLevelModel } from './LogLevel';

// Private API for logging the appropriate messages based upon the current logging level
function log(level: LogLevelModel, loggerId: string, messages: any[]) {
  let outputFn = console.log; // eslint-disable-line no-console
  switch (level.priority) {
    case LogLevel.FATAL.priority:
    case LogLevel.ERROR.priority:
      outputFn = console.error; // eslint-disable-line no-console
      break;
    case LogLevel.WARN.priority:
      outputFn = console.warn; // eslint-disable-line no-console
      break;
  }
  const outputMessages = messages.map(message => {
    // If it's an error and it has a stack trace, print that instead (the stack includes the
    // original error message)
    return message.stack ? message.stack : message;
  });
  outputFn.call(console, `${level.label} ${loggerId}:`, ...outputMessages);
}

export default class Logger {
  id: string;
  level: LogLevelModel;

  /**
   * @constructor
   * @param {string} id the identifier for this logger.
   * @param {LogLevel} the default log level.
   */
  constructor(id: string, level?: LogLevelModel) {
    this.id = id;
    this.level = level || LogLevel.INFO;
  }

  /**
   * Sets the current log level, which dictates the verbosity of logs that are output.  Logs with
   * equal or greater priority than the current log level are output.
   * @param {LogLevel} level
   * @returns {object} The logger
   */
  setLevel(level: LogLevelModel): this {
    this.level = level;
    return this;
  }

  /**
   * Returns whether a message of the provided level should be output based upon the current
   * level.
   * @param {LogLevel} level
   * @returns {boolean}
   */
  isLevelEnabled(level: LogLevelModel): boolean {
    return level.priority >= this.level.priority;
  }

  /**
   * Outputs a message with the TRACE level.
   * @param {string} ...messages one or more messages to output
   * @returns {object} the logger
   */
  trace(...messages: any[]): this {
    this.isLevelEnabled(LogLevel.TRACE) && log(LogLevel.TRACE, this.id, messages);
    return this;
  }

  /**
   * Outputs a message with the DEBUG level.
   * @param {string} ...messages one or more messages to output
   * @returns {object} the logger
   */
  debug(...messages: any[]): this {
    this.isLevelEnabled(LogLevel.DEBUG) && log(LogLevel.DEBUG, this.id, messages);
    return this;
  }

  /**
   * Outputs a message with the INFO level.
   * @param {string} message
   * @returns {object} the logger
   */
  info(...messages: any[]): this {
    this.isLevelEnabled(LogLevel.INFO) && log(LogLevel.INFO, this.id, messages);
    return this;
  }
  /**
   * Outputs a message with the WARN level.
   * @param {string} ...messages one or more messages to output
   * @returns {object} the logger
   */
  warn(...messages: any[]): this {
    this.isLevelEnabled(LogLevel.WARN) && log(LogLevel.WARN, this.id, messages);
    return this;
  }
  /**
   * Outputs a message with the ERROR level.
   * @param {string} ...messages one or more messages to output
   * @returns {object} the logger
   */
  error(...messages: any[]): this {
    this.isLevelEnabled(LogLevel.ERROR) && log(LogLevel.ERROR, this.id, messages);
    return this;
  }
  /**
   * Outputs a message with the FATAL level.
   * @param {string} ...messages one or more messages to output
   * @returns {object} the logger
   */
  fatal(...messages: any[]): this {
    this.isLevelEnabled(LogLevel.FATAL) && log(LogLevel.FATAL, this.id, messages);
    return this;
  }
}
