import BaseStore from './BaseStore';

import Constants from '@/constants';
import S2Dispatcher, { DispatchPayload } from '@/utils/S2Dispatcher';

import Immutable from 'immutable';

import type { IconId } from '@/components/shared/icon/IconSprite';

export type IconSpritePayload = DispatchPayload<{
  actionType: typeof Constants.actions.ICON_SPRITE;
  data: {
    activeIconIds: IconId[];
  };
}>;

export default class IconStore extends BaseStore {
  _activeIconIds: Immutable.Set<IconId>;

  constructor(dispatcher: S2Dispatcher) {
    super();
    this._activeIconIds = Immutable.Set();

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.ICON_SPRITE: {
          this._processIconSpriteDispatch(payload as IconSpritePayload);
        }
      }
    });
  }

  _processIconSpriteDispatch(dispatch: IconSpritePayload): void {
    for (const iconId of dispatch.data.activeIconIds) {
      this._activeIconIds = this._activeIconIds.add(iconId);
    }
    this.emitChange();
  }

  // Report that an icon should be included in the sprite
  addIconToSprite(iconId: IconId): void {
    if (this._activeIconIds.has(iconId)) {
      return;
    }
    this._activeIconIds = this._activeIconIds.add(iconId);
    this.emitChange();
  }

  // Get set of icons that should be in the sprite
  getActiveIconIds(): Immutable.List<IconId> {
    return this._activeIconIds.toList();
  }

  // Generate a payload that can be sent from the server to the browser to rehydrate the store
  getPayloadForRehydration(): IconSpritePayload {
    return {
      actionType: Constants.actions.ICON_SPRITE,
      data: {
        activeIconIds: this._activeIconIds.toJS() as IconId[],
      },
    };
  }
}
