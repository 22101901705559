import { gql } from 'graphql-tag';
import Immutable from 'immutable';

export type AuthorInfluenceStatisticsFromGraphQL = {
  citingAuthorCount: number;
  coAuthorCount: number;
  referencedAuthorCount: number;
  paperCount: number;
};

type Props = {
  numCitingAuthors: number;
  numCoAuthors: number;
  numReferencedAuthors: number;
  numPublications: number;
};

const defaultProps: Props = {
  numCitingAuthors: 0,
  numCoAuthors: 0,
  numReferencedAuthors: 0,
  numPublications: 0,
};

export const AuthorInfluenceStatisticsRecordFactory = Immutable.Record<Props>(defaultProps);

export type AuthorInfluenceStatisticsRecord = Immutable.RecordOf<Props>;

export function getAuthorInfluenceStatisticsFromGraphQL(
  args: AuthorInfluenceStatisticsFromGraphQL
): AuthorInfluenceStatisticsRecord {
  return AuthorInfluenceStatisticsRecordFactory({
    numCitingAuthors: args.citingAuthorCount,
    numCoAuthors: args.coAuthorCount,
    numReferencedAuthors: args.referencedAuthorCount,
    numPublications: args.paperCount,
  });
}

export const AUTHOR_INFLUENCE_STATISTICS_FRAGMENT_NAME = 'AuthorInfluenceStatisticsRecordFragment';

export const AUTHOR_INFLUENCE_STATISTICS_FRAGMENT = gql`
  fragment ${AUTHOR_INFLUENCE_STATISTICS_FRAGMENT_NAME} on Author {
    citingAuthorCount
    coAuthorCount
    referencedAuthorCount
    paperCount
  }
`;
