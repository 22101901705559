import { getString } from '@/content/i18n';
import { heapSubmitFeedbackButton } from '@/analytics/attributes/feedback';
import { hideModal } from '@/actions/ModalActionCreators';
import { isValidEmail } from '@/utils/form-validation';
import { ModalId } from '@/constants/Modal';
import { Nullable, ReactNodeish } from '@/utils/types';
import { S2airsCorrection } from '@/models/paper-pdf/S2AirsCorrection';
import Api from '@/api/Api';
import AuthStore from '@/stores/AuthStore';
import CLAlert, { LEVEL, SEVERITY } from '@/components/library/alert/CLAlert';
import CLButton, { TYPE } from '@/components/library/button/CLButton';
import ClickEvent from '@/analytics/models/ClickEvent';
import CLIconButton from '@/components/library/button/CLIconButton';
import CLModal from '@/components/library/modal/CLModal';
import ContentWithHTML from '@/components/util/ContentWithHTML';
import EnvInfo from '@/env/EnvInfo';
import EventTarget from '@/analytics/constants/EventTarget';
import Feature from '@/weblab/Feature';
import Icon from '@/components/shared/icon/Icon';
import Input from '@/components/shared/common/form/Input';
import IsDesktop from '@/components/util/env/IsDesktop';
import IsMobile from '@/components/util/env/IsMobile';
import MessageStore from '@/stores/MessageStore';
import ModalStore from '@/stores/ModalStore';
import PaperStore from '@/stores/PaperStore';
import ReaderPdfStore from '@/stores/reader/ReaderPdfStore';
import S2Dispatcher from '@/utils/S2Dispatcher';
import TextArea from '@/components/shared/common/form/TextArea';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';
import WeblabStore from '@/weblab/WeblabStore';

import classnames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

// Topic keys internal to UI code mapped to valid freshdesk "Type"
// Inform the Customer Support about adding a new "Type" and go to the link below to add it for both dev & prod
// Dev: https://s2dev.freshdesk.com/a/admin/ticket_fields
// Prod: https://semanticscholar.freshdesk.com/a/admin/ticket_fields
export const TOPIC_OPTIONS = {
  indexJournalRequest: 'Index Journal Request',
  takedownRequest: 'Takedown Request',
  authorDisambiguation: 'Author Disambiguation',
  dataQuality: 'Data Quality',
  featureRequest: 'Feature Request',
  semanticReader: 'Semantic Reader',
  tldr: 'TLDR',
  otherProblem: 'Other Problem',
};
Object.freeze(TOPIC_OPTIONS);

const DELAY_TIMEOUT = 500;

type FeedbackModalData = {
  s2airsCorrection?: Nullable<S2airsCorrection>;
  tags?: Nullable<string[]>;
  topicSelected: string;
};

type FormErrors = {
  nameError: Nullable<string>;
  emailError: Nullable<string>;
  topicError: Nullable<string>;
  subjectError: Nullable<string>;
  feedbackError: Nullable<string>;
};

type Props = {
  modalTitle: string;
  modalHeaderContent: string;
  buttonText: string;
  data: Nullable<FeedbackModalData>;
};

type StateFromWeblabStore = {
  isFreshdeskDownEnabled: boolean;
};

type State = {
  submitting: boolean;
  feedbackWasSubmitted: boolean;
  submissionError: Nullable<string>;
  isModalVisible: boolean;
  // Form fields
  name: string;
  email: string;
  subject: string;
  topic: string;
  feedback: string;
  url: string;
  formErrors: Nullable<FormErrors>;
} & StateFromWeblabStore;

export default class FeedbackModal extends React.PureComponent<Props, State> {
  static contextTypes = {
    authStore: PropTypes.instanceOf(AuthStore).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    messageStore: PropTypes.instanceOf(MessageStore).isRequired,
    api: PropTypes.instanceOf(Api).isRequired,
    readerPdfStore: PropTypes.instanceOf(ReaderPdfStore).isRequired,
    paperStore: PropTypes.instanceOf(PaperStore).isRequired,
    modalStore: PropTypes.instanceOf(ModalStore).isRequired,
    weblabStore: PropTypes.instanceOf(WeblabStore).isRequired,
  };

  static defaultProps = {
    modalTitle: getString(_ => _.feedback.modalTitle),
    modalHeaderContent: getString(_ => _.feedback.modalHeaderContent),
    buttonText: getString(_ => _.feedback.buttonText),
  };

  constructor(...args: [any]) {
    super(...args);

    const { authStore } = this.context;

    this.state = {
      submitting: false,
      feedbackWasSubmitted: false,
      name: '',
      email: authStore.getEmail() || authStore.getAlertEmail() || '',
      subject: '',
      topic: this.props.data?.topicSelected || '',
      feedback: '',
      url: '',
      isModalVisible: true,
      submissionError: null,
      formErrors: null,
      ...this.getStateFromWeblabStore(),
    };

    this.context.weblabStore.registerComponent(this, () => {
      this.setState(this.getStateFromWeblabStore());
    });
  }

  getStateFromWeblabStore(): StateFromWeblabStore {
    const { weblabStore } = this.context;

    return {
      isFreshdeskDownEnabled: weblabStore.isFeatureEnabled(Feature.FreshdeskDown),
    };
  }

  componentDidMount(): void {
    this.setState({ url: window.location.href });
  }

  handleInputChange(inputName: string, value: string): void {
    this.setState({
      ...this.state,
      [inputName]: value,
    });
  }

  handleTopicChange = event => {
    this.setState({ topic: event.target.value });
  };

  hideModal = (): void => {
    const { dispatcher } = this.context;

    this.setState({ isModalVisible: false });
    // delay hiding modal so animation can finish
    setTimeout(() => {
      dispatcher.dispatch(hideModal());
    }, DELAY_TIMEOUT);
  };

  validateFormState() {
    const { name, email, topic, subject, feedback } = this.state;

    let nameError: Nullable<string> = null;
    let emailError: Nullable<string> = null;
    let topicError: Nullable<string> = null;
    let subjectError: Nullable<string> = null;
    let feedbackError: Nullable<string> = null;

    if (!name) {
      nameError = getString(_ => _.feedback.form.error.missingName);
    }

    if (!email) {
      emailError = getString(_ => _.feedback.form.error.missingEmail);
    }

    if (email && !isValidEmail(email)) {
      emailError = getString(_ => _.feedback.form.error.invalidEmail);
    }

    if (!topic) {
      topicError = getString(_ => _.feedback.form.error.missingTopic);
    }

    if (!subject) {
      subjectError = getString(_ => _.feedback.form.error.missingSubject);
    }

    if (!feedback) {
      feedbackError = getString(_ => _.feedback.form.error.missingFeedback);
    }

    if (nameError || emailError || topicError || subjectError || feedbackError) {
      const formErrors = {
        nameError,
        emailError,
        topicError,
        subjectError,
        feedbackError,
      };
      this.setState({ formErrors });
      return formErrors;
    }

    return null;
  }

  submitFeedback = e => {
    const {
      envInfo: { isMobile },
    } = this.context;
    e.preventDefault();

    this.setState({
      submitting: true,
      formErrors: null,
    });

    const name = this.state.name.trim();
    const email = this.state.email.trim();
    const subject = this.state.subject.trim();
    const topic = this.state.topic;
    const feedback = this.state.feedback.trim();
    const url = this.state.url.trim();
    const tags = this.props.data?.tags;
    const s2airsCorrection = this.props.data?.s2airsCorrection;
    if (s2airsCorrection) {
      s2airsCorrection.detail.kind_specific_detail.feedback = feedback;
    }

    const formStateErrors = this.validateFormState();

    // Do not submit if errors exist
    if (formStateErrors) {
      this.setState({ submitting: false });
      return;
    }

    this.setState({ submitting: true, submissionError: null });
    this.context.api
      .submitFeedback({
        name,
        email,
        topic,
        subject,
        feedback,
        url,
        tags: tags,
        s2airsCorrection: s2airsCorrection,
      })
      .then(resp => {
        if (resp.ok) {
          this.context.messageStore.addSuccess(
            getString(_ => _.feedback.successText),
            getString(_ => _.feedback.successTitle)
          );
          this.setState(
            {
              feedbackWasSubmitted: true,
            },
            () => {
              if (isMobile) {
                // delay hide modal until after animation finishes and form slides out of view
                this.hideModal();
              } else {
                this.context.dispatcher.dispatch(hideModal());
              }
            }
          );

          trackAnalyticsEvent(ClickEvent.create(EventTarget.Feedback.SENT));
        } else {
          this.setState({ submissionError: getString(_ => _.feedback.error.submissionError) });
        }
      })
      .catch(() => {
        this.setState({ submissionError: getString(_ => _.feedback.error.submissionError) });
      });

    this.setState({ submitting: false });
  };

  getFeedbackPlaceholder(): Nullable<string> {
    const { topic } = this.state;

    if (topic === TOPIC_OPTIONS.takedownRequest) {
      return getString(_ => _.feedback.form.feedbackPlaceholderText.takedownRequest);
    }

    return null;
  }

  generateErrorList = errors => {
    return Immutable.List(errors.filter(msg => !!msg));
  };

  renderFeedbackContent(): ReactNodeish {
    const { authStore } = this.context;
    const {
      name,
      email,
      subject,
      topic,
      feedback,
      url,
      isFreshdeskDownEnabled,
      submissionError,
      submitting,
      formErrors,
    } = this.state;

    const authInfo = (() => {
      if (!authStore.hasAuthenticatedUser()) {
        return null;
      }
      const loginEmail = authStore.getEmail() || (
        <i>{getString(_ => _.feedback.form.noLoginEmail)}</i>
      );
      const loginMethodId = authStore.getLoginMethodId();
      const loginMethodStr = loginMethodId
        ? getString(_ => _.login.method[loginMethodId].methodName)
        : '';
      return (
        <React.Fragment>
          <label className="feedback-modal__label">
            {getString(_ => _.feedback.form.loginDetails)}
          </label>
          {loginEmail ? (
            <p>
              {loginEmail} from {loginMethodStr}
            </p>
          ) : (
            <p>{loginMethodStr}</p>
          )}
        </React.Fragment>
      );
    })();

    return (
      <React.Fragment>
        <div className="flex-row flex-space-between">
          <h2 className="feedback-modal__header">{this.props.modalTitle}</h2>
          <IsMobile>
            <CLIconButton
              onClick={this.hideModal}
              icon={<Icon icon="x" height="15" width="15" />}
              className="feedback-modal_mobile-close-button"
            />
          </IsMobile>
        </div>
        <div>
          <ContentWithHTML content__DEPRECATED={this.props.modalHeaderContent} />
          {isFreshdeskDownEnabled && (
            <div className="feedback-modal__error-container">
              <CLAlert level={LEVEL.FORM} severity={SEVERITY.ERROR}>
                <ContentWithHTML
                  content__DEPRECATED={getString(_ => _.feedback.error.serviceDownError)}
                />
              </CLAlert>
            </div>
          )}
          {!isFreshdeskDownEnabled && (
            <form onSubmit={this.submitFeedback}>
              {submissionError && (
                <div className="feedback-modal__error-container">
                  <CLAlert level={LEVEL.FORM} severity={SEVERITY.ERROR}>
                    <ContentWithHTML content__DEPRECATED={submissionError} />
                  </CLAlert>
                </div>
              )}
              <Input
                testId="feedback-name"
                ref="name"
                label={getString(_ => _.feedback.form.name) + ' *'}
                isRequired={true}
                value={name}
                onChange={this.handleInputChange.bind(this, 'name')}
                errors={this.generateErrorList([formErrors?.nameError])}
              />
              <Input
                testId="feedback-email"
                ref="email"
                type="email"
                label={getString(_ => _.feedback.form.email) + ' *'}
                isRequired={true}
                value={email}
                onChange={this.handleInputChange.bind(this, 'email')}
                errors={this.generateErrorList([formErrors?.emailError])}
              />
              <Input
                testId="feedback-subject"
                ref="subject"
                label={getString(_ => _.feedback.form.subject) + ' *'}
                isRequired={true}
                value={subject}
                onChange={this.handleInputChange.bind(this, 'subject')}
                errors={this.generateErrorList([formErrors?.subjectError])}
              />
              {/* simulate Input appearance since we have no reusable Select
                TODO create reuseable Select https://github.com/allenai/scholar/issues/10976*/}
              <div className="s2-form-input">
                <label>
                  {getString(_ => _.feedback.form.topic)} *
                  {formErrors?.topicError && (
                    <span className="input-error" data-test-id="input-error">
                      {formErrors?.topicError}
                    </span>
                  )}
                </label>
                <div className="select--no-styles">
                  <select
                    className={classnames('legacy__select', {
                      'has-errors': formErrors?.topicError,
                    })}
                    ref="topic"
                    required={true}
                    value={topic}
                    onChange={this.handleTopicChange}>
                    <option value="">{getString(_ => _.feedback.form.topicDropdownLabel)}</option>
                    {Object.keys(TOPIC_OPTIONS).map(topicKey => (
                      <option
                        value={TOPIC_OPTIONS[topicKey]}
                        key={topicKey}
                        selected={TOPIC_OPTIONS[topicKey] === { topic }}>
                        {getString(_ => _.feedback.form.topicOptions[topicKey]) ||
                          TOPIC_OPTIONS[topicKey]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <TextArea
                ref="feedback"
                label={getString(_ => _.feedback.form.feedback)}
                rows={4}
                isRequired={true}
                value={feedback}
                placeholder={this.getFeedbackPlaceholder()}
                onChange={this.handleInputChange.bind(this, 'feedback')}
                errors={this.generateErrorList([formErrors?.feedbackError])}
              />
              <label className="feedback-modal__label">{getString(_ => _.feedback.form.url)}</label>
              <p>{url}</p>
              {authInfo}
              <div className="submit-row">
                <CLButton
                  type={TYPE.PRIMARY}
                  onClick={this.submitFeedback}
                  label={
                    submitting ? getString(_ => _.feedback.submittingText) : this.props.buttonText
                  }
                  disabled={submitting}
                  {...heapSubmitFeedbackButton({
                    tags: this.props.data?.tags || [],
                    location: this.props.data?.s2airsCorrection
                      ? this.context.modalStore.getLocation()
                      : '',
                    'sub-location': this.props.data?.s2airsCorrection
                      ? this.context.modalStore.getSubLocation()
                      : '',
                    'topic-selected': this.props.data?.topicSelected || '',
                  })}
                />
              </div>
            </form>
          )}
        </div>
      </React.Fragment>
    );
  }

  render(): ReactNodeish {
    const { isModalVisible } = this.state;

    return (
      <React.Fragment>
        <IsDesktop>
          <CLModal className="feedback-modal" modalId={ModalId.FEEDBACK}>
            {this.renderFeedbackContent()}
          </CLModal>
        </IsDesktop>
        <IsMobile>
          <div
            data-modal-id={ModalId.FEEDBACK}
            className={classnames('feedback__modal__mobile', {
              'mod-animate-in': isModalVisible,
              'mod-animate-out': !isModalVisible,
            })}>
            {this.renderFeedbackContent()}
          </div>
        </IsMobile>
      </React.Fragment>
    );
  }
}
