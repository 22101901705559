import RecommendationsSettingFolderRow from './RecommendationsSettingFolderRow';

import { getString } from '@/content/i18n';
import {
  heapRecommendationSettingCloseButton,
  heapRecommendationSettingManagePreferenceButton,
  heapRecommendationSettingViewLibraryButton,
} from '@/analytics/attributes/recommendationPage';
import { hideShelf } from '@/actions/ShelfActionCreators';
import { RECOMMENDATIONS_SETTINGS } from '@/constants/ShelfId';
import AlertsStore from '@/stores/AlertsStore';
import Api from '@/api/Api';
import CLButton, { TYPE as BUTTON_TYPE } from '@/components/library/button/CLButton';
import EventTarget from '@/analytics/constants/EventTarget';
import LibraryFolderStore from '@/stores/LibraryFolderStore';
import Link from '@/router/Link';
import S2Dispatcher from '@/utils/S2Dispatcher';
import Shelf from '@/components/shared/shelf/Shelf';
import ShowEvent from '@/analytics/models/ShowEvent';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import PropTypes from 'prop-types';
import React from 'react';

export default class RecommendationsSettingsShelf extends React.PureComponent {
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    alertsStore: PropTypes.instanceOf(AlertsStore).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    libraryFolderStore: PropTypes.instanceOf(LibraryFolderStore).isRequired,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      ...this.getStateFromLibraryFolderStore(),
    };

    this.context.libraryFolderStore.registerComponent(this, () => {
      this.setState(this.getStateFromLibraryFolderStore());
    });
  }

  getStateFromLibraryFolderStore() {
    const { libraryFolderStore } = this.context;
    return {
      folders: libraryFolderStore.getFolders().valueSeq().toList(),
    };
  }

  closeShelf = event => {
    event.preventDefault();
    this.context.dispatcher.dispatch(hideShelf());
  };

  componentDidMount() {
    trackAnalyticsEvent(ShowEvent.create(EventTarget.Library.Shelf.RECOMMENDATIONS_SETTINGS_VIEW));
  }

  render() {
    const { folders } = this.state;

    return (
      <Shelf
        ariaLabel={getString(_ => _.recommendations.settingsShelf.closeShelfAriaLabel)}
        shelfId={RECOMMENDATIONS_SETTINGS}
        className="recommendations-settings__shelf"
        header={getString(_ => _.recommendations.settingsShelf.headerText)}>
        <div key="folders" className="recommendations-settings__section">
          <p className="shelf__secondary-header">
            {getString(_ => _.recommendations.settingsShelf.folderHeader)}
          </p>
          <p className="shelf__sub-header">
            {getString(_ => _.recommendations.settingsShelf.folderDescription)}
          </p>
          {folders.map(folder => (
            <RecommendationsSettingFolderRow folder={folder} />
          ))}
        </div>
        <div key="alerts" className="recommendations-settings__section">
          <p className="shelf__secondary-header">
            {getString(_ => _.recommendations.settingsShelf.emailAlertsText)}
          </p>
          <p className="shelf__sub-header">
            {getString(_ => _.recommendations.settingsShelf.emailAlertsDescription)}
          </p>
          <Link to="ACCOUNT_ALERTS" shouldUnderline={false}>
            <CLButton
              type={BUTTON_TYPE.TERTIARY}
              label={getString(_ => _.recommendations.settingsShelf.managePreferencesLabel)}
              className="recommendations-settings__manage-preferences-button"
              testId="recommendations-settings-manage-preferences-button"
              heapProps={heapRecommendationSettingManagePreferenceButton()}
            />
          </Link>
        </div>

        <div key="buttons" className="recommendations-settings__buttons">
          <CLButton
            type={BUTTON_TYPE.PRIMARY}
            label={getString(_ => _.recommendations.settingsShelf.closeButtonLabel)}
            onClick={this.closeShelf}
            testId="recommendations-settings-save-button"
            heapProps={heapRecommendationSettingCloseButton()}
          />
          <Link to="LIBRARY_WITH_FOLDERS" shouldUnderline={false}>
            <CLButton
              type={BUTTON_TYPE.TERTIARY}
              label={getString(_ => _.recommendations.settingsShelf.viewLibraryLabel)}
              className="recommendations-settings__library-button"
              testId="recommendations-settings-library-button"
              heapProps={heapRecommendationSettingViewLibraryButton()}
            />
          </Link>
        </div>
      </Shelf>
    );
  }
}
