import {
  AHPPapersSearchSortFromJS,
  AHPPapersSearchSortRecord,
  getAHPPapersSearchSortStateFromJS,
} from '@/models/AHPPapersSearchSort';
import {
  CitationStyleFromJS,
  CitationStyleRecord,
  getCitationStyleFromJS,
} from '@/models/CitationStyle';
import {
  CiteSeeStateFromJS,
  CiteSeeStateRecord,
  getCiteSeeStateFromJS,
} from '@/models/reader-widgets/ReaderCiteSee';
import {
  getNoteTakingStateFromJS,
  NoteTakingStateFromJS,
  NoteTakingStateRecord,
} from '@/models/reader-widgets/ReaderNoteTaking';
import {
  getSkimmingStateFromJS,
  SkimmingStateFromJS,
  SkimmingStateRecord,
} from '@/models/reader-widgets/ReaderSkimming';
import {
  getTermsStateFromJS,
  TermsStateFromJS,
  TermsStateRecord,
} from '@/models/reader-widgets/ReaderTerms';
import softError from '@/utils/softError';

import Immutable from 'immutable';

import type { Nullable } from '@/utils/types';

export type RawSettingsKey = {
  id: string;
};

// When new value record types are added, append type to this list
// ex. type UserSettingValue = CiteSeeStateRecord | SomeOtherRecord | AnotherRecord;
export type UserSettingValue =
  | AHPPapersSearchSortRecord
  | CitationStyleRecord
  | CiteSeeStateRecord
  | NoteTakingStateRecord
  | SkimmingStateRecord
  | TermsStateRecord;

export type UserSettingValueFromJS =
  | AHPPapersSearchSortFromJS
  | CitationStyleFromJS
  | CiteSeeStateFromJS
  | NoteTakingStateFromJS
  | SkimmingStateFromJS
  | TermsStateFromJS;

// keys for all user settings, regardless of if they are synced to the DB or not
export enum UserSettingKey {
  ahpPapersSearchSort = 'ahpPapersSearchSort',
  readerSettings = 'readerSettings',
  readerCiteSee = 'readerCiteSee',
  readerSkimming = 'readerSkimming',
  readerNoteTaking = 'readerNoteTaking',
  readerTerms = 'readerTerms',
  unsupportedKey = 'unsupportedKey',
  allPapersWorkerProcessed = 'allPapersWorkerProcessed',
  citationStyle = 'citationStyle',
}

// keys where the settings will be synced in the DB as well
// keep in sync with UserSettingKey in UserSettings.scala
export enum PersistentUserSettingKey {
  ahpPapersSearchSort = 'ahpPapersSearchSort',
  readerCiteSee = 'readerCiteSee',
  readerSkimming = 'readerSkimming',
  readerNoteTaking = 'readerNoteTaking',
  readerTerms = 'readerTerms',
  allPapersWorkerProcessed = 'allPapersWorkerProcessed',
  citationStyle = 'citationStyle',
}

export type UserSettingFromJS = {
  id: number;
  appUserId: number;
  settingsKey: RawSettingsKey;
  settingsValue: UserSettingValueFromJS | null;
  createdAtUtc: number;
  updatedAtUtc: number;
};

type Props = {
  settingsKey: UserSettingKey;
  settingsValue: Nullable<UserSettingValue>;
  updatedAtUtc: number;
};

const defaultProps: Props = {
  settingsKey: UserSettingKey.unsupportedKey,
  settingsValue: null,
  updatedAtUtc: 0,
};

export function getUserSettingValueFromJS(
  key: PersistentUserSettingKey,
  value: UserSettingValueFromJS
): UserSettingValue | null {
  switch (key) {
    case PersistentUserSettingKey.ahpPapersSearchSort:
      return getAHPPapersSearchSortStateFromJS(value as AHPPapersSearchSortFromJS);
    case PersistentUserSettingKey.readerSkimming:
      return getSkimmingStateFromJS(value as SkimmingStateFromJS);
    case PersistentUserSettingKey.citationStyle:
      return getCitationStyleFromJS(value as CitationStyleFromJS);
    case PersistentUserSettingKey.readerCiteSee:
      return getCiteSeeStateFromJS(value as CiteSeeStateFromJS);
    case PersistentUserSettingKey.readerTerms:
      return getTermsStateFromJS(value as TermsStateFromJS);
    case PersistentUserSettingKey.readerNoteTaking:
      return getNoteTakingStateFromJS(value as NoteTakingStateFromJS);
    // This setting is only being used in the backend temporarily, therefore we don't bother
    // translating the value
    case PersistentUserSettingKey.allPapersWorkerProcessed:
      return null;
    default:
      softError(
        'user-setting-value-conversion',
        `Error converting user setting value from JS for key ${key} and value ${JSON.stringify(
          value
        )}`
      );
      return null;
  }
}

export const UserSettingRecordFactory = Immutable.Record<Props>(defaultProps);
export type UserSettingRecord = Immutable.RecordOf<Props>;

export function getUserSettingFromJS(args: UserSettingFromJS): UserSettingRecord {
  const settingsKey = UserSettingKey[args?.settingsKey?.id] || UserSettingKey.unsupportedKey;
  if (settingsKey === UserSettingKey.unsupportedKey) {
    softError(
      'user-setting-value-conversion',
      `Unsupported key detected: ${args?.settingsKey?.id}`
    );
  }
  return UserSettingRecordFactory({
    ...args,
    settingsKey,
    settingsValue:
      args?.settingsValue && getUserSettingValueFromJS(settingsKey, args?.settingsValue),
  });
}
