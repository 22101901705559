import LoadingIndicator from './LoadingIndicator';

import { getString } from '@/content/i18n';
import CLPageAlertDanger from '@/components/library/page-alert/CLPageAlertDanger';
import Page from '@/components/shared/layout/Page';

import classnames from 'classnames';
import React from 'react';

import type { ReactNodeish } from '@/utils/types';

type Props = React.PropsWithChildren<{
  className?: string;
  isLoading: boolean;
  errorMessage?: string;
  loadingMessage?: string;
}>;

export default function LoadingPage({
  isLoading,
  errorMessage,
  loadingMessage = getString(_ => _.loadingPage.defaultLoadingMessage),
  className,
  children,
  ...pageProps
}: Props): ReactNodeish {
  return (
    <Page {...pageProps} className={classnames(className, 'loading-page')}>
      {errorMessage && <CLPageAlertDanger>{errorMessage}</CLPageAlertDanger>}

      {isLoading && !!loadingMessage && (
        <div className="loading-page__indicator">
          <div className="loading-page__indicator__img">
            <LoadingIndicator />
          </div>
          <div className="loading-page__indicator__message">{loadingMessage}</div>
        </div>
      )}

      {children}
    </Page>
  );
}
