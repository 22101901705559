import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

export default class AccountCorrectionsStatusRoute extends React.Component {
  static getPageTitle = () => getString(_ => _.account.page.corrections.pageTitle);

  static async willRouteTo({ api, authorCorrectionsStore }) {
    const authorId = authorCorrectionsStore.isModeratorCorrecting
      ? authorCorrectionsStore.getModeratorCorrectionsAuthorId()
      : null;
    if (authorId) {
      return Promise.all([
        api.fetchAuthorCorrectionsForAuthor({ authorId }),
        api.fetchPaperAuthorCorrectionsForAuthor({ authorId, includePaperModel: 'PaperLite' }),
      ]);
    }
  }

  render() {
    return (
      <AsyncLoadedPage
        showLoadingPage={false}
        load={{
          importer: () =>
            import(
              /* webpackChunkName: "shared-AccountCorrectionsStatusPage" */ './AccountCorrectionsStatusPage'
            ),
          chunkName: 'shared-AccountCorrectionsStatusPage',
          moduleId: require.resolveWeak('./AccountCorrectionsStatusPage'),
        }}
      />
    );
  }
}
