import Immutable from 'immutable';

import type { Nullable } from '@/utils/types';

export type StructuredAuthorNameFromJS = {
  firstName: string;
  middleNames: Nullable<string[]>;
  lastName: string;
};

type Props = {
  firstName: string;
  middleNames: Immutable.List<string>;
  lastName: string;
};

export const defaultProps: Props = {
  firstName: '',
  middleNames: Immutable.List(),
  lastName: '',
};

export const StructuredAuthorNameRecordFactory = Immutable.Record<Props>(defaultProps);

export type StructuredAuthorNameRecord = Immutable.RecordOf<Props>;

export function getStructuredAuthorNameFromJS(
  args: StructuredAuthorNameFromJS
): StructuredAuthorNameRecord {
  const { middleNames } = args;
  return StructuredAuthorNameRecordFactory({
    ...args,
    middleNames: middleNames ? Immutable.List(middleNames) : Immutable.List(),
  });
}
