import CLTextInputBase, { CLTextInputBaseProps } from './CLTextInputBase';

import { ReactNodeish } from '@/utils/types';

import React from 'react';

type CLTextInputProps = CLTextInputBaseProps;

export default function CLTextInput(props: CLTextInputProps): ReactNodeish {
  return <CLTextInputBase {...props} />;
}
