import { getString } from '@/content/i18n';
import AppContext from '@/AppContext';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

import type { WillRouteToResult } from '@/router/Route';

export default class AlertsListsRoute extends React.Component<{}> {
  static getPageTitle = () => getString(_ => _.account.page.alerts.pageTitle);

  static willRouteTo({ alertsStore, api }: AppContext): Promise<WillRouteToResult> {
    return alertsStore.isLoading() ? api.fetchAlerts() : Promise.resolve();
  }

  render() {
    return (
      <AsyncLoadedPage
        footer={false}
        load={{
          importer: () =>
            import(/* webpackChunkName: "desktop-AlertsListsPage" */ './AlertsListsPage'),
          chunkName: 'desktop-AlertsListsPage',
          moduleId: require.resolveWeak('./AlertsListsPage'),
        }}
      />
    );
  }
}
