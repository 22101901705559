import React from 'react';

export default function IconSemanticReaderHeader(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 723.02 149.67" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M240.26,62.53a2.73,2.73,0,0,1-.55.69,1.18,1.18,0,0,1-.84.28,2.21,2.21,0,0,1-1.11-.42,9.73,9.73,0,0,0-1.67-1,9.29,9.29,0,0,0-2.37-1,10.78,10.78,0,0,0-3.2-.41,10.45,10.45,0,0,0-3.06.41,5.8,5.8,0,0,0-2.23,1.26,4.89,4.89,0,0,0-1.39,2,6.43,6.43,0,0,0-.42,2.36,4,4,0,0,0,.84,2.65,7.51,7.51,0,0,0,2.22,1.81,10.09,10.09,0,0,0,3.07,1.25c1.11.42,2.36.7,3.48,1.11a26.32,26.32,0,0,1,3.48,1.4,16.19,16.19,0,0,1,3.06,2,11.77,11.77,0,0,1,2.23,2.92,9.4,9.4,0,0,1,.83,4.18,12,12,0,0,1-1,5A11.36,11.36,0,0,1,239,93a13.63,13.63,0,0,1-4.31,2.78,14.83,14.83,0,0,1-5.71,1,17.82,17.82,0,0,1-3.76-.41,14.45,14.45,0,0,1-3.48-1.12,13,13,0,0,1-3.06-1.67,12.65,12.65,0,0,1-2.51-2.23l1.67-2.92c.14-.28.42-.42.56-.56a1.93,1.93,0,0,1,.83-.27,2.24,2.24,0,0,1,1.4.55c.55.42,1.11.84,1.94,1.39a10.51,10.51,0,0,0,2.79,1.4,11.16,11.16,0,0,0,3.9.55,8.59,8.59,0,0,0,3.34-.55,7.25,7.25,0,0,0,2.5-1.4,6.32,6.32,0,0,0,1.54-2.22,6.59,6.59,0,0,0,.55-2.93,5.2,5.2,0,0,0-.83-2.92,7.84,7.84,0,0,0-2.23-1.95,10.14,10.14,0,0,0-3.06-1.25c-1.12-.42-2.37-.7-3.48-1.12a24,24,0,0,1-3.48-1.39,11.24,11.24,0,0,1-3.07-2,10.14,10.14,0,0,1-2.22-3.06,11.84,11.84,0,0,1,0-8.77,10.52,10.52,0,0,1,2.5-3.62,14.57,14.57,0,0,1,4-2.51,13.14,13.14,0,0,1,5.43-1A18.63,18.63,0,0,1,237.06,56a16.64,16.64,0,0,1,5,3.07Z"
        fill="#11223D"
      />
      <path
        d="M274.09,91.34v4.88H249.17V55.84h24.92v4.88H255v12.8h15.31v4.74H255V91.34Z"
        fill="#11223D"
      />
      <path
        d="M322.12,55.84V96.08H317V66.15a6.28,6.28,0,0,1,.14-1.54L303.75,89.26a2.27,2.27,0,0,1-2.23,1.39h-.84a2.08,2.08,0,0,1-2.08-1.39L284.81,64.61a22.58,22.58,0,0,1,.14,2.93V96.22h-5.29V55.84h4.46a3.08,3.08,0,0,1,1.25.14,2.38,2.38,0,0,1,.84.84l13.5,24.08a5.23,5.23,0,0,1,.7,1.53c.27.56.41,1.12.69,1.67a23.75,23.75,0,0,1,1.39-3.2l13.23-24.08a2.38,2.38,0,0,1,.84-.84,3,3,0,0,1,1.25-.14Z"
        fill="#11223D"
      />
      <path
        d="M364.58,96.22H360a1.67,1.67,0,0,1-1.25-.42,2.51,2.51,0,0,1-.7-1l-3.62-9.32H336.6L333,94.82a2.6,2.6,0,0,1-.69,1,2.26,2.26,0,0,1-1.26.42h-4.59l16-40.24h6Zm-11.83-15-6-15.59a38.65,38.65,0,0,1-1.25-4,10.4,10.4,0,0,1-.7,2.23c-.27.69-.41,1.25-.69,1.81l-6,15.59Z"
        fill="#11223D"
      />
      <path
        d="M401.89,55.84V96.08h-3.06a2.59,2.59,0,0,1-1.11-.28,4.47,4.47,0,0,1-1-.84L374.05,65.59a5.67,5.67,0,0,0,.14,1.53v29H368.9V55.84H372c.56,0,1,0,1.12.14a2.45,2.45,0,0,1,.83.84l22.83,29.51c0-.55-.14-1.11-.14-1.53v-29Z"
        fill="#11223D"
      />
      <path fill="#11223D" d="M438.23,60.86H425.42V96.08h-6V60.86H406.63v-5h31.6Z" />
      <path fill="#11223D" d="M449,96.22h-6V55.84h6Z" />
      <path
        d="M486.4,87.59a1.16,1.16,0,0,1,.83.41l2.37,2.65a15.64,15.64,0,0,1-6,4.45,19.6,19.6,0,0,1-8.49,1.53,20.53,20.53,0,0,1-7.93-1.53,18.34,18.34,0,0,1-6.13-4.17,20.54,20.54,0,0,1-3.9-6.55A22.94,22.94,0,0,1,455.77,76a22.94,22.94,0,0,1,1.39-8.35,20.85,20.85,0,0,1,4-6.55A16.44,16.44,0,0,1,467.47,57a21.25,21.25,0,0,1,8.07-1.53,18.74,18.74,0,0,1,7.66,1.39A21.06,21.06,0,0,1,489,60.58l-2,2.78a1.92,1.92,0,0,1-.55.56c-.14.14-.42.14-.84.14a1.69,1.69,0,0,1-1.25-.56,15,15,0,0,0-1.95-1.25A20.12,20.12,0,0,0,479.58,61a12.74,12.74,0,0,0-4.32-.55,13.59,13.59,0,0,0-5.43,1.11,11.71,11.71,0,0,0-4.31,3.06,12.84,12.84,0,0,0-2.79,4.88,19.15,19.15,0,0,0-1,6.54,19.15,19.15,0,0,0,1,6.54,14.29,14.29,0,0,0,2.93,4.88A11.71,11.71,0,0,0,470,90.51a13.83,13.83,0,0,0,5.29,1,18.92,18.92,0,0,0,3.06-.28,10.32,10.32,0,0,0,2.51-.69,11.08,11.08,0,0,0,2.23-1.12,19.43,19.43,0,0,0,2.09-1.53c.13-.14.27-.27.41-.27Z"
        fill="#11223D"
      />
      <path
        d="M157.84,11.68a10.84,10.84,0,0,1,2.1.21,11,11,0,0,1,8.88,10.63v.07a11,11,0,0,1-11,10.92H38a34.24,34.24,0,0,0,1.82-10.84v-.22a34.18,34.18,0,0,0-1.81-10.77H157.84M18.08,0c-.31,0-1.67.05-2,.88a1.59,1.59,0,0,0,.6,2.06A22.67,22.67,0,0,1,28.18,22.52v.15A22.68,22.68,0,0,1,16.67,42.26a1.58,1.58,0,0,0-.6,2.05c.34.83,1.7.88,2,.88H157.84a22.66,22.66,0,0,0,22.67-22.52v-.15A22.66,22.66,0,0,0,157.84,0H18.08Z"
        fill="#f4d35E"
      />
      <path
        d="M157.84,116.16a10.84,10.84,0,0,1,2.1.21A11,11,0,0,1,168.82,127v.07a11,11,0,0,1-11,10.92H38a34.29,34.29,0,0,0,1.82-10.84v-.23a34.12,34.12,0,0,0-1.81-10.76H157.84M18.09,104.48c-.32,0-1.68,0-2,.88a1.57,1.57,0,0,0,.6,2A22.69,22.69,0,0,1,28.18,127v.15a22.65,22.65,0,0,1-11.51,19.58,1.59,1.59,0,0,0-.6,2.06c.34.83,1.7.88,2,.88H157.84a21.85,21.85,0,0,0,4.3-.42,22.66,22.66,0,0,0,18.37-22.1V127a22.66,22.66,0,0,0-22.67-22.52H18.09Z"
        fill="#0F3875"
      />
      <path
        d="M142.47,63.81a34.58,34.58,0,0,0-1.83,10.84v.22a34.47,34.47,0,0,0,1.82,10.77H22.67a10.76,10.76,0,0,1-2.1-.21A11,11,0,0,1,11.68,74.8v-.07a11,11,0,0,1,11-10.92h119.8m19.95-11.68H22.67A22.66,22.66,0,0,0,0,74.65v.15A22.66,22.66,0,0,0,22.67,97.32H162.42c.32,0,1.68,0,2-.88a1.59,1.59,0,0,0-.61-2.06,22.62,22.62,0,0,1-11.5-19.58v-.15a22.66,22.66,0,0,1,11.5-19.59,1.58,1.58,0,0,0,.61-2.05c-.34-.83-1.7-.88-2-.88Z"
        fill="#1857B6"
      />
      <path
        d="M542.76,96.29h-7.51a3.26,3.26,0,0,1-3-1.6l-7.65-12.34A2.7,2.7,0,0,0,521.83,81H519V96.29h-8.32V56.06h12.65a26.11,26.11,0,0,1,7.21.87,14,14,0,0,1,4.9,2.43,9.28,9.28,0,0,1,2.8,3.7,12.15,12.15,0,0,1,.89,4.73,12.46,12.46,0,0,1-.57,3.79,11.19,11.19,0,0,1-1.65,3.25,12,12,0,0,1-2.66,2.61,13.54,13.54,0,0,1-3.6,1.83,8.14,8.14,0,0,1,1.49,1.07,7,7,0,0,1,1.25,1.48ZM523.27,75.11a10.66,10.66,0,0,0,3.49-.51,6.47,6.47,0,0,0,2.42-1.42,5.34,5.34,0,0,0,1.38-2.12A7.62,7.62,0,0,0,531,68.4a5.49,5.49,0,0,0-1.9-4.44q-1.9-1.58-5.78-1.58H519V75.11Z"
        fill="#0F3875"
      />
      <path
        d="M555.07,62.58V72.92H568.5v6.29H555.07V89.78h17.25v6.51H546.7V56.06h25.62v6.52Z"
        fill="#0F3875"
      />
      <path
        d="M614.86,96.29H608.4a2.88,2.88,0,0,1-1.77-.51,3.11,3.11,0,0,1-1-1.31l-2.71-7.69h-16l-2.72,7.69a3.16,3.16,0,0,1-1,1.26,2.64,2.64,0,0,1-1.75.56h-6.51l15.72-40.23h8.51ZM600.88,81l-4.35-12.31c-.26-.65-.53-1.41-.82-2.28s-.56-1.81-.82-2.83c-.26,1-.53,2-.82,2.87s-.56,1.64-.81,2.29L588.93,81Z"
        fill="#0F3875"
      />
      <path
        d="M654.65,76.16a21.65,21.65,0,0,1-1.49,8.12A18.58,18.58,0,0,1,649,90.64a19.28,19.28,0,0,1-6.51,4.16A22.75,22.75,0,0,1,634,96.29H618.57V56.06H634a22.75,22.75,0,0,1,8.43,1.5A19.15,19.15,0,0,1,649,61.73a18.71,18.71,0,0,1,4.18,6.37A21.33,21.33,0,0,1,654.65,76.16Zm-8.54,0a17.94,17.94,0,0,0-.84-5.68,12,12,0,0,0-2.38-4.27,10.52,10.52,0,0,0-3.79-2.69,12.82,12.82,0,0,0-5.06-.94H627v27.2H634a13,13,0,0,0,5.06-.93,10.29,10.29,0,0,0,3.79-2.69,12.09,12.09,0,0,0,2.38-4.28A18.11,18.11,0,0,0,646.11,76.16Z"
        fill="#0F3875"
      />
      <path
        d="M667.48,62.58V72.92H680.9v6.29H667.48V89.78h17.25v6.51H659.11V56.06h25.62v6.52Z"
        fill="#0F3875"
      />
      <path
        d="M723,96.29h-7.51a3.26,3.26,0,0,1-3-1.6l-7.65-12.34a3.51,3.51,0,0,0-1-1.06,3.42,3.42,0,0,0-1.68-.33h-2.83V96.29h-8.32V56.06h12.65a26,26,0,0,1,7.2.87,14,14,0,0,1,4.91,2.43,9.28,9.28,0,0,1,2.8,3.7,12.34,12.34,0,0,1,.89,4.73,12.46,12.46,0,0,1-.57,3.79,11.19,11.19,0,0,1-1.65,3.25,12,12,0,0,1-2.66,2.61,13.7,13.7,0,0,1-3.6,1.83,8.14,8.14,0,0,1,1.49,1.07,7.3,7.3,0,0,1,1.25,1.48ZM703.53,75.11A10.66,10.66,0,0,0,707,74.6a6.47,6.47,0,0,0,2.42-1.42,5.45,5.45,0,0,0,1.38-2.12,7.62,7.62,0,0,0,.45-2.66,5.49,5.49,0,0,0-1.9-4.44q-1.9-1.58-5.78-1.58h-4.33V75.11Z"
        fill="#0F3875"
      />
    </symbol>
  );
}
