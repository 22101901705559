import { getModerationStatusValue, ModerationStatusValue } from '@/constants/ModerationStatus';
import {
  getProfilePictureFromJS,
  ProfilePictureFromJS,
  ProfilePictureRecord,
} from '@/models/user/ProfilePicture';
import {
  ModerationStatusReasonByValue,
  ModerationStatusReasonValue,
  optModerationStatusReasonByValue,
} from '@/constants/ModerationStatusReason';

import idx from 'idx';
import Immutable from 'immutable';

import type { DEPRECATED__FlowOptional, Nullable, TODO } from '@/utils/types';

export type AuthorProfileFromJS = {
  id: number;
  ai2AuthorId: number;
  affiliations?: DEPRECATED__FlowOptional<string[]>;
  firstName?: DEPRECATED__FlowOptional<string>;
  middleNames?: DEPRECATED__FlowOptional<string[]>;
  lastName?: DEPRECATED__FlowOptional<string>;
  fullName?: DEPRECATED__FlowOptional<string>;
  homepageUrl?: DEPRECATED__FlowOptional<string>;
  orcidId?: DEPRECATED__FlowOptional<string>;
  moderationStatus: TODO<'wire enum thing with value'>;
  moderationStatusReason: TODO<'wire enum thing with value'>;
  moderator: DEPRECATED__FlowOptional<string>;
  email: DEPRECATED__FlowOptional<string>;
  claimedAtUtc: number;
  userComment: DEPRECATED__FlowOptional<string>;
  pronouns: TODO<'ask paul'>;
  profilePicture: Nullable<ProfilePictureFromJS>;
};

type Props = {
  id: number;
  ai2AuthorId: number;
  affiliations?: Nullable<string[]>;
  firstName?: Nullable<string>;
  middleNames?: Nullable<string[]>;
  lastName?: Nullable<string>;
  fullName?: Nullable<string>;
  homepageUrl?: Nullable<string>;
  orcidId?: Nullable<string>;
  moderationStatus: ModerationStatusValue;
  moderationStatusReason: Nullable<ModerationStatusReasonValue>;
  moderator: Nullable<string>;
  email: Nullable<string>;
  claimedAtUtc: number;
  userComment: Nullable<string>;
  pronouns: Nullable<string>;
  profilePicture: Nullable<ProfilePictureRecord>;
};

const defaultProps: Props = {
  id: -1,
  ai2AuthorId: -1,
  affiliations: [],
  firstName: null,
  middleNames: [],
  lastName: null,
  fullName: null,
  homepageUrl: null,
  orcidId: null,
  moderationStatus: 'Unsupported',
  moderationStatusReason: null,
  moderator: null,
  email: null,
  claimedAtUtc: -1,
  userComment: null,
  pronouns: null,
  profilePicture: null,
};

export const AuthorProfileRecordFactory = Immutable.Record<Props>(defaultProps);
export type AuthorProfileRecord = Immutable.RecordOf<Props>;

export function getAuthorProfileFromJS(params: AuthorProfileFromJS): AuthorProfileRecord {
  return AuthorProfileRecordFactory({
    ...params,
    profilePicture: params.profilePicture ? getProfilePictureFromJS(params.profilePicture) : null,
    moderationStatus: getModerationStatusValue(idx(params, _ => _.moderationStatus.value)),
    moderationStatusReason:
      ModerationStatusReasonByValue[
        optModerationStatusReasonByValue(idx(params, _ => _.moderationStatusReason.value)) || ''
      ] || null,
  });
}
