import { ReaderAnalyticsContextProvider } from './ReaderAnalyticsContext';
import ReaderHeaderStatic from './ReaderHeaderStatic';
import ReaderLoadingIndicator from './ReaderLoadingIndicator';

import { CiteSeeState, CiteSeeStateRecordFactory } from '@/models/reader-widgets/ReaderCiteSee';
import { createPaperDetailLoadedDispatch } from '@/actions/PaperDetailActionCreators';
import { DEPRECATED__FlowOptional } from '@/utils/types';
import { DublinCoreMeta } from '@/router/Route';
import { getPaperDetailDoi, getPaperDetailLinkedData } from '@/models/PaperDetail';
import { getString } from '@/content/i18n';
import { SkimmingState, SkimmingStateRecordFactory } from '@/models/reader-widgets/ReaderSkimming';
import { TermsState, TermsStateRecordFactory } from '@/models/reader-widgets/ReaderTerms';
import {
  updateCiteSeeState,
  updateReaderSkimmingState,
  updateTermsState,
} from '@/actions/ReaderWidgetActionCreators';
import { UserSettingKey, UserSettingRecord } from '@/models/user/UserSetting';
import AppContext from '@/AppContext';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import S2Redirect from '@/models/redirects/S2Redirect';
import schema, { SchemaData } from '@/utils/routing/schema';

import invariant from 'invariant';
import React from 'react';

type TODO__MATCH = any;

type Props = {
  match: TODO__MATCH;
};

export default class ReaderRoute extends React.Component<Props> {
  _startTimestamp: number;

  constructor(props, ...args: [any]) {
    super(props, ...args);

    this._startTimestamp = Date.now();
  }

  static getPageTitle({ paperStore }: AppContext) {
    const {
      title: { text: title },
    } = paperStore.getPaperDetail().paper;
    const paperTitle = `[PDF] ${title}`;
    return getString(_ => _.paperDetail.pageTitle, paperTitle);
  }

  static async willRouteTo(appContext, state) {
    const {
      api,
      authStore,
      paperStore,
      dispatcher,
      readerPdfStore,
      readerSkimmingStore,
      userSettingStore,
    } = appContext;

    // Pull params from the path
    const paperId = state.params?.paperId || null;
    invariant(typeof paperId === 'string', `Paper ID was not provided while routing to the Reader`);

    // Fetch paper details, if not loaded
    const paperDetailPromise = (async () => {
      const loadedPaperId = paperStore.getPaperDetail()?.paper?.id || null;
      if (loadedPaperId && loadedPaperId === paperId) {
        return null;
      }
      return await api.fetchPaperDetail({
        paperId,
        requireSlug: false,
      });
    })();

    // Fetch PDF data, if not loaded
    const pdfDataPromise = (async () => {
      const loadedPaperId = readerPdfStore.getPaperId();
      const maybePdfData = readerPdfStore.getPdfData();
      if (loadedPaperId && loadedPaperId === paperId) {
        return Promise.resolve({ resultData: maybePdfData?.toJS() });
      }
      return await api.fetchPdfData(paperId);
    })();

    // fetch cited by library for CiteSee
    (async () => {
      const isUserLoggedIn = authStore.hasAuthenticatedUser();

      isUserLoggedIn
        ? await api.fetchSharedCitationsWithLibraryForPaperIds([paperId])
        : await Promise.resolve();
    })();

    const redirectToPdp = () => {
      return new S2Redirect({
        query: state.query,
        routeName: 'PAPER_DETAIL_BY_ID',
        params: { paperId: paperId },
        replace: true,
      });
    };

    // Wait for APIs to complete
    const [paperDetailResponse, pdfDataResponse] = await Promise.all([
      paperDetailPromise,
      pdfDataPromise,
    ]);

    // Redirect to canonical, if paperId is not canonical
    if (paperDetailResponse && paperDetailResponse.resultData.responseType === 'CANONICAL') {
      const paperCanonicalId = paperDetailResponse.resultData?.canonicalId || null;
      if (paperCanonicalId) {
        return new S2Redirect({
          query: state.query,
          routeName: 'READER',
          params: { paperId: paperCanonicalId },
          replace: true,
        });
      }
    }

    // If no pdfSha we either don't have annotations or it is not an OA paper so redirect to PDP
    if (!pdfDataResponse?.resultData?.pdfSha) {
      return redirectToPdp();
    }

    // Create and dispatch a message saying the data is loaded
    const paperDetailLoadedDispatch = createPaperDetailLoadedDispatch(paperDetailResponse);

    if (paperDetailLoadedDispatch) {
      dispatcher.dispatch(paperDetailLoadedDispatch);
    }

    const hasSkimmingSnippets = readerSkimmingStore.hasSkimmingSnippets();
    if (hasSkimmingSnippets) {
      // initialize widget state for skimming from sticky settings
      const skimmingSettings: UserSettingRecord = userSettingStore.getUserSetting(
        UserSettingKey.readerSkimming
      );

      if (skimmingSettings?.settingsValue) {
        dispatcher.dispatch(
          updateReaderSkimmingState(
            SkimmingStateRecordFactory(skimmingSettings.settingsValue as SkimmingState)
          )
        );
      }
    }

    // initialize widget state for citesee from sticky settings
    const citeSeeSettings: UserSettingRecord = userSettingStore.getUserSetting(
      UserSettingKey.readerCiteSee
    );
    if (citeSeeSettings?.settingsValue) {
      const { isCitationsEnabled, isSavedToLibraryEnabled, isCitedByLibraryEnabled } =
        citeSeeSettings.settingsValue as CiteSeeState;
      dispatcher.dispatch(
        updateCiteSeeState(
          CiteSeeStateRecordFactory({
            isCitationsEnabled,
            isCitedByLibraryEnabled,
            isSavedToLibraryEnabled,
          })
        )
      );
    }

    // initialize widget state for term understanding from sticky settings
    const termSettings: UserSettingRecord = userSettingStore.getUserSetting(
      UserSettingKey.readerTerms
    );
    if (termSettings?.settingsValue) {
      const { showAcronyms, showTerms } = termSettings.settingsValue as TermsState;
      dispatcher.dispatch(
        updateTermsState(
          TermsStateRecordFactory({
            showAcronyms,
            showTerms,
          })
        )
      );
    }

    // Return all the payloads to be replayed in the browser
    return [paperDetailResponse, pdfDataResponse, paperDetailLoadedDispatch];
  }

  static getDublinCoreMeta({ paperStore }: AppContext): DublinCoreMeta {
    const paperDetail = paperStore.getPaperDetail();
    const paperDoi = getPaperDetailDoi(paperDetail);

    if (paperDoi) {
      return {
        dublinCoreId: 'doi:' + paperDoi,
        showDublinCoreRelation: false,
      };
    }

    if (paperDetail.paper.corpusId) {
      return {
        dublinCoreId: 'reader/' + paperDetail.paper.corpusId,
        showDublinCoreRelation: true,
      };
    }

    return {
      dublinCoreId: null,
      showDublinCoreRelation: false,
    };
  }

  static getAlternativePdfUrl({ paperStore }: AppContext): DEPRECATED__FlowOptional<string> {
    const paperDetail = paperStore.getPaperDetail();

    const {
      paper: { primaryPaperLink },
      entitlement,
    } = paperDetail;

    const paperUrl = entitlement ? entitlement.url : primaryPaperLink?.url;

    return paperUrl;
  }

  static getSchemaData(router, appContext): SchemaData {
    const paperDetail = appContext.paperStore.getPaperDetail();
    const breadcrumbData = [
      { path: '/reader', name: 'Reader' },
      { path: router.url, name: paperDetail.paper.title.text },
    ];

    return {
      breadCrumbs: schema.breadcrumb(appContext.envInfo.hostname, breadcrumbData),
      linkedData: getPaperDetailLinkedData(paperDetail, 'READER'),
    };
  }

  render() {
    const paperId = this.props.match?.params?.paperId || null;
    return (
      <ReaderAnalyticsContextProvider loadStartTimestamp={this._startTimestamp}>
        <AsyncLoadedPage
          header={<ReaderHeaderStatic paperId={paperId} />}
          footer={false}
          loadingMessage={null}
          load={{
            importer: () => import(/* webpackChunkName: "shared-ReaderPage" */ './ReaderPage'),
            chunkName: 'shared-ReaderPage',
            moduleId: require.resolveWeak('./ReaderPage'),
          }}>
          <div className="reader-loading-page">
            <div className="reader-loading-page__indicator">
              <ReaderLoadingIndicator />
            </div>
          </div>
        </AsyncLoadedPage>
      </ReaderAnalyticsContextProvider>
    );
  }
}
