import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import AuthorQueryStore from '@/stores/AuthorQueryStore';

import React from 'react';

import type { RouteConfig } from 'react-router-config';
import type { WillRouteToResult } from '@/router/Route';
import type AppContext from '@/AppContext';

type Props = {
  route: RouteConfig;
};

export default class AuthorPapersRoute extends React.Component<Props> {
  static async willRouteTo(appContext: AppContext, routerState): Promise<WillRouteToResult> {
    const { authorQueryStore } = appContext;
    const { authorId } = routerState.params;

    const promises: Promise<WillRouteToResult>[] = [];

    const shouldFetchPapers =
      authorQueryStore.isLoading() ||
      !authorQueryStore.hasQuery() ||
      authorQueryStore.getAuthorId() !== authorId ||
      authorQueryStore.isNewQuery(routerState.query);
    if (shouldFetchPapers) {
      promises.push(AuthorQueryStore.executeAHPQuery(appContext, routerState));
    }

    return Promise.all(promises);
  }

  render(): React.ReactNode {
    return (
      <AsyncLoadedPage
        showLoadingPage={false}
        load={{
          importer: () =>
            import(/* webpackChunkName: "shared-AuthorPapersPage" */ './AuthorPapersPage'),
          chunkName: 'shared-AuthorPapersPage',
          moduleId: require.resolveWeak('./AuthorPapersPage'),
        }}
      />
    );
  }
}
