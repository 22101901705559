import React from 'react';

export default function IconOpaqueEmptyFolder(props: { id?: string }) {
  return (
    <symbol id={props.id} fill="#FFFFFF" viewBox="0 0 239 222">
      <style type="text/css">
        {`
          .empty{height:220px; display:block; margin:24px auto;}
          .empty__items{stroke:#D9DADB;stroke-width:2;stroke-dasharray:6,4;}
          .empty__backdrop{opacity: 0.6}
        `}
      </style>
      <circle className="empty__backdrop" cx="128" cy="111" r="110" />
      <path
        className="empty__items"
        d="M98,22.4c-1.5-1.4-3.6-2.4-5.8-2.4H90v43.7h44v-2.2c0-2.2-0.7-4.2-2.4-5.8L98,22.4z M78.9,66.5V20H10.2 C5.6,20,2,23.6,2,28.1v158.7c0,4.5,3.6,8.3,8.2,8.1h115.6c4.5,0,8.2-3.6,8.2-8.1V74.8H87.3C82.7,74.8,79.1,71,78.9,66.5z M211,25.1 c0-0.9-0.3-1.8-1-2.5L195.5,8c-0.6-0.6-1.6-1-2.5-1H192v19h19V25.1z M157.5,83h49.9c2,0,3.5-1.6,3.5-3.5V30.8h-20.2 c-2,0-3.5-1.6-3.6-3.6V7h-29.7c-2,0-3.5,1.6-3.5,3.5v68.9C154,81.4,155.6,83.1,157.5,83z M200,130.9V103h-41.1 c-2.7,0-4.9,2.2-4.9,4.9v95.2c0,2.7,2.2,5,4.9,4.9h69.2c2.7,0,4.9-2.2,4.9-4.9v-67.2h-28C202.3,135.9,200.1,133.6,200,130.9z M231.6,124.5l-20.1-20.1c-0.9-0.9-2.2-1.4-3.5-1.4h-1.3v26.2H233v-1.3C233,126.6,232.6,125.5,231.6,124.5z"
      />
    </symbol>
  );
}
