import S2Redirect from '@/models/redirects/S2Redirect';

import React from 'react';

export default class EntityRedirect extends React.PureComponent {
  static async willRouteTo({ api, entityStore }, routerState) {
    const { entityId } = routerState.params;
    const currentEntity = entityStore.getEntity();
    const { query } = routerState;

    // There's no entity or the entity ids are different
    return !currentEntity || currentEntity.id !== entityId
      ? api
          .fetchEntityById(parseInt(entityId, 10))
          .then(response => getEntityPageRedirect(response.resultData, query))
      : getEntityPageRedirect(currentEntity, query);
  }

  render() {
    return null;
  }
}

function getEntityPageRedirect({ id, slug }, query) {
  return new S2Redirect({
    query,
    routeName: 'ENTITY',
    params: { slug, entityId: id },
    replace: true,
  });
}
