import CLFieldErrorBase, { ARROW_SIDE as _ARROW_SIDE } from './CLFieldErrorBase';

import React from 'react';

export const ARROW_SIDE = _ARROW_SIDE;

export default function CLFieldError(props) {
  return <CLFieldErrorBase {...props} />;
}

export const exampleConfig = {
  getComponent: async () => CLFieldError,
  fields: [
    {
      name: 'isInline',
      desc: 'Position the component based on the box, not the arrow',
      value: {
        type: 'boolean',
        default: false,
      },
    },
    {
      name: 'arrow',
      desc: 'Position of the arrow around the box',
      value: {
        type: 'select',
        default: ARROW_SIDE.TOP,
        options: [ARROW_SIDE.TOP, ARROW_SIDE.LEFT, ARROW_SIDE.RIGHT, ARROW_SIDE.BOTTOM],
      },
    },
    {
      name: 'widthPx',
      desc: 'Width (in px) of the tooltip',
      value: {
        type: 'number',
        default: null,
      },
    },
    {
      name: 'children',
      desc: 'Content to be displayed within the component',
      value: {
        type: 'jsx',
        default: '',
        // eslint-disable-next-line react/no-danger
        transform: html => <div dangerouslySetInnerHTML={{ __html: html }} />,
      },
    },
  ],

  examples: [
    {
      title: 'Top',
      desc: 'Tooltip with arrow on top',
      props: {
        isInline: false,
        arrow: ARROW_SIDE.TOP,
        widthPx: 200,
        children: `Top - This field is required, dummy!`,
      },
      render: comp => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}>
          <div style={{ position: 'relative' }}>{comp}</div>
        </div>
      ),
    },
    {
      title: 'Left',
      desc: 'Tooltip with arrow on left',
      props: {
        isInline: false,
        arrow: ARROW_SIDE.LEFT,
        widthPx: 200,
        children: `Left - This field is required, dummy!`,
      },
      render: comp => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}>
          <div style={{ position: 'relative' }}>{comp}</div>
        </div>
      ),
    },
    {
      title: 'Right',
      desc: 'Tooltip with arrow on right',
      props: {
        isInline: false,
        arrow: ARROW_SIDE.RIGHT,
        widthPx: 200,
        children: `Right - This field is required, dummy!`,
      },
      render: comp => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}>
          <div style={{ position: 'relative' }}>{comp}</div>
        </div>
      ),
    },
    {
      title: 'Bottom',
      desc: 'Tooltip with arrow on bottom',
      props: {
        isInline: false,
        arrow: ARROW_SIDE.BOTTOM,
        widthPx: 200,
        children: `Bottom - This field is required, dummy!`,
      },
      render: comp => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}>
          <div style={{ position: 'relative' }}>{comp}</div>
        </div>
      ),
    },
    {
      title: 'Inline',
      desc: 'Tooltip with arrow pointing at a something',
      props: {
        isInline: true,
        arrow: ARROW_SIDE.LEFT,
        widthPx: 150,
        children: `Don't click that link on the right...it's a trap!`,
      },
      render: comp => (
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <input type="text" className="legacy__input" />
          <div style={{ position: 'absolute', top: '6px', right: '6px', height: 0, width: 0 }}>
            {comp}
          </div>
        </div>
      ),
    },
  ],

  events: {},
};
