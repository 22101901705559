import * as format from '@/format';
import { addHeapProps } from '@/components/util/HeapTracking';
import { getString } from '@/content/i18n';
import { pluralize } from '@/util';
import { useAppContext } from '@/AppContext';
import Icon from '@/components/shared/icon/Icon';

import classnames from 'classnames';
import React from 'react';

export default function CLPaperTotalCitationsStat({
  citationStats,
  className,
  heapProps,
  testId,
  ...props
}) {
  const { envInfo } = useAppContext();
  const isMobile = envInfo.isMobile;

  if (!canRender(citationStats)) {
    return null;
  }

  const ariaLabel = pluralize(
    citationStats.numCitations,
    getString(_ => _.general.citationSingle),
    getString(_ => _.general.citationPlural),
    false
  );

  return (
    <div
      className={classnames('cl-paper-stat', 'cl-paper-stats__total-citations', className)}
      data-test-id={testId}
      {...addHeapProps(heapProps)}
      aria-label={ariaLabel}
      {...props}>
      <Icon icon="cited-by" width="12" height="12" className="cl-paper-stats__icon" />
      <span className="cl-paper-stats__v2-citations">
        {format.citationsValue(citationStats.numCitations)}
      </span>
      {isMobile && (
        <span className="cl-paper-stats__v2-citations-text">
          {pluralize(
            citationStats.numCitations,
            getString(_ => _.general.citationSingle),
            getString(_ => _.general.citationPlural),
            true
          )}
        </span>
      )}
    </div>
  );
}

CLPaperTotalCitationsStat.defaultProps = {
  testId: 'total-citations-stat',
};

export function canRender(citationStats) {
  return !!citationStats.numCitations;
}
