import { getString } from '@/content/i18n';
import { heapReaderToPdpLink } from '@/analytics/attributes/reader';
import { ReactNodeish } from '@/utils/types';
import Arrow from '@/components/shared/icon/Arrow';
import CLTooltip, { PLACEMENT } from '@/components/library/tooltip/CLTooltip';
import Icon from '@/components/shared/icon/Icon';
import Link from '@/router/Link';

import React from 'react';

type Props = {
  paperId?: string;
};

export default function ReaderPDPButton({ paperId }: Props): ReactNodeish {
  return (
    <div className="reader-pdp-button">
      <CLTooltip
        placement={PLACEMENT.RIGHT_BOTTOM}
        tooltipContent={getString(_ => _.reader.tooltips.backButton)}
        id="reader-pdp-button-voiceover"
        overlayClassName="reader__tooltip">
        <Link
          className="reader-pdp-button__arrow"
          to={paperId ? 'PAPER_DETAIL_BY_ID' : 'HOME'}
          params={{ paperId: paperId }}
          {...heapReaderToPdpLink()}
          aria-describedby="reader-pdp-button-voiceover"
          data-test-id="reader-back-button">
          <Arrow direction={Arrow.Direction.LEFT} />
        </Link>
      </CLTooltip>
      <Icon
        className="reader-pdp-button__icon"
        icon="semantic-reader-header"
        width="175"
        height="36"
      />
    </div>
  );
}
