import Route, { RouteType } from './Route';
import RouteRedirect, { mkRedirectTo } from './RouteRedirect';
import Routes from './Routes';

import { VenueRoute } from '@/components/shared/venue/VenueRoute';
import AccountAddPapersRoute from '@/components/shared/account/AccountAddPapersRoute';
import AccountAuthorClaimPendingRoute from '@/components/shared/account/AccountAuthorClaimPendingRoute';
import AccountAuthorContactRoute from '@/components/shared/account/AccountAuthorContactRoute';
import AccountAuthorUnclaimSuccessRoute from '@/components/shared/account/AccountAuthorUnclaimSuccessRoute';
import AccountContactRoute from '@/components/shared/account/AccountContactRoute';
import AccountCorrectionsStatusRoute from '@/components/shared/account/AccountCorrectionsStatusRoute';
import AccountManageRoute from '@/components/shared/account/AccountManageRoute';
import AccountRemovePapersRoute from '@/components/shared/account/AccountRemovePapersRoute';
import AccountRoute from '@/components/shared/account/AccountRoute';
import AddUserEnrollmentRedirect from '@/components/shared/account/AddUserEnrollmentRedirect';
import AdminEnrollmentsRoute from '@/components/shared/admin/AdminEnrollmentsRoute';
import AdminExperimentAuditRoute from '@/components/shared/admin/AdminExperimentAuditRoute';
import AdminExperimentsRoute from '@/components/shared/admin/AdminExperimentsRoute';
import AdminFeatureFlagsRoute from '@/components/shared/admin/AdminFeatureFlagsRoute';
import AdminLoginAsRoute from '@/components/shared/admin/AdminLoginAsRoute';
import AdminManageRolesRoute from '@/components/shared/admin/AdminManageRolesRoute';
import AdminManageUsersRoute from '@/components/shared/admin/AdminManageUsersRoute';
import AdminRoute from '@/components/shared/admin/AdminRoute';
import AlertsListsRoute from '@/components/shared/alerts/AlertsListsRoute';
import App from '@/App';
import AuthorCitingAuthorsRoute from '@/components/shared/author/AuthorCitingAuthorsRoute';
import AuthorClaimCompleteRoute from '@/components/shared/author/AuthorClaimCompleteRoute';
import AuthorClaimedRedirect from '@/components/shared/author/AuthorClaimedRedirect';
import AuthorClaimModerationRoute from '@/components/shared/moderation/AuthorClaimModerationRoute';
import AuthorClaimRoute from '@/components/shared/author/AuthorClaimRoute';
import AuthorCoAuthorsRoute from '@/components/shared/author/AuthorCoAuthorsRoute';
import AuthorPapersRoute from '@/components/shared/author/AuthorPapersRoute';
import AuthorReferencedAuthorsRoute from '@/components/shared/author/AuthorReferencedAuthorsRoute';
import AuthorRoute from '@/components/shared/author/AuthorRoute';
import AuthorWithoutSlugRedirect from '@/components/shared/author/AuthorWithoutSlugRedirect';
import BlankRoute from '@/components/shared/blank/BlankRoute';
import CrawlerRoute from '@/components/shared/crawler/CrawlerRoute';
import DebugAuthorListRoute from '@/components/shared/debug/DebugAuthorListRoute';
import DebugComponentLibraryPreviewRoute from '@/components/shared/debug/DebugComponentLibraryPreviewRoute';
import DebugComponentLibraryRoute from '@/components/shared/debug/DebugComponentLibraryRoute';
import DebugPaperListRoute from '@/components/shared/debug/DebugPaperListRoute';
import DebugPdfRoute from '@/components/shared/debug/DebugPdfRoute';
import DebugSpritesRoute from '@/components/shared/debug/DebugSpritesRoute';
import EntityLandingRoute from '@/components/shared/entity/EntityLandingRoute';
import EntityRedirect from '@/components/shared/entity/EntityRedirect';
import EntityRoute from '@/components/shared/entity/EntityRoute';
import HomeRoute from '@/components/shared/home/HomeRoute';
import LibraryAllEntriesRoute from '@/components/shared/library/LibraryAllEntriesRoute';
import LibraryFolderRoute from '@/components/shared/library/LibraryFolderRoute';
import LibraryRootRoute from '@/components/shared/library/LibraryRootRoute';
import LibraryUnsortedEntriesRoute from '@/components/shared/library/LibraryUnsortedEntriesRoute';
import ModerationRoute from '@/components/shared/moderation/ModerationRoute';
import ModeratorAuthorCorrectionsInputRoute from '@/components/shared/moderation/ModeratorAuthorCorrectionsInputRoute';
import ModeratorAuthorCorrectionsToolRoute from '@/components/shared/moderation/ModeratorAuthorCorrectionsToolRoute';
import NotFoundRoute from '@/components/shared/404/NotFoundRoute';
import PaperDetailByCorpusIdRedirect from '@/components/shared/paper-detail/PaperDetailByCorpusIdRedirect';
import PaperDetailFigureRoute from '@/components/shared/figure/PaperDetailFigureRoute';
import PaperDetailRedirect from '@/components/shared/paper-detail/PaperDetailRedirect';
import PaperDetailRoute from '@/components/shared/paper-detail/PaperDetailRoute';
import PaperLandingRoute from '@/components/shared/paper/PaperLandingRoute';
import ReaderLegacyRedirect from '@/components/shared/paper-detail/reader/ReaderLegacyRedirect';
import ReaderRoute from '@/components/shared/paper-detail/reader/ReaderRoute';
import RecommendationsRoute from '@/components/shared/recommendations/RecommendationsRoute';
import RemoveUserEnrollmentRedirect from '@/components/shared/account/RemoveUserEnrollmentRedirect';
import ResearchHomepageRoute from '@/components/shared/research/ResearchHomepageRoute';
import SearchResultsRoute from '@/components/shared/search/SearchResultsRoute';
import SharedLibraryFolderRoute from '@/components/shared/library/SharedLibraryFolderRoute';
import SignInRoute from '@/components/shared/auth/SignInRoute';
import SignOutRedirect from '@/components/shared/auth/SignOutRedirect';
import UnauthorizedFolderRoute from '@/components/shared/library/UnauthorizedFolderRoute';
import UnsubscribeFromAlertRoute from '@/components/shared/alerts/UnsubscribeFromAlertRoute';
import VerifyEmailRoute from '@/components/shared/user/VerifyEmailRoute';

const routes: RouteType[] = [
  Route({
    component: App,
    routes: [
      Route({ path: Routes.HOME, exact: true, component: HomeRoute }),

      Route({ path: Routes.ENTITY_LANDING, exact: true, component: EntityLandingRoute }),
      Route({ path: Routes.ENTITY_BY_ID, exact: true, component: EntityRedirect }),
      Route({ path: Routes.ENTITY, component: EntityRoute }),

      Route({ path: Routes.SEARCH, component: SearchResultsRoute }),

      Route({ path: Routes.PAPER_LANDING, exact: true, component: PaperLandingRoute }),
      Route({
        path: Routes.PAPER_DETAIL_BY_ID,
        exact: true,
        component: PaperDetailRedirect,
      }),
      Route({
        path: Routes.PAPER_DETAIL_BY_CORPUS_ID,
        exact: true,
        component: PaperDetailByCorpusIdRedirect,
      }),
      Route({
        path: Routes.PAPER_DETAIL_PDF_REDIRECT,
        exact: true,
        component: PaperDetailRedirect,
      }),
      Route({
        path: Routes.READER,
        exact: true,
        component: ReaderRoute,
      }),
      Route({
        path: Routes.READER_LEGACY,
        exact: true,
        component: ReaderLegacyRedirect,
      }),
      Route({ path: Routes.PAPER_DETAIL_CITED_BY, component: PaperDetailRedirect }),
      Route({ path: Routes.PAPER_DETAIL_REFERENCES, component: PaperDetailRedirect }),
      Route({
        path: Routes.PAPER_DETAIL,
        component: PaperDetailRoute,
        routes: [Route({ path: Routes.PAPER_DETAIL_FIGURE, component: PaperDetailFigureRoute })],
      }),

      Route({ path: Routes.ADMIN_EXPERIMENT_AUDIT, component: AdminExperimentAuditRoute }),
      Route({
        path: Routes.ADMIN,
        component: AdminRoute,
        routes: [
          Route({ path: Routes.ADMIN_EXPERIMENTS, component: AdminExperimentsRoute }),
          Route({ path: Routes.ADMIN_ENROLLMENTS, component: AdminEnrollmentsRoute }),
          Route({ path: Routes.ADMIN_FEATURE_FLAGS, component: AdminFeatureFlagsRoute }),
          Route({ path: Routes.ADMIN_LOGIN_AS, component: AdminLoginAsRoute }),
          Route({ path: Routes.ADMIN_MANAGE_ROLES, component: AdminManageRolesRoute }),
          Route({ path: Routes.ADMIN_MANAGE_USERS, component: AdminManageUsersRoute }),
        ],
      }),

      Route({
        path: Routes.MODERATION,
        component: ModerationRoute,
        routes: [
          Route({
            path: Routes.AUTHOR_CLAIM_MODERATION,
            component: AuthorClaimModerationRoute,
          }),
          Route({
            path: Routes.AUTHOR_CORRECTIONS_TOOL,
            component: ModeratorAuthorCorrectionsToolRoute,
            routes: [
              Route({
                path: Routes.AUTHOR_CORRECTIONS_TOOL_AUTHOR_INPUT,
                component: ModeratorAuthorCorrectionsInputRoute,
              }),
              Route({
                path: Routes.AUTHOR_CORRECTIONS_TOOL_CONTACT,
                component: AccountAuthorContactRoute,
              }),
              Route({
                path: Routes.AUTHOR_CORRECTIONS_TOOL_REMOVE_PAPERS,
                component: AccountRemovePapersRoute,
              }),
              Route({
                path: Routes.AUTHOR_CORRECTIONS_TOOL_ADD_PAPERS,
                component: AccountAddPapersRoute,
              }),
              Route({
                path: Routes.AUTHOR_CORRECTIONS_TOOL_STATUS,
                component: AccountCorrectionsStatusRoute,
              }),
            ],
          }),
        ],
      }),

      Route({ path: Routes.AUTHOR_LANDING, exact: true, component: RouteRedirect }),
      Route({ path: Routes.AUTHOR_PROFILE_BY_CLAIMED, component: AuthorClaimedRedirect }),
      Route({ path: Routes.AUTHOR_CLAIM, component: AuthorClaimRoute }),
      Route({ path: Routes.AUTHOR_CLAIM_COMPLETE, component: AuthorClaimCompleteRoute }),
      Route({
        path: Routes.AUTHOR_PROFILE_BY_ID,
        exact: true,
        component: AuthorWithoutSlugRedirect,
      }),
      Route({
        path: Routes.AUTHOR_PROFILE,
        component: AuthorRoute,
        routes: [
          Route({ path: Routes.AUTHOR_PROFILE_PAPERS, exact: true, component: AuthorPapersRoute }),
          Route({
            path: Routes.AUTHOR_PROFILE_CITING_AUTHORS,
            exact: true,
            component: AuthorCitingAuthorsRoute,
          }),
          Route({
            path: Routes.AUTHOR_PROFILE_REFERENCED_AUTHORS,
            exact: true,
            component: AuthorReferencedAuthorsRoute,
          }),
          Route({
            path: Routes.AUTHOR_PROFILE_CO_AUTHORS,
            exact: true,
            component: AuthorCoAuthorsRoute,
          }),
        ],
      }),

      Route({ path: Routes.ALERTS_UNSUBSCRIBE_ALL, component: UnsubscribeFromAlertRoute }),

      Route({
        path: Routes.LIBRARY_WITH_FOLDERS,
        component: LibraryRootRoute,
        routes: [
          Route({
            path: Routes.LIBRARY_ALL_ENTRIES,
            exact: true,
            component: LibraryAllEntriesRoute,
          }),
          Route({
            path: Routes.LIBRARY_UNSORTED_ENTRIES,
            exact: true,
            component: LibraryUnsortedEntriesRoute,
          }),
          Route({
            path: Routes.LIBRARY_FOLDER,
            exact: true,
            component: LibraryFolderRoute,
          }),
        ],
      }),
      Route({
        path: Routes.SHARED_LIBRARY_FOLDER,
        exact: true,
        component: SharedLibraryFolderRoute,
      }),
      Route({
        path: Routes.UNAUTHORIZED_FOLDER,
        exact: true,
        component: UnauthorizedFolderRoute,
      }),

      Route({
        path: Routes.ACCOUNT,
        component: AccountRoute,
        routes: [
          Route({
            path: Routes.ACCOUNT_AUTHOR_CLAIM_PENDING,
            component: AccountAuthorClaimPendingRoute,
          }),
          Route({
            path: Routes.ACCOUNT_AUTHOR_UNCLAIM_SUCCESS,
            component: AccountAuthorUnclaimSuccessRoute,
          }),
          Route({
            path: Routes.ACCOUNT_AUTHOR_CONTACT,
            component: AccountAuthorContactRoute,
          }),
          Route({ path: Routes.ACCOUNT_ALERTS, component: AlertsListsRoute }),
          Route({
            path: Routes.ACCOUNT_REMOVE_PAPERS,
            component: AccountRemovePapersRoute,
          }),
          Route({ path: Routes.ACCOUNT_ADD_PAPERS, component: AccountAddPapersRoute }),
          Route({
            path: Routes.ACCOUNT_CORRECTIONS,
            component: AccountCorrectionsStatusRoute,
          }),
          Route({ path: Routes.ACCOUNT_CONTACT, component: AccountContactRoute }),
          Route({ path: Routes.ACCOUNT_MANAGE, component: AccountManageRoute }),
        ],
      }),

      Route({ path: Routes.USER_VERIFY_EMAIL, component: VerifyEmailRoute }),

      Route({ path: Routes.SIGN_IN, component: SignInRoute }),
      Route({ path: Routes.SIGN_OUT, component: SignOutRedirect }),

      Route({ path: Routes.ADD_ENROLLMENT_REDIRECT, component: AddUserEnrollmentRedirect }),
      Route({ path: Routes.REMOVE_ENROLLMENT_REDIRECT, component: RemoveUserEnrollmentRedirect }),

      Route({ path: Routes.CRAWLER, component: CrawlerRoute }),
      Route({ path: Routes.ABOUT_ROOT, component: RouteRedirect }),
      Route({ path: Routes.ABOUT_PAGE, component: RouteRedirect }),
      Route({ path: Routes.API_GALLERY_ROOT, component: RouteRedirect }),
      Route({ path: Routes.API_GALLERY_PAGE, component: RouteRedirect }),
      Route({ path: Routes.FAQ_ROOT, component: RouteRedirect }),
      Route({ path: Routes.FAQ_PAGE, component: RouteRedirect }),
      Route({ path: Routes.PRODUCT_PAGE, component: RouteRedirect }),
      Route({ path: Routes.RESEARCH_ROOT, component: RouteRedirect }),
      Route({ path: Routes.RESEARCH_PAGE, component: RouteRedirect }),
      Route({ path: Routes.RESOURCES_ROOT, component: RouteRedirect }),
      Route({ path: Routes.RESOURCES_PAGE, component: RouteRedirect }),

      Route({
        path: Routes.CORD19_REDIRECT,
        component: mkRedirectTo('https://github.com/allenai/cord19'),
      }),

      Route({ path: Routes.DEBUG_AUTHORS, component: DebugAuthorListRoute }),
      Route({ path: Routes.DEBUG_PAPERS, component: DebugPaperListRoute }),
      Route({ path: Routes.DEBUG_PDF, component: DebugPdfRoute }),
      Route({
        path: Routes.DEBUG_COMPONENT_LIBRARY,
        exact: true,
        component: DebugComponentLibraryRoute,
      }),
      Route({
        path: Routes.DEBUG_COMPONENT_LIBRARY_COMPONENT,
        component: DebugComponentLibraryRoute,
      }),
      Route({
        path: Routes.DEBUG_COMPONENT_LIBRARY_PREVIEW,
        component: DebugComponentLibraryPreviewRoute,
      }),
      Route({ path: Routes.DEBUG_SPRITES, component: DebugSpritesRoute }),

      Route({
        path: Routes.RESEARCH_HOMEPAGE,
        exact: true,
        component: ResearchHomepageRoute,
      }),
      Route({ path: Routes.RECOMMENDATIONS, exact: true, component: RecommendationsRoute }),

      Route({ path: Routes.BLANK, component: BlankRoute }),
      Route({ path: Routes.VENUE, component: VenueRoute }),

      Route({ path: Routes.NOT_FOUND, exact: true, component: NotFoundRoute }),
      Route({ component: NotFoundRoute }),
    ],
  }),
];

export default routes;
