import { CorrectionTypeValue, optCorrectionTypeValue } from '@/constants/CorrectionType';
import { Nullable } from '@/utils/types';
import { optCompletionStatusValue } from '@/constants/CompletionStatus';
import { optModerationStatusValue } from '@/constants/ModerationStatus';

import Immutable from 'immutable';

export type PaperAuthorCorrectionFromJS = {
  id: Nullable<number>;
  authorId: Nullable<number>;
  corpusAuthorId: Nullable<number>;
  paperHash: Nullable<string>;
  position: Nullable<number>;
  correctionType: Nullable<CorrectionTypeValue>;
  fromAuthorId: Nullable<number>;
  fromCorpusAuthorId: Nullable<number>;
  createdAtUnix: Nullable<number>;
  updatedAtUnix: Nullable<number>;
  completionStatus: Nullable<string>;
  moderationStatus: Nullable<string>;
  moderatorEmail: Nullable<string>;
  moderatedAtUnix: Nullable<number>;
};

type Props = {
  id: Nullable<number>;
  authorId: Nullable<number>;
  corpusAuthorId: Nullable<number>;
  paperHash: Nullable<string>;
  position: Nullable<number>;
  correctionType: Nullable<CorrectionTypeValue>;
  fromAuthorId: Nullable<number>;
  fromCorpusAuthorId: Nullable<number>;
  createdAtUnix: Nullable<number>;
  updatedAtUnix: Nullable<number>;
  completionStatus: Nullable<string>;
  moderationStatus: Nullable<string>;
  moderatorEmail: Nullable<string>;
  moderatedAtUnix: Nullable<number>;
};

const defaultProps: Props = {
  id: null,
  authorId: null,
  corpusAuthorId: null,
  correctionType: null,
  paperHash: null,
  fromAuthorId: null,
  fromCorpusAuthorId: null,
  position: null,
  createdAtUnix: null,
  updatedAtUnix: null,
  completionStatus: null,
  moderationStatus: null,
  moderatorEmail: null,
  moderatedAtUnix: null,
};

export const PaperAuthorCorrectionFactory = Immutable.Record<Props>(defaultProps);

export type PaperAuthorCorrectionRecord = Immutable.RecordOf<Props>;

export function getPaperAuthorCorrectionFromJS(
  args: PaperAuthorCorrectionFromJS
): PaperAuthorCorrectionRecord {
  return PaperAuthorCorrectionFactory({
    ...args,
    completionStatus: optCompletionStatusValue(args?.completionStatus),
    correctionType: optCorrectionTypeValue(args?.correctionType),
    moderationStatus: optModerationStatusValue(args?.moderationStatus),
  });
}
