import invariant from 'invariant';

import type { DEPRECATED__FlowOptional } from '@/utils/types';

export type ModerationStatusReasonKey = keyof typeof byKey;
export type ModerationStatusReasonValue = (typeof byKey)[ModerationStatusReasonKey];

// This enum is also defined in /online/service/src/main/scala/org/allenai/s2/service/model/ModerationStatusReason.scala
// Any updates to reasons should be made in both files
const denyReason = {
  InsufficientData: 'Insufficient Data',
  IncorrectData: 'Incorrect Data',
  ClarificationNeeded: 'Clarification Needed',
  IncorrectClaim: 'Incorrect Claim',
  DuplicateClaim: 'Duplicate Claim',
} as const;
export const DenyReason = Object.freeze(denyReason);

const autoModerationReason = {
  EmailDomainMatch: 'Email Domain Match',
  EmailNotVerified: 'Email Not Verified',
  EmailSearchMatch: 'Email Search Match',
  OrcidIdMatch: 'ORCID ID Match',
  ReviewUserComments: 'Review User Comments',
} as const;

const byKey = {
  ...denyReason,
  ...autoModerationReason,
} as const;

// TODO: TS isn't quite sure what to make of this type
const byValue = Object.keys(byKey).reduce((memo, key) => {
  const value = byKey[key];
  memo[value] = key;
  return memo;
}, {});

export default Object.freeze(byKey);

export const ModerationStatusReasonByValue = Object.freeze(byValue);

export function getModerationStatusReasonByKey(
  key: DEPRECATED__FlowOptional<string>
): ModerationStatusReasonKey {
  const result = optModerationStatusReasonByKey(key);
  invariant(result, `Cannot convert value to ModerationStatusReason [key="${key || ''}"]`);
  return result;
}

export function optModerationStatusReasonByKey(
  key: DEPRECATED__FlowOptional<string>
): DEPRECATED__FlowOptional<ModerationStatusReasonKey> {
  if (key && key in byKey) {
    return key as ModerationStatusReasonKey;
  }
  return null;
}

export function getModerationStatusReasonByValue(
  value: DEPRECATED__FlowOptional<string>
): ModerationStatusReasonValue {
  const result = optModerationStatusReasonByValue(value);
  invariant(result, `Cannot convert value to ModerationStatusReason [value="${value || ''}"]`);
  return result;
}

export function optModerationStatusReasonByValue(
  value: DEPRECATED__FlowOptional<string>
): DEPRECATED__FlowOptional<ModerationStatusReasonValue> {
  if (value && value in byValue) {
    return value as ModerationStatusReasonValue;
  }
  return null;
}
