import CLTextInputBase, { CLTextInputBaseProps } from './CLTextInputBase';

import { ReactNodeish } from '@/utils/types';

import React from 'react';

type CLPasswordInputProps = Omit<CLTextInputBaseProps, 'type'>;

export default function CLPasswordInput(props: CLPasswordInputProps): ReactNodeish {
  return <CLTextInputBase {...props} type="password" />;
}
