/* eslint-disable no-unused-vars */

import BaseStore from './BaseStore';

import {
  getLibraryEntriesQueryFromPath,
  LibraryEntriesQueryRecord,
} from '@/models/library/LibraryEntriesQuery';
import { getLibraryEntryFromJS, LibraryEntryRecord } from '@/models/library/LibraryEntry';
import { hasValue, Nullable } from '@/utils/types';
import Constants, { RequestTypeValue } from '@/constants';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import Immutable from 'immutable';

import type { ApiRequestStartingPayload } from '@/api/BaseApi';
import type {
  ApiResponse,
  CreateLibraryEntryBulkResponseBody,
  DeleteLibraryEntryBulkResponseBody,
  GetLibraryEntriesResponseBody,
  RemoveEntriesFromFoldersResponseBody,
} from '@/api/ApiResponse';
import type S2Dispatcher from '@/utils/S2Dispatcher';

const GET_LIBRARY_ENTRIES_BY_FOLDER_RE = /\/library\/folders\/(\d+)\/entries/;

export default class LibraryPageStore extends BaseStore {
  #currentQuery: Nullable<LibraryEntriesQueryRecord>;
  #currentPath: Nullable<string>;
  #currentLoadRequest: Nullable<RequestTypeValue>;
  #currentFolderId: Nullable<number>; // only meaningful for currentLoadRequest GET_LIBRARY_ENTRIES_BY_FOLDER:
  #entriesByIdMap: Immutable.Map<number, LibraryEntryRecord>;
  #state: StoreStateValue;
  #currentPageNumber: number;
  #totalHits: Nullable<number>;
  #totalPages: Nullable<number>;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#entriesByIdMap = Immutable.OrderedMap();
    this.#state = StoreState.UNINITIALIZED;
    this.#currentQuery = null;
    this.#currentPath = null;

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const startingPayload = payload as ApiRequestStartingPayload;
          switch (startingPayload.requestType) {
            case Constants.requestTypes.GET_ALL_LIBRARY_ENTRIES:
            case Constants.requestTypes.GET_UNSORTED_LIBRARY_ENTRIES:
              return this.#handleGetLibraryEntriesStart(startingPayload);

            case Constants.requestTypes.GET_LIBRARY_ENTRIES_BY_FOLDER:
              return this.#handleGetLibraryEntriesByFolderStart(startingPayload);
          }
          break;
        }

        case Constants.actions.API_REQUEST_COMPLETE: {
          const completePayload = payload as ApiResponse;
          switch (completePayload.requestType) {
            case Constants.requestTypes.LOGOUT:
              return this.#handleLogoutComplete();

            case Constants.requestTypes.GET_ALL_LIBRARY_ENTRIES:
            case Constants.requestTypes.GET_LIBRARY_ENTRIES_BY_FOLDER:
            case Constants.requestTypes.GET_UNSORTED_LIBRARY_ENTRIES:
              return this.#handleGetLibraryEntriesComplete(completePayload);

            case Constants.requestTypes.CREATE_LIBRARY_ENTRY_BULK:
              return this.#handleCreateLibraryEntriesBulkComplete(completePayload);

            case Constants.requestTypes.REMOVE_ENTRIES_FROM_FOLDERS:
              return this.#handleRemoveEntriesFromFoldersComplete(completePayload);

            case Constants.requestTypes.DELETE_LIBRARY_ENTRIES_BULK:
              return this.#handleDeleteLibraryEntriesBulkComplete(completePayload);
          }
        }
      }
    });
  }

  #handleGetLibraryEntriesStart(payload: ApiRequestStartingPayload): void {
    this.#currentQuery = getLibraryEntriesQueryFromPath(payload.path);
    this.#currentPath = payload.path;
    this.#state = StoreState.LOADING;
    this.emitChange();
  }

  #handleGetLibraryEntriesByFolderStart(payload: ApiRequestStartingPayload): void {
    const folderId = (payload.path?.match(GET_LIBRARY_ENTRIES_BY_FOLDER_RE) || [])[1];
    this.#currentQuery = getLibraryEntriesQueryFromPath(payload.path);
    this.#currentPath = payload.path;
    this.#currentFolderId = parseInt(folderId);
    this.#state = StoreState.LOADING;
    this.emitChange();
  }

  #handleLogoutComplete(): void {
    this.#clearProperties();
    this.#state = StoreState.UNINITIALIZED;
    this.emitChange();
  }

  #handleGetLibraryEntriesComplete(payload: ApiResponse<GetLibraryEntriesResponseBody>): void {
    if (payload.path != this.#currentPath) {
      return;
    }

    const { resultData } = payload;
    const entriesList: Immutable.List<[number, LibraryEntryRecord]> = Immutable.List(
      resultData.entries
    ).map(entry => [entry.id, getLibraryEntryFromJS(entry)]);

    this.#entriesByIdMap = Immutable.OrderedMap(entriesList);
    this.#currentLoadRequest = payload.requestType;
    this.#currentPageNumber = resultData.pageNumber;
    this.#totalHits = resultData.totalHits;
    this.#totalPages = resultData.totalPages;
    this.#state = StoreState.LOADED;
    this.emitChange();
  }

  #handleCreateLibraryEntriesBulkComplete(
    payload: ApiResponse<CreateLibraryEntryBulkResponseBody>
  ): void {
    // the bulk create endpoint will return a list of entries created
    // ex. adding a paper to 2 folders will create 2 separate entries with that paper id
    // save just the first entry instance in the store otherwise we end up rendering duplicates
    const firstEntry = payload.resultData.entries[0];

    if (this.#currentLoadRequest === Constants.requestTypes.GET_UNSORTED_LIBRARY_ENTRIES) {
      // UNSORTED view, newly sorted entry: REMOVE the entry from the displayed list and adjust the totalHits
      this.#entriesByIdMap = this.#entriesByIdMap.delete(firstEntry.id);
      this.#adjustTotalHitsFromEntryRemoval();
    } else if (this.#currentLoadRequest !== Constants.requestTypes.GET_LIBRARY_ENTRIES_BY_FOLDER) {
      // Otherwise ADD the entry to the displayed list if not viewing by folder
      // AND if entry is completely new rather than just moving around folders
      const paperId = firstEntry.paper?.id || firstEntry.paperId;
      const knownPaperEntry = this.getEntryByPaperId(paperId);
      if (!knownPaperEntry) {
        this.#entriesByIdMap = this.#entriesByIdMap.set(
          firstEntry.id,
          getLibraryEntryFromJS(firstEntry)
        );
        this.#adjustTotalHitsFromEntryAddition();
      }
    }
    this.emitChange();
  }

  #handleRemoveEntriesFromFoldersComplete(
    payload: ApiResponse<RemoveEntriesFromFoldersResponseBody>
  ): void {
    const forCurrentFolder =
      hasValue(this.#currentFolderId) &&
      payload.resultData.folderIds.includes(this.#currentFolderId);
    if (
      this.#currentLoadRequest === Constants.requestTypes.GET_LIBRARY_ENTRIES_BY_FOLDER &&
      forCurrentFolder
    ) {
      // SORTED view, entry newly removed from folder: REMOVE the entry from the displayed list, adjust to total hits
      payload.resultData.entryIds.forEach(entryId => {
        this.#entriesByIdMap = this.#entriesByIdMap.delete(entryId);
      });
      this.#adjustTotalHitsFromEntryRemoval();
      this.emitChange();
    }
  }

  #handleDeleteLibraryEntriesBulkComplete(
    payload: ApiResponse<DeleteLibraryEntryBulkResponseBody>
  ): void {
    payload.resultData.deletedEntryIds.forEach(entryId => {
      this.#entriesByIdMap = this.#entriesByIdMap.delete(entryId);
    });
    this.#adjustTotalHitsFromEntryRemoval();
    this.emitChange();
  }

  #clearProperties(): void {
    this.#entriesByIdMap = Immutable.OrderedMap();
    this.#currentLoadRequest = null;
    this.#totalHits = null;
    this.#totalPages = null;
    this.#currentFolderId = null;
  }

  #adjustTotalHitsFromEntryRemoval(): void {
    if (this.#totalHits) {
      this.#totalHits = this.#totalHits - 1;
    }
  }

  #adjustTotalHitsFromEntryAddition(): void {
    if (this.#totalHits) {
      this.#totalHits = this.#totalHits + 1;
    } else {
      // treat falsey totalHits as 0 and set to 1 in case it's ever undefined rather than null or 0
      this.#totalHits = 1;
    }
  }

  getEntries(): Immutable.List<LibraryEntryRecord> {
    return this.#entriesByIdMap.valueSeq().toList();
  }

  getEntryByPaperId(paperId?: Nullable<string>): Nullable<LibraryEntryRecord> {
    if (!hasValue(paperId)) {
      return null;
    }

    // find paper by paper.id and fallback to the paper id on the entry itself if paper doesn't exist
    return (
      this.#entriesByIdMap
        .valueSeq()
        .filter(entry => (entry.paper?.id || entry.paperId) === paperId)
        .first() || null
    );
  }

  getCurrentPageNumber(): number {
    return this.#currentPageNumber;
  }

  getTotalHits(): Nullable<number> {
    return this.#totalHits;
  }

  getTotalPages(): Nullable<number> {
    return this.#totalPages;
  }

  getCurrentQuery(): Nullable<LibraryEntriesQueryRecord> {
    return this.#currentQuery;
  }

  getCurrentLoadRequest(): Nullable<RequestTypeValue> {
    return this.#currentLoadRequest;
  }

  getCurrentFolderId(): Nullable<number> {
    return this.#currentFolderId;
  }

  isLoading(): boolean {
    return this.isUninitialized() || this.#state === StoreState.LOADING;
  }

  isUninitialized(): boolean {
    return this.#state === StoreState.UNINITIALIZED;
  }
}
