import BaseStore from './BaseStore';

import { AlertFrequencyByValue, AlertFrequencyKey } from '@/constants/AlertFrequency';
import { EnrollmentValue, optEnrollmentValue } from '@/weblab/Enrollment';
import { getLoginMethodIdFromUser, LoginMethodId } from '@/constants/LoginMethods';
import { getUserRecordFromJS, UserFromJS, UserRecord } from '@/models/user/User';
import { hasValue, Nullable } from '@/utils/types';
import { ModalAction, ModalId } from '@/constants/Modal';
import { runWithAppcues } from '@/analytics/Appcues';
import { runWithHeap } from '@/analytics/Heap';
import { showModal, ShowModalAction } from '@/actions/ModalActionCreators';
import Constants from '@/constants';
import S2Dispatcher from '@/utils/S2Dispatcher';
import WeblabStore from '@/weblab/WeblabStore';

import Immutable from 'immutable';

import type {
  ApiResponse,
  EnrollmentResponseBody,
  UpdateAlertFrequencyResponseBody,
  UpdateAuthorProfileResponseBody,
  UpdateUserResponseBody,
  UserInfoResponseBody,
} from '@/api/ApiResponse';
import type { ModerationStatusValue } from '@/constants/ModerationStatus';
import type { RoleValue } from '@/constants/Role';

export const HEAP_LOGGED_IN_PROP_NAME = 'Is Signed In';
export const HEAP_AUTH_SOURCE = 'Auth Source';

export default class AuthStore extends BaseStore {
  #user: Nullable<UserRecord>;
  #userEnrollments: Immutable.Set<EnrollmentValue>;
  #attemptingToAuth: boolean;
  #isImposter: boolean;
  #loginPromise: Promise<UserRecord>;
  #weblabSore: WeblabStore;

  constructor(dispatcher: S2Dispatcher, weblabStore: WeblabStore) {
    super();

    this.#user = null;
    this.#userEnrollments = Immutable.Set();
    this.#attemptingToAuth = false;
    this.#isImposter = false;
    this.#weblabSore = weblabStore;

    runWithHeap(heap => {
      heap.addEventProperties({
        [HEAP_LOGGED_IN_PROP_NAME]: false,
      });
    });

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case ModalAction.SHOW_MODAL: {
          return this.#handleShowModal(payload as ShowModalAction);
        }
        case ModalAction.HIDE_MODAL: {
          return this.#handleHideModal();
        }
        case Constants.actions.API_REQUEST_COMPLETE: {
          const completePayload = payload as ApiResponse;
          switch (completePayload.requestType) {
            case Constants.requestTypes.USER_INFO: {
              return this.#handleUserInfoComplete(completePayload);
            }
            case Constants.requestTypes.UPDATE_USER: {
              return this.#handleUpdateUserComplete(completePayload);
            }
            case Constants.requestTypes.UPDATE_ALERT_FREQUENCY: {
              return this.#handleUpdateAlertFrequencyComplete(completePayload);
            }
            case Constants.requestTypes.USER_ADD_ENROLLMENT: {
              return this.#handleUserAddEnrollmentComplete(completePayload);
            }
            case Constants.requestTypes.LOGOUT: {
              return this.#handleLogoutComplete();
            }
            case Constants.requestTypes.VERIFY_EMAIL:
            case Constants.requestTypes.VERIFY_ALERT_EMAIL: {
              return this.#handleVerifyEmailComplete();
            }
            case Constants.requestTypes.AUTHOR_PROFILE_UPDATE: {
              return this.#handleAuthorProfileUpdateComplete(completePayload);
            }
          }
          break;
        }
      }
    });
  }

  #handleShowModal(payload: ShowModalAction): void {
    if (payload.id === ModalId.LOGIN) {
      this.#attemptingToAuth = true;
    }
  }

  #handleHideModal(): void {
    if (this.#attemptingToAuth) {
      this.#attemptingToAuth = false;
    }
  }

  #handleUserInfoComplete(payload: ApiResponse<UserInfoResponseBody>): void {
    this.#loginWith(payload.resultData.user);
    this.#userEnrollments = Immutable.Set(parseEnrollmentValues(payload.resultData.enrollments));
    this.#attemptingToAuth = false;
    this.#isImposter = payload.resultData.isImposter;
    this.emitChange();
  }

  #handleUpdateUserComplete(payload: ApiResponse<UpdateUserResponseBody>): void {
    this.#loginWith(payload.resultData);
    this.emitChange();
  }

  #handleUpdateAlertFrequencyComplete(
    payload: ApiResponse<UpdateAlertFrequencyResponseBody>
  ): void {
    this.#loginWith(payload.resultData);
    this.emitChange();
  }

  #handleUserAddEnrollmentComplete(payload: ApiResponse<EnrollmentResponseBody>): void {
    this.#userEnrollments = Immutable.Set(parseEnrollmentValues(payload.resultData.enrollments));
    this.emitChange();
  }

  #handleLogoutComplete(): void {
    this.#logout();
    this.emitChange();
  }

  #handleVerifyEmailComplete(): void {
    if (this.hasAuthenticatedUser() && this.#user) {
      this.#user = this.#user.update('alertEmailIsVerified', () => true);
      this.emitChange();
    }
  }

  #handleAuthorProfileUpdateComplete(payload: ApiResponse<UpdateAuthorProfileResponseBody>): void {
    if (this.hasAuthenticatedUser() && this.#user) {
      this.#user = this.#user.update(
        'claimedAuthorId',
        () => payload.resultData?.authorProfile.ai2AuthorId || null
      );
      this.emitChange();
    }
  }

  getUser(): Nullable<UserRecord> {
    return this.#user;
  }

  getEmail(): Nullable<string> {
    return this.#user ? this.#user.email : null;
  }

  getAlertEmail(): Nullable<string> {
    return this.#user ? this.#user.alertEmail : null;
  }

  getAlertFrequency(): Nullable<AlertFrequencyKey> {
    const alertFrequencyValue = this.#user?.alertFrequency;
    return alertFrequencyValue ? AlertFrequencyByValue[alertFrequencyValue] : null;
  }

  hasAuthenticatedUser(): boolean {
    // The !! casts a falsy or truthy object to a false / true
    return !!this.#user;
  }

  getLoginMethodId(): Nullable<LoginMethodId> {
    return getLoginMethodIdFromUser(this.#user);
  }

  hasVerifiedAlertEmail(): boolean {
    return this.#user ? !!this.#user.alertEmailIsVerified : false;
  }

  getUserRoles(): Immutable.List<RoleValue> {
    const roles = this.#user?.roles || Immutable.List();
    return roles;
  }

  hasUserRole(roleName: RoleValue): boolean {
    const roles = this.getUserRoles();
    return roles.contains(roleName);
  }

  // Author Id which the user has attempted to claim, regardless of moderation status
  getClaimedAuthorId(): Nullable<number> {
    return this.#user?.claimedAuthorId || null;
  }
  // Moderation status of the most recent claim made by a user
  getAuthorClaimModerationStatus(): Nullable<ModerationStatusValue> {
    return this.#user?.claimedAuthorModerationStatus || null;
  }

  getEnrollments(): Immutable.Set<EnrollmentValue> {
    return this.#userEnrollments;
  }

  isUserEnrolled(enrollmentValue: EnrollmentValue): boolean {
    return this.#userEnrollments.contains(enrollmentValue);
  }

  isImpersonating(): boolean {
    return this.#isImposter;
  }

  getUserHash(): Nullable<number> {
    return this.#user ? this.#user.hash : null;
  }

  ensureLogin({
    dispatcher,
    analyticData = null,
    location,
    subLocation = null,
    modalData = {},
  }: {
    dispatcher: S2Dispatcher;
    analyticData?: Nullable<object>;
    location: string;
    subLocation?: Nullable<string>;
    modalData?: Nullable<object>;
  }): Promise<UserRecord> {
    this.#loginPromise = new Promise((resolve, reject) => {
      const user = this.getUser();
      if (!user) {
        this.once(() => {
          const user = this.getUser();
          if (user) {
            resolve(user);
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('No user found.');
          }
        });
        dispatcher.dispatch(
          showModal({
            id: ModalId.LOGIN,
            data: { analyticData, ...modalData },
            location: location,
            subLocation: subLocation,
          })
        );
      } else {
        resolve(user);
      }
    });
    return this.#loginPromise;
  }

  #loginWith(userData: UserFromJS): void {
    const user = getUserRecordFromJS(userData);
    this.#user = user;
    runWithHeap(heap => {
      heap.identify(user.hash, 'user_hash');
      heap.addUserProperties({
        [HEAP_AUTH_SOURCE]: user.authSource,
      });
      heap.addEventProperties({
        [HEAP_LOGGED_IN_PROP_NAME]: true,
      });
    });
    runWithAppcues(appcues => {
      appcues.identify(user.hash);
    });
  }

  #logout(): void {
    this.#user = null;
    this.#userEnrollments = Immutable.Set();
    this.#isImposter = false;
    // This is a hack so that we clear user based experiment cookies upon logout!
    this.#weblabSore.onLogout();
    runWithHeap(heap => {
      heap.resetIdentity();
      heap.addEventProperties({
        [HEAP_LOGGED_IN_PROP_NAME]: false,
      });
    });
    runWithAppcues(appcues => {
      appcues.anonymous();
    });
  }

  clearUser(): void {
    this.#user = null;
    this.#userEnrollments = Immutable.Set();
    this.#isImposter = false;
    this.emitChange();
  }
}

function parseEnrollmentValues(rawEnrollments?: string[]): EnrollmentValue[] {
  if (!hasValue(rawEnrollments)) {
    return [];
  }
  return rawEnrollments
    .map(raw => optEnrollmentValue(raw))
    .filter((enrollmentValue): enrollmentValue is EnrollmentValue => enrollmentValue !== null);
}
