import { getPluralizedString, getString } from '@/content/i18n';
import { heapCueFolderLink } from '@/analytics/attributes/personalizedCues';
import { mapHooksToProps } from '@/utils/react-utils';
import { useAppContext, useStateFromStore } from '@/AppContext';
import Icon from '@/components/shared/icon/Icon';
import Link from '@/router/Link';

import Immutable from 'immutable';
import React from 'react';

import type { CueType } from '@/constants/CueType';
import type { LibraryFolderRecord } from '@/models/library/LibraryFolder';
import type { Nullable } from '@/utils/types';

type PropsFromParent = {
  folderId?: Nullable<number>;
  paperId?: Nullable<string>;
  prioritizedCue: CueType;
};

type Props = {
  folders: Immutable.List<LibraryFolderRecord>;
  mostRecentFolder: Nullable<LibraryFolderRecord>;
} & PropsFromParent;

export function CueLibraryFolderNames({ folders, mostRecentFolder, prioritizedCue }: Props) {
  return (
    <>
      {mostRecentFolder ? (
        <div className="cue-library-folder-names">
          <Link
            to="LIBRARY_FOLDER"
            params={{ libraryFolderId: mostRecentFolder.id.toString() }}
            className="cue-library-folder-names__folder-link"
            {...heapCueFolderLink({ 'cue-type': prioritizedCue })}>
            <>
              <Icon icon="folder-closed" height="11" width="15" />
              {mostRecentFolder.name}
            </>
          </Link>

          {folders.size > 1 &&
            getPluralizedString(_ => _.library.moreFoldersText, folders.size - 1)}
        </div>
      ) : (
        <div className="cue-library-folder-names">
          <Link
            to="LIBRARY_UNSORTED_ENTRIES"
            className="cue-library-folder-names__folder-link"
            {...heapCueFolderLink({ 'cue-type': prioritizedCue })}>
            <>
              <Icon icon="fa-bookmark" height="11" width="11" />
              {getString(_ => _.personalizedCue.inYourLibrary)}
            </>
          </Link>
        </div>
      )}
    </>
  );
}

export default mapHooksToProps<Props, PropsFromParent>(CueLibraryFolderNames, props => {
  const { folderId, paperId } = props;
  const { libraryFolderStore } = useAppContext();
  const libraryFolderStoreProps = useStateFromStore(libraryFolderStore, _ => ({
    folders: _.getFoldersWithPaperId(paperId),
    mostRecentFolder: _.getFolderById(folderId),
  }));

  return {
    ...libraryFolderStoreProps,
    ...props,
  };
});
