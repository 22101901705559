import {
  AUTHOR_LITE_FRAGMENT,
  AUTHOR_LITE_FRAGMENT_NAME,
  AuthorLiteFromGraphQL,
  AuthorLiteRecord,
  AuthorLiteRecordFactory,
  getAuthorLiteFromGraphQL,
} from './AuthorLite';

import { gql } from 'graphql-tag';
import Immutable from 'immutable';

export type ReferencedAuthorFromGraphQL = {
  referencedPaperCount: number;
  referencedAuthor: AuthorLiteFromGraphQL;
};

type Props = {
  paperCount: number;
  author: AuthorLiteRecord;
};

const defaultProps: Props = {
  paperCount: 0,
  author: AuthorLiteRecordFactory(),
};

export const ReferencedAuthorRecordFactory = Immutable.Record<Props>(defaultProps);

export type ReferencedAuthorRecord = Immutable.RecordOf<Props>;

export function getReferencedAuthorFromGraphQL(
  args: ReferencedAuthorFromGraphQL
): ReferencedAuthorRecord {
  const { referencedPaperCount, referencedAuthor } = args;
  return ReferencedAuthorRecordFactory({
    ...args,
    paperCount: referencedPaperCount,
    author: getAuthorLiteFromGraphQL(referencedAuthor),
  });
}

export const REFERENCED_AUTHOR_FRAGMENT_NAME = 'ReferencedAuthorFragment';

export const REFERENCED_AUTHOR_FRAGMENT = gql`
  ${AUTHOR_LITE_FRAGMENT}
  fragment ${REFERENCED_AUTHOR_FRAGMENT_NAME} on AuthorCitation {
    referencedPaperCount
    referencedAuthor {
      ...${AUTHOR_LITE_FRAGMENT_NAME}
    }
  }
`;
