import Immutable from 'immutable';

export type CitationStyleType = (typeof CitationStyle)[keyof typeof CitationStyle];

const CitationStyle = {
  BIBTEX: 'BibTex',
  ENDNOTE: 'EndNote',
  MLA: 'MLA',
  APA: 'APA',
  CHICAGO: 'Chicago',
} as const;

export const AllCitationStyles = Immutable.List(
  Object.keys(CitationStyle).map(style => CitationStyle[style])
);

export default CitationStyle;
