import { getCenterBottomCoordinates } from '@/components/library/dropdown/CLDropdownBase';
import { makePath } from '@/router/Routes';
import { TOTAL_CITATIONS } from '@/components/library/paper/stats/CLPaperStatsBase';
import { useAppContext } from '@/AppContext';
import CitationsHistogram from '@/components/shared/histogram/CitationsHistogram';
import CLPaperTotalCitationsStat, {
  canRender,
} from '@/components/library/paper/stats/buttons/CLPaperTotalCitationsStat';
import CLPopover, { ARROW_POSITION } from '@/components/library/popover/CLPopover';
import CLPortal from '@/components/library/popover/CLPortal';

import classnames from 'classnames';
import React from 'react';

const POPOVER_WIDTH = 350;

export default function CLPaperActionTotalCitationsStat({
  paper,
  onHover,
  heapProps,
  className,
  testId,
}) {
  const { citationStats } = paper;
  const [isHistogramVisible, setShowHistogram] = React.useState(false);
  const { envInfo } = useAppContext();
  const isMobile = envInfo.isMobile;
  const badgeRef = React.useRef();

  const showCitationPopover = React.useCallback(() => {
    if (onHover) {
      onHover(TOTAL_CITATIONS);
    }
    setShowHistogram(true);
  }, [setShowHistogram]);

  const hideCitationPopover = React.useCallback(() => {
    setShowHistogram(false);
  }, [setShowHistogram]);

  if (!canRender(citationStats)) {
    return null;
  }
  const citationPath = makePath({
    routeName: 'PAPER_DETAIL',
    params: { slug: paper.slug, paperId: paper.id },
    hash: 'citing-papers',
  });

  return (
    <div
      ref={badgeRef}
      className={classnames('cl-paper-stats__action-stat', className)}
      onMouseEnter={showCitationPopover}
      onMouseLeave={hideCitationPopover}
      onFocus={showCitationPopover}
      onBlur={hideCitationPopover}
      data-test-id={testId}>
      {/* TODO  #27899 - Find a better way to link to citation section of the PDP */}
      <a href={citationPath} className="cl-paper-stats__citation-pdp-link">
        <CLPaperTotalCitationsStat
          citationStats={citationStats}
          heapProps={heapProps}
          className={classnames('cl-paper-stats__badge-hover', {
            'cl-paper-stats__mobile-badge': isMobile,
            'cl-paper-stat__with-border': paper.isReaderAvailable,
          })}
        />
      </a>

      {badgeRef.current && !isMobile && !!isHistogramVisible ? (
        <CLPortal coordinates={getCenterBottomCoordinates(badgeRef.current)}>
          <CLPopover arrow={ARROW_POSITION.SIDE_TOP_POS_LEFT}>
            <CitationsHistogram
              width={POPOVER_WIDTH}
              id={paper.id + 'Popover'}
              citationStats={citationStats}
              entityId={paper.id}
            />
          </CLPopover>
        </CLPortal>
      ) : null}
    </div>
  );
}

CLPaperActionTotalCitationsStat.defaultProps = {
  testId: 'total-citations-stat',
};
