import Immutable from 'immutable';

export type PaperPdfFeatureVersionFromJS = {
  pipelineName: string;
  featureType: string;
  versionName: string;
};

type Props = {
  pipelineName: string;
  featureType: string;
  versionName: string;
};

const defaultProps: Props = {
  pipelineName: '',
  featureType: '',
  versionName: '',
};

export const PaperPdfFeatureVersionFactory = Immutable.Record<Props>(defaultProps);

export type PaperPdfFeatureVersionRecord = Immutable.RecordOf<Props>;

export function getPaperPdfFeatureVersionFromJS(
  args: PaperPdfFeatureVersionFromJS
): PaperPdfFeatureVersionRecord {
  return PaperPdfFeatureVersionFactory({
    ...args,
  });
}
