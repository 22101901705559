import { mkHeapAttributes } from '@/analytics/heapUtils';

// LibraryNav heap analytic ids

export const heapLibraryNavEditFolderButton = () =>
  mkHeapAttributes({
    id: 'library_nav_edit_folder_button',
  });

export const heapLibraryNavAllEntriesLink = () =>
  mkHeapAttributes({
    id: 'library_nav_all_entries_link',
  });

export const heapLibraryNavUnsortedEntriesLink = () =>
  mkHeapAttributes({
    id: 'library_nav_unsorted_entries_link',
  });

export const heapLibraryNavFolderLink = () =>
  mkHeapAttributes({
    id: 'library_nav_folder_link',
  });

export const heapLibraryNavAddFolderButton = () =>
  mkHeapAttributes({
    id: 'library_nav_add_folder_button',
  });

export const heapLibraryNavAddFolderSubmit = () =>
  mkHeapAttributes({
    id: 'library_nav_add_folder_submit',
  });

export const heapLibraryNavAddFolderCancel = () =>
  mkHeapAttributes({
    id: 'library_nav_add_folder_cancel',
  });

// LibraryOrganizePapersButton

export const heapLibraryOrganizePapersButton = () =>
  mkHeapAttributes({
    id: 'library_organize_papers_button',
  });

// RemoveFromLibraryButton on Paper Row

export const heapLibraryRemoveFromLibraryButton = () =>
  mkHeapAttributes({
    id: 'library_remove_from_library_button',
  });

export const heapLibraryRemoveFromLibraryUndoButton = () =>
  mkHeapAttributes({
    id: 'library_remove_from_library_undo_button',
  });

// LibrarySearchSortBar

export const heapLibrarySearchPapersInput = () =>
  mkHeapAttributes({
    id: 'library_search_papers_input',
  });

export const heapLibrarySearchPapersSubmitButton = () =>
  mkHeapAttributes({
    id: 'library_search_papers_submit_button',
  });

export const heapLibrarySearchPapersSortDropdown = (props: { 'sort-type': string }) =>
  mkHeapAttributes({
    id: 'library_sort_papers_dropdown',
    ...props,
  });

export const heapLibraryRecommendationEnable = () =>
  mkHeapAttributes({
    id: 'library_recommendation_enable_button',
  });

export const heapLibraryRecommendationDisable = () =>
  mkHeapAttributes({
    id: 'library_recommendation_disable_button',
  });

export const heapLibraryFolderResearchFeedLink = () =>
  mkHeapAttributes({
    id: 'library_folder_research_feed_link',
  });

export const heapSharedCopyFolderButton = () =>
  mkHeapAttributes({
    id: 'library_shared_copy_folder_button',
  });

export const heapViewSharedCopyFolderLink = () =>
  mkHeapAttributes({
    id: 'library_view_shared_copied_folder_link',
  });

export const heapSaveFolderDescriptionButton = () =>
  mkHeapAttributes({
    id: 'library_save_folder_description_button',
  });

export const heapShareFolderButton = (props: { 'is-public': boolean }) =>
  mkHeapAttributes({
    id: 'library_share_folder_button',
    ...props,
  });

export const heapConfirmPublicizeFolderButton = () =>
  mkHeapAttributes({
    id: 'library_confirm_publicize_folder_button',
  });

export const heapCancelPublicizeFolderButton = () =>
  mkHeapAttributes({
    id: 'library_cancel_publicize_folder_button',
  });
