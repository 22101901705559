import { Nullable } from './utils/types';

import Immutable from 'immutable';
import moment from 'moment';

// Intl.NumberFormat is not supported on Safari desktop, and is not supported by
// any mobile browsers except for mobile Chrome.
// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat#Browser_compatibility
const nf = typeof Intl !== 'undefined' && 'NumberFormat' in Intl ? new Intl.NumberFormat() : null;
const formatFallbackRegexp = /(\d)(?=(\d{3})+$)/g;

// Matches start-of-line or whitespace, followed by one or more punctuation characters,
// followed by end-of-line or whitespace
const floatingPunctuationRegexp =
  /(^|\s)[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-./:;<=>?@[\]^_`{|}~]+($|\s)/g;

/**
 * Returns the provided number, formatted for presentation.
 *
 * @param  {number} n the value to format
 * @return {string} the formatted value
 */
export function format(n: number): string {
  return nf ? nf.format(n) : (n + '').replace(formatFallbackRegexp, '$1,');
}

export function removeNewLines(n: string): string {
  return n.replace(/(\r\n|\n|\r)/gm, ' ');
}

export function removeFloatingPunctuation(n: string): string {
  return n.replace(floatingPunctuationRegexp, '$1');
}

/**
 * Formats pagination text.
 * @param {Object} pagination - The pagination data.
 * @param {number} page - Page number.
 * @param {number} pageSize - Page size.
 * @param {number} totalHits - Total hits.
 * @returns {string} - The formatted text.
 *
 * @example
 * formatPaginationText({ page: 1, pageSize: 10, totalHits: 10 }) // 1-10
 * formatPaginationText({ page: 2, pageSize: 10, totalHits: 11 }) // 11-11
 * formatPaginationText({ page: 1, pageSize: 10, totalHits: 1 }) // 1-1
 */
export function formatPaginationText({
  page,
  pageSize,
  totalHits,
}: {
  page: number;
  pageSize: number;
  totalHits: number;
}): string {
  if (totalHits === 0) {
    return `${totalHits}`;
  }

  const offset = (page - 1) * pageSize;
  const start = offset + 1;
  const end = offset + pageSize;

  return end > totalHits ? `${start}-${totalHits}` : `${start}-${end}`;
}

/**
 * Returns a singular or pluralized string.
 * @param {number} n - value to determine whether to use singular or plural. May optionally be
 *                     prefaced in front of the phrase.
 * @param {string} singleWord - singular version of the string.
 * @param {string} pluralWord - plural version of the string.
 * @param {boolean} wordOnly - boolean value to determine if singular or plural phrase should be
 *                             prefaced by `n`.
 * @returns {string} the formatted string with the correct pluralization.
 */
export function pluralize(
  n: number,
  singleWord: string,
  pluralWord?: Nullable<string>,
  wordOnly?: Nullable<boolean>
): string {
  const num = format(n);
  if (n === 1) {
    // Sometimes we need pluralized words that aren't prefaced by the number,
    // which is inferred from context (e.g. "Authors Bob and Steve").
    return wordOnly ? singleWord : `${num} ${singleWord}`;
  } else {
    const plural = pluralWord || `${singleWord}s`;
    return wordOnly ? plural : `${num} ${plural}`;
  }
}

export function domainValForMousePos(scale: any, pos: number): any {
  const domain = scale.domain();
  const leftEdges = domain.map(_ => scale(_));
  const width = scale.bandwidth();
  let j = 0;
  while (pos > leftEdges[j] + width && j < domain.length - 1) {
    j++;
  }

  return domain[j];
}

/**
 * The year property is modeled differently throughout the codebase.  This method returns true it appears to be a
 * valid year value.
 *
 * @param {mixed} value the year value
 * @return {boolean} true if a valid year, false if not
 */
export function isValidYear(value: number | string | { text: string }): boolean {
  let yearValue = '';
  switch (typeof value) {
    // Sometimes year is a HighlightedField, meaning it'll be an object with a text property
    case 'object':
      if (value && value.text) {
        yearValue = value.text;
      }
      break;
    // It can also just be a number in the case of a citation
    case 'number':
      yearValue = '' + value;
      break;
    case 'string':
      yearValue = value;
      break;
  }
  return yearValue.length === 4 && !isNaN(parseInt(yearValue, 10));
}

/**
 * The pubDate string is expected to be in format of "dd Month yyyy", such as '1 December 2000`.
 * This method returns true if it appears to be a valid date value, else false.
 *
 * @param {object} value in the shape of { text: "dd Month yyyy" }
 * @return {boolean} true if the string is a valid date, else false.
 */
export function isValidPubDate(value?: { text: string }): boolean {
  if (!value) {
    return false;
  }

  let timeUnits: string[] = [];
  try {
    timeUnits = value.text.split(' ');
  } catch (e) {
    return false;
  }

  if (timeUnits.length === 3) {
    const goodYear = timeUnits[2].length === 4 && !isNaN(parseInt(timeUnits[2], 10));
    const goodMonth = isNaN(parseInt(timeUnits[1], 10));
    const goodDay = timeUnits[0].length <= 2 && !isNaN(parseInt(timeUnits[0], 10));
    return goodYear && goodMonth && goodDay;
  }
  return false;
}

// For now, we can expect that our strings only include question marks
// and quotes/apostrophes. Other characters will be caught by encodeURIComponent.
const PatternSpace = /\s+/g;
const ReplacementPatterns = /[“”‘’?]/g;
/**
 * Returns a lower case hash-friendly string with spaces replaced by dashes.
 *
 * @param {string} str The string to be formatted as hash-friendly
 *
 * @return {string} the encoded string truncated to five words, with spaces replaced
 * by dashes, quotes and apostrophes removes, and lower case
 */
export function toHashFriendlyString(str?: string): string | undefined {
  if (typeof str === 'string') {
    const formattedStr = str
      .replace(ReplacementPatterns, '')
      .toLowerCase()
      .split(PatternSpace)
      .join('-');
    return encodeURIComponent(formattedStr);
  }
}

const PATTERN_NON_WORD_CHAR = /\W/;
const PATTERN_WORD_CHAR = /\w/;
const ELLIPSIS = '…';

/**
 * Truncates the provided text such that no more than limit characters are rendered and adds an
 * ellipsis upon truncation by default.  If the text is shorter than the provided limit, the full
 * text is returned.
 *
 * @param {string} text The text to truncate.
 * @param {number} limit The maximum number of characters to show.
 * @param {boolean} whether to include an ellipsis after the truncation, defaults to true
 *
 * @return {string} the truncated text, or full text if it's shorter than the provided limit.
 */
export function truncateText(text: string, limit: number, withEllipsis: boolean = true): string {
  if (typeof limit !== 'number') {
    throw new Error('limit must be a number');
  }

  if (withEllipsis) {
    limit -= ELLIPSIS.length;
  }

  if (text.length > limit) {
    while (
      limit > 1 &&
      (!PATTERN_WORD_CHAR.test(text[limit - 1]) || !PATTERN_NON_WORD_CHAR.test(text[limit]))
    ) {
      limit -= 1;
    }
    if (limit === 1) {
      return text;
    } else {
      const truncatedText = text.substring(0, limit);
      return withEllipsis ? truncatedText + ELLIPSIS : truncatedText + '.';
    }
  } else {
    return text;
  }
}

/**
 * Strips a valid year (YYYY) from text within the range of 1800-2200
 *
 * @param {string} text The text to strip year from.
 *
 * @return {string} the text now missing its year, or the same text if it never contained a valid year
 */
export function stripYearFromText(text: string) {
  const yearRegex = /\b(18|19|20|21|22)\d{2}\b/g;
  const matches = yearRegex.exec(text);
  if (matches) {
    const venueString = text;
    const validMatches: string[] = [];
    matches.forEach(function (match) {
      if (isValidYear(match)) {
        validMatches.push(match);
      }
    });
    const re = new RegExp(validMatches.join('|'), 'gi');
    return venueString.replace(re, '').trim();
  } else {
    return text;
  }
}

/**
 * Produces a label string specifying how many documents there are that satisfy a given filter
 */
export function label(filter: string, count: number): string {
  if (isNaN(count)) {
    return filter;
  } else {
    return `${filter} (${format(count)})`;
  }
}

/**
 * A minimal escape function, which only escapes < and >.
 *
 * @param  {string} str
 * @return {string}
 */
export function escapeHtmlLite(str: string): string {
  return str ? str.replace(/</g, '&lt;').replace(/>/g, '&gt;') : str;
}

export const generateRequestId = (() => {
  let counter = 0;

  return () => {
    counter++;
    return counter;
  };
})();

// Converts SHOUTY_SNAKE enum values into something-for-css-classes.
export function enumToCSSClass(input: string): string {
  return input.toLowerCase().replace(/[_]/gi, '-');
}

export function flattenDeep<T>(arr: T[]): T[] {
  return arr.reduce((flat, xs) => {
    if (Array.isArray(xs)) {
      return flat.concat(flattenDeep(xs));
    } else {
      return flat.concat(xs);
    }
  }, [] as T[]);
}

export function toTimecode(seconds: number, format: string = 'H:mm:ss'): string {
  return moment.utc(seconds * 1000).format(format);
}

// Convert a timestamp (200 seconds) into a human readable timecode (ex "3:20")
export function toHumanTimecode(seconds: number): string {
  return seconds >= 3600 // 1 hour
    ? toTimecode(seconds, 'H:mm:ss')
    : toTimecode(seconds, 'm:ss');
}

// Pack items into bins based on space
export function binPacker<T>(
  { maxSize, maintainSorting }: { maxSize: number; maintainSorting?: boolean },

  items: { size: number; item: T }[]
) {
  const binItems: { size: number; item: T }[][] = [];
  if (maintainSorting) {
    // Add to the current bin until full, then start a new bin
    let i = 0;
    let binSize = 0;
    for (const { size, item } of items) {
      if (binSize + size <= maxSize) {
        binSize += size;
      } else {
        i += 1;
        binSize = size;
      }
      if (!Array.isArray(binItems[i])) {
        binItems[i] = [];
      }
      binItems[i].push({ size, item });
    }
  } else {
    // Find the first bin with room, otherwise start a new bin
    const binSizes: number[] = [];
    for (const { size, item } of items) {
      let i = 0;
      while (maxSize < binSizes[i] + size) {
        i += 1;
      }
      binSizes[i] = i in binSizes ? binSizes[i] + size : size;
      if (!Array.isArray(binItems[i])) {
        binItems[i] = [];
      }
      binItems[i].push({ size, item });
    }
  }
  return binItems;
}

// Cache the results of a function based on it's arguments
// eslint-disable-next-line @typescript-eslint/ban-types
export function memoize<T extends Function>(fn: T): T {
  let cache = Immutable.Map<Immutable.List<any>, T>();
  // @ts-expect-error -- Typing here is so complicated that the code becomes unreadable
  return (...args) => {
    const key = Immutable.List(args);
    if (cache.has(key)) {
      return cache.get(key);
    }
    const result = fn(...args);
    cache = cache.set(key, result);
    return result;
  };
}

/**
 * Takes in any value and returns a string representation of it.
 * React component keys must be strings or numbers, and this ensures we get a string.
 * @param key The value to use as the component key
 * @returns String representation of 'key'
 */
export function stringifyReactKey(key: any): string {
  if (typeof key === 'string') {
    return key;
  }
  return JSON.stringify(key);
}
