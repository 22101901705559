import softError from '@/utils/softError';

export type EventTargetValue = string;
export type NestedEventTarget = { [key: string]: EventTargetValue | NestedEventTarget };

const EventTarget = {
  UserAlerts: {
    CREATE_SUCCESS: 'userAlerts.createSuccess',
    CREATE_FAILURE: 'userAlerts.createFailure',
    TOGGLE_SUCCESS: 'userAlerts.toggleSuccess',
    TOGGLE_FAILURE: 'userAlerts.toggleFailure',
    DELETE_SUCCESS: 'userAlerts.deleteSuccess',
    DELETE_FAILURE: 'userAlerts.deleteFailure',
    SET_FREQUENCY: 'userAlerts.setFrequency',
  },

  AccountSettings: {
    CONTACT_EMAIL_CONFLICT: 'contactSettings.contactEmailConflict',
    NO_EMAIL_ERROR: 'contactSettings.noEmailError',
    CHANGE_SUBSCRIPTION: 'contactSettings.changeSubscription',
    CHANGE_CONTACT_EMAIL: 'contactSettings.changeContactEmail',
    UNSUBSCRIBE_ALL: 'contactSettings.unsubscribeAll',
  },

  AdaptiveFeed: {
    ...paperObjectEventsAtLocation('adaptiveFeed.result'),
    ...paginationAtLocation('adaptiveFeed.result'),
  },

  AuthorCard: {
    AUTHOR_CARD_VIEW: 'authorCard.view',
  },

  AuthorClaimModeration: {
    APPROVE_CLAIM: 'authorClaimModeration.approveClaim',
    DENY_CLAIM: 'authorClaimModeration.denyClaim',
  },

  AuthorHomePage: {
    Publications: {
      ...paperObjectEventsAtLocation('authorHomePage.publications'),
      ...paginationAtLocation('authorHomePage.publications'),
      ...sortEventAtLocation('authorHomePage.publications'),
      SEARCH: 'authorHomePage.publications.search',
      YEAR_SLIDER: 'authorHomePage.publications.slider',
    },
    DENSE_PAPER_VIEW: 'authorHomePage.paperViewDensity.dense',
    NORMAL_PAPER_VIEW: 'authorHomePage.paperViewDensity.normal',
    IS_CLAIMED_BY_SIGNED_IN_USER_IMPRESSION: 'authorHomePage.isClaimedBySignedInUser',
    CitingAuthors: {
      AuthorRow: {
        ...authorRowEventsAtLocation('authorHomePage.citingAuthors.authorRow'),
      },
    },
    ReferencedAuthors: {
      AuthorRow: {
        ...authorRowEventsAtLocation('authorHomePage.referencedAuthors.authorRow'),
      },
    },
    CoAuthors: {
      AuthorRow: {
        ...authorRowEventsAtLocation('authorHomePage.coAuthors.authorRow'),
      },
    },
  },

  AuthorSuggestions: {
    SUBMIT: 'authorSuggestions.submit',
  },

  AUTOCOMPLETE_ENTRY: 'autocompleteEntry',
  AUTOCOMPLETE_MENU: 'autocompleteMenu',
  DemographicModal: {
    DEMOGRAPHIC_MODAL_IMPRESSION: 'demographicImpression',
    EMAIL_CONTACT_CONFLICT: 'demographicEmailConflict',
    DEMOGRAPHIC_SUBMIT: 'demographicSubmit',
  },
  EXAMPLE_QUERY: 'exampleQuery',
  Entity: {
    PREVIEW: 'entity.preview',
  },
  FACET: 'facet',
  FACET_CONTAINER: 'facetContainer',
  FAQ: 'faq',
  FACET_HAS_PDF: 'facetHasPdf',

  Feedback: {
    SENT: 'feedback.sent',
    SHOW: 'feedback.show',
  },

  FIGURE: 'figure',
  FILTER_BY: 'filterBy',
  GOOGLE_ONE_TAP: 'google-one-tap',
  ENTITY: 'entity',

  Home: {
    JOB_LINK: 'home.jobLink',
  },

  Library: {
    ...paperObjectEventsAtLocation('library'),
    ...sortEventAtLocation('library'),
    Tags: {
      CREATE_SUCCESS: 'library.tags.createSuccess',
      CREATE_FAILURE: 'library.tags.createFailure',
      DELETE_SUCCESS: 'library.tags.deleteSuccess',
      DELETE_FAILURE: 'library.tags.deleteFailure',
      RENAME_SUCCESS: 'library.tags.renameSuccess',
      RENAME_FAILURE: 'library.tags.renameFailure',
      MANAGE_SUCCESS: 'library.tags.manageSuccess',
      MANAGE_FAILURE: 'library.tags.manageFailure',
    },
    Shelf: {
      SAVE_TO_LIBRARY_VIEW: 'library.shelf.saveToLibrary.view',
      ORGANIZE_PAPERS_VIEW: 'library.shelf.organizePapers.view',
      FOLDER_SETTINGS_VIEW: 'library.shelf.folderSettings.view',
      RECOMMENDATIONS_SETTINGS_VIEW: 'library.shelf.recommendationsSettings.view',
    },
    Page: {
      ALL_ENTRIES_VIEW: 'library.page.allEntries.view',
      FOLDER_VIEW: 'library.page.folder.view',
      UNSORTED_ENTRIES_VIEW: 'library.page.unsortedEntries.view',
    },
  },

  ManageAccount: {
    MANAGE_ACCOUNT_SUBMIT: 'manageAccountSubmit',
  },

  PAGINATION: 'pagination',

  PaperDetail: {
    Abstract: {
      TOGGLE_OPEN: 'paperDetail.abstract.highlight.toggleOpen',
      TOGGLE_CLOSE: 'paperDetail.abstract.highlight.toggleClose',
      TOGGLE_BEGINNING: 'paperDetail.abstract.highlight.toggleBeginning',
      HIGHLIGHT_TOGGLE_RESULTS_ON: 'paperDetail.abstract.highlight.toggle.results.on',
      HIGHLIGHT_TOGGLE_RESULTS_OFF: 'paperDetail.abstract.highlight.toggle.results.off',
      HIGHLIGHT_TOGGLE_METHODS_ON: 'paperDetail.abstract.highlight.toggle.methods.on',
      HIGHLIGHT_TOGGLE_METHODS_OFF: 'paperDetail.abstract.highlight.toggle.methods.off',
      KEY_RESULT_HOVER: 'paperDetail.abstract.highlight.keyResult.hover',
    },
    Tldr: {
      MORE: 'paperDetail.tldr.more',
      LESS: 'paperDetail.tldr.less',
      DROPDOWN: 'paperDetail.tldr.dropdown',
    },
    Header: {
      ...paperObjectEventsAtLocation('paperDetail'),
    },
    References: {
      ...paperObjectEventsAtLocation('paperDetail.references'),
      ...sortEventAtLocation('paperDetail.references'),
      ...paginationAtLocation('paperDetail.references'),
      SEARCH: 'paperDetail.references.search',
    },
    Citations: {
      ...filterEventAtLocation('paperDetail.citations'),
      ...paperObjectEventsAtLocation('paperDetail.citations'),
      ...sortEventAtLocation('paperDetail.citations'),
      ...paginationAtLocation('paperDetail.citations'),
      SEARCH: 'paperDetail.citations.search',
      YEAR_SLIDER: 'paperDetail.citations.slider',
    },
    Topics: {
      IMPRESSION: 'paperDetail.topics.impression',
    },
    QA: {
      IMPRESSION: 'paperDetail.labs.qa',
    },
    RelatedPapers: {
      ...paperObjectEventsAtLocation('paperDetail.relatedPapers'),
      SEARCH_SIMILAR: 'paperDetail.relatedPapers.searchSimilar',
    },
    Cite: {
      CITATION_TEXT: 'paperDetail.cite.text',
      COPY_BUTTON: 'paperDetail.cite.copy',
      SELECT_STYLE: 'paperDetail.cite.selectStyle',
      DOWNLOAD_BUTTON: 'paperDetail.cite.download',
    },
    CueBadge: {
      CUE_BADGE_VIEW: 'paperDetail.personalizedCueBadge.view',
      CUE_PAPERS_SHELF_VIEW: 'paperDetail.personalizedCueBadge.cuePapersShelf.view',
      HAD_CUE_BADGE_NO_DISPLAY: 'paperDetail.personalizatedCueBadge.hadCueBadgeNoDisplay',
    },
    IMPRESSION: 'pdp.impression',
    SCORECARD_IMPRESSION: 'paperDetail.scorecardImpression',
    SEARCH_CALLOUT: 'paperDetail.searchCallout',
    SLIDES: 'paperDetail.slides',
    ALTERNATE_PAPER_LINKS: 'paperDetail.alternatePaperLinks',
    Reader: {
      Acronyms: {
        ON_CLICK: 'reader.acronyms.onclick',
        ON_LOAD: 'reader.acronyms.onload',
        PAGE_IMPRESSION: 'reader.acronyms.pageimpression',
      },
      Button: {
        IMPRESSION: 'paperDetail.reader',
        CLICK: 'paperDetail.reader.click',
      },
      Outline: {
        IMPRESSION: 'reader.tocImpression',
      },
      Skimming: {
        IMPRESSION: 'reader.skimming',
        PAGE_HIGHLIGHT: 'reader.skimming.pageHighlight',
        PAGE_FLAG: 'reader.skimming.pageFlag',
        SNIPPET: 'reader.skimming.snippet',
        SETTINGS: 'reader.skimming.settings',
      },
      Terms: {
        ON_CLICK: 'reader.terms.onclick',
        ON_LOAD: 'reader.terms.onload',
        PAGE_IMPRESSION: 'reader.terms.pageimpression',
      },
      CITATION_CARD: {
        ...paperObjectEventsAtLocation('readerCitationCard'),
      },
      Page: {
        IMPRESSION: 'reader.pageImpression',
      },
    },
    SCROLL_LANDMARKS: 'paperDetail.scrollLandmarks',
    ORDERED_PAPER_LIST_RESULT: 'paperDetail.orderedListResult',
    SIMILAR_PAPERS_LIST: 'paperDetail.similarPapersList',
    SIMILAR_PAPERS_SWAP_EXPERIMENT: 'paperDetail.similarPapersSwapExperiment',
  },

  PaperObject: {
    ...paperObjectEventsAtLocation('paperObject'),
  },

  CITATION_RESULT_PAPERS: 'citationResultPapers',

  Research: {
    Homepage: {
      AlertsCard: {
        ...paperObjectEventsAtLocation('research.homepage.alertsCard'),
      },
      ImpactCard: {
        ...paperObjectEventsAtLocation('research.homepage.impactCard'),
      },
      RecommendationsCard: {
        ...paperObjectEventsAtLocation('research.homepage.recommendationsCard'),
      },
    },
  },

  RESULT_PAPERS: 'resultPapers',
  SEARCH_FORM: 'searchForm',
  SERP_RESULT: 'serpResult',

  Serp: {
    ...paperObjectEventsAtLocation('serp.result'),
    ...paginationAtLocation('serp'),
    ...sortEventAtLocation('serp'),

    YEAR_BUCKET: 'serp.filterByYear.yearBucket',
    YEAR_SLIDER: 'serp.filterByYear.slider',

    MATCHED_AUTHOR_LIST: 'serp.matchedAuthorList',
    MATCHED_AUTHOR_LIST_TOGGLE: 'serp.matchedAuthorList.toggle',
    MATCHED_AUTHOR: 'serp.matchedAuthorList.matchedAuthor',

    NO_PAPERS_FOUND: 'serp.noPapersFound',
    SUGGESTED_QUERY: 'serp.suggestedQuery',

    MATCHED_ENTITY_CARD: 'serp.matchedEntityCard',
    SWAP: 'serp.swap',

    DENSE_PAPER_VIEW: 'serp.paperViewDensity.dense',
    NORMAL_PAPER_VIEW: 'serp.paperViewDensity.normal',

    CueBadge: {
      CUE_BADGE_VIEW: 'serp.personalizedCueBadge.view',
      CUE_PAPERS_SHELF_VIEW: 'serp.personalizedCueBadge.cuePapersShelf.view',
    },
  },

  VenuePage: {
    ...filterEventAtLocation('venuePage'),
    ...paperObjectEventsAtLocation('venuePage.result'),
    ...paginationAtLocation('venuePage'),
    ...sortEventAtLocation('venuePage'),

    YEAR_BUCKET: 'venuePage.filterByYear.yearBucket',
    YEAR_SLIDER: 'venuePage.filterByYear.slider',

    DENSE_PAPER_VIEW: 'venuePage.paperViewDensity.dense',
    NORMAL_PAPER_VIEW: 'venuePage.paperViewDensity.normal',
  },

  SHARE: 'share',
  SIGN_UP: 'loginModal.signUp',
  SIGN_IN: 'loginModal.signIn',
  SORT_BY: 'sortBy',
  STATS_TAB: 'statsTab',
  STICKY_NAV: 'stickyNav',
  SUBSCRIBE_SUBMIT: 'subscribeSubmit',
  WEBSHARE: 'webshare',

  isSerp(location: NestedEventTarget): boolean {
    if (location && typeof location.TITLE === 'string') {
      return location.TITLE.slice(0, 4) === 'serp';
    }
    return false;
  },

  /**
   * Returns the value found by recursively following a path of keys.
   *
   * @param {object} obj - The object to navigate.
   * @param {(...string|...number)} keys - The keys to follow.
   *
   * @throws {Error} Will throw an error for invalid parameters or if the key doesn't exist in the
   * provided object.
   *
   * @returns {string|number} - The value found following the key path.
   */
  getIn(obj: NestedEventTarget, ...keys: string[]): EventTargetValue {
    if (!obj || typeof obj !== 'object') {
      throw new Error('First argument must be an object.');
    }
    if (keys.length === 0) {
      throw new Error('Must provide at least one key.');
    }

    const currKey = keys[0];
    const next = obj[currKey];

    if (next) {
      if (typeof next === 'object') {
        // We must go deeper...
        return EventTarget.getIn(next, ...keys.slice(1));
      } else {
        // Found the correct path.
        return next;
      }
    } else {
      // Incorrect path. Complain softly.
      softError('eventTarget.missing', 'The current step on the event target is missing.', {
        obj,
        keys,
      });
      return '';
    }
  },
};

function paperObjectEventsAtLocation(location: string) {
  return {
    RESULT: `${location}.result`,
    TITLE: `${location}.title`,
    AUTHOR: `${location}.author`,
    VENUE: `${location}.venue`,
    STAT: `${location}.stat`,
    Abstract: {
      MORE: `${location}.abstract.more`,
      LESS: `${location}.abstract.less`,
    },
    Tldr: {
      MORE: `${location}.tldr.more`,
      LESS: `${location}.tldr.less`,
      DROPDOWN: `${location}.tldr.dropdown`,
    },
    Action: {
      CITE: `${location}.action.cite`,
      MORE: `${location}.action.more`,
      LESS: `${location}.action.less`,
      RELATED_PAPERS: `${location}.action.relatedPapers`,
      READING_LIST: `${location}.action.readingList`,
      PAPER_LINK: `${location}.action.paperLink`,
    },
  };
}

function authorRowEventsAtLocation(location: string) {
  return {
    AUTHOR: `${location}.author`,
  };
}

function filterEventAtLocation(location: string) {
  return {
    FILTER_BY: `${location}.filterBy`,
  };
}

function sortEventAtLocation(location: string) {
  return {
    SORT_BY: `${location}.sortBy`,
  };
}

function paginationAtLocation(location: string) {
  return {
    PAGINATION: `${location}.pagination`,
  };
}

export default EventTarget;
