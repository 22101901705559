import React from 'react';

export default function IconFeedList(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 40 32">
      <path
        d="M6.75,4.5V9c0,0.63-0.51,1.14-1.14,1.14h-4.5C0.51,10.11,0,9.6,0,9V4.5c0-0.63,0.51-1.14,1.14-1.14h4.5
        C6.24,3.36,6.75,3.87,6.75,4.5z M6.75,15.72v4.5c0,0.63-0.51,1.14-1.11,1.14h-4.5C0.51,21.36,0,20.85,0,20.22v-4.5
        c0-0.63,0.51-1.14,1.14-1.14h4.5C6.24,14.61,6.75,15.12,6.75,15.72z M6.75,27v4.5c0,0.63-0.51,1.14-1.14,1.14h-4.5
        C0.51,32.61,0,32.1,0,31.5V27c0-0.63,0.51-1.14,1.14-1.14h4.5C6.24,25.86,6.75,26.37,6.75,27z M36,5.61v2.25
        C36,8.49,35.49,9,34.86,9h-22.5c-0.63,0-1.14-0.51-1.14-1.14V5.61c0-0.63,0.51-1.14,1.14-1.14h22.5C35.49,4.5,36,4.98,36,5.61z
        M36,16.86v2.25c0,0.63-0.51,1.14-1.14,1.14h-22.5c-0.63,0-1.14-0.51-1.14-1.14v-2.25c0-0.63,0.51-1.14,1.14-1.14h22.5
        C35.49,15.72,36,16.23,36,16.86z M36,28.11v2.25c0,0.63-0.51,1.14-1.14,1.14h-22.5c-0.6,0-1.11-0.51-1.11-1.14v-2.25
        c0-0.63,0.51-1.14,1.14-1.14h22.5C35.49,27,36,27.48,36,28.11z"
      />
    </symbol>
  );
}
