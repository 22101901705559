import {
  AuthoredLibraryPaperCue,
  CitedByLibraryPaperCue,
  CitesLibraryPaperCue,
  CitesYourPaperCue,
  CoauthorCue,
  CueType,
  YouCitedAuthorCue,
} from '@/constants/CueType';
import { getPluralizedString, getString } from '@/content/i18n';

import Immutable from 'immutable';

import type { CueDataWrapperRecord } from '@/models/CueDataWrapper';
import type { Nullable } from './types';

export type CueText = { cueLabel: string; cueDescription: string };

const PAPER_CUES_PRIORITY = Immutable.List<CueType>([
  CitesYourPaperCue,
  CitedByLibraryPaperCue,
  CitesLibraryPaperCue,
]);

export const AUTHOR_CUES_PRIORITY = Immutable.List<CueType>([
  CoauthorCue,
  YouCitedAuthorCue,
  AuthoredLibraryPaperCue,
]);

export function getCueText(cueType: CueType, count: number): Nullable<CueText> {
  switch (cueType) {
    case AuthoredLibraryPaperCue:
      return {
        cueLabel: getPluralizedString(_ => _.personalizedCue.authoredLibraryPaperCue.label, count),
        cueDescription: getString(_ => _.personalizedCue.authoredLibraryPaperCue.cueDescription),
      };
    case CitedByLibraryPaperCue:
      return {
        cueLabel: getPluralizedString(_ => _.personalizedCue.citedByLibraryPaperCue.label, count),
        cueDescription: getString(_ => _.personalizedCue.citedByLibraryPaperCue.cueDescription),
      };
    case CitesLibraryPaperCue:
      return {
        cueLabel: getPluralizedString(_ => _.personalizedCue.citesLibraryPaperCue.label, count),
        cueDescription: getString(_ => _.personalizedCue.citesLibraryPaperCue.cueDescription),
      };
    case CitesYourPaperCue:
      return {
        cueLabel: getPluralizedString(_ => _.personalizedCue.citesYourPaperCue.label, count),
        cueDescription: getString(_ => _.personalizedCue.citesYourPaperCue.cueDescription),
      };
    case CoauthorCue:
      return {
        cueLabel: getPluralizedString(_ => _.personalizedCue.coauthorCue.label, count),
        cueDescription: getString(_ => _.personalizedCue.coauthorCue.cueDescription),
      };
    case YouCitedAuthorCue:
      return {
        cueLabel: getPluralizedString(_ => _.personalizedCue.youCitedAuthorCue.label, count),
        cueDescription: getString(_ => _.personalizedCue.youCitedAuthorCue.cueDescription),
      };
    default: {
      return null;
    }
  }
}

export function getPrioritizedCue(
  cues: Immutable.Map<CueType, Immutable.List<CueDataWrapperRecord>>
): Nullable<CueType> {
  // TODO: We will need to revisit if latency gets too large
  return PAPER_CUES_PRIORITY.find(cue => cues.has(cue)) || null;
}
