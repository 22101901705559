import { ReactNodeish } from '@/utils/types';

import classnames from 'classnames';
import React, { ComponentProps } from 'react';

type CLLayoutProps = {
  useDefaultBreakpoints?: boolean;
} & ComponentProps<'div'>;

export default function CLLayout({
  children,
  className,
  useDefaultBreakpoints,
  ...otherProps
}: CLLayoutProps): ReactNodeish {
  return (
    <div
      {...otherProps}
      className={classnames(
        {
          'cl-layout': true,
          'cl-layout--default-breakpoints': useDefaultBreakpoints,
        },
        className
      )}>
      {children}
    </div>
  );
}

CLLayout.defaultProps = {
  useDefaultBreakpoints: true,
};
