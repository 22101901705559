import { addHeapProps } from '@/components/util/HeapTracking';
import { AlertAction } from '@/constants/Alert';
import { getString } from '@/content/i18n';
import { HeapProps } from '@/analytics/heapUtils';
import { Nullable, ReactNodeish, ValueOf } from '@/utils/types';
import CLIconButton, { TYPE } from '@/components/library/button/CLIconButton';
import Icon from '@/components/shared/icon/Icon';

import classnames from 'classnames';
import React from 'react';

const PRIMARY = TYPE.PRIMARY;
const TERTIARY = TYPE.TERTIARY;

export const ALERT_BUTTON_TYPE = Object.freeze({
  PRIMARY,
  TERTIARY,
});

type Props = {
  action: AlertAction;
  queryType: string;
  className?: Nullable<string>;
  heapProps?: Nullable<HeapProps>;
  onClick?: Nullable<() => any>;
  type?: Nullable<AlertButtonType>;
  label?: Nullable<string>;
};

export type AlertButtonType = ValueOf<typeof ALERT_BUTTON_TYPE>;

/* Use CLPaperActionAlert for the business logic and display. This component is just the display */
export default function CLPaperAlertButton({
  action,
  queryType,
  onClick,
  className,
  heapProps,
  type,
  label,
}: Props): ReactNodeish {
  const buttonLabel = label ? label : getString(_ => _.alerts[`${action}Alerts`][queryType]);
  const ariaProps = {
    'aria-label': getString(_ => _.alerts[`${action}Alerts`].paperAlertAriaLabel),
  };

  return (
    <CLIconButton
      ariaProps={ariaProps}
      type={type}
      label={buttonLabel}
      icon={<Icon icon="fa-bell" height="11" width="11" />}
      onClick={onClick}
      className={classnames(
        {
          'cl-paper-action__button': true,
          [`cl-paper-alert--type-${type || ''}`]: true,
        },
        className
      )}
      {...(heapProps ? addHeapProps(heapProps) : {})}
    />
  );
}

CLPaperAlertButton.defaultProps = {
  type: TERTIARY,
};
