/* eslint-disable import/named */

import { isBrowser } from './env';
import { isString, Nullable } from './types';

import { getRouteNameForPath, RouteName } from '@/router/Routes';

import {
  createBrowserHistory,
  createMemoryHistory,
  History,
  Action as HistoryAction,
  Location,
  LocationDescriptorObject,
} from 'history';
import qs from 'qs';
import url from 'url';

import type { Request } from 'express';

export type UnlistenCallback = () => void;

/**
 * S2History is a proxy for the history npm module, used by ReactRouter V4. This
 * will make it easier to refactor our code in the future if we choose to upgrade.
 */
export default class S2History {
  _history: History;

  constructor(clientRequest?: Request) {
    if (clientRequest) {
      this._history = createMemoryHistory({
        initialEntries: [clientRequest.originalUrl],
      });
    } else if (typeof window !== 'undefined' && typeof window.history !== 'undefined') {
      this._history = createBrowserHistory();
    } else {
      this._history = createMemoryHistory();
    }
  }

  get originalHistory(): History {
    return this._history;
  }

  get action(): HistoryAction {
    return this._history.action;
  }

  get length(): number {
    return this._history.length;
  }

  get location(): Location {
    return this._history.location;
  }

  createHref(location: LocationDescriptorObject): string {
    return this._history.createHref(location);
  }

  push(path: string | LocationDescriptorObject, state?: any): void {
    if (isString(path)) {
      this._history.push(path, state);
    } else {
      this._history.push(path);
    }
  }

  replace(path: string | LocationDescriptorObject, state?: any): void {
    if (isString(path)) {
      this._history.replace(path, state);
    } else {
      this._history.replace(path);
    }
  }

  go(n: number): void {
    return this._history.go(n);
  }

  goBack(): void {
    return this._history.goBack();
  }

  goForward(): void {
    return this._history.goForward();
  }

  listen(
    // eslint-disable-next-line no-unused-vars
    cb: (location: LocationDescriptorObject, action: HistoryAction) => void
  ): UnlistenCallback {
    return this._history.listen(cb);
  }

  // Non-standard functions

  get locationQueryParams(): object {
    const search = this.location.search.replace(/^\?/, '');
    if (!search) {
      return {};
    }
    const query = qs.parse(search);
    return query;
  }

  // Figure out what route the page is currently on from the .location prop
  getLocationRouteName(): Nullable<RouteName> {
    return getRouteNameForPath(this.location.pathname);
  }

  // Figure out the relative URL for the currently loaded page
  getRelativeHref(): string {
    const { pathname, search, hash } = this.location;
    const relativeHref = url.format({
      pathname,
      search,
      hash,
    });
    return relativeHref;
  }
}

// Server handles several requests in parallel, so it needs a new instance for each request
export function getServerS2History(clientRequest: Request): S2History {
  return new S2History(clientRequest);
}

// Browser handles only a single request, so we can cache the instance
let inst: Nullable<S2History> = null;
export function getBrowserS2History(): Nullable<S2History> {
  if (!isBrowser()) {
    return null;
  }
  if (!inst) {
    inst = new S2History();
  }
  return inst;
}
