import ResearchAdContainer from './ResearchAdContainer';

import { getString } from '@/content/i18n';
import { SEARCH_LOCATIONS } from '@/constants/SearchLocations';
import Icon from '@/components/shared/icon/Icon';

import React from 'react';

const SearchBarRecommendationsMessage = ({ location }) => {
  const msg = getRecommendationsMessage(location);
  if (!msg) {
    return null;
  }

  const isResearchFTUEPage = location == SEARCH_LOCATIONS.researchFtue;

  return (
    <React.Fragment>
      <div className="search-bar-recommendations-message__arrow" />
      <ResearchAdContainer
        headerText={msg.searchBarHeaderText}
        subText={msg.searchBarSubText}
        className="search-bar-recommendations-message"
        isResearchFTUE={isResearchFTUEPage}>
        {isResearchFTUEPage && (
          <div className="research-ad-container__content__icon-container">
            <Icon
              className="research-ad-container__content__icon"
              icon="fa-bookmark"
              height="15"
              width="11"
            />
          </div>
        )}
      </ResearchAdContainer>
    </React.Fragment>
  );
};

export default SearchBarRecommendationsMessage;

export function getRecommendationsMessage(location) {
  switch (location) {
    case SEARCH_LOCATIONS.ftue:
      return {
        searchBarHeaderText: getString(
          _ => _.research.searchBarPopover.fromRatedPapersTab.mainText
        ),
        searchBarSubText: getString(_ => _.research.searchBarPopover.fromRatedPapersTab.subText),
      };
    case SEARCH_LOCATIONS.mobileNavMenu:
    case SEARCH_LOCATIONS.blankRecs:
    case SEARCH_LOCATIONS.sideBar:
      return {
        searchBarHeaderText: getString(_ => _.research.searchBarPopover.fromSideBar.mainText),
        searchBarSubText: null,
      };
    case SEARCH_LOCATIONS.ratedPapersTab:
      return {
        searchBarHeaderText: getString(
          _ => _.research.searchBarPopover.fromRatedPapersTab.mainText
        ),
        searchBarSubText: getString(_ => _.research.searchBarPopover.fromRatedPapersTab.subText),
      };
    case SEARCH_LOCATIONS.researchFtue:
      return {
        searchBarHeaderText: getString(_ => _.research.onboardingHomepage.searchBarMessage),
        searchBarSubText: null,
      };
  }
  return null;
}
