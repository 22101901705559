import BaseStore from './BaseStore';

import constants from '@/constants';
import Enrollment, { optEnrollmentValue } from '@/weblab/Enrollment';
import S2Dispatcher from '@/utils/S2Dispatcher';

import Immutable from 'immutable';

import type { ApiResponse, GetAllEnrollmentsResponseBody } from '@/api/ApiResponse';
import type { Nullable } from '@/utils/types';

export type EnrollmentMetadata = {
  enrollmentKey: string;
  userCount: number;
};

export default class EnrollmentsStore extends BaseStore {
  #enrollmentsByKey: Immutable.Map<string, EnrollmentMetadata>;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#enrollmentsByKey = Immutable.Map();

    dispatcher.register(payload => {
      switch (payload.actionType) {
        // Parse them sample queries when the associated API request is completed
        case constants.actions.API_REQUEST_COMPLETE: {
          const apiPayload = payload as ApiResponse;
          switch (apiPayload.requestType) {
            case constants.requestTypes.GET_ALL_ENROLLMENTS: {
              return this.#handleGetAllEnrollmentsComplete(apiPayload);
            }
          }
        }
      }
    });
  }

  #handleGetAllEnrollmentsComplete(payload: ApiResponse<GetAllEnrollmentsResponseBody>): void {
    const { enrollments } = payload.resultData;

    this.#enrollmentsByKey = Immutable.Map();

    // Add empty enrollments from code (to be overwritten later)
    for (const enrollmentKey of Object.values(Enrollment)) {
      const metadata: EnrollmentMetadata = {
        enrollmentKey,
        userCount: 0,
      };
      Object.freeze(metadata);
      this.#enrollmentsByKey = this.#enrollmentsByKey.set(metadata.enrollmentKey, metadata);
    }

    // Add enrollments from server (which may not be in code)
    for (const enrollment of enrollments) {
      const metadata: EnrollmentMetadata = {
        enrollmentKey: enrollment.key,
        userCount: enrollment.userCount,
      };
      Object.freeze(metadata);
      this.#enrollmentsByKey = this.#enrollmentsByKey.set(metadata.enrollmentKey, metadata);
    }

    if (!this.#enrollmentsByKey.isEmpty()) {
      this.emitChange();
    }
  }

  // Keys which exist in the DB
  getAllEnrollmentKeys(): Immutable.List<string> {
    return this.#enrollmentsByKey.keySeq().toList().sort();
  }

  // Keys which exist in both the DB and in the code
  getCodeEnrollmentKeys(): Immutable.List<string> {
    return this.getAllEnrollmentKeys().filter(key => !!optEnrollmentValue(key));
  }

  // Keys which exist in the DB, but not in code
  getUnknownEnrollmentKeys(): Immutable.List<string> {
    const codeKeysSet = this.getCodeEnrollmentKeys().toSet();
    return this.getAllEnrollmentKeys().filter(key => !codeKeysSet.contains(key));
  }

  // Metadata about an enrollment
  getMetadata(enrollmentKey: string): Nullable<EnrollmentMetadata> {
    return this.#enrollmentsByKey.get(enrollmentKey) || null;
  }
}
