import { LibraryEntryAnnotationState } from './LibraryEntryAnnotationState';
import { LibraryEntrySourceType } from './LibraryEntrySourceType';

import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

export type AddLibraryEntryBulkRequestFromJS = {
  paperId: string;
  paperTitle: string;
  folderIds?: Nullable<number[]>;
  annotationState?: Nullable<LibraryEntryAnnotationState>;
  sourceType?: Nullable<LibraryEntrySourceType>;
};

type Props = {
  paperId: string;
  paperTitle: string;
  folderIds: Immutable.List<number>;
  annotationState: Nullable<LibraryEntryAnnotationState>;
  sourceType: Nullable<LibraryEntrySourceType>;
};

export const defaultProps: Props = {
  paperId: '',
  paperTitle: '',
  folderIds: Immutable.List(),
  annotationState: null,
  sourceType: null,
};

export const AddLibraryEntryBulkRequestRecordFactory = Immutable.Record(defaultProps);
export type AddLibraryEntryBulkRequestRecord = Immutable.RecordOf<Props>;

export function getAddLibraryEntryBulkRequestFromJS(
  args: AddLibraryEntryBulkRequestFromJS
): AddLibraryEntryBulkRequestRecord {
  return AddLibraryEntryBulkRequestRecordFactory({
    ...args,
    folderIds: Immutable.List(args.folderIds || []),
  });
}
