import CLPaperStatsBase, { canRender as canRenderStats } from './CLPaperStatsBase';
import MockPaper from '../../../../../../test/utils/MockPaper';

import React from 'react';

export default function CLPaperStats({ paper, ...props }) {
  if (!canRenderStats(paper)) {
    return null;
  }

  return <CLPaperStatsBase paper={paper} {...props} isInteractive={true} />;
}

CLPaperStats.defaultProps = {
  totalCitations: true,
  hic: false,
  badges: true,
  externalStats: false,
};

// TODO(#21359): Split this into a separate file
const PAPER = MockPaper({});

export const exampleConfig = {
  getComponent: async () => CLPaperStats,
  fields: [
    {
      name: 'className',
      desc: 'HTML classes to be added to the component',
      value: {
        type: 'text',
        default: '',
      },
    },
    {
      name: 'totalCitations',
      desc: 'Display total citations',
      value: {
        type: 'boolean',
        default: true,
      },
    },
    {
      name: 'hic',
      desc: 'Display influential citation count',
      value: {
        type: 'boolean',
        default: true,
      },
    },
  ],

  examples: [
    {
      title: 'Paper with all stats',
      desc: '',
      props: {
        paper: PAPER,
      },
      render: comp => <div style={{ width: '300px', padding: '16px' }}>{comp}</div>,
    },
  ],

  events: {},
};
