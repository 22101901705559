import { isPaperLiteRecord, PaperishRecord } from '@/models/Paper';
import { RouteName } from '@/router/Routes';
import { stripYearFromText, truncateText } from '@/util';
import Constants from '@/constants';

export const extractVenueInformation = (
  paper: PaperishRecord,
  shouldStripYearFromVenue?: boolean,
  maxVenueLength: number = Constants.data.MAX_VENUE_LENGTH
): {
  venueText: string;
  venueRouteObject: { to: RouteName; query: { name: string } };
  isVenueTextTruncated: boolean;
  shouldLinkToVenuePage: boolean;
} => {
  const fullVenueText = isPaperLiteRecord(paper) ? paper.venue : paper.venue.text;

  const venueTextWithYearStripped = stripYearFromText(fullVenueText);

  const venueTextToTruncate = shouldStripYearFromVenue ? venueTextWithYearStripped : fullVenueText;

  const truncatedVenueText = truncateText(venueTextToTruncate, maxVenueLength);
  return {
    venueText: truncatedVenueText,
    venueRouteObject: { to: 'VENUE', query: { name: venueTextWithYearStripped } },
    isVenueTextTruncated: venueTextToTruncate != truncatedVenueText,
    shouldLinkToVenuePage: paper.venueId != null && paper.venueId !== '',
  };
};
