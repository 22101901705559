import Immutable from 'immutable';

export type CiteSeeStateFromJS = {
  isCitationsEnabled: boolean;
  isSavedToLibraryEnabled: boolean;
  isCitedByLibraryEnabled: boolean;
};

export type CiteSeeState = {
  isCitationsEnabled: boolean;
  isSavedToLibraryEnabled: boolean;
  isCitedByLibraryEnabled: boolean;
};

export const CiteSeeStateDefaultProps: CiteSeeState = {
  isCitationsEnabled: true,
  isSavedToLibraryEnabled: true,
  isCitedByLibraryEnabled: true,
};

export const CITESEE_RECORD_NAME = 'CiteSeeState';
export const CiteSeeStateRecordFactory = Immutable.Record<CiteSeeState>(
  CiteSeeStateDefaultProps,
  CITESEE_RECORD_NAME
);
export type CiteSeeStateRecord = Immutable.RecordOf<CiteSeeState>;

export function getCiteSeeStateFromJS(args: CiteSeeStateFromJS): CiteSeeStateRecord {
  return CiteSeeStateRecordFactory({
    ...args,
  });
}
