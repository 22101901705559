export const HEAP_S2AIRS_CORRECTION = {
  TOPIC_SELECTED: 'Data Quality',
  LOCATION: 'Reader',
  SUB_LOCATION: 'Citation-Card',
} as const;

export const READER_ABOUT_BUTTON = {
  HASH: 'semantic-reader',
};

export const READER_SKIMMING_FAQ = {
  HASH: 'what-are-skimming-highlights',
};

export enum ReaderCitationRelevanceCue {
  SAVED_TO_LIBRARY = 'SavedToLibrary',
  CITED_BY_LIBRARY = 'CitedByLibrary',
}

export const DEFAULT_MIN_PANEL_WIDTH = 324;
export const DEFAULT_MAX_PANEL_WIDTH = 700;
