import {
  getPaperPdfBoundingBoxFromJS,
  PaperPdfBoundingBoxPropsFromJS,
  PaperPdfBoundingBoxRecord,
} from './PaperPdfBoundingBox';

import Immutable from 'immutable';

export type TermContextFromJS = {
  s2airsId: string;
  mmdaIds: string[];
  text: string;
  boundingBoxes: PaperPdfBoundingBoxPropsFromJS[];
};

export type TermMentionFromJS = {
  s2airsId: string;
  mmdaId: string;
  context: TermContextFromJS;
  boundingBoxes: PaperPdfBoundingBoxPropsFromJS[];
};

export type TermFromJS = {
  s2airsId: string;
  displayText: string;
  termMentions: TermMentionFromJS[];
};

export type TermContextProps = {
  s2airsId: string;
  mmdaIds: string[];
  text: string;
  boundingBoxes: PaperPdfBoundingBoxRecord[];
};

const termContextDefaultProps: TermContextProps = {
  s2airsId: '',
  mmdaIds: [],
  text: '',
  boundingBoxes: [],
};

export const PdfTermContextFactory = Immutable.Record<TermContextProps>(termContextDefaultProps);
export type PdfTermContextRecord = Immutable.RecordOf<TermContextProps>;

type TermMentionProps = {
  s2airsId: string;
  mmdaId: string;
  context: PdfTermContextRecord;
  boundingBoxes: PaperPdfBoundingBoxRecord[];
};

const termMentionDefaultProps: TermMentionProps = {
  s2airsId: '',
  mmdaId: '',
  context: PdfTermContextFactory(),
  boundingBoxes: [],
};

export const PdfTermMentionFactory = Immutable.Record<TermMentionProps>(termMentionDefaultProps);
export type PdfTermMentionRecord = Immutable.RecordOf<TermMentionProps>;

type TermProps = {
  s2airsId: string;
  displayText: string;
  termMentions: PdfTermMentionRecord[];
};

const termDefaultProps: TermProps = {
  s2airsId: '',
  displayText: '',
  termMentions: [],
};

export const PdfTermFactory = Immutable.Record<TermProps>(termDefaultProps);
export type PdfTermRecord = Immutable.RecordOf<TermProps>;

export function getPdfTermFromJS(args: TermFromJS): PdfTermRecord {
  const mentions = args.termMentions.map(mention => {
    return PdfTermMentionFactory({
      s2airsId: mention.s2airsId,
      mmdaId: mention.mmdaId,
      boundingBoxes: mention.boundingBoxes.map(getPaperPdfBoundingBoxFromJS),
      context: mention.context
        ? PdfTermContextFactory({
            s2airsId: mention.context.s2airsId,
            mmdaIds: mention.context.mmdaIds,
            text: mention.context.text,
            boundingBoxes: mention.context.boundingBoxes.map(getPaperPdfBoundingBoxFromJS),
          })
        : PdfTermContextFactory(),
    });
  });

  return PdfTermFactory({
    s2airsId: args.s2airsId,
    displayText: args.displayText,
    termMentions: mentions,
  });
}

// clientside type that helps render mentions per page
export type PdfTermAndMention = {
  term: PdfTermRecord;
  termMention: PdfTermMentionRecord;
};
