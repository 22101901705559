import CuePaperRow from './CuePaperRow';

import {
  AuthoredLibraryPaperCue,
  CitedByLibraryPaperCue,
  CitesLibraryPaperCue,
  CitesYourPaperCue,
  CoauthorCue,
  CueType,
  YouCitedAuthorCue,
} from '@/constants/CueType';
import { getPluralizedString } from '@/content/i18n';
import { mapHooksToProps } from '@/utils/react-utils';
import { useAppContext, useStateFromStore } from '@/AppContext';
import Shimmer from '@/components/shared/loading/Shimmer';

import classnames from 'classnames';
import Immutable from 'immutable';
import React from 'react';

import type { AuthorId, PaperId } from '@/stores/CueStore';
import type { Nullable, ReactNodeish } from '@/utils/types';

export const MAX_PAPERS_SHOWN = 2;

type PropsFromParent = {
  authorId?: Nullable<AuthorId>;
  paperIds: Immutable.List<PaperId>;
  className?: string;
  prioritizedCue: CueType;
  showDefaultLoading?: boolean;
};

type Props = {
  isLoading?: boolean;
} & PropsFromParent;

export function CuePapersList({
  paperIds,
  className,
  prioritizedCue,
  isLoading,
  showDefaultLoading,
}: Props): ReactNodeish {
  const morePaperCount = paperIds ? paperIds.size - MAX_PAPERS_SHOWN : 0;
  const hasMoreThanMax = morePaperCount > 0;
  const papersToDisplay = paperIds ? paperIds.take(MAX_PAPERS_SHOWN) : Immutable.List<string>();

  return (
    <div className={classnames('cue-papers-list', className)}>
      {isLoading && showDefaultLoading ? (
        <div className={classnames('cue-papers-list', className)}>
          <CuePaperRow prioritizedCue={prioritizedCue} isLoading={isLoading} />
          <CuePaperRow prioritizedCue={prioritizedCue} isLoading={isLoading} />
        </div>
      ) : (
        papersToDisplay.map(paperId => {
          return (
            <CuePaperRow
              key={`cue-${paperId}`}
              prioritizedCue={prioritizedCue}
              paperId={paperId}
              isLoading={isLoading}
            />
          );
        })
      )}
      {hasMoreThanMax &&
        (isLoading ? (
          <Shimmer cssWidth="110px" />
        ) : (
          <p className="cue-papers-list__morePapers">
            {getPluralizedString(_ => _.personalizedCue.morePapersLabel, morePaperCount)}
          </p>
        ))}
    </div>
  );
}

export default mapHooksToProps<Props, PropsFromParent>(CuePapersList, props => {
  const { cueStore } = useAppContext();
  const { prioritizedCue } = props;

  const cueStoreState = useStateFromStore(cueStore, _ => {
    switch (prioritizedCue) {
      case AuthoredLibraryPaperCue:
      case CitedByLibraryPaperCue:
      case CitesLibraryPaperCue: {
        return {
          isLoading: _.isLibraryCueLoading(),
        };
      }
      case CitesYourPaperCue: {
        return {
          isLoading: _.isCuePaperLoading(),
        };
      }
      case CoauthorCue:
      case YouCitedAuthorCue: {
        return {
          isLoading: _.isAuthorCueLoading(),
        };
      }
    }
  });

  return {
    ...props,
    ...cueStoreState,
  };
});
