import React from 'react';

export default function IconTagStack(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 34 24">
      <path
        d="M23.34,10.59c0.44,0.44,0.66,0.97,0.66,1.59s-0.22,1.16-0.66,1.59l-9.56,9.56C13.34,23.78,12.81,24,12.19,24
        s-1.16-0.22-1.59-0.66l-9.94-9.94C0.22,12.97,0,12.44,0,11.81V2.25c0-0.62,0.22-1.16,0.66-1.59S1.62,0,2.25,0h9.56
        c0.62,0,1.16,0.22,1.59,0.66L23.34,10.59z M5.25,7.5c0.62,0,1.16-0.22,1.59-0.66S7.5,5.88,7.5,5.25S7.28,4.09,6.84,3.66
        S5.88,3,5.25,3S4.09,3.22,3.66,3.66S3,4.62,3,5.25s0.22,1.16,0.66,1.59S4.62,7.5,5.25,7.5z M29.34,13.78
        c0.44-0.44,0.66-0.97,0.66-1.59s-0.22-1.16-0.66-1.59l-9.94-9.94C18.97,0.22,18.44,0,17.81,0h-2.3l9.23,9.19
        c0.81,0.84,1.22,1.84,1.22,3s-0.41,2.16-1.22,3l-8.16,8.16c0.44,0.44,0.97,0.66,1.59,0.66s1.16-0.22,1.59-0.66L29.34,13.78z"
      />
    </symbol>
  );
}
