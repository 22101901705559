import { ChildRoutesContext } from '@/router/ChildRoutes';
import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import ModerationStatus from '@/constants/ModerationStatus';
import Routes from '@/router/Routes';
import S2Redirect from '@/models/redirects/S2Redirect';

import React from 'react';

export default class AccountRoute extends React.Component {
  static requiresAuthentication = () => true;

  static getPageTitle = () => getString(_ => _.account.fallbackPageTitle);

  static getRobotDirectives(robots) {
    robots.shouldFollow = false;
    robots.shouldIndex = false;
  }

  static async willRouteTo({ api, authorProfileStore, authStore }, routerState) {
    if (routerState.path === Routes.ACCOUNT) {
      const redirectRouteName = pickRedirectRouteName(authStore);
      return new S2Redirect({
        routeName: redirectRouteName,
        replace: true,
        status: 302,
      });
    }

    const apiCalls = [];
    const authorId = authStore.getClaimedAuthorId();
    if (authorId) {
      const authorProfile = authorProfileStore.getAuthorProfile();
      // Only fetch the profile if it isn't already stored. It may already be fetched by UserActionCreators.
      const authorProfileCall =
        !authorProfile || authorProfile.ai2AuthorId !== authorId
          ? api.fetchAuthorProfile({ authorId })
          : Promise.resolve(null);
      apiCalls.push(
        authorProfileCall,
        api.fetchAuthorCorrectionsForAuthor({ authorId }),
        api.fetchPaperAuthorCorrectionsForAuthor({ authorId, includePaperModel: 'PaperLite' })
      );
    }
    return Promise.all(apiCalls);
  }

  render() {
    const { route } = this.props;
    return (
      <ChildRoutesContext.Provider value={{ route }}>
        <AsyncLoadedPage
          footer={false}
          load={{
            importer: () => import(/* webpackChunkName: "shared-AccountPage" */ './AccountPage'),
            chunkName: 'shared-AccountPage',
            moduleId: require.resolveWeak('./AccountPage'),
          }}
        />
      </ChildRoutesContext.Provider>
    );
  }
}

export function pickRedirectRouteName(authStore) {
  switch (authStore.getAuthorClaimModerationStatus()) {
    case ModerationStatus.Pending:
    case ModerationStatus.PendingEmailVerification:
      return 'ACCOUNT_AUTHOR_CLAIM_PENDING';
    case ModerationStatus.Approved:
      return 'ACCOUNT_MANAGE';
  }
  // If not a claimed profile then redirect to the general settings section
  return 'ACCOUNT_MANAGE';
}
