import React from 'react';

export default function IconCitedByNotFilled(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 28.44 24">
      <path
        d="M24.97,24h-6.21c-1.91,0-3.46-1.55-3.46-3.46V8.89c0-4.9,3.99-8.89,8.89-8.89h0.39c1.26,0,2.29,1.03,2.29,2.29v2.33
	c0,1.26-1.03,2.29-2.29,2.29H24.2c-1.09,0-1.98,0.89-1.98,1.98v1.98h2.76c1.91,0,3.46,1.55,3.46,3.46v6.21
	C28.43,22.45,26.88,24,24.97,24z M24.2,2.25c-3.66,0-6.64,2.98-6.64,6.64v11.65c0,0.66,0.54,1.21,1.21,1.21h6.21
	c0.67,0,1.21-0.54,1.21-1.21v-6.21c0-0.66-0.54-1.21-1.21-1.21h-5.01V8.89c0-2.33,1.9-4.23,4.23-4.23h0.39l0.04-2.37L24.2,2.25z
	 M9.67,24H3.46C1.55,24,0,22.45,0,20.54V8.89C0,3.99,3.99,0,8.89,0h0.39c1.26,0,2.29,1.03,2.29,2.29v2.33
	c0,1.26-1.03,2.29-2.29,2.29H8.89c-1.09,0-1.98,0.89-1.98,1.98v1.98h2.76c1.91,0,3.46,1.55,3.46,3.46v6.21
	C13.12,22.45,11.58,24,9.67,24z M8.89,2.25c-3.66,0-6.64,2.98-6.64,6.64v11.65c0,0.66,0.54,1.21,1.21,1.21h6.21
	c0.66,0,1.21-0.54,1.21-1.21v-6.21c0-0.66-0.54-1.21-1.21-1.21H4.66V8.89c0-2.33,1.9-4.23,4.23-4.23h0.39l0.04-2.37L8.89,2.25z"
      />
    </symbol>
  );
}
