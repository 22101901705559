import CLPaperControlsBase from './CLPaperControlsBase';
import CLPaperControlsExpansionManager from './CLPaperControlsExpansionManager';
import MockPaper, { MockPaperLiteFromJs } from '../../../../../test/utils/MockPaper';

import { ExampleConfig } from '@/components/library/ExampleUtils';
import { HeapProps } from '@/analytics/heapUtils';
import { Nullable, ReactNodeish } from '@/utils/types';
import { PaperishRecord } from '@/models/Paper';
import { PaperLinkRecordFactory } from '@/models/PaperLink';
import PaperLinkType from '@/constants/PaperLinkType';

import React from 'react';

type Props = {
  className?: Nullable<string>;
  heapProps?: Nullable<HeapProps>;
  paper: PaperishRecord;
  actions?: Nullable<boolean> | Nullable<React.ReactNode>;
  flags?: Nullable<React.ReactNode>;
  stats?: Nullable<boolean> | Nullable<React.ReactNode>;
  annotation?: Nullable<React.ReactNode>;
  managed?: Nullable<boolean>;
  cues?: Nullable<boolean> | Nullable<React.ReactNode>;
};

function CLPaperControls(props: Props): ReactNodeish {
  const { managed } = props;

  // For mobile views, CLPapercontrolsExpansionManager can be added seperately
  // so that expansions are formatted correctly
  if (!managed) {
    return <CLPaperControlsBase {...props} />;
  }

  return (
    <CLPaperControlsExpansionManager>
      <CLPaperControlsBase {...props} />
    </CLPaperControlsExpansionManager>
  );
}

CLPaperControls.defaultProps = {
  actions: true,
  flags: false,
  stats: true,
  annotation: false,
  managed: true,
  cues: false,
};

export default CLPaperControls;

// TODO(#21359): Split this into a separate file
const PAPER = MockPaper({
  id: '94e2260617274d17ef9ebf84b5081d5fc919cbab',
  primaryPaperLink: PaperLinkRecordFactory({
    url: 'https://www.semanticscholar.org/paper/94e2260617274d17ef9ebf84b5081d5fc919cbab',
    linkType: PaperLinkType.S2,
    publisherName: 'Semantic Scholar',
  }),
});

const PAPER_LITE = MockPaperLiteFromJs({
  id: '94e2260617274d17ef9ebf84b5081d5fc919cbab',
});

export const exampleConfig: ExampleConfig = {
  getComponent: () => CLPaperControls,
  fields: [],

  examples: [
    {
      title: 'PaperActions with Paper',
      desc: '',
      props: {
        paper: PAPER,
      },
      render: comp => <div style={{ width: '1000px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'PaperActions with PaperLite',
      desc: '',
      props: {
        paper: PAPER_LITE,
      },
      render: comp => <div style={{ width: '1000px', padding: '16px' }}>{comp}</div>,
    },
  ],

  events: {},
};
