import { ApiRequestStartingPayload } from '@/api/BaseApi';
import { ApiResponse, PaperPdfDataResponseBody } from '@/api/ApiResponse';
import { getPaperPdfDataFromJS, PaperPdfRecord } from '@/models/paper-pdf/PaperPdf';
import { Nullable } from '@/utils/types';
import { PaperPdfOutputDescriptionRecord } from '@/models/paper-pdf/PaperPdfOutputDescription';
import BaseStore from '@/stores/BaseStore';
import Constants from '@/constants';
import S2Dispatcher from '@/utils/S2Dispatcher';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

export default class ReaderPdfStore extends BaseStore {
  dispatchToken: string;
  paperPdfRecord: Nullable<PaperPdfRecord>;
  state: StoreStateValue;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.state = StoreState.UNINITIALIZED;

    this.dispatchToken = dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const apiStartingPayload = payload as ApiRequestStartingPayload;
          switch (apiStartingPayload.requestType) {
            case Constants.requestTypes.PDF_DATA: {
              this.state = StoreState.LOADING;
              this.paperPdfRecord = null;
              this.emitChange();
              break;
            }
          }
          break;
        }

        case Constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.PDF_DATA: {
              const pdfDataApiResponse = apiResponse as ApiResponse<PaperPdfDataResponseBody>;
              const { paperId } = apiResponse.context;
              if (this.paperPdfRecord?.paperId !== paperId) {
                this.state = StoreState.LOADED;
                this.paperPdfRecord = getPaperPdfDataFromJS({
                  paperId,
                  ...pdfDataApiResponse.resultData,
                });
                this.emitChange();
              }
              break;
            }
          }
          break;
        }
      }
    });
  }

  isUninitialized(): boolean {
    return this.state === StoreState.UNINITIALIZED;
  }

  isLoading(): boolean {
    return this.isUninitialized() || this.state === StoreState.LOADING;
  }

  getPdfData(): Nullable<PaperPdfRecord> {
    return this.paperPdfRecord || null;
  }

  getPaperId(): Nullable<string> {
    if (!this.paperPdfRecord) {
      return null;
    }
    return this.paperPdfRecord.paperId || null;
  }

  getPdfSha(): Nullable<string> {
    if (!this.paperPdfRecord) {
      return null;
    }
    return this.paperPdfRecord.pdfSha;
  }

  getOutputDescription(): Nullable<PaperPdfOutputDescriptionRecord> {
    if (!this.paperPdfRecord) {
      return null;
    }
    return this.paperPdfRecord.outputDescription;
  }
}
