import React from 'react';

export default function IconRemoveBookmark(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 18 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 0C1.01 0 0 1.01 0 2.25V24L9 18.75L18 24V2.25C18 1.01 16.99 0 15.75 0H2.25ZM14.6836 4.07663L13.9263 3.31919C13.5022 2.89502 12.8152 2.89534 12.421 3.3098L9.00487 6.72659L5.59194 3.31305C5.17795 2.89898 4.491 2.89931 4.07661 3.31377L3.31858 4.07193C2.89409 4.49651 2.89376 5.18358 3.31786 5.58754L6.73079 9.00108L3.31461 12.4179C2.90022 12.8323 2.8999 13.5194 3.31389 13.9335L4.0611 14.6808C4.48519 15.105 5.17214 15.1047 5.58653 14.6902L9.00271 11.2734L12.4156 14.6869C12.8296 15.101 13.5166 15.1007 13.931 14.6862L14.689 13.9281C15.1034 13.5136 15.1037 12.8265 14.6897 12.4125L11.2768 8.99891L14.6829 5.59223C15.0972 5.17777 15.0976 4.4907 14.6836 4.07663Z"
      />
    </symbol>
  );
}
