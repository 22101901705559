import Immutable from 'immutable';
import invariant from 'invariant';

export const Temporary = 'Temporary' as const;
export const Permanent = 'Permanent' as const;

export const ALL_DURATION_TYPES = Immutable.List.of(Temporary, Permanent);

const byKey = {
  Temporary,
  Permanent,
} as const;

export type FeatureFlagDurationType = keyof typeof byKey;

export function getFeatureFlagDurationType(durationType: string): FeatureFlagDurationType {
  if (!durationType || !(durationType in byKey)) {
    invariant(durationType, `'${durationType || ''}' is not a valid FeatureFlagDurationType`);
  }

  return byKey[durationType];
}
