import BaseStore from './BaseStore';

import { isApiPayload } from '@/api/BaseApi';
import Constants from '@/constants';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import type { ApiResponse, UserInfoResponseBody } from '@/api/ApiResponse';
import type S2Dispatcher from '@/utils/S2Dispatcher';

export default class AccountNavStore extends BaseStore {
  _hasRecentAuthorCitations: boolean;
  _hasAlertsHistory: boolean;
  _hasRecommendationsHistory: boolean;
  _state: StoreStateValue;

  constructor(dispatcher: S2Dispatcher) {
    super();
    this._hasRecentAuthorCitations = false;
    this._hasAlertsHistory = false;
    this._hasRecommendationsHistory = false;
    this._state = StoreState.UNINITIALIZED;

    dispatcher.register(payload => {
      if (!isApiPayload(payload)) {
        return;
      }
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          switch (payload.requestType) {
            case Constants.requestTypes.USER_INFO: {
              this._state = StoreState.LOADING;
              this.emitChange();
              break;
            }
          }
          break;
        }
        case Constants.actions.API_REQUEST_COMPLETE: {
          switch (payload.requestType) {
            case Constants.requestTypes.USER_INFO: {
              const typedPayload = payload as ApiResponse<UserInfoResponseBody>;
              if (typedPayload.resultData) {
                this._hasRecentAuthorCitations = typedPayload.resultData.hasRecentAuthorCitations;
                this._hasAlertsHistory = typedPayload.resultData.hasAlertsHistory;
                this._hasRecommendationsHistory = typedPayload.resultData.hasRecommendationsHistory;
                this._state = StoreState.LOADED;
              } else {
                this._hasRecentAuthorCitations = false;
                this._hasAlertsHistory = false;
                this._hasRecommendationsHistory = false;
                this._state = StoreState.LOADED;
              }
              this.emitChange();
              break;
            }

            case Constants.requestTypes.LOGOUT: {
              this._hasRecentAuthorCitations = false;
              this._hasAlertsHistory = false;
              this._hasRecommendationsHistory = false;
              this._state = StoreState.UNINITIALIZED;
              this.emitChange();
              break;
            }
          }
          break;
        }
      }
    });
  }

  isUninitialized(): boolean {
    return this._state === StoreState.UNINITIALIZED;
  }

  isLoading(): boolean {
    return this.isUninitialized() || this._state === StoreState.LOADING;
  }

  hasRecentAuthorCitations(): boolean {
    return this._hasRecentAuthorCitations;
  }

  hasAlertsHistory(): boolean {
    return this._hasAlertsHistory;
  }

  hasRecommendationsHistory(): boolean {
    return this._hasRecommendationsHistory;
  }

  hasResearchDashboardContent(): boolean {
    return (
      this._hasRecentAuthorCitations || this._hasAlertsHistory || this._hasRecommendationsHistory
    );
  }
}
