import { getString } from '@/content/i18n';
import { getVenueQueryFromQueryParamsFromRouterState } from '@/models/VenueQuery';
import { isBrowser } from '@/utils/env';
import { RouterState, WillRouteToResult } from '@/router/Route';
import AppContext from '@/AppContext';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import Routes from '@/router/Routes';
import VenueQueryStore from '@/stores/VenueQueryStore';
import VenueStore from '@/stores/VenueStore';

import React from 'react';

export class VenueRoute extends React.Component {
  static getPageTitle({ venueStore }: { venueStore: VenueStore }): string {
    const venueName = venueStore.getVenueRecord()?.name;

    return venueName != null && venueName.length !== 0
      ? getString(_ => _.venuePage.pageTitleWithVenueName, venueName)
      : getString(_ => _.venuePage.pageTitleWhileLoading);
  }
  static getCanonicalUrl = (): string => Routes.VENUE;

  static async willRouteTo(
    appContext: AppContext,
    routerState: RouterState
  ): Promise<WillRouteToResult> {
    const { venueQueryStore } = appContext;
    const {
      query: { serverSideSearch },
    } = routerState;
    // display a loading screen when the app starts initially
    if (!isBrowser() && !serverSideSearch) {
      return VenueQueryStore.setQueryAndLoadingState(appContext, routerState);
    }
    const incomingQuery = getVenueQueryFromQueryParamsFromRouterState(routerState);
    const isNewQuery = venueQueryStore.isNewQuery(incomingQuery);
    if (isNewQuery) {
      const queryPromise = VenueQueryStore.executeSearchPapersByVenueQuery(appContext, routerState);
      const payload = await Promise.all([queryPromise]);
      const result = payload[0];
      if (!result.resultData) {
        return result;
      }
    }
    return null;
  }

  render() {
    return (
      <AsyncLoadedPage
        footer={false}
        load={{
          importer: () => import(/* webpackChunkName: "shared-VenuePage" */ './VenuePage'),
          chunkName: 'shared-VenuePage',
          moduleId: require.resolveWeak('./VenuePage'),
        }}
      />
    );
  }
}
