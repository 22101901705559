import PaperLinkType, { PaperLinkTypeValue } from '@/constants/PaperLinkType';

import { gql } from 'graphql-tag';
import Immutable from 'immutable';

import type { Nullable } from '@/utils/types';

export type PaperLinkFromJS = {
  url: string;
  linkType: PaperLinkTypeValue;
  publisherName: string;
};

export type PaperLinkFromGraphQL = {
  isPrimary: boolean;
  url: string;
  linkType: string;
  publisherName: Nullable<string>;
};

type Props = {
  isPrimary: Nullable<boolean>;
  url: string;
  linkType: PaperLinkTypeValue;
  publisherName: string;
};

const defaultProps: Props = {
  isPrimary: null,
  url: '',
  linkType: PaperLinkType.S2,
  publisherName: '',
};

export const PaperLinkRecordFactory = Immutable.Record(defaultProps);
export type PaperLinkRecord = Immutable.RecordOf<Props>;

export function getPaperLinkFromJS(args: PaperLinkFromJS): PaperLinkRecord {
  return PaperLinkRecordFactory({ ...args });
}

export function getPaperLinkFromGraphQL(args: PaperLinkFromGraphQL): PaperLinkRecord {
  const { publisherName, linkType: linkTypeRaw, ...rest } = args;
  const linkType = linkTypeRaw as PaperLinkTypeValue;
  return PaperLinkRecordFactory({
    ...rest,
    linkType,
    publisherName: publisherName || '',
  });
}

export const PAPER_LINK_FRAGMENT_NAME = 'PaperLinkRecordFragment';

export const PAPER_LINK_FRAGMENT = gql`
  fragment ${PAPER_LINK_FRAGMENT_NAME} on PaperLink {
    isPrimary
    url
    linkType
    publisherName
  }
`;
