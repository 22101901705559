import React from 'react';

export default function IconExportPaper(props: { id?: string }) {
  return (
    <symbol id={props.id} className="backdrop" viewBox="0 0 148 78">
      <style type="text/css">
        {`
        .backdrop{height:78px; width: 148px}
        .backdrop__papers{fill:#D9DADB; opacity:0.5;}
        .jewel__backdrop{fill:#1857B6;}
        .jewel__icon{fill:#fff;}
        `}
      </style>
      <path
        className="backdrop__papers"
        d="M10.3361 6.88612H16V20.9765C16 21.5447 15.5592 21.9992 15.0083 21.9992H0.991736C0.440771 22.0219 0 21.5447 0 20.9765V1.02269C0 0.454529 0.440771 0 0.991736 0H9.32231V5.8407C9.34435 6.40886 9.78512 6.88612 10.3361 6.88612ZM16 5.4998H10.6667V0H10.9311C11.1956 0 11.4601 0.113632 11.6364 0.295444L15.7135 4.49984C15.9118 4.70438 16 4.95437 16 5.22709V5.4998Z"
      />
      <path
        className="backdrop__papers"
        d="M32.3361 6.88612H38V20.9765C38 21.5447 37.5592 21.9992 37.0083 21.9992H22.9917C22.4408 22.0219 22 21.5447 22 20.9765V1.02269C22 0.454529 22.4408 0 22.9917 0H31.3223V5.8407C31.3444 6.40886 31.7851 6.88612 32.3361 6.88612ZM38 5.4998H32.6667V0H32.9311C33.1956 0 33.4601 0.113632 33.6364 0.295444L37.7135 4.49984C37.9118 4.70438 38 4.95437 38 5.22709V5.4998Z"
      />
      <path
        className="backdrop__papers"
        d="M54.3361 6.88612H60V20.9765C60 21.5447 59.5592 21.9992 59.0083 21.9992H44.9917C44.4408 22.0219 44 21.5447 44 20.9765V1.02269C44 0.454529 44.4408 0 44.9917 0H53.3223V5.8407C53.3444 6.40886 53.7851 6.88612 54.3361 6.88612ZM60 5.4998H54.6667V0H54.9311C55.1956 0 55.4601 0.113632 55.6364 0.295444L59.7135 4.49984C59.9118 4.70438 60 4.95437 60 5.22709V5.4998Z"
      />
      <path
        className="backdrop__papers"
        d="M76.3361 6.88612H82V20.9765C82 21.5447 81.5592 21.9992 81.0083 21.9992H66.9917C66.4408 22.0219 66 21.5447 66 20.9765V1.02269C66 0.454529 66.4408 0 66.9917 0H75.3223V5.8407C75.3444 6.40886 75.7851 6.88612 76.3361 6.88612ZM82 5.4998H76.6667V0H76.9311C77.1956 0 77.4601 0.113632 77.6364 0.295444L81.7135 4.49984C81.9118 4.70438 82 4.95437 82 5.22709V5.4998Z"
      />
      <path
        className="backdrop__papers"
        d="M98.3361 6.88612H104V20.9765C104 21.5447 103.559 21.9992 103.008 21.9992H88.9917C88.4408 22.0219 88 21.5447 88 20.9765V1.02269C88 0.454529 88.4408 0 88.9917 0H97.3223V5.8407C97.3444 6.40886 97.7851 6.88612 98.3361 6.88612ZM104 5.4998H98.6667V0H98.9311C99.1956 0 99.4601 0.113632 99.6364 0.295444L103.713 4.49984C103.912 4.70438 104 4.95437 104 5.22709V5.4998Z"
      />
      <path
        className="backdrop__papers"
        d="M120.336 6.88612H126V20.9765C126 21.5447 125.559 21.9992 125.008 21.9992H110.992C110.441 22.0219 110 21.5447 110 20.9765V1.02269C110 0.454529 110.441 0 110.992 0H119.322V5.8407C119.344 6.40886 119.785 6.88612 120.336 6.88612ZM126 5.4998H120.667V0H120.931C121.196 0 121.46 0.113632 121.636 0.295444L125.713 4.49984C125.912 4.70438 126 4.95437 126 5.22709V5.4998Z"
      />
      <path
        className="backdrop__papers"
        d="M142.336 6.88612H148V20.9765C148 21.5447 147.559 21.9992 147.008 21.9992H132.992C132.441 22.0219 132 21.5447 132 20.9765V1.02269C132 0.454529 132.441 0 132.992 0H141.322V5.8407C141.344 6.40886 141.785 6.88612 142.336 6.88612ZM148 5.4998H142.667V0H142.931C143.196 0 143.46 0.113632 143.636 0.295444L147.713 4.49984C147.912 4.70438 148 4.95437 148 5.22709V5.4998Z"
      />
      <path
        className="backdrop__papers"
        d="M10.3361 62.8861H16V76.9765C16 77.5447 15.5592 77.9992 15.0083 77.9992H0.991736C0.440771 78.0219 0 77.5447 0 76.9765V57.0227C0 56.4545 0.440771 56 0.991736 56H9.32231V61.8407C9.34435 62.4089 9.78512 62.8861 10.3361 62.8861ZM16 61.4998H10.6667V56H10.9311C11.1956 56 11.4601 56.1136 11.6364 56.2954L15.7135 60.4998C15.9118 60.7044 16 60.9544 16 61.2271V61.4998Z"
      />
      <path
        className="backdrop__papers"
        d="M32.3361 62.8861H38V76.9765C38 77.5447 37.5592 77.9992 37.0083 77.9992H22.9917C22.4408 78.0219 22 77.5447 22 76.9765V57.0227C22 56.4545 22.4408 56 22.9917 56H31.3223V61.8407C31.3444 62.4089 31.7851 62.8861 32.3361 62.8861ZM38 61.4998H32.6667V56H32.9311C33.1956 56 33.4601 56.1136 33.6364 56.2954L37.7135 60.4998C37.9118 60.7044 38 60.9544 38 61.2271V61.4998Z"
      />
      <path
        className="backdrop__papers"
        d="M54.3361 62.8861H60V76.9765C60 77.5447 59.5592 77.9992 59.0083 77.9992H44.9917C44.4408 78.0219 44 77.5447 44 76.9765V57.0227C44 56.4545 44.4408 56 44.9917 56H53.3223V61.8407C53.3444 62.4089 53.7851 62.8861 54.3361 62.8861ZM60 61.4998H54.6667V56H54.9311C55.1956 56 55.4601 56.1136 55.6364 56.2954L59.7135 60.4998C59.9118 60.7044 60 60.9544 60 61.2271V61.4998Z"
      />
      <path
        className="backdrop__papers"
        d="M76.3361 62.8861H82V76.9765C82 77.5447 81.5592 77.9992 81.0083 77.9992H66.9917C66.4408 78.0219 66 77.5447 66 76.9765V57.0227C66 56.4545 66.4408 56 66.9917 56H75.3223V61.8407C75.3444 62.4089 75.7851 62.8861 76.3361 62.8861ZM82 61.4998H76.6667V56H76.9311C77.1956 56 77.4601 56.1136 77.6364 56.2954L81.7135 60.4998C81.9118 60.7044 82 60.9544 82 61.2271V61.4998Z"
      />
      <path
        className="backdrop__papers"
        d="M98.3361 62.8861H104V76.9765C104 77.5447 103.559 77.9992 103.008 77.9992H88.9917C88.4408 78.0219 88 77.5447 88 76.9765V57.0227C88 56.4545 88.4408 56 88.9917 56H97.3223V61.8407C97.3444 62.4089 97.7851 62.8861 98.3361 62.8861ZM104 61.4998H98.6667V56H98.9311C99.1956 56 99.4601 56.1136 99.6364 56.2954L103.713 60.4998C103.912 60.7044 104 60.9544 104 61.2271V61.4998Z"
      />
      <path
        className="backdrop__papers"
        d="M120.336 62.8861H126V76.9765C126 77.5447 125.559 77.9992 125.008 77.9992H110.992C110.441 78.0219 110 77.5447 110 76.9765V57.0227C110 56.4545 110.441 56 110.992 56H119.322V61.8407C119.344 62.4089 119.785 62.8861 120.336 62.8861ZM126 61.4998H120.667V56H120.931C121.196 56 121.46 56.1136 121.636 56.2954L125.713 60.4998C125.912 60.7044 126 60.9544 126 61.2271V61.4998Z"
      />
      <path
        className="backdrop__papers"
        d="M142.336 62.8861H148V76.9765C148 77.5447 147.559 77.9992 147.008 77.9992H132.992C132.441 78.0219 132 77.5447 132 76.9765V57.0227C132 56.4545 132.441 56 132.992 56H141.322V61.8407C141.344 62.4089 141.785 62.8861 142.336 62.8861ZM148 61.4998H142.667V56H142.931C143.196 56 143.46 56.1136 143.636 56.2954L147.713 60.4998C147.912 60.7044 148 60.9544 148 61.2271V61.4998Z"
      />
      <path
        className="backdrop__papers"
        d="M10.3361 34.8861H16V48.9765C16 49.5447 15.5592 49.9992 15.0083 49.9992H0.991736C0.440771 50.0219 0 49.5447 0 48.9765V29.0227C0 28.4545 0.440771 28 0.991736 28H9.32231V33.8407C9.34435 34.4089 9.78512 34.8861 10.3361 34.8861ZM16 33.4998H10.6667V28H10.9311C11.1956 28 11.4601 28.1136 11.6364 28.2954L15.7135 32.4998C15.9118 32.7044 16 32.9544 16 33.2271V33.4998Z"
      />
      <path
        className="backdrop__papers"
        d="M32.3361 34.8861H38V48.9765C38 49.5447 37.5592 49.9992 37.0083 49.9992H22.9917C22.4408 50.0219 22 49.5447 22 48.9765V29.0227C22 28.4545 22.4408 28 22.9917 28H31.3223V33.8407C31.3444 34.4089 31.7851 34.8861 32.3361 34.8861ZM38 33.4998H32.6667V28H32.9311C33.1956 28 33.4601 28.1136 33.6364 28.2954L37.7135 32.4998C37.9118 32.7044 38 32.9544 38 33.2271V33.4998Z"
      />
      <path
        className="backdrop__papers"
        d="M54.3361 34.8861H60V48.9765C60 49.5447 59.5592 49.9992 59.0083 49.9992H44.9917C44.4408 50.0219 44 49.5447 44 48.9765V29.0227C44 28.4545 44.4408 28 44.9917 28H53.3223V33.8407C53.3444 34.4089 53.7851 34.8861 54.3361 34.8861ZM60 33.4998H54.6667V28H54.9311C55.1956 28 55.4601 28.1136 55.6364 28.2954L59.7135 32.4998C59.9118 32.7044 60 32.9544 60 33.2271V33.4998Z"
      />
      <path
        className="backdrop__papers"
        d="M76.3361 34.8861H82V48.9765C82 49.5447 81.5592 49.9992 81.0083 49.9992H66.9917C66.4408 50.0219 66 49.5447 66 48.9765V29.0227C66 28.4545 66.4408 28 66.9917 28H75.3223V33.8407C75.3444 34.4089 75.7851 34.8861 76.3361 34.8861ZM82 33.4998H76.6667V28H76.9311C77.1956 28 77.4601 28.1136 77.6364 28.2954L81.7135 32.4998C81.9118 32.7044 82 32.9544 82 33.2271V33.4998Z"
      />
      <path
        className="backdrop__papers"
        d="M98.3361 34.8861H104V48.9765C104 49.5447 103.559 49.9992 103.008 49.9992H88.9917C88.4408 50.0219 88 49.5447 88 48.9765V29.0227C88 28.4545 88.4408 28 88.9917 28H97.3223V33.8407C97.3444 34.4089 97.7851 34.8861 98.3361 34.8861ZM104 33.4998H98.6667V28H98.9311C99.1956 28 99.4601 28.1136 99.6364 28.2954L103.713 32.4998C103.912 32.7044 104 32.9544 104 33.2271V33.4998Z"
      />
      <path
        className="backdrop__papers"
        d="M120.336 34.8861H126V48.9765C126 49.5447 125.559 49.9992 125.008 49.9992H110.992C110.441 50.0219 110 49.5447 110 48.9765V29.0227C110 28.4545 110.441 28 110.992 28H119.322V33.8407C119.344 34.4089 119.785 34.8861 120.336 34.8861ZM126 33.4998H120.667V28H120.931C121.196 28 121.46 28.1136 121.636 28.2954L125.713 32.4998C125.912 32.7044 126 32.9544 126 33.2271V33.4998Z"
      />
      <path
        className="backdrop__papers"
        d="M142.336 34.8861H148V48.9765C148 49.5447 147.559 49.9992 147.008 49.9992H132.992C132.441 50.0219 132 49.5447 132 48.9765V29.0227C132 28.4545 132.441 28 132.992 28H141.322V33.8407C141.344 34.4089 141.785 34.8861 142.336 34.8861ZM148 33.4998H142.667V28H142.931C143.196 28 143.46 28.1136 143.636 28.2954L147.713 32.4998C147.912 32.7044 148 32.9544 148 33.2271V33.4998Z"
      />

      <circle className="jewel__backdrop" cx="74" cy="39" r="24" />
      <path
        className="jewel__icon"
        d="M72.5758 42.1333C72.5758 42.3167 72.6431 42.4667 72.7609 42.5833C72.8788 42.7 73.0303 42.7667 73.2155 42.7667H80.1515V48.0833C80.1515 48.35 80.0673 48.5667 79.8822 48.75C79.697 48.9333 79.4613 49 79.2088 49H65.9428C65.6734 49 65.4545 48.9167 65.2694 48.7333C65.0842 48.55 65 48.3167 65 48.0667V29.9333C65 29.6667 65.0842 29.45 65.2694 29.2667C65.4545 29.0833 65.6902 29 65.9428 29H73.8384V34.3167C73.8384 34.5833 73.9226 34.8 74.1077 34.9833C74.2929 35.1667 74.5118 35.25 74.7811 35.25H80.1515V40.25H73.2155C73.0303 40.25 72.8788 40.3167 72.7609 40.4333C72.6431 40.55 72.5758 40.7 72.5758 40.8667V42.1333ZM80.1515 33.7667V34H75.101V29H75.3367C75.6061 29 75.8249 29.0833 76.0101 29.2667L79.8822 33.1C80.0673 33.2833 80.1515 33.5 80.1515 33.7667ZM87.5253 41.0333C87.6599 41.1667 87.7273 41.3167 87.7273 41.5C87.7273 41.6833 87.6599 41.8333 87.5253 41.9667L83.7374 45.7167C83.5522 45.9167 83.3333 45.9833 83.064 45.8667C82.7946 45.75 82.6768 45.5833 82.6768 45.2833V42.75H80.1515V40.25H82.6768V37.7167C82.6768 37.4333 82.8114 37.2333 83.064 37.1333C83.3165 37.0333 83.5522 37.0833 83.7374 37.2833L87.5253 41.0333Z"
      />
    </symbol>
  );
}
