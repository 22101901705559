import { mkHeapAttributes } from '@/analytics/heapUtils';

export const heapSubmitFeedbackButton = (props: {
  tags: string[];
  'topic-selected': string;
  location: string;
  'sub-location': string;
}) =>
  mkHeapAttributes({
    id: 'submit_feedback_button',
    ...props,
  });
