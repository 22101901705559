import type { DEPRECATED__FlowOptional, Nullable } from '@/utils/types';

export type ModerationStatusKey = keyof typeof ModerationStatus;
export type ModerationStatusValue = (typeof ModerationStatus)[ModerationStatusKey];

const ModerationStatus = {
  Approved: 'Approved',
  Denied: 'Denied',
  Pending: 'Pending',
  PendingEmailVerification: 'PendingEmailVerification',
  Unsupported: 'Unsupported',
} as const;

// TODO: TS isn't quite sure what to make of this type
const byValue = Object.keys(ModerationStatus).reduce((memo, key) => {
  const value = ModerationStatus[key];
  memo[value] = key;
  return memo;
}, {});

// TODO: TS isn't quite sure what to make of this type
const byUrlParam = Object.keys(ModerationStatus).reduce((memo, key) => {
  const value = ModerationStatus[key];
  const urlParam = value.toLowerCase();
  memo[urlParam] = value;
  return memo;
}, {});

export default Object.freeze(ModerationStatus);

// TODO: TS isn't quite sure what to make of this type
export const ModerationStatusByValue = Object.freeze(byValue);

export function getModerationStatusValue(value: string): ModerationStatusValue {
  if (value in byValue) {
    return value as ModerationStatusValue;
  }
  return ModerationStatus.Unsupported;
}

export function optModerationStatusValue(
  value: DEPRECATED__FlowOptional<string>
): Nullable<ModerationStatusValue> {
  if (!value) {
    return null;
  }
  return getModerationStatusValue(value);
}

export function convertUrlParamToModerationStatusValue(
  param: string
): Nullable<ModerationStatusValue> {
  if (!param) {
    return null;
  }
  const value = byUrlParam[param.toLowerCase()];
  return value || null;
}
