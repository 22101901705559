import { extractVenueInformation } from './extractVenueInformation';
import { PaperLiteVenue } from './PaperLiteVenue';
import HighlightedPaperVenue from './HighlightedPaperVenue';

import { isPaperLiteRecord, PaperishRecord } from '@/models/Paper';
import { ReactNodeish } from '@/utils/types';
import ABTest from '@/components/util/abtest/ABTest';
import Control from '@/components/util/abtest/Control';
import Experiment from '@/weblab/Experiment';
import Variation from '@/components/util/abtest/Variation';

import React from 'react';

type PaperVenueProps = {
  paper: PaperishRecord;
  stripYear?: boolean;
  maxVenueTextLength?: number;
  className?: string;
};

export default function PaperVenue({
  paper,
  stripYear,
  maxVenueTextLength,
  className,
}: PaperVenueProps): ReactNodeish {
  if (!paper?.venue) {
    return null;
  }

  const VenueComponent = isPaperLiteRecord(paper) ? PaperLiteVenue : HighlightedPaperVenue;

  const { shouldLinkToVenuePage } = extractVenueInformation(paper, stripYear, maxVenueTextLength);

  const venueComponentProps = {
    className,
    paper,
    stripYear,
    maxVenueTextLength,
  };

  if (!shouldLinkToVenuePage) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore we determine if paper is the right type for the component when selecting which component to use
    return <VenueComponent {...venueComponentProps} />;
  }

  return (
    <ABTest experiment={Experiment.Venues} exposeOnRender>
      <Control>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore we determine if paper is the right type for the component when selecting which component to use */}
        {() => <VenueComponent {...venueComponentProps} />}
      </Control>
      <Variation variation={Experiment.Venues.Variation.ENABLE_VENUES}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore we determine if paper is the right type for the component when selecting which component to use */}
        {() => <VenueComponent {...venueComponentProps} isVenueLinkEnabled />}
      </Variation>
    </ABTest>
  );
}
