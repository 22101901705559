import { isBrowser } from '@/utils/env';
import softError from '@/utils/softError';

import type { DEPRECATED__FlowOptional, Nullable } from '@/utils/types';

export type AppcuesPropKey = string | number;
export type AppcuesPropValue = Nullable<string | number | boolean>;
export type AppcuesPropObject = { [key: AppcuesPropKey]: AppcuesPropValue };

export type AppcuesInstance = {
  anonymous: () => void;
  page: () => void;
  identify: (
    identity: string | number,
    props?: DEPRECATED__FlowOptional<AppcuesPropObject>
  ) => void;
};

export function optAppcues(): Nullable<AppcuesInstance> {
  if (!isBrowser()) {
    logOnce(() => softError('appcuesNotSet', 'attempted to use Appcues on the server'));
    return null;
  }
  return window.Appcues || null;
}

export function runWithAppcues(callback: (inst: AppcuesInstance) => any): void {
  try {
    const appcues = optAppcues();
    if (!appcues) {
      return;
    }

    callback(appcues);
  } catch (error) {
    softError('heapError', 'caught error in runWithAppcues() callback', error);
  }
}

let hasLogged = false;
function logOnce(cb: () => void): void {
  if (hasLogged) {
    return;
  }
  hasLogged = true;
  cb();
}
