import { ChildRoutesContext } from '@/router/ChildRoutes';
import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import LibraryFolderStore from '@/stores/LibraryFolderStore';
import Routes from '@/router/Routes';
import S2Redirect from '@/models/redirects/S2Redirect';

import PropTypes from 'prop-types';
import React from 'react';

export default class LibraryRootRoute extends React.PureComponent {
  static contextTypes = {
    libraryFolderStore: PropTypes.instanceOf(LibraryFolderStore).isRequired,
  };

  static getPageTitle = () => getString(_ => _.library.pageTitle);

  static willRouteTo(_, routerState) {
    if (routerState.path === Routes.LIBRARY_WITH_FOLDERS) {
      // Redirect to All Papers if someone hits root directly
      return new S2Redirect({
        routeName: 'LIBRARY_ALL_ENTRIES',
      });
    }
  }

  render() {
    const { route } = this.props;
    const { libraryFolderStore } = this.context;
    const hasLibraryBeenUsed =
      libraryFolderStore.getUnsortedPaperCount() === 0 &&
      libraryFolderStore.getUserGeneratedFolders().isEmpty();

    return hasLibraryBeenUsed ? (
      <ChildRoutesContext.Provider value={{ route }}>
        <AsyncLoadedPage
          footer={false}
          load={{
            importer: () =>
              import(/* webpackChunkName: "shared-LibraryFTUEPage" */ './LibraryFTUEPage'),
            chunkName: 'shared-LibraryFTUEPage',
            moduleId: require.resolveWeak('./LibraryFTUEPage'),
          }}
        />
      </ChildRoutesContext.Provider>
    ) : (
      <ChildRoutesContext.Provider value={{ route }}>
        <AsyncLoadedPage
          footer={false}
          load={{
            importer: () =>
              import(/* webpackChunkName: "shared-LibraryRootPage" */ './LibraryRootPage'),
            chunkName: 'shared-LibraryRootPage',
            moduleId: require.resolveWeak('./LibraryRootPage'),
          }}
        />
      </ChildRoutesContext.Provider>
    );
  }
}
