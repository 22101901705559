import { getString } from '@/content/i18n';
import { heapCreateLibraryFTUEStartButton } from '@/analytics/attributes/researchFTUEPage';
import { ModalId } from '@/constants/Modal';
import { showModal } from '@/actions/ModalActionCreators';
import CLButton from '@/components/library/button/CLButton';
import Icon from '@/components/shared/icon/Icon';
import Link from '@/router/Link';
import S2Dispatcher from '@/utils/S2Dispatcher';

import PropTypes from 'prop-types';
import React from 'react';

// NOTE: The Lighthouse accessibility tests rely on the FTUE header to be present to signify
// a successful login. If this class goes away or the data-test-id changes -
// login_with_puppeteer.js needs to be updated as well
export default class FTUEHeader extends React.PureComponent {
  static contextTypes = {
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
  };

  dispatchFTUEModal = () => {
    const { dispatcher } = this.context;
    dispatcher.dispatch(showModal({ id: ModalId.CREATE_LIBRARY_FTUE }));
  };

  render() {
    const { cardName, iconId, showButton, showResearchDashboardLink } = this.props;
    return (
      <React.Fragment>
        <header>
          <hgroup className="onboarding-pages__header">
            <div
              className="onboarding-pages__research-dashboard"
              data-test-id="research-dashboard__homepage">
              {showResearchDashboardLink && (
                <Link
                  className="onboarding-pages__research-dashboard__link"
                  data-test-id="research-homepage-link"
                  to="RESEARCH_HOMEPAGE">
                  {getString(_ => _.research.onboardingHomepage.researchDashboard)}
                </Link>
              )}
            </div>

            <div className="onboarding-pages__subhead">
              <Icon
                className="onboarding-pages__subhead__icon"
                icon={iconId}
                height="16"
                width="16"
              />
              {getString(_ => _.research.onboardingHomepage[`${cardName}Subhead`])}
            </div>
            <main>
              <h1 className="onboarding-pages__headline">
                {getString(_ => _.research.onboardingHomepage[`${cardName}Headline`])}
              </h1>
            </main>

            {showButton && (
              <CLButton
                onClick={this.dispatchFTUEModal}
                label={getString(_ => _.research.onboardingHomepage.setUpRecommendationsButton)}
                className="onboarding-pages__set-up-recommendations-button"
                heapProps={heapCreateLibraryFTUEStartButton()}
              />
            )}
          </hgroup>
        </header>
      </React.Fragment>
    );
  }
}
