export type AlertFrequencyKey = keyof typeof byKey;
export type AlertFrequencyValue = (typeof byKey)[AlertFrequencyKey];

const byKey = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
} as const;

const byValue = Object.keys(byKey).reduce((memo, key) => {
  const value = byKey[key];
  memo[value] = key;
  return memo;
}, {});

export default Object.freeze(byKey);

// TODO: TS isn't quite sure what to make of this type
export const AlertFrequencyByValue = Object.freeze(byValue);
