import CLContainerBase from './CLContainerBase';

import { ReactNodeish } from '@/utils/types';

import classnames from 'classnames';
import React, { ComponentProps } from 'react';

type CLCardContainerProps = ComponentProps<typeof CLContainerBase>;

export default function CLCardContainer({
  className,
  ...otherProps
}: CLCardContainerProps): ReactNodeish {
  return <CLContainerBase className={classnames('cl-card-container', className)} {...otherProps} />;
}

CLCardContainer.defaultProps = {
  hasDogEar: false,
};
