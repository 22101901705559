import AppFooter from '@/components/shared/layout/AppFooter';
import AppHeader from '@/components/shared/layout/AppHeader';
import BrowserUtil from '@/browser';
import FTUECard, { CIRCLE_POSITION } from '@/components/shared/research/FTUECard';
import FTUEHeader from '@/components/shared/research/FTUEHeader';
import Page from '@/components/shared/layout/Page';

import classnames from 'classnames';
import React from 'react';

export default class RecommendationsFTUEPage extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      isDarkAppHeader: false,
    };
  }

  onScroll = () => {
    this.setState({
      isDarkAppHeader: BrowserUtil.scrollTop() > 0,
    });
  };

  componentDidMount() {
    BrowserUtil.listenForScroll(this.onScroll);
  }

  componentWillUnmount() {
    BrowserUtil.stopListeningForScroll(this.onScroll);
  }

  render() {
    const { isDarkAppHeader } = this.state;
    // TODO  #29139 - Refactor to use App Context instead of isResearchFTUE prop
    return (
      <Page
        className="onboarding-pages"
        header={
          <AppHeader
            cssClass={classnames({
              'onboarding-pages__header--dark-gradient': isDarkAppHeader,
            })}
            isResearchFTUE={true}
          />
        }
        footer={<AppFooter theme="dark" />}>
        <FTUEHeader
          cardName="feed"
          iconId="lightning-bolt"
          showButton={false}
          showResearchDashboardLink={true}
        />
        <div className="onboarding-pages__content">
          <FTUECard
            cardName="library"
            borderIconId="fa-bookmark"
            explanationIconId="library-explanation"
            circlePosition={CIRCLE_POSITION.RIGHT}
            showOverlay={true}
          />

          <FTUECard
            cardName="feed"
            contentIconId="lightning-bolt"
            explanationIconId="feed-explanation"
            circlePosition={CIRCLE_POSITION.LEFT}
            className="onboarding-pages__content__card--large"
          />

          <FTUECard
            cardName="dashboard"
            borderIconId="research-dashboard"
            explanationIconId="research-dashboard-explanation"
            circlePosition={CIRCLE_POSITION.RIGHT}
            showOverlay={true}
          />
        </div>
      </Page>
    );
  }
}
