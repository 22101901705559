import { isNumber, isString } from '@/utils/types';
import { taggedSoftError } from '@/utils/softError';

import Immutable from 'immutable';
import invariant from 'invariant';

import type { FeatureKey } from './Feature';
import type { FeatureRecord } from './FeatureRecord';

export type DynamicFeatureConfig = {
  key: FeatureKey;
  salt: string;
  trafficRatio: number;
};

export type DynamicFeatureConfigMap = Immutable.Map<string, DynamicFeatureConfig>;

const softError = taggedSoftError('featureConfigUtils');

export function parseDynamicConfig(configStr: string): DynamicFeatureConfigMap {
  try {
    const configObj = JSON.parse(configStr);
    const config = Immutable.Map(configObj);
    // @ts-expect-error -- We should be validating this config more
    return config;
  } catch (error) {
    softError('Failed to parse feature config', error);
    return Immutable.Map();
  }
}

export function buildFeatureConfig(
  configStr: string,
  features: Immutable.List<FeatureRecord>
): DynamicFeatureConfigMap {
  const dynamicConfigMap = parseDynamicConfig(configStr);
  return features.reduce((map, feature) => {
    const featureKey = feature.KEY;
    const dynFeatureConfig = dynamicConfigMap.get(featureKey);

    if (dynFeatureConfig) {
      try {
        const featureConfig = validateDynamicFeatureConfig(feature, dynFeatureConfig);
        return map.set(featureKey, featureConfig);
      } catch (error) {
        softError(`config for feature "${featureKey}" is invalid, using fallback`, error.message);
      }
    }

    // Use static config defaults for feature (if dynamic config is missing or invalid)
    return map.set(featureKey, {
      key: featureKey,
      salt: featureKey,
      trafficRatio: feature.fallbackState ? 1 : 0,
    });
  }, Immutable.Map() as DynamicFeatureConfigMap);
}

export function validateDynamicFeatureConfig(
  feature: FeatureRecord,
  dynFeatureConfig: Record<string, unknown>
): DynamicFeatureConfig {
  const featureKey = feature.KEY;
  const { key, salt, trafficRatio } = dynFeatureConfig;

  // Validate "key"
  invariant(isString(key), `config key is invalid for feature [featureKey="${featureKey}"]`);
  invariant(
    key === featureKey,
    `config key mismatch [featureKey="${featureKey}", configKey="${key}"]`
  );

  // Validate "salt"
  invariant(
    isString(salt),
    `salt for feature is invalid [featureKey="${featureKey}", salt=${JSON.stringify(salt)}]`
  );
  invariant(salt.length > 0, `salt for feature is blank [featureKey="${featureKey}"]`);

  // Validate "trafficRatio"
  invariant(
    isNumber(trafficRatio),
    `traffic ratio for feature is invalid [featureKey="${featureKey}", trafficRatio=${JSON.stringify(
      trafficRatio
    )}]`
  );
  invariant(
    0 <= trafficRatio && trafficRatio <= 1,
    `traffic ratio is outside 0 and 1 (inclusive) [featureKey=${featureKey}, trafficRatio=${trafficRatio}]`
  );

  const featureConfig = { key, salt, trafficRatio };
  return featureConfig;
}
