import React from 'react';

export default function IconViewRegular(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 10 12">
      <rect width="10" height="2" rx="1" />
      <rect y="5" width="10" height="2" rx="1" />
      <rect y="10" width="10" height="2" rx="1" />
    </symbol>
  );
}
