import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import Routes from '@/router/Routes';

import React from 'react';

export default class EntityLandingRoute extends React.Component {
  static getPageTitle = () => getString(_ => _.entity.landingPage.title);

  static getCanonicalUrl = () => Routes.ENTITY_LANDING;

  render() {
    return (
      <AsyncLoadedPage
        footer={false}
        load={{
          importer: () =>
            import(/* webpackChunkName: "shared-EntityLandingPage" */ './EntityLandingPage'),
          chunkName: 'shared-EntityLandingPage',
          moduleId: require.resolveWeak('./EntityLandingPage'),
        }}
      />
    );
  }
}
