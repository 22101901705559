import React from 'react';

export default function IconCircleX(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 20 20">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-80.000000, -20.000000)" fill="#FF3333">
          <path d="M90,37 C86.1340068,37 83,33.8659932 83,30 C83,26.1340068 86.1340068,23 90,23 C93.8659932,23 97,26.1340068 97,30 C97,33.8659932 93.8659932,37 90,37 Z M91.5206804,29.9999462 L92.9514535,28.5691967 C93.3717377,28.1493495 93.3717377,27.4683884 92.9514535,27.0485413 C92.5315995,26.628264 91.8510573,26.628264 91.4307731,27.0485413 L90,28.4792909 L88.5692269,27.0485413 C88.1493728,26.628264 87.4679704,26.628264 87.0485465,27.0485413 C86.6282623,27.4683884 86.6282623,28.1493495 87.0485465,28.5691967 L88.4793196,29.9999462 L87.0485465,31.4306958 C86.6282623,31.8509731 86.6282623,32.531504 87.0485465,32.9513511 C87.2580433,33.1617049 87.5333575,33.2666667 87.8086716,33.2666667 C88.0839857,33.2666667 88.3592999,33.1617049 88.5692269,32.9513511 L90,31.5206016 L91.4307731,32.9513511 C91.6407001,33.1617049 91.9160143,33.2666667 92.1913284,33.2666667 C92.4662123,33.2666667 92.7415265,33.1617049 92.9514535,32.9513511 C93.3717377,32.531504 93.3717377,31.8509731 92.9514535,31.4306958 L91.5206804,29.9999462 Z" />
        </g>
      </g>
    </symbol>
  );
}
