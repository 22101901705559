import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

// s2ove venue type:
// https://github.com/search?q=repo%3Aallenai%2FS2OVE%20VenueType&type=code
enum VenueType {
  Journal = 'journal',
  Conference = 'conference',
}

export type VenueFromJS = {
  id: string;
  name: string;
  issn: Nullable<string>;
  url: Nullable<string>;
  type: Nullable<VenueType>;
  alternateNames: string[];
  alternateIssns: string[];
  alternateUrls: string[];
  sourceIds: string[];
};

type Props = {
  id: string;
  name: string;
  issn: Nullable<string>;
  url: Nullable<string>;
  type: Nullable<VenueType>;
  alternateNames: Immutable.List<string>;
  alternateIssns: Immutable.List<string>;
  alternateUrls: Immutable.List<string>;
  sourceIds: Immutable.List<string>;
};

const defaultProperties: Props = {
  id: '',
  name: '',
  issn: null,
  url: null,
  type: null,
  alternateNames: Immutable.List(),
  alternateIssns: Immutable.List(),
  alternateUrls: Immutable.List(),
  sourceIds: Immutable.List(),
};

export const VenueRecordFactory = Immutable.Record<Props>(defaultProperties);
export type VenueRecord = Immutable.RecordOf<Props>;

export function getVenueRecordFromJS(args: VenueFromJS): VenueRecord {
  const { alternateNames, alternateIssns, alternateUrls, sourceIds, ...rest } = args;
  return VenueRecordFactory({
    ...rest,
    alternateNames: Immutable.List(alternateNames),
    alternateIssns: Immutable.List(alternateIssns),
    alternateUrls: Immutable.List(alternateUrls),
    sourceIds: Immutable.List(sourceIds),
  });
}
