import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

export default class AdminManageUsersRoute extends React.Component {
  static getPageTitle = () => getString(_ => _.general.appName);

  static willRouteTo({ api }) {
    return api.getExperiments();
  }

  render() {
    return (
      <AsyncLoadedPage
        showLoadingPage={false}
        load={{
          importer: () =>
            import(/* webpackChunkName: "desktop-AdminManageUsersPage" */ './AdminManageUsersPage'),
          chunkName: 'desktop-AdminManageUsersPage',
          moduleId: require.resolveWeak('./AdminManageUsersPage'),
        }}
      />
    );
  }
}
