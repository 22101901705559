import { LibrarySort, LibrarySortType } from './LibrarySort';

import { DEPRECATED__FlowOptional } from '@/utils/types';

import Immutable from 'immutable';
import url from 'url';

export type RawLibraryEntriesQuery = {
  q: DEPRECATED__FlowOptional<string>;
  sort: DEPRECATED__FlowOptional<LibrarySortType>;
  page: DEPRECATED__FlowOptional<string>;
  pageSize: DEPRECATED__FlowOptional<number>;
};

type Props = {
  q: string;
  sort: LibrarySortType;
  page: number;
  pageSize: number;
};

export const LIBRARY_DEFAULT_PAGE_SIZE = 10;

export const defaultProps: Props = {
  q: '',
  page: 1,
  pageSize: LIBRARY_DEFAULT_PAGE_SIZE,
  sort: LibrarySort.DATE_ADDED_TO_LIBRARY,
};

export const LibraryEntriesQueryRecordFactory = Immutable.Record(defaultProps);
export type LibraryEntriesQueryRecord = Immutable.RecordOf<Props>;

export function getLibraryEntriesQueryFromQueryStringParams(
  args: RawLibraryEntriesQuery
): LibraryEntriesQueryRecord {
  const currentPage = args.page ? parseInt(args.page) : null;
  return LibraryEntriesQueryRecordFactory({
    ...args,
    sort: args.sort || defaultProps.sort,
    q: args.q || defaultProps.q,
    page: currentPage || defaultProps.page,
    pageSize: args.pageSize || defaultProps.pageSize,
  });
}

export function getLibraryEntriesQueryFromPath(path?: string): LibraryEntriesQueryRecord {
  if (!path) {
    return LibraryEntriesQueryRecordFactory();
  }
  const { query } = url.parse(path, /* parseQueryString */ true);
  // url.parse() returns a ParsedUrlQuery Dict, and we assume it has these fields
  return getLibraryEntriesQueryFromQueryStringParams(query as RawLibraryEntriesQuery);
}
