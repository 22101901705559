/**
 * Experiment Cookie Format
 *
 * exp_key_1=control&exp_key_3=test&exp_key_4=-test&exp_key_5=test
 *
 * Experiment |    State    | Variation
 * -----------|-------------|----------
 * exp_key_1  | EXPOSED     | control
 * exp_key_2  | UNEXPOSED   |
 * exp_key_3  | EXPOSED     | test
 * exp_key_4  | TERMINATED  | test
 * exp_key_5  | REMOVED     | test
 */

import {
  ExperimentExposureState,
  EXPOSED,
  REMOVED,
  TERMINATED,
  UNEXPOSED,
} from '@/constants/ExperimentExposureState';
import CookieJar, { DEFAULT_COOKIE_MAX_AGE } from '@/utils/cookies/CookieJar';

import Immutable from 'immutable';
import qs from 'qs';

import type { ExperimentKey, VariationKey } from './Experiment';
import type { ExperimentRecord } from './ExperimentRecord';
import type { Nullable } from '@/utils/types';

export type ExperimentCookie = {
  experimentKey: ExperimentKey;
  variationKey: VariationKey;
  exposureState: ExperimentExposureState;
};

const EXPERIMENT_COOKIE_NAME = 's2Exp';
const TERMINATED_PREFIX = '-';
const DEFAULT_COOKIE_OPTIONS = {
  maxAge: DEFAULT_COOKIE_MAX_AGE,
  path: '/',
};

export default class ExperimentCookieManager {
  _cookieJar: CookieJar;
  _experiments: Immutable.Map<ExperimentKey, ExperimentRecord>;

  constructor({
    cookieJar,
    experimentList,
  }: {
    cookieJar: CookieJar;
    experimentList: Immutable.List<ExperimentRecord>;
  }) {
    this._cookieJar = cookieJar;
    this._experiments = experimentList.reduce((map, exp) => map.set(exp.KEY, exp), Immutable.Map());
  }

  // Look up the state of an experiment
  getExperimentCookie(key: ExperimentKey): Nullable<ExperimentCookie> {
    return this._getExpCookie(key);
  }

  getExperimentCookiesMap(keys: ExperimentKey[]): { [key in ExperimentKey]?: ExperimentCookie } {
    const results = {};
    const cookieObj = this._readCookie();
    for (const key of keys) {
      if (cookieObj[key]) {
        results[key] = cookieObj[key];
      }
    }
    return results;
  }

  // Mark an experiment as exposed
  markAsExposed(experimentKey: ExperimentKey, variationKey: VariationKey): ExperimentCookie {
    let expCookie = this._getExpCookie(experimentKey);
    if (!expCookie) {
      expCookie = {
        experimentKey,
        variationKey,
        exposureState: EXPOSED,
      };
    } else {
      expCookie.variationKey = variationKey;
      expCookie.exposureState = EXPOSED;
    }
    this._setExpCookie(expCookie);
    return expCookie;
  }

  // Mark an experiment as terminated
  markAsTerminated(experimentKey: ExperimentKey, variationKey: VariationKey): ExperimentCookie {
    let expCookie = this._getExpCookie(experimentKey);
    if (!expCookie) {
      expCookie = {
        experimentKey,
        variationKey,
        exposureState: TERMINATED,
      };
    } else {
      expCookie.variationKey = variationKey;
      expCookie.exposureState = TERMINATED;
    }
    this._setExpCookie(expCookie);
    return expCookie;
  }

  // Delete removed experiments from the cookie
  cleanupRemovedExperiments(): void {
    const cookieObj = this._readCookie();
    const expCookieList = Object.values(cookieObj);
    const removedExpKeys = expCookieList
      .filter(_ => _.exposureState === REMOVED)
      .map(_ => _.experimentKey);
    for (const removedExpKey of removedExpKeys) {
      delete cookieObj[removedExpKey];
    }
    this._writeCookie(cookieObj);
  }

  // Clear the cookie related to a given experiment
  clearExperimentCookie(key: ExperimentKey): void {
    this._unsetExpCookie(key);
  }

  // Look up an experiment's state from the cookie
  _getExpCookie(key: ExperimentKey): Nullable<ExperimentCookie> {
    const expCookies = this._readCookie();
    return expCookies[key] || null;
  }

  // Update an experiment's state in the cookie
  _setExpCookie(cookie: ExperimentCookie): void {
    const expCookies = this._readCookie();
    expCookies[cookie.experimentKey] = cookie;
    this._writeCookie(expCookies);
  }

  // Remove an experiment from the cookie
  _unsetExpCookie(key: ExperimentKey): void {
    const expCookies = this._readCookie();
    delete expCookies[key];
    this._writeCookie(expCookies);
  }

  // Read and parse the experiment states from the cookie
  _readCookie(): { [key in ExperimentKey]?: ExperimentCookie } {
    const cookieStr = this._cookieJar.getCookie(EXPERIMENT_COOKIE_NAME);
    const expCookies = this._parseCookieStr(cookieStr || '');
    return expCookies;
  }

  // Save the state of experiments to the cookie
  _writeCookie(expCookies: { [key in ExperimentKey]?: ExperimentCookie }): void {
    const cookieStr = this._buildCookieStr(expCookies);
    this._cookieJar.saveCookie(EXPERIMENT_COOKIE_NAME, cookieStr, DEFAULT_COOKIE_OPTIONS);
  }

  // Encode experiment states as a cookie string
  _buildCookieStr(expCookies: { [key in ExperimentKey]?: ExperimentCookie }): string {
    const cookieList = Object.values(expCookies);
    const cookieObj = {};
    for (const expCookie of cookieList) {
      const value = (() => {
        switch (expCookie.exposureState) {
          case REMOVED:
            return null;
          case TERMINATED:
            return expCookie.variationKey ? TERMINATED_PREFIX + expCookie.variationKey : null;
          default:
            return expCookie.variationKey;
        }
      })();
      if (value) {
        cookieObj[expCookie.experimentKey] = value;
      }
    }
    const cookieStr = qs.stringify(cookieObj);
    return cookieStr;
  }

  // Extract the state of experiments from the cookie string
  _parseCookieStr(cookieStr: string): { [key in ExperimentKey]?: ExperimentCookie } {
    const expCookies = {};

    // Find experiments within the cookie
    const cookieObj = qs.parse(cookieStr);
    for (const [key, value] of Object.entries(cookieObj)) {
      if (!value || typeof value !== 'string') {
        // Unexposed leaked into the cookie
        continue;
      }
      const isTerminated = value.indexOf(TERMINATED_PREFIX) === 0;
      const variationKey = value.substr(isTerminated ? 1 : 0);
      const exp = this._experiments.get(key as any);

      if (!exp) {
        // Experiment was removed from code
        expCookies[key] = {
          experimentKey: key,
          variationKey,
          exposureState: REMOVED,
        };
      } else {
        // Experiment was exposed before
        expCookies[exp.KEY] = {
          experimentKey: exp.KEY,
          variationKey,
          exposureState: isTerminated ? TERMINATED : EXPOSED,
        };
      }
    }

    // Find experiments missing from cookie
    for (const expKey of this._experiments.keys()) {
      if (!expCookies.hasOwnProperty(expKey)) {
        // Experiment was never exposed
        expCookies[expKey] = {
          experimentKey: expKey,
          variationKey: null,
          exposureState: UNEXPOSED,
        };
      }
    }

    return expCookies;
  }
}
