import { showOrganizePapersShelf, showSaveToLibraryShelf } from './ShelfActionCreators';
import { showPageErrorMessage, SUCCESS } from './MessageActionCreators';

import {
  encodeNotRelevantTriggerContext,
  getNotRelevantTriggerContextFromJS,
  NotRelevantTriggerContext,
  NotRelevantTriggerContextProps,
} from '@/models/query-trigger/NotRelevantTriggerContext';
import {
  encodeSaveToLibraryTriggerContext,
  getSaveToLibraryTriggerContextFromJS,
  SaveToLibraryTriggerContext,
  SaveToLibraryTriggerContextFactory,
  SaveToLibraryTriggerContextProps,
} from '@/models/query-trigger/SaveToLibraryTriggerContext';
import { getAddLibraryEntryBulkRequestFromJS } from '@/models/library/AddLibraryEntryBulkRequest';
import { getPaperFromJS, PAPER_RECORD_NAME, PaperishRecord } from '@/models/Paper';
import { getPaperTitleStr } from '@/utils/paper-util';
import { getString } from '@/content/i18n';
import { heapLibraryRemoveFromLibraryUndoButton } from '@/analytics/attributes/libraryPage';
import { isAllPapersFolder } from '@/models/library/LibraryFolderSourceType';
import { LibraryFolderRecord } from '@/models/library/LibraryFolder';
import { NotRelevant } from '@/models/library/AnnotationType';
import { Recommendation } from '@/models/library/LibraryEntrySourceType';
import { searchStringToObj } from '@/router/routerUtils';
import AppContext from '@/AppContext';
import CLButton, { TYPE } from '@/components/library/button/CLButton';
import Routes, { makePath } from '@/router/Routes';
import S2Redirect from '@/models/redirects/S2Redirect';
import softError from '@/utils/softError';

import Immutable from 'immutable';
import React from 'react';

import type { DEPRECATED__FlowOptional, Nullable } from '@/utils/types';
import type { DispatchPayload } from '@/utils/S2Dispatcher';

const SAVE_TO_LIBRARY = 'SAVE_TO_LIBRARY';
const NOT_RELEVANT = 'NOT_RELEVANT';

export const allQueryTriggerIds = {
  NOT_RELEVANT,
  SAVE_TO_LIBRARY,
} as const;

type DispatchesOrRedirect = DispatchPayload[] | S2Redirect;
type TriggerId = keyof typeof allQueryTriggerIds;

/* This function checks for a `trigger` and `trigger_context` query string to trigger an action
 * Example actions: save to library, create an alert, open modal, etc.
 * `trigger_context` is an url encoded JSON string
 *
 * To add a new trigger action:
 *  1. Create a new triggerId const and add it to the `allQueryTriggerIds` obj above.
 *  2. Build a trigger context record if applicable. See SaveToLibraryTriggerContext as an example.
 *     Contexts carry information required to perform a trigger action. Not all triggers will require a context.
 *  3. Create a new helper function to dispatch your trigger action. See `triggerSaveToLibrary` as an example.
 *  4. Add a case to the switch statement in `validateAndBuildActionCreator` that calls the function created in step 3.
 */
export async function checkAndDispatchTriggersFromQueryString(
  appContext: AppContext
): Promise<DispatchesOrRedirect> {
  const { history } = appContext;

  // Pull trigger and context from query string
  const { trigger: rawTriggerId, trigger_context: rawTriggerContext } = searchStringToObj(
    history.location.search
  );

  // Parse trigger
  const triggerId = rawTriggerId ? getTriggerId(rawTriggerId) : null;
  if (!triggerId) {
    return [];
  }
  let triggerContextObj;
  try {
    triggerContextObj = rawTriggerContext ? JSON.parse(rawTriggerContext) : {};
  } catch (error) {
    softError('query-trigger-parse-failure', 'failed to parse the trigger context', error);
    return [];
  }

  return await validateAndBuildActionCreator({ triggerId, triggerContextObj, appContext });
}

export function getTriggerId(triggerId: string): DEPRECATED__FlowOptional<TriggerId> {
  const trigger = allQueryTriggerIds[triggerId];

  if (!trigger) {
    softError('query-trigger-request', `triggerId is unsupported [trigger=${triggerId}]`);
  }

  return trigger;
}

async function validateAndBuildActionCreator({
  triggerId,
  triggerContextObj,
  appContext,
}: {
  triggerId: TriggerId;
  triggerContextObj?: SaveToLibraryTriggerContextProps | NotRelevantTriggerContextProps;
  appContext: AppContext;
}): Promise<DispatchesOrRedirect> {
  switch (triggerId) {
    case SAVE_TO_LIBRARY: {
      let triggerContext = SaveToLibraryTriggerContextFactory();

      try {
        triggerContext = getSaveToLibraryTriggerContextFromJS(
          triggerContextObj as SaveToLibraryTriggerContextProps
        );
      } catch (error) {
        softError(
          'save-to-library-trigger-parse-failure',
          'failed to parse the trigger context to a SaveToLibraryTriggerContext',
          error
        );
        return [];
      }

      return await triggerSaveToLibrary(triggerContext, appContext);
    }
    case NOT_RELEVANT: {
      try {
        const triggerContext = getNotRelevantTriggerContextFromJS(
          triggerContextObj as NotRelevantTriggerContextProps
        );
        return await triggerNotRelevant(triggerContext, appContext);
      } catch (error) {
        softError(
          'not-relevant-trigger-parse-failure',
          'failed to parse the trigger context to a NotRelevantTriggerContext',
          error
        );
        return [];
      }
    }
    default:
      return [];
  }
}

/*
  An example of how to use this trigger would be
  https://semanticscholar.org?trigger=SAVE_TO_LIBRARY&trigger_context=%7B%22paperId%22%3A%20%226b55edf40b1239e45876347779f08190aea54652%22%7D'
  where the trigger_context was generated by url encoding a JSON like { paperId: 'paper123', folderId: 2 }
*/
export async function triggerSaveToLibrary(
  triggerContext: SaveToLibraryTriggerContext,
  appContext: AppContext
): Promise<DispatchesOrRedirect> {
  const dispatches: DispatchPayload[] = [];
  const { api, authStore, dispatcher, libraryFolderStore, paperStore } = appContext;

  // ensure user is logged in before opening shelf
  if (!authStore.getUser()) {
    return getSaveToLibraryLoginRedirect(triggerContext);
  }

  // wait on store to be loaded so we know if a paper already exists in library to show the correct shelf
  await libraryFolderStore.ready();

  // Fetch paper from PaperStore is it's already loaded otherwise from API
  const paperFromStore = paperStore.getPaperDetail()?.paper;
  let paper: Nullable<PaperishRecord> = null;

  if (paperFromStore && paperFromStore.id === triggerContext.paperId) {
    paper = paperFromStore;
  } else {
    const res = await api.fetchPapersByIds({
      paperIds: [triggerContext.paperId],
      model: 'Paper',
    });
    const rawPaper = res?.resultData?.papers?.[0];
    paper = rawPaper ? getPaperFromJS(rawPaper) : null;

    dispatches.push(res);
  }

  if (!paper) {
    softError(
      'save-to-library-query-trigger',
      `No paper found with [paperId=${triggerContext.paperId}]`
    );
    return dispatches;
  }

  const isInLibrary = libraryFolderStore.isPaperInLibrary(paper.id);

  // If there are selected folder ids, grab the records form the folder store
  let selectedFolders = Immutable.Set<LibraryFolderRecord>();
  if (triggerContext.folderId) {
    const folder = libraryFolderStore.getFolderById(triggerContext.folderId);
    if (folder && !isAllPapersFolder(folder)) {
      selectedFolders = Immutable.Set.of(folder);
    }
  }

  const action = showShelfForSaveToLibraryTrigger(isInLibrary, paper, selectedFolders);

  dispatches.push(action);
  dispatcher.dispatch(action);

  return dispatches;
}

export function showShelfForSaveToLibraryTrigger(
  isInLibrary: boolean,
  paper: PaperishRecord,
  selectedFolders: Immutable.Set<LibraryFolderRecord>
): DispatchPayload {
  if (isInLibrary) {
    return showOrganizePapersShelf({
      paperId: paper.id,
      paperTitle: getPaperTitleStr(paper),
    });
  } else {
    return showSaveToLibraryShelf({
      paper,
      selectedFolders,
    });
  }
}

function undoAnnotatePaperNotRelevant(
  paper: PaperishRecord,
  folder: LibraryFolderRecord,
  appContext: AppContext
): void {
  const { api, messageStore } = appContext;
  const entryRequest = getAddLibraryEntryBulkRequestFromJS({
    paperId: paper.id,
    paperTitle: getPaperTitleStr(paper),
    sourceType: Recommendation,
    folderIds: [folder.id],
    annotationState: null,
  });
  api
    .annotateLibraryEntry(entryRequest)
    .then(() => {
      // A really roundabout way to clear just one message...
      const successMessages = messageStore.getMessageOfType(SUCCESS);
      successMessages.forEach(msg => {
        // matching the whole message doesn't work for some reason, so just check for the paper title.
        if (msg.text.includes(getPaperTitleStr(paper))) {
          messageStore.removeMessage(msg);
        }
      });
      // Refresh the annotations
      api.getLibraryAnnotationEntries(NotRelevant);
    })
    .catch(error => {
      softError('not-relevant-query-trigger-undo', `Error undoing annotation [error=${error}]`);
    });
}

function showNotRelevantSuccessMessage(
  paper: PaperishRecord,
  folder: LibraryFolderRecord,
  appContext: AppContext
): void {
  const { messageStore } = appContext;
  const undoButton = (
    <CLButton
      type={TYPE.TERTIARY}
      label={getString(_ => _.recommendations.notRelevantPopup.undoLabel)}
      {...heapLibraryRemoveFromLibraryUndoButton()}
      onClick={() => {
        undoAnnotatePaperNotRelevant(paper, folder, appContext);
      }}
      className="remove-from-library__undo-button"
      testId="undo-remove-from-library"
    />
  );
  messageStore.addSuccess(
    getString(
      _ => _.recommendations.notRelevantPopup.message,
      getPaperTitleStr(paper),
      folder.name
    ),
    getString(_ => _.recommendations.notRelevantPopup.title),
    undoButton
  );
}

export async function triggerNotRelevant(
  triggerContext: NotRelevantTriggerContext,
  appContext: AppContext
): Promise<DispatchesOrRedirect> {
  const dispatches: DispatchPayload[] = [];
  const {
    api,
    authStore,
    dispatcher,
    libraryFolderStore,
    libraryRecommendationsStore,
    paperStore,
  } = appContext;

  // ensure user is logged in before opening shelf
  if (!authStore.getUser()) {
    return getNotRelevantLoginRedirect(triggerContext);
  }

  // wait on stores to be loaded
  await libraryFolderStore.ready();
  // The recommendations page also calls this on route change,
  // but we also need that data here.
  if (libraryRecommendationsStore.isRecommendationsAnnotationsUninitialized()) {
    await api.getLibraryAnnotationEntries(NotRelevant);
  }

  // Check whether the specified folder exists and is owned by the user
  const folder = libraryFolderStore.getFolderById(triggerContext.folderId);
  if (!folder) {
    softError(
      'not-relevant-query-trigger',
      `No folder found with [folderId=${triggerContext.folderId}]`
    );
    return dispatches;
  }

  // Fetch paper from PaperStore is it's already loaded otherwise from API
  const paperFromStore = paperStore.getPaperDetail()?.paper;
  let paper: Nullable<PaperishRecord> = null;

  if (paperFromStore && paperFromStore.id === triggerContext.paperId) {
    paper = paperFromStore;
  } else {
    const res = await api.fetchPapersByIds({
      paperIds: [triggerContext.paperId],
      model: PAPER_RECORD_NAME,
    });
    const rawPaper = res?.resultData?.papers?.[0];
    paper = rawPaper ? getPaperFromJS(rawPaper) : null;

    dispatches.push(res);
  }

  if (!paper) {
    softError(
      'not-relevant-query-trigger',
      `No paper found with [paperId=${triggerContext.paperId}]`
    );
    return dispatches;
  }

  const isAnnotated = libraryRecommendationsStore.isPaperNotRelevantInFolder(paper.id, folder.id);
  if (isAnnotated) {
    // Due to redirection madness, treat this as a successful annotation case.
    showNotRelevantSuccessMessage(paper, folder, appContext);
    return dispatches;
  }

  const entryRequest = getAddLibraryEntryBulkRequestFromJS({
    paperId: paper.id,
    paperTitle: getPaperTitleStr(paper),
    sourceType: Recommendation,
    folderIds: [folder.id],
    annotationState: NotRelevant,
  });
  api
    .annotateLibraryEntry(entryRequest)
    .then(() => {
      // Refresh the annotation statuses
      api.getLibraryAnnotationEntries(NotRelevant);
      // @ts-expect-error -- paper should never be null at this point...
      showNotRelevantSuccessMessage(paper, folder.id, appContext);
    })
    .catch(() => {
      const messagePayload = showPageErrorMessage({
        text: getString(_ => _.research.recommendations.annotateErrorMessage),
      });
      dispatcher.dispatch(messagePayload);
    });

  return dispatches;
}

// HACK: route user to static login page and then redirect them to the PDP url with the trigger query params after logging in
// This function is tightly coupled to the SaveToLibrary trigger just to get this feature out
// TODO (#27895): redesign a more generic/better way to handle users that are not logged in
export function getSaveToLibraryLoginRedirect(
  triggerContext: SaveToLibraryTriggerContext
): S2Redirect {
  const redirectUrl = makePath({
    path: Routes.PAPER_DETAIL_BY_ID,
    params: {
      paperId: triggerContext.paperId,
    },
    query: {
      trigger: SAVE_TO_LIBRARY,
      trigger_context: encodeSaveToLibraryTriggerContext(triggerContext),
    },
  });

  return new S2Redirect({
    path: Routes.SIGN_IN,
    query: { next: redirectUrl },
    replace: true,
  });
}

// TODO: Find a way to combine this and the function above
export function getNotRelevantLoginRedirect(triggerContext: NotRelevantTriggerContext): S2Redirect {
  const redirectUrl = makePath({
    path: Routes.RECOMMENDATIONS,
    query: {
      folderIds: triggerContext.folderId,
      trigger: NOT_RELEVANT,
      trigger_context: encodeNotRelevantTriggerContext(triggerContext),
    },
  });

  return new S2Redirect({
    path: Routes.SIGN_IN,
    query: { next: redirectUrl },
    replace: true,
  });
}
