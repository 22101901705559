import { addHeapProps } from '@/components/util/HeapTracking';
import { getString } from '@/content/i18n';
import CLIconButton, { TYPE } from '@/components/library/button/CLIconButton';
import Icon from '@/components/shared/icon/Icon';

import classnames from 'classnames';
import React from 'react';

export default function CLPaperCiteButton({ onClick, className, heapProps, testId, icon }) {
  return (
    <CLIconButton
      aria-label={getString(_ => _.paper.action.citeAriaLabel)}
      type={TYPE.TERTIARY}
      label={getString(_ => _.paper.action.cite)}
      icon={icon ? icon : <Icon icon="fa-quote" height="11" width="11" />}
      onClick={onClick}
      testId={testId}
      className={classnames('cl-paper-action__button', className)}
      {...(heapProps ? addHeapProps(heapProps) : {})}
    />
  );
}

CLPaperCiteButton.defaultProps = {
  testId: 'cite-link',
};
