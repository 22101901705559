import { PaperishRecord } from '@/models/Paper';
import EventTarget from '@/analytics/constants/EventTarget';
import trackRicoEvent from '@/analytics/trackRicoEvent';

// This file is a collection of the methods used to track Rico events on Paper elements

export function trackCitationDownloadClick(
  papers: Immutable.List<PaperishRecord>,
  citationStyle: string
) {
  papers.forEach(paper => {
    const eventData = {
      paperId: paper.id,
      citationStyle,
    };
    trackWithRico(EventTarget.PaperDetail.Cite.DOWNLOAD_BUTTON, eventData);
  });
}

export function trackCitationCopyClick(paperId: string, citationStyle: string) {
  const eventData = {
    paperId,
    citationStyle,
  };
  trackWithRico(EventTarget.PaperDetail.Cite.COPY_BUTTON, eventData);
}

export function trackSemanticReaderButtonClick(paperId: string) {
  const eventData = {
    paperId,
  };

  trackWithRico(EventTarget.PaperDetail.Reader.Button.CLICK, eventData);
}

function trackWithRico(eventName: string, data: object) {
  trackRicoEvent(eventName, data);
}
