import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

export type FigureFromJS = {
  caption?: Nullable<string>;
  name: string;
  figureType: string;
  uri?: Nullable<string>;
  cdnUri: string;
  height?: Nullable<number>;
  width?: Nullable<number>;
};

export type Props = {
  caption: string;
  name: string;
  displayName: string;
  figureType: string;
  uri: string;
  cdnUri: string;
  height: Nullable<number>;
  width: Nullable<number>;
};

const defaultProps: Props = {
  caption: '',
  // This is generally an index, ie "1.1"
  name: '',
  displayName: '',
  figureType: '',
  uri: '',
  cdnUri: '',
  height: null,
  width: null,
};

export const FigureRecordFactory = Immutable.Record<Props>(defaultProps);
export type FigureRecord = Immutable.RecordOf<Props>;

export function getFigureLinkedData(figure: FigureRecord): object {
  // TODO(markschaake): we do not have a 'name' for figures
  // even though there is a 'name' field. The 'name' field is actually
  // a one-based index for the image.
  return {
    '@type': 'ImageObject',
    caption: figure.caption,
    contentUrl: figure.cdnUri,
    url: figure.uri,
    height: figure.height,
    width: figure.width,
  };
}

export function getFigureFromJS(args: FigureFromJS): FigureRecord {
  return FigureRecordFactory({
    ...args,
    caption: args.caption ? args.caption : defaultProps.caption,
    uri: args.uri ? args.uri : defaultProps.uri,
    displayName: `${args.figureType} ${args.name}`,
  });
}
