import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

export default class AdminFeatureFlagsRoute extends React.Component {
  static getPageTitle = () => getString(_ => _.general.appName);

  static willRouteTo({ api }) {
    return api.getFeatureFlags();
  }

  render() {
    return (
      <AsyncLoadedPage
        showLoadingPage={false}
        load={{
          importer: () =>
            import(
              /* webpackChunkName: "desktop-AdminFeatureFlagsPage" */ './AdminFeatureFlagsPage'
            ),
          chunkName: 'desktop-AdminFeatureFlagsPage',
          moduleId: require.resolveWeak('./AdminFeatureFlagsPage'),
        }}
      />
    );
  }
}
