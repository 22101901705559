import RobotDirectives from './RobotDirectives';

import { DispatchPayload } from '@/utils/S2Dispatcher';
import { Nullable } from '@/utils/types';
import { RoleValue } from '@/constants/Role';
import { SchemaData } from '@/utils/routing/schema';
import AppContext from '@/AppContext';
import Redirect from '@/models/redirects/Redirect';

import type { RouteConfig } from 'react-router-config';

export type RouterState = {
  isExact: boolean;
  params: { [key: string]: any };
  path: string;
  query: { [key: string]: string | undefined };
  url: string;
};

export type WillRouteToResult = DispatchPayload | Redirect | null | void | WillRouteToResult[];

export type WillRouteTo = (
  appContext: AppContext,
  routerState: RouterState
) => WillRouteToResult | Promise<WillRouteToResult>;

export type GetPageTitle = (appContext: AppContext, routerState: RouterState) => Nullable<string>;

export type CitationMeta = {
  name: string;
  content: string;
};

export type GetPageCitationMeta = (
  appContext: AppContext
) => Nullable<Immutable.List<CitationMeta>>;

export type GetPageMetaDescription = (appContext: AppContext) => Nullable<string | undefined>;

export type DublinCoreMeta = {
  dublinCoreId: Nullable<string>;
  showDublinCoreRelation: boolean;
};

export type GetDublinCoreMeta = (appContext: AppContext) => DublinCoreMeta;

export type GetAlternativePdfUrl = (appContext: AppContext) => Nullable<string | undefined>;

export type GetCanonicalUrl = (routerState: RouterState) => Nullable<string | undefined>;

export type GetRobotDirectives = (robotDirectives: RobotDirectives, appContext: AppContext) => any;

export type GetSchemaData = (
  routerState: RouterState,
  appContext: AppContext
) => Nullable<SchemaData>;

export type GetPageFigure = (appContext: AppContext) => Nullable<string | undefined>;

type RequiresAuthentication = () => Nullable<boolean>;

export type RequiresRoles = () => RoleValue[];

export type RouteArguments = {
  component: RouteComponentStatics & React.ComponentType<any>;
} & RouteConfig;

export type RouteType = RouteConfig & RouteComponentStatics;

export interface RouteComponentStatics {
  readonly willRouteTo?: Nullable<WillRouteTo>;
  readonly getPageTitle?: Nullable<GetPageTitle>;
  readonly getPageCitationMeta?: Nullable<GetPageCitationMeta>;
  readonly getPageMetaDescription?: Nullable<GetPageMetaDescription>;
  readonly getDublinCoreMeta?: Nullable<GetDublinCoreMeta>;
  readonly getAlternativePdfUrl?: Nullable<GetAlternativePdfUrl>;
  readonly getCanonicalUrl?: Nullable<GetCanonicalUrl>;
  readonly getSchemaData?: Nullable<GetSchemaData>;
  readonly getRobotDirectives?: Nullable<GetRobotDirectives>;
  readonly getPageFigure?: Nullable<GetPageFigure>;
  readonly requiresAuthentication?: Nullable<RequiresAuthentication>;
  readonly requiresRoles?: Nullable<RequiresRoles>;
}

const Route = ({ path, component, exact, routes }: RouteArguments): RouteType => ({
  // Any valid URL path that path-to-regexp understands.
  // See: https://reacttraining.com/react-router/web/api/Route/path-string
  path,

  // A React component to render only when the location matches.
  // See: https://reacttraining.com/react-router/web/api/Route/component
  component,

  // When true, will only match if the path matches the location.pathname exactly.
  // See: https://reacttraining.com/react-router/web/api/Route/exact-bool
  exact,

  // {Route[]} - an array of nested routes
  routes,

  /**
   * Invoked as to provide an opportunity to fetch data required for the page and/or initialize the
   * state of stores. Should return a promise indicating when any asynchronous actions associated
   * with the page being loaded are complete.  Can return an `S2Redirect` or a `Redirect` to
   * indicate that the page should be redirected.
   *
   * @param {AppContext} appContext - the current application context instance
   * @param {Object} match - { params, isExact, path, url, query } contains information about how a
   *                         <Route path> matched the URL.
   * @return {Promise} - a promise which indicates whether the associated functionality required for
   *                     the page is complete (or failed).
   */
  willRouteTo: component.willRouteTo,

  /**
   * Gets the page title for the route.
   *
   * @param {AppContext} appContext - the current application context instance
   * @return {String} - the page title
   */
  getPageTitle: component.getPageTitle,

  /**
   * Gets the citation metadata for the route.
   *
   * @param {AppContext} appContext - the current application context instance
   * @param {Array[Object]} - an array containing objects consisting of a meta tag name and content
   */
  getPageCitationMeta: component.getPageCitationMeta,

  /**
   * Gets the meta description for the route.
   *
   * @param {AppContext} appContext - the current application context instance
   * @param {String} - the meta description
   */
  getPageMetaDescription: component.getPageMetaDescription,

  /**
   * Gets Dublin Core metadata of a paper.
   *
   * @param {AppContext} appContext - the current application context instance
   * @return {Object} - an object containing Dublin Core metadata
   */
  getDublinCoreMeta: component.getDublinCoreMeta,

  /**
   * Gets alternative pdf url  of a paper.
   *
   * @param {AppContext} appContext - the current application context instance
   * @return {String} - alternative pdf url of a paper
   */
  getAlternativePdfUrl: component.getAlternativePdfUrl,

  /**
   * Gets the canonical url for the route.
   *
   * @param {Object} match - { params, isExact, path, url, query } contains information about how a
   *                         <Route path> matched the URL.
   * @return {String} - the url
   */
  getCanonicalUrl: component.getCanonicalUrl,

  /**
   * Gets the schema data for the route.
   *
   * @param {AppContext} appContext - the current application context instance
   * @param {Object} match - { params, isExact, path, url, query } contains information about how a
   *                         <Route path> matched the URL.
   * @return {String} - the page linked data
   */
  getSchemaData: component.getSchemaData,

  /**
   * Get the commands used to control indexing and serving of a snippet.
   *
   * @param {RobotDirectives} robots - the state for directives to be sent
   * @param {AppContext} appContext - the current application context instance
   * @return {String} - the page linked data
   */
  getRobotDirectives: component.getRobotDirectives,

  /**
   * Gets the page title for the route.
   *
   * @param {AppContext} appContext - the current application context instance
   * @return {String} - the page figure
   */
  getPageFigure: component.getPageFigure,

  /**
   * Invoked to tell the router that the page requires the user to be authenticated in order to
   * fetch data. If a logged out user attempts to access this page, they will be shown a 401 page
   * with a login button. Logged in users will see the expected content.
   *
   * @return {boolean} - if true, tells the app to render a 401 error when the user isn't logged in.
   */
  requiresAuthentication: component.requiresAuthentication,

  /**
   * Get a list of roles, one of which the signed in user must have. Nested routes uses an
   * intersection of roles. Overrides requiresAuthentication, if set.
   */
  requiresRoles: component.requiresRoles,
});

export default Route;
