import AuthorCardPopover from './AuthorCardPopover';

import { AUTHOR_BOX_CLASS_NAME } from '@/components/library/paper/CLPaperAuthors';
import { AuthorCardRecord } from '@/models/author/AuthorCard';
import {
  AuthoredLibraryPaperCue,
  CoauthorCue,
  CueType,
  Unsupported,
  YouCitedAuthorCue,
} from '@/constants/CueType';
import { fetchAuthorCards } from '@/actions/AuthorActionCreators';
import { heapShowAuthorCardButton } from '@/analytics/attributes/authorCardPopover';
import { KEY_CODE_ESC } from '@/constants/KeyCode';
import { mapHooksToProps } from '@/utils/react-utils';
import { MAX_PAPERS_SHOWN } from '@/components/shared/paper/CuePapersList';
import { mkOnClickKeyDown } from '@/utils/a11y-utils';
import { Nullable, ReactNodeish } from '@/utils/types';
import { useAppContext, useStateFromStore } from '@/AppContext';
import Api from '@/api/Api';
import AuthorCardStore from '@/stores/author/AuthorCardStore';
import browser from '@/browser';
import CLButton from '@/components/library/button/CLButton';
import CLPortal, { Coordinates } from '@/components/library/popover/CLPortal';
import CueStore, { AuthorId, PaperId } from '@/stores/CueStore';
import Experiment from '@/weblab/Experiment';
import GraphQLApi from '@/api/GraphQLApi';
import Icon from '@/components/shared/icon/Icon';
import Link from '@/router/Link';

import classNames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import type { LibraryEntryRecord } from '@/models/library/LibraryEntry';
import type { PaperishRecord } from '@/models/Paper';

const POPOVER_DELAY_MS = 500;
const POPOVER_ARROW_OFFSET = 18;

type PropsFromParent = React.PropsWithChildren<{
  shouldOpenInNewTab?: Nullable<boolean>;
  onClick: (id: string) => void;
  showVerifiedCheckmark?: Nullable<boolean>;
  className?: Nullable<string>;
  authorId: AuthorId;
  slug: string;
  onWhiteBackground: boolean;
  paperIds: Immutable.List<PaperId>;
  paper?: Nullable<PaperishRecord>;
  isAuthorPersonalizedEnabled: boolean;
}>;

type Props = {
  isAuthorCueLoading: boolean;
  isAuthorPersonalized: boolean;
  isAuthorCardLoading: boolean;
  isOnClickEnabled: boolean;
  isUninitialized?: Nullable<boolean>;
  authorCard?: Nullable<AuthorCardRecord>;
  libraryEntriesMap: Immutable.Map<PaperId, LibraryEntryRecord>;
  prioritizedCue: CueType;
} & PropsFromParent;

type State = {
  isAuthorCardVisible: boolean;
  isClaimed: boolean;
};

export class AuthorCardActionPopover extends React.PureComponent<Props, State> {
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    authorCardStore: PropTypes.instanceOf(AuthorCardStore).isRequired,
    cueStore: PropTypes.instanceOf(CueStore).isRequired,
    graphQLApi: PropTypes.instanceOf(GraphQLApi).isRequired,
  };

  showTimer: NodeJS.Timeout;

  declare context: {
    api: Api;
    authorCardStore: AuthorCardStore;
    cueStore: CueStore;
    graphQLApi: GraphQLApi;
  };

  static defaultProps: {
    shouldOpenInNewTab: false;
    showVerifiedCheckmark: false;
    paperIds: Immutable.List<PaperId>;
  };

  #authorCardPopover = React.createRef<HTMLSpanElement>();

  constructor(...args: [any]) {
    super(...args);

    this.state = {
      isAuthorCardVisible: false,
      isClaimed: false,
    };
  }

  getAuthorCardFromGraphQL = (): void => {
    const { isAuthorCardVisible } = this.state;
    const { authorId, authorCard } = this.props;
    const { graphQLApi } = this.context;

    if (!isAuthorCardVisible && authorId && !authorCard) {
      fetchAuthorCards({ authorIds: Immutable.List([authorId]) }, { graphQLApi }, { limit: 1 });
    }
  };

  getLibraryCueEntriesData = async (paperIds: Immutable.List<PaperId>): Promise<void> => {
    const { libraryEntriesMap } = this.props;
    const { api } = this.context;

    const missingPaperIds = paperIds.filter(paperId => !libraryEntriesMap.get(paperId));

    if (missingPaperIds.isEmpty()) {
      return;
    }
    await api.fetchLibraryCueData(missingPaperIds);
  };

  getAuthorsCuePaperData = async (): Promise<void> => {
    const { api } = this.context;
    const { authorId, prioritizedCue, paperIds } = this.props;

    if (!paperIds.isEmpty()) {
      return;
    }
    await api.fetchAuthorCueData(authorId, prioritizedCue);
  };

  getCueInformationByCueType = () => {
    const { paperIds, prioritizedCue } = this.props;
    if (!paperIds) {
      return null;
    }
    switch (prioritizedCue) {
      case CoauthorCue:
      case YouCitedAuthorCue: {
        this.getAuthorsCuePaperData();
        break;
      }
      case AuthoredLibraryPaperCue: {
        const papersToDisplay = paperIds.take(MAX_PAPERS_SHOWN);
        this.getLibraryCueEntriesData(papersToDisplay);
        break;
      }
    }
  };

  showAuthorCardPopover = () => {
    clearTimeout(this.showTimer);
    this.getAuthorCardFromGraphQL();
    this.showTimer = setTimeout(() => {
      this.setState({
        isAuthorCardVisible: true,
      });
      this.getCueInformationByCueType();
    }, POPOVER_DELAY_MS);
  };

  closeAuthorCardPopover = (): void => {
    clearTimeout(this.showTimer);
    this.setState({
      isAuthorCardVisible: false,
    });
  };

  handleBlur = (event: React.FocusEvent<HTMLDivElement>): void => {
    if (!event.currentTarget.contains(event.relatedTarget as Element)) {
      this.closeAuthorCardPopover();
    }
  };

  handleFocus = (): void => {
    clearTimeout(this.showTimer);
    this.getAuthorCardFromGraphQL();
    this.setState({
      isAuthorCardVisible: true,
    });
  };

  onClickShowAuthorCardPopover = () => {
    clearTimeout(this.showTimer);
    this.getAuthorCardFromGraphQL();
    this.getCueInformationByCueType();
    this.setState({
      isAuthorCardVisible: true,
    });
  };

  getBottomLeftCoordinates = (element: Nullable<HTMLElement>): Coordinates => {
    if (!element) {
      return {};
    }
    const coordinates = element.getBoundingClientRect();
    return {
      leftPx: coordinates.left + POPOVER_ARROW_OFFSET,
      topPx: browser.offsetFromBody(element) + coordinates.height,
    };
  };

  _onClickKeyDownProps = mkOnClickKeyDown({
    onClick: this.closeAuthorCardPopover,
    overrideKeys: [KEY_CODE_ESC],
  });

  render(): ReactNodeish {
    const {
      authorId,
      shouldOpenInNewTab,
      children,
      onClick,
      authorCard,
      isAuthorCardLoading,
      isAuthorCueLoading,
      className,
      slug,
      onWhiteBackground,
      paper,
      prioritizedCue,
      isAuthorPersonalized,
      paperIds,
      isOnClickEnabled,
    } = this.props;
    const { isAuthorCardVisible } = this.state;

    // TODO (#38443) Make author cards work with keyboard navigation again (#38443)
    return (
      <span
        className={classNames('author-card-popover', className)}
        onMouseEnter={!isOnClickEnabled ? this.showAuthorCardPopover : undefined}
        onMouseLeave={!isOnClickEnabled ? this.closeAuthorCardPopover : undefined}
        ref={this.#authorCardPopover}
        data-test-id="author-card-popover">
        {isOnClickEnabled ? (
          <CLButton
            data-test-id="author-card-popover-button"
            className={classNames(AUTHOR_BOX_CLASS_NAME + ' author-card-popover__button', {
              'author-card-popover__link__with-background': onWhiteBackground,
              'author-card-popover__link--personalized': isAuthorPersonalized,
            })}
            onClick={this.onClickShowAuthorCardPopover}
            icon={
              isAuthorPersonalized ? (
                <Icon
                  className="author-card-popover__personalized-icon"
                  icon="personalization-small"
                  width="12"
                  height="12"
                  data-test-id="author-card-popover__personalized-icon"
                />
              ) : undefined
            }
            label={children}
            {...heapShowAuthorCardButton({
              'author-id': authorId,
            })}
          />
        ) : (
          <Link
            to="AUTHOR_PROFILE"
            params={{ authorId: authorId, slug: slug }}
            onClick={() => onClick(authorId)}
            shouldOpenInNewTab={shouldOpenInNewTab}
            className={classNames(AUTHOR_BOX_CLASS_NAME, {
              'author-card-popover__link__with-background': onWhiteBackground,
              'author-card-popover__link--personalized': isAuthorPersonalized,
            })}>
            <>
              {isAuthorPersonalized && (
                <Icon
                  className="author-card-popover__personalized-icon"
                  icon="personalization-small"
                  width="12"
                  height="12"
                  data-test-id="author-card-popover__personalized-icon"
                />
              )}
              {children}
            </>
          </Link>
        )}

        {isAuthorCardVisible && (
          <>
            {isOnClickEnabled && (
              <span
                data-test-id="author-card-popover-overlay"
                className="author-card-popover__overlay"
                {...this._onClickKeyDownProps}
              />
            )}
            <CLPortal coordinates={this.getBottomLeftCoordinates(this.#authorCardPopover.current)}>
              <AuthorCardPopover
                authorCard={authorCard}
                isAuthorCardLoading={isAuthorCardLoading}
                paper={paper}
                prioritizedCue={prioritizedCue}
                paperIds={paperIds}
                isAuthorPersonalized={isAuthorPersonalized}
                onClick={onClick}
                isAuthorCueLoading={isAuthorCueLoading}
              />
            </CLPortal>
          </>
        )}
      </span>
    );
  }
}

export default mapHooksToProps<Props, PropsFromParent>(AuthorCardActionPopover, props => {
  const { authorCardStore, cueStore, libraryFolderStore, weblabStore } = useAppContext();
  const { authorId, isAuthorPersonalizedEnabled } = props;

  const weblabStoreProps = useStateFromStore(weblabStore, _ => {
    const variationName = _.getVariation(Experiment.PersonalizedAuthorCardCues.KEY);

    return {
      isOnClickEnabled:
        variationName ===
          Experiment.PersonalizedAuthorCardCues.Variation
            .ENABLE_NON_PERSONALIZED_AUTHOR_CARD_ON_CLICK ||
        variationName ===
          Experiment.PersonalizedAuthorCardCues.Variation
            .ENABLE_PERSONALIZED_AUTHOR_CARD_CUES_ON_CLICK,
    };
  });

  const authorCardStoreProps = useStateFromStore(authorCardStore, _ => ({
    isAuthorCardLoading: _.isLoading(authorId),
    isUninitialized: _.isUninitialized(authorId),
    authorCard: _.getAuthorCard(authorId),
  }));

  const cueStoreProps = useStateFromStore(cueStore, _ => ({
    isAuthorPersonalized: _.isAuthorPersonalized(authorId),
    cueData: _.getPrioritizedAuthorCueByAuthorId(authorId),
    libraryEntriesMap: _.getEntries(),
    authorCueDataMap: _.getAuthorCuesDataMap(),
    isAuthorCueLoading: _.isAuthorCueLoading(),
  }));

  const prioritizedCue = cueStoreProps.cueData?.cueType || Unsupported;
  let paperIds = cueStoreProps.cueData?.paperIds || Immutable.List<PaperId>();

  // We need to confirm that a user hasn't removed the entry from the library
  // otherwise if a person uses the back button on their browser they will still see the cue
  if (paperIds && prioritizedCue === AuthoredLibraryPaperCue) {
    paperIds = paperIds
      .toSet()
      .intersect(
        libraryFolderStore.getUnsortedPaperIds().union(libraryFolderStore.getAllFolderPaperIds())
      )
      .toList();
  }

  return {
    ...weblabStoreProps,
    ...authorCardStoreProps,
    ...cueStoreProps,
    ...props,
    prioritizedCue,
    paperIds,
    isAuthorCueLoading:
      prioritizedCue !== AuthoredLibraryPaperCue ? cueStoreProps.isAuthorCueLoading : false,
    isAuthorPersonalized: cueStoreProps.isAuthorPersonalized && isAuthorPersonalizedEnabled,
  };
});
