import { ApiRequestStartingPayload } from '@/api/BaseApi';
import {
  ApiResponse,
  PaperPdfDataResponseBody,
  PaperPdfTermDefinitionResponseBody,
} from '@/api/ApiResponse';
import { Nullable } from '@/utils/types';
import {
  PdfTermDefinitionFactory,
  PdfTermDefinitionRecord,
} from '@/models/paper-pdf/PaperPdfTermDefinition';
import BaseStore from '@/stores/BaseStore';
import Constants from '@/constants';
import S2Dispatcher from '@/utils/S2Dispatcher';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import Immutable from 'immutable';

export default class ReaderTermDefinitionsStore extends BaseStore {
  #storeState: StoreStateValue;
  #currentPdfSha: Nullable<string>;
  #termIdToDefinition: Immutable.Map<string, PdfTermDefinitionRecord>;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#storeState = StoreState.UNINITIALIZED;
    this.#currentPdfSha = null;
    this.#termIdToDefinition = Immutable.Map();

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const apiStartingPayload = payload as ApiRequestStartingPayload;
          switch (apiStartingPayload.requestType) {
            case Constants.requestTypes.PDF_DATA: {
              this.#storeState = StoreState.LOADING;
              this.#currentPdfSha = null;
              this.#termIdToDefinition = Immutable.Map();
              this.emitChange();
              break;
            }
            case Constants.requestTypes.DEFINITION_FOR_PAPER_TERM: {
              this.#storeState = StoreState.LOADING;
              this.emitChange();
              break;
            }
          }
          break;
        }

        case Constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.PDF_DATA: {
              const pdfDataApiResponse = apiResponse as ApiResponse<PaperPdfDataResponseBody>;
              this.#currentPdfSha = pdfDataApiResponse.resultData.pdfSha;
              this.#storeState = StoreState.LOADED;
              break;
            }
            case Constants.requestTypes.DEFINITION_FOR_PAPER_TERM: {
              const termDefinitionsApiResponse =
                apiResponse as ApiResponse<PaperPdfTermDefinitionResponseBody>;
              const definition = termDefinitionsApiResponse.resultData
                ? PdfTermDefinitionFactory(termDefinitionsApiResponse.resultData)
                : null;

              // only update the definitions map if its for the curent pdf
              // prevents requests for definitions of a previous paper coming in late
              if (definition && definition.pdfSha === this.#currentPdfSha) {
                this.#termIdToDefinition = this.#termIdToDefinition.set(
                  definition.termId,
                  definition
                );

                this.#storeState = StoreState.LOADED;
                this.emitChange();
              }

              break;
            }
          }
          break;
        }

        case Constants.actions.API_REQUEST_FAILED: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.PDF_DATA: {
              this.#storeState = StoreState.ERROR;
              this.#currentPdfSha = null;
              this.#termIdToDefinition = Immutable.Map();
              this.emitChange();
              return;
            }
          }
          return;
        }
      }
    });
  }

  getDefinitionByTermId(termId: string): Nullable<PdfTermDefinitionRecord> {
    return this.#termIdToDefinition.get(termId) || null;
  }

  getDefinitions(): Immutable.Map<string, PdfTermDefinitionRecord> {
    return this.#termIdToDefinition;
  }

  getCurrentPdfSha(): Nullable<string> {
    return this.#currentPdfSha;
  }

  isUninitialized(): boolean {
    return this.#storeState === StoreState.UNINITIALIZED;
  }

  isLoading(): boolean {
    return this.isUninitialized() || this.#storeState === StoreState.LOADING;
  }

  isFailed(): boolean {
    return this.#storeState === StoreState.ERROR;
  }
}
