import BaseStore from './BaseStore';

import { ModalAction, ModalIdValue } from '@/constants/Modal';
import { Nullable } from '@/utils/types';
import Constants from '@/constants';

import Immutable from 'immutable';

import type { ShowModalAction } from '@/actions/ModalActionCreators';
import type S2Dispatcher from '@/utils/S2Dispatcher';

type ModalStateProps = {
  visibleModalId: Nullable<ModalIdValue>;
  visibleModalData: any;
  key: Nullable<string>;
  location: Nullable<string>;
  subLocation: Nullable<string>;
};

const ModalStateRecordFactory = Immutable.Record<ModalStateProps>({
  visibleModalId: null,
  visibleModalData: null,
  key: null,
  location: null,
  subLocation: null,
});

export type ModalStateRecord = Immutable.RecordOf<ModalStateProps>;

export default class ModalStore extends BaseStore {
  #modalState: ModalStateRecord;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#modalState = ModalStateRecordFactory();

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case ModalAction.SHOW_MODAL: {
          const action = payload as ShowModalAction;
          const newState = ModalStateRecordFactory({
            visibleModalId: action.id,
            visibleModalData: action.data,
            key: action.key,
            location: action.location,
            subLocation: action.subLocation,
          });
          if (!this.#modalState.equals(newState)) {
            this.#modalState = newState;
            this.emitChange(newState.visibleModalId, newState.visibleModalData, newState.key);
          }
          break;
        }
        // If we route away from the modal, we want it to close.
        case Constants.actions.ROUTING:
        case ModalAction.HIDE_MODAL: {
          const newState = ModalStateRecordFactory();
          if (!this.#modalState.equals(newState)) {
            this.#modalState = newState;
            this.emitChange(newState.visibleModalId, newState.visibleModalData, newState.key);
          }
          break;
        }
      }
    });
  }

  getLocation(): Nullable<string> {
    return this.#modalState.location;
  }

  getSubLocation(): Nullable<string> {
    return this.#modalState.subLocation;
  }

  getVisibleModalId(): Nullable<ModalIdValue> {
    return this.#modalState.visibleModalId;
  }

  getModalState(): ModalStateRecord {
    return this.#modalState;
  }
}
