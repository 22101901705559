import { KEY_CODE_SPACE } from '@/constants/KeyCode';
import { mkOnClickKeyDown } from '@/utils/a11y-utils';
import Icon from '@/components/shared/icon/Icon';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class Checkbox extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    checkboxText: PropTypes.string,
    value: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  };

  onChange = () => {
    this.props.onChange(!this.props.value);
  };

  _onClickKeyDownProps = mkOnClickKeyDown({
    onClick: this.onChange,
    overrideKeys: [KEY_CODE_SPACE],
  });

  render() {
    const { className, label, checkboxText, value } = this.props;
    const isSelected = value ? 'is-selected' : null;
    const topLevelClasses = classnames('s2-form-input', className);
    const hoverArea = classnames('checkbox', isSelected);

    return (
      <div className={topLevelClasses}>
        {label ? <label>{label}</label> : null}
        <span
          className={hoverArea}
          {...this._onClickKeyDownProps}
          role="checkbox"
          tabIndex={0}
          aria-checked={value}
          aria-labelledby={`checkbox-${checkboxText}`}>
          <div className="checkbox-input flex-row-vcenter">
            <Icon className="check flex-centered" icon="check" width="12" height="12" />
          </div>
          {checkboxText ? (
            <span id={`checkbox-${checkboxText}`} className="checkbox-text">
              {checkboxText}
            </span>
          ) : null}
        </span>
      </div>
    );
  }
}
