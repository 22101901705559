import { LibraryFolderIcon } from './LibraryFolderIcon';

import { getString } from '@/content/i18n';
import { isAllPapersFolder, isAuthorLibraryFolder } from '@/models/library/LibraryFolderSourceType';
import { LibraryFolderRecord } from '@/models/library/LibraryFolder';
import { Nullable, ReactNodeish } from '@/utils/types';
import { PathParams, RouteName } from '@/router/Routes';
import CLTooltip, { PLACEMENT } from '@/components/library/tooltip/CLTooltip';
import Icon from '@/components/shared/icon/Icon';
import Link from '@/router/Link';

import classnames from 'classnames';
import React from 'react';

type Props = {
  className?: Nullable<string>;
  folder: LibraryFolderRecord;
  folderIcon: Nullable<boolean | ReactNodeish>;
  recsIcon: Nullable<boolean | ReactNodeish>;
  isLink: boolean;
  hasRecommendationsEnabled: boolean;
  isActive: boolean;
  isPublic: boolean;
};

export default function LibraryFolderName({
  className,
  folder,
  folderIcon,
  recsIcon,
  isLink,
  hasRecommendationsEnabled,
  isActive,
  isPublic,
}: Props) {
  // Override certain LibraryFolderName props when handling an AllPapers folder
  const {
    to,
    params,
  }: {
    to: RouteName;
    params?: PathParams;
  } = isAllPapersFolder(folder)
    ? {
        to: 'LIBRARY_ALL_ENTRIES',
      }
    : {
        to: 'LIBRARY_FOLDER',
        params: { libraryFolderId: folder.id.toString() },
      };

  return (
    <div className={classnames('library-folder-name', className)}>
      {!!folderIcon && (
        <div className="library-folder-name__folder-icon">
          {folderIcon === true
            ? getLibraryFolderIcon({ sourceType: folder.sourceType, isActive, isPublic })
            : folderIcon}
        </div>
      )}
      <div
        className={classnames('library-folder-name__label', {
          'library-folder-name__label--all-papers': isAllPapersFolder(folder),
        })}
        // Only add selenium selector for AuthorLibraryFolder- this is used for AppCues
        data-test-id={isAuthorLibraryFolder(folder) ? folder.sourceType : ''}>
        <div className="library-folder-name__folder">
          {!!recsIcon &&
            (recsIcon === true ? (
              <CLTooltip
                placement={PLACEMENT.BOTTOM}
                tooltipContent={
                  hasRecommendationsEnabled
                    ? getString(_ => _.library.folderName.recommendationsEnabledTooltip)
                    : getString(_ => _.library.folderName.recommendationsDisabledTooltip)
                }>
                <Icon
                  icon="lightning-bolt"
                  height="14"
                  width="8"
                  className={classnames({
                    'library-folder-name__has-recs-enabled': hasRecommendationsEnabled,
                  })}
                />
              </CLTooltip>
            ) : (
              recsIcon
            ))}
          {isLink ? (
            <Link
              to={to}
              params={params}
              shouldUnderline={false}
              data-test-id="library-folder-name-link"
              className={classnames('library-folder-name__label', {
                'library-folder-name__label--is-active': isActive,
              })}>
              <>{folder.name}</>
            </Link>
          ) : (
            <div
              className={classnames('library-folder-name__label', {
                'library-folder-name__label--is-active': isActive,
              })}>
              {folder.name}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function getLibraryFolderIcon({ sourceType, isActive, isPublic }): ReactNodeish {
  const libraryFolderIcon = (
    <LibraryFolderIcon sourceType={sourceType} isActive={isActive} isPublic={isPublic} />
  );

  if (isPublic) {
    return (
      <CLTooltip
        placement={PLACEMENT.BOTTOM}
        tooltipContent={getString(_ => _.library.folderName.publicFolder)}>
        <span className="library-folder-name__folder-icon__container">{libraryFolderIcon}</span>
      </CLTooltip>
    );
  }
  return libraryFolderIcon;
}

LibraryFolderName.defaultProps = {
  folderIcon: true,
  recsIcon: true,
  isLink: true,
  hasRecommendationsEnabled: false,
  isActive: false,
  isPublic: false,
};
