import { CitationStyleType } from '@/constants/citation-style';
import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

export type CitationStyleFromJS = {
  citationStyle: Nullable<CitationStyleType>;
};

export type CitationStyle = {
  citationStyle: Nullable<CitationStyleType>;
};

export const CITATION_STYLE_RECORD_NAME = 'CitationStyle';
export const CitationStyleRecordFactory = Immutable.Record<CitationStyle>(
  {
    citationStyle: null,
  },
  CITATION_STYLE_RECORD_NAME
);
export type CitationStyleRecord = Immutable.RecordOf<CitationStyle>;

export function getCitationStyleFromJS(args: CitationStyleFromJS): CitationStyleRecord {
  return CitationStyleRecordFactory({
    ...args,
  });
}
