import { AnalyticsContext } from './CLPaperAnalytics';
import MockPaper, { MockPaperLiteFromJs } from '../../../../../test/utils/MockPaper';

import { addHeapProps } from '@/components/util/HeapTracking';
import { ExampleConfig } from '@/components/library/ExampleUtils';
import { getHighlightedFieldFromJS, HighlightedFieldRecord } from '@/models/HighlightedField';
import { HeapProps } from '@/analytics/heapUtils';
import { Nullable, ReactNodeish, TODO } from '@/utils/types';
import { PaperishRecord } from '@/models/Paper';
import HighlightedField, { MoreOrLess } from '@/components/shared/common/HighlightedField';

import classnames from 'classnames';
import React from 'react';

const ABSTRACT_CHAR_LIMIT = 200;

type TODO__PaperAnalyticsContext = TODO<'CLPaperAnalytics needs types'>;

type Props = {
  paper: Nullable<PaperishRecord>;
  abstract: Nullable<HighlightedFieldRecord>;
  onToggle?: (expandState: MoreOrLess) => void;
  className?: Nullable<string>;
  heapProps?: Nullable<HeapProps>;
  testId?: Nullable<string>;
  isInteractive?: Nullable<boolean>;
};

export default function CLPaperAbstract({
  paper,
  abstract: abstractOverride,
  onToggle,
  className,
  heapProps,
  testId,
}: Props): ReactNodeish {
  const abstract = ((abstractOverride, paper) => {
    if (abstractOverride !== null && abstractOverride !== undefined) {
      return abstractOverride;
    }

    if (paper) {
      return paper.paperAbstract;
    }
    return null;
  })(abstractOverride, paper);

  const { onToggleAbstract: onToggleAbstractAnalytics } =
    React.useContext<TODO__PaperAnalyticsContext>(AnalyticsContext);
  const onToggleWrap = React.useCallback(
    expandState => {
      onToggleAbstractAnalytics({
        expandState,
      });
      if (onToggle) {
        onToggle(expandState);
      }
    },
    [onToggleAbstractAnalytics, onToggle]
  );

  if (!abstract || !abstract.text) {
    return null;
  }

  return (
    <div
      className={classnames('cl-paper-abstract', className)}
      {...(heapProps ? addHeapProps(heapProps) : {})}
      data-test-id={testId}>
      <HighlightedField
        field={abstract}
        limit={ABSTRACT_CHAR_LIMIT}
        trackToggle={onToggleWrap}
        fullTextClassName="full-abstract"
        testId="paper-abstract-toggle"
      />
    </div>
  );
}

CLPaperAbstract.defaultProps = {
  paper: null,
  abstract: null,
  isInteractive: true,
};

// TODO(#21359): Split this into a separate file
const PAPER = MockPaper({
  paperAbstract: getHighlightedFieldFromJS({
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  }),
});
const PAPER_LITE = MockPaperLiteFromJs({
  paperAbstract: {
    text: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
});

const PAPER_WITH_HIGHLIGHT = MockPaper({
  paperAbstract: getHighlightedFieldFromJS({
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    fragments: [{ start: 6, end: 12 }],
  }),
});

const PAPER_LITE_WITH_HIGHLIGHT = MockPaperLiteFromJs({
  paperAbstract: {
    text: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    fragments: [{ start: 3, end: 7 }],
  },
});

export const exampleConfig: ExampleConfig = {
  getComponent: () => CLPaperAbstract,
  fields: [
    {
      name: 'className',
      desc: 'HTML classes to be added to the component',
      value: {
        type: 'text',
        default: '',
      },
    },
  ],

  examples: [
    {
      title: 'Paper abstract',
      desc: 'Abstract from a Paper obj',
      props: {
        paper: PAPER,
      },
      render: comp => <div style={{ width: '600px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'PaperLite abstract',
      desc: 'Abstract from a PaperLite obj',
      props: {
        paper: PAPER_LITE,
      },
      render: comp => <div style={{ width: '600px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'Paper abstract with highlight',
      desc: 'Abstract from a Paper obj with highlight',
      props: {
        paper: PAPER_WITH_HIGHLIGHT,
      },
      render: comp => <div style={{ width: '600px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'PaperLite abstract with highlight',
      desc: 'Abstract from a PaperLite obj with highlight',
      props: {
        paper: PAPER_LITE_WITH_HIGHLIGHT,
      },
      render: comp => <div style={{ width: '600px', padding: '16px' }}>{comp}</div>,
    },
  ],

  events: {},
};
