import { isBrowser } from './env';
import { nextMicroTask } from './promise-utils';
import softError from './softError';

import { hasBroadcastChannelSupport } from '@/browser';
import Feature from '@/weblab/Feature';

import { Dispatcher } from 'flux';

import type { Nullable } from './types';
import type WeblabStore from '@/weblab/WeblabStore';

// A payload may have many more properties, this is the minimum
export type DispatchPayload<T = unknown> = {
  actionType: string;
} & T;

const CROSS_TAB_BROADCAST_CHANNEL_NAME = 's2-dispatcher';

export default class S2Dispatcher extends Dispatcher<DispatchPayload> {
  #broadcastChannel: Nullable<BroadcastChannel> = null;
  #lastSuccessfulActionType: Nullable<string> = null;
  #lastSuccessfulRequestType: Nullable<string> = null;
  #weblabStore: Nullable<WeblabStore> = null;

  constructor() {
    super();
    if (isBrowser() && hasBroadcastChannelSupport()) {
      this.#broadcastChannel = new BroadcastChannel(CROSS_TAB_BROADCAST_CHANNEL_NAME);
      this.#broadcastChannel.addEventListener('message', this.#onBroadcastMessage);
    }
  }

  register(cb: (payload: DispatchPayload, ...args: any[]) => void): string {
    return super.register((payload: DispatchPayload, ...args: any[]) => {
      try {
        return cb(payload, ...args);
      } catch (error) {
        const debugValues = {
          actionType: payload.actionType,
          requestType: (payload as any).requestType,
          message: error.message,
        };
        softError(
          'dispatcher.error',
          `dispatch threw an error handling [${mkDebugMsg(debugValues)}]`,
          error
        );
      }
    });
  }

  dispatch<T>(payload: DispatchPayload<T>): void {
    try {
      const shouldLog =
        !!this.#weblabStore &&
        this.#weblabStore.isInitialized() &&
        this.#weblabStore.isFeatureEnabled(Feature.LogDispatches);
      if (shouldLog) {
        // eslint-disable-next-line no-console
        console.log('dispatch()', payload);
      }
      super.dispatch(payload);
      this.#lastSuccessfulActionType = payload.actionType || null;
      this.#lastSuccessfulRequestType = (payload as any).requestType || null;
    } catch (error) {
      const debugValues = {
        actionType: payload.actionType,
        requestType: (payload as any).requestType,
        lastActionType: this.#lastSuccessfulActionType,
        lastRequestType: this.#lastSuccessfulRequestType,
        message: error.message,
      };
      softError('dispatcher.error', `dispatch failed to send [${mkDebugMsg(debugValues)}]`);
      throw error;
    }
  }

  // Enqueue dispatch in the promise microtask queue
  async dispatchEventually<T>(payload: DispatchPayload<T>): Promise<void> {
    await nextMicroTask();
    this.dispatch(payload);
  }

  // Dispatch a payload across all tabs/windows on same origin
  // NOTE: Will not dispatch locally unless you pass { includeCurrentWindow: true }
  dispatchToOtherWindows<T>(
    payload: DispatchPayload<T>,
    { includeCurrentWindow }: { includeCurrentWindow?: Nullable<boolean> } = {}
  ): void {
    const bc = this.#broadcastChannel;
    if (!bc) {
      return;
    }
    // Converting to JSON enforces payloads are cloneable
    const message = JSON.stringify(payload);
    bc.postMessage(message);
    if (includeCurrentWindow) {
      // Need to use dispatchEventually() because other tabs will receive async
      this.dispatchEventually(payload);
    }
  }

  associateWithWeblabStore(weblabStore: WeblabStore): void {
    this.#weblabStore = weblabStore;
  }

  // Receive a message from another tab, to be dispatched in this tab
  #onBroadcastMessage = (message: MessageEvent<any>): void => {
    const { data } = message;
    const payload = JSON.parse(data);
    this.dispatchEventually(payload);
  };
}

function mkDebugMsg(debugValues: object): string {
  return Object.keys(debugValues)
    .map(key => `${key}=${(debugValues[key] || '') as string}`)
    .join(', ');
}
