import { getBrowserCookieJar } from './cookies/BrowserCookieJar';

import { WEBLAB_OPT_OUT } from '@/constants/UserCookie';

import Immutable from 'immutable';

import type { DEPRECATED__FlowOptional, RequestWithMiddleware } from './types';

const LOCAL_HOSTNAMES: Immutable.Set<string> = Immutable.Set([
  'localhost',
  '127.0.0.1',
  '0.0.0.0',
  's2.local.allenai.org',
]);

const NON_PRODUCTION_HOSTNAMES: Immutable.Set<string> = LOCAL_HOSTNAMES.concat([
  'development.semanticscholar.org',
]);

const NON_PRODUCTION_REGEXES = Immutable.List([/s2-online-frontend-adhoc-0\.dev\.ai2$/]);

/** Will only run `fn` in dev environment */
export const isDev = process.env.NODE_ENV !== 'production';

// Determine if code is running as a unit test
// NOTE: This should only be used to prevent side effects, like API calls, not behavioral changes
export const isUnitTest: () => boolean = (() => {
  const isUnitTest = typeof global.IS_UNIT_TEST !== 'undefined'; // Set in testHelper.js
  return () => isUnitTest;
})();

export const isBrowser: () => boolean = (() => {
  const isBrowser = typeof window !== 'undefined';
  return () => isBrowser;
})();

// Determine if the hostname is for production (unknown hostname or missing default to true)
export function isProductionHostname(hostname: DEPRECATED__FlowOptional<string>): boolean {
  if (!hostname) {
    return true;
  }
  if (NON_PRODUCTION_HOSTNAMES.has(hostname)) {
    return false;
  }
  for (const regex of NON_PRODUCTION_REGEXES) {
    if (regex.test(hostname)) {
      return false;
    }
  }
  return true;
}

// Determine if the hostname is for local development (unknown hostname or missing default to false)
export function isLocalHostname(hostname: DEPRECATED__FlowOptional<string>): boolean {
  if (!hostname) {
    return false;
  }
  return LOCAL_HOSTNAMES.has(hostname);
}

export function isTrackingAllowedOnServer(clientRequest: RequestWithMiddleware): boolean {
  const isDNTRequested = clientRequest.header('dnt') === '1';
  const hasOptOutCookie = clientRequest.cookieJar.getCookie(WEBLAB_OPT_OUT) === '1';
  return !(isDNTRequested || hasOptOutCookie);
}

export function isTrackingAllowedOnBrowser(): boolean {
  if (!isBrowser()) {
    return false;
  }
  const isDNTRequested = window.navigator.doNotTrack === '1';
  const hasOptOutCookie = getBrowserCookieJar().getCookie(WEBLAB_OPT_OUT) === '1';
  return !(isDNTRequested || hasOptOutCookie);
}
