import React from 'react';

export default function IconPaperMagnifyingGlass(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 92.9 120">
      <style type="text/css">
        {`
          .fold-paper-magnifying-glass{fill-rule:evenodd;clip-rule:evenodd;fill:#CECDCD;}
          .page-paper-magnifying-glass{fill:#E5E5E5;}
          .icon-paper-magnifying-glass{fill:#FFFFFF;}
        `}
      </style>
      <path className="fold-paper-magnifying-glass" d="M77.42,0L92.9,15.5H77.42V0z" />
      <polygon
        className="page-paper-magnifying-glass"
        points="77.41,0.01 0,0.01 0,120 92.9,120 92.9,15.49 77.41,15.49 "
      />
      <path
        className="icon-paper-magnifying-glass"
        d="M71.74,79c0.47,0.47,0.71,1.05,0.71,1.73s-0.24,1.25-0.71,1.73l-2.84,2.84
        C68.42,85.77,67.85,86,67.17,86s-1.25-0.24-1.73-0.71L55.28,75.14c-0.47-0.47-0.71-1.05-0.71-1.73v-1.62
        c-3.79,2.98-8.12,4.47-13,4.47c-3.86,0-7.4-0.95-10.61-2.84s-5.77-4.45-7.67-7.67s-2.84-6.75-2.84-10.61s0.95-7.4,2.84-10.61
        s4.45-5.77,7.67-7.67S37.71,34,41.57,34s7.4,0.95,10.61,2.84s5.77,4.45,7.67,7.67s2.84,6.75,2.84,10.61c0,4.88-1.49,9.21-4.47,13
        h1.62c0.68,0,1.25,0.24,1.73,0.71L71.74,79z M41.57,68.13c2.37,0,4.55-0.58,6.55-1.73s3.57-2.72,4.72-4.72s1.73-4.18,1.73-6.55
        s-0.58-4.55-1.73-6.55s-2.72-3.57-4.72-4.72s-4.18-1.73-6.55-1.73s-4.55,0.58-6.55,1.73s-3.57,2.72-4.72,4.72s-1.73,4.18-1.73,6.55
        s0.58,4.55,1.73,6.55s2.72,3.57,4.72,4.72S39.2,68.13,41.57,68.13z"
      />
    </symbol>
  );
}
