import { CitationFromJS, CitationRecord, getCitationFromJS } from './Citation';
import {
  CitationQueryFromJS,
  CitationQueryRecord,
  CitationQueryRecordFactory,
  getCitationQueryRecordFromJS,
} from './CitationQuery';

import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

type CitationQueryResponseFromJS = {
  query: CitationQueryFromJS;
  results: CitationFromJS[];
  totalPages: number;
  totalResults: number;
  aggTotalResults?: Nullable<number>;
  modifiedQueryString: string;
};

type Props = {
  query: CitationQueryRecord;
  results: Immutable.List<CitationRecord>;
  totalPages: number;
  totalResults: number;
  aggTotalResults: Nullable<number>;
  modifiedQueryString: string;
};

const defaultProps: Props = {
  query: CitationQueryRecordFactory(),
  results: Immutable.List(),
  totalPages: 0,
  totalResults: 0,
  aggTotalResults: null,
  modifiedQueryString: '',
};

export const CitationQueryResponseRecordFactory = Immutable.Record<Props>(defaultProps);
export type CitationQueryResponseRecord = Immutable.RecordOf<Props>;

export function getCitationQueryResponseFromJS(
  args: CitationQueryResponseFromJS
): CitationQueryResponseRecord {
  const response = { ...args };
  const results = Immutable.List(response.results.map(citation => getCitationFromJS(citation)));
  const query = getCitationQueryRecordFromJS(response.query);

  return CitationQueryResponseRecordFactory({
    query,
    results,
    totalPages: response.totalPages,
    totalResults: response.totalResults,
    aggTotalResults: response.aggTotalResults ? response.aggTotalResults : null,
    modifiedQueryString: response.modifiedQueryString,
  });
}
