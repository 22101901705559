import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

type Props = {};

export default class UnauthorizedFolderRoute extends React.PureComponent<Props> {
  static getPageTitle() {
    return getString(_ => _.library.unauthorizedPageTitle);
  }

  static requiresAuthentication = () => false;

  render() {
    return (
      <AsyncLoadedPage
        showLoadingPage={false}
        load={{
          importer: () =>
            import(
              /* webpackChunkName: "shared-UnauthorizedFolderPage" */ './UnauthorizedFolderPage'
            ),
          chunkName: 'shared-UnauthorizedFolderPage',
          moduleId: require.resolveWeak('./UnauthorizedFolderPage'),
        }}
      />
    );
  }
}
