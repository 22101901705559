import {
  FieldCorrectionFromJS,
  FieldCorrectionRecord,
  getFieldCorrectionRecordFromJS,
} from './FieldCorrection';

import { CompletionStatusValue, optCompletionStatusValue } from '@/constants/CompletionStatus';
import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

type Props = {
  id: Nullable<number>;
  authorId: Nullable<number>;
  createdAtUnix: Nullable<number>;
  updatedAtUnix: Nullable<number>;
  completionStatus: Nullable<CompletionStatusValue>;
  userId: Nullable<number>;
  fieldCorrections: Nullable<Immutable.List<FieldCorrectionRecord>>;
  fieldNames: Nullable<Immutable.List<string>>;
};

export type AuthorCorrectionFromJS = {
  id: number;
  authorId: number;
  createdAtUnix: number;
  updatedAtUnix: number;
  completionStatus: { value: string };
  userId: number;
  fieldCorrections: FieldCorrectionFromJS[];
  fieldNames: string[];
};

const defaultProperties: Props = {
  id: null,
  authorId: null,
  createdAtUnix: null,
  updatedAtUnix: null,
  completionStatus: null,
  userId: null,
  fieldCorrections: null,
  fieldNames: null,
};

export const AuthorCorrectionRecordFactory = Immutable.Record<Props>(defaultProperties);
export type AuthorCorrectionRecord = Immutable.RecordOf<Props>;

export function getAuthorCorrectionRecordFromJS(
  args: AuthorCorrectionFromJS
): AuthorCorrectionRecord {
  return AuthorCorrectionRecordFactory({
    ...args,
    completionStatus: optCompletionStatusValue(args.completionStatus?.value),
    fieldCorrections: Immutable.List(args.fieldCorrections || []).map(fc =>
      getFieldCorrectionRecordFromJS(fc)
    ),
    fieldNames: Immutable.List(args.fieldNames || []),
  });
}
