import { getString } from '@/content/i18n';
import { ModalId } from '@/constants/Modal';
import { Off, On } from '@/models/library/RecommendationStatus';
import { QUERY_TYPE } from '@/constants/Alert';
import { showModal } from '@/actions/ModalActionCreators';
import Alert from '@/components/shared/alerts/Alert';
import AlertsStore from '@/stores/AlertsStore';
import CLIconButton, { TYPE as BUTTON_TYPE } from '@/components/library/button/CLIconButton';
import EnvInfo from '@/env/EnvInfo';
import FolderRecommendationsToggle from '@/components/shared/library/FolderRecommendationsToggle';
import Icon from '@/components/shared/icon/Icon';
import LibraryFolderStore from '@/stores/LibraryFolderStore';
import S2Dispatcher from '@/utils/S2Dispatcher';
import softError from '@/utils/softError';
import WeblabStore from '@/weblab/WeblabStore';

import PropTypes from 'prop-types';
import React from 'react';

export default class researchFeedSettings extends React.PureComponent {
  static contextTypes = {
    alertsStore: PropTypes.instanceOf(AlertsStore).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    libraryFolderStore: PropTypes.instanceOf(LibraryFolderStore).isRequired,
    weblabStore: PropTypes.instanceOf(WeblabStore).isRequired,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      errorMessage: null,
      ...this.getStateFromLibraryFolderStore(),
      ...this.getStateFromAlertsStore(),
    };

    this.context.alertsStore.registerComponent(this, () => {
      this.setState(this.getStateFromAlertsStore);
    });
  }

  getStateFromLibraryFolderStore() {
    const { libraryFolderStore } = this.context;
    const { libraryFolderId } = this.props;

    const folder = libraryFolderStore.getFolderById(libraryFolderId);

    // This shouldn't happen so log error if it does
    if (!folder) {
      softError('library', `failed to find folder[id="${libraryFolderId}"]`);
      return {
        recommendationStatus: Off,
      };
    }

    return {
      recommendationStatus: folder.recommendationStatus,
    };
  }

  getStateFromAlertsStore = () => {
    const { alertsStore } = this.context;
    const alertsMap = alertsStore
      .getAlerts()
      .toList()
      .groupBy(alert => alert.queryType)
      .toMap();

    return {
      // $FlowFixMe: Next line causes error when flow typing was added to context (See: #22776)
      alerts: alertsMap,
      isLoading: alertsStore.isLoading(),
    };
  };

  onFailureRecs = () => {
    const recommendationStatus = this.state.recommendationStatus === On ? Off : On;
    this.setState({ recommendationStatus });

    if (this.context.envInfo.isMobile) {
      const errorMessageDescription = getString(
        _ => _.library.recsLabel.recommendationToggleErrorMessage
      );

      this.setState({
        errorMessage: errorMessageDescription,
      });
      return;
    }
    const errorMessageDescription = getString(
      _ => _.library.recsLabel.recommendationToggleErrorMessage
    );

    this.setState({
      errorMessage: errorMessageDescription,
    });
  };

  onToggleRecs = recommendationStatus => {
    this.setState({ recommendationStatus });
  };

  renderAlert(alert) {
    return (
      <Alert
        alert={alert}
        overrideLabel={getString(_ => _.library.libraryFolderSettingsShelf.alertText)}
        allowIcon={true}
        allowRemoval={false}
        key={alert.id}
      />
    );
  }

  getFolderAlert = () => {
    const { folder } = this.props;
    const alertsOfType = this.state.alerts.get(QUERY_TYPE.LIBRARY_FOLDER);
    if (!alertsOfType) {
      return null;
    }
    const alert = alertsOfType.find(alert => parseInt(alert.queryValue) === folder.id);
    if (!alert) {
      return null;
    }
    return <ul>{this.renderAlert(alert)}</ul>;
  };

  showNotRelevantModal = () => {
    const { folder } = this.props;
    this.context.dispatcher.dispatch(
      showModal({
        id: ModalId.RECOMMENDATIONS_NOT_RELEVANT,
        data: { folderId: folder.id, folderName: folder.name },
      })
    );
  };

  render() {
    const { folder } = this.props;
    const { recommendationStatus } = this.state;

    const displayAlertAndNotRelevant = recommendationStatus === Off ? false : true;

    return (
      <div className="library-folder-settings__recommendation">
        <label className="library-folder-settings__section-label">
          {getString(_ => _.library.libraryFolderSettingsShelf.researchFeedSetting)}
        </label>
        <div className="library-folder-settings__paper_recommendation">
          <FolderRecommendationsToggle
            key={folder.id}
            libraryFolderId={folder.id}
            onToggle={this.onToggleRecs}
            onFailure={this.onFailureRecs}
            testId="library-folder-settings-folder-toggle"
          />
          <span className="library-folder-settings__paper-recommendations-label">
            {getString(_ => _.library.libraryFolderSettingsShelf.recommendationText)}
          </span>
        </div>
        {displayAlertAndNotRelevant && (
          <div>
            <div className="library-folder-settings__paper_alert">{this.getFolderAlert()}</div>
            <CLIconButton
              className="library-folder-settings__recommendation__not-relevant-button"
              icon={<Icon icon="thumbs-down-active" height="12" width="12" />}
              label={getString(_ => _.library.libraryFolderSettingsShelf.notRelevantPapers)}
              type={BUTTON_TYPE.SECONDARY}
              onClick={this.showNotRelevantModal}
            />
          </div>
        )}
      </div>
    );
  }
}
