/* eslint-disable max-lines-per-function */

import React from 'react';

export default function IconResearchPapers(props: { id?: string }) {
  return (
    <symbol id={props.id} fill="none" viewBox="0 0 355 382">
      <path
        d="M78.63 64H33C31.9 64 31 64.9 31 66V172C31 173.1 31.9 174 33 174H114C115.1 174 116 173.1 116 172V91.38L88.63 64V91.38C88.63 92.48 89.53 93.38 90.63 93.38H106"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0)">
        <path
          d="M27.9699 146.11L16.6599 134.8C15.8799 134.02 14.6099 134.02 13.8299 134.8L9.58988 139.04C8.80988 139.82 8.80988 141.09 9.58988 141.87L22.3099 154.6L27.9699 160.26C28.7499 161.04 30.0199 161.04 30.7999 160.26L36.4599 154.6L70.3999 120.66C71.1799 119.88 71.1799 118.61 70.3999 117.83L66.1599 113.59C65.3799 112.81 64.1099 112.81 63.3299 113.59L30.7999 146.11C30.0199 146.89 28.7499 146.89 27.9699 146.11Z"
          fill="#EED9FF"
          stroke="#480073"
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M296.63 213H251C249.9 213 249 213.9 249 215V321C249 322.1 249.9 323 251 323H332C333.1 323 334 322.1 334 321V240.38L306.63 213V240.38C306.63 241.48 307.53 242.38 308.63 242.38H324"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip1)">
        <path
          d="M352.25 304.18L337.41 289.33C336.63 288.55 336.63 287.28 337.41 286.5L352.25 271.66C353.03 270.88 353.03 269.61 352.25 268.83L348.01 264.59C347.23 263.81 345.96 263.81 345.18 264.59L330.33 279.44C329.55 280.22 328.28 280.22 327.5 279.44L312.66 264.59C311.88 263.81 310.61 263.81 309.83 264.59L305.59 268.83C304.81 269.61 304.81 270.88 305.59 271.66L320.44 286.51C321.22 287.29 321.22 288.56 320.44 289.34L305.59 304.18C304.81 304.96 304.81 306.23 305.59 307.01L309.83 311.25C310.61 312.03 311.88 312.03 312.66 311.25L327.51 296.4C328.29 295.62 329.56 295.62 330.34 296.4L345.19 311.25C345.97 312.03 347.24 312.03 348.02 311.25L352.26 307.01C353.04 306.23 353.04 304.96 352.25 304.18Z"
          fill="#EED9FF"
          stroke="#480073"
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M76 198H31V308H116V228L86 198V228H106"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41 298.01H106"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41 288.01H106"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41 278.01H106"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41 268.01H106"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71 258.01H106"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71 248.01H106"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71 238.01H106"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41 228.01H76"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41 218.01H76"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41 208.01H76"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61 238.01H41V258.01H61V238.01Z"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M294 79H249V189H334V109L304 79V109H324"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M259 179.01H324"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M259 169.01H324"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M259 159.01H324"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M259 149.01H324"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M289 139.01H324"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M289 129.01H324"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M289 119.01H324"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M259 109.01H294"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M259 99.01H294"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M259 89.01H294"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M279 119.01H259V139.01H279V119.01Z"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M185 2H140V112H225V32L195 2V32H215"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 102.01H215"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 92.01H215"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 82.01H215"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 72.01H215"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M180 62.01H215"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M180 52.01H215"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M180 42.01H215"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 32.01H185"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 22.01H185"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 12.01H185"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M170 42.01H150V62.01H170V42.01Z"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M185 270H140V380H225V300L195 270V300H215"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 370.01H215"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 360.01H215"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 350.01H215"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 340.01H215"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 330.01H215"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 320.01H215"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 310.01H215"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 300.01H185"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 290.01H185"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 280.01H185"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M229 250H144V140H203.13L229 170.92V250Z" fill="#EED9FF" />
      <path
        d="M185 136H140V246H225V166L195 136V166H215"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 236.01H215"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 226.01H215"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 216.01H215"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 206.01H175"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 196.01H175"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 186.01H175"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 176.01H175"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 166.01H185"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 156.01H185"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 146.01H185"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M215 176.01H185V206.01H215V176.01Z"
        stroke="#480073"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip2)">
        <path
          d="M10 324H4C2.9 324 2 323.1 2 322V286C2 284.9 2.9 284 4 284H12V322C12 323.1 11.1 324 10 324Z"
          stroke="#480073"
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M56.83 292.69C56.82 292.62 56.8 292.56 56.79 292.5C56.54 288.34 53.09 285.04 48.86 285.04H33.95C32.67 285.04 31.72 283.85 32 282.6L35.02 269.3C35.52 267.09 33.86 265 31.6 265H31C30.37 265 29.74 265.2 28.91 265.9C28.18 266.51 19.16 280.56 16.6 284.16C16.22 284.69 15.62 285 14.97 285H12V318.34H15.59C15.87 318.34 16.14 318.4 16.4 318.52C29.43 324.42 47.16 324.66 47.54 324.66C49.35 324.66 50.93 323.68 51.8 322.24C51.85 322.17 51.9 322.1 51.95 322.02C53.77 318.7 55.45 312.9 56.36 306.11C57.03 301.08 57.16 296.37 56.83 292.69Z"
          fill="#EED9FF"
        />
        <path
          d="M56.83 291.69C56.82 291.62 56.8 291.56 56.79 291.5C56.54 287.34 53.09 284.04 48.86 284.04H33.95C32.67 284.04 31.72 282.85 32 281.6L35.02 268.3C35.52 266.09 33.86 264 31.6 264H31C30.37 264 29.74 264.2 28.91 264.9C28.18 265.51 19.16 279.56 16.6 283.16C16.22 283.69 15.62 284 14.97 284H12V317.34H15.59C15.87 317.34 16.14 317.4 16.4 317.52C29.43 323.42 47.16 323.66 47.54 323.66C49.35 323.66 50.93 322.68 51.8 321.24C51.85 321.17 51.9 321.1 51.95 321.02C53.77 317.7 55.45 311.9 56.36 305.11C57.03 300.08 57.16 295.37 56.83 291.69Z"
          stroke="#480073"
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect x="7" y="111" width="65.98" height="51.84" fill="white" />
        </clipPath>
        <clipPath id="clip1">
          <rect x="303" y="262" width="51.84" height="51.84" fill="white" />
        </clipPath>
        <clipPath id="clip2">
          <rect y="262" width="59" height="64.66" fill="white" />
        </clipPath>
      </defs>
    </symbol>
  );
}
