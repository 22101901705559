import React from 'react';

export default function IconInformation(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 36 36">
      <path
        d="M18,0c3.24,0,6.24,0.81,9,2.43S31.95,6.24,33.57,9c1.62,2.76,2.43,5.76,2.43,9s-0.81,6.24-2.43,9
        c-1.62,2.76-3.81,4.95-6.57,6.57C24.24,35.19,21.24,36,18,36s-6.24-0.81-9-2.43C6.24,31.95,4.05,29.76,2.43,27S0,21.24,0,18
        s0.81-6.24,2.43-9S6.24,4.05,9,2.43S14.76,0,18,0z M22.06,26.42v-1.74c0-0.24-0.08-0.45-0.25-0.62s-0.38-0.25-0.62-0.25h-0.87v-7.26
        c0-0.24-0.08-0.45-0.25-0.62s-0.38-0.25-0.62-0.25h-4.65c-0.24,0-0.45,0.08-0.62,0.25s-0.25,0.38-0.25,0.62v1.74
        c0,0.24,0.08,0.45,0.25,0.62c0.17,0.17,0.38,0.25,0.62,0.25h0.87v4.65h-0.87c-0.24,0-0.45,0.08-0.62,0.25s-0.25,0.38-0.25,0.62v1.74
        c0,0.24,0.08,0.45,0.25,0.62c0.17,0.17,0.38,0.25,0.62,0.25h6.39c0.24,0,0.45-0.08,0.62-0.25C21.98,26.87,22.06,26.66,22.06,26.42z
        M18,7.98c-0.82,0-1.54,0.3-2.14,0.91c-0.6,0.6-0.91,1.32-0.91,2.14s0.3,1.54,0.91,2.14c0.6,0.6,1.32,0.91,2.14,0.91
        s1.54-0.3,2.14-0.91c0.6-0.6,0.91-1.32,0.91-2.14s-0.3-1.54-0.91-2.14C19.54,8.29,18.82,7.98,18,7.98z"
      />
    </symbol>
  );
}
