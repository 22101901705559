import { ReactNodeish } from '@/utils/types';
import LoadingIndicator from '@/components/shared/loading/LoadingIndicator';

import React from 'react';

type Props = {
  totalBytes: number;
  loadedBytes: number;
};

export default function ReaderLoadingIndicator({ totalBytes, loadedBytes }: Props): ReactNodeish {
  const percentLoaded = calcPercentLoaded({ totalBytes, loadedBytes });

  const progressBarStyle = React.useMemo(() => {
    return { left: `${(percentLoaded - 100).toFixed(0)}%` };
  }, [percentLoaded]);

  return (
    <div className="reader-loading-indicator">
      <div className="reader-loading-indicator__icon">
        <LoadingIndicator width="32" height="32" />
      </div>
      <div className="reader-loading-indicator__progress-bar-container">
        <div
          className="reader-loading-indicator__progress-bar"
          style={progressBarStyle}
          data-percent-loaded={percentLoaded}
        />
      </div>
    </div>
  );
}

ReaderLoadingIndicator.defaultProps = {
  totalBytes: 0,
  loadedBytes: 0,
};

export function calcPercentLoaded({
  totalBytes,
  loadedBytes,
}: {
  totalBytes: number;
  loadedBytes: number;
}): number {
  if (totalBytes === 0) {
    return 0;
  }
  const percentLoaded = ((loadedBytes / totalBytes) * 100) | 0; // Rounded to nearest 1%
  const boundedPercentLoaded = Math.min(100, Math.max(0, percentLoaded));
  return boundedPercentLoaded;
}
