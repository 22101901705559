import React from 'react';

export default function IconShareFacebook(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 32 32">
      <path
        d="M32,3.43v25.14c0,1.89-1.54,3.43-3.43,3.43h-9.8V21.12h4.13L23.68,16h-4.91v-3.32c0-1.4,0.69-2.77,2.89-2.77
        h2.23V5.55c0,0-2.03-0.35-3.97-0.35c-4.05,0-6.69,2.45-6.69,6.89V16h-4.5v5.12h4.5V32h-9.8C1.54,32,0,30.46,0,28.57V3.43
        C0,1.54,1.54,0,3.43,0h25.14C30.46,0,32,1.54,32,3.43z"
      />
    </symbol>
  );
}
