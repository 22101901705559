import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

export default class DebugSpritesRoute extends React.Component {
  static pageTitle = () => getString(_ => _.general.appName);

  render() {
    return (
      <AsyncLoadedPage
        footer={false}
        load={{
          importer: () =>
            import(/* webpackChunkName: "shared-DebugSpritesPage" */ './DebugSpritesPage'),
          chunkName: 'shared-DebugSpritesPage',
          moduleId: require.resolveWeak('./DebugSpritesPage'),
        }}
      />
    );
  }
}
