import wrap from '@/utils/wrap';

import events from 'events';
import React from 'react';

const { EventEmitter } = events;

const CHANGE_EVENT = 'CHANGE';

const MAX_EVENT_LISTENERS = 2500;

export default class BaseStore {
  _baseStoreEvents: events;

  constructor() {
    this._baseStoreEvents = new EventEmitter();

    // The default for this is 10, but with lots of components individually attached to stores
    // this could grow considerably.
    this._baseStoreEvents.setMaxListeners(MAX_EVENT_LISTENERS);
  }

  addChangeListener(cb: (...args: any[]) => void): events {
    return this._baseStoreEvents.on(CHANGE_EVENT, cb);
  }

  removeChangeListener(cb: (...args: any[]) => void): events {
    return this._baseStoreEvents.removeListener(CHANGE_EVENT, cb);
  }

  once(listener: (...args: any[]) => void): events {
    return this._baseStoreEvents.once(CHANGE_EVENT, listener);
  }

  emitChange(...args: any[]): boolean {
    return this._baseStoreEvents.emit(CHANGE_EVENT, ...args);
  }

  /**
   * Attaches the component to the store, calling the provided callback whenever the store
   * changes.
   *
   * @param {React.Component} component The component to receive updates.
   * @param {function} onStoreChangedCallback A callback function to be executed when things change.
   *
   * @returns {BaseStore} The store instance being attached to.
   */
  registerComponent(
    component: React.Component<any, any>,
    onStoreChangedCallback: (...args: any[]) => void
  ): this {
    if (typeof onStoreChangedCallback !== 'function') {
      throw new Error('You must provide a callback.');
    }
    if (!(component instanceof React.Component)) {
      throw new Error('You must provide a react component.');
    }
    component.componentDidMount = wrap(
      component.componentDidMount && component.componentDidMount.bind(component),
      this.addChangeListener.bind(this, onStoreChangedCallback)
    );
    component.componentWillUnmount = wrap(
      component.componentWillUnmount && component.componentWillUnmount.bind(component),
      this.removeChangeListener.bind(this, onStoreChangedCallback)
    );
    return this;
  }
}
