import { getString } from '@/content/i18n';
import { LOGIN_LOCATION } from '@/constants/LoginMethods';
import { ModalId } from '@/constants/Modal';
import { QUERY_TYPE } from '@/constants/Alert';
import AlertButton from '@/components/shared/alerts/AlertButton';
import CLModal from '@/components/library/modal/CLModal';

import React from 'react';

export default class AuthorAlertsModal extends React.PureComponent {
  render() {
    const { author } = this.props.data;

    return (
      <CLModal modalId={ModalId.AUTHOR_ALERTS_MODAL}>
        <div
          className="author-alerts-modal"
          data-test-id="manage-author-alerts-list"
          ref="dropdown">
          <p className="author-alerts-modal__alert-header">
            {getString(_ => _.author.alerts.header)}
          </p>
          <ul>
            <li className="alert-button">
              <AlertButton
                className="author-alerts-modal__alert-toggle"
                queryType={QUERY_TYPE.AUTHOR_PAPER}
                queryValue={author.id}
                displayValue={author.name}
                subLocation={LOGIN_LOCATION.authorPapers}
                toggleLabel={getString(_ => _.author.alerts.papers)}
                isToggleButton
              />
            </li>
            <li className="alert-button">
              <AlertButton
                className="author-alerts-modal__alert-toggle"
                queryType={QUERY_TYPE.AUTHOR_CITATION}
                queryValue={author.id}
                displayValue={author.name}
                subLocation={LOGIN_LOCATION.authorCitations}
                toggleLabel={getString(_ => _.author.alerts.citations)}
                isToggleButton
              />
            </li>
          </ul>
        </div>
      </CLModal>
    );
  }
}
