import BaseStore from './BaseStore';

import { ApiRequestStartingPayload } from '@/api/BaseApi';
import { ApiResponse, ModerationStatsResponseBody } from '@/api/ApiResponse';
import {
  AuthorProfileFromJS,
  AuthorProfileRecord,
  getAuthorProfileFromJS,
} from '@/models/author/AuthorProfile';
import { AuthorRecord, getAuthorFromJS } from '@/models/author/Author';
import { AutoModerationCheckStatus } from '@/constants/AutoModerationCheckStatus';
import { getUserRecordFromJS, UserRecord } from '@/models/user/User';
import { ModerationStatusValue } from '@/constants/ModerationStatus';
import { Nullable } from '@/utils/types';
import Constants from '@/constants';
import S2Dispatcher from '@/utils/S2Dispatcher';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import Immutable from 'immutable';

const LOADING_STATES: StoreStateValue[] = [StoreState.UNINITIALIZED, StoreState.LOADING];

export default class AuthorClaimModerationStore extends BaseStore {
  #state: StoreStateValue;
  _authorProfiles: Immutable.List<AuthorProfileRecord>;
  _numPendingAuthorClaims: number;
  _currentModerationStatus: Nullable<ModerationStatusValue>;
  _currentFilterQuery: Nullable<string>;
  _claimedAuthor: Nullable<AuthorRecord>;
  _claimedAuthorProfile: Nullable<AuthorProfileRecord>;
  _claimedPreviousAuthorProfiles: Immutable.List<AuthorProfileRecord>;
  _claimingAppUser: Nullable<UserRecord>;
  _isOrcidVerified: Nullable<string>;
  dispatchToken: string;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#state = StoreState.UNINITIALIZED;
    this._authorProfiles = Immutable.List();
    this._numPendingAuthorClaims = 0;
    this._currentModerationStatus = null;
    this._currentFilterQuery = null;
    this._claimedAuthor = null;
    this._claimedAuthorProfile = null;
    this._claimedPreviousAuthorProfiles = Immutable.List();
    this._claimingAppUser = null;
    this._isOrcidVerified = null;

    this.dispatchToken = dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const apiStartingPayload = payload as ApiRequestStartingPayload;
          switch (apiStartingPayload.requestType) {
            case Constants.requestTypes.GET_AUTHOR_PROFILE_LIST_FOR_MODERATION: {
              this.#state = StoreState.LOADING;
              this.emitChange();
              break;
            }
          }
          break;
        }
        case Constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.MODERATION_STATS: {
              const moderationStatsResponse =
                apiResponse as ApiResponse<ModerationStatsResponseBody>;
              this._numPendingAuthorClaims =
                moderationStatsResponse.resultData.numPendingAuthorClaims;
              this.emitChange();
              break;
            }

            case Constants.requestTypes.GET_AUTHOR_PROFILE_LIST_FOR_MODERATION: {
              if (apiResponse.resultData) {
                this._authorProfiles = Immutable.List(
                  apiResponse.resultData.authorProfiles.map(raw => getAuthorProfileFromJS(raw))
                );
              }
              this.#state = StoreState.LOADED;
              this.emitChange();
              break;
            }

            case Constants.requestTypes.GET_AUTHOR_PROFILE_FOR_MODERATION: {
              const rawAuthor = apiResponse.resultData.author;
              this._claimedAuthor = rawAuthor ? getAuthorFromJS(rawAuthor) : null;
              const rawAuthorProfile = apiResponse.resultData.authorProfile;
              this._claimedAuthorProfile = rawAuthorProfile
                ? getAuthorProfileFromJS(rawAuthorProfile)
                : null;
              const rawPreviousAuthorProfile: AuthorProfileFromJS[] =
                apiResponse.resultData.previousAuthorProfiles;
              this._claimedPreviousAuthorProfiles = Immutable.List(rawPreviousAuthorProfile).map(
                raw => getAuthorProfileFromJS(raw)
              );
              const rawUser = apiResponse.resultData.appUser;
              this._claimingAppUser = rawUser ? getUserRecordFromJS(rawUser) : null;
              this.emitChange();
              break;
            }

            case Constants.requestTypes.GET_MODERATION_RECOMMENDATION: {
              this._isOrcidVerified = apiResponse.resultData
                ? apiResponse.resultData.result['value']
                : AutoModerationCheckStatus.InsufficientData;
              this.emitChange();
              break;
            }
          }
          break;
        }
      }
    });
  }

  isUninitialized(): boolean {
    return this.#state === StoreState.UNINITIALIZED;
  }

  isLoading(): boolean {
    return LOADING_STATES.includes(this.#state);
  }

  getAuthorProfiles(): Immutable.List<AuthorProfileRecord> {
    return this._authorProfiles;
  }

  getPendingAuthorClaimCount(): number {
    return this._numPendingAuthorClaims;
  }

  getClaimedAuthor(): Nullable<AuthorRecord> {
    return this._claimedAuthor;
  }

  getClaimedAuthorProfile(): Nullable<AuthorProfileRecord> {
    return this._claimedAuthorProfile;
  }

  getClaimedPreviousAuthorProfiles(): Immutable.List<AuthorProfileRecord> {
    return this._claimedPreviousAuthorProfiles;
  }

  getClaimingAppUser(): Nullable<UserRecord> {
    return this._claimingAppUser;
  }

  getOrcidResult(): Nullable<string> {
    return this._isOrcidVerified;
  }
}
