import {
  MessageBase,
  MessageContext,
  MessagesBase,
} from '@/components/shared/message/MessagesBase';
import { ReactNodeish } from '@/utils/types';
import IconButton from '@/components/shared/common/button/IconButton';

import React from 'react';

class Message extends MessageBase {
  render(): ReactNodeish {
    const { title, text, extraContent } = this.props.message;
    return (
      <li
        className={this.getClassNames()}
        onFocus={() => setTimeout(this.cancelTimer, 0)}
        onBlur={this.startTimer}
        ref="message">
        <div className="msg-title flex-row-vcenter">
          <span className="msg-title-text">{title}</span>
          <IconButton
            className="close-modal-button"
            iconProps={{ icon: 'remove-x', width: '10', height: '10' }}
            onClick={this.hideHandler}
          />
        </div>
        <div className="msg-text">{text}</div>
        <MessageContext.Provider value={{ hideHandler: this.hideHandler }}>
          {extraContent ? extraContent : null}
        </MessageContext.Provider>
      </li>
    );
  }
}

export default class Messages extends MessagesBase {
  renderImpl(): ReactNodeish {
    let { messages } = this.state;
    if (this.props.max) {
      messages = messages.take(this.props.max);
    }
    return (
      <ul className={this.getClassNames()}>
        {messages.map(msg => (
          <Message key={msg.id} message={msg} onClose={this.closeMessage} />
        ))}
      </ul>
    );
  }
}
