import MobileLibraryNavMenu from './MobileLibraryNavMenu';

import { capitalizeFirst } from '@/format';
import { getString } from '@/content/i18n';
import { hideModal, showModal } from '@/actions/ModalActionCreators';
import { KEY_CODE_RIGHT } from '@/constants/KeyCode';
import { mapHooksToProps } from '@/utils/react-utils';
import { mkOnClickKeyDown } from '@/utils/a11y-utils';
import { ModalId } from '@/constants/Modal';
import { useAppContext, useStateFromStore } from '@/AppContext';
import Api from '@/api/Api';
import AuthEvent from '@/analytics/models/AuthEvent';
import AuthStore from '@/stores/AuthStore';
import BrowserUtil from '@/browser';
import CLOverlay, { POSITION } from '@/components/library/layout/CLOverlay';
import CLTextButton from '@/components/library/button/CLTextButton';
import Icon from '@/components/shared/icon/Icon';
import Link from '@/router/Link';
import S2Dispatcher from '@/utils/S2Dispatcher';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';
import WeblabStore from '@/weblab/WeblabStore';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import type { ReactNodeish } from '@/utils/types';

const TIMEOUT_DELAY = 500;

type PropsFromParent = {};

type Props = {
  hasResearchDashboardContent: boolean;
} & PropsFromParent;

type State = {
  isHidden: boolean;
  isFeedMenuVisible: boolean;
  isLibraryMenuVisible: boolean;
};

export class MobileNavMenu extends React.PureComponent<Props, State> {
  context: {
    api: Api;
    authStore: AuthStore;
    dispatcher: S2Dispatcher;
    weblabStore: WeblabStore;
  };

  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    authStore: PropTypes.instanceOf(AuthStore).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    weblabStore: PropTypes.instanceOf(WeblabStore).isRequired,
  };

  constructor(...args: [any]) {
    super(...args);
    this.state = {
      isHidden: false,
      isFeedMenuVisible: false,
      isLibraryMenuVisible: false,
    };
  }

  componentDidMount(): void {
    // disable scrolling when modal opens
    BrowserUtil.disableBodyScroll();
  }

  componentWillUnmount(): void {
    BrowserUtil.enableBodyScroll();
  }

  hideModal = (): Promise<void> => {
    const { dispatcher } = this.context;
    this.setState({ isHidden: true });
    // set timeout so animation to slide menu out finishes before closing the modal
    return new Promise(function (resolve) {
      setTimeout(function () {
        resolve(dispatcher.dispatch(hideModal()));
      }, TIMEOUT_DELAY);
    });
  };

  showFeedbackModal = (): void => {
    const { dispatcher } = this.context;
    dispatcher.dispatch(showModal({ id: ModalId.FEEDBACK }));
  };

  showLibraryModal = (): void => {
    this.setState({ isLibraryMenuVisible: true });
  };

  hideLibraryModal = (): void => {
    this.setState({ isLibraryMenuVisible: false });
  };

  onClickSignOut = (): void => {
    const { authStore } = this.context;
    const authSource = authStore.getUser()?.authSource || 'None';
    const method = capitalizeFirst(authSource.toLowerCase(), ''); // Match the capitalization used in LoginMethods
    trackAnalyticsEvent(
      AuthEvent.create({
        method: method,
        state: AuthEvent.State.LOGGED_OUT,
        action: undefined,
        location: '',
        subLocation: '',
      })
    );
    this.context.api.logout();
    this.hideModal();
  };

  _onClickKeyDownShowLibraryModalProps = mkOnClickKeyDown<HTMLElement>({
    onClick: this.showLibraryModal,
    extraKeys: [KEY_CODE_RIGHT],
  });

  _onClickKeyDownShowFeedbackModalProps = mkOnClickKeyDown<HTMLElement>({
    onClick: this.showFeedbackModal,
  });

  render(): ReactNodeish {
    const { isLibraryMenuVisible } = this.state;
    const { hasResearchDashboardContent } = this.props;
    return (
      <React.Fragment>
        <CLOverlay
          position={POSITION.SIDE_RIGHT_POS_MIDDLE}
          data-modal-id={ModalId.MOBILE_NAV}
          className={classnames('mobile-nav__menu', {
            'animate-out': this.state.isHidden,
            'animate-in': !this.state.isHidden,
          })}
          // We may want to allow promises for void returns on attributes https://typescript-eslint.io/rules/no-misused-promises/#checksvoidreturn
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={this.hideModal}>
          <div className="mobile-nav__contents">
            <div className="mobile-nav__scrollable-container">
              <div className="mobile-nav__section">
                <p className="mobile-nav__section-header">
                  {getString(_ => _.account.mobileMenu.research.header)}
                </p>
                <ul>
                  {hasResearchDashboardContent && (
                    <li id="dashboard">
                      <Link
                        className="mobile-nav__section-link"
                        to="RESEARCH_HOMEPAGE"
                        aria-label={getString(_ => _.account.mobileMenu.research.dashboard)}>
                        <div className="mobile-nav__link-row">
                          <Icon
                            icon="research-dashboard"
                            height="16"
                            width="16"
                            className="mobile-nav__icon"
                          />
                          {getString(_ => _.account.mobileMenu.research.dashboard)}
                        </div>
                      </Link>
                    </li>
                  )}
                  <li
                    {...this._onClickKeyDownShowLibraryModalProps}
                    className="flex-row-vcenter flex-space-between"
                    id="library-nav-menu">
                    <div className="mobile-nav__link-row">
                      <Icon
                        icon="fa-bookmark"
                        height="16"
                        width="12"
                        className="mobile-nav__icon"
                      />
                      {getString(_ => _.account.mobileMenu.research.library)}
                    </div>
                    <Icon
                      icon="disclosure"
                      width="16"
                      height="16"
                      className="mobile-nav__arrow-icon"
                    />
                  </li>
                </ul>
              </div>
              <div className="mobile-nav__section">
                <p className="mobile-nav__section-header">
                  {getString(_ => _.account.mobileMenu.settings.header)}
                </p>
                <ul>
                  <li>
                    <Link
                      id="account"
                      className="mobile-nav__section-link"
                      to="ACCOUNT_MANAGE"
                      aria-label={getString(_ => _.account.mobileMenu.settings.account)}>
                      {getString(_ => _.account.mobileMenu.settings.account)}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="mobile-nav__section-link"
                      to="ACCOUNT_CONTACT"
                      aria-label={getString(_ => _.account.mobileMenu.settings.contact)}
                      id="contact">
                      {getString(_ => _.account.mobileMenu.settings.contact)}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="mobile-nav__section-link"
                      to="ACCOUNT_ALERTS"
                      aria-label={getString(_ => _.account.mobileMenu.settings.alert)}
                      id="alerts">
                      {getString(_ => _.account.mobileMenu.settings.alert)}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mobile-nav__section">
                <ul>
                  <li>
                    <Link
                      className="mobile-nav__section-link"
                      to="FAQ_ROOT"
                      aria-label={getString(_ => _.account.mobileMenu.faq)}
                      id="faq">
                      {getString(_ => _.account.mobileMenu.faq)}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="mobile-nav__section-link"
                      to="ABOUT_ROOT"
                      aria-label={getString(_ => _.account.mobileMenu.about)}
                      id="about">
                      {getString(_ => _.account.mobileMenu.about)}
                    </Link>
                  </li>
                  <li {...this._onClickKeyDownShowFeedbackModalProps} id="feedback">
                    {getString(_ => _.account.mobileMenu.contact)}
                  </li>
                </ul>
              </div>
            </div>
            <CLTextButton
              onClick={this.onClickSignOut}
              className="mobile-nav__sign-out"
              label={getString(_ => _.account.mobileMenu.signOut)}
              testId="mobile-nav__sign-out-button"
            />
          </div>
        </CLOverlay>
        {isLibraryMenuVisible && <MobileLibraryNavMenu onClickClose={this.hideLibraryModal} />}
      </React.Fragment>
    );
  }
}

export default mapHooksToProps<Props, PropsFromParent>(MobileNavMenu, () => {
  const { accountNavStore } = useAppContext();

  const accountNavStoreProps = useStateFromStore(accountNavStore, _ => ({
    hasResearchDashboardContent: _.hasResearchDashboardContent(),
  }));

  return {
    ...accountNavStoreProps,
  };
});
