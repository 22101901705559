import classNames from 'classnames';
import React from 'react';

const Tab = ({ children, isActive, className, ...rest }) => (
  <li className={classNames('tab', 'tab__button', className, { 'is-active': isActive })} {...rest}>
    {children}
  </li>
);

export default Tab;
