/* eslint-disable max-lines-per-function */

import { AuthorAliasRecordFactory } from '@/models/author/AuthorAlias';
import { CitationStatsRecordFactory } from '@/models/CitationStats';
import { getHighlightedFieldFromJS } from '@/models/HighlightedField';
import {
  getPaperLiteFromJS,
  getPaperLiteFromPaper,
  PaperLiteFromJS,
  PaperLiteRecord,
} from '@/models/PaperLite';
import { getScorecardStatFromJS } from '@/models/ScorecardStat';
import { getStructuredAuthorNameFromJS } from '@/models/StructuredAuthorName';
import { HighlightedAuthorRecordFactory } from '@/models/HighlightedAuthor';
import {
  PaperFromJS,
  PaperRecord,
  PaperRecordFactory,
  Props as PaperRecordFactoryProps,
} from '@/models/Paper';
import { PaperLinkRecordFactory } from '@/models/PaperLink';

import Immutable from 'immutable';

const defaultData: PaperRecordFactoryProps = {
  id: '123abc',
  title: getHighlightedFieldFromJS({
    text: 'Super Important Paper',
    fragments: [],
  }),
  paperAbstract: getHighlightedFieldFromJS({
    // the text needs to be longer than 350 chars in order to be truncated for the
    // mobile:PaperDetailAbstract click more/less tests
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    fragments: [],
  }),
  slug: 'super-important-paper',
  citationStats: CitationStatsRecordFactory({
    estNumCitations: 100,
    numCitations: 3000,
    numKeyCitations: 99,
    numReferences: 100,
    numViewableReferences: 50,
    numKeyReferences: 13,
  }),
  authors: Immutable.List([
    HighlightedAuthorRecordFactory({
      alias: AuthorAliasRecordFactory({
        name: 'Brandon',
        slug: 'brandon',
        ids: Immutable.List(['1337']),
      }),
      highlightedField: getHighlightedFieldFromJS({
        text: '',
        fragments: [],
      }),
    }),
  ]),
  scorecardStats: Immutable.List([
    getScorecardStatFromJS({
      typeKey: 'cited_by',
      citationCount: 1000,
      keyCitationCount: 10,
      citationIntentCount: { methodology: 30, background: 10, result: 20 },
    }),
  ]),
  structuredAuthors: Immutable.List([
    getStructuredAuthorNameFromJS({
      firstName: 'Seymoar',
      middleNames: ['Semantic'],
      lastName: 'Cyclopsees',
    }),
  ]),
  venue: getHighlightedFieldFromJS({
    text: 'Ravioli',
    fragments: [],
  }),
  hasPdf: true,
  links: Immutable.List([
    PaperLinkRecordFactory({
      url: 'https://www.thefirstlink.ninja',
      linkType: 's2',
    }),
  ]),
  primaryPaperLink: PaperLinkRecordFactory({
    url: 'https://www.thefirstlink.ninja',
    linkType: 's2',
  }),
  alternatePaperLinks: Immutable.List([]),
  year: getHighlightedFieldFromJS({ text: '1420' }),
  fieldsOfStudy: Immutable.List([]),
  badges: Immutable.List([]),
  corpusId: -1,
  cues: Immutable.Map(),
  doiInfo: null,
  entities: Immutable.List(),
  entityRelations: Immutable.List(),
  debugInfo: null,
  faqs: Immutable.List(),
  isReaderAvailable: false,
  indexInclusionReason: null,
  journal: null,
  paperAbstractTruncated: null,
  pubDate: null,
  readerId: null,
  socialLinks: Immutable.List(),
  tldr: null,
  venueId: '',
};

const defaultPaperLiteData: PaperLiteFromJS = {
  id: defaultData.id,
  title: defaultData.title.text,
  slug: defaultData.slug,
  authors: [{ ids: ['1337'], name: 'Brandon', slug: 'brandon' }],
  venue: defaultData.venue.text,
  year: defaultData.year.text,
  paperAbstract: {
    text: defaultData.paperAbstract.text,
    fragments: defaultData.paperAbstract.fragments.toArray(),
  },
  fieldsOfStudy: [],
  badges: [],
  venueId: null,
  pubDate: null,
  pubUpdateDate: null,
};

export default function MockPaper(data: Partial<PaperRecordFactoryProps> = {}): PaperRecord {
  return PaperRecordFactory({
    ...defaultData,
    ...data,
  });
}

export function MockPaperLite(data: Partial<PaperRecordFactoryProps> = {}): PaperLiteRecord {
  return getPaperLiteFromPaper(MockPaper(data));
}

export function MockPaperLiteFromJs(data: Partial<PaperLiteFromJS> = {}): PaperLiteRecord {
  return getPaperLiteFromJS({
    ...defaultPaperLiteData,
    ...data,
  });
}

export function getRawPaperFixture(overrides: Partial<PaperFromJS> = {}): PaperFromJS {
  return {
    id: '17453ddcfa6b29ab3d6d277fe88e204bb2ae23e4',
    corpusId: 52869656,
    title: {
      text: 'Data Mining: Concepts and Techniques',
      fragments: [],
    },
    paperAbstract: {
      text: '',
      fragments: [],
    },
    authors: [
      [
        {
          name: 'Jiawei Han',
          ids: ['1722175'],
          slug: 'Jiawei-Han',
        },
        {
          text: 'Jiawei Han',
          fragments: [],
        },
      ],
      [
        {
          name: 'Micheline Kamber',
          ids: ['7832113'],
          slug: 'Micheline-Kamber',
        },
        {
          text: 'Micheline Kamber',
          fragments: [],
        },
      ],
    ],
    structuredAuthors: [
      {
        firstName: 'Jiawei',
        middleNames: [],
        lastName: 'Han',
      },
      {
        firstName: 'Micheline',
        middleNames: [],
        lastName: 'Kamber',
      },
    ],
    badges: [],
    cues: {},
    debugInfo: null,
    doiInfo: null,
    fieldsOfStudy: [],
    isPdfVisible: false,
    indexInclusionReason: null,
    journal: null,
    paperAbstractTruncated: null,
    pubDate: null,
    readerId: null,
    tldr: null,
    venueId: '',
    year: {
      text: '2000',
      fragments: [],
    },
    venue: {
      text: '',
      fragments: [],
    },
    citationStats: {
      citedByBuckets: [
        {
          startKey: 1998,
          endKey: 1998,
          count: 4,
        },
        {
          startKey: 1999,
          endKey: 1999,
          count: 4,
        },
        {
          startKey: 2000,
          endKey: 2000,
          count: 7,
        },
        {
          startKey: 2001,
          endKey: 2001,
          count: 70,
        },
        {
          startKey: 2002,
          endKey: 2002,
          count: 143,
        },
        {
          startKey: 2003,
          endKey: 2003,
          count: 209,
        },
        {
          startKey: 2004,
          endKey: 2004,
          count: 308,
        },
        {
          startKey: 2005,
          endKey: 2005,
          count: 421,
        },
        {
          startKey: 2006,
          endKey: 2006,
          count: 478,
        },
        {
          startKey: 2007,
          endKey: 2007,
          count: 513,
        },
        {
          startKey: 2008,
          endKey: 2008,
          count: 570,
        },
        {
          startKey: 2009,
          endKey: 2009,
          count: 833,
        },
        {
          startKey: 2010,
          endKey: 2010,
          count: 829,
        },
        {
          startKey: 2011,
          endKey: 2011,
          count: 839,
        },
        {
          startKey: 2012,
          endKey: 2012,
          count: 1018,
        },
        {
          startKey: 2013,
          endKey: 2013,
          count: 993,
        },
        {
          startKey: 2014,
          endKey: 2014,
          count: 1018,
        },
        {
          startKey: 2015,
          endKey: 2015,
          count: 977,
        },
        {
          startKey: 2016,
          endKey: 2016,
          count: 845,
        },
        {
          startKey: 2017,
          endKey: 2017,
          count: 799,
        },
        {
          startKey: 2018,
          endKey: 2018,
          count: 332,
        },
      ],
      keyCitedByBuckets: [
        {
          startKey: 2000,
          endKey: 2000,
          count: 1,
        },
        {
          startKey: 2001,
          endKey: 2001,
          count: 11,
        },
        {
          startKey: 2002,
          endKey: 2002,
          count: 12,
        },
        {
          startKey: 2003,
          endKey: 2003,
          count: 34,
        },
        {
          startKey: 2004,
          endKey: 2004,
          count: 32,
        },
        {
          startKey: 2005,
          endKey: 2005,
          count: 46,
        },
        {
          startKey: 2006,
          endKey: 2006,
          count: 60,
        },
        {
          startKey: 2007,
          endKey: 2007,
          count: 61,
        },
        {
          startKey: 2008,
          endKey: 2008,
          count: 79,
        },
        {
          startKey: 2009,
          endKey: 2009,
          count: 113,
        },
        {
          startKey: 2010,
          endKey: 2010,
          count: 110,
        },
        {
          startKey: 2011,
          endKey: 2011,
          count: 115,
        },
        {
          startKey: 2012,
          endKey: 2012,
          count: 155,
        },
        {
          startKey: 2013,
          endKey: 2013,
          count: 160,
        },
        {
          startKey: 2014,
          endKey: 2014,
          count: 169,
        },
        {
          startKey: 2015,
          endKey: 2015,
          count: 154,
        },
        {
          startKey: 2016,
          endKey: 2016,
          count: 110,
        },
        {
          startKey: 2017,
          endKey: 2017,
          count: 106,
        },
        {
          startKey: 2018,
          endKey: 2018,
          count: 49,
        },
      ],
      numCitations: 11314,
      estNumCitations: 22031.362740399843,
      numReferences: 0,
      numKeyCitations: 1586,
      numKeyReferences: 0,
      numViewableReferences: 0,
      keyCitationRate: 0.14018030758352484,
    },
    socialLinks: [],
    links: [],
    alternatePaperLinks: [],
    entities: [
      {
        id: '7837',
        name: 'Data mining',
        slug: 'Data-mining',
      },
    ],
    entityRelations: [],
    faqs: [],
    scorecardStats: [
      {
        typeKey: 'cited_by',
        citationCount: 22031,
        keyCitationCount: 1586,
        citationIntentCount: {
          background: 1,
          methodology: 2,
          result: 3,
        },
      },
    ],
    hasPdf: false,
    primaryPaperLink: {
      linkType: 'anansi',
      publisherName: 'Anansi',
      url: 'http://www.someplacescary.com',
    },

    // Override properties
    ...overrides,
  };
}
