import { ApiResponse, PaperDetailResponseBody } from '@/api/ApiResponse';
import { Nullable } from '@/utils/types';
import Constants from '@/constants';
import GraphQLApi, { GraphQLResponse } from '@/api/GraphQLApi';

import gql from 'graphql-tag';

/**
 * Given the result of an paper detail api call, transform successfully completed api calls into final PaperDetailLoadedDispatch.
 */
export function createPaperDetailLoadedDispatch(
  paperDetailApiPayload: ApiResponse<PaperDetailResponseBody>
): Nullable<{
  actionType: typeof Constants.actions.PAPER_DETAIL_LOADED;
  paperDetailResponse: PaperDetailResponseBody;
}> {
  const isApiComplete =
    paperDetailApiPayload?.actionType === Constants.actions.API_REQUEST_COMPLETE;
  const isPaperDetailResponseData = !!paperDetailApiPayload?.resultData.paper; // as opposed to a canonical paper redirect

  if (!isApiComplete || !isPaperDetailResponseData) {
    return null;
  }

  const originalResultData = paperDetailApiPayload.resultData;

  // any required mutations before the PaperStore gets the dispatch can be done here

  const paperDetailLoadedDispatch = {
    actionType: Constants.actions.PAPER_DETAIL_LOADED,
    paperDetailResponse: originalResultData,
  };
  return paperDetailLoadedDispatch;
}

export type PaperRedirectByCorpusIdResponseFromGraphQL = {
  paper: {
    id: string;
    slug: string;
  };
};

export async function fetchPaperRedirectByCorpusId(
  { corpusId }: { corpusId: number },
  { graphQLApi }: { graphQLApi: GraphQLApi }
): Promise<ApiResponse<GraphQLResponse<PaperRedirectByCorpusIdResponseFromGraphQL>>> {
  const query = gql`
    query GetPaperRedirectByCorpusId($corpusId: Long) {
      paper(corpusId: $corpusId) {
        id
        slug
      }
    }
  `;

  const variables = {
    corpusId,
  };

  const result = await graphQLApi.fetchQuery<PaperRedirectByCorpusIdResponseFromGraphQL>({
    requestType: Constants.requestTypes.GQL__NO_STORE,
    query,
    variables,
    operationName: 'GetPaperRedirectByCorpusId',
  });
  return result;
}
