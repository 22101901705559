import Immutable from 'immutable';

export type JournalFromJS = {
  name: string;
  volume: string;
  pages: string;
};

const defaultProps: JournalFromJS = {
  name: '',
  volume: '',
  pages: '',
};

export const JournalRecordFactory = Immutable.Record(defaultProps);
export type JournalRecord = Immutable.RecordOf<JournalFromJS>;
