import { Nullable } from '@/utils/types';

import { gql } from 'graphql-tag';
import Immutable from 'immutable';

export type ProfilePictureFromJS = {
  height: Nullable<number>;
  url: string;
  width: Nullable<number>;
};

export type ProfilePictureFromGraphQL = {
  height: Nullable<number>;
  url: string;
  width: Nullable<number>;
};

type Props = {
  height: Nullable<number>;
  url: string;
  width: Nullable<number>;
};

const defaultProps: Props = {
  height: null,
  url: '',
  width: null,
};

export const ProfilePictureRecordFactory = Immutable.Record<Props>(defaultProps);

export type ProfilePictureRecord = Immutable.RecordOf<Props>;

export function getProfilePictureFromJS(args: ProfilePictureFromJS): ProfilePictureRecord {
  return ProfilePictureRecordFactory({
    height: args.height,
    url: args.url,
    width: args.width,
  });
}

export function getProfilePictureFromGraphQL(
  args: ProfilePictureFromGraphQL
): ProfilePictureRecord {
  return ProfilePictureRecordFactory({
    height: args.height,
    url: args.url,
    width: args.width,
  });
}

export const PROFILE_PICTURE_FRAGMENT_NAME = 'ProfilePictureRecordFragment';

export const PROFILE_PICTURE_FRAGMENT = gql`
  fragment ${PROFILE_PICTURE_FRAGMENT_NAME} on Image {
    height
    url
    width
  }
`;
