import React from 'react';

export default function IconFlag(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 32 32">
      <g>
        <path
          d="M4.774,3.542v25.201c0,0.82-0.663,1.486-1.487,1.486c-0.822,0-1.487-0.666-1.487-1.486V3.542
          c0-0.823,0.665-1.487,1.487-1.487C4.111,2.054,4.774,2.719,4.774,3.542z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.199,9.43c-0.762,1.536-1.579,3.035-2.691,4.353
          c-0.751,0.888-1.715,1.466-2.789,1.866c-1.782,0.662-3.59,0.743-5.393,0.108c-0.628-0.22-1.214-0.552-1.807-0.854
          c-1.115-0.57-2.273-0.946-3.537-0.897c-1.693,0.064-3.03,0.839-4.12,2.091c-0.22,0.254-0.419,0.525-0.639,0.807
          c0-0.824-0.002-1.627-0.003-2.432C7.218,10.989,7.214,7.506,7.216,4.025c0-0.09,0.027-0.213,0.087-0.265
          c1.25-1.103,2.666-1.845,4.357-1.972c1.534-0.116,2.87,0.396,4.08,1.303c0.763,0.57,1.375,1.287,2.014,1.981
          c1.126,1.235,2.245,2.485,3.481,3.612c0.734,0.666,1.499,1.278,2.398,1.712c1.405,0.677,2.714,0.505,3.935-0.439
          C27.786,9.789,27.988,9.605,28.199,9.43z"
        />
      </g>
    </symbol>
  );
}
