import CLPageAlertBase from './CLPageAlertBase';

import { ReactNodeish } from '@/utils/types';

import classnames from 'classnames';
import React, { ComponentProps } from 'react';

type CLPageAlertDangerProps = ComponentProps<typeof CLPageAlertBase>;

export default function CLPageAlertDanger({
  className,
  ...otherProps
}: CLPageAlertDangerProps): ReactNodeish {
  return (
    <CLPageAlertBase className={classnames(className, 'cl-page-alert--danger')} {...otherProps} />
  );
}
