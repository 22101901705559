import { mkHeapAttributes } from '@/analytics/heapUtils';

// FTUE Page to open the modal

export const heapCreateLibraryFTUEStartButton = () =>
  mkHeapAttributes({
    id: 'ftue_create_library_start_button',
  });

// CreateLibraryFTUEModal

export const heapCreateLibraryFTUESearchButton = () =>
  mkHeapAttributes({
    id: 'ftue_create_library_search_button',
  });

export const heapCreateLibraryFTUEAddPaperButton = () =>
  mkHeapAttributes({
    id: 'ftue_create_library_add_paper_button',
  });

export const heapCreateLibraryFTUESetupLaterButton = () =>
  mkHeapAttributes({
    id: 'ftue_create_library_finish_setup_later_button',
  });

export const heapCreateLibraryFTUEFinishSetupButton = () =>
  mkHeapAttributes({
    id: 'ftue_create_library_finish_setup_button',
  });
