import { DECORATIVE_ALT_TEXT } from '@/utils/a11y-utils';
import IconStore from '@/stores/IconStore';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import type { IconId } from './IconSprite';
import type { Nullable } from '@/utils/types';

export type IconElement = typeof Icon;

export type Props = {
  icon: IconId;
  alt?: Nullable<string>;
  testId?: Nullable<string>;
} & React.SVGAttributes<SVGSVGElement>;

export default class Icon extends React.PureComponent<Props> {
  static defaultProps = {
    alt: DECORATIVE_ALT_TEXT,
  };

  static contextTypes = {
    iconStore: PropTypes.instanceOf(IconStore).isRequired,
  };

  declare context: {
    iconStore: IconStore;
  };

  constructor(...args: [any]) {
    super(...args);
    this.context.iconStore.addIconToSprite(this.props.icon);
  }

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.icon !== this.props.icon) {
      this.context.iconStore.addIconToSprite(this.props.icon);
    }
  }

  render(): React.ReactNode {
    const { icon, testId, alt, ...otherProps } = this.props;
    const newProps = {
      ...otherProps,
      alt,
      className: classnames(this.props.className, 'icon-svg', `icon-${icon}`),
      'data-test-id': testId || `icon-${icon}`,
    };

    return (
      <svg aria-hidden={alt ? undefined : true} {...newProps}>
        <use xlinkHref={`#${icon}`} />
      </svg>
    );
  }
}
