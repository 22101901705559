import { getDirectShareUrlWithCorpusId } from './social-links-util';

import CitationStyle, { CitationStyleType } from '@/constants/citation-style';
import Routes, { makePath } from '@/router/Routes';

import moment from 'moment';

import type { Nullable } from './types';
import type { PaperRecord } from '@/models/Paper';
import type Immutable from 'immutable';

export type StructuredAuthorName = {
  lastName?: Nullable<string>;
  firstName?: Nullable<string>;
  middleNames: Immutable.List<string>;
};

const TERMINATING_PUNCTUATION = { '!': true, '.': true, '?': true };

// BibTeX escaped characters per http://www.bibtex.org/SpecialSymbols/
// https://link.springer.com/content/pdf/bbm%3A978-3-319-06425-3%2F1.pdf
// http://tug.ctan.org/info/symbols/comprehensive/symbols-letter.pdf
const BibTexSpecialChars = [
  ['{', '\\{'],
  ['}', '\\}'],
  ['$', '\\$'],
  ['&', '\\&'],
  ['%', '\\%'],
  ['#', '\\#'],
  ['_', '\\_'],
  ['Ä', '{\\"A}'],
  ['ä', '{\\"a}'],
  ['Ë', '{\\"E}'],
  ['ë', '{\\"e}'],
  ['Ï', '{\\"I}'],
  ['ï', '{\\"i}'],
  ['Ö', '{\\"O}'],
  ['ö', '{\\"o}'],
  ['Ü', '{\\"U}'],
  ['ü', '{\\"u}'],
  ['Ÿ', '{\\"Y}'],
  ['ÿ', '{\\"y}'],
  ['ß', '{\\ss}'],
  ['Ø', '{\\O}'],
  ['ø', '{\\o}'],
  ['Á', "{\\'A}"],
  ['á', "{\\'a}"],
  ['Ć', "{\\'C}"],
  ['ć', "{\\'c}"],
  ['É', "{\\'E}"],
  ['é', "{\\'e}"],
  ['Í', "{\\'I}"],
  ['í', "{\\'i}"],
  ['Ó', "{\\'O}"],
  ['ó', "{\\'o}"],
  ['Ú', "{\\'U}"],
  ['ú', "{\\'u}"],
  ['Ý', "{\\'Y}"],
  ['ý', "{\\'y}"],
  ['À', '{\\`A}'],
  ['à', '{\\`a}'],
  ['È', '{\\`E}'],
  ['è', '{\\`e}'],
  ['Ì', '{\\`I}'],
  ['ì', '{\\`i}'],
  ['Ò', '{\\`O}'],
  ['ò', '{\\`o}'],
  ['Ù', '{\\`U}'],
  ['ù', '{\\`u}'],
  ['Â', '{\\^A}'],
  ['â', '{\\^a}'],
  ['Ê', '{\\^E}'],
  ['ê', '{\\^e}'],
  ['Î', '{\\^I}'],
  ['î', '{\\^i}'],
  ['Ô', '{\\^O}'],
  ['ô', '{\\^o}'],
  ['Û', '{\\^u}'],
  ['û', '{\\^u}'],
  ['Ã', '{\\~A}'],
  ['ã', '{\\~a}'],
  ['Ñ', '{\\~N}'],
  ['ñ', '{\\~n}'],
  ['Õ', '{\\~O}'],
  ['õ', '{\\~o}'],
  ['Æ', '{\\aE}'],
  ['æ', '{\\ae}'],
  ['Œ', '{\\OE}'],
  ['œ', '{\\oe}'],
  ['Č', '{\\vC}'],
  ['č', '{\\vc}'],
  ['Š', '{\\vS}'],
  ['š', '{\\vs}'],
  ['ž', '{\\vz}'],
  ['Å', '{\\AA}'],
  ['å', '{\\aa}'],
  ['™', '{\\texttrademark}'],
  ['®', '{\\textregistered}'],
  ['©', '{\\textcopyright}'],
  ['α', '$\\alpha$'],
  ['β', '$\\beta$'],
  ['γ', '$\\gamma$'],
  ['Γ', '$\\Gamma$'],
  ['δ', '$\\delta$'],
  ['Δ', '$\\Delta$'],
  ['ε', '$\\epsilon$'],
  ['ζ', '$\\zeta$'],
  ['η', '$\\eta$'],
  ['θ', '$\\theta$'],
  ['Θ', '$\\Theta$'],
  ['ι', '$\\iota$'],
  ['κ', '$\\kappa$'],
  ['λ', '$\\lambda$'],
  ['Λ', '$\\Lambda$'],
  ['μ', '$\\mu$'],
  ['ν', '$\\nu$'],
  ['ξ', '$\\xi$'],
  ['ο', '$\\omicron$'],
  ['π', '$\\pi$'],
  ['Π', '$\\Pi$'],
  ['ρ', '$\\rho$'],
  ['σ', '$\\sigma$'],
  ['Σ', '$\\Sigma$'],
  ['τ', '$\\tau$'],
  ['υ', '$\\upsilon$'],
  ['Υ', '$\\Upsilon$'],
  ['φ', '$\\phi$'],
  ['Φ', '$\\Phi$'],
  ['χ', '$\\chi$'],
  ['ψ', '$\\psi$'],
  ['Ψ', '$\\Psi$'],
  ['ω', '$\\omega$'],
  ['Ω', '$\\Omega$'],
] as const;

export function encodeBibTexComponent(str: string): string {
  return BibTexSpecialChars.reduce((curStr, [character, replacement]) => {
    return curStr.replace(new RegExp(`[${character}]`, 'g'), replacement);
  }, str + '');
}

function authorNameLastThenFirst(
  structuredAuthorName: StructuredAuthorName,
  initialize?: boolean
): string {
  let formatted: string;
  const { firstName, lastName, middleNames } = structuredAuthorName;
  if (lastName && firstName) {
    const middleName = middleNames.join(' ');
    if (initialize) {
      const middleInitial =
        middleName.length > 0 ? middleName.substr(0, 1).toUpperCase() + '.' : '';
      const firstNameInitial = firstName.substr(0, 1).toUpperCase() + '.';
      formatted = `${lastName}, ${firstNameInitial}${middleInitial}`;
    } else {
      formatted = `${lastName}, ${firstName} ${middleName}`;
    }
  } else {
    // Fallback to something
    formatted = lastName || firstName || middleNames.first() || '';
  }
  return formatted.trim();
}

function authorNameFirstThenLast(structuredAuthorName: StructuredAuthorName): string {
  let formatted: string;
  const { firstName, lastName, middleNames } = structuredAuthorName;
  if (lastName && firstName) {
    formatted = [firstName, ...middleNames].concat([lastName]).join(' ');
  } else {
    formatted = lastName || firstName || middleNames.first() || '';
  }
  return formatted.trim();
}

/**
 * Returns the paper title with the following formatting changes:
 *  - removes wrapping whitespace
 *  - appends a period, unless the title ends in a '.', '!' or '?'
 * @param {string} title the title
 * @returns {string}
 */
function formattedPaperTitle(title: string): string {
  // use a map for fast lookup -- if the title ends in one of these we don't append a trailing '.'
  const withoutWhitespace = title.trim();
  if (!TERMINATING_PUNCTUATION[withoutWhitespace.substr(-1)]) {
    return `${withoutWhitespace}.`;
  } else {
    return withoutWhitespace;
  }
}

/**
 * Returns the year from paper.pubDate, falling back to paper.year only if there is no pubDate value
 * @param {PaperRecord} paper the paper
 * @returns {string}
 */
function preferPubdateYear(paper: PaperRecord): Nullable<string> {
  const pubDateText = paper.pubDate?.text || null;
  const pubDate = pubDateText ? moment(pubDateText, 'D MMMM YYYY').year().toString() : null;
  const fallbackYear = paper.year?.text || null;
  return pubDate || fallbackYear;
}

export function getMLACitation(paper: PaperRecord, abbreviateAuthors: boolean): string {
  const fields: string[] = [];

  // Authors
  if (!paper.structuredAuthors.isEmpty()) {
    const authorsLen = paper.structuredAuthors.size;
    if (abbreviateAuthors && authorsLen >= 3) {
      const firstAuthor = authorNameLastThenFirst(paper.structuredAuthors.first());
      fields.push(`${firstAuthor} et al.`);
    } else {
      fields.push(
        paper.structuredAuthors
          .map((author, index) => {
            if (index === 0) {
              const formattedName = authorNameLastThenFirst(author);
              if (authorsLen === 1) {
                return `${formattedName}.`;
              } else {
                return formattedName;
              }
            } else {
              const formatted = authorNameFirstThenLast(author);
              if (index === authorsLen - 1) {
                return ` and ${formatted}.`;
              } else {
                return `, ${formatted}`;
              }
            }
          })
          .join('')
      );
    }
  }

  // Title
  fields.push(`“${formattedPaperTitle(paper.title.text)}”`);

  // Journal / Venue Information and Year
  const yearString = preferPubdateYear(paper);
  const year = yearString ? ` (${yearString})` : '';

  if (paper.journal && paper.journal.name) {
    const pages = paper.journal.pages ? `: ${paper.journal.pages}` : ': n. pag';
    const volume = paper.journal.volume ? ` ${paper.journal.volume}` : '';
    fields.push(`<em>${paper.journal.name}</em>${volume}${year}${pages}.`);
  } else if (paper.venue && paper.venue.text) {
    fields.push(`<em>${paper.venue.text}</em>${year}.`);
  } else {
    fields.push(`${year}.`);
  }

  return fields.join(' ');
}

export function getAPACitation(paper: PaperRecord): string {
  const fields: string[] = [];

  // Authors
  if (!paper.structuredAuthors.isEmpty()) {
    fields.push(
      paper.structuredAuthors
        .map((author, index) => {
          const formatted = authorNameLastThenFirst(author, true);
          if (index > 0 && index === paper.structuredAuthors.size - 1) {
            return `& ${formatted}`;
          } else {
            return formatted;
          }
        })
        .join(', ')
    );
  }

  // Year
  const yearString = preferPubdateYear(paper);
  fields.push(yearString ? `(${yearString}).` : '');

  // Title
  fields.push(formattedPaperTitle(paper.title.text));

  // Journal / Venue Information
  if (paper.journal && paper.journal.name) {
    const pages = paper.journal.pages ? `, ${paper.journal.pages}` : '';
    const volume = paper.journal.volume ? `, ${paper.journal.volume}` : '';
    fields.push(`<em>${paper.journal.name}${volume}</em>${pages}.`);
  } else if (paper.venue && paper.venue.text) {
    fields.push(`<em>${paper.venue.text}</em>.`);
  }

  return fields.join(' ');
}

const PatternNonWordCharacters = /\W/g;
const PatternOneOrMoreSpaces = /\s/;

export function getBibTexCitation(paper: PaperRecord): string {
  const hasJournal = paper.journal && paper.journal.name ? true : false;
  const type = hasJournal ? 'article' : 'inproceedings';
  const firstAuthor = paper.structuredAuthors.first();

  // For the third portion of the id we'd like to use the first word of the title and the first
  // letter of the second and third words (this is per user feedback)
  const titleWords =
    paper.title && paper.title.text ? paper.title.text.split(PatternOneOrMoreSpaces) : [];
  const abbreviatedTitle = [titleWords.shift(), titleWords.shift(), titleWords.shift()]
    .filter(str => !!str)
    // @ts-expect-error -- the filter call above ensures str is not undefined
    .map((str, i) => (i > 0 ? str.substr(0, 1).toUpperCase() : str))
    .join('');

  const yearString = preferPubdateYear(paper);

  const idParts = [yearString ? yearString : '', abbreviatedTitle];
  if (firstAuthor) {
    if (firstAuthor.lastName) {
      idParts.unshift(firstAuthor.lastName);
    } else if (firstAuthor.firstName) {
      idParts.unshift(firstAuthor.firstName);
    }
  }

  const id = idParts
    .map(str => str.replace(PatternNonWordCharacters, ''))
    .join('')
    .substr(0, 45);
  const authors = paper.structuredAuthors.map(authorNameFirstThenLast).join(' and ');
  const journalOrVenue = hasJournal
    ? `journal={${encodeBibTexComponent(paper.journal?.name || '')}}`
    : paper.venue && paper.venue.text
      ? `booktitle={${encodeBibTexComponent(paper.venue.text)}}`
      : undefined;
  const year = yearString ? `year={${encodeBibTexComponent(yearString)}}` : undefined;
  const volume =
    hasJournal && paper.journal?.volume
      ? `volume={${encodeBibTexComponent(paper.journal.volume)}}`
      : undefined;
  const pages =
    hasJournal && paper.journal?.pages
      ? `pages={${encodeBibTexComponent(paper.journal.pages)}}`
      : undefined;

  const paperShareUrl = getPaperShareUrl(paper);
  const url = paperShareUrl ? `url={${encodeBibTexComponent(paperShareUrl)}}` : undefined;

  const fields = [
    `title={${encodeBibTexComponent(paper.title.text)}}`,
    `author={${encodeBibTexComponent(authors)}}`,
    journalOrVenue,
    year,
    volume,
    pages,
    url,
  ].filter(v => !!v);

  const fieldsStr = fields.reduce((str, field) => `${str},\n  ${field || ''}`, '') + '\n';
  return `@${type}{${encodeBibTexComponent(id)}${fieldsStr}}`;
}

export function getEndNoteCitation(paper: PaperRecord): string {
  const hasJournal = !!paper.journal?.name;
  const type = hasJournal ? 'Journal Article' : 'Conference Proceedings';
  const authors = paper.structuredAuthors.map(author => {
    return `%A ${authorNameLastThenFirst(author)}`;
  });
  const formatted = [`%0 ${type}`, `%T ${paper.title.text}`].concat(authors.toArray());
  const yearString = preferPubdateYear(paper);
  if (hasJournal) {
    formatted.push(`%J ${paper.journal?.name}`);
    if (paper.journal?.volume) {
      formatted.push(`%V ${paper.journal.volume}`);
    }
    if (paper.journal?.pages) {
      formatted.push(`%P ${paper.journal.pages}`);
    }
  } else if (paper.venue?.text) {
    formatted.push(`%B ${paper.venue.text}`);
  }
  if (yearString) {
    formatted.push(`%D ${yearString}`);
  }

  return `${formatted.join('\n')}`;
}

export function getPaperShareUrl(paper: PaperRecord): string {
  let url = '';

  if (paper.corpusId) {
    return getDirectShareUrlWithCorpusId(paper.corpusId);
  }

  if (typeof window !== 'undefined') {
    const path = makePath({
      path: Routes.PAPER_DETAIL_BY_ID,
      params: {
        paperId: paper.id,
      },
    });

    url = `${window.location.origin}${path}`;
  }

  return url;
}

export function getCitation(paper: PaperRecord, style: CitationStyleType): string {
  switch (style) {
    case CitationStyle.CHICAGO:
    case CitationStyle.MLA: {
      return getMLACitation(paper, CitationStyle.MLA === style);
    }
    case CitationStyle.APA: {
      return getAPACitation(paper);
    }
    case CitationStyle.BIBTEX: {
      return getBibTexCitation(paper);
    }
    case CitationStyle.ENDNOTE: {
      return getEndNoteCitation(paper);
    }
    default: {
      throw new Error(`Unknown citation style: ${style}`);
    }
  }
}

export function getFilename(style: CitationStyleType): string {
  switch (style) {
    case CitationStyle.BIBTEX:
      return 'citation.bib';
    case CitationStyle.ENDNOTE:
      return 'citation.enw';
    default:
      throw new Error(`Unknown citation style: ${style}`);
  }
}
