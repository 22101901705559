import { Nullable, ValueOf } from '@/utils/types';

import invariant from 'invariant';

export type AnnotationType = ValueOf<typeof byKey>;

export const More = 'More';
export const Less = 'Less';
export const NotRelevant = 'NotRelevant';
export const Unsupported = 'Unsupported';
export const NotRelevantRemoveX = 'NotRelevantRemoveX';

const byKey = {
  More,
  Less,
  NotRelevant,
  Unsupported,
  NotRelevantRemoveX,
} as const;

export const AnnotationTypeMap = Object.freeze(byKey);

export function getOptAnnotationType(optAnnotationType?: string): Nullable<AnnotationType> {
  if (optAnnotationType && optAnnotationType in byKey) {
    return byKey[optAnnotationType];
  }
  return null;
}

export function getAnnotationType(optAnnotationType?: string): AnnotationType {
  const expAnnotationType = getOptAnnotationType(optAnnotationType);
  invariant(expAnnotationType, `"${optAnnotationType || ''}" is not a valid AnnotationType`);
  return expAnnotationType;
}
