import { getVariationFromJS, VariationFromJS, VariationRecord } from './VariationRecord';

import Immutable from 'immutable';
import invariant from 'invariant';

import type { ExperimentKey, VariationKey } from './Experiment';
import type { Nullable } from '@/utils/types';
import type { RoutePath } from '@/router/Routes';

export enum ExperimentAllocation {
  Session = 'session',
  User = 'user',
}

type RouteBasedExposure = {
  path?: Nullable<RoutePath>;
  exact?: Nullable<boolean>;
  manualExposure?: Nullable<never>;
};

type ManualExposure = {
  path?: Nullable<never>;
  exact?: Nullable<never>;
  manualExposure?: Nullable<boolean>;
};

export type ExperimentFromJS = {
  key: ExperimentKey;
  description: string;
  allocationType: ExperimentAllocation;
  variations: VariationFromJS[];
  defaultVariation: VariationKey;
  hiddenFromOverrides?: Nullable<boolean>;
  includeInPerfMetrics?: Nullable<boolean>;
} & (RouteBasedExposure | ManualExposure);

type Props = {
  KEY: ExperimentKey;
  description: string;
  allocationType: ExperimentAllocation;
  path: Nullable<RoutePath>;
  exact: boolean;
  manualExposure: boolean;
  variations: Immutable.List<VariationRecord>;
  Variation: { [key in string]: VariationKey };
  defaultVariation: VariationKey;
  hiddenFromOverrides: boolean;
  includeInPerfMetrics: boolean;
};

function toVariationObj(variations: VariationFromJS[]): { [key: string]: VariationKey } {
  return variations.reduce((map, { key }) => {
    map[key.toUpperCase()] = key;
    return map;
  }, {});
}

const KEY_REGEX = /^\w+$/;

const defaultProps: Props = {
  // @ts-expect-error -- There is no default key
  KEY: '',
  description: '',
  allocationType: ExperimentAllocation.Session,
  // @ts-expect-error -- There is no default path
  path: '',
  exact: false,
  manualExposure: false,
  Variation: {},
  variations: Immutable.List(),
  defaultVariation: '',
  hiddenFromOverrides: false,
  includeInPerfMetrics: false,
};

export const ExperimentRecordFactory = Immutable.Record(defaultProps);
export type ExperimentRecord = Immutable.RecordOf<Props>;

export function getExperimentFromJS(args: ExperimentFromJS): ExperimentRecord {
  const {
    key,
    variations,
    defaultVariation,
    hiddenFromOverrides,
    includeInPerfMetrics,
    description,
    allocationType,
  } = args;
  invariant(KEY_REGEX.test(key), 'Key must be valid (just word characters)');

  if ('manualExposure' in args) {
    const { manualExposure } = args;
    return ExperimentRecordFactory({
      KEY: key,
      Variation: toVariationObj(variations),
      variations: Immutable.List(variations).map(raw => getVariationFromJS(raw)),
      manualExposure: !!manualExposure,
      defaultVariation: defaultVariation || '',
      hiddenFromOverrides: !!hiddenFromOverrides,
      includeInPerfMetrics: !!includeInPerfMetrics,
      description,
      allocationType,
      path: null,
      exact: false,
    });
  } else {
    const { path, exact } = args;
    return ExperimentRecordFactory({
      path,
      KEY: key,
      Variation: toVariationObj(variations),
      variations: Immutable.List(variations).map(raw => getVariationFromJS(raw)),
      exact: !!exact,
      defaultVariation: defaultVariation || '',
      hiddenFromOverrides: !!hiddenFromOverrides,
      includeInPerfMetrics: !!includeInPerfMetrics,
      description,
      allocationType,
      manualExposure: false,
    });
  }
}
