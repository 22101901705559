import AuthorProfileStore from './AuthorProfileStore';

import { Nullable } from '@/utils/types';
import {
  PaperAuthorCorrectionFactory,
  PaperAuthorCorrectionRecord,
} from '@/models/PaperAuthorCorrection';
import BaseStore from '@/stores/BaseStore';
import CorrectionType, { CorrectionTypeValue } from '@/constants/CorrectionType';
import S2Dispatcher from '@/utils/S2Dispatcher';

import Immutable from 'immutable';
import invariant from 'invariant';

export default class AuthorCorrectionsStore extends BaseStore {
  _authorProfileStore: AuthorProfileStore;
  _dispatcher: S2Dispatcher;
  _draftPaperIds: Immutable.Set<string>;
  _pendingCorrections: Immutable.Map<string, PaperAuthorCorrectionRecord>;
  _pendingIndexRequestUrls: Immutable.Set<string>;
  _moderatorCorrectionsAuthorId: Nullable<number>;
  _isModeratorCorrecting: boolean;

  constructor(dispatcher: S2Dispatcher, authorProfileStore: AuthorProfileStore) {
    super();
    this._authorProfileStore = authorProfileStore;
    this._dispatcher = dispatcher;
    this._draftPaperIds = Immutable.Set();
    this._pendingCorrections = Immutable.Map();
    this._pendingIndexRequestUrls = Immutable.Set();
    this._moderatorCorrectionsAuthorId = null;
    this._isModeratorCorrecting = false;
  }

  enqueueAddPaperCorrection({ paperId, position }: { paperId: string; position: number }): void {
    const correction = PaperAuthorCorrectionFactory({
      correctionType: CorrectionType.AddAuthorship,
      ...this._getClaimedAuthorIds(),
      paperHash: paperId,
      position,
    });
    this._pendingCorrections = this._pendingCorrections.set(paperId, correction);
    this._draftPaperIds = this._draftPaperIds.remove(paperId);
    this.emitChange();
  }

  enqueueRemovePaperCorrection({ paperId, position }: { paperId: string; position: number }): void {
    const correction = PaperAuthorCorrectionFactory({
      correctionType: CorrectionType.RemoveAuthorship,
      ...this._getClaimedAuthorIds(),
      paperHash: paperId,
      position,
    });
    this._pendingCorrections = this._pendingCorrections.set(paperId, correction);
    this._draftPaperIds = this._draftPaperIds.remove(paperId);
    this.emitChange();
  }

  enqueueSwapPaperCorrection({
    fromAuthorId,
    paperId,
    position,
  }: {
    fromAuthorId: Nullable<number>;
    paperId: string;
    position: number;
  }): void {
    const correction = PaperAuthorCorrectionFactory({
      correctionType: CorrectionType.SwapAuthorship,
      ...this._getClaimedAuthorIds(),
      fromAuthorId,
      paperHash: paperId,
      position,
    });
    this._pendingCorrections = this._pendingCorrections.set(paperId, correction);
    this._draftPaperIds = this._draftPaperIds.remove(paperId);
    this.emitChange();
  }

  setModeratorCorrectionsAuthorId(authorId: number): void {
    this._moderatorCorrectionsAuthorId = authorId;
    this.emitChange();
  }

  resetModeratorCorrections(): void {
    this._moderatorCorrectionsAuthorId = null;
    this._isModeratorCorrecting = false;
    this.emitChange();
  }

  getModeratorCorrectionsAuthorId(): Nullable<number> {
    return this._moderatorCorrectionsAuthorId;
  }

  setIsModeratorCorrecting(value: boolean): void {
    this._isModeratorCorrecting = value;
  }

  isModeratorCorrecting(): boolean {
    return this._isModeratorCorrecting;
  }

  hasPendingCorrections(): boolean {
    return !this._pendingCorrections.isEmpty();
  }

  getPendingCorrections(): Immutable.List<PaperAuthorCorrectionRecord> {
    return this._pendingCorrections.valueSeq().toList();
  }

  getPendingCorrectionTypeForPaperId(paperId: string): Nullable<CorrectionTypeValue> {
    const correction = this._pendingCorrections.get(paperId);
    if (!correction) {
      return null;
    }
    return correction.correctionType;
  }

  isCorrectionPendingForPaperId(paperId: string): boolean {
    return !!this.getPendingCorrectionTypeForPaperId(paperId);
  }

  cancelPendingCorrectionByPaperId(paperId: string): void {
    this._pendingCorrections = this._pendingCorrections.delete(paperId);
    this._draftPaperIds = this._draftPaperIds.remove(paperId);
    this.emitChange();
  }

  // providing no correctionType clears all types
  clearPendingCorrections(correctionType?: CorrectionTypeValue): void {
    let updatedCorrections;
    if (correctionType) {
      updatedCorrections = this._pendingCorrections.filter(
        correction => correction.correctionType !== correctionType
      );
    } else {
      updatedCorrections = this._pendingCorrections.clear();
    }

    // only emit if something changed
    if (this._pendingCorrections !== updatedCorrections) {
      this._pendingCorrections = updatedCorrections;
      this.emitChange();
    }
  }

  clearPendingIndexRequests(): void {
    if (this._pendingIndexRequestUrls) {
      this._pendingIndexRequestUrls = this._pendingIndexRequestUrls.clear();
      this.emitChange();
    }
  }

  addDraftPaperId(paperId: string): void {
    if (this._draftPaperIds.has(paperId)) {
      return;
    }
    this._draftPaperIds = this._draftPaperIds.add(paperId);
    this.emitChange();
  }

  removeDraftPaperId(paperId: string): void {
    if (!this._draftPaperIds.has(paperId)) {
      return;
    }
    this._draftPaperIds = this._draftPaperIds.remove(paperId);
    this.emitChange();
  }

  getDraftPaperIds(): Immutable.List<string> {
    return this._draftPaperIds.toList();
  }

  hasDraftPapers(): boolean {
    return !this._draftPaperIds.isEmpty();
  }

  addPendingIndexRequestUrl(url: string): void {
    if (this._pendingIndexRequestUrls.contains(url)) {
      return;
    }
    this._pendingIndexRequestUrls = this._pendingIndexRequestUrls.add(url);
    this.emitChange();
  }

  getPendingIndexRequestUrls(): Immutable.Set<string> {
    return this._pendingIndexRequestUrls;
  }

  removePendingIndexRequestUrl(url: string): void {
    if (!this._pendingIndexRequestUrls.has(url)) {
      return;
    }
    this._pendingIndexRequestUrls = this._pendingIndexRequestUrls.remove(url);
    this.emitChange();
  }

  _getClaimedAuthorIds(): { authorId: number } {
    if (this._moderatorCorrectionsAuthorId) {
      return { authorId: this._moderatorCorrectionsAuthorId };
    } else {
      const profile = this._authorProfileStore.getAuthorProfile();
      invariant(
        profile,
        'must have an author profile before using AuthorCorrectionsStore is author id is not provided by a moderator'
      );
      return {
        authorId: profile.ai2AuthorId,
      };
    }
  }
}
