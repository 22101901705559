import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

export default class UnsubscribeFromAlertRoute extends React.Component {
  static getPageTitle = () => getString(_ => _.alerts.unsubscribe.pageTitle);

  render() {
    return (
      <AsyncLoadedPage
        footer={false}
        load={{
          importer: () =>
            import(
              /* webpackChunkName: "shared-UnsubscribeFromAlertPage" */ './UnsubscribeFromAlertPage'
            ),
          chunkName: 'shared-UnsubscribeFromAlertPage',
          moduleId: require.resolveWeak('./UnsubscribeFromAlertPage'),
        }}
      />
    );
  }
}
