import LibraryFolderName from './LibraryFolderName';

import { getString } from '@/content/i18n';
import {
  heapLibraryShelfFolderCheckboxToggle,
  heapLibraryShelfFolderViewLink,
} from '@/analytics/attributes/libraryShelf';
import { hideShelf } from '@/actions/ShelfActionCreators';
import { LibraryFolderRecord } from '@/models/library/LibraryFolder';
import { On } from '@/models/library/RecommendationStatus';
import { Public } from '@/models/library/PrivacySetting';
import { ReactNodeish } from '@/utils/types';
import { useAppContext } from '@/AppContext';
import CLCheckboxInput, { SIZE } from '@/components/library/form/input/CLCheckboxInput';
import Icon from '@/components/shared/icon/Icon';
import Link from '@/router/Link';

import classnames from 'classnames';
import React from 'react';

type Props = {
  folder: LibraryFolderRecord;
  onChangeFolderRow: (folder: LibraryFolderRecord) => void;
  isChecked: boolean;
};

export default function LibraryShelfFolder({
  folder,
  onChangeFolderRow,
  isChecked,
}: Props): ReactNodeish {
  const { dispatcher, history } = useAppContext();

  const onChange = (): void => {
    onChangeFolderRow(folder);
  };

  const handleOnClick = (): void => {
    if (folder && history.location.pathname.includes(folder.id.toString())) {
      dispatcher.dispatch(hideShelf());
    }
  };

  const { envInfo } = useAppContext();
  const isMobile = envInfo.isMobile;
  const hasRecommendationsEnabled = folder.recommendationStatus === On;
  const isPublic = folder.privacySetting === Public;

  return (
    <div
      className={classnames('library__shelf__folder', {
        'library__shelf__folder--mobile': isMobile,
      })}>
      <label // eslint-disable-line jsx-a11y/label-has-associated-control
        className={classnames('library__shelf__folder__row', {
          'library__shelf__folder__row--mobile': isMobile,
          'library__shelf__folder__row--mobile--checked': isChecked,
        })}
        data-test-id="library-shelf-folder-row">
        <CLCheckboxInput
          ariaLabel={getString(_ => _.library.saveToLibraryShelf.checkboxAriaLabel, folder.name)}
          name={folder.name}
          value="selected"
          isChecked={isChecked}
          onChange={onChange}
          size={isMobile ? SIZE.LARGE : SIZE.DEFAULT}
          {...heapLibraryShelfFolderCheckboxToggle()}
          data-test-id="save-library-folder-checkbox"
          className={classnames({
            'library__shelf__folder-checkbox__container': true,
            'library__shelf__folder-checkbox--mobile': isMobile,
          })}
        />
        <LibraryFolderName
          className={classnames({
            'library-folder-name--mobile': isMobile,
            'library-folder-name--mobile--checked': isChecked,
          })}
          folder={folder}
          hasRecommendationsEnabled={hasRecommendationsEnabled}
          isLink={false}
          isPublic={isPublic}
        />
      </label>
      <Link
        to="LIBRARY_FOLDER"
        params={{ libraryFolderId: folder.id }}
        shouldUnderline={false}
        {...heapLibraryShelfFolderViewLink()}
        className={classnames('library__shelf__view-folder', {
          'library__shelf__view-folder--mobile': isMobile,
        })}
        data-test-id="library-folder-name-link"
        onClick={handleOnClick}>
        {getString(_ => _.library.saveToLibraryShelf.folderLinkLabel)}
        <Icon icon="arrow-lite" height="8px" width="12px" />
      </Link>
    </div>
  );
}
