import CLTextInputBase, { CLTextInputBaseProps } from './CLTextInputBase';

import { ReactNodeish } from '@/utils/types';
import Icon from '@/components/shared/icon/Icon';

import classnames from 'classnames';
import React from 'react';

type CLIconTextInputProps = {
  icon: Icon;
} & Omit<CLTextInputBaseProps, 'type'>;

export default function CLIconTextInput({
  className,
  icon,
  ...props
}: CLIconTextInputProps): ReactNodeish {
  return (
    <div className={classnames('cl-icon-text-input', className)}>
      <CLTextInputBase className="cl-icon-text-input__icon-placeholder" {...props} />
      {icon}
    </div>
  );
}
