import React from 'react';

export default function IconSearch(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 32 32">
      <path
        d="M29,28l-7.7-7.7c3.6-4.1,3.4-10.4-0.5-14.4C16.7,1.8,10,1.8,5.9,5.9c-4.1,4.1-4.1,10.8,0,14.9c2.1,2.1,4.8,3.1,7.5,3.1
        c2.5,0,4.9-0.9,6.9-2.6L28,29c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.5-0.2C29.3,28.7,29.3,28.2,29,28z M6.9,19.8
        c-3.5-3.5-3.5-9.3,0-12.8c1.8-1.8,4.1-2.7,6.4-2.7s4.7,0.9,6.4,2.7c3.5,3.5,3.5,9.3,0,12.8C16.2,23.3,10.5,23.3,6.9,19.8z"
      />
    </symbol>
  );
}
