import { getBody } from '@/browser';
import { hasValue, Nullable, ReactNodeish } from '@/utils/types';

import React from 'react';
import ReactDOM from 'react-dom';

export type Coordinates = {
  bottomPx?: Nullable<number>;
  leftPx?: Nullable<number>;
  rightPx?: Nullable<number>;
  topPx?: Nullable<number>;
};

type Props = {
  domNode?: HTMLElement;
  coordinates: Coordinates;
};

type State = {
  portalWrapper: HTMLDivElement;
  parentNode: HTMLElement;
};

export default class CLPortal extends React.PureComponent<Props, State> {
  constructor(...args: [any]) {
    super(...args);

    const { domNode, coordinates } = this.props;
    const portalWrapper = document.createElement('div');
    portalWrapper.setAttribute('style', makeCoordinateStyleStr(coordinates));

    this.state = {
      portalWrapper: portalWrapper,
      parentNode: domNode ? domNode : getBody(document), // mount portal to either the domNode if passed in, otherwise default to the body
    };
  }

  componentDidMount() {
    const { parentNode, portalWrapper } = this.state;
    if (parentNode) {
      parentNode.appendChild(portalWrapper);
    }
  }

  componentWillUnmount() {
    const { parentNode, portalWrapper } = this.state;
    if (parentNode) {
      parentNode.removeChild(portalWrapper);
    }
  }

  componentDidUpdate() {
    const { coordinates } = this.props;
    const { portalWrapper } = this.state;
    portalWrapper.setAttribute('style', makeCoordinateStyleStr(coordinates));
  }

  render(): ReactNodeish {
    return ReactDOM.createPortal(this.props.children, this.state.portalWrapper);
  }
}

export function makeCoordinateStyleStr({ topPx, rightPx, bottomPx, leftPx }: Coordinates): string {
  const styles = ['position: absolute;'];

  hasValue(topPx) && styles.push(`top: ${topPx}px;`);
  hasValue(rightPx) && styles.push(`right: ${rightPx}px;`);
  hasValue(bottomPx) && styles.push(`bottom: ${bottomPx}px;`);
  hasValue(leftPx) && styles.push(`left: ${leftPx}px;`);

  return styles.join(' ');
}
