import { focusOnFocusableElement, mkOnClickKeyDown } from '@/utils/a11y-utils';
import { KEY_CODE_ESC } from '@/constants/KeyCode';
import { ReactNodeish } from '@/utils/types';
import logger from '@/logger';

import classnames from 'classnames';
import React, { ComponentProps } from 'react';

const CENTER = 'center';
const SIDE_BOTTOM_POS_LEFT = 'bottom-left';
const SIDE_BOTTOM_POS_MIDDLE = 'bottom-middle';
const SIDE_LEFT_POS_MIDDLE = 'left-middle';
const SIDE_RIGHT_POS_BOTTOM = 'right-bottom';
const SIDE_RIGHT_POS_MIDDLE = 'right-middle';
const SIDE_TOP_POS_LEFT = 'top-left';
const SIDE_TOP_POS_MIDDLE = 'top-middle';
const SIDE_TOP_POS_RIGHT = 'top-right';

export const POSITION = {
  CENTER,
  SIDE_BOTTOM_POS_LEFT,
  SIDE_BOTTOM_POS_MIDDLE,
  SIDE_LEFT_POS_MIDDLE,
  SIDE_RIGHT_POS_BOTTOM,
  SIDE_RIGHT_POS_MIDDLE,
  SIDE_TOP_POS_LEFT,
  SIDE_TOP_POS_MIDDLE,
  SIDE_TOP_POS_RIGHT,
} as const;

type CLOverlayProps = {
  ariaProps?: Record<string, unknown>;
  position?: (typeof POSITION)[keyof typeof POSITION];
  shouldFocusOnContent?: boolean;
} & ComponentProps<'div'>;

export default function CLOverlay({
  ariaProps = {},
  children,
  className,
  position,
  shouldFocusOnContent, // set this to true when the first element is something you don't want to focus on for the intial focus
  onClick,
  ...otherProps
}: CLOverlayProps): ReactNodeish {
  const STOP_PROP = event => void event.stopPropagation();
  const positionClass = position ? `cl-overlay__content-position--${position}` : '';
  const focusTrappedRef = React.useRef(null);
  const _onClickKeyDownStopProps = React.useMemo(
    () =>
      mkOnClickKeyDown({
        onClick: STOP_PROP,
      }),
    []
  );

  React.useEffect(() => {
    const elementToFocusAfterClose = document.activeElement as HTMLElement;
    focusOnFocusableElement({
      focusTrappedRef,
      shouldFocusOnContent,
    });

    return () => {
      if (elementToFocusAfterClose) {
        elementToFocusAfterClose.focus();
      }
    };
  }, []);

  const _onClickKeyDownHideProps = React.useMemo(
    () =>
      onClick
        ? mkOnClickKeyDown({
            onClick: onClick,
            overrideKeys: [KEY_CODE_ESC],
            focusTrappedRef,
          })
        : {},
    [onClick]
  );

  return (
    <div
      {...otherProps}
      ref={focusTrappedRef}
      className={classnames('cl-overlay', positionClass, className)}
      {...ariaProps}
      {..._onClickKeyDownHideProps}>
      <div className="cl-overlay__content" {..._onClickKeyDownStopProps}>
        {children}
      </div>
    </div>
  );
}

export const exampleConfig = {
  getComponent: () => CLOverlay,
  fields: [
    {
      name: 'position',
      desc: 'Position content within the overlay',
      value: {
        type: 'select',
        default: POSITION.CENTER,
        options: [
          POSITION.CENTER,
          POSITION.SIDE_TOP_POS_LEFT,
          POSITION.SIDE_TOP_POS_MIDDLE,
          POSITION.SIDE_TOP_POS_RIGHT,
          POSITION.SIDE_RIGHT_POS_MIDDLE,
          POSITION.SIDE_RIGHT_POS_BOTTOM,
          POSITION.SIDE_BOTTOM_POS_MIDDLE,
          POSITION.SIDE_BOTTOM_POS_LEFT,
          POSITION.SIDE_LEFT_POS_MIDDLE,
        ],
      },
    },
    {
      name: 'children',
      desc: 'Content to be displayed within the component',
      value: {
        type: 'jsx',
        default: '',
        // eslint-disable-next-line react/no-danger
        transform: html => <div dangerouslySetInnerHTML={{ __html: html }} />,
      },
    },
    {
      name: 'shouldFocusOnContent',
      desc: 'Focuses on content if first interactive element is a close button or something else we do not want to focus on.',
      value: {
        type: 'boolean',
        default: false,
      },
    },
  ],

  examples: [
    {
      title: 'Basic',
      desc: 'Modal with some text',
      props: {
        widthPx: 400,
        children: `<div style="height: 50px; width: 50px; background-color: white">Some content</div>`,
        onClick: () => {
          logger.info('click overlay');
        },
      },
      render: comp => <div style={{ display: 'flex', height: '100%' }}>{comp}</div>,
    },
    {
      title: 'a11y initial focus',
      desc: 'Focuses on interactive element on initial focus',
      props: {
        widthPx: 400,
        children: `<div style="width: 50%; display: flex;" }><button style="width: 30px; height: 30px">x</button> <div style="background-color: white">'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'<a href="#"> Here </a></div><div>`,
        onClick: () => {
          logger.info('click overlay');
        },
        shouldFocusOnContent: true,
      },
      render: comp => <div>{comp}</div>,
    },
  ],

  events: {},
};

CLOverlay.defaultProps = {
  position: CENTER,
};
