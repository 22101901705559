import BaseStore from './BaseStore';

import { ApiResponse } from '@/api/ApiResponse';
import { CorpusInfoFromGraphQL } from '@/actions/CorpusInfoActionCreators';
import Constants from '@/constants';
import S2Dispatcher from '@/utils/S2Dispatcher';

// Number of papers to show in the search bar if the API fails
export const DEFAULT_PAPERS_COUNT = 214041663;

/**
 * A store for metadata about an index, such as the number of documents  available to search
 */
export default class IndexMetadataStore extends BaseStore {
  dispatchToken: string;
  totalPaperCount: number;

  constructor(dispatcher: S2Dispatcher) {
    super();
    this.totalPaperCount = DEFAULT_PAPERS_COUNT;

    this.dispatchToken = dispatcher.register(payload => {
      const apiResponse = payload as ApiResponse;
      switch (apiResponse.actionType) {
        case Constants.actions.API_REQUEST_COMPLETE: {
          if (this.isTotalPapersCountResponse(apiResponse)) {
            const corpusInfo = apiResponse.resultData.data?.corpusInfo || null;
            this.totalPaperCount = corpusInfo ? corpusInfo.paperCount : DEFAULT_PAPERS_COUNT;
            this.emitChange();
          }
          break;
        }
      }
    });
  }

  getTotalPapersCount(): number {
    return this.totalPaperCount;
  }

  isTotalPapersCountResponse(payload: ApiResponse<CorpusInfoFromGraphQL>): boolean {
    return payload.requestType === Constants.requestTypes.GQL__TOTAL_PAPER_COUNT;
  }
}
