import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

export type SampleQueryFromJS = Partial<Props>; // Same shape but all fields are optional.

export type Props = {
  field: Nullable<string>;
  queryType: Nullable<string>;
  query: string;
  sort: Nullable<string>;
};

const defaultProps: Props = {
  field: null,
  queryType: null,
  query: '',
  sort: null,
};

export const SampleQueryRecordFactory = Immutable.Record<Props>(defaultProps);
export type SampleQueryRecord = Immutable.RecordOf<Props>;

export function getSampleQueryFromJS({ query, ...args }: SampleQueryFromJS): SampleQueryRecord {
  return SampleQueryRecordFactory({
    ...args,
    query: query || defaultProps.query,
  });
}
