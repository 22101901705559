/* eslint-disable react/no-multi-comp */
import { MessageRecord } from '@/models/Message';
import { ReactNodeish } from '@/utils/types';
import BrowserUtil from '@/browser';
import MessageStore from '@/stores/MessageStore';

import { List } from 'immutable';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export const MESSAGE_DURATION_MS = 8000; // How long a message is on screen before hiding

export const MessageContext = React.createContext({ hideHandler: () => {} });

type MessageBaseProps = {
  onClose: (message: MessageRecord) => void;
  message: MessageRecord;
};

type MessageBaseState = {
  hide: boolean;
};

type MessagesBaseProps = {
  max: number;
};

type MessagesBaseState = {
  messages: List<MessageRecord>;
};

export class MessageBase extends React.PureComponent<MessageBaseProps, MessageBaseState> {
  timer: NodeJS.Timeout;

  constructor(...args: [any]) {
    super(...args);
    this.state = { hide: false };
  }

  hideHandler = (): void => {
    clearTimeout(this.timer);
    if (this.refs.message) {
      // @ts-expect-error -- TODO(#36196): Solve type mismatch between React.ReactInstance and Element
      BrowserUtil.once(this.refs.message, 'animationend', () => {
        this.props.onClose(this.props.message);
      });
    } else {
      this.props.onClose(this.props.message);
    }
    this.setState({ hide: true });
  };

  startTimer = (): void => {
    this.timer = setTimeout(this.hideHandler, MESSAGE_DURATION_MS);
  };

  cancelTimer = (): void => {
    clearTimeout(this.timer);
  };

  componentDidMount(): void {
    this.startTimer();
  }

  getClassNames(): string {
    return classNames(`msg msg-${this.props.message.type || ''}`, {
      'mod-animate-out': this.state.hide,
      'mod-animate-in': !this.state.hide,
    });
  }
}

/**
 * Base class for Messages implementations. Implementations must provide a
 * renderImpl() method that will be called by the base class' render method.
 */
export class MessagesBase extends React.PureComponent<MessagesBaseProps, MessagesBaseState> {
  static contextTypes = {
    messageStore: PropTypes.instanceOf(MessageStore).isRequired,
  };

  declare context: {
    messageStore: MessageStore;
  };

  constructor(...args: [any]) {
    super(...args);
    this.state = { messages: List<MessageRecord>() };
  }

  onMessageStoreChange = (): void => {
    this.setState({ messages: this.context.messageStore.messages });
  };

  componentDidMount(): void {
    this.context.messageStore.addChangeListener(this.onMessageStoreChange);
    // Handle the case where messages are preloaded in the store before this component mounts
    if (!this.context.messageStore.messages.isEmpty()) {
      this.setState({ messages: this.context.messageStore.messages }); // eslint-disable-line react/no-did-mount-set-state
    }
  }

  componentWillUnmount(): void {
    this.context.messageStore.removeChangeListener(this.onMessageStoreChange);
  }

  closeMessage = (msg: MessageRecord): void => {
    this.context.messageStore.removeMessage(msg);
  };

  getClassNames(): string {
    return 'msg-list';
  }

  /** Override this */
  renderImpl(): ReactNodeish {
    return null;
  }

  /** Do NOT override */
  render(): ReactNodeish {
    if (this.state.messages.isEmpty()) {
      return null;
    } else {
      return this.renderImpl();
    }
  }
}
