import {
  getPaperPdfBoundingBoxFromJS,
  PaperPdfBoundingBoxPropsFromJS,
  PaperPdfBoundingBoxRecord,
} from './PaperPdfBoundingBox';

import { Nullable, ValueOf } from '@/utils/types';

import Immutable from 'immutable';

export const SkimmingLabel = {
  objective: 'objective', // "Objective" highlights will be displayed as "Goal" on UI
  method: 'method',
  result: 'result',
} as const;

export type SkimmingLabelType = ValueOf<typeof SkimmingLabel>;

export const OpacityLevel = {
  dark: 'dark',
  medium: 'medium',
  light: 'light',
  none: 'none',
} as const;

export type OpacityLevelType = ValueOf<typeof OpacityLevel>;

export const NumberOfHighlights = {
  many: 'many',
  few: 'few',
} as const;

export type NumberOfHighlightsType = ValueOf<typeof NumberOfHighlights>;

export type SkimmingSnippetPropsFromJS = {
  s2airsId: string;
  id: string;
  text: string;
  score: number;
  label: string;
  boundingBoxes: PaperPdfBoundingBoxPropsFromJS[];
};

type Props = {
  scimId: string;
  label: SkimmingLabelType;
  score: number;
  text: string;
  boundingBoxes: PaperPdfBoundingBoxRecord[];
};

const defaultProps: Props = {
  scimId: '',
  label: SkimmingLabel.result,
  score: 0,
  text: '',
  boundingBoxes: [],
};

export const PaperPdfSkimmingSnippetFactory = Immutable.Record<Props>(defaultProps);
export type PaperPdfSkimmingSnippetRecord = Immutable.RecordOf<Props>;

export function getPaperPdfSkimmingSnippetFromJS({
  id,
  boundingBoxes,
  label,
  ...props
}: SkimmingSnippetPropsFromJS): Nullable<PaperPdfSkimmingSnippetRecord> {
  if (!(label in SkimmingLabel)) {
    return null;
  }

  return PaperPdfSkimmingSnippetFactory({
    ...props,
    scimId: id,
    boundingBoxes: (boundingBoxes || []).map(getPaperPdfBoundingBoxFromJS),
    label: SkimmingLabel[label],
  });
}
