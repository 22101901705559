import { getString } from '@/content/i18n';
import { ReactNodeish } from '@/utils/types';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

export default class CrawlerRoute extends React.Component {
  static getPageMetaDescription = (): string =>
    getString(_ => _.metaDescription.crawlerPage.content);

  static getPageTitle = (): string =>
    [getString(_ => _.metaDescription.crawlerPage.title), getString(_ => _.general.appName)].join(
      ' | '
    );

  render(): ReactNodeish {
    return (
      <AsyncLoadedPage
        footer={false}
        load={{
          importer: () => import(/* webpackChunkName: "desktop-CrawlerPage" */ './CrawlerPage'),
          chunkName: 'desktop-CrawlerPage',
          moduleId: require.resolveWeak('./CrawlerPage'),
        }}
      />
    );
  }
}
