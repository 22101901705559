import LayoverClient from './LayoverClient';
import LayoverDefaultClient from './LayoverDefaultClient';
import LayoverNoopClient from './LayoverNoopClient';

import { getRouteNameForPath, RouteName } from '@/router/Routes';
import { isBrowser, isTrackingAllowedOnBrowser, isUnitTest } from '@/utils/env';
import { runWithHeap } from '@/analytics/Heap';
import Experiment, { ExperimentNames } from '@/weblab/Experiment';
import Feature, { FeatureNames } from '@/weblab/Feature';
import logger from '@/logger';

import { v4 as uuidV4 } from 'uuid';

import type { Nullable } from '@/utils/types';
import type AppContext from '@/AppContext';

type CounterValue = {
  type: typeof COUNTER;
  by: number;
};

type DistributionValue = {
  type: typeof DISTRIBUTION;
  point: number;
};

type TimingValue = {
  type: typeof TIMER;
  from: number;
  to: number;
};

type MetricValue = CounterValue | DistributionValue | TimingValue;

type TaggedExperimentsState = { [expKey: string]: string };

type TaggedFeaturesState = { [featureKey: string]: boolean };

type MetricEntry = {
  name: string;
  value: MetricValue;
  tags: string[];
  order: number;
  appLoadId: string;
  pageLoadId: string;
  routeName: Nullable<RouteName>;
  experiments: TaggedExperimentsState;
  features: TaggedFeaturesState;
};

export const COUNTER = 'COUNTER';
export const DISTRIBUTION = 'DISTRIBUTION';
export const TIMER = 'TIMER';
const MAX_ENTRY_AGE_MS = 1000;
const MAX_ENTRIES_BEFORE_FLUSH = 50;

/**
 * Layover Metrics
 *
 * This class collects and transports logs from the browser to datadog through the Layover service.
 * See: /online/layover/src/routes/metrics/index.js
 */
export default class LayoverMetrics {
  _appLoadId: string; // UUID that is unique for every load of the app (every new tab, page refresh, etc.)
  _client: LayoverClient;
  _currentRouteName: Nullable<RouteName>;
  _globalTags: string[]; // Tags which should be added to every logged metric
  _hasHeapEventForPageLoad: boolean;
  _metricStartTimes: Map<string, number>;
  _nextMetricNum: number; // Incrementing number that allows events to be sorted on the server side without relying on timestamps
  _pageLoadId: string; // UUID that is unique for every page (different pageLoadId's can have the same appLoadId)
  _appContext: Nullable<AppContext>;

  constructor({ client }: { client: LayoverClient }) {
    this._client = client;
    this._metricStartTimes = new Map();
    this._appContext = null;
    this._nextMetricNum = 1;
    this._hasHeapEventForPageLoad = false;
    this._appLoadId = uuidV4();
    this._pageLoadId = uuidV4();
    this._globalTags = [];

    if (isBrowser() && typeof window !== 'undefined') {
      this._currentRouteName = getRouteNameForPath(window.location.pathname);
      window.addEventListener('popstate', () => this._onUrlChange());
    }
  }

  associateWithAppContext(appContext: AppContext): void {
    this._client.associateWithAppContext(appContext);
    this._appContext = appContext;
  }

  getGlobalTags(): string[] {
    return this._globalTags.slice(); // Slice returns a copy of the array
  }

  hasGlobalTag(tag: string): boolean {
    return this._globalTags.includes(tag);
  }

  addGlobalTag(tag: string): boolean {
    if (!this.hasGlobalTag(tag)) {
      this._globalTags.push(tag);
      return true;
    }
    return false;
  }

  removeGlobalTag(tag: string): boolean {
    const idx = this._globalTags.indexOf(tag);
    if (idx < 0) {
      return false;
    }
    this._globalTags.splice(idx, 1);
    return true;
  }

  markTimestamp(metricName: string, tags: string[] = []): void {
    if (!isBrowser()) {
      return;
    }
    this.sendDistribution(metricName, window.performance.now(), tags);
  }

  startTimer(metricName: string): void {
    if (!isBrowser()) {
      return;
    }
    this._metricStartTimes.set(metricName, Date.now());
  }

  endTimer(metricName: string, tags: string[] = []): void {
    if (!isBrowser()) {
      return;
    }
    const endTimeMs = Date.now();
    const startTimeMs = this._metricStartTimes.get(metricName);
    if (typeof startTimeMs !== 'number') {
      logger.warn(`Timer ended without starting [metricName="${metricName}]`);
      return;
    }
    this.sendTiming(metricName, startTimeMs, endTimeMs, tags);
  }

  sendTiming(
    metricName: string,
    startTimeMs: number,
    endTimeMs: number,
    tags: string[] = []
  ): boolean {
    if (!isBrowser()) {
      return false;
    }
    const entry = this._mkEntry(
      metricName,
      {
        type: TIMER,
        from: startTimeMs,
        to: endTimeMs,
      },
      tags
    );
    this._associateWithHeap();
    return this._client.send(entry);
  }

  // Increment a counter metric
  sendIncrement(metricName: string, by = 1, tags: string[] = []): boolean {
    if (!isBrowser()) {
      return false;
    }
    const entry = this._mkEntry(
      metricName,
      {
        type: COUNTER,
        by,
      },
      tags
    );
    this._associateWithHeap();
    return this._client.send(entry);
  }

  // Syntactic sugar for incrementing by negative number
  sendDecrement(metricName: string, by = 1, tags: string[] = []): boolean {
    this._associateWithHeap();
    return this.sendIncrement(metricName, -by, tags);
  }

  sendDistribution(metricName: string, value: number, tags: string[] = []): boolean {
    if (!isBrowser()) {
      return false;
    }
    const entry = this._mkEntry(
      metricName,
      {
        type: DISTRIBUTION,
        point: value,
      },
      tags
    );
    this._associateWithHeap();
    return this._client.send(entry);
  }

  // Create a metric entry to send to layover
  _mkEntry(metricName: string, value: MetricValue, tags: string[] = []): MetricEntry {
    const entry = {
      name: metricName,
      value,
      tags: [...this._globalTags, ...tags],
      appLoadId: this._appLoadId,
      pageLoadId: this._pageLoadId,
      order: this._nextMetricNum++,
      routeName: this._currentRouteName || null,
      features: this._getTaggedFeatures(),
      experiments: this._getTaggedExperiments(),
    };
    return entry;
  }

  _onUrlChange(): void {
    this._currentRouteName = getRouteNameForPath(window.location.pathname);
    this._pageLoadId = uuidV4();
    this._hasHeapEventForPageLoad = false;
  }

  _associateWithHeap(): void {
    if (this._hasHeapEventForPageLoad) {
      return;
    }
    runWithHeap(heap => {
      heap.track('layover_metrics', {
        appLoadId: this._appLoadId,
        pageLoadId: this._pageLoadId,
      });
      this._hasHeapEventForPageLoad = true;
    });
  }

  // Collect the status of experiments marked for tagging in metrics
  _getTaggedExperiments(): TaggedExperimentsState {
    const experiments = {};
    const experimentManager = this._appContext?.weblabStore?.experimentManager;
    if (!experimentManager) {
      return experiments;
    }
    for (const expName of ExperimentNames) {
      const exp = Experiment[expName];
      if (!exp.includeInPerfMetrics) {
        continue;
      }
      // Pull from experiment manager, because we need to know about exposures
      experiments[exp.KEY] = experimentManager.getExposedVariation(exp.KEY);
    }
    return experiments;
  }

  // Collect the status of features marked for tagging in metrics
  _getTaggedFeatures(): TaggedFeaturesState {
    const features = {};
    const weblabStore = this._appContext?.weblabStore;
    if (!weblabStore) {
      return features;
    }
    for (const featureName of FeatureNames) {
      const feature = Feature[featureName];
      if (!feature.includeInPerfMetrics) {
        continue;
      }
      features[feature.KEY] = weblabStore.isFeatureEnabled(feature);
    }
    return features;
  }
}

let inst: Nullable<LayoverMetrics> = null;
export function getLayoverMetrics(): LayoverMetrics {
  if (!inst) {
    const args = {
      destinationUrl: '/beacon/metrics',
      maxEntryAgeMs: MAX_ENTRY_AGE_MS,
      maxEntriesBeforeFlush: MAX_ENTRIES_BEFORE_FLUSH,
    };
    const shouldSendLogs = isBrowser() && !isUnitTest() && isTrackingAllowedOnBrowser();
    const client = shouldSendLogs ? new LayoverDefaultClient(args) : new LayoverNoopClient();
    inst = new LayoverMetrics({ client });
  }
  return inst;
}

export function measurePerfSync<TResult, TArgs>(
  metricName: string,
  cb: (...args: TArgs[]) => TResult,
  ...args: TArgs[]
): TResult {
  const metrics = getLayoverMetrics();
  metrics.markTimestamp(`timeTo${metricName}Start`);
  let result: TResult, durationMs: number;
  try {
    const startTimeMs = window.performance.now();
    result = cb(...args);
    const endTimeMs = window.performance.now();
    durationMs = endTimeMs - startTimeMs;
  } catch (error) {
    metrics.markTimestamp(`timeTo${metricName}Error`);
    throw error;
  }
  metrics.markTimestamp(`timeTo${metricName}End`);
  metrics.sendDistribution(`timeOf${metricName}`, durationMs);
  return result;
}

export async function measurePerfAsync<TResult, TArgs>(
  metricName: string,
  cb: (...args: TArgs[]) => Promise<TResult>,
  ...args: TArgs[]
): Promise<TResult> {
  const metrics = getLayoverMetrics();
  metrics.markTimestamp(`timeTo${metricName}Start`);
  let result: TResult, durationMs: number;
  try {
    const startTimeMs = window.performance.now();
    result = await cb(...args);
    const endTimeMs = window.performance.now();
    durationMs = endTimeMs - startTimeMs;
  } catch (error) {
    metrics.markTimestamp(`timeTo${metricName}Error`);
    throw error;
  }
  metrics.markTimestamp(`timeTo${metricName}End`);
  metrics.sendDistribution(`timeOf${metricName}`, durationMs);
  return result;
}
