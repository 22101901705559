import FigureWithStickyFooter from './FigureWithStickyFooter';

import { getBody } from '@/browser';
import { getString } from '@/content/i18n';
import { hideModal } from '@/actions/ModalActionCreators';
import { KEY_CODE_LEFT, KEY_CODE_RIGHT } from '@/constants/KeyCode';
import { makePath } from '@/router/Routes';
import { ModalId } from '@/constants/Modal';
import CLModal from '@/components/library/modal/CLModal';
import EnvInfo from '@/env/EnvInfo';
import Icon from '@/components/shared/icon/Icon';
import Link from '@/router/Link';
import S2Dispatcher from '@/utils/S2Dispatcher';
import S2History from '@/utils/S2History';
import ScreenReaderAnnouncement from '@/components/util/a11y/ScreenReaderAnnouncement';
import TextTruncator from '@/components/shared/common/TextTruncator';

import PropTypes from 'prop-types';
import React from 'react';

// Prevent clicking the next / previous arrows from hiding the modal
const stopPropagation = e => e.stopPropagation();

const FIGURE_CAPTION_WORD_LIMIT = 100;

export default class PaperFigureModal extends React.PureComponent {
  static contextTypes = {
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    history: PropTypes.instanceOf(S2History).isRequired,
  };

  changeFigure(idx) {
    const { paper, figureCount } = this.props;
    if (figureCount > 1 && this.isNotNullOrUndefined(idx)) {
      this.context.history.push(
        // $FlowFixMe complains idx could be null/undefined but we already check for it
        makePath({
          routeName: 'PAPER_DETAIL_FIGURE',
          params: { paperId: paper.id, slug: paper.slug, figureIndex: idx },
        })
      );
    }
  }

  getFigureAnnouncementText = () => {
    const { next, figureCount } = this.props;

    // next is the index of the next figure than the one we are on, but because there is a 0 index
    // we want to announce the text to be out of the total figure count
    // i.e. we are on the 0th indexed figure currently, therefore "1" is the `next` index, we announce we are on
    // "Figure 1 of 5" to reduce the 0 index in the span of the total figure count. If the next index is 0 we adjust it so its the end of the figures
    let figureIndexToAnnounce = next;
    if (next == 0) {
      figureIndexToAnnounce = figureCount;
    }

    return 'Figure ' + figureIndexToAnnounce + ' of ' + figureCount;
  };

  onKeyDown = event => {
    const { prev, next, showArrows } = this.props;

    switch (event.keyCode) {
      case KEY_CODE_LEFT: {
        event.preventDefault();
        showArrows && this.changeFigure(prev);
        break;
      }
      case KEY_CODE_RIGHT: {
        event.preventDefault();
        showArrows && this.changeFigure(next);
        break;
      }
    }
  };

  componentDidMount() {
    // Adding a onKeyDown listener on the parent div wouldn't work *until* the user clicked
    // something.  I also tried explicitly focusing the figure after render -- anyway, to fix
    // this we use a good ole' native listener
    document.addEventListener('keydown', this.onKeyDown);

    // disable scrolling when modal open
    getBody(document).style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
    getBody(document).style.removeProperty('overflow');
  }

  // This is used to make sure we can link to other figures correctly based on the passed in index
  // We need a separate method as 0 for an index can be falsy
  isNotNullOrUndefined = value => {
    return !!(value || value === 0);
  };

  defaultHideModal = () => {
    const { dispatcher } = this.context;
    dispatcher.dispatchEventually(hideModal());
  };

  renderControl() {
    const { figure } = this.props;

    return (
      <div className="flex-column flex-row-vcenter">
        <img src={figure.cdnUri} alt={figure.caption} data-test-id="figure-image" />
        <TextTruncator
          text={figure.caption}
          limit={FIGURE_CAPTION_WORD_LIMIT}
          truncateByDefault={true}
          truncateAriaLabel={getString(_ => _.figureDetail.truncateAriaLabel)}
          extendAriaLabel={getString(_ => _.figureDetail.expandAriaLabel)}
          extendActionText={getString(_ => _.figureDetail.continueReadingLabel)}
          className="figure-caption figure-modal__figure-caption-pdp"
        />
      </div>
    );
  }

  renderWithStickyFooter() {
    const { figure, paper } = this.props;

    return <FigureWithStickyFooter figure={figure} paper={paper} />;
  }

  renderArrow = (index, className, ariaLabel) => {
    const { paper, showArrows } = this.props;

    if (showArrows && this.isNotNullOrUndefined(index)) {
      return (
        <Link
          to="PAPER_DETAIL_FIGURE"
          onClick={stopPropagation}
          aria-label={ariaLabel}
          params={{ paperId: paper.id, slug: paper.slug, figureIndex: index }}
          className={className}>
          <Icon className="figure-modal__arrow" icon="disclosure" width="40" height="40" />
        </Link>
      );
    }
  };

  render() {
    const { isMobile } = this.context.envInfo;
    const { prev, next, onHideModal } = this.props;
    const onClickCloseModal = onHideModal ? () => onHideModal() : this.defaultHideModal;
    const figureAnnouncement = this.getFigureAnnouncementText();

    const ariaProps = {
      'aria-label': getString(_ => _.figureDetail.title),
      'aria-modal': true,
    };
    return (
      <CLModal
        ariaProps={ariaProps}
        data-modal-id={ModalId.ENTITY_FIGURE}
        onHideClick={onClickCloseModal}
        className="figure-modal">
        {this.renderArrow(
          prev,
          'prev-figure',
          getString(_ => _.figureDetail.prevAriaLabel)
        )}
        {isMobile ? this.renderControl() : this.renderWithStickyFooter()}
        {this.renderArrow(
          next,
          'next-figure',
          getString(_ => _.figureDetail.nextAriaLabel)
        )}
        <ScreenReaderAnnouncement message={figureAnnouncement} />
      </CLModal>
    );
  }
}
