const SearchActionName = {
  ResultList: {
    Impression: 'search.results.show',
  },
  Result: {
    Author: {
      CLICK: 'search.result.author.click',
    },
    Cite: {
      CLICK: 'search.result.cite.click',
    },
    Library: {
      SAVE: 'search.result.library.save',
    },
    Paper: {
      CLICK: 'search.result.paper.click',
    },
    Title: {
      CLICK: 'search.result.title.click',
    },
  },
} as const;

export default SearchActionName;
