import { Nullable, ValueOf } from '@/utils/types';

const byKey = {
  GETFTR: 'getftr',
  LIBKEY: 'libkey',
  UNSUPPORTED: 'unsupported',
} as const;

export type EntitlementSourceKey = keyof typeof byKey;

export type EntitlementSourceValue = ValueOf<typeof byKey>;

const byValue = Object.keys(byKey).reduce((memo, key) => {
  const value = byKey[key];
  memo[value] = key;
  return memo;
}, {});

export function getEntitlementSourceValue(value: string): EntitlementSourceValue {
  if (value in byValue) {
    return value as EntitlementSourceValue;
  }
  return byKey.UNSUPPORTED;
}

export default Object.freeze(byKey);

export const EntitlementSourceByValue = Object.freeze(byValue);

export function optEntitlementSourceValue(
  value: Nullable<string>
): Nullable<EntitlementSourceValue> {
  if (!value) {
    return null;
  }
  return getEntitlementSourceValue(value);
}
