import { getString } from '@/content/i18n';

import classnames from 'classnames';
import React from 'react';

export default class NetworkStatus extends React.PureComponent {
  state = {
    isOnline: true,
  };

  setOnline = () => {
    if (navigator.onLine === undefined || this.state.isOnline !== navigator.onLine) {
      this.setState({ isOnline: navigator.onLine });
    }
  };

  componentDidMount() {
    this.setOnline();
    window.addEventListener('online', this.setOnline);
    window.addEventListener('offline', this.setOnline);
  }

  componentWillUnmount() {
    window.addEventListener('online', this.setOnline);
    window.addEventListener('offline', this.setOnline);
  }

  render() {
    const { isOnline } = this.state;

    return (
      <div
        className={classnames({
          'network-status': true,
          'network-status--is-online': isOnline,
          'network-status--not-online': !isOnline,
        })}>
        {/* data-nosnippet attribute so Google doesn't display the offline text in search results snippet */}
        {!isOnline && (
          <div className="network-status__label" data-nosnippet>
            {getString(_ => _.appHeader.offlineLabel)}
          </div>
        )}
      </div>
    );
  }
}
