import React from 'react';

export default function IconPaper(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 32 32">
      <path
        d="M25.5,29.4h-19c-0.4,0-0.7-0.3-0.7-0.7V3.3c0-0.4,0.3-0.7,0.7-0.7h11.1c0.2,0,0.4,0.1,0.5,0.2l7.9,7.9
        c0.1,0.1,0.2,0.3,0.2,0.5v17.5C26.2,29.1,25.9,29.4,25.5,29.4z M7.2,28h17.5V11.5L17.3,4H7.2V28z"
      />
    </symbol>
  );
}
