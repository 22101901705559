import RecommendationsFTUEPage from './RecommendationsFTUEPage';
import RecommendationsPage from './RecommendationsPage';

import { getString } from '@/content/i18n';
import { isAllPapersFolder } from '@/models/library/LibraryFolderSourceType';
import { NotRelevant } from '@/models/library/LibraryEntryAnnotationState';
import { On } from '@/models/library/RecommendationStatus';
import LibraryFolderStore from '@/stores/LibraryFolderStore';
import ResearchHomepageStore from '@/stores/ResearchHomepageStore';
import S2Redirect from '@/models/redirects/S2Redirect';

import idx from 'idx';
import Immutable from 'immutable';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const MAX_VISIBLE_FOLDERS = 10;

export default class RecommendationsRoute extends React.Component {
  static requiresAuthentication = () => true;

  static getPageTitle = () => getString(_ => _.recommendations.pageTitle);

  static contextTypes = {
    libraryFolderStore: PropTypes.instanceOf(LibraryFolderStore).isRequired,
    researchHomepageStore: PropTypes.instanceOf(ResearchHomepageStore).isRequired,
  };

  static async willRouteTo({ api, libraryFolderStore }, routerState) {
    const libraryFolderIds = parseFoldersQuery(idx(routerState, _ => _.query.folderIds));
    const noFolders = idx(routerState, _ => _.query.noFolders);
    let foldersPayload = null;
    // Handle case where there's no folderIds attribute and we may need to build
    // a default set of visible folders and redirect
    if (libraryFolderIds.length === 0 && !noFolders) {
      if (libraryFolderStore.isUninitialized()) {
        foldersPayload = await api.getLibraryFolders();
      }
      await libraryFolderStore.ready();
      const recsFolders = libraryFolderStore.getFoldersWithRecommendationStatus(On);

      if (recsFolders.size > 0) {
        // Only redirect if we have folder ids to work with
        const visibleFolders = recsFolders.slice(0, MAX_VISIBLE_FOLDERS);
        const query = routerState.query || {};
        query.folderIds = visibleFolders.map(_ => _.id).join(',');
        const redirect = new S2Redirect({
          routeName: 'RECOMMENDATIONS',
          query: query,
          replace: true,
        });
        return redirect;
      }
    }

    // Whenever there is a change in route, refetch annotations
    let annotationPayload = null;
    annotationPayload = api.getLibraryAnnotationEntries(NotRelevant);

    const folderIds = Immutable.Set(libraryFolderIds);
    const windowUTC = Math.floor(Date.now() / 1000);
    const recsPayload = await api.getLibraryFoldersRecommendations(folderIds, windowUTC);

    return [foldersPayload, annotationPayload, recsPayload];
  }

  render() {
    const { libraryFolderStore, researchHomepageStore } = this.context;
    // Ignore the "All Papers" folder that exists for new users
    const foldersWithoutAllPapers = libraryFolderStore
      .getFolders()
      .filterNot(folder => isAllPapersFolder(folder));
    return !researchHomepageStore.hasRecommendationsHistory() &&
      foldersWithoutAllPapers.isEmpty() ? (
      <RecommendationsFTUEPage />
    ) : (
      <RecommendationsPage />
    );
  }
}

export function parseDayQuery(query) {
  if (!query) {
    return null;
  }
  if (!/^\d{4}-\d{2}-\d{2}$/.test(query)) {
    // Invalid format (####-##-##)
    return null;
  }
  const date = moment(query, 'YYYY-MM-DD');
  if (!date.isValid()) {
    return null;
  }
  return query;
}

export function parsePageQuery(query) {
  if (!query) {
    return null;
  }
  const page = parseInt(query, 10);
  if (Number.isNaN(page)) {
    return null;
  }
  if (page <= 0) {
    return null;
  }
  return page;
}

export function parseFoldersQuery(query) {
  if (!query) {
    return [];
  }
  if (!/^(\d+,?)+$/.test(query)) {
    // Invalid format (###,####)
    return [];
  }
  const parts = query.split(',');
  const idList = parts.map(id => parseInt(id, 10)).filter(id => !Number.isNaN(id) && 0 <= id);
  if (idList.length < parts.length) {
    // One or more IDs was dropped from being invalid
    return [];
  }
  return idList;
}
