import {
  getLibraryEntriesQueryFromPath,
  getLibraryEntriesQueryFromQueryStringParams,
} from '@/models/library/LibraryEntriesQuery';
import { getString } from '@/content/i18n';
import { LIBRARY_DEFAULT_SORT } from '@/models/library/LibrarySort';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import Constants from '@/constants';
import S2History from '@/utils/S2History';

import idx from 'idx';
import PropTypes from 'prop-types';
import React from 'react';

export const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE = 1;

export default class LibraryUnsortedEntriesRoute extends React.PureComponent {
  static getPageTitle = () => getString(_ => _.library.unsortedEntries.pageTitle);

  static requiresAuthentication = () => true;

  static async willRouteTo({ api, libraryPageStore }, routerState) {
    const query = idx(routerState, _ => _.query.q) || '';
    const sort = idx(routerState, _ => _.query.sort) || LIBRARY_DEFAULT_SORT;
    const page = idx(routerState, _ => _.query.page) || 1;
    const isNewRequestType =
      libraryPageStore.getCurrentLoadRequest() !==
      Constants.requestTypes.GET_UNSORTED_LIBRARY_ENTRIES;

    const currentQuery = libraryPageStore.getCurrentQuery();
    const incomingQuery = getLibraryEntriesQueryFromQueryStringParams({
      pageSize: DEFAULT_PAGE_SIZE,
      page,
      q: query,
      sort,
    });

    if (!incomingQuery.equals(currentQuery) || isNewRequestType) {
      return api.getUnsortedEntries(incomingQuery);
    }
  }

  static contextTypes = {
    history: PropTypes.instanceOf(S2History).isRequired,
  };

  render() {
    const { history } = this.context;
    const queryParams = getLibraryEntriesQueryFromPath(history.location.search);

    return (
      <AsyncLoadedPage
        showLoadingPage={false}
        compProps={{ currentPageNumber: queryParams.page || DEFAULT_PAGE }}
        load={{
          importer: () =>
            import(
              /* webpackChunkName: "shared-LibraryUnsortedEntriesPage" */ './LibraryUnsortedEntriesPage'
            ),
          chunkName: 'shared-LibraryUnsortedEntriesPage',
          moduleId: require.resolveWeak('./LibraryUnsortedEntriesPage'),
        }}
      />
    );
  }
}
