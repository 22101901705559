import { HeapProps } from '@/analytics/heapUtils';
import { Nullable, ReactNodeish } from '@/utils/types';
import CLIconButton, { TYPE } from '@/components/library/button/CLIconButton';
import Icon from '@/components/shared/icon/Icon';

import classnames from 'classnames';
import React from 'react';

type Props = {
  label?: Nullable<ReactNodeish | string>;
  onClick?: Nullable<() => void>;
  className?: Nullable<string>;
  heapProps?: Nullable<HeapProps>;
  ariaProps?: Record<string, unknown>;
};

export default function CLPaperExcerptAndIntentsFlag({
  label,
  onClick,
  className,
  heapProps,
  ariaProps,
}: Props): ReactNodeish {
  return (
    <CLIconButton
      ariaProps={ariaProps}
      type={TYPE.TERTIARY}
      className={classnames('cl-paper-flag__flag-button', className)}
      icon={<Icon icon="excerpt" height="11" width="11" />}
      label={label}
      onClick={onClick}
      heapProps={heapProps}
      testId="excerpt"
    />
  );
}
