import BaseStore from './BaseStore';

import { ApiResponse } from '@/api/ApiResponse';
import {
  getSampleQueryFromJS,
  SampleQueryFromJS,
  Props as SampleQueryType,
} from '@/models/SampleQuery';
import { Nullable } from '@/utils/types';
import Constants from '@/constants';
import S2Dispatcher from '@/utils/S2Dispatcher';

import Immutable from 'immutable';

/**
 * A store for sample queries, which are queries displayed on the home page that user's can
 * click (if they don't know what to search for).
 */
export default class SampleQueriesStore extends BaseStore {
  dispatchToken: string;
  #sampleQueries: Nullable<Immutable.List<SampleQueryType>>;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#sampleQueries = Immutable.List();

    this.dispatchToken = dispatcher.register(payload => {
      switch (payload.actionType) {
        // Parse them sample queries when the associated API request is completed
        case Constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse<SampleQueryFromJS[]>;
          if (apiResponse.requestType === Constants.requestTypes.SAMPLE_QUERIES) {
            this.#sampleQueries = Immutable.List(apiResponse.resultData.map(getSampleQueryFromJS));
            this.emitChange();
          }
          break;
        }

        // Clear out the list of sample queries whenever the user routes to another page.
        // This way if they load the home page, then return, we'll show them a new set
        // of queries
        case Constants.actions.ROUTING: {
          this.#sampleQueries = Immutable.List();
          this.emitChange();
          break;
        }
      }
    });
  }

  getSampleQueries(): Immutable.List<SampleQueryType> {
    return Immutable.List(this.#sampleQueries || undefined);
  }
}
