import OverridePane from './OverridePane';

import { KEY_CODE_O } from '@/constants/KeyCode';
import Constants from '@/constants';
import S2Dispatcher from '@/utils/S2Dispatcher';

import PropTypes from 'prop-types';
import React from 'react';

/**
 * OverrideManager, controls whether and when to show the OverridePane
 */
export default class OverrideManager extends React.PureComponent {
  static contextTypes = {
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      isVisible: false,
    };

    const { dispatcher } = this.context;
    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.SHOW_OVERRIDE_PANE: {
          this.setState({ isVisible: true });
          break;
        }
      }
    });
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = event => {
    if (event.altKey && event.ctrlKey && event.shiftKey && event.keyCode === KEY_CODE_O) {
      this.setState({ isVisible: !this.state.isVisible });
    }
  };

  onClose = () => {
    this.setState({
      isVisible: false,
    });
  };

  render() {
    if (!this.state.isVisible) {
      return null;
    }
    return <OverridePane onClose={this.onClose} />;
  }
}
