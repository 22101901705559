import {
  PersistentUserSettingKey,
  UserSettingKey,
  UserSettingValue,
} from '@/models/user/UserSetting';
import { useAppContext } from '@/AppContext';
import constants from '@/constants';

import invariant from 'invariant';

type UserSettingUtils = {
  setLocalUserSetting: (key: UserSettingKey, value: any) => void;
  setPersistentUserSetting: (key: UserSettingKey, value: UserSettingValue) => void;
  setUserSettingForLoggedInAndOutUser: (key: UserSettingKey, value: UserSettingValue) => void;
};

export function encodeSettingValue(settingValue): string {
  return JSON.stringify(settingValue);
}

export function useUserSettingUtils(): UserSettingUtils {
  const { api, authStore, dispatcher, userSettingStore } = useAppContext();

  /**
   * Saves a user setting to localStorage only, not persistend to user_settings table
   * @param key Key of the user setting
   * @param value Value of the user setting
   */
  function setLocalUserSetting(key: UserSettingKey, value: any) {
    dispatcher.dispatchEventually({
      actionType: constants.actions.SET_USER_SETTING,
      key,
      value: encodeSettingValue(value),
    });
  }

  /**
   * Saves a user setting to user_settings table, it will also sync to localStorage
   * @param key Key of the user setting
   * @param value Value of the user setting
   */
  function setPersistentUserSetting(key: UserSettingKey, value: UserSettingValue) {
    if (!PersistentUserSettingKey[key]) {
      invariant(
        key,
        'key must be a persistent key. Ensure it is part of the PersistentUserSettingKey enums.'
      );
      return;
    }
    const existingSetting = userSettingStore.getUserSetting(key);
    existingSetting ? api.updateUserSetting(key, value) : api.createUserSetting(key, value);
  }

  /**
   * Saves a user setting to localStorage if the user is unauthenticated or
   * saves the user settings to the user_settings table if the user is logged in
   * @param key Key of the user setting
   * @param value Value of the user setting
   */
  function setUserSettingForLoggedInAndOutUser(key: UserSettingKey, value: UserSettingValue) {
    // if the setting key is meant to be persisted in the db, wrap the value as a raw UserSettingFromJS so
    // the value saved in local storage will be the same shape as the record that comes back from the db
    const localSettingValue = PersistentUserSettingKey[key]
      ? {
          settingsKey: { id: key },
          settingsValue: value,
          updatedAtUtc: +new Date(),
        }
      : value;
    authStore.hasAuthenticatedUser()
      ? setPersistentUserSetting(key, value)
      : setLocalUserSetting(key, localSettingValue);
  }

  return { setUserSettingForLoggedInAndOutUser, setLocalUserSetting, setPersistentUserSetting };
}
