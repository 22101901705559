import { HEAP_ATTRIBUTE_PREFIX } from '@/constants/HeapAttribute';
import logger from '@/logger';

import invariant from 'invariant';
import PropTypes from 'prop-types';
import React from 'react';

let hasWarned = false;

export default class HeapTracking extends React.PureComponent {
  static childContextTypes = {
    heapProps: PropTypes.object.isRequired,
  };

  static contextTypes = {
    heapProps: PropTypes.object, // Used for checking for nested <HeapTracking/> components, do not use (see: <HeapContext/>)
    heapPropsChain: PropTypes.object,
  };

  getChildContext() {
    const heapProps =
      this.props.heapProps ||
      combineHeapProps(
        this.props.combiner,
        this.props.partialHeapProps,
        this.context.heapPropsChain
      );
    return {
      heapProps: addHeapProps(heapProps), // Converted into DOM attributes, with heap prefixes
    };
  }

  render() {
    if (!hasWarned && this.context.heapProps) {
      logger.warn(`<HeapTracking/> component is not allowed to be nested`);
      hasWarned = true;
    }
    return this.props.children || null;
  }
}

let hasLoggedHeapError = false;

/**
 * Add attributes to element for Heap tracking
 *
 * Example: <div {...addHeapProps({id: HEAP_PDP_FEATURED_CONTENT_ITEM, type: 'video'})} />
 * Output: <div data-heap-id="pdp_featured_content_item" data-heap-attr-type="video" />
 */
export const addHeapProps = props => {
  if (!props) {
    return;
  }

  // Need to double check that IE can get keys from object
  let keys = null;
  try {
    keys = Object.keys(props);
  } catch (error) {
    if (!hasLoggedHeapError) {
      hasLoggedHeapError = true;
      logger.error(error);
    }
  }

  const attrs = {};
  if (keys) {
    for (const key of keys) {
      if (ATTRIBUTE_FORMAT.test(key)) {
        const attrKey =
          key.indexOf(HEAP_ATTRIBUTE_PREFIX) === 0 ? key : HEAP_ATTRIBUTE_PREFIX + key;
        // $FlowFixMe: Flow thinks keys from an object don't exist...wrong!
        attrs[attrKey] = formatValue(props[key]);
      } else {
        logger.warn(`"${key}" is an invalid heap key`);
      }
    }
  }
  return attrs;
};

export const ATTRIBUTE_FORMAT = /^[a-z0-9-]{1,40}$/;

// Convert a JS value into a dom property value
export function formatValue(value) {
  switch (typeof value) {
    default:
      return JSON.stringify(value);
    case 'string':
      return value;
    case 'undefined':
      return '';
  }
}

// Run the combiner with props in the context to create heapProps
export function combineHeapProps(combiner, fromComp, fromContext) {
  try {
    invariant(
      typeof combiner === 'function',
      'combiner must be a function if heapProps is not provided'
    );
    invariant(fromContext, 'cannot use a combiner without a <HeapContext/> as a parent');
    return combiner(fromComp || {}, fromContext);
  } catch (ex) {
    logger.warn(ex);
    throw ex;
  }
}
