import React from 'react';

export default function IconModerationFlag(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 36 36">
      <path
        d="M32.52,4.71c1.49-0.69,3.2,0.39,3.2,2.04v17.09c0,0.74-0.36,1.44-0.97,1.85c-2.52,1.73-5.23,2.87-8.61,2.87
        c-4.74,0-7.85-2.45-11.62-2.45c-3.58,0-6.06,0.71-8.05,1.56v6.64c0,0.93-0.76,1.69-1.69,1.69H3.66c-0.93,0-1.69-0.76-1.69-1.69
        V7.17C0.95,6.46,0.28,5.28,0.28,3.94c0-2.23,1.85-4.03,4.1-3.93c2,0.08,3.64,1.69,3.77,3.69c0,0.07,0.01,0.18,0.01,0.25
        c0,0.4-0.12,1.03-0.26,1.41c1.46-0.54,3.03-0.85,4.78-0.85c4.74,0,7.85,2.45,11.62,2.45C27.15,6.95,30.11,5.83,32.52,4.71z"
      />
    </symbol>
  );
}
