import BaseStore from './BaseStore';

import { ApiRequestStartingPayload } from '@/api/BaseApi';
import {
  ApiResponse,
  CreateLibraryEntryBulkResponseBody,
  DeleteLibraryEntryBulkResponseBody,
  GetLibraryFolderByIdResponseBody,
  RemoveEntriesFromFoldersResponseBody,
} from '@/api/ApiResponse';
import {
  getLibraryEntriesQueryFromPath,
  LibraryEntriesQueryRecord,
} from '@/models/library/LibraryEntriesQuery';
import { getLibraryEntryFromJS, LibraryEntryRecord } from '@/models/library/LibraryEntry';
import { getLibraryFolderFromJS, LibraryFolderRecord } from '@/models/library/LibraryFolder';
import {
  getLibraryFolderOwnerFromJS,
  LibraryFolderOwnerRecord,
} from '@/models/library/LibraryFolderOwner';
import { hasValue, Nullable } from '@/utils/types';
import { PublicPromise } from '@/utils/promise-utils';
import Constants from '@/constants';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import Immutable from 'immutable';

export default class SharedLibraryFolderStore extends BaseStore {
  state: StoreStateValue;
  folder: Nullable<LibraryFolderRecord>;
  owner: Nullable<LibraryFolderOwnerRecord>;
  entries: Immutable.Set<LibraryEntryRecord>;
  #currentQuery: Nullable<LibraryEntriesQueryRecord>;
  #currentPath: Nullable<string>;
  #currentPageNumber: number;
  #totalHits: Nullable<number>;
  #totalPages: Nullable<number>;

  _ready: PublicPromise<void>;

  constructor(dispatcher) {
    super();

    this.state = StoreState.UNINITIALIZED;
    this._reset();

    this._ready = new PublicPromise();

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          switch (payload.requestType) {
            case Constants.requestTypes.GET_FOLDER_BY_ID: {
              this._reset();
              return this.#handleGetFolderByIdStart(payload);
            }
          }
          break;
        }

        case Constants.actions.API_REQUEST_COMPLETE: {
          const completePayload = payload as ApiResponse;
          switch (completePayload.requestType) {
            case Constants.requestTypes.GET_FOLDER_BY_ID: {
              return this.#handleGetFolderByIdComplete(payload);
            }
            case Constants.requestTypes.CREATE_LIBRARY_ENTRY_BULK:
              return this.#handleCreateLibraryEntriesBulkComplete(completePayload);

            case Constants.requestTypes.REMOVE_ENTRIES_FROM_FOLDERS:
              return this.#handleRemoveEntriesFromFoldersComplete(completePayload);

            case Constants.requestTypes.DELETE_LIBRARY_ENTRIES_BULK:
              return this.#handleDeleteLibraryEntriesBulkComplete(completePayload);
          }
          break;
        }
      }
    });
  }

  #handleGetFolderByIdStart(payload: ApiRequestStartingPayload): void {
    this.#currentQuery = getLibraryEntriesQueryFromPath(payload.path);
    this.#currentPath = payload.path;
    this.state = StoreState.LOADING;
    this.emitChange();
  }

  #handleGetFolderByIdComplete(typedPayload: ApiResponse<GetLibraryFolderByIdResponseBody>): void {
    // ensure payload is from the current query in case user switched to another folder page before the last request completed
    if (typedPayload.path != this.#currentPath) {
      return;
    }

    const { folder, entries, owner, pageNumber, totalHits, totalPages } = typedPayload.resultData;

    this.folder = getLibraryFolderFromJS(folder);
    this.owner = getLibraryFolderOwnerFromJS(owner);
    this.entries = Immutable.OrderedSet(entries).map(entry => getLibraryEntryFromJS(entry));

    this.#currentPageNumber = pageNumber;
    this.#totalHits = totalHits;
    this.#totalPages = totalPages;
    this.state = StoreState.LOADED;
    this.emitChange();
  }

  #handleCreateLibraryEntriesBulkComplete(
    payload: ApiResponse<CreateLibraryEntryBulkResponseBody>
  ): void {
    // the bulk create endpoint will return a list of entries created
    // ex. adding a paper to 2 folders will create 2 separate entries with that paper id
    // save just the first entry instance in the store otherwise we end up rendering duplicates
    const firstEntry = payload.resultData.entries[0];
    const paperId = firstEntry.paper?.id || firstEntry.paperId;
    const knownPaperEntry = this.getEntryByPaperId(paperId);

    if (!knownPaperEntry) {
      this.entries = this.entries.add(getLibraryEntryFromJS(firstEntry));
      this.#adjustTotalHitsFromEntryAddition();
    }

    this.emitChange();
  }

  #handleRemoveEntriesFromFoldersComplete(
    payload: ApiResponse<RemoveEntriesFromFoldersResponseBody>
  ): void {
    const currentFolderId = this.folder?.id;
    const forCurrentFolder =
      hasValue(currentFolderId) && payload.resultData.folderIds.includes(currentFolderId);
    if (forCurrentFolder) {
      // SORTED view, entry newly removed from folder: REMOVE the entry from the displayed list, adjust to total hits
      const deletedEntryIds = new Set(payload.resultData.entryIds);
      this.entries = this.entries.filter(entry => !deletedEntryIds.has(entry.id));
      this.#adjustTotalHitsFromEntryRemoval();
      this.emitChange();
    }
  }

  #handleDeleteLibraryEntriesBulkComplete(
    payload: ApiResponse<DeleteLibraryEntryBulkResponseBody>
  ): void {
    const deletedEntryIds = new Set(payload.resultData.deletedEntryIds);
    this.entries = this.entries.filter(entry => !deletedEntryIds.has(entry.id));
    this.#adjustTotalHitsFromEntryRemoval();
    this.emitChange();
  }

  #adjustTotalHitsFromEntryAddition(): void {
    if (this.#totalHits) {
      this.#totalHits = this.#totalHits + 1;
    } else {
      // treat falsey totalHits as 0 and set to 1 in case it's ever undefined rather than null or 0
      this.#totalHits = 1;
    }
  }

  #adjustTotalHitsFromEntryRemoval(): void {
    if (this.#totalHits) {
      this.#totalHits = this.#totalHits - 1;
    }
  }

  _reset(): void {
    this.folder = null;
    this.owner = null;
    this.entries = Immutable.Set();
    this.#currentQuery = null;
    this.#currentPath = null;
    this.#currentPageNumber = 1;
    this.#totalHits = null;
    this.#totalPages = null;
  }

  getFolder(): Nullable<LibraryFolderRecord> {
    return this.folder;
  }

  getEntries(): Immutable.Set<LibraryEntryRecord> {
    return this.entries;
  }

  getEntryByPaperId(paperId?: Nullable<string>): Nullable<LibraryEntryRecord> {
    if (!hasValue(paperId)) {
      return null;
    }

    // find paper by paper.id and fallback to the paper id on the entry itself if paper doesn't exist
    return (
      this.entries.filter(entry => (entry.paper?.id || entry.paperId) === paperId).first() || null
    );
  }

  getOwner(): Nullable<LibraryFolderOwnerRecord> {
    return this.owner;
  }

  getPaperCount(): number {
    return this.entries.size;
  }

  getCurrentPageNumber(): number {
    return this.#currentPageNumber;
  }

  getTotalHits(): Nullable<number> {
    return this.#totalHits;
  }

  getTotalPages(): Nullable<number> {
    return this.#totalPages;
  }

  getCurrentQuery(): Nullable<LibraryEntriesQueryRecord> {
    return this.#currentQuery;
  }

  isLoading(): boolean {
    return this.isUninitialized() || this.state === StoreState.LOADING;
  }

  isUninitialized(): boolean {
    return this.state === StoreState.UNINITIALIZED;
  }

  async ready(): Promise<void> {
    await this._ready;
  }
}
