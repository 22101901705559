import React from 'react';

export default function IconPencil(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 32 32">
      <path
        d="M17.76,6.25C17.93,6.08,18.11,6,18.32,6c0.21,0,0.37,0.08,0.5,0.25l6.93,6.93c0.17,0.12,0.25,0.29,0.25,0.5
        s-0.08,0.4-0.25,0.56L9.39,30.6l-7.62,1.37c-0.5,0.08-0.94-0.06-1.31-0.44c-0.37-0.37-0.52-0.81-0.44-1.31l1.37-7.62L17.76,6.25z
        M5.52,26.48v-3H3.27l-0.75,4l2,2l4-0.75v-2.25H5.52z M7.77,21.24c0.17,0.17,0.37,0.25,0.62,0.25c0.25,0,0.46-0.08,0.62-0.25
        l9.62-9.62c0.17-0.17,0.25-0.37,0.25-0.62c0-0.25-0.08-0.46-0.25-0.62s-0.37-0.25-0.62-0.25s-0.46,0.08-0.62,0.25l-9.62,9.62
        c-0.17,0.17-0.25,0.37-0.25,0.62C7.52,20.86,7.6,21.07,7.77,21.24z M31.13,8.87C31.71,8.29,32,7.58,32,6.74s-0.29-1.54-0.87-2.12
        l-3.75-3.75C26.8,0.29,26.09,0,25.25,0s-1.54,0.29-2.12,0.87l-2.87,2.87c-0.17,0.17-0.25,0.35-0.25,0.56c0,0.21,0.08,0.37,0.25,0.5
        l6.93,6.93c0.12,0.17,0.29,0.25,0.5,0.25c0.21,0,0.4-0.08,0.56-0.25L31.13,8.87z"
      />
    </symbol>
  );
}
