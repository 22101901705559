import ApiRequest from './ApiRequest';
import BaseApi from './BaseApi';

import {
  AuthoredLibraryPaperCue,
  CitedByLibraryPaperCue,
  CitesLibraryPaperCue,
  CitesYourPaperCue,
  CoauthorCue,
  CueType,
  YouCitedAuthorCue,
} from '@/constants/CueType';
import { AuthorLibraryFolder, Feed, Library } from '@/models/library/LibraryEntrySourceType';
import { CitationType } from '@/constants/Citation';
import { LibraryVisibleFolderTypes } from '@/models/library/LibraryFolderSourceType';
import { PrivacySetting } from '@/models/library/PrivacySetting';
import { RecommendationStatus } from '@/models/library/RecommendationStatus';
import { S2airsCorrection } from '@/models/paper-pdf/S2AirsCorrection';
import { UserSettingKey, UserSettingValue } from '@/models/user/UserSetting';
import { VenueQueryFromJS } from '@/models/VenueQuery';
import AlertFrequency, { AlertFrequencyKey } from '@/constants/AlertFrequency';
import Constants, { RequestTypeValue } from '@/constants';
import Experiment, { ExperimentKey } from '@/weblab/Experiment';
import Feature from '@/weblab/Feature';
import SortType from '@/constants/sort-type';

import Immutable from 'immutable';
import qs from 'qs';

import type { AddLibraryEntryBulkRequestRecord } from '@/models/library/AddLibraryEntryBulkRequest';
import type {
  AlertsHistoryResponseBody,
  AlertsResponseBody,
  ApiResponse,
  AuthorCorrectionsForAuthorResponseBody,
  AuthorCueDataResponseBody,
  AuthorDetailOrRedirectResponseBody,
  AuthorImpactResponseBody,
  AuthorPapersResponseBody,
  AuthorProfileForClaimModerationResponseBody,
  AuthorProfileListForClaimModerationResponseBody,
  AuthorProfileResponseBody,
  CitationIntersectionResponseBody,
  CitationsResponseBody,
  ClaimAuthorProfileResponseBody,
  CreateAlertResponseBody,
  CreateLibraryEntryBulkResponseBody,
  CreateLibraryFolderResponseBody,
  CreateUserSettingResponseBody,
  DeleteAlertResponseBody,
  DeleteLibraryEntryBulkResponseBody,
  DeleteLibraryFolderResponseBody,
  DeleteUserSettingByKeyResponseBody,
  DeleteUserSettingsResponseBody,
  EnrollmentResponseBody,
  EntityByIdResponseBody,
  ExposeUserResponseBody,
  FindAlertByQueryTypeAndValueResponseBody,
  GetAllEnrollmentsResponseBody,
  GetAllUserSettingsResponseBody,
  GetExperimentsResponseBody,
  GetFeatureFlagsResponseBody,
  GetLibraryEntriesResponseBody,
  GetLibraryFolderByIdResponseBody,
  GetLibraryFolderRecommendationsResponseBody,
  GetLibraryFoldersResponseBody,
  GetModerationRecommendationResponseBody,
  GetUserContactResponseBody,
  GetUserExposureResponseBody,
  GetUserSettingByKeyResponseBody,
  GetVenueResponseBody,
  LibraryAnnotationEntriesResponseBody,
  LibraryCueDataResponseBody,
  LibraryEntriesBulkResponseBody,
  LibraryEntriesResponseBody,
  LogoutResponseBody,
  MergeUsersResponseBody,
  ModerationStatsResponseBody,
  NoResponseBody,
  PaperAuthorCorrectionsForAuthorResponseBody,
  PaperCompletionResponseBody,
  PaperDetailResponseBody,
  PaperLabsResponseBody,
  PaperPdfDataResponseBody,
  PaperPdfTermDefinitionResponseBody,
  PaperPdfVisibilityResponseBody,
  PaperResponseBody,
  PapersBatchResponseBody,
  PapersWithCorrectionsAppliedForAuthorResponseBody,
  RecentArxivPubDateHistogramResponseBody,
  RemoveEntriesFromFoldersResponseBody,
  ResendVerificationEmailResponseBody,
  SampleQueriesResponseBody,
  SearchAuthorPapersResponseBody,
  SearchCitationPaperAggregationsResponseBody,
  SearchCitationPapersResponseBody,
  SearchPapersResponseBody,
  SearchReferencePaperAggregationsResponseBody,
  SearchReferencePapersResponseBody,
  SearchResponseBody,
  SearchSuggestionsResponseBody,
  SubmitAuthorIndexRequestsResponseBody,
  SubmitFeedbackResponseBody,
  SubmitPaperAuthorCorrectionsResponseBody,
  SubscribeResponseBody,
  TotalPapersCountResponseBody,
  TransferAuthorProfileResponseBody,
  UnclaimAuthorProfileResponseBody,
  UnsubscribeAllAlertsByTokenResponseBody,
  UpdateAlertFrequencyResponseBody,
  UpdateAlertResponseBody,
  UpdateAuthorProfileResponseBody,
  UpdateInternalNotesResponseBody,
  UpdateLibraryFolderResponseBody,
  UpdateModerationStatusResponseBody,
  UpdateUserResponseBody,
  UpdateUserSettingResponseBody,
  UserContactInfoResponseBody,
  UserInfoResponseBody,
  VerifyEmailResponseBody,
} from './ApiResponse';
import type { DEPRECATED__FlowOptional, Nullable, TODO } from '@/utils/types';
import type { LibraryEntriesQueryRecord } from '@/models/library/LibraryEntriesQuery';
import type { LibraryEntryAnnotationState } from '@/models/library/LibraryEntryAnnotationState';
import type { ModerationStatusReasonValue } from '@/constants/ModerationStatusReason';
import type { ModerationStatusValue } from '@/constants/ModerationStatus';
import type CitationQueryStore from '@/stores/CitationQueryStore';
import type ReferenceQueryStore from '@/stores/ReferenceQueryStore';
import type S2Dispatcher from '@/utils/S2Dispatcher';
import type WeblabStore from '@/weblab/WeblabStore';

export type PaperModelName = 'Paper' | 'PaperLite' | 'PaperId';

// TODO(codeviking): This class needs documentation!
export default class Api extends BaseApi {
  _authorQueryStore: TODO<'AuthorQueryStore'>;
  _citationQueryStore: CitationQueryStore;
  _referenceQueryStore: ReferenceQueryStore;
  _queryStore: TODO<'QueryStore'>;
  _paperStore: TODO<'PaperStore'>;
  _weblabStore: WeblabStore;

  _currentAuthorCorrectionsRequest: DEPRECATED__FlowOptional<
    ApiRequest<AuthorCorrectionsForAuthorResponseBody>
  >;
  _currentAuthorPaperCompletionRequest: DEPRECATED__FlowOptional<
    ApiRequest<PaperCompletionResponseBody>
  >;
  _currentCitationCompletionRequest: DEPRECATED__FlowOptional<
    ApiRequest<PaperCompletionResponseBody>
  >;
  _currentReferenceCompletionRequest: DEPRECATED__FlowOptional<
    ApiRequest<PaperCompletionResponseBody>
  >;
  _currentPaperAuthorCorrectionsRequest: DEPRECATED__FlowOptional<
    ApiRequest<PaperAuthorCorrectionsForAuthorResponseBody>
  >;
  _currentReaderPaperDetailRequest: DEPRECATED__FlowOptional<ApiRequest<PaperResponseBody>>;
  _currentSearchRequest: DEPRECATED__FlowOptional<
    ApiRequest<SearchResponseBody | SearchAuthorPapersResponseBody>
  >;
  _currentVenueRequest: DEPRECATED__FlowOptional<ApiRequest<GetVenueResponseBody>>;
  _currentSearchPapersRequest: DEPRECATED__FlowOptional<ApiRequest<SearchPapersResponseBody>>;
  _currentSearchCitationPapersRequest: DEPRECATED__FlowOptional<
    ApiRequest<SearchCitationPapersResponseBody>
  >;
  _currentSearchCitationPaperAggregationsRequest: DEPRECATED__FlowOptional<
    ApiRequest<SearchCitationPaperAggregationsResponseBody>
  >;

  _currentSearchReferencePapersRequest: DEPRECATED__FlowOptional<
    ApiRequest<SearchReferencePapersResponseBody>
  >;
  _currentSearchReferencePaperAggregationsRequest: DEPRECATED__FlowOptional<
    ApiRequest<SearchReferencePaperAggregationsResponseBody>
  >;

  _currentAuthorDetailRequest: DEPRECATED__FlowOptional<
    ApiRequest<AuthorDetailOrRedirectResponseBody>
  >;
  _currentFetchAuthorPapers: DEPRECATED__FlowOptional<ApiRequest<AuthorPapersResponseBody>>;
  _currentSuggestRequest: DEPRECATED__FlowOptional<ApiRequest<SearchSuggestionsResponseBody>>;
  _currentPaperDetailRequest: DEPRECATED__FlowOptional<ApiRequest<PaperDetailResponseBody>>;
  _currentPapersBatchRequest: DEPRECATED__FlowOptional<ApiRequest<PapersBatchResponseBody>>;
  _currentPapersWithCorrectionsAppliedForAuthorRequest: DEPRECATED__FlowOptional<
    ApiRequest<PapersWithCorrectionsAppliedForAuthorResponseBody>
  >;
  _currentAuthorProfileListForClaimModerationRequest: DEPRECATED__FlowOptional<
    ApiRequest<AuthorProfileListForClaimModerationResponseBody>
  >;
  _currentAuthorProfileForClaimModerationRequest: DEPRECATED__FlowOptional<
    ApiRequest<AuthorProfileForClaimModerationResponseBody>
  >;
  _currentGetLibraryEntriesRequest: DEPRECATED__FlowOptional<
    ApiRequest<LibraryEntriesResponseBody>
  >;
  _currentGetLibraryEntriesBulkRequest: DEPRECATED__FlowOptional<
    ApiRequest<LibraryAnnotationEntriesResponseBody>
  >;
  _currentCreateAlertRequestBody: Nullable<{
    queryType: string;
    queryValue: string;
  }>;

  // eslint-disable-next-line max-params
  constructor(
    dispatcher: S2Dispatcher,
    queryStore: TODO<'QueryStore'>,
    citationQueryStore: CitationQueryStore,
    referenceQueryStore: ReferenceQueryStore,
    paperStore: TODO<'PaperStore'>,
    weblabStore: WeblabStore,
    authorQueryStore: TODO<'AuthorQueryStore'>
  ) {
    super(dispatcher);
    // TODO(codeviking): The API really shouldn't dispatch anything.  It should just be a pure mechanism
    // for sending and receiving requests.  The willRouteTo handlers should individually decide how to
    // handle success / failure (by, for instance, more explicitly populating a store of their choice)
    // This will however require some rework!  This might be of merit for tech-debt week...
    this._queryStore = queryStore;
    this._authorQueryStore = authorQueryStore;
    this._citationQueryStore = citationQueryStore;
    this._referenceQueryStore = referenceQueryStore;
    this._paperStore = paperStore;
    this._weblabStore = weblabStore;
    this._currentAuthorCorrectionsRequest = null;
    this._currentPaperAuthorCorrectionsRequest = null;
  }

  /**
   * Executes a search request.
   * @return {Promise} a promise for the server's raw response
   */
  search(
    includeBadges?: DEPRECATED__FlowOptional<boolean>,
    includeTldrs?: DEPRECATED__FlowOptional<boolean>,
    getQuerySuggestions?: DEPRECATED__FlowOptional<boolean>
  ): Promise<ApiResponse<SearchResponseBody>> {
    this._cancelRequest(this._currentSearchRequest);
    const paperCuesVariationName = this._weblabStore.getVariation(Experiment.PaperCues.KEY);
    const personalizedAuthorCardVariationName = this._weblabStore.getVariation(
      Experiment.PersonalizedAuthorCardCues.KEY
    );

    let cues: CueType[] = [];
    switch (paperCuesVariationName) {
      case Experiment.PaperCues.Variation.CITED_BY_LIBRARY_CUE_ONLY: {
        cues = [CitedByLibraryPaperCue];
        break;
      }
      case Experiment.PaperCues.Variation.ALL_PAPER_CUES: {
        cues = [CitedByLibraryPaperCue, CitesYourPaperCue, CitesLibraryPaperCue];
        break;
      }
    }

    if (
      personalizedAuthorCardVariationName !==
      Experiment.PersonalizedAuthorCardCues.Variation.CONTROL
    ) {
      cues.push(AuthoredLibraryPaperCue, CoauthorCue, YouCitedAuthorCue);
    }

    const query = {
      ...this._queryStore.getPreparedQuery().toJS(),
      includeTldrs,
      performTitleMatch: true, // tells service to include a title-based search result if relevant one exists
      includeBadges,
      getQuerySuggestions,
      cues,
      includePdfVisibility: true,
    };

    const request = this.apiRequest<SearchResponseBody>({
      requestType: Constants.requestTypes.SEARCH,
      method: 'POST',
      path: '/search',
      data: query,
    });

    this._currentSearchRequest = request;
    return request.promise;
  }

  /**
   * Executes a search request for reference papers.
   * @return {Promise} a promise for the server's raw response
   */
  searchReferencePapers(paperId: string): Promise<ApiResponse<SearchReferencePapersResponseBody>> {
    // We fetch paper cues for citations if user is in any of the variantions for pdp cues experiment
    const fetchCues =
      this._weblabStore.isVariationEnabled(
        Experiment.PDPCitationAndReferencePaperCues.KEY,
        Experiment.PDPCitationAndReferencePaperCues.Variation
          .ENABLE_CITATION_AND_REFERENCE_PAPER_CUES
      ) ||
      this._weblabStore.isVariationEnabled(
        Experiment.PDPCitationAndReferencePaperCues.KEY,
        Experiment.PDPCitationAndReferencePaperCues.Variation
          .FETCH_CITATION_AND_REFERENCE_PAPER_CUES_DATA
      );

    const cues: CueType[] = fetchCues
      ? [CitedByLibraryPaperCue, CitesYourPaperCue, CitesLibraryPaperCue]
      : [];

    const query = {
      ...this._referenceQueryStore.getPreparedQuery().toJS(),
      citationType: CitationType.CITED_PAPERS,
      includePdfVisibility: true,
      cues: cues,
    };

    this._cancelRequest(this._currentSearchReferencePapersRequest);

    const request = this.apiRequest<SearchReferencePapersResponseBody>({
      requestType: Constants.requestTypes.SEARCH_REFERENCES,
      method: 'POST',
      path: '/search/paper/:paperId/citations',
      pathParams: { paperId },
      data: query,
    });

    this._currentSearchReferencePapersRequest = request;
    return request.promise;
  }

  searchReferencePaperAggregations(
    paperId: string
  ): Promise<ApiResponse<SearchReferencePaperAggregationsResponseBody>> {
    const query = {
      ...this._referenceQueryStore.getPreparedQuery().toJS(),
      citationType: CitationType.CITED_PAPERS,
    };

    this._cancelRequest(this._currentSearchReferencePaperAggregationsRequest);

    const request = this.apiRequest<SearchReferencePaperAggregationsResponseBody>({
      requestType: Constants.requestTypes.SEARCH_REFERENCES_AGG,
      method: 'POST',
      path: '/search/paper/:paperId/citation/aggregations',
      pathParams: { paperId },
      data: query,
    });

    this._currentSearchReferencePaperAggregationsRequest = request;
    return request.promise;
  }

  /**
   * Executes a search request for citing papers.
   * @return {Promise} a promise for the server's raw response
   */
  searchCitationPapers(paperId: string): Promise<ApiResponse<SearchCitationPapersResponseBody>> {
    const fetchCues =
      this._weblabStore.isVariationEnabled(
        Experiment.PDPCitationAndReferencePaperCues.KEY,
        Experiment.PDPCitationAndReferencePaperCues.Variation
          .ENABLE_CITATION_AND_REFERENCE_PAPER_CUES
      ) ||
      this._weblabStore.isVariationEnabled(
        Experiment.PDPCitationAndReferencePaperCues.KEY,
        Experiment.PDPCitationAndReferencePaperCues.Variation
          .FETCH_CITATION_AND_REFERENCE_PAPER_CUES_DATA
      );

    // We fetch paper cues for citations if user is in any of the variantions for pdp cues experiment
    const cues: CueType[] = fetchCues
      ? [CitedByLibraryPaperCue, CitesYourPaperCue, CitesLibraryPaperCue]
      : [];

    const query = {
      ...this._citationQueryStore.getPreparedQuery().toJS(),
      citationType: CitationType.CITING_PAPERS,
      includePdfVisibility: true,
      cues: cues,
    };

    this._cancelRequest(this._currentSearchCitationPapersRequest);

    const request = this.apiRequest<SearchCitationPapersResponseBody>({
      requestType: Constants.requestTypes.SEARCH_CITATIONS,
      method: 'POST',
      path: '/search/paper/:paperId/citations',
      pathParams: { paperId },
      data: query,
    });

    this._currentSearchCitationPapersRequest = request;
    return request.promise;
  }

  searchCitationPaperAggregations(
    paperId: string
  ): Promise<ApiResponse<SearchCitationPaperAggregationsResponseBody>> {
    const query = {
      ...this._citationQueryStore.getPreparedQuery().toJS(),
      citationType: CitationType.CITING_PAPERS,
    };

    this._cancelRequest(this._currentSearchCitationPaperAggregationsRequest);

    const request = this.apiRequest<SearchCitationPaperAggregationsResponseBody>({
      requestType: Constants.requestTypes.SEARCH_CITATIONS_AGG,
      method: 'POST',
      path: '/search/paper/:paperId/citation/aggregations',
      pathParams: { paperId },
      data: query,
    });

    this._currentSearchCitationPaperAggregationsRequest = request;
    return request.promise;
  }

  getVenueByName(venueName: string): Promise<ApiResponse<GetVenueResponseBody>> {
    this._cancelRequest(this._currentVenueRequest);
    const encodedVenueName = encodeURIComponent(venueName);
    const request = this.apiRequest<GetVenueResponseBody>({
      requestType: Constants.requestTypes.GET_VENUE_BY_NAME,
      method: 'GET',
      path: `/venue?name=${encodedVenueName}`,
      data: { encodedVenueName },
    });

    this._currentVenueRequest = request;
    return request.promise;
  }

  getVenueById(venueId: string): Promise<ApiResponse<GetVenueResponseBody>> {
    this._cancelRequest(this._currentVenueRequest);
    const request = this.apiRequest<GetVenueResponseBody>({
      requestType: Constants.requestTypes.GET_VENUE_BY_ID,
      method: 'GET',
      path: `/:venueId`,
      pathParams: { venueId },
    });

    this._currentVenueRequest = request;
    return request.promise;
  }

  // Search only for papers
  searchPapers(props: {
    query?: DEPRECATED__FlowOptional<string>;
    page?: DEPRECATED__FlowOptional<number>;
    limit?: DEPRECATED__FlowOptional<number>;
    model?: DEPRECATED__FlowOptional<PaperModelName>;
    excludeAuthorId?: DEPRECATED__FlowOptional<number>;
    prependSuggestions?: DEPRECATED__FlowOptional<boolean>; // Uses the suggestions endpoint to populate the first results
  }): Promise<ApiResponse<SearchPapersResponseBody>> {
    const {
      query = '',
      page = 1,
      limit = 10,
      model = 'PaperId',
      excludeAuthorId = null,
      prependSuggestions = false,
    } = props;
    this._cancelRequest(this._currentSearchPapersRequest);
    const request = this.apiRequest<SearchPapersResponseBody>({
      requestType: Constants.requestTypes.SEARCH_PAPERS,
      method: 'POST',
      path: '/search/paper',
      data: {
        query,
        page,
        limit,
        model,
        excludeAuthorId,
        prependSuggestions,
      },
    });
    this._currentSearchPapersRequest = request;
    return request.promise;
  }

  searchPapersByVenue(
    venueQueryJS: VenueQueryFromJS
  ): Promise<ApiResponse<SearchPapersResponseBody>> {
    this._cancelRequest(this._currentSearchRequest);
    const request = this.apiRequest<SearchPapersResponseBody>({
      requestType: Constants.requestTypes.SEARCH_PAPERS_BY_VENUE,
      method: 'POST',
      path: '/paper/search/venue',
      data: venueQueryJS,
    });

    return request.promise;
  }
  searchAuthorPapers(
    authorId: number | string
  ): Promise<ApiResponse<SearchAuthorPapersResponseBody>> {
    this._cancelRequest(this._currentSearchRequest);

    const includeTldrs = true;
    const query = {
      ...this._authorQueryStore.getPreparedQuery(SortType.INFLUENTIAL_CITATIONS.id).toJS(),
      includeTldrs,
    };

    const request = this.apiRequest<SearchAuthorPapersResponseBody>({
      requestType: Constants.requestTypes.AUTHOR_DETAIL_WITH_PAPERS,
      method: 'POST',
      path: `/author/:authorId`,
      pathParams: { authorId },
      data: query,
    });
    this._currentSearchRequest = request;
    return request.promise;
  }

  /**
   * Fetch an author's details without papers
   */
  fetchAuthorDetail(
    authorId: number,
    slug: DEPRECATED__FlowOptional<string>,
    requireSlug: Nullable<boolean> = true
  ): Promise<ApiResponse<AuthorDetailOrRedirectResponseBody>> {
    this._cancelRequest(this._currentAuthorDetailRequest);
    const query = {
      slug: slug,
      requireSlug: requireSlug,
    };
    const request = this.apiRequest<AuthorDetailOrRedirectResponseBody>({
      requestType: Constants.requestTypes.AUTHOR_DETAIL,
      method: 'GET',
      path: '/author/:authorId',
      pathParams: { authorId },
      data: query,
    });
    this._currentAuthorDetailRequest = request;
    return request.promise;
  }

  /**
   * Fetch an author's impact (ex. recent citations)
   */
  fetchAuthorImpact(props: {
    authorId: number;
    limit?: DEPRECATED__FlowOptional<number>;
  }): Promise<ApiResponse<AuthorImpactResponseBody>> {
    const { authorId, limit } = props;
    const request = this.apiRequest<AuthorImpactResponseBody>({
      requestType: Constants.requestTypes.AUTHOR_IMPACT,
      method: 'GET',
      path: '/author/:authorId/impact',
      pathParams: { authorId },
      data: { limit },
    });
    return request.promise;
  }

  fetchAuthorPapers(props: {
    authorId: number;
    filterText?: DEPRECATED__FlowOptional<string>;
    model?: DEPRECATED__FlowOptional<PaperModelName>;
  }): Promise<ApiResponse<AuthorPapersResponseBody>> {
    const { authorId, filterText = null, model = 'PaperLite' } = props;
    this._cancelRequest(this._currentFetchAuthorPapers);
    const request = this.apiRequest<AuthorPapersResponseBody>({
      requestType: Constants.requestTypes.AUTHOR_PAPERS,
      method: 'POST',
      path: '/author/:authorId/papers',
      pathParams: { authorId },
      data: { filterText, model },
    });
    this._currentFetchAuthorPapers = request;
    return request.promise;
  }

  fetchSearchSuggestions(query: string): ApiRequest<SearchSuggestionsResponseBody> {
    this._cancelRequest(this._currentSuggestRequest);
    const request = this.apiRequest<SearchSuggestionsResponseBody>({
      requestType: Constants.requestTypes.SEARCH_SUGGEST,
      method: 'GET',
      path: '/completion',
      data: {
        q: query,
      },
    });
    this._currentSuggestRequest = request;
    return request;
  }

  /** Fetches paper details for the given paper Id and slug with ALL additional query params */
  fetchPaperDetail(props: {
    paperId: string;
    slug?: DEPRECATED__FlowOptional<string>;
    citedPapersSort?: DEPRECATED__FlowOptional<string>;
    citedPapersLimit?: DEPRECATED__FlowOptional<number>;
    citedPapersOffset?: DEPRECATED__FlowOptional<number>;
    requireSlug?: DEPRECATED__FlowOptional<boolean>;
    citingPapersModelVersion?: DEPRECATED__FlowOptional<string>;
  }): Promise<ApiResponse<PaperDetailResponseBody>> {
    const {
      paperId,
      slug,
      citedPapersSort,
      citedPapersLimit,
      citedPapersOffset,
      requireSlug = true,
      citingPapersModelVersion,
    } = props;
    this._cancelRequest(this._currentPaperDetailRequest);

    const aggregateCitationsByIntent = true;
    const withEntitlements = this._weblabStore.isFeatureEnabled(Feature.WithPaperEntitlements);

    const query: TODO<'Define a type for these values, which overlaps with props'> = {
      slug,
      requireSlug,
      aggregateCitationsByIntent,
      withEntitlements,
    };

    // The server side knows our defaults, so only include what the caller specifies
    if (citedPapersSort) {
      query.citedPapersSort = citedPapersSort;
    }
    if (citedPapersLimit) {
      query.citedPapersLimit = citedPapersLimit;
    }
    if (citedPapersOffset) {
      query.citedPapersOffset = citedPapersOffset;
    }

    if (citingPapersModelVersion) {
      query.citingPapersModelVersion = citingPapersModelVersion;
    }

    const request = this.apiRequest<PaperDetailResponseBody>({
      requestType: Constants.requestTypes.PAPER_DETAIL,
      method: 'GET',
      path: '/paper/:paperId',
      pathParams: { paperId },
      data: query,
    });
    this._currentPaperDetailRequest = request;
    return request.promise;
  }

  fetchPaperLabs(props: { paperId: string }): Promise<ApiResponse<PaperLabsResponseBody>> {
    const { paperId } = props;

    const request = this.apiRequest<PaperLabsResponseBody>({
      requestType: Constants.requestTypes.PAPER_LABS,
      method: 'GET',
      path: '/labs/paper/:paperId',
      pathParams: { paperId },
    });
    return request.promise;
  }

  fetchReaderPaperById(props: { paperId: string }): Promise<ApiResponse<PaperResponseBody>> {
    const { paperId } = props;

    const request = this.apiRequest<PaperResponseBody>({
      requestType: Constants.requestTypes.READER_PAPER_DETAILS,
      method: 'GET',
      path: '/paper/:paperId/detail',
      pathParams: { paperId },
      data: { paperId },
    });
    this._currentReaderPaperDetailRequest = request;
    return request.promise;
  }

  fetchLibraryEntriesBulk(props: {
    paperIds?: Nullable<string[]>;
    corpusIds?: Nullable<number[]>;
  }): Promise<ApiResponse<LibraryEntriesBulkResponseBody>> {
    const { paperIds, corpusIds } = props;

    const request = this.apiRequest<LibraryEntriesBulkResponseBody>({
      requestType: Constants.requestTypes.GET_LIBRARY_ENTRIES_BULK,
      method: 'POST',
      path: '/library/entries/bulk',
      data: {
        paperIds: paperIds,
        corpusIds: corpusIds,
      },
    });

    this._currentGetLibraryEntriesBulkRequest = request;
    return request.promise;
  }

  fetchSharedCitationsWithLibraryForPaperIds(
    paperIds: string[]
  ): Promise<ApiResponse<CitationIntersectionResponseBody>> {
    return this.apiRequest<CitationIntersectionResponseBody>({
      requestType: Constants.requestTypes.GET_SHARED_LIBRARY_CITATIONS_FOR_PAPER_IDS,
      method: 'POST',
      path: '/library/entries/shared-citations',
      data: {
        paperIds,
      },
    }).promise;
  }

  _fetchPapersByIdsForRequestType({
    paperIds,
    model,
    requestType,
    fromDynamo = true, // setting false will fetch papers from Elasticsearch
  }: {
    paperIds: string[];
    model?: DEPRECATED__FlowOptional<PaperModelName>;
    requestType?: DEPRECATED__FlowOptional<RequestTypeValue>;
    fromDynamo?: Nullable<boolean>;
  }): Promise<ApiResponse<PapersBatchResponseBody>> {
    const reqType = requestType || Constants.requestTypes.PAPERS_BATCH;
    const path = fromDynamo ? '/paper/batch-v2' : '/paper/batch';
    const request = this.apiRequest<PapersBatchResponseBody>({
      requestType: reqType,
      method: 'POST',
      path,
      data: {
        ids: paperIds,
        model,
      },
    });
    this._currentPapersBatchRequest = request;
    return request.promise;
  }

  async fetchPapersByIds({
    paperIds,
    model,
    fromDynamo,
  }: {
    paperIds: string[];
    model?: DEPRECATED__FlowOptional<PaperModelName>;
    fromDynamo?: DEPRECATED__FlowOptional<boolean>;
  }): Promise<ApiResponse<PapersBatchResponseBody>> {
    return this._fetchPapersByIdsForRequestType({
      paperIds: paperIds,
      model: model,
      requestType: Constants.requestTypes.PAPERS_BATCH,
      fromDynamo: fromDynamo,
    });
  }

  fetchCitations(props: {
    paperId: string;
    citationType: string;
    sort: string;
    intent?: DEPRECATED__FlowOptional<string>;
    offset: number;
    pageSize: number;
    yearMin?: DEPRECATED__FlowOptional<number>;
    yearMax?: DEPRECATED__FlowOptional<number>;
    citingPapersModelVersion?: DEPRECATED__FlowOptional<string>;
  }): Promise<ApiResponse<CitationsResponseBody>> {
    const {
      paperId,
      citationType,
      sort,
      intent,
      offset,
      pageSize,
      yearMin,
      yearMax,
      citingPapersModelVersion,
    } = props;

    const query: TODO<'define a type for this, which overlaps with props'> = {
      sort: sort,
      offset: offset,
      citationType: citationType,
      citationsPageSize: pageSize,
      citingPapersYearMin: yearMin,
      citingPapersYearMax: yearMax,
    };
    if (intent && intent !== 'all') {
      query.citationIntent = intent;
    }
    if (citingPapersModelVersion) {
      query.citingPapersModelVersion = citingPapersModelVersion;
    }

    return this.apiRequest<CitationsResponseBody>({
      requestType: Constants.requestTypes.CITATIONS,
      method: 'GET',
      path: '/paper/:paperId/citations',
      pathParams: { paperId },
      data: query,
    }).promise;
  }

  fetchDefinitionForPdfTerm(props: {
    pdfSha: string;
    termId: string;
    termMentionId: string;
    outputDescription: string;
  }): Promise<ApiResponse<Nullable<PaperPdfTermDefinitionResponseBody>>> {
    const query = props;

    return this.apiRequest<Nullable<PaperPdfTermDefinitionResponseBody>>({
      requestType: Constants.requestTypes.DEFINITION_FOR_PAPER_TERM,
      method: 'GET',
      path: '/qa/term-definition',
      data: query,
    }).promise;
  }

  fetchPdfVisibility(paperId: string): Promise<ApiResponse<PaperPdfVisibilityResponseBody>> {
    return this.apiRequest<PaperPdfVisibilityResponseBody>({
      requestType: Constants.requestTypes.PDF_VISIBILITY,
      method: 'GET',
      path: '/paper/:paperId/pdf-visible',
      pathParams: { paperId },
      context: { paperId },
    }).promise;
  }

  fetchPdfData(paperId: string): Promise<ApiResponse<PaperPdfDataResponseBody>> {
    return this.apiRequest<PaperPdfDataResponseBody>({
      requestType: Constants.requestTypes.PDF_DATA,
      method: 'GET',
      path: '/paper/:paperId/pdf-data',
      pathParams: { paperId },
      context: { paperId },
    }).promise;
  }

  fetchCitationCompletions(props: {
    paperId: string;
    prefixQuery: string;
  }): Promise<ApiResponse<PaperCompletionResponseBody>> {
    this._cancelRequest(this._currentCitationCompletionRequest);

    const request = {
      ...this._citationQueryStore.getPreparedQuery().toJS(),
      citationType: CitationType.CITING_PAPERS,
    };

    const { paperId, prefixQuery } = props;

    const apiRequest = this.apiRequest<PaperCompletionResponseBody>({
      requestType: Constants.requestTypes.CITATION_COMPLETIONS,
      method: 'POST',
      path: '/search/paper/:paperId/completions/citation?q=' + prefixQuery,
      pathParams: { paperId },
      data: request,
    });
    this._currentCitationCompletionRequest = apiRequest;

    return apiRequest.promise;
  }

  fetchReferenceCompletions(props: {
    paperId: string;
    prefixQuery: string;
  }): Promise<ApiResponse<PaperCompletionResponseBody>> {
    this._cancelRequest(this._currentReferenceCompletionRequest);

    const request = {
      ...this._referenceQueryStore.getPreparedQuery().toJS(),
      citationType: CitationType.CITED_PAPERS,
    };

    const { paperId, prefixQuery } = props;

    const apiRequest = this.apiRequest<PaperCompletionResponseBody>({
      requestType: Constants.requestTypes.REFERENCE_COMPLETIONS,
      method: 'POST',
      path: '/search/paper/:paperId/completions/citation?q=' + prefixQuery,
      pathParams: { paperId },
      data: request,
    });
    this._currentReferenceCompletionRequest = apiRequest;

    return apiRequest.promise;
  }

  fetchAuthorPaperCompletions(props: {
    authorId: string;
    prefixQuery: string;
  }): Promise<ApiResponse<PaperCompletionResponseBody>> {
    this._cancelRequest(this._currentAuthorPaperCompletionRequest);

    const request = {
      ...this._authorQueryStore.getPreparedQuery().toJS(),
    };

    const { authorId, prefixQuery } = props;
    const apiRequest = this.apiRequest<PaperCompletionResponseBody>({
      requestType: Constants.requestTypes.AUTHOR_PAPER_COMPLETIONS,
      method: 'POST',
      path: `/author/:authorId/paper/completions?q=${prefixQuery}`,
      pathParams: { authorId },
      data: request,
    });
    this._currentAuthorPaperCompletionRequest = apiRequest;

    return apiRequest.promise;
  }

  fetchEntityById(
    id: number,
    queryFactors: any = {}
  ): Promise<ApiResponse<EntityByIdResponseBody>> {
    return this.apiRequest<EntityByIdResponseBody>({
      requestType: Constants.requestTypes.ENTITY_BY_ID,
      method: 'GET',
      path: '/topic/:id',
      pathParams: { id },
      data: queryFactors,
    }).promise;
  }

  fetchUserInfo(): Promise<ApiResponse<UserInfoResponseBody>> {
    return this.apiRequest<UserInfoResponseBody>({
      requestType: Constants.requestTypes.USER_INFO,
      method: 'GET',
      path: '/user',
    }).promise;
  }

  updateUser({ alertEmail }: { alertEmail: string }): Promise<ApiResponse<UpdateUserResponseBody>> {
    return this.apiRequest<UpdateUserResponseBody>({
      requestType: Constants.requestTypes.UPDATE_USER,
      method: 'PUT',
      path: '/user',
      data: {
        alertEmail,
      },
    }).promise;
  }

  updateUserRoles({
    userId,
    roles,
  }: {
    userId: number;
    roles: string[];
  }): Promise<ApiResponse<NoResponseBody>> {
    return this.apiRequest<NoResponseBody>({
      requestType: Constants.requestTypes.UPDATE_USER_ROLES,
      method: 'PUT',
      path: '/user/:userId/roles',
      pathParams: { userId },
      data: { roles },
    }).promise;
  }

  deleteUserById(userId: number): Promise<ApiResponse<any>> {
    return this.apiRequest({
      requestType: Constants.requestTypes.DELETE_USER,
      method: 'DELETE',
      path: '/user/:userId',
      pathParams: { userId },
    }).promise;
  }

  fetchBasicUserInfoById(userId: number): Promise<ApiResponse<any>> {
    return this.apiRequest({
      requestType: Constants.requestTypes.GET_BASIC_USER,
      method: 'GET',
      path: '/user/:userId',
      pathParams: { userId },
    }).promise;
  }

  loginAs({
    userId,
    next,
    reason,
  }: {
    userId: number;
    next?: DEPRECATED__FlowOptional<string>;
    reason: DEPRECATED__FlowOptional<string>;
  }): Promise<ApiResponse<any>> {
    return this.apiRequest({
      requestType: Constants.requestTypes.LOGIN_AS,
      method: 'GET',
      path: '/auth/login_as',
      data: {
        userId,
        next,
        reason,
      },
    }).promise;
  }

  // constants/AlertFrequency
  updateAlertFrequency({
    alertFrequency,
  }: {
    alertFrequency: AlertFrequencyKey;
  }): Promise<ApiResponse<UpdateAlertFrequencyResponseBody>> {
    const alertFrequencyValue = AlertFrequency[alertFrequency];
    return this.apiRequest<UpdateAlertFrequencyResponseBody>({
      requestType: Constants.requestTypes.UPDATE_ALERT_FREQUENCY,
      method: 'PUT',
      path: '/user/alert_frequency',
      data: { alertFrequency: alertFrequencyValue },
    }).promise;
  }

  resendVerificationEmail({
    email,
  }: {
    email?: DEPRECATED__FlowOptional<string>;
  }): Promise<ApiResponse<ResendVerificationEmailResponseBody>> {
    return this.apiRequest<ResendVerificationEmailResponseBody>({
      requestType: Constants.requestTypes.RESEND_VERIFICATION_EMAIL,
      method: 'PUT',
      path: '/user/resend_verification_email',
      data: { email },
    }).promise;
  }

  /**
   * add an enrollment to a user, by default applies to the currently signed in user.
   * An optional userId can be specified to set an enrollment on someone else.
   */
  addEnrollment(
    enrollmentKey: string,
    userId: DEPRECATED__FlowOptional<number>
  ): Promise<ApiResponse<EnrollmentResponseBody>> {
    return this.apiRequest<EnrollmentResponseBody>({
      requestType: Constants.requestTypes.USER_ADD_ENROLLMENT,
      method: 'PUT',
      path: '/user/enrollment',
      data: {
        enrollmentKey,
        userId,
      },
    }).promise;
  }

  /**
   * Remove an enrollment from a user, by default applies to the currently signed in user.
   * An optional userId can be specified to set an enrollment on someone else.
   */
  removeEnrollment(
    enrollmentKey: string,
    userId: DEPRECATED__FlowOptional<number>
  ): Promise<ApiResponse<EnrollmentResponseBody>> {
    return this.apiRequest<EnrollmentResponseBody>({
      requestType: Constants.requestTypes.USER_REMOVE_ENROLLMENT,
      method: 'DELETE',
      path: '/user/enrollment',
      data: {
        enrollmentKey,
        userId,
      },
    }).promise;
  }

  archiveEnrollment(enrollmentKey: string): Promise<ApiResponse<any>> {
    return this.apiRequest<any>({
      requestType: Constants.requestTypes.ARCHIVE_ENROLLMENT,
      method: 'DELETE',
      path: '/enrollment/:enrollmentKey',
      pathParams: {
        enrollmentKey,
      },
    }).promise;
  }

  getAllEnrollments(): Promise<ApiResponse<GetAllEnrollmentsResponseBody>> {
    return this.apiRequest<GetAllEnrollmentsResponseBody>({
      requestType: Constants.requestTypes.GET_ALL_ENROLLMENTS,
      method: 'GET',
      path: '/enrollment/all',
    }).promise;
  }

  findAlertByQueryTypeAndValue(
    queryType: string,
    queryValue: string
  ): Promise<ApiResponse<FindAlertByQueryTypeAndValueResponseBody>> {
    const query = {
      queryType,
      queryValue,
      offset: 0,
      limit: 1,
    };
    return this.apiRequest<FindAlertByQueryTypeAndValueResponseBody>({
      requestType: Constants.requestTypes.ALERTS_FOR_PAGE,
      method: 'GET',
      path: '/alerts',
      data: query,
    }).promise;
  }

  fetchAlertsHistory(
    pageSize?: DEPRECATED__FlowOptional<{ pageSize: number }>
  ): Promise<ApiResponse<AlertsHistoryResponseBody>> {
    return this.apiRequest<AlertsHistoryResponseBody>({
      requestType: Constants.requestTypes.ALERTS_HISTORY,
      method: 'GET',
      path: '/alert/history',
      data: {
        ...pageSize,
      },
    }).promise;
  }

  fetchAlerts(): Promise<ApiResponse<AlertsResponseBody>> {
    const query = { offset: 0, limit: 100 };
    return this.apiRequest<AlertsResponseBody>({
      requestType: Constants.requestTypes.ALERTS,
      method: 'GET',
      path: '/alerts',
      data: query,
    }).promise;
  }

  createAlert({
    queryType,
    queryValue,
  }: {
    queryType: string;
    queryValue: string;
  }): Promise<ApiResponse<CreateAlertResponseBody> | void> {
    // if a user double clicks on the create alert button too fast, it'll fire off 2 requests
    // which will error on the 2nd one due to db unique constraints
    // catch the 2nd duplicate call and no-op
    const oldReq = this._currentCreateAlertRequestBody;
    if (oldReq?.queryType === queryType && oldReq?.queryValue === queryValue) {
      this._currentCreateAlertRequestBody = null;
      return Promise.resolve();
    }
    this._currentCreateAlertRequestBody = {
      queryType,
      queryValue,
    };

    return this.apiRequest<CreateAlertResponseBody>({
      requestType: Constants.requestTypes.CREATE_ALERT,
      method: 'POST',
      path: '/alert',
      data: {
        queryType,
        queryValue,
      },
    }).promise;
  }

  updateAlert({
    alertId,
    queryValue,
    status,
    canonicalQueryValue,
  }: {
    alertId: number;
    queryValue: string;
    status?: DEPRECATED__FlowOptional<string>;
    canonicalQueryValue?: DEPRECATED__FlowOptional<string>;
  }): Promise<ApiResponse<UpdateAlertResponseBody>> {
    return this.apiRequest<UpdateAlertResponseBody>({
      requestType: Constants.requestTypes.UPDATE_ALERT,
      method: 'PUT',
      path: '/alert/:alertId',
      pathParams: { alertId },
      data: {
        queryValue,
        status,
        canonicalQueryValue,
      },
    }).promise;
  }

  deleteAlert(alertId: number): Promise<ApiResponse<DeleteAlertResponseBody>> {
    return this.apiRequest<DeleteAlertResponseBody>({
      requestType: Constants.requestTypes.DELETE_ALERT,
      method: 'DELETE',
      path: '/alert/:alertId',
      pathParams: { alertId },
    }).promise;
  }

  verifyEmail(verificationToken: string): Promise<ApiResponse<VerifyEmailResponseBody>> {
    const path = `/user/verify_email/${encodeURIComponent(verificationToken)}`;

    return this.apiRequest<VerifyEmailResponseBody>({
      requestType: Constants.requestTypes.VERIFY_EMAIL,
      method: 'PUT',
      path: path,
    }).promise;
  }

  unsubscribeAllAlertsByToken(
    unsubscribeAllToken: string
  ): Promise<ApiResponse<UnsubscribeAllAlertsByTokenResponseBody>> {
    const path = `/alerts/unsubscribe_all/${encodeURIComponent(unsubscribeAllToken)}`;

    return this.apiRequest<UnsubscribeAllAlertsByTokenResponseBody>({
      requestType: Constants.requestTypes.UNSUBSCRIBE_FROM_ALL_ALERTS,
      method: 'PUT',
      path,
    }).promise;
  }

  async logout(): Promise<ApiResponse<LogoutResponseBody>> {
    const response = await this.apiRequest<LogoutResponseBody>({
      requestType: Constants.requestTypes.LOGOUT,
      method: 'GET',
      path: '/auth/logout',
    }).promise;
    this._dispatcher.dispatchToOtherWindows(response);
    return response;
  }

  getUserContact(): Promise<ApiResponse<GetUserContactResponseBody>> {
    return this.apiRequest<GetUserContactResponseBody>({
      requestType: Constants.requestTypes.GET_USER_CONTACT,
      method: 'GET',
      path: '/user/contact',
    }).promise;
  }

  submitUserContactInfo(
    data: TODO<'UserContact'>
  ): Promise<ApiResponse<UserContactInfoResponseBody>> {
    return this.apiRequest<UserContactInfoResponseBody>({
      requestType: Constants.requestTypes.USER_CONTACT,
      method: 'PUT',
      path: '/user/contact',
      data,
    }).promise;
  }

  /**
   * Submit user feedback.
   *
   * @param {string} name The user's human name.
   * @param {string} email The user's email.
   * @param {string} topic Categorical topic of the feedback.
   * @param {string} subject User-input subject line.
   * @param {string} feedback User-input feedback to submit.
   * @param {string} url of the relevant page.
   * @param {tags} tags  for reader team to process ticket
   * @param {s2airsCorrection} s2airsCorrection correction data for reader team to process ticket
   *
   * @return {Promise} A promise which is resolved once the feedback has been submitted.
   */
  submitFeedback({
    name,
    email,
    topic,
    subject,
    feedback,
    url,
    tags,
    s2airsCorrection,
  }: {
    name: string;
    email: string;
    topic: string;
    subject: string;
    feedback: string;
    url: string;
    tags?: Nullable<string[]>;
    s2airsCorrection?: Nullable<S2airsCorrection>;
  }): Promise<ApiResponse<SubmitFeedbackResponseBody>> {
    const data = {
      name,
      email,
      topic,
      subject,
      feedback,
      url,
      tags,
      s2airsCorrection,
    };
    return ApiRequest.send<SubmitFeedbackResponseBody>({
      method: 'POST',
      path: '/feedback',
      data: data,
      extraHeaders: this._extraHeaders,
    }).promise;
  }

  /**
   * Subscribes the provided email address to the s2 newsletter in hubspot
   * @param {string} email
   * @return {Promise} A promise, resolved once subscribed.
   */
  subscribe(email: string): Promise<ApiResponse<SubscribeResponseBody>> {
    return ApiRequest.send({
      method: 'POST',
      path: '/subscribe/newsletter',
      data: { email: email },
      extraHeaders: this._extraHeaders,
    }).promise;
  }

  fetchAuthorProfile({
    authorId,
  }: {
    authorId: number;
  }): Promise<ApiResponse<AuthorProfileResponseBody>> {
    return this.apiRequest<AuthorProfileResponseBody>({
      requestType: Constants.requestTypes.AUTHOR_PROFILE_FETCH,
      method: 'GET',
      path: '/profile/author/:authorId',
      pathParams: { authorId },
    }).promise;
  }

  claimAuthorProfile(props: {
    authorId: number;
    email: string;
    fullName: string;
    userComment?: DEPRECATED__FlowOptional<string>;
    orcidId?: DEPRECATED__FlowOptional<string>;
    affiliations: string[];
    homepageUrl?: DEPRECATED__FlowOptional<string>;
    fieldsOfStudy?: DEPRECATED__FlowOptional<string[]>;
    currentOccupation?: DEPRECATED__FlowOptional<string>;
    claimToken?: DEPRECATED__FlowOptional<string>;
  }): Promise<ApiResponse<ClaimAuthorProfileResponseBody>> {
    const {
      authorId,
      email,
      fullName,
      userComment = null,
      orcidId = null,
      affiliations = [],
      homepageUrl = null,
      fieldsOfStudy = null,
      currentOccupation = null,
      claimToken = null,
    } = props;
    return this.apiRequest<ClaimAuthorProfileResponseBody>({
      requestType: Constants.requestTypes.AUTHOR_PROFILE_CLAIM,
      method: 'POST',
      path: '/profile/author/:authorId',
      pathParams: { authorId },
      data: {
        email,
        fullName,
        affiliations,
        homepageUrl,
        orcidId,
        userComment,
        fieldsOfStudy,
        currentOccupation,
        claimToken,
      },
    }).promise;
  }

  transferAuthorProfile(
    currentUserId: number,
    transferUserId: number
  ): Promise<ApiResponse<TransferAuthorProfileResponseBody>> {
    return this.apiRequest<TransferAuthorProfileResponseBody>({
      requestType: Constants.requestTypes.TRANSFER_AUTHOR_PROFILE,
      method: 'POST',
      path: '/profile/transfer',
      data: { currentUserId, transferUserId },
    }).promise;
  }

  unclaimAuthorProfile({
    authorId,
  }: {
    authorId: number;
  }): Promise<ApiResponse<UnclaimAuthorProfileResponseBody>> {
    return this.apiRequest<UnclaimAuthorProfileResponseBody>({
      requestType: Constants.requestTypes.AUTHOR_PROFILE_UNCLAIM,
      method: 'DELETE',
      path: '/profile/author/:authorId',
      pathParams: { authorId },
    }).promise;
  }

  updateAuthorProfile(props: {
    authorId: number;
    lastName: string;
    middleNames: string[];
    firstName: string;
    affiliation: string[];
    homepageUrl?: DEPRECATED__FlowOptional<string>;
    orcidId?: DEPRECATED__FlowOptional<string>;
    pronouns?: DEPRECATED__FlowOptional<string>;
  }): Promise<ApiResponse<UpdateAuthorProfileResponseBody>> {
    const {
      authorId,
      lastName,
      middleNames = [],
      firstName = null,
      affiliation = [],
      homepageUrl = null,
      orcidId = null,
      pronouns = null,
    } = props;
    return this.apiRequest<UpdateAuthorProfileResponseBody>({
      requestType: Constants.requestTypes.AUTHOR_PROFILE_UPDATE,
      method: 'PUT',
      path: '/profile/author/:authorId',
      pathParams: { authorId },
      data: {
        firstName,
        middleNames,
        lastName,
        affiliation,
        homepageUrl,
        orcidId,
        pronouns,
      },
    }).promise;
  }

  submitPaperAuthorCorrections(props: {
    authorId: number;
    corrections: TODO<'PaperAuthorCorrectionRecord'>[];
  }): Promise<ApiResponse<SubmitPaperAuthorCorrectionsResponseBody>> {
    const { authorId, corrections = [] } = props;
    // Copy only the fields we need
    const correctionsForApi = corrections.map(c => ({
      correctionType: c.correctionType,
      paperHash: c.paperHash,
      fromAuthorId: c.fromAuthorId || null,
      position: typeof c.position === 'number' ? c.position : null,
    }));
    return this.apiRequest<SubmitPaperAuthorCorrectionsResponseBody>({
      requestType: Constants.requestTypes.AUTHOR_MANAGE_PAPERS,
      method: 'POST',
      path: '/edit/author/:authorId/authorship',
      pathParams: { authorId },
      data: { corrections: correctionsForApi },
    }).promise;
  }

  fetchLibraryCueData(
    paperIds: Immutable.List<string>
  ): Promise<ApiResponse<LibraryCueDataResponseBody>> {
    const paperIdsString = paperIds.join('&papers=');

    const request = this.apiRequest<LibraryCueDataResponseBody>({
      requestType: Constants.requestTypes.FETCH_LIBRARY_CUE_DATA,
      method: 'GET',
      path: `/cues/paper-entries?papers=` + paperIdsString,
      context: { paperIds },
    });

    return request.promise;
  }

  fetchAuthorCueData(
    authorId: string,
    cueType: string
  ): Promise<ApiResponse<AuthorCueDataResponseBody>> {
    const authorRequest = this.apiRequest<AuthorCueDataResponseBody>({
      requestType: Constants.requestTypes.FETCH_AUTHOR_CUE_DATA,
      method: 'GET',
      path: `/cues/authored-papers?cueType=${cueType}&authorId=${authorId}`,
      context: { authorId },
    });

    return authorRequest.promise;
  }

  submitAuthorIndexRequests({
    urls,
  }: {
    urls: string[];
  }): Promise<ApiResponse<SubmitAuthorIndexRequestsResponseBody>> {
    return this.apiRequest<SubmitAuthorIndexRequestsResponseBody>({
      requestType: Constants.requestTypes.AUTHOR_INDEX_REQUEST_UPDATE,
      method: 'POST',
      path: '/edit/author/index-requests',
      data: { urls },
    }).promise;
  }

  /**
   * Gets the total number of papers available in search.
   * @returns {Promise}
   */
  fetchTotalPapersCount(): Promise<ApiResponse<TotalPapersCountResponseBody>> {
    return this.apiRequest<TotalPapersCountResponseBody>({
      requestType: Constants.requestTypes.GET_PAPER_COUNT,
      method: 'GET',
      path: '/search/paper/count',
    }).promise;
  }

  /**
   * Gets the stats for tabs in moderation section
   * @returns {Promise}
   */
  fetchModerationStats(): Promise<ApiResponse<ModerationStatsResponseBody>> {
    return this.apiRequest<ModerationStatsResponseBody>({
      requestType: Constants.requestTypes.MODERATION_STATS,
      method: 'GET',
      path: '/moderation/stats',
    }).promise;
  }

  setAuthorProfileModerationTag({
    authorProfileId,
    moderationTag,
  }: {
    authorProfileId: number;
    moderationTag: DEPRECATED__FlowOptional<string>;
  }): Promise<ApiResponse<TODO<'SetAuthorProfileModerationTag'>>> {
    return this.apiRequest<TODO<'SetAuthorProfileModerationTag'>>({
      requestType: Constants.requestTypes.SET_AUTHOR_PROFILE_MODERATION_TAG,
      method: 'PUT',
      path: '/moderation/author/:authorProfileId/claim/tag',
      pathParams: { authorProfileId },
      data: { moderationTag },
    }).promise;
  }

  /**
   * Gets author profiles that have a moderationStatus of pending that need to be moderated
   * @returns {Promise}
   */
  getAuthorProfileListForClaimModeration({
    status,
    query,
  }: {
    status: ModerationStatusValue;
    query?: DEPRECATED__FlowOptional<string>;
  }): Promise<ApiResponse<AuthorProfileListForClaimModerationResponseBody>> {
    const oldReq = this._currentAuthorProfileListForClaimModerationRequest;
    if (oldReq) {
      oldReq.cancel();
    }
    const req = this.apiRequest<AuthorProfileListForClaimModerationResponseBody>({
      requestType: Constants.requestTypes.GET_AUTHOR_PROFILE_LIST_FOR_MODERATION,
      method: 'GET',
      path: '/moderation/author/claim/status/:status',
      pathParams: { status },
      data: {
        query: query || '',
      },
    });
    this._currentAuthorProfileListForClaimModerationRequest = req;
    return req.promise;
  }

  getAuthorProfileForClaimModeration({
    authorProfileId,
  }: {
    authorProfileId: number;
  }): Promise<ApiResponse<AuthorProfileForClaimModerationResponseBody>> {
    const oldReq = this._currentAuthorProfileForClaimModerationRequest;
    if (oldReq) {
      oldReq.cancel();
    }
    const req = this.apiRequest<AuthorProfileForClaimModerationResponseBody>({
      requestType: Constants.requestTypes.GET_AUTHOR_PROFILE_FOR_MODERATION,
      method: 'GET',
      path: '/moderation/author_profile/:authorProfileId',
      pathParams: { authorProfileId },
    });
    this._currentAuthorProfileForClaimModerationRequest = req;
    return req.promise;
  }

  /**
   * Updates an author profiles moderation status
   * @param {number} authorProfileId - The id of the author profile to update.
   * @param {string} moderationStatus - The updated status
   * @param {string} moderator - The email of the moderator that made the decision
   * @param {string} internalNotes - Notes used internally to moderate
   * @param {string} moderationStatusReason - Reason for decision
   * @returns {Promise}
   */
  updateModerationStatus({
    authorProfileId,
    moderationStatus,
    moderator,
    moderationStatusReason,
    internalNotes,
  }: {
    authorProfileId: number;
    moderationStatus: ModerationStatusValue;
    moderator: string;
    moderationStatusReason: DEPRECATED__FlowOptional<ModerationStatusReasonValue>;
    internalNotes: string;
  }): Promise<ApiResponse<UpdateModerationStatusResponseBody>> {
    return this.apiRequest<UpdateModerationStatusResponseBody>({
      requestType: Constants.requestTypes.UPDATE_AUTHOR_CLAIM_STATUS,
      method: 'PUT',
      path: '/moderation/author/claim/:authorProfileId/status/:moderationStatus',
      pathParams: { authorProfileId, moderationStatus },
      data: { moderator, moderationStatusReason, internalNotes },
    }).promise;
  }

  /**
   * Updates an author profile's internal notes
   * @param {number} authorProfileId - The id of the author profile to update.
   * @param {string} internalNotes - Notes used internally to moderate
   * @returns {Promise}
   */
  updateInternalNotes({
    authorProfileId,
    internalNotes,
  }: {
    authorProfileId: number;
    internalNotes: DEPRECATED__FlowOptional<string>;
  }): Promise<ApiResponse<UpdateInternalNotesResponseBody>> {
    return this.apiRequest<UpdateInternalNotesResponseBody>({
      requestType: Constants.requestTypes.UPDATE_INTERNAL_NOTES,
      method: 'PUT',
      path: '/moderation/author/claim/:authorProfileId/notes',
      pathParams: { authorProfileId },
      data: { internalNotes },
    }).promise;
  }

  /**
   * Checks moderation rules for given author claim
   * @param {number} authorRowId - The id of the row to check.
   * @returns {Promise}
   */
  getModerationRecommendation({
    authorRowId,
  }: {
    authorRowId: number;
  }): Promise<ApiResponse<GetModerationRecommendationResponseBody>> {
    return this.apiRequest<GetModerationRecommendationResponseBody>({
      requestType: Constants.requestTypes.GET_MODERATION_RECOMMENDATION,
      method: 'GET',
      path: '/moderation/recommendations/:authorRowId',
      pathParams: { authorRowId },
    }).promise;
  }

  /**
   * Fetches a list of sample queries from the API.
   * @returns {Promise}
   */
  fetchSampleQueries(): Promise<ApiResponse<SampleQueriesResponseBody>> {
    // We only return default sample queries as of pr #34919 but other
    // keys can be used in the api if theyre defined
    return this.apiRequest<SampleQueriesResponseBody>({
      requestType: Constants.requestTypes.SAMPLE_QUERIES,
      method: 'GET',
      path: '/sample-queries/default',
    }).promise;
  }

  /**
   * Fetches folder metadata and entries for a given folder id if you are
   * the owner or if the folder is marked as public
   */
  getFolderById(
    id: number,
    query: LibraryEntriesQueryRecord
  ): Promise<ApiResponse<GetLibraryFolderByIdResponseBody>> {
    return this.apiRequest<GetLibraryFolderByIdResponseBody>({
      requestType: Constants.requestTypes.GET_FOLDER_BY_ID,
      method: 'GET',
      path: `/library/folder/:id/?${qs.stringify(query.toJSON())}`,
      pathParams: { id },
    }).promise;
  }

  /**
   * Fetches all entries from a user's library.
   * @param {LibraryEntriesQueryRecord} query - the query to request
   * @returns {Promise}
   */
  getAllLibraryEntries(
    query: LibraryEntriesQueryRecord
  ): Promise<ApiResponse<GetLibraryEntriesResponseBody>> {
    const sourceTypeFilter = this._getEntrySourceTypeFilterQuery();
    return this.apiRequest<GetLibraryEntriesResponseBody>({
      requestType: Constants.requestTypes.GET_ALL_LIBRARY_ENTRIES,
      method: 'GET',
      path: `/library/entries/all?${sourceTypeFilter}&` + qs.stringify(query.toJSON()),
    }).promise;
  }

  /**
   * Fetches all entries from a user's library.
   * @param folderId - folder to fetch entries for
   * @param {LibraryEntriesQueryRecord} query - the query to request
   * @returns {Promise}
   */
  getLibraryEntriesByFolderId(
    folderId: number,
    query: LibraryEntriesQueryRecord
  ): Promise<ApiResponse<GetLibraryEntriesResponseBody>> {
    const sourceTypeFilter = this._getEntrySourceTypeFilterQuery();
    return this.apiRequest<GetLibraryEntriesResponseBody>({
      requestType: Constants.requestTypes.GET_LIBRARY_ENTRIES_BY_FOLDER,
      method: 'GET',
      path:
        `/library/folders/:folderId/entries?${sourceTypeFilter}&` + qs.stringify(query.toJSON()),
      pathParams: { folderId },
    }).promise;
  }

  /**
   * Fetches entries that are not in a folder (unsorted) from a user's library.
   * @param {LibraryEntriesQueryRecord} query - the query to request
   * @returns {Promise}
   */
  getUnsortedEntries(
    query: LibraryEntriesQueryRecord
  ): Promise<ApiResponse<GetLibraryEntriesResponseBody>> {
    const sourceTypeFilter = this._getEntrySourceTypeFilterQuery();
    return this.apiRequest<GetLibraryEntriesResponseBody>({
      requestType: Constants.requestTypes.GET_UNSORTED_LIBRARY_ENTRIES,
      method: 'GET',
      path: `/library/folders/unsorted/entries?${sourceTypeFilter}&` + qs.stringify(query.toJSON()),
    }).promise;
  }

  /**
   * Deletes library entries from a user's library based on the provided paper ids using the
   * bulk endpoint.
   * @param {string} paperId - the paperId to delete from the library
   * @param {string[]} sources - the sourceTypes of the entries to delete
   * @returns {Promise}
   */
  async deleteLibraryEntriesBulk(
    paperId: string,
    sources?: DEPRECATED__FlowOptional<string[]>
  ): Promise<ApiResponse<DeleteLibraryEntryBulkResponseBody>> {
    const sourceTypes = sources ? JSON.stringify(sources) : [];

    const response = await this.apiRequest<DeleteLibraryEntryBulkResponseBody>({
      requestType: Constants.requestTypes.DELETE_LIBRARY_ENTRIES_BULK,
      method: 'DELETE',
      path: '/library/entries/bulk',
      data: { paperId, sourceTypes },
    }).promise;
    this._dispatcher.dispatchToOtherWindows(response);
    return response;
  }

  /**
   * Fetches recommendations history from a user's library based on the provided folder ids
   * and end datetime (in epoch seconds). getLibraryFoldersRecommendations and its counterpart
   * getMoreLibraryFoldersRecommendations call the exact same service operations, but have
   * different request types to signal different result handling in the store layer.
   * @param {Immutable.Set} folderIds - the folder ids to search
   * @param {number} windowUTC - the end datetime (in epoch seconds)
   * @returns {Promise}
   */
  _getLibraryFoldersRecsWithType(
    requestType: RequestTypeValue,
    folderIds: Immutable.Set<number>,
    windowUTC: number,
    limits?: {
      limitPerFolder?: number;
      dayLimit?: number;
    }
  ): Promise<ApiResponse<GetLibraryFolderRecommendationsResponseBody>> {
    const otherArgs = {
      windowUTC,
      ...limits,
    };
    return this.apiRequest<GetLibraryFolderRecommendationsResponseBody>({
      requestType,
      method: 'GET',
      // TODO: remove deprecated call
      // "The `querystring` API is considered Legacy. While it is still maintained, new code should use the `URLSearchParams` API instead."
      path: `/library/folders/recommendations?${qs.stringify(
        {
          folderIds: folderIds.toJSON(),
        },
        { encode: false, arrayFormat: 'repeat' }
      )}&${qs.stringify(otherArgs)}`,
      context: { folderIds: folderIds.toJSON() }, // apiRequest context passed through
    }).promise;
  }

  getLibraryFoldersRecommendations(
    folderIds: Immutable.Set<number>,
    windowUTC: number
  ): Promise<ApiResponse<GetLibraryFolderRecommendationsResponseBody>> {
    return this._getLibraryFoldersRecsWithType(
      Constants.requestTypes.GET_LIBRARY_FOLDERS_RECOMMENDATIONS,
      folderIds,
      windowUTC
    );
  }

  getMoreLibraryFoldersRecommendations(
    folderIds: Immutable.Set<number>,
    windowUTC: number
  ): Promise<ApiResponse<GetLibraryFolderRecommendationsResponseBody>> {
    return this._getLibraryFoldersRecsWithType(
      Constants.requestTypes.GET_MORE_LIBRARY_FOLDERS_RECOMMENDATIONS,
      folderIds,
      windowUTC
    );
  }

  /**
   * Fetches annotations by type made by a user
   * @param {LibraryEntryAnnotationState} annotationState - the folder ids to search
   * @returns {Promise}
   */
  getLibraryAnnotationEntries(
    annotationState: LibraryEntryAnnotationState
  ): Promise<ApiResponse<LibraryAnnotationEntriesResponseBody>> {
    return this.apiRequest<LibraryAnnotationEntriesResponseBody>({
      requestType: Constants.requestTypes.GET_LIBRARY_ANNOTATIONS_BY_STATE,
      method: 'GET',
      path: '/library/entries/annotations/:annotationState',
      pathParams: { annotationState },
    }).promise;
  }

  /**
   * Annotate an entry to a user's library.
   * @param {AddLibraryEntryBulkRequestRecord} entry - the entry to be annotated
   * @returns {Promise}
   */
  annotateLibraryEntry(
    entry: AddLibraryEntryBulkRequestRecord
  ): Promise<ApiResponse<CreateLibraryEntryBulkResponseBody>> {
    const entryJs = entry.toJS();

    return this.apiRequest<CreateLibraryEntryBulkResponseBody>({
      requestType: Constants.requestTypes.ANNOTATE_LIBRARY_ENTRY,
      method: 'POST',
      path: '/library/folders/entries/bulk',
      data: entryJs,
    }).promise;
  }

  /**
   * Adds an entry to a user's library. Multiple folders can be specified alongside annotationState/sourceType for the entry.
   * @param {AddLibraryEntryBulkRequestRecord} newEntry - the entry to be created
   * @returns {Promise}
   */
  async createLibraryEntryBulk(
    newEntry: AddLibraryEntryBulkRequestRecord
  ): Promise<ApiResponse<CreateLibraryEntryBulkResponseBody>> {
    const newEntryJs = newEntry.toJS();

    const response = await this.apiRequest<CreateLibraryEntryBulkResponseBody>({
      requestType: Constants.requestTypes.CREATE_LIBRARY_ENTRY_BULK,
      method: 'POST',
      path: '/library/folders/entries/bulk',
      data: newEntryJs,
    }).promise;

    this._dispatcher.dispatchToOtherWindows(response);
    return response;
  }

  /**
   * Fetches all folders from a user's library.
   * @returns {Promise}
   */
  getLibraryFolders(): Promise<ApiResponse<GetLibraryFoldersResponseBody>> {
    const entrySourceTypeFilter = this._getEntrySourceTypeFilterQuery();
    const folderSourceTypeFilter = this._getFolderSourceTypeFilterQuery();
    return this.apiRequest<GetLibraryFoldersResponseBody>({
      requestType: Constants.requestTypes.GET_LIBRARY_FOLDERS,
      method: 'GET',
      path: `/library/folders?${entrySourceTypeFilter}&${folderSourceTypeFilter}`,
    }).promise;
  }

  createLibraryFolder({
    name,
    recommendationStatus,
  }: {
    name: string;
    recommendationStatus: TODO<'RecommendationStatus'>;
  }): Promise<ApiResponse<CreateLibraryFolderResponseBody>> {
    return this.apiRequest<CreateLibraryFolderResponseBody>({
      requestType: Constants.requestTypes.CREATE_LIBRARY_FOLDER,
      method: 'POST',
      path: '/library/folders',
      data: {
        name,
        recommendationStatus,
      },
    }).promise;
  }

  copyLibraryFolder(folderId: number): Promise<ApiResponse<CreateLibraryFolderResponseBody>> {
    return this.apiRequest<CreateLibraryFolderResponseBody>({
      requestType: Constants.requestTypes.COPY_LIBRARY_FOLDER,
      method: 'POST',
      path: '/library/copy-folder',
      data: {
        folderId,
      },
    }).promise;
  }

  /**
   * Update a folder's name.
   * @param {number} id - The id of the folder to update.
   * @param {string} newName - The new name of for the folder.
   * @returns {Promise}
   */
  updateLibraryFolder({
    id,
    name,
    recommendationStatus,
    description,
    privacySetting,
  }: {
    id: number;
    name?: Nullable<string> | undefined;
    recommendationStatus?: Nullable<RecommendationStatus> | undefined;
    description?: Nullable<string> | undefined;
    privacySetting?: Nullable<PrivacySetting> | undefined;
  }): Promise<ApiResponse<UpdateLibraryFolderResponseBody>> {
    return this.apiRequest<UpdateLibraryFolderResponseBody>({
      requestType: Constants.requestTypes.UPDATE_LIBRARY_FOLDER,
      method: 'PUT',
      path: '/library/folders/:id',
      pathParams: { id },
      data: { name, recommendationStatus, description, privacySetting },
    }).promise;
  }

  /**
   * Delete a folder.
   * @param {number} id - The id of the tag to update.
   * @returns {Promise}
   */
  deleteLibraryFolder(id: number): Promise<ApiResponse<DeleteLibraryFolderResponseBody>> {
    return this.apiRequest<DeleteLibraryFolderResponseBody>({
      requestType: Constants.requestTypes.DELETE_LIBRARY_FOLDER,
      method: 'DELETE',
      path: '/library/folders/:id',
      pathParams: { id },
    }).promise;
  }

  /**
   * Removes entries from folders
   * @param {AddLibraryEntryBulkRequestRecord} newEntry - the entry to be created
   * @returns {Promise}
   */
  removeEntriesFromFolders(props: {
    paperIds: Immutable.List<string>;
    folderIds: Immutable.List<number>;
  }): Promise<ApiResponse<RemoveEntriesFromFoldersResponseBody>> {
    return this.apiRequest<RemoveEntriesFromFoldersResponseBody>({
      requestType: Constants.requestTypes.REMOVE_ENTRIES_FROM_FOLDERS,
      method: 'POST',
      path: '/library/entries/remove-folders',
      data: {
        ...props,
      },
    }).promise;
  }

  /**
   * Get recommendations for Research Homepage
   * @param {Immutable.Set} folderIds - folders to extract recommendations for
   * @param {number} pageSize - total number of recommendations of each folder
   * @returns {Promise}
   */
  getRecommendationsForResearchHome({
    folderIds,
    pageSize,
  }: {
    folderIds: Immutable.Set<number>;
    pageSize?: DEPRECATED__FlowOptional<number>;
  }): Promise<ApiResponse<GetLibraryFolderRecommendationsResponseBody>> {
    return this._getLibraryFoldersRecsWithType(
      Constants.requestTypes.GET_RECOMMENDATIONS_FOR_RESEARCH_HOME,
      folderIds,
      Math.floor(Date.now() / 1000),
      {
        limitPerFolder: pageSize == null ? 5 : pageSize,
        dayLimit: 1,
      }
    );
  }

  fetchAuthorCorrectionsForAuthor({
    authorId,
  }: {
    authorId: number;
  }): Promise<ApiResponse<AuthorCorrectionsForAuthorResponseBody>> {
    if (this._currentAuthorCorrectionsRequest) {
      this._currentAuthorCorrectionsRequest.cancel();
    }
    const request = this.apiRequest<AuthorCorrectionsForAuthorResponseBody>({
      requestType: Constants.requestTypes.FETCH_AUTHOR_CORRECTIONS,
      method: 'GET',
      path: '/edit/author/:authorId',
      pathParams: { authorId },
    });
    this._currentAuthorCorrectionsRequest = request;
    return request.promise;
  }

  fetchPaperAuthorCorrectionsForAuthor({
    authorId,
    includePaperModel,
  }: {
    authorId: number;
    includePaperModel: PaperModelName;
  }): Promise<ApiResponse<PaperAuthorCorrectionsForAuthorResponseBody>> {
    if (this._currentPaperAuthorCorrectionsRequest) {
      this._currentPaperAuthorCorrectionsRequest.cancel();
    }
    const request = this.apiRequest<PaperAuthorCorrectionsForAuthorResponseBody>({
      requestType: Constants.requestTypes.FETCH_PAPER_AUTHOR_CORRECTIONS,
      method: 'GET',
      path: '/edit/author/:authorId/authorship',
      pathParams: { authorId },
      data: { includePaperModel },
    });
    this._currentPaperAuthorCorrectionsRequest = request;
    return request.promise;
  }

  fetchPapersWithCorrectionsAppliedForAuthor(props: {
    authorId: number;
    filterText?: DEPRECATED__FlowOptional<string>;
    model?: DEPRECATED__FlowOptional<PaperModelName>;
  }): Promise<ApiResponse<PapersWithCorrectionsAppliedForAuthorResponseBody>> {
    const {
      authorId,
      filterText = null,
      model = 'PaperLite', // can be "Paper", "PaperId", or "PaperLite"
    } = props;
    if (this._currentPapersWithCorrectionsAppliedForAuthorRequest) {
      this._currentPapersWithCorrectionsAppliedForAuthorRequest.cancel();
    }
    const request = this.apiRequest<PapersWithCorrectionsAppliedForAuthorResponseBody>({
      requestType: Constants.requestTypes.FETCH_PAPERS_WITH_CORRECTIONS_APPLIED,
      method: 'POST',
      path: '/edit/author/:authorId/papers-with-pending-corrections',
      pathParams: { authorId },
      data: { filterText, model },
    });
    this._currentPapersWithCorrectionsAppliedForAuthorRequest = request;
    return request.promise;
  }

  /**
   * Gets list of experiments that are defined and their status
   * @returns {Promise}
   */
  getExperiments(): Promise<ApiResponse<GetExperimentsResponseBody>> {
    return this.apiRequest<GetExperimentsResponseBody>({
      requestType: Constants.requestTypes.GET_EXPERIMENTS,
      method: 'GET',
      path: '/weblab/experiments',
    }).promise;
  }

  /**
   * Gets list of experiments that are defined and their status
   * @returns {Promise}
   */
  getExperimentAudit(experimentKey: string): Promise<ApiResponse<GetExperimentsResponseBody>> {
    return this.apiRequest<GetExperimentsResponseBody>({
      requestType: Constants.requestTypes.GET_EXPERIMENTS,
      method: 'GET',
      path: '/weblab/experiments/audit/:experimentKey',
      pathParams: { experimentKey },
    }).promise;
  }

  setExperimentState(
    data: TODO<'SetExperimentStateRequest'>
  ): Promise<ApiResponse<TODO<'SetExperimentStateResponse'>>> {
    return this.apiRequest<TODO<'SetExperimentStateResponse'>>({
      requestType: Constants.requestTypes.SET_EXPERIMENT_STATE,
      method: 'POST',
      path: '/weblab/experiments',
      data,
    }).promise;
  }

  /**
   * Gets list of feature flags that are defined and their status
   * @returns {Promise}
   */
  getFeatureFlags(): Promise<ApiResponse<GetFeatureFlagsResponseBody>> {
    return this.apiRequest<GetFeatureFlagsResponseBody>({
      requestType: Constants.requestTypes.GET_FEATURE_FLAGS,
      method: 'GET',
      path: '/weblab/feature_flags',
    }).promise;
  }

  setFeatureFlagState(
    data: TODO<'SetFeatureFlagStateRequest'>
  ): Promise<ApiResponse<TODO<'SetFeatureFlagStateResponseBody'>>> {
    return this.apiRequest<TODO<'SetFeatureFlagStateResponseBody'>>({
      requestType: Constants.requestTypes.SET_FEATURE_FLAG_STATE,
      method: 'POST',
      path: '/weblab/feature_flags',
      data,
    }).promise;
  }

  /**
   * Exposes a user to an experiment based on their user id
   *
   * @returns {Promise}
   */
  exposeUser({
    experimentKey,
  }: {
    experimentKey: ExperimentKey;
  }): Promise<ApiResponse<ExposeUserResponseBody>> {
    return this.apiRequest<ExposeUserResponseBody>({
      requestType: Constants.requestTypes.USER_EXPOSE_TO_EXPERIMENT,
      method: 'POST',
      path: '/experiment/expose',
      data: { experimentKey },
    }).promise;
  }

  /**
   * Gets a user's exposure record for an experiment
   *
   * @returns {Promise}
   */
  getUserExposure({
    experimentKey,
  }: {
    experimentKey: ExperimentKey;
  }): Promise<ApiResponse<GetUserExposureResponseBody>> {
    return this.apiRequest<GetUserExposureResponseBody>({
      requestType: Constants.requestTypes.GET_USER_EXPOSURE,
      method: 'GET',
      path: '/experiment/:experimentKey/exposure',
      pathParams: { experimentKey },
    }).promise;
  }

  recentArxivPubDateHistogram(
    daysBack: number
  ): Promise<ApiResponse<RecentArxivPubDateHistogramResponseBody>> {
    return this.apiRequest<RecentArxivPubDateHistogramResponseBody>({
      requestType: Constants.requestTypes.RECENT_ARXIV_PUB_DATE,
      method: 'GET',
      path: '/search/recent-arxiv-pubdates',
      data: { daysBack },
    }).promise;
  }

  async getAllUserSettings(): Promise<ApiResponse<GetAllUserSettingsResponseBody>> {
    return this.apiRequest<GetAllUserSettingsResponseBody>({
      requestType: Constants.requestTypes.GET_ALL_USER_SETTINGS,
      method: 'GET',
      path: '/user/settings',
    }).promise;
  }

  async getUserSettingByKey(
    key: UserSettingKey
  ): Promise<ApiResponse<GetUserSettingByKeyResponseBody>> {
    return this.apiRequest<GetUserSettingByKeyResponseBody>({
      requestType: Constants.requestTypes.GET_USER_SETTING_BY_KEY,
      method: 'GET',
      path: '/user/settings/:key',
      pathParams: { key },
    }).promise;
  }

  async createUserSetting(
    key: UserSettingKey,
    value: UserSettingValue
  ): Promise<ApiResponse<CreateUserSettingResponseBody>> {
    return this.apiRequest<CreateUserSettingResponseBody>({
      requestType: Constants.requestTypes.CREATE_USER_SETTING,
      method: 'POST',
      path: '/user/settings',
      data: { key, value },
    }).promise;
  }

  async updateUserSetting(
    key: UserSettingKey,
    value: UserSettingValue
  ): Promise<ApiResponse<UpdateUserSettingResponseBody>> {
    return this.apiRequest<UpdateUserSettingResponseBody>({
      requestType: Constants.requestTypes.UPDATE_USER_SETTING,
      method: 'PUT',
      path: '/user/settings',
      data: { key, value },
    }).promise;
  }

  async deleteAllUserSettings(): Promise<ApiResponse<DeleteUserSettingsResponseBody>> {
    return this.apiRequest<DeleteUserSettingsResponseBody>({
      requestType: Constants.requestTypes.DELETE_ALL_USER_SETTINGS,
      method: 'DELETE',
      path: '/user/settings',
    }).promise;
  }

  async deleteUserSettingByKey(
    key: UserSettingKey
  ): Promise<ApiResponse<DeleteUserSettingByKeyResponseBody>> {
    return this.apiRequest<DeleteUserSettingByKeyResponseBody>({
      requestType: Constants.requestTypes.DELETE_USER_SETTING_BY_KEY,
      method: 'DELETE',
      path: '/user/settings/:key',
      pathParams: { key },
    }).promise;
  }

  async mergeUsers({
    primaryUserId,
    secondaryUserId,
  }: {
    primaryUserId: number;
    secondaryUserId: number;
  }): Promise<ApiResponse<MergeUsersResponseBody>> {
    return this.apiRequest<MergeUsersResponseBody>({
      requestType: Constants.requestTypes.MERGE_USERS,
      method: 'POST',
      path: '/user/:primaryUserId/merge',
      pathParams: { primaryUserId },
      data: { secondaryUserId },
    });
  }

  _getEntrySourceTypeFilterQuery(): string {
    const sourceTypes = [AuthorLibraryFolder, Library, Feed];
    return sourceTypes
      .map(sourceType => {
        return `${qs.stringify({ entrySourceTypeFilter: sourceType })}`;
      })
      .join('&');
  }

  _getFolderSourceTypeFilterQuery(): string {
    const sourceTypes = LibraryVisibleFolderTypes;
    return sourceTypes
      .map(sourceType => {
        return `${qs.stringify({ folderSourceTypeFilter: sourceType })}`;
      })
      .join('&');
  }
}
