import React from 'react';

export default function IconCheckmark(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 36 36">
      <path
        d="M0.53,19.2c-0.7-0.7-0.7-1.84,0-2.55l2.55-2.55c0.7-0.7,1.84-0.7,2.55,0l7.88,7.88L30.38,5.1c0.7-0.7,1.84-0.7,2.55,0l2.55,2.55c0.7,0.7,0.7,1.84,0,
        2.55l-20.7,20.7c-0.7,0.7-1.84,0.7-2.54,0L0.53,19.2z"
      />
    </symbol>
  );
}
