import LoginMethods from './LoginMethods';

import { COGNITO, FORGOT_PASSWORD, SIGN_IN } from '@/constants/LoginMethods';
import { fetchUserData } from '@/actions/UserActionCreators';
import { getString } from '@/content/i18n';
import { handleRedirect } from '@/router/clientUtils';
import { S2LogoFull } from '@/components/shared/s2Logos';
import { searchStringToObj } from '@/router/routerUtils';
import Api from '@/api/Api';
import AuthApi, { LoginCancelledError, LoginFailedError, LoginForbiddenError } from '@/api/AuthApi';
import AuthEvent from '@/analytics/models/AuthEvent';
import AuthStore from '@/stores/AuthStore';
import GraphQLApi from '@/api/GraphQLApi';
import Icon from '@/components/shared/icon/Icon';
import Link from '@/router/Link';
import Page from '@/components/shared/layout/Page';
import Redirect from '@/models/redirects/Redirect';
import Routes from '@/router/Routes';
import S2History from '@/utils/S2History';
import S2Redirect from '@/models/redirects/S2Redirect';
import softError from '@/utils/softError';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';
import WeblabStore from '@/weblab/WeblabStore';

import { matchRoutes } from 'react-router-config';
import idx from 'idx';
import PropTypes from 'prop-types';
import React from 'react';

export default class SignInPage extends React.Component {
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    authApi: PropTypes.instanceOf(AuthApi).isRequired,
    authStore: PropTypes.instanceOf(AuthStore).isRequired,
    history: PropTypes.instanceOf(S2History).isRequired,
    graphQLApi: PropTypes.instanceOf(GraphQLApi).isRequired,
    weblabStore: PropTypes.instanceOf(WeblabStore).isRequired,
  };

  state = {
    loginErrorMsg: null,
  };

  componentDidMount() {
    trackAnalyticsEvent(
      AuthEvent.create({
        state: AuthEvent.State.RENDERED,
      })
    );
  }

  openSignInModal = () => {
    const { history } = this.context;
    const route = this.props.route;
    const currentUrl = history.location.pathname + history.location.search;
    const redirectUrl = route
      ? idx(matchRoutes([route], history.location.pathname), _ => _[0].route.path)
      : currentUrl;
    const query = { signIn: true };

    if (redirectUrl !== Routes.SIGN_IN) {
      query.redirect = redirectUrl;
    }

    const redirect = new S2Redirect({
      routeName: 'HOME',
      query: query, // pass redirect url to login modal
      replace: true,
      status: 302,
    });

    handleRedirect({ redirect, history });
  };

  // if there is a `next` param, then redirect there
  // if a user navigated directly to the sign in route with no redirectUrl param, redirect them to the homepage after successful login
  // and if the user tried to navigate directly to an auth restricted route such as
  // /me/account/alerts but got interrupted by the sign in page, navigate them to their initially intended path after sign in
  getRedirectObj() {
    const { history } = this.context;
    const currentUrl = history.location.pathname + history.location.search;
    const query = searchStringToObj(history.location.search);

    // Should complete feature enrollment/withdrawal process after sign in/up/out
    if (query.enrollment) {
      return new Redirect({
        url: currentUrl,
        status: 302,
      });
    }

    if (query.next) {
      return new Redirect({ url: query.next, status: 302 });
    }

    const route = this.props.route;
    const redirectPath = route
      ? idx(matchRoutes([route], history.location.pathname), _ => _[0].route.path)
      : history.location.pathname;

    if (redirectPath === Routes.SIGN_IN) {
      return new S2Redirect({
        routeName: 'HOME',
        replace: true,
        status: 302,
      });
    }

    const redirectUrl = route
      ? idx(matchRoutes([route], history.location.pathname), _ => _[0].route.path)
      : currentUrl;

    return new Redirect({
      url: redirectUrl,
    });
  }

  onRequestLogin = args => {
    return this.login(args).catch(error => {
      softError('login', 'login had an uncaught error', error);
    });
  };

  async login({ method, action, email, password }) {
    const { api, authApi, authStore, history, graphQLApi, weblabStore } = this.context;

    // Data to be logged
    const analyticsData = {
      method,
      action,
    };

    const redirect = this.getRedirectObj();

    // Attempt login
    try {
      if (method === COGNITO && action === SIGN_IN) {
        if (!email || !password) {
          this.setState({
            loginErrorMsg: getString(_ => _.login.error.missingEmailPassword),
          });
          return;
        }
        trackAnalyticsEvent(
          AuthEvent.create({
            ...analyticsData,
            state: AuthEvent.State.STARTED,
          })
        );
        await authApi.loginViaPassword(method, email, password);
      } else if (action === FORGOT_PASSWORD) {
        trackAnalyticsEvent(
          AuthEvent.create({
            ...analyticsData,
            state: AuthEvent.State.STARTED,
          })
        );
        await authApi.loginViaForgotPassword(method);
      } else {
        trackAnalyticsEvent(
          AuthEvent.create({
            ...analyticsData,
            state: AuthEvent.State.STARTED,
          })
        );
        await authApi.loginViaOAuth(method);
      }
    } catch (error) {
      this.handleLoginError(error, method, action, analyticsData);
      return;
    }

    // Track completed login
    trackAnalyticsEvent(
      AuthEvent.create({
        ...analyticsData,
        state: AuthEvent.State.SUCCEEDED,
      })
    );

    await fetchUserData({ api, authStore, graphQLApi, weblabStore });
    handleRedirect({ history, redirect });
  }

  // Since there are lots of ways login can fail, handle them all in one place
  handleLoginError(error, method, action, analyticsData) {
    // A technical error occurred during login
    if (error instanceof LoginFailedError) {
      trackAnalyticsEvent(
        AuthEvent.create({
          ...analyticsData,
          state: AuthEvent.State.FAILED,
        })
      );
      this.setState({
        loginErrorMsg: getString(_ => _.login.error.networkFailure),
      });
      return;
    }

    // User cancelled login during provider's auth flow
    if (error instanceof LoginCancelledError) {
      trackAnalyticsEvent(
        AuthEvent.create({
          ...analyticsData,
          state: AuthEvent.State.CANCELLED,
        })
      );
      this.setState({
        loginErrorMsg: getString(_ => _.login.error.cancelledByUser),
      });
      return;
    }

    // Provider determined login is not allowed
    if (error instanceof LoginForbiddenError) {
      trackAnalyticsEvent(
        AuthEvent.create({
          ...analyticsData,
          state: AuthEvent.State.FORBIDDEN,
        })
      );
      this.setState({
        loginErrorMsg: getString(_ => _.login.error.forbiddenByProvider),
      });
      return;
    }

    // Something else failed, but we don't know what it is
    trackAnalyticsEvent(
      AuthEvent.create({
        ...analyticsData,
        state: AuthEvent.State.FAILED,
      })
    );
    this.setState({
      loginErrorMsg: getString(_ => _.login.error.unknownError),
    });
  }

  render() {
    const { loginErrorMsg } = this.state;

    return (
      <Page header={false} footer={false}>
        <div className="sign-in" data-test-id="sign-in-page">
          <div className="sign-in__left-column">
            <Link to="HOME" aria-label={getString(_ => _.appHeader.logoAriaLabel)}>
              <S2LogoFull />
            </Link>
            <div className="sign-in__login-methods-container" data-test-id="reading-list-sign-in">
              <h1 className="sign-in__header-text">
                {getString(_ => _.login.staticLoginPage.signInHeaderText)}
              </h1>
              {loginErrorMsg && (
                <div className="sign-in__error">
                  <div className="sign-in__error__icon">
                    <Icon icon="status-warning" />
                  </div>
                  <div className="sign-in__error__msg">{loginErrorMsg}</div>
                </div>
              )}
              <p className="sign-in__create-account-text">
                {getString(_ => _.login[SIGN_IN].switchLoginTypeLinkLabel)}
                <button
                  className="sign-in__login-link"
                  onClick={this.openSignInModal}
                  data-test-id="create-account">
                  {getString(_ => _.login[SIGN_IN].switchLoginTypeLinkText)}
                </button>
              </p>
              <LoginMethods onRequestLogin={this.onRequestLogin} action={SIGN_IN} />
            </div>
          </div>
          <div className="sign-in__right-column">
            <h2 className="sign-in__description-header">
              {getString(_ => _.login.descriptionHeader)}
            </h2>
            <p className="sign-in__description">{getString(_ => _.login.descriptionText)}</p>
            <iframe
              id="cognito-signout-iframe"
              aria-hidden="true"
              title="Intentionally blank"
              className="cognito-signout__iframe"
              tabIndex="-1"
            />
          </div>
        </div>
      </Page>
    );
  }
}
