import ManageAlertsLink from './ManageAlertsLink';

import { getAlertSuccessMessage } from '@/utils/alert-util';
import { getString } from '@/content/i18n';
import { hideModal } from '@/actions/ModalActionCreators';
import { ModalId } from '@/constants/Modal';
import AlertsStore from '@/stores/AlertsStore';
import Api from '@/api/Api';
import AuthStore from '@/stores/AuthStore';
import Checkbox from '@/components/shared/common/form/Checkbox';
import CLButton, { TYPE } from '@/components/library/button/CLButton';
import CLModal from '@/components/library/modal/CLModal';
import EventTarget from '@/analytics/constants/EventTarget';
import Input from '@/components/shared/common/form/Input';
import MessageStore from '@/stores/MessageStore';
import S2Dispatcher from '@/utils/S2Dispatcher';
import S2History from '@/utils/S2History';
import softError from '@/utils/softError';
import SubmitEvent from '@/analytics/models/SubmitEvent';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import invariant from 'invariant';
import PropTypes from 'prop-types';
import React from 'react';

export default class CreateAlertModal extends React.PureComponent {
  static contextTypes = {
    alertsStore: PropTypes.instanceOf(AlertsStore).isRequired,
    api: PropTypes.instanceOf(Api).isRequired,
    authStore: PropTypes.instanceOf(AuthStore).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    history: PropTypes.instanceOf(S2History).isRequired,
    messageStore: PropTypes.instanceOf(MessageStore).isRequired,
  };

  state = {
    email: '',
    isLoading: true,
    isSubmitting: false,
    resendVerificationEmail: false,
    submitted: false,
    success: false,
    error: null,
  };

  /* eslint-disable react/no-did-mount-set-state */
  componentDidMount() {
    const { alertsStore } = this.context;
    const { newAlert } = alertsStore;
    invariant(
      newAlert,
      'There must be a newAlert in the alertsStore to render the CreateAlertsPage'
    );
    const { queryType, queryValue } = newAlert;

    setTimeout(
      () =>
        Promise.all([this.context.api.findAlertByQueryTypeAndValue(queryType, queryValue)]).then(
          () => {
            this.setState({ isLoading: false });
          }
        ),
      0
    );
  }

  handleEmailChange = email => {
    this.setState({ email });
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.setState({ isSubmitting: true });

    const { api, alertsStore, authStore } = this.context;

    const { newAlert } = alertsStore;
    invariant(
      newAlert,
      'There must be a newAlert in the alertsStore to render the CreateAlertsPage'
    );
    const { id: alertId, queryType: alertType } = newAlert;
    const { email, resendVerificationEmail } = this.state;
    const { CREATE_SUCCESS, CREATE_FAILURE } = EventTarget.UserAlerts;

    try {
      if (email) {
        await api.updateUser({ alertEmail: email });
      } else if (resendVerificationEmail) {
        await api.resendVerificationEmail({ email: authStore.getAlertEmail() });
      }

      await api.createAlert(newAlert);
      trackAnalyticsEvent(SubmitEvent.create(CREATE_SUCCESS, { alertId, alertType }));
      this.setState({
        success: true,
        error: null,
        isSubmitting: false,
        submitted: true,
      });
    } catch (error) {
      trackAnalyticsEvent(
        SubmitEvent.create(CREATE_FAILURE, {
          alertId,
          alertType,
          status: error.status,
          error: error.error,
        })
      );

      this.setState({
        error: error || 'There was a problem with your request',
        success: false,
        isSubmitting: false,
        submitted: true,
      });
    }
  };

  handleCancel = () => {
    const { history } = this.context;
    history.goBack();
  };

  handleResendVerification = bool => {
    this.setState(() => ({
      resendVerificationEmail: bool,
    }));
  };

  renderAlertForm() {
    const { email, isSubmitting, resendVerificationEmail } = this.state;
    const { authStore, alertsStore } = this.context;
    const { newAlert } = alertsStore;
    invariant(
      newAlert,
      'There must be a newAlert in the alertsStore to render the CreateAlertsPage'
    );

    const { queryType, displayValue } = newAlert;
    const { alertEmail, alertEmailIsVerified } = authStore.getUser() || {};

    const description = getString(_ => _.alerts.create[queryType].description);

    return (
      <form className="create-alert-content" onSubmit={this.handleSubmit}>
        <section className="margin-tb-med">
          <p className="margin-tb-med">{description}</p>
          <p className="display-text">
            <strong>{displayValue}</strong>
          </p>
          {!alertEmail ? (
            <Input
              isRequired
              label="Your email"
              placeholder="example@email.com"
              value={email}
              className="alert-email-input"
              testId="alert-email-input"
              onChange={this.handleEmailChange}
            />
          ) : null}
          {!alertEmailIsVerified && alertEmail ? (
            <Checkbox
              className="margin-tb-med"
              label={getString(_ => _.alerts.create.form.resendVerificationLabel)}
              checkboxText={getString(_ => _.alerts.create.form.resendVerificationText)}
              value={resendVerificationEmail}
              onChange={this.handleResendVerification}
            />
          ) : null}
        </section>
        <section className="form-buttons">
          <button
            data-test-id="create-alert-form-submit-button"
            className="button button--lg button--primary create-alert-button"
            type="submit"
            disabled={isSubmitting}>
            {getString(_ => _.alerts.create.form.buttonText)}
          </button>
          <CLButton
            type={TYPE.TERTIARY}
            onClick={this.onClickCloseModal}
            label={getString(_ => _.alerts.create.form.cancelButtonText)}
          />
        </section>
      </form>
    );
  }

  renderSuccess() {
    const { messageStore, alertsStore, authStore } = this.context;
    const { newAlert } = alertsStore;
    // $FlowFixMe: Next line causes error when flow typing was added to context (See: #22776)
    const { queryType, displayValue } = newAlert;

    this.onClickCloseModal();
    // Show success message
    const header = getString(_ => _.alerts.create[queryType].createdTitle);
    // $FlowFixMe: Next line causes error when flow typing was added to context (See: #22776)
    const body = getAlertSuccessMessage(queryType, displayValue, authStore.getAlertEmail());
    messageStore.addSuccess(body, header, <ManageAlertsLink />);
  }

  renderError() {
    const { messageStore } = this.context;
    this.onClickCloseModal();

    const { error } = this.state;
    softError('createAlertModal.renderError', 'An error caused the alert not to be created', error);

    if (error && error.errorCode === 'UserAlertLimitExceeded') {
      const header = getString(_ => _.alerts.create.message.limitError.header);
      const body = getString(_ => _.alerts.create.message.limitError.body);
      messageStore.addError(body, header, <ManageAlertsLink />);
    } else {
      const header = getString(_ => _.alerts.create.message.error.header);
      const body = getString(_ => _.alerts.create.message.error.body);
      messageStore.addError(body, header, <ManageAlertsLink />);
    }
  }

  renderPostRequestState() {
    return this.state.error ? this.renderError() : this.renderSuccess();
  }

  onClickCloseModal = () => {
    this.context.dispatcher.dispatch(hideModal());
  };

  render() {
    const { submitted } = this.state;
    const { alertsStore } = this.context;

    const { newAlert } = alertsStore;
    invariant(
      newAlert,
      'There must be a newAlert in the alertsStore to render the CreateAlertsPage'
    );
    const { queryType } = newAlert;

    return (
      <CLModal modalId={ModalId.CREATE_ALERT} className="alert-modal">
        <div className="alert-modal__content">
          <div className="alert-modal__header">
            <h1 className="alert-modal__header-text">
              {getString(_ => _.alerts.create[queryType].title)}
            </h1>
          </div>
          <div className="alert-modal__alert-information">
            {!submitted ? this.renderAlertForm() : this.renderPostRequestState()}
          </div>
        </div>
      </CLModal>
    );
  }
}
