import { getString } from '@/content/i18n';
import { heapReaderButtonPaperRow } from '@/analytics/attributes/reader';
import { Nullable } from '@/utils/types';
import CLTooltip, { PLACEMENT as TOOLTIP_PLACEMENT } from '@/components/library/tooltip/CLTooltip';
import IconButton from '@/components/shared/common/button/IconButton';
import Link from '@/router/Link';

import classnames from 'classnames';
import React from 'react';

type Props = {
  paperId: string;
  className?: Nullable<string>;
};

export default function CLPaperReaderButton({ className, paperId }: Props) {
  return (
    <CLTooltip
      placement={TOOLTIP_PLACEMENT.BOTTOM}
      id="semantic-reader-tooltip"
      tooltipContent={getString(_ => _.paperDetail.viewReaderButtonTooltip)}>
      <Link
        to="READER"
        params={{ paperId }}
        shouldUnderline={false}
        shouldOpenInNewTab={true}
        aria-describedby="cl-paper-reader-button-tooltip"
        data-test-id="cl-paper-reader-button"
        aria-label={getString(_ => _.paperDetail.readerButtonAriaLabel)}
        className="cl-paper-action__reader-link">
        <IconButton
          tagName="a"
          className={classnames(
            'cl-paper-action__reader-button cl-paper-action__reader-button--square-corner',
            className
          )}
          iconProps={{
            height: '14',
            width: '14',
            icon: 'semantic-reader',
            className: 'cl-paper-action__reader-button-icon',
          }}
          text={getString(_ => _.paperDetail.viewReaderButtonWithoutSemanticReaderSuffix)}
          testId="cl-paper-reader-button"
          {...heapReaderButtonPaperRow()}
        />
      </Link>
    </CLTooltip>
  );
}
