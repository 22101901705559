import { DEPRECATED__FlowOptional, Nullable } from '@/utils/types';

export type CompletionStatusKey = keyof typeof byKey;
export type CompletionStatusValue = (typeof byKey)[CompletionStatusKey];

const byKey = {
  Submitted: 'Submitted',
  Cancelled: 'Cancelled',
  Processing: 'Processing',
  Failed: 'Failed',
  Succeeded: 'Succeeded',
} as const;

// TODO: TS isn't sure what to make of this type
const byValue = Object.keys(byKey).reduce((memo, key) => {
  const value = byKey[key];
  memo[value] = key;
  return memo;
}, {});

export default Object.freeze(byKey);

export const CompletionStatusByValue = Object.freeze(byValue);

export function getCompletionStatusValue(value: string): CompletionStatusValue {
  if (value in byValue) {
    return value as CompletionStatusValue;
  }
  return byKey.Failed;
}

export function optCompletionStatusValue(
  value: DEPRECATED__FlowOptional<string>
): Nullable<CompletionStatusValue> {
  if (!value) {
    return null;
  }
  return getCompletionStatusValue(value);
}
