import { ReactNodeish } from './types';

import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

// The prettier version we have doesn't know how to deal with `type RouteComponentProps` in the other import
// so we need a duplicate import otherwise we can't actually import it
// when we upgrade prettier we can change this
// eslint-disable-next-line
import type { RouteComponentProps } from 'react-router-dom';

type ScrollToTopProps = {} & RouteComponentProps;

// NOTE: If we want to add functionality to ignore certain routes there's a few ways we can go about it
// We can use hashes on those routes if that works how we want with native browser functionality
// We can add a list of routes to ignore and check the location/rest of the route to see if it matches

function ScrollToTop({ location }: ScrollToTopProps): ReactNodeish {
  useEffect(() => {
    if (window == null) {
      return;
    }

    // If there's a hash in the location we want to scroll to that instead of the top
    // the browser will handle this for us automatically so we can exit early
    if (location.hash) {
      return;
    }

    window.scrollTo({ top: 0, left: 0 });
  }, [location]);

  return null;
}

export default withRouter(ScrollToTop);
