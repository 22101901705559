import { CoAuthorshipRecord, getCoAuthorshipFromGraphQL } from '@/models/author/CoAuthorship';
import { FetchAuthorCoAuthorsPageResponse } from '@/actions/AuthorActionCreators';
import { getAuthorIdAsNumber } from '@/utils/author-utils';
import { getParamsForRoute, getRouteNameForPath } from '@/router/Routes';
import { Nullable } from '@/utils/types';
import BaseStore from '@/stores/BaseStore';
import Constants from '@/constants';
import S2Dispatcher from '@/utils/S2Dispatcher';
import softError from '@/utils/softError';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import Immutable from 'immutable';

import type { ApiRequestStartingPayload } from '@/api/BaseApi';
import type { ApiResponse } from '@/api/ApiResponse';
import type { GraphQLResponse } from '@/api/GraphQLApi';
import type { RoutingPayload } from '@/actions/RouterActionCreators';

export const MAX_CO_AUTHORS_PER_PAGE = 20;

export default class AuthorCoAuthorsStore extends BaseStore {
  #state: StoreStateValue;
  #authorId: Nullable<number>;
  #numCoAuthors: number;
  #endCursorForPage: Immutable.Map<number, string>;
  #authorsByPage: Immutable.Map<number, Immutable.List<CoAuthorshipRecord>>;
  #pageNum: number;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#state = StoreState.UNINITIALIZED;
    this.#reset();

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.ROUTING: {
          const routingPayload = payload as RoutingPayload;
          this.#handleRouting(routingPayload);
          return;
        }
        case Constants.actions.API_REQUEST_STARTING: {
          const apiStartingPayload = payload as ApiRequestStartingPayload;
          switch (apiStartingPayload.requestType) {
            case Constants.requestTypes.GQL__AUTHOR_CO_AUTHORS: {
              return this.#handleAuthorsStart(apiStartingPayload);
            }
          }
          return;
        }
        case Constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.GQL__AUTHOR_CO_AUTHORS: {
              return this.#handleAuthorsComplete(apiResponse);
            }
          }
          return;
        }
      }
    });
  }

  #reset(): void {
    this.#authorId = null;
    this.#endCursorForPage = Immutable.Map();
    this.#numCoAuthors = 0;
    this.#authorsByPage = Immutable.Map();
    this.#pageNum = 1;
  }

  #handleRouting(payload: RoutingPayload): void {
    let hasChanged = false;
    const routeName = getRouteNameForPath(payload.state.path);

    // Reset store if author has changed
    if (routeName?.startsWith('AUTHOR_PROFILE')) {
      const [, authorIdStr] = getParamsForRoute(routeName, payload.state.path);
      if (authorIdStr !== this.#authorId?.toString()) {
        this.#reset();
        hasChanged = true;
      }
    }

    // Check for a change to the page number
    if (routeName === 'AUTHOR_PROFILE_CO_AUTHORS') {
      const { page } = payload.state.query || {};
      const pageNum = page ? parseInt(page, 10) : 1;
      if (this.hasAuthorsForPage(pageNum)) {
        this.#pageNum = pageNum;
        hasChanged = true;
      }
    }

    if (hasChanged) {
      this.emitChange();
    }
  }

  #handleAuthorsStart(payload: ApiRequestStartingPayload): void {
    let hasChanged = false;

    const authorId = payload.context?.variables?.authorId || null;
    if (authorId && this.#authorId !== authorId) {
      this.#reset();
      this.#authorId = authorId;
      hasChanged = true;
    }

    if (this.#state !== StoreState.LOADING) {
      this.#state = StoreState.LOADING;
      hasChanged = true;
    }

    if (hasChanged) {
      this.emitChange();
    }
  }

  #handleAuthorsComplete(
    payload: ApiResponse<GraphQLResponse<FetchAuthorCoAuthorsPageResponse>>
  ): void {
    const authorId = payload.context?.variables?.authorId;
    const pageNumber: number = payload.context.pageNumber;
    if (this.#authorId !== null && this.#authorId !== authorId) {
      // A request has come in out of order
      return;
    }

    const author = payload.resultData.data?.author;
    if (!author) {
      softError(
        'AuthorCoAuthorsStore',
        `author [id="${authorId}"] did not return data from GraphQL`
      );
      return;
    }

    this.#state = StoreState.LOADED;
    this.#authorId = authorId;
    this.#numCoAuthors = author.coAuthorCount;
    this.#endCursorForPage = this.#endCursorForPage.set(
      pageNumber,
      author.coAuthors.pageInfo.endCursor
    );
    this.#pageNum = pageNumber;
    const coAuthorList = Immutable.List(
      author.coAuthors.edges.map(_ => getCoAuthorshipFromGraphQL(_.node))
    );
    this.#authorsByPage = this.#authorsByPage.set(pageNumber, coAuthorList);

    this.emitChange();
  }

  isUninitialized(): boolean {
    return this.#state === StoreState.UNINITIALIZED;
  }

  isLoading(): boolean {
    return this.isUninitialized() || this.#state === StoreState.LOADING;
  }

  isAuthorLoaded(authorId: number | string): boolean {
    if (this.isLoading()) {
      return false;
    }
    return this.#authorId === getAuthorIdAsNumber(authorId);
  }

  hasAuthorsForPage(pageNum: number): boolean {
    return this.#authorsByPage.has(pageNum);
  }

  getCoAuthorsForPage(pageNum: number): Immutable.List<CoAuthorshipRecord> {
    return this.#authorsByPage.get(pageNum) || Immutable.List();
  }

  getCoAuthorsForCurrentPage(): Immutable.List<CoAuthorshipRecord> {
    return this.getCoAuthorsForPage(this.#pageNum);
  }

  getPageNumber(): number {
    return this.#pageNum;
  }

  getTotalPages(): number {
    if (this.#numCoAuthors <= 0) {
      return 0;
    }
    return Math.ceil(this.#numCoAuthors / MAX_CO_AUTHORS_PER_PAGE);
  }

  getEndCursorForPage(pageNum: number): Nullable<string> {
    return this.#endCursorForPage.get(pageNum) || null;
  }
}
