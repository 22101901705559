export type PaperLinkTypeValue = (typeof PaperLinkType)[keyof typeof PaperLinkType];

const PaperLinkType = {
  ACL: 'acl',
  ACM: 'acm',
  ANANSI: 'anansi',
  ARXIV: 'arxiv',
  DBLP: 'dblp',
  DOI: 'doi',
  EDUCAUSE: 'educause',
  IEEE: 'ieee',
  INSTITUTIONAL_ACCESS: 'institutional',
  MEDLINE: 'medline',
  OPEN_ACCESS: 'openaccess',
  PUBLISHER: 'publisher',
  S2: 's2',
  WILEY: 'wiley',
} as const;

Object.freeze(PaperLinkType);

export default PaperLinkType;
