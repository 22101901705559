import { ReactNodeish } from '@/utils/types';

import React from 'react';

export default function IconArrowUp(props: { id?: string }): ReactNodeish {
  return (
    <symbol id={props.id} viewBox="0 0 16 24">
      <path d="M8.00869 9.80912e-08C7.98888 9.78485e-08 7.94924 0.0200006 7.92943 0.0200006C7.83034 0.0200006 7.73125 0.04 7.63217 0.08C7.59253 0.1 7.57271 0.119999 7.5529 0.139999C7.47363 0.179999 7.37454 0.219999 7.31509 0.299999L0.279966 7.36C-0.0965613 7.76 -0.0965613 8.4 0.299784 8.78C0.676311 9.16 1.31046 9.16 1.70681 8.78L6.99801 3.44L6.99801 23C6.99801 23.56 7.45381 24 8.00869 24C8.56358 24 8.99956 23.56 8.99956 23L8.99956 3.42L14.3106 8.78C14.6871 9.16 15.3213 9.16 15.7176 8.78C16.0941 8.4 16.0941 7.76 15.7176 7.36L8.7023 0.280001C8.52394 0.100001 8.26632 1.01247e-07 8.00869 9.80912e-08Z" />
    </symbol>
  );
}
