import trackAnalyticsEvents from './trackAnalyticsEvents';

import type AnalyticsEvent from './models/AnalyticsEvent';

/**
 * Post an analytics event.
 *
 * @param {AnalyticsEvent} event - The event to be sent. Must be an instance of AnalyticsEvent.
 * @param {delayMs} number - The number of millisconds to wait before sending the event
 */
export default function trackAnalyticsEvent(event: AnalyticsEvent): void {
  trackAnalyticsEvents([event]);
}
