import {
  CUE_PAPERS,
  LIBRARY_FOLDER_SETTINGS,
  ORGANIZE_PAPERS,
  RECOMMENDATIONS_SETTINGS,
  SAVE_TO_LIBRARY,
  ShelfId,
} from '@/constants/ShelfId';
import { DispatchPayload } from '@/utils/S2Dispatcher';
import { LibraryFolderRecord } from '@/models/library/LibraryFolder';
import { PaperishRecord } from '@/models/Paper';
import { ShelfContext } from '@/models/shelf/ShelfContexts';
import Constants from '@/constants';

import Immutable from 'immutable';

import type { CueType } from '@/constants/CueType';

const { HIDE_SHELF, SHOW_SHELF } = Constants.actions;

export const ShelfAction = {
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
} as const;

export type ShowShelfAction = DispatchPayload<{
  actionType: typeof SHOW_SHELF;
  id: ShelfId;
  context: ShelfContext;
}>;

export type HideShelfAction = DispatchPayload<{
  actionType: typeof HIDE_SHELF;
  id?: ShelfId;
}>;

export function hideShelf(optOldShelfId?: ShelfId): HideShelfAction {
  return {
    actionType: HIDE_SHELF,
    id: optOldShelfId,
  };
}

function showShelf(shelfId: ShelfId, context: ShelfContext): ShowShelfAction {
  return {
    actionType: SHOW_SHELF,
    id: shelfId,
    context,
  };
}

export const showCuePapersShelf = ({
  paperIds,
  cueDescription,
  cueHeader,
  prioritizedCue,
  viewCuePaperShelfEvent,
}: {
  paperIds: Immutable.List<string>;
  cueDescription: string;
  cueHeader: string;
  prioritizedCue: CueType;
  viewCuePaperShelfEvent: string;
}): ShowShelfAction =>
  showShelf(CUE_PAPERS, {
    cuePapers: {
      paperIds,
      cueDescription,
      cueHeader,
      prioritizedCue,
      viewCuePaperShelfEvent,
    },
  });

export const showLibraryFolderSettingsShelf = ({
  libraryFolderId,
}: {
  libraryFolderId: number;
}): ShowShelfAction =>
  showShelf(LIBRARY_FOLDER_SETTINGS, {
    libraryFolderSettings: {
      libraryFolderId,
    },
  });

export const showSaveToLibraryShelf = ({
  paper,
  selectedFolders,
}: {
  paper: PaperishRecord;
  selectedFolders: Immutable.Set<LibraryFolderRecord>;
}): ShowShelfAction => {
  return showShelf(SAVE_TO_LIBRARY, {
    saveToLibrary: { paper, selectedFolders },
  });
};

export const showOrganizePapersShelf = ({
  paperId,
  paperTitle,
}: {
  paperId: string;
  paperTitle: string;
}): ShowShelfAction =>
  showShelf(ORGANIZE_PAPERS, {
    organizePapers: { paperId, paperTitle },
  });

export const showRecommendationsSettingsShelf = (): ShowShelfAction =>
  showShelf(RECOMMENDATIONS_SETTINGS, {});
