import { redirectToPaperDetailAbstract } from '@/components/shared/paper-detail/PaperDetailByCorpusIdRedirect';
import LoadingPage from '@/components/shared/loading/LoadingPage';
import PaperDetailResponseType from '@/constants/PaperDetailResponseType';

import React, { PureComponent } from 'react';

/**
 * Component which redirects to the paper detail page, if a paper is found with the provided id.
 */
export default class PaperDetailRedirect extends PureComponent {
  static willRouteTo(appContext, routerState) {
    const { api, paperStore } = appContext;
    const currentPaperDetails = paperStore.getPaperDetail();
    const {
      query,
      params: { paperId },
    } = routerState;

    if (
      (!!currentPaperDetails.paper && currentPaperDetails.paper.id !== '') ||
      currentPaperDetails.id !== paperId
    ) {
      return api.fetchPaperDetail({ paperId, requireSlug: false }).then(response => {
        // If the paper id has changed, we'll get a redirect. Otherwise we'll get
        // the paper.
        switch (response.resultData.responseType) {
          case PaperDetailResponseType.CANONICAL: {
            const { canonicalId: id, canonicalSlug: slug } = response.resultData;
            return redirectToPaperDetailAbstract({ id, slug, query });
          }
          case PaperDetailResponseType.PAPER_DETAIL: {
            const { id, slug } = response.resultData.paper;
            return redirectToPaperDetailAbstract({ id, slug, query });
          }
          default: {
            throw new Error(`Unknown response type ${response.resultData.responseType}`);
          }
        }
      });
    } else {
      const { id, slug } = currentPaperDetails.paper;
      return Promise.resolve(redirectToPaperDetailAbstract({ id, slug, query }));
    }
  }

  render() {
    return <LoadingPage isLoading />;
  }
}
