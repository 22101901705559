import React from 'react';

export default function IconFeedExplanation(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 198.2 168">
      <style type="text/css">
        {`.research-base{fill:#CED4D7;}
        .research-highlight{fill:#5492EF;}
        .research-reverse{fill:#fff;}
        `}
      </style>

      <path
        className="research-base"
        d="M71.2,77.9c-0.2-0.2-0.5-0.3-0.9-0.3H70V84h6.5v-0.3c0-0.3-0.1-0.6-0.3-0.9L71.2,77.9z M103.5,65
          c-0.2-0.2-0.5-0.3-0.9-0.3h-0.3v6.5h6.5v-0.3c0-0.3-0.1-0.6-0.3-0.9L103.5,65z M71.2,39.1c-0.2-0.2-0.5-0.3-0.9-0.3H70v6.5h6.5
          v-0.3c0-0.3-0.1-0.6-0.3-0.9L71.2,39.1z M135.8,52c-0.2-0.2-0.5-0.3-0.9-0.3h-0.3v6.5h6.5v-0.3c0-0.3-0.1-0.6-0.3-0.9L135.8,52z
           M135.8,13.3c-0.2-0.2-0.5-0.3-0.9-0.3h-0.3v6.5h6.5v-0.3c0-0.3-0.1-0.6-0.3-0.9L135.8,13.3z M38.9,65c-0.2-0.2-0.5-0.3-0.9-0.3
          h-0.3v6.5h6.5v-0.3c0-0.3-0.1-0.6-0.3-0.9L38.9,65z M173,31.1l-4.9-4.9c-0.2-0.2-0.5-0.3-0.9-0.3h-0.3v6.5h6.5V32
          C173.4,31.7,173.3,31.4,173,31.1z M165.3,32.7v-6.9h-10.1c-0.7,0-1.2,0.5-1.2,1.2v23.4c0,0.7,0.5,1.2,1.2,1.2h17
          c0.7,0,1.2-0.5,1.2-1.2V33.9h-6.9C165.9,33.9,165.3,33.4,165.3,32.7z M165.3,71.5v-6.9h-10.1c-0.7,0-1.2,0.5-1.2,1.2V84h19.4V72.7
          h-6.9C165.9,72.7,165.3,72.1,165.3,71.5z M133,19.8v-6.9h-10.1c-0.7,0-1.2,0.5-1.2,1.2v23.4c0,0.7,0.5,1.2,1.2,1.2h17
          c0.7,0,1.2-0.5,1.2-1.2V21h-6.9C133.5,21,133,20.5,133,19.8z M168.1,65c-0.2-0.2-0.5-0.3-0.9-0.3h-0.3v6.5h6.5v-0.3
          c0-0.3-0.1-0.6-0.3-0.9L168.1,65z M38.9,26.2c-0.2-0.2-0.5-0.3-0.9-0.3h-0.3v6.5h6.5V32c0-0.3-0.1-0.6-0.3-0.9L38.9,26.2z
           M68.4,45.6v-6.9H58.3c-0.7,0-1.2,0.5-1.2,1.2v23.4c0,0.7,0.5,1.2,1.2,1.2h17c0.7,0,1.2-0.5,1.2-1.2V46.9h-6.9
          C68.9,46.9,68.4,46.3,68.4,45.6z M133,58.6v-6.9h-10.1c-0.7,0-1.2,0.5-1.2,1.2v23.4c0,0.7,0.5,1.2,1.2,1.2h17
          c0.7,0,1.2-0.5,1.2-1.2V59.8h-6.9C133.5,59.8,133,59.2,133,58.6z M36.1,71.5v-6.9H26c-0.7,0-1.2,0.5-1.2,1.2V84h19.4V72.7h-6.9
          C36.6,72.7,36.1,72.1,36.1,71.5z M36.1,32.7v-6.9H26c-0.7,0-1.2,0.5-1.2,1.2v23.4c0,0.7,0.5,1.2,1.2,1.2h17c0.7,0,1.2-0.5,1.2-1.2
          V33.9h-6.9C36.6,33.9,36.1,33.4,36.1,32.7z M57.1,78.7V84h11.3v-6.5H58.3C57.6,77.5,57.1,78.1,57.1,78.7z M68.4,6.9V0H58.3
          c-0.7,0-1.2,0.5-1.2,1.2v23.4c0,0.7,0.5,1.2,1.2,1.2h17c0.7,0,1.2-0.5,1.2-1.2V8.1h-6.9C68.9,8.1,68.4,7.5,68.4,6.9z M100.7,32.7
          v-6.9H90.6c-0.7,0-1.2,0.5-1.2,1.2v23.4c0,0.7,0.5,1.2,1.2,1.2h17c0.7,0,1.2-0.5,1.2-1.2V33.9h-6.9
          C101.2,33.9,100.7,33.4,100.7,32.7z M103.5,26.2c-0.2-0.2-0.5-0.3-0.9-0.3h-0.3v6.5h6.5V32c0-0.3-0.1-0.6-0.3-0.9L103.5,26.2z
           M100.7,71.5v-6.9H90.6c-0.7,0-1.2,0.5-1.2,1.2V84h19.4V72.7h-6.9C101.2,72.7,100.7,72.1,100.7,71.5z M71.2,0.3
          C71,0.1,70.6,0,70.3,0H70v6.5h6.5V6.1c0-0.3-0.1-0.6-0.3-0.9L71.2,0.3z"
      />
      <path
        className="research-highlight"
        d="M100.7,133.9v-6.9H90.6c-0.7,0-1.2,0.5-1.2,1.2v23.4c0,0.7,0.5,1.2,1.2,1.2h17c0.7,0,1.2-0.5,1.2-1.2v-16.6
          h-6.9C101.2,135.2,100.7,134.6,100.7,133.9z M103.5,127.4c-0.2-0.2-0.5-0.3-0.9-0.3h-0.3v6.5h6.5v-0.3c0-0.3-0.1-0.6-0.3-0.9
          L103.5,127.4z M38.9,108c-0.2-0.2-0.5-0.3-0.9-0.3h-0.3v6.5h6.5v-0.3c0-0.3-0.1-0.6-0.3-0.9L38.9,108z M36.1,114.6v-6.9H26
          c-0.7,0-1.2,0.5-1.2,1.2v23.4c0,0.7,0.5,1.2,1.2,1.2h17c0.7,0,1.2-0.5,1.2-1.2v-16.6h-6.9C36.6,115.8,36.1,115.2,36.1,114.6z
           M168.1,142.5c-0.2-0.2-0.5-0.3-0.9-0.3h-0.3v6.5h6.5v-0.3c0-0.3-0.1-0.6-0.3-0.9L168.1,142.5z M165.3,149v-6.9h-10.1
          c-0.7,0-1.2,0.5-1.2,1.2v23.4c0,0.7,0.5,1.2,1.2,1.2h17c0.7,0,1.2-0.5,1.2-1.2v-16.6h-6.9C165.9,150.2,165.3,149.7,165.3,149z
           M121.9,82.9v-6c0-1.1-0.6-2.1-1.3-3.1c-0.8-1-1.9-1.3-3-1.3h-17.2l-5.7-5.7H80.6c-1.1,0-1.9,0.4-2.8,1.1c-0.9,0.8-1.3,1.9-1.3,3.1
          v11.9H0v2.2h76.5V97c0,1.1,0.4,2.1,1.1,3.1c0.8,1,1.9,1.3,3,1.3h36.9c1.1,0,2.1-0.6,3-1.3c0.9-0.8,1.3-1.9,1.3-3.1V85.1h76.3v-2.2
          H121.9z"
      />
      <path
        className="research-reverse"
        d="M103.7,83.7c0.3,0,0.5,0.1,0.6,0.4c0.1,0.2,0.1,0.5,0,0.8L99,94.4c-0.1,0.3-0.4,0.4-0.6,0.4
          c-0.2,0-0.4-0.1-0.6-0.3c-0.1-0.2-0.2-0.4-0.1-0.7l1.4-6.1h-3.6c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.2-0.2-0.4-0.2-0.6l1-7.6
          c0-0.2,0.1-0.3,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2h4.3c0.2,0,0.4,0.1,0.6,0.3c0.1,0.2,0.2,0.4,0.1,0.6l-1.3,4.1H103.7z"
      />
    </symbol>
  );
}
