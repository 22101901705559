// This should be kept in sync with the corresponding Scala-side LibraryEntryLite model
import { DEPRECATED__FlowOptional, Nullable } from '@/utils/types';

import Immutable from 'immutable';

export type LibraryEntryLiteFromJS = {
  id: number;
  paperId: string;
  corpusId: Nullable<number>;
};

type Props = {
  id: number;
  paperId: string;
  corpusId: Nullable<number>;
};

export const RECORD_NAME = 'LibraryEntryLite';

export const defaultProps: Props = {
  id: -1,
  paperId: '',
  corpusId: null,
};

export const LibraryEntryLiteRecordFactory = Immutable.Record(defaultProps, RECORD_NAME);
export type LibraryEntryLiteRecord = Immutable.RecordOf<Props>;

export function getLibraryEntryLiteFromJS(args: LibraryEntryLiteFromJS): LibraryEntryLiteRecord {
  return LibraryEntryLiteRecordFactory({
    ...args,
  });
}

export function getCorpusIdsFromLibraryEntryLite(
  entries: DEPRECATED__FlowOptional<LibraryEntryLiteFromJS[]>
): number[] {
  if (!entries) {
    return [];
  }

  const corpusIdsArr: number[] = [];
  return entries.reduce((corpusIds, entry) => {
    if (entry.corpusId) {
      corpusIds.push(entry.corpusId);
    }
    return corpusIds;
  }, corpusIdsArr);
}
