import AnalyticsEvent from './AnalyticsEvent';

import EventType from '@/analytics/constants/EventType';

import merge from 'merge';

/**
 * Model for an event we'd like to track.  This class should never be tracked directly, the intent is
 * that you create a subclass modeling the specific event you'd like to track.
 */
export default class HoverEvent extends AnalyticsEvent {
  constructor(target: string, data?: any) {
    super(EventType.HOVER, merge.recursive({ target }, data));
  }

  static create(target: string, data?: any): HoverEvent {
    return new HoverEvent(target, data);
  }
}
