import {
  FieldOfStudyName,
  FieldOfStudyRecord,
  optFieldOfStudyRecord,
} from '@/constants/FieldOfStudy';
import {
  getProfilePictureFromGraphQL,
  PROFILE_PICTURE_FRAGMENT,
  PROFILE_PICTURE_FRAGMENT_NAME,
  ProfilePictureFromGraphQL,
  ProfilePictureRecord,
} from '@/models/user/ProfilePicture';
import { Nullable } from '@/utils/types';

import { gql } from 'graphql-tag';
import Immutable from 'immutable';

export type AuthorLiteFromGraphQL = {
  id: number;
  slug: string;
  fullName: string;
  affiliationNames: string[];
  paperCount: number;
  hIndex: number;
  citationCount: number;
  primaryFieldOfStudy: Nullable<FieldOfStudyName>;
  profilePicture: Nullable<ProfilePictureFromGraphQL>;
};

type Props = {
  id: number;
  slug: string;
  name: string;
  affiliations: Immutable.List<string>;
  paperCount: number;
  hIndex: number;
  citationCount: number;
  primaryFieldOfStudy: Nullable<FieldOfStudyRecord>;
  profilePicture: Nullable<ProfilePictureRecord>;
};

const defaultProps: Props = {
  id: -1,
  slug: '',
  name: '',
  affiliations: Immutable.List(),
  paperCount: 0,
  hIndex: 0,
  citationCount: 0,
  primaryFieldOfStudy: null,
  profilePicture: null,
};

export const AuthorLiteRecordFactory = Immutable.Record<Props>(defaultProps);

export type AuthorLiteRecord = Immutable.RecordOf<Props>;

export function getAuthorLiteFromGraphQL(args: AuthorLiteFromGraphQL): AuthorLiteRecord {
  const { affiliationNames, fullName, primaryFieldOfStudy, profilePicture } = args;
  return AuthorLiteRecordFactory({
    ...args,
    name: fullName,
    affiliations: Immutable.List(affiliationNames || []),
    primaryFieldOfStudy: optFieldOfStudyRecord(primaryFieldOfStudy),
    profilePicture: profilePicture ? getProfilePictureFromGraphQL(profilePicture) : null,
  });
}

export const AUTHOR_LITE_FRAGMENT_NAME = 'AuthorLiteRecordFragment';

export const AUTHOR_LITE_FRAGMENT = gql`
  ${PROFILE_PICTURE_FRAGMENT}

  fragment ${AUTHOR_LITE_FRAGMENT_NAME} on Author {
    id
    slug
    fullName
    affiliationNames
    paperCount
    hIndex
    citationCount
    primaryFieldOfStudy
    profilePicture {
      ...${PROFILE_PICTURE_FRAGMENT_NAME}
    }
  }
`;
