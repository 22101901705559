/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */

import type { CookieSerializeOptions } from 'cookie';

export type OptionalCookieFields = CookieSerializeOptions;

export type RequiredCookieFields = {
  maxAge: number;
};

export type CookieOptions = RequiredCookieFields & OptionalCookieFields;

export type CookieValues = { [name: string]: string };

// Default to one year maxAge for cookies
export const DEFAULT_COOKIE_MAX_AGE = 365 * 24 * 60 * 60;

export default class CookieJar {
  // This should be an interface, but we need to support context prop-types
  saveCookie(name: string, value: string, options: CookieOptions): void {}
  getCookie(name: string): string | void {}
  getCookies(): CookieValues {
    return {};
  }
  removeCookie(name: string, options: OptionalCookieFields): void {}
}
