import { AUTHOR_CLAIM_TOKEN } from '@/constants/UserCookie';
import { ChildRoutesContext } from '@/router/ChildRoutes';
import { createPaperDetailLoadedDispatch } from '@/actions/PaperDetailActionCreators';
import { getCitationQueryRecordFromQueryStringParams } from '@/models/CitationQuery';
import { getLayoverLogger } from '@/utils/layover/LayoverLogger';
import {
  getPaperDetailCitationMeta,
  getPaperDetailDescription,
  getPaperDetailLinkedData,
} from '@/models/PaperDetail';
import { getReferenceQueryRecordFromQueryStringParams } from '@/models/ReferenceQuery';
import { getString } from '@/content/i18n';
import { isPdfUrl } from '@/utils/paper-link-util';
import { QUERY_TYPE } from '@/constants/Alert';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import Citation from '@/constants/Citation';
import CitationQueryStore from '@/stores/CitationQueryStore';
import Constants from '@/constants';
import Feature from '@/weblab/Feature';
import logger from '@/logger';
import ReferenceQueryStore from '@/stores/ReferenceQueryStore';
import Routes, { makePath } from '@/router/Routes';
import S2Redirect from '@/models/redirects/S2Redirect';
import schema from '@/utils/routing/schema';
import SortType from '@/constants/sort-type';

import deepEqual from 'fast-deep-equal';
import idx from 'idx';
import React from 'react';

const ONE_DAY_IN_SECONDS = 24 * 60 * 60;

export default class PaperDetailRoute extends React.Component {
  static getPageTitle({ paperStore }) {
    const {
      alternatePaperLinks,
      primaryPaperLink,
      title: { text: title },
    } = paperStore.getPaperDetail().paper;

    const hasPdf =
      (primaryPaperLink ? isPdfUrl(primaryPaperLink.url) : false) ||
      alternatePaperLinks.some(paperLink => isPdfUrl(paperLink.url));
    const paperTitle = hasPdf ? `[PDF] ${title}` : title;

    return getString(_ => _.paperDetail.pageTitle, paperTitle);
  }

  static getPageCitationMeta({ paperStore }) {
    return getPaperDetailCitationMeta(paperStore.getPaperDetail());
  }

  static getPageMetaDescription({ paperStore }) {
    return getPaperDetailDescription(paperStore.getPaperDetail());
  }

  static getSchemaData(router, appContext) {
    const paperDetail = appContext.paperStore.getPaperDetail();
    const breadcrumbData = [
      { path: '/paper', name: 'Papers' },
      { path: router.url, name: paperDetail.paper.title.text },
    ];

    return {
      breadCrumbs: schema.breadcrumb(appContext.envInfo.hostname, breadcrumbData),
      linkedData: getPaperDetailLinkedData(paperDetail, 'PAPER_DETAIL'),
    };
  }

  static getCanonicalUrl(routerState) {
    if (routerState.params.slug) {
      const { paperId, slug } = routerState.params;
      return makePath({ routeName: 'PAPER_DETAIL', params: { paperId, slug } });
    } else {
      const { paperId } = routerState.params;
      return makePath({ routeName: 'PAPER_DETAIL_BY_ID', params: { paperId } });
    }
  }

  static getRobotDirectives(robots, { history }) {
    // Crawlers should only be indexing the default render state.
    if (Object.keys(history.locationQueryParams).length > 0) {
      robots.shouldIndex = false;
    }
  }

  static async willRouteTo(appContext, state) {
    const {
      authStore,
      api,
      citationQueryStore,
      referenceQueryStore,
      cookieJar,
      dispatcher,
      envInfo,
      paperStore,
      weblabStore,
    } = appContext;

    const { path, routes, query, params } = state;
    // This param should always be defined, but null-checking to satisfy Flow.
    const paperId = params.paperId ? params.paperId : '';
    const slug = params.slug;

    const paperDetail = paperStore.getPaperDetail();
    const isNewPaper = paperId !== paperDetail.paper.id;

    const { nextCitedPapersPageInfo } = await parseCitationQueryParams({ query });

    let paperDetailPromise;
    if (isNewPaper) {
      paperDetailPromise = api.fetchPaperDetail({
        paperId,
        slug,
        citedPapersSort: nextCitedPapersPageInfo.sort,
        citedPapersLimit: nextCitedPapersPageInfo.pageSize,
        citedPapersOffset: nextCitedPapersPageInfo.offset,
      });
    } else {
      paperDetailPromise = fetchCitationsIfNeeded({
        api,
        paperId,
        paperDetail,
        nextCitedPapersPageInfo,
      });
    }

    let labsPromise = null;
    // TODO: (#39524) Remove check for PaperQA flag once feature flags
    // are enforced on the backend for Labs.
    if (weblabStore.isFeatureEnabled(Feature.PaperLabs)) {
      labsPromise = api.fetchPaperLabs({ paperId }).catch(err => {
        // Capture error, but don't block the rest of the page from loading
        getLayoverLogger().logNamedError('error.labs.request', err);
        return null;
      });
    }

    const addAlertsPromise = !envInfo.isMobile && authStore.hasAuthenticatedUser() && isNewPaper;
    const alertsPromise = addAlertsPromise
      ? api.findAlertByQueryTypeAndValue(QUERY_TYPE.PAPER_CITATION, paperId)
      : Promise.resolve();

    // TODO: (#20576) Fix alerts fetching on PDP
    // const userInfoPromise = authStore.hasAuthenticatedUser() && isNewPaper
    //   ? api.fetchUserInfo()
    //   : Promise.resolve();

    citationQueryStore.setIndependentQueryParams(query);
    referenceQueryStore.setIndependentQueryParams(query);

    const citationsPromise = (() => {
      const currentQuery = citationQueryStore.getQuery();
      const nextQuery = getCitationQueryRecordFromQueryStringParams(query);

      if (isNewPaper || !deepEqual(currentQuery, nextQuery)) {
        return CitationQueryStore.executeSearchQuery(appContext, state);
      } else {
        return Promise.resolve();
      }
    })();

    const referencePromise = (() => {
      const currentQuery = referenceQueryStore.getQuery();
      const nextQuery = getReferenceQueryRecordFromQueryStringParams(query);

      if (isNewPaper || !deepEqual(currentQuery, nextQuery)) {
        return ReferenceQueryStore.executeSearchQuery(appContext, state);
      } else {
        return Promise.resolve();
      }
    })();

    // get paper pdf visibility to determine whether to show Reader button
    const readerVisibilityPromise = (() => {
      if (isNewPaper && !envInfo.isMobile) {
        try {
          return api.fetchPdfVisibility(paperId);
        } catch (err) {
          logger.error(err);
        }
      } else {
        return Promise.resolve();
      }
    })();

    // This needs to be set *after* the paper detail request is started, as we always
    // reset this value upon starting an API request for a paper's details
    dispatcher.dispatch({
      actionType: Constants.actions.UPDATE_FOCUSED_FIGURE_INDEX,
      figureIndex: params.figureIndex,
    });

    return Promise.all([
      paperDetailPromise,
      labsPromise,
      alertsPromise,
      /* userInfoPromise, TODO: (#20576) Fix alerts fetching on PDP */
      citationsPromise,
      referencePromise,
      readerVisibilityPromise,
    ]).then(payloads => {
      const [paperDetailPayload] = payloads;

      // Check if we need to redirect to the canonical url
      // $FlowFixMe: PaperDetailResponse correctly has this type listed but here flow is only seeing PaperDetailResponseData and not the PaperDetailCanonicalRedirectData that this can also be
      if (idx(paperDetailPayload, _ => _.resultData.canonicalId)) {
        const nextParams = {
          ...params,
          // $FlowFixMe: "property `resultData` is missing in  undefined", should covered by the idx if above
          paperId: paperDetailPayload.resultData.canonicalId,
          // $FlowFixMe: "property `resultData` is missing in  undefined", should covered by the idx if above
          slug: paperDetailPayload.resultData.canonicalSlug,
        };

        return routes
          ? new S2Redirect({
              query,
              routeName: routes[routes.length - 1].name,
              params: nextParams,
              replace: true,
            })
          : new S2Redirect({ path, query, params: nextParams, replace: true });
      } else {
        const paperDetailLoadedDispatch = createPaperDetailLoadedDispatch(paperDetailPayload);
        paperDetailLoadedDispatch && dispatcher.dispatch(paperDetailLoadedDispatch);

        const isFigureDetailPage = routes
          ? routes.filter(r => r.name === 'PAPER_DETAIL_FIGURE').length > 0
          : path === Routes.PAPER_DETAIL_FIGURE;
        const paper = paperStore.getPaperDetail();

        // Add claim token to cookies if present
        if (weblabStore.isFeatureEnabled(Feature.AuthorClaimOnPDP)) {
          if ('claim' in query) {
            const value = [query.claim, paper.paper.id].join(',');
            cookieJar.saveCookie(AUTHOR_CLAIM_TOKEN, value, { maxAge: ONE_DAY_IN_SECONDS });
          }
        }

        // If we're viewing a figure make sure the figure exists
        const figureExists =
          paperStore.focusedFigureIndex === null ||
          paperStore.focusedFigureIndex === undefined ||
          !paper.figures.has(paperStore.focusedFigureIndex);
        return isFigureDetailPage && figureExists
          ? // eslint-disable-next-line prefer-promise-reject-errors
            Promise.reject({ status: 404 })
          : [...payloads, paperDetailLoadedDispatch];
      }
    });
  }

  render() {
    const paperId = idx(this.props.match, _ => _.params.paperId);
    const { route } = this.props;

    return (
      <ChildRoutesContext.Provider value={{ route }}>
        <AsyncLoadedPage
          footer={false}
          compProps={{ paperId }}
          load={{
            importer: () =>
              import(/* webpackChunkName: "shared-PaperDetailPage" */ './PaperDetailPage'),
            chunkName: 'shared-PaperDetailPage',
            moduleId: require.resolveWeak('./PaperDetailPage'),
          }}
        />
      </ChildRoutesContext.Provider>
    );
  }
}

/**
 * Parse the rather involved querystring for citations/references related parameters, providing
 * defaults for any missing values. Returns redirectNeeded if the requested parameters are incongruent
 * with the current paper.
 */
async function parseCitationQueryParams({ query }) {
  const nextCitedPapersPageSize = parseInt(query.citedPapersLimit) || Citation.CITATIONS_PAGE_SIZE;
  const nextCitedPapersOffset = parseInt(query.citedPapersOffset) || 0;

  const nextCitedPapersSort = parseCitationSort({
    query,
    citationType: 'citedPapers',
  });

  return {
    nextCitedPapersPageInfo: {
      offset: nextCitedPapersOffset,
      sort: nextCitedPapersSort,
      pageSize: nextCitedPapersPageSize,
    },
  };
}

// determine correct sort based on query, store + experiment state
export function parseCitationSort({ query, citationType }) {
  const querySpecifiedSort = query[`${citationType}Sort`];
  if (querySpecifiedSort) {
    return querySpecifiedSort;
  } else {
    return SortType.RELEVANCE.id;
  }
}

/**
 * Compares the current PaperDetail state with the query param requests (coming in via the next*) to
 * see if we need to request updated citations from the api and if so call the api.
 */
export function fetchCitationsIfNeeded({
  api,
  paperId,
  paperDetail,
  nextCitedPapersPageInfo,
  citingPapersModelVersion,
}) {
  const currentCitedPapersPageInfo = {
    // $FlowFixMe: Doesn't see the getIn from Record
    sort: paperDetail.getIn(['citedPapers', 'sort']),
    pageSize: paperDetail.citedPapers.requestedPageSize,
    offset:
      // $FlowFixMe: Doesn't see the getIn from Record
      (paperDetail.getIn(['citedPapers', 'pageNumber']) - 1) *
      paperDetail.citedPapers.requestedPageSize,
  };

  if (!deepEqual(currentCitedPapersPageInfo, nextCitedPapersPageInfo)) {
    return api.fetchCitations({
      paperId,
      citationType: 'citedPapers',
      sort: nextCitedPapersPageInfo.sort,
      intent: null, // citedPapers type doesn't have intent filter yet
      offset: nextCitedPapersPageInfo.offset,
      pageSize: nextCitedPapersPageInfo.pageSize,
      // citedPapers type doesn't have date filter yet
      citingPapersModelVersion,
    });
  } else {
    return Promise.resolve();
  }
}
