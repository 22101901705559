import { getString } from '@/content/i18n';
import {
  heapLibraryRecommendationExcludeFeed,
  heapLibraryRecommendationIncludeFeed,
} from '@/analytics/attributes/recommendationPage';
import { makePath } from '@/router/Routes';
import { mkOnClickKeyDown } from '@/utils/a11y-utils';
import { On } from '@/models/library/RecommendationStatus';
import CLCardContainer from '@/components/library/container/CLCardContainer';
import CLTooltip, { PLACEMENT } from '@/components/library/tooltip/CLTooltip';
import Icon from '@/components/shared/icon/Icon';
import LibraryFolderStore from '@/stores/LibraryFolderStore';
import LibraryRecommendationsStore from '@/stores/LibraryRecommendationsStore';
import S2History from '@/utils/S2History';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const MAX_FOLDER = 10;

export default class RecommendationsViewingFilterNav extends React.PureComponent {
  static contextTypes = {
    history: PropTypes.instanceOf(S2History).isRequired,
    libraryFolderStore: PropTypes.instanceOf(LibraryFolderStore).isRequired,
    libraryRecommendationsStore: PropTypes.instanceOf(LibraryRecommendationsStore).isRequired,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      attemptToClickOverLimitMaxFolderCount: false,
      ...this.getStateFromLibraryFolderStore(),
      ...this.getStateFromLibraryRecommendationsStore(),
    };

    this.context.libraryFolderStore.registerComponent(this, () => {
      this.setState(this.getStateFromLibraryFolderStore());
    });

    this.context.libraryRecommendationsStore.registerComponent(this, () => {
      this.setState(this.getStateFromLibraryRecommendationsStore());
    });
  }

  getStateFromLibraryFolderStore = () => {
    const { libraryFolderStore } = this.context;
    const folders = libraryFolderStore.getFoldersWithRecommendationStatus(On);

    return {
      folders,
    };
  };

  getStateFromLibraryRecommendationsStore = () => {
    const { libraryFolderStore, libraryRecommendationsStore } = this.context;
    const visibleFolderIds = libraryRecommendationsStore.getVisibleFolderIds();

    const folders = libraryFolderStore.getFoldersWithRecommendationStatus(On);

    const visibleFolders = folders.filter(folder => visibleFolderIds.includes(folder.id)).toSet();

    return {
      visibleFoldersId: visibleFolders.map(folder => folder.id).toSet(),
    };
  };

  onToggleFolder = folder => {
    const { visibleFoldersId } = this.state;

    const routeName = 'RECOMMENDATIONS';
    const folderId = folder.id;

    const isClickOverLimitMaxFolderCount =
      !visibleFoldersId.has(folderId) && visibleFoldersId.size === MAX_FOLDER;

    this.setState({
      attemptToClickOverLimitMaxFolderCount: isClickOverLimitMaxFolderCount,
    });

    // When user attempts to click a folder if the current count is 10
    // we just want to add a class to make it flash red and wont execute anything
    if (isClickOverLimitMaxFolderCount) {
      return;
    }

    const newVisibleFolderIds = visibleFoldersId.has(folderId)
      ? visibleFoldersId.remove(folderId)
      : visibleFoldersId.add(folderId);
    const query = newVisibleFolderIds.isEmpty()
      ? { noFolders: true }
      : { folderIds: newVisibleFolderIds.join(',') };
    const path = makePath({
      routeName: routeName,
      query: query,
    });
    this.context.history.push(path);
  };

  renderIcon(visibleFolderCount) {
    if (visibleFolderCount === MAX_FOLDER) {
      return (
        <CLTooltip
          placement={PLACEMENT.TOP}
          tooltipContent={getString(_ => _.recommendations.limitFeed)}>
          <Icon icon="eye-slash" height="14" width="14" />
        </CLTooltip>
      );
    }
    return <Icon icon="eye-slash" height="14" width="14" />;
  }

  render() {
    const { folders, visibleFoldersId, attemptToClickOverLimitMaxFolderCount } = this.state;

    const maxFolderCount = Math.min(folders.size, MAX_FOLDER);

    const foldersIds = folders.map(folder => folder.id).toSet();

    const visibleFolderCount = Math.min(visibleFoldersId.intersect(foldersIds).size, MAX_FOLDER);

    return (
      <CLCardContainer className="recommendations__viewing-filter-nav">
        <div className="recommendations__viewing-filter-nav__header-container">
          <h2
            className="recommendations__viewing-filter-nav__header"
            aria-label={getString(_ => _.recommendations.recommendationsViewingAriaLabel)}>
            {getString(_ => _.recommendations.recommendationsViewingFilterHeader)}
          </h2>
          <div
            aria-atomic="true"
            aria-live="polite"
            className={classnames('recommendations__viewing-filter-nav__header-count', {
              'recommendations__viewing-filter-nav__header-count--limit':
                visibleFolderCount === MAX_FOLDER,
              'recommendations__viewing-filter-nav__header-count--attempt':
                attemptToClickOverLimitMaxFolderCount,
            })}>
            {visibleFolderCount + '/' + maxFolderCount}
            <span className="screen-reader-only">
              {getString(_ => _.recommendations.activeFeeds)}
            </span>
          </div>
        </div>

        {folders.map(folder => (
          <div key={folder.id} className="flex-row">
            <div
              role="button"
              tabIndex={0}
              /* Bad pattern as we lose out on performance but we do this inline in order to get
               access to the folder parameter */
              {...mkOnClickKeyDown({
                onClick: () => this.onToggleFolder(folder),
              })}
              className={classnames('recommendations__viewing-filter-nav__item', {
                'recommendations__viewing-filter-nav__item--visible': visibleFoldersId.has(
                  folder.id
                ),
                'recommendations__viewing-filter-nav__item--visible--limit':
                  !visibleFoldersId.has(folder.id) && visibleFolderCount === MAX_FOLDER,
              })}
              aria-pressed={visibleFoldersId.has(folder.id)}
              aria-disabled={!visibleFoldersId.has(folder.id) && visibleFolderCount === MAX_FOLDER}
              aria-describedby={
                !visibleFoldersId.has(folder.id) &&
                visibleFolderCount === MAX_FOLDER &&
                'feed-limit-count-tooltip'
              }
              {...(visibleFoldersId.has(folder.id)
                ? heapLibraryRecommendationIncludeFeed()
                : heapLibraryRecommendationExcludeFeed())}>
              {visibleFoldersId.has(folder.id) ? (
                <Icon
                  icon="eye"
                  height="14"
                  width="14"
                  {...heapLibraryRecommendationIncludeFeed()}
                />
              ) : (
                this.renderIcon(visibleFolderCount)
              )}
              {folder.name}
            </div>
          </div>
        ))}
        <span hidden id="feed-limit-count-tooltip">
          {getString(_ => _.recommendations.limitFeed)}
        </span>
      </CLCardContainer>
    );
  }
}
