import {
  getPaperPdfFeatureVersionFromJS,
  PaperPdfFeatureVersionFromJS,
  PaperPdfFeatureVersionRecord,
} from './PaperPdfFeatureVersion';
import {
  getPaperPdfOutputComponentIdFromJS,
  PaperPdfOutputComponentIdFromJS,
  PaperPdfOutputComponentIdRecord,
} from './PaperPdfOutputComponentId';

import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

export type PaperPdfOutputDescriptionFromJS = {
  formatVersion: string;
  ddbDocs: Nullable<PaperPdfOutputComponentIdFromJS[]>;
  requestedFeatureVersions: Nullable<PaperPdfFeatureVersionFromJS[]>;
  constructionStrategy: string;
  gitCommit: string;
};

type Props = {
  formatVersion: string;
  ddbDocs: Immutable.List<PaperPdfOutputComponentIdRecord>;
  requestedFeatureVersions: Immutable.List<PaperPdfFeatureVersionRecord>;
  constructionStrategy: string;
  gitCommit: string;
};

const defaultProps: Props = {
  formatVersion: '',
  ddbDocs: Immutable.List(),
  requestedFeatureVersions: Immutable.List(),
  constructionStrategy: '',
  gitCommit: '',
};

export const PaperPdfOutputDescriptionFactory = Immutable.Record<Props>(defaultProps);

export type PaperPdfOutputDescriptionRecord = Immutable.RecordOf<Props>;

export function getPaperPdfOutputDescriptionFromJS(
  outputDescription: PaperPdfOutputDescriptionFromJS
): PaperPdfOutputDescriptionRecord {
  const newddbDocsList = Immutable.List(outputDescription?.ddbDocs || []).map(ddbDoc =>
    getPaperPdfOutputComponentIdFromJS(ddbDoc)
  );

  const newRequestedFeatureVersionsList = Immutable.List(
    outputDescription?.requestedFeatureVersions || []
  ).map(requestedFeatureVersion => getPaperPdfFeatureVersionFromJS(requestedFeatureVersion));

  return PaperPdfOutputDescriptionFactory({
    constructionStrategy: outputDescription?.constructionStrategy || '',
    ddbDocs: newddbDocsList,
    formatVersion: outputDescription?.formatVersion || '',
    gitCommit: outputDescription?.gitCommit || '',
    requestedFeatureVersions: newRequestedFeatureVersionsList,
  });
}
