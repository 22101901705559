import { getLayoverLogger } from '@/utils/layover/LayoverLogger';
import { getString } from '@/content/i18n';
import { runWithHeap } from '@/analytics/Heap';
import logger from '@/logger';

import React from 'react';

/**
 * This component is intended never to leave the React Tree. If it does, then we
 * know the React Tree has been removed, and the DOM is now blank. This component
 * detects that by expecting useEffect's clean up callback is never run.
 */
export default function WhitePageDetector() {
  React.useEffect(() => {
    return () => {
      getLayoverLogger().logNamedError(
        'error.whitepage',
        'An unknown error has caused the react dom to unmount, causing a white page'
      );
      logger.error('white page has been detected');

      runWithHeap(heap => {
        heap.track('error.whitepage');
      });

      // Display error message on white page
      const elem = document.createElement('div');
      elem.style.margin = '16px';
      elem.innerHTML = getString(_ => _.error.whitepage.description);
      elem.className = 'cl-page-alert cl-page-alert--danger';
      if (document.body) {
        document.body.appendChild(elem);
      }
      document.title = getString(_ => _.error.whitepage.pageTitle);
    };
  }, []);
  return null;
}
