import { ChildRoutesContext } from '@/router/ChildRoutes';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

import type { RouteConfig } from 'react-router-config';

type Props = {
  route: RouteConfig;
};
export default class ModeratorAuthorCorrectionsToolRoute extends React.Component<Props> {
  render(): React.ReactNode {
    const { route } = this.props;
    return (
      <ChildRoutesContext.Provider value={{ route }}>
        <AsyncLoadedPage
          showLoadingPage={false}
          load={{
            importer: () =>
              import(
                /* webpackChunkName: "desktop-ModeratorAuthorCorrectionsToolPage" */ './ModeratorAuthorCorrectionsToolPage'
              ),
            chunkName: 'desktop-ModeratorAuthorCorrectionsToolPage',
            moduleId: require.resolveWeak('./ModeratorAuthorCorrectionsToolPage'),
          }}
        />
      </ChildRoutesContext.Provider>
    );
  }
}
