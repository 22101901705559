import Icon from './Icon';

import { Nullable } from '@/utils/types';
import CLIconButton, { TYPE } from '@/components/library/button/CLIconButton';

import classNames from 'classnames';
import React from 'react';

export type DirectionKey = keyof typeof Direction;
export type DirectionValue = (typeof Direction)[keyof typeof Direction];

type Props = {
  width: string;
  height: string;
  direction: DirectionValue;
  animate: boolean;
  onClick?: Nullable<() => void>;
  className: string;
  ariaProps?: Record<string, unknown>;
};

export const Direction = Object.freeze({
  UP: 'up',
  RIGHT: 'right',
  DOWN: 'down',
  LEFT: 'left',
});

export default class Arrow extends React.PureComponent<Props> {
  static get Direction() {
    return Direction;
  }

  static defaultProps = {
    width: '12',
    height: '12',
    direction: Arrow.Direction.RIGHT,
    animate: true,
    className: '',
  };

  render(): React.ReactNode {
    const { animate, direction, width, height, onClick, className, ariaProps, ...rest } =
      this.props;
    // We need to use a wrapper (and rotate it) due to issues in Firefox associated with rotating
    // (and transforming in general) SVGS.

    return (
      <CLIconButton
        ariaProps={ariaProps}
        type={TYPE.TERTIARY}
        icon={<Icon icon="disclosure" width={width} height={height} />}
        onClick={onClick || undefined}
        className={classNames({
          [`arrow direction--${direction}`]: true,
          'animate--transform': animate,
          [`${className} direction--${direction}`]: className,
        })}
        {...rest}
      />
    );
  }
}
