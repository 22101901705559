import React from 'react';

export default function IconFilter(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 60 60">
      <path
        d="M40.9,18c0.3,0,0.6,0.1,0.8,0.3s0.3,0.5,0.3,0.8s-0.1,0.6-0.3,0.8L33,28.6v12.3c0,0.5-0.2,0.8-0.6,1
        s-0.8,0.2-1.2-0.1l-3.8-2.6c-0.3-0.2-0.5-0.6-0.5-0.9v-9.7l-8.7-8.7c-0.2-0.2-0.3-0.5-0.3-0.8s0.1-0.6,0.3-0.8s0.5-0.3,0.8-0.3
        H40.9z"
      />
    </symbol>
  );
}
