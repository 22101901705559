import React from 'react';

export default function IconUserWithPencil(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 44.99 36">
      <path
        d="M22.07,20.24c1.36,0,2.65,0.28,3.87,0.84s2.27,1.34,3.16,2.32l-6.05,6.05l-0.56,5.06c-0.05,0.47,0.02,0.96,0.21,1.48H3.37
        c-0.94,0-1.73-0.33-2.39-0.98C0.33,34.35,0,33.55,0,32.62v-2.95c0-1.69,0.42-3.26,1.27-4.71s1.99-2.6,3.44-3.44
        c1.45-0.84,3.02-1.27,4.71-1.27h1.19c1.64,0.75,3.35,1.12,5.13,1.12s3.49-0.37,5.13-1.12H22.07z M15.75,17.99
        c-1.64,0-3.15-0.4-4.53-1.19c-1.38-0.8-2.47-1.89-3.27-3.27S6.75,10.64,6.75,9s0.4-3.15,1.19-4.53s1.89-2.47,3.27-3.27
        S14.11,0,15.75,0s3.15,0.4,4.53,1.19s2.47,1.89,3.27,3.27c0.8,1.38,1.19,2.89,1.19,4.53s-0.4,3.15-1.19,4.53
        c-0.8,1.38-1.89,2.47-3.27,3.27C18.9,17.6,17.39,17.99,15.75,17.99z M25.23,30.44l9.7-9.7L40,25.8l-9.7,9.7l-4.29,0.49
        c-0.37,0.05-0.69-0.06-0.95-0.32c-0.26-0.26-0.36-0.57-0.32-0.95L25.23,30.44z M44.49,18.91c0.33,0.33,0.49,0.73,0.49,1.19
        s-0.16,0.87-0.49,1.19l-2.95,2.95l-5.06-5.06l2.95-2.95c0.33-0.33,0.73-0.49,1.19-0.49s0.87,0.16,1.19,0.49L44.49,18.91z"
      />
    </symbol>
  );
}
