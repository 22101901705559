import { ChildRoutesContext } from '@/router/ChildRoutes';
import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

import type { RouteConfig } from 'react-router-config';
import type { WillRouteToResult } from '@/router/Route';
import type AppContext from '@/AppContext';

type Props = {
  route: RouteConfig;
};

export default class ModerationRoute extends React.Component<Props> {
  static getPageTitle = () => getString(_ => _.moderation.pageTitle);

  static requiresAuthentication = () => true;

  static async willRouteTo({ api }: AppContext): Promise<WillRouteToResult> {
    return api.fetchModerationStats();
  }

  render(): React.ReactNode {
    const { route } = this.props;
    return (
      <ChildRoutesContext.Provider value={{ route }}>
        <AsyncLoadedPage
          footer={false}
          load={{
            importer: () =>
              import(/* webpackChunkName: "desktop-ModerationPage" */ './ModerationPage'),
            chunkName: 'desktop-ModerationPage',
            moduleId: require.resolveWeak('./ModerationPage'),
          }}
        />
      </ChildRoutesContext.Provider>
    );
  }
}
