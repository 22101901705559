// This should be kept in sync with the corresponding Scala-side LibraryFolderWithEntries model
import { getCorpusIdsFromLibraryEntryLite, LibraryEntryLiteFromJS } from './LibraryEntryLite';
import {
  getLibraryFolderSourceType,
  LibraryFolderSourceType,
  Unsupported as SourceTypeUnsupported,
} from './LibraryFolderSourceType';
import {
  getOptPrivacySetting,
  PrivacySetting,
  Unsupported as PrivacySettingUnsupported,
} from './PrivacySetting';
import {
  getRecommendationStatus,
  RecommendationStatus,
  Unsupported as RecommendationUnsupported,
} from './RecommendationStatus';

import { DEPRECATED__FlowOptional, Nullable } from '@/utils/types';

import Immutable from 'immutable';

export const RECORD_NAME = 'LibraryFolder';

export type LibraryFolderFromJS = {
  id: number;
  name: string;
  recommendationStatus?: { id: string } | string;
  sourceType?: { id: string } | string;
  entries: DEPRECATED__FlowOptional<LibraryEntryLiteFromJS[]>; // the get folder APIs return an entry id with the paper id in case we need it in the future
  description?: string;
  privacySetting?: { id: string } | string;
};

type Props = {
  id: number;
  name: string;
  recommendationStatus: RecommendationStatus;
  sourceType: LibraryFolderSourceType;
  paperIds: Immutable.Set<string>;
  corpusIds: Immutable.Set<number>;
  description: Nullable<string>;
  privacySetting: Nullable<PrivacySetting>;
};

const defaultProps: Props = {
  id: -1,
  name: '',
  recommendationStatus: RecommendationUnsupported,
  sourceType: SourceTypeUnsupported,
  paperIds: Immutable.Set(),
  corpusIds: Immutable.Set(),
  description: '',
  privacySetting: null,
};

export const LibraryFolderRecordFactory = Immutable.Record(defaultProps, RECORD_NAME);
export type LibraryFolderRecord = Immutable.RecordOf<Props>;

export function getLibraryFolderFromJS(args: LibraryFolderFromJS): LibraryFolderRecord {
  return LibraryFolderRecordFactory({
    id: args.id,
    name: args.name,
    recommendationStatus: (() => {
      switch (typeof args.recommendationStatus) {
        case 'string':
          return getRecommendationStatus(args.recommendationStatus);
        case 'object':
          return getRecommendationStatus(args.recommendationStatus.id);
        default:
          return RecommendationUnsupported;
      }
    })(),
    sourceType: (() => {
      switch (typeof args.sourceType) {
        case 'string':
          return getLibraryFolderSourceType(args.sourceType);
        case 'object':
          return getLibraryFolderSourceType(args.sourceType.id);
        default:
          return SourceTypeUnsupported;
      }
    })(),
    paperIds: args.entries
      ? Immutable.Set(args.entries.map(entry => entry.paperId))
      : Immutable.Set(),
    corpusIds: Immutable.Set(getCorpusIdsFromLibraryEntryLite(args.entries)),
    description: args.description,
    privacySetting: (() => {
      switch (typeof args.privacySetting) {
        case 'undefined':
          return null;
        case 'string':
          return getOptPrivacySetting(args.privacySetting);
        case 'object':
          return getOptPrivacySetting(args.privacySetting.id);
        default:
          return PrivacySettingUnsupported;
      }
    })(),
  });
}
