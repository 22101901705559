import Histogram from './Histogram';
import HistogramTitle from './HistogramTitle';

import * as format from '@/format';
import * as util from '@/util';
import { getString } from '@/content/i18n';
import CLRule from '@/components/library/layout/CLRule';

import React from 'react';

export default class CitationsHistogram extends React.PureComponent {
  renderTitle() {
    const totalCitations = format.citationsValue(this.props.citationStats.numCitations);
    const title = !this.props.hideLegendTitle
      ? `${totalCitations} ${getString(_ => _.citations.estimatedTotal.label)}`
      : null;
    if (title) {
      return <HistogramTitle className="total-citation-histogram__title" text={title} />;
    } else {
      return null;
    }
  }

  renderInfluentialCitations() {
    if (this.props.citationStats.numKeyCitations != 0) {
      const {
        citationStats: { numKeyCitations },
      } = this.props;
      const highlyInfluentialCount = numKeyCitations;
      const influentialText = util.pluralize(
        numKeyCitations,
        getString(_ => _.citations.influential.label),
        null,
        true
      );
      return (
        <div className="total-citation-histogram___highly-influential-stats">
          <em> {highlyInfluentialCount} </em>
          <span>{influentialText}</span>
        </div>
      );
    } else {
      return null;
    }
  }

  renderHistogramContent() {
    const bucketRenderers = [
      {
        buckets: this.props.citationStats.citedByBuckets,
        renderer: Histogram.bucketRenderers.selectedBuckets,
      },
    ];

    const currentYear = new Date().getFullYear();
    return (
      <div className="histogram__chart">
        <Histogram
          id={this.props.id}
          bucketRenderers={bucketRenderers}
          renderPopover={true}
          renderAxis={true}
          width={this.props.width}
          height={Histogram.DEFAULT_HEIGHT}
          paddingLeftPx={
            typeof this.props.paddingLeftPx === 'number'
              ? this.props.paddingLeftPx
              : Histogram.DefaultPaddingPx.LEFT
          }
          paddingRightPx={
            typeof this.props.paddingRightPx === 'number'
              ? this.props.paddingRightPx
              : Histogram.DefaultPaddingPx.RIGHT
          }
          paddingTopPx={
            typeof this.props.paddingTopPx === 'number'
              ? this.props.paddingTopPx
              : Histogram.DefaultPaddingPx.TOP
          }
          paddingBottomPx={
            typeof this.props.paddingBottomPx == 'number'
              ? this.props.paddingBottomPx
              : Histogram.DefaultPaddingPx.BOTTOM
          }
          highlightRecentYears={false}
          enableBarHoverEffect={false}
          minMaxYear={currentYear}
          popoverLabel={getString(_ => _.citations.histogram.popoverLabel)}
        />
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="box citation-histogram total-citation-histogram">
          {this.renderTitle()}
          {this.renderInfluentialCitations()}
        </div>
        <CLRule />
        <div className="box citation-histogram total-citation-histogram-content">
          {this.renderHistogramContent()}
        </div>
      </div>
    );
  }
}
