import { FieldOfStudyName, getFieldOfStudyByName } from '@/constants/FieldOfStudy';
import { optUserOccupationValue, UserOccupationValue } from '@/constants/UserOccupation';

import idx from 'idx';
import Immutable from 'immutable';

import type { DEPRECATED__FlowOptional, Nullable } from '@/utils/types';

export type FieldOfStudyFromJS = { id: string };

export type UserContactFromJS = {
  s2AccountEmail: DEPRECATED__FlowOptional<string>;
  firstName: DEPRECATED__FlowOptional<string>;
  lastName: DEPRECATED__FlowOptional<string>;
  currentOccupation: DEPRECATED__FlowOptional<{ value: string }>;
  fieldsOfStudy: DEPRECATED__FlowOptional<FieldOfStudyFromJS[]>;
  websiteUrl: DEPRECATED__FlowOptional<string>;
  hasCompletedDemographics: DEPRECATED__FlowOptional<boolean>;

  //Subscriptions
  productFeaturesSpotlightSubscription: DEPRECATED__FlowOptional<boolean>;
  semanticScholarNewsletterSubscription: DEPRECATED__FlowOptional<boolean>;
  allenInstituteNewsletterSubscription: DEPRECATED__FlowOptional<boolean>;
  developerUpdatesSubscription: DEPRECATED__FlowOptional<boolean>;
  accessibilityUpdatesSubscription: DEPRECATED__FlowOptional<boolean>;
};

type Props = {
  s2AccountEmail: Nullable<string>;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  currentOccupation: Nullable<UserOccupationValue>;
  fieldsOfStudy: FieldOfStudyName[];
  websiteUrl: Nullable<string>;
  hasCompletedDemographics: Nullable<boolean>;

  //Subscriptions
  productFeaturesSpotlightSubscription: Nullable<boolean>;
  semanticScholarNewsletterSubscription: Nullable<boolean>;
  allenInstituteNewsletterSubscription: Nullable<boolean>;
  developerUpdatesSubscription: Nullable<boolean>;
  accessibilityUpdatesSubscription: Nullable<boolean>;
};

const defaultProps: Props = {
  s2AccountEmail: null,
  firstName: null,
  lastName: null,
  currentOccupation: null,
  fieldsOfStudy: [],
  websiteUrl: null,
  hasCompletedDemographics: null,

  //Subscriptions
  productFeaturesSpotlightSubscription: null,
  semanticScholarNewsletterSubscription: null,
  allenInstituteNewsletterSubscription: null,
  developerUpdatesSubscription: null,
  accessibilityUpdatesSubscription: null,
};

export const UserContactRecordFactory = Immutable.Record<Props>(defaultProps);
export type UserContactRecord = Immutable.RecordOf<Props>;

export function getUserContactFromJS(args: UserContactFromJS): UserContactRecord {
  const fields = idx(args, _ => _.fieldsOfStudy);
  const optFields =
    fields &&
    fields.map(field => getFieldOfStudyByName(field.id)).map(_ => _.name as FieldOfStudyName);

  return UserContactRecordFactory({
    ...args,
    currentOccupation: optUserOccupationValue(args.currentOccupation?.value),
    fieldsOfStudy: optFields || [],
  });
}
