// This should be kept in sync with a corresponding scala-side model TBD

import { isString, Nullable } from '@/utils/types';

import invariant from 'invariant';

export type RecommendationStatus = keyof typeof byKey;

export const On = 'On';
export const Off = 'Off';
export const Unsupported = 'Unsupported';

const byKey = {
  On,
  Off,
  Unsupported,
} as const;

export const RecommendationStatusMap = Object.freeze(byKey);

export function getOptRecommendationStatus(
  optStatus?: Nullable<string>
): Nullable<RecommendationStatus> {
  if (!isString(optStatus)) {
    return null;
  }
  if (optStatus in byKey) {
    return byKey[optStatus];
  }
  return null;
}

export function getRecommendationStatus(optStatus?: Nullable<string>): RecommendationStatus {
  const expStatus = getOptRecommendationStatus(optStatus);
  invariant(expStatus, `"$optStatus || ''}" is not a valid RecommendationStatus`);
  return expStatus;
}
