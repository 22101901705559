import Immutable from 'immutable';

export type TermsStateFromJS = {
  showAcronyms: boolean;
  showTerms: boolean;
};

export type TermsState = {
  showAcronyms: boolean;
  showTerms: boolean;
};

export const TermsStateDefaultProps: TermsState = {
  showAcronyms: true,
  showTerms: true,
};

export const TERMS_RECORD_NAME = 'TermsState';
export const TermsStateRecordFactory = Immutable.Record<TermsState>(
  TermsStateDefaultProps,
  TERMS_RECORD_NAME
);
export type TermsStateRecord = Immutable.RecordOf<TermsState>;

export function getTermsStateFromJS(args: TermsStateFromJS): TermsStateRecord {
  return TermsStateRecordFactory({
    ...args,
  });
}
