import { RelationshipLabelRecord, RelationshipLabelRecordFactory } from './RelationshipLabel';

import Immutable from 'immutable';

export type RelatedEntityFromJS = {
  label: any;
  id: string;
  name: string;
  slug: string;
};

type Props = {
  label: RelationshipLabelRecord;
  id: string;
  name: string;
  slug: string;
};

const defaultProps: Props = {
  label: RelationshipLabelRecordFactory(),
  id: '',
  name: '',
  slug: '',
};

export const RelatedEntityRecordFactory = Immutable.Record<Props>(defaultProps);
export type RelatedEntityRecord = Immutable.RecordOf<Props>;

export function getRelatedEntityFromJS(args: RelatedEntityFromJS): RelatedEntityRecord {
  return RelatedEntityRecordFactory({
    ...args,
    label: RelationshipLabelRecordFactory(args.label),
  });
}
