const CitationActionName = {
  ResultList: {
    Impression: 'citation.results.show',
  },
  Result: {
    Author: {
      CLICK: 'citation.result.author.click',
    },
    Cite: {
      CLICK: 'citation.result.cite.click',
    },
    Library: {
      SAVE: 'citation.result.library.save',
    },
    Paper: {
      CLICK: 'citation.result.paper.click',
    },
    Title: {
      CLICK: 'citation.result.title.click',
    },
  },
} as const;

export default CitationActionName;
