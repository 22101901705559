import {
  initFirstPageLoadAnalytics,
  initPdfReaderAnalytics,
  PdfFirstPageLoadTimes,
  PdfLoadTimes,
} from '@/utils/reader-analytics-util';

import React from 'react';

import type { ReactNodeish } from '@/utils/types';

type Props = React.PropsWithChildren<{
  loadStartTimestamp: number;
}>;

export type ReaderAnalyticsContext = {
  readerAnalytics: PdfLoadTimes;
  firstPageLoadAnalytics: PdfFirstPageLoadTimes;
  setReaderAnalytics: (analytics: PdfLoadTimes) => void;
  setFirstPageLoadAnalytics: (firstPageLOadAnalytics: PdfFirstPageLoadTimes) => void;
};

const DEFAULT_CONTEXT: ReaderAnalyticsContext = {
  readerAnalytics: {
    pdfLoadMs: {
      startTimestamp: null,
      endTimestamp: null,
    },
  },
  firstPageLoadAnalytics: {
    firstPageLoadMs: {
      startTimestamp: null,
      endTimestamp: null,
    },
  },
  setReaderAnalytics: () => {},
  setFirstPageLoadAnalytics: () => {},
};

export const ReaderAnalyticsContext = React.createContext(DEFAULT_CONTEXT);

export function ReaderAnalyticsContextProvider({
  children,
  loadStartTimestamp,
}: Props): ReactNodeish {
  const [readerAnalytics, setReaderAnalytics] = React.useState(
    initPdfReaderAnalytics(loadStartTimestamp)
  );

  const [firstPageLoadAnalytics, setFirstPageLoadAnalytics] = React.useState(
    initFirstPageLoadAnalytics(loadStartTimestamp)
  );

  const analyticsProps: ReaderAnalyticsContext = {
    readerAnalytics,
    firstPageLoadAnalytics,
    setReaderAnalytics,
    setFirstPageLoadAnalytics,
  };

  return (
    <ReaderAnalyticsContext.Provider value={analyticsProps}>
      {children}
    </ReaderAnalyticsContext.Provider>
  );
}
