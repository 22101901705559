import { AuthorDetailsRecord } from '@/models/author/AuthorDetails';
import { Nullable } from '@/utils/types';
import AppContext from '@/AppContext';
import LoadingPage from '@/components/shared/loading/LoadingPage';
import S2Redirect from '@/models/redirects/S2Redirect';

import React from 'react';

import type { WillRouteToResult } from '@/router/Route';

type Props = object;

export default class AuthorWithoutSlugRedirect extends React.Component<Props> {
  static async willRouteTo(appContext: AppContext, routerState: any): Promise<WillRouteToResult> {
    const { authorStore, api } = appContext;
    const { authorId } = routerState.params || {};

    if (!authorId) {
      return new S2Redirect({ routeName: 'HOME' });
    }

    let authorDetails: Nullable<AuthorDetailsRecord> = authorStore.getAuthorDetails();
    if (!authorDetails || authorDetails.author?.id !== authorId) {
      await api.fetchAuthorDetail(authorId, /* slug */ null, /* requireSlug */ false);
      authorDetails = authorStore.getAuthorDetails();
    }

    const author = authorDetails?.author;
    if (!author || !author.id) {
      return new S2Redirect({ routeName: 'HOME' });
    }

    let { slug } = author;
    if (!slug || slug === '.') {
      slug = '_';
    }
    return new S2Redirect({
      replace: true,
      routeName: 'AUTHOR_PROFILE',
      params: { authorId: author.id, slug },
      query: routerState.query,
    });
  }

  render() {
    return <LoadingPage isLoading />;
  }
}
