import React from 'react';

export default function IconSettings(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 36 36">
      <path
        d="M36,5.6v2.25c0,0.63-0.51,1.14-1.14,1.14H20.25v1.14c0,0.6-0.51,1.11-1.11,1.11h-2.28c-0.6,0-1.11-0.51-1.11-1.14V8.99H1.14
  C0.51,8.99,0,8.48,0,7.88V5.6c0-0.63,0.51-1.14,1.14-1.14h14.64V3.38c-0.03-0.63,0.48-1.14,1.08-1.14h2.25
  c0.63,0,1.14,0.51,1.14,1.14v1.14h14.64C35.49,4.49,36,5,36,5.6z M36,16.88v2.25c0,0.6-0.51,1.11-1.14,1.11h-5.61v1.14
  c0,0.63-0.51,1.14-1.14,1.14h-2.25c-0.63,0-1.14-0.51-1.14-1.14v-1.14H1.14C0.51,20.23,0,19.72,0,19.1v-2.22
  c0-0.63,0.51-1.14,1.14-1.14h23.64V14.6c0-0.63,0.51-1.14,1.14-1.14h2.25c0.63,0,1.14,0.51,1.14,1.14v1.14h5.64
  C35.49,15.73,36,16.24,36,16.88z M36,28.09v2.25c0,0.63-0.51,1.14-1.14,1.14H11.25v1.14c0,0.63-0.51,1.14-1.14,1.14H7.86
  c-0.63,0-1.14-0.51-1.14-1.14v-1.14H1.14C0.51,31.48,0,30.97,0,30.37v-2.28c0-0.63,0.51-1.14,1.14-1.14h5.64v-1.08
  c0-0.63,0.51-1.14,1.14-1.14h2.25c0.63,0,1.14,0.51,1.14,1.14v1.14h23.64C35.49,26.98,36,27.49,36,28.09z"
      />
    </symbol>
  );
}
