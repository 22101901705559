import type { Nullable } from './utils/types';

// NOTE: Keep in sync with online/service/src/main/scala/org/allenai/s2/service/common/util/UserAgentUtil.scala

const DESKTOP_STR_MATCH = ['VR', 'Quest', 'Oculus', 'Minimo', 'Tizen', 'TV'] as const;

const MOBILE_STR_MATCH = [
  'Mobi',
  'PDA',
  'Mini',
  'Nokia',
  'HTC',
  'Kindle',
  'iOS',
  'Android',
  'JUC',
] as const;

/** Returns true if the provided user-agent string is indicative of a mobile device, false if not.
 * @param {string} userAgent the user agent string
 * @return {boolean}
 */
export function isMobileUserAgent(userAgent?: Nullable<string>): boolean {
  // Assume desktop if no string provided
  if (typeof userAgent !== 'string') {
    return false;
  }

  // Check for signs of desktop browser
  for (const str of DESKTOP_STR_MATCH) {
    if (userAgent.indexOf(str) !== -1) {
      return false;
    }
  }

  // Check for signs of mobile browser
  for (const str of MOBILE_STR_MATCH) {
    if (userAgent.indexOf(str) !== -1) {
      return true;
    }
  }

  // Assume the rest are desktop
  return false;
}
