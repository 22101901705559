import Immutable from 'immutable';

type Props = {
  id: string;
  name: string;
};

/**
 * JS representation for a org.allenai.common.Enum instance.
 * DEPRECATED: We have better ways to represent enum-like things in the webapp code.
 *             Don't use this!! It's here for legacy reasons only!
 */
export const EnumRecordFactory = Immutable.Record<Props>({
  id: '',
  name: '',
});
export type EnumRecord = Immutable.RecordOf<Props>;
