import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

export type MatchedAuthorFromJS = {
  id: Nullable<string>;
  name: Nullable<string>;
  slug: Nullable<string>;
  influentialCitationCount: Nullable<number>;
  citationCount: Nullable<number>;
  paperCount: Nullable<number>;
  affiliations: Nullable<any>;
};

type Props = {
  id: Nullable<string>;
  name: Nullable<string>;
  slug: Nullable<string>;
  influentialCitationCount: Nullable<number>;
  citationCount: Nullable<number>;
  paperCount: Nullable<number>;
  affiliations: Nullable<any>;
};

export const defaultProps: Props = {
  id: null,
  name: null,
  slug: null,
  influentialCitationCount: null,
  citationCount: null,
  paperCount: null,
  affiliations: null,
};

export const MatchedAuthorRecordFactory = Immutable.Record<Props>(defaultProps);

export type MatchedAuthorRecord = Immutable.RecordOf<Props>;

export function getMatchedAuthorFromJS(args: MatchedAuthorFromJS): MatchedAuthorRecord {
  return MatchedAuthorRecordFactory({
    ...args,
  });
}
