import Immutable from 'immutable';

type Props = {
  count: number;
  startKey: number;
  endKey: number;
};

const defaultProps: Props = {
  count: 0,
  startKey: 0,
  endKey: 0,
};

export const YearRangeBucketRecordFactory = Immutable.Record<Props>(defaultProps);
export type YearRangeBucketRecord = Immutable.RecordOf<Props>;
