import BaseStore from './BaseStore';

import {
  ApiResponse,
  CreateLibraryEntryBulkResponseBody,
  CreateLibraryFolderResponseBody,
  DeleteLibraryEntryBulkResponseBody,
  GetLibraryFoldersResponseBody,
  UpdateLibraryFolderResponseBody,
} from '@/api/ApiResponse';
import {
  getCorpusIdsFromLibraryEntryLite,
  LibraryEntryLiteFromJS,
} from '@/models/library/LibraryEntryLite';
import { getLibraryFolderFromJS, LibraryFolderRecord } from '@/models/library/LibraryFolder';
import { hasValue, Nullable } from '@/utils/types';
import { isSystemGeneratedFolder } from '@/models/library/LibraryFolderSourceType';
import { LibraryEntryFromJS } from '@/models/library/LibraryEntry';
import { PublicPromise } from '@/utils/promise-utils';
import { RecommendationStatus } from '@/models/library/RecommendationStatus';
import Constants from '@/constants';
import softError from '@/utils/softError';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import Immutable from 'immutable';

const CORPUS_IDS = 'corpusIds';
const PAPER_IDS = 'paperIds';

export default class LibraryFolderStore extends BaseStore {
  state: StoreStateValue;
  folders: Immutable.OrderedMap</* id */ number, LibraryFolderRecord>;
  unsortedPaperIds: Immutable.Set<string>;
  unsortedCorpusIds: Immutable.Set<number>;

  _ready: PublicPromise<void>;

  constructor(dispatcher) {
    super();

    this.state = StoreState.UNINITIALIZED;
    this._reset();

    this._ready = new PublicPromise();

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          switch (payload.requestType) {
            case Constants.requestTypes.GET_LIBRARY_FOLDERS: {
              this.state = StoreState.LOADING;
              this.emitChange();
              break;
            }
          }
          break;
        }

        case Constants.actions.API_REQUEST_COMPLETE: {
          switch (payload.requestType) {
            case Constants.requestTypes.GET_LIBRARY_FOLDERS: {
              const typedPayload: ApiResponse<GetLibraryFoldersResponseBody> = payload;
              const unsortedEntries: LibraryEntryLiteFromJS[] =
                typedPayload.resultData.unsortedLibraryEntries;
              const folderList: Immutable.List<[number, LibraryFolderRecord]> = Immutable.List(
                typedPayload.resultData.folders
              )
                .map(rawFolder => getLibraryFolderFromJS(rawFolder)) // Convert to models
                .sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })) // Sort by alpha (so Résumé comes after Réservé)
                .map(folder => [folder.id, folder]); // Use id for key in OrderedMap

              this.folders = Immutable.OrderedMap(folderList);
              this.unsortedPaperIds = Immutable.Set(unsortedEntries.map(entry => entry.paperId));
              this.unsortedCorpusIds = Immutable.Set(
                getCorpusIdsFromLibraryEntryLite(unsortedEntries)
              );
              this.state = StoreState.LOADED;
              this._ready.resolve();
              this.emitChange();
              break;
            }

            case Constants.requestTypes.LOGOUT: {
              this._reset();
              this.state = StoreState.UNINITIALIZED;
              this._ready.reject(new Error('Client signed out'));
              this.emitChange();
              break;
            }

            case Constants.requestTypes.CREATE_LIBRARY_FOLDER: {
              const typedPayload: ApiResponse<CreateLibraryFolderResponseBody> = payload;
              const { folder } = typedPayload.resultData;
              if (folder) {
                this.folders = this.folders.set(folder.id, getLibraryFolderFromJS(folder));
                this.emitChange();
              } else {
                softError(
                  'library-folder-store.error',
                  'create_library_folder did not return a valid folder payload'
                );
              }
              break;
            }

            case Constants.requestTypes.UPDATE_LIBRARY_FOLDER: {
              const typedPayload: ApiResponse<UpdateLibraryFolderResponseBody> = payload;
              const rawFolder = typedPayload.resultData.folder;
              const folderId = rawFolder.id;
              const oldFolder = this.getFolderById(folderId);
              if (!oldFolder) {
                softError(
                  'library-folder-store.error',
                  `update_library_folder did not return a valid folder payload [folderId=${folderId}]`
                );
                break;
              }

              const newFolder = getLibraryFolderFromJS(rawFolder);
              this.folders = this.folders.set(folderId, newFolder);
              this.emitChange();
              break;
            }

            case Constants.requestTypes.DELETE_LIBRARY_FOLDER: {
              // get folder ID from path
              const { path } = payload;
              const deletedId = Number.parseInt(path.split('/').slice(-1));
              if (Number.isInteger(deletedId)) {
                this.folders = this.folders.delete(deletedId);
                this.emitChange(payload.requestType);
              } else {
                softError(
                  'library-folder-store.error',
                  'delete_library_folder did not return a valid folder id payload'
                );
              }
              break;
            }

            case Constants.requestTypes.CREATE_LIBRARY_ENTRY_BULK: {
              const typedPayload: ApiResponse<CreateLibraryEntryBulkResponseBody> = payload;
              for (const entry of typedPayload.resultData.entries) {
                const existingFolderRecord = entry?.folderId
                  ? this.folders.get(entry.folderId)
                  : null;
                if (!entry.folderId) {
                  this.#addEntryToUnsorted(entry);
                } else if (existingFolderRecord) {
                  this.#addEntryToFolder(entry, existingFolderRecord);
                }
              }
              this.emitChange();
              break;
            }

            case Constants.requestTypes.DELETE_LIBRARY_ENTRIES_BULK: {
              const typedPayload: ApiResponse<DeleteLibraryEntryBulkResponseBody> = payload;
              const deletedPaperId = typedPayload.resultData.paperId;
              const deletedCorpusId = typedPayload.resultData.corpusId;
              // remove entries from folders' entries
              this.folders.forEach(folder => {
                let newFolderRecord = folder.set(PAPER_IDS, folder.paperIds.remove(deletedPaperId));

                if (deletedCorpusId) {
                  newFolderRecord = newFolderRecord.set(
                    CORPUS_IDS,
                    newFolderRecord.corpusIds.remove(deletedCorpusId)
                  );
                }
                this.folders = this.folders.set(folder.id, newFolderRecord);
              });

              // remove entries from unsortedLibraryEntries
              this.unsortedPaperIds = this.unsortedPaperIds.remove(deletedPaperId);
              this.unsortedCorpusIds = deletedCorpusId
                ? this.unsortedCorpusIds.remove(deletedCorpusId)
                : this.unsortedCorpusIds;
              this.emitChange();
              break;
            }
          }
          break;
        }
      }
    });
  }

  // Set all the store variables to empty
  _reset(): void {
    this.folders = Immutable.OrderedMap();
    this.unsortedPaperIds = Immutable.Set();
    this.unsortedCorpusIds = Immutable.Set();
  }

  getPaperCount(folderId: number): Nullable<number> {
    const optFolder = this.folders.get(folderId);
    return optFolder ? optFolder.paperIds.size : null;
  }

  getUnsortedPaperIds(): Immutable.Set<string> {
    return this.unsortedPaperIds;
  }

  getUnsortedCorpusIds(): Immutable.Set<number> {
    return this.unsortedCorpusIds;
  }

  getAllFolderPaperIds(): Immutable.Set<string> {
    const nestedArray = this.folders
      .valueSeq()
      .map(folder => folder.paperIds.toArray())
      .toArray();
    const allFolderPaperIds = nestedArray.reduce((a, b) => {
      return a.concat(b);
    }, []);
    return Immutable.Set(allFolderPaperIds);
  }

  getAllFolderCorpusIds(): Immutable.Set<number> {
    const nestedArray = this.folders
      .valueSeq()
      .map(folder => folder.corpusIds.toArray())
      .toArray();
    const allFolderCorpusIds = nestedArray.reduce((a, b) => {
      return a.concat(b);
    }, []);
    return Immutable.Set(allFolderCorpusIds);
  }

  getUnsortedPaperCount(): number {
    return this.unsortedPaperIds.size;
  }

  getTotalPaperCount(): number {
    return this.getUnsortedPaperCount() + this.getAllFolderPaperIds().size;
  }

  getFolders(): Immutable.OrderedMap<number, LibraryFolderRecord> {
    return this.folders;
  }

  getUserGeneratedFolders(): Immutable.OrderedMap<number, LibraryFolderRecord> {
    return this.folders.filter(folder => !isSystemGeneratedFolder(folder));
  }

  getFolderById(id?: Nullable<number>): Nullable<LibraryFolderRecord> {
    if (!hasValue(id)) {
      return null;
    }
    return this.folders.valueSeq().find(folder => folder.id === id) || null;
  }

  isLoading(): boolean {
    return this.isUninitialized() || this.state === StoreState.LOADING;
  }

  isUninitialized(): boolean {
    return this.state === StoreState.UNINITIALIZED;
  }

  isPaperInAnyFolder(paperId: string): boolean {
    return !!this.folders.find(folder => folder.paperIds.has(paperId));
  }

  isPaperInUnsorted(paperId: string): boolean {
    return this.unsortedPaperIds.has(paperId);
  }

  isPaperInLibrary(paperId: string): boolean {
    return this.isPaperInUnsorted(paperId) || this.isPaperInAnyFolder(paperId);
  }

  isPaperWithCorpusIdInAnyFolder(corpusId: number): boolean {
    return !!this.folders.find(folder => folder.corpusIds.has(corpusId));
  }

  isPaperWithCorpusIdInUnsorted(corpusId: number): boolean {
    return this.unsortedCorpusIds.has(corpusId);
  }

  isPaperWithCorpusIdInLibrary(corpusId: number): boolean {
    return (
      this.isPaperWithCorpusIdInUnsorted(corpusId) || this.isPaperWithCorpusIdInAnyFolder(corpusId)
    );
  }

  async ready(): Promise<void> {
    await this._ready;
  }

  getFoldersWithPaperId(paperId?: Nullable<string>): Immutable.List<LibraryFolderRecord> {
    if (!hasValue(paperId)) {
      return Immutable.List();
    }
    return this.folders
      .filter(folder => folder.paperIds.has(paperId))
      .valueSeq()
      .toList();
  }

  getFoldersWithCorpusId(corpusId?: Nullable<number>): Immutable.List<LibraryFolderRecord> {
    if (!hasValue(corpusId)) {
      return Immutable.List();
    }
    return this.folders
      .filter(folder => folder.corpusIds.has(corpusId))
      .valueSeq()
      .toList();
  }

  getFoldersWithRecommendationStatus(
    status: RecommendationStatus
  ): Immutable.List<LibraryFolderRecord> {
    return this.folders
      .filter(folder => folder.recommendationStatus === status)
      .valueSeq()
      .toList();
  }

  #addEntryToUnsorted(entry: LibraryEntryFromJS): void {
    const maybePaperId = entry?.paper?.id || entry?.paperId;
    const maybeCorpusId = entry?.paper?.corpusId || entry?.corpusId;

    if (maybePaperId) {
      this.unsortedPaperIds = this.unsortedPaperIds.add(maybePaperId);
    }

    if (maybeCorpusId) {
      this.unsortedCorpusIds = this.unsortedCorpusIds.add(maybeCorpusId);
    }
  }

  #addEntryToFolder(entry: LibraryEntryFromJS, folder: LibraryFolderRecord): void {
    if (!folder || !entry) {
      return;
    }

    const maybePaperId = entry?.paper?.id || entry?.paperId;
    const maybeCorpusId = entry?.paper?.corpusId || entry?.corpusId;
    let newFolderRecord = folder;

    if (maybePaperId) {
      newFolderRecord = newFolderRecord.set(PAPER_IDS, newFolderRecord.paperIds.add(maybePaperId));
    }

    if (maybeCorpusId) {
      newFolderRecord = newFolderRecord.set(
        CORPUS_IDS,
        newFolderRecord.corpusIds.add(maybeCorpusId)
      );
    }

    this.folders = this.folders.set(folder.id, newFolderRecord);
  }
}
