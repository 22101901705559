import { getString } from '@/content/i18n';
import { heapLibraryNavAddFolderButton } from '@/analytics/attributes/libraryPage';
import { KEY_CODE_ESC, KEY_CODE_LEFT } from '@/constants/KeyCode';
import { LibraryFolderRecord } from '@/models/library/LibraryFolder';
import { mkOnClickKeyDown, OnClickKeyDownOutput } from '@/utils/a11y-utils';
import { Public } from '@/models/library/PrivacySetting';
import Icon from '@/components/shared/icon/Icon';
import LibraryFolderName from '@/components/shared/library/LibraryFolderName';
import LibraryFolderStore from '@/stores/LibraryFolderStore';
import LibraryNavAddFolder from '@/components/shared/library/LibraryNavAddFolder';
import Link from '@/router/Link';

import classnames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

const STOP_PROP = event => void event.stopPropagation();
const TIMEOUT_DELAY_MS = 500;

type Props = {
  onClickClose: () => void;
};

type StateFromLibraryFolderStore = { folders: Immutable.List<LibraryFolderRecord> };

type State = {
  isHidden: boolean;
} & StateFromLibraryFolderStore;

export default class MobileLibraryNavMenu extends React.PureComponent<Props, State> {
  static contextTypes = {
    libraryFolderStore: PropTypes.instanceOf(LibraryFolderStore).isRequired,
  };

  declare context: {
    libraryFolderStore: LibraryFolderStore;
  };

  constructor(...args: [any]) {
    super(...args);
    this.state = {
      isHidden: false,
      ...this.getStateFromLibraryFolderStore(),
    };

    this.context.libraryFolderStore.registerComponent(this, () => {
      this.setState(this.getStateFromLibraryFolderStore());
    });
  }

  getStateFromLibraryFolderStore = (): StateFromLibraryFolderStore => {
    const libraryFolderStore: LibraryFolderStore = this.context.libraryFolderStore;

    return {
      folders: libraryFolderStore.getFolders().valueSeq().toList(),
    };
  };

  hideMenu = (): void => {
    const { onClickClose } = this.props;

    this.setState({ isHidden: true });
    // set timeout so animation to slide menu out finishes before closing the modal
    setTimeout(onClickClose, TIMEOUT_DELAY_MS);
  };

  _onClickKeyDownHideMenuProps: OnClickKeyDownOutput<EventTarget> = mkOnClickKeyDown({
    onClick: this.hideMenu,
    overrideKeys: [KEY_CODE_ESC, KEY_CODE_LEFT],
  });

  _onClickKeyDownStopProps: OnClickKeyDownOutput<EventTarget> = mkOnClickKeyDown({
    onClick: STOP_PROP,
    overrideKeys: [KEY_CODE_ESC, KEY_CODE_LEFT],
  });

  render(): React.ReactNode {
    const { folders } = this.state;
    return (
      <div className="mobile-nav__overlay-menu" {...this._onClickKeyDownHideMenuProps}>
        <div
          className={classnames('mobile-nav__overlay-menu__content', {
            'animate-out': this.state.isHidden,
            'animate-in': !this.state.isHidden,
          })}
          {...this._onClickKeyDownStopProps}>
          <div
            className="mobile-nav__overlay-menu__sidebar"
            {...this._onClickKeyDownHideMenuProps}
            role="button">
            <Icon icon="disclosure" width="16" height="16" className="mobile-nav__arrow-icon" />
          </div>
          <div className="mobile-nav__contents">
            <div className="mobile-nav__scrollable-container">
              <div className="mobile-nav__section mobile-nav__library-folders">
                <p className="mobile-nav__section-header">
                  {getString(_ => _.account.mobileMenu.research.library)}
                </p>
                <ul>
                  <li>
                    <Link
                      className="mobile-nav__section-link mobile-nav__link-row--dense"
                      to="LIBRARY_ALL_ENTRIES"
                      aria-label={getString(_ => _.account.mobileMenu.research.allPapers)}>
                      <React.Fragment>
                        <Icon icon="fa-page" height="16" width="12" className="mobile-nav__icon" />
                        {getString(_ => _.account.mobileMenu.research.allPapers)}
                      </React.Fragment>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="mobile-nav__section-link mobile-nav__link-row--dense"
                      to="LIBRARY_UNSORTED_ENTRIES"
                      aria-label={getString(_ => _.account.mobileMenu.research.unsortedPapers)}>
                      <React.Fragment>
                        <Icon icon="fa-inbox" height="11" width="16" className="mobile-nav__icon" />
                        {getString(_ => _.account.mobileMenu.research.unsortedPapers)}
                      </React.Fragment>
                    </Link>
                  </li>
                  <li className="mobile-nav__library-folders__list">
                    {folders.map(folder => {
                      return (
                        <LibraryFolderName
                          folder={folder}
                          key={folder.id}
                          isPublic={folder.privacySetting === Public}
                        />
                      );
                    })}
                  </li>
                </ul>
              </div>
            </div>
            <div className="mobile-nav__library__create-folder">
              <LibraryNavAddFolder heapLinkProps={heapLibraryNavAddFolderButton()} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
