import BaseStore from './BaseStore';

import { AUTHOR_CUES_PRIORITY } from '@/utils/personalized-cues-util';
import {
  CueDataWrapperFromJS,
  CueDataWrapperRecord,
  CueDataWrapperRecordFactory,
  getCueDataWrapperFromJS,
} from '@/models/CueDataWrapper';
import {
  CuePaperRecord,
  getCuePaperFromGraphQL,
  getCuePaperFromPaperRecord,
} from '@/models/CuePaper';
import { getLibraryEntryFromJS, LibraryEntryRecord } from '@/models/library/LibraryEntry';
import Constants from '@/constants';
import S2Dispatcher from '@/utils/S2Dispatcher';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import Immutable from 'immutable';

import type { ApiRequestStartingPayload } from '@/api/BaseApi';
import type { ApiResponse } from '@/api/ApiResponse';
import type { Nullable } from '@/utils/types';

export type AuthorId = string;
export type PaperId = string;

export default class CueStore extends BaseStore {
  #libraryCuesStoreState: StoreStateValue;
  #cuePapersStoreState: StoreStateValue;
  #authorCuesStoreState: StoreStateValue;
  #cuesStoreState: StoreStateValue;
  #entriesByPaperIdMap: Immutable.Map<PaperId, LibraryEntryRecord>;
  #cuePapersByPaperIdMap: Immutable.Map<PaperId, CuePaperRecord>;
  #authorCuesDataByAuthorId: Immutable.Map<AuthorId, CueDataWrapperRecord>;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#libraryCuesStoreState = StoreState.UNINITIALIZED;
    this.#cuePapersStoreState = StoreState.UNINITIALIZED;
    this.#authorCuesStoreState = StoreState.UNINITIALIZED;
    this.#cuesStoreState = StoreState.UNINITIALIZED;
    this.#entriesByPaperIdMap = Immutable.Map();
    this.#cuePapersByPaperIdMap = Immutable.Map();
    this.#authorCuesDataByAuthorId = Immutable.Map();

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const startingPayload = payload as ApiRequestStartingPayload;
          switch (startingPayload.requestType) {
            case Constants.requestTypes.FETCH_LIBRARY_CUE_DATA: {
              this.#handleFetchLibraryCueDataStart();
              this.emitChange();
              return;
            }
            case Constants.requestTypes.GQL__CUE_PAPERS: {
              this.#handleFetchCuePaperDataStart();
              this.emitChange();
              return;
            }
            case Constants.requestTypes.FETCH_AUTHOR_CUE_DATA: {
              this.#handleFetchAuthorCueDataStart();
              this.emitChange();
              return;
            }
            case Constants.requestTypes.SEARCH: {
              this.#handlSearchStart();
              this.emitChange();
              return;
            }
          }
          return;
        }
        case Constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.FETCH_LIBRARY_CUE_DATA: {
              this.#handleFetchLibraryCueDataComplete(apiResponse);
              this.emitChange();
              return;
            }
            case Constants.requestTypes.GQL__CUE_PAPERS: {
              this.#handleFetchCuePaperDataComplete(apiResponse);
              this.emitChange();
              return;
            }
            case Constants.requestTypes.FETCH_AUTHOR_CUE_DATA: {
              this.#handleFetchAuthorCueDataComplete(apiResponse);
              this.emitChange();
              return;
            }
            case Constants.requestTypes.SEARCH: {
              this.#handleSearchComplete(apiResponse);
              this.emitChange();
              return;
            }
          }
          return;
        }
      }
    });
  }

  #handleFetchLibraryCueDataStart(): void {
    this.#libraryCuesStoreState = StoreState.LOADING;
  }

  #handleFetchCuePaperDataStart(): void {
    this.#cuePapersStoreState = StoreState.LOADING;
  }

  #handleFetchAuthorCueDataStart(): void {
    this.#authorCuesStoreState = StoreState.LOADING;
  }

  #handlSearchStart(): void {
    this.#cuesStoreState = StoreState.LOADING;
  }

  #handleFetchLibraryCueDataComplete(payload: ApiResponse): void {
    payload.resultData.libraryEntries.map(entry => {
      const maybeExisitingEntry = this.#entriesByPaperIdMap.get(entry.paperId);
      if (maybeExisitingEntry && maybeExisitingEntry.createdAtUtc > entry.createdAtUtc) {
        return;
      }
      this.#entriesByPaperIdMap = this.#entriesByPaperIdMap.set(
        entry.paperId,
        getLibraryEntryFromJS(entry)
      );
    });
    this.#libraryCuesStoreState = StoreState.LOADED;
  }

  #handleFetchCuePaperDataComplete(payload: ApiResponse): void {
    payload.resultData.data.papers.map(cuePaper => {
      this.#cuePapersByPaperIdMap = this.#cuePapersByPaperIdMap.set(
        cuePaper.id,
        getCuePaperFromGraphQL(cuePaper)
      );
    });
    this.#cuePapersStoreState = StoreState.LOADED;
  }

  #handleFetchAuthorCueDataComplete(payload: ApiResponse): void {
    const rawPaperIds = payload.resultData.papers.map(rawPaper => {
      this.#cuePapersByPaperIdMap = this.#cuePapersByPaperIdMap.set(
        rawPaper.id,
        getCuePaperFromPaperRecord(rawPaper)
      );
      return rawPaper.id;
    });

    const oldAuthorCueData = this.#authorCuesDataByAuthorId.get(payload.context.authorId);
    if (oldAuthorCueData && oldAuthorCueData.authorId) {
      this.#authorCuesDataByAuthorId = this.#authorCuesDataByAuthorId.set(
        oldAuthorCueData.authorId,
        CueDataWrapperRecordFactory({
          authorId: oldAuthorCueData.authorId,
          cueType: oldAuthorCueData.cueType,
          paperIds: Immutable.List(rawPaperIds),
        })
      );
    }
    this.#authorCuesStoreState = StoreState.LOADED;
  }

  // handles author cues from search response
  #handleSearchComplete(response: ApiResponse): void {
    const results = response.resultData.results;
    for (const paper of results) {
      // we need the author cues priorty to exclude the paper cues and to make sure
      // we are only saving the most prioritized author cue for an author to the map
      for (const cueType of AUTHOR_CUES_PRIORITY) {
        const rawCues = paper.cues[cueType] || [];
        for (const rawCue of rawCues) {
          this.#maybeAddAuthorCueToMap(rawCue);
        }
      }
    }
    this.#cuesStoreState = StoreState.LOADED;
  }

  #maybeAddAuthorCueToMap(rawCue: CueDataWrapperFromJS): void {
    if (rawCue.authorId && !this.#authorCuesDataByAuthorId.has(rawCue.authorId)) {
      const cue = getCueDataWrapperFromJS(rawCue);
      if (cue.authorId) {
        this.#authorCuesDataByAuthorId = this.#authorCuesDataByAuthorId.set(cue.authorId, cue);
      }
    }
  }

  isLibraryCueUninitialized(): boolean {
    return !this.#libraryCuesStoreState || this.#libraryCuesStoreState === StoreState.UNINITIALIZED;
  }

  isCuePaperUninitialized(): boolean {
    return !this.#cuePapersStoreState || this.#cuePapersStoreState === StoreState.UNINITIALIZED;
  }

  isAuthorCueUninitialized(): boolean {
    return !this.#authorCuesStoreState || this.#authorCuesStoreState === StoreState.UNINITIALIZED;
  }

  isLibraryCueLoading(): boolean {
    return this.isLibraryCueUninitialized() || this.#libraryCuesStoreState === StoreState.LOADING;
  }

  isCuePaperLoading(): boolean {
    return this.isCuePaperUninitialized() || this.#cuePapersStoreState === StoreState.LOADING;
  }

  isAuthorCueLoading(): boolean {
    return this.isAuthorCueUninitialized() || this.#authorCuesStoreState === StoreState.LOADING;
  }

  areCuesLoading(): boolean {
    return (
      !this.#cuesStoreState ||
      this.#cuesStoreState === StoreState.UNINITIALIZED ||
      this.#cuesStoreState === StoreState.LOADING
    );
  }

  getEntryByPaperId(paperId: PaperId): Nullable<LibraryEntryRecord> {
    return this.#entriesByPaperIdMap.get(paperId) || null;
  }

  getEntries(): Immutable.Map<PaperId, LibraryEntryRecord> {
    return this.#entriesByPaperIdMap;
  }

  getCuePaperByPaperId(paperId: PaperId): Nullable<CuePaperRecord> {
    return this.#cuePapersByPaperIdMap.get(paperId) || null;
  }

  getCuePapers(): Immutable.Map<PaperId, CuePaperRecord> {
    return this.#cuePapersByPaperIdMap;
  }

  isAuthorPersonalized(authorId: AuthorId): boolean {
    return this.#authorCuesDataByAuthorId.has(authorId);
  }

  getPrioritizedAuthorCueByAuthorId(authorId: Nullable<AuthorId>): Nullable<CueDataWrapperRecord> {
    if (!authorId) {
      return null;
    }
    return this.#authorCuesDataByAuthorId.get(authorId) || null;
  }

  getAuthorCuesDataMap(): Immutable.Map<AuthorId, CueDataWrapperRecord> {
    return this.#authorCuesDataByAuthorId;
  }
}
