import Redirect from './Redirect';

import { isBoolean, Nullable } from '@/utils/types';
import { mkRouterObj } from '@/router/routerUtils';
import { PathParams, RouteName } from '@/router/Routes';

export default class S2Redirect extends Redirect {
  pathname?: string;
  search?: string;
  replace: boolean;

  constructor({
    routeName,
    params,
    query,
    replace = false,
    status,
    path,
  }: {
    params?: Nullable<PathParams>;
    query?: object;
    replace?: boolean;
    status?: number;
  } & ({ routeName: RouteName; path?: string } | { path: string; routeName?: RouteName })) {
    super({ status });
    const { pathname, search } = mkRouterObj({ path, routeName, params, query });
    this.pathname = pathname;
    this.search = search;
    this.replace = isBoolean(replace) ? replace : false;
  }
}
