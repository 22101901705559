import Immutable from 'immutable';

type Props = {
  parentToChild: string;
  childToParent: string;
};

const defaultProps: Props = {
  parentToChild: '',
  childToParent: '',
};

export const RelationshipLabelRecordFactory = Immutable.Record<Props>(defaultProps);
export type RelationshipLabelRecord = Immutable.RecordOf<Props>;
