import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

type SourceId = {
  id: string;
  source: {
    id: string;
  };
};

export type DebugInfoFromJS = {
  sourceIds: SourceId[];
  sourceUris: string[];
  duplicateIds: string[];
  earliestAcquisitionDate: Nullable<number>;
  corpusId: Nullable<number>;
};

type Props = {
  sourceIds: Immutable.List<SourceId>;
  sourceUris: Immutable.List<string>;
  duplicateIds: Immutable.List<string>;
  earliestAcquisitionDate: Nullable<number>;
  corpusId: Nullable<number>;
};

export const defaultProps: Props = {
  sourceIds: Immutable.List(),
  sourceUris: Immutable.List(),
  duplicateIds: Immutable.List(),
  earliestAcquisitionDate: null,
  corpusId: null,
};

export const DebugInfoRecordFactory = Immutable.Record<Props>(defaultProps);

export type DebugInfoRecord = Immutable.RecordOf<Props>;

export function debugInfoFromJs(args: DebugInfoFromJS): DebugInfoRecord {
  return DebugInfoRecordFactory({
    ...args,
    sourceIds: Immutable.List(args.sourceIds),
    sourceUris: Immutable.List(args.sourceUris),
    duplicateIds: Immutable.List(args.duplicateIds),
  });
}
