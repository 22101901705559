import CLButtonBase, { CLButtonBaseProps } from './CLButtonBase';

import {
  LOGIN_ACTION as _LOGIN_ACTION,
  LOGIN_METHOD_ID as _LOGIN_METHOD_ID,
  LoginAction,
  LoginMethodId,
} from '@/constants/LoginMethods';
import { getString } from '@/content/i18n';
import Icon from '@/components/shared/icon/Icon';

import classnames from 'classnames';
import React from 'react';

import type { ReactNodeish } from '@/utils/types';

export const LOGIN_ACTION = _LOGIN_ACTION;
export const LOGIN_METHOD_ID = _LOGIN_METHOD_ID;

export type CLLoginButtonProps = {
  method: LoginMethodId;
  action: LoginAction;
} & CLButtonBaseProps;

export default function CLLoginButton({
  method,
  action,
  className,
  ...props
}: CLLoginButtonProps): ReactNodeish {
  return (
    <CLButtonBase
      {...props}
      icon={getMethodIcon(method)}
      label={getString(_ => _.login.method[method][`${action}Label`])}
      className={classnames('cl-login-button', `cl-login-button--method-${method}`, className)}
    />
  );
}

export function getMethodIcon(provider: LoginMethodId): ReactNodeish {
  switch (provider) {
    case LOGIN_METHOD_ID.COGNITO:
      return <Icon icon="cognito-login" />;
    case LOGIN_METHOD_ID.GOOGLE:
      return <Icon icon="google-login" />;
    case LOGIN_METHOD_ID.FACEBOOK:
      return <Icon icon="facebook-login" />;
    case LOGIN_METHOD_ID.TWITTER:
      return <Icon icon="twitter-login" />;
    case LOGIN_METHOD_ID.OPENATHENS:
      return <Icon icon="openathens-login" />;
    default:
      return <Icon icon="user" />;
  }
}

export const exampleConfig = {
  getComponent: () => CLLoginButton,
  fields: [
    {
      name: 'method',
      desc: 'Provider which will be initiated when the button is clicked',
      value: {
        type: 'select',
        default: LOGIN_METHOD_ID.GOOGLE,
        options: Object.values(LOGIN_METHOD_ID),
      },
    },
    {
      name: 'action',
      desc: 'Whether the form is for creating an account (vs. signing into an existing one)',
      value: {
        type: 'select',
        default: LOGIN_ACTION.SIGN_IN,
        options: Object.values(LOGIN_ACTION),
      },
    },
    {
      name: 'className',
      desc: 'HTML classes to be added to the button',
      value: {
        type: 'text',
        default: '',
      },
    },
  ],

  examples: [
    {
      title: 'Google',
      desc: 'Login button for Google',
      props: {
        method: LOGIN_METHOD_ID.GOOGLE,
      },
      render: comp => (
        <div
          style={{
            border: '1px solid #ccc',
            minWidth: '200px',
            maxWidth: '500px',
            padding: '1rem',
            margin: '1rem',
          }}>
          <h2>Sign In to Semantic Scholar</h2>
          <div style={{ margin: '1rem 0' }}>{comp}</div>
          <div>
            Don't have an account?
            <button style={{ color: '#1857b6', background: 'none' }}>Create One.</button>
          </div>
        </div>
      ),
    },
    {
      title: 'Facebook',
      desc: 'Login button for Facebook',
      props: {
        method: LOGIN_METHOD_ID.FACEBOOK,
      },
      render: comp => (
        <div
          style={{
            border: '1px solid #ccc',
            minWidth: '200px',
            maxWidth: '500px',
            padding: '1rem',
            margin: '1rem',
          }}>
          <h2>Sign In to Semantic Scholar</h2>
          <div style={{ margin: '1rem 0' }}>{comp}</div>
          <div>
            Don't have an account?
            <button style={{ color: '#1857b6', background: 'none' }}>Create One.</button>
          </div>
        </div>
      ),
    },
    {
      title: 'Twitter',
      desc: 'Login button for Twitter',
      props: {
        method: LOGIN_METHOD_ID.TWITTER,
      },
      render: comp => (
        <div
          style={{
            border: '1px solid #ccc',
            minWidth: '200px',
            maxWidth: '500px',
            padding: '1rem',
            margin: '1rem',
          }}>
          <h2>Sign In to Semantic Scholar</h2>
          <div style={{ margin: '1rem 0' }}>{comp}</div>
          <div>
            Don't have an account?
            <button style={{ color: '#1857b6', background: 'none' }}>Create One.</button>
          </div>
        </div>
      ),
    },
    {
      title: 'Open Athens',
      desc: 'Login button for Open Athens',
      props: {
        method: LOGIN_METHOD_ID.OPENATHENS,
      },
      render: comp => (
        <div
          style={{
            border: '1px solid #ccc',
            minWidth: '200px',
            maxWidth: '500px',
            padding: '1rem',
            margin: '1rem',
          }}>
          <h2>Sign In to Semantic Scholar</h2>
          <div style={{ margin: '1rem 0' }}>{comp}</div>
          <div>
            Don't have an account?
            <button style={{ color: '#1857b6', background: 'none' }}>Create One.</button>
          </div>
        </div>
      ),
    },
    {
      title: 'Cognito',
      desc: 'Login button for Cognito',
      props: {
        method: LOGIN_METHOD_ID.COGNITO,
      },
      render: comp => (
        <div
          style={{
            border: '1px solid #ccc',
            minWidth: '200px',
            maxWidth: '500px',
            padding: '1rem',
            margin: '1rem',
          }}>
          <h2>Sign In to Semantic Scholar</h2>
          <div style={{ margin: '1rem 0' }}>{comp}</div>
          <div>
            Don't have an account?
            <button style={{ color: '#1857b6', background: 'none' }}>Create One.</button>
          </div>
        </div>
      ),
    },
  ],

  events: {
    onClick: event => ({
      label: event.currentTarget.innerText,
      class: event.currentTarget.className,
    }),
  },
};
