import CLRuleBase from './CLRuleBase';

import React from 'react';

export default function CLRule(props) {
  return <CLRuleBase {...props} />;
}

CLRule.defaultProps = {
  isFullWidth: false,
};
