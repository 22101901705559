import Experiment, {
  ExperimentKey,
  getDefaultVariationForExperiment,
  getExperimentList,
  VariationKey,
} from './Experiment';
import ExperimentManager from './ExperimentManager';
import Feature, { FeatureKey, getFeatureList } from './Feature';
import FeatureManager from './FeatureManager';
import WeblabConfig, { S2WeblabConfigObj } from './WeblabConfig';

import { HeapPropValue, isHeapPropKeyValid, runWithHeap } from '@/analytics/Heap';
import { isBrowser } from '@/utils/env';
import { isString, Nullable, RequestWithMiddleware } from '@/utils/types';
import { taggedSoftInvariant } from '@/utils/softError';
import {
  TID,
  WEBLAB_FORCED_FEATURES,
  WEBLAB_FORCED_VARIATIONS,
  WEBLAB_OPT_OUT,
} from '@/constants/UserCookie';
import Api from '@/api/Api';
import BaseStore from '@/stores/BaseStore';
import CookieJar from '@/utils/cookies/CookieJar';
import logger from '@/logger';

import Immutable from 'immutable';
import invariant from 'invariant';
import qs from 'qs';
import url from 'url';

import type { EnrollmentValue } from './Enrollment';
import type { FeatureRecord } from './FeatureRecord';
import type AuthStore from '@/stores/AuthStore';

const softInvariant = taggedSoftInvariant('weblabStore');

export const COOKIE_REGEX_HEAP_PROPS = /^_hp2_props\.\d+$/;
const HOURS_TO_SEC = 60 * 60;
export const COOKIE_FORCED_MAX_AGE_SEC = 1 * HOURS_TO_SEC; // 1 hour, since this is mostly used for debugging and demoing
export const OVERRIDE_RESET_VALUE = 'reset'; // String used to reset overrides

// Window sizes that should be tracked in Heap
// TODO: (#) Remove after 2024-06-30
const HEAP_KEY_IS_RETURNING_TMPL = 'Is $1-day Returning (Non-BoD)'; // $1 = number of days for window
const RETURN_WINDOWS_TO_REMOVE = [1, 7, 14, 28, 90];

const OBSOLETE_PROP_KEYS = [
  'is_exposed',
  ...RETURN_WINDOWS_TO_REMOVE.map(days =>
    HEAP_KEY_IS_RETURNING_TMPL.replace('$1', days.toString())
  ),
];

/**
  Weblab Store coordinates fetching experiment states from ExperimentManager and logging them to Heap
*/
export default class WeblabStore extends BaseStore {
  api: Api;
  cookieJar: CookieJar;
  weblabConfig: WeblabConfig;
  forcedFeatures: Nullable<Immutable.Map<FeatureKey, boolean>>;
  forcedVariations: Nullable<Immutable.Map<ExperimentKey, VariationKey>>;
  experimentManager: Nullable<ExperimentManager>;
  featureManager: Nullable<FeatureManager>;
  authStore: AuthStore;
  optOut: boolean;
  tid: string;
  experimentMap: typeof Experiment;

  getDefaultVariationForExperimentKey: (key: ExperimentKey) => VariationKey;

  constructor(
    clientRequest: RequestWithMiddleware,
    cookieJar: CookieJar,
    experimentMap?: typeof Experiment
  ) {
    super();

    this.cookieJar = cookieJar;
    this.experimentMap = experimentMap || Experiment;
    this.getDefaultVariationForExperimentKey = getDefaultVariationForExperiment(this.experimentMap);

    let urlParts: url.UrlWithStringQuery;
    if (isBrowser()) {
      urlParts = url.parse(location.toString());
    } else if (clientRequest) {
      urlParts = url.parse(clientRequest.originalUrl);
    } else {
      logger.warn('Unable to find weblab properties. Skipping construction.');
      return;
    }

    this.tid = this.cookieJar.getCookie(TID) || '';

    runWithHeap(heap => {
      if (this.tid) {
        heap.addEventProperties({
          tid: this.tid,
        });
      } else {
        logger.error('Unable to set tid in heap, tid not populated in weblabstore');
      }
    });

    if (urlParts.search) {
      setOverridesFromQueryString(urlParts.search, this.cookieJar);
    }
    const opts = this.getWeblabOptions(this.cookieJar);
    this.optOut = !!opts?.optOut;
    this.forcedFeatures = opts?.forcedFeatures || null;
    this.forcedVariations = opts?.forcedVariations || null;

    this.weblabConfig = this.getWeblabConfig();

    this.experimentManager = null;
    this.featureManager = null;
  }

  getWeblabConfig(): WeblabConfig {
    return new WeblabConfig();
  }

  /**
   * Activate this weblab and return variation for this customer. This method
   * should be considered protected. This should be implemented by descendants
   * of this class, but not invoked outside this class.
   *
   * @param  {string}  experimentKey
   * @param  {string}  tid
   *
   * @returns {string} variation for current customer.
   */
  _activate(experimentKey: ExperimentKey): Nullable<VariationKey> {
    invariant(
      this.experimentManager,
      'Cannot activate an experiment until ExperimentManager is ready'
    );
    return this.experimentManager.activate(experimentKey);
  }

  // Expose an experiment
  expose(experimentKey: ExperimentKey): VariationKey {
    if (!this.optOut && !this.forcedVariations) {
      const oldVariation = this.getVariation(experimentKey);
      const newVariation = this._activate(experimentKey);
      if (oldVariation !== newVariation) {
        this.emitChange();
      }
    }
    return this.getVariation(experimentKey);
  }

  // Collects up the heap props which are to be added to every event
  buildHeapEventProperties(): Immutable.Map<string, HeapPropValue> {
    const experimentManager = this.experimentManager;
    invariant(experimentManager, 'cannot track heap events without an experiment manager');
    const featureManager = this.featureManager;
    invariant(featureManager, 'cannot track heap events without a feature manager');

    const heapProps = Immutable.Map<string, HeapPropValue>()
      .merge(experimentManager.buildTrackExperimentsObject())
      .merge(featureManager.buildTrackFeaturesObject())
      .filter((value, key) =>
        softInvariant(
          isHeapPropKeyValid(key),
          `buildHeapEventProperties(): Event prop key "${key}" with value "${value}" was invalid and is being removed.`
        )
      );
    return heapProps;
  }

  initializeVariations(experiments: ExperimentKey[]): void {
    experiments.forEach(experimentKey => {
      this.expose(experimentKey);
    });
  }

  /**
   * Initializes the weblab context by fetching an S2 experiment config
   * (each defining the available experiments) and initializing the ExperimentManager.
   *
   * returns promise with experiment manager config string respectively (which define the experiments that are currently
   * available).
   */
  async initialize(): Promise<S2WeblabConfigObj> {
    const weblabConfigObj = await this.fetchWeblabConfig();
    const tid = this.tid || ''; // TODO: Handle case where tid is missing

    // Setup experiment manager
    if (!weblabConfigObj.experimentsDynamicConfig) {
      logger.warn('Weblab config for experiments is missing. Check WeblabStore for possible bugs.');
    }

    const experimentManager = new ExperimentManager({
      configStr: weblabConfigObj.experimentsDynamicConfig || '{}',
      cookieJar: this.cookieJar,
      experimentList: getExperimentList(this.experimentMap),
      tid,
    });
    this.experimentManager = experimentManager;

    // This is added to account for another way of initializing the authStore and api in the experimentManager.
    // Without it, the webapp will not load JS in certain browsers because of timing issues.
    // TODO: (#36482) Eventually decouple the weblab store from initializing the experiment manager
    if (this.api) {
      experimentManager.associateWithApi(this.api);
    }
    if (this.authStore) {
      experimentManager.associateWithAuthStore(this.authStore);
    }

    experimentManager.cleanupExperiments();

    // Setup feature manager
    if (!weblabConfigObj.featuresDynamicConfig) {
      logger.warn('Weblab config for features is missing. Check WeblabStore for possible bugs.');
    }
    const featureManager = new FeatureManager({
      configStr: weblabConfigObj.featuresDynamicConfig || '{}',
      featureList: getFeatureList(Feature),
      tid,
    });
    this.featureManager = featureManager;
    featureManager.syncEventProperties();

    // Remove unused props from heap
    runWithHeap(heap => {
      const propsToRemove = this.parseHeapCookiePropertyRemoval();
      propsToRemove.forEach(prop => heap.removeEventProperty(prop));
    });

    // Return config (to be embedded in HTML)
    return weblabConfigObj;
  }

  associateWithAuthStore(authStore: AuthStore): void {
    invariant(!this.authStore, 'Cannot associate authStore again');
    this.authStore = authStore;
    this.authStore.addChangeListener(this.onAuthStoreChanged);

    // TODO: (#36482) Eventually decouple the weblab store from initializing the experiment manager
    if (this.experimentManager) {
      this.experimentManager?.associateWithAuthStore(this.authStore);
    }
  }

  associateWithApi(api: Api): void {
    invariant(!this.api, 'Cannot associate api again');
    this.api = api;

    if (this.experimentManager) {
      this.experimentManager?.associateWithApi(this.api);
    }
  }

  onAuthStoreChanged = (): void => {
    // Listen for changes in user enrollments
    // As a hack, just emit the authStore changes at all, longer term it would be better to just
    // track changes to enrollments but it'll require more book keeping.
    this.emitChange();
  };

  isInitialized(): boolean {
    return !!this.experimentManager && !!this.featureManager;
  }

  parseHeapCookiePropertyRemoval(): string[] {
    const heapProps = parseHeapCookieForEventProps(this.cookieJar);
    if (!heapProps) {
      return [];
    }
    const activeExperimentKeys = getExperimentList(this.experimentMap)
      .map(_ => _.KEY)
      .toJS() as ExperimentKey[];
    const activeFeatureKeys = getFeatureList(Feature)
      .filter(_ => _.includeAsEventProperty)
      .map(_ => _.KEY)
      .toJS() as FeatureKey[];
    const propsToRemove = [
      ...findPropsToRemove(heapProps, activeExperimentKeys, /^experiment:(\w+)$/),
      ...findPropsToRemove(heapProps, activeFeatureKeys, /^feature:(\w+)$/),
      ...findObsoletePropsToRemove(heapProps),
    ];
    return propsToRemove;
  }

  getExperimentMap(): typeof Experiment {
    return this.experimentMap;
  }

  /**
   * Find variation for current customer.
   * Example: const variation = weblab.getVariation(Experiment.Example.KEY);
   *
   * @param {string} experiment the unique key for the experiment
   *
   * @returns {string} variation for current customer. Default variation if not exposed.
   */
  getVariation(experimentKey: ExperimentKey): VariationKey {
    let variation = this._optVariation(experimentKey);
    if (!variation) {
      // Check for terminated variations as first fallback
      variation = this.experimentManager?.getTerminatedVariation(experimentKey) ?? null;
    }
    if (!variation) {
      // Otherwise, fallback to default variation
      variation = this.getDefaultVariationForExperimentKey(experimentKey);
    }
    return variation;
  }

  /**
   * Pull variation from several sources, returning optional variation
   */
  _optVariation(experimentKey: ExperimentKey): Nullable<VariationKey> {
    if (this.forcedVariations) {
      return this.forcedVariations.get(experimentKey) ?? null;
    }
    if (this.optOut) {
      return null;
    }
    const experimentManager = this.experimentManager;
    invariant(
      experimentManager,
      'experiment manager was not constructed before checking for a variation'
    );
    return experimentManager.getVariation(experimentKey);
  }

  /**
   * Returns whether the provided experiment variation is active.
   *
   * @param {string} experimentKey the unique identifier for the experiment
   * @param {string} variation the unique identifier for the experiment variation
   *
   * @returns {boolean}
   */
  isVariationEnabled(experimentKey: ExperimentKey, variation: VariationKey): boolean {
    softInvariant(!!experimentKey, `experimentKey was not provided to isVariationEnabled()`);
    softInvariant(
      !!variation,
      `variation was not provided to isVariationEnabled() [experimentKey=${experimentKey}]`
    );
    return this.getVariation(experimentKey) === variation;
  }

  /**
   * Check if a feature is enabled for the current user.
   *
   * @param {Feature} feature feature in features.js
   * @return {boolean} Whether the feature is rolled out to this user
   */
  isFeatureEnabled(feature: FeatureRecord): boolean {
    if (this.optOut) {
      return !!feature.fallbackState;
    }
    const forcedFeatures = this.forcedFeatures;
    if (forcedFeatures) {
      if (forcedFeatures.has(feature.KEY)) {
        return !!forcedFeatures.get(feature.KEY);
      }
      return !!feature.fallbackState;
    }
    invariant(
      this.featureManager,
      `Feature manager was not setup before checking if feature "${feature.KEY}" is enabled`
    );
    return this.featureManager.isFeatureEnabled(feature.KEY);
  }

  isUserEnrolled(enrollment: EnrollmentValue): boolean {
    invariant(
      this.authStore,
      'authStore must be associated before user enrollments can be checked'
    );
    const enrollments = this.authStore.getEnrollments();
    return enrollments.contains(enrollment);
  }

  // When a user logs out, we clear their user-based experiment cookies
  // TODO(#36482) This is a hack!!
  onLogout(): void {
    if (this.experimentManager) {
      this.experimentManager.clearUserExperimentCookies();
    }
  }

  /**
   * Returns an options object for initializing a Weblab instance
   * @param {CookieJar} cookieJar
   *
   * @return {object}
   */
  getWeblabOptions(cookieJar: CookieJar): Nullable<{
    optOut: boolean;
    forcedFeatures: Nullable<Immutable.Map<FeatureKey, boolean>>;
    forcedVariations: Nullable<Immutable.Map<ExperimentKey, VariationKey>>;
  }> {
    const hasOptOut = cookieJar.getCookie(WEBLAB_OPT_OUT) === '1';
    if (hasOptOut) {
      return {
        optOut: true,
        forcedFeatures: null,
        forcedVariations: null,
      };
    }

    const parseCookieJson = (key: string): Nullable<Immutable.Map<any, any>> => {
      const value = cookieJar.getCookie(key);
      if (!value) {
        return null;
      }
      try {
        return Immutable.Map(JSON.parse(value));
      } catch (error) {
        logger.error(`Malformed ${key} cookie`);
        return null;
      }
    };
    return {
      optOut: false,
      forcedFeatures: parseCookieJson(WEBLAB_FORCED_FEATURES),
      forcedVariations: parseCookieJson(WEBLAB_FORCED_VARIATIONS),
    };
  }

  async fetchWeblabConfig(): Promise<S2WeblabConfigObj> {
    if (Object.keys(this.experimentMap).length === 0) {
      return {
        experimentsDynamicConfig: null,
        featuresDynamicConfig: null,
      };
    }
    return await this.weblabConfig.getConfig();
  }

  /*************************************************************************************
   * _UNSAFE access to internal variations/overrides state.                            *
   * Should NOT be used except by weblabstore internally or by tests/debug tooling.    *
   *************************************************************************************/

  // returns any experiment overrides that might be in place
  // only intended to be used by internal debug tooling
  getForcedVariations__UNSAFE(): Nullable<Immutable.Map<ExperimentKey, VariationKey>> {
    return this.forcedVariations;
  }

  // sets the weblab store forced variations for testing
  // only intended to be used by internal debug tooling
  setForcedVariations__UNSAFE(
    forcedVariations: Nullable<Immutable.Map<ExperimentKey, VariationKey>>
  ): void {
    this.forcedVariations = forcedVariations;
    this.emitChange();
  }

  // sets the weblab store forced features for testing
  // only intended to be used by internal debug tooling
  setForcedFeatures__UNSAFE(forcedFeatures: Nullable<Immutable.Map<FeatureKey, boolean>>): void {
    this.forcedFeatures = forcedFeatures;
    this.emitChange();
  }

  getExposedVariation__UNSAFE(experimentKey: ExperimentKey): Nullable<VariationKey> {
    return this.experimentManager
      ? this.experimentManager.getExposedVariation(experimentKey)
      : null;
  }
}

// Find overrides in the query string and update forced cookies accordingly
export function setOverridesFromQueryString(queryString: string, cookieJar: CookieJar): void {
  const {
    experiments,
    features,
    override_duration_h: overrideDurationHours,
  } = qs.parse(queryString[0] === '?' ? queryString.substr(1) : queryString);
  const maybeOverrideDurationSec = overrideDurationHours
    ? parseInt(overrideDurationHours, 10) * HOURS_TO_SEC
    : undefined;
  if (isString(experiments)) {
    setExperimentOverrides(experiments, cookieJar, maybeOverrideDurationSec);
  }
  if (isString(features)) {
    setForcedFeatures(features, cookieJar, maybeOverrideDurationSec);
  }
}

function setExperimentOverrides(
  config: string,
  cookieJar: CookieJar,
  cookieDurationSec: number = COOKIE_FORCED_MAX_AGE_SEC
): void {
  if (config === OVERRIDE_RESET_VALUE) {
    // Reset overrides
    cookieJar.removeCookie(WEBLAB_FORCED_VARIATIONS, {
      path: '/',
    });
    return;
  }

  // Save overrides to cookie
  const overrides = {};
  const search = /(\w+):(\w+)\b/g;
  let match;
  while ((match = search.exec(config))) {
    const name = match[1];
    const group = match[2];
    if (name && group) {
      overrides[name] = group;
    }
  }
  cookieJar.saveCookie(WEBLAB_FORCED_VARIATIONS, JSON.stringify(overrides), {
    maxAge: cookieDurationSec,
    path: '/',
  });
}

function setForcedFeatures(
  config: string,
  cookieJar: CookieJar,
  cookieDurationSec: number = COOKIE_FORCED_MAX_AGE_SEC
): void {
  if (config === OVERRIDE_RESET_VALUE) {
    // Reset overrides
    cookieJar.removeCookie(WEBLAB_FORCED_FEATURES, {
      path: '/',
    });
    return;
  }

  // Save overrides to cookie
  const overrides = {};
  const search = /(\w+):(true|false)\b/g;
  let match;
  while ((match = search.exec(config))) {
    const name = match[1];
    const group = match[2];
    if (name && group) {
      overrides[name] = JSON.parse(group); // "false" => false
    }
  }
  cookieJar.saveCookie(WEBLAB_FORCED_FEATURES, JSON.stringify(overrides), {
    maxAge: cookieDurationSec,
    path: '/',
  });
}

// Parse a cookie to find props heap is adding to all logged events
export function parseHeapCookieForEventProps(cookieJar: CookieJar): string[] {
  const heapProps = Object.keys(cookieJar.getCookies())
    .filter(cookieName => COOKIE_REGEX_HEAP_PROPS.test(cookieName))
    .map(cookieName => cookieJar.getCookie(cookieName))
    .map(cookieValue => {
      try {
        return cookieValue ? JSON.parse(decodeURIComponent(cookieValue)) : null;
      } catch (error) {
        logger.error('failed to parse heap props cookie', error);
      }
    })
    .find(_ => _);
  return heapProps ? Object.keys(heapProps) : [];
}

export function findObsoletePropsToRemove(heapProps: string[]): string[] {
  const extractedKeys = OBSOLETE_PROP_KEYS.map(propKey =>
    heapProps.map(heapPropKey => [
      heapPropKey,
      (mkPropRegexFromKey(propKey).exec(heapPropKey) || [])[1],
    ])
  );
  const propsToRemove = Array.prototype.concat
    .call([], ...extractedKeys)
    .filter(([, key]) => key)
    .map(([heapPropKey]) => heapPropKey);
  return propsToRemove;
}

export function mkPropRegexFromKey(key: string): RegExp {
  return new RegExp(`^${key}:(\\w+)$`, 'gi');
}

// Find heap props in an object that are missing from active keys
export function findPropsToRemove(
  heapProps: string[],
  activeKeys: string[],
  propToKeyRegex: RegExp
): string[] {
  const activeKeysSet = new Set(activeKeys);
  const propsToRemove = heapProps
    .map(heapPropKey => [heapPropKey, (propToKeyRegex.exec(heapPropKey) || [])[1]])
    .filter(([, key]) => key && !activeKeysSet.has(key.toString()))
    .map(([heapPropKey]) => heapPropKey);
  return propsToRemove;
}
