import BaseStore from './BaseStore';

import { filterTypeToProp } from '@/models/QueryUtils';
import {
  getVenueQueryRecordFromJS,
  getVenueQueryRecordFromQueryParams,
  Props,
  VenueQueryFromQueryParams,
  VenueQueryRecord,
  VenueQueryRecordFactory,
} from '@/models/VenueQuery';
import {
  getVenueQueryResponseFromJS,
  VenueQueryResponseFromJS,
  VenueQueryResponseRecord,
  VenueQueryResponseRecordFactory,
} from '@/models/VenueQueryResponse';
import { Nullable } from '@/utils/types';
import { YearRangeRecordFactory } from '@/models/YearRange';
import Constants from '@/constants';
import QueryRoutes from '@/utils/routing/query-routes';
import QueryState from '@/constants/query-state';
import S2History from '@/utils/S2History';
import SortType from '@/constants/sort-type';

import deepEqual from 'fast-deep-equal';
import Immutable from 'immutable';

function withFacetValueToggled(facetValues, value) {
  return facetValues.contains(value) ? facetValues.remove(value) : facetValues.add(value);
}

export default class VenueQueryStore extends BaseStore {
  history: S2History;
  _query: VenueQueryRecord;
  _state: string;
  // there should always be a default record in our query store.
  // so keeping this non-nullable.
  _venueQueryResponses: VenueQueryResponseRecord;
  // if there is no last search payload, set this to null.
  _lastSearchPayload: Nullable<VenueQueryResponseFromJS>;

  constructor(dispatcher, history) {
    super();

    this.history = history;

    this.init();

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.VENUE_QUERY_UPDATED: {
          this._query = getVenueQueryRecordFromQueryParams(payload.query);
          this._state = this.shouldFilterOrLoading(this._query);
          this.emitChange();
          break;
        }
        case Constants.actions.API_REQUEST_STARTING: {
          if (payload.requestType === Constants.requestTypes.SEARCH_PAPERS_BY_VENUE) {
            this._state = this.shouldFilterOrLoading(this._query);

            this.emitChange();
          }
          break;
        }
        case Constants.actions.API_REQUEST_COMPLETE: {
          if (payload.requestType === Constants.requestTypes.SEARCH_PAPERS_BY_VENUE) {
            const results = payload.resultData;
            this._venueQueryResponses = getVenueQueryResponseFromJS(results);
            this._state = QueryState.LOADED;
            this._lastSearchPayload = payload;
            const newQuery = getVenueQueryRecordFromJS(results.query);
            if (!Immutable.is(newQuery, this._query)) {
              this._query = newQuery;
            }
            this.emitChange();
          }
          break;
        }
      }
    });
  }

  init() {
    this._query = VenueQueryRecordFactory();
    this._venueQueryResponses = VenueQueryResponseRecordFactory();
    this._state = QueryState.INITIALIZED;
    this._lastSearchPayload = null;

    return this;
  }

  reset() {
    this.init();
    this.emitChange();
    return this;
  }

  getQuery() {
    return this._query;
  }

  getQueryResponse() {
    return this._venueQueryResponses;
  }

  shouldFilterOrLoading(query: VenueQueryRecord) {
    // here is where we check whether we should set the
    // state to be filtering or loading. We only set to loading
    // if the incomingVenueQuery !== currentVenueQuery.
    // Else we would be displaying the loading screen during
    // filtering event, which is something we don't want.
    const currentVenueQuery = this._venueQueryResponses.query.name;
    const incomingVenueQuery = query.name;
    if (currentVenueQuery === incomingVenueQuery) {
      return QueryState.FILTERING;
    } else {
      return QueryState.LOADING;
    }
  }

  isNewQuery(query: VenueQueryFromQueryParams) {
    // this method is used in the routing page (VenueRoutes.tsx), where
    // we only fetch new data if it's a completely new query.
    // We can't simply just check if the venue is different;
    // it won't fetch data if one of the facet is toggled,
    // where the query changes but the venue doesn't.
    return !deepEqual(this._venueQueryResponses.query, getVenueQueryRecordFromQueryParams(query));
  }

  getFilterDocumentCount(filterType, value) {
    const convertedFilterType = filterTypeToProp(filterType);
    if (this._venueQueryResponses.stats.has(convertedFilterType)) {
      const matchingFacet = this._venueQueryResponses.stats
        .get(convertedFilterType)
        .find(facet => facet.value === value);
      return matchingFacet && matchingFacet.documentCount;
    }
  }

  isFilterActive(filterType, value) {
    const propName = filterTypeToProp(filterType);
    const filter = this._query[propName];
    return filter?.contains(value);
  }

  isLoading(): boolean {
    return this._state === QueryState.LOADING || this._state === QueryState.INITIALIZED;
  }

  isSortOnlyChange(prevQuery, nextQuery) {
    const prevNoSort = prevQuery.set('sort', 'none');
    const nextNoSort = nextQuery.set('sort', 'none');

    return prevQuery.get('sort') !== nextQuery.get('sort') && Immutable.is(prevNoSort, nextNoSort);
  }

  prepareVenueQueryRecord(
    query: VenueQueryRecord,
    sort: Nullable<string> = SortType.INFLUENTIAL_CITATIONS.id
  ): VenueQueryRecord {
    return query.withMutations(query => {
      if (!query.yearFilter?.min || !query.yearFilter?.max) {
        query.set('yearFilter', null);
      }
      if (!query.sort && sort) {
        query.set('sort', sort);
      }
      return query;
    });
  }

  getVenueQueryRecordsInStore(): VenueQueryResponseRecord {
    return this._venueQueryResponses;
  }

  getStoreState(): string {
    return this._state;
  }

  /**
   * Changes the route when a filter is toggled
   * @param {string} filterType: The type of the filter being toggled, e.g. "author"
   * @param {string} value: The text value of the toggled filter
   */
  routeToToggleFilter(filterType, value, router) {
    const queryFilterType = filterTypeToProp(filterType) as keyof Props;

    // Update our query with the appropriate filter enabled (or disabled). We have two "types" of
    // filters -- the old which simply exist as individual keys on the query object (i.e. "authors")
    // and the new ones which are simply expressed as a set of "facets".  We have to figure out
    // which "type" of filter we're adding or removing before we proceed, as they're handled
    // differently (for now)
    const queryWithUpdatedFilters = this._query.update(queryFilterType, filterValues => {
      return withFacetValueToggled(filterValues, value);
    });

    // Always take the user back to the first page
    const newQuery = queryWithUpdatedFilters.set('page', 1);
    QueryRoutes.changeRouteForQuery(newQuery, this.history, router);
  }

  /**
   * Changes the route when the year histogram slider is engaged
   * @param {string} startYear: The earliest year in the range
   * @param {string} endYear: The latest year in the range
   */
  routeToYearRange(startYear, endYear, router) {
    const newQuery = this._query.merge({
      yearFilter: YearRangeRecordFactory({
        min: startYear,
        max: endYear,
      }),
      page: 1,
    });
    QueryRoutes.changeRouteForQuery(newQuery, this.history, router);
  }

  /**
   * Changes the route when the search results list is sorted.
   * TODO: Valid sorts should be an enum, especially once we introduce more.
   * @param {string} sort: The type of sort.
   */
  routeToSort(sort, router) {
    const newQuery = this._query.merge({
      sort: sort,
      page: 1,
    });
    QueryRoutes.changeRouteForQuery(newQuery, this.history, router);
  }

  routeToPage(page, router) {
    const newQuery = this._query.merge({
      page: page,
    });
    QueryRoutes.changeRouteForQuery(newQuery, this.history, router);
  }

  getIndependentQuery() {
    return {};
  }

  /**
   * Executes a search by venue query.
   *
   * @param {object} appContext - The app context.
   * @param {object} routerState - The current router state.
   * @returns {Promise}
   */
  static executeSearchPapersByVenueQuery({ api, dispatcher, venueQueryStore }, { query }) {
    dispatcher.dispatch({ query, actionType: Constants.actions.VENUE_QUERY_UPDATED });

    const venueQuery = venueQueryStore.getQuery();
    const venueQueryJS = venueQueryStore.prepareVenueQueryRecord(venueQuery).toJS();

    return api.searchPapersByVenue(venueQueryJS);
  }

  /**
   * On the server, we return a resolved promise and set the query state to LOADING.
   *
   * @param {object} appContext - The app context.
   * @param {object} routerState - The current router state.
   * @returns {Promise.resolve}
   */
  static setQueryAndLoadingState({ dispatcher }, { query }) {
    const serverRendering = {
      query,
      actionType: Constants.actions.QUERY_UPDATED,
    };

    dispatcher.dispatch(serverRendering);
    return Promise.resolve(serverRendering);
  }
}
