import { ExperimentMap, VariationKey } from '@/weblab/Experiment';
import { ExperimentRecord } from '@/weblab/ExperimentRecord';
import WeblabStore from '@/weblab/WeblabStore';

import PropTypes from 'prop-types';
import React from 'react';

import type { Nullable, ReactNodeish } from '@/utils/types';

export type VariationChooser = (map: ExperimentMap) => VariationKey;
export type ExperimentChooser = (map: ExperimentMap) => ExperimentRecord;

export type ControlVariationProps = {
  variation: VariationKey | VariationChooser;
  children: ReactNodeish | (() => ReactNodeish);
};

type Props = React.PropsWithChildren<{
  experiment: ExperimentRecord | ExperimentChooser;
  isolated?: Nullable<boolean>;
  exposeOnRender?: Nullable<boolean>;
}>;

type State = {
  [key in string]: boolean;
};

export default class ABTest extends React.PureComponent<Props, State> {
  static contextTypes = {
    weblabStore: PropTypes.instanceOf(WeblabStore).isRequired,
  };

  constructor(...args: [any]) {
    super(...args);
    const { exposeOnRender } = this.props;

    const experiment = this.getExperimentModel();

    if (exposeOnRender) {
      this.context.weblabStore.expose(experiment.KEY);
    }

    this.state = this.getStateFromWeblabStore();

    this.context.weblabStore.registerComponent(this, () => {
      this.setState(this.getStateFromWeblabStore());
    });
  }

  getStateFromWeblabStore(): State {
    const { KEY, Variation } = this.getExperimentModel();

    const state = Object.keys(Variation).reduce((state, VARIATION) => {
      const variation = Variation[VARIATION];
      state[variation] = this.context.weblabStore.isVariationEnabled(KEY, variation);

      return state;
    }, {});

    return state;
  }

  getExperimentModel(): ExperimentRecord {
    const { experiment } = this.props;
    const { weblabStore } = this.context;

    return typeof experiment === 'function'
      ? experiment(weblabStore.getExperimentMap())
      : experiment;
  }

  getChildrenArray() {
    return React.Children.toArray(this.props.children);
  }

  render() {
    const { weblabStore } = this.context;

    return (
      // The typing here is forced. TS and the React types won't figure this out by themselves.
      this.getChildrenArray().find(({ props: { variation } }: { props: ControlVariationProps }) => {
        const variationKey =
          typeof variation === 'function' ? variation(weblabStore.getExperimentMap()) : variation;
        return this.state[variationKey];
      }) || null
    );
  }
}
