import { getString } from '@/content/i18n';
import { ReactNodeish } from '@/utils/types';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import Routes from '@/router/Routes';

import React from 'react';

// Blank page is used as a baseline for the weight of our webapp (so we can subtract that from any page we care to measure)
export default class BlankRoute extends React.Component {
  static getPageTitle = (): string => getString(_ => _.general.fullPageTitle, 'Blank');

  static getCanonicalUrl = (): string => Routes.BLANK;

  render(): ReactNodeish {
    return (
      <AsyncLoadedPage
        footer={false}
        load={{
          importer: () => import(/* webpackChunkName: "shared-BlankPage" */ './BlankPage'),
          chunkName: 'shared-BlankPage',
          moduleId: require.resolveWeak('./BlankPage'),
        }}
      />
    );
  }
}
