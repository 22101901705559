import CLPaperActions from './CLPaperActions';
import CLPaperStats from './stats/CLPaperStats';

import { addHeapProps } from '@/components/util/HeapTracking';
import { checkForPaperRecord } from '@/utils/paper-util';
import { citationStatsHasKeyCitations } from '@/models/CitationStats';

import classnames from 'classnames';
import React from 'react';

export default class CLPaperControlsBase extends React.PureComponent {
  static defaultProps = {
    actions: true,
    flags: false,
    stats: true,
    annotation: false,
    cues: false,
  };

  renderStat() {
    const { paper, stats } = this.props;
    const maybePaperRecord = checkForPaperRecord(paper);
    const hasCitationStats =
      maybePaperRecord &&
      (maybePaperRecord.citationStats.numCitations > 0 ||
        citationStatsHasKeyCitations(maybePaperRecord.citationStats));
    // if stat is default one and actually has citation stat then we mount the div
    if (typeof stats === 'boolean' && hasCitationStats) {
      return (
        <div className="cl-paper-controls__stats">
          <CLPaperStats paper={maybePaperRecord} />
        </div>
      );
    }

    // if stats is a custom stat and either it has a citationStat or it has badge then we mount the div
    if (
      React.isValidElement(stats) &&
      (stats.props.citation?.numCitedBy > 0 ||
        paper?.badges.count() > 0 ||
        stats.props.citation?.isKey)
    ) {
      return <div className="cl-paper-controls__stats">{stats}</div>;
    }

    return stats;
  }

  render() {
    const { className, heapProps, paper, actions, flags, stats, annotation, cues } = this.props;

    return (
      <div
        className={classnames('cl-paper__bulleted-row', 'cl-paper-controls', className)}
        {...(heapProps ? addHeapProps(heapProps) : {})}>
        {!!stats && this.renderStat()}
        {!!cues && cues}
        <div className="cl-paper-controls__actions">
          {!!flags && flags}
          {!!actions && (actions === true ? <CLPaperActions paper={paper} /> : actions)}
          {!!annotation && annotation}
        </div>
      </div>
    );
  }
}
