import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import S2Redirect from '@/models/redirects/S2Redirect';

import React from 'react';

export default class SignInRoute extends React.Component {
  static willRouteTo({ authStore }) {
    if (authStore.hasAuthenticatedUser()) {
      return new S2Redirect({
        routeName: 'HOME',
        replace: true,
        status: 302,
      });
    }
  }

  render() {
    return (
      <AsyncLoadedPage
        footer={false}
        load={{
          importer: () => import(/* webpackChunkName: "desktop-SignInPage" */ './SignInPage'),
          chunkName: 'desktop-SignInPage',
          moduleId: require.resolveWeak('./SignInPage'),
        }}
      />
    );
  }
}
