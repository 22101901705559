import { CitationType } from '@/constants/Citation';
import { getString } from '@/content/i18n';
import { HeapProps } from '@/analytics/heapUtils';
import { Nullable, ReactNodeish } from '@/utils/types';
import CLIconButton, { TYPE as BUTTON_TYPE } from '@/components/library/button/CLIconButton';
import Icon from '@/components/shared/icon/Icon';

import classnames from 'classnames';
import React from 'react';

type Props = {
  className?: string;
  heapProps?: Nullable<HeapProps>;
  onClick?: Nullable<() => void | Promise<void>>;
  citationType: CitationType;
};

export default function CLPaperHighlyInfluencedFlag({
  citationType,
  onClick,
  className,
  heapProps,
  ...props
}: Props): ReactNodeish {
  return (
    <CLIconButton
      type={BUTTON_TYPE.TERTIARY}
      className={classnames(
        'cl-paper-flag__flag-button cl-paper-flag__highly-influenced',
        className
      )}
      icon={<Icon icon="scorecard-highly-influential" height="11" width="11" />}
      label={getString(_ => _.citations.highlyInfluenced[citationType])}
      onClick={onClick}
      heapProps={heapProps}
      testId={citationType === 'citingPapers' ? 'highlyInfluenced' : 'highlyInfluential'}
      {...props}
    />
  );
}
