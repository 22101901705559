import { getString } from '@/content/i18n';
import { ReactNodeish } from '@/utils/types';
import { WillRouteToResult } from '@/router/Route';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

export default class AdminExperimentsRoute extends React.Component {
  static getPageTitle = () => getString(_ => _.general.appName);

  static willRouteTo({ api }): Promise<WillRouteToResult> {
    return api.getExperiments();
  }

  render(): ReactNodeish {
    return (
      <AsyncLoadedPage
        showLoadingPage={false}
        load={{
          importer: () =>
            import(/* webpackChunkName: "desktop-AdminExperimentsPage" */ './AdminExperimentsPage'),
          chunkName: 'desktop-AdminExperimentsPage',
          moduleId: require.resolveWeak('./AdminExperimentsPage'),
        }}
      />
    );
  }
}
