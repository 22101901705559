import LoadingIndicator from '@/components/shared/loading/LoadingIndicator';
import Redirect from '@/models/redirects/Redirect';
import S2Redirect from '@/models/redirects/S2Redirect';

import React from 'react';

// Example URL: https://s2.local.allenai.org:8080/me/withdraw?enrollment=feed_lens&next=/me/library
export default class RemoveUserEnrollmentRedirect extends React.PureComponent {
  static async willRouteTo({ api }, routerState) {
    const {
      query: { enrollment, next },
    } = routerState;
    if (enrollment) {
      await api.removeEnrollment(enrollment);
      if (next) {
        return new Redirect({ url: next, status: 302 });
      }
    }
    return new S2Redirect({
      routeName: 'HOME',
    });
  }

  static requiresAuthentication() {
    return true;
  }

  render() {
    return <LoadingIndicator />;
  }
}
