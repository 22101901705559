import BaseStore from './BaseStore';

import { ApiRequestStartingPayload } from '@/api/BaseApi';
import {
  ApiResponse,
  GetLibraryFolderRecommendationsResponseBody,
  LibraryAnnotationEntriesResponseBody,
} from '@/api/ApiResponse';
import { DEPRECATED__FlowOptional } from '@/utils/types';
import {
  FolderRecommendationDayRecord,
  getFolderRecommendationDayFromJS,
} from '@/models/library/FolderRecommendationDay';
import {
  getLibraryEntryAnnotationState,
  LibraryEntryAnnotationState,
  NotRelevant,
} from '@/models/library/LibraryEntryAnnotationState';
import { getLibraryEntryFromJS, LibraryEntryRecord } from '@/models/library/LibraryEntry';
import Constants from '@/constants'; // Reusing these constants for now
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import Immutable from 'immutable';

export default class LibraryRecommendationsStore extends BaseStore {
  #recsDaysList: Immutable.List<FolderRecommendationDayRecord>;
  #visibleFolderIds: Immutable.Set<number>;
  #state: StoreStateValue;
  #nextWindowUTC: DEPRECATED__FlowOptional<number>;
  #annotationEntriesByState: Immutable.Map<
    LibraryEntryAnnotationState,
    Immutable.List<LibraryEntryRecord>
  >;
  #recommendationsAnnotationsState: StoreStateValue;

  constructor(dispatcher) {
    super();

    this.#recsDaysList = Immutable.List();
    this.#visibleFolderIds = Immutable.Set();
    this.#state = StoreState.UNINITIALIZED;
    this.#nextWindowUTC = null;
    this.#annotationEntriesByState = Immutable.Map();
    this.#recommendationsAnnotationsState = StoreState.UNINITIALIZED;

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const apiStartingPayload = payload as ApiRequestStartingPayload;
          switch (apiStartingPayload.requestType) {
            case Constants.requestTypes.GET_LIBRARY_FOLDERS_RECOMMENDATIONS: {
              this.#state = StoreState.LOADING;
              this.emitChange();
              break;
            }
            case Constants.requestTypes.GET_MORE_LIBRARY_FOLDERS_RECOMMENDATIONS: {
              this.#state = StoreState.LOADING;
              this.emitChange();
              break;
            }
          }
          break;
        }

        case Constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.LOGOUT: {
              this.clearProperties();
              this.#state = StoreState.UNINITIALIZED;
              this.emitChange();
              break;
            }
            case Constants.requestTypes.GET_LIBRARY_FOLDERS_RECOMMENDATIONS: {
              const libraryFoldersRecommendationsApiResponse =
                payload as ApiResponse<GetLibraryFolderRecommendationsResponseBody>;
              this.#visibleFolderIds = Immutable.Set(
                libraryFoldersRecommendationsApiResponse.context.folderIds
              );
              this.#recsDaysList = Immutable.List(
                libraryFoldersRecommendationsApiResponse.resultData.days
              ).map(getFolderRecommendationDayFromJS);
              this.#nextWindowUTC =
                libraryFoldersRecommendationsApiResponse.resultData.nextWindowUTC;
              this.#state = StoreState.LOADED;
              this.emitChange();
              break;
            }

            case Constants.requestTypes.GET_MORE_LIBRARY_FOLDERS_RECOMMENDATIONS: {
              const moreLibraryFoldersRecommendationsApiResponse =
                payload as ApiResponse<GetLibraryFolderRecommendationsResponseBody>;
              this.#visibleFolderIds = Immutable.Set(
                moreLibraryFoldersRecommendationsApiResponse.context.folderIds
              );
              const newDays = Immutable.List(
                moreLibraryFoldersRecommendationsApiResponse.resultData.days
              ).map(getFolderRecommendationDayFromJS);
              this.#recsDaysList = this.#recsDaysList.concat(newDays);
              this.#nextWindowUTC =
                moreLibraryFoldersRecommendationsApiResponse.resultData.nextWindowUTC;
              this.#state = StoreState.LOADED;
              this.emitChange();
              break;
            }

            case Constants.requestTypes.GET_LIBRARY_ANNOTATIONS_BY_STATE: {
              const libraryAnnotationsByStateApiResponse =
                payload as ApiResponse<LibraryAnnotationEntriesResponseBody>;
              const { resultData } = libraryAnnotationsByStateApiResponse;
              const resultList = Immutable.List(resultData.entries);

              if (resultList.size > 0) {
                // Create map of annotationState -> List<LibraryEntry>
                // { 'notRelevant' -> [LibraryEntry(...] }
                const groupedByState = resultList.groupBy(_ =>
                  getLibraryEntryAnnotationState(_.annotationState)
                );

                // Go through each annotation state and update the map with the modeled LibraryEntries
                groupedByState.map((entryList, state) => {
                  this.#annotationEntriesByState = this.#annotationEntriesByState.setIn(
                    [state],
                    entryList.map(entry => getLibraryEntryFromJS(entry))
                  );
                });
              } else {
                // Reset the map if the API response is empty
                this.#annotationEntriesByState = Immutable.Map();
              }

              this.#recommendationsAnnotationsState = StoreState.LOADED;
              this.emitChange();
              break;
            }
          }
        }
      }
    });
  }

  isRecommendationsAnnotationsUninitialized(): boolean {
    return this.#recommendationsAnnotationsState === StoreState.UNINITIALIZED;
  }

  isPaperNotRelevantInFolder(paperId: string, folderId: number): boolean {
    const notRelevantEntries = this.#annotationEntriesByState.get(NotRelevant);
    if (!notRelevantEntries || notRelevantEntries.isEmpty()) {
      return false;
    }

    return !notRelevantEntries
      .filter(_ => _.paperId === paperId && _.folderId === folderId)
      .isEmpty();
  }

  getNotRelevantPapersInFolder(folderId: number): Immutable.List<LibraryEntryRecord> {
    const notRelevantEntries = this.#annotationEntriesByState.get(NotRelevant);
    if (!notRelevantEntries || notRelevantEntries.isEmpty()) {
      return Immutable.List();
    }

    return notRelevantEntries.filter(_ => _.folderId === folderId);
  }

  clearProperties(): void {
    this.#recsDaysList = Immutable.List();
    this.#visibleFolderIds = Immutable.Set();
    this.#nextWindowUTC = null;
  }

  getRecommendationsByDay(): Immutable.List<FolderRecommendationDayRecord> {
    return this.#recsDaysList;
  }

  getNextWindowUTC(): DEPRECATED__FlowOptional<number> {
    return this.#nextWindowUTC;
  }

  getVisibleFolderIds(): Immutable.Set<number> {
    return this.#visibleFolderIds;
  }

  isLoading(): boolean {
    return this.isUninitialized() || this.#state === StoreState.LOADING;
  }

  isUninitialized(): boolean {
    return this.#state === StoreState.UNINITIALIZED;
  }
}
