import React from 'react';

export default function IconClearInput(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 16 16">
      <path
        d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8c4.4,0,8-3.6,8-8S12.4,0,8,0z M11.5,10.7c0.1,0.1,0.1,0.4,0,0.5l-0.3,0.3
        c-0.1,0.1-0.4,0.1-0.5,0L8,8.9l-2.7,2.7c-0.1,0.1-0.4,0.1-0.5,0l-0.3-0.3c-0.1-0.1-0.1-0.4,0-0.5L7.1,8L4.5,5.3
        c-0.1-0.1-0.1-0.4,0-0.5l0.3-0.3c0.1-0.1,0.4-0.1,0.5,0L8,7.1l2.7-2.7c0.1-0.1,0.4-0.1,0.5,0l0.3,0.3c0.1,0.1,0.1,0.4,0,0.5L8.9,8
        L11.5,10.7z"
      />
    </symbol>
  );
}
