import { addHeapProps } from '@/components/util/HeapTracking';
import { BadgeId, BadgeType, UNSUPPORTED } from '@/models/PaperBadge';
import { enumToCSSClass } from '@/util';
import { getString } from '@/content/i18n';
import { HeapProps } from '@/analytics/heapUtils';
import { IconId } from '@/components/shared/icon/IconSprite';
import { Nullable, ReactNodeish } from '@/utils/types';
import CLTooltip, {
  AlignConfig,
  Placement,
  TOOLTIP_TRIGGER,
  TooltipReactContent,
} from '@/components/library/tooltip/CLTooltip';
import Icon from '@/components/shared/icon/Icon';

import classnames from 'classnames';
import React from 'react';

type PaperBadgeData = {
  iconId?: Nullable<IconId>;
  symbol?: Nullable<string>;
  text: string;
  tooltip: TooltipReactContent;
  ariaLabel?: Nullable<string>;
};

type Props = {
  className?: Nullable<string>;
  heapProps?: Nullable<HeapProps>;
  isCompact?: Nullable<boolean>;
  tooltipPlacement: Placement;
  type: BadgeId;
  testId?: Nullable<string>;
};

export default function CLPaperBadge({
  className,
  isCompact,
  heapProps,
  tooltipPlacement,
  testId,
  type,
}: Props): ReactNodeish {
  // Safely catch the default PaperBadgeRecord value
  if (type === BadgeType.UNSUPPORTED) {
    return null;
  }
  const badgeData = badgeTypeMap[type];
  const align: AlignConfig = {
    points: ['tl', 'bl'],
    offset: [-16, 2],
    overflow: { adjustX: true, adjustY: true },
    targetOffset: [0, 0],
    useCssRight: false,
    useCssBottom: false,
    useCssTransform: false,
  };

  let badge: ReactNodeish = null;
  if (badgeData.iconId && badgeData.symbol) {
    badge = (
      <span className="cl-paper-stats__combined">
        <CLPaperStatsIcon iconId={badgeData.iconId} />
        <span className="cl-paper-stats__symbol">{badgeData.symbol}</span>
      </span>
    );
  } else if (badgeData.symbol) {
    badge = <span className="cl-paper-stats__symbol">{badgeData.symbol}</span>;
  } else if (badgeData.iconId) {
    badge = <CLPaperStatsIcon iconId={badgeData.iconId} />;
  }

  return (
    <CLTooltip
      placement={tooltipPlacement}
      tooltipContent={badgeData.tooltip}
      trigger={[TOOLTIP_TRIGGER.HOVER, TOOLTIP_TRIGGER.FOCUS]}
      align={align}>
      <div
        className={classnames(
          'cl-paper-stat',
          'cl-paper-stat__collapsable',
          { 'cl-paper-stat__icon-only': isCompact },
          'cl-paper-stat__adjust-badge-position',
          `cl-paper-stats__badge__${enumToCSSClass(type)}`,
          className
        )}
        data-test-id={testId}
        aria-label={badgeData.ariaLabel || undefined}
        {...(heapProps ? addHeapProps(heapProps) : {})}>
        {badge}
        {!isCompact && <span className="cl-paper-stats__hideable-text">{badgeData.text}</span>}
      </div>
    </CLTooltip>
  );
}
const badgeTypeMap: {
  // This enforces badgeTypeMap having one entry for every badge type except for UNSUPPORTED.
  [key in keyof Omit<typeof BadgeType, typeof UNSUPPORTED>]: PaperBadgeData;
} = {
  [BadgeType.OPEN_ACCESS]: {
    symbol: 'PDF',
    text: getString(_ => _.paper.badge.openAccess.title),
    tooltip: getString(_ => _.paper.badge.openAccess.tooltip),
    ariaLabel: getString(_ => _.paper.badge.openAccess.ariaLabel),
  },
  [BadgeType.UNPAYWALL]: {
    iconId: 'open-access',
    symbol: 'PDF',
    text: getString(_ => _.paper.badge.unpaywall.title),
    tooltip: getString(_ => _.paper.badge.unpaywall.tooltip),
    ariaLabel: getString(_ => _.paper.badge.unpaywall.ariaLabel),
  },
  [BadgeType.PRE_PRINT]: {
    symbol: 'PP',
    text: getString(_ => _.paper.badge.prePrint.title),
    tooltip: getString(_ => _.paper.badge.prePrint.tooltip),
  },
  [BadgeType.CORD_19]: {
    symbol: 'C',
    text: getString(_ => _.paper.badge.cord19.title),
    tooltip: getString(_ => _.paper.badge.cord19.tooltip),
  },
  [BadgeType.CLINICAL_TRIAL]: {
    symbol: 'c',
    text: getString(_ => _.paper.badge.clinicalTrial.title),
    tooltip: getString(_ => _.paper.badge.clinicalTrial.tooltip),
  },
};

const CLPaperStatsIcon = ({ iconId }) => {
  return <Icon className="cl-paper-stats__icon" icon={iconId} height="12" width="12" />;
};

// TODO(#21359): Split this into a separate file
export const exampleConfig = {
  getComponent: () => CLPaperBadge,
  fields: [
    {
      name: 'type',
      desc: 'Type of badge to display',
      value: {
        type: 'select',
        default: BadgeType.OPEN_ACCESS,
        options: [
          BadgeType.OPEN_ACCESS,
          BadgeType.UNPAYWALL,
          BadgeType.PRE_PRINT,
          BadgeType.CORD_19,
          BadgeType.CLINICAL_TRIAL,
        ],
      },
    },
    {
      name: 'compact',
      desc: 'Display the compact verison of the badge (no text)',
      value: {
        type: 'boolean',
        default: false,
      },
    },
  ],

  examples: [
    {
      title: 'Example',
      desc: 'Select a type to see how the various types look',
      props: {
        compact: false,
        type: BadgeType.OPEN_ACCESS,
      },
      render: comp => (
        <div
          style={{
            width: '500px',
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            padding: '16px',
          }}>
          {comp}
        </div>
      ),
    },
  ],

  events: {},
};
