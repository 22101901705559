import { Nullable } from '@/utils/types';
import {
  NumberOfHighlights,
  NumberOfHighlightsType,
  OpacityLevel,
  OpacityLevelType,
} from '@/models/paper-pdf/PaperPdfSkimmingSnippet';

import Immutable from 'immutable';

export type SkimmingStateFromJS = {
  result: boolean;
  method: boolean;
  objective: boolean;
  opacityLevel: OpacityLevelType;
  showMarginFlags: boolean;
};

export type SkimmingState = {
  result: boolean;
  method: boolean;
  objective: boolean;
  opacityLevel: OpacityLevelType;
  numberOfHighlights: NumberOfHighlightsType;
  showMarginFlags: boolean;
};

export const SkimmingStateDefaultProps: SkimmingState = {
  result: false,
  method: false,
  objective: false,
  opacityLevel: OpacityLevel.light,
  numberOfHighlights: NumberOfHighlights.few,
  showMarginFlags: true,
};

export const SKIMMING_RECORD_NAME = 'SkimmingState';
export const SkimmingStateRecordFactory = Immutable.Record<SkimmingState>(
  SkimmingStateDefaultProps,
  SKIMMING_RECORD_NAME
);
export type SkimmingStateRecord = Immutable.RecordOf<SkimmingState>;

export function getSkimmingStateFromJS(args: SkimmingStateFromJS): SkimmingStateRecord {
  return SkimmingStateRecordFactory({
    ...args,
  });
}

export type SkimmingInteractionStateFromJS = {
  hoveredPanelSnippetId: Nullable<string>;
};

export type SkimmingInteractionState = {
  hoveredPanelSnippetId: Nullable<string>;
};

export const SkimmingInteractionStateDefaultProps: SkimmingInteractionState = {
  hoveredPanelSnippetId: null,
};

export const SKIMMING_INTERACTION_RECORD_NAME = 'SkimmingInteractionState';
export const SkimmingInteractionStateRecordFactory = Immutable.Record<SkimmingInteractionState>(
  SkimmingInteractionStateDefaultProps,
  SKIMMING_INTERACTION_RECORD_NAME
);
export type SkimmingInteractionStateRecord = Immutable.RecordOf<SkimmingInteractionState>;

export function getSkimmingInteractionStateFromJS(
  args: SkimmingInteractionStateFromJS
): SkimmingInteractionStateRecord {
  return SkimmingInteractionStateRecordFactory({
    ...args,
  });
}
