import { FacetValueFromJS, getFacetValueFromJS } from './FacetValue';
import { getYearBucketFromFacet, YearBucketFromFacet } from './YearBucket';

import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

type CitationQueryAggregationResponseFromJS = {
  stats: {
    coAuthors: FacetValueFromJS[];
    authors: FacetValueFromJS[];
    venues: FacetValueFromJS[];
    publicationTypes: FacetValueFromJS[];
    fieldsOfStudy: FacetValueFromJS[];
    years: YearBucketFromFacet[];
    filteredYears?: Nullable<YearBucketFromFacet[]>;
    countOfPapersWithViewablePdf: number;
    totalNumCitedBy: number;
    totalNumKeyCitations: number;
    totalCountAfterFilters: number;
  };
};

type Props = {
  stats: Immutable.Map<string, any>;
};

const defaultProperties: Props = {
  stats: Immutable.Map({
    authors: Immutable.List(),
    coAuthors: Immutable.List(),
    filteredYears: Immutable.List(),
    venues: Immutable.List(),
    years: Immutable.List(),
    totalCountAfterFilters: 0,
    countOfPapersWithViewablePdf: 0,
  }),
};

export const FACET_KEYS = [
  'years',
  'filteredYears',
  'countOfPapersWithViewablePdf',
  'totalNumCitedBy',
  'totalNumKeyCitations',
  'totalCountAfterFilters',
];

export const CitationQueryAggregationResponseRecordFactory =
  Immutable.Record<Props>(defaultProperties);
export type CitationQueryAggregationResponseRecord = Immutable.RecordOf<Props>;

export function getCitationQueryAggregationResponseFromJS(
  args: CitationQueryAggregationResponseFromJS
): CitationQueryAggregationResponseRecord {
  const response = { ...args };

  // Model the "stats" member. The scala equivalent is `PaperSearchAggregations`.
  const stats = Object.keys(response.stats).reduce((map, facetKey) => {
    switch (facetKey) {
      case 'years':
      case 'filteredYears':
        return map.set(
          facetKey,
          Immutable.List(response.stats[facetKey]?.map(year => getYearBucketFromFacet(year)))
        );
      // This really weird, but required to stick with our existing paradigm
      case 'countOfPapersWithViewablePdf':
        return map.set('countOfPapersWithViewablePdf', response.stats.countOfPapersWithViewablePdf);
      case 'totalNumCitedBy':
        return map.set('totalNumCitedBy', response.stats.totalNumCitedBy);
      case 'totalNumKeyCitations':
        return map.set('totalNumKeyCitations', response.stats.totalNumKeyCitations);
      case 'totalCountAfterFilters':
        return map.set('totalCountAfterFilters', response.stats.totalCountAfterFilters);
      default:
        return map.set(
          facetKey,
          Immutable.OrderedSet(response.stats[facetKey].map(val => getFacetValueFromJS(val)))
        );
    }
  }, Immutable.Map<string, any>());

  return CitationQueryAggregationResponseRecordFactory({
    stats: stats,
  });
}
