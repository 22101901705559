import {
  getLibraryEntriesQueryFromPath,
  getLibraryEntriesQueryFromQueryStringParams,
} from '@/models/library/LibraryEntriesQuery';
import { getLibraryFolderIdFromMatch } from '@/utils/library-utils';
import { getString } from '@/content/i18n';
import { isAllPapersFolder } from '@/models/library/LibraryFolderSourceType';
import { LIBRARY_DEFAULT_SORT } from '@/models/library/LibrarySort';
import { NotRelevant } from '@/models/library/LibraryEntryAnnotationState';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import Constants from '@/constants';
import Routes from '@/router/Routes';
import S2History from '@/utils/S2History';
import S2Redirect from '@/models/redirects/S2Redirect';

import idx from 'idx';
import PropTypes from 'prop-types';
import React from 'react';

export const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE = 1;

export default class LibraryFolderRoute extends React.PureComponent {
  static getPageTitle({ libraryFolderStore }, routerState) {
    const folderId = getLibraryFolderIdFromMatch(routerState);
    const folder = libraryFolderStore.getFolderById(folderId);
    const pageTitle = folder
      ? getString(_ => _.library.pageTitleWithFolder, folder.name)
      : getString(_ => _.library.pageTitle);
    return pageTitle;
  }

  static requiresAuthentication = () => true;

  static async willRouteTo({ api, libraryPageStore, libraryFolderStore }, routerState) {
    const { libraryFolderId } = routerState.params;
    const query = idx(routerState, _ => _.query.q) || '';
    const sort = idx(routerState, _ => _.query.sort) || LIBRARY_DEFAULT_SORT;
    const page = idx(routerState, _ => _.query.page) || 1;

    // Redirect to the user's all papers page for the All Papers folder.
    const folder = libraryFolderStore.getFolderById(parseInt(libraryFolderId, 10));
    if (isAllPapersFolder(folder)) {
      return new S2Redirect({
        path: Routes.LIBRARY_ALL_ENTRIES,
        replace: true,
      });
    }

    const isNewRequestType =
      libraryPageStore.getCurrentLoadRequest() !==
      Constants.requestTypes.GET_LIBRARY_ENTRIES_BY_FOLDER;
    const isNewFolderId = libraryFolderId !== libraryPageStore.getCurrentFolderId();

    const currentQuery = libraryPageStore.getCurrentQuery();
    const incomingQuery = getLibraryEntriesQueryFromQueryStringParams({
      pageSize: DEFAULT_PAGE_SIZE,
      page,
      q: query,
      sort,
    });

    let recsPayload = null;
    // Whenever there is a change in route, refetch annotations
    recsPayload = api.getLibraryAnnotationEntries(NotRelevant);

    let entriesPayload = null;
    if (!incomingQuery.equals(currentQuery) || isNewRequestType || isNewFolderId) {
      entriesPayload = api.getLibraryEntriesByFolderId(
        parseInt(libraryFolderId, 10),
        incomingQuery
      );
    }

    return [entriesPayload, recsPayload];
  }

  static contextTypes = {
    history: PropTypes.instanceOf(S2History).isRequired,
  };

  render() {
    const { history } = this.context;
    const queryParms = getLibraryEntriesQueryFromPath(history.location.search);
    const libraryFolderId = getLibraryFolderIdFromMatch(this.props.match);

    return (
      <AsyncLoadedPage
        key={libraryFolderId /* each page should render new components */}
        showLoadingPage={false}
        compProps={{ libraryFolderId, currentPageNumber: queryParms.page || DEFAULT_PAGE }}
        load={{
          importer: () =>
            import(/* webpackChunkName: "shared-LibraryFolderPage" */ './LibraryFolderPage'),
          chunkName: 'shared-LibraryFolderPage',
          moduleId: require.resolveWeak('./LibraryFolderPage'),
        }}
      />
    );
  }
}
