import { getString } from '@/content/i18n';
import { ReactNodeish } from '@/utils/types';
import { RouterState } from '@/router/routerUtils';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

type Props = {
  match: RouterState;
};

export default class AdminExperimentAuditRoute extends React.Component<Props> {
  static getPageTitle = () => getString(_ => _.general.appName);

  static requiresAuthentication = () => true;

  render(): ReactNodeish {
    const { match } = this.props;
    const experimentKey = match?.params?.key;
    return (
      <AsyncLoadedPage
        showLoadingPage={false}
        compProps={{ experimentKey }}
        load={{
          importer: () =>
            import(
              /* webpackChunkName: "desktop-AdminExperimentAuditPage" */ './AdminExperimentAuditPage'
            ),
          chunkName: 'desktop-AdminExperimentAuditPage',
          moduleId: require.resolveWeak('./AdminExperimentAuditPage'),
        }}
      />
    );
  }
}
