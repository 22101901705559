import React from 'react';

export default function IconLibraryExplanation(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 175.6 168">
      <style type="text/css">
        {`.research-base{fill:#CED4D7;}
        .research-highlight{fill:#5492EF;}
        .research-reverse{fill:#fff;}
        `}
      </style>

      <path
        className="research-base"
        d="M93,92c-0.3-0.3-0.6-0.4-1-0.4h-0.4v7.6h7.6v-0.4c0-0.4-0.1-0.7-0.4-1L93,92z M89.7,99.7v-8.1H77.8
      c-0.8,0-1.4,0.6-1.4,1.4v27.7c0,0.8,0.6,1.5,1.4,1.4h20.1c0.8,0,1.4-0.6,1.4-1.4v-19.6h-8.1C90.4,101.2,89.7,100.5,89.7,99.7z
       M127.9,99.7v-8.1H116c-0.8,0-1.4,0.6-1.4,1.4v27.7c0,0.8,0.6,1.5,1.4,1.4H136c0.8,0,1.4-0.6,1.4-1.4v-19.6h-8.1
      C128.6,101.2,127.9,100.5,127.9,99.7z M131.2,92c-0.3-0.3-0.6-0.4-1-0.4h-0.4v7.6h7.6v-0.4c0-0.4-0.1-0.7-0.4-1L131.2,92z
       M169.4,92c-0.3-0.3-0.6-0.4-1-0.4H168v7.6h7.6v-0.4c0-0.4-0.1-0.7-0.4-1L169.4,92z M166.1,99.7v-8.1h-11.9c-0.8,0-1.4,0.6-1.4,1.4
      v27.7c0,0.8,0.6,1.5,1.4,1.4h20.1c0.8,0,1.4-0.6,1.4-1.4v-19.6h-8.1C166.7,101.2,166.1,100.5,166.1,99.7z M16.7,92
      c-0.3-0.3-0.6-0.4-1-0.4h-0.4v7.6h7.6v-0.4c0-0.4-0.1-0.7-0.4-1L16.7,92z M13.3,99.7v-8.1H1.4c-0.8,0-1.4,0.6-1.4,1.4v27.7
      c0,0.8,0.6,1.5,1.4,1.4h20.1c0.8,0,1.4-0.6,1.4-1.4v-19.6h-8.1C14,101.2,13.4,100.5,13.3,99.7z M93,46.2c-0.3-0.3-0.6-0.4-1-0.4
      h-0.4v7.6h7.6v-0.4c0-0.4-0.1-0.7-0.4-1L93,46.2z M89.7,53.9v-8.1H77.8c-0.8,0-1.4,0.6-1.4,1.4v27.7c0,0.8,0.6,1.5,1.4,1.4h20.1
      c0.8,0,1.4-0.6,1.4-1.4V55.4h-8.1C90.4,55.4,89.7,54.7,89.7,53.9z M127.9,53.9v-8.1H116c-0.8,0-1.4,0.6-1.4,1.4v27.7
      c0,0.8,0.6,1.5,1.4,1.4H136c0.8,0,1.4-0.6,1.4-1.4V55.4h-8.1C128.6,55.4,127.9,54.7,127.9,53.9z M131.2,46.2
      c-0.3-0.3-0.6-0.4-1-0.4h-0.4v7.6h7.6v-0.4c0-0.4-0.1-0.7-0.4-1L131.2,46.2z M51.5,53.9v-8.1H39.6c-0.8,0-1.4,0.6-1.4,1.4v27.7
      c0,0.8,0.6,1.5,1.4,1.4h20.1c0.8,0,1.4-0.6,1.4-1.4V55.4H53C52.2,55.4,51.6,54.7,51.5,53.9z M54.8,46.2c-0.3-0.3-0.6-0.4-1-0.4
      h-0.4v7.6h7.6v-0.4c0-0.4-0.1-0.7-0.4-1L54.8,46.2z M13.3,53.9v-8.1H1.4c-0.8,0-1.4,0.6-1.4,1.4v27.7c0,0.8,0.6,1.5,1.4,1.4h20.1
      c0.8,0,1.4-0.6,1.4-1.4V55.4h-8.1C14,55.4,13.4,54.7,13.3,53.9z M16.7,46.2c-0.3-0.3-0.6-0.4-1-0.4h-0.4v7.6h7.6v-0.4
      c0-0.4-0.1-0.7-0.4-1L16.7,46.2z M89.7,8.1V0H77.8c-0.8,0-1.4,0.6-1.4,1.4v27.7c0,0.8,0.6,1.5,1.4,1.4h20.1c0.8,0,1.4-0.6,1.4-1.4
      V9.6h-8.1C90.4,9.6,89.7,8.9,89.7,8.1z M93,0.4C92.8,0.2,92.4,0,92,0h-0.4v7.6h7.6V7.3c0-0.4-0.1-0.7-0.4-1L93,0.4z M127.9,8.1V0
      H116c-0.8,0-1.4,0.6-1.4,1.4v27.7c0,0.8,0.6,1.5,1.4,1.4H136c0.8,0,1.4-0.6,1.4-1.4V9.6h-8.1C128.6,9.6,127.9,8.9,127.9,8.1z
       M131.2,0.4c-0.3-0.3-0.6-0.4-1-0.4h-0.4v7.6h7.6V7.3c0-0.4-0.1-0.7-0.4-1L131.2,0.4z M175.2,6.2l-5.8-5.8c-0.3-0.3-0.6-0.4-1-0.4
      H168v7.6h7.6V7.3C175.6,6.9,175.5,6.5,175.2,6.2z M166.1,8.1V0h-11.9c-0.8,0-1.4,0.6-1.4,1.4v27.7c0,0.8,0.6,1.5,1.4,1.4h20.1
      c0.8,0,1.4-0.6,1.4-1.4V9.6h-8.1C166.7,9.6,166.1,8.9,166.1,8.1z M89.7,145.6v-8.1H77.8c-0.8,0-1.4,0.6-1.4,1.4v27.7
      c0,0.8,0.6,1.5,1.4,1.4h20.1c0.8,0,1.4-0.6,1.4-1.4V147h-8.1C90.4,147,89.7,146.4,89.7,145.6z M93,137.9c-0.3-0.3-0.6-0.4-1-0.4
      h-0.4v7.6h7.6v-0.4c0-0.4-0.1-0.7-0.4-1L93,137.9z M127.9,145.6v-8.1H116c-0.8,0-1.4,0.6-1.4,1.4v27.7c0,0.8,0.6,1.5,1.4,1.4H136
      c0.8,0,1.4-0.6,1.4-1.4V147h-8.1C128.6,147,127.9,146.4,127.9,145.6z M131.2,137.9c-0.3-0.3-0.6-0.4-1-0.4h-0.4v7.6h7.6v-0.4
      c0-0.4-0.1-0.7-0.4-1L131.2,137.9z M54.8,0.4c-0.3-0.3-0.6-0.4-1-0.4h-0.4v7.6h7.6V7.3c0-0.4-0.1-0.7-0.4-1L54.8,0.4z M51.5,8.1V0
      H39.6c-0.8,0-1.4,0.6-1.4,1.4v27.7c0,0.8,0.6,1.5,1.4,1.4h20.1c0.8,0,1.4-0.6,1.4-1.4V9.6H53C52.2,9.6,51.6,8.9,51.5,8.1z
       M51.5,145.6v-8.1H39.6c-0.8,0-1.4,0.6-1.4,1.4v27.7c0,0.8,0.6,1.5,1.4,1.4h20.1c0.8,0,1.4-0.6,1.4-1.4V147H53
      C52.2,147,51.6,146.4,51.5,145.6z M54.8,137.9c-0.3-0.3-0.6-0.4-1-0.4h-0.4v7.6h7.6v-0.4c0-0.4-0.1-0.7-0.4-1L54.8,137.9z
       M13.3,145.6v-8.1H1.4c-0.8,0-1.4,0.6-1.4,1.4v27.7c0,0.8,0.6,1.5,1.4,1.4h20.1c0.8,0,1.4-0.6,1.4-1.4V147h-8.1
      C14,147,13.4,146.4,13.3,145.6z M16.7,137.9c-0.3-0.3-0.6-0.4-1-0.4h-0.4v7.6h7.6v-0.4c0-0.4-0.1-0.7-0.4-1L16.7,137.9z"
      />
      <path
        className="research-highlight"
        d="M54.8,92c-0.3-0.3-0.6-0.4-1-0.4h-0.4v7.6h7.6v-0.4c0-0.4-0.1-0.7-0.4-1L54.8,92z M51.5,99.7v-8.1H39.6
      c-0.8,0-1.4,0.6-1.4,1.4v27.7c0,0.8,0.6,1.5,1.4,1.4h20.1c0.8,0,1.4-0.6,1.4-1.4v-19.6H53C52.2,101.2,51.6,100.5,51.5,99.7z
       M175.2,52.1l-5.8-5.8c-0.3-0.3-0.6-0.4-1-0.4H168v7.6h7.6v-0.4C175.6,52.7,175.5,52.3,175.2,52.1z M166.1,53.9v-8.1h-11.9
      c-0.8,0-1.4,0.6-1.4,1.4v27.7c0,0.8,0.6,1.5,1.4,1.4h20.1c0.8,0,1.4-0.6,1.4-1.4V55.4h-8.1C166.7,55.4,166.1,54.7,166.1,53.9z
       M13.3,8.1V0H1.4C0.6,0,0,0.6,0,1.4v27.7c0,0.8,0.6,1.5,1.4,1.4h20.1c0.8,0,1.4-0.6,1.4-1.4V9.6h-8.1C14,9.6,13.4,8.9,13.3,8.1z
       M16.7,0.4C16.4,0.2,16,0,15.7,0h-0.4v7.6h7.6V7.3c0-0.4-0.1-0.7-0.4-1L16.7,0.4z M166.1,145.6v-8.1h-11.9c-0.8,0-1.4,0.6-1.4,1.4
      v27.7c0,0.8,0.6,1.5,1.4,1.4h20.1c0.8,0,1.4-0.6,1.4-1.4V147h-8.1C166.7,147,166.1,146.4,166.1,145.6z M169.4,137.9
      c-0.3-0.3-0.6-0.4-1-0.4H168v7.6h7.6v-0.4c0-0.4-0.1-0.7-0.4-1L169.4,137.9z"
      />
    </symbol>
  );
}
