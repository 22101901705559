import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import idx from 'idx';
import React from 'react';

export default class DebugComponentLibraryPreviewRoute extends React.Component {
  render() {
    const componentName = idx(this.props, _ => _.match.params.component) || null;
    const exampleIndexStr = idx(this.props, _ => _.match.params.exampleIndex) || '';
    const exampleIndex = /^\d+$/.test(exampleIndexStr) ? parseInt(exampleIndexStr, 10) : null;
    return (
      <AsyncLoadedPage
        footer={false}
        compProps={{ componentName, exampleIndex }}
        load={{
          importer: () =>
            import(
              /* webpackChunkName: "shared-DebugComponentLibraryPreviewPage" */ './DebugComponentLibraryPreviewPage'
            ),
          chunkName: 'shared-DebugComponentLibraryPreviewPage',
          moduleId: require.resolveWeak('./DebugComponentLibraryPreviewPage'),
        }}
      />
    );
  }
}
