import AuthorAlertsModal from './AuthorAlertsModal';
import CiteModal from './CiteModal';
import CollaboratorsModal from './CollaboratorsModal';
import ConfirmPublicFolderModal from './ConfirmPublicFolderModal';
import CreateLibraryFTUEModal from './CreateLibraryFTUEModal';
import DemographicModal from './DemographicModal';
import ExportModal from './ExportModal';
import FeedbackModal from './FeedbackModal';
import LoginModal from './LoginModal';
import SourcesModal from './SourcesModal';
import TerminateExperimentModal from './TerminateExperimentModal';

import { getBody } from '@/browser';
import { ModalId } from '@/constants/Modal';
import { SIGN_IN, SIGN_UP } from '@/constants/LoginMethods';
import CreateAlertModal from '@/components/shared/alerts/CreateAlertModal';
import DeleteAlertModal from '@/components/shared/alerts/DeleteAlertModal';
import MobileNavMenu from '@/components/mobile/account/MobileNavMenu';
import ModalStore from '@/stores/ModalStore';
import PaperFigureModal from '@/components/shared/figure/PaperFigureModal';
import RecommendationsNotRelevantModal from '@/components/shared/common/modal/RecommendationsNotRelevantModal';
import S2Dispatcher from '@/utils/S2Dispatcher';

import * as PropTypes from 'prop-types';
import React from 'react';

export default class ModalManager extends React.PureComponent {
  state = {
    visibleModalId: undefined,
    data: undefined,
    key: undefined,
  };

  static contextTypes = {
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    modalStore: PropTypes.instanceOf(ModalStore).isRequired,
  };

  componentDidMount() {
    const { modalStore } = this.context;
    modalStore.addChangeListener(this.onModalStoreChange);
  }

  componentWillUnmount() {
    const { modalStore } = this.context;
    modalStore.removeChangeListener(this.onModalStoreChange);
  }

  onModalStoreChange = (visibleModalId, data, key) => {
    const body = getBody(window.document);
    if (visibleModalId) {
      // Modal is showing, prevent scrolling by positioning the body to the scroll point
      body.style.top = `-${window.scrollY}px`;
      body.style.position = 'fixed';
    } else {
      // Modal is hiding, reset ability to scroll, if previously locked
      const top = body.style.top;
      if (top) {
        body.style.position = '';
        body.style.top = '';
        const scrollY = top ? -parseInt(top, 10) : 0;
        window.scrollTo(0, scrollY);
      }
    }

    this.setState({
      visibleModalId: visibleModalId,
      data: data,
      key: key,
    });
  };

  render() {
    // If visibleModalId is undefined, don't even check for whether there's a modal to render as we
    // can short circuit the whole case statement. This isn't *really* a performance tweak, it's
    // more of a safeguard just in case one of the modalId constants referenced here is typo'd
    // (and thus undefined), which would put a modal in a forever visible state
    // NOTE this is currently used on both the desktop and mobile sites, but every case
    // except for HOME uses a desktop component. If we decide to add collaborators,
    // login, etc. to the modal site, we will need to change this implementation.
    if (this.state.visibleModalId !== undefined) {
      switch (this.state.visibleModalId) {
        case ModalId.AUTHOR_ALERTS_MODAL: {
          return <AuthorAlertsModal data={this.state.data} />;
        }
        case ModalId.CITE: {
          return <CiteModal data={this.state.data} />;
        }
        case ModalId.COLLABORATORS: {
          return <CollaboratorsModal />;
        }
        case ModalId.CONFIRM_PUBLIC_LIBRARY: {
          return <ConfirmPublicFolderModal data={this.state.data} />;
        }
        case ModalId.DEMOGRAPHIC: {
          return <DemographicModal data={this.state.data} />;
        }
        case ModalId.EXPORT: {
          return <ExportModal data={this.state.data} />;
        }
        case ModalId.FEEDBACK: {
          return <FeedbackModal data={this.state.data} />;
        }
        case ModalId.CREATE_LIBRARY_FTUE: {
          return <CreateLibraryFTUEModal />;
        }
        case ModalId.LOGIN: {
          return <LoginModal data={this.state.data} loginAction={SIGN_IN} />;
        }
        case ModalId.MOBILE_NAV: {
          return <MobileNavMenu data={this.state.data} />;
        }
        case ModalId.RECOMMENDATIONS_NOT_RELEVANT: {
          return <RecommendationsNotRelevantModal data={this.state.data} />;
        }
        case ModalId.SIGNUP: {
          return <LoginModal data={this.state.data} loginAction={SIGN_UP} />;
        }
        case ModalId.SOURCES: {
          return <SourcesModal />;
        }
        case ModalId.ENTITY_FIGURE: {
          const { figure, paper, next, prev, figureCount, showArrows } = this.state.data;
          return (
            <PaperFigureModal
              figure={figure}
              paper={paper}
              next={next}
              prev={prev}
              figureCount={figureCount}
              showArrows={showArrows}
            />
          );
        }
        case ModalId.CREATE_ALERT: {
          return <CreateAlertModal />;
        }
        case ModalId.DELETE_ALERT: {
          return <DeleteAlertModal alert={this.state.data} />;
        }
        case ModalId.TERMINATE_EXPERIMENT: {
          return <TerminateExperimentModal experiment={this.state.data} />;
        }
        default: {
          return null;
        }
      }
    } else {
      return null;
    }
  }
}
