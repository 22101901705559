import React from 'react';

export default function IconGoogleLogin(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 36 36">
      <path
        style={{ fill: '#F4BC44' }}
        d="M7.77,18c0-1.17,0.19-2.29,0.54-3.34l-6.06-4.63c-1.18,2.4-1.85,5.11-1.85,7.97s0.67,5.57,1.85,7.97l6.06-4.63 C7.97,20.29,7.77,19.17,7.77,18z"
      />
      <path
        style={{ fill: '#DC483B' }}
        d="M2.25,10.03l6.06,4.63c1.4-4.24,5.38-7.29,10.1-7.29c2.54,0,4.83,0.9,6.63,2.37l5.24-5.24 C27.08,1.72,22.99,0,18.41,0C11.3,0,5.18,4.07,2.25,10.03z"
      />
      <path
        style={{ fill: '#37A958' }}
        d="M18.41,14.73v6.95h9.65c-0.9,4.42-4.66,6.95-9.65,6.95c-4.73,0-8.71-3.06-10.1-7.31l-6.05,4.64 C5.18,31.92,11.29,36,18.41,36c9,0,17.18-6.55,17.18-18c0-1.06-0.16-2.21-0.41-3.27H18.41z"
      />
      <path
        style={{ fill: '#557BB9' }}
        d="M18.41,14.73v6.95h9.65c-0.48,2.37-1.8,4.19-3.68,5.38l5.75,4.45c3.3-3.07,5.45-7.65,5.45-13.51 c0-1.06-0.16-2.21-0.41-3.27H18.41z"
      />
    </symbol>
  );
}
