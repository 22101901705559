import CLCheckboxInputBase, {
  CHECKBOX_STATE as _CHECKBOX_STATE,
  SIZE as _SIZE,
  CLCheckboxInputBaseProps,
} from './CLCheckboxInputBase';

import { ExampleConfig } from '@/components/library/ExampleUtils';
import { ReactNodeish } from '@/utils/types';

import React from 'react';

export const SIZE = _SIZE;
export const CHECKBOX_STATE = _CHECKBOX_STATE;

type CLCheckboxInputProps = CLCheckboxInputBaseProps;

export default function CLCheckboxInput(props: CLCheckboxInputProps): ReactNodeish {
  // wrapper div is needed here to help with the positioning of the hidden <input>
  // since it's very difficult to style an input element,
  // this component works by hacking a hidden input element with a styled div that looks like a checkbox
  return (
    <div>
      <CLCheckboxInputBase {...props} />
    </div>
  );
}

export const exampleConfig: ExampleConfig = {
  getComponent: () => CLCheckboxInput,
  fields: [
    {
      name: 'isChecked',
      desc: 'Whether checkbox is checked using a boolean or enum',
      value: {
        type: 'select',
        default: 'true',
        options: [
          'true',
          'false',
          CHECKBOX_STATE.CHECKED,
          CHECKBOX_STATE.UNCHECKED,
          CHECKBOX_STATE.INDETERMINATE,
        ],
      },
    },
    {
      name: 'isDisabled',
      desc: 'Whether checkbox is disabled',
      value: {
        type: 'boolean',
        default: false,
      },
    },
    {
      name: 'name',
      desc: 'Name of input',
      value: {
        type: 'text',
        default: 'test',
      },
    },
    {
      name: 'value',
      desc: 'Value of input',
      value: {
        type: 'text',
        default: 'test',
      },
    },
    {
      name: 'size',
      desc: 'Size of checkbox',
      value: {
        type: 'select',
        default: SIZE.DEFAULT,
        options: [SIZE.DEFAULT, SIZE.LARGE],
      },
    },
  ],

  examples: [
    {
      title: 'Checked',
      desc: 'A checked checkbox',
      props: {
        isChecked: true,
        onChange: () => {},
      },
      render: comp => comp,
    },
    {
      title: 'Unchecked',
      desc: 'An unchecked checkbox',
      props: {
        isChecked: false,
        onChange: () => {},
      },
      render: comp => comp,
    },
    {
      title: 'Disabled Checked',
      desc: 'A disabled checkbox that is checked',
      props: {
        isDisabled: true,
        isChecked: true,
        onChange: () => {},
      },
      render: comp => comp,
    },
    {
      title: 'Disabled Unchecked',
      desc: 'A disabled checkbox that is unchecked',
      props: {
        isDisabled: true,
        isChecked: false,
        onChange: () => {},
      },
      render: comp => comp,
    },
    {
      title: 'Large Checkbox',
      desc: 'A large checkbox',
      props: {
        isChecked: true,
        onChange: () => {},
        size: SIZE.LARGE,
      },
      render: comp => comp,
    },
    {
      title: 'Indeterminate Checkbox',
      desc: 'An indeterminate checkbox',
      props: {
        isChecked: CHECKBOX_STATE.INDETERMINATE,
        onChange: () => {},
      },
      render: comp => comp,
    },
    {
      title: 'Disabled Indeterminate',
      desc: 'A disabled indeterminate checkbox',
      props: {
        isChecked: CHECKBOX_STATE.INDETERMINATE,
        isDisabled: true,
        onChange: () => {},
      },
      render: comp => comp,
    },
  ],

  events: {
    onChange: () => 'onChange',
  },
};
