import Immutable from 'immutable';

export type EntityLiteProperties = {
  id: string;
  name: string;
  slug: string;
};

const defaultProperties: EntityLiteProperties = {
  id: '',
  name: '',
  slug: '',
};

/** This model is a trimmed down version of the Entity model. This is used for features that require
 * a limited amount of information compared to the full Entity Page, e.g. papers.
 */
export const EntityLiteRecordFactory = Immutable.Record<EntityLiteProperties>(defaultProperties);
export type EntityLiteRecord = Immutable.RecordOf<EntityLiteProperties>;
