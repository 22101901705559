import React from 'react';

export default function IconStatusWarning(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 40 32">
      <path
        d="M20,21.1c-1,0-1.81,0.81-1.81,1.81S19,24.71,20,24.71s1.81-0.81,1.81-1.81S21,21.1,20,21.1z M21.03,7.29h-2.06
        c-0.22,0-0.4,0.08-0.55,0.23c-0.15,0.15-0.23,0.33-0.23,0.55v10.61c0,0.22,0.08,0.4,0.23,0.55c0.15,0.15,0.33,0.23,0.55,0.23h2.06
        c0.22,0,0.4-0.08,0.55-0.23c0.15-0.15,0.23-0.33,0.23-0.55V8.06c0-0.22-0.08-0.4-0.23-0.55C21.43,7.37,21.25,7.29,21.03,7.29z
        M33.84,8C32.4,5.55,30.45,3.6,28,2.16S22.88,0,20,0c-2.88,0-5.55,0.72-8,2.16S7.6,5.55,6.16,8C4.72,10.45,4,13.12,4,16
        s0.72,5.55,2.16,8c1.44,2.45,3.39,4.4,5.84,5.84S17.12,32,20,32c2.88,0,5.55-0.72,8-2.16s4.4-3.39,5.84-5.84
        c1.44-2.45,2.16-5.12,2.16-8S35.28,10.45,33.84,8z M31.16,22.45C30,24.43,28.43,26,26.45,27.16c-1.98,1.16-4.13,1.74-6.45,1.74
        s-4.47-0.58-6.45-1.74C11.57,26,10,24.43,8.84,22.45S7.1,18.32,7.1,16s0.58-4.47,1.74-6.45S11.57,6,13.55,4.84
        C15.53,3.68,17.68,3.1,20,3.1s4.47,0.58,6.45,1.74C28.43,6,30,7.57,31.16,9.55S32.9,13.68,32.9,16S32.32,20.47,31.16,22.45z"
      />
    </symbol>
  );
}
