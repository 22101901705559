import { buildShareFolderLink } from './util';

import { getParamsForRoute } from '@/router/Routes';
import { getString } from '@/content/i18n';
import { handleRedirect } from '@/router/clientUtils';
import {
  heapLibraryFolderSettingsShelfConfirmDeleteButton,
  heapLibraryFolderSettingsShelfCopyShareableLinkButton,
  heapLibraryFolderSettingsShelfDeleteButton,
  heapLibraryFolderSettingsShelfEditNameInput,
  heapLibraryFolderSettingsShelfEditPrivacyPrivate,
  heapLibraryFolderSettingsShelfEditPrivacyPublic,
  heapLibraryFolderSettingsShelfSaveButton,
} from '@/analytics/attributes/libraryShelf';
import { hideShelf } from '@/actions/ShelfActionCreators';
import {
  isEditableFolder,
  isSystemGeneratedFolder,
} from '@/models/library/LibraryFolderSourceType';
import { LIBRARY_FOLDER_SETTINGS } from '@/constants/ShelfId';
import { LibraryFolderRecord } from '@/models/library/LibraryFolder';
import { Nullable } from '@/utils/types';
import { PrivacySettingMap, Private, Public } from '@/models/library/PrivacySetting';
import { RecommendationStatus } from '@/models/library/RecommendationStatus';
import Api from '@/api/Api';
import AuthStore from '@/stores/AuthStore';
import BrowserUtil from '@/browser';
import CLButton, { TYPE as BUTTON_TYPE } from '@/components/library/button/CLButton';
import CLIconButton from '@/components/library/button/CLIconButton';
import CLTextInput from '@/components/library/form/input/CLTextInput';
import EventTarget from '@/analytics/constants/EventTarget';
import FormLevelMessage from '@/components/shared/message/FormLevelMessage';
import Icon from '@/components/shared/icon/Icon';
import LibraryFolderStore from '@/stores/LibraryFolderStore';
import LoadingIndicator from '@/components/shared/loading/LoadingIndicator';
import ResearchFeedSettings from '@/components/shared/research/ResearchFeedSettings';
import S2Dispatcher from '@/utils/S2Dispatcher';
import S2History from '@/utils/S2History';
import S2Redirect from '@/models/redirects/S2Redirect';
import Shelf from '@/components/shared/shelf/Shelf';
import ShowEvent from '@/analytics/models/ShowEvent';
import softError from '@/utils/softError';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import PropTypes from 'prop-types';
import React from 'react';

const LIBRARY_FOLDER_ROUTE = 'LIBRARY_FOLDER';

type StateFromLibraryFolderStore = {
  folder: Nullable<LibraryFolderRecord>;
  folderNameInput: Nullable<string>;
  recommendationStatus: Nullable<RecommendationStatus>;
  privacySetting: string;
};

type State = {
  isSending: boolean;
  errorMessage: Nullable<string>;
  isConfirmingDelete: boolean;
  isPublicLinkCopied: boolean;
} & StateFromLibraryFolderStore;

type Props = {
  context: {
    libraryFolderId: number;
  };
};

export default class LibraryFolderSettingsShelf extends React.PureComponent<Props, State> {
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    authStore: PropTypes.instanceOf(AuthStore).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    history: PropTypes.instanceOf(S2History).isRequired,
    libraryFolderStore: PropTypes.instanceOf(LibraryFolderStore).isRequired,
  };

  declare context: {
    api: Api;
    authStore: AuthStore;
    dispatcher: S2Dispatcher;
    history: S2History;
    libraryFolderStore: LibraryFolderStore;
  };

  constructor(...args: [any]) {
    super(...args);

    this.state = {
      isSending: false,
      errorMessage: null,
      isConfirmingDelete: false,
      isPublicLinkCopied: false,
      ...this.getStateFromLibraryFolderStore(),
    };
  }

  getStateFromLibraryFolderStore(): StateFromLibraryFolderStore {
    const { libraryFolderStore } = this.context;
    const {
      context: { libraryFolderId },
    } = this.props;

    const folder = libraryFolderStore.getFolderById(libraryFolderId);

    // This shouldn't happen so log error if it does
    if (!folder) {
      softError('library', `failed to find folder[id="${libraryFolderId}"]`);
      return {
        folder: null,
        folderNameInput: '',
        recommendationStatus: null,
        privacySetting: Private,
      };
    }

    return {
      folder: folder,
      folderNameInput: folder.name,
      recommendationStatus: folder.recommendationStatus,
      privacySetting: folder.privacySetting ? folder.privacySetting : Private,
    };
  }

  componentDidMount(): void {
    trackAnalyticsEvent(ShowEvent.create(EventTarget.Library.Shelf.FOLDER_SETTINGS_VIEW));
  }

  async saveFolderUpdate({ id, name, privacySetting }): Promise<void> {
    const { api } = this.context;
    await api.updateLibraryFolder({ id, name, privacySetting });
  }

  fetchAlerts(): void {
    const { api } = this.context;
    const { folder } = this.state;
    try {
      api.fetchAlerts();
    } catch (e) {
      softError('alerts', `failed to fetch after updating folder[id="${folder?.id}"]`, e);
    }
  }

  onClickSave = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    const { dispatcher } = this.context;
    const { folderNameInput, folder, privacySetting } = this.state;

    // in the case where we can't find a folder given the id from the libraryFolderStore, return early
    if (!folder) {
      return;
    }

    // do nothing if no changes
    if (!this.isDirty()) {
      dispatcher.dispatch(hideShelf());
      return;
    }

    this.setState({ isSending: true });

    this.saveFolderUpdate({
      id: folder.id,
      name: folderNameInput,
      privacySetting,
    })
      .then(() => {
        this.setState({
          isSending: false,
        });
        dispatcher.dispatch(hideShelf());
        this.fetchAlerts(); // Refresh the alerts store as the folder alert is modified too
      })
      .catch(error => {
        this.setState({
          isSending: false,
          errorMessage: getString(_ => _.library.libraryFolderSettingsShelf.updateErrorMessage),
        });
        softError('library', `failed to update folder[id="${folder.id}"]`, error);
      });
  };

  onChangeFolderNameInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    this.setState({
      folderNameInput: event.target.value,
    });
  };

  isDirty(): boolean {
    const { folderNameInput, folder, privacySetting } = this.state;
    return folderNameInput?.trim() !== folder?.name || privacySetting !== folder?.privacySetting;
  }

  async callDeleteFolder(id: number): Promise<void> {
    const { api } = this.context;
    await api.deleteLibraryFolder(id);
    await api.getLibraryFolders(); // Refresh the store with the folder gone and any papers moved to unsorted
    await api.fetchAlerts(); // Refresh the alerts store as the folder alert would be deleted as well
  }

  onClickDelete = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();

    this.setState({
      isConfirmingDelete: true,
    });
  };

  onConfirmDelete = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    const { folder } = this.state;

    // in the case where we can't find a folder given the id from the libraryFolderStore, return early
    if (!folder) {
      return;
    }

    this.setState({
      isSending: true,
    });

    this.callDeleteFolder(folder.id)
      .then(() => {
        this.setState({
          isSending: false,
        });
        this.context.dispatcher.dispatch(hideShelf());

        // If the folderId matches the one in the path, then we redirect to all papers after deletion
        this.checkFolderRedirect();
      })
      .catch(error => {
        softError('library', `failed to delete folder[id="${folder.id}"]`, error);
        this.setState({
          isSending: false,
          errorMessage: getString(_ => _.library.libraryFolderSettingsShelf.deleteErrorMessage),
        });
      });
  };

  checkFolderRedirect = (): void => {
    const {
      context: { libraryFolderId },
    } = this.props;
    const [libraryFolderIdStr] = getParamsForRoute(LIBRARY_FOLDER_ROUTE, location.pathname);
    const libraryFolderIdNum = parseInt(libraryFolderIdStr, 10);
    if (libraryFolderIdNum === libraryFolderId) {
      // issue redirect to All Papers page
      const { history } = this.context;
      const redirect = new S2Redirect({
        routeName: 'LIBRARY_ALL_ENTRIES',
      });
      handleRedirect({ history, redirect });
    }
  };

  onConfirmDeleteCancel = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    this.setState({
      isConfirmingDelete: false,
    });
  };

  onChangePrivacySetting = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const { folder } = this.state;
    const updatedPrivacy = PrivacySettingMap[event.target.value];
    this.setState({
      privacySetting: updatedPrivacy,
      isPublicLinkCopied: false,
    });

    // in the case where we can't find a folder given the id from the libraryFolderStore, return early
    if (!folder) {
      return;
    }

    // autosave only the public/private change in the background
    // all other field changes will only be saved when user explicitly clicks save button
    this.saveFolderUpdate({
      id: folder.id,
      name: folder.name,
      privacySetting: updatedPrivacy,
    }).catch(error => {
      softError('library', `failed to update folder[id="${folder.id}"]`, error);
    });
  };

  onClickCopyPublicLink = (): void => {
    const { folder } = this.state;
    const shareUrl = folder ? buildShareFolderLink(folder.id) : '';
    BrowserUtil.copyToClipboard(shareUrl);
    this.setState({
      isPublicLinkCopied: true,
    });

    setTimeout(() => {
      this.setState({
        isPublicLinkCopied: false,
      });
    }, 10000);
  };

  render(): React.ReactNode {
    const {
      folderNameInput,
      isSending,
      errorMessage,
      folder,
      isConfirmingDelete,
      privacySetting,
      isPublicLinkCopied,
    } = this.state;
    const {
      context: { libraryFolderId },
    } = this.props;
    if (!folder) {
      return (
        <Shelf
          shelfId={LIBRARY_FOLDER_SETTINGS}
          className="library-folder-settings__shelf"
          header={getString(_ => _.library.libraryFolderSettingsShelf.headerText)}>
          <div
            className="library-folder-settings__update-folder__error-msg"
            data-test-id="library-folder-settings-save-error">
            {getString(_ => _.library.libraryFolderSettingsShelf.folderNotFoundError)}
          </div>
        </Shelf>
      );
    }
    return (
      <Shelf
        ariaLabel={getString(_ => _.library.libraryFolderSettingsShelf.closeShelfAriaLabel)}
        shelfId={LIBRARY_FOLDER_SETTINGS}
        className="library-folder-settings__shelf"
        header={getString(_ => _.library.libraryFolderSettingsShelf.headerText)}>
        <div className="library-folder-settings-shelf">
          {!!errorMessage && (
            <div
              className="library-folder-settings__update-folder__error-msg"
              data-test-id="library-folder-settings-save-error">
              <FormLevelMessage message={errorMessage} />
            </div>
          )}
          {isEditableFolder(folder) && (
            <div className="library-folder-settings__metadata">
              <label className="library-folder-settings__input-label">
                {getString(_ => _.library.libraryFolderSettingsShelf.folderNameLabel)}
              </label>
              <CLTextInput
                ariaLabel={getString(_ => _.library.libraryFolderSettingsShelf.editFolderName)}
                className="library-folder-settings__name-input"
                value={folderNameInput}
                onChange={this.onChangeFolderNameInput}
                {...heapLibraryFolderSettingsShelfEditNameInput()}
                data-test-id="library-folder-settings-input"
              />
            </div>
          )}
          <ResearchFeedSettings folder={folder} libraryFolderId={libraryFolderId} />
          {isEditableFolder(folder) && (
            <div className="library-folder-settings__metadata">
              <label className="library-folder-settings__section-label">
                {getString(_ => _.library.libraryFolderSettingsShelf.folderSharingLabel)}
              </label>
              <div className="library-folder-settings__privacy">
                <div className="search-sort dropdown-filters__sort-control library-folder-settings__privacy__select">
                  <select
                    value={privacySetting}
                    onChange={this.onChangePrivacySetting}
                    data-test-id="library-folder-settings-privacy-select"
                    className="legacy__select search-sort-select">
                    <option
                      key={Private}
                      value={Private}
                      {...heapLibraryFolderSettingsShelfEditPrivacyPrivate()}>
                      {Private}
                    </option>
                    <option
                      key={Public}
                      value={Public}
                      {...heapLibraryFolderSettingsShelfEditPrivacyPublic()}>
                      {Public}
                    </option>
                  </select>
                  <Icon icon="disclosure" height="12" width="12" className="dropdown-arrow-icon" />
                </div>
                <div className="library-folder-settings__privacy__label">
                  {privacySetting === Public
                    ? getString(_ => _.library.libraryFolderSettingsShelf.publicFolderDescription)
                    : getString(_ => _.library.libraryFolderSettingsShelf.privateFolderDescription)}
                </div>
              </div>
              {privacySetting === Public &&
                (isPublicLinkCopied ? (
                  <CLIconButton
                    onClick={this.onClickCopyPublicLink}
                    type={BUTTON_TYPE.TERTIARY}
                    icon={<Icon icon="checkmark" height="10" width="13" />}
                    label={getString(_ => _.library.libraryFolderSettingsShelf.copiedLink)}
                    className="library-folder-settings__privacy__copied-button"
                    data-test-id="library-folder-settings-copied-link-button"
                    {...heapLibraryFolderSettingsShelfCopyShareableLinkButton()}
                  />
                ) : (
                  <CLIconButton
                    onClick={this.onClickCopyPublicLink}
                    type={BUTTON_TYPE.TERTIARY}
                    icon={<Icon icon="fa-link" height="12" width="12" />}
                    label={getString(_ => _.library.libraryFolderSettingsShelf.copyLink)}
                    className="library-folder-settings__privacy__copy-button"
                    data-test-id="library-folder-settings-copy-link-button"
                    {...heapLibraryFolderSettingsShelfCopyShareableLinkButton()}
                  />
                ))}
            </div>
          )}
          {!isConfirmingDelete && (
            <div className="library-folder-settings__buttons">
              {!!isSending && <LoadingIndicator />}
              <CLButton
                type={BUTTON_TYPE.PRIMARY}
                label={getString(_ => _.library.libraryFolderSettingsShelf.saveButtonLabel)}
                onClick={this.onClickSave}
                disabled={isSending}
                {...heapLibraryFolderSettingsShelfSaveButton()}
                testId="library-folder-settings-save-button"
              />
              {!isSystemGeneratedFolder(folder) && (
                <CLButton
                  type={BUTTON_TYPE.TERTIARY}
                  label={getString(_ => _.library.libraryFolderSettingsShelf.deleteButtonLabel)}
                  className="library-folder-settings__delete-button"
                  onClick={this.onClickDelete}
                  disabled={isSending}
                  {...heapLibraryFolderSettingsShelfDeleteButton()}
                  testId="library-folder-settings-delete-button"
                />
              )}
            </div>
          )}
          {isConfirmingDelete && (
            <div className="library-folder-settings__delete-confirmation">
              <p className="shelf__secondary-header">
                {getString(_ => _.library.libraryFolderSettingsShelf.confirmDeleteHeaderText)}
              </p>
              <p className="shelf__sub-header">
                {getString(_ => _.library.libraryFolderSettingsShelf.confirmDeleteDialog)}
              </p>
              <CLButton
                type={BUTTON_TYPE.PRIMARY}
                label={getString(
                  _ => _.library.libraryFolderSettingsShelf.confirmDeleteButtonLabel
                )}
                onClick={this.onConfirmDelete}
                disabled={isSending}
                {...heapLibraryFolderSettingsShelfConfirmDeleteButton()}
                className={'library-folder-settings__delete-confirmation-button'}
                testId="library-folder-delete-confirmation-button"
              />
              <CLButton
                type={BUTTON_TYPE.TERTIARY}
                label={getString(_ => _.library.libraryFolderSettingsShelf.cancelDeleteButtonLabel)}
                onClick={this.onConfirmDeleteCancel}
                disabled={isSending}
                testId="library-folder-delete-confirmation-cancel-button"
              />
            </div>
          )}
        </div>
      </Shelf>
    );
  }
}
