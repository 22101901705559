import React from 'react';

export default function IconFaPrint(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 12 12">
      <path
        d="M12,6v2.62C12,8.83,11.83,9,11.62,9H10.5v2.25c0,0.41-0.34,0.75-0.75,0.75h-7.5c-0.41,0-0.75-0.34-0.75-0.75V9H0.38
		    C0.17,9,0,8.83,0,8.62V6c0-0.83,0.67-1.5,1.5-1.5V0.75C1.5,0.34,1.84,0,2.25,0h6.44c0.2,0,0.39,0.08,0.53,0.22l1.06,1.06
		    c0.14,0.14,0.22,0.33,0.22,0.53V4.5C11.33,4.5,12,5.17,12,6z M9,3H7.88C7.67,3,7.5,2.83,7.5,2.62V1.5H3v3.75h6V3z M9,8.25H3v2.25h6
		    V8.25z M10.69,6.38c0-0.31-0.25-0.56-0.56-0.56S9.56,6.06,9.56,6.38s0.25,0.56,0.56,0.56S10.69,6.69,10.69,6.38z"
      />
    </symbol>
  );
}
