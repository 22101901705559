import {
  getPaperPdfSkimmingSnippetFromJS,
  PaperPdfSkimmingSnippetRecord,
  SkimmingLabelType,
} from '@/models/paper-pdf/PaperPdfSkimmingSnippet';
import { Nullable } from '@/utils/types';
import BaseStore from '@/stores/BaseStore';
import Constants from '@/constants';
import S2Dispatcher from '@/utils/S2Dispatcher';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import Immutable from 'immutable';

import type { ApiRequestStartingPayload } from '@/api/BaseApi';
import type { ApiResponse, PaperPdfDataResponseBody } from '@/api/ApiResponse';

export default class ReaderSkimmingStore extends BaseStore {
  #storeState: StoreStateValue;
  #scimIdToSkimmingSnippetMap: Immutable.Map<string, PaperPdfSkimmingSnippetRecord>;
  #pageIndexToScimIdsMap: Immutable.Map<number, Immutable.Set<string>>;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#storeState = StoreState.UNINITIALIZED;
    this.#scimIdToSkimmingSnippetMap = Immutable.Map();
    this.#pageIndexToScimIdsMap = Immutable.Map();

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const apiStartingPayload = payload as ApiRequestStartingPayload;
          switch (apiStartingPayload.requestType) {
            case Constants.requestTypes.PDF_DATA: {
              this.#scimIdToSkimmingSnippetMap = Immutable.Map();
              this.#pageIndexToScimIdsMap = Immutable.Map();
              this.#storeState = StoreState.LOADING;
              this.emitChange();
              return;
            }
          }
          return;
        }

        case Constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.PDF_DATA: {
              const typedPayload = payload as ApiResponse<PaperPdfDataResponseBody>;
              // Note: Fields can be dropped if they are assigned 'None'
              const rawSnippets = typedPayload?.resultData?.skimmingSnippets?.feature || [];
              // Note: We filter out __background__ snippets as they are legacy and unused.
              // Consider whether to filter these in service in the future.
              rawSnippets
                .filter(record => record.label !== 'background')
                .forEach(rawSnippet => {
                  const snippet = getPaperPdfSkimmingSnippetFromJS(rawSnippet);

                  if (!snippet) {
                    return;
                  }

                  this.#scimIdToSkimmingSnippetMap = this.#scimIdToSkimmingSnippetMap.set(
                    snippet.scimId,
                    snippet
                  );

                  snippet.boundingBoxes
                    .map(boundingBox => boundingBox.page)
                    .forEach(pageIndex => {
                      const scimIdsSet = this.#pageIndexToScimIdsMap.get(
                        pageIndex,
                        Immutable.Set<string>()
                      );

                      this.#pageIndexToScimIdsMap = this.#pageIndexToScimIdsMap.set(
                        pageIndex,
                        scimIdsSet.add(snippet.scimId)
                      );
                    });
                });

              this.#storeState = StoreState.LOADED;
              this.emitChange();

              return;
            }
          }
          return;
        }

        case Constants.actions.API_REQUEST_FAILED: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.PDF_DATA: {
              this.#storeState = StoreState.ERROR;
              this.emitChange();
              return;
            }
          }
          return;
        }
      }
    });
  }

  getFilteredScimIdsByPageIndex(
    pageIndex: number,
    labelExcludes: SkimmingLabelType[] = [],
    score: number = 0
  ): Immutable.Set<string> {
    const filteredIds = this.#scimIdToSkimmingSnippetMap
      .filter(record => record.score >= score && !labelExcludes.includes(record.label))
      .keySeq();

    return this.#pageIndexToScimIdsMap.get(pageIndex, Immutable.Set('')).intersect(filteredIds);
  }

  getFilteredSkimmingSnippetList(
    labelExcludes: SkimmingLabelType[] = [],
    score: number = 0
  ): Immutable.List<PaperPdfSkimmingSnippetRecord> {
    const filteredSnippets = this.#scimIdToSkimmingSnippetMap
      .filter(record => record.score >= score && !labelExcludes.includes(record.label))
      .toList();

    return filteredSnippets;
  }

  getSkimmingSnippetById(scimId: string): Nullable<PaperPdfSkimmingSnippetRecord> {
    return this.#scimIdToSkimmingSnippetMap.get(scimId, null);
  }

  getCountsBySnippetLabel(score: number = 0): Immutable.Map<SkimmingLabelType, number> {
    return this.#scimIdToSkimmingSnippetMap.reduce((countsByLabelMap, record) => {
      if (record.score >= score) {
        return countsByLabelMap.set(record.label, countsByLabelMap.get(record.label, 0) + 1);
      }
      return countsByLabelMap;
    }, Immutable.Map());
  }

  hasSkimmingSnippets(): boolean {
    return !this.#scimIdToSkimmingSnippetMap.isEmpty();
  }

  isUninitialized(): boolean {
    return this.#storeState === StoreState.UNINITIALIZED;
  }

  isLoading(): boolean {
    return this.isUninitialized() || this.#storeState === StoreState.LOADING;
  }

  isFailed(): boolean {
    return this.#storeState === StoreState.ERROR;
  }
}
