import { focusOnFocusableElement, mkOnClickKeyDown } from '@/utils/a11y-utils';
import { hideModal } from '@/actions/ModalActionCreators';
import { KEY_CODE_ESC } from '@/constants/KeyCode';
import S2Dispatcher from '@/utils/S2Dispatcher';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

const stopPropagation = e => e.stopPropagation();

export default class Modal extends PureComponent {
  static contextTypes = {
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
  };

  static propTypes = {
    className: PropTypes.string,
    darkBackground: PropTypes.bool,
    modalId: PropTypes.string,
    onHidden: PropTypes.func,
    onHideClick: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    this.focusTrappedRef = React.createRef();
  }

  hideModal = () => {
    if (typeof this.props.onHideClick === 'function') {
      this.props.onHideClick();
    } else {
      this.context.dispatcher.dispatch(hideModal());
    }
  };

  _onClickKeyDownStopProps = mkOnClickKeyDown({
    onClick: stopPropagation,
  });

  componentDidMount() {
    this.elementToFocusAfterClose = document.activeElement;
    focusOnFocusableElement({ focusTrappedRef: this.focusTrappedRef });
  }

  componentWillUnmount() {
    if (this.elementToFocusAfterClose) {
      this.elementToFocusAfterClose.focus();
    }
    if (typeof this.props.onHidden === 'function') {
      this.props.onHidden();
    }
  }

  render() {
    return (
      <div
        data-modal-id={this.props.modalId}
        className={classNames('modal-container', { 'dark-modal': this.props.darkBackground })}
        ref={this.focusTrappedRef}
        {...mkOnClickKeyDown({
          onClick: this.hideModal,
          overrideKeys: [KEY_CODE_ESC],
          focusTrappedRef: this.focusTrappedRef,
        })}>
        <div
          role="dialog"
          className={classNames('modal', this.props.className)}
          {...this._onClickKeyDownStopProps}
          ref="modal">
          {this.props.children}
        </div>
      </div>
    );
  }
}
