import { getFacetValueFromJS } from './FacetValue';
import { getPaperFromJS, PaperFromJS, PaperRecord } from './Paper';
import {
  getVenueQueryRecordFromJS,
  VenueQueryFromJS,
  VenueQueryRecord,
  VenueQueryRecordFactory,
} from './VenueQuery';
import { getYearBucketFromFacet } from './YearBucket';
import { PaperSearchAggregationsFromJS } from './QueryUtils';

import Immutable from 'immutable';

export type VenueQueryResponseFromJS = {
  query: VenueQueryFromJS;
  results: PaperFromJS[]; // gotta use this as the CL paper components assume this type.
  totalPages: number;
  totalHits: number;
  stats: PaperSearchAggregationsFromJS;
};

type Props = {
  query: VenueQueryRecord;
  stats: Immutable.Map<string, any>;
  results: Immutable.List<PaperRecord>;
  totalPages: number;
  totalResults: number;
};

const defaultProps: Props = {
  query: VenueQueryRecordFactory(),
  stats: Immutable.Map({
    authors: Immutable.List(),
    coAuthors: Immutable.List(),
    filteredYears: Immutable.List(),
    fieldsOfStudy: Immutable.List(),
    years: Immutable.List(),
    totalCountAfterFilters: 0,
  }),
  results: Immutable.List(),
  totalPages: 0,
  totalResults: 0,
};

export const VenueQueryResponseRecordFactory = Immutable.Record<Props>(defaultProps);
export type VenueQueryResponseRecord = Immutable.RecordOf<Props>;

export function getVenueQueryResponseFromJS(
  args: VenueQueryResponseFromJS
): VenueQueryResponseRecord {
  const response = { ...args };
  const papers = Immutable.List(response.results.map(venuePaper => getPaperFromJS(venuePaper)));
  const query = getVenueQueryRecordFromJS(response.query);
  // venues shouldn't be a facet on the venue page.
  // Filter out venues stats object, as we do not want venues to be displayed on the filter bar.
  // The fields in the stats object are being used as
  // fields in the filter bar (e.g. the authors stats are being used to power the "filter by author" filter facet).
  const statsWithoutVenues = Object.keys(response.stats).reduce(function (filtered, key) {
    if (key !== 'venues') {
      filtered[key] = response.stats[key];
    }
    return filtered;
  }, {});

  const enum facets {
    filteredYears = 'filteredYears',
    countOfPapersWithViewablePdf = 'countOfPapersWithViewablePdf',
    totalNumCitedBy = 'totalNumCitedBy',
    totalNumKeyCitations = 'totalNumKeyCitations',
    totalCountAfterFilters = 'totalCountAfterFilters',
    years = 'years',
  }

  const stats = Object.keys(statsWithoutVenues).reduce((map, facetKey) => {
    switch (facetKey) {
      case facets.years:
        return map.set(
          facets.years,
          Immutable.List(response.stats[facetKey]?.map(year => getYearBucketFromFacet(year)))
        );
      case facets.filteredYears:
        return map.set(
          facets.filteredYears,
          Immutable.List(response.stats[facetKey]?.map(year => getYearBucketFromFacet(year)))
        );
      case facets.countOfPapersWithViewablePdf:
        return map.set(
          facets.countOfPapersWithViewablePdf,
          response.stats.countOfPapersWithViewablePdf
        );
      case facets.totalNumCitedBy:
        return map.set(facets.totalNumCitedBy, response.stats.totalNumCitedBy);
      case facets.totalNumKeyCitations:
        return map.set(facets.totalNumKeyCitations, response.stats.totalNumKeyCitations);
      case facets.totalCountAfterFilters:
        return map.set(facets.totalCountAfterFilters, response.stats.totalCountAfterFilters);
      default:
        return map.set(
          facetKey,
          Immutable.OrderedSet(response.stats[facetKey].map(val => getFacetValueFromJS(val)))
        );
    }
  }, Immutable.Map<string, any>());
  return VenueQueryResponseRecordFactory({
    query: query,
    results: papers,
    stats: stats,
    totalPages: response.totalPages,
    totalResults: response.totalHits,
  });
}
