import BaseStore from './BaseStore';

import { FeatureFlagDurationType, Temporary } from '@/models/feature-flag/FeatureFlagDurationType';
import constants from '@/constants';
import Feature, { getFeatureList } from '@/weblab/Feature';

import Immutable from 'immutable';

import type { ApiResponse, GetFeatureFlagsResponseBody } from '@/api/ApiResponse';
import type { FeatureRecord } from '@/weblab/FeatureRecord';
import type { Nullable } from '@/utils/types';
import type S2Dispatcher from '@/utils/S2Dispatcher';

export type FeatureFlagConfig = {
  key: string;
  id: Nullable<number>;
  salt: Nullable<string>;
  trafficRatio: number;
  durationType: FeatureFlagDurationType;
  isInDb: boolean;
};

export default class FeatureFlagStore extends BaseStore {
  #featureFlagsByKey: Immutable.Map<string, FeatureFlagConfig>;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#featureFlagsByKey = Immutable.Map();

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case constants.requestTypes.GET_FEATURE_FLAGS:
              return this.#handleGetFeatureFlagsComplete(apiResponse);
          }
          break;
        }
      }
    });
  }

  #handleGetFeatureFlagsComplete(payload: ApiResponse<GetFeatureFlagsResponseBody>): void {
    const { states } = payload.resultData;
    const statesByKey = new Map(Object.entries(states));
    const featuresByKey = Immutable.Map<string, FeatureRecord>(
      getFeatureList(Feature).map(feature => [feature.KEY, feature])
    );

    this.#featureFlagsByKey = featuresByKey.map(featureRecord => {
      const flagState = statesByKey.get(featureRecord.KEY);
      let trafficRatio = 0;
      if (flagState) {
        trafficRatio = flagState.trafficRatio;
      } else if (featureRecord) {
        trafficRatio = featureRecord.fallbackState ? 1 : 0;
      }

      const config: FeatureFlagConfig = {
        key: featureRecord.KEY,
        id: flagState?.id || null,
        salt: flagState?.salt || null,
        trafficRatio,
        durationType: flagState?.durationType?.id || Temporary,
        isInDb: !!flagState,
      };
      Object.freeze(config);
      return config;
    });

    this.emitChange();
  }

  getFeatureFlagsConfigs(): Immutable.Map<string, FeatureFlagConfig> {
    return this.#featureFlagsByKey;
  }
}
