import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

type Props = {
  field: Nullable<string>;
  previousValue: Nullable<any>;
  changeValue: Nullable<any>;
};

export type FieldStateFromJS = {
  value: any;
  field: string;
};

export type FieldCorrectionFromJS = {
  field: string;
  change: FieldStateFromJS;
  previous: FieldStateFromJS;
};

const defaultProps: Props = {
  field: null,
  previousValue: null,
  changeValue: null,
};

export const FieldCorrectionRecordFactory = Immutable.Record<Props>(defaultProps);
export type FieldCorrectionRecord = Immutable.RecordOf<Props>;

export function getFieldCorrectionRecordFromJS(args: FieldCorrectionFromJS): FieldCorrectionRecord {
  return FieldCorrectionRecordFactory({
    field: args.field,
    previousValue: args.previous?.value,
    changeValue: args.change?.value,
  });
}
