import { isFunction, isUndefined } from './types';

/**
 * Returns a function which executes orig and then fn, effectively "wrapping" fn within orig.
 *
 * @param {function} orig The original function.
 * @param {function} fn The function to be wrapped.
 *
 * @returns {function} A function which executes orig and then fn.
 */
export default function wrap<TArgs>(
  orig?: (...args: TArgs[]) => void,
  fn?: (...args: TArgs[]) => void
): (...args: TArgs[]) => void {
  if (!isFunction(fn)) {
    throw new Error("Attempt to wrap something which isn't a function.");
  }
  if (isFunction(orig)) {
    const _orig = orig;
    return (...args) => {
      _orig(...args);
      fn(...args);
    };
  } else if (isUndefined(orig)) {
    return fn;
  } else {
    throw new Error("Attempt to wrap something with something which isn't a function");
  }
}
