import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

export type AHPPapersSearchSortFromJS = {
  ahpPapersSearchSort: Nullable<string>;
};

export type AHPPapersSearchSort = {
  ahpPapersSearchSort: Nullable<string>;
};

export const AHP_PAPERS_SEARCH_SORT_RECORD_NAME = 'AHPPapersSearchSort';
export const AHPPapersSearchSortRecordFactory = Immutable.Record<AHPPapersSearchSort>(
  {
    ahpPapersSearchSort: null,
  },
  AHP_PAPERS_SEARCH_SORT_RECORD_NAME
);
export type AHPPapersSearchSortRecord = Immutable.RecordOf<AHPPapersSearchSort>;

export function getAHPPapersSearchSortStateFromJS(
  args: AHPPapersSearchSortFromJS
): AHPPapersSearchSortRecord {
  return AHPPapersSearchSortRecordFactory({
    ...args,
  });
}
