import Immutable from 'immutable';

export type ScorecardStatFromJS = {
  typeKey: 'cited_by';
  citationCount: number;
  keyCitationCount: number;
  citationIntentCount: {
    methodology: number;
    background: number;
    result: number;
  };
};

type Props = {
  typeKey: 'cited_by';
  citationCount: number;
  keyCitationCount: number;
  citationIntentCount: {
    methodology: number;
    background: number;
    result: number;
  };
};

export type ScorecardStatRecord = Immutable.RecordOf<Props>;

export const CITED_BY = 'cited_by';
const defaultProps: Props = {
  citationCount: 0,
  keyCitationCount: 0,
  citationIntentCount: {
    methodology: 0,
    background: 0,
    result: 0,
  },
  typeKey: CITED_BY,
};

export const CitedByScorecardStatRecordFactory = Immutable.Record(defaultProps);

export const getScorecardStatFromJS = (args: ScorecardStatFromJS): ScorecardStatRecord => {
  switch (args.typeKey) {
    default:
      return CitedByScorecardStatRecordFactory();
    case CITED_BY:
      return CitedByScorecardStatRecordFactory(args);
  }
};
