import React from 'react';

export default function IconSemanticReader(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="138 138 157 157">
      <path
        d="M274.06,162.15a10.14,10.14,0,0,1,1.8.17,9.43,9.43,0,0,1,7.61,9.15v0a9.42,9.42,0,0,1-7.64,9.15,9.56,9.56,0,0,1-1.77.17H171.52a29.37,29.37,0,0,0,1.55-9.21v-.26a29.37,29.37,0,0,0-1.55-9.21H274.06m-119.63-10c-.27,0-1.43,0-1.73.75a1.36,1.36,0,0,0,.52,1.76,19.4,19.4,0,0,1,9.85,16.77v.12a19.39,19.39,0,0,1-9.85,16.77,1.36,1.36,0,0,0-.52,1.76c.3.71,1.46.75,1.73.75H274.06a19.4,19.4,0,0,0,19.41-19.28v-.12a19.4,19.4,0,0,0-19.41-19.28H154.43Z"
        fill="#F4D35E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M278.3,206.76h0m-17.4,0a29.1,29.1,0,0,0-1.56,9.22v.25a29,29,0,0,0,1.56,9.21H158.35a9.11,9.11,0,0,1-1.79-.17,9.41,9.41,0,0,1-7.61-9.14v0a9.42,9.42,0,0,1,7.63-9.15,9.56,9.56,0,0,1,1.77-.17H260.9m17.08-10H158.35a18.49,18.49,0,0,0-3.67.36A19.39,19.39,0,0,0,139,216.05v.12a19.4,19.4,0,0,0,19.4,19.28H278c.28,0,1.44-.05,1.73-.76a1.35,1.35,0,0,0-.52-1.76,19.41,19.41,0,0,1-9.85-16.77v-.12a19.43,19.43,0,0,1,9.85-16.77,1.34,1.34,0,0,0,.52-1.75c-.29-.72-1.45-.76-1.73-.76Z"
        fill="#1857B6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M274.06,251.58a9.31,9.31,0,0,1,1.8.18,9.42,9.42,0,0,1,7.61,9.14V261a9.44,9.44,0,0,1-7.64,9.15,9.56,9.56,0,0,1-1.77.17H171.52a29.43,29.43,0,0,0,1.55-9.22v-.25a29.38,29.38,0,0,0-1.55-9.22H274.06m-119.63-10c-.27,0-1.43,0-1.73.76a1.36,1.36,0,0,0,.52,1.76,19.39,19.39,0,0,1,9.85,16.76V261a19.37,19.37,0,0,1-9.85,16.76,1.37,1.37,0,0,0-.52,1.76c.3.71,1.46.76,1.73.76H274.06A19.46,19.46,0,0,0,293.47,261v-.13a19.46,19.46,0,0,0-19.41-19.28H154.43Z"
        fill="#0F3875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </symbol>
  );
}
