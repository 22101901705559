import React from 'react';

export default function IconSprinkles(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 396 467" fill="none">
      <path
        d="M341 2V22"
        stroke="white"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M319.79 10.79L333.93 24.93"
        stroke="white"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M311 32H331"
        stroke="white"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M319.79 53.21L333.93 39.07"
        stroke="white"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M341 62V42"
        stroke="white"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M362.21 53.21L348.07 39.07"
        stroke="white"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M371 32H351"
        stroke="white"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M362.21 10.79L348.07 24.93"
        stroke="white"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150 42C155.523 42 160 37.5228 160 32C160 26.4772 155.523 22 150 22C144.477 22 140 26.4772 140 32C140 37.5228 144.477 42 150 42Z"
        stroke="white"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 414C11.09 414 11.09 426 20.18 426C29.27 426 29.27 414 38.36 414C47.45 414 47.45 426 56.54 426C65.63 426 65.63 414 74.72 414C83.81 414 83.81 426 92.9 426C101.99 426 101.99 414 111.08 414C120.17 414 120.17 426 129.26 426C138.35 426 138.35 414 147.44 414C156.53 414 156.53 426 165.62 426C174.71 426 174.71 414 183.81 414C192.91 414 192.9 426 202 426"
        stroke="white"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M364.53 410V430"
        stroke="white"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M336 430.73L355.02 436.91"
        stroke="white"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M346.9 464.27L358.65 448.09"
        stroke="white"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M382.17 464.27L370.41 448.09"
        stroke="white"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M393.06 430.73L374.04 436.91"
        stroke="white"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </symbol>
  );
}
