import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import S2Redirect from '@/models/redirects/S2Redirect';

import React from 'react';

export default class AccountAuthorContactRoute extends React.Component {
  static getPageTitle = () => getString(_ => _.account.page.authorContact.pageTitle);

  static async willRouteTo({ api, authStore, authorProfileStore, authorCorrectionsStore }) {
    if (!authorCorrectionsStore.isModeratorCorrecting()) {
      const authorId = authStore.getClaimedAuthorId();
      const profile = authorProfileStore.getAuthorProfile();
      if (!authorId) {
        return new S2Redirect({
          routeName: 'ACCOUNT_CONTACT',
          replace: true,
          status: 302,
        });
      }
      if (!profile) {
        // Preload author profile for server rendering
        return api.fetchAuthorProfile({ authorId });
      }
    } else {
      const authorId = authorCorrectionsStore.getModeratorCorrectionsAuthorId();
      if (authorId) {
        return [api.fetchAuthorDetail(authorId, null, false), api.fetchAuthorProfile({ authorId })];
      }
    }
  }

  render() {
    return (
      <AsyncLoadedPage
        showLoadingPage={false}
        load={{
          importer: () =>
            import(
              /* webpackChunkName: "shared-AccountAuthorContactPage" */ './AccountAuthorContactPage'
            ),
          chunkName: 'shared-AccountAuthorContactPage',
          moduleId: require.resolveWeak('./AccountAuthorContactPage'),
        }}
      />
    );
  }
}
