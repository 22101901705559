/* Models an experiment membership record for S2 Research experiments
 * conducted via Skipper.
 * Specifies the type and identity of an object, along with the
 * name of the experiment in which it is a member.
 * Optionally includes precomputed free-form data with which to
 * furnish an experimental treatment UI.
 */

import { ValueOf } from '@/utils/types';

import Immutable from 'immutable';

export type SkipperExperimentFromJS = {
  objectKey: string;
  objectType: ObjectType;
  experimentName: string;
  objectData: any;
  createdAt: string;
  expiresAt: string;
};

type Props = {
  objectKey: string;
  objectType: ObjectType;
  experimentName: string;
  objectData: any;
};

export type ObjectType = ValueOf<typeof OBJECT_TYPE>;
export const OBJECT_TYPE = {
  PAPER: 'paper',
  CITATION: 'citation',
  PAPER_CITATIONS: 'paper-citations',
} as const;

const defaultProps: Props = {
  objectKey: '',
  objectType: OBJECT_TYPE.PAPER,
  experimentName: '',
  objectData: null,
};

export const SkipperExperimentRecordFactory = Immutable.Record<Props>(defaultProps);
export type SkipperExperimentRecord = Immutable.RecordOf<Props>;

export function getSkipperExperimentFromJS(args: SkipperExperimentFromJS): SkipperExperimentRecord {
  return SkipperExperimentRecordFactory({ ...args });
}
