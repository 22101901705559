import React from 'react';

export default function IconSmallX(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 24 24">
      <path
        d="M17.5,12l6.1-6C23.9,5.7,24,5.3,24,4.9c0-0.4-0.1-0.8-0.4-1.1l-3.4-3.4C19.9,0.1,19.5,0,19.1,0c-0.4,0-0.7,
        0.1-1,0.4L12,6.5 L6,0.4C5.7,0.1,5.4,0,4.9,0C4.4,0,4.1,0.1,3.8,0.4L0.4,3.7C0.1,4,0,4.3,0,4.8s0.1,0.8,0.4,1.1L6.5,
        12l-6.1,6C0.1,18.3,0,18.7,0,19.1 c0,0.4,0.1,0.8,0.4,1.1l3.4,3.4C4.1,23.9,4.5,24,4.9,24c0.4,0,0.7-0.1,1-0.4l6.1-6.1l6,
        6.1c0.3,0.3,0.6,0.4,1.1,0.4 c0.5,0,0.8-0.1,1.1-0.4l3.4-3.3c0.3-0.3,0.4-0.6,0.4-1.1c0-0.5-0.1-0.8-0.4-1.1L17.5,12z"
      />
    </symbol>
  );
}
