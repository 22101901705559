import { fetchAuthorCoAuthorsUntilPageNumber } from '@/actions/AuthorActionCreators';
import { MAX_CO_AUTHORS_PER_PAGE } from '@/stores/author/AuthorCoAuthorsStore';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

import type { WillRouteToResult } from '@/router/Route';
import type AppContext from '@/AppContext';

export default class AuthorCoAuthorsRoute extends React.Component {
  static async willRouteTo(appContext: AppContext, routerState): Promise<WillRouteToResult> {
    const { authorId } = routerState.params;
    const { page } = routerState.query;
    const { authorCoAuthorsStore } = appContext;

    const promises: Promise<WillRouteToResult>[] = [];

    if (!authorCoAuthorsStore.isAuthorLoaded(authorId) || page) {
      const pageNumber = page ? parseInt(page, 10) : 1;
      promises.push(
        fetchAuthorCoAuthorsUntilPageNumber(
          {
            authorId,
            limit: MAX_CO_AUTHORS_PER_PAGE,
            pageNumber,
          },
          appContext
        )
      );
    }

    return Promise.all(promises);
  }

  render(): React.ReactNode {
    return (
      <AsyncLoadedPage
        showLoadingPage={false}
        load={{
          importer: () =>
            import(/* webpackChunkName: "shared-AuthorCoAuthorsPage" */ './AuthorCoAuthorsPage'),
          chunkName: 'shared-AuthorCoAuthorsPage',
          moduleId: require.resolveWeak('./AuthorCoAuthorsPage'),
        }}
      />
    );
  }
}
