import { mkHeapAttributes } from '@/analytics/heapUtils';

import type { LoginMethodId } from '@/constants/LoginMethods';

export const heapSignInModalButton = () =>
  mkHeapAttributes({
    id: 'sign_in_modal_button',
  });

export const heapSignUpModalButton = () =>
  mkHeapAttributes({
    id: 'sign_up_modal_button',
  });

export const heapSignInMethodButton = (props: { method: LoginMethodId }) =>
  mkHeapAttributes({
    id: 'sign_in_method_button',
    ...props,
  });

export const heapSignOutButton = () =>
  mkHeapAttributes({
    id: 'sign_out_button',
  });

export const heapLibraryLink = () =>
  mkHeapAttributes({
    id: 'research_menu_library_link',
  });

export const heapResearchHomepageLink = () =>
  mkHeapAttributes({
    id: 'research_menu_homepage_link',
  });
