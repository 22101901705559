import React from 'react';

export default function IconResearchDashboardExplanation(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 165.8 168">
      <style type="text/css">
        {`.research-base{fill:#CED4D7;}
        .research-highlight{fill:#5492EF;}
        .research-reverse{fill:#fff;}
        `}
      </style>

      <path
        className="research-highlight"
        d="M4,0h118c2.2,0,4,1.8,4,4v160c0,2.2-1.8,4-4,4H4c-2.2,0-4-1.8-4-4V4C0,1.8,1.8,0,4,0z"
      />
      <path
        className="research-base"
        d="M161.8,0h-27.4c-2.2,0-4,1.8-4,4v42.8c0,2.2,1.8,4,4,4h27.4c2.2,0,4-1.8,4-4V4C165.8,1.8,164,0,161.8,0z
           M161.8,55.3h-27.4c-2.2,0-4,1.8-4,4V164c0,2.2,1.8,4,4,4h27.4c2.2,0,4-1.8,4-4V59.3C165.8,57.1,164,55.3,161.8,55.3z"
      />
      <path
        className="research-reverse"
        d="M93.4,31.4v-7H83c-0.7,0-1.2,0.5-1.2,1.2v24.1c0,0.7,0.5,1.3,1.2,1.2h17.4c0.7,0,1.2-0.5,1.2-1.2v-17h-7
          C94,32.6,93.4,32,93.4,31.4z M101.3,29.7l-5.1-5.1c-0.2-0.2-0.5-0.4-0.9-0.4h-0.3v6.6h6.6v-0.3C101.7,30.3,101.6,30,101.3,29.7z
           M93.4,66.7v-7H83c-0.7,0-1.2,0.5-1.2,1.2V85c0,0.7,0.5,1.3,1.2,1.2h17.4c0.7,0,1.2-0.5,1.2-1.2V68h-7C94,68,93.4,67.4,93.4,66.7z
           M96.3,60c-0.2-0.2-0.5-0.4-0.9-0.4h-0.3v6.6h6.6V66c0-0.3-0.1-0.6-0.4-0.9L96.3,60z M38.8,24.7c-0.2-0.2-0.5-0.4-0.9-0.4h-0.3v6.6
          h6.6v-0.3c0-0.3-0.1-0.6-0.4-0.9L38.8,24.7z M35.9,31.4v-7H25.5c-0.7,0-1.2,0.5-1.2,1.2v24.1c0,0.7,0.5,1.3,1.2,1.2H43
          c0.7,0,1.2-0.5,1.2-1.2v-17h-7C36.5,32.6,35.9,32,35.9,31.4z M35.9,66.7v-7H25.5c-0.7,0-1.2,0.5-1.2,1.2V85c0,0.7,0.5,1.3,1.2,1.2
          H43c0.7,0,1.2-0.5,1.2-1.2V68h-7C36.5,68,35.9,67.4,35.9,66.7z M38.8,60c-0.2-0.2-0.5-0.4-0.9-0.4h-0.3v6.6h6.6V66
          c0-0.3-0.1-0.6-0.4-0.9L38.8,60z M35.9,102.1v-7H25.5c-0.7,0-1.2,0.5-1.2,1.2v24.1c0,0.7,0.5,1.3,1.2,1.2H43c0.7,0,1.2-0.5,1.2-1.2
          v-17h-7C36.5,103.4,35.9,102.8,35.9,102.1z M38.8,95.4c-0.2-0.2-0.5-0.4-0.9-0.4h-0.3v6.6h6.6v-0.3c0-0.3-0.1-0.6-0.4-0.9
          L38.8,95.4z M67.5,24.7c-0.2-0.2-0.5-0.4-0.9-0.4h-0.3v6.6h6.6v-0.3c0-0.3-0.1-0.6-0.4-0.9L67.5,24.7z M64.6,31.4v-7H54.3
          c-0.7,0-1.2,0.5-1.2,1.2v24.1c0,0.7,0.5,1.3,1.2,1.2h17.4c0.7,0,1.2-0.5,1.2-1.2v-17h-7C65.2,32.6,64.7,32,64.6,31.4z M67.5,60
          c-0.2-0.2-0.5-0.4-0.9-0.4h-0.3v6.6h6.6V66c0-0.3-0.1-0.6-0.4-0.9L67.5,60z M64.6,66.7v-7H54.3c-0.7,0-1.2,0.5-1.2,1.2V85
          c0,0.7,0.5,1.3,1.2,1.2h17.4c0.7,0,1.2-0.5,1.2-1.2V68h-7C65.2,68,64.7,67.4,64.6,66.7z M64.6,102.1v-7H54.3
          c-0.7,0-1.2,0.5-1.2,1.2v24.1c0,0.7,0.5,1.3,1.2,1.2h17.4c0.7,0,1.2-0.5,1.2-1.2v-17h-7C65.2,103.4,64.7,102.8,64.6,102.1z
           M67.5,95.4c-0.2-0.2-0.5-0.4-0.9-0.4h-0.3v6.6h6.6v-0.3c0-0.3-0.1-0.6-0.4-0.9L67.5,95.4z"
      />
    </symbol>
  );
}
