import { FilterRecord, FilterRecordFactory } from '@/models/Filter';

import Immutable from 'immutable';

export enum CitationType {
  CITING_PAPERS = 'citingPapers',
  CITED_PAPERS = 'citedPapers',
}

const Citation = {
  CITATIONS_PAGE_SIZE: 10,
  MAX_CITATION_PAGES: 100, // Server limits us to 101 pages
  ALL: 'all',
  RESULT: 'result',
  METHODOLOGY: 'methodology',
  BACKGROUND: 'background',

  INTENTS: {
    RESULT: FilterRecordFactory({ id: 'result' }),
    METHODOLOGY: FilterRecordFactory({ id: 'methodology' }),
    BACKGROUND: FilterRecordFactory({ id: 'background' }),

    ALL_INTENTS: FilterRecordFactory({ id: 'all' }),
  },

  /**
   * Returns the Citation Intent filter options for the cited-by lists on the paper detail page
   * in the standard display order.
   * @returns {Immutable.List<FilterRecord>}
   */
  getIntents(): Immutable.List<FilterRecord> {
    return Immutable.List.of(
      this.INTENTS.ALL_INTENTS,
      this.INTENTS.BACKGROUND,
      this.INTENTS.METHODOLOGY,
      this.INTENTS.RESULT
    );
  },
};

Object.freeze(Citation);
Object.freeze(Citation.INTENTS);
export default Citation;
