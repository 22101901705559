import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import AppPage, { AppPageContent, AppPageFooter, AppPageHeader } from './AppPage';

import { Nullable, ReactNodeish } from '@/utils/types';

import React, { PropsWithChildren, ReactNode } from 'react';

type PageProps = PropsWithChildren<{
  className?: Nullable<string>;
  header?: Nullable<ReactNode>;
  footer?: Nullable<ReactNode>;
}>;

export default function Page({
  children,
  className,
  header,
  footer,
  ...otherProps
}: PageProps): ReactNodeish {
  return (
    <AppPage {...otherProps} className={className}>
      {header && (
        <AppPageHeader>{typeof header === 'boolean' ? <AppHeader /> : header}</AppPageHeader>
      )}

      {children && <AppPageContent>{children}</AppPageContent>}

      {footer && (
        <AppPageFooter>{typeof footer === 'boolean' ? <AppFooter /> : footer}</AppPageFooter>
      )}
    </AppPage>
  );
}

Page.defaultProps = {
  header: true,
  footer: true,
};
