import { getString } from '@/content/i18n';
import { isAI2OwnedUrl } from '@/constants/AI2Domain';

import React from 'react';

export default class ExternalLink extends React.PureComponent {
  render() {
    const { allowReferrer, children, href, ...rest } = this.props;

    const shouldAllowReferrer =
      typeof allowReferrer === 'boolean' ? allowReferrer : isAI2OwnedUrl(href);

    return (
      <a
        href={href}
        target="_blank"
        rel={`noopener ${!shouldAllowReferrer ? 'noreferrer' : ''}`}
        {...rest}>
        {children}
        <span className="screen-reader-only">{getString(_ => _.general.newTab)}</span>
      </a>
    );
  }
}
