import { COGNITO, FORGOT_PASSWORD, SIGN_IN } from '@/constants/LoginMethods';
import { getString } from '@/content/i18n';
import CLButton, {
  BEHAVIOR as BUTTON_BEHAVIOR,
  TYPE as BUTTON_TYPE,
} from '@/components/library/button/CLButton';
import CLEmailInput from '@/components/library/form/input/CLEmailInput';
import CLFieldError, { ARROW_SIDE } from '@/components/library/form/error/CLFieldError';
import CLPasswordInput from '@/components/library/form/input/CLPasswordInput';
import CLTextButton from '@/components/library/button/CLTextButton';

import React from 'react';

// defined by cognito, it's essentially anything but whitespace
const VALID_USERNAME_REGEX = /^\S+$/;

export default function LoginMethods({ onRequestLogin }) {
  // State for form
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [usernameErrorMessage, setUsernameErrorMessage] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const onEmailChanged = event => {
    setUsernameErrorMessage('');
    setEmail(event.currentTarget.value);
  };

  const onClickPasswordReset = event => {
    event.preventDefault();
    onRequestLogin({
      method: COGNITO,
      action: FORGOT_PASSWORD,
    });
  };

  const onClickSubmit = async event => {
    event.preventDefault();
    if (!VALID_USERNAME_REGEX.test(email)) {
      setUsernameErrorMessage(getString(_ => _.login.signIn.error.invalidEmail));
      return;
    } else {
      setUsernameErrorMessage('');
    }

    setIsSubmitting(true);
    await onRequestLogin({
      method: COGNITO,
      action: SIGN_IN,
      email,
      password,
    });
    setIsSubmitting(false);
  };

  const isSignInEnabled = email && password && !isSubmitting;

  return (
    <form className="login-methods__form" onSubmit={e => e.preventDefault()}>
      <div className="login-methods__form__field">
        {!!usernameErrorMessage && (
          <CLFieldError arrow={ARROW_SIDE.BOTTOM}>{usernameErrorMessage}</CLFieldError>
        )}
        <CLEmailInput
          name="email"
          value={email}
          onChange={onEmailChanged}
          placeholder={getString(_ => _.login.loginForm.emailPlaceholder)}
        />
      </div>
      <div className="login-methods__form__field">
        <CLPasswordInput
          value={password}
          onChange={e => setPassword(e.currentTarget.value)}
          placeholder={getString(_ => _.login.loginForm.passwordPlaceholder)}
        />
      </div>
      <div className="login-methods__form__actions">
        <div>
          <CLTextButton
            label={getString(_ => _.login.loginForm.passwordResetLabel)}
            behavior={BUTTON_BEHAVIOR.BUTTON}
            onClick={onClickPasswordReset}
          />
        </div>
        <div>
          <CLButton
            type={BUTTON_TYPE.PRIMARY}
            label={getString(_ => _.login.signIn.submitButtonLabel)}
            behavior={BUTTON_BEHAVIOR.SUBMIT}
            onClick={onClickSubmit}
            disabled={!isSignInEnabled}
          />
        </div>
      </div>
    </form>
  );
}
