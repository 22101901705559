import CLButtonBase, { SIZE as _SIZE, TYPE as _TYPE, CLButtonBaseProps } from './CLButtonBase';

import Icon from '@/components/shared/icon/Icon';

import classnames from 'classnames';
import React from 'react';

import type { ExampleConfig } from '@/components/library/ExampleUtils';
import type { ReactNodeish } from '@/utils/types';

export const SIZE = _SIZE;
export const TYPE = {
  DEFAULT: _TYPE.DEFAULT,
  PRIMARY: _TYPE.PRIMARY,
  SECONDARY: _TYPE.SECONDARY,
} as const;

export type CLDropdownButtonProps = CLButtonBaseProps;

export default function CLDropdownButton({
  className,
  ...props
}: CLDropdownButtonProps): ReactNodeish {
  return (
    <CLButtonBase
      arrow={
        <Icon
          icon="disclosure"
          height="12"
          width="12"
          className="cl-dropdown-button__dropdown-arrow"
        />
      }
      className={classnames('cl-dropdown-button', className)}
      {...props}
    />
  );
}

export const exampleConfig: ExampleConfig = {
  getComponent: () => CLDropdownButton,
  fields: [
    {
      name: 'label',
      desc: 'Text displayed within the button',
      value: {
        type: 'text',
        default: 'Button Text',
      },
    },
    {
      name: 'type',
      desc: 'Importance within the form',
      value: {
        type: 'select',
        default: TYPE.DEFAULT,
        options: [TYPE.DEFAULT, TYPE.PRIMARY, TYPE.SECONDARY],
      },
    },
    {
      name: 'size',
      desc: 'How large the button should be',
      value: {
        type: 'select',
        default: SIZE.DEFAULT,
        options: [SIZE.DEFAULT, SIZE.SMALL, SIZE.LARGE],
      },
    },
    {
      name: 'icon',
      desc: 'Key to an icon in the icon sprite',
      value: {
        type: 'icon',
        default: null,
        transform: iconKey => (iconKey ? <Icon icon={iconKey} /> : null),
      },
    },
    {
      name: 'hasArrowDivider',
      desc: 'Whether to show a line between the label and the dropdown arrow',
      value: {
        type: 'boolean',
        default: false,
      },
    },
    {
      name: 'className',
      desc: 'HTML classes to be added to the button',
      value: {
        type: 'text',
        default: '',
      },
    },
  ],

  examples: [
    {
      title: 'General',
      desc: 'Dropdown with some simple text',
      props: {
        label: 'Dropdown Button',
        type: TYPE.DEFAULT,
        size: SIZE.DEFAULT,
        className: '',
      },
    },
    {
      title: 'Account Dropdown Button',
      desc: 'Dropdown button used for the Account Menu',
      props: {
        label: 'Account',
        type: TYPE.DEFAULT,
        size: SIZE.DEFAULT,
        hasArrowDivider: true,
        icon: 'user',
        className: '',
      },
    },
  ],

  events: {
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => ({
      label: event.currentTarget.innerText,
      class: event.currentTarget.className,
    }),
  },
};
