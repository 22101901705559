import { getString } from '@/content/i18n';
import AuthStore from '@/stores/AuthStore';

import PropTypes from 'prop-types';
import React from 'react';

export default class LoginAsStatus extends React.PureComponent {
  static contextTypes = {
    authStore: PropTypes.instanceOf(AuthStore).isRequired,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      ...this.getStateFromAuthStore(),
    };

    this.context.authStore.registerComponent(this, () => {
      this.setState(this.getStateFromAuthStore());
    });
  }

  getStateFromAuthStore() {
    const { authStore } = this.context;
    return {
      subjectUser: authStore.isImpersonating() ? authStore.getUser() || null : null,
    };
  }

  render() {
    const { subjectUser } = this.state;
    if (!subjectUser) {
      return null;
    }
    return (
      <div className="login-as-status">
        <div className="login-as-status__label">
          {getString(_ => _.appHeader.impersonationLabel, subjectUser.fullName, subjectUser.id)}
        </div>
      </div>
    );
  }
}
