// This should be kept in sync with the corresponding Scala-side FolderRecommendationDay model

import {
  FolderRecommendedPaperFromJS,
  FolderRecommendedPaperRecord,
  getFolderRecommendedPaperFromJS,
} from './FolderRecommendedPaper';

import Immutable from 'immutable';

export type FolderRecommendationDayFromJS = {
  date: string;
  papers: FolderRecommendedPaperFromJS[];
};

export type Props = {
  date: string;
  papers: Immutable.List<FolderRecommendedPaperRecord>;
};

const defaultProps: Props = {
  date: '',
  papers: Immutable.List(),
};

export const RECORD_NAME = 'FolderRecommendationDay';

export const FolderRecommendationDayRecordFactory = Immutable.Record(defaultProps, RECORD_NAME);
export type FolderRecommendationDayRecord = Immutable.RecordOf<Props>;

export function getFolderRecommendationDayFromJS(
  args: FolderRecommendationDayFromJS
): FolderRecommendationDayRecord {
  return FolderRecommendationDayRecordFactory({
    ...args,
    papers: Immutable.List(args.papers).map(raw => getFolderRecommendedPaperFromJS(raw)),
  });
}
