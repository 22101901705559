import { isBrowser } from './utils/env';
import { isString, Nullable } from './utils/types';
import { memoize } from './util';
import { nextTask } from './utils/promise-utils';
import { softInvariant } from './utils/softError';

import invariant from 'invariant';

const VENDOR_PREFIXES = ['moz', 'webkit', 'ms', 'o'] as const;
type Prefix = (typeof VENDOR_PREFIXES)[number] | undefined;
const PATTERN_EVENT_TYPE = /start|end$/;
const MAX_SCROLL_PIXELS_PER_STEP = 45;

let listeningScroll = false;
let scrollListeners: (() => void)[] = [];
const onScroll = () => {
  scrollListeners.forEach(listener => listener());
};

let listeningResize = false;
const resizeListeners: (() => void)[] = [];
const onResize = () => {
  resizeListeners.forEach(listener => listener());
};

// Collection of scripts currently being loaded
const loadingScripts: { [key in string]: Promise<string> } = {};

let isScrolling = false;

/** Utilities intended for use in-browser only.
 */
const BrowserUtil = {
  /** Creates a <script> element for loading the javascript at the provided url.
   * @param {string} url
   * @returns the appropriate <script> element
   */
  createScript(url: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    return script;
  },

  /** Returns or sets scrollTop for the current browser window in a cross browser fashion.
   * @param {number|undefined} the scroll position, if undefined the scroll position remains
   *                           unchanged.
   * @returns the current scroll offset
   */
  scrollTop(pos?: Nullable<number>): number {
    const docEl = getDocumentElement(document);
    const body = getBody(document);
    if (typeof pos === 'number') {
      docEl.scrollTop = body.scrollTop = pos;
    }
    return Math.max(body.scrollTop, docEl.scrollTop);
  },

  /** Loads the javascript at the provided url
   * @param {string} url
   * @returns {Promise} a promise which is resolved once the provided external script is loaded
   */
  loadScript(url: string): Promise<string> {
    if (loadingScripts.hasOwnProperty(url)) {
      return loadingScripts[url];
    } else if (document.querySelectorAll(`script[src="${url}"]`).length !== 0) {
      return Promise.resolve(url);
    } else {
      const promise = new Promise<string>((resolve, reject) => {
        const head = getHead(document);
        const scriptEl = head.appendChild(BrowserUtil.createScript(url));
        const onLoadedOrError = event => {
          delete loadingScripts[url];
          scriptEl.removeEventListener('load', onLoadedOrError);
          scriptEl.removeEventListener('error', onLoadedOrError);
          if (event.type === 'load') {
            resolve(url);
          } else {
            reject(event);
          }
        };
        scriptEl.addEventListener('load', onLoadedOrError);
        scriptEl.addEventListener('error', onLoadedOrError);
      });
      loadingScripts[url] = promise;
      return promise;
    }
  },

  deleteScript(url: string): void {
    const scripts = document.querySelectorAll(`script[src="${url}"]`);
    scripts.forEach(script => {
      if (script && script.parentNode) {
        script.parentNode.removeChild(script);
      }
    });
  },

  listenForScroll(fn: () => void): typeof self {
    if (!listeningScroll && isBrowser()) {
      document.addEventListener('scroll', onScroll);
      listeningScroll = true;
    }
    scrollListeners.push(fn);
    return this;
  },

  stopListeningForScroll(fn: () => void): number {
    const idx = scrollListeners.indexOf(fn);
    if (idx !== -1) {
      scrollListeners.splice(idx, 1);
    }
    const len = scrollListeners.length;
    if (len === 0 && isBrowser()) {
      document.removeEventListener('scroll', onScroll);
      listeningScroll = false;
    }
    return len;
  },

  // Should only be used for tests.
  resetScrollListeners(): void {
    listeningScroll = false;
    scrollListeners = [];
  },

  listenForResize(fn: () => void): typeof self {
    if (!listeningResize && isBrowser()) {
      window.addEventListener('resize', onResize);
      listeningResize = true;
    }
    resizeListeners.push(fn);
    return this;
  },

  stopListeningForResize(fn: () => void): number {
    const idx = resizeListeners.indexOf(fn);
    if (idx !== -1) {
      resizeListeners.splice(idx, 1);
    }
    const len = resizeListeners.length;
    if (len === 0 && isBrowser()) {
      window.removeEventListener('resize', onResize);
      listeningResize = false;
    }
    return len;
  },

  offsetFromBody(element: Nullable<HTMLElement>): number {
    if (!element) {
      return 0;
    } else {
      if (element.offsetParent && element.offsetParent !== document.body) {
        return element.offsetTop + this.offsetFromBody(element.offsetParent);
      } else {
        return element.offsetTop;
      }
    }
  },

  requestAnimationFrame(fn: () => void): Nullable<number> {
    if (isBrowser()) {
      if (window.requestAnimationFrame) {
        return window.requestAnimationFrame(fn);
      } else {
        return window.setTimeout(fn, 0);
      }
    }
    return null;
  },

  once(element: Element, eventName: string, fn: (...args: any[]) => void): void {
    const en = this.vendorAppropriateEventName(eventName);
    const cb = (...args) => {
      element.removeEventListener(en, cb);
      fn(...args);
    };
    return element.addEventListener(en, cb);
  },

  // TODO(codeviking): This really needs testing across more browsers
  vendorAppropriateEventName(eventName: string): string {
    const body = getBody(document);
    // Quick shortcut to detect if the default event name is supported, otherwise we're going
    // to have to check for prefixed versions of the event
    if (`on${eventName.toLowerCase()}` in body) {
      return eventName;
    }

    // remove "start" or "end" from the event name to convert it to it's css property name
    const cssPropertyName = eventName.replace(PATTERN_EVENT_TYPE, '');
    // undefined represents no prefix
    const prefixes: Prefix[] = ([undefined] as Prefix[]).concat(VENDOR_PREFIXES);
    let eventPrefix: string | boolean = false;

    // go through each entry and see if it's a valid style attribute for the current browser, if so
    // stop and record that prefix
    while (eventPrefix === false && prefixes.length > 0) {
      const prefix = prefixes.shift();
      const maybeCssPropName =
        prefix !== undefined
          ? prefix + cssPropertyName.slice(0, 1).toUpperCase() + cssPropertyName.slice(1)
          : cssPropertyName;
      if (!!prefix && maybeCssPropName in body.style) {
        eventPrefix = prefix;
      }
    }

    // if we found a vendor prefix prepare the appropriate event name
    if (eventPrefix) {
      // webkit is weird and requires a camelCased event name, so do something special for it
      if (eventPrefix === 'webkit') {
        const eventType = (PATTERN_EVENT_TYPE.exec(eventName) || []).shift();
        if (eventType) {
          return (
            eventPrefix +
            eventName.slice(0, 1).toUpperCase() +
            eventName.slice(1, eventName.length - eventType.length - 1) +
            eventType.slice(0, 1).toUpperCase() +
            eventType.slice(1)
          );
        } else {
          return eventPrefix + eventName.slice(0, 1).toUpperCase() + eventName.slice(1);
        }
      } else {
        return eventPrefix + eventName;
      }
    } else {
      // no prefix, return the regular event
      return eventName;
    }
  },

  // Feature detect for CSSOM support
  hasNativeSmoothScrollSupport: memoize(
    () => 'scrollBehavior' in (document?.documentElement.style || {})
  ),

  /**
   * Scroll to the provided element.
   *
   * @param {HTMLElement} element the element to scroll to
   * @param {function} callback a callback to execute once scrolled to the provided element
   *
   * @returns {void}
   */
  smoothScrollTo(element: HTMLElement, callback: () => void): void {
    const body = getBody(document);
    // Only one scroll can be active at a time
    if (!isScrolling) {
      isScrolling = true;
      const scroll = () => {
        const top = BrowserUtil.offsetFromBody(element);
        const viewportTop = BrowserUtil.scrollTop();
        const maxScrollPos = body.scrollHeight - window.innerHeight;
        const diff = Math.abs(Math.min(maxScrollPos, top) - viewportTop);

        if (diff > 1) {
          BrowserUtil.requestAnimationFrame(() => {
            window.scrollBy(
              0,
              (top > viewportTop ? 1 : -1) * Math.min(diff, MAX_SCROLL_PIXELS_PER_STEP)
            );
            scroll();
          });
        } else {
          isScrolling = false;
          if (typeof callback === 'function') {
            callback();
          }
        }
      };
      scroll();
    }
  },

  getViewportSize(): Nullable<{ height: number; width: number }> {
    if (typeof document === 'undefined') {
      return null;
    }
    const docEl = getDocumentElement(document);
    return {
      height: +docEl.clientHeight,
      width: +docEl.clientWidth,
    };
  },

  getTitle(): string {
    const title = document && document.title;
    invariant(title, 'title element does not exist');
    return title;
  },

  getPath(): string {
    let path = window && window.location.pathname;
    const query = window && window.location.search;
    invariant(path, 'path element does not exist');
    if (query) {
      path += query;
    }
    return path;
  },

  getGoogleAnalyticsId(): string {
    const id = window && window.googleAnalyticsId;
    invariant(id, 'google analytics id does not exist');
    return id;
  },

  // prevent body scrolling, useful for when modals are open and don't want background to scroll
  disableBodyScroll(): void {
    const body = getBody(document);
    const scrollPosition = BrowserUtil.scrollTop();
    body.style.overflow = 'hidden';
    body.scrollTop = scrollPosition;
  },

  enableBodyScroll(): void {
    const body = getBody(document);
    const docEl = getDocumentElement(document);

    const scrollPosition = BrowserUtil.scrollTop();
    body.style.removeProperty('overflow');
    docEl.scrollTop = scrollPosition;
  },

  copyToClipboard(textToCopy: string): void {
    const textField = document.createElement('textarea');
    textField.innerHTML = textToCopy;
    if (document.body) {
      document.body.appendChild(textField);
    }
    textField.select();
    document.execCommand('copy');
    textField.remove();
  },
};

export default BrowserUtil;

export function hasMultiCanvasSupport(userAgent: string): boolean {
  if (!isString(userAgent)) {
    return false;
  }
  if (userAgent.indexOf('iPhone') !== -1) {
    return false;
  }
  if (userAgent.indexOf('Mobile Safari') !== -1 && userAgent.indexOf('OPR') !== -1) {
    return false;
  }
  const regex = /^((?!chrome|android|crios|fxios).)*safari/i;
  if (regex.test(userAgent)) {
    return false;
  }
  return true;
}

export function hasBroadcastChannelSupport(): boolean {
  return isBrowser() && 'BroadcastChannel' in window;
}

export function getDocumentElement(document: Document): HTMLElement {
  const docEl = document && document.documentElement;
  invariant(docEl, 'documentElement element does not exist');
  return docEl;
}

export function getBody(document: Document): HTMLElement {
  const body = document && document.body;
  invariant(body, 'body element does not exist');
  return body;
}

export function getHead(document: Document): HTMLElement {
  const head = document && document.head;
  invariant(head, 'head element does not exist');
  return head;
}

// Returns a promise which resolves when the load event fires, or immediately if already fired
export function loadEventFired(): Promise<void> {
  return new Promise((resolve, reject) => {
    if (typeof window !== 'object') {
      return reject(new Error('load event cannot fire outside the browser'));
    }
    if (window.document.readyState === 'complete') {
      return resolve();
    }
    window.addEventListener('load', () => resolve());
  });
}

export async function performanceTimingReady(): Promise<void> {
  if (!isBrowser()) {
    throw new Error('performance.timing is not available on the server');
  }

  // Perf metrics aren't ready until the first task after the load event fires
  await loadEventFired();
  await nextTask();

  // Track cases where perf metrics _still_ aren't ready
  softInvariant(
    !!window?.performance.timing.loadEventEnd,
    'browser.performanceTimingReady',
    'performance.timing.loadEventEnd was not ready when performanceTimingReady() completed'
  );
}

export async function nextVisibilityChange(): Promise<DocumentVisibilityState> {
  if (!isBrowser()) {
    throw new Error('cannot listen for browser visibility changes on the server');
  }
  return new Promise(resolve => {
    window.addEventListener('visibilitychange', function callback() {
      resolve(document.visibilityState);
      window.removeEventListener('visibilitychange', callback, true);
    });
  });
}

// Update the CSS Prop --app-height when the browser changes size
export function listenForAppHeightChanges(): void {
  if (!isBrowser()) {
    return;
  }
  const docEl = getDocumentElement(window.document);
  const updateProp = () => {
    const heightPx = docEl.clientHeight || window.innerHeight || 0;
    docEl.style.setProperty('--app-height', `${heightPx}px`);
  };
  window.addEventListener('resize', updateProp);
  window.addEventListener('orientationchange', updateProp);
  updateProp();
}

// Create a selector which can identify an element for debugging
export function getSelectorForLogging(lcpElement?: HTMLElement): string {
  if (!lcpElement) {
    return '';
  }

  const elementSelectors: (string | undefined)[] = [];
  const getElementSelector = (element: Nullable<HTMLElement>): string | undefined => {
    if (!element) {
      return undefined;
    }

    // Prefer IDs
    if (element.id) {
      return '#' + element.id;
    }

    // Heap landmarks are relatively unique
    const heapId = element.getAttribute('data-heap-id');
    if (heapId) {
      return `[data-heap-id="${heapId}"]`;
    }

    // Test IDs are also relatively unique
    const testId = element.getAttribute('data-test-id');
    if (testId) {
      return `[data-test-id="${testId}"]`;
    }

    // Classes can be useful, but not very unique
    const classes = [...element.classList];
    if (classes.length > 0) {
      return classes.map(c => '.' + c).join('');
    }

    // Fallback to tags
    return element.tagName.toLowerCase();
  };

  let element: Nullable<HTMLElement> = lcpElement;
  while (element && element !== document.body) {
    elementSelectors.unshift(getElementSelector(element));
    element = element.parentElement;
  }

  const selector = elementSelectors.join(' ');
  return selector;
}
