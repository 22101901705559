import { getCenterBottomCoordinates } from '@/components/library/dropdown/CLDropdownBase';
import { getString } from '@/content/i18n';
import { INFLUENTIAL_CITATIONS } from '@/components/library/paper/stats/CLPaperStatsBase';
import { makePath } from '@/router/Routes';
import { pluralize } from '@/util';
import { useAppContext } from '@/AppContext';
import CLPaperInfluentialCitationsStat, {
  canRender,
} from '@/components/library/paper/stats/buttons/CLPaperInfluentialCitationsStat';
import CLPopover, { ARROW_POSITION } from '@/components/library/popover/CLPopover';
import CLPortal from '@/components/library/popover/CLPortal';
import CLRule from '@/components/library/layout/CLRule';
import ContentWithHTML from '@/components/util/ContentWithHTML';
import Sort from '@/constants/sort-type';

import classnames from 'classnames';
import React from 'react';

const POPOVER_WIDTH = 350;

export default function CLPaperActionInfluentialCitationsStat({
  paper,
  onHover,
  heapProps,
  className,
  testId,
}) {
  const { citationStats } = paper;
  const [isPopoverVisible, setShowPopover] = React.useState(false);
  const { envInfo } = useAppContext();
  const isMobile = envInfo.isMobile;
  const badgeRef = React.useRef();

  const showHighlyInfluencedPopover = React.useCallback(() => {
    if (onHover) {
      onHover(INFLUENTIAL_CITATIONS);
    }
    setShowPopover(true);
  }, [setShowPopover]);

  const hideHighlyInfluencedPopover = React.useCallback(() => {
    setShowPopover(false);
  }, [setShowPopover]);

  if (!canRender(citationStats)) {
    return null;
  }
  const influentialCitationsPath = makePath({
    routeName: 'PAPER_DETAIL',
    params: { slug: paper.slug, paperId: paper.id },
    query: { sort: Sort.IS_INFLUENTIAL_CITATION.id },
    hash: 'citing-papers',
  });
  const popoverContent = (
    <div className="cl-paper-stats__popover cl-paper-stats__influential-popover-title">
      <h4 className="cl-paper-stats__popover__header cl-paper-stats__influential-popover-title__header">
        {pluralize(
          citationStats.numKeyCitations,
          getString(_ => _.citations.influential.label)
        )}
      </h4>
    </div>
  );

  return (
    <div
      ref={badgeRef}
      className={classnames('cl-paper-stats__action-stat', className)}
      onMouseEnter={showHighlyInfluencedPopover}
      onMouseLeave={hideHighlyInfluencedPopover}
      onFocus={showHighlyInfluencedPopover}
      onBlur={hideHighlyInfluencedPopover}
      data-test-id={testId}>
      {/* TODO  #27899 - Find a better way to link to citation section of the PDP */}
      <a href={influentialCitationsPath} className="cl-paper-stats__citation-pdp-link">
        <CLPaperInfluentialCitationsStat
          citationStats={citationStats}
          heapProps={heapProps}
          className={classnames('cl-paper-stats__badge-hover', {
            'cl-paper-stats__mobile-badge': isMobile,
          })}
        />
      </a>
      {badgeRef.current && !isMobile && !!isPopoverVisible ? (
        <CLPortal coordinates={getCenterBottomCoordinates(badgeRef.current)}>
          <CLPopover arrow={ARROW_POSITION.SIDE_TOP_POS_LEFT} widthPx={POPOVER_WIDTH}>
            {popoverContent}
            <CLRule />
            <ContentWithHTML content={_ => _.citations.influential.text} />
          </CLPopover>
        </CLPortal>
      ) : null}
    </div>
  );
}

CLPaperActionInfluentialCitationsStat.defaultProps = {
  testId: 'influential-citations-stat',
};
