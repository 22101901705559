import AnalyticsEvent from '@/analytics/models/AnalyticsEvent';
import EventType from '@/analytics/constants/EventType';

import type { DEPRECATED__FlowOptional } from '@/utils/types';

export type RouteLoadTimes = {
  requestTimeMs: RouteLoadMetric;
  requestTimeWithoutRedirectMs: RouteLoadMetric;
  authTimeMs: RouteLoadMetric;
  willRouteToTimeMs: RouteLoadMetric;
  initiatesRedirect: RouteLoadMetric;
  hasError: RouteLoadMetric;
};

export type RouteLoadMetric = {
  startTimestamp?: DEPRECATED__FlowOptional<number | boolean>;
  endTimestamp?: DEPRECATED__FlowOptional<number | boolean>;
};

export type RouteLoadTimesKey = keyof RouteLoadTimes;

/**
 * Event for tracking second page load time metrics
 *
 * @prop requestTimeMs {number} the total time in milliseconds from the start of a route request to the end time when the page is ready.
 *        If there was a redirect prior to the current route request, it includes the time from the start of that redirect
 * @prop requestTimeWithoutRedirectMs {number} the total time in milliseconds from the start of the a route request
 *       to the end time when the page is ready, excluding any preceding redirects
 * @prop authTimeMs {number} the total time in milliseconds to check for authentication
 * @prop willRouteToTimeMs {number} the total time in milliseconds to run the willRouteTo function of the route requested component
 * @prop initiatesRedirect {boolean} indicates if the user navigated away while we were loading data mid route request,
 *       if initiatesRedirect = true, the redirectStartTimestamp will be initiated and the following route request's requestTimeMs
 *       will include the full time box from when the redirect started until when the route request is complete
 * @prop hasError {boolean} indicates whether an error occurred during the route request
 */
export default class RouteLoadTimingEvent extends AnalyticsEvent {
  requestTimeMs: number;
  requestTimeWithoutRedirectMs: number;
  authTimeMs: number;
  willRouteToTimeMs: number;
  initiatesRedirect: boolean;
  hasError: boolean;

  static create(routeLoadObj: RouteLoadTimes): RouteLoadTimingEvent {
    const event = new this(routeLoadObj);
    return event;
  }

  constructor(routeLoadObj: RouteLoadTimes) {
    super(EventType.ROUTELOADTIMING);

    for (const metric of Object.keys(routeLoadObj)) {
      if (routeLoadObj[metric].endTimestamp) {
        this[metric] = routeLoadObj[metric].endTimestamp - routeLoadObj[metric].startTimestamp;
      } else {
        this[metric] = routeLoadObj[metric].startTimestamp;
      }
    }
  }
}
