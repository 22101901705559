import { CitationFromJS, CitationRecord, getCitationFromJS } from './Citation';
import {
  getReferenceQueryRecordFromJS,
  ReferenceQueryFromJS,
  ReferenceQueryRecord,
  ReferenceQueryRecordFactory,
} from './ReferenceQuery';

import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

type ReferenceQueryResponseFromJS = {
  query: ReferenceQueryFromJS;
  results: CitationFromJS[];
  totalPages: number;
  totalResults: number;
  aggTotalResults?: Nullable<number>;
  modifiedQueryString: string;
};

type Props = {
  query: ReferenceQueryRecord;
  results: Immutable.List<CitationRecord>;
  totalPages: number;
  totalResults: number;
  aggTotalResults: Nullable<Nullable<number>>;
  modifiedQueryString: string;
};

const defaultProps: Props = {
  query: ReferenceQueryRecordFactory(),
  results: Immutable.List(),
  totalPages: 0,
  totalResults: 0,
  aggTotalResults: null,
  modifiedQueryString: '',
};

export const ReferenceQueryResponseRecordFactory = Immutable.Record<Props>(defaultProps);
export type ReferenceQueryResponseRecord = Immutable.RecordOf<Props>;

export function getReferenceQueryResponseFromJS(
  args: ReferenceQueryResponseFromJS
): ReferenceQueryResponseRecord {
  const response = { ...args };
  const results = Immutable.List(response.results.map(citation => getCitationFromJS(citation)));
  const query = getReferenceQueryRecordFromJS(response.query);

  return ReferenceQueryResponseRecordFactory({
    query,
    results,
    totalPages: response.totalPages,
    totalResults: response.totalResults,
    aggTotalResults: response.aggTotalResults ? response.aggTotalResults : null,
    modifiedQueryString: response.modifiedQueryString,
  });
}
