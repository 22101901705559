import { getString } from '@/content/i18n';
import { NotRelevant } from '@/models/library/LibraryEntryAnnotationState';
import { On } from '@/models/library/RecommendationStatus';
import AccountNavStore from '@/stores/AccountNavStore';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import ResearchHomepageStore from '@/stores/ResearchHomepageStore';

import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

const NUM_RECOMMENDATIONS_TO_FETCH = 5;
const MAXIMUM_CITING_PAPERS = 3;
const MAX_VISIBLE_FOLDERS = 10;

export default class ResearchHomepageRoute extends React.Component {
  static requiresAuthentication = () => true;

  static getPageTitle = () => getString(_ => _.research.pageTitle);

  static contextTypes = {
    accountNavStore: PropTypes.instanceOf(AccountNavStore).isRequired,
    researchHomepageStore: PropTypes.instanceOf(ResearchHomepageStore).isRequired,
  };

  static async willRouteTo({
    api,
    authStore,
    authorStore,
    libraryRecommendationsStore,
    libraryFolderStore,
    researchHomepageStore,
  }) {
    const proms = [];
    if (researchHomepageStore.isAlertsHistoryUninitialized()) {
      // Fetch latest alerts
      proms.push(api.fetchAlertsHistory());
    }

    const claimedAuthorId = authStore.getClaimedAuthorId();
    if (claimedAuthorId) {
      // Fetch author details for card
      const shouldFetchAuthor =
        authorStore.isUninitialized() || authorStore.isNewOrCanonicalAuthor(claimedAuthorId);
      if (shouldFetchAuthor) {
        proms.push(api.searchAuthorPapers(claimedAuthorId));
      }

      // Fetch author impact
      if (researchHomepageStore.isAuthorImpactUninitialized()) {
        proms.push(
          api.fetchAuthorImpact({
            authorId: claimedAuthorId,
            limit: MAXIMUM_CITING_PAPERS,
          })
        );
      }
    }

    if (libraryRecommendationsStore.isRecommendationsAnnotationsUninitialized()) {
      proms.push(api.getLibraryAnnotationEntries(NotRelevant));
    }

    // Handle case where there's no folderIds attribute and we may need to build
    // a default set of visible folders and redirect
    if (libraryFolderStore.isUninitialized()) {
      const prom = api.getLibraryFolders();
      proms.push(prom);
      await prom;
    }

    await libraryFolderStore.ready();
    const recsFolders = libraryFolderStore.getFoldersWithRecommendationStatus(On);
    const visibleFolders = recsFolders.slice(0, MAX_VISIBLE_FOLDERS).map(_ => _.id);
    if (visibleFolders.isEmpty()) {
      // Force the ResearchHomepageStore to initialize empty recommendations
      researchHomepageStore.initializeEmptyRecommendationsHistory();
    } else if (researchHomepageStore.isRecommendationsHistoryUninitialized()) {
      proms.push(
        api.getRecommendationsForResearchHome({
          folderIds: Immutable.Set(visibleFolders),
          pageSize: NUM_RECOMMENDATIONS_TO_FETCH,
        })
      );
    }

    return Promise.all(proms);
  }

  constructor(...args) {
    super(...args);

    this.state = {
      ...this.getStateFromAccountNavStore(),
    };

    this.context.accountNavStore.registerComponent(this, () => {
      this.setState(this.getStateFromAccountNavStore());
    });
  }

  getStateFromAccountNavStore() {
    const { accountNavStore } = this.context;
    const isFTUE = !accountNavStore.hasResearchDashboardContent();
    return {
      isFTUE,
    };
  }

  render() {
    const { isFTUE } = this.state;
    if (isFTUE) {
      return (
        <AsyncLoadedPage
          footer={false}
          load={{
            importer: () =>
              import(/* webpackChunkName: "shared-ResearchFTUEPage" */ './ResearchFTUEPage'),
            chunkName: 'shared-ResearchFTUEPage',
            moduleId: require.resolveWeak('./ResearchFTUEPage'),
          }}
        />
      );
    }
    return (
      <AsyncLoadedPage
        footer={false}
        load={{
          importer: () =>
            import(/* webpackChunkName: "shared-ResearchHomepage" */ './ResearchHomepage'),
          chunkName: 'shared-ResearchHomepage',
          moduleId: require.resolveWeak('./ResearchHomepage'),
        }}
      />
    );
  }
}
