import {
  getPaperPdfBoundingBoxFromJS,
  PaperPdfBoundingBoxPropsFromJS,
  PaperPdfBoundingBoxRecord,
} from './PaperPdfBoundingBox';

import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

export type PaperPdfCitationMentionsFromJS = {
  boundingBoxes: Nullable<PaperPdfBoundingBoxPropsFromJS[]>;
  s2airsId: Nullable<string>;
  text: Nullable<string>;
};

type Props = {
  boundingBoxes: Immutable.List<PaperPdfBoundingBoxRecord>;
  s2airsId: Nullable<string>;
  text: Nullable<string>;
};

const defaultProps: Props = {
  boundingBoxes: Immutable.List(),
  s2airsId: null,
  text: null,
};

export const PaperPdfCitationMentionsFactory = Immutable.Record<Props>(defaultProps);

export type PaperPdfCitationMentionsRecord = Immutable.RecordOf<Props>;

export function getPaperPdfCitationMentionsFromJS(
  args: PaperPdfCitationMentionsFromJS
): PaperPdfCitationMentionsRecord {
  const { boundingBoxes } = args;
  const newBoundingBoxesList = Immutable.List(boundingBoxes || []).map(boundingBox =>
    getPaperPdfBoundingBoxFromJS(boundingBox)
  );
  return PaperPdfCitationMentionsFactory({
    boundingBoxes: newBoundingBoxesList,
  });
}
