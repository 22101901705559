import { AllocationConfig, shouldAllocate } from './decisions';
import {
  buildFeatureConfig,
  DynamicFeatureConfig,
  DynamicFeatureConfigMap,
} from './featureConfigUtils';

import { HeapPropObject, runWithHeap } from '@/analytics/Heap';

import Immutable from 'immutable';
import invariant from 'invariant';

import type { FeatureKey } from './Feature';
import type { FeatureRecord } from './FeatureRecord';

export default class FeatureManager {
  _config: DynamicFeatureConfigMap;
  _featureMap: Immutable.Map<FeatureKey, FeatureRecord>;
  _tid: string;

  constructor({
    configStr,
    featureList,
    tid,
  }: {
    configStr: string;
    featureList: Immutable.List<FeatureRecord>;
    tid: string;
  }) {
    this._config = this._buildConfig(configStr, featureList);
    this._featureMap = featureList.reduce(
      (map, feature) => map.set(feature.KEY, feature),
      Immutable.Map()
    );
    this._tid = tid;
  }

  isFeatureEnabled(key: FeatureKey): boolean {
    const featureConfig = this._config.get(key);
    invariant(featureConfig, `feature "${key}" not found in Feature.js`);
    const allocationConfig: AllocationConfig = {
      tid: this._tid,
      allocationSalt: featureConfig.salt,
      trafficRatio: featureConfig.trafficRatio,
    };
    const isEnabled = shouldAllocate(allocationConfig);
    return isEnabled;
  }

  buildTrackFeaturesObject(): Immutable.Map<string, boolean> {
    return this._featureMap
      .valueSeq()
      .toList()
      .filter(feature => feature.includeAsEventProperty)
      .reduce((result, feature) => {
        return result.set(mkHeapPropFromModel(feature), this.isFeatureEnabled(feature.KEY));
      }, Immutable.Map());
  }

  syncEventProperties(): void {
    runWithHeap(heap => {
      // TODO: Remove props from heap that are no longer needed
      const heapProps = this.buildTrackFeaturesObject().toJS() as HeapPropObject;
      heap.addEventProperties(heapProps);
    });
  }

  _buildConfig(
    configStr: string,
    featureList: Immutable.List<FeatureRecord>
  ): DynamicFeatureConfigMap {
    const config = buildFeatureConfig(configStr, featureList);
    return config;
  }
}

export function mkHeapPropFromKey(key: FeatureKey): string {
  return `feature:${key}`;
}

export function mkHeapPropFromModel(model: FeatureRecord): string {
  return mkHeapPropFromKey(model.KEY);
}

export function mkHeapPropFromConfig(config: DynamicFeatureConfig): string {
  return mkHeapPropFromKey(config.key);
}
