/* Provides an access layer over a set of SkipperExperiment records.
 * Used to determine membership of a given object in an S2 Research
 * experiment, and to retrieve any associated precomputed data.
 */

import {
  getSkipperExperimentFromJS,
  SkipperExperimentFromJS,
  SkipperExperimentRecord,
} from './SkipperExperiment';

import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

export default class SkipperExperiments {
  static get EMPTY(): SkipperExperiments {
    return SkipperExperiments.fromJS([]);
  }

  static fromJS(raws: SkipperExperimentFromJS[]): SkipperExperiments {
    const asRecords = Immutable.Seq(raws.map(getSkipperExperimentFromJS));

    return new SkipperExperiments(asRecords);
  }

  #experimentsMap: Immutable.Map<string, SkipperExperimentRecord>;

  constructor(experiments: Immutable.Seq.Indexed<SkipperExperimentRecord>) {
    this.#experimentsMap = experiments.reduce((acc, exp) => {
      return acc.set(exp.experimentName, exp);
    }, Immutable.Map());
  }

  /**
   * Determine membership of the current object in a given Skipper
   * experiment.
   *
   * @param   {string}  experimentName
   * @returns {boolean}
   */
  memberOf(experimentName: string): boolean {
    return this.#experimentsMap.has(experimentName);
  }

  /**
   * Provides precomputed experimental data for the associated object
   * for a given experiment.
   *
   * @param    {string}  experimentName
   * @returns  {*}
   */
  dataFor<TExperimentData = any>(experimentName: string): Nullable<TExperimentData> {
    if (this.memberOf(experimentName)) {
      return this.#experimentsMap.get(experimentName)?.objectData || null;
    }

    return null;
  }

  /**
   * Returns a list of all experiments for this object.
   *
   * @returns Immutable.List<SkipperExperimentRecord> the experiments
   */
  all(): Immutable.List<SkipperExperimentRecord> {
    return this.#experimentsMap.toList().sortBy(item => item.experimentName);
  }
}
