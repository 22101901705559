import * as format from '@/format';
import { addHeapProps } from '@/components/util/HeapTracking';
import { citationStatsHasKeyCitations, CitationStatsRecord } from '@/models/CitationStats';
import { getString } from '@/content/i18n';
import { HeapProps } from '@/analytics/heapUtils';
import { Nullable, ReactNodeish } from '@/utils/types';
import { pluralize } from '@/util';
import Icon from '@/components/shared/icon/Icon';

import classnames from 'classnames';
import React, { ComponentProps } from 'react';

type CLPaperInfluentialCitationsStatProps = {
  citationStats: CitationStatsRecord;
  heapProps?: Nullable<HeapProps>;
  testId?: Nullable<string>;
} & ComponentProps<'div'>;

export default function CLPaperInfluentialCitationsStat({
  citationStats,
  className,
  heapProps,
  testId,
  ...props
}: CLPaperInfluentialCitationsStatProps): ReactNodeish {
  if (!canRender(citationStats)) {
    return null;
  }

  const ariaLabel = pluralize(
    citationStats.numKeyCitations,
    getString(_ => _.citations.influential.label),
    getString(_ => _.citations.influential.labelPlural),
    false
  );

  return (
    <div
      className={classnames('cl-paper-stat', 'cl-paper-stats__influential-citation', className)}
      data-test-id={testId}
      {...addHeapProps(heapProps)}
      aria-label={ariaLabel}
      {...props}>
      <Icon
        icon="scorecard-highly-influential"
        width="12"
        height="12"
        className="cl-paper-stats__icon"
      />
      {format.citationsValue(citationStats.numKeyCitations)}
    </div>
  );
}

CLPaperInfluentialCitationsStat.defaultProps = {
  testId: 'influential-citations-stat',
};

export function canRender(citationStats) {
  return citationStatsHasKeyCitations(citationStats);
}
