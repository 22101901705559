import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

export type TldrFromJS = {
  text: string;
  abstractSimilarityScore: Nullable<number>;
};

type Props = {
  text: string;
  abstractSimilarityScore: Nullable<number>;
};

export const defaultProps: Props = {
  text: '',
  abstractSimilarityScore: null,
};

export const TldrRecordFactory = Immutable.Record<Props>(defaultProps);

export type TldrRecord = Immutable.RecordOf<Props>;

export function getTldrFromJS(args: TldrFromJS): TldrRecord {
  return TldrRecordFactory({
    ...args,
  });
}
