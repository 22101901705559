import { addHeapProps } from '@/components/util/HeapTracking';
import { COOKIE_BANNER_DISMISSED, WEBLAB_OPT_OUT } from '@/constants/UserCookie';
import { getString } from '@/content/i18n';
import { HEAP_COOKIE_BANNER_ACCEPT, HEAP_COOKIE_BANNER_OPT_OUT } from '@/constants/HeapAttribute';
import { ReactNodeish } from '@/utils/types';
import AuthStore from '@/stores/AuthStore';
import CookieJar from '@/utils/cookies/CookieJar';
import ExternalLink from '@/components/shared/ExternalLink';
import Feature from '@/weblab/Feature';
import FeatureEnabled from '@/components/util/features/FeatureEnabled';
import FeatureGate from '@/components/util/features/FeatureGate';

import PropTypes from 'prop-types';
import React from 'react';

type Props = {};

type StateFromAuthStore = {
  isUserAuthenticated: boolean;
};

type State = {
  isBannerDismissed: boolean;
} & StateFromAuthStore;

export default class CookieBanner extends React.PureComponent<Props, State> {
  static contextTypes = {
    authStore: PropTypes.instanceOf(AuthStore).isRequired,
    cookieJar: PropTypes.instanceOf(CookieJar).isRequired,
  };

  constructor(...args: [any]) {
    super(...args);
    this.state = {
      isBannerDismissed: isCookieBannerDismissed(this.context.cookieJar),
      ...this.getStateFromAuthStore(),
    };

    this.context.authStore.registerComponent(this, () => {
      this.setState(this.getStateFromAuthStore());
    });
  }

  getStateFromAuthStore(): StateFromAuthStore {
    const { authStore } = this.context;

    // Don't show the banner if either the user has explicitly dismissed it, or
    // if they are signed in.
    return {
      isUserAuthenticated: authStore.hasAuthenticatedUser(),
    };
  }

  dismissBanner(): void {
    this.setState({ isBannerDismissed: true });
    this.context.cookieJar.saveCookie(COOKIE_BANNER_DISMISSED, '1', {
      maxAge: 9e9,
      path: '/',
    });
  }

  optOutOfWeblabs(): void {
    this.context.cookieJar.saveCookie(WEBLAB_OPT_OUT, '1', {
      maxAge: 9e9,
      path: '/',
    });
  }

  onClickDismiss: React.MouseEventHandler = event => {
    event.preventDefault();
    this.dismissBanner();
  };

  onClickOptOut: React.MouseEventHandler = event => {
    event.preventDefault();
    this.dismissBanner();
    this.optOutOfWeblabs();

    // Wait for analytics to send before reloading the page, so that the
    // cookie can take effect
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  render(): ReactNodeish {
    const { isBannerDismissed, isUserAuthenticated } = this.state;
    if (isBannerDismissed || isUserAuthenticated) {
      return null;
    }
    return (
      <div className="cookie-banner" data-test-id="cookie-banner">
        <div className="cookie-banner__content">
          <div className="cookie-banner__message">
            <span className="cookie-banner__message-body">
              {getString(_ => _.cookieBanner.bodyText)}{' '}
              <ExternalLink
                className="cookie-banner__copyright-link"
                allowReferrer
                href="https://allenai.org/privacy-policy.html">
                {getString(_ => _.cookieBanner.privacyPolicyLabel)}
              </ExternalLink>
              {', '}
              <ExternalLink
                className="cookie-banner__copyright-link"
                allowReferrer
                href="https://allenai.org/terms">
                {getString(_ => _.cookieBanner.termsOfServiceLabel)}
              </ExternalLink>
              {', and '}
              <ExternalLink
                className="cookie-banner__copyright-link"
                allowReferrer
                href="http://api.semanticscholar.org/corpus/legal">
                {getString(_ => _.cookieBanner.datasetLicenseLabel)}
              </ExternalLink>
            </span>
          </div>
          <div className="cookie-banner__actions">
            <button
              className="cookie-banner__action-btn button button--secondary"
              onClick={this.onClickDismiss}
              data-test-id="cookie-banner__dismiss-btn"
              {...addHeapProps({ id: HEAP_COOKIE_BANNER_ACCEPT })}>
              {getString(_ => _.cookieBanner.dismissButtonLabel)}
            </button>
            <FeatureGate feature={Feature.WeblabOptOut}>
              <FeatureEnabled>
                {() => (
                  <React.Fragment>
                    <button
                      className="cookie-banner__action-btn button button--tertiary"
                      data-test-id="cookie-banner__opt-out-btn"
                      onClick={this.onClickOptOut}
                      {...addHeapProps({ id: HEAP_COOKIE_BANNER_OPT_OUT })}>
                      {getString(_ => _.cookieBanner.optOutButtonLabel)}
                    </button>
                  </React.Fragment>
                )}
              </FeatureEnabled>
            </FeatureGate>
          </div>
        </div>
      </div>
    );
  }
}

export function isCookieBannerDismissed(cookieJar: CookieJar): boolean {
  const jar = cookieJar || new CookieJar();
  return !!jar.getCookie(COOKIE_BANNER_DISMISSED);
}
