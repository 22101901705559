import React from 'react';

export default function IconFaUnlock(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 36 36">
      <path
        d="M24.91,5c1.39,0,2.67,0.35,3.86,1.04s2.12,1.63,2.82,2.82s1.04,2.47,1.04,3.86v4.06c0,0.34-0.12,0.63-0.36,0.86
        S31.75,18,31.41,18h-1.62c-0.34,0-0.63-0.12-0.86-0.36s-0.36-0.52-0.36-0.86v-4.06c0-1.02-0.36-1.88-1.09-2.59s-1.59-1.07-2.59-1.07
        s-1.85,0.36-2.56,1.09s-1.07,1.6-1.07,2.62v3.61h2.44c0.68,0,1.25,0.24,1.73,0.71s0.71,1.05,0.71,1.73v9.75
        c0,0.68-0.24,1.25-0.71,1.73S24.36,31,23.69,31H5.81c-0.68,0-1.25-0.24-1.73-0.71s-0.71-1.05-0.71-1.73v-9.75
        c0-0.68,0.24-1.25,0.71-1.73s1.05-0.71,1.73-0.71h11.38v-3.61c0-1.39,0.35-2.67,1.04-3.86s1.62-2.13,2.79-2.84S23.48,5,24.91,5z"
      />
    </symbol>
  );
}
