import { getString } from '@/content/i18n';
import CLFieldError, { ARROW_SIDE } from '@/components/library/form/error/CLFieldError';
import FolderRecommendationsToggle from '@/components/shared/library/FolderRecommendationsToggle';
import LibraryFolderName from '@/components/shared/library/LibraryFolderName';

import React from 'react';

export default class RecommendationsSettingFolderRow extends React.PureComponent {
  constructor(...args) {
    super(...args);

    this.state = {
      errorMessage: null,
    };
  }

  onUpdateRecommendationError = () => {
    this.setState({
      errorMessage: getString(_ => _.recommendations.settingsShelf.errorMessage),
    });
  };

  render() {
    const { folder } = this.props;
    const { errorMessage } = this.state;
    return (
      <div key={folder.id} className="recommendations-settings__folder-row">
        <div className="recommendations-setting_folder-wrapper">
          {!!errorMessage && (
            <CLFieldError
              key="error"
              className="recommendations-settings__error-msg"
              data-test-id="recommendations-settings-save-error"
              arrow={ARROW_SIDE.BOTTOM}
              widthPx={200}>
              {errorMessage}
            </CLFieldError>
          )}
          <FolderRecommendationsToggle
            key={folder.id}
            libraryFolderId={folder.id}
            onFailure={this.onUpdateRecommendationError}
            testId="recommendations-settings-folder-toggle"
          />
        </div>

        <LibraryFolderName folder={folder} folderIcon={false} recsIcon={false} />
      </div>
    );
  }
}
