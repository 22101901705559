import { isString, Nullable } from './types';

import { getHighlightedFieldFromJS, HighlightedFieldRecord } from '@/models/HighlightedField';
import { isPaperRecord, PaperishRecord, PaperRecord } from '@/models/Paper';

import moment from 'moment';

// Converts a Paper's pubDate to a highlighted field from a LocalDate
// since the pubdate is a LocalDate in ES and ES only supports string highlighting (https://github.com/elastic/elasticsearch/issues/9881)
// this is a hacky workaround to get query highlighting of pubdate year on the SERP
export function createPubDateHighlightedField(
  year: Nullable<HighlightedFieldRecord>,
  pubDate: string
): HighlightedFieldRecord {
  const momentPubdate = moment(pubDate);
  const pubDateString = momentPubdate.format('D MMMM YYYY');
  const yearString = momentPubdate.year().toString();

  // check whether the year is highlighted and if that year matches the year of the pubdate then highlight it
  if (year && year.fragments.size > 0 && year.text === yearString) {
    const yearStartIndex = pubDate.indexOf(yearString);

    return getHighlightedFieldFromJS({
      text: pubDateString,
      fragments: [{ start: yearStartIndex, end: yearStartIndex + 4 }],
    });
  }

  return getHighlightedFieldFromJS({
    text: pubDateString,
    fragments: [],
  });
}

// helper function to differentiate between a Paper and a PaperLite when they are typed as PaperishRecord
export function checkForPaperRecord(paperish: PaperishRecord): Nullable<PaperRecord> {
  if (isPaperRecord(paperish)) {
    return paperish;
  }
  return null;
}

// helper function to grab title text from a Paper and PaperLite
export function getPaperTitleStr(paperish: PaperishRecord): string {
  return isString(paperish.title) ? paperish.title : paperish.title.text;
}
