import React from 'react';

export default function IconDataset(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 32 32">
      <path
        d="M15,1.6C7.616,1.6,1.629,3.467,1.629,5.771v4.092
        c0,2.303,5.987,4.171,13.371,4.171c7.385,0,13.371-1.868,13.371-4.171V5.771C28.371,3.467,22.385,1.6,15,1.6z M15,17.102
        c-6.828,0-12.462-1.597-13.271-3.658c-0.066,0.167-0.101,0.337-0.101,0.509v4.094c0,2.305,5.987,4.17,13.371,4.17
        c7.385,0,13.371-1.865,13.371-4.17v-4.094c0-0.172-0.034-0.343-0.101-0.509C27.465,15.504,21.83,17.102,15,17.102z M15,25.287
        c-6.828,0-12.462-1.598-13.271-3.662c-0.066,0.168-0.101,0.34-0.101,0.512v4.094c0,2.303,5.987,4.17,13.371,4.17
        c7.385,0,13.371-1.867,13.371-4.17v-4.094c0-0.172-0.034-0.344-0.101-0.512C27.465,23.689,21.83,25.287,15,25.287z"
      />
    </symbol>
  );
}
