import AnalyticsEvent from './AnalyticsEvent';

import EventType from '@/analytics/constants/EventType';

/** Model for an event which indicates that the user exited the site. */
export default class UnloadEvent extends AnalyticsEvent {
  static create(): UnloadEvent {
    return new UnloadEvent();
  }

  constructor() {
    super(EventType.UNLOAD);
  }
}
