import { AnalyticsContext } from './CLPaperAnalytics';
import MockPaper, { MockPaperLiteFromJs } from '../../../../../test/utils/MockPaper';

import { addHeapProps } from '@/components/util/HeapTracking';
import { ExampleConfig } from '@/components/library/ExampleUtils';
import { getHighlightedFieldFromJS } from '@/models/HighlightedField';
import { getString } from '@/content/i18n';
import { heapPaperTitle } from '@/analytics/attributes/paperObject';
import { HeapProps } from '@/analytics/heapUtils';
import { Nullable, ReactNodeish, TODO } from '@/utils/types';
import { PaperishRecord } from '@/models/Paper';
import CLTooltip, { PLACEMENT } from '@/components/library/tooltip/CLTooltip';
import constants from '@/constants';
import HighlightedField from '@/components/shared/common/HighlightedField';
import Link from '@/router/Link';

import React from 'react';

type TODO__PaperAnalyticsContext = TODO<'CLPaperAnalytics needs types'>;

type Props = {
  paper: PaperishRecord;
  heapProps?: Nullable<HeapProps>;
  testId?: Nullable<string>;
  onClick?: Nullable<(paper: PaperishRecord) => void>;
  shouldOpenInNewTab?: Nullable<boolean>;
  titleTagName?: string;
  isInteractive?: Nullable<boolean>;
};

export default function CLPaperTitle({
  paper,
  heapProps,
  testId,
  onClick,
  shouldOpenInNewTab,
  titleTagName = 'h3',
  isInteractive,
}: Props): ReactNodeish {
  const { id, slug, title } = paper;

  const { onClickTitle: onClickTitleAnalytics } =
    React.useContext<TODO__PaperAnalyticsContext>(AnalyticsContext);
  const onClickWrap = React.useCallback(() => {
    onClickTitleAnalytics();
    if (onClick) {
      onClick(paper);
    }
  }, [onClickTitleAnalytics, onClick]);

  const paperTitle = React.createElement(
    titleTagName,
    {
      id: getString(_ => _.ariaLabelledByIds.paperTitleId, id),
      className: 'cl-paper-title',
      tabIndex: !id ? '0' : '',
      'aria-describedby': !id
        ? 'described-by-' + getString(_ => _.ariaLabelledByIds.paperTitleId, id)
        : '',
    },
    typeof title === 'string' ? (
      title
    ) : (
      <HighlightedField
        field={title}
        limit={constants.text.MAX_PAPER_TITLE_CHARS}
        expandable={false}
      />
    )
  );

  if (!id) {
    return (
      <CLTooltip
        placement={PLACEMENT.BOTTOM_LEFT}
        id={'described-by-' + getString(_ => _.ariaLabelledByIds.paperTitleId, id)}
        tooltipContent={getString(_ => _.paper.title.missingInCorpus)}>
        {paperTitle}
      </CLTooltip>
    );
  }

  return isInteractive ? (
    <Link
      {...(heapProps ? addHeapProps(heapProps) : heapPaperTitle())}
      data-test-id={testId}
      onClick={onClickWrap}
      to="PAPER_DETAIL"
      params={{ paperId: id, slug }}
      shouldOpenInNewTab={shouldOpenInNewTab}
      enableVisitedStyle={true}>
      {paperTitle}
    </Link>
  ) : (
    paperTitle
  );
}

CLPaperTitle.defaultProps = {
  testId: 'title-link',
  shouldOpenInNewTab: false,
  isInteractive: true,
};

// TODO(#21359): Split this into a separate file
const PAPER_NO_HIGHLIGHT = MockPaper({
  id: '1234',
  title: getHighlightedFieldFromJS({ text: 'A Paper with No Highlight' }),
});
const PAPER_WITH_HIGHLIGHT = MockPaper({
  id: '1234',
  title: getHighlightedFieldFromJS({
    text: 'A Paper with Highlight',
    fragments: [{ start: 2, end: 7 }],
  }),
});

const PAPER_LITE = MockPaperLiteFromJs({
  slug: 'super-important-paper',
});

export const exampleConfig: ExampleConfig = {
  getComponent: () => CLPaperTitle,
  fields: [
    {
      name: 'isInteractive',
      desc: 'Adds links and click handlers when enabled',
      value: {
        type: 'boolean',
        default: true,
      },
    },
  ],

  examples: [
    {
      title: 'Title with no highlights',
      desc: '',
      props: {
        paper: PAPER_NO_HIGHLIGHT,
        isInteractive: true,
      },
      render: comp => <div style={{ width: '300px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'Title with highlights',
      desc: '',
      props: {
        paper: PAPER_WITH_HIGHLIGHT,
        isInteractive: true,
      },
      render: comp => <div style={{ width: '300px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'PaperLite Title',
      desc: '',
      props: {
        paper: PAPER_LITE,
        isInteractive: true,
      },
      render: comp => <div style={{ width: '300px', padding: '16px' }}>{comp}</div>,
    },
  ],

  events: {
    onClick: (event: React.SyntheticEvent<HTMLInputElement>) => ({
      title: event.currentTarget.innerText,
    }),
  },
};
