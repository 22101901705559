import BaseStore from './BaseStore';

import { ApiResponse } from '@/api/ApiResponse';
import { CiteSeeStateRecordFactory } from '@/models/reader-widgets/ReaderCiteSee';
import {
  isCiteSeeStateRecord,
  isNoteTakingStateRecord,
  isSkimmingInteractionStateRecord,
  isSkimmingStateRecord,
  isTermsStateRecord,
  ReaderWidget,
  ReaderWidgetState,
} from '@/models/reader-widgets/ReaderWidgets';
import { Nullable } from '@/utils/types';
import {
  ReaderWidgetAction,
  SetActiveReaderWidgetAction,
  UpdateReaderWidgetStateAction,
} from '@/actions/ReaderWidgetActionCreators';
import { SkimmingStateRecordFactory } from '@/models/reader-widgets/ReaderSkimming';
import { TermsStateRecordFactory } from '@/models/reader-widgets/ReaderTerms';
import constants from '@/constants';

import Immutable from 'immutable';
import invariant from 'invariant';

import type S2Dispatcher from '@/utils/S2Dispatcher';

export default class ReaderWidgetStore extends BaseStore {
  #widgetStates: Immutable.Map<ReaderWidget, ReaderWidgetState>;
  #activeWidget: Nullable<ReaderWidget>;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#activeWidget = null;
    this.#widgetStates = Immutable.Map<ReaderWidget, ReaderWidgetState>()
      .set(ReaderWidget.CITE_SEE, CiteSeeStateRecordFactory())
      .set(ReaderWidget.SKIMMING, SkimmingStateRecordFactory())
      .set(ReaderWidget.TERM_UNDERSTANDING, TermsStateRecordFactory());

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case ReaderWidgetAction.UPDATE_STATE: {
          const typedPayload = payload as UpdateReaderWidgetStateAction;
          const { widgetType, widgetState } = typedPayload;
          this.#handleUpdateStateForWidget(widgetType, widgetState);
          break;
        }
        case ReaderWidgetAction.SET_ACTIVE_WIDGET: {
          const typedPayload = payload as SetActiveReaderWidgetAction;
          this.#handleSetActiveWidget(typedPayload.widgetType);
          break;
        }
        case constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case constants.requestTypes.LOGOUT: {
              // citesee is only available for logged in users so close the panel if it was open upon logging out
              if (this.#activeWidget === ReaderWidget.CITE_SEE) {
                this.#activeWidget = null;
                this.emitChange();
              }
              break;
            }
          }
        }
      }
    });
  }

  getWidgetStates(): Immutable.Map<string, ReaderWidgetState> {
    return this.#widgetStates;
  }

  getStateForWidget(widget: ReaderWidget): Nullable<ReaderWidgetState> {
    return this.#widgetStates.get(widget) || null;
  }

  getActiveWidget(): Nullable<ReaderWidget> {
    return this.#activeWidget;
  }

  #handleUpdateStateForWidget(widget: ReaderWidget, state: ReaderWidgetState): void {
    this.#validateWidgetState(widget, state);
    this.#widgetStates = this.#widgetStates.set(widget, state);
    this.emitChange();
  }

  #handleSetActiveWidget(activeWidget: Nullable<ReaderWidget>): void {
    if (this.#activeWidget !== activeWidget) {
      this.#activeWidget = activeWidget;
      this.emitChange();
    }
  }

  #validateWidgetState(widget: ReaderWidget, state: ReaderWidgetState) {
    switch (widget) {
      case ReaderWidget.CITE_SEE: {
        invariant(
          isCiteSeeStateRecord(state),
          `Widget type ${widget} does not match widget state ${JSON.stringify(state)}`
        );
        break;
      }
      case ReaderWidget.NOTE_TAKING: {
        invariant(
          isNoteTakingStateRecord(state),
          `Widget type ${widget} does not match widget state ${JSON.stringify(state)}`
        );
        break;
      }
      case ReaderWidget.SKIMMING: {
        invariant(
          isSkimmingStateRecord(state),
          `Widget type ${widget} does not match widget state ${JSON.stringify(state)}`
        );
        break;
      }
      case ReaderWidget.SKIMMING_INTERACTION: {
        invariant(
          isSkimmingInteractionStateRecord(state),
          `Widget type ${widget} does not match widget state ${JSON.stringify(state)}`
        );
        break;
      }
      case ReaderWidget.TERM_UNDERSTANDING: {
        invariant(
          isTermsStateRecord(state),
          `Widget type ${widget} does not match widget state ${JSON.stringify(state)}`
        );
        break;
      }
      default:
        invariant(false, `Invalid widget type ${widget}`);
    }
  }
}
