import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import ModerationStatus, {
  convertUrlParamToModerationStatusValue,
} from '@/constants/ModerationStatus';
import S2History from '@/utils/S2History';

import PropTypes from 'prop-types';
import React from 'react';

import type { RouterState, WillRouteToResult } from '@/router/Route';
import type AppContext from '@/AppContext';

export default class AuthorClaimModerationRoute extends React.Component {
  declare context: {
    history: S2History;
  };

  static getPageTitle = () => getString(_ => _.authorClaimModeration.pageTitle);

  static requiresAuthentication = () => true;

  static async willRouteTo(
    { api, claimModerationStore }: AppContext,
    routerState: RouterState
  ): Promise<WillRouteToResult> {
    if (!claimModerationStore.isUninitialized()) {
      return;
    }
    const status =
      convertUrlParamToModerationStatusValue(routerState.params.status) ||
      ModerationStatus.Unsupported;
    const { query } = routerState.query;
    return api.getAuthorProfileListForClaimModeration({ status, query });
  }

  static contextTypes = {
    history: PropTypes.instanceOf(S2History).isRequired,
  };

  render(): React.ReactNode {
    const { location } = this.context.history;
    return (
      <AsyncLoadedPage
        showLoadingPage={false}
        compProps={{ location }}
        load={{
          importer: () =>
            import(
              /* webpackChunkName: "desktop-AuthorClaimModerationPage" */ './AuthorClaimModerationPage'
            ),
          chunkName: 'desktop-AuthorClaimModerationPage',
          moduleId: require.resolveWeak('./AuthorClaimModerationPage'),
        }}
      />
    );
  }
}
