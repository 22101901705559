import React from 'react';

export default function IconStatusApproved(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 40 32">
      <path
        d="M20,0c2.88,0,5.55,0.72,8,2.16S32.4,5.55,33.84,8c1.44,2.45,2.16,5.12,2.16,8s-0.72,5.55-2.16,8
        c-1.44,2.45-3.39,4.4-5.84,5.84C25.55,31.28,22.88,32,20,32s-5.55-0.72-8-2.16C9.55,28.4,7.6,26.45,6.16,24S4,18.88,4,16
        s0.72-5.55,2.16-8S9.55,3.6,12,2.16S17.12,0,20,0z M20,3.1c-2.32,0-4.47,0.58-6.45,1.74S10,7.57,8.84,9.55S7.1,13.68,7.1,16
        s0.58,4.47,1.74,6.45s2.73,3.55,4.71,4.71S17.68,28.9,20,28.9s4.47-0.58,6.45-1.74s3.55-2.73,4.71-4.71S32.9,18.32,32.9,16
        s-0.58-4.47-1.74-6.45S28.43,6,26.45,4.84S22.32,3.1,20,3.1z M29.03,11.48c0.17,0.17,0.26,0.37,0.26,0.58
        c0,0.22-0.09,0.39-0.26,0.52l-11.1,11.03c-0.17,0.17-0.37,0.26-0.58,0.26c-0.22,0-0.39-0.09-0.52-0.26l-5.87-5.87
        c-0.17-0.17-0.26-0.37-0.26-0.58c0-0.22,0.09-0.39,0.26-0.52l1.48-1.48c0.13-0.13,0.3-0.19,0.52-0.19c0.22,0,0.41,0.06,0.58,0.19
        l3.81,3.94l9.16-9.1c0.13-0.13,0.3-0.19,0.52-0.19c0.22,0,0.41,0.09,0.58,0.26L29.03,11.48z"
      />
    </symbol>
  );
}
