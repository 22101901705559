import { getBrowserCookieJar } from '@/utils/cookies/BrowserCookieJar';
import { incrementCounter, REFRESH_UI_OUTDATED } from '@/utils/counter';
import { isBrowser, isLocalHostname } from '@/utils/env';
import logger from '@/logger';

export const LAST_RELOADED_COOKIE = 's2_ui_last_reloaded';
export const MIN_RELOAD_DELAY_SEC = 30;
export const UI_OUTDATED_HEADER = 'x-s2-ui-version-outdated';

export function wasReloadedRecently(): boolean {
  if (!isBrowser()) {
    // Servers don't refresh
    return false;
  }

  const lastReloadedCookie = getBrowserCookieJar().getCookie(LAST_RELOADED_COOKIE);
  if (typeof lastReloadedCookie !== 'string') {
    // Cookie wasn't set
    return false;
  }

  const lastReloadedTimestamp = parseInt(lastReloadedCookie, 10);
  if (Number.isNaN(lastReloadedTimestamp)) {
    // Cookie has bad value
    return false;
  }

  const timeSinceForcedReloadSec = (Date.now() - lastReloadedTimestamp) / 1000;
  return timeSinceForcedReloadSec < MIN_RELOAD_DELAY_SEC;
}

/**
 * When receiving a response from an API call, the backend
 * service may add a header as a flag, indicating that the
 * current browser client is out of date.
 * This can happen following a rollout, and the present
 * code induces a page reload to make sure the client
 * gets the latest version of the browser application.
 *
 * @param {superagent.Response} response
 */
export default function reloadIfUIOutdated(response: any): void {
  if (!isBrowser()) {
    // Don't reload the server
    return;
  }

  if (isLocalHostname(document.location.hostname)) {
    // Don't reload locally
    return;
  }

  const isUIOutdated = response.headers[UI_OUTDATED_HEADER] === '1';
  if (!isUIOutdated) {
    // API didn't say to update
    return;
  }

  if (wasReloadedRecently()) {
    // Don't refresh if we did too recently
    logger.error(
      `last UI reload occurred less than ${MIN_RELOAD_DELAY_SEC} seconds ago so another will not be triggered.`
    );
    return;
  }

  // Looks like we need to reload
  getBrowserCookieJar().saveCookie(LAST_RELOADED_COOKIE, Date.now().toString(), {
    maxAge: MIN_RELOAD_DELAY_SEC,
  });
  incrementCounter(REFRESH_UI_OUTDATED);
  document.location.reload();
}
