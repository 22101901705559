import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import logger from '@/logger';

import idx from 'idx';
import React from 'react';

export default class AccountContactRoute extends React.Component {
  static getPageTitle = () => getString(_ => _.account.page.contact.pageTitle);

  static async willRouteTo({ userContactStore, api }) {
    return userContactStore.isUninitialized()
      ? api.getUserContact().catch(ex => {
          if (idx(ex, _ => _.errorCode) === 'contactMissing') {
            logger.info('No contact associated yet');
          } else {
            throw ex;
          }
        })
      : Promise.resolve();
  }

  render() {
    return (
      <AsyncLoadedPage
        showLoadingPage={false}
        load={{
          importer: () =>
            import(/* webpackChunkName: "shared-AccountContactPage" */ './AccountContactPage'),
          chunkName: 'shared-AccountContactPage',
          moduleId: require.resolveWeak('./AccountContactPage'),
        }}
      />
    );
  }
}
