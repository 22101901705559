import { ApiRequestStartingPayload } from '@/api/BaseApi';
import { ApiResponse, PaperPdfDataResponseBody } from '@/api/ApiResponse';
import {
  getEmptyPaperPdfOverlapsMap,
  OverlapItemProps,
  PaperPdfOverlapsMap,
  ReaderFeatureNameType,
} from '@/models/paper-pdf/PaperPdfOverlaps';
import { getPaperPdfInterationsFromJS } from '@/models/paper-pdf/PaperPdfInteractions';
import BaseStore from '@/stores/BaseStore';
import Constants from '@/constants';
import S2Dispatcher from '@/utils/S2Dispatcher';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import Immutable from 'immutable';

export default class ReaderInteractionsStore extends BaseStore {
  #storeState: StoreStateValue;
  #overlaps: PaperPdfOverlapsMap;
  // this map is for speeding up accessing #overlaps
  #s2airsIdToOverlapKeyMap: Immutable.Map<string, OverlapItemProps>;
  /*
    s2airs Ids to overlap item info
  */
  constructor(dispatcher: S2Dispatcher) {
    super();

    this.#storeState = StoreState.UNINITIALIZED;
    this.#overlaps = getEmptyPaperPdfOverlapsMap();
    this.#s2airsIdToOverlapKeyMap = Immutable.Map();

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const apiStartingPayload = payload as ApiRequestStartingPayload;
          switch (apiStartingPayload.requestType) {
            case Constants.requestTypes.PDF_DATA: {
              this.#storeState = StoreState.LOADING;
              this.#overlaps = getEmptyPaperPdfOverlapsMap();
              this.#s2airsIdToOverlapKeyMap = Immutable.Map();
              this.emitChange();
              break;
            }
          }
          break;
        }

        case Constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.PDF_DATA: {
              const pdfDataApiResponse = apiResponse as ApiResponse<PaperPdfDataResponseBody>;
              const interactions = pdfDataApiResponse?.resultData?.interactions;
              if (interactions) {
                const parsedData = getPaperPdfInterationsFromJS(interactions);
                this.#overlaps = parsedData.overlaps;
                parsedData.overlaps.forEach((_, keyItem) => {
                  this.#s2airsIdToOverlapKeyMap = this.#s2airsIdToOverlapKeyMap.set(
                    keyItem.s2airsId,
                    keyItem
                  );
                });
              }

              this.#storeState = StoreState.LOADED;
              this.emitChange();
              break;
            }
          }
          break;
        }

        case Constants.actions.API_REQUEST_FAILED: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.PDF_DATA: {
              this.#storeState = StoreState.ERROR;
              this.#overlaps = getEmptyPaperPdfOverlapsMap();
              this.#s2airsIdToOverlapKeyMap = Immutable.Map();
              this.emitChange();
              return;
            }
          }
          return;
        }
      }
    });
  }

  hasOverlap(s2airsIdKey: string, featureNameToCheck?: ReaderFeatureNameType): boolean {
    const keyItem = this.#s2airsIdToOverlapKeyMap.get(s2airsIdKey);
    if (!keyItem) {
      return false;
    }

    // return if there is no overlaps for s2airsIdKey
    // or s2airsIdKey has overlaps but there is no specific feature to check
    const overlaps = this.#overlaps.get(keyItem);
    if (!overlaps || !featureNameToCheck) {
      return !!overlaps;
    }

    return !!overlaps.find(overlapItem => overlapItem.featureName == featureNameToCheck);
  }

  isUninitialized(): boolean {
    return this.#storeState === StoreState.UNINITIALIZED;
  }

  isLoading(): boolean {
    return this.isUninitialized() || this.#storeState === StoreState.LOADING;
  }

  isFailed(): boolean {
    return this.#storeState === StoreState.ERROR;
  }
}
