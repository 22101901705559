import { DEPRECATED__FlowOptional, isBoolean, isNumber, isString, Nullable } from '@/utils/types';
import { isBrowser, isTrackingAllowedOnBrowser } from '@/utils/env';
import logger from '@/logger';
import softError, { softInvariant } from '@/utils/softError';

export type HeapPropKey = string | number;
export type HeapPropValue = Nullable<string | number | boolean>;
export type HeapPropObject = { [key: HeapPropKey]: HeapPropValue };

export type HeapInstance = {
  addEventProperties: (obj: HeapPropObject) => void;
  addUserProperties: (obj: HeapPropObject) => void;
  appid: string;
  clearEventProperties: () => void;
  config: unknown;
  identity: DEPRECATED__FlowOptional<string>;
  identify: (
    identity: string | number,
    userPropName: DEPRECATED__FlowOptional<HeapPropKey> // NOTE: userPropName is undocumented
  ) => void;
  loaded: boolean;
  removeEventProperty: (key: HeapPropKey) => void;
  resetIdentity: () => void;
  track: (eventName: string, props?: Nullable<HeapPropObject>) => void;
  userId: string;
  version: unknown;
};

const HEAP_EVENT_PROP_REGEX = /^[a-zA-Z][\w\s:\-.()]{1,50}$/;

export function optHeap(): Nullable<HeapInstance> {
  if (!isBrowser() || !isHeapInstance(window.heap)) {
    return null;
  }
  if (!isTrackingAllowedOnBrowser()) {
    return null;
  }
  return window.heap || null;
}

export function runWithHeap(callback: (inst: HeapInstance) => any): void {
  const heap = optHeap();
  if (!heap) {
    if (isBrowser() && isTrackingAllowedOnBrowser()) {
      logOnce(() => logger.error('attempted to use Heap before the global was set'));
    }
    return;
  }

  try {
    callback(getProxiedHeap(heap));
  } catch (error) {
    softError('heapError', 'caught error in runWithHeap() callback', error);
  }
}

export function getProxiedHeap(heap: HeapInstance): HeapInstance {
  // Wrap addEventProperties to catch potentially bad values being added which have in the past exploded
  // our redshift
  return new Proxy(heap, {
    get: (target, prop) => {
      switch (prop) {
        case 'addEventProperties':
          return heapProps => {
            // iterate over the prop keys and filter out and error on any bad keys
            const cleanedProperties: any = Object.keys(heapProps)
              .filter(key =>
                softInvariant(
                  isHeapPropKeyValid(key),
                  'heap.eventPropKeyCheck',
                  `Event prop key "${key}" was invalid in ${JSON.stringify(heapProps)}`
                )
              )
              .reduce((obj, key) => {
                obj[key] = heapProps[key];
                return obj;
              }, {});

            // If there are any keys left, call the original heap method
            if (Object.keys(cleanedProperties).length) {
              return target[prop](cleanedProperties);
            }
          };
        default:
          return target[prop];
      }
    },
  });
}

export function isHeapPropKeyValid(key: any): boolean {
  return isString(key) && HEAP_EVENT_PROP_REGEX.test(key);
}

/**
 * This validates the shape of our HeapInstance to ensure it matches
 * the expected interface at runtime. Due to interventions by browser-
 * and network-level ad-blockers, relying on window.heap being defined
 * does not guarantee all functions are present.
 */
function isHeapInstance(heap: any): heap is HeapInstance {
  return (
    !!heap &&
    typeof heap.clearEventProperties === 'function' &&
    typeof heap.identify === 'function' &&
    typeof heap.resetIdentity === 'function' &&
    typeof heap.track === 'function'
  );
}

let hasLogged = false;
function logOnce(cb: () => void): void {
  if (hasLogged) {
    return;
  }
  hasLogged = true;
  cb();
}

// Removes values from event data that cannot be sent to Heap
export function getHeapPropsFromEventData(eventData: Record<HeapPropKey, unknown>): HeapPropObject {
  const heapProps: HeapPropObject = {};
  for (const [key, value] of Object.entries(eventData)) {
    if (isString(value) || isNumber(value) || isBoolean(value)) {
      heapProps[key] = value;
    } else {
      logger.warn(
        `event prop "${key}" was dropped because "${typeof value}" is not a valid Heap prop type`
      );
    }
  }
  return heapProps;
}
