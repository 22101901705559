import { Nullable, ReactNodeish } from '@/utils/types';

import classnames from 'classnames';
import React, { ComponentProps, PropsWithChildren, ReactNode } from 'react';

type CLContainerBaseProps = PropsWithChildren<
  {
    footer?: Nullable<ReactNode>;
    hasDogEar?: boolean;
  } & ComponentProps<'div'>
>;

export default function CLContainerBase({
  children,
  className,
  footer,
  hasDogEar,
  ...otherProps
}: CLContainerBaseProps): ReactNodeish {
  return (
    <div
      {...otherProps}
      className={classnames(
        {
          'cl-container': true,
          'cl-container--has-footer': !!footer,
          'cl-container--has-dog-ear': hasDogEar,
        },
        className
      )}>
      <div className="cl-container__content">{children}</div>
      {footer && <div className="cl-container__footer">{footer}</div>}
    </div>
  );
}

CLContainerBase.defaultProps = {
  hasDogEar: false,
};
