import { getLayoverLogger } from '@/utils/layover/LayoverLogger';

import React from 'react';

export default class RenderErrorLogger extends React.PureComponent {
  componentDidCatch(error, info) {
    getLayoverLogger().logError(error, info);
  }

  render() {
    return this.props.children || null;
  }
}
