import { FigureFromJS, FigureRecord, FigureRecordFactory, getFigureFromJS } from './Figure';

import Immutable from 'immutable';

export type PaperFigureFromJS = {
  paperId: string;
  paperSlug: string;
  figure: FigureFromJS;
};

type Props = {
  paperId: string;
  paperSlug: string;
  figure: FigureRecord;
};

const defaultProps: Props = {
  paperId: '',
  paperSlug: '',
  figure: FigureRecordFactory(),
};

export const PaperFigureRecordFactory = Immutable.Record<Props>(defaultProps);
export type PaperFigureRecord = Immutable.RecordOf<Props>;

export function getPaperFigureFromJS(args: PaperFigureFromJS): PaperFigureRecord {
  return PaperFigureRecordFactory({
    ...args,
    figure: getFigureFromJS(args.figure),
  });
}
