import CLButtonBase, { CLButtonBaseProps } from './CLButtonBase';

import classnames from 'classnames';
import React from 'react';

import type { ReactNodeish } from '@/utils/types';

export type CLTextButtonProps = CLButtonBaseProps;

export default function CLTextButton({
  className,
  ...extraProps
}: CLTextButtonProps): ReactNodeish {
  return <CLButtonBase className={classnames('cl-text-button', className)} {...extraProps} />;
}
