import Immutable from 'immutable';

import type { Nullable } from '@/utils/types';
import type { VariationKey } from './Experiment';

export type VariationFromJS = {
  key: VariationKey;
  description?: Nullable<string>;
};

type Props = {
  KEY: VariationKey;
  description: Nullable<string>;
};

const defaultProps: Props = {
  KEY: '',
  description: null,
};

export const VariationRecordFactory = Immutable.Record(defaultProps);
export type VariationRecord = Immutable.RecordOf<Props>;

export function getVariationFromJS(args: VariationFromJS): VariationRecord {
  const { key: KEY, description = null } = args;
  return VariationRecordFactory({
    KEY,
    description,
  });
}
