import classnames from 'classnames';
import React from 'react';

export const CLPaperControlsContext = React.createContext({
  setExpandedContent: null,
});

export default class CLPaperControlsExpansionManager extends React.PureComponent {
  state = {
    expandedContent: null,
  };

  setExpandedContent = expandedContent => {
    this.setState({
      expandedContent,
    });
  };

  render() {
    const { children } = this.props;
    const { expandedContent } = this.state;

    return (
      <CLPaperControlsContext.Provider value={{ setExpandedContent: this.setExpandedContent }}>
        {children}
        {/*
          expandedContent is used to render items below the CLPaperControls row. Contents are injected in using context
          Example of usage can be found in CLPaperFlags where it injects a dropdown explanation of excerpts when the Excerpt Flag is clicked
        */}
        <div
          className={classnames({
            'cl-paper-controls__expanded-content': true,
            'cl-paper-controls__expanded-content__is-visible': !!expandedContent,
          })}>
          {expandedContent}
        </div>
      </CLPaperControlsContext.Provider>
    );
  }
}
