import AnalyticsEvent from './AnalyticsEvent';

import EventType from '@/analytics/constants/EventType';

import merge from 'merge';

export default class ClickEvent extends AnalyticsEvent {
  constructor(target: string, data?: any) {
    super(EventType.CLICK, merge.recursive({ target }, data));
  }

  static create(target: string, data?: any): ClickEvent {
    return new ClickEvent(target, data);
  }
}
