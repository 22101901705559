import { getLayoverLogger } from './layover/LayoverLogger';
import { getLayoverMetrics } from './layover/LayoverMetrics';
import { incrementCounter, READER_FILE_LOAD_ERROR } from './counter';
import { isBrowser } from './env';
import softError from './softError';

import { ReaderWidget } from '@/models/reader-widgets/ReaderWidgets';
import { SkimmingStateRecordFactory } from '@/models/reader-widgets/ReaderSkimming';
import { useAppContext } from '@/AppContext';
import { useCiteSeeCounts } from '@/components/shared/paper-detail/reader/citesee-utils';
import {
  useReaderWidgetAvailability,
  useReaderWidgetStateMetrics,
} from '@/components/shared/paper-detail/reader/reader-widget-utils';
import AnalyticsEvent from '@/analytics/models/AnalyticsEvent';
import EventTarget from '@/analytics/constants/EventTarget';
import ReaderAcronymsStore from '@/stores/reader/ReaderAcronymsStore';
import ReaderTermsStore from '@/stores/reader/ReaderTermsStore';
import ShowEvent from '@/analytics/models/ShowEvent';
import trackAnalyticsEvents from '@/analytics/trackAnalyticsEvents';
import trackRicoEvent from '@/analytics/trackRicoEvent';

import { DocumentContext, TransformContext, useVisiblePagesStore } from '@allenai/pdf-components';
import React from 'react';

import type { Nullable } from './types';

export type LoadTimingMetric = {
  startTimestamp: Nullable<number>;
  endTimestamp: Nullable<number>;
};

export type PdfLoadTimes = {
  pdfLoadMs: LoadTimingMetric;
};

export type PdfOutlineLoadTimes = {
  outlineLoadMs: LoadTimingMetric;
};

export type PdfFirstPageLoadTimes = {
  firstPageLoadMs: LoadTimingMetric;
};

export type PdfInputDelayTimes = {
  inputDelayMs: LoadTimingMetric;
};

const LAYOVER_PREFIX = 'reader.timing.';

export function initPdfReaderAnalytics(totalLoadStartTimestamp: number): PdfLoadTimes {
  const pdfLoadObj: PdfLoadTimes = {
    pdfLoadMs: {
      startTimestamp: totalLoadStartTimestamp,
      endTimestamp: null,
    },
  };

  return pdfLoadObj;
}

export function initOutlineLoadAnalytics(): PdfOutlineLoadTimes {
  return {
    outlineLoadMs: {
      startTimestamp: Date.now(),
      endTimestamp: null,
    },
  };
}

export function initFirstPageLoadAnalytics(loadStartTimestamp: number): PdfFirstPageLoadTimes {
  return {
    firstPageLoadMs: {
      startTimestamp: loadStartTimestamp,
      endTimestamp: null,
    },
  };
}

export function initInputDelayAnalytics(startTimestamp: number): PdfInputDelayTimes {
  return {
    inputDelayMs: {
      startTimestamp: startTimestamp,
      endTimestamp: null,
    },
  };
}

export function finishOutlineLoadAnalytics(
  outlineLoadObj: PdfOutlineLoadTimes,
  tags: string[] = []
): void {
  if (outlineLoadObj.outlineLoadMs.endTimestamp !== null) {
    return;
  }

  outlineLoadObj.outlineLoadMs.endTimestamp = Date.now();
  fireLayoverEvent(`${LAYOVER_PREFIX}outlineLoad`, outlineLoadObj.outlineLoadMs, tags);
}

export function finishPdfLoadAnalytics(
  pdfLoadObj: PdfLoadTimes,
  paperStats?: {
    error?: string;
    paperId?: string;
    pdfSha?: string;
    pdfUrl?: string;
    pdfFileSizeBytes: number;
    pdfNumPages: number;
  },
  tags?: string[]
): void {
  if (pdfLoadObj.pdfLoadMs.endTimestamp !== null) {
    return;
  }
  pdfLoadObj.pdfLoadMs.endTimestamp = Date.now();
  fireTimingEvents(pdfLoadObj, tags);

  // NOTE: (2022-07-14) This is to help with determining an SLA for the Reader.
  //       We can remove this code once an SLA has been established.
  if (paperStats) {
    const pdfLoadDurationMs =
      pdfLoadObj.pdfLoadMs.endTimestamp - (pdfLoadObj.pdfLoadMs.startTimestamp || 0);
    getLayoverLogger().log('reader.pdfLoadMetrics', {
      ...paperStats,
      ...pdfLoadObj,
      pdfLoadDurationMs,
    });
  }
}

export function finishFirstPageLoadAnalytics(
  firstPageLoadObj: PdfFirstPageLoadTimes,
  tags?: string[]
): void {
  if (firstPageLoadObj.firstPageLoadMs.endTimestamp !== null) {
    return;
  }

  firstPageLoadObj.firstPageLoadMs.endTimestamp = Date.now();
  fireLayoverEvent(`${LAYOVER_PREFIX}firstPageLoadTime`, firstPageLoadObj.firstPageLoadMs, tags);
}

export function finishInputDelayAnalytics(
  inputDelayObj: PdfInputDelayTimes,
  tags?: string[]
): void {
  if (inputDelayObj.inputDelayMs.endTimestamp !== null) {
    return;
  }

  inputDelayObj.inputDelayMs.endTimestamp = Date.now();
  fireLayoverEvent(`${LAYOVER_PREFIX}inputDelayTime`, inputDelayObj.inputDelayMs, tags);
}

function fireLayoverEvent(name: string, rawMetric: LoadTimingMetric, tags?: string[]): void {
  if (!rawMetric.startTimestamp || !rawMetric.endTimestamp) {
    softError(
      'readerAnalytics',
      `Attempted to capture reader analytics for "${name}" before it was ready.`
    );
    return;
  }

  // Make Flow typechecking happy
  const formattedMetric = {
    startTimestamp: rawMetric.startTimestamp,
    endTimestamp: rawMetric.endTimestamp,
  };

  getLayoverMetrics().sendTiming(
    name,
    formattedMetric.startTimestamp,
    formattedMetric.endTimestamp,
    tags
  );
}

function fireTimingEvents(pdfLoadObj: PdfLoadTimes, tags?: string[]): void {
  fireLayoverEvent(`${LAYOVER_PREFIX}pdfLoad`, pdfLoadObj.pdfLoadMs, tags);
}

function trackAcronymsPageImpressionRicoEvent(
  pagesScrolledIntoView: number[],
  readerAcronymsStore: ReaderAcronymsStore
): void {
  const mentionsVisible = pagesScrolledIntoView.map(pageNum =>
    readerAcronymsStore.getMentionIdsInPage(pageNum - 1)
  );

  // only log to rico if there are mentions
  if (!mentionsVisible.flat().length) {
    return;
  }

  const ricoData = {
    pageNumbers: pagesScrolledIntoView.join(','),
    mentions: mentionsVisible,
    outputDescription: readerAcronymsStore.getEncodedOutputDescription(),
  };

  trackRicoEvent(EventTarget.PaperDetail.Reader.Acronyms.PAGE_IMPRESSION, ricoData);
}

function trackTermsPageImpressionRicoEvent(
  pagesScrolledIntoView: number[],
  readerTermsStore: ReaderTermsStore
): void {
  const mentionsVisible = pagesScrolledIntoView.map(pageNum =>
    readerTermsStore.getMentionIdsInPage(pageNum - 1)
  );

  // only log to rico if there are mentions
  if (!mentionsVisible.flat().length) {
    return;
  }

  const ricoData = {
    pageNumbers: pagesScrolledIntoView.join(','),
    mentions: mentionsVisible,
    outputDescription: readerTermsStore.getEncodedOutputDescription(),
  };

  trackRicoEvent(EventTarget.PaperDetail.Reader.Terms.PAGE_IMPRESSION, ricoData);
}

export default function usePageImpressionMetrics() {
  const { scale } = React.useContext(TransformContext);
  const { numPages } = React.useContext(DocumentContext);
  const { readerAcronymsStore, readerTermsStore, readerWidgetStore } = useAppContext();
  const readerWidgetStateMetrics = useReaderWidgetStateMetrics();
  const citeSeeCounts = useCiteSeeCounts();
  const {
    isAcronymsAvailable,
    isCiteSeeAvailable,
    isSkimmingV2Available,
    isTermUnderstandingAvailable,
  } = useReaderWidgetAvailability();
  const {
    state: { pagesScrolledIntoView },
  } = useVisiblePagesStore();

  React.useEffect(() => {
    const { pageIndexToCiteSeeCount, savedToLibraryCount, citedByLibraryCount } = citeSeeCounts;
    const skimmingState = SkimmingStateRecordFactory(
      readerWidgetStore.getStateForWidget(ReaderWidget.SKIMMING) || undefined
    );
    const visiblePages = [...pagesScrolledIntoView.keys()];

    if (isAcronymsAvailable && visiblePages.length) {
      trackAcronymsPageImpressionRicoEvent(visiblePages, readerAcronymsStore);
    }

    if (isTermUnderstandingAvailable && visiblePages.length) {
      trackTermsPageImpressionRicoEvent(visiblePages, readerTermsStore);
    }

    const pageImpressions: AnalyticsEvent[] = visiblePages.map(pageNum => {
      const acronymsData = isAcronymsAvailable
        ? {
            acronymCountOnPage: readerAcronymsStore.getMentionIdsInPage(pageNum - 1)?.length || 0,
            totalUniqueAcronyms: readerAcronymsStore.getTotalUniqueAcronyms(),
            uniqueAcronymCountOnPage: readerAcronymsStore.getUniqueAcronymCountOnPage(pageNum - 1),
          }
        : [];

      const termsData = isTermUnderstandingAvailable
        ? {
            termCountOnPage: readerTermsStore.getMentionIdsInPage(pageNum - 1)?.length || 0,
            totalUniqueTerms: readerTermsStore.getTerms().size,
            uniqueTermCountOnPage: readerTermsStore.getUniqueTermCountOnPage(pageNum - 1),
          }
        : [];

      const citeSeeData = isCiteSeeAvailable
        ? {
            citeSeeCountOnPage: pageIndexToCiteSeeCount.get(pageNum - 1) || 0,
            totalCiteSeePaperCount: savedToLibraryCount + citedByLibraryCount,
          }
        : [];

      const skimmingData = isSkimmingV2Available
        ? {
            skimmingSettings: JSON.stringify(skimmingState.toObject()),
          }
        : [];

      return ShowEvent.create(EventTarget.PaperDetail.Reader.Page.IMPRESSION, {
        pageNum,
        zoomValue: scale.toFixed(3),
        totalPages: numPages,
        readerWidgetState: JSON.stringify(readerWidgetStateMetrics),
        ...acronymsData,
        ...citeSeeData,
        ...skimmingData,
        ...termsData,
      });
    });

    trackAnalyticsEvents(pageImpressions);
  }, [pagesScrolledIntoView]);
}

export const ReaderPageImpressionsComponent = () => {
  // wrapping this hook in a component prevents its siblings from rerendering
  usePageImpressionMetrics();
  return null; // nothing to render
};

export function sendFileLoadErrorToDataDog(error: string, pdfURL: string): void {
  const networkData = {};
  if (isBrowser() && typeof window !== 'undefined') {
    const navigator = window.navigator['connection'];
    for (const key in navigator) {
      if (typeof navigator[key] !== 'function') {
        networkData[key] = navigator[key];
      }
    }
    networkData['visibilityState'] = document?.visibilityState || {};
    networkData['onLine'] = window.navigator?.onLine || {};
  }
  const propsToSend = {
    pdfURL,
    ...networkData,
  };
  getLayoverLogger().logNamedError(READER_FILE_LOAD_ERROR, error, propsToSend);
  incrementCounter(READER_FILE_LOAD_ERROR);
}
