import { isBrowser } from '@/utils/env';
import logger from '@/logger';

import request from 'superagent';

import type { ExperimentEnvRecord } from '@/constants/ExperimentEnvironment';
import type { Nullable } from '@/utils/types';

export type S2WeblabConfigObj = {
  experimentsDynamicConfig: Nullable<string>;
  featuresDynamicConfig: Nullable<string>;
};

export default class WeblabConfig {
  static config: Nullable<S2WeblabConfigObj> = null;

  constructor() {
    if (isBrowser() && typeof window.S2_WEBLAB_CONFIG !== 'undefined') {
      WeblabConfig.config = window.S2_WEBLAB_CONFIG;
      delete window.S2_WEBLAB_CONFIG;
    }
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async getConfig(): Promise<S2WeblabConfigObj> {
    return (
      WeblabConfig.config || {
        experimentsDynamicConfig: null,
        featuresDynamicConfig: null,
      }
    );
  }

  async fetchDynamicConfigForEnvironment(
    environment: Nullable<ExperimentEnvRecord>
  ): Promise<S2WeblabConfigObj> {
    if (environment) {
      try {
        const result: any = await new Promise((resolve, reject) => {
          request.get(`${environment.url}`).end((error, res) => {
            error ? reject(error) : resolve(res);
          });
        });
        const experimentsDynamicConfig = JSON.stringify(result?.body?.states || {});
        return { experimentsDynamicConfig, featuresDynamicConfig: '{}' };
      } catch (error) {
        logger.error(
          'Failed to fetch config for ' + environment.id + '. Not activating experiments',
          error
        );
        return { experimentsDynamicConfig: '{}', featuresDynamicConfig: '{}' };
      }
    } else {
      return { experimentsDynamicConfig: '{}', featuresDynamicConfig: '{}' };
    }
  }
}
