import { CitationFromJS, CitationRecord } from './Citation';

import { Nullable } from '@/utils/types';
import CitationConstants, { CitationType } from '@/constants/Citation';
import SortConstants from '@/constants/sort-type';

import Immutable from 'immutable';

export type CitationPageFromJS = {
  citations: CitationFromJS[];
  citationType: string;
  pageNumber: number;
  totalCitations: number;
  totalCitationsUnfiltered: number;
  totalPages: number;
  requestedPageSize: number;
  sort: string;
  lastSort: string;
  citationIntent: string;
  lastIntent: string;
  yearFilter: {
    min: Nullable<number>;
    max: Nullable<number>;
  };
};

type Props = {
  citations: Immutable.List<CitationRecord>;
  citationType: CitationType;
  pageNumber: number;
  totalCitations: number;
  totalCitationsUnfiltered: number;
  totalPages: number;
  requestedPageSize: number;
  sort: string;
  lastSort: string;
  citationIntent: string;
  lastIntent: string;
  loading: boolean;
  yearFilter: Immutable.Map<string, Nullable<number>>;
};

const defaultProperties: Props = {
  citations: Immutable.List(),
  citationType: CitationType.CITING_PAPERS,
  pageNumber: 1,
  totalCitations: 0,
  totalCitationsUnfiltered: 0,
  totalPages: 1,
  requestedPageSize: 10,
  sort: SortConstants.RELEVANCE.id,
  lastSort: SortConstants.RELEVANCE.id,
  citationIntent: CitationConstants.INTENTS.ALL_INTENTS.id,
  lastIntent: CitationConstants.INTENTS.ALL_INTENTS.id,
  loading: false,
  yearFilter: Immutable.Map({
    min: null,
    max: null,
  }),
};

export const CitationPageRecordFactory = Immutable.Record<Props>(defaultProperties);
export type CitationPageRecord = Immutable.RecordOf<Props>;
