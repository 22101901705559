import React from 'react';

export default function IconEntityLink(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 24 24">
      <g
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round">
        <g transform="translate(-950.000000, -706.000000)" stroke="#237aaa">
          <g transform="translate(930.000000, 242.000000)">
            <g transform="translate(0.000000, 179.000000)">
              <g transform="translate(20.000000, 284.000000)">
                <g transform="translate(0.000000, 1.000000)">
                  <path d="M14.7272727,12.5454545 L14.8210909,12.6392727 C15.6730909,13.4912727 17.0541818,13.4912727 17.9061818,12.6392727 L22.2316364,8.31490909 C23.5036364,7.04181818 23.5036364,4.95927273 22.2316364,3.68618182 L20.3138182,1.76945455 C19.0418182,0.496363636 16.9581818,0.496363636 15.6861818,1.76945455 L11.3607273,6.09381818 C10.5087273,6.94581818 10.5087273,8.32690909 11.3607273,9.18 L11.4545455,9.27272727" />
                  <path d="M9.27272727,11.4545455 L9.17890909,11.3618182 C8.32690909,10.5087273 6.94581818,10.5087273 6.09381818,11.3618182 L1.76836364,15.6861818 C0.496363636,16.9592727 0.496363636,19.0418182 1.76836364,20.3149091 L3.68618182,22.2316364 C4.95818182,23.5047273 7.04181818,23.5047273 8.31381818,22.2316364 L12.6392727,17.9072727 C13.4912727,17.0541818 13.4912727,15.6730909 12.6392727,14.8210909 L12.5454545,14.7272727" />
                  <path d="M7.75778182,16.2427636 L16.2417818,7.75767273" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </symbol>
  );
}
