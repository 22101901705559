import { getString } from '@/content/i18n';
import { ModalId } from '@/constants/Modal';
import { ReactNodeish } from '@/utils/types';
import CLModal from '@/components/library/modal/CLModal';
import CLRule from '@/components/library/layout/CLRule';
import ExternalLink from '@/components/shared/ExternalLink';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class CollaboratorsModal extends PureComponent {
  static propTypes = {
    onHideClick: PropTypes.func,
  };

  render(): ReactNodeish {
    return (
      <CLModal modalId={ModalId.COLLABORATORS} className="collaborators-modal">
        <div className="collaborators-modal__content">
          <h2 className="collaborators-modal__header">
            {getString(_ => _.appFooter.collaborators)}
          </h2>
          <ul className="collaborators flex-row">
            <li className="collaborators__collaborator">
              <p className="collaborators__collaborator-info">
                {getString(_ => _.appFooter.martiH)}
              </p>
              <p className="collaborators__collaborator-info collaborators__collaborator-subtext">
                {getString(_ => _.appFooter.martiHStatement)}
              </p>
            </li>
            <li className="collaborators__collaborator">
              <p className="collaborators__collaborator-info">
                {getString(_ => _.appFooter.reinaldoB)}
              </p>
              <p className="collaborators__collaborator-info collaborators__collaborator-subtext">
                {getString(_ => _.appFooter.concatenatorText)}
                <ExternalLink href="http://academic.odysci.com/">
                  {getString(_ => _.appFooter.odySci)}
                </ExternalLink>{' '}
                {getString(_ => _.appFooter.team)}
              </p>
            </li>
            <li className="collaborators__collaborator">
              <p className="collaborators__collaborator-info">{getString(_ => _.appFooter.leeG)}</p>
              <p className="collaborators__collaborator-info collaborators__collaborator-subtext">
                {getString(_ => _.appFooter.concatenatorText)}
                <ExternalLink href="http://citeseerx.ist.psu.edu/">
                  {getString(_ => _.appFooter.citeSeer)}
                </ExternalLink>{' '}
                {getString(_ => _.appFooter.team)}
              </p>
            </li>
            <li className="collaborators__collaborator">
              <p className="collaborators__collaborator-info">
                {getString(_ => _.appFooter.paulG)}
              </p>
              <p className="collaborators__collaborator-info collaborators__collaborator-subtext">
                {getString(_ => _.appFooter.paulGStatement)}
              </p>
            </li>
            <li className="collaborators__collaborator">
              <p className="collaborators__collaborator-info">
                {getString(_ => _.appFooter.johnB)}
              </p>
              <p className="collaborators__collaborator-info collaborators__collaborator-subtext">
                {getString(_ => _.appFooter.johnBStatement)}
              </p>
            </li>
            <li className="collaborators__collaborator">
              <p className="collaborators__collaborator-info">
                {getString(_ => _.appFooter.microsoftR)}
              </p>
              <p className="collaborators__collaborator-info collaborators__collaborator-subtext">
                {getString(_ => _.appFooter.concatenatorText)}
                <ExternalLink href="https://www.microsoft.com/en-us/research/project/academic/">
                  {getString(_ => _.appFooter.microsoftA)}
                </ExternalLink>{' '}
                {getString(_ => _.appFooter.team)}
              </p>
            </li>
          </ul>
        </div>
        <CLRule />
        <div className="collaborators-modal__content">
          <h2 className="collaborators-modal__header">
            {getString(_ => _.appFooter.attributions)}
          </h2>
          <ul className="collaborators flex-row">
            <li className="collaborators__attribution">
              <p className="collaborators__collaborator-info">
                {getString(_ => _.appFooter.fontAwesome)}
              </p>
              <p className="collaborators__collaborator-info collaborators__collaborator-subtext">
                {getString(_ => _.appFooter.fontAwesomeStatement)}
                <ExternalLink href="https://fontawesome.com/">
                  {getString(_ => _.appFooter.fontAwesome)}
                </ExternalLink>
              </p>
            </li>
          </ul>
        </div>
      </CLModal>
    );
  }
}
