import BaseStore from './BaseStore';

import { ApiResponse, PaperLabsResponseBody } from '@/api/ApiResponse';
import { getPaperLabRecordFromJS, PaperLabRecord } from '@/models/paper/PaperLab';
import { Nullable } from '@/utils/types';
import Constants from '@/constants';
import S2Dispatcher from '@/utils/S2Dispatcher';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import Immutable from 'immutable';

import type { ApiRequestStartingPayload } from '@/api/BaseApi';

export default class PaperLabsStore extends BaseStore {
  #storeState: StoreStateValue = StoreState.UNINITIALIZED;
  #paperId: Nullable<string> = null;
  #labs: Immutable.List<PaperLabRecord> = Immutable.List();

  constructor(dispatcher: S2Dispatcher) {
    super();

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const apiRequest = payload as ApiRequestStartingPayload;
          switch (apiRequest.requestType) {
            case Constants.requestTypes.PAPER_LABS: {
              return this.#handlePaperLabsStarting();
            }
          }
          break;
        }
        case Constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.PAPER_LABS: {
              return this.#handlePaperLabsComplete(apiResponse);
            }
          }
          break;
        }

        case Constants.actions.ROUTING: {
          return this.#handleRouting();
        }
      }
    });
  }

  #handlePaperLabsStarting(): void {
    this.#storeState = StoreState.LOADING;
    this.emitChange();
  }

  #handlePaperLabsComplete(payload: ApiResponse<PaperLabsResponseBody>): void {
    const { paperId, labs } = payload.resultData;
    this.#paperId = paperId;
    this.#labs = Immutable.List(labs)
      .map(raw => getPaperLabRecordFromJS(raw))
      .filter(lab => !!lab.html); // Remove any labs that don't have HTML
    this.#storeState = StoreState.LOADED;
    this.emitChange();
  }

  #handleRouting(): void {
    this.#storeState = StoreState.UNINITIALIZED;
    this.#paperId = null;
    this.#labs = Immutable.List();
    this.emitChange();
  }

  isUninitialized(): boolean {
    return this.#storeState === StoreState.UNINITIALIZED;
  }

  isLoading(): boolean {
    return this.isUninitialized() || this.#storeState === StoreState.LOADING;
  }

  getPaperId(): Nullable<string> {
    return this.#paperId;
  }

  getLabs(): Immutable.List<PaperLabRecord> {
    return this.#labs;
  }
}
