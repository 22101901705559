import {
  getVisibilityFromJS,
  PaperPdfVisibilityRecord,
} from '@/models/paper-pdf/PaperPdfVisibility';
import { Nullable } from '@/utils/types';
import BaseStore from '@/stores/BaseStore';
import Constants from '@/constants';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import type { ApiRequestStartingPayload } from '@/api/BaseApi';
import type { ApiResponse, PaperPdfVisibilityResponseBody } from '@/api/ApiResponse';
import type S2Dispatcher from '@/utils/S2Dispatcher';

export default class ReaderVisibilityStore extends BaseStore {
  state: StoreStateValue;
  pdfVisibility: Nullable<PaperPdfVisibilityRecord>;

  constructor(dispatcher: S2Dispatcher) {
    super();

    this.state = StoreState.UNINITIALIZED;

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const startingPayload = payload as ApiRequestStartingPayload;
          switch (startingPayload.requestType) {
            case Constants.requestTypes.PDF_VISIBILITY: {
              this.state = StoreState.LOADING;
              this.pdfVisibility = null;
              this.emitChange();
              break;
            }
          }
          break;
        }

        case Constants.actions.API_REQUEST_COMPLETE: {
          const rawResponse = payload as ApiResponse<any>;
          switch (rawResponse.requestType) {
            case Constants.requestTypes.PDF_VISIBILITY: {
              const response = rawResponse as ApiResponse<PaperPdfVisibilityResponseBody>;
              this.state = StoreState.LOADED;
              const { paperId } = response.context;
              const newVisibility = getVisibilityFromJS({ paperId, ...response.resultData });
              if (newVisibility !== this.pdfVisibility) {
                this.pdfVisibility = newVisibility;
                this.emitChange();
              }
              break;
            }
          }
          break;
        }
      }
    });
  }

  isUninitialized(): boolean {
    return this.state === StoreState.UNINITIALIZED;
  }

  isLoading(): boolean {
    return this.isUninitialized() || this.state === StoreState.LOADING;
  }

  getPaperId(): Nullable<string> {
    if (!this.pdfVisibility) {
      return null;
    }
    return this.pdfVisibility.paperId || null;
  }

  getVisibility(): Nullable<PaperPdfVisibilityRecord> {
    return this.pdfVisibility || null;
  }
}
