import {
  getProfilePictureFromGraphQL,
  PROFILE_PICTURE_FRAGMENT,
  PROFILE_PICTURE_FRAGMENT_NAME,
  ProfilePictureFromGraphQL,
  ProfilePictureRecord,
} from '@/models/user/ProfilePicture';

import { gql } from 'graphql-tag';
import Immutable from 'immutable';

import type { Nullable } from '@/utils/types';

export type AuthorCardFromGraphQL = {
  id: number;
  slug: string;
  fullName: string;
  pronouns: Nullable<string>;
  affiliationNames: string[];
  paperCount: number;
  hIndex: number;
  citationCount: number;
  influentialPaperCount: number;
  isClaimed: boolean;
  profilePicture: Nullable<ProfilePictureFromGraphQL>;
};

type Props = {
  id: number;
  slug: string;
  name: string;
  pronouns: Nullable<string>;
  affiliationNames: Immutable.List<string>;
  paperCount: number;
  hIndex: number;
  citationCount: number;
  influentialPaperCount: number;
  isClaimed: boolean;
  profilePicture: Nullable<ProfilePictureRecord>;
};

const defaultProps: Props = {
  id: -1,
  slug: '',
  name: '',
  pronouns: null,
  affiliationNames: Immutable.List(),
  paperCount: 0,
  hIndex: 0,
  citationCount: 0,
  influentialPaperCount: 0,
  isClaimed: false,
  profilePicture: null,
};

export const AuthorCardRecordFactory = Immutable.Record<Props>(defaultProps);

export type AuthorCardRecord = Immutable.RecordOf<Props>;

export function getAuthorCardFromGraphQL(args: AuthorCardFromGraphQL): AuthorCardRecord {
  const { affiliationNames, fullName, profilePicture } = args;
  return AuthorCardRecordFactory({
    ...args,
    name: fullName,
    affiliationNames: Immutable.List(affiliationNames || []),
    profilePicture: profilePicture ? getProfilePictureFromGraphQL(profilePicture) : null,
  });
}

export const AUTHOR_CARD_FRAGMENT_NAME = 'AuthorCardRecordFragment';

export const AUTHOR_CARD_FRAGMENT = gql`
  ${PROFILE_PICTURE_FRAGMENT}

  # some stuff that isn't grahpql

  fragment ${AUTHOR_CARD_FRAGMENT_NAME} on Author {
    id
    slug
    fullName
    affiliationNames
    paperCount
    pronouns
    hIndex
    citationCount
    influentialPaperCount
    isClaimed
    profilePicture {
      ...${PROFILE_PICTURE_FRAGMENT_NAME}
    }
  }
`;
