import { DEPRECATED__FlowOptional, Nullable, ObjectProperties } from '@/utils/types';

import invariant from 'invariant';

export type RoleKey = keyof typeof byKey;
export type RoleValue = (typeof byKey)[keyof typeof byKey];

// Keep in sync with RoleName.scala
export const ADMIN = 'Admin';
export const AUTHOR_CLAIM_MODERATOR = 'AuthorClaimModerator';
export const LOGIN_AS = 'LoginAs';
export const S2_TEAM = 'S2Team';
export const UNSUPPORTED = 'Unsupported';

const byKey = {
  ADMIN,
  AUTHOR_CLAIM_MODERATOR,
  LOGIN_AS,
  S2_TEAM,
} as const;

const byValue = Object.keys(byKey).reduce(
  (memo, key) => {
    const value = byKey[key];
    memo[value] = key;
    return memo;
  },
  {} as Record<ObjectProperties<typeof byKey>, keyof typeof byKey>
);

export default Object.freeze(byKey);

export const RoleByValue = Object.freeze(byValue);

export function optRoleValue(optValue: DEPRECATED__FlowOptional<string>): Nullable<RoleValue> {
  if (!optValue) {
    return null;
  }
  if (optValue in byValue) {
    return optValue as RoleValue;
  }
  return null;
}

export function getRoleValue(value: DEPRECATED__FlowOptional<string>): RoleValue {
  const optValue = optRoleValue(value);
  invariant(optValue, `"${value || 'null'}" is not a valid RoleValue`);
  return optValue;
}
