import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

import type { match } from 'react-router';
import type S2History from '@/utils/S2History';

type Props = {
  history: S2History;
  match: match<any>;
};

export default class DebugPdfRoute extends React.Component<Props> {
  static pageTitle = () => getString(_ => _.general.appName);

  render() {
    const paperId = this.props.match?.params?.paperId || null;
    const queryParams: any = this.props.history.locationQueryParams;
    const chosenLinkHash = (queryParams?.link && parseInt(queryParams.link, 10)) || null;
    return (
      <AsyncLoadedPage
        footer={false}
        compProps={{ paperId, chosenLinkHash }}
        load={{
          importer: () => import(/* webpackChunkName: "shared-DebugPdfPage" */ './DebugPdfPage'),
          chunkName: 'shared-DebugPdfPage',
          moduleId: require.resolveWeak('./DebugPdfPage'),
        }}
      />
    );
  }
}
