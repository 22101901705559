import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

enum VisibleUrlType {
  ARXIV = 'ARXIV',
  S3 = 'S3',
}

export type VisibleUrlFromJS = {
  url: Nullable<string>;
  urlType: Nullable<VisibleUrlType>;
};

type VisibleUrlProps = {
  url: Nullable<string>;
  urlType: Nullable<VisibleUrlType>;
};

const defaultVisibleUrlProps: VisibleUrlProps = {
  url: null,
  urlType: null,
};

export type VisibleUrlRecord = Immutable.RecordOf<VisibleUrlProps>;
export const VisibleUrlFactory = Immutable.Record<VisibleUrlProps>(defaultVisibleUrlProps);

type Props = {
  paperId: string;
  pdfSha: Nullable<{ id: string }>;
  pdfUrl: Nullable<VisibleUrlFromJS>;
  pdfUrlSelfHosted: Nullable<VisibleUrlFromJS>;
};

const defaultProps: Props = {
  paperId: '',
  pdfSha: null,
  pdfUrl: null,
  pdfUrlSelfHosted: null,
};

export const PaperPdfVisibilityFactory = Immutable.Record<Props>(defaultProps);
export type PaperPdfVisibilityRecord = Immutable.RecordOf<Props>;

export type PaperPdfVisibilityFromJS = {
  paperId: string;
  pdfSha: Nullable<{ id: string }>;
  pdfUrl: Nullable<VisibleUrlFromJS>;
  pdfUrlSelfHosted: Nullable<VisibleUrlFromJS>;
};

export function getVisibilityFromJS(args: PaperPdfVisibilityFromJS): PaperPdfVisibilityRecord {
  return PaperPdfVisibilityFactory({ ...args });
}
