import IconArrow from './svgs/IconArrow';
import IconArrowLite from './svgs/IconArrowLite';
import IconArrowUp from './svgs/IconArrowUp';
import IconAuthor from './svgs/IconAuthor';
import IconBeaker from './svgs/IconBeaker';
import IconBrokenLink from './svgs/IconBrokenLink';
import IconChart from './svgs/IconChart';
import IconCheck from './svgs/IconCheck';
import IconCheckInGreenCircle from './svgs/IconCheckInGreenCircle';
import IconCheckmark from './svgs/IconCheckmark';
import IconCirclePlus from './svgs/IconCirclePlus';
import IconCircleX from './svgs/IconCircleX';
import IconCitation from './svgs/IconCitation';
import IconCitedBy from './svgs/IconCitedBy';
import IconCitedByNotFilled from './svgs/IconCitedByNotFilled';
import IconCiteSee from './svgs/IconCiteSee';
import IconClearInput from './svgs/IconClearInput';
import IconClock from './svgs/IconClock';
import IconCognitoLogin from './svgs/IconCognitoLogin';
import IconCopy from './svgs/IconCopy';
import IconCoVizLogo from './svgs/IconCoVizLogo';
import IconDataset from './svgs/IconDataset';
import IconDisclosure from './svgs/IconDisclosure';
import IconEmail from './svgs/IconEmail';
import IconEmotion0 from './svgs/IconEmotion0';
import IconEmotion1 from './svgs/IconEmotion1';
import IconEmotion2 from './svgs/IconEmotion2';
import IconEmotion3 from './svgs/IconEmotion3';
import IconEmotion4 from './svgs/IconEmotion4';
import IconEmptyFolder from './svgs/IconEmptyFolder';
import IconEmptyLibrary from './svgs/IconEmptyLibrary';
import IconEntity from './svgs/IconEntity';
import IconEntityLink from './svgs/IconEntityLink';
import IconEnvelope from './svgs/IconEnvelope';
import IconExcerpt from './svgs/IconExcerpt';
import IconExpandArrow from './svgs/IconExpandArrow';
import IconExport from './svgs/IconExport';
import IconExportPaper from './svgs/IconExportPaper';
import IconEye from './svgs/IconEye';
import IconEyeSlash from './svgs/IconEyeSlash';
import IconFaBell from './svgs/IconFaBell';
import IconFaBellNotFilled from './svgs/IconFaBellNotFilled';
import IconFaBookmark from './svgs/IconFaBookmark';
import IconFaBookmarkNotFilled from './svgs/IconFaBookmarkNotFilled';
import IconFacebook from './svgs/IconFacebook';
import IconFacebookLogin from './svgs/IconFacebookLogin';
import IconFaCheck from './svgs/IconFaCheck';
import IconFaCompass from './svgs/IconFaCompass';
import IconFaFileDownload from './svgs/IconFaFileDownload';
import IconFaGlobe from './svgs/IconFaGlobe';
import IconFaHand from './svgs/IconFaHand';
import IconFaHorizontalRule from './svgs/IconFaHorizontalRule';
import IconFaInbox from './svgs/IconFaInbox';
import IconFaLink from './svgs/IconFaLink';
import IconFaLinkBox from './svgs/IconFaLinkBox';
import IconFaLinkBroken from './svgs/IconFaLinkBroken';
import IconFaLinkOut from './svgs/IconFaLinkOut';
import IconFaNote from './svgs/IconFaNote';
import IconFaPage from './svgs/IconFaPage';
import IconFaPDF from './svgs/IconFaPDF';
import IconFaPen from './svgs/IconFaPen';
import IconFaPrint from './svgs/IconFaPrint';
import IconFaQuote from './svgs/IconFaQuote';
import IconFaUnlock from './svgs/IconFaUnlock';
import IconFeedExplanation from './svgs/IconFeedExplanation';
import IconFeedList from './svgs/IconFeedList';
import IconFilter from './svgs/IconFilter';
import IconFlag from './svgs/IconFlag';
import IconFolderAdd from './svgs/IconFolderAdd';
import IconFolderClosed from './svgs/IconFolderClosed';
import IconFolderNotFound from './svgs/IconFolderNotFound';
import IconFolderOpened from './svgs/IconFolderOpened';
import IconGear from './svgs/IconGear';
import IconGetFtr from './svgs/IconGetFtr';
import IconGlobe from './svgs/IconGlobe';
import IconGoogleLogin from './svgs/IconGoogleLogin';
import IconHamburger from './svgs/IconHamburger';
import IconHelp from './svgs/IconHelp';
import IconHighlighter from './svgs/IconHighlighter';
import IconHorizontalResizeHandle from './svgs/IconHorizontalResizeHandle';
import IconInformation from './svgs/IconInformation';
import IconLeaf from './svgs/IconLeaf';
import IconLibraryEntryRemove from './svgs/IconLibraryEntryRemove';
import IconLibraryExplanation from './svgs/IconLibraryExplanation';
import IconLightningBolt from './svgs/IconLightningBolt';
import IconMic from './svgs/IconMic';
import IconMinus from './svgs/IconMinus';
import IconModerationFlag from './svgs/IconModerationFlag';
import IconMsgError from './svgs/IconMsgError';
import IconNotRelevant from './svgs/IconNotRelevant';
import IconOpaqueEmptyFolder from './svgs/IconOpaqueEmptyFolder';
import IconOpaqueEmptyLibrary from './svgs/IconOpaqueEmptyLibrary';
import IconOpenAccess from './svgs/IconOpenAccess';
import IconOpenathensLogin from './svgs/IconOpenathensLogin';
import IconOrcidId from './svgs/IconOrcidId';
import IconPaper from './svgs/IconPaper';
import IconPaperMagnifyingGlass from './svgs/IconPaperMagnifyingGlass';
import IconPaperQuestionMark from './svgs/IconPaperQuestionMark';
import IconPaperSearch from './svgs/IconPaperSearch';
import IconPdf from './svgs/IconPdf';
import IconPencil from './svgs/IconPencil';
import IconPersonalizationSmall from './svgs/IconPersonalizationSmall';
import IconPlus from './svgs/IconPlus';
import IconPublicFolderClosed from './svgs/IconPublicFolderClosed';
import IconPublicFolderOpened from './svgs/IconPublicFolderOpened';
import IconReadingList from './svgs/IconReadingList';
import IconRemoveBookmark from './svgs/IconRemoveBookmark';
import IconRemoveX from './svgs/IconRemoveX';
import IconResearchDashboard from './svgs/IconResearchDashboard';
import IconResearchDashboardExplanation from './svgs/IconResearchDashboardExplanation';
import IconResearchPapers from './svgs/IconResearchPapers';
import IconScorecardHighlyInfluential from './svgs/IconScorecardHighlyInfluential';
import IconSearch from './svgs/IconSearch';
import IconSearchSmall from './svgs/IconSearchSmall';
import IconSemanticReader from './svgs/IconSemanticReader';
import IconSemanticReaderHeader from './svgs/IconSemanticReaderHeader';
import IconSettings from './svgs/IconSettings';
import IconShare from './svgs/IconShare';
import IconShareEmail from './svgs/IconShareEmail';
import IconShareFacebook from './svgs/IconShareFacebook';
import IconShareTwitter from './svgs/IconShareTwitter';
import IconSignOut from './svgs/IconSignOut';
import IconSlides from './svgs/IconSlides';
import IconSmallX from './svgs/IconSmallX';
import IconSprinkles from './svgs/IconSprinkles';
import IconSquareMinus from './svgs/IconSquareMinus';
import IconSquarePlus from './svgs/IconSquarePlus';
import IconStatusApproved from './svgs/IconStatusApproved';
import IconStatusWaiting from './svgs/IconStatusWaiting';
import IconStatusWarning from './svgs/IconStatusWarning';
import IconTabPapers from './svgs/IconTabPapers';
import IconTag from './svgs/IconTag';
import IconTagStack from './svgs/IconTagStack';
import IconTermUnderstanding from './svgs/IconTermUnderstanding';
import IconTheorems from './svgs/IconTheorems';
import IconThumbsDown from './svgs/IconThumbsDown';
import IconThumbsDownActive from './svgs/IconThumbsDownActive';
import IconThumbsUp from './svgs/IconThumbsUp';
import IconThumbsUpActive from './svgs/IconThumbsUpActive';
import IconTrendingDown from './svgs/IconTrendingDown';
import IconTrendingNeutral from './svgs/IconTrendingNeutral';
import IconTrendingUp from './svgs/IconTrendingUp';
import IconTrophy from './svgs/IconTrophy';
import IconTwitter from './svgs/IconTwitter';
import IconTwitterLogin from './svgs/IconTwitterLogin';
import IconUser from './svgs/IconUser';
import IconUserWithCheckmark from './svgs/IconUserWithCheckmark';
import IconUserWithPencil from './svgs/IconUserWithPencil';
import IconVenue from './svgs/IconVenue';
import IconViewDense from './svgs/IconViewDense';
import IconViewRegular from './svgs/IconViewRegular';
import IconX from './svgs/IconX';

import AppContext, { mapAppContextToProps, useStateFromStore } from '@/AppContext';
import softError from '@/utils/softError';

import Immutable from 'immutable';
import React from 'react';

export type IconId = keyof typeof iconIdToIconMap;

type PropsFromAppContext = {
  activeIconIds: Immutable.List<IconId>;
};

type Props = object & PropsFromAppContext;

// Using a constant makes the re-render more efficient
const SVG_STYLE = {
  display: 'none',
};

const loggedIconErrors = new Set();

export class IconSprite extends React.PureComponent<Props> {
  render(): React.ReactNode {
    const { activeIconIds } = this.props;
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={SVG_STYLE}>
        {activeIconIds.map(iconId => {
          const Comp = iconIdToIconMap[iconId];
          if (!Comp) {
            if (!loggedIconErrors.has(iconId)) {
              loggedIconErrors.add(iconId);
              softError('iconSprite', `failed to render icon "${iconId}"`);
            }
            return null;
          }
          return <Comp id={iconId} key={iconId} />;
        })}
      </svg>
    );
  }
}

/***** Import comp above, id below *****/

const iconIdToIconMap = {
  arrow: IconArrow,
  'arrow-lite': IconArrowLite,
  'arrow-up': IconArrowUp,
  author: IconAuthor,
  beaker: IconBeaker,
  'broken-link': IconBrokenLink,
  checkmark: IconCheckmark,
  chart: IconChart,
  clock: IconClock,
  check: IconCheck,
  'check-in-green-circle': IconCheckInGreenCircle,
  'circle-plus': IconCirclePlus,
  'circle-x': IconCircleX,
  citation: IconCitation,
  citesee: IconCiteSee,
  'cited-by': IconCitedBy,
  'cited-by-not-filled': IconCitedByNotFilled,
  'clear-input': IconClearInput,
  'cognito-login': IconCognitoLogin,
  copy: IconCopy,
  'coviz-logo': IconCoVizLogo,
  dataset: IconDataset,
  disclosure: IconDisclosure,
  email: IconEmail, // TODO: (#28541) Remove icons which are not used
  'emotion-0': IconEmotion0,
  'emotion-1': IconEmotion1,
  'emotion-2': IconEmotion2,
  'emotion-3': IconEmotion3,
  'emotion-4': IconEmotion4,
  'empty-folder': IconEmptyFolder,
  'empty-library': IconEmptyLibrary,
  entity: IconEntity,
  'entity-link': IconEntityLink,
  envelope: IconEnvelope,
  excerpt: IconExcerpt,
  'expand-arrow': IconExpandArrow,
  eye: IconEye,
  export: IconExport,
  'export-paper': IconExportPaper,
  'eye-slash': IconEyeSlash,
  'fa-bell': IconFaBell,
  'fa-bell-not-filled': IconFaBellNotFilled,
  'fa-bookmark-not-filled': IconFaBookmarkNotFilled,
  'fa-bookmark': IconFaBookmark,
  'fa-check': IconFaCheck,
  'fa-compass': IconFaCompass,
  'fa-file-download': IconFaFileDownload,
  'fa-hand': IconFaHand,
  'fa-horizontal-rule': IconFaHorizontalRule,
  'fa-inbox': IconFaInbox,
  'fa-link': IconFaLink,
  'fa-link-box': IconFaLinkBox,
  'fa-link-broken': IconFaLinkBroken,
  'fa-link-out': IconFaLinkOut,
  'fa-note': IconFaNote,
  'fa-page': IconFaPage,
  'fa-pdf': IconFaPDF,
  'fa-pen': IconFaPen,
  'fa-print': IconFaPrint,
  'fa-quote': IconFaQuote,
  'fa-unlock': IconFaUnlock,
  'fa-globe': IconFaGlobe,
  facebook: IconFacebook,
  'facebook-login': IconFacebookLogin,
  'feed-explanation': IconFeedExplanation,
  'feed-list': IconFeedList,
  filter: IconFilter,
  flag: IconFlag, // TODO: (#28541) Remove icons which are not used
  'folder-add': IconFolderAdd,
  'folder-closed': IconFolderClosed,
  'folder-opened': IconFolderOpened,
  'folder-not-found': IconFolderNotFound,
  gear: IconGear,
  getftr: IconGetFtr,
  globe: IconGlobe,
  'google-login': IconGoogleLogin,
  hamburger: IconHamburger,
  help: IconHelp,
  highlighter: IconHighlighter,
  'horizontal-resize-handle': IconHorizontalResizeHandle,
  information: IconInformation,
  leaf: IconLeaf,
  'library-entry-remove': IconLibraryEntryRemove,
  'library-explanation': IconLibraryExplanation,
  'lightning-bolt': IconLightningBolt,
  mic: IconMic,
  minus: IconMinus,
  'moderation-flag': IconModerationFlag,
  'msg-error': IconMsgError,
  'not-relevant': IconNotRelevant,
  'orcid-id': IconOrcidId,
  'opaque-empty-folder': IconOpaqueEmptyFolder,
  'opaque-empty-library': IconOpaqueEmptyLibrary,
  'openathens-login': IconOpenathensLogin,
  'open-access': IconOpenAccess,
  paper: IconPaper,
  'paper-magnifying-glass': IconPaperMagnifyingGlass,
  'paper-question-mark': IconPaperQuestionMark,
  'paper-search': IconPaperSearch,
  pencil: IconPencil,
  'personalization-small': IconPersonalizationSmall,
  pdf: IconPdf,
  plus: IconPlus,
  'public-folder-closed': IconPublicFolderClosed,
  'public-folder-opened': IconPublicFolderOpened,
  'reading-list': IconReadingList,
  'remove-bookmark': IconRemoveBookmark,
  'remove-x': IconRemoveX,
  'research-dashboard': IconResearchDashboard,
  'research-dashboard-explanation': IconResearchDashboardExplanation,
  'research-papers': IconResearchPapers,
  'scorecard-highly-influential': IconScorecardHighlyInfluential,
  search: IconSearch, // TODO: (#28541) Remove icons which are not used
  'search-small': IconSearchSmall,
  'semantic-reader': IconSemanticReader,
  'semantic-reader-header': IconSemanticReaderHeader,
  settings: IconSettings,
  slides: IconSlides, // TODO: (#28541) Remove icons which are not used
  share: IconShare,
  'share-email': IconShareEmail,
  'share-facebook': IconShareFacebook,
  'share-twitter': IconShareTwitter,
  'sign-out': IconSignOut,
  'small-x': IconSmallX,
  sprinkles: IconSprinkles,
  'square-minus': IconSquareMinus,
  'square-plus': IconSquarePlus,
  'status-approved': IconStatusApproved,
  'status-warning': IconStatusWarning,
  'status-waiting': IconStatusWaiting,
  'tab-papers': IconTabPapers,
  tag: IconTag, // TODO: (#28541) Remove icons which are not used
  'tag-stack': IconTagStack,
  'term-understanding': IconTermUnderstanding,
  theorems: IconTheorems,
  'thumbs-up': IconThumbsUp,
  'thumbs-down': IconThumbsDown,
  'thumbs-up-active': IconThumbsUpActive,
  'thumbs-down-active': IconThumbsDownActive,
  'trending-down': IconTrendingDown,
  'trending-neutral': IconTrendingNeutral,
  'trending-up': IconTrendingUp,
  trophy: IconTrophy,
  twitter: IconTwitter,
  'twitter-login': IconTwitterLogin,
  user: IconUser,
  'user-with-pencil': IconUserWithPencil,
  'user-with-checkmark': IconUserWithCheckmark,
  venue: IconVenue,
  'view-dense': IconViewDense,
  'view-regular': IconViewRegular,
  x: IconX,
} as const;

export const iconIdToIconCompMap = Immutable.Map(iconIdToIconMap);

export default mapAppContextToProps<Props, PropsFromAppContext>(
  IconSprite,
  (appContext: AppContext) => {
    // Sorting prevents issue with confused shadow dom
    const sortByAlpha = (a: IconId, b: IconId) => (a === b ? 0 : a > b ? 1 : -1);
    const iconStoreProps = useStateFromStore(appContext.iconStore, _ => ({
      activeIconIds: _.getActiveIconIds().sort(sortByAlpha),
    }));

    return {
      ...iconStoreProps,
    };
  }
);
