import { Nullable } from '@/utils/types';

export type InitialDirectives = {
  shouldArchive?: Nullable<boolean>;
  shouldFollow?: Nullable<boolean>;
  shouldIndex?: Nullable<boolean>;
};

export default class RobotDirectives {
  #initial: InitialDirectives;
  #shouldArchive: boolean;
  #shouldFollow: boolean;
  #shouldIndex: boolean;

  constructor(initial: InitialDirectives = {}) {
    this.#initial = initial;
    this.reset();
  }

  get shouldArchive(): boolean {
    return this.#shouldArchive;
  }

  set shouldArchive(bool: boolean) {
    this.#shouldArchive = !!bool;
  }

  get shouldFollow(): boolean {
    return this.#shouldFollow;
  }

  set shouldFollow(bool: boolean) {
    this.#shouldFollow = !!bool;
  }

  get shouldIndex(): boolean {
    return this.#shouldIndex;
  }

  set shouldIndex(bool: boolean) {
    this.#shouldIndex = !!bool;
  }

  // Put directives back to where they were initially
  reset(): void {
    this.removeAllDirectives();
    if ('shouldArchive' in this.#initial) {
      this.#shouldArchive = !!this.#initial.shouldArchive;
    }
    if ('shouldFollow' in this.#initial) {
      this.#shouldFollow = !!this.#initial.shouldFollow;
    }
    if ('shouldIndex' in this.#initial) {
      this.#shouldIndex = !!this.#initial.shouldIndex;
    }
  }

  // Allow robots to do anything
  removeAllDirectives(): void {
    this.#shouldArchive = true;
    this.#shouldFollow = true;
    this.#shouldIndex = true;
  }

  getDirectives(): Nullable<string> {
    // Docs: https://developers.google.com/search/reference/robots_meta_tag
    const directives: string[] = [];
    if (!this.#shouldArchive) {
      directives.push('noarchive');
    }
    if (!this.#shouldIndex && !this.#shouldFollow) {
      directives.push('none');
    } else {
      if (!this.#shouldFollow) {
        directives.push('nofollow');
      }
      if (!this.#shouldIndex) {
        directives.push('noindex');
      }
    }
    if (directives.length === 0) {
      return null;
    }
    return directives.join(',');
  }
}
