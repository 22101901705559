import * as format from '@/format';
import { getString } from '@/content/i18n';
import { makePath } from '@/router/Routes';
import {
  paperDetailHasFigure,
  paperDetailHasFigures,
  paperDetailHasTitle,
} from '@/models/PaperDetail';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

export default class PaperDetailFigureRoute extends React.Component {
  static getPageTitle(appContext) {
    const paperDetails = appContext.paperStore.getPaperDetail();

    if (
      paperDetailHasTitle(paperDetails) &&
      paperDetailHasFigures(paperDetails) &&
      paperDetailHasFigure(paperDetails, appContext.paperStore.focusedFigureIndex || 0)
    ) {
      return format.figureDetailPageTitle(
        paperDetails.paper.title.text,
        paperDetails.figures.get(appContext.paperStore.focusedFigureIndex || 0)
      );
    }

    return getString(_ => _.paperDetail.figure.fallbackPageTitle);
  }

  static getPageMetaDescription(appContext) {
    const paperDetail = appContext.paperStore.getPaperDetail();
    const figure = paperDetail.figures.get(appContext.paperStore.focusedFigureIndex || 0);
    const paperTitle = paperDetail.paper.title.text;
    return getString(_ => _.metaDescription.paperFigure.content, figure.caption, paperTitle);
  }

  static getPageFigure(appContext) {
    const paperDetail = appContext.paperStore.getPaperDetail();
    const figure = paperDetail.figures.get(appContext.paperStore.focusedFigureIndex || 0);
    return figure;
  }

  static getCanonicalUrl(routerState) {
    if (routerState.params.slug) {
      const { figureIndex, paperId, slug } = routerState.params;
      return makePath({ routeName: 'PAPER_DETAIL_FIGURE', params: { figureIndex, paperId, slug } });
    }

    return null;
  }

  render() {
    return (
      <AsyncLoadedPage
        showLoadingPage={false}
        load={{
          importer: () =>
            import(
              /* webpackChunkName: "shared-PaperDetailFigurePage" */ './PaperDetailFigurePage'
            ),
          chunkName: 'shared-PaperDetailFigurePage',
          moduleId: require.resolveWeak('./PaperDetailFigurePage'),
        }}
      />
    );
  }
}
