// This should be kept in sync with a corresponding scala-side model LibraryEntry.AnnotationState
import { Nullable, ValueOf } from '@/utils/types';

import invariant from 'invariant';

export const NotRelevant = 'NotRelevant';
export const Hidden = 'Hidden';
export const Unsupported = 'Unsupported';

const byKey = {
  NotRelevant,
  Hidden,
  Unsupported,
} as const;

export const LibraryEntryAnnotationStateMap = Object.freeze(byKey);

export type LibraryEntryAnnotationState = ValueOf<typeof byKey>;

export function getOptLibraryEntryAnnotationState(
  optState?: Nullable<string>
): Nullable<LibraryEntryAnnotationState> {
  if (optState && optState in byKey) {
    return byKey[optState];
  }
  return null;
}

export function getLibraryEntryAnnotationState(
  optState?: Nullable<string>
): LibraryEntryAnnotationState {
  const expState = getOptLibraryEntryAnnotationState(optState);
  invariant(expState, `"${optState || ''}" is not a valid LibraryEntryAnnotationState`);
  return expState;
}
