import React from 'react';

export default function IconEmotion4(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 36 36">
      <path
        id="rate-5"
        d="M36,18c0,9.95-8.06,18-18,18C8.06,36,0,27.94,0,18C0,8.05,8.06,0,18,0C27.94,0,36,8.06,36,18z M28.44,24 c0.14-0.86-0.68-1.57-1.5-1.3c-2.19,0.71-5.45,1.1-8.93,1.1c-3.48,0-6.74-0.4-8.93-1.1c-0.83-0.27-1.65,0.44-1.5,1.3 c0.67,3.99,6.04,6.77,10.44,6.77C22.4,30.77,27.76,27.99,28.44,24z M9.87,14.52c0,1.28,1.04,2.32,2.32,2.32s2.32-1.04,2.32-2.32 c0-1.28-1.04-2.32-2.32-2.32C10.91,12.19,9.87,13.23,9.87,14.52z M21.48,14.52c0,1.28,1.04,2.32,2.32,2.32 c1.28,0,2.32-1.04,2.32-2.32c0-1.28-1.04-2.32-2.32-2.32C22.52,12.19,21.48,13.23,21.48,14.52z"
      />
    </symbol>
  );
}
