/* eslint-disable react/prop-types */

import Icon from '@/components/shared/icon/Icon';

import React from 'react';

export default function VerifiedCheckmark({ sizePx }) {
  return (
    <span className="verified-checkmark" style={{ width: sizePx ? sizePx + 'px' : '100%' }}>
      <div className="verified-checkmark__circle">
        <Icon icon="checkmark" />
      </div>
    </span>
  );
}
