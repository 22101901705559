import MockPaper from '../../../../../test/utils/MockPaper';

import { getHighlightedFieldFromJS } from '@/models/HighlightedField';
import { PaperishRecord } from '@/models/Paper';
import { ReactNodeish } from '@/utils/types';

import classnames from 'classnames';
import Immutable from 'immutable';
import React from 'react';

const MAX_FIELDS_TO_DISPLAY = 2;

export default function CLPaperFoS({
  paper,
  className,
  heapId,
}: {
  paper: PaperishRecord;
  className?: string;
  heapId?: string;
}): ReactNodeish {
  const { fieldsOfStudy } = paper;

  if (fieldsOfStudy.isEmpty()) {
    return null;
  }

  const firstTwo = fieldsOfStudy.take(MAX_FIELDS_TO_DISPLAY);

  return (
    <span className={classnames('cl-paper-fos', className)} data-heap-id={heapId}>
      {firstTwo.join(', ')}
    </span>
  );
}

// TODO(#21359): Split this into a separate file
const PAPER_NO_FOS = MockPaper({
  title: getHighlightedFieldFromJS({ text: 'A Paper with No Fields of Study' }),
  fieldsOfStudy: Immutable.List(),
});
const PAPER_1_FOS = MockPaper({
  title: getHighlightedFieldFromJS({ text: 'A Paper with One Field of Study' }),
  fieldsOfStudy: Immutable.List(['Biology']),
});
const PAPER_2_FOS = MockPaper({
  title: getHighlightedFieldFromJS({ text: 'A Paper with Two Fields of Study' }),
  fieldsOfStudy: Immutable.List(['Biology', 'Computer Science']),
});
const PAPER_3_FOS = MockPaper({
  title: getHighlightedFieldFromJS({ text: 'A Paper with Three Fields of Study' }),
  fieldsOfStudy: Immutable.List(['Biology', 'Computer Science', 'Paleontology']),
});

export const exampleConfig = {
  getComponent: () => CLPaperFoS,
  fields: [
    {
      name: 'className',
      desc: 'HTML classes to be added to the component',
      value: {
        type: 'text',
        default: '',
      },
    },
  ],

  examples: [
    {
      title: '0 Fields of Study',
      desc: 'Data provided by a PaperRecord with 0 fields of study',
      props: {
        paper: PAPER_NO_FOS,
      },
      render: comp => <div style={{ width: '300px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: '1 Field of Study',
      desc: 'Data provided by a PaperRecord with 1 field of study',
      props: {
        paper: PAPER_1_FOS,
      },
      render: comp => <div style={{ width: '300px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: '2 Fields of Study',
      desc: 'Data provided by a PaperRecord with 2 fields of study',
      props: {
        paper: PAPER_2_FOS,
      },
      render: comp => <div style={{ width: '300px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: '3 Fields of Study',
      desc: 'Data provided by a PaperRecord with 3 fields of study. Only two should be displayed.',
      props: {
        paper: PAPER_3_FOS,
      },
      render: comp => <div style={{ width: '300px', padding: '16px' }}>{comp}</div>,
    },
  ],

  events: {},
};
