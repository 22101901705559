import { ADMIN } from '@/constants/Role';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

export default class AdminManageRolesRoute extends React.Component {
  static requiresRoleFrom = () => [ADMIN];

  render() {
    return (
      <AsyncLoadedPage
        showLoadingPage={false}
        load={{
          importer: () =>
            import(/* webpackChunkName: "desktop-AdminManageRolesPage" */ './AdminManageRolesPage'),
          chunkName: 'desktop-AdminManageRolesPage',
          moduleId: require.resolveWeak('./AdminManageRolesPage'),
        }}
      />
    );
  }
}
