import { getCitation } from '@/utils/citation-util';
import { getPaperFromJS } from '@/models/Paper';
import { getPluralizedString, getString } from '@/content/i18n';
import { ModalId } from '@/constants/Modal';
import Api from '@/api/Api';
import BrowserUtil from '@/browser';
import CitationStyle from '@/constants/citation-style';
import CLButton, { TYPE } from '@/components/library/button/CLButton';
import CLModal from '@/components/library/modal/CLModal';
import DownloadCitationLink from '@/components/desktop/common/paper/DownloadCitationLink';
import Icon from '@/components/shared/icon/Icon';
import LoadingIndicator from '@/components/shared/loading/LoadingIndicator';

import idx from 'idx';
import PropTypes from 'prop-types';
import React from 'react';

// defines which citation styles are available as file downloads vs being displayed
// in plain text for the user to copy.
const COPY_CITATION_STYLES = [
  CitationStyle.BIBTEX,
  CitationStyle.MLA,
  CitationStyle.APA,
  CitationStyle.CHICAGO,
];
const DOWNLOAD_CITATION_STYLES = [CitationStyle.BIBTEX, CitationStyle.ENDNOTE];

export default class CiteModal extends React.PureComponent {
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      isLoaded: false,
      currentCitationCopied: null,
      copied: '',
      totalFailedPapers: 0,
      papers: [],
    };
  }

  componentDidMount() {
    this.fetchPapers();
  }

  fetchPapers = async () => {
    const { api } = this.context;
    const { paperIds } = this.props.data;
    const res = await api.fetchPapersByIds({
      paperIds: paperIds.toArray(),
      model: 'Paper',
    });

    const papers = idx(res, _ => _.resultData.papers) || [];
    const failedPaperIds = idx(res, _ => _.resultData.failedPaperIds) || [];

    this.setState({
      isLoaded: true,
      totalFailedPapers: failedPaperIds.length,
      papers: papers.map(paperData => getPaperFromJS(paperData)),
    });
  };

  copyCitationToClipboard = citationStyle => {
    const { papers } = this.state;
    this.setState({
      currentCitationCopied: citationStyle,
    });
    const citations = papers.map(paper => getCitation(paper, citationStyle)).join('\n\n');
    BrowserUtil.copyToClipboard(citations);
  };

  renderCitationButtons() {
    const { papers, currentCitationCopied } = this.state;
    return (
      <div className="export-modal__content">
        <div className="export-modal__item">
          <h4 className="export-modal__item-label">{getString(_ => _.modal.export.copyTitle)}</h4>
          <div className="export-modal__item-buttons">
            {COPY_CITATION_STYLES.map(citationStyle => (
              <CLButton
                className="export-modal__item-button"
                key={citationStyle}
                type={TYPE.PRIMARY}
                label={
                  currentCitationCopied === citationStyle
                    ? getString(_ => _.export.copyButtonText)
                    : getString(_ => _.modal.export.citationStyleLabel[citationStyle])
                }
                onClick={() => this.copyCitationToClipboard(citationStyle)}
                testId="export-modal-copy-citation-button"
              />
            ))}
          </div>
        </div>
        <div className="export-modal__item">
          <h4 className="export-modal__item-label">
            {getString(_ => _.modal.export.downloadTitle)}
          </h4>
          <div className="export-modal__item-buttons">
            {DOWNLOAD_CITATION_STYLES.map(citationStyle => (
              <DownloadCitationLink
                papers={papers}
                citationStyle={citationStyle}
                key={citationStyle}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { isLoaded, totalFailedPapers } = this.state;
    const {
      data: { paperIds },
    } = this.props;

    return (
      <CLModal className="export-modal" modalId={ModalId.EXPORT}>
        <div className="export-modal__header" data-test-id="export-modal-header">
          <h2 className="export-modal__header-text">
            {getPluralizedString(_ => _.modal.export.exportCountLabel, paperIds.size)}
          </h2>
          {isLoaded && totalFailedPapers > 0 && (
            <span className="export-modal__error" data-test-id="export-modal-error">
              <Icon
                className="export-modal__error-icon"
                icon="status-warning"
                width="14"
                height="14"
              />
              {getPluralizedString(_ => _.modal.export.exportErrorMessage, totalFailedPapers)}
            </span>
          )}
        </div>
        {isLoaded ? (
          this.renderCitationButtons()
        ) : (
          <LoadingIndicator className="export-modal__loading-indicator" />
        )}
      </CLModal>
    );
  }
}
