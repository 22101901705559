/* eslint-disable import/named */

import { DublinCoreMeta, WillRouteToResult } from './Route';
import { getRouteNameForPath, makePath, PathParams, RouteName } from './Routes';
import RobotDirectives from './RobotDirectives';

import { getString } from '@/content/i18n';
import { Nullable, TODO } from '@/utils/types';
import { RoleValue } from '@/constants/Role';
import AppContext from '@/AppContext';
import HttpStatusMeta from '@/constants/HttpStatusMeta';
import OpenGraph from '@/constants/OpenGraph';
import schema from '@/utils/routing/schema';

import { History, Location, LocationDescriptorObject } from 'history';
import { match, matchPath } from 'react-router';
import { matchRoutes, RouteConfig } from 'react-router-config';
import escape from 'escape-html';
import invariant from 'invariant';
import qs from 'qs';

type TODO__Existing = TODO<'Existing TODO'>;

// This is the router that in the context object
export type RouterContext = {
  history: History;
  route: {
    location: Location;
    match: match<any>;
  };
};

export type ActiveRoute = {
  match: match<any>;
  route: TODO__Existing;
};

export type RouterState = {
  params: { [key: string]: string };
  path: string;
  query: { [key: string]: Nullable<string> };
  url: string;
  routes: TODO__Existing[];
};

// Object used to create a route with mkRouterObj()
export type RawRouterObject = {
  path?: Nullable<string>;
  routeName?: Nullable<RouteName>;
  params?: Nullable<PathParams>;
  query?: Nullable<object>;
};

export type HttpStatus = {
  status: number;
};

/**
 * Parses a search string into an object.
 *
 * @param {string} searchStr - The search string to be parsed.
 * @return {object} - The parsed search string.
 */
export function searchStringToObj(searchStr?: Nullable<string>): any {
  if (!searchStr) {
    return {};
  }
  if (searchStr[0] === '?') {
    // The search string includes the ?, but qs.parse() expects `key=value`, so if there is a
    // query string, we slice off the question mark.
    searchStr = searchStr.slice(1);
  }
  return qs.parse(searchStr);
}

/**
 * Removes falsy values from a query object, excluding 0 and false, and stringifys the resulting
 * object to a query string.
 *
 * @param query {object} - the query object to be cleaned and stringified.
 * @return {string} - the stringified query object.
 */
export function cleanAndStringifyQuery(query: object): string {
  const cleanedQuery = Object.keys(query).reduce((newQuery, key) => {
    const currVal = query[key];

    if (
      (currVal || currVal === 0 || currVal === false) && // 0 and false are ok
      (!Array.isArray(currVal) || currVal.length !== 0) && // non-empty array
      (typeof currVal !== 'object' ||
        currVal.constructor !== Object ||
        Object.keys(currVal).length !== 0) // non-empty object
    ) {
      newQuery[key] = currVal;
    }

    return newQuery;
  }, {});

  return qs.stringify(cleanedQuery);
}

/**
 * Send all api requests for the active routes.
 *
 * @param {Object[]} activeRoutes - the current router state
 * @param {AppContext} appContext - global context for the app
 * @param {Promise[] = []} extraRequests - optional extra requests. Defaults to an empty array.
 * @return {Promise[]}
 */
export function runActiveWillRouteTos({
  activeRoutes,
  appContext,
  extraRequests = [],
}: {
  activeRoutes: ActiveRoute[];
  appContext: AppContext;
  extraRequests?: Promise<WillRouteToResult>[];
}): Promise<WillRouteToResult>[] {
  // Use the child route's matched path, which has the most data about the current url
  const { match } = activeRoutes[activeRoutes.length - 1];
  return activeRoutes.reduce((requests, { route }) => {
    if (route.willRouteTo) {
      requests.push(route.willRouteTo(appContext, match));
    }
    return requests;
  }, extraRequests);
}

/**
 * Gets the active routes for the given url.
 *
 * @param {Route[]} routes - application route configuration
 * @param {String} pathname - the requested path
 * @param {Object} query - parsed query string as an object
 * @return {ReactRouter.Match[]} - contains information about how a <Route path> matched the URL
 *                                 with parsed query parameters:
 *                                 { params, isExact, path, url, query }
 */
export function getActiveRoutes({
  routes,
  pathname,
  query,
}: {
  routes: RouteConfig[];
  pathname: string;
  query: object;
}): ActiveRoute[] {
  invariant(routes, `"routes" is missing from arguments in routerUtils.getActiveRoutes()`);
  invariant(routes.length > 0, `"routes" is an empty array in routerUtils.getActiveRoutes()`);
  return (
    matchRoutes(routes, pathname)
      // shim query string parameters into react router's match object.
      // see: https://github.com/ReactTraining/react-router/issues/4410
      .map(({ route, match }) => {
        // @ts-expect-error we're adding a "query" field to match here. This is odd.
        // see: https://github.com/allenai/scholar/issues/36107
        match.query = query;

        // Decoding must happen after match, to prevent decoded forward
        // slashes from interfering with route parameter extraction,
        // see: https://github.com/allenai/scholar/issues/9018
        Object.keys(match.params).forEach(key => {
          match.params[key] = decodeURIComponent(match.params[key]);
        });

        return { route, match };
      })
  );
}

export function collectRobotDirectives({
  activeRoutes,
  appContext,
}: {
  activeRoutes: ActiveRoute[];
  appContext: AppContext;
}): Nullable<string> {
  const robots = new RobotDirectives({ shouldArchive: false });
  for (const { route } of activeRoutes) {
    if (route.getRobotDirectives) {
      route.getRobotDirectives(robots, appContext);
    }
  }
  return robots.getDirectives();
}

/**
 * Determine whether the current page requires authentication
 *
 * @param {Object[]} activeRoutes - the current router state
 * @return {boolean} true if the page requires authentication
 */
export function pageRequiresAuth(activeRoutes: ActiveRoute[]): boolean {
  if (activeRoutes.some(({ route }) => route.requiresRoles)) {
    // requiresRoles() overrides requiresAuthentication()
    return true;
  }
  return activeRoutes.some(
    ({ route }) => route.requiresAuthentication && route.requiresAuthentication()
  );
}

/**
 * Determine what roles are needed to render a page (intersection of roles)
 */
export function getRequiredRoles(activeRoutes: ActiveRoute[]): Nullable<RoleValue[]> {
  if (!activeRoutes.some(_ => _.route.requiresRoles)) {
    // No routes require roles
    return null;
  }

  // Build a set of all roles for all routes
  let roles = new Set<RoleValue>(
    activeRoutes.reduce(
      (memo, activeRoute) =>
        activeRoute.route.requiresRoles ? memo.concat(activeRoute.route.requiresRoles()) : memo,
      []
    )
  );

  // Filter roles to what is included in each one, for routes that set them
  for (const activeRoute of activeRoutes) {
    if (activeRoute.route.requiresRoles) {
      roles = new Set(activeRoute.route.requiresRoles().filter(role => roles.has(role)));
    }
  }

  return [...roles];
}

/**
 * Gets the page title
 * @param {Object[]} activeRoutes - the current router state
 * @param {AppContext} appContext - global context for the app
 * @param {Location} location - the location information about the current path
 * @return {String} the page title
 */
export function getPageTitle({
  activeRoutes,
  appContext,
  location,
}: {
  activeRoutes: ActiveRoute[];
  appContext: AppContext;
  location: Location;
}): string {
  const DEFAULT_TITLE = 'Semantic Scholar';
  const PATTERN_HTML_TAGS = /[<>]/g;
  const routesWithGetPageTitle = activeRoutes.filter(({ route }) => route.getPageTitle);
  if (routesWithGetPageTitle.length > 0) {
    const { route } = routesWithGetPageTitle[routesWithGetPageTitle.length - 1];

    const matchProps = location && matchPath(location.pathname, route);
    const routeTitle = route.getPageTitle(appContext, matchProps);

    if (routeTitle) {
      // Escape HTML tags, so that someone can't cause the <title /> tag to be prematurely
      // ended (enabling them to inject arbitrary content / script into the document).
      return routeTitle.replace(PATTERN_HTML_TAGS, char => (char === '<' ? '&lt;' : '&gt;'));
    } else {
      return DEFAULT_TITLE;
    }
  } else {
    return DEFAULT_TITLE;
  }
}

/**
 * Gets the canonical url from the route if possible
 * @param {Object[]} activeRoutes - the current router state
 * @return {String|undefined} the url or undefined
 */
export function maybeGetCanonicalUrl({
  activeRoutes,
}: {
  activeRoutes: ActiveRoute[];
}): Nullable<string> {
  const routesWithGetCanonicalUrl = activeRoutes.filter(({ route }) => route.getCanonicalUrl);
  if (routesWithGetCanonicalUrl.length > 0) {
    const { route, match } = routesWithGetCanonicalUrl[routesWithGetCanonicalUrl.length - 1];
    const canonicalUrl = route.getCanonicalUrl(match);
    // this will throw a 500 if we try to do a .replace() on an undefined canonical url so check that it exists
    if (canonicalUrl) {
      // Escaping HTML, encodes single quote to (&#39;). Google is not able to parse this encoded canonical URL.
      return escape(canonicalUrl.replace(/'/g, '%27'));
    }
  }
  return null;
}

export function getRouteNameForRoutes(activeRoutes: ActiveRoute[]): Nullable<RouteName> {
  const lastRoute = activeRoutes[activeRoutes.length - 1];
  if (!lastRoute) {
    return null;
  }
  return getRouteNameForPath(lastRoute.match.path);
}

/**
 * Gets the favicon file path to a favicon based on the route name
 * @param {Object[]} activeRoutes - the current router state
 * @return {String} the page favicon filepath
 */
export function getFaviconFilePath({ activeRoutes }: { activeRoutes: ActiveRoute[] }): string {
  const routeName = getRouteNameForRoutes(activeRoutes);
  return getFaviconFilePathByRouteName(routeName);
}

/**
 * Gets the file path to a favicon based on the route name
 * @param {String} routeName - the current route name
 * @return {String} - favicon file path
 */
export function getFaviconFilePathByRouteName(routeName?: Nullable<string>): string {
  switch (routeName) {
    case 'READER':
      return 'img/reader';
    default:
      return 'img';
  }
}

/**
 * Gets the page citation metadata
 * @param {Object[]} activeRoutes - the current router state
 * @param {AppContext} appContext - global context for the app
 * @return {Object} the citation metadata and tag names
 */
export function getPageCitationMeta({
  activeRoutes,
  appContext,
}: {
  activeRoutes: ActiveRoute[];
  appContext: AppContext;
}): Nullable<object> {
  const routesWithGetPageCitationMeta = activeRoutes.filter(
    ({ route }) => route.getPageCitationMeta
  );
  if (routesWithGetPageCitationMeta.length > 0) {
    const { route } = routesWithGetPageCitationMeta[routesWithGetPageCitationMeta.length - 1];
    return route.getPageCitationMeta(appContext);
  }
  return null;
}

/**
 * Gets the page meta description
 * @param {Object[]} activeRoutes - the current router state
 * @param {AppContext} appContext - global context for the app
 * @return {String} the meta description
 */
export function getPageMetaDescription({
  activeRoutes,
  appContext,
}: {
  activeRoutes: ActiveRoute[];
  appContext: AppContext;
}): string {
  const DEFAULT_META_DESCRIPTION = getString(_ => _.metaDescription.general.content);
  const routesWithGetPageMetaDescription = activeRoutes.filter(
    ({ route }) => route.getPageMetaDescription
  );
  if (routesWithGetPageMetaDescription.length > 0) {
    const { route } = routesWithGetPageMetaDescription[routesWithGetPageMetaDescription.length - 1];
    // escape HTML, since certain papers include HTML in their meta abstract (and we use
    // their abstract for the description).
    return escape(route.getPageMetaDescription(appContext));
  }
  return DEFAULT_META_DESCRIPTION;
}

/**
 * Gets Dublin Core metadata which is used for describing physical or digital resources,
 * such as books, works of art, video, images, and web pages. It is a set of 15 core
 * elements. Currently we only use two of them for Hypothes.is to recognize papers.
 * https://www.dublincore.org/specifications/dublin-core/dcmi-terms/
 *
 * @param {Object[]} activeRoutes - the current router state
 * @param {AppContext} appContext - global context for the app
 * @returns {DublinCoreMeta} an object containing dublin core meta
 */
export function getDublinCoreMeta({
  activeRoutes,
  appContext,
}: {
  activeRoutes: ActiveRoute[];
  appContext: AppContext;
}): DublinCoreMeta {
  const routesWithDublinCoreMeta = activeRoutes.filter(({ route }) => route.getDublinCoreMeta);

  if (routesWithDublinCoreMeta.length === 0) {
    return {
      dublinCoreId: null,
      showDublinCoreRelation: false,
    };
  }

  // Type assertion used because routesWithAlternativePdfUrl is not empty here
  const { route } = routesWithDublinCoreMeta.pop() as ActiveRoute;

  return route.getDublinCoreMeta(appContext);
}

/**
 * Gets the alternative pdf url which is used by Hypothesis to sync annotations from different pdf sources
 * https://web.hypothes.is/help/how-hypothesis-interacts-with-document-metadata/#dublin-core-metadata
 *
 * @param {Object[]} activeRoutes - the current router state
 * @param {AppContext} appContext - global context for the app
 * @returns {String} a paper's alternative pdf url
 */
export function getAlternativePdfUrl({
  activeRoutes,
  appContext,
}: {
  activeRoutes: ActiveRoute[];
  appContext: AppContext;
}) {
  const routesWithAlternativePdfUrl = activeRoutes.filter(
    ({ route }) => route.getAlternativePdfUrl
  );

  if (routesWithAlternativePdfUrl.length === 0) {
    return null;
  }

  // Type assertion used because routesWithAlternativePdfUrl is not empty here
  const { route } = routesWithAlternativePdfUrl.pop() as ActiveRoute;

  return route.getAlternativePdfUrl(appContext);
}

/**
 * Gets the page figure (image for sharing)
 * @param {Object[]} activeRoutes - the current router state
 * @param {AppContext} appContext - global context for the app
 * @return {String} the page figure
 */
export function getPageFigure({
  activeRoutes,
  appContext,
}: {
  activeRoutes: ActiveRoute[];
  appContext: AppContext;
}): {
  uri: string;
  height: number;
  width: number;
} {
  const routesWithGetFigure = activeRoutes.filter(({ route }) => route.getPageFigure);
  if (routesWithGetFigure.length > 0) {
    const { route } = routesWithGetFigure[routesWithGetFigure.length - 1];
    return route.getPageFigure(appContext) || OpenGraph.DEFAULT_SHARE_IMAGE;
  }
  return OpenGraph.DEFAULT_SHARE_IMAGE;
}

/**
 * Gets the page schema
 * @param {Object[]} activeRoutes - the current router state
 * @param {AppContext} appContext - global context for the app
 * @return {String} the page linked data
 */
export function getPageSchemaData({
  activeRoutes,
  appContext,
}: {
  activeRoutes: ActiveRoute[];
  appContext: AppContext;
}): Nullable<string> {
  // For each route, collect breadcrumb and linked data
  const { allBreadCrumbData, allLinkedData } = activeRoutes
    .filter(({ route }) => route.getSchemaData)
    .reduce(
      (
        { allBreadCrumbData, allLinkedData }: { allBreadCrumbData: any[]; allLinkedData: any[] },
        { route, match }
      ) => {
        const { breadCrumbs, linkedData } = route.getSchemaData(match, appContext);
        if (breadCrumbs) {
          allBreadCrumbData.push(...breadCrumbs);
        }
        if (linkedData) {
          allLinkedData.push(linkedData);
        }
        return { allLinkedData, allBreadCrumbData };
      },
      { allBreadCrumbData: [], allLinkedData: [] }
    );

  if (allLinkedData.length > 0 || allBreadCrumbData.length > 0) {
    return JSON.stringify(schema.dataToSchema(allBreadCrumbData, allLinkedData));
  }
  return null;
}

/**
 * Get data related to the error for rendering.
 *
 * @param {Object} error - the error we're catching
 * @return {Object} a data object
 */
export function getErrorData(error: any): {
  title: string;
  status: number;
  metaDescription: string;
} {
  const status = (() => {
    if (typeof error.status === 'number') {
      return error.status;
    }
    const code = error?.code || error?.error?.code || null;
    switch (code) {
      case 'ECONNRESET':
        return 503;
      case 'ERR_UNESCAPED_CHARACTERS':
        return 400;
      default:
        return 500;
    }
  })();

  let title;
  let metaDescription;
  switch (status) {
    case 401:
      title = HttpStatusMeta.UNAUTHORIZED.TITLE;
      metaDescription = HttpStatusMeta.UNAUTHORIZED.DESC;
      break;
    case 404:
      title = HttpStatusMeta.NOT_FOUND.TITLE;
      metaDescription = HttpStatusMeta.NOT_FOUND.DESC;
      break;
    default:
      title = HttpStatusMeta.SERVER_ERROR.TITLE;
      metaDescription = HttpStatusMeta.SERVER_ERROR.DESC;
  }

  return { title, status, metaDescription };
}

/**
 * Takes a routeName or path, params, and query, and returns a valid location-like object for
 * react-router. If routeName is provided, then it will be used to create a location object;
 * otherwise, path will be used.
 * See: https://www.npmjs.com/package/history for more information on the location object.
 *
 * @param {String} path - A full path regex, e.g., /paper/:slug/:paperId
 * @param {String} routeName - A named route from the Routes enum.
 * @param {Object} params - An object containing the params for the given route.
 * @param {Object} query - An object containing the query string represented as { key: value }
 * @return {Object} A (partial) location object.
 */
export function mkRouterObj({
  path,
  routeName,
  params,
  query,
}: RawRouterObject): LocationDescriptorObject {
  const pathname = routeName ? makePath({ routeName, params }) : makePath({ path, params });
  return {
    pathname,
    search: query ? cleanAndStringifyQuery(query) : '',
  };
}
