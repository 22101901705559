import { gql } from 'graphql-tag';
import Immutable from 'immutable';

import type { DEPRECATED__FlowOptional, Nullable } from '@/utils/types';

export type AuthorStatisticsFromJS = {
  hIndex: DEPRECATED__FlowOptional<number>;
  totalCitationCount: DEPRECATED__FlowOptional<number>;
  totalPaperCount: DEPRECATED__FlowOptional<number>;
  totalInfluentialCitationCount: DEPRECATED__FlowOptional<number>;
};

export type AuthorStatisticsFromGraphQL = {
  citationCount: number;
  paperCount: number;
  influentialPaperCount: number;
  hIndex: number;
};

type Props = {
  hIndex: Nullable<number>;
  totalCitationCount: Nullable<number>;
  totalPaperCount: Nullable<number>;
  totalInfluentialCitationCount: Nullable<number>;
};

const defaultProps: Props = {
  hIndex: null,
  totalCitationCount: null,
  totalPaperCount: null,
  totalInfluentialCitationCount: null,
};

export const AuthorStatisticsRecordFactory = Immutable.Record<Props>(defaultProps);

export type AuthorStatisticsRecord = Immutable.RecordOf<Props>;

export function getEmptyAuthorStatistics(): AuthorStatisticsRecord {
  return AuthorStatisticsRecordFactory();
}

export function getAuthorStatisticsFromJS(args: AuthorStatisticsFromJS): AuthorStatisticsRecord {
  return AuthorStatisticsRecordFactory(args);
}

export function hasDisplayableAuthorStatistics(stats: AuthorStatisticsRecord): boolean {
  return (stats.totalInfluentialCitationCount || 0) > 0;
}

export function getAuthorStatisticsFromGraphQL(
  args: AuthorStatisticsFromGraphQL
): AuthorStatisticsRecord {
  return getAuthorStatisticsFromJS({
    hIndex: args.hIndex,
    totalInfluentialCitationCount: args.influentialPaperCount,
    totalCitationCount: args.citationCount,
    totalPaperCount: args.paperCount,
  });
}

export const AUTHOR_STATISTICS_FRAGMENT_NAME = 'AuthorStatisticsRecordFragment';

export const AUTHOR_STATISTICS_FRAGMENT = gql`
  fragment ${AUTHOR_STATISTICS_FRAGMENT_NAME} on Author {
    citationCount
    paperCount
    influentialPaperCount
    hIndex
  }
`;
