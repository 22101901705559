import { getString } from '@/content/i18n';
import {
  heapLibraryNavAddFolderCancel,
  heapLibraryNavAddFolderSubmit,
} from '@/analytics/attributes/libraryPage';
import { HeapProps } from '@/analytics/heapUtils';
import { maybeCreateAlert } from '@/utils/alert-util';
import { Nullable, ReactNodeish } from '@/utils/types';
import { Off, On } from '@/models/library/RecommendationStatus';
import { QUERY_TYPE } from '@/constants/Alert';
import Api from '@/api/Api';
import AuthStore from '@/stores/AuthStore';
import CLButton, { SIZE, TYPE } from '@/components/library/button/CLButton';
import CLFieldError, { ARROW_SIDE } from '@/components/library/form/error/CLFieldError';
import CLIconButton from '@/components/library/button/CLIconButton';
import CLTextInput from '@/components/library/form/input/CLTextInput';
import FormLevelMessage from '@/components/shared/message/FormLevelMessage';
import Icon from '@/components/shared/icon/Icon';
import LoadingIndicator from '@/components/shared/loading/LoadingIndicator';
import softError from '@/utils/softError';
import Toggle from '@/components/shared/common/form/Toggle';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

type Props = {
  heapLinkProps: HeapProps;
};

type FormState = {
  folderName: string;
  hasFolderRecommendationsOn: boolean;
  errorMessage: Nullable<string>;
};
type State = {
  isFormOpen: boolean;
  isSending: boolean;
  isFolderNameMissingError: boolean;
} & FormState;

export const DEFAULT_FORM_STATE = Object.freeze({
  folderName: '',
  hasFolderRecommendationsOn: true,
  errorMessage: null,
});

const preventDefault = (event: React.FormEvent) => {
  event.preventDefault();
};

export default class LibraryNavAddFolder extends React.PureComponent<Props, State> {
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    authStore: PropTypes.instanceOf(AuthStore).isRequired,
  };

  declare context: {
    api: Api;
    authStore: AuthStore;
  };

  _formRef: Nullable<HTMLFormElement>;

  constructor(...args: [any]) {
    super(...args);

    this.state = {
      ...DEFAULT_FORM_STATE,
      isFormOpen: false,
      isSending: false,
      isFolderNameMissingError: false,
      errorMessage: null,
    };
  }

  setFormRef = (ref: Nullable<HTMLFormElement>): void => {
    this._formRef = ref;
  };

  onClickAddFolderButton = (event: React.MouseEvent): void => {
    event.preventDefault();
    this.setState({ isFormOpen: true }, () => {
      this.focusOnFirstInput();
    });
  };

  onChangeFolderName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    this.setState({
      folderName: event.target.value,
      isFolderNameMissingError: false,
    });
  };

  onClickRecommendationsToggle = (): void => {
    this.setState({
      hasFolderRecommendationsOn: !this.state.hasFolderRecommendationsOn,
    });
  };

  onSubmitAddFolderForm = (event: React.MouseEvent): void => {
    event.preventDefault();
    const { folderName, hasFolderRecommendationsOn } = this.state;

    if (!folderName.trim()) {
      this.setState({ isFolderNameMissingError: true });
      return;
    }

    this.setState({
      isSending: true,
      isFolderNameMissingError: false,
      errorMessage: null,
    });

    this.submitCreateFolder({ folderName, hasFolderRecommendationsOn })
      .then(() => {
        this.setState({
          isSending: false,
          folderName: DEFAULT_FORM_STATE.folderName,
        });
      })
      .catch(error => {
        softError('libraryNavAddFolder', 'failed to create new library folder', error);

        const errorMessageText =
          error.errorCode === 'LibraryTagAlreadyExistsError'
            ? getString(_ => _.library.addFolderForm.folderExistsErrorMessage)
            : getString(_ => _.library.addFolderForm.errorMessage);

        this.setState({
          isSending: false,
          errorMessage: errorMessageText,
        });
      });
  };

  onClickCancelForm = (event: React.MouseEvent): void => {
    event.preventDefault();
    this.setState({ isFormOpen: false, ...DEFAULT_FORM_STATE });
  };

  focusOnFirstInput(): void {
    if (!this._formRef) {
      return;
    }
    const inputEl = this._formRef.querySelector<HTMLInputElement>('input[type="text"], textarea');
    if (inputEl) {
      inputEl.focus();
    }
  }

  async submitCreateFolder({
    folderName,
    hasFolderRecommendationsOn,
  }: {
    folderName: string;
    hasFolderRecommendationsOn: boolean;
  }): Promise<void> {
    const { api, authStore } = this.context;
    const response = await api.createLibraryFolder({
      name: folderName,
      recommendationStatus: hasFolderRecommendationsOn ? On : Off,
    });

    maybeCreateAlert(api, authStore, QUERY_TYPE.LIBRARY_FOLDER, response.resultData.folder.id);
    await api.getLibraryFolders(); // Refresh folders in nav
  }

  onNameInputFocus = (): void => {
    this.setState({
      isFolderNameMissingError: false,
      errorMessage: null,
    });
  };

  renderButton(): ReactNodeish {
    return (
      <CLIconButton
        icon={
          <div className="library-nav__item__icon">
            <Icon icon="folder-add" height="16" width="16" />
          </div>
        }
        size={SIZE.LARGE}
        label={
          <div className="library-nav__item__label">
            {getString(_ => _.library.nav.addFolderButtonLabel)}
          </div>
        }
        testId="library-nav-add-button"
        className="library-nav__item__link link-button--no-underline"
        type={TYPE.TERTIARY}
        onClick={this.onClickAddFolderButton}
      />
    );
  }

  renderForm(): ReactNodeish {
    const {
      isSending,
      isFolderNameMissingError,
      folderName,
      hasFolderRecommendationsOn,
      errorMessage,
    } = this.state;
    return (
      <form
        className="library-nav__add-folder__form"
        data-test-id="library-nav-add-form"
        onSubmit={preventDefault}
        ref={this.setFormRef}>
        {!!errorMessage && <FormLevelMessage message={errorMessage} />}
        <div
          className={classnames(
            'library-nav__add-folder__row library-nav__add-folder__row--text-input',
            {
              'library-nav__add-folder__row--text-input__has-name-error':
                !!isFolderNameMissingError,
            }
          )}>
          <CLTextInput
            data-test-id="library-nav-add-folder-name"
            value={folderName}
            onFocus={this.onNameInputFocus}
            className="library-nav-add-folder-name--text-input"
            onChange={this.onChangeFolderName}
            placeholder={getString(_ => _.library.addFolderForm.folderNameLabel)}
          />
          {!!isFolderNameMissingError && (
            <CLFieldError
              className="library-nav__add-folder__row__error"
              data-test-id="library-nav-add-folder-name-error"
              arrow={ARROW_SIDE.TOP}
              widthPx={200}>
              {getString(_ => _.library.addFolderForm.missingNameErrorMessage)}
            </CLFieldError>
          )}
        </div>
        <div
          className={classnames({
            'library-nav__add-folder__row': true,
            'library-nav__add-folder__row--recs-toggle': true,
            'library-nav__add-folder__row--has-folder-recs-on': hasFolderRecommendationsOn,
          })}>
          <Toggle
            selected={hasFolderRecommendationsOn}
            onClick={this.onClickRecommendationsToggle}
            id="library-nav__add-folder__recs__toggle">
            <Icon icon="lightning-bolt" height="16" width="8" />
            <span>{getString(_ => _.library.addFolderForm.feedToggleLabel)}</span>
          </Toggle>
        </div>
        <div className="library-nav__add-folder__row library-nav__add-folder__row--actions">
          <div>
            <CLButton
              type={TYPE.PRIMARY}
              testId="library-nav-add-form-submit"
              label={getString(_ => _.library.addFolderForm.submitButtonLabel)}
              {...heapLibraryNavAddFolderSubmit()}
              onClick={this.onSubmitAddFolderForm}
            />
          </div>
          <div>
            <CLButton
              type={TYPE.SECONDARY}
              testId="library-nav-add-form-cancel"
              label={getString(_ => _.library.addFolderForm.closeButtonLabel)}
              {...heapLibraryNavAddFolderCancel()}
              onClick={this.onClickCancelForm}
            />
          </div>
        </div>
        <div className="library-nav__add-folder__row">{!!isSending && <LoadingIndicator />}</div>
      </form>
    );
  }

  render(): ReactNodeish {
    const { isFormOpen } = this.state;
    const { heapLinkProps } = this.props;

    return (
      <div className="library-nav__add-folder" {...heapLinkProps}>
        {isFormOpen ? this.renderForm() : this.renderButton()}
      </div>
    );
  }
}
