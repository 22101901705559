import { ReactNodeish } from '@/utils/types';
import IconButton from '@/components/shared/common/button/IconButton';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

type Props = {
  onClick: () => void;
};

export default class CloseModalButton extends PureComponent<Props> {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };
  render(): ReactNodeish {
    return (
      <IconButton
        className="close-modal-button"
        iconProps={{ icon: 'x', width: '20', height: '20' }}
        onClick={this.props.onClick}
      />
    );
  }
}
