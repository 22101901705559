import type { Nullable, TODO } from '@/utils/types';

export type LoginMethodId = (typeof LOGIN_METHOD_ID)[keyof typeof LOGIN_METHOD_ID];

export type LoginAction = (typeof LOGIN_ACTION)[keyof typeof LOGIN_ACTION];

export type LoginLocationValue = (typeof LOGIN_LOCATION)[keyof typeof LOGIN_LOCATION];

// Login Method IDs
export const COGNITO = 'cognito';
export const FACEBOOK = 'facebook';
export const GOOGLE = 'google';
export const GOOGLE_ONE_TAP = 'googleOneTap';
export const OPENATHENS = 'openathens';
export const TWITTER = 'twitter';
export const LOGIN_METHOD_ID = Object.freeze({
  COGNITO,
  FACEBOOK,
  GOOGLE,
  GOOGLE_ONE_TAP,
  OPENATHENS,
  TWITTER,
} as const);

// Login Actions
export const SIGN_IN = 'signIn';
export const SIGN_UP = 'signUp';
export const FORGOT_PASSWORD = 'forgotPassword';
export const LOGIN_ACTION = Object.freeze({
  SIGN_UP,
  SIGN_IN,
  FORGOT_PASSWORD,
} as const);

export const LOGIN_LOCATION = {
  alertButton: 'alert-button',
  anonymousFeed: 'anonymous-feed',
  authorCitations: 'author-citations',
  authorPapers: 'author-papers',
  authorPage: 'author-page',
  deleteAlertModal: 'delete_alert_modal',
  homePage: 'home-page',
  library: 'library_link',
  navBar: 'nav_bar',
  pdpAlert: 'pdp_alert',
  pdpLibrary: 'pdp_library',
  pdpPage: 'pdp_page',
  serp: 'serp',
  topic: 'watch-topic',
  unauthorizedFolderPage: 'unauthorized-folder-page',
} as const;

// Keep in sync with online/service/src/main/scala/org/allenai/s2/service/user/AuthSource.scala
export function getLoginMethodIdFromUser(
  user: Nullable<TODO<'UserRecord'>>
): Nullable<LoginMethodId> {
  if (!user) {
    return null;
  }
  switch (user.authSource) {
    case 'COGNITO':
      return COGNITO;
    case 'FACEBOOK':
      return FACEBOOK;
    case 'GOOGLE':
      return GOOGLE;
    case 'OPENATHENS':
      return OPENATHENS;
    case 'TWITTER':
      return TWITTER;
  }
  return null;
}
