import Immutable from 'immutable';

export type PaperFAQFromJS = {
  questionId: number;
  question: string;
  answers: string[];
};

type Props = {
  questionId: number;
  question: string;
  answers: Immutable.List<string>;
};

const defaultProps: Props = {
  questionId: 1,
  question: '',
  answers: Immutable.List(),
};

export const PaperFAQRecordFactory = Immutable.Record(defaultProps);
export type PaperFAQRecord = Immutable.RecordOf<Props>;

export const getPaperFAQFromJS = ({
  questionId,
  question,
  answers,
}: PaperFAQFromJS): PaperFAQRecord => {
  return PaperFAQRecordFactory({
    questionId,
    question,
    answers: Immutable.List(answers.map(a => a.toString())),
  });
};
