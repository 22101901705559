import { AnalyticsContextHelper } from '@/components/library/paper/CLPaperAnalytics';
import { CLPaperControlsContext } from '@/components/library/paper/CLPaperControlsExpansionManager';
import { getString } from '@/content/i18n';
import { heapAnnotateRecommendationRelevant } from '@/analytics/attributes/paperObject';
import { ModalId } from '@/constants/Modal';
import { showModal } from '@/actions/ModalActionCreators';
import CLButton, { TYPE as BUTTON_TYPE } from '@/components/library/button/CLButton';
import EnvInfo from '@/env/EnvInfo';
import Icon from '@/components/shared/icon/Icon';
import LibraryFolderStore from '@/stores/LibraryFolderStore';
import LibraryRecommendationsStore from '@/stores/LibraryRecommendationsStore';
import S2Dispatcher from '@/utils/S2Dispatcher';

import PropTypes from 'prop-types';
import React from 'react';

export default class AnnotatePaperPopup extends React.PureComponent {
  static contextTypes = {
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    libraryRecommendationsStore: PropTypes.instanceOf(LibraryRecommendationsStore).isRequired,
    libraryFolderStore: PropTypes.instanceOf(LibraryFolderStore).isRequired,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
  };

  _onClickAnnotationAnalytics = null;
  _setControlsExpandedContent = null;

  constructor(...args) {
    super(...args);

    this.state = {
      ...this.getStateFromLibraryRecommendationsStore(),
      ...this.getStateFromLibraryFoldersStore(),
    };

    this.context.libraryRecommendationsStore.registerComponent(this, () => {
      this.setState(this.getStateFromLibraryRecommendationsStore());
    });

    this.context.libraryFolderStore.registerComponent(this, () => {
      this.setState(this.getStateFromLibraryFoldersStore());
    });
  }

  getStateFromLibraryFoldersStore() {
    const { folderId } = this.props;
    const folderPaperCount = this.context.libraryFolderStore.getPaperCount(folderId);
    const paperCount = folderPaperCount ? folderPaperCount : 0;
    return {
      paperCount,
    };
  }

  getStateFromLibraryRecommendationsStore() {
    const { folderId } = this.props;
    const notRelevantEntries =
      this.context.libraryRecommendationsStore.getNotRelevantPapersInFolder(folderId);
    const notRelevantPaperCount = notRelevantEntries.size;
    return {
      notRelevantPaperCount,
    };
  }

  setAnalyticsCallbacks = ({ onClickAnnotationButton }) => {
    this._onClickAnnotationAnalytics = onClickAnnotationButton;
  };

  onClickClose = () => {
    if (this._onClickAnnotationAnalytics) {
      this._onClickAnnotationAnalytics();
    }

    if (this._setControlsExpandedContent) {
      this._setControlsExpandedContent(null);
    }
  };

  onClickUndo = () => {
    const { onUndoClick } = this.props;
    if (typeof onUndoClick === 'function') {
      onUndoClick();
    }

    if (this._onClickAnnotationAnalytics) {
      this._onClickAnnotationAnalytics();
    }

    if (this._setControlsExpandedContent) {
      this._setControlsExpandedContent(null);
    }
  };

  showNotRelevantModal = () => {
    const { folderId, folderName } = this.props;
    this.context.dispatcher.dispatch(
      showModal({
        id: ModalId.RECOMMENDATIONS_NOT_RELEVANT,
        data: { folderId: folderId, folderName: folderName },
      })
    );
  };

  renderPopup = shouldShowInfoString => {
    const {
      envInfo: { isMobile },
    } = this.context;
    const iconString = isMobile ? 'thumbs-down-active' : 'thumbs-down';

    return (
      <div className="annotations-popup">
        <AnalyticsContextHelper onUpdate={this.setAnalyticsCallbacks}>
          <div className="annotation-popup__background">
            <div className="annotation-popup__text">
              <Icon className="annotation-popup__icon" icon={iconString} width="16" height="16" />
              <h3 className="annotation-popup__header">
                {getString(_ => _.research.recommendations.annotationCard.cardLabel)}
              </h3>
              {shouldShowInfoString && (
                <p className="annotation-popup__details">
                  {getString(_ => _.research.recommendations.annotationCard.improveRecsMessage)}
                </p>
              )}
              {!shouldShowInfoString && <p />}
            </div>
            <div>
              <CLButton
                type={BUTTON_TYPE.PRIMARY}
                label={getString(_ => _.research.recommendations.annotationCard.closeButtonLabel)}
                onClick={this.onClickClose}
                className="annotation-popup__button"
              />
              <CLButton
                type={BUTTON_TYPE.SECONDARY}
                label={getString(_ => _.research.recommendations.annotationCard.undoButtonLabel)}
                onClick={this.onClickUndo}
                heapProps={heapAnnotateRecommendationRelevant()}
                className="annotation-popup__button"
              />
              <CLButton
                type={BUTTON_TYPE.TERTIARY}
                label={getString(_ => _.research.recommendations.annotationCard.notRelevantPapers)}
                onClick={this.showNotRelevantModal}
                className="annotation-popup__link"
              />
            </div>
          </div>
        </AnalyticsContextHelper>
      </div>
    );
  };

  render() {
    const { notRelevantPaperCount, paperCount } = this.state;
    const shouldShowInfoString = paperCount < 5 || notRelevantPaperCount < 3;

    return (
      <CLPaperControlsContext.Consumer>
        {({ setExpandedContent }) => {
          this._setControlsExpandedContent = setExpandedContent;
          return this.renderPopup(shouldShowInfoString);
        }}
      </CLPaperControlsContext.Consumer>
    );
  }
}
