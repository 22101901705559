import { addHeapProps } from '@/components/util/HeapTracking';
import { hasValue, Nullable, ReactNodeish, ValueOf } from '@/utils/types';
import { HeapProps } from '@/analytics/heapUtils';

import classnames from 'classnames';
import React from 'react';

const BORDERLESS = 'borderless';
const DEFAULT = 'default';
const RESEARCH = 'research';
const SIDE_BOTTOM_POS_LEFT = 'bottom-left';
const SIDE_BOTTOM_POS_MIDDLE = 'bottom-middle';
const SIDE_BOTTOM_POS_RIGHT = 'bottom-right';
const SIDE_LEFT_POS_BOTTOM = 'left-bottom';
const SIDE_LEFT_POS_MIDDLE = 'left-middle';
const SIDE_LEFT_POS_TOP = 'left-top';
const SIDE_RIGHT_POS_BOTTOM = 'right-bottom';
const SIDE_RIGHT_POS_MIDDLE = 'right-middle';
const SIDE_RIGHT_POS_TOP = 'right-top';
const SIDE_TOP_POS_LEFT = 'top-left';
const SIDE_TOP_POS_MIDDLE = 'top-middle';
const SIDE_TOP_POS_RIGHT = 'top-right';

export const ARROW_POSITION = {
  SIDE_BOTTOM_POS_LEFT,
  SIDE_BOTTOM_POS_MIDDLE,
  SIDE_BOTTOM_POS_RIGHT,
  SIDE_LEFT_POS_BOTTOM,
  SIDE_LEFT_POS_MIDDLE,
  SIDE_LEFT_POS_TOP,
  SIDE_RIGHT_POS_BOTTOM,
  SIDE_RIGHT_POS_MIDDLE,
  SIDE_RIGHT_POS_TOP,
  SIDE_TOP_POS_LEFT,
  SIDE_TOP_POS_MIDDLE,
  SIDE_TOP_POS_RIGHT,
} as const;

export const THEME = {
  DEFAULT,
  BORDERLESS,
  RESEARCH,
} as const;

export type ArrowPosition = ValueOf<typeof ARROW_POSITION>;
export type PopoverTheme = ValueOf<typeof THEME>;

export type Props = React.PropsWithChildren<{
  arrow: ArrowPosition;
  ariaProps?: Nullable<object>;
  className?: Nullable<string>;
  heapProps?: Nullable<HeapProps>;
  isInline?: Nullable<boolean>;
  theme?: Nullable<PopoverTheme>;
  widthPx?: Nullable<number>;
}> &
  React.HTMLAttributes<HTMLDivElement>;

export default function CLPopoverBase({
  arrow,
  ariaProps,
  children,
  className,
  heapProps,
  isInline,
  theme = DEFAULT,
  widthPx,
  ...extraProps
}: Props): ReactNodeish {
  const style = hasValue(widthPx) ? { width: `${widthPx}px` } : {};

  return (
    <div
      {...extraProps}
      className={classnames(
        {
          'cl-popover': true,
          [`cl-popover--arrow-${arrow}`]: true,
          [`cl-popover--theme-${theme}`]: true,
          [`cl-popover--is-${isInline ? 'inline' : 'floating'}`]: true,
        },
        className
      )}
      style={style}
      {...(heapProps ? addHeapProps(heapProps) : {})}
      {...ariaProps}>
      <div className="cl-popover__arrow-wrapper">
        <div className="cl-popover__arrow" />
      </div>
      <div className="cl-popover__content">{children}</div>
    </div>
  );
}
