import { isNumber, Nullable } from '@/utils/types';

import Immutable from 'immutable';

export type PaperPdfBoundingBoxPropsFromJS = {
  page: Nullable<number>;
  left: Nullable<number>;
  top: Nullable<number>;
  width: Nullable<number>;
  height: Nullable<number>;
};

type Props = {
  page: number;
  left: number;
  top: number;
  width: number;
  height: number;
};

const defaultProps: Props = {
  page: 0,
  left: 0,
  top: 0,
  width: 0,
  height: 0,
};

export const PaperPdfBoundingBoxFactory = Immutable.Record<Props>(defaultProps);
export type PaperPdfBoundingBoxRecord = Immutable.RecordOf<Props>;

export function getPaperPdfBoundingBoxFromJS(
  args: PaperPdfBoundingBoxPropsFromJS
): PaperPdfBoundingBoxRecord {
  const { page, left, top, width, height } = args || {};

  // This can only guarantee the correctness of data transformation but
  // not a correct bounding box when its raw attributes are wrong.
  // TODO #33136: filter out wrong bounding boxes in scala codes
  return PaperPdfBoundingBoxFactory({
    page: page || 0,
    left: left || 0,
    top: top || 0,
    width: width || 0,
    height: height || 0,
  });
}

export function getEmptyPaperPdfBoundingRecord(): PaperPdfBoundingBoxRecord {
  return PaperPdfBoundingBoxFactory();
}

export function isBoundingBoxComplete(box: PaperPdfBoundingBoxPropsFromJS): boolean {
  return (
    isNumber(box.page) &&
    isNumber(box.height) &&
    isNumber(box.width) &&
    isNumber(box.left) &&
    isNumber(box.top)
  );
}
