import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import idx from 'idx';
import React from 'react';

export default class DebugComponentLibraryRoute extends React.Component {
  render() {
    const componentName = idx(this.props, _ => _.match.params.component) || null;
    return (
      <AsyncLoadedPage
        footer={false}
        compProps={{ componentName }}
        load={{
          importer: () =>
            import(
              /* webpackChunkName: "shared-DebugComponentLibraryPage" */ './DebugComponentLibraryPage'
            ),
          chunkName: 'shared-DebugComponentLibraryPage',
          moduleId: require.resolveWeak('./DebugComponentLibraryPage'),
        }}
      />
    );
  }
}
