import CLPaperActionInfluentialCitationsStat from './actions/CLPaperActionInfluentialCitationsStat';
import CLPaperActionTotalCitationsStat from './actions/CLPaperActionTotalCitationsStat';
import CLPaperBadgeList, { canRender as canRenderBadges } from './badges/CLPaperBadgeList';
import CLPaperInfluentialCitationsStat, {
  canRender as canRenderHIC,
} from './buttons/CLPaperInfluentialCitationsStat';
import CLPaperTotalCitationsStat, {
  canRender as canRenderTotalCitations,
} from './buttons/CLPaperTotalCitationsStat';

import { addHeapProps } from '@/components/util/HeapTracking';
import { AnalyticsContext } from '@/components/library/paper/CLPaperAnalytics';
import { citationStatsHasKeyCitations } from '@/models/CitationStats';
import { useAppContext } from '@/AppContext';
import CLPaperReaderButton from '@/components/library/paper/paper-actions/buttons/CLPaperReaderButton';

import classnames from 'classnames';
import React from 'react';

export const TOTAL_CITATIONS = 'TOTAL_CITATIONS';
export const INFLUENTIAL_CITATIONS = 'INFLUENTIAL_CITATIONS';

export default function CLPaperStatsBase({
  paper,
  className,
  heapProps,
  totalCitations,
  hic,
  badges,
  onHover,
  isInteractive,
}) {
  const { citationStats } = paper;
  const { envInfo } = useAppContext();
  const { onHoverStats: onHoverStatsAnalytics } = React.useContext(AnalyticsContext);
  const onHoverWrap = React.useCallback(
    citationStat => {
      onHoverStatsAnalytics({ citationStat });
      if (onHover) {
        onHover(citationStat);
      }
    },
    [onHoverStatsAnalytics]
  );

  if (!canRender(paper)) {
    return null;
  }

  const showReaderButton = paper.isReaderAvailable && !envInfo.isMobile;
  const hasCitationData = canRenderTotalCitations(citationStats) || canRenderHIC(citationStats);

  return (
    <ul className={classnames('cl-paper-stats-list', className)} {...addHeapProps(heapProps)}>
      {!!totalCitations && canRenderTotalCitations(citationStats) && (
        <li className="cl-paper-stats__item">
          {totalCitations === true ? (
            isInteractive ? (
              <CLPaperActionTotalCitationsStat paper={paper} onHover={onHoverWrap} />
            ) : (
              <CLPaperTotalCitationsStat
                citationStats={citationStats}
                className={classnames({
                  'cl-paper-stat__with-border': paper.isReaderAvailable,
                })}
              />
            )
          ) : (
            totalCitations
          )}
        </li>
      )}
      {!!hic && canRenderHIC(citationStats) && (
        <li className="cl-paper-stats__item">
          {hic === true ? (
            isInteractive ? (
              <CLPaperActionInfluentialCitationsStat paper={paper} onHover={onHoverWrap} />
            ) : (
              <CLPaperInfluentialCitationsStat citationStats={citationStats} />
            )
          ) : (
            hic
          )}
        </li>
      )}

      {showReaderButton && (
        <li className="cl-paper-stats__item">
          <CLPaperReaderButton
            paperId={paper.id}
            className={classnames({
              'cl-paper-action__reader-button--no-margin': !hasCitationData,
            })}
          />
        </li>
      )}

      {!!badges && !showReaderButton && canRenderBadges(paper) && (
        <li className="cl-paper-stats__item">
          {badges === true ? (
            <CLPaperBadgeList paper={paper} showReaderButton={paper.isReaderAvailable} isCompact />
          ) : (
            badges
          )}
        </li>
      )}
    </ul>
  );
}

CLPaperStatsBase.defaultProps = {
  totalCitations: true,
  hic: false,
  isInteractive: true,
};

export function canRender(paper) {
  const { citationStats } = paper;

  return (
    canRenderBadges(paper) ||
    citationStatsHasKeyCitations(citationStats) ||
    citationStats.numCitations > 0
  );
}
