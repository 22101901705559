import BaseStore from './BaseStore';

import { HideShelfAction, ShowShelfAction } from '@/actions/ShelfActionCreators';
import { Nullable } from '@/utils/types';
import { ShelfContext } from '@/models/shelf/ShelfContexts';
import { ShelfId } from '@/constants/ShelfId';
import Constants from '@/constants';
import logger from '@/logger';
import S2Dispatcher from '@/utils/S2Dispatcher';

import type { RoutingPayload } from '@/actions/RouterActionCreators';

export default class ShelfStore extends BaseStore {
  #visibleShelfId: Nullable<ShelfId> = null;
  #shelfContext: Nullable<ShelfContext> = null;

  constructor(dispatcher: S2Dispatcher) {
    super();

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.SHOW_SHELF: {
          const { id: newShelfId, context } = payload as ShowShelfAction;
          if (newShelfId === this.#visibleShelfId) {
            logger.warn(`Shelf '${newShelfId}' is already visible.`);
            break;
          }

          // Hide old shelf
          if (this.#visibleShelfId) {
            this.#visibleShelfId = null;
            this.emitChange();
          }

          // Show new shelf
          this.#visibleShelfId = newShelfId;
          this.#shelfContext = context || null;
          this.emitChange();
          break;
        }

        case Constants.actions.ROUTING: // If we route away from page, close all shelves
        case Constants.actions.HIDE_SHELF: {
          // @ts-expect-error -- RoutingPayload doesn't have an "id" prop
          const { id: optOldShelfId } = payload as HideShelfAction | RoutingPayload;

          // Hide a specific shelf, if visible
          if (optOldShelfId) {
            if (this.#visibleShelfId !== optOldShelfId) {
              logger.info(
                `shelf '${optOldShelfId}' was told to hide, but ${this.#visibleShelfId} was visible`
              );
              break;
            }
            this.#visibleShelfId = null;
            this.#shelfContext = null;
            this.emitChange();
            break;
          }

          // Hide any shelf, if visible
          if (this.#visibleShelfId) {
            this.#visibleShelfId = null;
            this.#shelfContext = null;
            this.emitChange();
            break;
          }

          break;
        }
      }
    });
  }

  // Determines what shelf should be rendered
  getVisibleShelfId(): Nullable<ShelfId> {
    return this.#visibleShelfId;
  }

  // Context passed to the rendered shelf
  getShelfContext(): Nullable<ShelfContext> {
    return this.#shelfContext;
  }
}
