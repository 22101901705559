import { Nullable, ValueOf } from '@/utils/types';

// Keep in sync with CueType.scala
export type CueType = ValueOf<typeof byKey>;

// General assumption for lib cues: a user saw paper row X in S2 website. Paper X has an author Y.
// CitedByLibraryPaperCue cue applies to paper row X when paper X is cited by papers bookmarked in current user's library
export const CitedByLibraryPaperCue = 'CitedByLibraryPaperCue';

// CitesLibraryPaperCue cue applies to paper row X when paper X cites papers bookmarked in current user's library.
export const CitesLibraryPaperCue = 'CitesLibraryPaperCue';

// AuthoredLibraryPaperCue cue applies to author Y when author Y authored some papers in current user's library.
export const AuthoredLibraryPaperCue = 'AuthoredLibraryPaperCue';

// YouCitedAuthorCue cue applies to author Y when author Y authored some papers current user cited in its own paper work.
export const YouCitedAuthorCue = 'YouCitedAuthorCue';

// CoauthorCue cue applies to author Y when author Y authored some papers with current user. In other words, Y and current user coauthored some papers.
export const CoauthorCue = 'CoauthorCue';

// CitesYourPaperCue cue applies to paper X when paper X cites papers current user authored.
export const CitesYourPaperCue = 'CitesYourPaperCue';

export const Unsupported = 'Unsupported';

const byKey = {
  CitedByLibraryPaperCue,
  CitesLibraryPaperCue,
  AuthoredLibraryPaperCue,
  YouCitedAuthorCue,
  CoauthorCue,
  CitesYourPaperCue,
  Unsupported,
} as const;

export const CueTypeMap = Object.freeze(byKey);

export function getOptCueType(optSource?: Nullable<string>): Nullable<CueType> {
  if (optSource && optSource in byKey) {
    return byKey[optSource];
  }
  return null;
}

export function getCueType(optSource?: Nullable<string>): CueType {
  return getOptCueType(optSource) || Unsupported;
}
