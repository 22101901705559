import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

export default class AccountRemovePapersRoute extends React.Component {
  static getPageTitle = () => getString(_ => _.account.page.removePapers.pageTitle);

  render() {
    return (
      <AsyncLoadedPage
        showLoadingPage={false}
        load={{
          importer: () =>
            import(
              /* webpackChunkName: "shared-AccountRemovePapersPage" */ './AccountRemovePapersPage'
            ),
          chunkName: 'shared-AccountRemovePapersPage',
          moduleId: require.resolveWeak('./AccountRemovePapersPage'),
        }}
      />
    );
  }
}
