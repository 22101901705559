/* Generate page schema */

export type SchemaData = {
  breadCrumbs: BreadcrumbListItem[];
  linkedData: { [key: string]: string };
};

export type BreadcrumbItem = {
  '@id': string;
  name: string;
};

export type BreadcrumbListItem = {
  '@type': 'ListItem';
  position: number;
  item: BreadcrumbItem;
};

export type BreadcrumbList = {
  '@type': 'BreadcrumbList';
  itemListElement: BreadcrumbListItem[];
};

export type BreadcrumbSchema = {
  '@context': string;
  '@graph': BreadcrumbList | UnknownBreadcrumbType[];
};

type UnknownBreadcrumbType = any; // TODO: Need to figure out what linkedData is

export default {
  /* Takes the hostname and an array of data { path, name } and generates breadcrumb */
  breadcrumb(host: string, data: { path: string; name: string }[]): BreadcrumbListItem[] {
    return data.map(({ path, name }, index) => {
      return {
        '@type': 'ListItem',
        position: index + 1, // position starts at 1
        item: { '@id': host + path, name: name },
      };
    });
  },

  dataToSchema(
    breadCrumbData: BreadcrumbListItem[],
    linkedData: UnknownBreadcrumbType[]
  ): BreadcrumbSchema {
    const breadCrumbs = {
      '@type': 'BreadcrumbList',
      itemListElement: breadCrumbData,
    };
    return {
      '@context': 'http://schema.org',
      '@graph': [breadCrumbs, ...linkedData],
    };
  },
};
