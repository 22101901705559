import React from 'react';

export default function IconShareTwitter(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 32 32">
      <path
        d="M32,3.43v25.14c0,1.89-1.54,3.43-3.43,3.43H3.43C1.54,32,0,30.46,0,28.57V3.43C0,1.54,1.54,0,3.43,0h25.14
        C30.46,0,32,1.54,32,3.43z M27.43,8.91c-0.84,0.36-1.76,0.63-2.7,0.73c0.97-0.58,1.71-1.49,2.06-2.58c-0.9,0.54-1.91,0.93-2.97,1.13
        c-0.86-0.92-2.07-1.48-3.42-1.48c-2.86,0-5.24,2.58-4.57,5.76c-3.89-0.2-7.35-2.06-9.66-4.9C5.76,8.26,5.53,9.06,5.53,9.94v0.01
        c0,1.38,0.93,3.13,2.09,3.89c-0.76-0.03-1.49-0.24-2.11-0.59v0.06c0,2.28,1.61,4.16,3.75,4.6c-0.74,0.19-1.39,0.19-2.11,0.08
        c0.59,1.86,2.32,3.21,4.38,3.25c-1.61,1.26-3.62,2-5.81,2c-0.39,0-0.75-0.01-1.13-0.06c2.07,1.33,4.54,2.1,7.19,2.1
        c8.61,0,13.33-7.14,13.33-13.33c0-0.2,0-0.41-0.01-0.61C25.99,10.68,26.79,9.85,27.43,8.91z"
      />
    </symbol>
  );
}
