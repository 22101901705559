import { getString } from '@/content/i18n';
import ErrorMessageBlock from '@/components/shared/message/ErrorMessageBlock';
import Link from '@/router/Link';

import React from 'react';

type Props = { statusCode: string };

export default class ErrorPage extends React.Component<Props> {
  render(): React.ReactNode {
    const { statusCode } = this.props;

    return (
      <ErrorMessageBlock
        image="generalError"
        status={statusCode}
        altText={getString(_ => _.serverError.imageAltText)}>
        <h1 className="error-message__main-text">{getString(_ => _.serverError.mainText)}</h1>
        <p className="error-message__subtext">{getString(_ => _.serverError.subText)}</p>
        <p className="error-message__code">{getString(_ => _.serverError.codeText, statusCode)}</p>
        <Link to="HOME" className="button button--primary error-message__button">
          <React.Fragment>{getString(_ => _.serverError.backButtonLabel)}</React.Fragment>
        </Link>
      </ErrorMessageBlock>
    );
  }
}
