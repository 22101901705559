import { AlertQueryType, QUERY_TYPE, QUERY_TYPE_TO_ALERT_TYPE, STATUS } from '@/constants/Alert';
import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

type EnumWithValue = {
  value: string;
};

type AlertFromJS = {
  id: number;
  status: EnumWithValue;
  displayValue: string;
  canonicalQueryValue: Nullable<string>;
  alertType: EnumWithValue;
  queryType: EnumWithValue;
  queryValue: string | number;
};

type Props = {
  id: number;
  isActive: boolean;
  displayValue: string;
  alertType: string;
  canonicalQueryValue: Nullable<string>;
  queryType: AlertQueryType;
  queryValue: string;
};

export const defaultProps: Props = {
  id: -1,
  isActive: false,
  displayValue: '',
  alertType: '',
  canonicalQueryValue: null,
  queryType: QUERY_TYPE.LIBRARY_FOLDER, // have to pick a default
  queryValue: '',
};

export const AlertRecordFactory = Immutable.Record<Props>(defaultProps);

export type AlertRecord = Immutable.RecordOf<Props>;

export function getAlertFromJS(args: AlertFromJS): AlertRecord {
  return AlertRecordFactory({
    ...args,
    isActive: args.status.value === STATUS.ACTIVE,
    queryType: QUERY_TYPE[args.queryType.value],
    queryValue: args.queryValue.toString(),
    alertType: QUERY_TYPE_TO_ALERT_TYPE[args.queryType.value],
  });
}
