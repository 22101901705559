import {
  getEmptyPaperPdfBoundingRecord,
  getPaperPdfBoundingBoxFromJS,
  PaperPdfBoundingBoxPropsFromJS,
  PaperPdfBoundingBoxRecord,
} from './PaperPdfBoundingBox';

import { Nullable } from '@/utils/types';

import Immutable from 'immutable';

export type AcronymDefinitionFromJS = {
  s2airsId: Nullable<string>;
  mmdaId: Nullable<string>;
  text: Nullable<string>;
  boundingBoxes: PaperPdfBoundingBoxPropsFromJS[];
  mentions: RawAcronymMention[];
};

// RawAcronymMention is AcronymMention but without definitionText and definitionBoundingBox. It may also have more than 1 boundingBox.
// Multiple boundingBoxes can happen if an acronym is split across two pages
export type RawAcronymMention = {
  s2airsId: Nullable<string>;
  mmdaId: Nullable<string>;
  text: Nullable<string>;
  boundingBoxes: PaperPdfBoundingBoxPropsFromJS[];
};

export type AcronymMentionFromJS = {
  s2airsId: Nullable<string>;
  mmdaId: Nullable<string>;
  text: Nullable<string>;
  definitionS2airsId: Nullable<string>;
  definitionMmdaId: Nullable<string>;
  definitionText: Nullable<string>;
  definitionBoundingBox: PaperPdfBoundingBoxPropsFromJS;
  boundingBox: PaperPdfBoundingBoxPropsFromJS;
};

type AcronymMentionProps = {
  s2airsId: Nullable<string>;
  mmdaId: Nullable<string>;
  text: Nullable<string>;
  definitionS2airsId: Nullable<string>;
  definitionMmdaId: Nullable<string>;
  definitionText: Nullable<string>;
  definitionBoundingBox: PaperPdfBoundingBoxRecord;
  boundingBox: PaperPdfBoundingBoxRecord;
};

const acronymMentionDefaultProps: AcronymMentionProps = {
  s2airsId: null,
  mmdaId: null,
  text: null,
  definitionS2airsId: null,
  definitionMmdaId: null,
  definitionText: null,
  definitionBoundingBox: getEmptyPaperPdfBoundingRecord(),
  boundingBox: getEmptyPaperPdfBoundingRecord(),
};

export const PaperPdfAcronymMentionFactory = Immutable.Record<AcronymMentionProps>(
  acronymMentionDefaultProps
);
export type PaperPdfAcronymMentionRecord = Immutable.RecordOf<AcronymMentionProps>;

export function getPaperPdfAcronymMentionFromJS(
  args: AcronymMentionFromJS
): PaperPdfAcronymMentionRecord {
  return PaperPdfAcronymMentionFactory({
    s2airsId: args.s2airsId,
    mmdaId: args.mmdaId,
    text: args.text,
    definitionS2airsId: args.definitionS2airsId,
    definitionMmdaId: args.definitionMmdaId,
    definitionText: args.definitionText,
    definitionBoundingBox: getPaperPdfBoundingBoxFromJS(args.definitionBoundingBox),
    boundingBox: getPaperPdfBoundingBoxFromJS(args.boundingBox),
  });
}
