import {
  AUTHOR_STATISTICS_FRAGMENT,
  AUTHOR_STATISTICS_FRAGMENT_NAME,
  AuthorStatisticsFromGraphQL,
  AuthorStatisticsFromJS,
  AuthorStatisticsRecord,
  getAuthorStatisticsFromGraphQL,
  getAuthorStatisticsFromJS,
  getEmptyAuthorStatistics,
} from './AuthorStatistics';

import {
  getProfilePictureFromGraphQL,
  getProfilePictureFromJS,
  PROFILE_PICTURE_FRAGMENT,
  PROFILE_PICTURE_FRAGMENT_NAME,
  ProfilePictureFromGraphQL,
  ProfilePictureFromJS,
  ProfilePictureRecord,
} from '@/models/user/ProfilePicture';

import { gql } from 'graphql-tag';
import Immutable from 'immutable';

import type { DEPRECATED__FlowOptional, Nullable } from '@/utils/types';

export type AuthorFromGraphQL = {
  id: number;
  fullName: string;
  slug: string;
  affiliationNames: string[];
  orcidId: Nullable<string>;
  homepage: Nullable<string>;
  profilePicture: Nullable<ProfilePictureFromGraphQL>;
} & AuthorStatisticsFromGraphQL;

export type AuthorFromJS = {
  id: DEPRECATED__FlowOptional<string>;
  name: DEPRECATED__FlowOptional<string>;
  firstName: DEPRECATED__FlowOptional<string>;
  middleNames: string[];
  lastName: DEPRECATED__FlowOptional<string>;
  slug: DEPRECATED__FlowOptional<string>;
  email: DEPRECATED__FlowOptional<string>;
  affiliations: string[];
  orcidId: DEPRECATED__FlowOptional<string>;
  homepage: DEPRECATED__FlowOptional<string>;
  lastModifiedDate: DEPRECATED__FlowOptional<string>;
  statistics: AuthorStatisticsFromJS;
  profilePicture: Nullable<ProfilePictureFromJS>;
};

type Props = {
  id: Nullable<string>;
  name: Nullable<string>;
  firstName: Nullable<string>;
  middleNames: Immutable.List<string>;
  lastName: Nullable<string>;
  slug: Nullable<string>;
  email: Nullable<string>;
  affiliations: Immutable.List<string>;
  orcidId: Nullable<string>;
  homepage: Nullable<string>;
  lastModifiedDate: Nullable<string>;
  statistics: AuthorStatisticsRecord;
  profilePicture: Nullable<ProfilePictureRecord>;
};

const defaultProps: Props = {
  id: null,
  name: null,
  firstName: null,
  middleNames: Immutable.List(),
  lastName: null,
  slug: null,
  email: null,
  affiliations: Immutable.List(),
  orcidId: null,
  homepage: null,
  lastModifiedDate: null,
  statistics: getEmptyAuthorStatistics(),
  profilePicture: null,
};

export const AuthorRecordFactory = Immutable.Record<Props>(defaultProps);

export type AuthorRecord = Immutable.RecordOf<Props>;

export function getAuthorFromJS(args: AuthorFromJS): AuthorRecord {
  const { affiliations, middleNames, statistics, profilePicture, ...props } = args || {};
  return AuthorRecordFactory({
    ...props,
    affiliations: Immutable.List(affiliations || []),
    middleNames: Immutable.List(middleNames || []),
    statistics: statistics ? getAuthorStatisticsFromJS(statistics) : defaultProps.statistics,
    profilePicture: profilePicture ? getProfilePictureFromJS(profilePicture) : null,
  });
}

export function getAuthorFromGraphQL(args: AuthorFromGraphQL): AuthorRecord {
  const { affiliationNames, fullName, id, profilePicture, ...props } = args;
  return AuthorRecordFactory({
    ...props,
    id: id.toString(),
    name: fullName,
    firstName: null,
    middleNames: Immutable.List(),
    lastName: null,
    affiliations: Immutable.List(affiliationNames),
    statistics: getAuthorStatisticsFromGraphQL(args),
    profilePicture: profilePicture ? getProfilePictureFromGraphQL(profilePicture) : null,
  });
}

export const AUTHOR_FRAGMENT_NAME = 'AuthorRecordFragment';

export const AUTHOR_FRAGMENT = gql`
  ${AUTHOR_STATISTICS_FRAGMENT}
  ${PROFILE_PICTURE_FRAGMENT}

  fragment ${AUTHOR_FRAGMENT_NAME} on Author {
    id
    fullName
    slug
    affiliationNames
    orcidId
    homepage
    profilePicture {
      ...${PROFILE_PICTURE_FRAGMENT_NAME}
    }
    ...${AUTHOR_STATISTICS_FRAGMENT_NAME}
  }
`;
