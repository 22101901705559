import React from 'react';

export default function IconLeaf(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 41 37">
      <path
        d="M38.4609 1.20312C39.8672 4.29688 40.5703 7.67188 40.5703 11.3281C40.5703 17.4688 38.7891 22.7188 35.2266 27.0781C31.7109 31.3906 27.2109 33.7578 21.7266 34.1797C19.1016 34.4609 16.7109 34.0625 14.5547 32.9844C12.4453 31.9062 10.7578 30.5 9.49219 28.7656C7.00781 30.8281 5.32031 32.9375 4.42969 35.0938C4.19531 35.6562 3.77344 36.0547 3.16406 36.2891C2.60156 36.5703 2.03906 36.5938 1.47656 36.3594C0.914062 36.125 0.492188 35.7031 0.210938 35.0938C-0.0234375 34.5312 -0.0234375 33.9688 0.210938 33.4062C0.632812 32.4219 1.21875 31.3906 1.96875 30.3125C2.71875 29.1875 3.84375 27.8984 5.34375 26.4453C6.84375 24.9922 8.53125 23.7031 10.4062 22.5781C12.3281 21.4531 14.7422 20.4922 17.6484 19.6953C20.6016 18.8984 23.7422 18.5 27.0703 18.5C27.3984 18.5 27.6562 18.4062 27.8438 18.2188C28.0781 17.9844 28.1953 17.7031 28.1953 17.375C28.1953 17.0469 28.0781 16.7891 27.8438 16.6016C27.6562 16.3672 27.3984 16.25 27.0703 16.25C19.1484 16.25 12.4453 18.2188 6.96094 22.1562C6.86719 21.5 6.82031 21.0312 6.82031 20.75C6.82031 17 8.13281 13.8125 10.7578 11.1875C13.3828 8.5625 16.5703 7.25 20.3203 7.25H25.9453C28.1484 7.25 30.1641 6.71094 31.9922 5.63281C33.8672 4.50781 35.3672 2.98437 36.4922 1.0625C36.7266 0.6875 37.0547 0.5 37.4766 0.5C37.9453 0.5 38.2734 0.734375 38.4609 1.20312Z"
        fill="#1857B6"
      />
    </symbol>
  );
}
