import { KEY_CODE_ESC } from '@/constants/KeyCode';
import { mkOnClickKeyDown } from '@/utils/a11y-utils';
import { ReactNodeish } from '@/utils/types';
import browser, { getBody } from '@/browser';
import CLDropdownButton, {
  SIZE as _SIZE,
  TYPE as _TYPE,
  CLDropdownButtonProps,
} from '@/components/library/button/CLDropdownButton';
import CLPopover, { ARROW_POSITION, ArrowPosition } from '@/components/library/popover/CLPopover';
import CLPortal, { Coordinates } from '@/components/library/popover/CLPortal';

import classnames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';

export const SIZE = _SIZE;
export const TYPE = _TYPE;

export type CLDropdownBaseProps = React.PropsWithChildren<{
  isDropdownShown?: boolean;
  arrow?: ArrowPosition;
  coordinates?: Coordinates;
  className?: string;
  contents?: () => ReactNodeish;
  disabled?: boolean;
  popover?: (popoverProps: object, contentsNode: ReactNodeish) => ReactNodeish;
  onHideDropdown?: () => void;
  onShowDropdown?: () => void;
  usePortal?: boolean;
}> &
  Omit<CLDropdownButtonProps, 'arrow'>;

type State = {
  isPopoverVisible: boolean;
  arrowPos: ArrowPosition;
  coordinates: Coordinates;
};

export default class CLDropdownBase extends React.PureComponent<CLDropdownBaseProps, State> {
  _blurTimeoutId;
  _popoverAnchorRef;
  _portalObserver;
  _portalRef;
  _resizeObserver;

  constructor(...args: [any]) {
    super(...args);

    this.state = {
      isPopoverVisible: this.isComponentControlled() ? !!this.props.isDropdownShown : false,
      arrowPos: this.props.arrow ? this.props.arrow : ARROW_POSITION.SIDE_TOP_POS_LEFT,
      coordinates: this.props.coordinates ? this.props.coordinates : {},
    };
  }

  componentWillUnmount() {
    if (this._portalObserver) {
      this._portalObserver.disconnect();
      this._portalObserver = null;
    }

    if (this._resizeObserver) {
      this._resizeObserver.disconnect();
      this._resizeObserver = null;
    }
  }

  componentDidUpdate(oldProps) {
    if (this.isComponentControlled() && oldProps.isDropdownShown !== this.props.isDropdownShown) {
      this.setState({ isPopoverVisible: !!this.props.isDropdownShown });
    }
  }

  /*
    This is only used for when usePortal = true.

    We set up an IntersectionObserver to track when the dropdown is opened and the portal is rendered.
    When the IntersectionObserver is triggered, a ResizeObserver is set up to track window resizes
    and updates the coordinates of the portal to keep it positioned correctly.

    Once the dropdown is closed, both observers are unregistered.
  */
  setPortalRef = portalRef => {
    // don't update coordinates on resize if coordinates are passed in props as it will be a controlled component
    if (this.props.coordinates) {
      return;
    }

    if (typeof IntersectionObserver !== 'function') {
      // Browser doesn't support, so don't do anything
      return;
    }
    let observer = this._portalObserver;
    if (!observer) {
      observer = new IntersectionObserver(this.handlePortalIntersection, {});
      this._portalObserver = observer;
    }

    if (portalRef) {
      observer.observe(portalRef);
    } else if (this._portalRef) {
      // unregister from Intersection and Resize observers once the dropdown is closed
      observer.unobserve(this._portalRef);
      const body = getBody(document);
      if (this._resizeObserver && body) {
        this._resizeObserver.unobserve(body);
      }
    }
    this._portalRef = portalRef;
  };

  // set up observer to update coordinates of the portal on window resize
  handlePortalIntersection = () => {
    let resizeObserver = this._resizeObserver;
    if (!resizeObserver) {
      resizeObserver = new ResizeObserver(this.updatePortalCoordinates);
    }
    this._resizeObserver = resizeObserver;

    // register the body as the observed element
    const body = getBody(document);
    if (body) {
      resizeObserver.observe(body);
    }
  };

  updatePortalCoordinates = () => {
    const anchor = ReactDOM.findDOMNode(this);
    if (anchor instanceof HTMLElement) {
      this.setState({
        coordinates: getCenterBottomCoordinates(anchor),
      });
    }
  };

  isComponentControlled(): boolean {
    return typeof this.props.isDropdownShown === 'boolean';
  }

  getPopoverAnchorRef = ref => {
    this._popoverAnchorRef = ref;
    const arrowPos = this.calculateArrowPosition();
    this.setState({ arrowPos });
  };

  calculateArrowPosition(): ArrowPosition {
    // if arrow position was passed in from props then use that otherwise base it off the anchor center point on viewport
    const { arrow } = this.props;
    if (arrow) {
      return arrow;
    }

    // Position based anchor center point on viewport
    const viewport = browser.getViewportSize();
    const anchorRef = this._popoverAnchorRef;
    if (!anchorRef || !viewport || !window) {
      return ARROW_POSITION.SIDE_TOP_POS_LEFT;
    }
    const rect = anchorRef.getBoundingClientRect();

    // Vertical position
    const refCenterY = rect.top + rect.height / 2 + window.scrollY;
    const vpCenterY = window.scrollY + viewport.height / 2;
    const isArrowTop = refCenterY < vpCenterY;

    // Horizontal position
    const refCenterX = rect.left + rect.width / 2 + window.scrollX;
    const vpLeftX = window.scrollX + viewport.width / 3;
    const vpRightX = window.scrollX + (viewport.width / 3) * 2;
    const isOnLeftThird = refCenterX < vpLeftX;
    const isOnRightThird = vpRightX < refCenterX;

    if (isOnLeftThird) {
      return isArrowTop ? ARROW_POSITION.SIDE_TOP_POS_LEFT : ARROW_POSITION.SIDE_BOTTOM_POS_LEFT;
    } else if (isOnRightThird) {
      return isArrowTop ? ARROW_POSITION.SIDE_TOP_POS_RIGHT : ARROW_POSITION.SIDE_BOTTOM_POS_RIGHT;
    }
    return isArrowTop ? ARROW_POSITION.SIDE_TOP_POS_MIDDLE : ARROW_POSITION.SIDE_BOTTOM_POS_MIDDLE;
  }

  onClickDropdownButton = event => {
    const { disabled, isDropdownShown, onShowDropdown, onHideDropdown } = this.props;
    // If button is disabled, just preventDefault and return
    if (disabled) {
      event.preventDefault();
      return;
    }
    // If we are externally controlled, AND we have open and close operations,
    // preventDefault and use those. Otherwise, if we're externally controlled
    // but don't define open and close, fall through WITHOUT preventDefault.
    if (this.isComponentControlled()) {
      if (onShowDropdown && onHideDropdown) {
        event.preventDefault();
        if (isDropdownShown) {
          onHideDropdown();
        } else {
          onShowDropdown();
        }
      }
      return;
    }
    // Otherwise, we are enabled and self-managing: preventDefault and toggle popover state
    event.preventDefault();
    this.setState(({ isPopoverVisible }) => ({ isPopoverVisible: !isPopoverVisible }));
  };

  onClickCapture = event => {
    const { onHideDropdown } = this.props;
    if (this.isComponentControlled()) {
      if (onHideDropdown) {
        event.preventDefault();
        onHideDropdown();
      }
      return;
    }
    event.preventDefault();
    this.setState({ isPopoverVisible: false });
  };

  _onClickKeyDownCaptureProps = mkOnClickKeyDown({
    onClick: this.onClickCapture,
    overrideKeys: [KEY_CODE_ESC],
  });

  _onClickKeyDownDropdownProps = mkOnClickKeyDown({
    onClick: this.onClickDropdownButton,
  });

  onFocus = () => {
    clearTimeout(this._blurTimeoutId);
  };

  onBlur = () => {
    const { onHideDropdown } = this.props;
    // Timeout allows blurring of one component while focusing on the other
    this._blurTimeoutId = setTimeout(() => {
      if (this.isComponentControlled()) {
        if (onHideDropdown) {
          onHideDropdown();
        }
        return;
      }
      this.setState({ isPopoverVisible: false });
    }, 50);
  };

  renderContents(): ReactNodeish {
    const { usePortal, contents, popover } = this.props;
    const { arrowPos, coordinates } = this.state;

    // NOTE: Because we can have both contents() and popover(), we need to pass those nodes
    //       to the popover() function. And since we need control over some props, like
    //       arrowPos, we have to pass those props to the popover() callback. It is the
    //       responsibility of the override to add those props to their popover.
    const popoverProps = {
      arrow: arrowPos,
    };
    const contentsNode = contents ? contents() : null;
    const popoverNode = popover ? popover(popoverProps, contentsNode) : null;

    const dropdownContents = (
      <div className="cl-dropdown__popover__anchor" ref={this.getPopoverAnchorRef}>
        <div
          className="cl-dropdown__popover__click-capture"
          {...this._onClickKeyDownCaptureProps}
        />
        {popoverNode ? popoverNode : <CLPopover {...popoverProps}>{contentsNode}</CLPopover>}
      </div>
    );

    if (!usePortal) {
      return dropdownContents;
    }

    const coords = coordinates || { topPx: 0, leftPx: 0 };
    return (
      <CLPortal coordinates={coords}>
        <div ref={this.setPortalRef}>{dropdownContents}</div>
      </CLPortal>
    );
  }

  render(): ReactNodeish {
    const {
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      arrow,
      children,
      className,
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      isDropdownShown,
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      contents,
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      popover,
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      onHideDropdown,
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      onShowDropdown,

      // NOTE: Below are props we want to pass to the button, like click handlers.
      //       Everything we don't want passed through should be in the list above.
      ...buttonProps
    } = this.props;
    const { isPopoverVisible, arrowPos } = this.state;
    const ariaProps = {
      'aria-expanded': isPopoverVisible,
    };
    return (
      <div
        className={classnames(
          {
            'cl-dropdown': true,
            'cl-dropdown--has-visible-popover': isPopoverVisible,
            [`cl-dropdown--arrow-pos-${arrowPos}`]: true,
          },
          className
        )}>
        <div
          className="cl-dropdown__button"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          {...this._onClickKeyDownDropdownProps}>
          {children ? children : <CLDropdownButton {...buttonProps} ariaProps={ariaProps} />}
        </div>
        <div className="cl-dropdown__popover" onFocus={this.onFocus} onBlur={this.onBlur}>
          {isPopoverVisible && this.renderContents()}
        </div>
      </div>
    );
  }
}

export function getCenterBottomCoordinates(element): Coordinates {
  const coordinates = element.getBoundingClientRect();
  return {
    leftPx: coordinates.left + coordinates.width / 2,
    topPx: browser.offsetFromBody(element) + coordinates.height,
  };
}

/*
  Return the center-bottom position of an HTML element based on its CSS style.
*/
export function getCenterBottomCoordinatesFromStyle(element): Coordinates {
  return {
    leftPx: parseInt(element.style.left) + parseInt(element.style.width) / 2,
    topPx: parseInt(element.style.top) + parseInt(element.style.height),
  };
}
