import { getHeapIdsFromNode } from './heapUtils';

import { getLayoverMetrics } from '@/utils/layover/LayoverMetrics';
import { incrementCounter, PERF_TIMING_NO_SUPPORT } from '@/utils/counter';
import { isBrowser } from '@/utils/env';
import { loadEventFired, performanceTimingReady } from '@/browser';
import { PublicPromise } from '@/utils/promise-utils';
import { taggedSoftError } from '@/utils/softError';

import { type Metric, onCLS, onFCP, onFID, onINP, onLCP } from 'web-vitals';
import idx from 'idx';

import type { Nullable } from '@/utils/types';

const softError = taggedSoftError('appLoadMetrics');

export async function trackPerformanceMetrics(): Promise<void> {
  if (!isBrowser()) {
    softError('trackPerformanceMetrics called from server');
    return;
  }
  await loadEventFired();
  await Promise.all([trackPerfTimingMetrics(), trackSingleFireMetrics(), trackMultiFireMetrics()]);
}

export async function trackPerfTimingMetrics(): Promise<void> {
  await performanceTimingReady();
  const timing = idx(window, _ => _.performance.timing);
  if (!timing) {
    softError('browser does not support performance.timing');
    incrementCounter(PERF_TIMING_NO_SUPPORT);
    return;
  }

  // See docs for info on timing of events
  // Timeline Chart: https://www.w3.org/TR/navigation-timing/timing-overview.png
  // Spec: https://www.w3.org/TR/navigation-timing/#sec-navigation-timing-interface
  const {
    navigationStart,
    unloadEventStart,
    unloadEventEnd,
    redirectStart,
    redirectEnd,
    // fetchStart,
    domainLookupStart,
    domainLookupEnd,
    connectStart,
    connectEnd,
    secureConnectionStart,
    requestStart,
    responseStart,
    responseEnd,
    domLoading,
    domInteractive,
    domContentLoadedEventStart,
    domContentLoadedEventEnd,
    domComplete,
    loadEventStart,
    loadEventEnd,
  } = timing;

  // Legacy Boomr metrics
  sendMetricIfValidTiming('timeToFirstByte', navigationStart, responseStart);
  sendMetricIfValidTiming('timeOfDnsLookup', domainLookupStart, domainLookupEnd);
  sendMetricIfValidTiming('timeOfConnection', connectStart, connectEnd);
  sendMetricIfValidTiming('timeOfTlsHandshake', secureConnectionStart, connectEnd); // NOTE: This metric was incorrect in boomr; it measured the TCP handshake, not TLS
  sendMetricIfValidTiming('timeToDomComplete', navigationStart, domComplete);
  sendMetricIfValidTiming('timeToDomInteractive', navigationStart, domInteractive);
  sendMetricIfValidTiming('timeToLoadEvent', navigationStart, loadEventStart);

  // Navigation metrics
  sendMetricIfValidTiming('timeToDomLoading', navigationStart, domLoading);
  sendMetricIfValidTiming(
    'timeToDomContentLoadedStart',
    navigationStart,
    domContentLoadedEventStart
  );
  sendMetricIfValidTiming('timeToDomContentLoadedStart', navigationStart, domContentLoadedEventEnd);

  // Stage Metrics
  sendMetricIfValidTiming('previousUnload', unloadEventStart, unloadEventEnd);
  sendMetricIfValidTiming('timeOfRedirect', redirectStart, redirectEnd);
  sendMetricIfValidTiming('timeOfTcpHandshake', connectStart, secureConnectionStart); // NOTE: This used to be the TLS handshake metrics, which was erroneously named
  sendMetricIfValidTiming('timeOfRequestToFirstByte', requestStart, responseStart);
  sendMetricIfValidTiming('timeOfRequestToLastByte', requestStart, responseEnd);
  sendMetricIfValidTiming('timeOfResponseDownload', responseStart, responseEnd);
  sendMetricIfValidTiming('timeOfResponseToLoadEndEvent', responseEnd, loadEventEnd);
}

// Send metric to Layover if the duration is valid (e.g. end - start >= 0)
function sendMetricIfValidTiming(metricName: string, startTimeMs: number, endTimeMs: number): void {
  if (!startTimeMs || !endTimeMs || endTimeMs < startTimeMs) {
    return;
  }
  getLayoverMetrics().sendTiming(metricName, startTimeMs, endTimeMs);
}

export async function trackSingleFireMetrics(): Promise<void> {
  const metrics = getLayoverMetrics();
  const proms: Promise<void>[] = [];

  proms.push(
    getFirstContentfulPaint()
      .then(fcp => {
        if (typeof fcp === 'number') {
          metrics.sendTiming('firstContentfulPaint', 0, fcp);
        }
      })
      .catch(error =>
        softError(
          `failed to get first contentful paint metric [message=${error.message as string}]`
        )
      )
  );

  proms.push(
    getLargestContentfulPaint()
      .then(lcp => {
        if (typeof lcp === 'number') {
          metrics.sendTiming('largestContentfulPaint', 0, lcp);
        }
      })
      .catch(error =>
        softError(
          `failed to get largest contentful paint metric [message=${error.message as string}]`
        )
      )
  );

  proms.push(
    getFirstInputDelay()
      .then(fid => {
        if (typeof fid === 'number') {
          metrics.sendTiming('firstInputDelay', 0, fid);
        }
      })
      .catch(error =>
        softError(`failed to get first input delay metric [message=${error.message as string}]`)
      )
  );

  proms.push(
    getCumulativeLayoutShift()
      .then(cls => {
        if (typeof cls === 'number') {
          metrics.sendDistribution('cumulativeLayoutShift', cls);
        }
      })
      .catch(error =>
        softError(
          `failed to get cumulative layout shift metric [message=${error.message as string}]`
        )
      )
  );

  return void (await Promise.all(proms));
}

export function trackMultiFireMetrics(): void {
  const metrics = getLayoverMetrics();

  (async () => {
    for await (const inp of watchInteractionToNextPaint()) {
      const tags: string[] = [];
      if (inp.closestHeapId) {
        tags.push(`closest_heap_id:${inp.closestHeapId}`);
      }
      metrics.sendDistribution('interactionToNextPaint', inp.value, tags);
    }
  })();
}

// Measures the time from when the page starts loading to when any part of the
// page's content is rendered on the screen
// https://web.dev/fcp/
export async function getFirstContentfulPaint(): Promise<number> {
  const metric: Metric = await new Promise(resolve => onFCP(resolve));
  return metric.value;
}

// When the largest content element in the viewport is rendered to the screen.
// https://web.dev/lighthouse-largest-contentful-paint
export async function getLargestContentfulPaint(): Promise<number> {
  const metric: Metric = await new Promise(resolve => onLCP(resolve));
  return metric.value;
}

// Measures the time from when a user first interacts with your site to the time
// when the browser is actually able to respond to that interaction.
// https://web.dev/fid/
export async function getFirstInputDelay(): Promise<number> {
  const metric: Metric = await new Promise(resolve => onFID(resolve));
  return metric.value;
}

// The sum of the individual layout shift scores for each unexpected layout
// shift that occurs between when the page starts loading.
// https://web.dev/cls
export async function getCumulativeLayoutShift(): Promise<number> {
  const metric: Metric = await new Promise(resolve => onCLS(resolve));
  return metric.value;
}

// INP observes the latency of all interactions a user has made with the page,
// and reports a single value which all (or nearly all) interactions were below.
// https://web.dev/inp/
export async function* watchInteractionToNextPaint(): AsyncGenerator<
  { closestHeapId: Nullable<string>; value: number },
  never
> {
  let pubProm = new PublicPromise<Metric>();
  onINP(
    metric => {
      const oldProm = pubProm;
      pubProm = new PublicPromise();
      oldProm.resolve(metric);
    },
    {
      durationThreshold: 16, // 16 ms = 1 frame at 60 Hz
      reportAllChanges: true,
    }
  );

  while (true) {
    const metric = await pubProm;
    yield {
      closestHeapId: findClosestHeapIdForMetric(metric),
      value: metric.value,
    };
  }
}

function findClosestHeapIdForMetric(metric: Metric): Nullable<string> {
  for (const entry of metric.entries) {
    if ('target' in entry) {
      const heapIds = getHeapIdsFromNode(entry.target);
      if (heapIds.length > 0) {
        return heapIds[0];
      }
    }
  }
  return null;
}
