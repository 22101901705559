// This should be kept in sync with a corresponding scala-side model LibraryEntry.SourceType
import { Nullable, ValueOf } from '@/utils/types';

import invariant from 'invariant';

export type LibraryEntrySourceType = ValueOf<typeof byKey>;

export const AuthorLibraryFolder = 'AuthorLibraryFolder';
export const Feed = 'Feed';
export const Library = 'Library';
export const Recommendation = 'Recommendation';
export const Unsupported = 'Unsupported';

const byKey = {
  AuthorLibraryFolder,
  Feed,
  Library,
  Recommendation,
  Unsupported,
} as const;

export const LibraryEntrySourceTypeMap = Object.freeze(byKey);

export function getOptLibraryEntrySourceType(
  optSource?: Nullable<string>
): Nullable<LibraryEntrySourceType> {
  if (optSource && optSource in byKey) {
    return byKey[optSource];
  }
  return null;
}

export function getLibraryEntrySourceType(optSource?: Nullable<string>): LibraryEntrySourceType {
  const expSource = getOptLibraryEntrySourceType(optSource);
  invariant(expSource, `"${optSource || ''}" is not a valid LibraryEntrySourceType`);
  return expSource;
}
