import { getLayoverLogger } from './layover/LayoverLogger';
import { isBrowser } from './env';

import logger from '@/logger';

const VALID_TAG_FORMAT = /^[a-zA-Z0-9.]+$/;

export default function softError(tag: string, msg: string, ...args: any[]): void {
  const isValidTag = VALID_TAG_FORMAT.test(tag);
  if (!isValidTag) {
    logger.warn(`soft error tag format is invalid [tag="${tag}"]`);
  }
  const stack = new Error().stack;
  logger.error(tag, msg, stack, args);
  getLayoverLogger().log('softError.' + tag, { msg, stack, args });
}

export function taggedSoftError(tag: string) {
  return (msg: string, ...args: any[]): void => {
    return softError(tag, msg, ...args);
  };
}

export function softInvariant(test: boolean, tag: string, msg: string, ...args: any[]): boolean {
  if (!test) {
    softError(tag, msg, ...args);
  }
  return test;
}

export function taggedSoftInvariant(tag: string) {
  return (test: boolean, msg: string, ...args: any[]) => {
    return softInvariant(test, tag, msg, ...args);
  };
}

const tagsThatHaveSoftErrored = new Set<string>();

export function softErrorOnceInBrowser(tag: string, msg: string, ...args: any[]): void {
  if (!isBrowser()) {
    // Only log in the client
    return;
  }
  if (tagsThatHaveSoftErrored.has(tag)) {
    // Soft error has already logged
    return;
  }

  // Soft error has not yet logged
  tagsThatHaveSoftErrored.add(tag);
  return softError(tag, msg, ...args);
}
