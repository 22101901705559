import AddFolderForm from './AddFolderForm';
import LibraryShelfFolder from './LibraryShelfFolder';
import RemoveFromLibraryButton from './RemoveFromLibraryButton';

import {
  ApiResponse,
  CreateLibraryEntryBulkResponseBody,
  RemoveEntriesFromFoldersResponseBody,
} from '@/api/ApiResponse';
import {
  DENSITY as BUTTON_DENSITY,
  FONT_SIZE as BUTTON_FONT_SIZE,
} from '@/components/library/button/CLButtonBase';
import { getAddLibraryEntryBulkRequestFromJS } from '@/models/library/AddLibraryEntryBulkRequest';
import { getString } from '@/content/i18n';
import {
  heapLibraryShelfAddFolderButton,
  heapLibraryShelfFolderFilterInput,
  heapLibraryShelfRemoveFromLibraryButton,
  heapLibraryShelfSaveAndCloseButton,
} from '@/analytics/attributes/libraryShelf';
import { hideShelf } from '@/actions/ShelfActionCreators';
import { isAllPapersFolder } from '@/models/library/LibraryFolderSourceType';
import { Library } from '@/models/library/LibraryEntrySourceType';
import { LibraryFolderRecord } from '@/models/library/LibraryFolder';
import { Nullable, ReactNodeish } from '@/utils/types';
import { ORGANIZE_PAPERS } from '@/constants/ShelfId';
import { OrganizePapersShelfContext } from '@/models/shelf/ShelfContexts';
import Api from '@/api/Api';
import CLButton, { TYPE as BUTTON_TYPE } from '@/components/library/button/CLButton';
import CLIconButton from '@/components/library/button/CLIconButton';
import CLIconTextInput from '@/components/library/form/input/CLIconTextInput';
import Constants from '@/constants';
import EnvInfo from '@/env/EnvInfo';
import EventTarget from '@/analytics/constants/EventTarget';
import FormLevelMessage from '@/components/shared/message/FormLevelMessage';
import Icon from '@/components/shared/icon/Icon';
import LibraryFolderStore from '@/stores/LibraryFolderStore';
import S2Dispatcher from '@/utils/S2Dispatcher';
import Shelf from '@/components/shared/shelf/Shelf';
import ShowEvent from '@/analytics/models/ShowEvent';
import softError from '@/utils/softError';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import classnames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import type { WillRouteToResult } from '@/router/Route';

type Props = {
  context: OrganizePapersShelfContext;
};

type StateFromLibraryFolderStore = {
  folderList: Immutable.List<LibraryFolderRecord>; // All of the user's folders, sorted in render order
  folderIdsForPaper: Immutable.Set<number>; // Folder IDs which have the paper from props
};

type State = {
  errorMessage: Nullable<string>;
  isAddFolderFormOpen: boolean;
  filterText: string;
  filteredFolderIds: Nullable<Immutable.Set<number>>; // Folder IDs which match the filter (or null without a filter)
  folderSelectionByFolderId: Nullable<Immutable.Map<number, boolean>>; // Folder IDs which the user has selected (or null if no changes)
  isSending: boolean;
} & StateFromLibraryFolderStore;

export default class OrganizePapersShelf extends React.PureComponent<Props, State> {
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    libraryFolderStore: PropTypes.instanceOf(LibraryFolderStore).isRequired,
  };

  declare context: {
    api: Api;
    dispatcher: S2Dispatcher;
    envInfo: EnvInfo;
    libraryFolderStore: LibraryFolderStore;
  };

  constructor(...args: [any]) {
    super(...args);

    this.state = {
      errorMessage: null,
      isAddFolderFormOpen: false,
      filterText: '',
      filteredFolderIds: null,
      folderSelectionByFolderId: null,
      isSending: false,
      ...this.getStateFromLibraryFolderStore(),
    };

    this.context.libraryFolderStore.registerComponent(this, () => {
      this.setState(this.getStateFromLibraryFolderStore());
    });
  }

  getStateFromLibraryFolderStore(): StateFromLibraryFolderStore {
    const { libraryFolderStore } = this.context;
    const {
      context: { paperId },
    } = this.props;

    const folderList = libraryFolderStore
      .getFolders()
      .filterNot(_ => isAllPapersFolder(_))
      .valueSeq()
      .toList();
    const folderIdsForPaper = libraryFolderStore
      .getFoldersWithPaperId(paperId)
      .map(folder => folder.id)
      .toSet();

    return {
      folderList,
      folderIdsForPaper,
    };
  }

  componentDidMount(): void {
    trackAnalyticsEvent(ShowEvent.create(EventTarget.Library.Shelf.ORGANIZE_PAPERS_VIEW));
  }

  onClickFolder = (folder: LibraryFolderRecord): void => {
    const isFolderChecked = this.shouldFolderIdBeChecked(folder.id);
    const oldFolderSelections = this.state.folderSelectionByFolderId || Immutable.Map();
    this.setState({
      folderSelectionByFolderId: oldFolderSelections.set(folder.id, !isFolderChecked),
    });
  };

  createLibraryEntry({
    paperId,
    paperTitle,
    folderIds,
  }: {
    paperId: string;
    paperTitle: string;
    folderIds: Immutable.List<number>;
  }): Promise<ApiResponse<CreateLibraryEntryBulkResponseBody>> {
    const { api } = this.context;
    return api.createLibraryEntryBulk(
      getAddLibraryEntryBulkRequestFromJS({
        paperId,
        paperTitle,
        folderIds: folderIds.toArray(),
        sourceType: Library,
      })
    );
  }

  removePaperFromFolders(
    folderIds: Immutable.List<number>
  ): Promise<ApiResponse<RemoveEntriesFromFoldersResponseBody>> {
    const { api } = this.context;
    const {
      context: { paperId },
    } = this.props;
    return api.removeEntriesFromFolders({
      paperIds: Immutable.List([paperId]),
      folderIds,
    });
  }

  async organizePapers(
    selectedFolderIds: Immutable.List<number>,
    deselectedFolderIds: Immutable.List<number>
  ): Promise<WillRouteToResult> {
    const {
      context: { paperId, paperTitle },
    } = this.props;
    const promises: Promise<WillRouteToResult>[] = [];
    if (!selectedFolderIds.isEmpty()) {
      promises.push(
        this.createLibraryEntry({
          paperId,
          paperTitle,
          folderIds: selectedFolderIds,
        })
      );
    }

    if (!deselectedFolderIds.isEmpty()) {
      promises.push(this.removePaperFromFolders(deselectedFolderIds));
    }

    await Promise.all(promises);
  }

  onClickAddFolderButton = (event: React.MouseEvent): void => {
    event.preventDefault();

    this.setState(prevState => ({
      isAddFolderFormOpen: !prevState.isAddFolderFormOpen,
    }));
  };

  onClickCloseForm = (event: React.MouseEvent): void => {
    event.preventDefault();
    this.setState({ isAddFolderFormOpen: false });
  };

  onClickRemoveFromLibrary = async (): Promise<void> => {
    const { api } = this.context;
    const {
      context: { paperId },
    } = this.props;

    await api.deleteLibraryEntriesBulk(paperId);
  };

  onClickSave = (): void => {
    const {
      context: { paperId },
    } = this.props;
    const { api, dispatcher } = this.context;
    const { folderList, folderSelectionByFolderId, folderIdsForPaper } = this.state;
    this.setState({ isSending: true });

    if (!folderSelectionByFolderId) {
      // User has made no changes, so just hide the shelf
      this.hideOrganizeShelf();
      return;
    }

    // Split lists by checked and unchecked by a user
    const allFolderIds = folderList.map(folder => folder.id).toSet();
    const selectedFolderIds = folderSelectionByFolderId
      .filter(isSelected => isSelected) // Keep only selected folders
      .filter((isSelected, folderId) => allFolderIds.has(folderId)) // Remove selections for folders that no longer exist
      .filter((isSelected, folderId) => !folderIdsForPaper.has(folderId)) // Remove selections that would create a duplicate entry
      .keySeq()
      .toList();
    const deselectedFolderIds = folderSelectionByFolderId
      .filter(isSelected => !isSelected) // Keep only unselected folders
      .filter((isSelected, folderId) => allFolderIds.has(folderId)) // Remove selections for folders that no longer exist
      .filter((isSelected, folderId) => folderIdsForPaper.has(folderId)) // Remove selections that would remove a non-existent entry
      .keySeq()
      .toList();

    this.organizePapers(selectedFolderIds, deselectedFolderIds)
      .then(() => {
        this.hideOrganizeShelf();
        api.getLibraryFolders();
        this.setState({ isSending: false });
      })
      .catch(error => {
        const selectedFolderStr = selectedFolderIds.join(', ');
        const deselectedFolderStr = deselectedFolderIds.join(', ');
        softError(
          'library',
          `failed to organize library[paperId="${paperId}, deselectedFolderIds=${deselectedFolderStr}, selectedFolderIds=${selectedFolderStr} ${error.status}"]`,
          error
        );
        this.setState({
          errorMessage: getString(_ => _.library.organizePapersShelf.errorMessage),
          isSending: false,
        });
      });

    dispatcher.dispatch({
      actionType: Constants.actions.RESEARCH_LIST_UNSELECT_ALL,
    });
  };

  onChangeFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const filterText = event.target.value;
    const { folderList } = this.state;

    // Do an exact text match on folder names
    const hasFilterText = filterText.trim().length > 0;
    const foldersMatchingFilter = hasFilterText
      ? folderList.filter(folder =>
          folder.name.toLowerCase().includes(filterText.trim().toLowerCase())
        )
      : folderList;

    const filteredFolderIds = foldersMatchingFilter.map(folder => folder.id).toSet();
    this.setState({
      filterText,
      filteredFolderIds,
    });
  };

  hideOrganizeShelf = (): void => {
    this.context.dispatcher.dispatch(hideShelf());
  };

  // Determine if a given folder should be checked or not
  shouldFolderIdBeChecked(folderId) {
    const { folderIdsForPaper, folderSelectionByFolderId } = this.state;
    if (folderSelectionByFolderId && folderSelectionByFolderId.has(folderId)) {
      return folderSelectionByFolderId.get(folderId);
    }
    return folderIdsForPaper.has(folderId);
  }

  render(): ReactNodeish {
    const {
      filterText,
      isAddFolderFormOpen,
      errorMessage,
      folderList,
      filteredFolderIds,
      isSending,
    } = this.state;

    const {
      context: { paperId, paperTitle },
    } = this.props;

    const { isMobile } = this.context.envInfo;

    // Determine which folders to render
    const foldersToRender = filteredFolderIds
      ? folderList.filter(folder => filteredFolderIds.contains(folder.id))
      : folderList;

    // Determine what folders should be selected
    const selectedFolderIds = foldersToRender
      .map(folder => folder.id)
      .filter(folderId => this.shouldFolderIdBeChecked(folderId))
      .toSet();

    return (
      <Shelf
        ariaLabel={getString(_ => _.library.organizePapersShelf.closeShelfAriaLabel)}
        shelfId={ORGANIZE_PAPERS}
        className="organize-papers__shelf"
        header={getString(_ => _.library.organizePapersShelf.headerText)}>
        <div className="library__shelf__scroll-container">
          <div className="organize-papers__bordered-section">
            {!!errorMessage && (
              <div className="organize-papers__error-msg" data-test-id="organize-papers-save-error">
                <FormLevelMessage message={errorMessage} />
              </div>
            )}
            <div className="flex-row-vcenter flex-space-between">
              <p className="shelf__secondary-header">
                {getString(_ => _.library.organizePapersShelf.subHeadText)}
              </p>
              <CLIconButton
                icon={<Icon icon="folder-add" height="12" width="16" />}
                {...heapLibraryShelfAddFolderButton()}
                label={getString(_ => _.library.organizePapersShelf.addNewFolderLabel)}
                testId="organize-papers-add-folder-button"
                className="organize-papers__add-folder link-button--no-underline"
                type={BUTTON_TYPE.TERTIARY}
                onClick={this.onClickAddFolderButton}
              />
            </div>
            {isAddFolderFormOpen && <AddFolderForm onClickClose={this.onClickCloseForm} />}
            <CLIconTextInput
              className={classnames('organize-papers__filter', {
                'organize-papers__filter--mobile': isMobile,
              })}
              data-test-id="organize-papers-filter-input"
              icon={<Icon icon="search-small" height="16" width="16" />}
              {...heapLibraryShelfFolderFilterInput()}
              onChange={e => this.onChangeFilter(e)}
              placeholder={getString(_ => _.library.organizePapersShelf.filterPlaceholderText)}
              value={filterText}
            />
            {foldersToRender.size > 0 && (
              <div className="organize-papers__folders">
                {foldersToRender.map(folder => (
                  <LibraryShelfFolder
                    key={folder.id}
                    folder={folder}
                    onChangeFolderRow={this.onClickFolder}
                    isChecked={selectedFolderIds.has(folder.id)}
                  />
                ))}
              </div>
            )}

            {foldersToRender.size === 0 && filterText.length !== 0 && (
              <div className="organize-papers__folders-not-found_wrapper">
                <Icon icon="folder-not-found" height="156" width="156" />
                <h4 className="organize-papers__folders-no-folders-title">
                  {getString(_ => _.library.organizePapersShelf.noFolderFound, filterText)}
                </h4>
              </div>
            )}
          </div>
          <div className="organize-papers__bordered-section">
            <CLButton
              type={BUTTON_TYPE.PRIMARY}
              label={getString(_ => _.library.organizePapersShelf.saveAndCloseButtonLabel)}
              fontSize={isMobile ? BUTTON_FONT_SIZE.MEDIUM : BUTTON_FONT_SIZE.DEFAULT}
              density={isMobile ? BUTTON_DENSITY.COMFORTABLE : BUTTON_DENSITY.DEFAULT}
              onClick={this.onClickSave}
              testId="organize-papers-save-button"
              {...heapLibraryShelfSaveAndCloseButton()}
              className="organize-papers__save-button"
              disabled={isSending}
            />
            <RemoveFromLibraryButton
              className="organize-papers__remove-from-library-button"
              onSaveClick={this.hideOrganizeShelf}
              paperId={paperId}
              paperTitle={paperTitle}
              heapProps={heapLibraryShelfRemoveFromLibraryButton()}
              buttonLabel={getString(
                _ => _.library.organizePapersShelf.removeFromLibraryButtonLabel
              )}
            />
          </div>
        </div>
      </Shelf>
    );
  }
}
