import { addHeapProps } from '@/components/util/HeapTracking';
import { getString } from '@/content/i18n';
import CLIconButton, { SHAPE, SIZE, TYPE } from '@/components/library/button/CLIconButton';
import Icon from '@/components/shared/icon/Icon';
import logger from '@/logger';

import classnames from 'classnames';
import React from 'react';

export default function CLModalContainer({
  ariaProps = {},
  children,
  className,
  heapProps = {},
  heightPx,
  onClickClose,
  widthPx,
}) {
  const style = {};
  if (typeof widthPx === 'number') {
    style.width = `${widthPx}px`;
  }

  if (typeof heightPx === 'number') {
    style.height = `${heightPx}px`;
  }
  const closeAriaProps = { 'aria-label': getString(_ => _.componentLibrary.modal.closeAriaLabel) };

  return (
    <div
      role="dialog"
      className="flex-row"
      {...(heapProps ? addHeapProps(heapProps) : {})}
      {...ariaProps}>
      <div className={classnames('cl-modal__content', className)} style={style}>
        {children}
      </div>
      <div className="cl-modal__close-section">
        <CLIconButton
          ariaProps={closeAriaProps}
          type={TYPE.PRIMARY}
          icon={<Icon icon="x" width="16" height="16" />}
          shape={SHAPE.CIRCLE}
          size={SIZE.LARGE}
          className={classnames(`cl-modal__circle-button`, 'cl-modal__close-button')}
          onClick={onClickClose}
        />
      </div>
    </div>
  );
}

export const exampleConfig = {
  getComponent: async () => CLModalContainer,
  fields: [
    {
      name: 'heightPx',
      desc: 'Height (in px) of the modal',
      value: {
        type: 'number',
        default: null,
      },
    },
    {
      name: 'widthPx',
      desc: 'Width (in px) of the modal',
      value: {
        type: 'number',
        default: null,
      },
    },
    {
      name: 'children',
      desc: 'Content to be displayed within the component',
      value: {
        type: 'jsx',
        default: '',
        // eslint-disable-next-line react/no-danger
        transform: html => <div dangerouslySetInnerHTML={{ __html: html }} />,
      },
    },
  ],

  examples: [
    {
      title: 'General',
      desc: 'Modal with some text',
      props: {
        widthPx: 400,
        children: `<div style="margin: 10px">'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'</div>`,
        onClickClose: () => {
          logger.info('click close');
        },
      },
      render: comp => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
          }}>
          {comp}
        </div>
      ),
    },
  ],

  events: {},
};
