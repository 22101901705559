import { fetchAuthorCitingAuthorsUntilPageNumber } from '@/actions/AuthorActionCreators';
import { MAX_CITING_AUTHORS_PER_PAGE } from '@/stores/author/AuthorCitingAuthorsStore';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

import type { ReactNodeish } from '@/utils/types';
import type { WillRouteToResult } from '@/router/Route';
import type AppContext from '@/AppContext';

export default class AuthorCitingAuthorsRoute extends React.Component {
  static async willRouteTo(appContext: AppContext, routerState): Promise<WillRouteToResult> {
    const { authorId } = routerState.params;
    const { page } = routerState.query;
    const { authorCitingAuthorsStore } = appContext;

    const promises: Promise<WillRouteToResult>[] = [];

    if (!authorCitingAuthorsStore.isAuthorLoaded(authorId) || page) {
      const pageNumber = page ? parseInt(page, 10) : 1;
      promises.push(
        fetchAuthorCitingAuthorsUntilPageNumber(
          {
            authorId,
            limit: MAX_CITING_AUTHORS_PER_PAGE,
            pageNumber,
          },
          appContext
        )
      );
    }

    return Promise.all(promises);
  }

  render(): ReactNodeish {
    return (
      <AsyncLoadedPage
        showLoadingPage={false}
        load={{
          importer: () =>
            import(
              /* webpackChunkName: "shared-AuthorCitingAuthorsPage" */ './AuthorCitingAuthorsPage'
            ),
          chunkName: 'shared-AuthorCitingAuthorsPage',
          moduleId: require.resolveWeak('./AuthorCitingAuthorsPage'),
        }}
      />
    );
  }
}
