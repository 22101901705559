import classnames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class Input extends PureComponent {
  static propTypes = {
    inputClassName: PropTypes.string,
    testId: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    bottomHelpText: PropTypes.string,
    placeholder: PropTypes.string,
    isRequired: PropTypes.bool,
    isDisabled: PropTypes.bool,
    value: PropTypes.string,
    errors: PropTypes.instanceOf(Immutable.List),
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
  };

  static defaultProps = {
    type: 'text',
    errors: Immutable.List([]),
  };

  onChange = e => {
    this.props.onChange(e.target.value);
  };

  focus() {
    this.textInput.focus();
  }

  render() {
    const {
      inputClassName,
      className,
      type,
      label,
      bottomHelpText,
      placeholder,
      isRequired,
      isDisabled,
      value,
      errors,
      testId,
      name,
    } = this.props;

    const optional = !isRequired ? <span className="optional-label">Optional</span> : null;
    const hasErrors = errors.size > 0;
    const classes = classnames(className, {
      'has-errors': hasErrors,
      's2-form-input': type !== 'hidden',
    });
    const inputClasses = classnames('legacy__input', 'input', inputClassName);

    return (
      <div className={classes}>
        {/* if there are multiple errors, show only first one. Successive errors will display after first one gets resolved */}
        {(label || hasErrors) && (
          <label htmlFor={label} className="label">
            <span className="input-label-text">
              {label} {optional}
            </span>
            {hasErrors && (
              <span className="input-error" data-test-id="input-error">
                {errors.first()}
              </span>
            )}
          </label>
        )}
        <input
          id={label}
          data-test-id={testId ? testId : undefined}
          ref={input => (this.textInput = input)}
          className={inputClasses}
          type={type}
          placeholder={placeholder}
          required={isRequired}
          disabled={isDisabled}
          value={value}
          onChange={this.onChange}
          name={name}
        />
        {bottomHelpText && <span className="help-text-bottom">{bottomHelpText}</span>}
      </div>
    );
  }
}
