import { getRelatedEntityFromJS, RelatedEntityRecord } from './RelatedEntity';
import { getTimelinePaperFromJS, TimelinePaperRecord } from './TimelinePaper';

import { LinkProps, LinkRecord, LinkRecordFactory } from '@/models/Link';
import SkipperExperiments from '@/skipper/models/SkipperExperiments';

import Immutable from 'immutable';

export type FoundEntityFromJS = {
  id: string;
  name: string;
  slug: string;
  definition: string;
  definitionText: string;
  alternativeNames: string[];
  sourceUrls: LinkProps[];
  relatedEntities: any[];
  papers: any[];
  skipperExperiments: any;
};

type Props = {
  id: string;
  name: string;
  slug: string;
  definition: string;
  definitionText: string;
  alternativeNames: Immutable.Set<string>;
  sourceUrls: Immutable.Set<LinkRecord>;
  relatedEntities: Immutable.Set<RelatedEntityRecord>;
  papers: Immutable.Seq<number, TimelinePaperRecord>;
  skipperExperiments: SkipperExperiments;
};

const defaultProps: Props = {
  id: '',
  name: '',
  slug: '',
  definition: '',
  definitionText: '',
  alternativeNames: Immutable.Set(),
  sourceUrls: Immutable.Set(),
  relatedEntities: Immutable.Set(),
  papers: Immutable.Seq(),
  skipperExperiments: SkipperExperiments.EMPTY,
};

export const FoundEntityRecordFactory = Immutable.Record<Props>(defaultProps);
export type FoundEntityRecord = Immutable.RecordOf<Props>;

export function getFoundEntityFromJS(args: FoundEntityFromJS): FoundEntityRecord {
  const { alternativeNames, sourceUrls, relatedEntities, papers, skipperExperiments } = args;

  return FoundEntityRecordFactory({
    ...args,
    alternativeNames: Immutable.Set(alternativeNames),
    sourceUrls: Immutable.Set(sourceUrls.map(LinkRecordFactory)),
    relatedEntities: Immutable.OrderedSet(relatedEntities.map(getRelatedEntityFromJS)),
    papers: Immutable.Seq(papers.map(getTimelinePaperFromJS)),
    skipperExperiments: SkipperExperiments.fromJS(skipperExperiments),
  });
}
