import { Nullable } from '@/utils/types';

import Immutable from 'immutable';
import invariant from 'invariant';

export type SaveToLibraryTriggerContextProps = {
  paperId: string;
  folderId: Nullable<number>;
};

const defaultProperties: SaveToLibraryTriggerContextProps = {
  paperId: '',
  folderId: null,
};

export const SaveToLibraryTriggerContextFactory =
  Immutable.Record<SaveToLibraryTriggerContextProps>(defaultProperties);
export type SaveToLibraryTriggerContext = Immutable.RecordOf<SaveToLibraryTriggerContextProps>;

export function getSaveToLibraryTriggerContextFromJS(
  args?: SaveToLibraryTriggerContextProps
): SaveToLibraryTriggerContext {
  if (!args || typeof args !== 'object') {
    throw new Error('cannot validate a SaveToLibraryTriggerContext without a context');
  }

  const { paperId, folderId } = args;
  invariant(
    typeof paperId === 'string',
    `context 'paperId' must be a string [paperId=%s]`,
    paperId
  );

  if (folderId) {
    invariant(
      typeof folderId === 'number',
      `context 'folderId' must be a number [folderId=%s]`,
      folderId
    );
  }

  return SaveToLibraryTriggerContextFactory({
    paperId,
    folderId,
  });
}

export function encodeSaveToLibraryTriggerContext(context: SaveToLibraryTriggerContext): string {
  return JSON.stringify(context);
}
