import { fetchUserData } from '@/actions/UserActionCreators';
import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

export default class AuthorClaimCompleteRoute extends React.Component {
  static getPageTitle = () => getString(_ => _.authorClaimComplete.pageTitle);

  static requiresAuthentication = () => true;

  static async willRouteTo({ api, authStore, graphQLApi, weblabStore }) {
    return fetchUserData({ api, authStore, graphQLApi, weblabStore });
  }

  render() {
    return (
      <AsyncLoadedPage
        footer={false}
        load={{
          importer: () =>
            import(
              /* webpackChunkName: "desktop-AuthorClaimCompletePage" */ './AuthorClaimCompletePage'
            ),
          chunkName: 'desktop-AuthorClaimCompletePage',
          moduleId: require.resolveWeak('./AuthorClaimCompletePage'),
        }}
      />
    );
  }
}
