import ReaderPDPButton from './button/ReaderPDPButton';

import { ReactNodeish } from '@/utils/types';
import AccountMenu from '@/components/shared/account/AccountMenu';

import React from 'react';

type Props = {
  paperId?: string;
};

export default function ReaderHeaderStatic({ paperId }: Props): ReactNodeish {
  return (
    <div className="reader-header">
      <div className="reader-header__primary-bar">
        <div className="reader-header__primary-bar__nav">
          <ReaderPDPButton paperId={paperId} />
        </div>
        <div className="reader-header__primary-bar__menus">
          <AccountMenu isReader={true} />
        </div>
      </div>
    </div>
  );
}
