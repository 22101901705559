import Icon from '@/components/shared/icon/Icon';

import classnames from 'classnames';
import React from 'react';

type Props = {
  animating?: boolean;
  className?: string;
  height?: string;
  testId?: string;
  width?: string;
};

const LoadingIndicator = ({
  animating = true,
  width = '16',
  height = '16',
  className,
  testId,
}: Props) => (
  <Icon
    icon="gear"
    testId={testId}
    className={classnames('loading-indicator', className, { 'is-animating': animating })}
    // These are defaults, but you can size via className
    width={width}
    height={height}
  />
);

export default LoadingIndicator;
