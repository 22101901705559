import { CommonQueryProps } from './QueryUtils';
import { parseYears } from './Query';
import { YearRangeRecord, YearRangeRecordFactory } from './YearRange';

import { Nullable } from '@/utils/types';
import Citation from '@/constants/Citation';
import SortType from '@/constants/sort-type';

import Immutable from 'immutable';
import merge from 'merge';

export type ReferenceQueryFromQueryParams = {
  citedAuthor?: string[];
  citedCoAuthor?: string[];
  citedVenue?: string[];
  citedFos?: string[];
  citedYear: [string, string];
  citedPage: number | string;
  citedSort?: string;
  citedPdf?: string;
  citedQueryString?: string;
  citedCitationIntent?: Nullable<string>;
};

export type ReferenceQueryFromJS = {
  authors: string[];
  coAuthors: string[];
  venues: string[];
  fieldsOfStudy: string[];
  yearFilter: YearRangeRecord;
  requireViewablePdf?: Nullable<boolean>;
  matchingEntityQuery?: Nullable<string>;
  templateId?: Nullable<string>;
  paperId?: Nullable<string>;
  citationIntent?: Nullable<string>;
  queryString?: Nullable<string>;
  citationRankingModelVersion?: Nullable<string>;
};

/**
 * This model is specifically about a _Search Within References_ Query
 */
export type ReferenceQueryProperties = CommonQueryProps & {
  requireViewablePdf: boolean;
  citationIntent?: Nullable<string>;
  queryString?: Nullable<string>;
  citationRankingModelVersion?: Nullable<string>;
};

const defaultProperties: ReferenceQueryProperties = {
  page: 1,
  pageSize: 10,
  sort: SortType.RELEVANCE.id,
  authors: Immutable.Set(),
  coAuthors: Immutable.Set(),
  venues: Immutable.Set(),
  yearFilter: YearRangeRecordFactory(),
  requireViewablePdf: false,
  fieldsOfStudy: Immutable.OrderedSet(),
  citationIntent: undefined,
  queryString: undefined,
  citationRankingModelVersion: undefined,
};

export const RECORD_NAME = 'ReferenceQuery';
export const ReferenceQueryRecordFactory = Immutable.Record(defaultProperties, RECORD_NAME);
export type ReferenceQueryRecord = Immutable.RecordOf<ReferenceQueryProperties>;

export function isReferenceQueryRecord(r: unknown): r is ReferenceQueryRecord {
  return Immutable.isRecord(r) && Immutable.Record.getDescriptiveName(r) === RECORD_NAME;
}

export function resetReferenceQueryRecord(query: ReferenceQueryRecord): ReferenceQueryRecord {
  return ReferenceQueryRecordFactory({
    sort: query.sort,
  });
}

/**
 * Prepares the query to be sent to the service layer by ensuring the sort parameter is set, and
 * that the year filter isn't set if either the min or the max is invalid.
 *
 * @param {string} sort=SortType.RELEVANCE.id
 *
 * @returns {object}
 */
export function prepareReferenceQueryRecord(
  query: ReferenceQueryRecord,
  sort: Nullable<string> = SortType.RELEVANCE.id
): ReferenceQueryRecord {
  return query.withMutations(query => {
    if (!query.yearFilter?.min || !query.yearFilter?.max) {
      query.set('yearFilter', null);
    }
    if (!query.sort && sort) {
      query.set('sort', sort);
    }
    return query;
  });
}

export function referenceQueryRecordToQueryString(
  query: ReferenceQueryRecord
): ReferenceQueryFromQueryParams {
  const params: any = {};

  const startYear = query.getIn(['yearFilter', 'min']);
  const endYear = query.getIn(['yearFilter', 'max']);
  if (startYear && endYear) {
    params.citedYear = [startYear, endYear];
  }

  params.citedAuthor = query.authors.toArray();
  params.citedCoAuthor = query.coAuthors.toArray();
  params.citedFos = query.fieldsOfStudy.toArray();
  params.citedVenue = query.venues.toArray();

  const sort = query.sort;
  if (sort) {
    params.citedSort = sort;
  }
  const page = query.page;
  if (page > 1) {
    params.citedPage = page;
  }

  // Only append the `citedPdf` param if it's set to true, as `&citedPdf=false` in the url might mislead
  // a user (or us!) and cause them (or us!) to think that their search indludes results without
  // a PDF
  if (query.requireViewablePdf === true) {
    params.citedPdf = true;
  }

  if (query.citationIntent && query.citationIntent !== Citation.INTENTS.ALL_INTENTS.id) {
    params.citedCitationIntent = query.citationIntent;
  }

  if (query.queryString) {
    params.citedQueryString = query.queryString;
  }

  if (query.citationRankingModelVersion) {
    params.citationRankingModelVersion = query.citationRankingModelVersion;
  }

  return params;
}

export function getReferenceQueryRecordFromQueryStringParams(
  query: ReferenceQueryFromQueryParams
): ReferenceQueryRecord {
  const years = parseYears(query.citedYear);

  return ReferenceQueryRecordFactory({
    authors: Immutable.Set(query.citedAuthor),
    coAuthors: Immutable.Set(query.citedCoAuthor),
    venues: Immutable.Set(query.citedVenue),
    yearFilter: new YearRangeRecordFactory({
      min: years.startYear,
      max: years.endYear,
    }),
    sort: query.citedSort || SortType.RELEVANCE.id,
    page: query.citedPage
      ? typeof query.citedPage === 'string'
        ? parseInt(query.citedPage, 10)
        : query.citedPage
      : 1,
    requireViewablePdf: query.citedPdf === 'true',
    fieldsOfStudy: Immutable.OrderedSet(query.citedFos),
    citationIntent: query.citedCitationIntent,
    queryString: query.citedQueryString,
  });
}

export function getIndependentReferenceQueryStringParams(
  // Takes a Partial type because `delete` can only be called on optional fields.
  query: Partial<ReferenceQueryFromQueryParams>
): Omit<
  ReferenceQueryFromQueryParams,
  | 'citedYear'
  | 'citedAuthor'
  | 'citedCoAuthor'
  | 'citedVenue'
  | 'citedSort'
  | 'citedPage'
  | 'citedPdf'
  | 'citedFos'
  | 'citedCitationIntent'
  | 'citedQueryString'
> {
  const result = { ...query };
  delete result.citedYear;
  delete result.citedAuthor;
  delete result.citedCoAuthor;
  delete result.citedVenue;
  delete result.citedSort;
  delete result.citedPage;
  delete result.citedPdf;
  delete result.citedFos;
  delete result.citedCitationIntent;
  delete result.citedQueryString;
  return result;
}

export function getReferenceQueryRecordFromJS(
  rawQuery: ReferenceQueryFromJS
): ReferenceQueryRecord {
  return ReferenceQueryRecordFactory(
    merge.recursive(true, rawQuery, {
      authors: Immutable.Set(rawQuery.authors),
      coAuthors: Immutable.Set(rawQuery.coAuthors),
      venues: Immutable.Set(rawQuery.venues),
      yearFilter: new YearRangeRecordFactory(rawQuery.yearFilter),
      requireViewablePdf: rawQuery.requireViewablePdf,
      fieldsOfStudy: Immutable.OrderedSet(rawQuery.fieldsOfStudy),
      citationIntent: rawQuery.citationIntent,
      queryString: rawQuery.queryString,
    })
  );
}
