import { AnyQuery, AnyQueryParams, anyQueryToQueryParams } from '@/models/QueryUtils';
import { getQueryRecordFromQueryStringString, isQueryRecord, QueryRecord } from '@/models/Query';
import { mkRouterObj } from '@/router/routerUtils';
import { RouteName } from '@/router/Routes';
import { TODO } from '@/utils/types';
import S2History from '@/utils/S2History';

type TODO__FilterType = TODO<'Not sure what a "filterType" is'>;
type TODO__Sort = TODO<'Not sure what a "sort" is'>;
export type TODO__RouterChildContext_router =
  TODO<'RouterChildContext.router, which is not an exported type'>;

/* Public functions for updating the route with new query params */

// TODO(codeviking): Update Consumers to use proper casing
const queryRoutes = {
  // eslint-disable-next-line max-params
  changeRouteForPartialQuery(
    independentQuery: Partial<AnyQueryParams>,
    partialQuery: AnyQuery,
    history: S2History,
    router: TODO__RouterChildContext_router,
    targetRouteName?: RouteName
  ) {
    const { route } = router;
    const { params, path } = route.match;

    const urlQuery = anyQueryToQueryParams(partialQuery);
    const newQuery = {
      ...independentQuery,
      ...urlQuery,
    };

    history.push(
      mkRouterObj({
        params,
        path,
        routeName: targetRouteName,
        query: newQuery,
      })
    );
  },

  changeRouteForQuery(
    query: AnyQuery,
    history: S2History,
    router: TODO__RouterChildContext_router,
    targetRouteName?: RouteName
  ) {
    const { route } = router;
    const { params, path } = route.match;

    history.push(
      mkRouterObj({
        params,
        path,
        routeName: targetRouteName,
        query: anyQueryToQueryParams(query),
      })
    );
  },

  routeForQuery(
    query: QueryRecord | string,
    filterType: TODO__FilterType,
    routeName?: RouteName,
    defaultSort?: TODO__Sort
  ) {
    // Default to search page so as not to break everything
    const route = routeName || 'SEARCH';
    const q = isQueryRecord(query)
      ? query
      : getQueryRecordFromQueryStringString(query, filterType, defaultSort);

    return {
      to: route,
      query: anyQueryToQueryParams(q),
    };
  },
};

export default queryRoutes;
