import { DEPRECATED__FlowOptional, isFunction, isNumber, isObject, isString } from '@/utils/types';
import { getLayoverLogger } from '@/utils/layover/LayoverLogger';
import { isBrowser } from '@/utils/env';
import { URL_PREFIX } from '@/api/ApiConfig__target';
import logger from '@/logger';

import invariant from 'invariant';
import request from 'superagent';

import type { ApiResponse } from './ApiResponse';

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export type ApiRequestArguments = {
  method: HttpMethod;
  path: string;
  data?: DEPRECATED__FlowOptional<unknown>;
  dataType?: DEPRECATED__FlowOptional<string>;
  extraHeaders?: DEPRECATED__FlowOptional<unknown>;
  debug?: DEPRECATED__FlowOptional<boolean>;
  timeout?: DEPRECATED__FlowOptional<number>;
  baseUrl?: DEPRECATED__FlowOptional<string>;
};

const API_REQUEST_TIMEOUT = 30000;

const HttpHeader = {
  S2_CLIENT: 'X-S2-Client',
  S2_UI_VERSION: 'X-S2-UI-Version',
};

// TODO(codeviking): Consider moving this to a utility library specific to the api package
const UI_VERSION: DEPRECATED__FlowOptional<string> = (function getUiVersion() {
  if (isBrowser()) {
    const versionMeta = Array.prototype.slice
      .apply(document.getElementsByTagName('meta'))
      .filter(function (meta) {
        return meta.getAttribute('name') === 's2-ui-version';
      })
      .shift();
    if (versionMeta) {
      return versionMeta.getAttribute('content');
    }
  }
  return null;
})();

/**
 * Representation of a request made to the S2 API layer.
 *
 * This is essentially a wrapper for a Promise reflecting the state of an XHR request while
 * providing the capability to cancel the request.
 *
 * @param {object} args The properties of the request.
 * @param {string} args.method The request method, 'GET' or 'POST'.
 * @param {string} args.path The url path to request.
 * @param {object} args.data The data to include with the request.
 * @param {string} [args.dataType='application/json'] The request body type.
 * @param {object} [args.extraHeaders=undefined] An object with additional HTTP headers to include.
 * @param {boolean} [args.debug=false] If true, debug data will be returned by the API.
 * @param {number} [args.timeout=API_REQUEST_TIMEOUT] The maximum time in milliseconds to wait
 * for a request to complete.
 *
 * TODO(codeviking): This test lacks any type of test coverage. We should at some point test this.
 */
export default class ApiRequest<TRespBody> {
  aborted: boolean;
  complete: boolean;
  reject?: (error: any, response?: ApiResponse<TRespBody>) => void;
  req: any;
  resolve?: (result: ApiResponse<TRespBody>) => void;
  promise: Promise<ApiResponse<TRespBody>>;
  baseUrl: string;

  constructor(args: ApiRequestArguments) {
    const {
      method,
      path,
      data,
      dataType = 'application/json',
      extraHeaders = undefined,
      debug = false,
      timeout = API_REQUEST_TIMEOUT,
      baseUrl = URL_PREFIX,
    } = args;

    invariant(baseUrl, 'baseUrl is required for creating an ApiRequest');
    this.baseUrl = baseUrl;

    const req = ApiRequest.getRequestBuilder()(method, this.apiUrl(path));
    this.req = req;
    req.set('Cache-Control', 'no-cache,no-store,must-revalidate,max-age=-1');
    if (isString(dataType)) {
      req.type(dataType);
    }
    if (isNumber(timeout)) {
      req.timeout(timeout);
    }

    if (isObject<object>(extraHeaders)) {
      Object.getOwnPropertyNames(extraHeaders).forEach(headerName => {
        const value = extraHeaders[headerName];
        if (value) {
          req.set(headerName, value);
        }
      });
    }

    if (UI_VERSION && isBrowser()) {
      req.set(HttpHeader.S2_UI_VERSION, UI_VERSION);
    }
    req.set(HttpHeader.S2_CLIENT, `webapp-${isBrowser() ? 'browser' : 'node'}`);

    if (debug) {
      req.query('debug=true');
    }

    this._modifyRequestBeforeStarting(req);

    if (isObject(data) || isString(data)) {
      if (method === 'GET') {
        req.query(data);
      } else {
        req.send(data);
      }
    }

    // Wrap the promise so that we can call reject / resolve externally
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });

    this.complete = false;
    this.aborted = false;
  }

  /**
   * Send the associated request.
   *
   * @param {function} [success=undefined] Optional handler for transforming the response prior to
   * it being processed by consumers of the promise API.
   * @param {function} [failure=undefined] Optional handler for transforming the error prior to
   * it being processed by consumers of the promise API.
   *
   * @returns {ApiRequest} The ApiRequest instance.
   */
  send(
    success?: DEPRECATED__FlowOptional<(response) => ApiResponse<TRespBody>>,
    failure?: DEPRECATED__FlowOptional<(error: any, response) => any>
  ): this {
    if (!this.aborted && !this.complete) {
      this.req.end((err, resp) => {
        if (!this.aborted) {
          this.complete = true;
          if (err) {
            // If the URL is changed while XHR requests are still in flight, PhantomJS cancel those
            // requests in a fashion that causes them to appear as failures (due to cross domain
            // errors). This was causing non-deterministic failures in our automated tests, which
            // use PhantomJS to run UI integration tests. To avoid this issue, we eat this exception
            // and prevent the promise from being resolved, which prevents the error from occuring.
            if (
              isBrowser() &&
              err.crossDomain &&
              navigator &&
              typeof navigator.userAgent === 'string' &&
              navigator.userAgent.indexOf('PhantomJS') !== -1
            ) {
              return;
            }

            // Log failed request to layover
            try {
              if (err) {
                throw err;
              }
              const layoverLogger = getLayoverLogger();
              const resource = parseResourceFromUrl(this.req.url);
              const statusCode = resp.status;
              const method = this.req.method;
              layoverLogger.buildEventDataForError(err).then(eventData => {
                layoverLogger.logVital(
                  ['apiError', resource, method, statusCode].join('.'),
                  () => ({
                    ...eventData,
                    resource,
                    statusCode,
                    method,
                    statusText: resp.statusText,
                    requestHeaders: this.req.header,
                    responseHeaders: resp.headers,
                    url: this.req.url,
                    type: resp.type,
                    text: (resp.text || '').substr(0, 100),
                  })
                );
              });
            } catch (error) {
              logger.error(error);
            }

            if (isFunction(failure)) {
              this.reject?.(failure(err, resp));
            } else {
              this.reject?.(err, resp);
            }
          } else {
            if (isFunction(success)) {
              this.resolve?.(success(resp));
            } else {
              this.resolve?.(resp);
            }
          }
        }
      });
    }
    return this;
  }

  /**
   * Cancels the request.
   *
   * @returns {undefined}
   */
  cancel(): void {
    if (!this.complete && !this.aborted) {
      this.aborted = true;
      this.req.abort();
      // TODO(codeviking): Right now we can't inform the subscribers that the request is being
      // canceled, as it triggers the error handlers (which usually shows an error message and / or
      // takes the user to an error page).  Longer term we should likely be more explicit about
      // handling "canceled" requests (or at the least make it possible to do so).
      // this.reject({ status: 'canceled' });
    }
  }

  /**
   * Alias for Promise.then
   *
   * @see https://www.npmjs.com/package/promise#promisethenonfulfilled-onrejected
   */
  then<TSucc>(
    onSucc?: (resp: ApiResponse<TRespBody>) => TSucc,
    onFail?: (error: any) => TSucc
  ): Promise<TSucc> {
    return this.promise.then(onSucc, onFail);
  }

  /**
   * Alias for Promise.catch
   *
   * @see https://www.npmjs.com/package/promise#promisecatchonrejected
   */
  catch(...args: any): Promise<any> {
    return this.promise.catch(...args);
  }

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  _modifyRequestBeforeStarting(req: request.SuperAgentRequest): void {
    // Noop, allows for subclasses to modify a request before it starts
  }

  /**
   * Returns a fully qualified url for the specified url path.
   *
   * @param {string} path The url path.
   *
   * @returns {string} The fully qualified API url.
   */
  static apiUrl(path: string): string {
    return `${URL_PREFIX}${path}`;
  }

  apiUrl(path: string): string {
    return `${this.baseUrl}${path}`;
  }

  /**
   * Creates and sends a new ApiRequest.
   *
   * @param {object} props The ApiRequest properties.
   * @see {ApiRequest#constructor}
   *
   * @returns {ApiRequest}
   */
  static send<TRespBody>(props: ApiRequestArguments): ApiRequest<TRespBody> {
    return new ApiRequest<TRespBody>(props).send();
  }

  static getRequestBuilder(): typeof request {
    return request;
  }
}

export function parseResourceFromUrl(url: string): string {
  const path = url.split('?')[0];
  // grab the first part of the path after /v/api/
  return path.split('/')[3] || 'unknown';
}
