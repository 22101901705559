export type ShelfId = keyof typeof allShelfIds;

export const CUE_PAPERS = 'CUE_PAPERS';
export const LIBRARY_FOLDER_SETTINGS = 'LIBRARY_FOLDER_SETTINGS';
export const ORGANIZE_PAPERS = 'ORGANIZE_PAPERS';
export const RECOMMENDATIONS_SETTINGS = 'RECOMMENDATIONS_SETTINGS';
export const SAVE_TO_LIBRARY = 'SAVE_TO_LIBRARY';

const allShelfIds = Object.freeze({
  CUE_PAPERS,
  LIBRARY_FOLDER_SETTINGS,
  ORGANIZE_PAPERS,
  RECOMMENDATIONS_SETTINGS,
  SAVE_TO_LIBRARY,
});

export default allShelfIds;
