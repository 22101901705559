import Immutable from 'immutable';

export type HighlightedFragmentFromJS = {
  start: number;
  end: number;
};

type Props = {
  start: number;
  end: number;
};

const defaultProps: Props = {
  start: 0,
  end: 0,
};

export const HighlightedFragmentRecordFactory = Immutable.Record<Props>(defaultProps);

export type HighlightedFragmentRecord = Immutable.RecordOf<Props>;

export function getHighlightedFragmentFromJS(
  args: HighlightedFragmentFromJS
): HighlightedFragmentRecord {
  return HighlightedFragmentRecordFactory({
    ...args,
  });
}
