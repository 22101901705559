import { getRelatedEntityFromJS, RelatedEntityFromJS, RelatedEntityRecord } from './RelatedEntity';

import { LinkProps, LinkRecord, LinkRecordFactory } from '@/models/Link';

import Immutable from 'immutable';

export type MatchedEntityFromJS = {
  id: string;
  name: string;
  slug: string;
  definition: string;
  definitionText: string;
  relatedEntities: RelatedEntityFromJS[];
  sourceUrls: Immutable.List<LinkProps>;
};

type Props = {
  id: string;
  name: string;
  slug: string;
  definition: string;
  definitionText: string;
  relatedEntities: Immutable.Set<RelatedEntityRecord>;
  sourceUrls: Immutable.List<LinkRecord>;
};

const defaultProps: Props = {
  id: '',
  name: '',
  slug: '',
  definition: '',
  definitionText: '',
  relatedEntities: Immutable.Set(),
  sourceUrls: Immutable.List(),
};

export const MatchedEntityRecordFactory = Immutable.Record(defaultProps);
export type MatchedEntityRecord = Immutable.RecordOf<Props>;

export function getMatchedEntityFromJS(args: MatchedEntityFromJS): MatchedEntityRecord {
  return MatchedEntityRecordFactory({
    ...args,
    relatedEntities: Immutable.OrderedSet(args.relatedEntities.map(getRelatedEntityFromJS)),
    sourceUrls: Immutable.List(args.sourceUrls.map(LinkRecordFactory)),
  });
}
