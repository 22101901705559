import React from 'react';

export default function IconFaLinkBox(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 32 32">
      <path
        d="M28.57,0H3.43C1.54,0,0,1.54,0,3.43v25.14C0,30.46,1.54,32,3.43,32h25.14c1.89,0,3.43-1.54,3.43-3.43V3.43
        C32,1.54,30.46,0,28.57,0z M18.95,22l-2.81,2.81c-2.47,2.47-6.49,2.47-8.96,0c-2.47-2.47-2.47-6.5,0-8.97l1.55-1.55
        c0.41-0.39,1.12-0.12,1.14,0.46c0.04,0.75,0.16,1.48,0.41,2.21c0.09,0.25,0.02,0.52-0.16,0.69l-0.55,0.53
        c-1.15,1.17-1.19,3.08-0.04,4.25c1.17,1.19,3.09,1.19,4.26,0.02l2.81-2.79c1.17-1.17,1.17-3.08,0-4.25
        c-0.16-0.16-0.3-0.27-0.43-0.36c-0.18-0.11-0.3-0.34-0.32-0.53c0-0.43,0.14-0.89,0.5-1.23l0.87-0.87c0.23-0.23,0.59-0.27,0.85-0.07
        c0.25,0.18,0.64,0.5,0.85,0.71C21.44,15.56,21.4,19.54,18.95,22z M24.81,16.14l-1.55,1.55c-0.41,0.41-1.12,0.14-1.14-0.44
        c-0.04-0.73-0.16-1.48-0.41-2.19c-0.07-0.25-0.02-0.52,0.16-0.69l0.55-0.55c1.17-1.17,1.21-3.08,0.05-4.25
        c-1.17-1.19-3.09-1.19-4.26-0.02l-2.81,2.79c-1.17,1.17-1.17,3.08,0,4.25c0.16,0.16,0.3,0.27,0.43,0.36
        c0.16,0.11,0.28,0.34,0.3,0.53c0.02,0.43-0.14,0.89-0.5,1.23l-0.87,0.89c-0.23,0.23-0.59,0.25-0.85,0.07
        c-0.25-0.18-0.64-0.5-0.85-0.71c-2.49-2.49-2.45-6.5-0.02-8.95c0,0,0-0.02,0.02-0.02l2.81-2.79c2.47-2.47,6.49-2.47,8.96,0
        C27.28,9.65,27.28,13.67,24.81,16.14z"
      />
    </symbol>
  );
}
