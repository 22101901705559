import { EnumRecord, EnumRecordFactory } from '@/models/Enum';

import Immutable from 'immutable';

import type { Nullable, ValueOf } from '@/utils/types';

export type FieldOfStudyKey = keyof typeof FieldOfStudy;
export type FieldOfStudyId = keyof typeof FieldOfStudyIdToName;
export type FieldOfStudyName = ValueOf<typeof FieldOfStudyIdToName>;
export type FieldOfStudyRecord = EnumRecord;
export type FieldOfStudyFilterOption = {
  value: string;
  displayValue: string;
  documentCount: number;
};

const FieldOfStudyIdToName = {
  'agricultural-and-food-sciences': 'Agricultural and Food Sciences',
  art: 'Art',
  biology: 'Biology',
  business: 'Business',
  'computer-science': 'Computer Science',
  chemistry: 'Chemistry',
  economics: 'Economics',
  education: 'Education',
  engineering: 'Engineering',
  'environmental-science': 'Environmental Science',
  geography: 'Geography',
  geology: 'Geology',
  history: 'History',
  law: 'Law',
  linguistics: 'Linguistics',
  'materials-science': 'Materials Science',
  mathematics: 'Mathematics',
  medicine: 'Medicine',
  philosophy: 'Philosophy',
  physics: 'Physics',
  'political-science': 'Political Science',
  psychology: 'Psychology',
  sociology: 'Sociology',
  all: 'All Fields',
  unsupported: 'Unsupported',
} as const;

function mkEnum(id: FieldOfStudyId): EnumRecord {
  return EnumRecordFactory({ id, name: FieldOfStudyIdToName[id] });
}

const FieldOfStudy = {
  AGRICULTURAL_AND_FOOD_SCIENCES: mkEnum('agricultural-and-food-sciences'),
  ART: mkEnum('art'),
  BIOLOGY: mkEnum('biology'),
  BUSINESS: mkEnum('business'),
  COMPUTER_SCIENCE: mkEnum('computer-science'),
  CHEMISTRY: mkEnum('chemistry'),
  ECONOMICS: mkEnum('economics'),
  EDUCATION: mkEnum('education'),
  ENGINEERING: mkEnum('engineering'),
  ENVIRONMENTAL_SCIENCE: mkEnum('environmental-science'),
  GEOGRAPHY: mkEnum('geography'),
  GEOLOGY: mkEnum('geology'),
  HISTORY: mkEnum('history'),
  LAW: mkEnum('law'),
  LINGUISTICS: mkEnum('linguistics'),
  MATERIALS_SCIENCE: mkEnum('materials-science'),
  MATHEMATICS: mkEnum('mathematics'),
  MEDICINE: mkEnum('medicine'),
  PHILOSOPHY: mkEnum('philosophy'),
  PHYSICS: mkEnum('physics'),
  POLITICAL_SCIENCE: mkEnum('political-science'),
  PSYCHOLOGY: mkEnum('psychology'),
  SOCIOLOGY: mkEnum('sociology'),
  ALL_FIELDS: mkEnum('all'),
  UNSUPPORTED: mkEnum('unsupported'),

  /**
   * Returns a list of the possible field of study values, sorted based on display preference.
   * @return {Immutable.List[FieldOfStudy]}
   */
  all(): Immutable.List<EnumRecord> {
    return Immutable.List.of(
      this.ALL_FIELDS,
      this.AGRICULTURAL_AND_FOOD_SCIENCES,
      this.ART,
      this.BIOLOGY,
      this.BUSINESS,
      this.COMPUTER_SCIENCE,
      this.CHEMISTRY,
      this.ECONOMICS,
      this.EDUCATION,
      this.ENGINEERING,
      this.ENVIRONMENTAL_SCIENCE,
      this.GEOGRAPHY,
      this.GEOLOGY,
      this.HISTORY,
      this.LAW,
      this.LINGUISTICS,
      this.MATERIALS_SCIENCE,
      this.MATHEMATICS,
      this.MEDICINE,
      this.PHILOSOPHY,
      this.PHYSICS,
      this.POLITICAL_SCIENCE,
      this.PSYCHOLOGY,
      this.SOCIOLOGY
    );
  },

  selectors(): Immutable.List<EnumRecord> {
    return Immutable.List.of(
      this.ART,
      this.BIOLOGY,
      this.BUSINESS,
      this.COMPUTER_SCIENCE,
      this.CHEMISTRY,
      this.ECONOMICS,
      this.ENGINEERING,
      this.ENVIRONMENTAL_SCIENCE,
      this.GEOGRAPHY,
      this.GEOLOGY,
      this.HISTORY,
      this.MATERIALS_SCIENCE,
      this.MATHEMATICS,
      this.MEDICINE,
      this.PHILOSOPHY,
      this.PHYSICS,
      this.POLITICAL_SCIENCE,
      this.PSYCHOLOGY,
      this.SOCIOLOGY
    );
  },

  s2Selectors(): Immutable.List<EnumRecord> {
    return Immutable.List.of(
      this.AGRICULTURAL_AND_FOOD_SCIENCES,
      this.ART,
      this.BIOLOGY,
      this.BUSINESS,
      this.COMPUTER_SCIENCE,
      this.CHEMISTRY,
      this.ECONOMICS,
      this.EDUCATION,
      this.ENGINEERING,
      this.ENVIRONMENTAL_SCIENCE,
      this.GEOGRAPHY,
      this.GEOLOGY,
      this.HISTORY,
      this.LAW,
      this.LINGUISTICS,
      this.MATERIALS_SCIENCE,
      this.MATHEMATICS,
      this.MEDICINE,
      this.PHILOSOPHY,
      this.PHYSICS,
      this.POLITICAL_SCIENCE,
      this.PSYCHOLOGY,
      this.SOCIOLOGY
    );
  },
};

const byName = Object.keys(FieldOfStudy).reduce((memo, key) => {
  const record = FieldOfStudy[key];
  const name = record.name.toLowerCase();
  memo[name] = record;
  return memo;
}, {}) as Record<FieldOfStudyName, EnumRecord>;

export const FieldOfStudyByName = Object.freeze(byName);

export function getFieldOfStudyByName(name: string): FieldOfStudyRecord {
  const lowName = name.toLowerCase();
  return byName[lowName] || FieldOfStudy.UNSUPPORTED;
}

export function optFieldOfStudyRecord(name: Nullable<string>): Nullable<FieldOfStudyRecord> {
  if (!name) {
    return null;
  }
  const fos = getFieldOfStudyByName(name);
  return FieldOfStudy.UNSUPPORTED.equals(fos) ? null : fos;
}

type ExtractFieldsOfStudyParam = {
  displayValue: string;
  documentCount: number;
  unfilteredDocumentCount: number;
  value: string;
};

export function extractFieldsOfStudy(
  data: Immutable.OrderedSet<ExtractFieldsOfStudyParam>
): Nullable<Immutable.OrderedSet<FieldOfStudyFilterOption>> {
  if (!data) {
    return null;
  }

  return data.map(_ => {
    const fos = getFieldOfStudyByName(_.value);
    return {
      value: fos.id,
      displayValue: fos.name,
      documentCount: _.documentCount,
    };
  });
}

export default Object.freeze(FieldOfStudy);
