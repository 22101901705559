import { getString } from '@/content/i18n';
import Icon from '@/components/shared/icon/Icon';
import UserOccupation from '@/constants/UserOccupation';

import React from 'react';

export default function UserOccupationInput({ currentOccupation, onChangeCurrentRole }) {
  return (
    <div className="standard-form">
      <label className="label">
        {getString(_ => _.demographicForm.currentRoleLabel)}
        <span className="optional-label">{getString(_ => _.demographicForm.optionalLabel)}</span>
      </label>
      <div className="select-dropdown-arrow">
        <select
          className="legacy__select select"
          value={currentOccupation}
          onChange={onChangeCurrentRole}>
          <option value={undefined} />
          {Object.keys(UserOccupation).map(key => (
            <option value={UserOccupation[key]} key={key}>
              {UserOccupation[key]}
            </option>
          ))}
        </select>
        <Icon icon="disclosure" height="10" width="10" className="dropdown-arrow-icon" />
      </div>
    </div>
  );
}
