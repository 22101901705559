import React from 'react';

import type { ReactNodeish } from '@/utils/types';

export default function IconPersonalizationSmall(props: { id?: string }): ReactNodeish {
  return (
    <symbol id={props.id} viewBox="0 0 12 12">
      <path
        d="M2.36,2.01C2.25,1.92,2.13,1.88,1.99,1.88c-0.18,0-0.34,0.08-0.45,0.21c-0.81,1-1.25,2.26-1.23,3.55
		    c0.02,1.25-0.08,2.5-0.3,3.72C-0.02,9.51,0.01,9.67,0.1,9.8c0.09,0.13,0.22,0.21,0.38,0.24c0.51,0.1,0.67-0.46,0.68-0.47
		    c0.23-1.3,0.34-2.62,0.32-3.95c-0.02-1.03,0.32-2,0.97-2.8C2.64,2.58,2.61,2.21,2.36,2.01z M6.12,2.7L6.12,2.7
		    c-0.9,0-1.72,0.33-2.31,0.93C3.24,4.22,2.93,4.99,2.94,5.81C2.97,7.41,2.82,9,2.5,10.55c-0.03,0.15,0,0.31,0.08,0.44
		    s0.22,0.22,0.37,0.25c0.31,0.06,0.62-0.15,0.69-0.45c0.34-1.63,0.5-3.31,0.47-4.98C4.1,5.06,4.52,4.38,5.19,4.07
		    c0.28-0.13,0.58-0.2,0.91-0.2c0.3,0,0.58,0.07,0.85,0.18C7.7,4.38,8.19,5.1,8.2,5.89c0.03,1.59-0.09,3.19-0.35,4.75
		    c-0.05,0.32,0.16,0.62,0.48,0.67C8.87,11.41,9,10.84,9,10.84c0.27-1.63,0.39-3.3,0.37-4.96C9.34,4.15,7.89,2.73,6.12,2.7z
		    M6.15,5.39c-0.32,0-0.58,0.26-0.58,0.58c0.03,1.81-0.14,3.59-0.5,5.35c-0.02,0.13,0.03,0.35,0.16,0.5C5.32,11.94,5.46,12,5.63,12
		    l0.06,0c0.26-0.02,0.47-0.21,0.53-0.47c0.37-1.68,0.55-3.58,0.52-5.56C6.73,5.65,6.47,5.39,6.15,5.39z M12,5.78
		    C11.95,2.64,9.29,0.05,6.08,0L5.94,0C5.52,0,5.08,0.06,4.63,0.16C4.32,0.24,4.12,0.55,4.2,0.86c0.07,0.31,0.4,0.5,0.7,0.43
		    c0.36-0.08,0.74-0.13,1.13-0.13c2.63,0.04,4.77,2.11,4.81,4.63c0.02,1.26-0.04,2.52-0.19,3.76c-0.04,0.32,0.2,0.61,0.51,0.64
		    c0.43,0.05,0.62-0.33,0.64-0.51C11.91,8.75,12,7.21,12,6.25L12,5.78z"
      />
    </symbol>
  );
}
