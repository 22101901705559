import CreateLibraryBlankState from './CreateLibraryBlankState';
import CreateLibrarySearchResults from './CreateLibrarySearchResults';

import { getAddLibraryEntryBulkRequestFromJS } from '@/models/library/AddLibraryEntryBulkRequest';
import { getPaperFromJS, PaperRecord } from '@/models/Paper';
import { getPluralizedString, getString } from '@/content/i18n';
import { handleRedirect } from '@/router/clientUtils';
import {
  heapCreateLibraryFTUEFinishSetupButton,
  heapCreateLibraryFTUESearchButton,
  heapCreateLibraryFTUESetupLaterButton,
} from '@/analytics/attributes/researchFTUEPage';
import { hideModal } from '@/actions/ModalActionCreators';
import { Library } from '@/models/library/LibraryEntrySourceType';
import { maybeCreateAlert } from '@/utils/alert-util';
import { ModalId } from '@/constants/Modal';
import { On } from '@/models/library/RecommendationStatus';
import { QUERY_TYPE } from '@/constants/Alert';
import { ReactNodeish } from '@/utils/types';
import { SHAPE, SIZE } from '@/components/library/button/CLButtonBase';
import { truncateText } from '@/util';
import Api from '@/api/Api';
import AuthStore from '@/stores/AuthStore';
import CLButton from '@/components/library/button/CLButton';
import CLCardContainer from '@/components/library/container/CLCardContainer';
import CLIconButton from '@/components/library/button/CLIconButton';
import CLModal from '@/components/library/modal/CLModal';
import CLRule from '@/components/library/layout/CLRule';
import CLTextButton from '@/components/library/button/CLTextButton';
import CLTextInput from '@/components/library/form/input/CLTextInput';
import EnvInfo from '@/env/EnvInfo';
import Icon from '@/components/shared/icon/Icon';
import MessageStore from '@/stores/MessageStore';
import S2Dispatcher from '@/utils/S2Dispatcher';
import S2History from '@/utils/S2History';
import S2Redirect from '@/models/redirects/S2Redirect';
import ScreenReaderAnnouncement, {
  Politeness,
} from '@/components/util/a11y/ScreenReaderAnnouncement';

import classnames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, { ChangeEvent } from 'react';

export const MAX_SELECTED_PAPERS = 3;
export const MAX_TITLE_CHARACTERS = 70;
export const SCREEN_READER_DURATION_MS = 1000;

type Props = {};

type State = {
  addedPapers: Immutable.OrderedMap<string, PaperRecord>;
  hasCompletedSearch: boolean;
  isFooterPeeking?: boolean;
  queryText: string;
  searchPapers: Immutable.List<PaperRecord>;
  showSearchDisplay: boolean;
};

export default class CreateLibraryFTUEModal extends React.PureComponent<Props, State> {
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    authStore: PropTypes.instanceOf(AuthStore).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    history: PropTypes.instanceOf(S2History).isRequired,
    messageStore: PropTypes.instanceOf(MessageStore).isRequired,
  };

  constructor(...args: [any]) {
    super(...args);

    this.state = {
      searchPapers: Immutable.List(),
      queryText: '',
      showSearchDisplay: false,
      addedPapers: Immutable.OrderedMap(),
      hasCompletedSearch: false,
      isFooterPeeking: true,
    };
  }

  onClickLaterButton = () => {
    this.context.dispatcher.dispatch(hideModal());
  };

  onChangeQueryText = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ queryText: event.currentTarget.value });
  };

  clearSearch = () => {
    const { addedPapers } = this.state;
    this.setState({
      queryText: '',
      searchPapers: Immutable.List(),
      hasCompletedSearch: false,
      showSearchDisplay: !addedPapers.isEmpty(), // If there are selected papers, then we continue to show the selected papers
    });
  };

  onClickSearchPapers: React.MouseEventHandler = () => {
    this.searchPapers();
  };

  searchPapers = async (): Promise<void> => {
    const { queryText } = this.state;

    const result = await this.context.api.searchPapers({
      query: queryText,
      page: 1,
      limit: 10,
      model: 'Paper',
      prependSuggestions: true,
    });

    const searchPapers = Immutable.List(result?.resultData?.papers || []).map(getPaperFromJS);
    this.setState({ searchPapers, showSearchDisplay: true, hasCompletedSearch: true });
  };

  onClickFooterHeader = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const { isFooterPeeking } = this.state;
    this.setState({ isFooterPeeking: !isFooterPeeking });
  };

  onAddPaper = (paper: PaperRecord): void => {
    const { addedPapers } = this.state;
    this.setState({
      addedPapers: addedPapers.set(paper.id, paper),
    });
  };

  onRemovePaper = (paper: PaperRecord): void => {
    const { addedPapers } = this.state;
    this.setState({
      addedPapers: addedPapers.delete(paper.id),
    });
  };

  renderBlankCard = () => {
    const { addedPapers } = this.state;
    const blankCards: React.ReactElement[] = [];
    for (let i = addedPapers.size + 1; i <= MAX_SELECTED_PAPERS; i++) {
      blankCards.push(
        <div
          className="create-library-ftue-modal__footer-blankstate-card"
          data-test-id="create-library-footer-blank-card">
          {i}
        </div>
      );
    }
    return blankCards;
  };

  renderSearchButtons = () => {
    const { showSearchDisplay } = this.state;
    return showSearchDisplay ? (
      <CLButton
        className="create-library-ftue-modal__buttons-finish"
        label={getString(_ => _.research.onboardingHomepage.createLibraryModal.finishButton)}
        onClick={this.saveLibraryPapers}
        heapProps={heapCreateLibraryFTUEFinishSetupButton()}
        data-test-id="create-library-finish-setup-button"
      />
    ) : (
      <CLButton
        className="create-library-ftue-modal__buttons-search"
        label={getString(_ => _.research.onboardingHomepage.createLibraryModal.searchButton)}
        onClick={this.onClickSearchPapers}
        heapProps={heapCreateLibraryFTUESearchButton()}
      />
    );
  };

  renderSearchResults = () => {
    const { showSearchDisplay, searchPapers, addedPapers } = this.state;

    return (
      showSearchDisplay && (
        <CreateLibrarySearchResults
          searchPapers={searchPapers}
          onAddPaper={this.onAddPaper}
          onRemovePaper={this.onRemovePaper}
          addedPapers={addedPapers}
        />
      )
    );
  };

  renderSearchIcon = () => {
    const { isMobile } = this.context.envInfo;
    const { showSearchDisplay } = this.state;
    return (
      (isMobile || showSearchDisplay) && (
        <CLIconButton
          icon={<Icon icon="search-small" height="12" width="12" />}
          className="create-library-ftue-modal__search-icon"
          onClick={this.onClickSearchPapers}
          ariaProps={this._searchButtonAriaProps}
          heapProps={heapCreateLibraryFTUESearchButton()}
        />
      )
    );
  };

  renderNoSearchResults = () => {
    const { queryText } = this.state;
    return <CreateLibraryBlankState clearSearch={this.clearSearch} queryText={queryText} />;
  };

  renderMobileSelectedPapers = () => {
    const { addedPapers } = this.state;

    return (
      <React.Fragment>
        <button
          className="create-library-ftue-modal__footer-mobile-button"
          onClick={this.onClickFooterHeader}
          data-test-id="create-library-mobile-footer-header-button">
          <div className="create-library-ftue-modal__footer-header">
            <Icon
              icon="fa-bookmark"
              width="10"
              height="14"
              className="create-library-ftue-modal__footer-icon"
            />
            <h2 className="create-library-ftue-modal__footer__mobile-header-text">
              {getString(_ => _.research.onboardingHomepage.createLibraryModal.mobileFooterText)}
            </h2>
          </div>
          <ScreenReaderAnnouncement
            politeness={Politeness.POLITE}
            message={getString(
              _ => _.research.onboardingHomepage.createLibraryModal.papersSelectedScreenReaderLabel,
              addedPapers.size,
              MAX_SELECTED_PAPERS
            )}
            isScreenReaderOnly={true}
            durationMs={SCREEN_READER_DURATION_MS}
          />
          {!addedPapers.isEmpty() && (
            <div className="create-library-ftue-modal__mobile-selected-papers">
              {getPluralizedString(
                _ =>
                  _.research.onboardingHomepage.createLibraryModal.mobileFooterSelectedPapersButton,
                addedPapers.size
              )}
            </div>
          )}
        </button>
      </React.Fragment>
    );
  };

  renderDesktopSelectedPapers = () => {
    const { addedPapers } = this.state;
    return (
      <div className="create-library-ftue-modal__footer-header">
        <Icon
          icon="fa-bookmark"
          width="10"
          height="14"
          className="create-library-ftue-modal__footer-icon"
        />
        <h2 className="create-library-ftue-modal__footer__header-text">
          {getString(_ => _.research.onboardingHomepage.createLibraryModal.footerText)}
        </h2>
        <ScreenReaderAnnouncement
          politeness={Politeness.POLITE}
          message={getString(
            _ => _.research.onboardingHomepage.createLibraryModal.papersSelectedScreenReaderLabel,
            addedPapers.size,
            MAX_SELECTED_PAPERS
          )}
          isScreenReaderOnly={true}
        />
      </div>
    );
  };

  renderSelectedPapers = () => {
    const { isMobile } = this.context.envInfo;
    const { addedPapers, isFooterPeeking } = this.state;

    return (
      <React.Fragment>
        <div
          className={classnames('create-library-ftue-modal__footer-content', {
            'create-library-ftue-modal__footer-content--is-peeking': isFooterPeeking && isMobile,
          })}>
          {isMobile ? this.renderMobileSelectedPapers() : this.renderDesktopSelectedPapers()}

          <div className="create-library-ftue-modal__footer-papers">
            {addedPapers.valueSeq().map(paper => (
              <div key={paper.id} className="create-library-modal__footer-card-container">
                <div className="create-library-modal__footer-card-icon-container">
                  <CLIconButton
                    icon={<Icon icon="remove-x" height="8" width="8" />}
                    className="create-library-modal__footer-icon-x"
                    size={SIZE.SMALL}
                    shape={SHAPE.CIRCLE}
                    onClick={() => this.onRemovePaper(paper)}
                    ariaProps={this._removePaperButtonAriaProps}
                  />
                </div>
                <CLCardContainer
                  className="create-library-modal__results"
                  data-test-id="create-library-ftue-added-paper"
                  hasDogEar>
                  <div className="create-library-modal__footer-paper-title">
                    {truncateText(paper.title.text, MAX_TITLE_CHARACTERS)}
                  </div>
                </CLCardContainer>
              </div>
            ))}
            {this.renderBlankCard()}
          </div>
        </div>
      </React.Fragment>
    );
  };

  redirectToLibrary = (libraryFolderId: number) => {
    const { history } = this.context;
    const redirect = new S2Redirect({
      routeName: 'LIBRARY_FOLDER',
      params: {
        libraryFolderId,
      },
    });
    handleRedirect({ history, redirect });
  };

  saveLibraryPapers = () => {
    this.saveLibraryPapersImpl().catch(() => {
      this.context.messageStore.addError(
        getString(_ => _.research.onboardingHomepage.createLibraryModal.errorMessage)
      );
    });
  };

  async saveLibraryPapersImpl() {
    const { api, authStore } = this.context;
    const { addedPapers } = this.state;
    const libraryFolderResponse = await api.createLibraryFolder({
      name: getString(_ => _.research.onboardingHomepage.createLibraryModal.firstLibraryFolder),
      recommendationStatus: On,
    });
    const libraryFolderId = libraryFolderResponse.resultData.folder.id;
    const promises = addedPapers.valueSeq().map(paper =>
      api.createLibraryEntryBulk(
        getAddLibraryEntryBulkRequestFromJS({
          paperId: paper.id,
          paperTitle: paper.title.text,
          sourceType: Library,
          folderIds: [libraryFolderId],
          annotationState: null,
        })
      )
    );
    const alertPromise = maybeCreateAlert(
      api,
      authStore,
      QUERY_TYPE.LIBRARY_FOLDER,
      libraryFolderId
    );
    await Promise.all([...promises, alertPromise]);
    this.redirectToLibrary(libraryFolderId);
  }

  _searchButtonAriaProps = {
    'aria-label': getString(
      _ => _.research.onboardingHomepage.createLibraryModal.searchButtonAriaLabel
    ),
  };

  _removePaperButtonAriaProps = {
    'aria-label': getString(
      _ => _.research.onboardingHomepage.createLibraryModal.removePaperAriaLabel
    ),
  };

  render(): ReactNodeish {
    const { isMobile } = this.context.envInfo;
    const { hasCompletedSearch, queryText, searchPapers, showSearchDisplay } = this.state;
    const showBlankState = hasCompletedSearch && showSearchDisplay && searchPapers.isEmpty();

    return (
      <CLModal modalId={ModalId.CREATE_LIBRARY_FTUE} className="create-library-ftue-modal">
        <div className="create-library-ftue-modal__content">
          <h2 className="create-library-ftue-modal__header">
            {getString(_ => _.research.onboardingHomepage.createLibraryModal.header)}
          </h2>
          <div className="flex-row">
            <CLTextInput
              className="create-library-ftue-modal__input"
              placeholder={
                isMobile
                  ? getString(
                      _ => _.research.onboardingHomepage.createLibraryModal.mobilePlaceholder
                    )
                  : getString(_ => _.research.onboardingHomepage.createLibraryModal.placeholder)
              }
              onChange={this.onChangeQueryText}
              value={queryText}
              data-test-id="create-library-ftue-modal-search-input"
            />
            {this.renderSearchIcon()}
          </div>
        </div>

        <div
          className={classnames({
            'create-library-ftue-modal__blank-space': showSearchDisplay && !hasCompletedSearch,
          })}>
          {showBlankState ? this.renderNoSearchResults() : this.renderSearchResults()}
        </div>

        <div
          className={classnames({
            'create-library-ftue-modal__footer-container': showSearchDisplay || isMobile,
          })}>
          {showSearchDisplay && this.renderSelectedPapers()}
          {showSearchDisplay && isMobile && <CLRule />}
          <div className="create-library-ftue-modal__buttons">
            <CLTextButton
              className="create-library-ftue-modal__buttons-setup-later"
              label={getString(_ => _.research.onboardingHomepage.createLibraryModal.laterButton)}
              onClick={this.onClickLaterButton}
              heapProps={heapCreateLibraryFTUESetupLaterButton()}
              data-test-id="create-library-setup-later-button"
            />
            {this.renderSearchButtons()}
          </div>
        </div>
      </CLModal>
    );
  }
}
