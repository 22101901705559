import {
  AUTHOR_LITE_FRAGMENT,
  AUTHOR_LITE_FRAGMENT_NAME,
  AuthorLiteFromGraphQL,
  AuthorLiteRecord,
  AuthorLiteRecordFactory,
  getAuthorLiteFromGraphQL,
} from './AuthorLite';

import { gql } from 'graphql-tag';
import Immutable from 'immutable';

export type CitingAuthorFromGraphQL = {
  citingPaperCount: number;
  citingAuthor: AuthorLiteFromGraphQL;
};

type Props = {
  paperCount: number;
  author: AuthorLiteRecord;
};

const defaultProps: Props = {
  paperCount: 0,
  author: AuthorLiteRecordFactory(),
};

export const CitingAuthorRecordFactory = Immutable.Record<Props>(defaultProps);

export type CitingAuthorRecord = Immutable.RecordOf<Props>;

export function getCitingAuthorFromGraphQL(args: CitingAuthorFromGraphQL): CitingAuthorRecord {
  const { citingPaperCount, citingAuthor } = args;
  return CitingAuthorRecordFactory({
    ...args,
    paperCount: citingPaperCount,
    author: getAuthorLiteFromGraphQL(citingAuthor),
  });
}

export const CITING_AUTHOR_FRAGMENT_NAME = 'CitingAuthorFragment';

export const CITING_AUTHOR_FRAGMENT = gql`
  ${AUTHOR_LITE_FRAGMENT}
  fragment ${CITING_AUTHOR_FRAGMENT_NAME} on AuthorCitation {
    citingPaperCount
    citingAuthor {
      ...${AUTHOR_LITE_FRAGMENT_NAME}
    }
  }
`;
