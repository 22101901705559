/**
 * This is a file of domains owned by AI2
 */

import URL from 'url';

import type { Nullable } from '@/utils/types';

export const ALLENAI_ORG = 'allenai.org';
export const SEMANTICSCHOLAR_ORG = 'semanticscholar.org';

export const AI2_DOMAINS = {
  ALLENAI_ORG,
  SEMANTICSCHOLAR_ORG,
} as const;

export type AI2Domain = (typeof AI2_DOMAINS)[keyof typeof AI2_DOMAINS];

export const AI2_DOMAIN_LIST: Readonly<AI2Domain[]> = Object.freeze(Object.values(AI2_DOMAINS));

// Determine if the given URL is an AI2 domain
export function isAI2OwnedDomain(domain: Nullable<string>): boolean {
  if (!domain) {
    return false;
  }
  return AI2_DOMAIN_LIST.includes(domain.toLowerCase() as AI2Domain);
}

export function isAI2OwnedUrl(url: Nullable<string>): boolean {
  if (!url) {
    return false;
  }
  // TODO: URL.parse() is deprecated in favor of the WHATWG URL API
  const urlObj = URL.parse(url);
  if (!urlObj.host && urlObj.pathname) {
    return urlObj.pathname.startsWith('/');
  }
  const tld = (urlObj.host || '').split('.').slice(-2).join('.').toLowerCase();
  return isAI2OwnedDomain(tld);
}
