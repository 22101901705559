import CloseModalButton from './CloseModalButton';

import { hideModal } from '@/actions/ModalActionCreators';
import S2Dispatcher from '@/utils/S2Dispatcher';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class ModalHeader extends PureComponent {
  static contextTypes = {
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
  };

  static propTypes = {
    title: PropTypes.string.isRequired,
    onHideClick: PropTypes.func,
  };

  hideModal = () => {
    if (typeof this.props.onHideClick === 'function') {
      this.props.onHideClick();
    } else {
      this.context.dispatcher.dispatch(hideModal());
    }
  };

  render() {
    return (
      <header className="flex-row-vcenter modal-header">
        <h2>{this.props.title}</h2>
        {this.props.children}
        <span className="flex-right">
          <CloseModalButton onClick={this.hideModal} />
        </span>
      </header>
    );
  }
}
