import { getCitation, getFilename } from '@/utils/citation-util';
import { getString } from '@/content/i18n';
import { heapCitationDownloadLink } from '@/analytics/attributes/paperObject';
import { trackCitationDownloadClick } from '@/components/shared/paper/PaperActionTracking';
import CLButton, { TYPE } from '@/components/library/button/CLButton';

import { fromByteArray } from 'base64-js';
import React from 'react';

const DownloadCitationLink = ({ papers, citationStyle }) => {
  const label = getString(_ => _.modal.export.citationStyleLabel[citationStyle]);
  const filename = getFilename(citationStyle);
  const fileBody = papers.map(paper => getCitation(paper, citationStyle)).join('\n\n');
  const dataUrl = getDataUrl(fileBody);

  // This sends an event per paper that is being downloaded
  const trackCopyPerPaper = React.useCallback(() => {
    trackCitationDownloadClick(papers, citationStyle);
  }, [papers, citationStyle]);

  return (
    <a
      href={dataUrl}
      download={filename}
      {...heapCitationDownloadLink({ 'citation-style': citationStyle })}>
      <CLButton
        className="download-citation-link"
        type={TYPE.PRIMARY}
        label={label}
        onClick={trackCopyPerPaper}
      />
    </a>
  );
};

/**
 * Produces a valid dataUri with the provided string as file body.
 * @param {*} citationBody
 */
export function getDataUrl(citationBody) {
  // btoa only handles ascii so we need to encode the string ourselves
  const encoder = new TextEncoder();
  const fileBodyBase64 = fromByteArray(encoder.encode(citationBody));
  // Formatting the data uri this way ensures the browser will prompt the user to download the
  // file vs opening it in the browser.
  return `data:application/octet-stream;charset=utf-8;base64,${fileBodyBase64}`;
}

export default DownloadCitationLink;
