import BaseStore from './BaseStore';

import { ApiRequestStartingPayload } from '@/api/BaseApi';
import { ApiResponse, EntityByIdResponseBody } from '@/api/ApiResponse';
import { FoundEntityRecord, getFoundEntityFromJS } from '@/models/entity/FoundEntity';
import { Nullable } from '@/utils/types';
import Constants from '@/constants';
import S2Dispatcher from '@/utils/S2Dispatcher';

export default class EntityStore extends BaseStore {
  entity: Nullable<FoundEntityRecord>;
  loading: boolean;

  constructor(dispatcher: S2Dispatcher) {
    super();
    this.entity = null;
    this.loading = true;

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const apiStartingPayload = payload as ApiRequestStartingPayload;
          switch (apiStartingPayload.requestType) {
            case Constants.requestTypes.ENTITY_BY_ID: {
              this.loading = true;
              this.entity = null;
              this.emitChange();
              break;
            }
          }
          break;
        }
        case Constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.ENTITY_BY_ID: {
              const entityByIdResponseBody = payload as ApiResponse<EntityByIdResponseBody>;
              if (entityByIdResponseBody.resultData.responseType === 'found') {
                this.loading = false;
                this.entity = getFoundEntityFromJS(entityByIdResponseBody.resultData);
                this.emitChange();
              }
              break;
            }
          }
          break;
        }
      }
    });
  }

  getEntity(): Nullable<FoundEntityRecord> {
    return this.entity;
  }

  isLoading(): boolean {
    return this.loading;
  }
}
