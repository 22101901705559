import Icon, { Props as _IconProps } from '@/components/shared/icon/Icon';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import type { Nullable } from '@/utils/types';

type Props = {
  className?: Nullable<string>;
  iconProps: IconProps;
  responsiveText?: string;
  tagName?: Nullable<string>;
  testId?: Nullable<string>;
  text?: Nullable<string>;
  isAlternateSourceButtonLink?: Nullable<boolean>;
  isPdf?: Nullable<boolean>;
} & Record<string, any>;

export type IconProps = _IconProps;

export default class IconButton extends React.PureComponent<Props> {
  static defaultProps = {
    tagName: 'button',
  };

  static contextTypes = {
    heapProps: PropTypes.object,
  };

  declare context: {
    heapProps: any;
  };

  render() {
    const {
      className,
      responsiveText,
      iconProps,
      testId,
      tagName,
      text,
      isAlternateSourceButtonLink,
      isPdf,
      ...extraProps // Some components send more props, and we need to pass those to the final element
    } = this.props;

    let children = <Icon {...iconProps} />;
    const isOpenAccessIcon = iconProps.icon === 'open-access' ? true : false;
    const showPdfIcon = isOpenAccessIcon && isAlternateSourceButtonLink && isPdf;

    if (text) {
      children = (
        <span className="flex-row-centered">
          {children}
          {showPdfIcon ? <Icon icon="pdf" width="24" height="18" /> : null}
          <span className="icon-button-text">{text}</span>
          {responsiveText && <span className="icon-button-responsive-text">{responsiveText}</span>}
        </span>
      );
    }

    const props = {
      children,
      className: classNames('icon-button', className),
      'data-test-id': testId,
      ...(this.context.heapProps || {}),
      ...extraProps,
    };
    return React.createElement(tagName || IconButton.defaultProps.tagName, props, children);
  }
}
