import { ExperimentAllocation, ExperimentRecord, getExperimentFromJS } from './ExperimentRecord';

import Routes from '@/router/Routes';

import Immutable from 'immutable';
import invariant from 'invariant';
import pathToRegexp from 'path-to-regexp';

import type { Nullable } from '@/utils/types';

export const AATestKey = 'new_ab_framework_aa';
export const AAStableHashSessionTestKey = 'aa_stable_hash_session_test';
export const AAStableHashUserTestKey = 'aa_stable_hash_user_test';
export const AAStableHashUserAlertsTestKey = 'aa_stable_hash_user_alerts_test';
export const AAUserBasedTestKey = 'aa_user_based_test';
export const ABTestMockKey = 'new_ab_framework_mock_ab';
export const AlertsAATestScalaOnlyKey = 'alerts_aa_test';
export const PaperCuesKey = 'paper_cues';
export const PDPCitationAndReferencePaperCuesKey = 'pdp_citation_and_reference_paper_cues';
export const PersonalizedAuthorCardCuesKey = 'personalized_author_card_cues';
export const ReaderLinkStylingKey = 'reader_link_styling';
export const TopicsBeta3Key = 'topics_beta3';
export const TermUnderstandingKey = 'term_understanding';
export const VenuesKey = 'venues';

export type VariationKey = string;

export type ExperimentKey =
  | typeof AATestKey
  | typeof AAUserBasedTestKey
  | typeof AAStableHashSessionTestKey
  | typeof AAStableHashUserTestKey
  | typeof AAStableHashUserAlertsTestKey
  | typeof ABTestMockKey
  | typeof AlertsAATestScalaOnlyKey
  | typeof PaperCuesKey
  | typeof PDPCitationAndReferencePaperCuesKey
  | typeof PersonalizedAuthorCardCuesKey
  | typeof ReaderLinkStylingKey
  | typeof TopicsBeta3Key
  | typeof TermUnderstandingKey
  | typeof VenuesKey;

// NOTE: ExperimentKey (like null_hypothesis) is not strongly typed

// Update this map of experiments when you add logic to detect a variation.
// Be sure to add it to the correct section; otherwise, your experiment might not run.
// For more details see https://github.com/allenai/scholar/blob/main/online/Experimenting.md
export type ExperimentMap = { [key in ExperimentName]: ExperimentRecord };

const Experiment = {
  AATest: getExperimentFromJS({
    key: AATestKey,
    path: Routes.ALL,
    description: 'An A/A test to validate new AB coin tossing logic is correct.',
    variations: [{ key: 'control' }, { key: 'test' }],
    defaultVariation: 'control',
    allocationType: ExperimentAllocation.Session,
  }),

  AAStableHashSessionTest: getExperimentFromJS({
    key: AAStableHashSessionTestKey,
    path: Routes.ALL,
    description:
      'An A/A test to validate new AB stable hash logic is correct for session based experiments.',
    variations: [{ key: 'control' }, { key: 'test' }],
    defaultVariation: 'control',
    allocationType: ExperimentAllocation.Session,
  }),

  AAStableHashUserTest: getExperimentFromJS({
    key: AAStableHashUserTestKey,
    path: Routes.ALL,
    description:
      'An A/A test to validate new AB stable hash logic is correct for user based experiments with a frontend activation.',
    variations: [{ key: 'control' }, { key: 'test' }],
    defaultVariation: 'control',
    allocationType: ExperimentAllocation.User,
  }),

  AAStableHashUserAlertsTest: getExperimentFromJS({
    key: AAStableHashUserAlertsTestKey,
    path: Routes.ALL,
    description:
      'An A/A test to validate new AB stable hash logic is correct for user based experiments with a backend activation.',
    variations: [{ key: 'control' }, { key: 'test' }],
    defaultVariation: 'control',
    allocationType: ExperimentAllocation.User,
  }),

  // This is a user based experiment meant to always run so that we can analyze that allocations
  // are behaving correctly at any given time
  AAUserBasedTest: getExperimentFromJS({
    key: AAUserBasedTestKey,
    path: Routes.ALL,
    description: 'An A/A experiment to test the user based exposure platform',
    variations: [{ key: 'control' }, { key: 'test' }],
    defaultVariation: 'control',
    allocationType: ExperimentAllocation.User,
  }),

  ABTestMock: getExperimentFromJS({
    key: ABTestMockKey,
    path: Routes.PAPER_DETAIL,
    description: 'An A/B mock test in which a new event is created ',
    variations: [
      { key: 'control', description: '100% of clicks create events' },
      { key: 'test_50', description: '50% of clicks create events' },
      { key: 'test_90', description: '90% of clicks create events' },
    ],
    defaultVariation: 'control',
    allocationType: ExperimentAllocation.Session,
  }),

  AlertsAATestScalaOnly: getExperimentFromJS({
    key: AlertsAATestScalaOnlyKey,
    description:
      'A scala side experiment to run an AA test on alert emails. This is in JS just to enable the admin GUI to control it in the short run.',
    variations: [{ key: 'control' }, { key: 'test' }],
    defaultVariation: 'control',
    manualExposure: true,
    allocationType: ExperimentAllocation.User,
  }),

  PaperCues: getExperimentFromJS({
    key: PaperCuesKey,
    path: Routes.SEARCH,
    description: 'Experiment that tests adding more paper cues and the effects of latency.',
    variations: [
      { key: 'control', description: 'no personalized cues are shown' },
      { key: 'cited_by_library_cue_only', description: 'only cited by library cue is shown' },
      {
        key: 'all_paper_cues',
        description: 'all paper cues are displayed and ranked by priority.',
      },
    ],
    defaultVariation: 'control',
    allocationType: ExperimentAllocation.User,
  }),

  PDPCitationAndReferencePaperCues: getExperimentFromJS({
    key: PDPCitationAndReferencePaperCuesKey,
    path: Routes.PAPER_DETAIL,
    description:
      'Experiment that tests adding paper cues to the citation/reference sections of a PDP',
    variations: [
      {
        key: 'control',
        description:
          'no personalized cues are fetched or shown on the PDP for citation/reference papers',
      },
      {
        key: 'fetch_citation_and_reference_paper_cues_data',
        description: 'personalized cues are fetched from the server but not displayed',
      },
      {
        key: 'enable_citation_and_reference_paper_cues',
        description:
          'personalized cues are fetched and shown on the PDP for citation/reference papers',
      },
    ],
    defaultVariation: 'control',
    allocationType: ExperimentAllocation.User,
  }),

  PersonalizedAuthorCardCues: getExperimentFromJS({
    key: PersonalizedAuthorCardCuesKey,
    description:
      'Experiment that shows non-personalized and personalized author cards for a user on authors within paper rows on click/hover.',
    variations: [
      {
        key: 'control',
        description:
          'the non-personalized and personalized author cards are not shown and not fetched',
      },
      {
        key: 'enable_non_personalized_author_card_on_hover',
        description: 'the non-personalized author card is shown on hover',
      },
      {
        key: 'enable_non_personalized_author_card_on_click',
        description: 'the non-personalized author card is shown on click',
      },
      {
        key: 'enable_personalized_author_card_cues_on_hover',
        description: 'a personalized author card with cues on hover is shown to the user',
      },
      {
        key: 'enable_personalized_author_card_cues_on_click',
        description: 'a personalized author card with cues on click is shown to the user.',
      },
      {
        key: 'fetch_personalized_author_cues',
        description: 'personalized author cues are fetched but not displayed',
      },
    ],
    defaultVariation: 'control',
    path: Routes.SEARCH,
    allocationType: ExperimentAllocation.User,
  }),

  ReaderLinkStylingKey: getExperimentFromJS({
    key: ReaderLinkStylingKey,
    path: Routes.READER,
    description: 'Experiment that updates citation link styles in the reader',
    variations: [
      {
        key: 'control',
        description: `blue citation with dotted underline, citesee is orange/pink with dotted underline`,
      },
      {
        key: 'no_underline',
        description: 'blue citation with no underline, citesee is orange/pink with no underline',
      },
      {
        key: 'solid_underline',
        description:
          'black citation with solid underline, citesee is orange/pink with solid underline',
      },
    ],
    defaultVariation: 'control',
    allocationType: ExperimentAllocation.Session,
  }),

  TermUnderstanding: getExperimentFromJS({
    key: TermUnderstandingKey,
    path: Routes.READER,
    description: 'Experiment that enables definitions on terms on the reader',
    variations: [{ key: 'control' }, { key: 'enable_term_understanding' }],
    defaultVariation: 'control',
    allocationType: ExperimentAllocation.Session,
  }),

  TopicsBeta3: getExperimentFromJS({
    key: TopicsBeta3Key,
    path: Routes.PAPER_DETAIL,
    description: 'Experiment that shows the Topics card on the PDP.',
    variations: [
      { key: 'control', description: `Don't show the Topics card on the PDP.` },
      { key: TopicsBeta3Key, description: 'Show the Topics card on the PDP.' },
    ],
    defaultVariation: 'control',
    allocationType: ExperimentAllocation.Session,
  }),

  Venues: getExperimentFromJS({
    key: VenuesKey,
    description: 'Experiment that shows the venues page and links to venue pages.',
    variations: [
      { key: 'control', description: "Don't show the venue page or links to venue pages." },
      { key: 'enable_venues', description: 'Show the venue page and links to venue pages.' },
    ],
    defaultVariation: 'control',
    allocationType: ExperimentAllocation.Session,
    manualExposure: true,
  }),
};

export type ExperimentName = keyof typeof Experiment;
export const ExperimentNames = Immutable.List(Object.keys(Experiment) as ExperimentName[]);

// We want to match on the beginning of a route so that the experiments for `/paper/:slug/:paperId`
// are also activated when on `/paper/:slug/:paperId/figure/:figureIndex`. But we only want `/` to
// match.
// For more info, see: https://github.com/pillarjs/path-to-regexp#usage
const getOpts = exact => ({ end: exact });

/**
 * Returns an array of experiment keys for experiments whose path matches the current
 * provided pathname.
 */
export function getActiveExperimentKeys(
  experiments: typeof Experiment,
  pathname: string
): ExperimentKey[] {
  const expNames = Object.keys(experiments) as ExperimentName[];
  return expNames
    .filter(key => !experiments[key].manualExposure)
    .reduce((keys, experimentKey) => {
      const { path, exact, KEY } = experiments[experimentKey];

      // @ts-expect-error -- path does exist, but only when not manually exposed, which is why they are filtered out
      const matches = pathToRegexp(path, [], getOpts(exact)).exec(pathname);
      return matches ? keys.concat(KEY) : keys;
    }, [] as ExperimentKey[]);
}

export function asExperimentName(experimentName: Nullable<string>): Nullable<ExperimentName> {
  return ExperimentNames.find(name => name === experimentName) || null;
}

export function getDefaultVariationForExperiment(
  experiments: typeof Experiment
): (key: ExperimentKey) => VariationKey {
  return experimentKey => {
    const experimentName = Object.keys(experiments).find(
      name => experiments[name].KEY === experimentKey
    );
    invariant(experimentName, `experimentName was not found for key: ${experimentKey}`);
    const { defaultVariation } = experiments[experimentName];
    invariant(
      defaultVariation,
      `defaultVariation is required for experimentName: ${experimentName}`
    );
    return defaultVariation;
  };
}

export function getExperimentList(
  experimentsObj: typeof Experiment
): Immutable.List<ExperimentRecord> {
  const experiments = Immutable.List(Object.values(experimentsObj));
  return experiments;
}

Object.freeze(Experiment);

export default Experiment;
