import AnalyticsEvent from './AnalyticsEvent';

import EventType from '@/analytics/constants/EventType';

import merge from 'merge';

/**
 * Generic class for tracking when something was shown.
 *
 * @param {string} target - The item that was shown, e.g., Facet or Author Info Box
 * @param {object} [data] - Optional data object for extra information about the event.
 */
export default class ShowEvent extends AnalyticsEvent {
  constructor(target: string, data?: any) {
    super(EventType.SHOW, merge.recursive({ target }, data));
  }

  static create(target: string, data?: any): ShowEvent {
    return new ShowEvent(target, data);
  }
}
