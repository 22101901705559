import React from 'react';

/**
 * Utility to map state from AppContext stores into component props in a generic way.
 * The goal is to make get rid of getStateFromStore() functions.
 *
 *
 * Example: Author Page -- Pulling props from AppContext
 *
 * type Props = {
 *   isLoading: boolean;
 *   author: AuthorDetailRecord
 * } & PropsFromParent;
 *
 * export default mapHooksToProps<Props>(AuthorPage, () => {
 *   const { authorStore, authorQueryStore } = useAppContext();
 *   const authorQueryStoreProps = useStateFromStore(authorQueryStore, _ => ({
 *     isLoading: _.isLoading(),
 *   }));
 *   const authorStoreProps = useStateFromStore(authorStore, _ => ({
 *     isLoading: _.isLoading(),
 *     author: _.getAuthorDetails(),
 *   }));
 *   return {
 *     ...authorQueryStoreProps,
 *     ...authorStoreProps,
 *     isLoading: authorQueryStoreProps.isLoading || authorStoreProps.isLoading,
 *   };
 * });
 *
 *
 * Example: FeatureGate -- Passing only partial props
 *
 * type PropsFromParent = {
 *   children: React.ReactNode;
 *   feature: FeatureRecord;
 * };
 *
 * type Props = {
 *   children: React.ReactNode;
 *   isEnabled: boolean;
 * };
 *
 * export default mapHooksToProps<Props, PropsFromParent>(props => {
 *   const { children, feature } = props;
 *   const { weblabStore } = useAppContext();
 *   const isEnabled = useStateFromStore(weblabStore, _ => _.isFeatureEnabled(feature), [feature]);
 *   return {
 *     children,
 *     isEnabled,
 *   };
 * });
 */
export function mapHooksToProps<TProps, TPropsFromParent = {}>(
  Component: any,
  propMapper: (passedProps: TPropsFromParent) => TProps
): React.FunctionComponent<TPropsFromParent> {
  return function HookMappedComponent(passedProps: TPropsFromParent): React.ReactElement {
    return React.createElement(Component, propMapper(passedProps));
  };
}
