import { parseYears } from './Query';
import { YearRangeRecord, YearRangeRecordFactory } from './YearRange';

import { Nullable } from '@/utils/types';
import { RouterState } from '@/router/Route';
import SortType from '@/constants/sort-type';

import Immutable from 'immutable';

export type VenueQueryFromQueryParams = {
  authors?: string[];
  coAuthor?: string[];
  name: string; // by name we mean the venue name e.g. https://semanticscholar.org/venue?name=ICSE.
  fos?: string[];
  year: [string, string];
  page: number | string;
  sort?: string;
  pdf?: string;
};

export type VenueQueryFromJS = {
  authors: string[];
  coAuthors: string[];
  fieldsOfStudy: string[];
  name: string;
  page: number;
  pageSize: number;
  requireViewablePdf?: Nullable<boolean>;
  sort: string;
  yearFilter: YearRangeRecord;
};

/**
 * NOTE: Don't use CommonQueryProps as we don't have
 * a venues field in our query
 */

export type Props = {
  authors: Immutable.Set<string>;
  coAuthors: Immutable.Set<string>;
  fieldsOfStudy: Immutable.OrderedSet<string>;
  name: string;
  page: number;
  pageSize: number;
  requireViewablePdf?: Nullable<boolean>;
  sort: string;
  yearFilter: Nullable<YearRangeRecord>;
};

const defaultProperties: Props = {
  authors: Immutable.Set(),
  coAuthors: Immutable.Set(),
  fieldsOfStudy: Immutable.OrderedSet(),
  name: '',
  page: 1,
  pageSize: 10,
  requireViewablePdf: null,
  sort: SortType.INFLUENTIAL_CITATIONS.id,
  yearFilter: YearRangeRecordFactory(),
};

export const RECORD_NAME = 'VenueQuery';
export const VenueQueryRecordFactory = Immutable.Record<Props>(defaultProperties, RECORD_NAME);
export type VenueQueryRecord = Immutable.RecordOf<Props>;

export function isVenueQueryRecord(r: unknown): r is VenueQueryRecord {
  return Immutable.isRecord(r) && Immutable.Record.getDescriptiveName(r) === RECORD_NAME;
}

export function resetVenueQueryRecord(query: VenueQueryRecord): VenueQueryRecord {
  return VenueQueryRecordFactory({
    name: query.name,
    sort: query.sort,
  });
}

export function venueQueryRecordToQueryString(query: VenueQueryRecord): VenueQueryFromQueryParams {
  const startYear = query.getIn(['yearFilter', 'min']);
  const endYear = query.getIn(['yearFilter', 'max']);
  const year = startYear && endYear ? [startYear, endYear] : undefined;
  const authors = query.authors.toArray();
  const coAuthor = query.coAuthors.toArray();
  const fos = query.fieldsOfStudy.toArray();
  const name = query.name;
  const sort = query.sort ? query.sort : undefined;
  const page = query.page > 1 ? query.page : undefined;
  const pdf = query.requireViewablePdf ? true : undefined;
  const params = {
    authors,
    coAuthor,
    name,
    fos,
    year,
    page,
    sort,
    pdf,
  };
  return params as VenueQueryFromQueryParams;
}

export function getVenueQueryFromQueryParamsFromRouterState(
  routerState: RouterState
): VenueQueryFromQueryParams {
  const { query } = routerState;
  const authors = query.authors ? query.authors : undefined;
  const coAuthor = query.coAuthors ? query.coAuthors : undefined;
  const fos = query.fos ? query.fos : undefined;
  const name = query.name;
  const sort = query.sort ? query.sort : undefined;
  const page = query.page ? query.page : undefined;
  const pdf = query.pdf === 'true' ? 'true' : 'false';

  const startYear = query.year?.at(0);
  const endYear = query.year?.at(1);
  const year = query.year ? [startYear, endYear] : undefined;

  const params = {
    authors,
    coAuthor,
    name,
    fos,
    year,
    page,
    sort,
    pdf,
  };

  return params as VenueQueryFromQueryParams;
}

export function getVenueQueryRecordFromQueryParams(
  query: VenueQueryFromQueryParams
): VenueQueryRecord {
  const years = parseYears(query.year);
  return VenueQueryRecordFactory({
    authors: Immutable.Set(query.authors),
    coAuthors: Immutable.Set(query.coAuthor),
    fieldsOfStudy: Immutable.OrderedSet(query.fos),
    name: query.name,
    page: query.page ? (typeof query.page === 'string' ? parseInt(query.page, 10) : query.page) : 1,
    requireViewablePdf: query.pdf === 'true',
    sort: query.sort || SortType.INFLUENTIAL_CITATIONS.id,
    yearFilter: YearRangeRecordFactory({
      min: years.startYear,
      max: years.endYear,
    }),
  });
}

export function getVenueQueryRecordFromJS(rawQuery: VenueQueryFromJS): VenueQueryRecord {
  return VenueQueryRecordFactory({
    authors: Immutable.Set(rawQuery.authors),
    coAuthors: Immutable.Set(rawQuery.coAuthors),
    fieldsOfStudy: Immutable.OrderedSet(rawQuery.fieldsOfStudy),
    name: rawQuery.name,
    page: rawQuery.page,
    requireViewablePdf: rawQuery.requireViewablePdf,
    sort: rawQuery.sort,
    yearFilter: YearRangeRecordFactory(rawQuery.yearFilter),
  });
}
