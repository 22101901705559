import CookieJar, { CookieOptions, CookieValues, OptionalCookieFields } from './CookieJar';

import cookie from 'cookie';

import type { Nullable } from '@/utils/types';

export { DEFAULT_COOKIE_MAX_AGE } from './CookieJar';

/**
 * Creates a new cookie jar for the browser
 *
 * NOTE: It's recommended to using the singleton from getBrowserCookieJar(), which
 *       makes unit testing easier.
 */
export default class BrowserCookieJar extends CookieJar {
  saveCookie(name: string, value: string, options: CookieOptions): void {
    document.cookie = cookie.serialize(name, value, options);
  }

  getCookie(name: string): string | void {
    const cookies = this.getCookies();
    return cookies[name];
  }

  getCookies(): CookieValues {
    const cookies = cookie.parse(document.cookie);
    return cookies;
  }

  removeCookie(name: string, options: OptionalCookieFields = {}): void {
    // Replace this cookie with an expired one.
    const newOptions = { ...options, maxAge: -1 };
    this.saveCookie(name, '', newOptions);
  }
}

let singleton: Nullable<CookieJar> = null;
export function getBrowserCookieJar(): CookieJar {
  if (!singleton) {
    singleton = new BrowserCookieJar();
  }
  return singleton;
}
