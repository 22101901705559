import AddFolderForm from './AddFolderForm';
import LibraryShelfFolder from './LibraryShelfFolder';

import {
  DENSITY as BUTTON_DENSITY,
  FONT_SIZE as BUTTON_FONT_SIZE,
} from '@/components/library/button/CLButtonBase';
import { getAddLibraryEntryBulkRequestFromJS } from '@/models/library/AddLibraryEntryBulkRequest';
import { getPaperTitleStr } from '@/utils/paper-util';
import { getString } from '@/content/i18n';
import {
  heapLibraryShelfAddFolderButton,
  heapLibraryShelfFolderFilterInput,
  heapLibraryShelfSaveAndCloseButton,
} from '@/analytics/attributes/libraryShelf';
import { hideShelf } from '@/actions/ShelfActionCreators';
import { isAllPapersFolder } from '@/models/library/LibraryFolderSourceType';
import { Library } from '@/models/library/LibraryEntrySourceType';
import { LibraryFolderRecord } from '@/models/library/LibraryFolder';
import { Nullable, ReactNodeish } from '@/utils/types';
import { SAVE_TO_LIBRARY } from '@/constants/ShelfId';
import { SaveToLibraryShelfContext } from '@/models/shelf/ShelfContexts';
import Api from '@/api/Api';
import CLButton, { TYPE as BUTTON_TYPE } from '@/components/library/button/CLButton';
import CLIconButton from '@/components/library/button/CLIconButton';
import CLIconTextInput from '@/components/library/form/input/CLIconTextInput';
import EnvInfo from '@/env/EnvInfo';
import EventTarget from '@/analytics/constants/EventTarget';
import FormLevelMessage from '@/components/shared/message/FormLevelMessage';
import Icon from '@/components/shared/icon/Icon';
import LibraryFolderStore from '@/stores/LibraryFolderStore';
import Link from '@/router/Link';
import LoadingIndicator from '@/components/shared/loading/LoadingIndicator';
import S2Dispatcher from '@/utils/S2Dispatcher';
import Shelf from '@/components/shared/shelf/Shelf';
import ShowEvent from '@/analytics/models/ShowEvent';
import softError from '@/utils/softError';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import classnames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

type Props = {
  context: SaveToLibraryShelfContext;
};

type StateFromLibraryFolder = {
  filteredFolders: Immutable.List<LibraryFolderRecord>;
  folders: Immutable.OrderedMap<number, LibraryFolderRecord>;
  selectedFolders: Immutable.Set<LibraryFolderRecord>;
};

type State = {
  isAddFolderFormOpen: boolean;
  errorMessage: Nullable<string>;
  filterText: string;
  isDirty: boolean;
  isSending: boolean;
} & StateFromLibraryFolder;

export default class SaveToLibraryShelf extends React.PureComponent<Props, State> {
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    libraryFolderStore: PropTypes.instanceOf(LibraryFolderStore).isRequired,
  };

  declare context: {
    api: Api;
    dispatcher: S2Dispatcher;
    envInfo: EnvInfo;
    libraryFolderStore: LibraryFolderStore;
  };

  constructor(...args: [any]) {
    super(...args);

    this.state = {
      isAddFolderFormOpen: false,
      errorMessage: null,
      filterText: '',
      isDirty: false,
      isSending: false,
      ...this.getStateFromLibraryFolderStore(),
    };

    this.context.libraryFolderStore.registerComponent(this, () => {
      this.setState(this.getStateFromLibraryFolderStore());
    });
  }

  componentWillUnmount(): void {
    const { isDirty, selectedFolders } = this.state;
    if (isDirty) {
      const { paper } = this.props.context;
      this.createLibraryEntry({
        paperId: paper.id,
        paperTitle: getPaperTitleStr(paper),
        folderIds: selectedFolders.toList().map(_ => _.id),
      }).catch(error => {
        softError(
          'library',
          `failed to save to library while exiting [paperId="${paper.id}] with status ${error.status}"]`,
          error
        );
      });
    }
  }

  getStateFromLibraryFolderStore(): StateFromLibraryFolder {
    const { paper } = this.props.context;
    const libraryFolderStore = this.context.libraryFolderStore;
    const preselectedFoldersFromContext = this.props.context.selectedFolders;
    const foldersWithPaperId = libraryFolderStore.getFoldersWithPaperId(paper.id).toSet();

    // If a recommended paper is being saved, we check whether the generating folder
    // has been added to the context to be pre-selected. Then we take the union of any other folders the paper
    // may already be in to get the full set of folders to pre-select
    const selectedFolders =
      preselectedFoldersFromContext && !preselectedFoldersFromContext.isEmpty()
        ? foldersWithPaperId.union(preselectedFoldersFromContext)
        : foldersWithPaperId;

    const visibleFolders = libraryFolderStore.getFolders().filterNot(_ => isAllPapersFolder(_));
    return {
      filteredFolders: visibleFolders.valueSeq().toList(),
      folders: visibleFolders,
      selectedFolders: selectedFolders,
    };
  }

  componentDidMount(): void {
    trackAnalyticsEvent(ShowEvent.create(EventTarget.Library.Shelf.SAVE_TO_LIBRARY_VIEW));
  }

  handleFolderCheckbox = (folder: LibraryFolderRecord) => {
    this.setState(({ selectedFolders }) => ({
      isDirty: true,
      selectedFolders: selectedFolders.includes(folder)
        ? selectedFolders.delete(folder)
        : selectedFolders.add(folder),
    }));
  };

  onClickAddFolderButton = (event: React.SyntheticEvent<HTMLElement>): void => {
    event.preventDefault();

    this.setState(prevState => ({
      isAddFolderFormOpen: !prevState.isAddFolderFormOpen,
    }));
  };

  onClickCloseForm = (event: React.SyntheticEvent<HTMLElement>): void => {
    event.preventDefault();
    this.setState({ isAddFolderFormOpen: false });
  };

  onSuccessfulSave = (folder: LibraryFolderRecord) => {
    this.handleFolderCheckbox(folder);
  };

  async createLibraryEntry({
    paperId,
    paperTitle,
    folderIds,
  }: {
    paperId: string;
    paperTitle: string;
    folderIds: Immutable.List<number>;
  }): Promise<void> {
    const { api } = this.context;
    await api.createLibraryEntryBulk(
      getAddLibraryEntryBulkRequestFromJS({
        paperId,
        paperTitle,
        folderIds: folderIds.toArray(),
        sourceType: Library,
      })
    );
    await api.getLibraryFolders(); // Refresh folders in shelf
  }

  onClickSave = (): void => {
    const { selectedFolders } = this.state;
    const {
      context: { paper },
    } = this.props;

    this.setState({
      isSending: true,
    });

    this.createLibraryEntry({
      paperId: paper.id,
      paperTitle: getPaperTitleStr(paper),
      folderIds: selectedFolders.toList().map(folder => folder.id),
    })
      .then(() => {
        this.setState({
          isDirty: false,
          isSending: false,
        });

        this.context.dispatcher.dispatch(hideShelf());
      })
      .catch(error => {
        softError(
          'library',
          `failed to save to library[paperId="${paper.id}] with status ${error.status}"]`,
          error
        );
        this.setState({
          isSending: false,
          errorMessage: getString(_ => _.library.saveToLibraryShelf.errorMessage),
        });
      });
  };

  onChangeFilter = (filterText: string): void => {
    const { folders } = this.state;

    // resets to full folder list if filter is cleared
    if (filterText.trim().length === 0) {
      this.setState({
        filterText,
        filteredFolders: folders.valueSeq().toList(),
      });

      return;
    }

    const newFilteredFolders = folders.filter(folder => {
      return folder.name.toLowerCase().includes(filterText.trim().toLowerCase());
    });

    this.setState({
      filterText,
      filteredFolders: newFilteredFolders.valueSeq().toList(),
    });
  };

  render(): ReactNodeish {
    const {
      filteredFolders,
      filterText,
      isAddFolderFormOpen,
      errorMessage,
      isSending,
      selectedFolders,
    } = this.state;

    const { isMobile } = this.context.envInfo;
    const headerComp = isMobile ? (
      <div key="header" className="shelf__header-container shelf__header-container--mobile">
        <h3 className="shelf__primary-header shelf__primary-header--mobile">
          {getString(_ => _.library.saveToLibraryShelf.headerText)}
        </h3>
        <Link to="LIBRARY_ALL_ENTRIES" shouldUnderline={false}>
          <>{getString(_ => _.library.saveToLibraryShelf.viewLibraryButtonLabel)}</>
        </Link>
      </div>
    ) : (
      getString(_ => _.library.saveToLibraryShelf.headerText)
    );

    return (
      <Shelf
        ariaLabel={getString(_ => _.library.saveToLibraryShelf.closeShelfAriaLabel)}
        shelfId={SAVE_TO_LIBRARY}
        className="save-library__shelf"
        header={headerComp}>
        <div className="library__shelf__scroll-container">
          <div className="save-library__shelf__bordered-section">
            {!!errorMessage && (
              <div
                className="save-library__shelf__error-msg"
                data-test-id="save-library-shelf-save-error">
                <FormLevelMessage message={errorMessage} />
              </div>
            )}
            <div className="flex-row-vcenter flex-space-between">
              <p className="shelf__secondary-header">
                {getString(_ => _.library.saveToLibraryShelf.subHeadText)}
              </p>
              <CLIconButton
                icon={<Icon icon="folder-add" height="12" width="16" />}
                {...heapLibraryShelfAddFolderButton()}
                label={getString(_ => _.library.saveToLibraryShelf.addFolderLabel)}
                testId="save-library-add-folder-button"
                className="save-library__shelf__add-folder link-button--no-underline"
                type={BUTTON_TYPE.TERTIARY}
                onClick={this.onClickAddFolderButton}
              />
            </div>
            {isAddFolderFormOpen && (
              <AddFolderForm
                onClickClose={this.onClickCloseForm}
                onSuccessfulSave={this.onSuccessfulSave}
              />
            )}
            <CLIconTextInput
              className={classnames('save-library__shelf__filter', {
                'save-library__shelf__filter--mobile': isMobile,
              })}
              data-test-id="save-library-filter-input"
              icon={<Icon icon="search-small" height="16" width="16" />}
              {...heapLibraryShelfFolderFilterInput()}
              onChange={e => this.onChangeFilter(e.target.value)}
              placeholder={getString(_ => _.library.saveToLibraryShelf.filterPlaceholderText)}
              value={filterText}
            />
            {filteredFolders.size > 0 && (
              <div className="save-library__shelf__folders">
                {filteredFolders.map(folder => (
                  <LibraryShelfFolder
                    key={folder.id}
                    folder={folder}
                    onChangeFolderRow={this.handleFolderCheckbox}
                    isChecked={selectedFolders.includes(folder)}
                  />
                ))}
              </div>
            )}
            {filteredFolders.size === 0 && filterText.length !== 0 && (
              <div className="save-library__shelf__folder-not-found_wrapper">
                <Icon icon="folder-not-found" height="156" width="156" />
                <h4 className="save-library__shelf__folders-no-folders-title">
                  {getString(_ => _.library.saveToLibraryShelf.noFolderFound, filterText)}
                </h4>
              </div>
            )}
          </div>
          <div className="save-library__shelf__section save-library__shelf__save-button">
            <CLButton
              type={BUTTON_TYPE.PRIMARY}
              label={getString(_ => _.library.saveToLibraryShelf.saveButtonLabel)}
              fontSize={isMobile ? BUTTON_FONT_SIZE.MEDIUM : BUTTON_FONT_SIZE.DEFAULT}
              density={isMobile ? BUTTON_DENSITY.COMFORTABLE : BUTTON_DENSITY.DEFAULT}
              onClick={this.onClickSave}
              {...heapLibraryShelfSaveAndCloseButton()}
              testId="save-library-save-button"
              disabled={isSending}
            />
            {!!isSending && <LoadingIndicator />}
          </div>
        </div>
      </Shelf>
    );
  }
}
