import HistogramYearPopover from './HistogramYearPopover';

import * as util from '@/util';
import { mkOnClickKeyDown } from '@/utils/a11y-utils';
import { YearStatBucketRecordFactory } from '@/models/citation-stats/YearStatBucket';

import { max, range } from 'd3-array';
import { scaleBand, scaleLinear, scaleLog } from 'd3-scale';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

// TODO(codeviking): This should really be dynamic, what if the font overflows the container?
const POPOVER_WIDTH = 175;

/** The maximum width of an individual bar on the histogram. */
const MAX_HISTOGRAM_BAR_WIDTH = 40;

const LOG_SCALE_START = 0.75;

function hasBuckets(bucketRenderers) {
  return bucketRenderers.some(b => b.buckets && b.buckets.size > 0);
}

function getBucketValue(b) {
  return b.value ? b.value : b.count;
}

export default class Histogram extends React.PureComponent {
  static propTypes = {
    bucketRenderers: PropTypes.arrayOf(
      PropTypes.shape({
        buckets: PropTypes.object,
        renderer: PropTypes.func,
      })
    ).isRequired,
    id: PropTypes.string.isRequired,
    renderAxis: PropTypes.bool.isRequired,
    minSelected: PropTypes.number.isRequired,
    maxSelected: PropTypes.number.isRequired,
    onBucketClick: PropTypes.func,
    renderPopover: PropTypes.bool,
    filterPopoverCount: PropTypes.func,
    useLogScale: PropTypes.bool.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    paddingTopPx: PropTypes.number.isRequired,
    paddingRightPx: PropTypes.number.isRequired,
    paddingBottomPx: PropTypes.number.isRequired,
    paddingLeftPx: PropTypes.number.isRequired,
    enableBarHoverEffect: PropTypes.bool,
    recentYears: PropTypes.number,
    minDisplayYears: PropTypes.number,
    highlightRecentYears: PropTypes.bool,
    minMaxYear: PropTypes.number,
    showLabelsAsRange: PropTypes.bool,
    showOnlyFirstAndLastXAxisValues: PropTypes.bool,
    displayPopoverForEmptyBucket: PropTypes.bool.isRequired,
    popoverLabel: PropTypes.string,
    centerBucketPopover: PropTypes.bool,
  };

  static defaultProps = {
    minSelected: Number.MIN_VALUE,
    maxSelected: Number.MAX_VALUE,
    useLogScale: false,
    renderAxis: false,
    paddingTopPx: 0,
    paddingRightPx: 0,
    paddingBottomPx: 0,
    paddingLeftPx: 0,
    enableBarHoverEffect: true,
    showOnlyFirstAndLastXAxisValues: false,
    displayPopoverForEmptyBucket: true,
    centerBucketPopover: false,
  };

  static DEFAULT_HEIGHT = 140;

  static get DefaultPaddingPx() {
    return {
      TOP: 10,
      RIGHT: 20,
      BOTTOM: 35,
      LEFT: 60,
    };
  }

  static xScale(min, max, step, width) {
    return scaleBand()
      .rangeRound([0, width])
      .domain(range(min, max + 1, step))
      .padding(0.2);
  }

  static scaleState(props) {
    // TODO(codeviking): There's code below that fails if the histogram doesn't have at least one
    // bucket to render.  We should fix the Histogram so that it handles the abscence of any buckets
    // more gracefully
    const primaryBuckets = hasBuckets(props.bucketRenderers)
      ? props.bucketRenderers[0].buckets
      : Immutable.List([YearStatBucketRecordFactory()]);

    const width = props.width - props.paddingRightPx - props.paddingLeftPx;
    const height = props.height - props.paddingTopPx - props.paddingBottomPx;

    let yScale;
    if (props.useLogScale) {
      yScale = scaleLog().domain([
        LOG_SCALE_START,
        max(primaryBuckets.toArray(), b => getBucketValue(b)),
      ]);
    } else {
      yScale = scaleLinear().domain([0, max(primaryBuckets.toArray(), b => getBucketValue(b))]);
    }
    yScale.rangeRound([0, height]);

    let minMaxYear = 0;
    if (typeof props.minMaxYear === 'number') {
      minMaxYear = props.minMaxYear;
    }

    const maxDisplayYear = Math.max(minMaxYear, primaryBuckets.last().endKey);

    let minDisplayYear;
    if (typeof props.minDisplayYears === 'number' && props.minDisplayYears > 0) {
      minDisplayYear = Math.min(
        primaryBuckets.first().startKey,
        maxDisplayYear - (props.minDisplayYears - 1)
      );
    } else {
      minDisplayYear = primaryBuckets.first().startKey;
    }

    const xScale = Histogram.xScale(
      minDisplayYear,
      maxDisplayYear,
      primaryBuckets.first().endKey - primaryBuckets.first().startKey + 1,
      width
    );

    // HACK (codeviking)
    // We wrap d3's yScale to resolve an issue with the log scale in D3 (log(0) is Infinity, which
    // was causing an exception on the client):
    // @see https://github.com/mbostock/d3/issues/1420
    const yScaleShim = value => {
      if (props.useLogScale) {
        return yScale(Math.max(value, LOG_SCALE_START));
      } else {
        return yScale(value);
      }
    };
    yScaleShim.ticks = (...args) => yScale.ticks(...args);

    return {
      xScale: xScale,
      yScale: yScaleShim,
      popoverBucketKey: null,
    };
  }

  static get bucketRenderers() {
    return {
      selectedBuckets(histogram, buckets, idx, alwaysInactive) {
        return (
          <g key={idx} className="buckets">
            {buckets.map(b => {
              const isHovered =
                histogram.props.enableBarHoverEffect &&
                (histogram.state.popoverBucketKey === b.startKey ||
                  histogram.state.popoverBucketKey === b.endKey);

              // if the highlightRecentYears flag is true, only the recent years should be highlighted
              let isActive;
              if (histogram.props.highlightRecentYears) {
                const minHighlightedYear =
                  new Date().getFullYear() -
                  (typeof histogram.props.recentYears === 'number'
                    ? histogram.props.recentYears - 1
                    : 0);
                isActive = !alwaysInactive && b.startKey >= minHighlightedYear;
              } else {
                isActive =
                  !alwaysInactive &&
                  b.startKey >= histogram.props.minSelected &&
                  b.startKey <= histogram.props.maxSelected;
              }

              let gradientName;
              if (isHovered && !alwaysInactive) {
                gradientName = 'Hover';
              } else if (isActive) {
                gradientName = '';
              } else {
                gradientName = 'Inactive';
              }
              const fill = `url(#BarGradient${gradientName}-${histogram.props.id})`;

              const x = histogram.state.xScale(b.startKey) + histogram.props.paddingLeftPx;
              const y =
                histogram.props.height -
                histogram.state.yScale(getBucketValue(b)) +
                histogram.props.paddingTopPx -
                histogram.props.paddingBottomPx;

              const scaledBarWidth = histogram.state.xScale.bandwidth();
              const xAdj = (scaledBarWidth - MAX_HISTOGRAM_BAR_WIDTH) / 2;
              const barWidth = Math.min(scaledBarWidth, MAX_HISTOGRAM_BAR_WIDTH);

              return (
                <g key={b.startKey}>
                  <rect
                    data-test-id="histogram-bar"
                    fill={fill}
                    width={barWidth}
                    height={histogram.state.yScale(getBucketValue(b))}
                    x={xAdj > 0 ? x + xAdj : x}
                    y={y}
                  />
                </g>
              );
            })}
          </g>
        );
      },

      unfilteredBuckets(histogram, buckets, idx) {
        return Histogram.bucketRenderers.selectedBuckets(histogram, buckets, idx, true);
      },
    };
  }

  constructor(...args) {
    super(...args);

    this.state = Histogram.scaleState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(Histogram.scaleState(nextProps));
  }

  bucketKeyForEvent(e) {
    const boundingRect = ReactDOM.findDOMNode(this).getBoundingClientRect();
    const xPos = e.clientX - boundingRect.left - this.props.paddingLeftPx;
    return util.domainValForMousePos(this.state.xScale, xPos);
  }

  onClick = e => {
    const key = this.bucketKeyForEvent(e);
    const primaryBuckets = this.props.bucketRenderers[0].buckets;
    const bucket = primaryBuckets.find(b => b.startKey === key);
    if (bucket && this.props.onBucketClick) {
      this.props.onBucketClick(bucket);
      e.stopPropagation();
    }
  };

  onMouseMove = e => {
    const key = this.bucketKeyForEvent(e);
    this.setState({ popoverBucketKey: key });
  };

  onMouseLeave = () => {
    this.setState({ popoverBucketKey: null });
  };

  _onClickKeyDownProps = mkOnClickKeyDown({
    onClick: this.onClick,
  });

  renderPopoverBucket() {
    if (this.props.renderPopover && this.state.popoverBucketKey) {
      const bucket = this.props.bucketRenderers[0].buckets.find(
        b => b.startKey === this.state.popoverBucketKey
      );

      if (bucket && this.props.centerBucketPopover) {
        const count = this.props.filterPopoverCount ? this.props.filterPopoverCount(bucket) : null;
        return (
          (this.props.displayPopoverForEmptyBucket || count > 0) && (
            <div className="bucket-popover-centered">
              <HistogramYearPopover bucket={bucket} count={count} label={this.props.popoverLabel} />
            </div>
          )
        );
      }

      if (bucket) {
        const x = Math.round(
          this.state.xScale(bucket.startKey) +
            this.props.paddingLeftPx +
            this.state.xScale.bandwidth() / 2 -
            POPOVER_WIDTH / 2
        );

        const y = this.state.yScale(getBucketValue(bucket)) + this.props.paddingBottomPx;
        const count = this.props.filterPopoverCount ? this.props.filterPopoverCount(bucket) : null;

        return this.props.displayPopoverForEmptyBucket || count > 0 ? (
          <div className="bucket-popover" style={{ left: x, bottom: y }}>
            <HistogramYearPopover bucket={bucket} count={count} label={this.props.popoverLabel} />
          </div>
        ) : null;
      }
    }

    return null;
  }

  renderXAxis() {
    const y = this.props.height - this.props.paddingBottomPx + this.props.paddingTopPx + 20;

    const step = Math.max(1, Math.round(33 / this.state.xScale.bandwidth()));
    const labelCount = this.state.xScale.domain().length;

    const allValues = this.state.xScale.domain();
    const values = this.props.showOnlyFirstAndLastXAxisValues
      ? [allValues[0], allValues[allValues.length - 1]]
      : allValues;

    return (
      <g className="x-axis">
        {values.map((val, i) => {
          // The histogram needs three different year formats depending on how many
          // years need to be displayed and how wide the histogram is. This was
          // handled solely with arbitrary boundaries based on how many years are
          // present, but that approach was insufficient because the paper detail
          // histogram is 100px narrower than the SERP histogram.
          // Instead, we can test for the minimum width necessary to display
          // the full year with some space between labels. This produces
          // labels that are appropriate to the width of the histogram.
          // In cases where we want to display a range rather than just a year,
          // we are always counting from the current month and construct the range
          // here because the x axis is independent from the data.
          let label = val.toString();
          const truncatedYear = label.substr(label.length - 2);
          const spacePerFullYearLabel = 45;

          if (this.props.showLabelsAsRange) {
            const month = new Date().getMonth() + 1;
            const nextYear = (val + 1).toString().substr(label.length - 2);
            label = `${month}/${truncatedYear} - ${month}/${nextYear}`;
          } else if (
            !this.props.showOnlyFirstAndLastXAxisValues &&
            this.props.width / labelCount <= spacePerFullYearLabel
          ) {
            label = `'${truncatedYear}`;
          }

          if (
            this.props.showOnlyFirstAndLastXAxisValues ||
            (i === 0 && step > 3) ||
            (i + 1) % step === 0
          ) {
            const x =
              Math.round(this.state.xScale(val) + this.state.xScale.bandwidth() / 2) +
              this.props.paddingLeftPx;
            return (
              <g key={val}>
                <text x={x} y={y}>
                  {label}
                </text>
                <line x1={x} x2={x} y1={y - 14} y2={y - 20} />
              </g>
            );
          } else {
            return null;
          }
        })}
      </g>
    );
  }

  renderYAxis() {
    const x1 = this.props.paddingLeftPx - 8;
    const x2 = x1 + this.props.width - this.props.paddingLeftPx - this.props.paddingRightPx + 16;
    const ticks = this.state.yScale.ticks(3);

    return ticks.map(tick => {
      if (!Number.isInteger(tick)) {
        return null;
      }

      const y =
        this.props.height -
        this.state.yScale(tick) +
        this.props.paddingTopPx -
        this.props.paddingBottomPx;
      return (
        // Note that there is not a y-axis, just lines corresponding to where
        // tick marks would be.
        <g key={tick} className="y-axis-tick">
          <text x={x1 - 5} y={y}>
            {tick}
          </text>
          <line x1={x1} x2={x2} y1={y} y2={y} />
        </g>
      );
    });
  }

  render() {
    if (hasBuckets(this.props.bucketRenderers)) {
      return (
        <div
          className="histogram"
          {...this._onClickKeyDownProps}
          onMouseMove={this.onMouseMove}
          onMouseLeave={this.onMouseLeave}>
          {this.renderPopoverBucket()}
          <svg width={this.props.width} height={this.props.height}>
            <linearGradient id={'BarGradient-' + this.props.id} x1="0" x2="0" y1="0" y2="1">
              <stop className="bar-stop1" offset="0" />
              <stop className="bar-stop2" offset="1" />
            </linearGradient>
            <linearGradient id={'BarGradientInactive-' + this.props.id} x1="0" x2="0" y1="0" y2="1">
              <stop className="bar-stop1-inactive" offset="0" />
              <stop className="bar-stop2-inactive" offset="1" />
            </linearGradient>
            <linearGradient id={'BarGradientHover-' + this.props.id} x1="0" x2="0" y1="0" y2="1">
              <stop className="bar-stop-hover" offset="0" />
            </linearGradient>
            {this.props.renderAxis ? this.renderYAxis() : null}
            {this.props.renderAxis ? this.renderXAxis() : null}
            {this.props.bucketRenderers.map(({ renderer, buckets }, i) => {
              return renderer(this, buckets, i);
            })}
          </svg>
        </div>
      );
    } else {
      return null;
    }
  }
}
