import softError from './softError';

import { AlertQueryType, QUERY_TYPE, STATUS } from '@/constants/Alert';
import { AlertRecordFactory } from '@/models/Alert';
import { getString } from '@/content/i18n';
import { Off, On, RecommendationStatus } from '@/models/library/RecommendationStatus';
import AlertsStore from '@/stores/AlertsStore';
import Api from '@/api/Api';
import AuthStore from '@/stores/AuthStore';
import ContentWithHTML from '@/components/util/ContentWithHTML';

import React from 'react';

export function getAlertSuccessMessage(
  queryType: AlertQueryType,
  displayValue: string,
  email: string
): React.ReactNode {
  switch (queryType) {
    case QUERY_TYPE.PAPER_CITATION:
    case QUERY_TYPE.AUTHOR_CITATION:
      return (
        <ContentWithHTML
          content={_ => _.alerts.create.message.success.citationAlertBody}
          args={[email, displayValue]}
        />
      );
    case QUERY_TYPE.AUTHOR_PAPER:
      return (
        <ContentWithHTML
          content={_ => _.alerts.create.message.success.authorPaperAlertBody}
          args={[email, displayValue]}
        />
      );
    case QUERY_TYPE.ENTITY_PAPER:
      return (
        <ContentWithHTML
          content={_ => _.alerts.create.message.success.entityAlertBody}
          args={[email, displayValue]}
        />
      );
    default:
      return getString(_ => _.alerts.create.message.success.body, displayValue);
  }
}

export async function maybeCreateAlert(
  api: Api,
  authStore: AuthStore,
  queryType: AlertQueryType,
  queryValue: number
): Promise<void> {
  const alertEmail = authStore.getAlertEmail();
  const loginEmail = authStore.getEmail();

  // no op if there is no login email or alert email
  if (!alertEmail && !loginEmail) {
    return;
  }

  // if user does not have an alert email, set up alert email using the login email
  if (!alertEmail && loginEmail) {
    try {
      await api.updateUser({ alertEmail: loginEmail });
    } catch (error) {
      softError(
        'userUpdateFailure',
        `failed to set alert email for user[id=${authStore.getUser()?.id}]`,
        error
      );
      return;
    }
  }

  // create alert
  const newAlert = AlertRecordFactory({ queryType, queryValue: queryValue.toString() });
  try {
    await api.createAlert(newAlert);
  } catch (error) {
    softError('createAlertFailure', `failed to create alert`, error);
  }
}

export async function maybeUpdateLibraryFolderAlert(
  api: Api,
  alertsStore: AlertsStore,
  libraryFolderId: number,
  recommendationStatus: RecommendationStatus
): Promise<void> {
  const alert = alertsStore.getAlert(QUERY_TYPE.LIBRARY_FOLDER, libraryFolderId.toString());

  if (!alert) {
    return;
  }

  if (recommendationStatus === On && !alert.isActive) {
    await api.updateAlert({
      alertId: parseInt(alert.id, 10), // base 10
      queryValue: alert.queryValue,
      status: STATUS.ACTIVE,
    });
  } else if (recommendationStatus === Off && alert.isActive) {
    await api.updateAlert({
      alertId: parseInt(alert.id, 10), // base 10
      queryValue: alert.queryValue,
      status: STATUS.DISABLED,
    });
  }
}
