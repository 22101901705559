import CLPaperAbstract from './CLPaperAbstract';
import CLPaperActions from './CLPaperActions';
import CLPaperExcerptAndIntentsFlag from './flags/CLPaperExcerptAndIntentsFlag';
import CLPaperHighlyInfluencedFlag from './flags/CLPaperHighlyInfluencedFlag';
import CLPaperMeta from './CLPaperMeta';
import CLPaperStats from './stats/CLPaperStats';
import CLPaperTitle from './CLPaperTitle';
import MockPaper, { MockPaperLiteFromJs } from '../../../../../test/utils/MockPaper';

import { addHeapProps } from '@/components/util/HeapTracking';
import { AuthorAliasFromJS, getAuthorAliasFromJS } from '@/models/author/AuthorAlias';
import { checkForPaperRecord } from '@/utils/paper-util';
import { CitationType } from '@/constants/Citation';
import { ExampleConfig } from '@/components/library/ExampleUtils';
import { getHighlightedFieldFromJS } from '@/models/HighlightedField';
import { HeapProps } from '@/analytics/heapUtils';
import { HighlightedAuthorRecordFactory } from '@/models/HighlightedAuthor';
import { Nullable, ReactNodeish } from '@/utils/types';
import { PaperishRecord } from '@/models/Paper';
import { PaperLinkRecordFactory } from '@/models/PaperLink';
import CLCardContainer from '@/components/library/container/CLCardContainer';
import PaperLinkType from '@/constants/PaperLinkType';

import classnames from 'classnames';
import Immutable from 'immutable';
import React from 'react';

type Props = {
  className?: Nullable<string>;
  heapProps?: Nullable<HeapProps>;
  paper: PaperishRecord;
  title: Nullable<boolean> | ReactNodeish;
  meta?: Nullable<boolean> | ReactNodeish;
  abstract?: Nullable<boolean> | ReactNodeish;
  controls?: Nullable<boolean> | ReactNodeish;
  header?: ReactNodeish;
  footer?: ReactNodeish;
  hasDogEar?: boolean;
  isInteractive?: Nullable<boolean>;
};

export default class CLPaperCard extends React.PureComponent<Props> {
  static defaultProps = {
    title: true,
    meta: true,
    abstract: true,
    controls: true,
    hasDogEar: false,
    isInteractive: true,
  };

  render(): ReactNodeish {
    const {
      title,
      meta,
      abstract,
      controls,
      className,
      heapProps,
      paper,
      header,
      footer,
      hasDogEar,
      isInteractive,
    } = this.props;

    const cardFooter = footer && <div className="cl-paper-card__footer">{footer}</div>;

    return (
      <CLCardContainer
        className={classnames('cl-paper-card', className)}
        {...addHeapProps(heapProps)}
        hasDogEar={hasDogEar}
        footer={cardFooter}
        data-paper-id={paper.id}>
        {header && <div className="cl-paper-card__header">{header}</div>}
        <div className="cl-paper-card__content">
          {renderTitle({ title, paper, isInteractive })}
          {renderMeta({ meta, paper, isInteractive })}
          {renderAbstract({ abstract, paper })}
          {renderControls({ controls, paper })}
        </div>
      </CLCardContainer>
    );
  }
}

function renderTitle({
  title,
  paper,
  isInteractive,
}: {
  title: Nullable<boolean> | ReactNodeish;
  paper: PaperishRecord;
  isInteractive?: Nullable<boolean>;
}) {
  if (!title) {
    return null;
  }

  return typeof title === 'boolean' ? (
    <CLPaperTitle paper={paper} isInteractive={isInteractive} />
  ) : (
    title
  );
}

function renderMeta({
  meta,
  paper,
  isInteractive,
}: {
  meta?: Nullable<boolean> | ReactNodeish;
  paper: PaperishRecord;
  isInteractive?: Nullable<boolean>;
}) {
  if (!meta) {
    return null;
  }

  return typeof meta === 'boolean' ? (
    // stacks paper meta by default in CLPaperCard
    <CLPaperMeta paper={paper} shouldStackMeta={true} isInteractive={isInteractive} />
  ) : (
    meta
  );
}

function renderAbstract({
  abstract,
  paper,
}: {
  abstract?: Nullable<boolean> | ReactNodeish;
  paper: PaperishRecord;
}) {
  if (!abstract) {
    return null;
  }

  return typeof abstract === 'boolean' ? <CLPaperAbstract paper={paper} /> : abstract;
}

function renderControls({
  controls,
  paper,
}: {
  controls?: Nullable<boolean> | ReactNodeish;
  paper: PaperishRecord;
}) {
  if (!controls) {
    return null;
  }

  if (typeof controls === 'boolean') {
    // Only show citation stats for PaperRecords
    const maybePaperRecord = checkForPaperRecord(paper);
    return maybePaperRecord ? (
      <div className="cl-paper-card__controls">
        <CLPaperStats paper={maybePaperRecord} />
      </div>
    ) : null;
  }

  // override controls with passed in prop
  return <div className="cl-paper-card__controls">{controls}</div>;
}

// TODO(#21359): Split this into a separate file
const rawAuthor: AuthorAliasFromJS = {
  name: 'John Doe',
  slug: 'John-Doe',
  ids: ['1337'],
  optIsClaimed: false,
};

const author1 = HighlightedAuthorRecordFactory({
  alias: getAuthorAliasFromJS(rawAuthor),
  highlightedField: getHighlightedFieldFromJS({
    text: 'John Doe',
    fragments: [],
  }),
});

const PAPER = MockPaper({
  id: '94e2260617274d17ef9ebf84b5081d5fc919cbab',
  authors: Immutable.List([author1, author1, author1, author1]),
  primaryPaperLink: PaperLinkRecordFactory({
    url: 'https://www.semanticscholar.org/paper/94e2260617274d17ef9ebf84b5081d5fc919cbab',
    linkType: PaperLinkType.S2,
    publisherName: 'Semantic Scholar',
  }),
  pubDate: getHighlightedFieldFromJS({ text: '5 May 2001' }),
});

const PAPER_LITE = MockPaperLiteFromJs({
  id: '94e2260617274d17ef9ebf84b5081d5fc919cbab',
  authors: [rawAuthor, rawAuthor, rawAuthor, rawAuthor],
  pubDate: '2001-05-05',
  slug: 'super-important-paper',
});

export const exampleConfig: ExampleConfig = {
  getComponent: () => CLPaperCard,
  fields: [
    {
      name: 'title',
      desc: 'Displayed default title or not',
      value: {
        type: 'boolean',
        default: true,
      },
    },
    {
      name: 'meta',
      desc: 'Displayed default paper meta or not',
      value: {
        type: 'boolean',
        default: true,
      },
    },
    {
      name: 'abstract',
      desc: 'Displayed default abstract or not',
      value: {
        type: 'boolean',
        default: true,
      },
    },
    {
      name: 'controls',
      desc: 'Displayed default controls or not',
      value: {
        type: 'boolean',
        default: true,
      },
    },
    {
      name: 'isInteractive',
      desc: 'Adds links and click handlers when enabled',
      value: {
        type: 'boolean',
        default: true,
      },
    },
  ],

  examples: [
    {
      title: 'PaperCard with Paper',
      desc: '',
      props: {
        paper: PAPER,
        isInteractive: true,
      },
      render: comp => <div style={{ width: '500px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'PaperCard with PaperLite',
      desc: '',
      props: {
        paper: PAPER_LITE,
        isInteractive: true,
      },
      render: comp => <div style={{ width: '500px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'Card with flags in header',
      desc: '',
      props: {
        paper: PAPER,
        isInteractive: true,
        header: (
          <div style={{ display: 'flex', height: '100%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '0 10px',
                width: '50%',
                borderRight: '1px solid #d9dadb',
              }}>
              <CLPaperHighlyInfluencedFlag citationType={CitationType.CITING_PAPERS} />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '0 10px',
                width: '50%',
              }}>
              <CLPaperExcerptAndIntentsFlag label="View 3 Excerpts" />
            </div>
          </div>
        ),
      },
      render: comp => <div style={{ width: '500px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'Card with paper actions in footer',
      desc: '',
      props: {
        paper: PAPER,
        isInteractive: true,
        footer: (
          <div style={{ display: 'flex', height: '100%', padding: '0 20px' }}>
            <CLPaperActions paper={PAPER_LITE} />
          </div>
        ),
      },
      render: comp => <div style={{ width: '500px', padding: '16px' }}>{comp}</div>,
    },
  ],

  events: {},
};
