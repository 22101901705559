import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

export default class AccountAddPapersRoute extends React.Component {
  static getPageTitle = () => getString(_ => _.account.page.addPapers.pageTitle);

  render() {
    return (
      <AsyncLoadedPage
        showLoadingPage={false}
        load={{
          importer: () =>
            import(/* webpackChunkName: "shared-AccountAddPapersPage" */ './AccountAddPapersPage'),
          chunkName: 'shared-AccountAddPapersPage',
          moduleId: require.resolveWeak('./AccountAddPapersPage'),
        }}
      />
    );
  }
}
