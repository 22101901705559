import {
  ActiveRoute,
  collectRobotDirectives,
  getAlternativePdfUrl,
  getDublinCoreMeta,
  getFaviconFilePath,
  getPageCitationMeta,
  getPageFigure,
  getPageMetaDescription,
  getPageSchemaData,
  getPageTitle,
  getRequiredRoles,
  maybeGetCanonicalUrl,
  pageRequiresAuth,
} from './routerUtils';

import { isLinkElement, isMetaElement, Nullable } from '@/utils/types';
import AppContext from '@/AppContext';
import logger from '@/logger';
import Redirect from '@/models/redirects/Redirect';
import S2Dispatcher from '@/utils/S2Dispatcher';
import S2History from '@/utils/S2History';
import S2Redirect from '@/models/redirects/S2Redirect';
import softError from '@/utils/softError';

import invariant from 'invariant';

import type { Location } from 'history';

// Location should be exported?
// eslint-disable-next-line import/named

// rehydrate inline data from server render
export function rehydrateStoresFromPrefetchedData(dispatcher: S2Dispatcher) {
  if (!window.DATA) {
    logger.error('window.DATA is missing (maybe already checked?).');
    return;
  }

  const serverData = JSON.parse(decodeURIComponent(window.atob(window.DATA)));
  window.DATA = undefined;

  if (!Array.isArray(serverData)) {
    logger.error('Attempted to rehydrate with a non-array.');
    return;
  }

  // data fetched on the server is represented as a series of actions which we dispatch on the
  // client to repopulate the stores with the same data that was on the server.
  for (const action of serverData) {
    dispatcher.dispatch(action);
  }
}

/**
 * Updates all of the content in the <head /> tag of the page
 * @param {Object} state - the current router state
 * @param {function} router - used to redirect and get the canonical url
 * @param {AppContext} appContext - global context for the app
 * @param {Object} location - the location information
 */

export function updateHeadTagContent({
  activeRoutes,
  appContext,
  location,
}: {
  activeRoutes: ActiveRoute[];
  appContext: AppContext;
  location: Location;
}): void {
  updatePageTitle(getPageTitle({ activeRoutes, appContext, location }));
  updateFaviconLinks(getFaviconFilePath({ activeRoutes }));
  updatePageCitationMeta(getPageCitationMeta({ activeRoutes, appContext }));
  updateDublinCoreMeta(getDublinCoreMeta({ activeRoutes, appContext }));
  updateAlternativePdfUrl(getAlternativePdfUrl({ activeRoutes, appContext }));
  updatePageMetaDescription(getPageMetaDescription({ activeRoutes, appContext }));
  updatePageMetaFigure(getPageFigure({ activeRoutes, appContext }));
  updatePageLink(maybeGetCanonicalUrl({ activeRoutes }));
  updatePageSchemaData(getPageSchemaData({ activeRoutes, appContext }));
  updateRobotsMeta(collectRobotDirectives({ activeRoutes, appContext }));
}

/**
 * Updates the page title
 * @param {string} title - the new page title
 */
export function updatePageTitle(title) {
  document.title = title;

  if (title) {
    updateOrCreateMetaByName('twitter:title', title);
    updateOrCreateMetaByProperty('og:title', title);
  } else {
    removeMetaByName('twitter:title');
    removeMetaByProperty('og:title');
  }
}

/**
 * Checks if favicon needs updating based on route, if so update the favicon links in the header
 * @param {string} faviconFilePath - the file path to the current page's favicon
 */
export function updateFaviconLinks(faviconFilePath) {
  const links = document.head.querySelectorAll('.favicon');
  if (!links.length || !links[0]) {
    return;
  }
  let firstLink;
  if (isLinkElement(links[0])) {
    firstLink = links[0].href;
  } else if (isMetaElement(links[0])) {
    firstLink = links[0].textContent;
  }
  invariant(!!firstLink, 'unrecognized link type');
  if (firstLink === getLinkToNewFavicon(firstLink, faviconFilePath)) {
    return; // favicon path doesnt need updating
  }

  links.forEach(element => {
    if (isLinkElement(element)) {
      element.href = getLinkToNewFavicon(element.href, faviconFilePath);
    } else if (isMetaElement(element)) {
      element.content = getLinkToNewFavicon(element.content, faviconFilePath);
    }
  });
}

export function getLinkToNewFavicon(href, filePath) {
  const fileName = href.substr(href.lastIndexOf('/') + 1);
  const darkMode = href.includes('/darkmode/') ? 'darkmode/' : '';
  return href.substr(0, href.lastIndexOf('/img/')) + '/' + filePath + '/' + darkMode + fileName;
}

/**
 * Updates the page citation meta tags
 * @param {array} citationMeta - the new page citation meta
 */
export function updatePageCitationMeta(citationMeta) {
  if (citationMeta) {
    citationMeta.forEach(meta => updateOrCreateMetaByProperty(meta.name, meta.content));
  }
}

/**
 * Updates the page meta description
 * @param {string} metaDescription - the new page meta description
 */
export function updatePageMetaDescription(metaDescription) {
  if (metaDescription) {
    updateOrCreateMetaByName('description', metaDescription);
    updateOrCreateMetaByName('twitter:description', metaDescription);
    updateOrCreateMetaByProperty('og:description', metaDescription);
  } else {
    removeMetaByName('description');
    removeMetaByName('twitter:description');
    removeMetaByProperty('og:description');
  }
}

/**
 * Updates the Dublin Core meta
 * @param {DublinCoreMeta} dublinCoreMeta
 */
export function updateDublinCoreMeta(dublinCoreMeta) {
  const DC_ID = 'dc.identifier';
  const DC_RELATION_PART = 'dc.relation.ispartof';

  if (dublinCoreMeta) {
    if (dublinCoreMeta.dublinCoreId) {
      updateOrCreateMetaByName(DC_ID, dublinCoreMeta.dublinCoreId);
    }
    if (dublinCoreMeta.showDublinCoreRelation) {
      updateOrCreateMetaByName(DC_RELATION_PART, `https://${location.host}`);
    }
  } else {
    removeMetaByName(DC_ID);
    removeMetaByName(DC_RELATION_PART);
  }
}

/**
 * Updates the alternative pdf url meta
 * @param {string} alternativePdfUrl
 */
export function updateAlternativePdfUrl(alternativePdfUrl) {
  const ALTERNATIVE_PDF_URL_ID = 'citation_pdf_url';

  if (alternativePdfUrl) {
    updateOrCreateMetaByName(ALTERNATIVE_PDF_URL_ID, alternativePdfUrl);
  } else {
    removeMetaByName(ALTERNATIVE_PDF_URL_ID);
  }
}

/**
 * Updates the page meta figure
 * @param {Object} metaFigure - the link to the new page meta figure
 */
export function updatePageMetaFigure(metaFigure) {
  if (metaFigure) {
    updateOrCreateMetaByProperty('og:image', metaFigure.uri);
    updateOrCreateMetaByProperty('og:image:secure_url', metaFigure.uri);
    updateOrCreateMetaByProperty('og:image:width', metaFigure.width);
    updateOrCreateMetaByProperty('og:image:height', metaFigure.height);
    updateOrCreateMetaByName('twitter:image', metaFigure.uri);
  } else {
    removeMetaByProperty('og:image');
    removeMetaByProperty('og:image:secure_url');
    removeMetaByProperty('og:image:width');
    removeMetaByProperty('og:image:height');
    removeMetaByName('twitter:image');
  }
}

/**
 * Updates the page link
 * @param {string} canonicalUrl - the new page URL
 */
export function updatePageLink(canonicalUrl: Nullable<string>) {
  const link = getCanonicalUrlElement();
  if (canonicalUrl) {
    // canonicalUrl is relative, so need to add the origin if possible
    const href =
      typeof window !== 'undefined' ? window.location.origin + canonicalUrl : canonicalUrl;
    link.setAttribute('href', href);
  } else {
    if (link.parentElement) {
      link.parentElement.removeChild(link);
    }
  }
}

function getFirstMetaByProperty(propertyName: string): HTMLMetaElement | undefined {
  // This query should only return HTMLMetaElements, hence the type assertion
  return getHeadElementsByQuery(`meta[property="${propertyName}"]`).pop() as
    | HTMLMetaElement
    | undefined;
}

function getFirstMetaByName(name: string): HTMLMetaElement | undefined {
  // This query should only return HTMLMetaElements, hence the type assertion
  return getHeadElementsByQuery(`meta[name="${name}"]`).pop() as HTMLMetaElement | undefined;
}

function getHeadElementsByQuery(query: string): Element[] {
  return Array.from(document.head.querySelectorAll(query));
}

/**
 * Updates the schema markup for the page
 * @param {String} schemaData - the markup in json format
 */
function updatePageSchemaData(schemaData: Nullable<string>): void {
  const className = 'schema-data';
  const existing = document.head.getElementsByClassName(className);

  if (schemaData) {
    // Update existing elements
    if (existing.length > 0) {
      existing[0].innerHTML = schemaData;
    } else {
      // Create new element
      const script = document.createElement('script');
      script.setAttribute('type', 'application/ld+json');
      script.setAttribute('class', className);
      script.innerHTML = schemaData;
      document.head.appendChild(script);
    }
    // Remove linked data if the new page has none
  } else if (existing.length > 0) {
    document.head.removeChild(existing[0]);
  }
}

function updateRobotsMeta(directives: Nullable<string>): void {
  if (directives) {
    updateOrCreateMetaByName('robots', directives);
  } else {
    removeMetaByName('robots');
  }
}

function getCanonicalUrlElement(): Element {
  const existing = getHeadElementsByQuery('link[rel="canonical"]');

  if (existing.length > 0) {
    if (existing.length > 1) {
      throw new Error('More than one canonical URL. This should not occur.');
    }
    return existing[0];
  } else {
    const link = document.createElement('link');
    link.setAttribute('rel', 'canonical');
    document.head.appendChild(link);
    return link;
  }
}

function updateOrCreateMetaByName(name: string, content: any): void {
  const existing = getFirstMetaByName(name);
  if (existing) {
    existing.setAttribute('content', content);
  } else {
    const meta = document.createElement('meta');
    meta.setAttribute('property', name);
    meta.setAttribute('name', name);
    meta.setAttribute('content', content);
    document.head.appendChild(meta);
  }
}

function updateOrCreateMetaByProperty(property: string, content: any): void {
  const existing = getFirstMetaByProperty(property);
  if (existing) {
    existing.setAttribute('content', content);
  } else {
    const meta = document.createElement('meta');
    meta.setAttribute('property', property);
    meta.setAttribute('content', content);
    document.head.appendChild(meta);
  }
}

function removeMetaByName(name: string): void {
  const node = getFirstMetaByName(name);
  if (node) {
    node.parentElement?.removeChild(node);
  }
}

function removeMetaByProperty(property: string): void {
  const node = getFirstMetaByProperty(property);
  node?.parentElement?.removeChild(node);
}

/**
 * Attempt to check for authentication, if the page requires it.
 * @param {AppContext} appContext - global context for the app
 * @param {ReactElement} activeRoutes - the routes (urls) supported by the app
 * @return {Promise} Return a promise that resolves if the auth check is successful
 */
// This doesn't need to be async according to TS, but we rely on it being an async function
// eslint-disable-next-line @typescript-eslint/require-await
export async function maybeGetAuth({
  appContext,
  activeRoutes,
}: {
  appContext: AppContext;
  activeRoutes: ActiveRoute[];
}): Promise<void> {
  const { authStore } = appContext;
  if (!pageRequiresAuth(activeRoutes)) {
    return;
  }
  const requiredRoles = getRequiredRoles(activeRoutes);
  const hasAuth =
    authStore.hasAuthenticatedUser() &&
    (!requiredRoles || requiredRoles.some(role => authStore.hasUserRole(role)));
  if (!hasAuth) {
    // User isn't signed in or doesn't have the required roles, so fake a 401
    // eslint-disable-next-line no-throw-literal
    throw { status: 401 };
  }
}

/**
 * Handles a client-side redirect.
 *
 * @param {Object} history - the history object
 * @param {Redirect} redirect - instance of the redirect
 * #
 */
export function handleRedirect({
  history,
  redirect,
}: {
  history: S2History;
  redirect: Redirect;
}): void {
  if (redirect instanceof S2Redirect) {
    if (redirect.replace) {
      history.replace(redirect);
    } else {
      history.push(redirect);
    }
  } else {
    if (!redirect.url) {
      softError('clientUtils.handleRedirect', 'no redirect URL given');
    } else {
      document.location = redirect.url;
    }
  }
}
