import { getBrowserCookieJar } from './utils/cookies/BrowserCookieJar';
import { getLayoverLogger } from './utils/layover/LayoverLogger';
import { getLayoverMetrics, measurePerfSync } from './utils/layover/LayoverMetrics';
import { getLayoverRico } from './utils/layover/LayoverRico';
import { getRouteNameForPath } from './router/Routes';
import { isDev } from './utils/env';
import { isMobileUserAgent } from './user-agent';
import { listenForAppHeightChanges } from './browser';
import { listenForHeapIdEvents } from './analytics/heapUtils';
import { nextTask } from './utils/promise-utils';
import { rehydrateStoresFromPrefetchedData } from './router/clientUtils';
import { trackPerformanceMetrics } from './analytics/performanceMetrics';
import AppContext, { AppReactContext } from './AppContext';
import Experiment, { getActiveExperimentKeys } from './weblab/Experiment';
import RenderErrorLogger from './components/util/RenderErrorLogger';
import routes from './router/appRoutes';

import { renderRoutes } from 'react-router-config';
import { Router } from 'react-router';
import invariant from 'invariant';
import React from 'react';
import ReactDOM from 'react-dom';

// Enable reporting of unhandled exceptions within promises while in development
if (isDev) {
  // eslint-disable-next-line import/no-extraneous-dependencies, @typescript-eslint/no-var-requires
  require('promise/lib/rejection-tracking').enable(); // eslint-disable-line no-undef
}

const AppContextProvider = AppReactContext.Provider;

setBundleFilePath();
(async () => {
  const { pathname } = document.location;
  const isMobile = isMobileUserAgent(navigator.userAgent);
  const cookieJar = getBrowserCookieJar();
  const appContext = AppContext.create({ isMobile, cookieJar });
  const { dispatcher, weblabStore, history } = appContext;

  // Init things that require app context
  getLayoverLogger().associateWithAppContext(appContext);
  getLayoverRico().associateWithAppContext(appContext);
  const layoverMetrics = getLayoverMetrics();
  layoverMetrics.associateWithAppContext(appContext);
  layoverMetrics.addGlobalTag(`device_type:${isMobile ? 'mobile' : 'desktop'}`);

  // Init async things
  await weblabStore.initialize();

  // Before we mount the application, we need to initialize the correct weblab variations to
  // ensure that the client application matches what was prerendered on the server.
  weblabStore.initializeVariations(getActiveExperimentKeys(Experiment, pathname));

  // Listen for global events
  listenForAppHeightChanges();
  listenForUncaughtErrors();
  const appElement = document.getElementById('app');
  invariant(appElement, 'Element with id "app" required to rehydrate app');
  listenForHeapIdEvents(appElement);
  history.listen(() => {
    if (typeof window !== 'undefined') {
      window.s2RouteName = getRouteNameForPath(history.location.pathname);
    }
  });

  // Replay dispatches from server, to sync browser's stores with server's (see willRouteTo())
  await nextTask();
  measurePerfSync('HydrateStores', () => rehydrateStoresFromPrefetchedData(dispatcher));
  await nextTask(); // Allow rehydrate to run in its own task

  // With stores in sync and routes fetched, rehydrate the webapp
  measurePerfSync('HydrateReact', () => {
    // HACK: ReactRouter v4 takes a _slightly_ different history type, which we don't have access to
    const history = appContext.history as any;
    const appComps = (
      <RenderErrorLogger>
        <AppContextProvider value={appContext}>
          <Router history={history}>{renderRoutes(routes, { appContext })}</Router>
        </AppContextProvider>
      </RenderErrorLogger>
    );
    ReactDOM.hydrate(appComps, appElement);
  });

  // Instrument app with performance metrics
  await nextTask();
  const metricsPromises: Promise<any>[] = [];
  metricsPromises.push(trackPerformanceMetrics());
  await Promise.all(metricsPromises);
})().catch(error => {
  // layover catching of errors does not kick in yet so log explicitly
  getLayoverLogger().logError(error);
  throw error;
});

export function listenForUncaughtErrors() {
  window.addEventListener('error', event => {
    getLayoverLogger().logErrorEvent(event);
  });
}

// Load the modules from same path as this file (CDN)
export function setBundleFilePath() {
  const currentScript = window.document.currentScript;
  invariant(
    currentScript instanceof HTMLScriptElement,
    'document.currentScript must be populated and a script'
  );
  __webpack_public_path__ = currentScript.src.split('/').slice(0, -1).join('/') + '/';
}
