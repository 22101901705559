import classnames from 'classnames';
import React from 'react';

export default function CLRuleBase({ children, className, isFullWidth, label, ...otherProps }) {
  return (
    <div
      {...otherProps}
      className={classnames(
        {
          'cl-rule': true,
          'cl-rule--no-label': !label,
          'cl-rule--has-label': !!label,
          'cl-rule--full-width': isFullWidth,
          'cl-rule--partial-width': !isFullWidth,
        },
        className
      )}>
      {label && (
        <React.Fragment>
          <div className="cl-rule__before" />
          <div className="cl-rule__label">{label}</div>
          <div className="cl-rule__after" />
        </React.Fragment>
      )}
      {children}
    </div>
  );
}
