import { isBrowser } from './utils/env';
import { isFunction } from './utils/types';
import CookieBanner from './components/shared/common/CookieBanner';
import ErrorPage from './components/shared/error/ErrorPage';
import Feature from './weblab/Feature';
import FeatureEnabled from './components/util/features/FeatureEnabled';
import FeatureGate from './components/util/features/FeatureGate';
import GoogleOneTap from './components/shared/common/login/GoogleOneTap';
import IconSprite from './components/shared/icon/IconSprite';
import MessageManager from './components/shared/message/MessageManager';
import ModalManager from './components/desktop/common/modal/ModalManager';
import NotFoundPage from './components/shared/404/NotFoundPage';
import OverrideManager from './components/shared/common/OverrideManager';
import ScrollToTop from './utils/ScrollToTop';
import ShelfManager from './components/shared/shelf/ShelfManager';
import SignInPage from './components/shared/auth/SignInPage';
import softError from './utils/softError';
import WeblabStore from './weblab/WeblabStore';
import WhitePageDetector from './components/util/WhitePageDetector';
import withClientRouter from './router/withClientRouter';

import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import React from 'react';

// Exported as default wrapped in withClientRouter at the end of the file.
export class App extends React.PureComponent {
  static propTypes = {
    route: PropTypes.shape({
      routes: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    }).isRequired,
    apiError: PropTypes.object,
  };

  static contextTypes = {
    weblabStore: PropTypes.instanceOf(WeblabStore).isRequired,
  };

  renderError({ apiError }) {
    if ('toJS' in apiError && isFunction(apiError.toJS)) {
      // Some apiError objects are Immutable.Record's.; This makes them objects we can see in our logs.
      apiError = apiError.toJS();
    }
    const statusCode = apiError.status;
    switch (statusCode) {
      case 401:
        if (shouldLogSoftError(statusCode)) {
          softError(
            'app.renderSignInPage',
            `Sign In page was rendered because an API returned a 401: (${JSON.stringify(apiError)})`
          );
        }
        return <SignInPage />;
      case 404:
        if (shouldLogSoftError(statusCode)) {
          // 404's are reported in nginx on the server, so we only need browser-side 404's
          softError(
            'app.renderNotFoundPage',
            `Not Found page was rendered because an API returned a 404': (${JSON.stringify(
              apiError
            )})`
          );
        }
        return <NotFoundPage />;
      default:
        if (shouldLogSoftError(statusCode)) {
          softError(
            'app.renderErrorPage',
            `Error page was rendered because an API returned an unexpected error: (${JSON.stringify(
              apiError
            )})`
          );
        }
        return <ErrorPage statusCode={statusCode} />;
    }
  }

  render() {
    const { route, apiError } = this.props;
    return (
      <React.Fragment>
        <OverrideManager />
        <ModalManager />
        <MessageManager />
        <FeatureGate feature={Feature.ScrollToTop}>
          <ScrollToTop />
        </FeatureGate>
        {apiError ? this.renderError({ apiError }) : renderRoutes(route.routes)}
        <ShelfManager />
        <FeatureGate feature={Feature.AuthGoogleOneTap}>
          <FeatureEnabled>
            <GoogleOneTap />
          </FeatureEnabled>
        </FeatureGate>
        <CookieBanner />
        <IconSprite />
        <WhitePageDetector />
      </React.Fragment>
    );
  }
}

export default withClientRouter(App);

export function shouldLogSoftError(statusCode) {
  switch (statusCode) {
    case 404:
    case 500:
    case 503:
      // Nginx reports these errors from the server, so only log if in the browser
      return isBrowser();
  }
  return true;
}
