import { getString } from '@/content/i18n';
import {
  heapLibraryRecommendationDisable,
  heapLibraryRecommendationEnable,
} from '@/analytics/attributes/libraryPage';
import { LibraryFolderRecord } from '@/models/library/LibraryFolder';
import { maybeUpdateLibraryFolderAlert } from '@/utils/alert-util';
import { Nullable, ReactNodeish } from '@/utils/types';
import { Off, On, RecommendationStatus } from '@/models/library/RecommendationStatus';
import AlertsStore from '@/stores/AlertsStore';
import Api from '@/api/Api';
import Icon from '@/components/shared/icon/Icon';
import LibraryFolderStore from '@/stores/LibraryFolderStore';
import softError from '@/utils/softError';
import Toggle from '@/components/shared/common/form/Toggle';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

type Props = {
  libraryFolderId: number;
  onToggle?: Nullable<(RecommendationStatus) => void>;
  onSuccess?: Nullable<(RecommendationStatus) => void>;
  onFailure?: (recs?: RecommendationStatus) => void;
  className?: Nullable<string>;
  testId: string;
  seleniumSelector?: Nullable<string>;
};

type StateFromLibraryFolderStore = {
  folder: Nullable<LibraryFolderRecord>;
  recommendationStatus: Nullable<RecommendationStatus>;
};

type State = {} & StateFromLibraryFolderStore;

export default class FolderRecommendationsToggle extends React.PureComponent<Props, State> {
  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    alertsStore: PropTypes.instanceOf(AlertsStore).isRequired,
    libraryFolderStore: PropTypes.instanceOf(LibraryFolderStore).isRequired,
  };

  declare context: {
    api: Api;
    alertsStore: AlertsStore;
    libraryFolderStore: LibraryFolderStore;
  };

  constructor(...args: [any]) {
    super(...args);

    this.state = {
      ...this.getStateFromLibraryFolderStore(),
    };

    this.context.libraryFolderStore.registerComponent(this, () => {
      this.setState(this.getStateFromLibraryFolderStore());
    });
  }

  getStateFromLibraryFolderStore(): StateFromLibraryFolderStore {
    const { libraryFolderStore } = this.context;
    const { libraryFolderId } = this.props;

    const folder = libraryFolderStore.getFolderById(libraryFolderId);

    if (!folder) {
      return {
        folder: null,
        recommendationStatus: null,
      };
    }

    return {
      folder,
      recommendationStatus: folder.recommendationStatus,
    };
  }

  async updateRecommendationState({
    libraryFolderId,
    recommendationStatus,
  }: {
    libraryFolderId: number;
    recommendationStatus: RecommendationStatus;
  }): Promise<void> {
    const { api, alertsStore } = this.context;
    await api.updateLibraryFolder({
      id: libraryFolderId,
      recommendationStatus,
    });

    maybeUpdateLibraryFolderAlert(api, alertsStore, libraryFolderId, recommendationStatus);
  }

  onClickToggle = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    const { libraryFolderId, onToggle, onSuccess, onFailure } = this.props;
    const previousRecsStatus = this.state.recommendationStatus;

    // Toggle status, so On becomes Off
    const recommendationStatus = previousRecsStatus === On ? Off : On;

    // NOTE: This updates the UI before the API and the store can update, to make the UI feel snappy
    this.setState({ recommendationStatus });
    if (typeof onToggle === 'function') {
      onToggle(recommendationStatus);
    }

    this.updateRecommendationState({ libraryFolderId, recommendationStatus })
      .then(
        () => {
          if (typeof onSuccess === 'function') {
            onSuccess(recommendationStatus);
          }
        },
        error => {
          softError(
            'folder-recommendations-toggle',
            `Failed to toggle recommendation status [recommendationStatus=${recommendationStatus}, folderId=${libraryFolderId}]`,
            error
          );
          // undo optimistic toggle
          this.setState({
            recommendationStatus: previousRecsStatus,
          });

          if (typeof onFailure === 'function') {
            onFailure(previousRecsStatus || undefined);
          }
        }
      )
      .catch(error => {
        softError('folder-recommendations-toggle', `callback threw an error`, error);
      });
  };

  render(): ReactNodeish {
    const { recommendationStatus, folder } = this.state;
    const { className, testId } = this.props;
    if (!folder) {
      return null;
    }

    const hasRecommendationsEnabled = recommendationStatus === On;

    const heapProps = hasRecommendationsEnabled
      ? heapLibraryRecommendationEnable()
      : heapLibraryRecommendationDisable();

    return (
      <div className={classnames('folder-recommendations-toggle', className)}>
        <Toggle
          selected={hasRecommendationsEnabled}
          onClick={this.onClickToggle}
          testId={testId}
          heapProps={heapProps}
          id="folder-recs__toggle">
          <Icon
            icon="lightning-bolt"
            height="11"
            width="7"
            aria-label={getString(_ => _.library.recsLabel.reconmendationsToggleAriaLabel)}
            className={classnames({
              'folder-recommendations-toggle__has-recs-enabled': hasRecommendationsEnabled,
            })}
          />
        </Toggle>
      </div>
    );
  }
}
