import { LibrarySort } from '@/models/library/LibrarySort';
import { SortRecord, SortRecordFactory } from '@/models/Sort';

import Immutable from 'immutable';

export const RECENCY = SortRecordFactory({ id: 'pub-date', icon: 'flag' });
export const RELEVANCE = SortRecordFactory({ id: 'relevance' });
export const YEAR = SortRecordFactory({ id: 'year', icon: 'flag' });
export const INFLUENTIAL_CITATIONS = SortRecordFactory({ id: 'influence', icon: 'theorems' });
export const TOTAL_CITATIONS = SortRecordFactory({ id: 'total-citations', icon: 'cited-by' });
export const IS_INFLUENTIAL_CITATION = SortRecordFactory({
  id: 'is-influential',
  icon: 'theorems',
});
export const ALPHABETICAL = SortRecordFactory({ id: LibrarySort.ALPHABETICAL, icon: '' });
export const DATE_ADDED_TO_LIBRARY = SortRecordFactory({
  id: LibrarySort.DATE_ADDED_TO_LIBRARY,
  icon: '',
});
export const LIBRARY_RECENCY = SortRecordFactory({ id: LibrarySort.RECENCY, icon: '' });

/**
 * Enumeration of available sort options.
 *
 * @see PaperMeta.Sort for the server's understanding of available sort options.
 */
export default {
  RECENCY,
  RELEVANCE,
  YEAR,
  INFLUENTIAL_CITATIONS,
  TOTAL_CITATIONS,
  IS_INFLUENTIAL_CITATION,
  ALPHABETICAL,
  DATE_ADDED_TO_LIBRARY,
  LIBRARY_RECENCY,

  /**
   * Returns the sort options for the author paper list.
   * @returns {Immutable.List<SortRecord>}
   */
  authorPaperSearch(): Immutable.List<SortRecord> {
    return Immutable.List.of(INFLUENTIAL_CITATIONS, TOTAL_CITATIONS, RECENCY);
  },
  /**
   * Returns the sort options for the standard SERP.
   * @returns {Immutable.List<SortRecord>}
   */
  paperSearch: function (): Immutable.List<SortRecord> {
    return Immutable.List.of(RELEVANCE, TOTAL_CITATIONS, INFLUENTIAL_CITATIONS, RECENCY);
  },
  /**
   * Returns the sort options for the references / cited-by lists on the paper detail page.
   * @returns {Immutable.List<SortRecord>}
   */
  citations: function (): Immutable.List<SortRecord> {
    return Immutable.List.of(IS_INFLUENTIAL_CITATION, YEAR);
  },
  citationsWithRelevance: function (): Immutable.List<SortRecord> {
    return Immutable.List.of(RELEVANCE, IS_INFLUENTIAL_CITATION, YEAR);
  },
  citationsFiltered: function (): Immutable.List<SortRecord> {
    return Immutable.List.of(IS_INFLUENTIAL_CITATION, TOTAL_CITATIONS, RECENCY);
  },
  citationsFilteredWithRelevance: function (): Immutable.List<SortRecord> {
    return Immutable.List.of(RELEVANCE, IS_INFLUENTIAL_CITATION, TOTAL_CITATIONS, RECENCY);
  },
  library: function (): Immutable.List<SortRecord> {
    return Immutable.List.of(DATE_ADDED_TO_LIBRARY, ALPHABETICAL, LIBRARY_RECENCY);
  },

  /**
   * Returns the sort options for the Venue Page.
   * @returns {Immutable.List<SortRecord>}
   */
  venuePapersSearch: function (): Immutable.List<SortRecord> {
    return Immutable.List.of(INFLUENTIAL_CITATIONS, TOTAL_CITATIONS, RECENCY);
  },
};
