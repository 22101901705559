const authorLanding = 'author-landing';
const blankRecs = 'blank-recs';
const ftue = 'ftue';
const mobileNavMenu = 'mobile-nav-menu';
const sideBar = 'side-bar';
const ratedPapersTab = 'rated-papers-tab';
const researchFtue = 'research-ftue';

export const SEARCH_LOCATIONS = {
  authorLanding,
  blankRecs,
  ftue,
  mobileNavMenu,
  sideBar,
  ratedPapersTab,
  researchFtue,
} as const;
