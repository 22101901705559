import { runWithHeap } from './Heap';

import { getLayoverMetrics } from '@/utils/layover/LayoverMetrics';
import { HEAP_ATTRIBUTE_PREFIX } from '@/constants/HeapAttribute';
import logger from '@/logger';

import invariant from 'invariant';

import type { Nullable } from '@/utils/types';

export type HeapProps = {
  id: string;
} & any;

export type HeapAttributes = {
  'data-heap-id': string;
} & any;

let hasLoggedHeapError = false;

/**
 * Add attributes to element for Heap tracking
 *
 * Example: <div {...mkHeapAttributes({id: HEAP_PDP_FEATURED_CONTENT_ITEM, type: 'video'})} />
 * Output: <div data-heap-id="pdp_featured_content_item" data-heap-attr-type="video" />
 */
export const mkHeapAttributes = (props: HeapProps): HeapAttributes => {
  // Need to double check that IE can get keys from object
  let keys: Nullable<string[]> = null;
  try {
    keys = Object.keys(props);
  } catch (error) {
    if (!hasLoggedHeapError) {
      hasLoggedHeapError = true;
      logger.error(error);
    }
  }

  const attrs = {
    'data-heap-id': '',
  };
  if (keys) {
    for (const key of keys) {
      if (ATTRIBUTE_FORMAT.test(key)) {
        attrs[HEAP_ATTRIBUTE_PREFIX + key] = formatValue(props[key]);
      } else {
        logger.warn(`"${key}" is an invalid heap key`);
      }
    }
  }
  invariant(attrs['data-heap-id'], 'A heap attribute object did not include a heap id');
  return attrs;
};

// Buttons defined here: https://www.w3.org/TR/pointerevents/#the-button-property
export const RIGHT_CLICK_BUTTON = 2;

export const ATTRIBUTE_FORMAT = /^[a-z0-9-]{1,40}$/;
export const HEAP_ID_FORMAT = /^[\w-]{1,40}$/; // NOTE: hyphens should not be allowed, but are grandfathered in

// Convert a JS value into a dom property value
export function formatValue(value: any): string {
  switch (typeof value) {
    case 'string':
      return value;
    case 'undefined':
      return '';
    default:
      return JSON.stringify(value);
  }
}

// Create listeners to send counters to Datadog through Layover
export function listenForHeapIdEvents(rootElem: Node): void {
  listenForClickOnHeapId(rootElem);
  listenForRightClickOnHeapId(rootElem);
}

function getEventTarget(event: Event): EventTarget {
  // @ts-expect-error -- Not all browsers have "composedPath"
  return 'composedPath' in event ? event.composedPath()[0] : event.target;
}

function trackHeapIds(prefix: string, heapIds: string[]) {
  const layoverMetrics = getLayoverMetrics();
  for (const heapId of heapIds) {
    layoverMetrics.sendIncrement(`${prefix}.${heapId}`);
  }
  runWithHeap(heap => {
    for (const heapId of heapIds) {
      heap.track(`${prefix}_${heapId}`);
    }
  });
}

export function listenForClickOnHeapId(rootElem: Node): void {
  rootElem.addEventListener(
    'click',
    event => {
      try {
        const targetEl = getEventTarget(event);
        const heapIds = getHeapIdsFromNode(targetEl);
        trackHeapIds('click', heapIds);
      } catch (error) {
        logger.error('failed to process click event for counters', error);
      }
    },
    /* useCapture */ true
  );
}

export function listenForRightClickOnHeapId(rootElem: Node): void {
  rootElem.addEventListener(
    'pointerdown',
    event => {
      try {
        if ((event as PointerEvent).button !== RIGHT_CLICK_BUTTON) {
          return;
        }

        const targetEl = getEventTarget(event);
        const heapIds = getHeapIdsFromNode(targetEl);
        trackHeapIds('right_click', heapIds);
      } catch (error) {
        logger.error('failed to process click event for counters', error);
      }
    },
    /* useCapture */ true
  );
}

// Collect [data-heap-id] attributes up the DOM tree
export function getHeapIdsFromNode(target: Nullable<EventTarget>): string[] {
  const heapIds: string[] = [];

  let node = target;
  while (node instanceof window.HTMLElement) {
    const heapIdElem = node.closest('[data-heap-id]');
    if (!heapIdElem) {
      break;
    }
    const heapId = heapIdElem.getAttribute('data-heap-id');
    if (heapId && HEAP_ID_FORMAT.test(heapId)) {
      heapIds.push(heapId);
    } else {
      logger.warn(
        `element with [data-heap-id] was found, but the value was incorrect [heapId="${
          heapId || ''
        }"]`
      );
    }
    node = heapIdElem.parentNode;
  }

  return heapIds;
}
