import CLIconButton, { ICON_POSITION, TYPE } from '@/components/library/button/CLIconButton';
import Icon from '@/components/shared/icon/Icon';

import React from 'react';

export default class MultiselectChip extends React.PureComponent {
  render() {
    const { name, onClick } = this.props;
    return (
      <CLIconButton
        type={TYPE.PRIMARY}
        label={name}
        icon={<Icon icon="x" width="10" height="10" className="multiselect-chip__remove-icon" />}
        iconPosition={ICON_POSITION.RIGHT}
        onClick={onClick}
        className="multiselect-chip"
      />
    );
  }
}
