import AppContext from '@/AppContext';
import LoadingPage from '@/components/shared/loading/LoadingPage';
import S2Redirect from '@/models/redirects/S2Redirect';

import React from 'react';

import type { RouterState, WillRouteToResult } from '@/router/Route';

/**
 * Component which redirects the old Reader link to the new one
 */
export default class ReaderLegacyRedirect extends React.PureComponent {
  static willRouteTo(appContext: AppContext, routerState: RouterState): WillRouteToResult {
    const {
      query,
      params: { paperId },
    } = routerState;
    return new S2Redirect({
      query,
      routeName: 'READER',
      params: { paperId },
      replace: true,
    });
  }

  render(): React.ReactNode {
    return <LoadingPage isLoading />;
  }
}
