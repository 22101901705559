import React from 'react';

export default function IconPaperQuestionMark(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 92.9 120">
      <style type="text/css">
        {`
          .fold-paper-question-mark{fill - rule:evenodd;clip-rule:evenodd;fill:#CECDCD;}
          .page-paper-question-mark{fill:#E5E5E5;}
          .icon-paper-question-mark{fill:#FFFFFF;}
        `}
      </style>
      <path className="fold-paper-question-mark" d="M77.42,0L92.9,15.5H77.42V0z" />
      <polygon
        className="page-paper-question-mark"
        points="77.41,0.01 0,0.01 0,120 92.9,120 92.9,15.49 77.41,15.49 "
      />
      <path
        className="icon-paper-question-mark"
        d="M40.93,69.47c0-3.4,0.38-6.09,1.15-8.06c0.77-1.97,2.01-3.67,3.74-5.12
        c1.72-1.44,3.03-2.77,3.93-3.97c0.9-1.2,1.35-2.52,1.35-3.97c0-3.52-1.51-5.28-4.54-5.28c-1.4,0-2.53,0.51-3.39,1.54
        c-0.86,1.03-1.32,2.41-1.36,4.16H29.99c0.05-4.66,1.51-8.29,4.4-10.89c2.89-2.6,6.94-3.9,12.16-3.9c5.19,0,9.22,1.2,12.07,3.6
        c2.85,2.4,4.28,5.81,4.28,10.24c0,1.93-0.38,3.69-1.15,5.28c-0.77,1.58-2,3.2-3.7,4.86l-4.02,3.74c-1.14,1.1-1.93,2.22-2.38,3.37
        c-0.44,1.15-0.69,2.62-0.73,4.39H40.93z M39.49,80.1c0-1.72,0.62-3.14,1.87-4.25c1.25-1.11,2.8-1.66,4.67-1.66
        c1.86,0,3.42,0.55,4.67,1.66c1.25,1.11,1.87,2.52,1.87,4.25c0,1.72-0.62,3.14-1.87,4.25c-1.25,1.11-2.8,1.66-4.67,1.66
        c-1.86,0-3.42-0.55-4.67-1.66C40.12,83.24,39.49,81.82,39.49,80.1z"
      />
    </symbol>
  );
}
