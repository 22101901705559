import LayoverClient from './LayoverClient';
import LayoverDefaultClient from './LayoverDefaultClient';
import LayoverNoopClient from './LayoverNoopClient';
import LayoverStackTraceParser from './LayoverStackTraceParser';

import { getBrowserCookieJar } from '@/utils/cookies/BrowserCookieJar';
import { getRouteNameForPath, RouteName } from '@/router/Routes';
import { isBrowser, isUnitTest } from '@/utils/env';
import Experiment, { ExperimentNames } from '@/weblab/Experiment';
import Feature, { FeatureNames } from '@/weblab/Feature';
import logger from '@/logger';
import S2History, { getBrowserS2History } from '@/utils/S2History';

import { v4 as uuidV4 } from 'uuid';
import idx from 'idx';
import moment from 'moment';
import Url from 'url';

import type { Nullable } from '@/utils/types';
import type AppContext from '@/AppContext';

type EventData = object;
type EventDataGetter = EventData | (() => EventData);
type LoggableError = Error | string;

type LogEntry = {
  eventName: string; // Short description of the event that occurred
  eventData: EventData; // Metadata about the event
  eventNum: number; // Number used to order the events for a page load
  eventId: string; // ID which is generated for each event (helps with de-duping)
  browserId: Nullable<string>; // ID which comes from a long lived cookie
  sessionId: string; // ID from a short lived cookie, which is periodically refreshed
  appLoadId: string; // ID assigned when the webapp loads
  pageLoadId: string; // ID changed on every page transition
  pageUrl: any; // URL of the page when the event was logged
  routeName: Nullable<RouteName>; // Route.js name for path
  experiments: { [expKey: string]: Nullable<string> }; // Map of experiments exposed at the time of the event
  features: { [featureKey: string]: Nullable<boolean> }; // Map of features at the time of the event
  clientTimestamp: string; // Time when the event occurred on the client (ISO 8601, UTC)
  uiVersion: string; // Version of the code that the client has loaded
};

export const SESSION_TIMEOUT_MS = 1.8e6; // 30 minutes
export const SESSION_KEEP_ALIVE_TIMEOUT_MS = 6e4; //  1 minute
export const MAX_TOP_OF_STACK_CHARS = 1000; // Number of characters to log of the stack
export const MAX_TOP_OF_STACK_LINES = 15; // Number of lines log of the stack

// Events which should not be logged
export const EVENT_NAME_BLOCK_LIST = [
  /^\s*$/, // Empty strings and whitespace-only
  /^error.ResizeObserver\b/, // Error thrown by ResizeObservers that have no user impact
];

/**
 * Layover Logger
 *
 * This class collects and transports logs from the browser to datadog through the Layover service.
 * See: /online/layover/src/routes/log-entries/index.js
 *
 * WARNING: Datadog has a limitation of 4000 characters per event. Since the server adds an extra
 *          500 characters or so, event logs should limit themselves to a json encoded size of 3000.
 */
export default class LayoverLogger {
  _appLoadId: string; // UUID that is unique for every load of the app (every new tab, page refresh, etc.)
  _browserId: Nullable<string>; // Same as the tid cookie
  _client: LayoverClient;
  _history: Nullable<S2History>;
  _nextEventNum: number; // Incrementing number that allows events to be sorted on the server side without relying on timestamps
  _pageLoadId: string; // UUID that is unique for every page (different pageLoadId's can have the same appLoadId)
  _sessionId: string; // UUID shared between app loads
  _stackParser: LayoverStackTraceParser;
  _uiVersion: string; // Version of the code that the client has loaded
  _appContext?: Nullable<AppContext>;

  constructor({
    client,
    history,
    uiVersion,
  }: {
    client: LayoverClient;
    history: Nullable<S2History>;
    uiVersion: string;
  }) {
    this._client = client;
    this._history = history;
    this._stackParser = new LayoverStackTraceParser();
    this._uiVersion = uiVersion;
    this._appContext = null;
    this._nextEventNum = 1;

    if (isBrowser() && typeof window !== 'undefined') {
      const cookieJar = getBrowserCookieJar();
      this._browserId = cookieJar.getCookie('tid') || null;
      this._sessionId = cookieJar.getCookie('sid') || uuidV4();
      this._appLoadId = uuidV4();
      this._pageLoadId = uuidV4();

      this._keepSessionAlive();
      setInterval(() => this._keepSessionAlive(), SESSION_KEEP_ALIVE_TIMEOUT_MS);

      if (this._history) {
        this._history.listen(this._onUrlChange);
      } else {
        window.addEventListener('popstate', this._onUrlChange);
      }
    }
  }

  associateWithAppContext(appContext: AppContext): void {
    this._client.associateWithAppContext(appContext);
    this._appContext = appContext;
  }

  getBrowserId(): Nullable<string> {
    return this._browserId;
  }

  getSessionId(): string {
    return this._sessionId;
  }

  getAppLoadId(): string {
    return this._appLoadId;
  }

  getPageLoadId(): string {
    return this._pageLoadId;
  }

  _mkLogEntry(eventName: string, eventData: object): LogEntry {
    return {
      eventName,
      eventData,
      eventNum: this._nextEventNum++,
      eventId: uuidV4(),
      browserId: this.getBrowserId(),
      sessionId: this.getSessionId(),
      appLoadId: this.getAppLoadId(),
      pageLoadId: this.getPageLoadId(),
      pageUrl: this._getPageUrl(),
      routeName: this._getRouteName(),
      experiments: this.getActiveExperiments(),
      features: this.getActiveFeatures(),
      clientTimestamp: moment().toISOString(),
      uiVersion: this._uiVersion,
    };
  }

  // Eventually send a log entry to the server
  log(eventName: string, eventData: EventDataGetter): boolean {
    if (isEventNameBlocked(eventName)) {
      return false;
    }
    const entry = this._mkLogEntry(eventName, this._getLogEntryData(eventData));
    return this._client.send(entry);
  }

  // Send a log entry as fast as possible
  logVital(eventName: string, eventData: EventDataGetter): boolean {
    if (isEventNameBlocked(eventName)) {
      return false;
    }
    const entry = this._mkLogEntry(eventName, this._getLogEntryData(eventData));
    return this._client.sendNow(entry);
  }

  // Parse an error object (or similar) and send as vital log
  async logError(error?: LoggableError, extraProps?: object): Promise<boolean> {
    const errorData = await this.buildEventDataForError(error);
    const eventData: any = {
      ...errorData,
      ...(extraProps || {}),
    };
    return this.logVital(eventData.eventName as string, eventData as object);
  }

  // Same as #logError() but records under specified error name for event
  async logNamedError(
    errorName: string,
    error?: LoggableError,
    extraProps?: object
  ): Promise<boolean> {
    const errorData = await this.buildEventDataForError(error);
    const eventData = {
      ...errorData,
      ...(extraProps || {}),
      eventName: errorName,
    };
    return this.logVital(errorName, eventData);
  }

  async buildEventDataForError(error?: LoggableError): Promise<object> {
    const isTypeMissing = typeof error === 'string' && !/\w:\W/.test(error);
    const errorType = isTypeMissing ? 'UnknownError' : this._parseErrorType(error);
    const eventName = `error.${errorType}`;
    let message: Nullable<string> = null;
    let topOfStack: Nullable<string> = null;
    if (error instanceof Error) {
      message = error.message;
      const stack = await this._stackParser.parse(error);
      topOfStack = stack // Limit the size of the logs by removing some formatting
        .split('\n')
        .slice(0, MAX_TOP_OF_STACK_LINES) // Max lines
        .map(_ => _.replace(/^\s+at /, '')) // Remove '   at ' in the beginning of line
        .join('\n')
        .substr(0, MAX_TOP_OF_STACK_CHARS);
    } else if (typeof error === 'string') {
      message = error;
    } else {
      message = String.prototype.toString.call(error || ''); // Catch all so we can know what other cases to handle
    }
    return {
      eventName,
      errorType,
      message,
      topOfStack,
    };
  }

  // Handle browser's ErrorEvent (from window.onerror, websocket.onerror, or image.onerror)
  async logErrorEvent(event: ErrorEvent, extraProps?: object): Promise<boolean> {
    extraProps = {
      filename: event.filename,
      lineno: event.lineno,
      colno: event.colno,
      type: event.type,
      ...(extraProps || {}),
    };
    if (event.error instanceof Error) {
      return this.logError(event.error, extraProps);
    }
    const errorType = this._parseErrorType(event.message);
    const eventData = {
      eventName: `error.${errorType}`,
      errorType,
      message: event.message,
      ...extraProps,
    };
    return this.logVital(eventData.eventName, eventData);
  }

  // Determine the type of error passed to it (see unit test for examples)
  _parseErrorType(error?: LoggableError): string {
    let errorMsg = 'UnknownError';
    if (typeof error === 'string') {
      errorMsg = error;
    } else if (error instanceof Error) {
      errorMsg = (error.stack || '').split('\n')[0];
    }
    errorMsg = errorMsg.replace(/^Uncaught /, '');
    const type = errorMsg.split(':')[0];
    return type;
  }

  // Safely get the log data, or log an error to allow for debugging
  _getLogEntryData(getter: EventDataGetter): object {
    if (typeof getter === 'function') {
      try {
        return getter();
      } catch (error) {
        logger.error(error);
        return {
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          __type: (idx(error, _ => _.constructor.name) || '') + error,
          __message: error.message,
          __stack: error.stack,
        };
      }
    }
    return getter;
  }

  // Change to a new page, change the pageLoadId
  _onUrlChange = (): void => {
    this._pageLoadId = uuidV4();
  };

  // Reset the expire time on the sessionId cookie
  _keepSessionAlive(): void {
    const cookieJar = getBrowserCookieJar();
    cookieJar.saveCookie('sid', this._sessionId, {
      maxAge: Math.floor(SESSION_TIMEOUT_MS / 1000),
    });
  }

  // Collect the status of all experiments, even if they are not exposed
  getActiveExperiments(): { [expKey: string]: Nullable<string> } {
    const experiments = {};
    for (const expName of ExperimentNames) {
      const exp = Experiment[expName];
      const variation =
        this._appContext?.weblabStore.experimentManager &&
        this._appContext?.weblabStore.getVariation(exp.KEY);
      experiments[exp.KEY] = typeof variation === 'string' ? variation : null;
    }
    return experiments;
  }

  // Collect the status of all features, even if they are not in datafile
  getActiveFeatures(): { [featureKey: string]: Nullable<boolean> } {
    const features = {};
    for (const featureName of FeatureNames) {
      const feature = Feature[featureName];

      features[feature.KEY] = this._appContext?.weblabStore.isFeatureEnabled(feature) || null;
    }
    return features;
  }

  // Build an object based on the URL
  _getPageUrl(): Nullable<Url.UrlWithStringQuery> {
    if (this._history) {
      return Url.parse(this._history.getRelativeHref());
    }
    if (typeof window !== 'undefined') {
      return Url.parse(window.location.href);
    }
    return null;
  }

  // Determine the active route name, if possible
  _getRouteName(): Nullable<RouteName> {
    if (this._history) {
      return this._history.getLocationRouteName();
    }
    const pageUrl = this._getPageUrl();
    if (pageUrl) {
      return getRouteNameForPath(pageUrl.pathname);
    }
    return null;
  }
}

let inst: Nullable<LayoverLogger> = null;
export function getLayoverLogger(): LayoverLogger {
  if (!inst) {
    const uiVersion =
      global.document?.querySelector('meta[name="s2-ui-version"]')?.getAttribute('content') ||
      global.process?.env?.UI_VERSION ||
      'unknown';
    const args = {
      destinationUrl: '/beacon/logs',
    };
    const shouldSendLogs = isBrowser() && !isUnitTest();
    const client = shouldSendLogs ? new LayoverDefaultClient(args) : new LayoverNoopClient();
    const history = getBrowserS2History();
    inst = new LayoverLogger({ client, history, uiVersion });
  }
  return inst;
}

export function isEventNameBlocked(eventName: string): boolean {
  for (const regex of EVENT_NAME_BLOCK_LIST) {
    if (regex.test(eventName)) {
      return true;
    }
  }
  return false;
}
