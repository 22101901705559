/* Mapping of cookie keys used in the S2 webapp */
export const AUTHOR_CLAIM_TOKEN = 's2Claim';
export const COOKIE_BANNER_DISMISSED = 's2_copyright_dismissed'; // Cookie is incorrectly named for historical reasons
export const SERP_RESULT_DENSITY = 'compact_serp_results';
export const TID = 'tid';
export const WEBLAB_FORCED_FEATURES = 's2WeblabForcedFeatures';
export const WEBLAB_FORCED_VARIATIONS = 's2WeblabForcedVariations';
export const WEBLAB_OPT_OUT = 's2WeblabOptOut';

const cookieKeys = {
  AUTHOR_CLAIM_TOKEN,
  COOKIE_BANNER_DISMISSED: COOKIE_BANNER_DISMISSED,
  SERP_RESULT_DENSITY,
  TID,
  WEBLAB_FORCED_FEATURES,
  WEBLAB_FORCED_VARIATIONS,
  WEBLAB_OPT_OUT,
} as const;
Object.freeze(cookieKeys);
export default cookieKeys;
