export type StoreStateKey = keyof typeof byKey;
export type StoreStateValue = (typeof byKey)[StoreStateKey];

const byKey = {
  UNINITIALIZED: 'UNINITIALIZED',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
} as const;

// TODO: TS isn't quite sure what to make of this type
const byValue = Object.keys(byKey).reduce((memo, key) => {
  const value = byKey[key];
  memo[value] = key;
  return memo;
}, {});

export default Object.freeze(byKey);

export const StoreStateByValue = Object.freeze(byValue);
