import { mkHeapAttributes } from '@/analytics/heapUtils';

export const heapRecommendationPageSettingButton = () =>
  mkHeapAttributes({
    id: 'recommendation_page_setting_button',
  });

export const heapRecommendationSettingCloseButton = () =>
  mkHeapAttributes({
    id: 'recommendation_page_setting_close_button',
  });

export const heapRecommendationSettingManagePreferenceButton = () =>
  mkHeapAttributes({
    id: 'recommendation_page_setting_manage_preference_button',
  });

export const heapRecommendationSettingViewLibraryButton = () =>
  mkHeapAttributes({
    id: 'recommendation_page_setting_view_library_button',
  });

export const heapLibraryRecommendationIncludeFeed = () =>
  mkHeapAttributes({
    id: 'recommendation_page_setting_include_feed',
  });

export const heapLibraryRecommendationExcludeFeed = () =>
  mkHeapAttributes({
    id: 'recommendation_page_setting_exclude_feed',
  });

export const heapLibraryRecommendationResearchDashboardLink = () =>
  mkHeapAttributes({
    id: 'recommendation_page_research_dashboard_link',
  });

export const heapLibraryRecommendationResearchHomePageLink = () =>
  mkHeapAttributes({
    id: 'recommendation_page_research_homepage_link',
  });

export const heapLibraryRecommendationViewLibraryLink = () =>
  mkHeapAttributes({
    id: 'recommendation_page_library_link',
  });
