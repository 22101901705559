import { getString } from '@/content/i18n';
import { Nullable, ReactNodeish } from '@/utils/types';
import { useAppContext } from '@/AppContext';
import CLButton, { TYPE as BUTTON_TYPE } from '@/components/library/button/CLButton';
import Link from '@/router/Link';

import classnames from 'classnames';
import React from 'react';

type ManageAlertsLinkProps = {
  shouldOpenInNewTab?: Nullable<boolean>;
};

export default function ManageAlertsLink({
  shouldOpenInNewTab,
}: ManageAlertsLinkProps): ReactNodeish {
  const { envInfo, messageStore } = useAppContext();
  const isMobile = envInfo.isMobile;

  return (
    <Link to="ACCOUNT_ALERTS" shouldOpenInNewTab={shouldOpenInNewTab}>
      <CLButton
        type={BUTTON_TYPE.PRIMARY}
        className={classnames('alert-manage-link', { 'alert-manage-link__mobile': isMobile })}
        label={getString(_ => _.alerts.create.message.success.alertsButton)}
        onClick={() => {
          messageStore.clearMessages();
        }}
      />
    </Link>
  );
}
