import MockPaper from '../../../../../../test/utils/MockPaper';

import { addHeapProps } from '@/components/util/HeapTracking';
import { AnalyticsContextHelper } from '@/components/library/paper/CLPaperAnalytics';
import { getLinkTextForPaperRowActions, isPdfUrl } from '@/utils/paper-link-util';
import { HEAP_PAPER_LINK_TARGET } from '@/constants/HeapAttribute';
import { PaperLinkRecordFactory } from '@/models/PaperLink';
import ExternalLink from '@/components/shared/ExternalLink';
import Icon from '@/components/shared/icon/Icon';
import PaperLinkType from '@/constants/PaperLinkType';

import React from 'react';

export default class CLPaperActionViewPaper extends React.PureComponent {
  _onClickViewPaperAnalytics;

  setAnalyticsCallbacks = ({ onClickViewPaper }) => {
    this._onClickViewPaperAnalytics = onClickViewPaper;
  };

  clickHandler(args) {
    if (this._onClickViewPaperAnalytics) {
      this._onClickViewPaperAnalytics(args);
    }

    const { onClick } = this.props;
    if (typeof onClick === 'function') {
      onClick(args);
    }
  }

  onClickLink = () => {
    const link = this.props.paper.primaryPaperLink;
    if (!link) {
      return;
    }

    const { linkType, url } = link;
    this.clickHandler({ linkType, linkUrl: url });
  };

  onContextMenu = () => {
    const link = this.props.paper.primaryPaperLink;
    if (!link) {
      return;
    }

    const { linkType, url } = link;
    this.clickHandler({ linkType, linkUrl: url, isRightClick: true });
  };

  render() {
    const { paper, heapProps } = this.props;
    const link = paper.primaryPaperLink;

    if (!link) {
      return null;
    }
    const isPdfLink = isPdfUrl(link.url);

    return (
      <AnalyticsContextHelper onUpdate={this.setAnalyticsCallbacks}>
        <ExternalLink
          onClick={this.onClickLink}
          onContextMenu={this.onContextMenu}
          href={link.url}
          className="flex-row cl-paper-view-paper"
          data-test-id="paper-link"
          {...addHeapProps({
            id: HEAP_PAPER_LINK_TARGET,
            'link-type': link.linkType,
            'direct-pdf-link': isPdfLink,
            'unpaywall-link': false,
            'primary-link': true,
            'paper-id': paper.id,
            ...heapProps,
          })}>
          <Icon icon={iconByLinkType(link.linkType)} height="11" width="11" />
          {getLinkTextForPaperRowActions(link)}
        </ExternalLink>
      </AnalyticsContextHelper>
    );
  }
}

/** Helper function which returns the appropriate icon's properties for the provided link type.
 * @param {PaperLinkType} linkType the link type.
 * @returns {object} an object with the icon's properties.
 */
export function iconByLinkType(linkType) {
  switch (linkType) {
    case PaperLinkType.ARXIV:
    case PaperLinkType.S2:
    case PaperLinkType.EDUCAUSE:
      return 'fa-pdf';
    default:
      return 'fa-link-out';
  }
}

// TODO(#21359): Split this into a separate file
const PAPER_PDF_ICON = MockPaper({
  primaryPaperLink: PaperLinkRecordFactory({
    url: 'https://www.semanticscholar.org',
    linkType: PaperLinkType.S2,
    publisherName: 'Semantic Scholar',
  }),
});

const PAPER_ARROW_ICON = MockPaper({
  primaryPaperLink: PaperLinkRecordFactory({
    url: 'https://www.semanticscholar.org',
    linkType: PaperLinkType.MEDLINE,
    publisherName: 'Semantic Scholar',
  }),
});

export const exampleConfig = {
  getComponent: async () => CLPaperActionViewPaper,
  fields: [
    {
      name: 'className',
      desc: 'HTML classes to be added to the component',
      value: {
        type: 'text',
        default: '',
      },
    },
  ],

  examples: [
    {
      title: 'View Paper Button - pdf',
      desc: 'View paper button with pdf icon',
      props: {
        paper: PAPER_PDF_ICON,
      },
      render: comp => <div style={{ width: '300px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'View Paper Button - arrow',
      desc: 'View paper button with arrow out icon',
      props: {
        paper: PAPER_ARROW_ICON,
      },
      render: comp => <div style={{ width: '300px', padding: '16px' }}>{comp}</div>,
    },
  ],

  events: {},
};
