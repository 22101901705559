import LoadingPage from '@/components/shared/loading/LoadingPage';
import Redirect from '@/models/redirects/Redirect';

import React from 'react';

export default class SignOutRedirect extends React.Component {
  static willRouteTo() {
    // Redirect to the API to logout, which redirects to the homepage
    return new Redirect({
      url: '/api/1/auth/logout?next=/',
      status: 302,
    });
  }

  render() {
    return <LoadingPage isLoading />;
  }
}
