import invariant from 'invariant';

import type { DEPRECATED__FlowOptional, Nullable } from '@/utils/types';

export type ExperimentStatus = (typeof byKey)[keyof typeof byKey];

export const PENDING = 'PENDING';
export const RUNNING = 'RUNNING';
export const FINISHED = 'FINISHED';
export const NOT_IN_DATABASE = 'NOT IN DATABASE';

const byKey = {
  PENDING,
  RUNNING,
  FINISHED,
  NOT_IN_DATABASE,
} as const;

export const ALL_STATUSES = Object.freeze(byKey);

export function optExperimentStatus(
  optStatus: DEPRECATED__FlowOptional<string>
): Nullable<ExperimentStatus> {
  if (optStatus && optStatus in byKey) {
    return byKey[optStatus];
  }
  return null;
}

export function getExperimentStatus(optStatus: DEPRECATED__FlowOptional<string>): ExperimentStatus {
  const status = optExperimentStatus(optStatus);
  invariant(status, `"${optStatus || ''}" is not a valid ExperimentStatus`);
  return status;
}
