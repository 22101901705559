import { ApiRequestFailurePayload, ApiRequestStartingPayload } from '@/api/BaseApi';
import { ApiResponse, PaperPdfDataResponseBody, PaperResponseBody } from '@/api/ApiResponse';
import { CitationFeatureFromJS } from '@/models/paper-pdf/PaperPdfCitations';
import { getPaperFromJS, PaperRecord } from '@/models/Paper';
import {
  getPaperPdfBoundingBoxFromJS,
  isBoundingBoxComplete,
  PaperPdfBoundingBoxRecord,
} from '@/models/paper-pdf/PaperPdfBoundingBox';
import {
  getPaperPdfCitationMentionFromJS,
  PaperPdfCitationMentionFromJS,
  PaperPdfCitationMentionRecord,
} from '@/models/paper-pdf/PaperPdfCitationMention';
import { Nullable } from '@/utils/types';
import BaseStore from '@/stores/BaseStore';
import Constants from '@/constants';
import S2Dispatcher from '@/utils/S2Dispatcher';
import StoreState, { StoreStateValue } from '@/constants/StoreState';

import idx from 'idx';
import Immutable from 'immutable';

export type PageIndexToCited = Immutable.Map<number, Immutable.List<PaperPdfCitationMentionRecord>>;

type FlattenedCitations = {
  pageIndex: number;
  citedPaperId: Nullable<string>;
  referenceText: Nullable<string>;
  s2airsId: Nullable<string>;
  boundingBox: PaperPdfBoundingBoxRecord;
  citedCorpusId: Nullable<number>;
  text: Nullable<string>;
};

export default class ReaderCitationStore extends BaseStore {
  pageIndexToCitedMapping: PageIndexToCited;
  citedPaperIdToLoadingState: Immutable.Map<string, StoreStateValue>;
  citedPaperIdToPaperRecord: Immutable.Map<string, PaperRecord>;
  constructor(dispatcher: S2Dispatcher) {
    super();
    this.pageIndexToCitedMapping = Immutable.Map();
    this.citedPaperIdToLoadingState = Immutable.Map();
    this.citedPaperIdToPaperRecord = Immutable.Map();

    dispatcher.register(payload => {
      switch (payload.actionType) {
        case Constants.actions.API_REQUEST_STARTING: {
          const apiStartingPayload = payload as ApiRequestStartingPayload;
          switch (apiStartingPayload.requestType) {
            case Constants.requestTypes.PDF_DATA: {
              this.pageIndexToCitedMapping = Immutable.Map();
              this.citedPaperIdToLoadingState = Immutable.Map();
              this.citedPaperIdToPaperRecord = Immutable.Map();
              this.emitChange();
              break;
            }
            case Constants.requestTypes.READER_PAPER_DETAILS: {
              const { requestData } = apiStartingPayload;
              const { paperId } = requestData;
              if (paperId) {
                if (!this.citedPaperIdToLoadingState.has(paperId)) {
                  this.citedPaperIdToLoadingState = this.citedPaperIdToLoadingState.set(
                    paperId,
                    StoreState.LOADING
                  );
                }
              }
              this.emitChange();
              break;
            }
          }
          break;
        }

        case Constants.actions.API_REQUEST_COMPLETE: {
          const apiResponse = payload as ApiResponse;
          switch (apiResponse.requestType) {
            case Constants.requestTypes.PDF_DATA: {
              const pdfDataApiResponse = apiResponse as ApiResponse<PaperPdfDataResponseBody>;
              const rawCitations = pdfDataApiResponse?.resultData.citations?.feature || null;
              if (rawCitations) {
                this.pageIndexToCitedMapping = mapPageIndexToCitation(rawCitations);
                this.emitChange();
              }
              break;
            }

            case Constants.requestTypes.READER_PAPER_DETAILS: {
              const paperDataApiResponse = apiResponse as ApiResponse<PaperResponseBody>;
              const paper = idx(paperDataApiResponse, _ => _.resultData.paper);
              const paperId = idx(paperDataApiResponse, _ => _.resultData.id);
              if (paper && paperId) {
                const paperModel = getPaperFromJS(paper);
                this.citedPaperIdToPaperRecord = this.citedPaperIdToPaperRecord.set(
                  paperId,
                  paperModel
                );

                this.citedPaperIdToLoadingState = this.citedPaperIdToLoadingState.set(
                  paperId,
                  StoreState.LOADED
                );
                this.emitChange();
              }
              break;
            }
          }
          break;
        }

        case Constants.actions.API_REQUEST_FAILED: {
          const apiFailurePayload = payload as ApiRequestFailurePayload;
          switch (apiFailurePayload.requestType) {
            case Constants.requestTypes.READER_PAPER_DETAILS: {
              const failedPapers = this.citedPaperIdToLoadingState.filter(
                state => state === StoreState.LOADING
              );
              for (const paperId of failedPapers.keys()) {
                this.citedPaperIdToLoadingState = this.citedPaperIdToLoadingState.set(
                  paperId,
                  StoreState.ERROR
                );
              }
              this.emitChange();
            }
          }
          break;
        }
      }
    });
  }

  isPaperNotInitialized(citedPaperId: string): boolean {
    return !this.citedPaperIdToLoadingState.has(citedPaperId);
  }

  isPaperFailedLoading(citedPaperId: string): boolean {
    return this.citedPaperIdToLoadingState.get(citedPaperId) === StoreState.ERROR;
  }

  isPaperStored(citedPaperId: string): boolean {
    return this.citedPaperIdToLoadingState.get(citedPaperId) === StoreState.LOADED;
  }

  getPageIndexToCitationMapping(): PageIndexToCited {
    return this.pageIndexToCitedMapping;
  }

  getPaperData(citedPaperId: string): Nullable<PaperRecord> {
    if (!this.citedPaperIdToPaperRecord) {
      return null;
    }
    return this.citedPaperIdToPaperRecord.get(citedPaperId) || null;
  }
}

// function takes in a citations data and map each citation respectively based on the page index
function mapPageIndexToCitation(rawCitations: CitationFeatureFromJS[]): PageIndexToCited {
  // Collect citations into a flattened record
  const flattenedCitationsArray: FlattenedCitations[] = [];
  for (const citation of rawCitations) {
    if (!citation.mentions) {
      continue;
    }
    for (const mention of citation.mentions) {
      if (!mention.boundingBoxes) {
        continue;
      }
      for (const boundingBox of mention.boundingBoxes) {
        if (!isBoundingBoxComplete(boundingBox)) {
          continue;
        }
        const boxRecord = getPaperPdfBoundingBoxFromJS(boundingBox);
        const box: FlattenedCitations = {
          pageIndex: boxRecord.page,
          citedPaperId: citation.citedPaperId,
          referenceText: citation.referenceText,
          boundingBox: boxRecord,
          s2airsId: mention.s2airsId,
          citedCorpusId: citation.citedCorpusId,
          text: mention.text,
        };
        flattenedCitationsArray.push(box);
      }
    }
  }
  const flattenedCitations = Immutable.List(flattenedCitationsArray);

  // Break flattened citations in a map indexed by page index
  return flattenedCitations.reduce((pageIndexToCitation, flattenedCitations) => {
    if (!pageIndexToCitation.has(flattenedCitations.pageIndex)) {
      pageIndexToCitation = pageIndexToCitation.set(flattenedCitations.pageIndex, Immutable.List());
    }

    const citation: PaperPdfCitationMentionFromJS = {
      id: flattenedCitations.citedPaperId,
      referenceText: flattenedCitations.referenceText,
      boundingBox: flattenedCitations.boundingBox,
      s2airsId: flattenedCitations.s2airsId,
      corpusId: flattenedCitations.citedCorpusId,
      text: flattenedCitations.text,
    };

    const citationRecord = getPaperPdfCitationMentionFromJS(citation);

    let citationBasedOnPageIndex = pageIndexToCitation.get(flattenedCitations.pageIndex);

    if (citationBasedOnPageIndex) {
      citationBasedOnPageIndex = citationBasedOnPageIndex.push(citationRecord);

      pageIndexToCitation = pageIndexToCitation.set(
        flattenedCitations.pageIndex,
        citationBasedOnPageIndex
      );
    }

    return pageIndexToCitation;
  }, Immutable.Map());
}
