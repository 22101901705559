import React from 'react';

export default function IconEntitlement(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 100 100">
      <path d="M100 50C100 77.625 77.625 100 50 100C41.25 100 33 97.75 25.8333 93.7917L32.0417 87.625C37.4583 90.2083 43.5833 91.6667 50 91.6667C73 91.6667 91.6667 73 91.6667 50C91.6667 43.5833 90.2083 37.4583 87.625 32.0417L93.7917 25.8333C97.75 33 100 41.25 100 50ZM50 0C58.75 0 67 2.25 74.1667 6.20833L67.9583 12.375C62.5417 9.79167 56.4167 8.33333 50 8.33333C27 8.33333 8.33333 27 8.33333 50C8.33333 56.4167 9.79167 62.5417 12.375 67.9583L6.20833 74.1667C2.25 67 0 58.75 0 50C0 22.375 22.375 0 50 0Z" />
      <path d="M50.0001 72.2084C62.2654 72.2084 72.2084 62.2654 72.2084 50C72.2084 37.7347 62.2654 27.7917 50.0001 27.7917C37.7348 27.7917 27.7917 37.7347 27.7917 50C27.7917 62.2654 37.7348 72.2084 50.0001 72.2084Z" />
    </symbol>
  );
}
