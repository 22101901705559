import React from 'react';

export default function IconHorizontalResizeHandle(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 3 13">
      <path
        d="M1.5,3C2.33,3,3,2.33,3,1.5C3,0.67,2.33,0,1.5,0S0,0.67,0,1.5C0,2.33,0.67,3,1.5,3z
        M1.5,5C0.67,5,0,5.67,0,6.5 C0,7.33,0.67,8,1.5,8S3,7.33,3,6.5C3,5.67,2.33,5,1.5,5z
        M1.5,10C0.67,10,0,10.67,0,11.5C0,12.33,0.67,13,1.5,13S3,12.33,3,11.5
        C3,10.67,2.33,10,1.5,10z"
      />
    </symbol>
  );
}
