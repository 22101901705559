import CLLayoutBase from './CLLayoutBase';

import { isObject, ReactNodeish } from '@/utils/types';

import classnames from 'classnames';
import React, { ComponentProps, ReactElement } from 'react';

const LEFT = 'left';
const RIGHT = 'right';

export const ASIDE_POSITION = {
  LEFT,
  RIGHT,
};

type AllowedCLComponent =
  | ReactElement<ComponentProps<typeof CLUpperAside>>
  | ReactElement<ComponentProps<typeof CLLowerAside>>
  | ReactElement<ComponentProps<typeof CLMain>>
  | boolean
  | null
  | undefined;

type CLTwoColumnLayoutProps = {
  children: Array<AllowedCLComponent> | AllowedCLComponent;
  asidePosition?: (typeof ASIDE_POSITION)[keyof typeof ASIDE_POSITION];
} & ComponentProps<typeof CLLayoutBase>;

export default function CLTwoColumnLayout({
  asidePosition,
  children,
  className,
  ...otherProps
}: CLTwoColumnLayoutProps) {
  // The filter makes this casting fairly safe. Still doing some null checking within the .finds to make sure we're covered though!
  const kids = React.Children.toArray(children).filter(
    child => isObject<AllowedCLComponent>(child) && child.hasOwnProperty('type')
  ) as Array<ReactElement>;
  const upper = kids.find(_ => _?.type === CLUpperAside);
  const lower = kids.find(_ => _?.type === CLLowerAside);
  const main = kids.find(_ => _?.type === CLMain);

  return (
    <CLLayoutBase
      {...otherProps}
      className={classnames(
        {
          'cl-layout--2-col': true,
          'cl-layout--2-col-aside-left': asidePosition === LEFT,
          'cl-layout--2-col-aside-right': asidePosition === RIGHT,
          'cl-layout--no-lower': !lower,
          'cl-layout--has-lower': !!lower,
        },
        className
      )}>
      {upper}
      {main}
      {lower}
    </CLLayoutBase>
  );
}

CLTwoColumnLayout.defaultProps = {
  asidePosition: LEFT,
  useDefaultBreakpoints: true,
};

export const CLUpperAside = ({
  children,
  className,
  ...props
}: ComponentProps<'aside'>): ReactNodeish => {
  return (
    <aside className={classnames(className, 'cl-layout__aside-upper')} {...props}>
      {children}
    </aside>
  );
};

export const CLLowerAside = ({
  children,
  className,
  ...props
}: ComponentProps<'aside'>): ReactNodeish => {
  return (
    <aside className={classnames(className, 'cl-layout__aside-lower')} {...props}>
      {children}
    </aside>
  );
};

export const CLMain = ({ children, className, ...props }: ComponentProps<'main'>): ReactNodeish => {
  return (
    <main className={classnames(className, 'cl-layout__main')} {...props}>
      {children}
    </main>
  );
};
