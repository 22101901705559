import AlertLink from './AlertLink';

import { AlertRecord } from '@/models/Alert';
import { getString } from '@/content/i18n';
import { ModalId } from '@/constants/Modal';
import { Off, On } from '@/models/library/RecommendationStatus';
import { QUERY_TYPE, STATUS } from '@/constants/Alert';
import { ReactNodeish } from '@/utils/types';
import { showModal } from '@/actions/ModalActionCreators';
import Api from '@/api/Api';
import CLIconButton, { ICON_POSITION, TYPE } from '@/components/library/button/CLIconButton';
import EventTarget from '@/analytics/constants/EventTarget';
import Icon from '@/components/shared/icon/Icon';
import LibraryFolderStore from '@/stores/LibraryFolderStore';
import S2Dispatcher from '@/utils/S2Dispatcher';
import softError from '@/utils/softError';
import SubmitEvent from '@/analytics/models/SubmitEvent';
import Toggle from '@/components/shared/common/form/Toggle';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

type Props = {
  alert: AlertRecord;
  overrideLabel?: string;
  allowRemoval?: boolean;
  allowIcon?: boolean;
};

export default class Alert extends React.PureComponent<Props> {
  static defaultProps = {
    overrideLabel: null,
    allowRemoval: true,
    allowIcon: false,
  };

  static contextTypes = {
    api: PropTypes.instanceOf(Api).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    libraryFolderStore: PropTypes.instanceOf(LibraryFolderStore).isRequired,
  };

  declare context: {
    api: Api;
    dispatcher: S2Dispatcher;
    libraryFolderStore: LibraryFolderStore;
  };

  onToggle = (): void => {
    const { id: alertId, isActive, queryValue, queryType } = this.props.alert;
    const alertStatus = isActive ? STATUS.DISABLED : STATUS.ACTIVE;
    const { TOGGLE_SUCCESS, TOGGLE_FAILURE } = EventTarget.UserAlerts;

    if (queryType === QUERY_TYPE.LIBRARY_FOLDER) {
      this.updateLibraryFolderStatus(alertStatus, queryValue);
    }

    this.context.api
      .updateAlert({ alertId, queryValue, status: alertStatus })
      .then(() => trackAnalyticsEvent(SubmitEvent.create(TOGGLE_SUCCESS, { alertId, alertStatus })))
      .catch(({ status, error }) => {
        trackAnalyticsEvent(
          SubmitEvent.create(TOGGLE_FAILURE, { alertId, alertStatus, error, status })
        );
      });
  };

  async updateLibraryFolderStatus(alertStatus: string, queryValue: string): Promise<void> {
    const { api, libraryFolderStore } = this.context;
    const libraryFolderId = parseInt(queryValue, 10); // base 10
    const folder = libraryFolderStore.getFolderById(libraryFolderId);

    if (!folder) {
      softError(
        'updateLibraryFolderStatus',
        `library folder could not be found with libraryFolderId=${libraryFolderId}`
      );
      return;
    }

    if (alertStatus === STATUS.ACTIVE && folder.recommendationStatus === Off) {
      await api.updateLibraryFolder({
        id: libraryFolderId,
        recommendationStatus: On,
      });
    }
  }

  onDelete = (): void => {
    this.context.dispatcher.dispatch(
      showModal({ id: ModalId.DELETE_ALERT, data: this.props.alert })
    );
  };

  render(): ReactNodeish {
    const { isActive, displayValue, queryType, queryValue } = this.props.alert;
    const { overrideLabel, allowIcon, allowRemoval } = this.props;
    const className = classnames('alert-subscription', 'flex-row-vcenter', {
      'is-disabled': !isActive,
    });

    return (
      <li className={className}>
        <Toggle
          className="account-alerts__subscriptions__toggle"
          selected={isActive}
          onClick={this.onToggle}
          id={`alerts-subscription__${queryValue}`}>
          {allowIcon && (
            <Icon
              icon="fa-bell"
              height="11"
              width="9"
              className={classnames({
                'alerts-subscription_icon': !isActive,
                'alerts__subscriptions__icon__has-recs-enabled': isActive,
              })}
            />
          )}
          {overrideLabel ? (
            <span className="alerts-subscription-label">{overrideLabel}</span>
          ) : (
            <AlertLink queryType={queryType} queryValue={queryValue} displayValue={displayValue} />
          )}
        </Toggle>
        {allowRemoval && (
          <CLIconButton
            type={TYPE.TERTIARY}
            className="alert__delete-button flex-static mod-clickable"
            icon={<Icon icon="x" width="15" height="15" />}
            iconPosition={ICON_POSITION.RIGHT}
            onClick={this.onDelete}
            label={getString(_ => _.alerts.delete.removeLabel)}
          />
        )}
      </li>
    );
  }
}
