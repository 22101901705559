import { ApiResponse } from './ApiResponse';
import BaseApi from './BaseApi';

import { Nullable } from '@/utils/types';
import { RequestTypeValue } from '@/constants';
import Feature from '@/weblab/Feature';
import logger from '@/logger';
import S2Dispatcher from '@/utils/S2Dispatcher';
import WeblabStore from '@/weblab/WeblabStore';

import { print } from 'graphql';

import type { DocumentNode } from 'graphql/language/ast';

export type GraphQLResponse<TResultData> = {
  extensions?: {
    metrics?: unknown; // Comes from profiling, by adding ?debug=true to URL, or adding { "__profile": true } to variables
  };
  errors?: any;
  data: Nullable<TResultData>;
};
export default class GraphQLApi extends BaseApi {
  #weblabStore: WeblabStore;

  constructor(dispatcher: S2Dispatcher, weblabStore: WeblabStore) {
    super(dispatcher);
    this.#weblabStore = weblabStore;
  }

  async fetchQuery<TResultData>({
    requestType,
    query: queryNode,
    variables = {},
    operationName, // Typically the name of the query being run
    context = {},
  }: {
    requestType: RequestTypeValue;
    query: DocumentNode;
    variables?: object;
    operationName: string;
    context?: object;
  }): Promise<ApiResponse<GraphQLResponse<TResultData>>> {
    const isGraphQLProxyEnabled = this.#weblabStore.isInitialized()
      ? this.#weblabStore.isFeatureEnabled(Feature.GraphQLProxy)
      : Feature.GraphQLProxy.fallbackState;

    const path = isGraphQLProxyEnabled ? '/graphql-proxy' : '/graphql';
    const query = print(queryNode);
    const request = this.apiRequest<GraphQLResponse<TResultData>>({
      requestType,
      method: 'POST',
      path,
      data: {
        query,
        variables,
        operationName,
      },
      context: {
        ...context,
        variables,
      },
    });
    const result = await request.promise;
    if (result.resultData?.extensions?.metrics) {
      logger.debug(
        `GraphQL: ${operationName} profiling results`,
        result.resultData?.extensions?.metrics
      );
    }
    return result;
  }
}
