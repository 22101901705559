import CLPaperAbstract from './CLPaperAbstract';
import CLPaperControls from './CLPaperControls';
import CLPaperMeta from './CLPaperMeta';
import CLPaperTitle from './CLPaperTitle';
import MockPaper, { MockPaperLiteFromJs } from '../../../../../test/utils/MockPaper';

import { addHeapProps } from '@/components/util/HeapTracking';
import { AuthorAliasFromJS, getAuthorAliasFromJS } from '@/models/author/AuthorAlias';
import { ExampleConfig } from '@/components/library/ExampleUtils';
import { getHighlightedFieldFromJS } from '@/models/HighlightedField';
import { HighlightedAuthorRecordFactory } from '@/models/HighlightedAuthor';
import { Nullable, ReactNodeish } from '@/utils/types';
import { PaperishRecord } from '@/models/Paper';
import { PaperLinkRecordFactory } from '@/models/PaperLink';
import PaperLinkType from '@/constants/PaperLinkType';

import classnames from 'classnames';
import Immutable from 'immutable';
import React from 'react';

type Props = {
  className?: Nullable<string>;
  heapProps?: Nullable<object>;
  paper: PaperishRecord;
  title: Nullable<boolean> | ReactNodeish;
  meta?: Nullable<boolean> | ReactNodeish;
  abstract?: Nullable<boolean> | ReactNodeish;
  controls?: Nullable<boolean> | ReactNodeish;
  topMeta?: ReactNodeish;
  isInteractive?: Nullable<boolean>;
};

export default class CLPaperRow extends React.PureComponent<Props> {
  static defaultProps = {
    title: true,
    meta: true,
    abstract: true,
    controls: true,
    isInteractive: true,
  };

  render(): ReactNodeish {
    const { title, meta, abstract, controls, topMeta, className, heapProps, paper, isInteractive } =
      this.props;
    return (
      <div
        className={classnames('cl-paper-row', className)}
        {...(heapProps ? addHeapProps(heapProps) : {})}
        data-paper-id={paper.id}>
        {topMeta && topMeta}
        {renderTitle({ title, paper, isInteractive })}
        {renderMeta({ meta, paper, isInteractive })}
        {renderAbstract({ abstract, paper, isInteractive })}
        {renderControls({ controls, paper })}
      </div>
    );
  }
}

function renderTitle({
  title,
  paper,
  isInteractive,
}: {
  title: Nullable<boolean> | ReactNodeish;
  paper: PaperishRecord;
  isInteractive?: Nullable<boolean>;
}): ReactNodeish {
  if (!title) {
    return null;
  }

  return typeof title === 'boolean' ? (
    <CLPaperTitle paper={paper} isInteractive={isInteractive} />
  ) : (
    title
  );
}

function renderMeta({
  meta,
  paper,
  isInteractive,
}: {
  meta?: Nullable<boolean> | ReactNodeish;
  paper: PaperishRecord;
  isInteractive?: Nullable<boolean>;
}): ReactNodeish {
  if (!meta) {
    return null;
  }

  return typeof meta === 'boolean' ? (
    <CLPaperMeta paper={paper} isInteractive={isInteractive} />
  ) : (
    meta
  );
}

function renderAbstract({
  abstract,
  paper,
  isInteractive,
}: {
  abstract?: Nullable<boolean> | ReactNodeish;
  paper: PaperishRecord;
  isInteractive?: Nullable<boolean>;
}): ReactNodeish {
  if (!abstract) {
    return null;
  }

  return typeof abstract === 'boolean' ? (
    <CLPaperAbstract paper={paper} isInteractive={isInteractive} />
  ) : (
    abstract
  );
}

function renderControls({
  controls,
  paper,
}: {
  controls?: Nullable<boolean> | ReactNodeish;
  paper: PaperishRecord;
}): ReactNodeish {
  if (!controls || !paper.id) {
    return null;
  }

  return typeof controls === 'boolean' ? <CLPaperControls paper={paper} /> : controls;
}

// TODO(#21359): Split this into a separate file
const rawAuthor: AuthorAliasFromJS = {
  name: 'John Doe',
  slug: 'John-Doe',
  ids: ['1337'],
  optIsClaimed: false,
};

const author1 = HighlightedAuthorRecordFactory({
  alias: getAuthorAliasFromJS(rawAuthor),
  highlightedField: getHighlightedFieldFromJS({
    text: 'John Doe',
    fragments: [],
  }),
});

const PAPER = MockPaper({
  id: '94e2260617274d17ef9ebf84b5081d5fc919cbab',
  authors: Immutable.List([author1, author1, author1, author1]),
  primaryPaperLink: PaperLinkRecordFactory({
    url: 'https://www.semanticscholar.org/paper/94e2260617274d17ef9ebf84b5081d5fc919cbab',
    linkType: PaperLinkType.S2,
    publisherName: 'Semantic Scholar',
  }),
  pubDate: getHighlightedFieldFromJS({ text: '5 May 2001' }),
});

const PAPER_LITE = MockPaperLiteFromJs({
  id: '94e2260617274d17ef9ebf84b5081d5fc919cbab',
  authors: [rawAuthor, rawAuthor, rawAuthor, rawAuthor],
  pubDate: '2001-05-05',
  slug: 'super-important-paper',
});

export const exampleConfig: ExampleConfig = {
  getComponent: () => CLPaperRow,
  fields: [
    {
      name: 'title',
      desc: 'Displayed default title or not',
      value: {
        type: 'boolean',
        default: true,
      },
    },
    {
      name: 'meta',
      desc: 'Displayed default paper meta or not',
      value: {
        type: 'boolean',
        default: true,
      },
    },
    {
      name: 'abstract',
      desc: 'Displayed default abstract or not',
      value: {
        type: 'boolean',
        default: true,
      },
    },
    {
      name: 'controls',
      desc: 'Displayed default controls or not',
      value: {
        type: 'boolean',
        default: true,
      },
    },
    {
      name: 'isInteractive',
      desc: 'Adds links and click handlers when enabled',
      value: {
        type: 'boolean',
        default: true,
      },
    },
  ],

  examples: [
    {
      title: 'PaperActions with Paper',
      desc: '',
      props: {
        paper: PAPER,
        isInteractive: true,
      },
      render: comp => <div style={{ width: '800px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'PaperActions with PaperLite',
      desc: '',
      props: {
        paper: PAPER_LITE,
        isInteractive: true,
      },
      render: comp => <div style={{ width: '800px', padding: '16px' }}>{comp}</div>,
    },
  ],

  events: {},
};
