import React from 'react';

export default function IconCitedBy(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 36 36">
      <path
        d="M12.4,18c0.9,0,1.7,0.3,2.4,1s1,1.5,1,2.4v9c0,0.9-0.3,1.7-1,2.4s-1.5,1-2.4,1h-9c-0.9,0-1.7-0.3-2.4-1s-1-1.5-1-2.4V13.5
        c0-2,0.5-3.9,1.5-5.6s2.4-3.1,4.1-4.1s3.6-1.5,5.6-1.5h0.6c0.5,0,0.9,0.2,1.2,0.5s0.5,0.7,0.5,1.2v3.4c0,0.5-0.2,0.9-0.5,1.2
        S12.3,9,11.8,9h-0.6C10,9,9,9.4,8.1,10.3s-1.3,1.9-1.3,3.2V18H12.4z M32.6,18c0.9,0,1.7,0.3,2.4,1s1,1.5,1,2.4v9
        c0,0.9-0.3,1.7-1,2.4s-1.5,1-2.4,1h-9c-0.9,0-1.7-0.3-2.4-1s-1-1.5-1-2.4V13.5c0-2,0.5-3.9,1.5-5.6s2.4-3.1,4.1-4.1s3.6-1.5,5.6-1.5
        h0.6c0.5,0,0.9,0.2,1.2,0.5s0.5,0.7,0.5,1.2v3.4c0,0.5-0.2,0.9-0.5,1.2S32.5,9,32.1,9h-0.6c-1.2,0-2.3,0.4-3.2,1.3S27,12.3,27,13.5
        V18H32.6z"
      />
    </symbol>
  );
}
