import { CueType } from '@/constants/CueType';
import { mkHeapAttributes } from '@/analytics/heapUtils';

export const heapCuePaperTitle = (props: { 'cue-type': CueType }) =>
  mkHeapAttributes({
    id: 'cue_paper_title_link',
    ...props,
  });

export const heapCueBadgeButton = (props: {
  'cue-type': CueType;
  'paper-id': string;
  index: number;
}) =>
  mkHeapAttributes({
    id: 'cue_badge_button',
    ...props,
  });

export const heapCueFolderLink = (props: { 'cue-type': CueType }) =>
  mkHeapAttributes({
    id: 'cue_library_folder_link',
    ...props,
  });

export const heapCueLearnMoreLink = (props: { 'cue-type': CueType }) =>
  mkHeapAttributes({
    id: 'cue_learn_more_link',
    ...props,
  });
