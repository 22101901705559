import { getString } from '@/content/i18n';
import { ModalId } from '@/constants/Modal';
import { S2LogoFull } from '@/components/shared/s2Logos';
import { showModal } from '@/actions/ModalActionCreators';
import AccountMenu from '@/components/shared/account/AccountMenu';
import ClickEvent from '@/analytics/models/ClickEvent';
import EventTarget from '@/analytics/constants/EventTarget';
import Link from '@/router/Link';
import LoginAsStatus from '@/components/shared/common/LoginAsStatus';
import NetworkStatus from '@/components/shared/common/NetworkStatus';
import S2Dispatcher from '@/utils/S2Dispatcher';
import Searchbar from '@/components/shared/search/Searchbar';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class AppHeader extends PureComponent {
  // TODO  #29139 - Refactor to use App Context instead of isResearchFTUE prop
  static propTypes = {
    cssClass: PropTypes.string,
    isAdminPage: PropTypes.bool,
    showSearchBar: PropTypes.bool.isRequired,
    submitSearchOnFieldOfStudyChange: PropTypes.bool,
    isResearchFTUE: PropTypes.bool,
    readerLogo: PropTypes.node,
  };

  static defaultProps = {
    cssClass: 'header--light',
    showUserControls: true,
    showSearchBar: true,
    submitSearchOnFieldOfStudyChange: false,
  };

  static contextTypes = {
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      spacerHeight: null,
    };
  }

  showFeedbackModal = () => {
    trackAnalyticsEvent(ClickEvent.create(EventTarget.Feedback.SHOW));
    this.context.dispatcher.dispatch(showModal({ id: ModalId.FEEDBACK }));
  };

  trackFAQClick = () => {
    trackAnalyticsEvent(ClickEvent.create(EventTarget.FAQ));
  };

  render() {
    const {
      cssClass,
      showUserControls,
      showSearchBar,
      submitSearchOnFieldOfStudyChange,
      isAdminPage,
      isResearchFTUE,
      readerLogo,
    } = this.props;

    const isReader = readerLogo ? true : false;

    const className = classNames(
      'header',
      { [showSearchBar ? 'has-search' : 'no-search']: true },
      { 'header--research-ftue': isResearchFTUE },
      cssClass,
      { 'header__is-admin-page': isAdminPage },
      { 'header--pdf-reader': isReader },
      'flex-row-vcenter'
    );

    return (
      <div>
        <a href="#search-form" className="screen-reader-only">
          Skip to search form
        </a>
        <a href="#main-content" className="screen-reader-only">
          Skip to main content
        </a>
        <a href="#account-menu" className="screen-reader-only">
          Skip to account menu
        </a>
        <header className={className} role="banner" ref={element => (this.header = element)}>
          {readerLogo
            ? readerLogo
            : showSearchBar && (
                <Link
                  to="HOME"
                  className={classNames('s2-logo-container', {
                    's2-logo-container--research-ftue': isResearchFTUE,
                  })}
                  aria-label={getString(_ => _.appHeader.logoAriaLabel)}>
                  <S2LogoFull />
                </Link>
              )}

          {showSearchBar && (
            <Searchbar
              isResearchFTUE={isResearchFTUE}
              submitOnFieldOfStudyChange={submitSearchOnFieldOfStudyChange}
            />
          )}
          <div
            className={classNames('header-right flex-right flex-row-vcenter', {
              'header-right--research-ftue': isResearchFTUE,
            })}>
            {showUserControls && <AccountMenu isResearchFTUE={isResearchFTUE} isReader={true} />}
          </div>
        </header>
        <LoginAsStatus />
        <NetworkStatus />
      </div>
    );
  }
}
