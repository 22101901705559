import React from 'react';

export function S2HomePageLogo(): React.ReactElement {
  return (
    <svg
      viewBox="0 0 763.8 81.2"
      aria-labelledby="s2-homepage-logo-title s2-homepage-logo-desc"
      role="img"
      className="logo logo--homepage">
      <title id="s2-homepage-logo-title">Semantic Scholar</title>
      <desc id="s2-homepage-logo-desc">Semantic Scholar's Logo</desc>
      <g className="logo__wordmark">
        <path d="M158.6,8.7c-0.2,0.4-0.4,0.6-0.7,0.8c-0.2,0.2-0.5,0.3-0.9,0.3c-0.4,0-0.8-0.2-1.3-0.5s-1.1-0.8-1.9-1.2 c-0.7-0.4-1.6-0.8-2.7-1.2c-1-0.4-2.3-0.5-3.7-0.5c-1.4,0-2.5,0.2-3.6,0.5c-1,0.4-1.9,0.8-2.6,1.4c-0.7,0.6-1.2,1.3-1.6,2.2 c-0.4,0.8-0.5,1.7-0.5,2.7c0,1.2,0.3,2.3,1,3.1c0.6,0.8,1.5,1.5,2.5,2.1c1,0.6,2.2,1.1,3.5,1.5s2.7,0.9,4,1.3c1.4,0.5,2.7,1,4,1.6 c1.3,0.6,2.5,1.4,3.5,2.3c1,0.9,1.9,2,2.5,3.4c0.6,1.3,1,2.9,1,4.9c0,2-0.3,4-1,5.7c-0.7,1.8-1.7,3.3-3,4.7 c-1.3,1.3-3,2.4-4.9,3.1c-1.9,0.8-4.2,1.1-6.6,1.1c-1.5,0-2.9-0.1-4.3-0.4c-1.4-0.3-2.7-0.7-4-1.2c-1.2-0.5-2.4-1.2-3.5-1.9 c-1.1-0.7-2.1-1.6-2.9-2.5l2-3.3c0.2-0.3,0.4-0.5,0.7-0.6c0.3-0.2,0.6-0.3,0.9-0.3c0.5,0,1,0.2,1.6,0.7c0.6,0.5,1.3,1,2.2,1.6 c0.9,0.6,1.9,1.1,3.1,1.6c1.2,0.5,2.7,0.7,4.4,0.7c1.4,0,2.7-0.2,3.8-0.6c1.1-0.4,2.1-0.9,2.8-1.6c0.8-0.7,1.3-1.5,1.8-2.5 c0.4-1,0.6-2.1,0.6-3.3c0-1.4-0.3-2.5-0.9-3.3c-0.6-0.9-1.5-1.6-2.5-2.2c-1-0.6-2.2-1.1-3.5-1.5c-1.3-0.4-2.7-0.8-4-1.3 c-1.4-0.4-2.7-1-4-1.5c-1.3-0.6-2.5-1.3-3.5-2.3c-1-0.9-1.9-2.1-2.5-3.5c-0.6-1.4-0.9-3.1-0.9-5.2c0-1.7,0.3-3.3,1-4.8 c0.6-1.5,1.6-2.9,2.8-4.1c1.2-1.2,2.8-2.1,4.6-2.9c1.8-0.7,3.9-1.1,6.2-1.1c2.6,0,5,0.4,7.2,1.2c2.2,0.8,4.1,2,5.7,3.6L158.6,8.7z " />
        <path d="M197.5,41.9l0,5.6h-28.7V1.2h28.7v5.6h-21.8v14.7h17.5v5.4h-17.5v15.1H197.5z" />
        <path d="M252.7,1.2v46.3h-6V14.5c0-0.5,0-1,0-1.6c0-0.6,0.1-1.1,0.1-1.7l-15.4,28.3c-0.5,1.1-1.4,1.6-2.5,1.6h-1 c-1.1,0-1.9-0.5-2.5-1.6l-15.7-28.4c0.1,1.2,0.2,2.3,0.2,3.4v32.9h-6V1.2h5.1c0.6,0,1.1,0.1,1.4,0.2c0.3,0.1,0.6,0.4,1,1 l15.5,27.7c0.3,0.6,0.6,1.2,0.8,1.8s0.5,1.2,0.7,1.9c0.5-1.3,1-2.5,1.6-3.7l15.2-27.7c0.3-0.5,0.6-0.9,0.9-1s0.8-0.2,1.4-0.2 H252.7z" />
        <path d="M301.5,47.5h-5.3c-0.6,0-1.1-0.1-1.5-0.5c-0.4-0.3-0.7-0.7-0.8-1.2l-4.1-10.7h-20.4l-4.1,10.7 c-0.2,0.4-0.4,0.8-0.8,1.1c-0.4,0.3-0.9,0.5-1.5,0.5h-5.3l18.4-46.3h6.9L301.5,47.5z M287.9,30.2l-6.9-18c-0.5-1.2-1-2.8-1.5-4.6 c-0.2,0.9-0.5,1.8-0.7,2.6c-0.2,0.8-0.5,1.5-0.7,2.1l-6.8,17.9H287.9z" />
        <path d="M344.4,1.2v46.3h-3.5c-0.5,0-1-0.1-1.3-0.3c-0.4-0.2-0.7-0.5-1.1-0.9l-26.1-33.8c0,0.6,0.1,1.2,0.1,1.7 c0,0.6,0,1.1,0,1.6v31.8h-6V1.2h3.5c0.6,0,1.1,0.1,1.4,0.2c0.3,0.1,0.6,0.5,1,1l26.2,33.8c-0.1-0.6-0.1-1.2-0.1-1.8 c0-0.6,0-1.1,0-1.7V1.2H344.4z" />
        <path d="M386.2,6.9h-14.7v40.6h-6.8V6.9h-14.8V1.2h36.3V6.9z" />
        <path d="M398.5,47.5h-6.9V1.2h6.9V47.5z" />
        <path d="M441.5,37.6c0.4,0,0.7,0.1,1,0.5l2.7,3c-1.9,2.2-4.2,4-6.9,5.2c-2.7,1.2-6,1.8-9.8,1.8 c-3.4,0-6.4-0.6-9.1-1.7s-5-2.8-7-4.9c-1.9-2.1-3.4-4.6-4.5-7.5c-1.1-2.9-1.6-6.1-1.6-9.6c0-3.5,0.6-6.7,1.7-9.6 c1.1-2.9,2.7-5.4,4.7-7.5c2-2.1,4.4-3.7,7.2-4.9c2.8-1.2,5.9-1.7,9.2-1.7c3.3,0,6.2,0.5,8.8,1.6c2.6,1.1,4.8,2.5,6.7,4.3l-2.3,3.2 c-0.2,0.2-0.3,0.4-0.6,0.6c-0.2,0.2-0.5,0.2-0.9,0.2c-0.4,0-0.9-0.2-1.4-0.6c-0.5-0.4-1.3-0.9-2.2-1.4c-0.9-0.5-2-1-3.3-1.4 c-1.3-0.4-2.9-0.6-4.9-0.6c-2.3,0-4.4,0.4-6.3,1.2c-1.9,0.8-3.6,2-4.9,3.5c-1.4,1.5-2.5,3.4-3.2,5.6c-0.8,2.2-1.1,4.7-1.1,7.5 c0,2.8,0.4,5.3,1.2,7.5c0.8,2.2,1.9,4.1,3.3,5.6c1.4,1.5,3,2.7,4.9,3.5c1.9,0.8,3.9,1.2,6.1,1.2c1.3,0,2.5-0.1,3.6-0.2 c1.1-0.2,2-0.4,2.9-0.7c0.9-0.3,1.7-0.7,2.5-1.2c0.8-0.5,1.6-1.1,2.4-1.8c0.2-0.2,0.4-0.3,0.5-0.4 C441.1,37.6,441.3,37.6,441.5,37.6z" />
        <path d="M493.5,10.1c-0.3,0.5-0.7,0.9-1,1.2c-0.4,0.3-0.8,0.4-1.4,0.4c-0.5,0-1-0.2-1.6-0.5c-0.6-0.3-1.2-0.7-1.9-1 c-0.7-0.4-1.5-0.7-2.5-1c-0.9-0.3-2-0.5-3.2-0.5c-2,0-3.6,0.4-4.6,1.3c-1,0.9-1.5,2-1.5,3.5c0,0.9,0.3,1.7,0.9,2.4 c0.6,0.6,1.4,1.2,2.4,1.6c1,0.5,2.1,0.9,3.4,1.2c1.3,0.4,2.5,0.8,3.9,1.3c1.3,0.5,2.6,1,3.9,1.6c1.3,0.6,2.4,1.4,3.4,2.4 c1,1,1.8,2.1,2.4,3.5c0.6,1.4,0.9,3.1,0.9,5c0,2.2-0.4,4.2-1.1,6.1c-0.8,1.9-1.8,3.5-3.3,4.9c-1.4,1.4-3.2,2.5-5.3,3.3 c-2.1,0.8-4.5,1.2-7.1,1.2c-1.5,0-3-0.2-4.5-0.5s-3-0.7-4.4-1.3c-1.4-0.5-2.8-1.2-4-1.9c-1.2-0.8-2.3-1.6-3.3-2.5l3.2-5.1 c0.3-0.4,0.6-0.7,1-0.9c0.4-0.2,0.9-0.4,1.4-0.4c0.6,0,1.3,0.2,2,0.6c0.7,0.4,1.4,0.9,2.2,1.4c0.8,0.5,1.8,0.9,2.9,1.4 c1.1,0.4,2.3,0.6,3.8,0.6c2,0,3.5-0.4,4.6-1.3c1.1-0.9,1.6-2.3,1.6-4.1c0-1.1-0.3-2-0.9-2.7c-0.6-0.7-1.4-1.3-2.4-1.7 c-1-0.5-2.1-0.8-3.3-1.2c-1.2-0.3-2.5-0.7-3.8-1.1c-1.3-0.4-2.6-0.9-3.8-1.5c-1.2-0.6-2.4-1.4-3.3-2.4c-1-1-1.8-2.2-2.4-3.7 c-0.6-1.5-0.9-3.3-0.9-5.5c0-1.8,0.4-3.5,1.1-5.2c0.7-1.7,1.8-3.2,3.1-4.5c1.4-1.3,3.1-2.4,5.1-3.1c2-0.8,4.3-1.2,6.9-1.2 c1.4,0,2.8,0.1,4.2,0.3c1.4,0.2,2.7,0.6,3.9,1c1.2,0.4,2.4,1,3.4,1.6s2,1.3,2.9,2.1L493.5,10.1z" />
        <path d="M534,35.3c0.3,0,0.5,0,0.8,0.1c0.3,0.1,0.5,0.3,0.7,0.5l4.3,4.5c-1.9,2.5-4.3,4.3-7.1,5.6 c-2.8,1.3-6.2,1.9-10.1,1.9c-3.6,0-6.8-0.6-9.6-1.8c-2.8-1.2-5.2-2.9-7.2-5s-3.5-4.7-4.5-7.6c-1.1-2.9-1.6-6.1-1.6-9.6 c0-3.5,0.6-6.7,1.7-9.7c1.1-2.9,2.7-5.5,4.8-7.6s4.5-3.8,7.4-5c2.9-1.2,6-1.8,9.5-1.8c1.8,0,3.4,0.2,5,0.5c1.6,0.3,3,0.7,4.4,1.3 c1.4,0.5,2.6,1.2,3.7,2c1.1,0.8,2.1,1.6,3,2.5l-3.7,4.9c-0.2,0.3-0.5,0.6-0.8,0.8c-0.3,0.2-0.8,0.4-1.4,0.4 c-0.4,0-0.8-0.1-1.1-0.3s-0.7-0.4-1.1-0.6s-0.8-0.5-1.3-0.8c-0.5-0.3-1-0.6-1.6-0.8c-0.6-0.2-1.4-0.5-2.2-0.6 c-0.8-0.2-1.8-0.3-3-0.3c-1.8,0-3.5,0.3-4.9,1c-1.5,0.7-2.8,1.6-3.9,2.9c-1.1,1.3-1.9,2.8-2.5,4.7c-0.6,1.9-0.9,4-0.9,6.4 c0,2.4,0.3,4.5,1,6.4c0.7,1.9,1.5,3.4,2.7,4.7c1.1,1.3,2.4,2.3,3.9,2.9c1.5,0.7,3.1,1,4.8,1c1,0,1.9,0,2.7-0.1 c0.8-0.1,1.6-0.3,2.3-0.5c0.7-0.2,1.4-0.5,2-0.9c0.6-0.4,1.3-0.9,1.9-1.4c0.3-0.2,0.5-0.4,0.8-0.5C533.4,35.4,533.7,35.3,534,35.3 z" />
        <path d="M584.6,0.5v47h-11V27.6h-19.1v19.9h-11v-47h11v19.6h19.1V0.5H584.6z" />
        <path d="M639.2,24c0,3.4-0.6,6.6-1.8,9.5c-1.2,2.9-2.8,5.5-4.9,7.6c-2.1,2.2-4.7,3.9-7.7,5.1c-3,1.2-6.3,1.8-10,1.8 c-3.7,0-7-0.6-10-1.8c-3-1.2-5.6-2.9-7.7-5.1c-2.1-2.2-3.8-4.7-5-7.6c-1.2-2.9-1.8-6.1-1.8-9.5c0-3.4,0.6-6.6,1.8-9.5 c1.2-2.9,2.8-5.5,5-7.6c2.1-2.1,4.7-3.8,7.7-5.1s6.3-1.8,10-1.8c3.7,0,7,0.6,10,1.9c3,1.2,5.6,2.9,7.7,5.1 c2.1,2.1,3.8,4.7,4.9,7.6C638.6,17.5,639.2,20.6,639.2,24z M628.1,24c0-2.3-0.3-4.4-0.9-6.3c-0.6-1.9-1.5-3.4-2.6-4.7 c-1.1-1.3-2.5-2.3-4.1-3s-3.5-1-5.6-1c-2.1,0-4,0.3-5.6,1c-1.6,0.7-3,1.7-4.2,3c-1.1,1.3-2,2.9-2.6,4.7c-0.6,1.9-0.9,3.9-0.9,6.3 c0,2.3,0.3,4.4,0.9,6.3c0.6,1.9,1.5,3.4,2.6,4.7c1.1,1.3,2.5,2.3,4.2,3c1.6,0.7,3.5,1,5.6,1c2.1,0,3.9-0.3,5.6-1 c1.6-0.7,3-1.7,4.1-3c1.1-1.3,2-2.9,2.6-4.7C627.8,28.4,628.1,26.3,628.1,24z" />
        <path d="M673.4,38.8v8.7h-28.3v-47H656v38.3H673.4z" />
        <path d="M722.3,47.5h-8.4c-0.9,0-1.7-0.2-2.3-0.7c-0.6-0.4-1-1-1.3-1.7l-2.8-8.2h-17.9l-2.8,8.2 c-0.2,0.6-0.7,1.1-1.3,1.6c-0.6,0.5-1.4,0.7-2.3,0.7h-8.5l18.2-47h11.2L722.3,47.5z M705,29.5l-4.4-12.9c-0.3-0.8-0.7-1.8-1-2.9 c-0.4-1.1-0.7-2.3-1.1-3.6c-0.3,1.3-0.7,2.5-1,3.6c-0.4,1.1-0.7,2.1-1,2.9l-4.3,12.8H705z" />
        <path d="M763.8,47.5H754c-1.8,0-3.1-0.7-3.9-2.1l-7.8-13.6c-0.4-0.6-0.8-1-1.2-1.3s-1.1-0.4-1.9-0.4h-2.8v17.4h-10.9 v-47h15.3c3.4,0,6.3,0.3,8.7,1c2.4,0.7,4.4,1.7,5.9,2.9c1.5,1.2,2.6,2.7,3.3,4.4c0.7,1.7,1,3.5,1,5.5c0,1.5-0.2,3-0.6,4.3 c-0.4,1.4-1,2.6-1.8,3.8c-0.8,1.2-1.8,2.2-3,3.1c-1.2,0.9-2.5,1.6-4,2.2c0.7,0.4,1.4,0.8,2,1.4c0.6,0.5,1.2,1.2,1.7,2L763.8,47.5z M740.7,22.6c1.5,0,2.7-0.2,3.8-0.6c1-0.4,1.9-0.9,2.6-1.6c0.7-0.7,1.1-1.4,1.5-2.3c0.3-0.9,0.5-1.9,0.5-2.9c0-2.1-0.7-3.7-2-4.8 c-1.3-1.2-3.4-1.7-6.2-1.7h-4.4v13.9H740.7z" />
      </g>
      <g className="logo__mark">
        <path
          className="logo__checkmark"
          d="M110.9,25.5c-3.9,2.5-6.6,3.8-9.9,5.8C81.8,42.9,63.4,55.8,49,72.8l-6.8,8.4L21,47.6 c4.7,3.8,16.5,14.3,21.3,16.7l15.5-11.7C68.7,45,99.2,28.4,110.9,25.5z"
        />
        <path
          className="logo__paper-one"
          d="M37.2,56c1.6,1.3,3.2,2.5,4.5,3.4c3.5-16.9,0.6-35.2-8.7-51.1c15.6-0.2,31.2-0.4,46.7-0.7 c3.5,7.7,5.5,16,6,24.5c1.4-0.7,2.7-1.4,4.1-2c-0.5-8.6-2.9-18-7.7-28.9c-21.3,0-42.5,0-63.8,0C32.2,17.6,38.4,37.4,37.2,56z"
        />
        <path
          className="logo__paper-two"
          d="M34.3,53.7c0.4,0.4,0.9,0.7,1.3,1.1C35,39.1,29.1,22.9,17.9,9.1c-3.3,0-6.6,0-9.9,0 C22.3,22.3,31.1,38.2,34.3,53.7z"
        />
        <path
          className="logo__paper-three"
          d="M30.9,50.7c0.5,0.4,1,0.8,1.5,1.2c-4.2-11.7-11.5-23.2-21.9-33.2c-3.5,0-7,0-10.5,0 C13.4,28.3,23.7,39.4,30.9,50.7z"
        />
      </g>
    </svg>
  );
}

export function S2LogoFull(): React.ReactElement {
  return (
    <svg
      viewBox="0 0 552.2 100"
      aria-labelledby="s2-logo-full-title s2-logo-full-desc"
      role="img"
      className="logo logo--logo-full">
      <title id="s2-logo-full-title">Semantic Scholar</title>
      <desc id="s2-logo-full-desc">Semantic Scholar's Logo</desc>
      <g className="logo__wordmark">
        <path d="M174,40.5c-0.1,0.2-0.3,0.4-0.4,0.5c-0.1,0.1-0.3,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.8-0.3 c-0.3-0.2-0.7-0.5-1.2-0.7c-0.5-0.3-1-0.5-1.7-0.7c-0.6-0.2-1.4-0.3-2.3-0.3c-0.8,0-1.6,0.1-2.2,0.3c-0.6,0.2-1.2,0.5-1.6,0.9 c-0.4,0.4-0.8,0.8-1,1.4c-0.2,0.5-0.3,1.1-0.3,1.7c0,0.8,0.2,1.4,0.6,1.9c0.4,0.5,0.9,0.9,1.6,1.3c0.6,0.4,1.4,0.7,2.2,0.9 c0.8,0.3,1.7,0.5,2.5,0.8c0.9,0.3,1.7,0.6,2.5,1c0.8,0.4,1.6,0.9,2.2,1.4c0.6,0.6,1.2,1.3,1.6,2.1c0.4,0.8,0.6,1.8,0.6,3 c0,1.3-0.2,2.5-0.7,3.6c-0.4,1.1-1.1,2.1-1.9,2.9c-0.8,0.8-1.9,1.5-3.1,2c-1.2,0.5-2.6,0.7-4.1,0.7c-0.9,0-1.8-0.1-2.7-0.3 c-0.9-0.2-1.7-0.4-2.5-0.8c-0.8-0.3-1.5-0.7-2.2-1.2c-0.7-0.5-1.3-1-1.8-1.6l1.2-2.1c0.1-0.2,0.3-0.3,0.4-0.4 c0.2-0.1,0.4-0.2,0.6-0.2c0.3,0,0.6,0.1,1,0.4c0.4,0.3,0.8,0.6,1.4,1s1.2,0.7,2,1c0.8,0.3,1.7,0.4,2.8,0.4c0.9,0,1.7-0.1,2.4-0.4 c0.7-0.2,1.3-0.6,1.8-1c0.5-0.4,0.8-1,1.1-1.6c0.3-0.6,0.4-1.3,0.4-2.1c0-0.8-0.2-1.5-0.6-2.1c-0.4-0.5-0.9-1-1.6-1.4 c-0.6-0.4-1.4-0.7-2.2-0.9c-0.8-0.3-1.7-0.5-2.5-0.8c-0.9-0.3-1.7-0.6-2.5-1c-0.8-0.4-1.6-0.8-2.2-1.4c-0.6-0.6-1.2-1.3-1.6-2.2 c-0.4-0.9-0.6-2-0.6-3.3c0-1,0.2-2,0.6-3c0.4-1,1-1.8,1.8-2.6c0.8-0.7,1.7-1.3,2.9-1.8c1.1-0.5,2.4-0.7,3.9-0.7 c1.6,0,3.1,0.3,4.5,0.8c1.4,0.5,2.5,1.3,3.6,2.2L174,40.5z" />
        <path d="M198.2,61.2l0,3.5h-17.9V35.7h17.9v3.5h-13.6v9.2h11v3.4h-11v9.4H198.2z" />
        <path d="M232.7,35.7v28.9H229V44.1c0-0.3,0-0.6,0-1s0-0.7,0.1-1.1l-9.6,17.7c-0.3,0.7-0.9,1-1.6,1h-0.6 c-0.7,0-1.2-0.3-1.5-1L206,42c0.1,0.8,0.1,1.5,0.1,2.1v20.6h-3.8V35.7h3.2c0.4,0,0.7,0,0.9,0.1s0.4,0.3,0.6,0.6l9.7,17.3 c0.2,0.3,0.4,0.7,0.5,1.1c0.2,0.4,0.3,0.8,0.5,1.2c0.3-0.8,0.6-1.6,1-2.3l9.5-17.3c0.2-0.3,0.4-0.5,0.6-0.6s0.5-0.1,0.9-0.1H232.7 z" />
        <path d="M263.3,64.7H260c-0.4,0-0.7-0.1-0.9-0.3c-0.2-0.2-0.4-0.4-0.5-0.7l-2.6-6.7h-12.8l-2.6,6.7 c-0.1,0.3-0.3,0.5-0.5,0.7c-0.3,0.2-0.6,0.3-0.9,0.3h-3.3l11.5-28.9h4.3L263.3,64.7z M254.8,53.9l-4.3-11.2 c-0.3-0.8-0.6-1.7-0.9-2.9c-0.1,0.6-0.3,1.1-0.5,1.6c-0.2,0.5-0.3,0.9-0.5,1.3l-4.3,11.2H254.8z" />
        <path d="M290.1,35.7v28.9h-2.2c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.3-0.7-0.6l-16.3-21.1c0,0.4,0.1,0.7,0.1,1.1 c0,0.4,0,0.7,0,1v19.8h-3.8V35.7h2.2c0.4,0,0.7,0,0.8,0.1c0.2,0.1,0.4,0.3,0.6,0.6l16.4,21.2c0-0.4-0.1-0.8-0.1-1.1 c0-0.4,0-0.7,0-1V35.7H290.1z" />
        <path d="M316.2,39.3H307v25.3h-4.3V39.3h-9.2v-3.6h22.7V39.3z" />
        <path d="M323.9,64.7h-4.3V35.7h4.3V64.7z" />
        <path d="M350.8,58.5c0.2,0,0.4,0.1,0.6,0.3l1.7,1.9c-1.2,1.4-2.6,2.5-4.3,3.2c-1.7,0.8-3.7,1.1-6.1,1.1 c-2.1,0-4-0.4-5.7-1.1c-1.7-0.7-3.1-1.7-4.4-3c-1.2-1.3-2.1-2.9-2.8-4.7c-0.7-1.8-1-3.8-1-6c0-2.2,0.3-4.2,1-6 c0.7-1.8,1.7-3.4,2.9-4.7c1.2-1.3,2.7-2.3,4.5-3c1.7-0.7,3.7-1.1,5.8-1.1c2.1,0,3.9,0.3,5.5,1c1.6,0.7,3,1.6,4.2,2.7l-1.4,2 c-0.1,0.1-0.2,0.3-0.4,0.4c-0.1,0.1-0.3,0.1-0.6,0.1c-0.3,0-0.6-0.1-0.9-0.4c-0.3-0.3-0.8-0.5-1.4-0.9c-0.6-0.3-1.2-0.6-2.1-0.9 c-0.8-0.3-1.8-0.4-3.1-0.4c-1.4,0-2.8,0.3-3.9,0.8c-1.2,0.5-2.2,1.2-3.1,2.2c-0.9,0.9-1.5,2.1-2,3.5c-0.5,1.4-0.7,2.9-0.7,4.7 c0,1.8,0.2,3.3,0.7,4.7c0.5,1.4,1.2,2.5,2.1,3.5c0.9,1,1.9,1.7,3.1,2.2c1.2,0.5,2.5,0.7,3.8,0.7c0.8,0,1.6-0.1,2.2-0.2 c0.7-0.1,1.3-0.3,1.8-0.5c0.6-0.2,1.1-0.5,1.6-0.8c0.5-0.3,1-0.7,1.5-1.1c0.1-0.1,0.2-0.2,0.3-0.2 C350.5,58.5,350.6,58.5,350.8,58.5z" />
        <path d="M383.2,41.3c-0.2,0.3-0.4,0.6-0.6,0.7c-0.2,0.2-0.5,0.2-0.9,0.2c-0.3,0-0.6-0.1-1-0.3 c-0.4-0.2-0.8-0.4-1.2-0.7c-0.5-0.2-1-0.5-1.5-0.7c-0.6-0.2-1.2-0.3-2-0.3c-1.3,0-2.2,0.3-2.9,0.8c-0.6,0.5-0.9,1.3-0.9,2.2 c0,0.6,0.2,1.1,0.6,1.5c0.4,0.4,0.9,0.7,1.5,1c0.6,0.3,1.3,0.5,2.1,0.8c0.8,0.2,1.6,0.5,2.4,0.8c0.8,0.3,1.6,0.6,2.4,1 c0.8,0.4,1.5,0.9,2.1,1.5c0.6,0.6,1.1,1.3,1.5,2.2c0.4,0.9,0.6,1.9,0.6,3.1c0,1.4-0.2,2.6-0.7,3.8c-0.5,1.2-1.2,2.2-2,3.1 c-0.9,0.9-2,1.6-3.3,2.1c-1.3,0.5-2.8,0.8-4.5,0.8c-0.9,0-1.8-0.1-2.8-0.3c-0.9-0.2-1.9-0.5-2.7-0.8c-0.9-0.3-1.7-0.7-2.5-1.2 c-0.8-0.5-1.5-1-2-1.6l2-3.2c0.2-0.2,0.4-0.4,0.6-0.6c0.3-0.2,0.5-0.2,0.9-0.2c0.4,0,0.8,0.1,1.2,0.4c0.4,0.3,0.9,0.5,1.4,0.8 c0.5,0.3,1.1,0.6,1.8,0.8c0.7,0.3,1.5,0.4,2.4,0.4c1.2,0,2.2-0.3,2.9-0.8c0.7-0.5,1-1.4,1-2.6c0-0.7-0.2-1.2-0.6-1.7 c-0.4-0.4-0.9-0.8-1.5-1.1s-1.3-0.5-2.1-0.7c-0.8-0.2-1.6-0.5-2.4-0.7c-0.8-0.3-1.6-0.6-2.4-1c-0.8-0.4-1.5-0.9-2.1-1.5 c-0.6-0.6-1.1-1.4-1.5-2.3c-0.4-0.9-0.6-2.1-0.6-3.5c0-1.1,0.2-2.2,0.7-3.2c0.4-1,1.1-2,2-2.8c0.9-0.8,1.9-1.5,3.2-2 c1.2-0.5,2.7-0.7,4.3-0.7c0.9,0,1.8,0.1,2.6,0.2c0.9,0.1,1.7,0.3,2.4,0.6c0.8,0.3,1.5,0.6,2.1,1c0.7,0.4,1.3,0.8,1.8,1.3 L383.2,41.3z" />
        <path d="M408.6,57.1c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.3,0.2,0.5,0.3l2.7,2.8c-1.2,1.5-2.7,2.7-4.4,3.5 c-1.8,0.8-3.9,1.2-6.3,1.2c-2.2,0-4.2-0.4-6-1.1c-1.8-0.8-3.3-1.8-4.5-3.1c-1.2-1.3-2.2-2.9-2.8-4.8c-0.7-1.8-1-3.8-1-6 c0-2.2,0.4-4.2,1.1-6c0.7-1.8,1.7-3.4,3-4.7c1.3-1.3,2.8-2.4,4.6-3.1c1.8-0.7,3.8-1.1,5.9-1.1c1.1,0,2.1,0.1,3.1,0.3 c1,0.2,1.9,0.5,2.7,0.8c0.8,0.3,1.6,0.8,2.3,1.2c0.7,0.5,1.3,1,1.9,1.6l-2.3,3.1c-0.1,0.2-0.3,0.4-0.5,0.5 c-0.2,0.2-0.5,0.2-0.8,0.2c-0.2,0-0.5-0.1-0.7-0.2c-0.2-0.1-0.4-0.2-0.7-0.4c-0.2-0.2-0.5-0.3-0.8-0.5c-0.3-0.2-0.6-0.3-1-0.5 c-0.4-0.2-0.9-0.3-1.4-0.4c-0.5-0.1-1.2-0.2-1.9-0.2c-1.1,0-2.2,0.2-3.1,0.6c-0.9,0.4-1.7,1-2.4,1.8c-0.7,0.8-1.2,1.8-1.6,2.9 c-0.4,1.2-0.6,2.5-0.6,4c0,1.5,0.2,2.8,0.6,4c0.4,1.2,1,2.2,1.7,3c0.7,0.8,1.5,1.4,2.5,1.8c0.9,0.4,1.9,0.6,3,0.6 c0.6,0,1.2,0,1.7-0.1c0.5-0.1,1-0.2,1.4-0.3c0.4-0.1,0.9-0.3,1.2-0.6c0.4-0.2,0.8-0.5,1.2-0.9c0.2-0.1,0.3-0.2,0.5-0.3 C408.2,57.1,408.4,57.1,408.6,57.1z" />
        <path d="M440.2,35.3v29.4h-6.9V52.3h-11.9v12.4h-6.9V35.3h6.9v12.3h11.9V35.3H440.2z" />
        <path d="M474.3,50c0,2.1-0.4,4.1-1.1,5.9c-0.7,1.8-1.8,3.4-3.1,4.8c-1.3,1.3-2.9,2.4-4.8,3.2c-1.9,0.8-4,1.1-6.2,1.1 c-2.3,0-4.4-0.4-6.2-1.1s-3.5-1.8-4.8-3.2c-1.3-1.4-2.4-2.9-3.1-4.8c-0.7-1.8-1.1-3.8-1.1-5.9c0-2.1,0.4-4.1,1.1-5.9 c0.7-1.8,1.8-3.4,3.1-4.8c1.3-1.3,2.9-2.4,4.8-3.2s4-1.1,6.2-1.1c2.3,0,4.4,0.4,6.2,1.2c1.9,0.8,3.5,1.8,4.8,3.2 c1.3,1.3,2.4,2.9,3.1,4.8C474,45.9,474.3,47.9,474.3,50z M467.4,50c0-1.5-0.2-2.8-0.6-3.9c-0.4-1.2-0.9-2.1-1.6-3 c-0.7-0.8-1.6-1.4-2.6-1.9c-1-0.4-2.2-0.6-3.5-0.6c-1.3,0-2.5,0.2-3.5,0.6c-1,0.4-1.9,1-2.6,1.9c-0.7,0.8-1.2,1.8-1.6,3 s-0.6,2.5-0.6,3.9c0,1.5,0.2,2.8,0.6,3.9s0.9,2.1,1.6,3c0.7,0.8,1.6,1.4,2.6,1.8c1,0.4,2.2,0.6,3.5,0.6c1.3,0,2.5-0.2,3.5-0.6 c1-0.4,1.9-1,2.6-1.8c0.7-0.8,1.2-1.8,1.6-3C467.2,52.8,467.4,51.5,467.4,50z" />
        <path d="M495.7,59.2v5.4H478V35.3h6.8v23.9H495.7z" />
        <path d="M526.3,64.7H521c-0.6,0-1.1-0.1-1.4-0.4c-0.4-0.3-0.6-0.6-0.8-1.1l-1.7-5.1h-11.2l-1.7,5.1 c-0.1,0.4-0.4,0.7-0.8,1c-0.4,0.3-0.9,0.5-1.4,0.5h-5.3l11.4-29.4h7L526.3,64.7z M515.5,53.5l-2.7-8c-0.2-0.5-0.4-1.1-0.6-1.8 c-0.2-0.7-0.5-1.4-0.7-2.2c-0.2,0.8-0.4,1.6-0.6,2.3c-0.2,0.7-0.4,1.3-0.6,1.8l-2.7,8H515.5z" />
        <path d="M552.2,64.7h-6.2c-1.1,0-2-0.4-2.5-1.3l-4.9-8.5c-0.2-0.4-0.5-0.6-0.8-0.8c-0.3-0.2-0.7-0.3-1.2-0.3H535v10.9 h-6.8V35.3h9.6c2.1,0,3.9,0.2,5.4,0.7c1.5,0.4,2.7,1,3.7,1.8s1.6,1.7,2.1,2.8c0.4,1.1,0.6,2.2,0.6,3.5c0,1-0.1,1.9-0.4,2.7 c-0.3,0.8-0.6,1.6-1.1,2.3c-0.5,0.7-1.1,1.4-1.8,1.9c-0.7,0.6-1.6,1-2.5,1.4c0.4,0.2,0.9,0.5,1.2,0.9c0.4,0.3,0.7,0.7,1,1.2 L552.2,64.7z M537.8,49.1c0.9,0,1.7-0.1,2.4-0.4c0.7-0.2,1.2-0.6,1.6-1c0.4-0.4,0.7-0.9,0.9-1.5c0.2-0.6,0.3-1.2,0.3-1.8 c0-1.3-0.4-2.3-1.3-3c-0.8-0.7-2.1-1.1-3.9-1.1H535v8.7H537.8z" />
      </g>
      <g className="logo__mark">
        <path
          className="logo__checkmark"
          d="M138.7,30.4c-4.9,3.1-8.3,4.8-12.4,7.2c-24,14.5-47.1,30.6-65,51.9L52.7,100L26.3,58 c5.9,4.7,20.6,17.9,26.6,20.8l19.4-14.6C85.8,54.7,124,34,138.7,30.4z"
        />
        <path
          className="logo__paper-one"
          d="M46.5,68.5c2,1.6,4,3.1,5.6,4.3c4.4-21.2,0.7-44-10.9-63.8c19.5-0.3,38.9-0.5,58.4-0.8 c4.4,9.7,6.9,20,7.5,30.6c1.7-0.9,3.4-1.7,5.1-2.5c-0.6-10.8-3.7-22.6-9.6-36.2C76,0,49.5,0,22.9,0C40.2,20.5,48.1,45.3,46.5,68.5 z"
        />
        <path
          className="logo__paper-two"
          d="M42.9,65.7c0.5,0.5,1.1,0.9,1.6,1.3c-0.8-19.7-8.2-39.8-22.1-57.1c-4.1,0-8.3,0-12.4,0 C27.9,26.4,38.8,46.3,42.9,65.7z"
        />
        <path
          className="logo__paper-three"
          d="M38.6,61.9c0.6,0.5,1.2,1,1.9,1.6c-5.2-14.6-14.4-29-27.4-41.6c-4.4,0-8.7,0-13.1,0 C16.7,33.9,29.6,47.8,38.6,61.9z"
        />
      </g>
    </svg>
  );
}

export function S2LogoMarkOnly(): React.ReactElement {
  return (
    <svg
      viewBox="0 0 83.2 60"
      aria-labelledby="s2-logo-mark-only-title s2-logo-mark-only-desc"
      role="img"
      className="logo logo--logo-only">
      <title id="s2-logo-mark-only-title">Semantic Scholar</title>
      <desc id="s2-logo-mark-only-desc">Semantic Scholar's Logo</desc>
      <g className="logo__mark">
        <path
          className="logo__checkmark"
          d="M83.2,18.3c-2.9,1.8-5,2.9-7.4,4.3c-14.4,8.7-28.3,18.4-39,31.1L31.6,60L15.8,34.8c3.5,2.8,12.4,10.7,16,12.5 l11.6-8.8C51.5,32.8,74.4,20.4,83.2,18.3z"
        />
        <path
          className="logo__paper-one"
          d="M27.9,41.1c1.2,1,2.4,1.9,3.4,2.6c2.6-12.7,0.4-26.4-6.5-38.3c11.7-0.2,23.4-0.3,35-0.5 c2.6,5.8,4.1,12,4.5,18.4c1-0.5,2-1,3.1-1.5C66.9,15.2,65.1,8.2,61.6,0C45.6,0,29.7,0,13.7,0C24.1,12.3,28.8,27.2,27.9,41.1z"
        />
        <path
          className="logo__paper-two"
          d="M25.8,39.4c0.3,0.3,0.7,0.5,1,0.8c-0.5-11.8-4.9-23.9-13.3-34.3c-2.5,0-5,0-7.5,0 C16.7,15.8,23.3,27.8,25.8,39.4z"
        />
        <path
          className="logo__paper-three"
          d="M23.2,37.1c0.4,0.3,0.7,0.6,1.1,0.9c-3.1-8.7-8.6-17.4-16.4-24.9c-2.6,0-5.2,0-7.9,0 C10,20.3,17.8,28.7,23.2,37.1z"
        />
      </g>
    </svg>
  );
}
