import CuePapersList from './CuePapersList';

import { CUE_PAPERS } from '@/constants/ShelfId';
import { getString } from '@/content/i18n';
import { heapCueLearnMoreLink } from '@/analytics/attributes/personalizedCues';
import Icon from '@/components/shared/icon/Icon';
import Link from '@/router/Link';
import Shelf from '@/components/shared/shelf/Shelf';
import ShowEvent from '@/analytics/models/ShowEvent';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';

import React from 'react';

import type { CuePapersShelfContext } from '@/models/shelf/ShelfContexts';
import type { ReactNodeish } from '@/utils/types';

type Props = {
  context: CuePapersShelfContext;
};

export default class CuePapersShelf extends React.PureComponent<Props> {
  componentDidMount() {
    const {
      context: { prioritizedCue, viewCuePaperShelfEvent },
    } = this.props;

    trackAnalyticsEvent(
      ShowEvent.create(viewCuePaperShelfEvent, {
        cueType: prioritizedCue,
      })
    );
  }

  renderHeader = (): ReactNodeish => {
    const {
      context: { cueHeader },
    } = this.props;

    return (
      <div className="flex-row cue-papers-shelf__header-container">
        <Icon className="cue-papers-shelf__icon" icon="citesee" height="14" width="14" />
        <h3 className="cue-papers-shelf__header">{cueHeader}</h3>
      </div>
    );
  };

  render(): ReactNodeish {
    const {
      context: { cueDescription, paperIds, prioritizedCue },
    } = this.props;

    return (
      <Shelf shelfId={CUE_PAPERS} header={this.renderHeader()}>
        <CuePapersList
          className="cue-papers-shelf__cue-papers"
          paperIds={paperIds}
          prioritizedCue={prioritizedCue}
        />
        <hr className="cue-papers-shelf__seperator" />
        <footer className="cue-papers-shelf__footer">
          <p className="cue-paper-shelf__footer-text">{cueDescription} </p>

          <Link
            className="cue-paper-shelf__footer-link"
            to="FAQ_ROOT"
            hash="why-are-some-papers-annotated-with-a-thumbprint-icon-how-do-these-work"
            {...heapCueLearnMoreLink({ 'cue-type': prioritizedCue })}>
            <>
              {getString(_ => _.personalizedCue.learnMore)}
              <Icon
                icon="arrow-lite"
                width="12"
                height="8"
                className="cue-papers-shelf__arrow-icon"
              />
            </>
          </Link>
        </footer>
      </Shelf>
    );
  }
}
