import { CueType, getCueType, Unsupported } from '@/constants/CueType';

import Immutable from 'immutable';

import type { Nullable } from '@/utils/types';

export const CUE_DATA_WRAPPER_RECORD_NAME = 'CueDataWrapper';

// Keep in sync with CueDataWrapper.scala
export type CueDataWrapperFromJS = {
  // AI2ID for author of cued paper. This field is optional and depends on the cue applies to author or paper
  authorId?: Nullable<string>;
  cueType: string;
  // PaperIds of papers associated with this cue
  paperIds: string[];
};

type Props = {
  authorId: Nullable<string>;
  cueType: CueType;
  paperIds: Immutable.List<string>;
};

export const defaultProps: Props = {
  authorId: null,
  cueType: Unsupported,
  paperIds: Immutable.List(),
};

export const CueDataWrapperRecordFactory = Immutable.Record<Props>(
  defaultProps,
  CUE_DATA_WRAPPER_RECORD_NAME
);

export type CueDataWrapperRecord = Immutable.RecordOf<Props>;

export function isCueDataWrapperRecord(record: unknown): record is CueDataWrapperRecord {
  return (
    Immutable.isRecord(record) &&
    Immutable.Record.getDescriptiveName(record) === CUE_DATA_WRAPPER_RECORD_NAME
  );
}

export function getCueDataWrapperFromJS(args: CueDataWrapperFromJS): CueDataWrapperRecord {
  return CueDataWrapperRecordFactory({
    ...args,
    cueType: getCueType(args.cueType),
    paperIds: Immutable.List(args.paperIds),
  });
}

export function getCueDataWrapperMapFromJS(
  cueData: Record<string, CueDataWrapperFromJS[]>
): Immutable.Map<CueType, Immutable.List<CueDataWrapperRecord>> {
  const map = new Map<CueType, Immutable.List<CueDataWrapperRecord>>();
  for (const [rawCueType, rawCueDataWrappers] of Object.entries(cueData)) {
    const cueType = getCueType(rawCueType);
    const cues = Immutable.List(
      rawCueDataWrappers.map(rawCueDataWrapper => getCueDataWrapperFromJS(rawCueDataWrapper))
    );
    map.set(cueType, cues);
  }
  return Immutable.Map(map);
}
