import { AnalyticsContextHelper } from './CLPaperAnalytics';
import MockPaper, { MockPaperLiteFromJs } from '../../../../../test/utils/MockPaper';

import { addHeapProps } from '@/components/util/HeapTracking';
import {
  AuthorAliasFromJS,
  authorAliasHasAuthorHomePage,
  getAuthorAliasFromJS,
} from '@/models/author/AuthorAlias';
import { ExampleConfig } from '@/components/library/ExampleUtils';
import { focusOnNewContent } from '@/utils/a11y-utils';
import { getHighlightedFieldFromJS } from '@/models/HighlightedField';
import { getPluralizedString, getString } from '@/content/i18n';
import { heapAuthorListItem } from '@/analytics/attributes/paperObject';
import { HeapProps } from '@/analytics/heapUtils';
import {
  HighlightedAuthorRecord,
  HighlightedAuthorRecordFactory,
} from '@/models/HighlightedAuthor';
import { Nullable, ReactNodeish, TODO } from '@/utils/types';
import AuthorCardActionPopover from '@/components/shared/author/AuthorCardActionPopover';
import CLButton from '@/components/library/button/CLButton';
import CueStore, { PaperId } from '@/stores/CueStore';
import EnvInfo from '@/env/EnvInfo';
import Experiment from '@/weblab/Experiment';
import HighlightedField from '@/components/shared/common/HighlightedField';
import Icon from '@/components/shared/icon/Icon';
import Link from '@/router/Link';
import WeblabStore from '@/weblab/WeblabStore';

import classnames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import type { CueDataWrapperRecord } from '@/models/CueDataWrapper';
import type { PaperishRecord } from '@/models/Paper';

const COUNT_TO_DISPLAY_WITH_TOGGLE = 2;
const LAST_HIDDEN_AUTHOR_OFFSET = 2;
const TOGGLE_CLASS_NAME = 'cl-paper-authors__toggle';
export const AUTHOR_LINK_CLASS_NAME = 'cl-paper-authors__author-link';
export const AUTHOR_BOX_CLASS_NAME = 'cl-paper-authors__author-box';

type TODO__PaperAnalyticsContext = TODO<'CLPaperAnalytics needs types'>;

export type OnClickAuthorData = {
  authorId: string;
};

type Props = {
  authors?: Nullable<Immutable.List<HighlightedAuthorRecord>>;
  heapProps?: Nullable<HeapProps>;
  maxToDisplay: number;
  onAuthorClick: (id: string) => void;
  onWhiteBackground: boolean;
  testId: string;
  shouldLinkToAHP: Nullable<boolean>;
  shouldOpenInNewTab: boolean;
  paper?: Nullable<PaperishRecord>;
  paperIds: Immutable.List<PaperId>;
  showAuthorCardPopover?: Nullable<boolean>;
};

type State = {
  isCollapsed: boolean;
} & StateFromWeblabStore &
  StateFromCueStore;

type StateFromWeblabStore = {
  isAuthorCardDisplayed: boolean;
  isAuthorCardPersonalizedEnabled: boolean;
};

type StateFromCueStore = {
  authorCuesMap: Immutable.Map<string, CueDataWrapperRecord>;
};

export default class CLPaperAuthors extends React.PureComponent<Props, State> {
  static defaultProps = {
    maxToDisplay: 6,
    onAuthorClick: () => {},
    shouldLinkToAHP: true,
    testId: 'author-list',
    shouldOpenInNewTab: false,
    // when the authors are used on a white background, we want the '+ x authors' button to
    // be visible by adding a grey background to the button
    onWhiteBackground: true,
    // showAuthorCardPopover is a temporary prop to be cleaned up after the non-personalized author card experiment is over.
    // It is used to not show the author card on reader citation cards.
    // TODO: (#35509) Clean up showAuthorCardPopover prop
    showAuthorCardPopover: false,
    paperIds: Immutable.List(),
  };

  declare context: {
    cueStore: CueStore;
    envInfo: EnvInfo;
    weblabStore: WeblabStore;
  };

  static contextTypes = {
    cueStore: PropTypes.instanceOf(CueStore).isRequired,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    weblabStore: PropTypes.instanceOf(WeblabStore).isRequired,
  };

  _onClickAuthorAnalytics: Nullable<(OnClickAuthorData) => void>;

  #firstNewAuthor = React.createRef<HTMLSpanElement>();
  #authorList = React.createRef<HTMLSpanElement>();

  constructor(...args: [any]) {
    super(...args);
    this.state = {
      isCollapsed: true,
      ...this.getStateFromWeblabStore(),
      ...this.getStateFromCueStore(),
    };
    this.context.weblabStore.registerComponent(this, () => {
      this.setState(this.getStateFromWeblabStore());
    });

    this.context.cueStore.registerComponent(this, () => {
      this.setState(this.getStateFromCueStore());
    });
  }

  getStateFromWeblabStore(): StateFromWeblabStore {
    const weblabStore: WeblabStore = this.context.weblabStore;

    const variationName = weblabStore.getVariation(Experiment.PersonalizedAuthorCardCues.KEY);
    const isAuthorCardPersonalizedEnabled =
      variationName ===
        Experiment.PersonalizedAuthorCardCues.Variation
          .ENABLE_PERSONALIZED_AUTHOR_CARD_CUES_ON_HOVER ||
      variationName ===
        Experiment.PersonalizedAuthorCardCues.Variation
          .ENABLE_PERSONALIZED_AUTHOR_CARD_CUES_ON_CLICK;

    return {
      isAuthorCardDisplayed:
        variationName !== Experiment.PersonalizedAuthorCardCues.Variation.CONTROL &&
        variationName !==
          Experiment.PersonalizedAuthorCardCues.Variation.FETCH_PERSONALIZED_AUTHOR_CUES,
      isAuthorCardPersonalizedEnabled,
    };
  }

  getStateFromCueStore(): StateFromCueStore {
    const { cueStore } = this.context;
    return {
      authorCuesMap: cueStore.getAuthorCuesDataMap(),
    };
  }

  componentDidUpdate(_, prevState) {
    const { isCollapsed } = this.state;

    if (prevState.isCollapsed !== isCollapsed) {
      focusOnNewContent({
        collapsed: isCollapsed,
        listRef: this.#authorList,
        newContentRef: this.#firstNewAuthor,
        defaultButtonClass: TOGGLE_CLASS_NAME,
        expandedTargetClass: AUTHOR_BOX_CLASS_NAME,
      });
    }
  }

  setAnalyticsCallbacks = ({ onClickAuthor }: TODO__PaperAnalyticsContext): void => {
    this._onClickAuthorAnalytics = onClickAuthor;
  };

  onClickAuthor = (authorId: string): void => {
    if (this._onClickAuthorAnalytics) {
      this._onClickAuthorAnalytics({ authorId });
    }
    const { onAuthorClick } = this.props;
    if (onAuthorClick) {
      onAuthorClick(authorId);
    }
  };

  handleAuthorListOnClick(event: React.SyntheticEvent): void {
    event.stopPropagation();
    this.setState(prevState => ({ isCollapsed: !prevState.isCollapsed }));
  }

  areHiddenAuthorsPersonalized(
    authorList: Immutable.List<HighlightedAuthorRecord>,
    countToDisplayWithToggle: number
  ): boolean {
    const { authorCuesMap, isAuthorCardPersonalizedEnabled } = this.state;
    const { showAuthorCardPopover } = this.props;
    const {
      envInfo: { isMobile },
    } = this.context;

    if (
      authorCuesMap.isEmpty() ||
      isMobile ||
      !isAuthorCardPersonalizedEnabled ||
      !showAuthorCardPopover
    ) {
      return false;
    }

    const firstHiddenAuthorIndex = countToDisplayWithToggle - 1;
    const lastHiddenAuthorIndex = authorList.size - LAST_HIDDEN_AUTHOR_OFFSET;
    for (let i = firstHiddenAuthorIndex; i < lastHiddenAuthorIndex; i++) {
      const authorId = authorList.get(i)?.alias.ids.first();
      if (authorId && authorCuesMap.has(authorId)) {
        return true;
      }
    }
    return false;
  }

  renderAuthorLink(author: HighlightedAuthorRecord): ReactNodeish {
    const { showAuthorCardPopover, shouldOpenInNewTab, onWhiteBackground, paper, paperIds } =
      this.props;
    const { isCollapsed, isAuthorCardDisplayed } = this.state;

    const {
      envInfo: { isMobile },
    } = this.context;
    const { isAuthorCardPersonalizedEnabled } = this.state;

    return !isMobile && showAuthorCardPopover && isAuthorCardDisplayed ? (
      <AuthorCardActionPopover
        authorId={author.alias.ids.first()}
        shouldOpenInNewTab={shouldOpenInNewTab}
        onClick={this.props.onAuthorClick}
        slug={author.alias.slug}
        className={classnames('notranslate', {
          'cl-paper-authors__author__expanded': !isCollapsed && isAuthorCardDisplayed,
        })}
        onWhiteBackground={onWhiteBackground}
        paper={paper}
        paperIds={paperIds}
        isAuthorPersonalizedEnabled={isAuthorCardPersonalizedEnabled}>
        <HighlightedField field={author.highlightedField} />
      </AuthorCardActionPopover>
    ) : (
      <AuthorLink
        author={author}
        isCollapsed={isCollapsed}
        onWhiteBackground={onWhiteBackground}
        onClick={this.onClickAuthor}
        shouldOpenInNewTab={shouldOpenInNewTab}>
        <HighlightedField field={author.highlightedField} />
      </AuthorLink>
    );
  }

  render() {
    const { authors, maxToDisplay, paper, shouldLinkToAHP, heapProps, testId, onWhiteBackground } =
      this.props;
    const { isCollapsed } = this.state;

    // an author list or paper must be provided to render authors
    if (!authors && !paper) {
      return null;
    }

    const authorList = authors ? authors : paper?.authors;

    if (!authorList || authorList.isEmpty()) {
      return null;
    }

    const hasMoreThanMax = authorList.size > maxToDisplay;
    // by default render 3 authors with expand toggle Ex: `author1, author2, +n authors, lastAuthor`
    // but if the max is 2, then display 2 authors with expand toggle Ex: `author1, +n authors, lastAuthor`
    const countToDisplayWithToggle = maxToDisplay === 2 ? 2 : 3;

    // If we're truncating, display the first, (second if maxToDisplay >= 3) and last authors
    // If the max is < 2, the truncation toggle will not appear
    const authorsToDisplay =
      hasMoreThanMax && isCollapsed && maxToDisplay >= 2
        ? authorList.take(countToDisplayWithToggle - 1).push(authorList.last())
        : maxToDisplay < 2
          ? authorList.take(maxToDisplay)
          : authorList;

    const numberOfHiddenAuthors = authorList.size - countToDisplayWithToggle;
    const authorListToggleAriaProps = {
      'aria-expanded': !isCollapsed,
      'aria-label': getString(_ => _.moreAuthors.expandAriaLabel, numberOfHiddenAuthors),
    };

    // If the list is collapsed we display the toggle between the second and last author, but
    // if the full list is shown we display the toggle after the last author. To make this easier
    // to read I cache the resulting output in a local variable, making the inline conditions
    // associated with it's display (hopefully) easier to read
    const authorListToggle =
      hasMoreThanMax && maxToDisplay >= countToDisplayWithToggle ? (
        <AuthorListToggle
          onClick={e => this.handleAuthorListOnClick(e)}
          isCollapsed={isCollapsed}
          amount={numberOfHiddenAuthors}
          onWhiteBackground={onWhiteBackground}
          ariaProps={authorListToggleAriaProps}
          isTogglePersonalized={this.areHiddenAuthorsPersonalized(
            authorList,
            countToDisplayWithToggle
          )}
        />
      ) : null;

    const maxAuthorIdx = authorsToDisplay.size - 1;

    return (
      <AnalyticsContextHelper onUpdate={this.setAnalyticsCallbacks}>
        <span ref={this.#authorList} className="cl-paper-authors">
          {authorsToDisplay.map((author, i) => (
            <span
              key={`${author.alias.ids.join('-')}-${i}`}
              {...(heapProps
                ? addHeapProps({ ...heapProps, 'author-id': author.alias.ids.first() })
                : heapAuthorListItem({ 'author-id': author.alias.ids.first() }))}
              data-test-id={testId}
              ref={
                i === COUNT_TO_DISPLAY_WITH_TOGGLE && !isCollapsed
                  ? this.#firstNewAuthor
                  : undefined
              }>
              {authorListToggle && isCollapsed && i === maxAuthorIdx && (
                <span>{authorListToggle} </span>
              )}
              {shouldLinkToAHP && authorAliasHasAuthorHomePage(author.alias) ? (
                this.renderAuthorLink(author)
              ) : (
                <span
                  className={classnames('cl-paper-authors__author-box', 'notranslate', {
                    'cl-paper-authors__author-box__with-background': onWhiteBackground,
                    'cl-paper-authors__author__expanded': !isCollapsed,
                  })}>
                  <HighlightedField field={author.highlightedField} />
                </span>
              )}
            </span>
          ))}
          {authorListToggle && !isCollapsed && <span> {authorListToggle}</span>}
        </span>
      </AnalyticsContextHelper>
    );
  }
}

const AuthorLink = ({
  author,
  onClick,
  shouldOpenInNewTab,
  children,
  onWhiteBackground,
  isCollapsed,
}: React.PropsWithChildren<{
  author: HighlightedAuthorRecord;
  onClick: (id: string) => void;
  shouldOpenInNewTab?: boolean;
  onWhiteBackground: boolean;
  isCollapsed: boolean;
}>): ReactNodeish => (
  <Link
    to="AUTHOR_PROFILE"
    params={{ authorId: author.alias.ids.first(), slug: author.alias.slug }}
    className={classnames(AUTHOR_BOX_CLASS_NAME, 'notranslate', {
      'cl-paper-authors__author-box__with-background': onWhiteBackground,
      'cl-paper-authors__author__expanded': !isCollapsed,
    })}
    onClick={() => onClick(author.alias.ids.first())}
    shouldOpenInNewTab={shouldOpenInNewTab}>
    {children}
  </Link>
);

AuthorLink.defaultProps = {
  onClick: () => {},
};

const AuthorListToggle = ({
  amount,
  isCollapsed,
  onClick,
  onWhiteBackground,
  ariaProps,
  isTogglePersonalized,
}: {
  amount: number;
  isCollapsed: boolean;
  onClick: (e: React.SyntheticEvent) => void;
  onWhiteBackground?: boolean;
  ariaProps: Record<string, unknown>;
  isTogglePersonalized: boolean;
}): ReactNodeish => (
  <CLButton
    icon={authorToggleIcon({ isTogglePersonalized, isCollapsed })}
    onClick={onClick}
    testId={isCollapsed ? 'author-list-expand' : 'author-list-collapse'}
    label={
      isCollapsed
        ? getPluralizedString(_ => _.paper.authorToggle.author, amount)
        : getString(_ => _.paper.authorToggle.less)
    }
    ariaProps={ariaProps}
    className={classnames({
      'cl-paper-authors__toggle': TOGGLE_CLASS_NAME,
      'cl-paper-authors__toggle__with-background': onWhiteBackground,
      'cl-paper-authors__toggle__expanded': !isCollapsed,
      'cl-paper-authors__author-card-popover-experiment__personalized-toggle':
        isTogglePersonalized && isCollapsed,
    })}
  />
);

function authorToggleIcon({
  isTogglePersonalized,
  isCollapsed,
}: {
  isTogglePersonalized: boolean;
  isCollapsed: boolean;
}): ReactNodeish | undefined {
  if (isTogglePersonalized && isCollapsed) {
    return <Icon icon="personalization-small" width="12" height="12" />;
  } else if (!isCollapsed) {
    return <Icon icon="square-minus" width="10" height="10" />;
  }
}

/* eslint-enable react/no-multi-comp */

// TODO(#21359): Split this into a separate file
const rawAuthor: AuthorAliasFromJS = {
  name: 'John Doe',
  slug: 'John-Doe',
  ids: ['1337'],
  optIsClaimed: false,
};

const author1 = HighlightedAuthorRecordFactory({
  alias: getAuthorAliasFromJS(rawAuthor),
  highlightedField: getHighlightedFieldFromJS({
    text: 'John Doe',
    fragments: [],
  }),
});

const PAPER_AUTHORS = MockPaper({
  authors: Immutable.List([author1, author1, author1, author1]),
});

const PAPER_LITE_AUTHORS = MockPaperLiteFromJs({
  authors: [rawAuthor, rawAuthor, rawAuthor, rawAuthor],
});

export const exampleConfig: ExampleConfig = {
  getComponent: () => CLPaperAuthors,
  fields: [
    {
      name: 'shouldLinkToAHP',
      desc: 'Should author link to AHP',
      value: {
        type: 'boolean',
        default: true,
      },
    },
    {
      name: 'maxToDisplay',
      desc: 'Max authors to display',
      value: {
        type: 'number',
        default: 6,
      },
    },
  ],

  examples: [
    {
      title: 'Paper authors',
      desc: 'Author list from a Paper',
      props: {
        paper: PAPER_AUTHORS,
      },
      render: comp => (
        <div style={{ width: '300px', padding: '16px', backgroundColor: '#f0f0f0' }}>{comp}</div>
      ),
    },
    {
      title: 'PaperLite authors',
      desc: 'Author list from a PaperLite',
      props: {
        paper: PAPER_LITE_AUTHORS,
      },
      render: comp => (
        <div style={{ width: '300px', padding: '16px', backgroundColor: '#f0f0f0' }}>{comp}</div>
      ),
    },
    {
      title: 'List of authors',
      desc: 'Author list from just a list of authors with link to PDP',
      props: {
        authors: Immutable.List([author1, author1, author1, author1]),
      },
      render: comp => (
        <div style={{ width: '300px', padding: '16px', backgroundColor: '#f0f0f0' }}>{comp}</div>
      ),
    },
    {
      title: 'Condensed Paper authors',
      desc: 'Paper authors with condensed toggle',
      props: {
        paper: PAPER_AUTHORS,
        maxToDisplay: 3,
      },
      render: comp => (
        <div style={{ width: '300px', padding: '16px', backgroundColor: '#f0f0f0' }}>{comp}</div>
      ),
    },
  ],

  events: {},
};
