import { getString } from '@/content/i18n';
import { ReactNodeish } from '@/utils/types';
import ErrorMessageBlock from '@/components/shared/message/ErrorMessageBlock';
import Link from '@/router/Link';

import React from 'react';

export default class NotFoundPage extends React.Component {
  render(): ReactNodeish {
    return (
      <ErrorMessageBlock
        image="404"
        status="404"
        altText={getString(_ => _.pageNotFound.imageAltText)}>
        <h1 className="error-message__main-text">{getString(_ => _.pageNotFound.mainText)}</h1>
        <p className="error-message__subtext">{getString(_ => _.pageNotFound.subText)}</p>
        <p className="error-message__code">{getString(_ => _.pageNotFound.codeText)}</p>
        <Link to="HOME" className="button button--primary error-message__button">
          <>{getString(_ => _.pageNotFound.backButtonLabel)}</>
        </Link>
      </ErrorMessageBlock>
    );
  }
}
