import { hasValue, Nullable } from '@/utils/types';
import { ModalAction, ModalIdValue } from '@/constants/Modal';

import type { DispatchPayload } from '@/utils/S2Dispatcher';

export type ShowModalAction = DispatchPayload<{
  actionType: typeof ModalAction.SHOW_MODAL;
  id: ModalIdValue;
  data: Nullable<object>;
  key: Nullable<string>;
  location: Nullable<string>;
  subLocation: Nullable<string>;
}>;

export type HideModalAction = DispatchPayload<{
  actionType: typeof ModalAction.HIDE_MODAL;
}>;

/**
 * Action creator for showing a modal.
 *
 * @param id {string} the id of the modal to show.
 * @param data {object} content to be displayed by the modal if the modal is not self-contained, e.g.,
 *                      Login, Feedback, etc. are self-contained.
 * @param key {string} The subtype of id, e.g., INFLUENTIAL_MODAL can have 'influenced' and 'influencedBy'.
 * @param location {string} the location the modal was opened from (location types are in LoginMethod.js)
 * @param subLocation {string} the sublocation the modal was opened from
 *        (Ex. location = alert-button, subLocation = watch-topic, the alert button prompted the modal but it was the watch-topic alert button)
 * @returns {object} the action to be dispatched
 */
export function showModal({
  id,
  data,
  key,
  location,
  subLocation,
}: {
  id: ModalIdValue;
  data?: Nullable<object>;
  key?: Nullable<string>;
  location?: Nullable<string>;
  subLocation?: Nullable<string>;
}): ShowModalAction {
  return {
    id,
    data: hasValue(data) ? data : null,
    key: hasValue(key) ? key : null,
    actionType: ModalAction.SHOW_MODAL,
    location: hasValue(location) ? location : null,
    subLocation: hasValue(subLocation) ? subLocation : null,
  };
}

/**
 * Action creator for hiding the currently active modal.
 *
 * @returns {object} the action to be dispatched
 */
export function hideModal(): HideModalAction {
  return { actionType: ModalAction.HIDE_MODAL };
}
