import { isString, Nullable, ReactNodeish } from '@/utils/types';
import Link from '@/router/Link';
import ProfilePicture, { Shape } from '@/components/shared/user/ProfilePicture';

import React from 'react';

import type { AuthorLiteRecord } from '@/models/author/AuthorLite';
import type { AuthorProfileRecord } from '@/models/author/AuthorProfile';
import type { AuthorRecord } from '@/models/author/Author';
import type { ProfilePictureRecord } from '@/models/user/ProfilePicture';

type PropsWithAuthor = {
  author: Nullable<AuthorRecord>;
};

type PropsWithAuthorLite = {
  authorLite: Nullable<AuthorLiteRecord>;
};

type PropsWithAuthorProfile = {
  authorProfile: Nullable<AuthorProfileRecord>;
};

type PropsWithProfilePicture = {
  authorId?: Nullable<string>;
  slug?: Nullable<string>;
  profilePicture: Nullable<ProfilePictureRecord>;
};

type Props = {
  altText?: Nullable<string>;
  shouldLinkToProfile?: boolean;
  heightPx?: Nullable<number>;
  widthPx?: Nullable<number>;
  sizePx?: Nullable<number>;
  shape: Shape;
  fallback?: Nullable<ReactNodeish | boolean>;
} & (PropsWithAuthor | PropsWithAuthorLite | PropsWithAuthorProfile | PropsWithProfilePicture);

export { Shape } from '../user/ProfilePicture';

export default function AuthorProfilePicture(props: Props): ReactNodeish {
  const { shouldLinkToProfile, heightPx, widthPx, sizePx, shape, fallback } = props;

  // Props needed to render the profile picture
  let altText: string = 'author';
  let profilePicture: Nullable<ProfilePictureRecord> = null;
  let propsForLink: Nullable<{
    authorId: string;
    slug: string;
  }> = null;

  // Pull props, depending on which props are passed in
  if ('author' in props && props.author) {
    const { author } = props;
    if (author.name) {
      altText = author.name;
    }
    profilePicture = author.profilePicture;
    propsForLink =
      shouldLinkToProfile && author.id && author.slug
        ? { authorId: author.id, slug: author.slug }
        : null;
  } else if ('authorLite' in props && props.authorLite) {
    const { authorLite } = props;
    altText = authorLite.name;
    profilePicture = authorLite.profilePicture || null;
    propsForLink = shouldLinkToProfile
      ? { authorId: authorLite.id.toString(), slug: authorLite.slug }
      : null;
  } else if ('authorProfile' in props && props.authorProfile) {
    const { authorProfile } = props;
    if (authorProfile.fullName) {
      altText = authorProfile.fullName;
    }
    profilePicture = authorProfile.profilePicture || null;
    propsForLink = shouldLinkToProfile ? { authorId: authorProfile.id.toString(), slug: '' } : null;
  } else if ('profilePicture' in props && props.profilePicture) {
    const { profilePicture: profilePictureProp, authorId, slug } = props;
    if (isString(props.altText)) {
      altText = props.altText;
    }
    profilePicture = profilePictureProp;
    propsForLink = shouldLinkToProfile && authorId && slug ? { authorId, slug } : null;
  }

  // Render image, possibly wrapped in a link
  const imgElem = (
    <ProfilePicture
      altText={altText}
      profilePicture={profilePicture}
      heightPx={heightPx}
      widthPx={widthPx}
      sizePx={sizePx}
      shape={shape}
      fallback={fallback}
    />
  );
  if (propsForLink) {
    return (
      <Link to="AUTHOR_PROFILE" params={propsForLink} shouldUnderline={false}>
        {imgElem}
      </Link>
    );
  }
  return imgElem;
}

AuthorProfilePicture.defaultProps = {
  shape: Shape.Circle,
};
