import { cleanAndStringifyQuery } from './routerUtils';

import logger from '@/logger';

import invariant from 'invariant';
import pathToRegexp from 'path-to-regexp';

import type { DEPRECATED__FlowOptional, Nullable } from '@/utils/types';

export type PathParams = { [key: string]: Nullable<string | number> };
export type RouteName = keyof typeof Routes;
export type RoutePath = (typeof Routes)[keyof typeof Routes];

// This logic was added so we could treat Links differently for proxied routes
// so that the browser back button works
//  related issue: #29512
export const PROXIED_ROUTE_NAMES: RouteName[] = [
  'ABOUT_PAGE',
  'ABOUT_ROOT',
  'AUTHOR_LANDING',
  'FAQ_PAGE',
  'FAQ_ROOT',
  'PRODUCT_ROOT',
  'PRODUCT_PAGE',
  'RESEARCH_PAGE',
  'RESEARCH_ROOT',
  'RESOURCES_PAGE',
  'RESOURCES_ROOT',
];

/**
 * NOTE: The order of these routes matters. They are in the preferred order for
 *       route match checking. See getRouteNameForPath() below for details.
 */
const Routes = {
  HOME: '/',
  SEARCH: '/search',

  PAPER_LANDING: '/paper',
  PAPER_DETAIL: '/paper/:slug/:paperId',
  PAPER_DETAIL_BY_ID: '/paper/:paperId',
  PAPER_DETAIL_BY_CORPUS_ID: '/p/:corpusId',
  PAPER_DETAIL_PDF_REDIRECT: '/paper/:paperId/pdf',
  PAPER_DETAIL_FIGURE: '/paper/:slug/:paperId/figure/:figureIndex',
  PAPER_DETAIL_ABSTRACT: '/paper/:slug/:paperId',
  PAPER_DETAIL_CITED_BY: '/paper/:slug/:paperId/cited-by',
  PAPER_DETAIL_REFERENCES: '/paper/:slug/:paperId/references',

  READER: '/reader/:paperId',
  // NOTE: This is the old path for the reader, here to redirect to the new one
  READER_LEGACY: '/paper/:paperId/pdf-data/:pdfSha',

  AUTHOR_LANDING: '/author',
  AUTHOR_PROFILE_BY_CLAIMED: '/me/author',
  AUTHOR_CLAIM: '/author/:slug/:authorId/claim',
  AUTHOR_CLAIM_COMPLETE: '/author/:slug/:authorId/claim-complete',
  AUTHOR_PROFILE_BY_ID: '/author/:authorId(\\d+)',
  AUTHOR_PROFILE: '/author/:slug/:authorId(\\d+)',
  AUTHOR_PROFILE_PAPERS: '/author/:slug/:authorId(\\d+)',
  AUTHOR_PROFILE_CITING_AUTHORS: '/author/:slug/:authorId(\\d+)/citing-authors',
  AUTHOR_PROFILE_REFERENCED_AUTHORS: '/author/:slug/:authorId(\\d+)/referenced-authors',
  AUTHOR_PROFILE_CO_AUTHORS: '/author/:slug/:authorId(\\d+)/co-authors',

  ADMIN: '/admin',
  ADMIN_EXPERIMENTS: '/admin/experiments',
  ADMIN_EXPERIMENT_AUDIT: '/admin/experiments/audit/:key',
  ADMIN_ENROLLMENTS: '/admin/enrollments',
  ADMIN_FEATURE_FLAGS: '/admin/features',
  ADMIN_LOGIN_AS: '/admin/login-as',
  ADMIN_MANAGE_ROLES: '/admin/roles',
  ADMIN_MANAGE_USERS: '/admin/users',

  MODERATION: '/moderation',
  AUTHOR_CLAIM_MODERATION: '/moderation/author/claim/status/:status',

  AUTHOR_CORRECTIONS_TOOL: '/moderation/author/corrections',
  AUTHOR_CORRECTIONS_TOOL_AUTHOR_INPUT: '/moderation/author/corrections/author-id',
  AUTHOR_CORRECTIONS_TOOL_CONTACT: '/moderation/author/corrections/author-contact',
  AUTHOR_CORRECTIONS_TOOL_ADD_PAPERS: '/moderation/author/corrections/add-papers',
  AUTHOR_CORRECTIONS_TOOL_REMOVE_PAPERS: '/moderation/author/corrections/remove-papers',
  AUTHOR_CORRECTIONS_TOOL_STATUS: '/moderation/author/corrections/status',

  ENTITY_LANDING: '/topic',
  ENTITY_BY_ID: '/topic/:entityId',
  ENTITY: '/topic/:slug/:entityId',

  ALERTS_UNSUBSCRIBE_ALL: '/alerts/unsubscribe-all/:unsubscribeAllToken',

  LIBRARY_WITH_FOLDERS: '/me/library',
  LIBRARY_FOLDER: '/me/library/folder/:libraryFolderId',
  LIBRARY_ALL_ENTRIES: '/me/library/all',
  LIBRARY_UNSORTED_ENTRIES: '/me/library/unsorted',
  SHARED_LIBRARY_FOLDER: '/shared/library/folder/:libraryFolderId',
  UNAUTHORIZED_FOLDER: '/folder/unauthorized',

  RESEARCH_HOMEPAGE: '/me/research',
  RECOMMENDATIONS: '/me/recommendations',

  ACCOUNT: '/me/account',
  ACCOUNT_AUTHOR_CLAIM_PENDING: '/me/account/author-claim-pending',
  ACCOUNT_AUTHOR_UNCLAIM_SUCCESS: '/me/account/author-unclaimed/:authorId',
  ACCOUNT_AUTHOR_CONTACT: '/me/account/author-contact',
  ACCOUNT_REMOVE_PAPERS: '/me/account/remove-papers',
  ACCOUNT_ADD_PAPERS: '/me/account/add-papers',
  ACCOUNT_CORRECTIONS: '/me/account/corrections',
  ACCOUNT_CONTACT: '/me/account/email',
  ACCOUNT_MANAGE: '/me/account/manage',
  ACCOUNT_ALERTS: '/me/account/alerts',
  ADD_ENROLLMENT_REDIRECT: '/me/enroll',
  REMOVE_ENROLLMENT_REDIRECT: '/me/withdraw',

  USER_VERIFY_EMAIL: '/user/email/verify/:verificationToken',

  SIGN_IN: '/sign-in',
  SIGN_OUT: '/sign-out',

  CRAWLER: '/crawler',

  ABOUT_ROOT: '/about',
  ABOUT_PAGE: '/about/:page',
  API_GALLERY_ROOT: '/api-gallery',
  API_GALLERY_PAGE: '/api-gallery/:page',
  FAQ_ROOT: '/faq',
  FAQ_PAGE: '/faq/:page',
  PRODUCT_ROOT: '/product',
  PRODUCT_PAGE: '/product/:page',
  PRODUCT_API_LICENSE: '/product/api/license',
  RESEARCH_ROOT: '/research',
  RESEARCH_PAGE: '/research/:page',
  RESOURCES_ROOT: '/resources',
  RESOURCES_PAGE: '/resources/:page',

  CORD19_REDIRECT: '/cord19',

  DEBUG_AUTHORS: '/debug/authors',
  DEBUG_COMPONENT_LIBRARY: '/debug/components',
  DEBUG_COMPONENT_LIBRARY_COMPONENT: '/debug/component-library/gui/:component',
  DEBUG_COMPONENT_LIBRARY_PREVIEW:
    '/debug/component-library/preview/:component/example/:exampleIndex',
  DEBUG_PAPERS: '/debug/papers',
  DEBUG_PDF: '/debug/pdf/:paperId',
  DEBUG_SPRITES: '/debug/sprites',

  NOT_FOUND: '/404',

  BLANK: '/blank',

  VENUE: '/venue',

  ALL: '(.*)',
} as const;

export default Routes;

export function isRouteProxied(routeName: RouteName): boolean {
  return PROXIED_ROUTE_NAMES.includes(routeName);
}

export const RouteRegexes = Object.keys(Routes).reduce((result, routeName) => {
  result[routeName] = pathToRegexp(Routes[routeName]);
  return result;
}, {});

/**
 * Constructs a url from the provided route name OR path, params, and query.
 *
 * @param routeName {string} - name of the route
 * @param path {string} - the path regex, e.g., '/foo/:slug/:id'
 * @param params {object} - parameter object, e.g., { slug: 'abc', id: 123 }
 * @param query {object} - query object, e.g., { foo: 'bar' }
 * @return {string} - url
 */
export function makePath({
  routeName,
  path,
  params,
  query,
  hash,
}: {
  routeName?: RouteName;
  path?: Nullable<string>;
  params?: Nullable<PathParams>;
  query?: Nullable<object>;
  hash?: Nullable<string>;
}): string {
  let pathname: Nullable<string> = null;
  if (path !== undefined) {
    pathname = path;
  } else if (routeName) {
    pathname = Routes[routeName];
  }
  if (pathname !== null && pathname !== undefined) {
    const qs = cleanAndStringifyQuery(query || {});
    const hs = hash ? `#${hash}` : '';
    return pathToRegexp.compile(pathname)(params || {}) + (qs.length > 0 ? `?${qs}` : '') + hs;
  }
  logger.error(
    `Attempted to construct a path using a route name "${
      routeName || 'unknown'
    }" that does not exist in the Routes enum.`
  );
  return routeName || '';
}

// Determine route name for a path using the regex used by the router
export function getRouteNameForPath(path: DEPRECATED__FlowOptional<string>): Nullable<RouteName> {
  if (!path) {
    return null;
  }
  const allRouteNames = Object.keys(Routes) as RouteName[];
  const routeName = allRouteNames.find(routeName => RouteRegexes[routeName].test(path));
  if (!routeName || routeName === 'ALL') {
    return null;
  }
  return routeName;
}

// Parse a path for params
export function getParamsForRoute(routeName: RouteName, path?: string): string[] {
  if (!path) {
    return [];
  }
  const regex = RouteRegexes[routeName];
  invariant(regex, `Route name doesn't expect [routeName="${routeName}"]`);
  const result = regex.exec(path);
  if (!result) {
    return [];
  }
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [match, ...params] = result;
  return params;
}
