// This should be kept in sync with a corresponding scala-side model LibraryEntry.Status
import { Nullable, ValueOf } from '@/utils/types';

import invariant from 'invariant';

export type LibraryEntryStatus = ValueOf<typeof byKey>;

export const Created = 'Created';
export const Deleted = 'Deleted';
export const Unsupported = 'Unsupported';

const byKey = {
  Created,
  Deleted,
  Unsupported,
} as const;

export const LibraryEntryStatusMap = Object.freeze(byKey);

export function getOptLibraryEntryStatus(
  optStatus: Nullable<string>
): Nullable<LibraryEntryStatus> {
  if (optStatus && optStatus in byKey) {
    return byKey[optStatus];
  }
  return null;
}

export function getLibraryEntryStatus(optStatus: Nullable<string>): LibraryEntryStatus {
  const expStatus = getOptLibraryEntryStatus(optStatus);
  invariant(expStatus, `"${optStatus || ''}" is not a valid LibraryEntryStatus`);
  return expStatus;
}
