import { makePath } from '@/router/Routes';

import Immutable from 'immutable';

import type { DEPRECATED__FlowOptional, Nullable } from '@/utils/types';

export type AuthorAliasFromJS = {
  name: string;
  slug: string;
  ids?: DEPRECATED__FlowOptional<string[]>;
  optIsClaimed?: DEPRECATED__FlowOptional<boolean>;
};

type Props = {
  name: string;
  slug: string;
  ids: Immutable.List<string>;
  optIsClaimed: Nullable<boolean>;
};

const defaultProps: Props = {
  name: '',
  slug: '',
  ids: Immutable.List(),
  optIsClaimed: null,
};

export const AuthorAliasRecordFactory = Immutable.Record<Props>(defaultProps);

export type AuthorAliasRecord = Immutable.RecordOf<Props>;

export function getAuthorAliasFromJS(args: AuthorAliasFromJS): AuthorAliasRecord {
  const { ids, ...props } = args;
  return AuthorAliasRecordFactory({
    ...props,
    ids: Immutable.List(ids || []),
  });
}

/**
 * Returns whether the author has an author home page which should be linked to.
 * @returns {boolean}
 */
export function authorAliasHasAuthorHomePage(authorAlias: AuthorAliasRecord): boolean {
  // TODO(codeviking): The current spec is only to link if the author has a single DBLP id.
  // We don't have this information, so simply check for a single id and link to them in that
  // situation.
  return authorAlias.ids.size === 1;
}

/**
 * Returns the JSON-LD (JSON Linked Data) representation of the author.
 */
export function getAuthorAliasLinkedData(authorAlias: AuthorAliasRecord) {
  // https://schema.org/Person
  const data: {
    '@type': 'Person';
    name: typeof authorAlias.name;
    mainEntityOfPage: any;
  } = {
    '@type': 'Person',
    name: authorAlias.name,
    mainEntityOfPage: null,
  };

  if (authorAliasHasAuthorHomePage(authorAlias)) {
    data.mainEntityOfPage = makePath({
      routeName: 'AUTHOR_PROFILE',
      params: { authorId: getAuthorAliasCanonicalId(authorAlias), slug: authorAlias.slug || '' },
    });
  }
  return data;
}

/**
 * Returns the author's ID, or null if it does not have one.
 */
export function getAuthorAliasCanonicalId(authorAlias: AuthorAliasRecord): string {
  const ids =
    typeof authorAlias.ids === 'object' ? Immutable.List(authorAlias.ids) : authorAlias.ids;
  return ids.first();
}

/**
 * Returns whether the author has been claimed (or null if unknown, because server didn't check)
 */
export function isAuthorAliasClaimed(authorAlias: AuthorAliasRecord): Nullable<boolean> {
  return typeof authorAlias.optIsClaimed === 'boolean' ? authorAlias.optIsClaimed : null;
}
