import { isLocalHostname, isProductionHostname } from '@/utils/env';

import type { Nullable } from '@/utils/types';

/**
 * Information about the browser environment.
 *
 * @param {object} [props]
 * @param {boolean} [props.isMobile=false] flag indicating that the client on a mobile device
 * @param {string} [props.hostname] the url hostname, does not include port
 */
export default class EnvInfo {
  hostname: string;
  isMobile: boolean;
  isTrackingAllowed: boolean;

  constructor({
    hostname,
    isMobile,
    isTrackingAllowed,
  }: {
    hostname: string;
    isMobile?: Nullable<boolean>;
    isTrackingAllowed?: Nullable<boolean>;
  }) {
    this.hostname = hostname;
    this.isMobile = !!isMobile;
    this.isTrackingAllowed = !!isTrackingAllowed;
  }

  isProduction(): boolean {
    return isProductionHostname(this.hostname);
  }

  isLocal(): boolean {
    return isLocalHostname(this.hostname);
  }
}
