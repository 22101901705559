import { getHeapPropsFromEventData, runWithHeap } from './Heap';
import AnalyticsEvent from './models/AnalyticsEvent';
import Pageview from './events/generic/Pageview';

import { getLayoverMetrics } from '@/utils/layover/LayoverMetrics';
import { isBrowser, isTrackingAllowedOnBrowser } from '@/utils/env';
import BrowserUtil from '@/browser';
import logger from '@/logger';

/**
 * Post several analytics events.
 *
 * @param {AnalyticsEvent[]} data - An array of the events to be sent. All objects in the array
 * should be instances of AnalyticsEvent.
 *
 * @returns {undefined} the ApiRequest instance
 */
export default function trackAnalyticsEvents(events: AnalyticsEvent[]): void {
  if (!isBrowser() || !isTrackingAllowedOnBrowser()) {
    return;
  }
  if ('gtag' in window) {
    // As of Firefox 67 (2019-09) Google Analytics (as well as HubSpot) is being blocked from
    // sending any analytics back to us. At the time Firefox represents ~7% of our traffic.
    // https://github.com/allenai/scholar/issues/17019
    sendGaEvents(events);
  }
  sendHeapEvents(events);
  sendLayoverEvents(events);
}

function sendHeapEvents(events: AnalyticsEvent[]): void {
  // We don't want to send duplicate pageviews to Heap, so exclude them
  events
    .filter(e => e.eventType !== 'pageview')
    .forEach(function (e: AnalyticsEvent) {
      const target = e.eventData && e.eventData.target ? e.eventData.target : 'general';
      const eventName = `${target as string}.${e.eventType}`;

      // Stringify viewport and screen size since heap keys and values must be a number or string
      const overrides = {
        eventData: undefined,
        screenSize: JSON.stringify(e.screenSize),
        viewportSize: JSON.stringify(e.viewportSize),
      };

      // scholar#16545
      // We're seeing a race condition where on page-load impression events are getting mis-attributed
      // to which path they occurred on.
      let delayMs = 0;
      if (e.eventType === 'show') {
        delayMs = 50;
      }
      setTimeout(() => {
        // This was moved into the setTimeout() to fix #17922, where some heap events were not logged, or were causing errors
        runWithHeap(heap => {
          heap.track(eventName, getHeapPropsFromEventData({ ...e, ...e.eventData, ...overrides }));
        });
      }, delayMs);
    });
}

function sendGaEvents(events: AnalyticsEvent[]): void {
  events.forEach(appEvent => {
    if (appEvent instanceof AnalyticsEvent) {
      const gaData = appEvent.toGoogleAnalyticsData();
      if (gaData) {
        if (appEvent instanceof Pageview) {
          window.gtag('config', BrowserUtil.getGoogleAnalyticsId(), gaData);
        } else {
          window.gtag('event', appEvent.eventType, gaData);
        }
      } else {
        logger.warn(
          'toGoogleAnalyticsData() not implemented for ' + appEvent.eventType + ' event.'
        );
      }
    } else {
      throw new Error('Attempted to send an event that was not an instance of AnalyticsEvent.');
    }
  });
}

// Send events (to Datadog) via Layover as counters
function sendLayoverEvents(events: AnalyticsEvent[]): void {
  events
    .filter(event => event instanceof AnalyticsEvent)
    .forEach(event => {
      const target = event.eventData && event.eventData.target ? event.eventData.target : 'general';
      const eventName = `${target as string}.${event.eventType}`;
      getLayoverMetrics().sendIncrement(eventName);
    });
}
