import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';
import Routes from '@/router/Routes';

import React from 'react';

export default class PaperLandingRoute extends React.Component {
  static getPageTitle = () => getString(_ => _.paper.landingPage.title);

  static getCanonicalUrl = () => Routes.PAPER_LANDING;

  render() {
    return (
      <AsyncLoadedPage
        footer={false}
        load={{
          importer: () =>
            import(/* webpackChunkName: "shared-PaperLandingPage" */ './PaperLandingPage'),
          chunkName: 'shared-PaperLandingPage',
          moduleId: require.resolveWeak('./PaperLandingPage'),
        }}
      />
    );
  }
}
