export type KeyCodeKey = keyof typeof KeyCode;
export type KeyCodeValue = (typeof KeyCode)[keyof typeof KeyCode];

export const KEY_CODE_TAB = 9;
export const KEY_CODE_ENTER = 13;
export const KEY_CODE_ESC = 27;
export const KEY_CODE_SPACE = 32;
export const KEY_CODE_LEFT = 37;
export const KEY_CODE_UP = 38;
export const KEY_CODE_RIGHT = 39;
export const KEY_CODE_DOWN = 40;
export const KEY_CODE_O = 79;

export const KEY_ENTER = 'Enter';

const KeyCode = {
  DOWN: KEY_CODE_DOWN,
  ENTER: KEY_CODE_ENTER,
  ESC: KEY_CODE_ESC,
  LEFT: KEY_CODE_LEFT,
  RIGHT: KEY_CODE_RIGHT,
  SPACE: KEY_CODE_SPACE,
  TAB: KEY_CODE_TAB,
  UP: KEY_CODE_UP,
  O: KEY_CODE_O,
} as const;

export default Object.freeze(KeyCode);

// TODO: TS isn't quite sure what to make of this type
export const byValue = Object.freeze(
  Object.keys(KeyCode).reduce((memo, key) => {
    const value = KeyCode[key];
    memo[value] = key;
    return memo;
  }, {})
);
