import {
  AUTHOR_LITE_FRAGMENT,
  AUTHOR_LITE_FRAGMENT_NAME,
  AuthorLiteFromGraphQL,
  AuthorLiteRecord,
  AuthorLiteRecordFactory,
  getAuthorLiteFromGraphQL,
} from './AuthorLite';

import { gql } from 'graphql-tag';
import Immutable from 'immutable';

export type CoAuthorshipFromGraphQL = {
  paperCount: number;
  coAuthor: AuthorLiteFromGraphQL;
};

type Props = {
  paperCount: number;
  coAuthor: AuthorLiteRecord;
};

const defaultProps: Props = {
  paperCount: 0,
  coAuthor: AuthorLiteRecordFactory(),
};

export const CoAuthorshipRecordFactory = Immutable.Record<Props>(defaultProps);

export type CoAuthorshipRecord = Immutable.RecordOf<Props>;

export function getCoAuthorshipFromGraphQL(args: CoAuthorshipFromGraphQL): CoAuthorshipRecord {
  const { coAuthor } = args;
  return CoAuthorshipRecordFactory({
    ...args,
    coAuthor: getAuthorLiteFromGraphQL(coAuthor),
  });
}

export const CO_AUTHORSHIP_FRAGMENT_NAME = 'CoAuthorshipFragment';

export const CO_AUTHORSHIP_FRAGMENT = gql`
  ${AUTHOR_LITE_FRAGMENT}
  fragment ${CO_AUTHORSHIP_FRAGMENT_NAME} on CoAuthorship {
    paperCount
    coAuthor {
      ...${AUTHOR_LITE_FRAGMENT_NAME}
    }
  }
`;
