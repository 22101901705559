import type { Nullable, TODO } from '@/utils/types';

/**
 * The goal of this file is to enforce that the values logged in Heap are
 * kept consistent. Historically, Heap events have been tracking using
 * CSS selectors of class names or data-test-id, which are not
 * stable. Through proper typing, we can limit gaps in our analytics due
 * to changes in the DOM.
 *
 * How to use this file:
 *
 * 1) Add a constant for the data-heap-id value
 * 2) Create a new type which uses that id, along with other attributes
 * 3) Add that new type to the HeapTrackingData type
 * 4) Use addHeapTracking in React component
 */
export type HeapTrackingData =
  | AlternatePaperLinksDropdownTrackingData
  | CitationBoxTrackingData
  | CitationTitleTrackingData
  | CitationAuthorTrackingData
  | CookieBannerAcceptTrackingData
  | CookieBannerOptOutTrackingData
  | EntityClickData
  | FeedAnnotateLessButtonData
  | FeedAnnotateMoreButtonData
  | FeedLinkAnonAnnotatePaper
  | FeedLinkOpenMenu
  | LibrarySavePaper
  | MatchedContentPaginate
  | PDPScorecardTrackingData
  | PDPCitationScorecardTrackingData
  | PaperLinkTrackingData
  | PaperNavTrackingData
  | PDPActionsSaveData
  | PDPActionsAlertData
  | PDPActionsCiteData
  | SupplementYourResearchPaperLinkData;

export type AlternatePaperLinksDropdownTrackingData = {
  id: typeof HEAP_ALTERNATE_PAPER_LINKS_DROPDOWN_TRACKING_DATA;
  'paper-id': string;
  'has-open-links': boolean;
  'has-open-nonpdf': boolean;
  'has-unpaywall': boolean;
  'num-alternates': number;
  'has-institutional': boolean;
};

export type CitationBoxTrackingData = {
  id: typeof HEAP_CITATION_BOX;
  'paper-id': Nullable<string>;
  type: TODO<'CitationBoxType'>;
  'citation-type': string;
};

export type CitationTitleTrackingData = {
  id: typeof HEAP_CITATION_TITLE;
  'paper-id': Nullable<string>;
  'citation-type': string;
  'has-intents': boolean;
};

export type CitationAuthorTrackingData = {
  id: typeof HEAP_CITATION_AUTHOR;
  'author-id': string;
};

export type CookieBannerAcceptTrackingData = {
  id: typeof HEAP_COOKIE_BANNER_ACCEPT;
};

export type CookieBannerOptOutTrackingData = {
  id: typeof HEAP_COOKIE_BANNER_OPT_OUT;
};

export type EntityClickData = {
  id: typeof HEAP_ENTITY_CLICK;
};

export type FeedAnnotateMoreButtonData = {
  id: typeof HEAP_FEED_ANNOTATE_MORE_BUTTON;
};

export type FeedAnnotateLessButtonData = {
  id: typeof HEAP_FEED_ANNOTATE_LESS_BUTTON;
};

export type FeedLinkAnonAnnotatePaper = {
  id: typeof HEAP_FEED_LINK_ANON_ANNOTATE_PAPER;
};

export type FeedLinkOpenMenu = {
  id: typeof HEAP_FEED_LINK_OPEN_MENU;
};

export type LibrarySavePaper = {
  id: typeof HEAP_LIBRARY_SAVE_PAPER;
};

export type MatchedContentPaginate = {
  id: typeof HEAP_MATCHED_CONTENT_PAGINATE;
  'page-number': number;
};

export type PDPScorecardTrackingData = {
  id: typeof HEAP_PDP_SCORECARD_ITEM;
  type: string;
};

export type PDPCitationScorecardTrackingData = {
  id: typeof HEAP_CITATION_SCORECARD_ITEM;
  key: string;
  'has-intents': boolean;
};

export type PaperNavTrackingData = {
  id: typeof HEAP_PAPER_NAV_TARGET;
  nav: string;
};

export type PaperLinkTrackingData = {
  id: typeof HEAP_PAPER_LINK_TARGET;
  'link-type': TODO<'PaperLinkTypeValue'>;
  'direct-pdf-link': boolean;
  'unpaywall-link': boolean;
  'primary-link': boolean;
  'paper-id': string;
  institutional: boolean;
  entitlement?: boolean;
  'entitlement-source'?: string;
};

export type PDPActionsSaveData = {
  id: typeof HEAP_PDP_ACTIONS_SAVE;
};

export type PDPActionsAlertData = {
  id: typeof HEAP_PDP_ACTIONS_ALERT;
};

export type PDPActionsCiteData = {
  id: typeof HEAP_PDP_ACTIONS_CITE;
};

export type SupplementYourResearchPaperLinkData = {
  id: typeof HEAP_SUPPLEMENT_YOUR_RESEARCH_PAPER_LINK;
  'paper-id': string;
};

export const HEAP_ATTRIBUTE_PREFIX = 'data-heap-';
export const HEAP_ID_ATTRIBUTE = `${HEAP_ATTRIBUTE_PREFIX}id`;

// Heap IDs
export const HEAP_ALTERNATE_PAPER_LINKS_DROPDOWN_TRACKING_DATA =
  'alternate_alternate_paper_links_dropdown';
export const HEAP_AUTHOR_LIST_ITEM = 'heap_author_list_item';
export const HEAP_CITATION_AUTHOR = 'citation_author';
export const HEAP_CITATION_BOX = 'citation_box';
export const HEAP_CITATION_FILTER_DROPDOWN = 'citation_filter_dropdown';
export const HEAP_CITATION_SCORECARD_ITEM = 'scorecard_citation_item';
export const HEAP_CITATION_TITLE = 'citation_title';
export const HEAP_COOKIE_BANNER_ACCEPT = 'cookie_banner_accept';
export const HEAP_COOKIE_BANNER_OPT_OUT = 'cookie_banner_opt_out';
export const HEAP_ENTITY_CLICK = 'entity_click';
export const HEAP_EXTERNAL_CONTENT_ITEM = 'external_content_item';
export const HEAP_FEATURED_CONTENT_PAGINATE = 'featured_content_paginate';
export const HEAP_FEEDBACK_ANSWER = 'feedback_answer';
export const HEAP_FEEDBACK_BUTTON = 'feedback_button';
export const HEAP_FEEDBACK_SUBMIT = 'feedback_submit';
export const HEAP_MATCHED_CONTENT_PAGINATE = 'matched_content_paginate';
export const HEAP_PAPER_LINK_TARGET = 'paper_link_target';
export const HEAP_PAPER_META_BOTTOM = 'paper_meta_bottom';
export const HEAP_PAPER_META_TOP = 'paper_meta_top';
export const HEAP_PAPER_NAV_TARGET = 'paper_nav_target';
export const HEAP_PDP_ACTIONS_ALERT = 'pdp_actions_alert';
export const HEAP_PDP_ACTIONS_CITE = 'pdp_actions_cite';
export const HEAP_PDP_ACTIONS_SAVE = 'pdp_actions_save';
export const HEAP_PDP_SCORECARD_ITEM = 'pdp_scorecard_item';
export const HEAP_SUPPLEMENT_YOUR_RESEARCH_PAPER_LINK = 'supplement_your_research_paper_link';
export const HEAP_TOPIC_PAPER_TITLE_LINK = 'topic_page_paper_title_link';

// TODO: (#20217) Feed Heap IDs should be all lowercase and using underscores, not hyphens
export const HEAP_FEED_ANNOTATE_LESS_BUTTON = 'feed_annotation_button_Less';
export const HEAP_FEED_ANNOTATE_MORE_BUTTON = 'feed_annotation_button_More';
export const HEAP_FEED_LINK_ANON_ANNOTATE_PAPER = 'feed-link-anon-annotate-paper';
export const HEAP_FEED_LINK_OPEN_MENU = 'feed-link-open-menu';
export const HEAP_FEED_PDP_ACTIONS_FEED = 'pdp_actions_feed';
export const HEAP_LIBRARY_SAVE_PAPER = 'save-paper-to-library';
