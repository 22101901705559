import { AuthorFromJS, AuthorRecord, AuthorRecordFactory, getAuthorFromJS } from './Author';
import { AuthorStatisticsRecord } from './AuthorStatistics';

import { getString } from '@/content/i18n';
import { ProfilePictureFromJS, ProfilePictureRecord } from '@/models/user/ProfilePicture';
import { SkipperExperimentFromJS } from '@/skipper/models/SkipperExperiment';
import SkipperExperiments from '@/skipper/models/SkipperExperiments';

import Immutable from 'immutable';

import type { DEPRECATED__FlowOptional, Nullable } from '@/utils/types';

export type AuthorDetailsFromJS = {
  author?: DEPRECATED__FlowOptional<AuthorFromJS>;
  isClaimedByAuthor?: DEPRECATED__FlowOptional<boolean>;
  isClaimedBySignedInUser?: DEPRECATED__FlowOptional<boolean>;
  isVerified?: DEPRECATED__FlowOptional<boolean>;
  hasPendingCorrections?: DEPRECATED__FlowOptional<boolean>;
  skipperExperiments?: SkipperExperimentFromJS[];
  totalPublications?: DEPRECATED__FlowOptional<number>;
  pronouns?: DEPRECATED__FlowOptional<string>;
  profilePicture: Nullable<ProfilePictureFromJS>;
};

type Props = {
  author: Nullable<AuthorRecord>;
  isClaimedByAuthor: Nullable<boolean>;
  isClaimedBySignedInUser: Nullable<boolean>;
  isVerified: Nullable<boolean>;
  hasPendingCorrections: Nullable<boolean>;
  skipperExperiments: SkipperExperiments;
  totalPublications: Nullable<number>;
  pronouns: Nullable<string>;
  profilePicture: Nullable<ProfilePictureRecord>;
};

const defaultProps: Props = {
  author: AuthorRecordFactory(),
  isClaimedByAuthor: null,
  isClaimedBySignedInUser: null,
  isVerified: null,
  hasPendingCorrections: null,
  skipperExperiments: SkipperExperiments.EMPTY,
  totalPublications: null,
  pronouns: null,
  profilePicture: null,
};

export const AuthorDetailsRecordFactory = Immutable.Record<Props>(defaultProps);

export type AuthorDetailsRecord = Immutable.RecordOf<Props>;

export function getAuthorDetailsFromJS(args: AuthorDetailsFromJS): AuthorDetailsRecord {
  const { author, skipperExperiments, profilePicture, ...props } = args;
  return AuthorDetailsRecordFactory({
    ...props,
    // TODO: Would we ever have an author details response without an author?
    author: author
      ? getAuthorFromJS({
          ...author,
          profilePicture,
        })
      : null,
    skipperExperiments: skipperExperiments
      ? SkipperExperiments.fromJS(skipperExperiments)
      : defaultProps.skipperExperiments,
  });
}

export function getAuthorPageMetaDescription(authorDetails: AuthorDetailsRecord): string {
  const statistics: AuthorStatisticsRecord | undefined = authorDetails?.author?.statistics;
  const influentialCount = statistics?.totalInfluentialCitationCount;
  const papersCount = authorDetails.totalPublications || 0;
  if (typeof influentialCount === 'number' && influentialCount > 0) {
    return getString(
      _ => _.metaDescription.authorHomePage.contentInfluential,
      authorDetails?.author?.name || '',
      influentialCount,
      papersCount
    );
  }
  return getString(
    _ => _.metaDescription.authorHomePage.content,
    authorDetails?.author?.name || '',
    papersCount
  );
}

export function getAuthorId(authorDetails: AuthorDetailsRecord): Nullable<number> {
  return authorDetails.author?.id ? parseInt(authorDetails.author.id) : null;
}
