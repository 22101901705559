import { Nullable } from '@/utils/types';

import IncrementalMurmurhash from 'imurmurhash';
import invariant from 'invariant';

import type { DynamicVariationConfig } from './experimentConfigUtils';
import type { VariationKey } from './Experiment';

// !!! Keep this file up to date with online/service/src/main/scala/org/allenai/s2/service/experiment/DecisionEngine.scala
const MAX_HASH_VALUE = Math.pow(2, 32);

export type AllocationConfig = {
  allocationSalt: string;
  trafficRatio: number;
} & (
  | // We require either the tid OR the userId depending on the allocation type of session or user
  {
      tid: string;
      userId?: Nullable<never>;
    }
  | {
      tid?: Nullable<never>;
      userId: string;
    }
);

export type ExposeToVariationConfig = {
  identifier: string; // Either the tid OR the userId depending on the allocation type of session or user
  exposureSalt: string;
  variations: DynamicVariationConfig[];
};

// Enables test injection of mock versions
let getHashValue = (token: string, salt: string): number => getHashValueImpl(token, salt);

/**
 * Makes a stable decision on if a given user should be included in a particular experiment.
 */
export function shouldAllocate(allocationConfig: AllocationConfig): boolean {
  const tid = allocationConfig.tid;
  const userId = allocationConfig.userId;
  const allocationSalt = allocationConfig.allocationSalt;

  invariant(
    (!tid && !!userId) || (!!tid && !userId),
    'Only a tid or a userId is allowed to determine allocation'
  );

  const token = tid ? tid : userId;

  if (!token) {
    // This should never happen because we have an invariant check beforehand but ts didn't like when there was no explicit check
    return false;
  }
  const ratio = getHashValue(token, allocationSalt);
  return ratio <= allocationConfig.trafficRatio;
}

/**
 * Returns a floating point value between 0 and 1.
 * Only exported for testing purposes, clients should use `shouldAllocate`
 */
export function getHashValueImpl(token: string, salt: string): number {
  return (
    IncrementalMurmurhash()
      .hash(token + salt)
      .result() / MAX_HASH_VALUE
  );
}

/**
 * Picks a variation from a list weighted according to the ratio of exposures given to each
 * variation. The ratios should sum to 1.0.
 *
 * The exposureSalt and identifier (either a tid for session based experiments or userId for user based experiments) are used
 * with the murmur3 algorithm to generate a hash value that is used to choose a variation from the list
 */
export function getRandomVariation(exposeToVariationConfig: ExposeToVariationConfig): VariationKey {
  const { exposureSalt, identifier, variations } = exposeToVariationConfig;

  // Validate ratios sum to 1.0
  const ratioSum = variations.reduce((sum, variation) => variation.ratio + sum, 0);
  invariant(ratioSum === 1.0, `Variation ratios must sum to 1.0, got ${ratioSum}`);

  const hashValue = getHashValueImpl(identifier, exposureSalt);

  let cumulativeRatio = 0;
  // iterate over the variations and see which window the random number falls into
  const variation = variations.find(variation => {
    invariant(variation.ratio >= 0, 'Variations cannot have a negative ratio');
    cumulativeRatio += variation.ratio;
    return hashValue <= cumulativeRatio;
  });

  // handle any potential weird edge case stemming from bad ratios by returning the last variant
  return (variation || variations[0]).key;
}

export function setGetHashValue__TESTONLY(getter: (...args: any[]) => number): void {
  getHashValue = getter;
}
