import React from 'react';

export default function IconTheorems(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 32 32">
      <g>
        <path d="M15.003,30.835c1.543,0.006,2.703-0.713,3.544-2.062h-7.055C12.334,30.105,13.475,30.829,15.003,30.835z" />
        <path
          d="M18.548,25.014c-0.167-0.001-0.336-0.001-0.503-0.001c-2.183,0-4.366,0-6.548,0.002c-0.579,0-0.932,0.272-0.999,0.758
          c-0.078,0.562,0.315,0.979,0.961,0.985c1.188,0.011,2.376,0.004,3.564,0.004c1.176,0,2.351,0.005,3.526-0.002
          c0.623-0.004,1.017-0.354,1.015-0.875C19.563,25.366,19.16,25.02,18.548,25.014z"
        />
        <path
          d="M25.351,8.794c-0.647-3.618-3.806-6.521-7.979-7.389c-0.771-0.163-1.558-0.24-2.343-0.24c-3.274,0-6.504,1.357-8.43,3.72
          c-2.59,3.175-2.675,6.578-0.885,10.039c0.551,1.064,1.386,2.024,2.147,3c1.12,1.434,1.906,1.95,1.924,3.722
          c0.008,0.82,0.641,1.326,1.588,1.328c0.521,0.001,1.043,0.002,1.564,0.002c0.694,0,1.388-0.001,2.082-0.001
          c0.695,0,1.389,0.001,2.083,0.001c0.521,0,1.043-0.001,1.563-0.002c0.966-0.002,1.58-0.508,1.599-1.355
          c0.029-1.479,0.54-1.827,1.463-3.061c0.541-0.726,1.112-1.437,1.67-2.152C25.185,14.091,25.84,11.546,25.351,8.794z"
        />
      </g>
    </symbol>
  );
}
