import Logger from './Logger';

// Create a cross-browser complaint "map" (an object without a prototype)
const loggers = Object.create(null);

export function getLogger(id: string): Logger {
  if (!loggers[id]) {
    loggers[id] = new Logger(id);
  }
  return loggers[id];
}
