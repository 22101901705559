import React from 'react';

export default function IconExpandArrow(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 24 24">
      <g
        id="Final-Design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round">
        <g id="Mobile-Entity-Page" transform="translate(-321.000000, -342.000000)" stroke="#546973">
          <g id="Metrics" transform="translate(21.000000, 324.000000)">
            <g>
              <g transform="translate(301.000000, 19.000000)">
                <path d="M6.375,11.625 L0.375,17.625" id="Stroke-353" />
                <polyline id="Stroke-354" points="4.125 17.625 0.375 17.625 0.375 13.875" />
                <path d="M11.625,6.375 L17.625,0.375" id="Stroke-355" />
                <polyline id="Stroke-356" points="13.875 0.375 17.625 0.375 17.625 4.125" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </symbol>
  );
}
