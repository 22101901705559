/* eslint-disable no-duplicate-imports */
import { renderRoutes } from 'react-router-config';
import React from 'react';

import type { Nullable } from '@/utils/types';
import type { RouteConfig } from 'react-router-config';

export const ChildRoutesContext = React.createContext<{
  route: Nullable<RouteConfig>;
}>({
  route: null,
});

export default function ChildRoutes(props: any) {
  const { route } = React.useContext(ChildRoutesContext);
  if (!route) {
    return null;
  }
  return renderRoutes(route.routes, props);
}
