import CLPaperActions from './CLPaperActions';
import CLPaperCard from './CLPaperCard';
import CLPaperRow from './CLPaperRow';
import MockPaper, { MockPaperLiteFromJs } from '../../../../../test/utils/MockPaper';

import { AuthorAliasFromJS, getAuthorAliasFromJS } from '@/models/author/AuthorAlias';
import { BadgeType, getPaperBadgeFromJS } from '@/models/PaperBadge';
import { ExampleConfig } from '@/components/library/ExampleUtils';
import { getHighlightedFieldFromJS } from '@/models/HighlightedField';
import { HeapProps } from '@/analytics/heapUtils';
import { HighlightedAuthorRecordFactory } from '@/models/HighlightedAuthor';
import { Nullable, ReactNodeish, TODO } from '@/utils/types';
import { PaperishRecord } from '@/models/Paper';
import { PaperLinkRecordFactory } from '@/models/PaperLink';
import IsDesktop from '@/components/util/env/IsDesktop';
import IsMobile from '@/components/util/env/IsMobile';
import PaperLinkType from '@/constants/PaperLinkType';

import Immutable from 'immutable';
import React from 'react';

export const CARD = 'card';
export const ROW = 'row';

type TODO__OptionalOverride = TODO<'OptionalOverride needs a type'>;
type PaperObjectContext = typeof CARD | typeof ROW;

type Props = {
  className?: Nullable<string> | ((meta: PaperObjectContext) => string);
  heapProps?: Nullable<HeapProps>;
  paper: PaperishRecord;
  title: Nullable<boolean> | ReactNodeish;
  meta?: Nullable<boolean> | ReactNodeish;
  abstract?: Nullable<boolean> | ReactNodeish | ((meta: PaperObjectContext) => ReactNodeish);
  controls?: Nullable<boolean> | ReactNodeish | ((meta: PaperObjectContext) => ReactNodeish);
  topMeta?: ReactNodeish;
  header?: ReactNodeish;
  footer?: ReactNodeish;
  hasDogEar?: boolean;
  isInteractive?: Nullable<boolean>;
};

export default class CLPaperObject extends React.PureComponent<Props> {
  static defaultProps = {
    title: true,
    meta: true,
    abstract: true,
    controls: true,
    isInteractive: true,
  };

  render(): ReactNodeish {
    const { title, meta, abstract, controls, isInteractive, className, ...otherProps } = this.props;

    return (
      <React.Fragment>
        <IsDesktop>
          {() => (
            <CLPaperRow
              title={getOptionalOverride(title, ROW)}
              meta={getOptionalOverride(meta, ROW)}
              abstract={getOptionalOverride(abstract, ROW)}
              controls={getOptionalOverride(controls, ROW)}
              isInteractive={isInteractive}
              className={getClassesOptionalOverride(className, ROW)}
              {...otherProps}
            />
          )}
        </IsDesktop>
        <IsMobile>
          {() => (
            <CLPaperCard
              title={getOptionalOverride(title, CARD)}
              meta={getOptionalOverride(meta, CARD)}
              abstract={getOptionalOverride(abstract, CARD)}
              controls={getOptionalOverride(controls, CARD)}
              isInteractive={getOptionalOverride(isInteractive, CARD)}
              className={getClassesOptionalOverride(className, CARD)}
              {...otherProps}
            />
          )}
        </IsMobile>
      </React.Fragment>
    );
  }
}

export function getOptionalOverride(
  override: TODO<TODO__OptionalOverride>,
  paperObjContext: PaperObjectContext
): Nullable<boolean> {
  if (typeof override === 'boolean') {
    return override;
  }

  if (typeof override === 'function') {
    return override(paperObjContext);
  }

  return override;
}

export function getClassesOptionalOverride(
  override: Nullable<string> | ((meta: PaperObjectContext) => string) | undefined,
  paperObjContext: PaperObjectContext
): Nullable<string> {
  if (typeof override === 'string') {
    return override;
  }

  if (typeof override === 'function') {
    return override(paperObjContext);
  }

  return null;
}

// TODO(#21359): Split this into a separate file
const rawAuthor: AuthorAliasFromJS = {
  name: 'John Doe',
  slug: 'John-Doe',
  ids: ['1337'],
  optIsClaimed: false,
};

const author1 = HighlightedAuthorRecordFactory({
  alias: getAuthorAliasFromJS(rawAuthor),
  highlightedField: getHighlightedFieldFromJS({
    text: 'John Doe',
    fragments: [],
  }),
});

const PAPER = MockPaper({
  id: '94e2260617274d17ef9ebf84b5081d5fc919cbab',
  authors: Immutable.List([author1, author1, author1, author1]),
  primaryPaperLink: PaperLinkRecordFactory({
    url: 'https://www.semanticscholar.org/paper/94e2260617274d17ef9ebf84b5081d5fc919cbab',
    linkType: PaperLinkType.S2,
    publisherName: 'Semantic Scholar',
  }),
  pubDate: getHighlightedFieldFromJS({ text: '5 May 2001' }),
  badges: Immutable.List([getPaperBadgeFromJS({ id: BadgeType.OPEN_ACCESS })]),
});

const PAPER_LITE = MockPaperLiteFromJs({
  id: '94e2260617274d17ef9ebf84b5081d5fc919cbab',
  authors: [rawAuthor, rawAuthor, rawAuthor, rawAuthor],
  pubDate: '2001-05-05',
  slug: 'super-important-paper',
  badges: [{ id: BadgeType.OPEN_ACCESS }],
});

const overrideFunction = meta => {
  switch (meta) {
    case ROW:
      return <div>Overriden Row Title</div>;
    case CARD:
      return <div>Overriden Card Title</div>;
  }
};

export const exampleConfig: ExampleConfig = {
  getComponent: () => CLPaperObject,
  fields: [
    {
      name: 'title',
      desc: 'Displayed default title or not',
      value: {
        type: 'boolean',
        default: true,
      },
    },
    {
      name: 'meta',
      desc: 'Displayed default paper meta or not',
      value: {
        type: 'boolean',
        default: true,
      },
    },
    {
      name: 'abstract',
      desc: 'Displayed default abstract or not',
      value: {
        type: 'boolean',
        default: true,
      },
    },
    {
      name: 'controls',
      desc: 'Displayed default controls or not',
      value: {
        type: 'boolean',
        default: true,
      },
    },
    {
      name: 'isInteractive',
      desc: 'Adds links and click handlers when enabled',
      value: {
        type: 'boolean',
        default: true,
      },
    },
  ],

  examples: [
    {
      title: 'PaperObject with Paper',
      desc: '',
      props: {
        paper: PAPER,
        isInteractive: true,
        footer: <CLPaperActions paper={PAPER} viewPaper={true} />,
      },
      render: comp => <div style={{ maxWidth: '800px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'PaperObject with PaperLite',
      desc: '',
      props: {
        paper: PAPER_LITE,
        isInteractive: true,
        footer: <CLPaperActions paper={PAPER_LITE} viewPaper={false} />,
      },
      render: comp => <div style={{ maxWidth: '800px', padding: '16px' }}>{comp}</div>,
    },
    {
      title: 'Paper with ContextualOverride',
      desc: '',
      props: {
        paper: PAPER,
        title: overrideFunction,
        isInteractive: true,
      },
      render: comp => <div style={{ maxWidth: '800px', padding: '16px' }}>{comp}</div>,
    },
  ],

  events: {},
};
