import Immutable from 'immutable';

import type { DEPRECATED__FlowOptional, TODO } from '@/utils/types';

type TODO__MentionContext = TODO<'MentionContext'>;

export type EntityRelationMentionFromJS = {
  srcEntityId: string;
  srcEntityName: string;
  srcEntitySlug: string;
  destEntityId: string;
  destEntityName: string;
  destEntitySlug: string;
  relationshipType: string;
  relationshipSubtype: string;
  mentionContexts: DEPRECATED__FlowOptional<TODO__MentionContext[]>;
};

export type Props = {
  srcEntityId: string;
  srcEntityName: string;
  srcEntitySlug: string;
  destEntityId: string;
  destEntityName: string;
  destEntitySlug: string;
  relationshipType: string;
  relationshipSubtype: string;
  mentionContexts: Immutable.List<TODO__MentionContext>;
};

const defaultProps: Props = {
  srcEntityId: '',
  srcEntityName: '',
  srcEntitySlug: '',
  destEntityId: '',
  destEntityName: '',
  destEntitySlug: '',
  relationshipType: '',
  relationshipSubtype: '',
  mentionContexts: Immutable.List(),
};

export const EntityRelationMentionRecordFactory = Immutable.Record<Props>(defaultProps);
export type EntityRelationMentionRecord = Immutable.RecordOf<Props>;

export function getEntityRelationMentionFromJS(
  args: EntityRelationMentionFromJS
): EntityRelationMentionRecord {
  return EntityRelationMentionRecordFactory({
    ...args,
    mentionContexts: Immutable.List(args.mentionContexts || []),
  });
}
