import { AuthorFromJS, AuthorRecord, AuthorRecordFactory, getAuthorFromJS } from './Author';

import Immutable from 'immutable';

export type AuthorRecommendationFromJS = {
  author: AuthorFromJS;
  isCoauthor: boolean;
};

type Props = {
  author: AuthorRecord;
  isCoauthor: boolean;
};

const defaultProps: Props = {
  author: AuthorRecordFactory(),
  isCoauthor: false,
};

export const AuthorRecommendationRecordFactory = Immutable.Record<Props>(defaultProps);

export type AuthorRecommendationRecord = Immutable.RecordOf<Props>;

export function getAuthorRecommendationFromJS({ author, ...props }) {
  return AuthorRecommendationRecordFactory({
    ...props,
    author: getAuthorFromJS(author),
  });
}
