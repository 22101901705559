import {
  getEmptyPaperPdfOverlapsMap,
  getPaperPdfOverlapsFromJS,
  PaperPdfOverlapsMap,
  PaperPdfOverlapsPropsFromJS,
} from './PaperPdfOverlaps';

import Immutable from 'immutable';

export type PaperPdfInteractionsPropsFromJS = {
  overlaps: PaperPdfOverlapsPropsFromJS;
};

type Props = {
  overlaps: PaperPdfOverlapsMap;
};

const defaultProps: Props = {
  overlaps: getEmptyPaperPdfOverlapsMap(),
};

export const PaperPdfInteractionsFactory = Immutable.Record<Props>(defaultProps);
export type PaperPdfInteractionsRecord = Immutable.RecordOf<Props>;

export function getPaperPdfInterationsFromJS({
  overlaps,
}: PaperPdfInteractionsPropsFromJS): PaperPdfInteractionsRecord {
  return PaperPdfInteractionsFactory({ overlaps: getPaperPdfOverlapsFromJS(overlaps) });
}
