import { getString } from '@/content/i18n';
import AsyncLoadedPage from '@/components/util/AsyncLoadedPage';

import React from 'react';

export default class AccountAuthorClaimPendingRoute extends React.Component {
  static getPageTitle = () => getString(_ => _.account.page.authorClaimPending.pageTitle);

  render() {
    return (
      <AsyncLoadedPage
        showLoadingPage={false}
        load={{
          importer: () =>
            import(
              /* webpackChunkName: "shared-AccountAuthorClaimPendingPage" */ './AccountAuthorClaimPendingPage'
            ),
          chunkName: 'shared-AccountAuthorClaimPendingPage',
          moduleId: require.resolveWeak('./AccountAuthorClaimPendingPage'),
        }}
      />
    );
  }
}
