import React from 'react';

export default function IconResearchDashboard(props: { id?: string }) {
  return (
    <symbol id={props.id} viewBox="0 0 24 24">
      <path d="M2.4,0h5.9c1.3,0,2.4,1.1,2.4,2.4v3.5c0,1.3-1.1,2.4-2.4,2.4H2.4C1.1,8.3,0,7.2,0,5.9V2.4C0,1.1,1.1,0,2.4,0z" />
      <path d="M15.7,0h5.9C22.9,0,24,1.1,24,2.4v8.2c0,1.3-1.1,2.4-2.4,2.4h-5.9c-1.3,0-2.4-1.1-2.4-2.4V2.4C13.3,1.1,14.4,0,15.7,0z" />
      <path d="M2.4,11h5.9c1.3,0,2.4,1.1,2.4,2.4v8.2c0,1.3-1.1,2.4-2.4,2.4H2.4C1.1,24,0,22.9,0,21.6v-8.2C0,12,1.1,11,2.4,11z" />
      <path
        d="M15.7,15.7h5.9c1.3,0,2.4,1.1,2.4,2.4v3.5c0,1.3-1.1,2.4-2.4,2.4h-5.9c-1.3,0-2.4-1.1-2.4-2.4v-3.5
        C13.3,16.8,14.4,15.7,15.7,15.7z"
      />
    </symbol>
  );
}
